import { useModal } from '@resily/geisha'
import { useMemo } from 'react'

import { ChildOkrReOrderingModal, ChildOkrReOrderingModalProps } from './ChildOkrReOrderingModal'
import { useChildrenOkrNodesQuery } from './graphql'
import { useReOrderingChildrenOkr } from './hooks/useReOrderingChildrenOkr'

export type ChildOkrReOrderingModalContainerProps = Pick<
  ChildOkrReOrderingModalProps,
  'isOpened' | 'onClose'
> & {
  okrNodeId: string | null | undefined
}

export const ChildOkrReOrderingModalContainer: React.VFC<
  ChildOkrReOrderingModalContainerProps & {
    modalComponent: ReturnType<typeof useModal>[0]
  }
> = ({ modalComponent: Modal, okrNodeId, ...rest }) => {
  const { data } = useChildrenOkrNodesQuery({
    variables: { okrNodeId: okrNodeId ?? '' },
    skip: okrNodeId == null,
  })
  const childrenOkrNodes = useMemo(() => data?.childrenOkrNodes ?? [], [data?.childrenOkrNodes])

  const { orderedChildrenOkrNodes, handleReOrderingChildrenOkrNodes } = useReOrderingChildrenOkr(
    okrNodeId,
    childrenOkrNodes,
  )

  return (
    <ChildOkrReOrderingModal
      modalComponent={Modal}
      childrenOkrNodes={orderedChildrenOkrNodes}
      setReOrderingChildrenOkrNodes={handleReOrderingChildrenOkrNodes}
      {...rest}
    />
  )
}
