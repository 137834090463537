import { useMemo, PropsWithoutRef, VFC } from 'react'

import { ReactComponent as LogoImg } from './logo.svg'
import { ReactComponent as LogoOnlyImg } from './logo_only.svg'

export type Props = PropsWithoutRef<JSX.IntrinsicElements['svg']> & { iconOnly?: boolean }

export const Logo: VFC<Props> = ({ iconOnly = false, ...props }) => {
  const Icon = useMemo(() => (iconOnly ? LogoOnlyImg : LogoImg), [iconOnly])
  return <Icon {...props} />
}

Logo.displayName = 'Logo'
