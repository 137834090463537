import { createEnumParam } from 'use-query-params'

import { PartialNever } from '../../../lib/type'

export const ObjectiveTabObj = {
  'm-o-description': 'm-o-description',
  'm-o-note': 'm-o-note',
  'm-o-activity': 'm-o-activity',
} as const
export type ObjectiveTab = keyof typeof ObjectiveTabObj
export const ObjectiveTabParam = createEnumParam<ObjectiveTab>(
  Object.values<ObjectiveTab>(ObjectiveTabObj),
)

export const KeyResultTabObj = {
  'm-kr-description': 'm-kr-description',
  'm-kr-action-plan': 'm-kr-action-plan',
  'm-kr-note': 'm-kr-note',
  'm-kr-activity': 'm-kr-activity',
} as const
export type KeyResultTab = keyof typeof KeyResultTabObj
export const KeyResultTabParam = createEnumParam<KeyResultTab>(
  Object.values<KeyResultTab>(KeyResultTabObj),
)

export type LoadableProps<T extends Record<never, never>> =
  | ({ loading: true } & PartialNever<T>)
  | ({ loading?: false } & T)
