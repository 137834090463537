import { PropsWithoutRef } from 'react'

import { BreadcrumbParams } from '../../components/domain/Breadcrumb'
import { NoteCommentCard as PersonalNoteCommentCard } from '../../components/domain/NoteCommentCard'
import { NoteDetailCard as PersonalNoteDetailCard } from '../../components/domain/NoteDetailCard'
import { PersonalNoteOptionContainer } from '../../components/domain/PersonalNoteOption'
import { PersonalNoteUserDialogContainer } from '../../components/domain/PersonalNoteUserDialog'
import { PageContent } from '../../components/pageContent'
import { Drawer } from '../../components/ui/Drawer'
import { useTranslation } from '../../i18n'
import { Screen } from '../../lib/screen'
import { useTracking } from '../../lib/tracking'
import { color } from '../../styles/newColors'
import { AuthRouteProps } from '../../types/authRouteProps'

import { File, PersonalNoteCommentItemNoteFragment, PersonalNoteDetailFragment } from './graphql'
import { useInjection } from './hooks'

export const PersonalNoteDetailContainer: React.FC<AuthRouteProps> = (props) => {
  const data = useInjection(props)

  if (!data) {
    return null
  }

  const {
    author,
    personalNote,
    breadcrumbs,
    deletePersonalNote,
    copyPersonalNote,
    createPersonalNoteReaction,
    deletePersonalNoteReaction,
    createPersonalNoteComment,
    updatePersonalNoteComment,
    deletePersonalNoteComment,
    createPersonalNoteCommentReaction,
    deletePersonalNoteCommentReaction,
    onPersonalNoteEdit,
    setNeedBlock,
    isDrawerOpen,
    shouldExpireCache,
    onDrawerClose,
    onPersonalNoteUserUpdate,
    onPersonalNoteUserClose,
  } = data

  return (
    <PersonalNoteDetail
      author={author}
      personalNote={personalNote}
      breadcrumbs={breadcrumbs}
      deletePersonalNote={deletePersonalNote}
      copyPersonalNote={copyPersonalNote}
      createPersonalNoteComment={createPersonalNoteComment}
      updatePersonalNoteComment={updatePersonalNoteComment}
      deletePersonalNoteComment={deletePersonalNoteComment}
      createPersonalNoteReaction={createPersonalNoteReaction}
      deletePersonalNoteReaction={deletePersonalNoteReaction}
      createPersonalNoteCommentReaction={createPersonalNoteCommentReaction}
      deletePersonalNoteCommentReaction={deletePersonalNoteCommentReaction}
      onPersonalNoteEdit={onPersonalNoteEdit}
      setNeedBlock={setNeedBlock}
      isDrawerOpen={isDrawerOpen}
      shouldExpireCache={shouldExpireCache}
      onDrawerClose={onDrawerClose}
      onPersonalNoteUserUpdate={onPersonalNoteUserUpdate}
      onPersonalNoteUserClose={onPersonalNoteUserClose}
    />
  )
}

PersonalNoteDetailContainer.displayName = 'PersonalNoteDetailContainer'

export type Props = PropsWithoutRef<JSX.IntrinsicElements['div']> & {
  personalNote: PersonalNoteDetailFragment
  author: {
    userId: string
    firstName: string
    lastName: string
    avatarUrl: string
    isDisabled: boolean
  }
  breadcrumbs: BreadcrumbParams
  deletePersonalNote: (id: string) => void
  copyPersonalNote: (id: string, title: string) => void
  createPersonalNoteComment: (
    parentNoteId: string,
    body: string,
    plainText: string,
    attachments: ReadonlyArray<File>,
  ) => void
  updatePersonalNoteComment: (comment: PersonalNoteCommentItemNoteFragment) => void
  deletePersonalNoteComment: (id: string) => void
  createPersonalNoteReaction: (emoji: string) => void
  deletePersonalNoteReaction: (reactedId: string) => void
  createPersonalNoteCommentReaction: (noteCommentId: string, emoji: string) => void
  deletePersonalNoteCommentReaction: (noteCommentId: string, reactedId: string) => void
  onPersonalNoteEdit: () => void
  setNeedBlock: (value: boolean) => void
  isDrawerOpen: boolean
  shouldExpireCache: boolean
  onDrawerClose: () => void
  onPersonalNoteUserUpdate: () => void
  onPersonalNoteUserClose: () => void
}

export const PersonalNoteDetail: React.FC<Props> = ({
  personalNote,
  author,
  breadcrumbs,
  deletePersonalNote,
  copyPersonalNote,
  createPersonalNoteComment,
  updatePersonalNoteComment,
  deletePersonalNoteComment,
  createPersonalNoteReaction,
  deletePersonalNoteReaction,
  createPersonalNoteCommentReaction,
  deletePersonalNoteCommentReaction,
  onPersonalNoteEdit,
  setNeedBlock,
  isDrawerOpen,
  shouldExpireCache,
  onDrawerClose,
  onPersonalNoteUserUpdate,
  onPersonalNoteUserClose,
}) => {
  const { t } = useTranslation()

  useTracking(
    t('PERSONAL_NOTE_DETAIL_PAGE_TITLE', { noteTitle: personalNote.title }),
    Screen.PersonalNoteDetail,
  )

  return (
    <PageContent
      breadcrumbs={breadcrumbs}
      layout={{
        css: {
          backgroundColor: color('background-bk-5'),
          padding: '60px max(40px, calc((100% - 1045px)/2)) ',
          minWidth: '1125px',
        },
      }}
    >
      <div
        css={{
          display: 'flex',
        }}
      >
        <div
          css={{
            minWidth: '805px',
          }}
        >
          <PersonalNoteDetailCard
            note={personalNote}
            onEdit={onPersonalNoteEdit}
            copyNote={copyPersonalNote}
            deleteNote={deletePersonalNote}
            createNoteReaction={createPersonalNoteReaction}
            deleteNoteReaction={deletePersonalNoteReaction}
          />
          <PersonalNoteCommentCard
            noteId={personalNote.id}
            author={author}
            comments={personalNote.comments}
            setNeedBlock={setNeedBlock}
            createNoteComment={createPersonalNoteComment}
            updateNoteComment={updatePersonalNoteComment}
            deleteNoteComment={deletePersonalNoteComment}
            createNoteCommentReaction={createPersonalNoteCommentReaction}
            deleteNoteCommentReaction={deletePersonalNoteCommentReaction}
            css={{
              marginTop: 36,
            }}
          />
        </div>
        <div css={{ marginTop: '9px', marginLeft: '8px', minWidth: '232px' }}>
          <PersonalNoteOptionContainer personalNoteId={personalNote.id} />
        </div>
      </div>
      <Drawer open={isDrawerOpen} onClose={onDrawerClose}>
        <PersonalNoteUserDialogContainer
          personalNoteId={personalNote.id}
          shouldExpireCache={shouldExpireCache}
          onUpdated={onPersonalNoteUserUpdate}
          close={onPersonalNoteUserClose}
        />
      </Drawer>
    </PageContent>
  )
}

PersonalNoteDetail.displayName = 'PersonalNoteDetail'
