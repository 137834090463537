import React from 'react'

import { customerPortalFormURL } from '../../constants'
import { Form } from '../Form'

export const SubscriptionContent: React.VFC = () => (
  <Form action={customerPortalFormURL}>
    <Form.TitleSection>
      <Form.TitleSection.Title>プラン・お支払い設定</Form.TitleSection.Title>
      <Form.TitleSection.Description>
        招待可能なライセンス数の変更やプランのキャンセルができます。
        <br />
        その他、お支払い履歴や請求先情報の確認も可能です。
      </Form.TitleSection.Description>
    </Form.TitleSection>
    <Form.SubmitButton>プラン・お支払いの設定</Form.SubmitButton>
  </Form>
)
