import { css } from '@emotion/react'
import { Skeleton } from '@resily/geisha'

import { ObjectiveAvatar } from '../../../../../components/domain/AvatarWithContributor'
import { KeyResultName } from '../../../../../components/domain/KeyResultName'
import { Avatar } from '../../../../../components/ui/Avatar'
import { Link } from '../../../../../components/ui/Link'
import { ProgressRate } from '../../../../../components/ui/Meter'
import { TooltipNew } from '../../../../../components/ui/TooltipNew'
import { useTranslation } from '../../../../../i18n'
import { isIncludedUser as isIncludedUserKeyResult } from '../../../../../lib/domain/keyResult/keyResult'
import { isIncludedUser as isIncludedUserObjective } from '../../../../../lib/domain/objective'
import { fontSize } from '../../../../../styles/font'
import { color } from '../../../../../styles/newColors'

import { ThinKeyResultProgressRateBar } from './ThinKeyResultProgressRateBar'
import { TrendButton } from './TrendButton'
import {
  KR_AVATER_RIGHT_SPACE,
  KR_AVATER_SIZE,
  KR_PROGRESS_LEFT_SPACE,
  KR_PROGRESS_WIDTH,
  O_KR_GAP,
  O_WIDTH,
} from './const'
import { ObjectiveFragment } from './graphql'

const rowRootCss = css({
  padding: '16px 0',
  display: 'flex',
  alignItems: 'center',
  gap: O_KR_GAP,
  boxShadow: `0px 1px 0px 0px ${color('border-bk-10')}`,
})

const objectiveAreaCss = css({
  width: O_WIDTH,
  padding: 12,
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '44px 212px',
})
const objectiveCss = css(objectiveAreaCss, {
  borderRadius: 4,
  cursor: 'pointer',
  '.oname': {
    textDecoration: 'none',
  },
  ':hover': {
    backgroundColor: color('hover-background-bk-5'),
    '.oname': {
      color: color('resily-orange-100'),
      opacity: 1,
    },
  },
})
const objectiveNameCss = css({
  ...fontSize('medium', 'bold'),
  overflow: 'hidden',
  // stylelint-disable-next-line
  display: '-webkit-box',
  // stylelint-disable-next-line
  WebkitLineClamp: 3,
  // stylelint-disable-next-line
  WebkitBoxOrient: 'vertical',
  '&[data-disabled="true"]': {
    textDecoration: 'line-through',
    opacity: 0.2,
    ':hover': { opacity: 1 },
  },
})
const objectiveProgressRateAndTrendAreaCss = css({
  gridColumnStart: 2,
  gridColumnEnd: 2,
  gridRowStart: 2,
  gridRowEnd: 2,
  marginTop: 10,
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
})

const keyResultsCss = css({ width: `calc(100% - ${O_WIDTH + O_KR_GAP}px)` })
const keyResultRowAreaCss = css({
  height: 28,
  paddingLeft: 4,
  display: 'flex',
  alignItems: 'center',
  '& > *:nth-child(1)': {
    flex: `0 0 ${KR_AVATER_SIZE}px`,
    marginRight: KR_AVATER_RIGHT_SPACE,
  },
  '& > *:nth-child(2)': {
    flex: '1 1 auto',
    width: `calc(100% - ${
      KR_AVATER_SIZE + KR_AVATER_RIGHT_SPACE + KR_PROGRESS_LEFT_SPACE + KR_PROGRESS_WIDTH
    }px)`,
  },
  '& > *:nth-child(3)': {
    flex: `0 0 ${KR_PROGRESS_WIDTH}px`,
    marginLeft: KR_PROGRESS_LEFT_SPACE,
  },
})
const keyResultRowCss = css(keyResultRowAreaCss, {
  borderRadius: 4,
  cursor: 'pointer',
  '.krname a': {
    textDecoration: 'none',
  },
  ':hover': {
    backgroundColor: color('hover-background-bk-5'),
    '.krname a': {
      color: color('resily-orange-100'),
    },
  },
})
const keyResultNameCss = css({
  display: 'flex',
  alignItems: 'center',
  '& > svg': { flex: '0 0 auto' },
  '& > div': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
})

export type Props = {
  termId: string
  userId: string
  objective: ObjectiveFragment
  onClickObjectiveName: (objectiveId: string, isAssigned: boolean) => void
  onClickKeyResultName: (objectiveId: string, keyResultId: string, isAssigned: boolean) => void
  onClickObjectiveTrend: (objectiveId: string, isAssigned: boolean) => void
  onClickKeyResultTrend: (objectiveId: string, keyResultId: string, isAssigned: boolean) => void
}

export const OkrsTableRow: React.FC<Props> = ({
  termId,
  userId,
  objective,
  onClickObjectiveName,
  onClickKeyResultName,
  onClickObjectiveTrend,
  onClickKeyResultTrend,
}) => {
  const { t } = useTranslation()
  const isAssignedToObjective = isIncludedUserObjective(objective, userId ? { id: userId } : null)

  return (
    <li css={rowRootCss}>
      {/* eslint-disable-next-line jsx-a11y/interactive-supports-focus, jsx-a11y/click-events-have-key-events */}
      <div
        role="button"
        css={objectiveCss}
        onClick={() => onClickObjectiveName(objective.id, isAssignedToObjective)}
      >
        <ObjectiveAvatar size="okr-map-list-page" termId={termId} owner={objective.owner} />
        <TooltipNew title={t('LOOK_AT_X', { x: t('DETAIL') })}>
          <Link
            className="oname"
            css={objectiveNameCss}
            data-disabled={objective.isDisabled}
            onClick={(e) => {
              // 上位Div要素のonClickObjectiveNamekが発火しないようにする
              e.stopPropagation()
              onClickObjectiveName(objective.id, isAssignedToObjective)
            }}
          >
            {objective.name}
          </Link>
        </TooltipNew>
        <div css={objectiveProgressRateAndTrendAreaCss}>
          <ProgressRate color={color('objective-blue-100')} rate={objective.progressRate || 0} />
          {objective.progressRateTrend !== 0 && (
            <TrendButton
              progressRateTrend={objective.progressRateTrend}
              onClick={(e) => {
                // 上位Div要素のonClickObjectiveNameが発火しないようにする
                e.stopPropagation()
                onClickObjectiveTrend(objective.id, isAssignedToObjective)
              }}
            />
          )}
        </div>
      </div>
      <ul css={keyResultsCss}>
        {objective.keyResults.map((kr) => {
          let MyOkrAvatarData: typeof kr.owner | typeof kr.members[number] | undefined
          if (kr.owner.id === userId) {
            MyOkrAvatarData = kr.owner
          } else if (kr.members.some((m) => m.id === userId)) {
            MyOkrAvatarData = kr.members.find((m) => m.id === userId)
          }

          return (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events
            <li
              key={kr.id}
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
              role="button"
              css={keyResultRowCss}
              onClick={() => {
                onClickKeyResultName(
                  objective.id,
                  kr.id,
                  isIncludedUserKeyResult(kr, userId ? { id: userId } : null),
                )
              }}
            >
              {MyOkrAvatarData != null ? (
                <div>
                  <Avatar
                    size="xsmall"
                    firstName={MyOkrAvatarData.firstName}
                    lastName={MyOkrAvatarData.lastName}
                    avatarUrl={MyOkrAvatarData.avatar?.url}
                    isUserDisabled={MyOkrAvatarData.isDisabled}
                  />
                </div>
              ) : (
                <div /> // spacer
              )}
              <TooltipNew title={t('LOOK_AT_X', { x: t('DETAIL') })}>
                <KeyResultName
                  css={keyResultNameCss}
                  className="krname"
                  fontSize="small"
                  fontStyle="bold"
                  keyResult={kr}
                  onClick={noop} // スタイルを変えるためだけに渡してる
                />
              </TooltipNew>
              <ThinKeyResultProgressRateBar keyResult={kr} onClickTrend={onClickKeyResultTrend} />
            </li>
          )
        })}
      </ul>
    </li>
  )
}

const noop = () => {}

export const OkrsTableRowSkeleton: React.FC = () => (
  <li css={rowRootCss}>
    <div css={objectiveAreaCss}>
      <Skeleton circle height={28} />
      <div css={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
        <Skeleton text width={212} height={14} />
        <Skeleton text width={212} height={14} />
        <Skeleton text width={212} height={14} />
      </div>
      <div css={objectiveProgressRateAndTrendAreaCss}>
        <Skeleton text width={84} height={16} />
      </div>
    </div>
    <ul css={keyResultsCss}>
      {new Array(4).fill(undefined).map(() => (
        <li css={keyResultRowAreaCss}>
          <Skeleton circle height={16} />
          <Skeleton text height={12} />
          <Skeleton text height={12} />
        </li>
      ))}
    </ul>
  </li>
)
