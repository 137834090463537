import { css } from '@emotion/react'
import { useMemo } from 'react'

import { color } from '../../../../../../styles/newColors'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useStyles = () =>
  useMemo(
    () =>
      ({
        root: css({
          paddingBottom: 8,
        }),
        header: css({
          display: 'flex',
          alignItems: 'center',
          borderBottom: `1px solid ${color('border-bk-10')}`,
          paddingBottom: '8px',
          lineHeight: '20px',
        }),
        titleColumn: css({
          paddingLeft: '20px',
          width: '372px',
        }),
        progressColumn: css({
          width: '100px',
        }),
        valuesColumn: css({
          width: '101px',
        }),
        lastUpdateColumn: css({
          width: '59px',
        }),
      } as const),
    [],
  )
