import type { OkrTitleProps } from '@resily/geisha'

import { TargetSettingType } from '../../graphql'

type TargetSettingTypeForGeisha = OkrTitleProps['leftIconProps']

export const castTargetSetting = (targetSetting: TargetSettingType): TargetSettingTypeForGeisha => {
  switch (targetSetting) {
    case TargetSettingType.Moon:
      return { type: 'moonshot' }
    case TargetSettingType.Roof:
      return { type: 'roofshot' }
    default:
      return undefined
  }
}
