import { Box } from 'grommet'
import React, { useMemo } from 'react'

import { Breadcrumb, BreadcrumbParams } from '../../components/domain/Breadcrumb'
import { PageContent } from '../../components/pageContent'
import { StyledText } from '../../components/ui/StyledText'
import { useTranslation } from '../../i18n'
import { formatDateGraphql } from '../../lib/date'
import { Screen } from '../../lib/screen'
import { useTracking } from '../../lib/tracking'
import { color } from '../../styles/newColors'
import { AuthRouteProps } from '../../types/authRouteProps'
import { okrTermsAdmin } from '../../urls'

import { AdminOkrTerms } from './components/AdminOkrTerms'
import { useInjection } from './hooks'
import { adminOkrTermContainerLayout, sectionDescriptionWrapperCss } from './styles'

const convertDateToGraphqlDate = (date: Date): Date =>
  formatDateGraphql(date.toISOString()) as unknown as Date

export const AdminOkrTermsContainer: React.FC<AuthRouteProps> = () => {
  const { t } = useTranslation()

  useTracking(t('ADMIN_OKR_TERMS_PAGE_TITLE'), Screen.OkrTermSetting)

  const breadcrumbs = useMemo<BreadcrumbParams>(
    () => ({
      url: okrTermsAdmin,
      items: [
        {
          breadcrumbName: 'setting',
        },
        {
          breadcrumbName: 'okrTerms',
        },
      ],
    }),
    [],
  )

  const data = useInjection()

  if (!data) {
    return null
  }
  const { okrTerms, updateOkrTerm, copyOkrTerm, createOkrTerm, deleteOkrTerm, downloadOkrTerm } =
    data

  return (
    <PageContent
      css={{ position: 'relative' }}
      contentBackgroundColor={color('background-bk-5')}
      layout={adminOkrTermContainerLayout}
    >
      <>
        <Breadcrumb breadcrumbs={breadcrumbs} />
        <div css={sectionDescriptionWrapperCss}>
          <StyledText weight="bold" size="xlarge">
            {t('OKR_TERM')}
          </StyledText>
          <Box gap="none">
            <StyledText size="medium">{t('OKR_TERM_DESCRIPTION_FIRST')}</StyledText>
            <StyledText size="medium">{t('OKR_TERM_DESCRIPTION_SECOND')}</StyledText>
          </Box>
        </div>

        <AdminOkrTerms
          terms={okrTerms}
          onUpdate={(values, onUpdated) =>
            updateOkrTerm({
              id: values.id,
              startDate: convertDateToGraphqlDate(values.startDate),
              endDate: convertDateToGraphqlDate(values.endDate),
              name: values.name,
            }).then(onUpdated)
          }
          onCopy={(values, onCopied) =>
            copyOkrTerm({
              id: values.id,
              startDate: convertDateToGraphqlDate(values.startDate),
              endDate: convertDateToGraphqlDate(values.endDate),
              name: values.name,
            }).then(() => onCopied())
          }
          onCreate={(values, onCreated) => {
            createOkrTerm({
              startDate: convertDateToGraphqlDate(values.startDate),
              endDate: convertDateToGraphqlDate(values.endDate),
              name: values.name,
            }).then(onCreated)
          }}
          onDelete={(termId, onDeleted) => {
            deleteOkrTerm({
              id: termId,
            }).then(onDeleted)
          }}
          onDownload={downloadOkrTerm}
        />
      </>
    </PageContent>
  )
}

AdminOkrTermsContainer.displayName = 'AdminOkrTermsContainer'
