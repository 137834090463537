import { BreadcrumbParams } from '../../components/domain/Breadcrumb'
import { NoteCommentCard } from '../../components/domain/NoteCommentCard'
import { NoteDetailCard } from '../../components/domain/NoteDetailCard'
import { NoteOptionContainer } from '../../components/domain/NoteOption'
import { NoteUserDialogContainer } from '../../components/domain/NoteUserDialog'
import { PageContent } from '../../components/pageContent'
import { Drawer } from '../../components/ui/Drawer'
import { useTranslation } from '../../i18n'
import { Screen } from '../../lib/screen'
import { useTracking } from '../../lib/tracking'
import { color } from '../../styles/newColors'
import { AuthRouteProps } from '../../types/authRouteProps'

import { CommentItemNoteFragment, File, NoteDetailFragment } from './graphql'
import { useInjection } from './hooks'

export const NoteDetailContainer: React.FC<AuthRouteProps> = (props) => {
  const data = useInjection(props)

  if (!data) {
    return null
  }

  const {
    author,
    note,
    breadcrumbs,
    deleteNote,
    copyNote,
    createNoteReaction,
    deleteNoteReaction,
    createComment,
    updateComment,
    deleteComment,
    createNoteCommentReaction,
    deleteNoteCommentReaction,
    onNoteEdit,
    setNeedBlock,
    isDrawerOpen,
    shouldExpireCache,
    onDrawerClose,
    onNoteUserUpdate,
    onNoteUserClose,
  } = data

  return (
    <NoteDetail
      author={author}
      note={note}
      breadcrumbs={breadcrumbs}
      deleteNote={deleteNote}
      copyNote={copyNote}
      createNoteReaction={createNoteReaction}
      deleteNoteReaction={deleteNoteReaction}
      createComment={createComment}
      updateComment={updateComment}
      deleteComment={deleteComment}
      createNoteCommentReaction={createNoteCommentReaction}
      deleteNoteCommentReaction={deleteNoteCommentReaction}
      onNoteEdit={onNoteEdit}
      setNeedBlock={setNeedBlock}
      isDrawerOpen={isDrawerOpen}
      shouldExpireCache={shouldExpireCache}
      onDrawerClose={onDrawerClose}
      onNoteUserUpdate={onNoteUserUpdate}
      onNoteUserClose={onNoteUserClose}
    />
  )
}

NoteDetailContainer.displayName = 'NoteDetailContainer'

export type Props = {
  note: NoteDetailFragment
  author: {
    userId: string
    firstName: string
    lastName: string
    avatarUrl: string
    isDisabled: boolean
  }
  breadcrumbs: BreadcrumbParams
  deleteNote: (id: string) => void
  copyNote: (id: string, title: string) => void
  createNoteReaction: (emoji: string) => void
  deleteNoteReaction: (reactedId: string) => void
  createComment: (
    parentNoteId: string,
    body: string,
    plainText: string,
    attachments: ReadonlyArray<File>,
  ) => void
  updateComment: (comment: CommentItemNoteFragment) => void
  deleteComment: (id: string) => void
  createNoteCommentReaction: (noteCommentId: string, emoji: string) => void
  deleteNoteCommentReaction: (noteCommentId: string, reactedId: string) => void
  onNoteEdit: () => void
  setNeedBlock: (value: boolean) => void
  isDrawerOpen: boolean
  shouldExpireCache: boolean
  onDrawerClose: () => void
  onNoteUserUpdate: () => void
  onNoteUserClose: () => void
}

export const NoteDetail: React.FC<Props> = ({
  note,
  author,
  breadcrumbs,
  deleteNote,
  copyNote,
  createNoteReaction,
  deleteNoteReaction,
  createNoteCommentReaction,
  deleteNoteCommentReaction,
  createComment,
  updateComment,
  deleteComment,
  onNoteEdit,
  setNeedBlock,
  isDrawerOpen,
  shouldExpireCache,
  onDrawerClose,
  onNoteUserUpdate,
  onNoteUserClose,
}) => {
  const { t } = useTranslation()
  useTracking(t('NOTE_DETAIL_PAGE_TITLE', { noteTitle: note.title }), Screen.NoteDetail)

  return (
    <PageContent
      breadcrumbs={breadcrumbs}
      layout={{
        css: {
          backgroundColor: color('background-bk-5'),
          padding: '60px max(40px, calc((100% - 1045px)/2)) ',
          minWidth: '1125px',
        },
      }}
    >
      <div
        css={{
          display: 'flex',
        }}
      >
        <div
          css={{
            minWidth: '805px',
          }}
        >
          <NoteDetailCard
            note={note}
            onEdit={onNoteEdit}
            copyNote={copyNote}
            deleteNote={deleteNote}
            createNoteReaction={createNoteReaction}
            deleteNoteReaction={deleteNoteReaction}
          />
          <NoteCommentCard
            noteId={note.id}
            author={author}
            comments={note.comments}
            setNeedBlock={setNeedBlock}
            createNoteComment={createComment}
            updateNoteComment={updateComment}
            deleteNoteComment={deleteComment}
            createNoteCommentReaction={createNoteCommentReaction}
            deleteNoteCommentReaction={deleteNoteCommentReaction}
            css={{
              marginTop: 36,
            }}
          />
        </div>
        <div css={{ marginTop: '9px', marginLeft: '8px', minWidth: '232px' }}>
          <NoteOptionContainer noteId={note.id} />
        </div>
      </div>
      <Drawer open={isDrawerOpen} onClose={onDrawerClose}>
        <NoteUserDialogContainer
          noteId={note.id}
          shouldExpireCache={shouldExpireCache}
          onUpdated={onNoteUserUpdate}
          close={onNoteUserClose}
        />
      </Drawer>
    </PageContent>
  )
}

NoteDetail.displayName = 'NoteDetail'
