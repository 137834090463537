import { Spinner } from 'grommet'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { FixedSizeList as List } from 'react-window'

import { useTranslation } from '../../../i18n'
import { filterString } from '../../../lib/array'
import { bySearchTextOrName } from '../../../lib/domain/user/searchText'
import { border } from '../../../styles/border'
import { fontSize } from '../../../styles/font'
import { color } from '../../../styles/newColors'
import { Icon } from '../Icon'
import { StyledText } from '../StyledText'
import { TooltipNew } from '../TooltipNew'

import { OPTION_CLASSNAME, OPTION_FOCUS_CLASSNAME, useKeyboardCursor } from './hooks'

const ICON_WIDTH = 24
const ROW_WIDTH = 274
const ROW_HEIGHT = 40

export type Option = {
  id: string // onClickOptionに渡される値
  name: string // tooltipDisabledがfalseの時にtooptipに表示される文字列 && searchText未指定時の検索対象
  searchText?: string // canSearchがtrueの時に入力された文字列で検索される際の対象になる文字列
  selected: boolean // hideSelectedCheckがfalseの時にチェックマークを表示するかどうか
  icon?: React.ReactElement // 幅はmargin/padding込みでICON_WIDTHであることを想定
  isDisabled: boolean // 無効表示用のフラグ
}

export type Props = {
  canSearch: boolean
  tooltipDisabled?: boolean
  isLoading?: boolean
  searchPlaceholder?: string
  options: ReadonlyArray<Option>
  selectedIds?: ReadonlyArray<string>
  onClickOption: (id: string) => void
  onBlurFromMutliSelect?: () => void
  hideSelectedCheck?: boolean
  full?: boolean
}

export const MultiSelect: React.FC<Props> = ({
  canSearch,
  tooltipDisabled = false,
  isLoading = false,
  searchPlaceholder,
  onBlurFromMutliSelect,
  options,
  selectedIds,
  onClickOption,
  children,
  hideSelectedCheck = false,
  full = false,
}) => {
  const { t } = useTranslation()

  const multiSelectRef = useRef<HTMLDivElement>(null)
  const ref = useRef<HTMLInputElement>(null)

  const [searchText, setSearchText] = useState<string>('')
  const [searchedOptions, setSearchedOptions] = useState<ReadonlyArray<Option>>(options)

  useKeyboardCursor(ref, canSearch)

  useLayoutEffect(() => {
    if (canSearch && ref.current) {
      ref.current.focus()
    }
  }, [canSearch])

  useEffect(() => {
    if (canSearch) {
      setSearchedOptions(filterString(searchText, options, bySearchTextOrName))
    } else {
      setSearchedOptions(options)
    }
  }, [canSearch, options, searchText])

  useLayoutEffect(() => {
    const onBlur = (e: FocusEvent) => {
      const prevIsThisElement = e.target && multiSelectRef.current?.contains(e.target as Node)
      const nextIsNotThisElement =
        e.relatedTarget && !multiSelectRef.current?.contains(e.relatedTarget as Node)

      if (prevIsThisElement && nextIsNotThisElement && onBlurFromMutliSelect) {
        onBlurFromMutliSelect()
      }
    }

    document.addEventListener('blur', onBlur, true)
    return () => document.removeEventListener('blur', onBlur, true)
  }, [onBlurFromMutliSelect])

  const listWidth = searchedOptions.find((o) => !!o.icon)?.icon ? ROW_WIDTH + ICON_WIDTH : ROW_WIDTH

  return (
    <div
      ref={multiSelectRef}
      css={{
        backgroundColor: color('white-100'),
        border: border('simple-30'),
        boxShadow: '0px 8px 16px rgb(34 41 67 / 8%)',
        borderRadius: '4px',
        width: full ? '100%' : listWidth + 2,
      }}
    >
      {canSearch ? (
        <input
          ref={ref}
          type="text"
          placeholder={searchPlaceholder}
          value={searchText}
          onChange={(event) => setSearchText(event.currentTarget.value)}
          css={{
            ...fontSize('medium'),
            height: '50px',
            width: '100%',
            color: color('text-bk-100'),
            paddingLeft: '16px',
            paddingRight: '16px',
            marginBottom: '1px',
            boxShadow: '0px 1px 0px #E9EAEC',
            '::placeholder': {
              color: color('text-bk-30'),
            },
            ':focus': {
              backgroundColor: color('background-bk-5'),
              borderRadius: '4px 4px 0 0',
              outline: 'none',
              boxShadow: '0px 1px 0px #DC440A',
            },
          }}
        />
      ) : null}

      {isLoading && (
        <div
          style={{
            display: 'flex',
            padding: '10px 0',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '4px',
          }}
        >
          <Spinner />
        </div>
      )}
      {!isLoading && (
        <div>
          <List
            itemCount={searchedOptions.length}
            itemData={searchedOptions}
            itemSize={ROW_HEIGHT}
            height={Math.min(200, ROW_HEIGHT * searchedOptions.length)}
            width={full ? '100%' : listWidth}
          >
            {({ index, data, style }) => {
              const option: Option = data[index]

              return (
                <button
                  className={OPTION_CLASSNAME}
                  type="button"
                  style={style}
                  css={{
                    width: full ? '100%' : listWidth,
                    height: ROW_HEIGHT,
                    paddingLeft: '8px',
                    ':focus': {
                      outline: 'none',
                    },
                    ':hover': {
                      backgroundColor: color('hover-background-bk-5'),
                    },
                    [`&.${OPTION_FOCUS_CLASSNAME}`]: {
                      backgroundColor: color('hover-background-bk-5'),
                    },
                  }}
                  onClick={() => onClickOption(option.id)}
                >
                  <TooltipNew
                    disabled={tooltipDisabled}
                    title={option.name}
                    margin={{ left: '24px' }}
                    dropProps={{
                      align: {
                        top: 'bottom',
                        left: 'left',
                      },
                      stretch: false,
                      plain: true,
                    }}
                  >
                    <div
                      css={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {!hideSelectedCheck &&
                      (option.selected || selectedIds?.includes(option.id)) ? (
                        <Icon type="mdiCheck" css={{ marginRight: '8px' }} />
                      ) : (
                        <div css={{ width: hideSelectedCheck ? '8px' : '24px', flexShrink: 0 }} />
                      )}
                      <div
                        css={{
                          display: 'flex',
                          alignItems: 'center',
                          width: `calc(100% - ${hideSelectedCheck ? '8px' : '24px'})`,
                        }}
                      >
                        {
                          // NOTE: アイコン未指定時にその分の空欄で埋めたい場合は以下のコメントを外す
                          option.icon /* || <div css={{ width: '16px', marginRight: '2px' }} /> */
                        }
                        <StyledText
                          css={{
                            display: 'inline-block',
                            maxWidth: full ? '100%' : '15rem',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            marginLeft: option.icon ? '8px' : '0',
                          }}
                        >
                          {!option.isDisabled ? (
                            <span>{option.name}</span>
                          ) : (
                            <span css={{ color: color('text-bk-20') }}>
                              ({t('DISABLING')})
                              <span css={{ textDecoration: 'line-through' }}>{option.name}</span>
                            </span>
                          )}
                        </StyledText>
                      </div>
                    </div>
                  </TooltipNew>
                </button>
              )
            }}
          </List>
        </div>
      )}
      <div css={{ boxShadow: '0px -1px 0px #E9EAEC' }}>{children}</div>
    </div>
  )
}

MultiSelect.displayName = 'MultiSelect'
