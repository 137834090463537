import { Skeleton } from '@resily/geisha'

import { styles } from './styles/activityCardSkelton.styles'

type Props = {
  showCheckinComment?: boolean
}

export const ActivityCardSkelton: React.FC<Props> = ({ showCheckinComment }) => (
  <div css={styles.root}>
    <div css={styles.header}>
      <div css={styles.link}>
        <Skeleton circle width={28} height={28} css={styles.avatar} />
        <Skeleton text width={322} height={12} />
      </div>
      <Skeleton text width={96} height={12} />
    </div>
    <div css={styles.body}>
      <Skeleton width={400} height={68} />
      {showCheckinComment && (
        <div>
          <div css={styles.checkinComment}>
            <Skeleton text width={64} height={20} css={styles.checkinLabel} />
            <Skeleton text width={180} height={14} />
          </div>
          <div css={styles.checkinComment}>
            <Skeleton text width={64} height={20} css={styles.checkinLabel} />
            <Skeleton text width={180} height={14} />
          </div>
          <div css={styles.checkinComment}>
            <Skeleton text width={64} height={20} css={styles.checkinLabel} />
            <Skeleton text width={180} height={14} />
          </div>
          <div css={styles.checkinComment}>
            <Skeleton text width={64} height={20} css={styles.checkinLabel} />
            <Skeleton text width={180} height={14} />
          </div>
        </div>
      )}
    </div>
  </div>
)
