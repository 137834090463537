import { AnchorProps, Box, Anchor } from 'grommet'

import { color } from '../../styles/newColors'

type Props = AnchorProps & {
  disabled?: boolean
  space?: boolean
  icon: JSX.Element
  onClick?: () => void
}

export const MenuListItem: React.FC<Props> = ({ disabled = false, icon, onClick, ...props }) => (
  <Box
    as="li"
    hoverIndicator={{ color: disabled ? '' : color('hover-background-bk-5') }}
    onClick={() => {
      if (!disabled && onClick) {
        onClick()
      }
    }} // hoverIndicatorを機能させるにはonClickがtruthyである必要がある
    pad={{ horizontal: '16px', vertical: '4px' }}
    css={{
      boxShadow: 'none',
    }}
  >
    <Anchor
      weight="normal"
      color={disabled ? color('text-bk-30') : color('text-bk-100')}
      {...props}
      icon={icon}
    />
  </Box>
)

MenuListItem.displayName = 'MenuListItem'
