import { useNavigate, useParams } from 'react-router-dom'

import { CheckinSummaryPageView } from '../../components/domain/CheckinSummaryPageView'
import { useCurrentUser } from '../../contexts/UserContext'
import { AuthRouteProps } from '../../types/authRouteProps'
import { generateCheckinSummaryEdit } from '../../urls'

import {
  CheckinSummaryDocument,
  CheckinSummaryQuery,
  CheckinSummaryQueryVariables,
  useCheckinSummaryQuery,
  useCreateCheckinSummaryReactionMutation,
  useDeleteCheckinSummaryReactionMutation,
  useOkrNodeQuery,
  useUpdateKeyResultSharedItemMutation,
} from './graphql'

export const CheckinSummaryContainer: React.FC<AuthRouteProps> = () => {
  const navigate = useNavigate()
  const user = useCurrentUser()

  const { okrNodeId, checkinSummaryId } = useParams<{
    okrNodeId: string
    checkinSummaryId: string
  }>()

  const summaryQuery = useCheckinSummaryQuery({
    variables: {
      checkinSummaryId: checkinSummaryId ?? '',
    },
    // NOTE: 古い情報が表示されてしまうためキャッシュを無効にする
    fetchPolicy: 'network-only',
    skip: checkinSummaryId == null,
  })

  const okrNodeQuery = useOkrNodeQuery({
    variables: {
      okrNodeId,
    },
  })

  const [updateKeyResultSharedItem] = useUpdateKeyResultSharedItemMutation()
  const [createReaction] = useCreateCheckinSummaryReactionMutation()
  const [deleteReaction] = useDeleteCheckinSummaryReactionMutation()

  if (!user) return null

  if (!summaryQuery.data?.checkinSummary) return null
  const { checkinSummary } = summaryQuery.data

  if (!okrNodeQuery.data?.okrNode) return null

  const createReactionMutation = async (emoji: string) => {
    await createReaction({
      variables: { checkinSummaryId: checkinSummaryId ?? '', emoji },
      update(cache, { data }) {
        if (!data) {
          return
        }

        const option = {
          query: CheckinSummaryDocument,
          variables: { checkinSummaryId: checkinSummaryId ?? '' },
        }
        const res = cache.readQuery<CheckinSummaryQuery, CheckinSummaryQueryVariables>(option)
        if (!res) {
          return
        }

        const NewCheckinSummary = res.checkinSummary

        if (
          NewCheckinSummary.reactions.find((e) => e.emoji === emoji && e.user?.id === user?.id) !=
          null
        )
          return

        cache.writeQuery<CheckinSummaryQuery, CheckinSummaryQueryVariables>({
          ...option,
          data: {
            checkinSummary: {
              ...NewCheckinSummary,
              reactions: [...NewCheckinSummary.reactions, data.createCheckinSummaryReaction],
            },
          },
        })
      },
    }).catch(() => {})
  }

  const deleteReactionMutation = async (id: string) => {
    await deleteReaction({
      variables: { id },
      update(cache) {
        const option = {
          query: CheckinSummaryDocument,
          variables: { checkinSummaryId: checkinSummaryId ?? '' },
        }
        const res = cache.readQuery<CheckinSummaryQuery, CheckinSummaryQueryVariables>(option)
        if (!res) {
          return
        }

        const NewCheckinSummary = res.checkinSummary

        cache.writeQuery<CheckinSummaryQuery, CheckinSummaryQueryVariables>({
          ...option,
          data: {
            checkinSummary: {
              ...NewCheckinSummary,
              reactions: NewCheckinSummary.reactions.filter((e) => e.id !== id),
            },
          },
        })
      },
    }).catch(() => {})
  }

  return (
    <CheckinSummaryPageView
      checkinSummary={checkinSummary}
      onClickDetailEdit={() => {
        navigate(generateCheckinSummaryEdit(checkinSummary.node.id, checkinSummary.id))
      }}
      onClickUpdateSharedItem={(id, itemType, body) =>
        updateKeyResultSharedItem({
          variables: {
            id,
            itemType,
            body,
          },
          update(cache, { data: updatedData }) {
            if (!updatedData) return

            // 更新対象のkeyResultSharedItemが更新されたら（削除されていなければ）、Apollo Cacheの更新はしない
            if (updatedData.updateKeyResultSharedItem?.body) return

            // Apollo Cacheから削除したkeyResultSharedItemを削除
            cache.evict({ id: cache.identify(updatedData.updateKeyResultSharedItem) })
          },
        })
      }
      onClickCreateReaction={createReactionMutation}
      onClickDeleteReaction={deleteReactionMutation}
    />
  )
}

CheckinSummaryContainer.displayName = 'CheckinSummaryContainer'
