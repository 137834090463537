import { CSSObject } from '@emotion/react'
import { FC } from 'react'

import { ListCard } from '../../../../../components/ui/ListCard'
import { StyledText } from '../../../../../components/ui/StyledText'
import { useTranslation } from '../../../../../i18n'
import { formatDateInput } from '../../../../../lib/date'
import { TermFragment } from '../../../api/graphql'

import { AdminOkrTermsPageTermTableButtonCell } from './AdminOkrTermsPageTermTableButtonCell'

export type Props = {
  terms: ReadonlyArray<TermFragment>
  loadingTermId?: string
  onClickEdit: (termId: string) => void
  onClickCopy: (termId: string) => void
  onClickFileExport: (termId: string) => Promise<void>
  onClickDelete: (termId: string) => void
}

export const AdminOkrTermsPageTermTable: FC<Props> = ({
  terms,
  loadingTermId,
  onClickEdit,
  onClickCopy,
  onClickFileExport,
  onClickDelete,
}) => {
  const { t } = useTranslation()

  const dateToString = (date?: Date) => (date ? formatDateInput(date.toString()) : '')

  return (
    <ListCard
      css={{ height: '100%' }}
      emptyMessage={t('X_EMPTY', { x: t('SETTING_OKR_TERM') })}
      borderless
      header={<div />}
      table={{
        headers: [
          {
            title: t('TERM_NAME'),
            css: {
              paddingLeft: '6px',
            },
          },
          {
            title: t('TERM_FROM_START_TO_END'),
            minWidth: 192,
            css: {
              width: '100%',
            },
          },
          {
            title: '',
            minWidth: 304,
          },
        ],
        data: {
          rows: terms.map((term) => ({
            key: term.id,
            cells: [
              {
                // 期間名
                key: 'term-name',
                cell: (
                  <StyledText size="large" isWrap numberOfOmittedLines={1}>
                    {term.name}
                  </StyledText>
                ),
                css: termNameCss,
              },
              {
                // 期間（開始日〜終了日）
                key: 'start-date-end-date',
                cell: (
                  <StyledText size="large">{`${dateToString(term.startDate)} ~ ${dateToString(
                    term.endDate,
                  )}`}</StyledText>
                ),
                minWidth: 192,
                css: termDateCss,
              },
              {
                // コピー、ファイルエクスポート、削除
                key: 'action-button',
                cell: (
                  <AdminOkrTermsPageTermTableButtonCell
                    isCopyButtonLoading={loadingTermId === term.id}
                    onClickEdit={() => {
                      onClickEdit(term.id)
                    }}
                    onClickCopy={() => {
                      if (loadingTermId === term.id) return
                      onClickCopy(term.id)
                    }}
                    onClickFileExport={async () => {
                      await onClickFileExport(term.id)
                    }}
                    onClickDelete={() => {
                      onClickDelete(term.id)
                    }}
                  />
                ),
                minWidth: 304,
              },
            ],
          })),
        },
      }}
    />
  )
}

AdminOkrTermsPageTermTable.displayName = 'AdminOkrTermsPageTermTable'

const termNameCss: CSSObject = {
  paddingLeft: '6px',
}

const termDateCss: CSSObject = {
  width: '100%',
}
