import { RenderLeafProps } from 'slate-react'

// bold marks type
export enum MarksType {
  BOLD = 'bold',
  HIGHLIGHT = 'highlight',
  UNDERLINE = 'underline',
  ITALIC = 'italic',
  STRIKE_THROUGH = 'strikethrough',
  CODE = 'code',
}

// Text node
export type MarksNode = Partial<Record<MarksType, boolean>> & {
  type?: never
  text: string
  // NOTE: slate-yjs で使う
  // isCaret?: boolean
}

// renderLeaf props
export interface MarksRenderLeafProps extends RenderLeafProps {
  leaf: MarksNode
}
