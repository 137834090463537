import { Group } from 'grommet-icons'
import { Fragment, useEffect, useState } from 'react'

import { useTranslation } from '../../../i18n'
import { getUserSearchText } from '../../../lib/domain/user/searchText'
import { InterpolationWithTheme } from '../../../lib/emotion'
import { Avatar } from '../../ui/Avatar'
import { MultiSelect, Option } from '../../ui/MultiSelect'

import { GroupFragment, UserFragment } from './graphql'

// 選択されている有効なユーザ + 選択されている無効なユーザ + 選択可能なユーザ - 重複しているユーザー
export const getCandidateContributors = (
  members: ReadonlyArray<UserFragment>,
  candidateMembers: ReadonlyArray<UserFragment>,
  ownerId: string,
): Array<UserFragment> =>
  Array.from(
    new Map(
      [
        ...members.filter((m) => !m.isDisabled),
        ...members.filter((m) => m.isDisabled),
        ...candidateMembers.filter((u) => u.id !== ownerId),
      ].map((user) => [user.id, user]),
    ).values(),
  )

export const isCandidateGroup = (
  group: GroupFragment,
  ownerId: string,
  selectedMembers: ReadonlyArray<UserFragment>,
): boolean => {
  // メンバー全員がオーナーか無効の場合省く
  if (group.members.every(({ id, isDisabled }) => id === ownerId || isDisabled)) return false
  // 選択されていないメンバーがKRのオーナーか無効ユーザーのみのグループは省く
  const notIncludedMembersInOptionGroup = group.members.filter(
    (gm) => !selectedMembers.some((m) => gm.id === m.id),
  )
  return !notIncludedMembersInOptionGroup.every((m) => m.id === ownerId || m.isDisabled)
}

export type ContributorProps = {
  users: ReadonlyArray<UserFragment>
  groups: ReadonlyArray<GroupFragment>
  selectedUserIds: ReadonlyArray<string>
  listCss?: InterpolationWithTheme
  disabledMask?: boolean
  onClose: () => void
  onClickUserOption: (next: Array<string>) => void
}

export const Contributor: React.FC<ContributorProps> = ({
  onClose,
  users,
  groups,
  listCss,
  disabledMask = false,
  selectedUserIds,
  onClickUserOption,
}) => {
  const { t } = useTranslation()
  const [optionList, setOptionList] = useState<ReadonlyArray<Option>>([])

  useEffect(() => {
    const userList = users.map<Option>(
      ({ id, firstName, lastName, email, avatar, isDisabled }) => ({
        id,
        name: t('FULL_NAME', { firstName, lastName }),
        searchText: getUserSearchText({ firstName, lastName, email }),
        selected: selectedUserIds.some((value) => value === id),
        isDisabled,
        icon: (
          <Avatar
            tooltipDisabled
            firstName={firstName}
            lastName={lastName}
            avatarUrl={avatar?.url}
            size="xsmall"
            css={{ marginRight: '8px' }}
            isUserDisabled={isDisabled}
          />
        ),
      }),
    )

    const groupList = groups
      .map<Option>(({ id, name }) => ({
        id,
        name,
        searchText: name,
        selected: selectedUserIds.some((value) => value === id),
        // Groupは無効化表示をしないため明示的にfalseを渡す
        isDisabled: false,
        icon: <Group css={{ marginRight: '8px' }} size="14px" />,
      }))
      .sort((a, b) => (b.selected ? 1 : -1) - (a.selected ? 1 : -1))

    setOptionList(userList.concat(groupList))
  }, [selectedUserIds, setOptionList, t, users, groups])

  return (
    <Fragment>
      {!disabledMask && (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <div
          css={{
            position: 'fixed',
            width: '100vw',
            height: '100vh',
            top: 0,
            left: 0,
            backgroundColor: 'transparent',
            zIndex: 1,
          }}
          onClick={onClose}
        />
      )}
      <div css={[{ position: 'absolute', zIndex: 2 }, listCss]}>
        <MultiSelect
          canSearch
          options={optionList}
          searchPlaceholder={t('INPUT_X', { x: t('USER_NAME') })}
          onClickOption={async (id) => {
            const group = groups.find((g) => g.id === id)
            let next: Array<string> = []
            if (group) {
              const targetMembers = group.members
                .filter((m) => !selectedUserIds.includes(m.id) && !m.isDisabled)
                .map((m) => m.id)
              next = selectedUserIds.concat(...targetMembers)
            } else if (selectedUserIds.indexOf(id) >= 0) {
              next = selectedUserIds.filter((c) => c !== id)
            } else {
              next = selectedUserIds.concat(id)
            }
            onClickUserOption(next)
          }}
        />
      </div>
    </Fragment>
  )
}

Contributor.displayName = 'Contributor'
