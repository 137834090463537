import { Transforms, Node, Range, Path, Editor } from 'slate'

// Get x-slate-fragment attribute from data-slate-fragment
const CATCH_SLATE_FRAGMENT = /data-slate-fragment="(.+?)"/m
export const getSlateFragmentAttribute = (dataTransfer: DataTransfer): string | undefined => {
  const htmlData = dataTransfer.getData('text/html')
  const [, fragment] = htmlData.match(CATCH_SLATE_FRAGMENT) || []
  return fragment
}

/**
 * ※必ず最外側に配置すること
 */
export const withMonkeyPatch = (editor: Editor): Editor => {
  const { deleteBackward, insertData } = editor

  // NOTE: 編集モードでコピペした際は真っ先にinsertFragmentに処理を移譲しないとtext/html等のdeserializerが働いてしまう
  editor.insertData = (data) => {
    const fragment = data.getData('application/x-slate-fragment') || getSlateFragmentAttribute(data)
    if (fragment) {
      editor.insertFragment(JSON.parse(decodeURIComponent(window.atob(fragment))), true)
      return
    }

    insertData(data)
  }

  // NOTE: voidエレメント直下のNodeを削除する際にバグを抱えている
  //       この拡張を消すと、voidエレメント直下のParagraphを削除する際
  //       直上のvoidエレメントごと消えてしまうための回避策
  // See: https://github.com/ianstormtaylor/slate/issues/3991
  editor.deleteBackward = (unit) => {
    if (
      !editor.selection ||
      !Range.isCollapsed(editor.selection) ||
      editor.selection.anchor.offset !== 0
    ) {
      return deleteBackward(unit)
    }

    try {
      const parentPath = Path.parent(editor.selection.anchor.path)
      const parentNode = Node.get(editor, parentPath)
      const parentIsEmpty = Node.string(parentNode).length === 0
      const parentPathIsEmpty = Path.previous(parentPath).length > 0

      if (parentIsEmpty && parentPathIsEmpty) {
        const prevNodePath = Path.previous(parentPath)
        const prevNode = Node.get(editor, prevNodePath)
        if (Editor.isVoid(editor, prevNode)) {
          return Transforms.removeNodes(editor)
        }
      }
    } catch (e) {
      // nothing to do
    }

    return deleteBackward(unit)
  }

  return editor
}
