import { BoxTypes, Box, Text } from 'grommet'
import { useState, useEffect } from 'react'

import { NewCircleMeter } from '../../../../components/ui/NewCircleMeter'

export const calculateProgressRate = (
  resources: ReadonlyArray<{ progressRate?: number | null }>,
): number | undefined => {
  if (resources.length === 0) {
    return undefined
  }
  return (
    resources.reduce((rate, r) => (r.progressRate == null ? rate : rate + r.progressRate), 0) /
    resources.length
  )
}

export type ProgressRateProps = BoxTypes & {
  mainColor: string
  resources: ReadonlyArray<{ progressRate?: number | null }>
}

export const ProgressRate: React.FC<ProgressRateProps> = ({ mainColor, resources, ...props }) => {
  const [progressRate, setProgressRate] = useState<number | undefined>(undefined)

  useEffect(() => {
    setProgressRate(calculateProgressRate(resources))
  }, [resources])

  return (
    <Box {...props} direction="row" justify="center" align="center">
      <Text
        css={{
          fontSize: 14,
          lineHeight: 1.35,
          marginRight: 16,
        }}
      >
        {resources.length}
      </Text>
      <NewCircleMeter
        meterColor={mainColor}
        ratio={progressRate == null ? undefined : progressRate / 100}
      />
    </Box>
  )
}

ProgressRate.displayName = 'ProgressRate'
