import { VFC } from 'react'

import { useTranslation } from '../../../i18n'
import { color } from '../../../styles/newColors'
import { Button } from '../../ui/Button'
import { Icon } from '../../ui/Icon'
import { Link } from '../../ui/Link'
import { StyledText } from '../../ui/StyledText'

export type Props = {
  href: string
  title: string | JSX.Element
  message: string | JSX.Element
  onClose: () => void
  onClick: () => void
}

export const CheckinAlert: VFC<Props> = ({ href, title, message, onClose, onClick }) => {
  const { t } = useTranslation()

  return (
    <div
      css={{
        position: 'fixed',
        bottom: '44px',
        left: '50%',
        whiteSpace: 'nowrap',
        transform: 'translateX(-50%)',
        justifyContent: 'center',
        zIndex: 10,
      }}
    >
      <div
        css={{
          boxShadow: '0px 16px 32px rgb(34 41 67 / 16%)',
          borderRadius: '4px',
          display: 'inline-block',
          backgroundColor: color('white-100'),
        }}
      >
        <div
          css={{
            height: '64px',
            display: 'flex',
            justifyContent: 'center',
            margin: '0 10px',
          }}
        >
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Icon type="rocket" css={{ width: '40px', height: '40px' }} />
            <div
              css={{
                display: 'flex',
                flexDirection: 'column',
                marginLeft: '12px',
              }}
            >
              <StyledText weight="bold" size="medium">
                {title}
              </StyledText>
              <StyledText size="small" color="text-bk-50">
                {message}
              </StyledText>
            </div>
            <Link method="newTab" href={href} onClickBefore={onClick}>
              <Button
                newColor="resily-orange-100"
                weight="normal"
                size="ss"
                css={{ padding: '0 10px', marginLeft: '20px' }}
              >
                {t('CHECKIN')}
              </Button>
            </Link>

            <button
              type="button"
              css={{
                '&:focus': {
                  outline: 'none',
                },
                cursor: 'pointer',
                marginLeft: '35px',
              }}
              onClick={onClose}
            >
              <Icon
                type="clear"
                css={{
                  ':hover': { color: color('text-bk-100') },
                  color: color('border-bk-30'),
                  width: '20px',
                  height: '20px',
                }}
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

CheckinAlert.displayName = 'CheckinAlert'
