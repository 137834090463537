import { Box, Button, Collapsible } from 'grommet'
import { FormDown, FormUp } from 'grommet-icons'
import React, { ReactNode, useState, MouseEvent, useCallback, createRef } from 'react'
import { v1 as uuid } from 'uuid'

import {
  ObjectiveAvatar,
  KeyResultOwner,
  KeyResultContributors,
} from '../../../../components/domain/AvatarWithContributor/index'
import { ProgressRateTooltip } from '../../../../components/domain/ProgressRateTooltip'
import { Icon } from '../../../../components/ui/Icon'
import { Link } from '../../../../components/ui/Link'
import { StyledText } from '../../../../components/ui/StyledText/index'
import { useTranslation } from '../../../../i18n'
import { barColorConfidence } from '../../../../lib/confidence'
import { keyResultToConfidenceLevel } from '../../../../lib/domain/confidence'
import { color } from '../../../../styles/newColors'
import {
  DRAWER_QUERY_ID,
  DRAWER_QUERY_TAB,
  DRAWER_QUERY_TYPE,
  generateTrees,
} from '../../../../urls'
import { NewProgressRate } from '../../NewProgressRate'
import { FloatDrawer } from '../FloatDrawer'
import { OkrInfo } from '../FloatDrawer/Content/OkrInfo'
import { UpdateKeyResultContent } from '../FloatDrawer/Content/UpdateKeyResultContent'
import { UpdateObjectiveContent } from '../FloatDrawer/Content/UpdateObjectiveContent'
import { OkrNodeFragment } from '../graphql'

const FLOAT_DRAWER_DEFAULT_WIDTH = 380
const FLOAT_DRAWER_DEFAULT_HEIGHT = 420

const FLOAT_DRAWER_MIN_WIDTH = 240
const FLOAT_DRAWER_MIN_HEIGHT = 270

export type Props = {
  userId: string
  termId: string
  okrNode: OkrNodeFragment
  onClickOkr: (e: MouseEvent<HTMLElement, MouseEvent>, okrNodeId: string) => void
}

type FloatDrawerList = ReadonlyArray<{ uniqId: string; reactNode: ReactNode }>

export const AssignedOkrList: React.FC<Props> = ({ ...props }) => {
  const [floatDrawerList, setFloatDrawerList] = useState<FloatDrawerList>([])

  const onClickOkrInfo = useCallback(
    (uniqId: string, floatDrawer: ReactNode) =>
      setFloatDrawerList((prevState) =>
        prevState.some((p) => p.uniqId === uniqId)
          ? prevState
          : prevState.concat({ uniqId, reactNode: floatDrawer }),
      ),
    [],
  )

  const onClickCloseFloatDrawer = useCallback((uniqId: string) => {
    setFloatDrawerList((prevState) => prevState.filter((prev) => prev.uniqId !== uniqId))
  }, [])

  return (
    <>
      <AssignedOkrListContent
        {...props}
        onClickOkrInfo={onClickOkrInfo}
        onClickCloseFloatDrawer={onClickCloseFloatDrawer}
      />
      {floatDrawerList.map((floatDrawer) => floatDrawer.reactNode)}
    </>
  )
}

AssignedOkrList.displayName = 'AssignedOkrList'

const AssignedOkrListContent: React.FC<
  Props & {
    onClickOkrInfo: (id: string, floatDrawer: ReactNode) => void
    onClickCloseFloatDrawer: (uniqId: string) => void
  }
> = ({ onClickCloseFloatDrawer, userId, termId, okrNode, onClickOkr, onClickOkrInfo }) => {
  const { t } = useTranslation()
  const okrListRef = createRef<HTMLDivElement>()

  const [open, setOpen] = useState<boolean>(false)

  const okrEditFloatTitle = t('EDIT_OKR')

  const ExpandButton = ({ ...rest }) => (
    <div {...rest}>
      {open ? (
        <FormUp
          css={{
            width: '16px',
            height: '16px',
          }}
        />
      ) : (
        <FormDown
          css={{
            width: '16px',
            height: '16px',
          }}
        />
      )}
    </div>
  )

  return (
    <Box
      ref={okrListRef}
      css={{
        border: '1px solid #E9EAEC',
        boxSizing: 'border-box',
        borderRadius: '8px',
        padding: '20px 24px 6px',
        outline: 'none',
        boxShadow: 'none',
        backgroundColor: open ? '#F8F9F9' : undefined,
      }}
      onClick={(e: MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setOpen(!open)
        onClickOkr(e, okrNode.id)
      }}
    >
      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            fontSize: '12px',
            borderLeft: `2px solid ${color('objective-blue-100')}`,
            paddingLeft: 12,
            marginBottom: '10px',
            marginRight: '24px',
          }}
        >
          <StyledText
            size="small"
            css={{
              color: color('text-bk-50'),
              marginBottom: '8px',
              lineHeight: '18px',
              textAlign: 'start',
            }}
          >
            {`${t('PARENT_OKR')}：
              ${okrNode.parentNode ? okrNode.parentNode?.objective.name : t('NONE')}`}
          </StyledText>
          <StyledText
            css={{
              fontWeight: 'bold',
              borderLeft: `2px solid ${color('objective-blue-100')}`,
              paddingLeft: 12,
              lineHeight: '20px',
              display: 'inline-block',
            }}
          >
            {okrNode.objective.name}
          </StyledText>
        </div>
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div
            css={{
              position: 'relative',
              width: '84px',
              marginRight: '8px',
            }}
          >
            <NewProgressRate
              color={color('objective-blue-100')}
              rate={okrNode.objective.progressRate ?? 0}
            />
          </div>
          <div
            css={{
              marginRight: '8px',
            }}
          >
            <ObjectiveAvatar
              termId={termId}
              owner={okrNode.objective.owner}
              size="one-on-one-drawer-okr-tab"
              usersStyle={{
                userIds: [userId],
                css: {
                  border: '2px solid #DC440A',
                  height: '20px',
                  width: '20px',
                },
              }}
            />
          </div>
          <div
            css={{
              display: 'flex',
            }}
          >
            <Button
              css={{
                marginRight: '8px',
              }}
              onClick={(e: MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation()
                const id = `okr-edit-${okrNode.objective.id}`
                onClickOkrInfo(
                  id,
                  <FloatDrawer
                    uniqId={id}
                    default={{
                      x: e.clientX - FLOAT_DRAWER_DEFAULT_WIDTH,
                      y: 18 + e.clientY,
                      width: FLOAT_DRAWER_DEFAULT_WIDTH,
                      height: FLOAT_DRAWER_DEFAULT_HEIGHT,
                    }}
                    title={okrEditFloatTitle}
                    onClickClose={onClickCloseFloatDrawer}
                  >
                    <UpdateObjectiveContent objective={okrNode.objective} />
                  </FloatDrawer>,
                )
              }}
            >
              <Icon
                type="editCircle"
                css={{
                  width: '24px',
                  height: '24px',
                }}
              />
            </Button>

            <Button
              onClick={(e: MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation()
                const uniqId = uuid()
                onClickOkrInfo(
                  uniqId,
                  <FloatDrawer
                    key={uniqId}
                    title={
                      <StyledText
                        css={{
                          borderLeft: `2px solid ${color('objective-blue-100')}`,
                          paddingLeft: 12,
                        }}
                      >
                        {okrNode.objective.name}
                      </StyledText>
                    }
                    uniqId={uniqId}
                    bounds="window"
                    default={{
                      x: e.clientX - FLOAT_DRAWER_DEFAULT_WIDTH,
                      y: 18 + e.clientY,
                      width: FLOAT_DRAWER_DEFAULT_WIDTH,
                      height: FLOAT_DRAWER_DEFAULT_HEIGHT,
                    }}
                    minWidth={FLOAT_DRAWER_MIN_WIDTH}
                    minHeight={FLOAT_DRAWER_MIN_HEIGHT}
                    onClickClose={onClickCloseFloatDrawer}
                  >
                    <OkrInfo uniqId={uniqId} objective={okrNode.objective} />
                  </FloatDrawer>,
                )
              }}
              css={{
                marginRight: '8px',
              }}
            >
              <Icon
                type="infoCircle"
                css={{
                  width: '24px',
                  height: '24px',
                }}
              />
            </Button>

            <Button
              css={{
                marginRight: '10px',
              }}
              onClick={(e: MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation()
              }}
            >
              <Link
                method="newTab"
                href={generateTrees({
                  termId,
                  nodeId: okrNode.id,
                  userIds: [userId],
                  [DRAWER_QUERY_TYPE]: 'objective',
                  [DRAWER_QUERY_TAB]: 'info',
                  [DRAWER_QUERY_ID]: okrNode.objective.id,
                })}
                css={{
                  marginRight: '10px',
                }}
                onClick={(e) => {
                  e.stopPropagation()
                }}
              >
                <Icon
                  type="mapCircle"
                  css={{
                    width: '24px',
                    height: '24px',
                  }}
                />
              </Link>
            </Button>
          </div>
          <Button
            style={okrNode.keyResults.length === 0 ? { visibility: 'hidden' } : undefined}
            onClick={(e: MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation()
              setOpen(!open)
            }}
          >
            <ExpandButton
              css={{
                padding: 0,
                verticalAlign: 'text-bottom',
                marginLeft: '4px',
              }}
            />
          </Button>
        </div>
      </div>

      {okrNode.keyResults.map((keyResult) => (
        <Collapsible key={keyResult.id} open={open}>
          <div
            css={{
              marginBottom: '12px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div
              css={{
                paddingLeft: '14px',
                width: '332px',
                marginRight: '24px',
              }}
            >
              <StyledText
                css={{
                  borderLeft: `2px solid ${color('kr-green-100')}`,
                  paddingLeft: 12,
                  lineHeight: '20px',
                  display: 'inline-block',
                }}
              >
                {keyResult.name}
              </StyledText>
            </div>

            <div
              css={{
                display: 'flex',
                alignItems: 'center',
                marginRight: '20px',
              }}
            >
              <div
                css={{
                  position: 'relative',
                  width: '84px',
                  marginRight: '8px',
                }}
              >
                <ProgressRateTooltip keyResult={keyResult}>
                  <NewProgressRate
                    color={color(barColorConfidence(keyResultToConfidenceLevel(keyResult)))}
                    rate={keyResult.progressRate ?? 0}
                  />
                </ProgressRateTooltip>
              </div>
              <div
                css={{
                  marginRight: '8px',
                }}
              >
                <KeyResultOwner
                  size="one-on-one-drawer-okr-tab"
                  termId={termId}
                  owner={keyResult.owner}
                  keyResult={keyResult}
                  usersStyle={{
                    userIds: [userId],
                    css: {
                      border: '2px solid #DC440A',
                      height: '20px',
                      width: '20px',
                    },
                  }}
                />
              </div>
              <div
                css={{
                  marginRight: '8px',
                }}
              >
                <KeyResultContributors
                  size="one-on-one-drawer-okr-tab"
                  termId={termId}
                  displayTotalContributors={3}
                  contributors={keyResult.members}
                  keyResult={keyResult}
                  usersStyle={{
                    userIds: [userId],
                    css: {
                      border: '2px solid #DC440A',
                      height: '20px',
                      width: '20px',
                    },
                  }}
                />
              </div>
              <div
                css={{
                  display: 'flex',
                }}
              >
                <Button
                  css={{
                    marginRight: '8px',
                  }}
                  onClick={(e: MouseEvent<HTMLButtonElement>) => {
                    e.stopPropagation()
                    const id = `okr-edit-${keyResult.id}`
                    onClickOkrInfo(
                      id,
                      <FloatDrawer
                        uniqId={id}
                        default={{
                          x: e.clientX - FLOAT_DRAWER_DEFAULT_WIDTH,
                          y: 18 + e.clientY,
                          width: FLOAT_DRAWER_DEFAULT_WIDTH,
                          height: FLOAT_DRAWER_DEFAULT_HEIGHT,
                        }}
                        title={okrEditFloatTitle}
                        onClickClose={onClickCloseFloatDrawer}
                      >
                        <UpdateKeyResultContent keyResult={keyResult} />
                      </FloatDrawer>,
                    )
                  }}
                >
                  <Icon
                    type="editCircle"
                    css={{
                      width: '24px',
                      height: '24px',
                    }}
                  />
                </Button>
                <Button
                  css={{
                    marginRight: '20px',
                  }}
                  onClick={(e: MouseEvent<HTMLButtonElement>) => {
                    e.stopPropagation()
                    const uniqId = uuid()
                    onClickOkrInfo(
                      uniqId,
                      <FloatDrawer
                        key={uniqId}
                        title={
                          <StyledText
                            css={{
                              borderLeft: `2px solid ${color('objective-blue-100')}`,
                              paddingLeft: 12,
                            }}
                          >
                            {keyResult.name}
                          </StyledText>
                        }
                        uniqId={uniqId}
                        bounds="window"
                        default={{
                          x: e.clientX - FLOAT_DRAWER_DEFAULT_WIDTH,
                          y: 18 + e.clientY,
                          width: FLOAT_DRAWER_DEFAULT_WIDTH,
                          height: FLOAT_DRAWER_DEFAULT_HEIGHT,
                        }}
                        minWidth={FLOAT_DRAWER_MIN_WIDTH}
                        minHeight={FLOAT_DRAWER_MIN_HEIGHT}
                        onClickClose={onClickCloseFloatDrawer}
                      >
                        <OkrInfo uniqId={uniqId} keyResult={keyResult} />
                      </FloatDrawer>,
                    )
                  }}
                >
                  <Icon
                    type="infoCircle"
                    css={{
                      width: '24px',
                      height: '24px',
                    }}
                  />
                </Button>
              </div>
            </div>
          </div>
        </Collapsible>
      ))}
    </Box>
  )
}

AssignedOkrListContent.displayName = 'AssignedOkrListContent'
