import { css } from '@emotion/react'
import { useMemo } from 'react'

import { color } from '../../../../styles/newColors'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useStyles = () =>
  useMemo(
    () =>
      ({
        root: css({
          flex: '0 0 472px',
          maxWidth: '472px',
          background: color('white-100'),
        }),
      } as const),
    [],
  )
