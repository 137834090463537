import { PropsWithoutRef } from 'react'

import * as colors from '../../../styles/colors'

export type Props = PropsWithoutRef<JSX.IntrinsicElements['span']> & {
  backgroundColor?: string
  fontSize?: number
  lineHeight?: number
}

export const NoteLabel: React.FC<Props> = ({
  backgroundColor = colors.border,
  fontSize = 14,
  lineHeight = 20,
  children,
  ...props
}) => (
  <span>
    <span
      css={{
        margin: 2,
        display: 'inline-flex',
        backgroundColor,
        padding: '4px 8px',
        borderRadius: 3,
        alignItems: 'center',
        wordBreak: 'break-all',
        color: 'white',
        lineHeight: `${lineHeight}px`,
        fontSize: `${fontSize}px`,
        fontWeight: 'bold',
        boxShadow: 'inset 0 -1px 0 rgb(27 31 35 / 12%)',
      }}
      {...props}
    >
      {children}
    </span>
  </span>
)

NoteLabel.displayName = 'NoteLabel'
