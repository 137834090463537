import { useState, useCallback, useEffect, useMemo } from 'react'

import { useTranslation } from '../../../../i18n'
import { not } from '../../../../lib/union'
import { UserFragment } from '../../graphql'
import { SetQueryParamsType, Sort, SortObject } from '../types'

const orderList: Readonly<['asc', 'desc']> = ['asc', 'desc']

export const useSort = (
  sort: {
    sortBy: Sort['sortBy']
    order: Sort['order']
  },
  users: ReadonlyArray<UserFragment>,
  setQueryParams: SetQueryParamsType,
): {
  sortedUsers: ReadonlyArray<UserFragment>
  handleClickSortBy: (sortBy: Sort['sortBy']) => void
} => {
  const { t } = useTranslation()

  const [sortedUsers, setSortedUsers] = useState<ReadonlyArray<UserFragment>>([])

  const mappedUsers = useMemo(
    () =>
      users.map((user) => {
        const fullName = t('FULL_NAME', { firstName: user.firstName, lastName: user.lastName })
        const currentSignInAtTime = user.currentSignInAt
          ? new Date(user.currentSignInAt).getTime() // 日時がISOStringでDate型にされているため、Date型に変換
          : 0
        const lastUpdatedAtTime = user.lastUpdatedAt
          ? new Date(user.lastUpdatedAt).getTime() // 日時がISOStringでDate型にされているため、Date型に変換
          : 0

        return {
          ...user,
          fullName,
          currentSignInAtTime,
          lastUpdatedAtTime,
        }
      }),

    [t, users],
  )

  const sorting = useMemo(
    () =>
      mappedUsers.sort((a, b) => {
        const s = sort.order === 'asc' ? 1 : -1

        switch (sort.sortBy) {
          case SortObject.objective:
            return s * (a.ownerObjectives.length - b.ownerObjectives.length)
          case SortObject.keyResult:
            return s * (a.ownerKeyResults.length - b.ownerKeyResults.length)
          case SortObject.contributor:
            return s * (a.memberKeyResults.length - b.memberKeyResults.length)
          case SortObject.lastLoggedIn:
            return s * (a.currentSignInAtTime - b.currentSignInAtTime)
          default:
            if (sort.order === 'desc' ? a.fullName > b.fullName : a.fullName < b.fullName) {
              return -1
            }
            return 1
        }
      }),
    [mappedUsers, sort.order, sort.sortBy],
  )

  useEffect(() => {
    setSortedUsers(sorting)
  }, [sorting])

  const handleClickSortBy = useCallback(
    (sortBy: Sort['sortBy']) => {
      const newOrder = sort.order && sort.sortBy === sortBy ? not(orderList)(sort.order) : 'desc'

      setQueryParams({
        sort: sortBy,
        order: newOrder,
      })
    },
    [setQueryParams, sort.order, sort.sortBy],
  )

  return { sortedUsers, handleClickSortBy }
}
