import { useStyles } from './RightSection.styles'

export type Props = {
  showRightSection: boolean
}

export const RightSection: React.FC<Props> = ({ showRightSection, children }) => {
  const styles = useStyles({ showRightSection })

  return <div css={styles.root}>{children}</div>
}
RightSection.displayName = 'RightSection'
