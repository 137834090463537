import { object, string, SchemaOf, ObjectSchema } from 'yup'

import { OkrGlossary } from '../../graphql'
import { TFunction } from '../../i18n'
import { bytes } from '../string'

// NOTE: 用語ごとに最大文字数を設定
//       半角文字を1バイト、全角文字を2バイトとしてカウントする
// See: https://github.com/Resily/resily-new/issues/8776#issuecomment-1031106116
const maxByte: { [key in OkrGlossary]: number } = {
  [OkrGlossary.Objective]: 16,
  [OkrGlossary.KeyResult]: 16,
  [OkrGlossary.Kr]: 20,
  [OkrGlossary.Okr]: 8,
  [OkrGlossary.OneOnOne]: 12,
  [OkrGlossary.OkrMeeting]: 20,
  [OkrGlossary.WinSessions]: 24,
  [OkrGlossary.PriorityItem]: 24,
  [OkrGlossary.ChallengesAndObstacles]: 24,
  [OkrGlossary.Other]: 24,
  [OkrGlossary.ActionPlan]: 20,
  [OkrGlossary.Confidence]: 16,
  [OkrGlossary.Contributors]: 18,
  [OkrGlossary.Assignee]: 16,
  [OkrGlossary.CheckIn]: 16,
}

type BaseSchema = {
  okrGlossary?: string
  customTextJa: string
  customTextEn: string
}

const okrGlossarySchema = (t: TFunction, okrGlossary: OkrGlossary): SchemaOf<BaseSchema> =>
  object({
    okrGlossary: string().optional(),
    customTextJa: string()
      .defined()
      .required(t('REQUIRED_ERROR'))
      // 特殊文字を受け付けないようにする
      // eslint-disable-next-line no-control-regex
      .matches(/^(\w|\s|[^\x01-\x7E]|-|\(|\))*$/, t('INVALID_SYMBOL'))
      .test(
        'max',
        () =>
          t('MAX_OKR_GLOSSARY_MESSAGE', {
            fullWidth: maxByte[okrGlossary] / 2,
            halfWidth: maxByte[okrGlossary],
          }),
        (value = '') => bytes(value) <= maxByte[okrGlossary],
      ),
    customTextEn: string()
      .defined()
      .required(t('REQUIRED_ERROR'))
      // 特殊文字を受け付けないようにする
      // eslint-disable-next-line no-control-regex
      .matches(/^(\w|\s|[^\x01-\x7E]|-|\(|\))*$/, t('INVALID_SYMBOL'))
      .test(
        'max',
        () =>
          t('MAX_OKR_GLOSSARY_MESSAGE', {
            fullWidth: maxByte[okrGlossary] / 2,
            halfWidth: maxByte[okrGlossary],
          }),
        (value = '') => bytes(value) <= maxByte[okrGlossary],
      ),
  })

export const okrGlossarySchemas = (
  t: TFunction,
): ObjectSchema<{ [key in OkrGlossary]: SchemaOf<BaseSchema> }> => {
  const schemaObject = Array.from(Object.values(OkrGlossary)).reduce((obj, og) => {
    obj[og] = okrGlossarySchema(t, og)
    return obj
  }, {} as { [key in OkrGlossary]: SchemaOf<BaseSchema> })

  return object(schemaObject)
}
