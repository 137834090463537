import { css } from '@emotion/react'
import { useMemo } from 'react'

import { color } from '../../../../../../styles/newColors'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useStyles = () =>
  useMemo(
    () =>
      ({
        emptyRow: css({
          width: '100%',
          height: '48px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '4px',
          color: color('text-bk-30'),
          borderBottom: `1px solid ${color('border-bk-10')}`,
        }),
      } as const),
    [],
  )
