import { TFunction } from '../../../i18n'

import {
  HasNotSetProgressRateKeyResultFragment,
  IsMeasurementInvalidKeyResultFragment,
  InputMaybe,
  KeyResultUpdateType,
} from './graphql'

// 定性目標（目標値: 100、初期値： 0、単位： %）かどうか
export const isQualitativeGoal = (
  t: TFunction,
  targetValue?: InputMaybe<number>,
  initialValue?: InputMaybe<number>,
  unit?: InputMaybe<string>,
): boolean => targetValue === 100 && !initialValue && unit === t('PERCENTAGE')

// 目標値が未設定かどうか
export const isNotFilledRequiredTargetValue = ({
  updateType,
  targetValue,
  isAutoAggregate,
}: HasNotSetProgressRateKeyResultFragment): boolean =>
  updateType === KeyResultUpdateType.Automate && targetValue == null && isAutoAggregate === false

// KeyResultの進捗が測定不可能な状態になっているかどうか（目標値と初期値が同値かどうか）
export const isMeasurementInvalid = ({
  updateType,
  targetValue,
  initialValue,
}: IsMeasurementInvalidKeyResultFragment): boolean =>
  updateType === KeyResultUpdateType.Automate && targetValue === initialValue
