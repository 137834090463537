import { User } from '../../graphql'
import { TFunction } from '../../i18n'

/**
 * 有効なユーザーリストを返却する
 * @param users ReadonlyArray<U>
 * @returns ReadonlyArray<U>
 */
export const getCandidateUsers = <U extends Pick<User, 'id' | 'isDisabled'>>(
  users: ReadonlyArray<U>,
): Array<U> => users.filter((u) => !u.isDisabled)

/**
 * 責任者の選択リストを返却する
 * @param users ReadonlyArray<U>
 * @param owner U
 * @param contributors? ReadonlyArray<U>
 * @returns ReadonlyArray<U>
 */
export const getOwnerOptions = <U extends Pick<User, 'id' | 'isDisabled'>>(
  users: ReadonlyArray<U>,
  owner: U,
  contributors?: ReadonlyArray<U>,
): Array<U> => {
  const result: Array<U> = []
  // 選択されている責任者（有効無効問わず）
  result.push(owner)
  // 有効なユーザーリストからコントリビュータに選択されているユーザーを除外して返却
  if (contributors) {
    const cIdList = contributors.map((c) => c.id)
    return result.concat(users.filter((u) => cIdList.findIndex((cid) => cid === u.id) === -1))
  }
  return result.concat(users)
}

/**
 * ユーザーが、入力された氏名またはメールアドレスにマッチするかを評価する
 * @param params
 * @returns bool
 */
export const matchUser = (params: {
  text: string
  user: Pick<User, 'firstName' | 'lastName' | 'email'>
  t: TFunction
}): boolean => {
  const { text, user, t } = params
  const fullName = t('FULL_NAME', { firstName: user.firstName, lastName: user.lastName })
  return fullName.includes(text) || user.email.includes(text)
}
