import { useMemo, useCallback, useEffect, useRef } from 'react'

import { useOkrModal } from '../../../../../components/standalone/OkrModal'
import { Screen } from '../../../../../lib/screen'
import { tracker } from '../../../../../lib/tracking'

import { Activity } from './Activity'
import { COUNT } from './const'
import { ActivityResourceType, useActivitiesQuery } from './graphql'

export type ActivitiesProps = {
  userId: string
  okrTermId: string
  resourceType: ActivityResourceType
}

export const Activities: React.FC<ActivitiesProps> = ({ userId, okrTermId, resourceType }) => {
  const fetchCount = useRef(COUNT)
  useEffect(() => {
    // MEMO: 最初のデータ取得で10件以上の取得を防ぐためにresourceType変更時は初期化する
    fetchCount.current = COUNT
  }, [resourceType])

  const { data, loading, fetchMore } = useActivitiesQuery({
    variables: {
      resourceType,
      userId,
      okrTermId,
      first: fetchCount.current,
    },
  })
  const activities = useMemo(
    () => data?.findActivitiesByUserId?.edges ?? [],
    [data?.findActivitiesByUserId?.edges],
  )

  const endCursor = useMemo(
    () => data?.findActivitiesByUserId?.pageInfo.endCursor,
    [data?.findActivitiesByUserId?.pageInfo.endCursor],
  )
  const hasNextPage = useMemo(
    () => data?.findActivitiesByUserId?.pageInfo.hasNextPage,
    [data?.findActivitiesByUserId?.pageInfo.hasNextPage],
  )
  const canFetchMore = endCursor != null && hasNextPage

  const onClickMore = useCallback(() => {
    if (!canFetchMore) return
    tracker.UserClickMoreButtonInHome(Screen.HomeActivity)

    fetchMore({
      variables: {
        first: COUNT,
        after: endCursor,
      },
    })
    // MEMO:fetchMore後に取得できたデータ件数を加算したいが、activitiesの件数をuseEffectで管理すると挙動がおかしくなるため、固定値を加算する
    fetchCount.current += COUNT
  }, [canFetchMore, fetchMore, endCursor])

  const { OkrModal, openOkrModalWithKeyResultDrawer } = useOkrModal()
  const openOkrModal = useCallback(
    (objectiveId: string, keyResultId: string) => {
      openOkrModalWithKeyResultDrawer(objectiveId, keyResultId, Screen.HomeActivity)
    },
    [openOkrModalWithKeyResultDrawer],
  )

  return (
    <>
      <Activity
        termId={okrTermId}
        loading={data == null && loading}
        activities={activities}
        onClickKeyResultName={openOkrModal}
        showMore={canFetchMore}
        onClickMore={onClickMore}
      />
      {OkrModal}
    </>
  )
}
