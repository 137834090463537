import { useEffect, useCallback, RefObject } from 'react'

export const OPTION_CLASSNAME = 'MultiSelect__Option'
export const OPTION_FOCUS_CLASSNAME = 'MultiSelect__Option--Focus'

export const useKeyboardCursor = (ref: RefObject<HTMLInputElement>, canSearch: boolean): void => {
  const onKeyUpInSearch = useCallback(
    (e: KeyboardEvent) => {
      if (
        !canSearch ||
        !ref.current ||
        !ref.current.contains(e.target as HTMLElement) ||
        e.code !== 'ArrowDown'
      )
        return

      e.stopImmediatePropagation()
      const optionButton = document.querySelectorAll<HTMLButtonElement>(`.${OPTION_CLASSNAME}`)[0]
      optionButton.focus()
      optionButton.classList.add(OPTION_FOCUS_CLASSNAME)
    },
    [ref, canSearch],
  )

  const onKeyDownInOption = useCallback(
    (e: KeyboardEvent) => {
      if (
        !(e.target as HTMLElement).classList.contains(OPTION_CLASSNAME) ||
        (e.code !== 'ArrowDown' && e.code !== 'ArrowUp')
      ) {
        return
      }
      e.stopImmediatePropagation()

      const el = e.target as HTMLElement
      const next = e.code === 'ArrowDown' ? el.nextElementSibling : el.previousElementSibling
      if (
        !next &&
        e.code === 'ArrowUp' &&
        el === document.querySelectorAll<HTMLButtonElement>(`.${OPTION_CLASSNAME}`)[0]
      ) {
        el.classList.remove(OPTION_FOCUS_CLASSNAME)
        ref?.current?.focus()
        return
      }
      if (!next || !next.classList.contains(OPTION_CLASSNAME)) {
        return
      }

      const nextButton = next as HTMLButtonElement
      el.classList.remove(OPTION_FOCUS_CLASSNAME)
      nextButton.classList.add(OPTION_FOCUS_CLASSNAME)
      nextButton.focus()
    },
    [ref],
  )

  useEffect(() => {
    document.addEventListener('keydown', onKeyDownInOption, true)
    return (): void => document.removeEventListener('keydown', onKeyDownInOption, true)
  }, [onKeyDownInOption])

  useEffect(() => {
    document.addEventListener('keyup', onKeyUpInSearch, true)
    return (): void => document.removeEventListener('keyup', onKeyUpInSearch, true)
  }, [onKeyUpInSearch])
}
