import { useTranslation } from '../../../../../../i18n'
import { Icon } from '../../../../../ui/Icon'
import { StyledText } from '../../../../../ui/StyledText'

import { useStyles } from './KeyResultAreaTableEmptyRow.styles'

export type Props = {
  openKeyResultCreateModal: () => void // TODO: 命名 & 型
}

export const KeyResultAreaTableEmptyRow: React.VFC<Props> = ({ openKeyResultCreateModal }) => {
  const { t } = useTranslation()
  const styles = useStyles()

  return (
    // eslint-disable-next-line jsx-a11y/interactive-supports-focus, jsx-a11y/click-events-have-key-events
    <div role="button" onClick={openKeyResultCreateModal} css={styles.emptyRow}>
      <Icon type="plus" height={12} width={12} />
      <StyledText fontStyle="regular" size="medium">
        {t('ADD_X', { x: t('KEY_RESULT') })}
      </StyledText>
    </div>
  )
}
KeyResultAreaTableEmptyRow.displayName = 'KeyResultAreaTableEmptyRow'
