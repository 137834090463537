import { Anchor, AnchorProps } from 'grommet'
import React, { useCallback, MouseEvent } from 'react'
import { useNavigate } from 'react-router-dom'

export type LinkMethod = 'push' | 'replace' | 'newTab'

export type Props = AnchorProps &
  Omit<JSX.IntrinsicElements['a'], 'color'> & {
    method?: LinkMethod
    onClickBefore?: () => void
  }

export const Link: React.FC<Props> = ({
  method = 'push',
  href,
  onClickBefore = () => {},
  onClick,
  ...props
}) => {
  const navigate = useNavigate()

  const handleClick = useCallback(
    (e: MouseEvent<HTMLAnchorElement>) => {
      onClickBefore()
      switch (method) {
        case 'push':
          e.preventDefault()
          if (onClick) {
            onClick(e)
          }
          if (!href) {
            return
          }
          if (e.ctrlKey || e.metaKey) {
            window.open(href)
            return
          }
          navigate(href)
          break
        case 'newTab':
          e.preventDefault()
          if (onClick) {
            onClick(e)
          }
          if (!href) {
            return
          }
          window.open(href)
          break
        default:
          e.preventDefault()
          if (onClick) {
            onClick(e)
          }
          if (!href) {
            return
          }
          if (e.ctrlKey || e.metaKey) {
            window.open(href)
            return
          }
          navigate(href, { replace: true })
      }
    },
    [navigate, href, method, onClick, onClickBefore],
  )

  return <Anchor href={href} onClick={handleClick} {...props} />
}

Link.displayName = 'Link'
