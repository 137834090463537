import { PropsWithRef, forwardRef } from 'react'

import * as colors from '../../../styles/colors'

type Size = 'm'

type Props = PropsWithRef<JSX.IntrinsicElements['input']> & {
  inputSize?: Size
  isError?: boolean
}

const sizes: { [key in Size]: number } = {
  m: 40,
}

export const Input = forwardRef(
  ({ inputSize = 'm', isError, ...props }: Props, ref: React.Ref<HTMLInputElement>) => (
    <input
      ref={ref}
      css={[
        {
          flexGrow: 1,
          width: '256px',
          height: sizes[inputSize],
          padding: '0 16px',
          lineHeight: `${sizes[inputSize]}px`,
          backgroundColor: colors.baseFill,
          border: `1px solid ${colors.border}`,
          borderRadius: '4px',
          outline: 'none',
          transition: 'border-color 0.1s ease-out',
          '&:hover, &:focus': {
            borderColor: colors.main,
          },
          '&:disabled': {
            color: colors.inactive,
            backgroundColor: colors.background,
            borderColor: colors.inactive,
          },
        },
        isError
          ? {
              borderColor: colors.negative,
            }
          : null,
      ]}
      {...props}
    />
  ),
)

Input.displayName = 'InputDeprecated'
