import { Box, BoxProps } from 'grommet'
import React, { useState } from 'react'

import { useTranslation } from '../../../i18n'
import { Button } from '../Button'
import { Checkbox } from '../Checkbox'
import { Popover, PopoverLink } from '../Popover/deprecated'
import { StyledText } from '../StyledText'

export type Option = {
  key: string
  value: string
  text: string
  hint?: string
  link?: PopoverLink
}

type WithOption = Pick<Option, 'key' | 'value'>

export type Props = BoxProps & {
  exportUrl: string
  exportOptions: Array<Option>
}

export const CsvDownloadLink: React.FC<Props> = ({ exportUrl, exportOptions, ...props }) => {
  const { t } = useTranslation()
  const [withOptions, setWithOptions] = useState<Array<WithOption>>([])

  return (
    <Box gap="20px" {...props}>
      <Box gap="12px">
        {exportOptions.map((exportOption) => (
          <Box key={exportOption.key} direction="row" height={{ max: '14px' }}>
            <Checkbox
              checked={withOptions.some((option) => option.key === exportOption.key)}
              onChange={(e) => {
                const newWithOptions = e.target.checked
                  ? [...withOptions, { key: exportOption.key, value: exportOption.value }]
                  : [...withOptions.filter((option) => option.key !== exportOption.key)]
                setWithOptions(newWithOptions)
              }}
            >
              <div>
                <StyledText color="text-bk-100" lineHeight="14px">
                  {exportOption.text}
                </StyledText>
              </div>
            </Checkbox>
            {!!exportOption.hint && (
              <Popover
                icon="help"
                text={exportOption.hint}
                link={exportOption.link}
                position={{ left: '-145px', top: exportOption.link ? '-5px' : '0' }}
                hasGap={false}
                css={{ height: '12px', marginLeft: '4px' }}
              />
            )}
          </Box>
        ))}
      </Box>
      <Button
        newColor="resily-orange-100"
        weight="normal"
        size="s"
        css={{ width: '116px' }}
        onClick={() => {
          if (!!exportOptions.length && !!withOptions.length) {
            const query = withOptions.map((option) => `${option.key}=${option.value}`).join('&')
            window.location.href = `${exportUrl}?${query}`
            return
          }
          window.location.href = exportUrl
        }}
      >
        {t('DOWNLOAD')}
      </Button>
    </Box>
  )
}

CsvDownloadLink.displayName = 'CsvDownloadLink'
