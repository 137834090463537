import React, { useMemo, useState, useRef, useLayoutEffect } from 'react'

import { groupsToTreeModel } from '../../../lib/domain/group'
import { border } from '../../../styles/border'
import { fontSize } from '../../../styles/font'
import { color } from '../../../styles/newColors'

import { GroupTreeAccordion } from './GroupTreeAccordion'
import { SearchGroupTree } from './SearchGroupTree'
import { GroupFragment } from './graphql'
import { useKeyboardCursor } from './hooks'

export type Props = {
  groups: ReadonlyArray<GroupFragment>
  searchPlaceholder?: string
  selectedIds: ReadonlyArray<string>
  onClickOption: (id: string) => void
}

export const MultiSelectGroupTree: React.FC<Props> = ({
  groups,
  searchPlaceholder,
  selectedIds,
  onClickOption,
}) => {
  const [searchText, setSearchText] = useState<string>('')
  const searchInputRef = useRef<HTMLInputElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)

  useKeyboardCursor(searchInputRef, containerRef)

  useLayoutEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.focus()
    }
  }, [])

  const treeizedGroups = useMemo(() => groupsToTreeModel<GroupFragment>(groups), [groups])

  return (
    <div
      css={{
        backgroundColor: color('white-100'),
        border: border('simple-30'),
        boxShadow: '0px 8px 16px rgb(34 41 67 / 8%)',
        borderRadius: '4px',
        width: '480px',
        height: '365px',
        overflow: 'hidden',
      }}
    >
      <input
        ref={searchInputRef}
        type="text"
        placeholder={searchPlaceholder}
        value={searchText}
        onChange={(event) => setSearchText(event.currentTarget.value)}
        css={[
          fontSize('medium'),
          {
            height: '50px',
            width: '100%',
            color: color('text-bk-100'),
            paddingLeft: '16px',
            paddingRight: '16px',
            marginBottom: '1px',
            boxShadow: '0px 1px 0px #E9EAEC',
            '::placeholder': {
              color: color('text-bk-30'),
            },
            ':focus': {
              backgroundColor: color('background-bk-5'),
              borderRadius: '4px 4px 0 0',
              outline: 'none',
              boxShadow: '0px 1px 0px #DC440A',
            },
          },
        ]}
      />
      <div ref={containerRef} style={{ height: '315px', width: '480px', overflow: 'scroll' }}>
        {searchText === '' ? (
          <GroupTreeAccordion
            groups={treeizedGroups}
            selectedIds={selectedIds}
            onClickOption={onClickOption}
          />
        ) : (
          <SearchGroupTree
            groups={treeizedGroups}
            selectedIds={selectedIds}
            searchText={searchText}
            onClickOption={onClickOption}
          />
        )}
      </div>
    </div>
  )
}

MultiSelectGroupTree.displayName = 'MultiSelectGroupTree'
