import { css } from '@emotion/react'
import React from 'react'

import { Icon } from '../../../components/ui/Icon'
import { TooltipNew, TooltipNewProps } from '../../../components/ui/TooltipNew'
import { useTranslation } from '../../../i18n'
import { color } from '../../../styles/newColors'

const buttonCss = css({
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: 'fit-content',
  minWidth: '32px',
  maxWidth: '59px',
  height: '38px',
  padding: '4px',
  borderRadius: '4px',
  ':hover': {
    backgroundColor: color('white-100'),
    outline: `1px solid ${color('border-bk-30')}`,
  },
})

const nonChangeCss = css({
  margin: '6px 8px',
  width: '8px',
  height: '2px',
  transform: 'scaleY(0.75)', // height: 1.5px だとデザインが実現できないのでtransformで変形している
  backgroundColor: color('text-bk-50'),
})

const fontCss = css({
  fontSize: '12px',
  fontWeight: 'bold',
  lineHeight: '12px',
})

const upNumCss = css(fontCss, {
  color: color('tag-darkgreen'),
  '&::before': { content: '"↑"' },
})

const downNumCss = css(fontCss, {
  color: color('tag-red'),
  '&::before': { content: '"↓"' },
})

const confidencesCss = css({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
})

export type Props = Omit<TooltipNewProps, 'title'> & {
  progressRateTrend: number
  upConfidenceScoreNum: number
  downConfidenceScoreNum: number
  onClick: NonNullable<JSX.IntrinsicElements['button']['onClick']>
}

export const TrendButton: React.VFC<Props> = ({
  progressRateTrend,
  upConfidenceScoreNum,
  downConfidenceScoreNum,
  onClick,
  ...props
}) => {
  const { t } = useTranslation()

  const progressRateTrendAbs = Math.abs(progressRateTrend)
  const upConfidenceScoreNumAbs = Math.abs(upConfidenceScoreNum)
  const downConfidenceScoreNumAbs = Math.abs(downConfidenceScoreNum)

  return (
    <TooltipNew
      title={t('LOOK_AT_X', { x: t('JOIN', { a: t('UPDATION'), b: t('HISTORY') }) })}
      {...props}
    >
      <button type="button" css={buttonCss} onClick={onClick}>
        {/* eslint-disable-next-line no-nested-ternary */}
        {progressRateTrendAbs > 0 ? (
          Math.sign(progressRateTrend) > 0 ? (
            <span css={upNumCss}>{Math.min(progressRateTrendAbs, 999)}pt</span>
          ) : (
            <span css={downNumCss}>{Math.min(progressRateTrendAbs, 999)}pt</span>
          )
        ) : (
          <div css={nonChangeCss} />
        )}
        {upConfidenceScoreNumAbs > 0 || downConfidenceScoreNumAbs > 0 ? (
          <div css={confidencesCss}>
            <Icon type="confidence" width={12} height={12} />
            {upConfidenceScoreNumAbs > 0 ? (
              <span css={upNumCss}>{Math.min(upConfidenceScoreNumAbs, 9)}</span>
            ) : null}
            {downConfidenceScoreNumAbs > 0 ? (
              <span css={downNumCss}>{Math.min(downConfidenceScoreNumAbs, 9)}</span>
            ) : null}
          </div>
        ) : (
          <div css={nonChangeCss} />
        )}
      </button>
    </TooltipNew>
  )
}

TrendButton.displayName = 'TrendButton'
