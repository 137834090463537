import { includes } from './string'

export const some = <T>(a: ReadonlyArray<T>, b: ReadonlyArray<T>): boolean => {
  const s = new Set(b)
  return a.some((e) => s.has(e))
}

export const toggle = <T>(ids: ReadonlyArray<T>, id: T): Array<T> =>
  ids.includes(id) ? ids.filter((idsId) => idsId !== id) : ids.concat([id])

/**
 * テキスト検索
 * @param {string} search 検索するテキスト(スペース区切りで複数指定可能)
 * @param {ReadonlyArray<T>} elements 検索対象のリスト
 * @param {(el: T) => Array<string>} extractValue 検索文字列を返す関数
 * @return {Array<T>} テキスト検索結果
 */
export const filterString = <T>(
  search: string,
  elements: ReadonlyArray<T>,
  extractValue: (el: T) => Array<string>,
): Array<T> =>
  elements.filter((el) =>
    extractValue(el).some((v) =>
      search
        .toLowerCase()
        .split(/\s+/)
        .filter((s) => s !== '')
        .every((s) => includes(v, s)),
    ),
  )

/**
 * 非破壊的に要素の並び替え
 *
 * @param {ReadonlyArray<T>}  elements 並べ替える配列
 * @param {number} from  並べ替える要素のindex番号
 * @param {number} to    並べ替え先のindex番号
 * @return {ReadonlyArray<T> | null} 並べ替え後の配列 | null（fromが適切ではない場合）
 */
export const moveItemIndex = <T>(
  elements: ReadonlyArray<T>,
  from: number,
  to: number,
): ReadonlyArray<T> | null => {
  if (elements.length - 1 < from) {
    return null
  }

  const copyElements = elements.slice()
  const element = copyElements[from]
  copyElements.splice(from, 1)
  copyElements.splice(to, 0, element)
  return copyElements
}
