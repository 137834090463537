import dayjs from 'dayjs'
import { useMemo } from 'react'

import { CheckinSummaryFragment, CheckinSummaryStatus } from '../../graphql'

const CHECKIN_TERM_FORMAT = 'MM/DD'

// チェックイン期間の表示用に変換
const convertLabel = (startDate: Date, endDate: Date) => {
  const convertStartDate = dayjs(startDate).format(CHECKIN_TERM_FORMAT)
  const convertEndDate = dayjs(endDate).format(CHECKIN_TERM_FORMAT)
  return `${convertStartDate} ~ ${convertEndDate}`
}

export type ReturnType = ReadonlyArray<{
  id: string
  label: string
  isCheckinDone: boolean
}>

export const useDisplayUpperLimitData = (
  rawData: ReadonlyArray<CheckinSummaryFragment>,
  hasNextPage: boolean,
): ReturnType => {
  // チェックイン一覧の表示盛業 hasNextPageがtrueの場合はデータ取得数 - 1 まで表示, 超えていない場合は上限まで表示
  const displayLimitData = useMemo(
    () => (hasNextPage ? rawData.slice(0, rawData.length - 1) : rawData),
    [hasNextPage, rawData],
  )

  // 表示用のデータ
  const displayData = useMemo(
    () =>
      displayLimitData.map((data) => ({
        id: data.id,
        label: convertLabel(data.startDate, data.endDate),
        isCheckinDone:
          data.status === CheckinSummaryStatus.Done || data.status === CheckinSummaryStatus.Now,
      })),
    [displayLimitData],
  )

  return displayData
}
