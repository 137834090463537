import { Box } from 'grommet'
import { useCallback, useState } from 'react'
import { createPortal } from 'react-dom'
import { Rnd, Props as RndProps } from 'react-rnd'

import { Icon } from '../../../../components/ui/Icon'
import { StyledText } from '../../../../components/ui/StyledText'
import { color } from '../../../../styles/newColors'

const ENABLE_RESIZING = {
  top: false,
  right: false,
  bottom: false,
  left: false,
  topRight: false,
  bottomRight: true,
  bottomLeft: false,
  topLeft: false,
} as const

type Props = {
  uniqId: string
  title: string | React.ReactNode
  children: React.ReactChild
  onClickClose: (uniqId: string) => void
} & RndProps

export const FloatDrawer: React.VFC<Props> = ({
  title,
  uniqId,
  children,
  onClickClose,
  ...props
}) => {
  const [dragging, setDragging] = useState(false)
  const onDragStartHandler = useCallback(() => setDragging(true), [])
  const onDragStopHandler = useCallback(() => setDragging(false), [])

  return createPortal(
    <Rnd
      dragHandleClassName="handler"
      style={{
        zIndex: 11,
        overflow: 'hidden',
        backgroundColor: color('white-100'),
        boxShadow: '0px 1px 16px rgb(0 0 0 / 10%)',
        borderRadius: '8px',
      }}
      enableResizing={ENABLE_RESIZING}
      onDragStart={onDragStartHandler}
      onDragStop={onDragStopHandler}
      resizeHandleComponent={{
        bottomRight: <ResizeHandler />,
      }}
      bounds="body"
      handleStyles={{ rightBottom: { right: '0', bottom: '0' } }}
      {...props}
    >
      <Box css={{ height: '100%' }}>
        <div
          className="handler"
          css={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '16px 16px 16px 24px',
            borderBottom: '1px solid #E9EAEC',
            cursor: dragging ? 'grabbing' : 'grab',
          }}
        >
          <Title title={title} />
          <Icon
            type="mdiClear"
            css={{
              cursor: 'pointer',
              width: '24px',
              height: '24px',
            }}
            onClick={(e) => {
              e.stopPropagation()
              onClickClose(uniqId)
            }}
          />
        </div>
        <div css={{ overflowY: 'auto' }}>{children}</div>
      </Box>
    </Rnd>,
    document.getElementById('main-content-id') || document.body,
  )
}

FloatDrawer.displayName = 'FloatDrawer'

const Title: React.VFC<Pick<Props, 'title'>> = ({ title }) => {
  if (typeof title !== 'string') {
    return <div css={{ marginTop: '8px' }}>{title}</div>
  }

  return (
    <StyledText
      css={{
        fontWeight: 'bold',
        lineHeight: '20px',
        display: 'inline-block',
        marginTop: '8px',
      }}
    >
      {title}
    </StyledText>
  )
}

Title.displayName = 'Title'

const ResizeHandler: React.FC = () => (
  <div
    css={{
      position: 'absolute',
      right: '10px',
      bottom: '14px',
      transform: 'rotateZ(135deg)',
      '&:hover > .resize-border': {
        backgroundColor: `${color('border-bk-50')}`,
      },
    }}
  >
    <div
      className="resize-border"
      css={{
        margin: '0 auto',
        height: '1px',
        width: '8px',
        backgroundColor: `${color('border-bk-30')}`,
      }}
    />
    <div
      className="resize-border"
      css={{
        margin: '0 auto',
        marginTop: '4px',
        height: '1px',
        width: '16px',
        backgroundColor: `${color('border-bk-30')}`,
      }}
    />
  </div>
)

ResizeHandler.displayName = 'ResizeHandler'
