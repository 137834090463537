import { useCallback, useEffect, useState } from 'react'

import { AuthRouteProps } from '../../../types/authRouteProps'

import { TermFragment, useOkrTermsLazyQuery } from './graphql'

// pathをgenerateする際に現在の期間を参照するため、セッションストレージを経由する
const CURRENT_TERM_ID_SS_KEY = 'current_term_id'

export const removeCurrentTerm = (): void => {
  if (typeof window !== 'undefined') {
    window.sessionStorage.removeItem(CURRENT_TERM_ID_SS_KEY)
  }
}

export const getCurrentTermId = (): string | null => {
  if (typeof window !== 'undefined') {
    return window.sessionStorage.getItem(CURRENT_TERM_ID_SS_KEY)
  }
  return null
}

// termIdがprimaryかを判定する
export const isPrimaryOkrTerm = (
  okrTerms: ReadonlyArray<TermFragment> | null | undefined,
  okrTermId: string | null,
): boolean => okrTerms?.find((term) => term.primary)?.id === okrTermId

const saveCurrentTermId = (id: string): void => {
  if (typeof window !== 'undefined') {
    window.sessionStorage.setItem(CURRENT_TERM_ID_SS_KEY, id)
  }
}

export type Props = AuthRouteProps

// OkrTermIdContextにprimaryなtermをセットし、returnする
export const useCurrentTermIdQuery = (
  onOkrTermLoaded: Props['onOkrTermLoaded'],
): string | null | undefined => {
  const [query, resp] = useOkrTermsLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-and-network',
  })
  const [currentTermId, setStateCurrentTermId] = useState<string | null | undefined>(undefined)

  useEffect(() => {
    query()
  }, [query])

  const setCurrentTermIdAll = useCallback(
    (termId: string) => {
      setStateCurrentTermId(termId)
      saveCurrentTermId(termId)
      onOkrTermLoaded(termId)
    },
    [onOkrTermLoaded],
  )

  useEffect(() => {
    if (!resp.data) {
      return
    }

    const term = resp.data.okrTerms.find(({ primary }) => primary)
    if (term === undefined) {
      setStateCurrentTermId(null)
      return
    }

    setCurrentTermIdAll(term.id)
  }, [resp.data, setCurrentTermIdAll])

  return currentTermId
}

type UseOkrTermsLazyQueryReturnT = ReturnType<typeof useOkrTermsLazyQuery>
type CurrentTermLazyQuery = UseOkrTermsLazyQueryReturnT[0]
type CurrentTermLazyQueryResult = Omit<UseOkrTermsLazyQueryReturnT[1], 'data'> & {
  data:
    | {
        okrTerm: TermFragment
      }
    | undefined
}

export const useCurrentTermIdLazyQuery = (
  onOkrTermLoaded: Props['onOkrTermLoaded'],
): [CurrentTermLazyQuery, CurrentTermLazyQueryResult] => {
  const [termsQuery, termsResp] = useOkrTermsLazyQuery()
  const [currentTermId, setStateCurrentTermId] = useState<string | null | undefined>(undefined)

  const setCurrentTermIdAll = useCallback(
    (termId: string) => {
      setStateCurrentTermId(termId)
      saveCurrentTermId(termId)
      onOkrTermLoaded(termId)
    },
    [onOkrTermLoaded],
  )

  const memoizedTermsQuery: typeof termsQuery = useCallback(
    (...args) => termsQuery(...args),
    [termsQuery],
  )

  useEffect(() => {
    if (!termsResp.data) {
      return
    }

    const term = termsResp.data.okrTerms.find(({ primary }) => primary)
    if (term === undefined) {
      setStateCurrentTermId(null)
      return
    }

    setCurrentTermIdAll(term.id)
  }, [setCurrentTermIdAll, termsResp])

  return [
    memoizedTermsQuery,
    {
      ...termsResp,
      data: currentTermId ? { okrTerm: { id: currentTermId, primary: true } } : undefined,
    },
  ]
}
