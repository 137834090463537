import { ActivityCardColProps as GeishaActivityCardColProps } from '@resily/geisha'

import { useTranslation } from '../../../../../../../../../i18n'
import { Props as RichTextEditorProps } from '../../../../../../../../ui/RichTextEditor'
import { CheckinCommentNames, CHECKIN_COMMENT_NAMES_I18N_KEY } from '../../../../../../constants'
import { isCommentChanged } from '../../../../../../utils'
import { Props as ActivityProps } from '../types'

type Args = {
  keyResultComments: ActivityProps['keyResultComments']
  lastKeyResultComments: ActivityProps['keyResultComments']
}

type ReturnItem = Omit<GeishaActivityCardColProps, 'children'> & {
  keyName: string
  initialValueJSON: RichTextEditorProps['initialValueJSON']
  plainText?: string
  isChanged: boolean
}

type Return = {
  isChanged: boolean
  items: ReadonlyArray<ReturnItem>
}

// geishaのActivityCardで使いやすくしたkeyResultCommentsのデータ構造を返却する
export const useTransformActivityKeyResultComments = ({
  keyResultComments,
  lastKeyResultComments,
}: Args): Return => {
  const { t } = useTranslation()

  const comments: Return['items'] = Object.entries(keyResultComments).map(([key, comment]) => ({
    keyName: key,
    label: t(CHECKIN_COMMENT_NAMES_I18N_KEY[key as CheckinCommentNames]),
    initialValueJSON: comment?.treeJson,
    plainText: comment?.plainText,
    isChanged: isCommentChanged(
      lastKeyResultComments[key as CheckinCommentNames],
      comment,
      comment.isCurrentComment,
    ),
  }))

  return {
    isChanged: comments.some((c) => c.isChanged),
    items: comments.filter((c) => c.isChanged),
  }
}
