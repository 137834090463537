import { useParams, useNavigate } from 'react-router-dom'

import { BreadcrumbParams } from '../../components/domain/Breadcrumb'
import { useTranslation } from '../../i18n'
import { useOkrTermLoadable } from '../../lib/domain/useOkrTermLoadable'
import { convertToFileInput } from '../../lib/fileInput'
import { usePrompt } from '../../lib/prompt'
import { AuthRouteProps } from '../../types/authRouteProps'
import { generateKeyResult, keyResultEdit as keyResultEditPath } from '../../urls'

import {
  FileFragment,
  OkrDetailKeyResultFragment,
  useOkrNodeQuery,
  useUpdateKeyResultDescriptionMutation,
  useUpdateKeyResultNameMutation,
} from './graphql'

import type { Props } from './index'

export const useInjection = ({ onOkrTermLoaded }: AuthRouteProps): null | Props => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const originPath = window.location.pathname + window.location.search + window.location.hash
  const [, setNeedBlock] = usePrompt({ message: t('LEAVE_WARNING'), originPath })

  const { keyResultId } = useParams()
  const { data } = useOkrNodeQuery({
    variables: { keyResultId },
    // NOTE: 古い情報が表示されてしまうためキャッシュを無効にする
    fetchPolicy: 'network-only',
  })
  const [updateKeyResultName] = useUpdateKeyResultNameMutation()
  const [updateKeyResultDescription] = useUpdateKeyResultDescriptionMutation()

  useOkrTermLoadable(onOkrTermLoaded, undefined, data?.okrNode.term.id)

  const { keyResults } = data?.okrNode || { keyResults: [] }
  const keyResult = keyResults.find((e) => e.id === keyResultId)

  if (!keyResult || !data?.okrNode) {
    return null
  }

  const updateName = async (name: string) => {
    await updateKeyResultName({
      variables: {
        id: keyResult.id,
        name,
      },
    })
  }

  const updateDescription = async (
    description: string,
    plainText: string,
    attachments: ReadonlyArray<FileFragment>,
  ) => {
    await updateKeyResultDescription({
      variables: {
        id: keyResult.id,
        description: {
          treeJson: description,
          plainText,
        },
        attachments: attachments.map((attachment) => convertToFileInput(attachment)),
      },
    })
  }

  const { okrNode } = data

  const breadcrumbs: BreadcrumbParams = {
    url: keyResultEditPath,
    items: [
      {
        breadcrumbName: 'okr',
        ...okrNode,
        objective: {
          id: keyResult.objective.id,
          name: keyResult.objective.name,
        },
      },
      {
        breadcrumbName: 'keyResult',
        ...keyResult,
        node: {
          id: okrNode.id,
        },
      },
      {
        breadcrumbName: 'keyResultEdit',
        ...keyResult,
        node: {
          id: okrNode.id,
        },
      },
    ],
  }

  const onUpdateKeyResult = async (
    { name, description, attachments }: OkrDetailKeyResultFragment,
    redirect: boolean,
  ) => {
    await updateName(name)
    await updateDescription(description?.treeJson || '', description?.plainText || '', attachments)
    setNeedBlock(false)
    if (redirect) {
      navigate(generateKeyResult(okrNode.id, keyResult.id))
    }
  }

  const onClickCancel = () => {
    navigate(generateKeyResult(okrNode.id, keyResult.id))
  }

  return {
    breadcrumbs,
    keyResult,
    onUpdateKeyResult,
    onClickCancel,
    setNeedBlock,
  }
}
