import { IconTextButton } from '@resily/geisha'
import { useRef, ChangeEvent } from 'react'

import { File } from '../../../graphql'
import { useTranslation } from '../../../i18n'
import { client } from '../../../lib/client'

export type Props = {
  onChange: (file: File) => void
}

export const AttachFile: React.FC<Props> = ({ onChange }) => {
  const ref = useRef<HTMLInputElement>(null)
  const { t } = useTranslation()

  const handleChangeFile = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.currentTarget.files) return
    const { files } = e.currentTarget
    const item = files.item(0)
    if (!item) return
    const res = await client.uploadFiles<{ name: string; path: string; url: string }>(
      '/upload/files',
      { media: item },
    )
    if (!res) return
    onChange(res)
  }

  return (
    <IconTextButton variant="secondary" iconType="attachment" onClick={() => ref.current?.click()}>
      <input type="file" ref={ref} onChange={handleChangeFile} style={{ display: 'none' }} />
      {t('ATTACH_FILES')}
    </IconTextButton>
  )
}

AttachFile.displayName = 'AttachFile'
