import { DropButton, Grommet } from 'grommet'
import { Fragment, useCallback, useMemo, useState } from 'react'

import { useTranslation } from '../../../i18n'
import { formatDateInput } from '../../../lib/date'
import { sortActionPlansByStatus, sortActionPlansByDueDate } from '../../../lib/domain/actionPlan'
import { fontSize } from '../../../styles/font'
import { color } from '../../../styles/newColors'
import { generateActionPlan } from '../../../urls'
import { AvatarWithName } from '../../ui/AvatarWithName'
import { BorderdButton } from '../../ui/BorderdButton'
import { Button } from '../../ui/Button'
import { Icon } from '../../ui/Icon'
import { Link } from '../../ui/Link'
import { ListCard } from '../../ui/ListCard'
import { StyledText } from '../../ui/StyledText'
import { TextButton } from '../../ui/TextButton'
import { ActionPlanStatusBadge } from '../ActionPlanStatusBadge'
import { ActionPlanStatusSelect } from '../ActionPlanStatusSelect'
import { OkrToolTip } from '../OkrTooltip'

import { ActionPlanFragment, ActionPlanStatus } from './graphql'

const SORT_TYPE_LIST = ['status', 'dueDate'] as const
type SortType = typeof SORT_TYPE_LIST[number]
type SortOrder = 'desc' | 'asc'

const avatarFontSize = fontSize('medium').fontSize

export type Props = {
  actionPlans: Array<ActionPlanFragment>
  termId: string
  onUpdateActionPlanStatus: (data: { actionPlanId: string; status: ActionPlanStatus }) => void
  onClickCreateActionPlan?: () => void
}

export const ActionPlanListCard: React.FC<Props> = ({
  actionPlans,
  termId,
  onUpdateActionPlanStatus,
  onClickCreateActionPlan,
}) => {
  const { t } = useTranslation()
  const [isShowAll, setIsShowAll] = useState(false)
  const [isHideCompletedActionPlans, setIsHideCompletedActionPlans] = useState(true)
  const [actionPlanStatusOpen, setActionPlanStatusOpen] = useState<string | undefined>(undefined)
  const [sort, setSort] = useState<{ type: SortType; order: SortOrder }>({
    type: 'status',
    order: 'desc',
  })

  const setSortByType = useCallback(
    (type: SortType): void => {
      if (sort.order === 'desc')
        return setSort({
          type,
          order: 'asc',
        })
      return setSort({
        type,
        order: 'desc',
      })
    },
    [sort],
  )

  const completedActionPlansNumber: number = useMemo(
    () => actionPlans.filter((a) => a.status === ActionPlanStatus.Completed).length,
    [actionPlans],
  )

  const showActionPlans = useMemo((): Array<ActionPlanFragment> => {
    const filterActionPlans = isHideCompletedActionPlans
      ? actionPlans.filter((a) => a.status !== ActionPlanStatus.Completed)
      : actionPlans
    const isAsc = sort.order === 'asc'
    if (sort.type === 'status')
      return sortActionPlansByStatus<ActionPlanFragment>(filterActionPlans, isAsc)
    if (sort.type === 'dueDate')
      return sortActionPlansByDueDate<ActionPlanFragment>(filterActionPlans, isAsc)
    return filterActionPlans
  }, [isHideCompletedActionPlans, actionPlans, sort])

  return (
    <ListCard
      header={
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '32px',
          }}
        >
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Icon
              type="actionPlan"
              width={24}
              height={24}
              color={color('text-bk-50')}
              css={{ marginTop: '5px', marginRight: '8px' }}
            />
            <StyledText size="xxlarge" color="text-bk-100">
              {t('ACTION_PLAN')}
            </StyledText>
          </div>
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Button
              className="show-done-button"
              newColor="white-100"
              weight="normal"
              size="ss"
              disableFocusStyle
              onClick={() => setIsHideCompletedActionPlans(!isHideCompletedActionPlans)}
            >
              {isHideCompletedActionPlans
                ? t('SHOW_X_DONE', { x: completedActionPlansNumber })
                : t('HIDE_COMPLETED')}
            </Button>
            {onClickCreateActionPlan && (
              <TextButton
                icon="plus"
                color="text-bk-50"
                onClick={onClickCreateActionPlan}
                css={{ paddingTop: '8px', paddingBottom: '8px' }}
              >
                {t('CREATE_ACTION_PLAN')}
              </TextButton>
            )}
          </div>
        </div>
      }
      table={{
        headers: [
          {
            title: <div css={{ paddingLeft: '24px' }}>{t('ACTION_PLAN_NAME')}</div>,
            minWidth: 564,
          },
          {
            title: (
              <TextButton
                color={sort?.type === 'status' ? 'resily-orange-100' : 'text-bk-50'}
                hoverColor={sort?.type === 'status' ? 'resily-orange-100' : 'text-bk-50'}
                size="small"
                onClick={() => setSortByType('status')}
                css={{
                  padding: '0px',
                  fill: color('resily-orange-100'),
                }}
              >
                {t('STATUS')}
                <Icon
                  className="select-down-icon"
                  type="mdiKeyboardArrowDown"
                  color={
                    sort?.type === 'status' ? color('resily-orange-100') : color('border-bk-20')
                  }
                  css={{
                    transform:
                      sort?.type === 'status' && sort.order === 'asc'
                        ? 'rotate(-180deg)'
                        : undefined,
                    marginLeft: '4px',
                    verticalAlign: 'text-bottom',
                  }}
                />
              </TextButton>
            ),
            minWidth: 120,
          },
          {
            title: (
              <TextButton
                color={sort?.type === 'dueDate' ? 'resily-orange-100' : 'text-bk-50'}
                hoverColor={sort?.type === 'dueDate' ? 'resily-orange-100' : 'text-bk-50'}
                onClick={() => setSortByType('dueDate')}
                size="small"
                css={{
                  padding: '0px',
                }}
              >
                {t('DUE_DATE')}
                <Icon
                  className="select-down-icon"
                  type="mdiKeyboardArrowDown"
                  color={
                    sort?.type === 'dueDate' ? color('resily-orange-100') : color('border-bk-20')
                  }
                  css={{
                    transform:
                      sort?.type === 'dueDate' && sort.order === 'asc'
                        ? 'rotate(-180deg)'
                        : undefined,
                    marginLeft: '4px',
                    verticalAlign: 'text-bottom',
                  }}
                />
              </TextButton>
            ),
            minWidth: 128,
          },
          {
            title: t('OWNER'),
            minWidth: 148,
          },
        ],
        data: {
          css: {
            borderBottom: 'none',
          },
          rows: showActionPlans
            .filter((_, i) => isShowAll || i < 5)
            .map((a) => ({
              key: a.id,
              css: {
                ':hover': {
                  backgroundColor: color('hover-background-bk-5'),
                  '.show-okr-button': {
                    display: 'block',
                  },
                  '.select-status-icon': {
                    display: 'block',
                  },
                },
              },
              cells: [
                {
                  key: 'title',
                  cell: (
                    <div
                      css={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div
                        css={{
                          padding: '18px 16px 18px 24px',
                        }}
                      >
                        <Link
                          href={generateActionPlan(a.keyResult.node.id, a.keyResult.id, a.id)}
                          css={{
                            ':hover': {
                              color: color('resily-orange-100'),
                              textDecoration: 'underline',
                            },
                          }}
                        >
                          <StyledText
                            isWrap
                            css={{
                              wordBreak: 'break-all',
                              overflow: 'hidden',
                            }}
                          >
                            {a.title}
                          </StyledText>
                        </Link>
                      </div>
                      <div
                        css={{
                          display: 'flex',
                          flex: '0 0 122px',
                          alignItems: 'center',
                        }}
                      >
                        <OkrToolTip
                          krID={a.keyResult.id}
                          objective={{
                            ...a.keyResult.objective,
                            keyResults: a.keyResult.objective.keyResults.filter(
                              (kr) => !kr.isDisabled,
                            ),
                          }}
                        >
                          <div css={{ display: 'block' }}>
                            <BorderdButton
                              className="show-okr-button"
                              text={t('SHOW_OKR')}
                              css={{ marginRight: '32px' }}
                            />
                          </div>
                        </OkrToolTip>
                      </div>
                    </div>
                  ),
                  minWidth: 564,
                },
                {
                  key: 'status',
                  cell: (
                    <Grommet
                      theme={{
                        global: {
                          drop: {
                            background: 'transparent',
                            shadowSize: 'none',
                          },
                        },
                      }}
                    >
                      <DropButton
                        open={actionPlanStatusOpen === a.id}
                        dropAlign={{ top: 'bottom' }}
                        onClose={() => setActionPlanStatusOpen(undefined)}
                        onOpen={() => setActionPlanStatusOpen(a.id)}
                        dropContent={
                          <Fragment>
                            <div css={{ marginTop: '8px' }} />
                            <ActionPlanStatusSelect
                              onSelect={(status) => {
                                onUpdateActionPlanStatus({ actionPlanId: a.id, status })
                                setActionPlanStatusOpen(undefined)
                              }}
                            />
                          </Fragment>
                        }
                        css={{
                          display: 'flex',
                          ':hover': {
                            path: {
                              fill: color('resily-orange-100'),
                            },
                            ':focus': {
                              boxShadow: 'none',
                            },
                          },
                        }}
                      >
                        <ActionPlanStatusBadge status={a.status} />
                        <div
                          css={{
                            width: '18px',
                          }}
                        >
                          <Icon
                            className="select-status-icon"
                            type="selectDown"
                            color={color('text-bk-50')}
                            css={{
                              display: 'none',
                              verticalAlign: 'middle',
                              width: '8px',
                              height: '8px',
                              margin: '8px',
                            }}
                          />
                        </div>
                      </DropButton>
                    </Grommet>
                  ),
                  minWidth: 120,
                },
                {
                  key: 'due-date',
                  cell: (
                    <StyledText color="text-bk-100">
                      {formatDateInput(a.dueDate ? new Date(a.dueDate).toDateString() : '')}
                    </StyledText>
                  ),
                  minWidth: 128,
                },
                {
                  key: 'owner',
                  cell: (
                    <AvatarWithName
                      size="small"
                      firstName={a.owner.firstName}
                      lastName={a.owner.lastName}
                      avatarUrl={a.owner.avatar?.url}
                      linkage={{
                        userId: a.owner.id,
                        termId,
                      }}
                      css={{
                        width: '148px',
                        fontSize: avatarFontSize,
                      }}
                      isUserDisabled={a.owner.isDisabled}
                      newColor={!a.owner.isDisabled ? undefined : 'text-bk-20'}
                      avatarStyle={{ opacity: !a.owner.isDisabled ? '1' : '0.1' }}
                    />
                  ),
                  minWidth: 148,
                },
              ],
            })),
          rowMinHeight: 54,
          disabledScroll: true,
        },
      }}
      footer={
        <div
          css={{
            height: '36px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-end',
          }}
        >
          {showActionPlans.length > 5 && !isShowAll ? (
            <TextButton
              color="text-bk-100"
              onClick={() => setIsShowAll(true)}
              css={{
                paddingTop: '0px',
                paddingBottom: '0px',
                ':hover': {
                  path: {
                    fill: color('resily-orange-100'),
                  },
                },
              }}
            >
              {t('SHOW_ALL_ITEMS')}
              <Icon
                className="select-down-icon"
                type="mdiKeyboardArrowDown"
                color={color('text-bk-50')}
                css={{
                  marginLeft: '4px',
                  verticalAlign: 'text-bottom',
                }}
              />
            </TextButton>
          ) : undefined}
        </div>
      }
    />
  )
}

ActionPlanListCard.displayName = 'ActionPlanListCard'
