import { useTranslation } from '../../../../../i18n'
import { StyledText } from '../../../../ui/StyledText'

import { useStyles } from './ChildOkrReOrderingContent.styles'

export const ChildOkrReOrderingContent: React.FC = ({ children }) => {
  const { t } = useTranslation()
  const styles = useStyles()

  return (
    <>
      <StyledText as="div" css={styles.description} size="small" fontStyle="regular">
        {t('RE_ORDERING_CHILD_OKR_DESCRIPTION')}
      </StyledText>
      {children}
    </>
  )
}
