import { Avatar, OkrTitle } from '@resily/geisha'
import { forwardRef } from 'react'
import {
  DraggableProvidedDragHandleProps,
  DraggableProvidedDraggableProps,
} from 'react-beautiful-dnd'

import { color } from '../../../../../styles/newColors'
import { Icon } from '../../../../ui/Icon'
import { OkrNodeFragment } from '../graphql'

import { useStyles } from './ChildOkrReOrderingRow.styles'

export type ChildOkrReOrderingRowProps = Pick<OkrNodeFragment, 'objective'> & {
  draggableProps: DraggableProvidedDraggableProps
  dragHandleProps?: DraggableProvidedDragHandleProps
}

export const ChildOkrReOrderingRow = forwardRef<HTMLDivElement, ChildOkrReOrderingRowProps>(
  ({ objective, draggableProps, dragHandleProps }, ref) => {
    const styles = useStyles()

    return (
      <div ref={ref} css={styles.root} {...draggableProps} {...dragHandleProps}>
        <div css={styles.draggableIcon}>
          <Icon type="dragIndicator" width={12} height={12} color={color('text-bk-30')} />
        </div>
        <div css={styles.avatar}>
          <Avatar
            size="md"
            firstName={objective.owner.firstName}
            lastName={objective.owner.lastName}
            src={objective.owner.avatar?.url}
          />
        </div>
        <div css={styles.title}>
          <OkrTitle
            type="objective"
            size="medium"
            title={objective.name}
            withBorder
            fontWeight="regular"
            disabled={objective.isDisabled}
          />
        </div>
      </div>
    )
  },
)
