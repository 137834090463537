import { useEffect } from 'react'
import { useParams, useLocation, useNavigate } from 'react-router-dom'

import { BreadcrumbParams } from '../../components/domain/Breadcrumb'
import { useTranslation } from '../../i18n'
import { usePrompt } from '../../lib/prompt'
import { Screen } from '../../lib/screen'
import { useTracking } from '../../lib/tracking'
import { AuthRouteProps } from '../../types/authRouteProps'
import * as urls from '../../urls'
import { actionPlanEdit as actionPlanEditPath, generateActionPlanEdit } from '../../urls'
import {
  ActionPlanFragment,
  useFindActionPlanByIdQuery,
  useUpdateActionPlanMutation,
} from '../ActionPlanDetail/graphql'

export const useInjection = ({
  onOkrTermLoaded,
}: AuthRouteProps): null | {
  breadcrumbs: BreadcrumbParams
  actionPlan: ActionPlanFragment
  onUpdateActionPlan: (actionPlan: Partial<ActionPlanFragment>) => void
  onClickCancel: () => void
  setNeedBlock: (value: boolean) => void
  actionPlanId: string
} => {
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const originPath = window.location.pathname + window.location.search + window.location.hash
  const [, setNeedBlock] = usePrompt({ message: t('LEAVE_WARNING'), originPath })
  const { actionPlanId } = useParams()
  const res = useFindActionPlanByIdQuery({
    variables: { id: actionPlanId ?? '' },
    skip: actionPlanId == null,
  })
  const [updateActionPlan] = useUpdateActionPlanMutation()
  useTracking(t('ACTION_PLAN_EDIT_PAGE_TITLE'), Screen.ActionPlanEdit)

  useEffect(() => {
    if (!res.data) {
      return
    }
    onOkrTermLoaded(res.data.findActionPlanById.keyResult.objective.node.term.id)
  }, [res, onOkrTermLoaded])
  if (!res.data) {
    return null
  }
  const actionPlan = res.data.findActionPlanById

  // FIXME: 古いURLサポートのためのリダイレクト処理
  if (location.pathname.match(/^\/action-plans\/.*?\/edit.*/)) {
    navigate(
      generateActionPlanEdit(
        actionPlan.keyResult.objective.node.id,
        actionPlan.keyResult.id,
        actionPlanId ?? '',
      ),
      { replace: true },
    )
  }

  const updateActionPlanMutation = async (value: Partial<ActionPlanFragment>) => {
    if (!res.data) {
      return
    }
    const variables: ActionPlanFragment = {
      ...res.data.findActionPlanById,
      ...value,
    }
    await updateActionPlan({
      variables: {
        id: variables.id,
        title: variables.title,
        body: {
          treeJson: variables.body?.treeJson || '',
          plainText: variables.body?.plainText || '',
        },
        ownerId: variables.owner.id,
        keyResultId: variables.keyResult.id,
        memberIds: variables.members.map((e) => e.id),
        updateType: variables.updateType,
      },
    }).catch(() => {})
  }

  const breadcrumbs: BreadcrumbParams = {
    url: actionPlanEditPath,
    items: [
      {
        breadcrumbName: 'okr',
        id: actionPlan.keyResult.objective.node.id,
        objective: {
          id: actionPlan.keyResult.objective.id,
          name: actionPlan.keyResult.objective.name,
        },
      },
      {
        breadcrumbName: 'keyResult',
        id: actionPlan.keyResult.id,
        name: actionPlan.keyResult.name,
        node: {
          id: actionPlan.keyResult.objective.node.id,
        },
      },
      {
        breadcrumbName: 'actionPlans',
        ...actionPlan.keyResult,
        id: actionPlan.keyResult.id,
        node: {
          id: actionPlan.keyResult.objective.node.id,
        },
      },
      {
        breadcrumbName: 'actionPlan',
        ...actionPlan,
        keyResult: {
          ...actionPlan.keyResult,
          id: actionPlan.keyResult.id,
          node: {
            id: actionPlan.keyResult.objective.node.id,
          },
        },
      },
    ],
  }

  const onUpdateActionPlan = async (value: Partial<ActionPlanFragment>) => {
    await updateActionPlanMutation(value)
    navigate(
      urls.generateActionPlan(
        actionPlan.keyResult.objective.node.id,
        actionPlan.keyResult.id,
        actionPlan.id,
      ),
    )
  }
  const onClickCancel = () => {
    navigate(
      urls.generateActionPlan(
        actionPlan.keyResult.objective.node.id,
        actionPlan.keyResult.id,
        actionPlan.id,
      ),
    )
  }

  return {
    breadcrumbs,
    actionPlan,
    onUpdateActionPlan,
    onClickCancel,
    setNeedBlock,
    actionPlanId: actionPlanId ?? '',
  }
}
