import { TextInput, Box, Nav } from 'grommet'
import React, { PropsWithoutRef, useState, useEffect, useRef } from 'react'

import { Button } from '../../components/ui/ButtonDeprecated'
import { FormField } from '../../components/ui/FormField'
import { NoteLabel } from '../../components/ui/NoteLabel'
import { useTranslation } from '../../i18n'
import * as colors from '../../styles/colors'
import { LabelColors, border, selectedColor } from '../../styles/colors'

import { NoteLabelPartsFragment, useIsNoteLabelUsedLazyQuery } from './graphql'

export type EditAreaProps = {
  name: string
  color: string
  updateNoteLabel: (name: string, color: string) => ReturnType<Props['updateNoteLabel']>
  disabled: boolean
}

export const EditArea: React.FC<EditAreaProps> = ({ name, color, updateNoteLabel }) => {
  const { t } = useTranslation()
  const [nameValue, setName] = useState(name)
  const [colorValue, setColor] = useState(color)
  return (
    <Box direction="row" justify="start" pad="20px" gap="20px">
      <FormField label={t('NAME')}>
        <TextInput
          onBlur={() => updateNoteLabel(nameValue, colorValue)}
          placeholder="Name"
          maxLength={20}
          value={nameValue}
          onChange={(e) => setName(e.target.value)}
        />
      </FormField>
      <FormField label={t('COLOR')}>
        <Box direction="row" width="100%">
          {LabelColors.map((labelColor) => (
            <Button
              onClick={() => {
                setColor(labelColor)
                updateNoteLabel(nameValue, labelColor)
              }}
              css={{
                width: '20px',
                height: '20px',
                padding: '2px',
                margin: '2px',
                border: `1px solid ${colorValue === labelColor ? selectedColor : border}`,
                borderRadius: '2px',
              }}
            >
              <Box width="100%" height="100%" background={labelColor} />
            </Button>
          ))}
        </Box>
      </FormField>
    </Box>
  )
}

EditArea.displayName = 'EditArea'

type Props = PropsWithoutRef<JSX.IntrinsicElements['div']> & {
  label: NoteLabelPartsFragment
  updateNoteLabel: (id: string, name: string, color: string) => Promise<unknown>
  deleteNoteLabel: (id: string) => Promise<unknown>
}

export const NoteLabelListItem: React.FC<Props> = ({ label, updateNoteLabel, deleteNoteLabel }) => {
  const { t } = useTranslation()
  const [editable, setEditable] = useState(false)
  const [updating, setUpdating] = useState(false)
  const [query] = useIsNoteLabelUsedLazyQuery({
    onCompleted: (data) => {
      if (data.isNoteLabelUsed) {
        // eslint-disable-next-line no-alert
        if (window.confirm(t('CONFIRMATION_OF_DELETE_USED_LABEL'))) {
          deleteNoteLabel(label.id).finally(() => setUpdating(false))
        } else {
          setUpdating(false)
        }
      } else {
        deleteNoteLabel(label.id).finally(() => setUpdating(false))
      }
    },
  })

  return (
    <Box as="li" direction="column" border={{ side: 'bottom', color: colors.border }}>
      <Box direction="row" justify="between" align="center">
        <Box margin={{ left: '34px' }}>
          <NoteLabel backgroundColor={label.color}>{label.name}</NoteLabel>
        </Box>
        <Nav direction="row" pad="5px">
          {!editable && (
            <Button type="button" color="main" onClick={() => setEditable(!editable)}>
              {t('EDIT')}
            </Button>
          )}
          <Button
            color="warning"
            disabled={updating}
            onClick={() => {
              // eslint-disable-next-line no-alert
              if (window.confirm(t('CONFIRMATION_OF_DELETE'))) {
                setUpdating(true)
                query({ variables: { noteLabelId: label.id } })
              }
            }}
          >
            {t('DELETE')}
          </Button>
        </Nav>
      </Box>
      {editable && (
        <Modal onRequestClose={() => setEditable(false)}>
          <EditArea
            disabled={updating}
            name={label.name}
            color={label.color}
            updateNoteLabel={(name, color) => {
              setUpdating(true)
              return updateNoteLabel(label.id, name, color).finally(() => setUpdating(false))
            }}
          />
        </Modal>
      )}
    </Box>
  )
}

NoteLabelListItem.displayName = 'NoteLabelListItem'

type ModalProps = {
  onAfterOpen?: () => void
  onRequestClose?: () => void
  children: React.ReactNode
}

const Modal: React.FC<ModalProps> = ({ onAfterOpen, onRequestClose, children }) => {
  const modalRef = useRef<HTMLDivElement>(null)
  const handleClickEvent = (evt: MouseEvent) => {
    // https://stackoverflow.com/questions/48433096/react-flowtype-node-contains-event-target
    if (!(evt.target instanceof Node)) return
    // ref内にクリックされたeventのDOMが含まれているかを確認する
    if (modalRef.current && !modalRef.current.contains(evt.target)) {
      if (onRequestClose) {
        onRequestClose()
      }
    }
  }

  // componentDidMountとcomponentWillUnmountの代替
  useEffect(() => {
    window.addEventListener('click', handleClickEvent)
    if (onAfterOpen) {
      onAfterOpen()
    }
    return () => window.removeEventListener('click', handleClickEvent)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <div ref={modalRef}>{children}</div>
}

Modal.displayName = 'Modal'
