import { css } from '@emotion/react'
import { useMemo } from 'react'

import { color } from '../../../styles/newColors'

export const avatarWidth = 24
export const selectedTextFontSize = 12
export const selectedBoxPadding = 12
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useStyles = (disabled?: boolean) =>
  useMemo(
    () =>
      ({
        root: css({
          position: 'relative',
        }),
        select: css({
          minWidth: 250,
          width: '100%',
          height: 40,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '12px 24px 12px 16px',
          gap: 8,
          userSelect: 'none',
          border: `1px solid ${color('border-bk-30')}`,
          borderRadius: 4,
          backgroundColor: disabled ? color('background-bk-5') : undefined,
          pointerEvents: disabled ? 'none' : 'auto',
        }),
        values: css({
          width: '100%',
          display: 'flex',
          alignItems: 'center',
        }),
        value: css({
          paddingLeft: `${selectedBoxPadding}px`,
          '&:first-of-type': { paddingLeft: 0 },
          [`&[data-is-display-none="true"]`]: {
            display: 'none',
          },
        }),
        placeholder: css({
          paddingLeft: '5px',
          paddingRight: '5px',
        }),
        tooltip: css({
          display: 'inline-flex',
          cursor: 'pointer',
        }),
        dropdown: css({
          position: 'absolute',
          top: '40px',
          zIndex: 501,
          width: '100%',
        }),
        rightIcon: css({
          width: 16,
          height: 16,
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          '> svg': {
            transition: 'transform 0.1s',
            '&[data-show-drop="true"]': { transform: 'scaleY(-1)' },
          },
        }),
      } as const),
    [disabled],
  )
