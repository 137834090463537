import { keyResultToConfidenceLevel } from '../../../lib/domain/confidence'

import { KeyResultFragment } from './graphql'

import { ConfidenceTag, Props as ConfidenceTagProps } from './index'

export type Props = Omit<ConfidenceTagProps, 'confidenceLevel'> & {
  keyResult: KeyResultFragment
}

export const OwnerConfidenceTag: React.VFC<Props> = ({ keyResult, ...props }) => {
  const ownerConfidenceLevel = keyResultToConfidenceLevel(keyResult)

  return <ConfidenceTag confidenceLevel={ownerConfidenceLevel} {...props} />
}

OwnerConfidenceTag.displayName = 'OwnerConfidenceTag'
