import { color } from './newColors'

export type BorderAlias = keyof typeof BorderAliasObj

export const BorderAliasObj = {
  'simple-80': `1px solid ${color('border-bk-80')}`,
  'simple-50': `1px solid ${color('border-bk-50')}`,
  'simple-40': `1px solid ${color('border-bk-40')}`,
  'simple-30': `1px solid ${color('border-bk-30')}`,
  'simple-20': `1px solid ${color('border-bk-20')}`,
  'simple-10': `1px solid ${color('border-bk-10')}`,
  'simple-resily-orange-100': `1px solid ${color('resily-orange-100')}`,
  transparent: `1px solid transparent`,
} as const

export const border = <T extends keyof typeof BorderAliasObj>(alias: T): typeof BorderAliasObj[T] =>
  BorderAliasObj[alias]
