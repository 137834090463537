import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd'

import { KeyResultAreaKeyResultFragment } from '../../../graphql'

import {
  KeyResultAreaTableEmptyRow,
  Props as KeyResultAreaTableEmptyRowProps,
} from './KeyResultAreaTableEmptyRow'
import { KeyResultAreaTableRow, Props as KeyResultAreaTableRowProps } from './KeyResultAreaTableRow'

export type Props = Pick<KeyResultAreaTableRowProps, 'termId' | 'onClickKeyResultName'> &
  KeyResultAreaTableEmptyRowProps & {
    keyResults: ReadonlyArray<KeyResultAreaKeyResultFragment>
    updateKeyResultDisplayOrder: (result: DropResult) => void
  }

export const KeyResultAreaTableRows: React.VFC<Props> = ({
  termId,
  keyResults,
  onClickKeyResultName,
  openKeyResultCreateModal,
  updateKeyResultDisplayOrder,
}) => (
  <>
    {keyResults.length > 0 ? (
      <DragDropContext onDragEnd={updateKeyResultDisplayOrder}>
        <Droppable droppableId="droppable">
          {(provided, { isDraggingOver }) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {keyResults.map((kr, index) => (
                <KeyResultAreaTableRow
                  key={kr.id}
                  index={index}
                  kr={kr}
                  termId={termId}
                  hoverActionDisabled={isDraggingOver}
                  onClickKeyResultName={onClickKeyResultName}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    ) : (
      <KeyResultAreaTableEmptyRow openKeyResultCreateModal={openKeyResultCreateModal} />
    )}
  </>
)
KeyResultAreaTableRows.displayName = 'KeyResultAreaTableRows'
