import { Box } from 'grommet'

import { StyledText } from '../../../../../components/ui/StyledText'
import { useTranslation } from '../../../../../i18n'
import { DisabledUserTable } from '../../UserTable/DisabledUserTable'
import { DisabledUserTableProps } from '../../UserTable/DisabledUserTable/types'
import { emptyCss } from '../styles'

import { DisabledUserTabProps } from './types'

export const DisabledUserTab: React.FC<DisabledUserTabProps> = ({ disableUsers, onRowClick }) => {
  const { t } = useTranslation()
  return (
    <Box>
      <DisabledUserTable
        users={disableUsers}
        onClickRow={onRowClick as DisabledUserTableProps['onClickRow']}
      />
      {disableUsers.length < 1 && (
        <StyledText css={emptyCss}>{t('TARGET_USER_IS_EMPTY')}</StyledText>
      )}
    </Box>
  )
}
