import { css } from '@emotion/react'
import { useMemo } from 'react'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useStyles = () =>
  useMemo(
    () =>
      ({
        root: css({
          position: 'relative',
          minWidth: 0,
          flex: 1,
        }),
        multiSelectWrapper: css({
          position: 'absolute',
          zIndex: 101,
        }),
      } as const),
    [],
  )
