import { PropsWithoutRef } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import type { DrawerType } from '../../components/domain/OkrDrawer/types'
import { Avatar } from '../../components/ui/Avatar'
import { DateTime } from '../../components/ui/DateTime'
import * as colors from '../../styles/colors'
import * as urls from '../../urls'
import { makeDrawerSearchParams } from '../../urls'

import { NotificationFragment } from './graphql'

type Props = PropsWithoutRef<JSX.IntrinsicElements['div']> & {
  node: NotificationFragment
}

const getLink = (node: NotificationFragment) => {
  const params = (type: DrawerType) =>
    makeDrawerSearchParams(type, 'info', node.resource.id).toString()

  switch (node.resource.__typename) {
    case 'Note':
      return urls.generateNote(node.resource.id)
    case 'KeyResult':
      return `${urls.generateOkr(node.resource.node.id)}?${params('keyResult')}`
    case 'Objective':
      return `${urls.generateOkr(node.resource.node.id)}?${params('objective')}`
    default:
      return urls.notFound
  }
}

export const NotificationListItem: React.FC<Props> = ({ node }) => (
  <li>
    <RouterLink
      css={{
        '&:hover': {
          background: '#efefef',
        },

        '&:focus': {
          background: '#efefef',
        },
        outline: 'none',
        textDecoration: 'none',
        backgroundColor: node.alreadyRead ? '#f6f8fa' : 'white',
        cursor: 'pointer',
        height: 60,
        borderBottom: `1px solid ${colors.border}`,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
      to={getLink(node)}
    >
      <div
        css={{
          marginLeft: '24px',
          display: 'flex',
        }}
      >
        <div css={{ marginLeft: '10px' }}>{node.changesBody}</div>
      </div>
      <div css={{ display: 'flex' }}>
        <Avatar
          css={{
            flex: '0 0 40px',
            width: '40px',
            height: '40px',
            marginRight: '10px',
          }}
          firstName={node.triggeredUser.firstName}
          lastName={node.triggeredUser.lastName}
          avatarUrl={node.triggeredUser.avatar?.url}
          isUserDisabled={node.triggeredUser.isDisabled}
        />
        <div css={{ color: 'grey', marginTop: '10px', marginRight: '24px' }}>
          <DateTime datetime={node.createdAt} />
        </div>
      </div>
    </RouterLink>
  </li>
)

NotificationListItem.displayName = 'NotificationListItem'
