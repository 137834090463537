import { useParams, useNavigate } from 'react-router-dom'

import { BreadcrumbParams } from '../../components/domain/Breadcrumb'
import { useTranslation } from '../../i18n'
import { useOkrTermLoadable } from '../../lib/domain/useOkrTermLoadable'
import { Screen } from '../../lib/screen'
import { useTracking } from '../../lib/tracking'
import { AuthRouteProps } from '../../types/authRouteProps'
import { generateKeyResultEdit, keyResult as keyResultPath } from '../../urls'

import { OkrDetailKeyResultFragment, useOkrNodeQuery } from './graphql'

export const useInjection = ({
  onOkrTermLoaded,
}: AuthRouteProps): null | {
  breadcrumbs: BreadcrumbParams
  onKeyResultEdit: () => void
  keyResult: OkrDetailKeyResultFragment
} => {
  const { keyResultId } = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { data } = useOkrNodeQuery({
    variables: { keyResultId },
    // NOTE: 古い情報が表示されてしまうためキャッシュを無効にする
    fetchPolicy: 'network-only',
  })
  useTracking(t('KEY_RESULT'), Screen.KeyResultDetailInfo)

  useOkrTermLoadable(onOkrTermLoaded, undefined, data?.okrNode.term.id)

  const { keyResults } = data?.okrNode || { keyResults: [] }
  const keyResult = keyResults.find((e) => e.id === keyResultId)
  if (!keyResult || !data?.okrNode) {
    return null
  }

  const node = data.okrNode

  const breadcrumbs: BreadcrumbParams = {
    url: keyResultPath,
    items: [
      {
        breadcrumbName: 'okr',
        ...node,
        objective: {
          id: keyResult.objective.id,
          name: keyResult.objective.name,
        },
      },
      {
        breadcrumbName: 'keyResult',
        ...keyResult,
        node: {
          id: node.id,
        },
      },
      {
        breadcrumbName: 'keyResultDetail',
        ...keyResult,
        node: {
          id: node.id,
        },
      },
    ],
  }

  const onKeyResultEdit = () => {
    navigate(generateKeyResultEdit(node.id, keyResult.id))
  }

  return {
    breadcrumbs,
    onKeyResultEdit,
    keyResult,
  }
}
