import { useCallback, useContext, useMemo } from 'react'

import { OkrTermIdContext } from '../../../../../contexts/OkrTermIdContext'
import { findScreen } from '../../../../../lib/screen'
import { tracker } from '../../../../../lib/tracking'
import { Activities } from '../../Common/Activities'
import { LoadableProps } from '../../types'

import { useActivitiesQuery } from './graphql'

const ACTIVITIES_PER_PAGE = 10

export type DataProps = {
  keyResultIds: ReadonlyArray<string> | null | undefined
}

export type Props = LoadableProps<DataProps>

export const ActivityContainer: React.VFC<Props> = ({
  loading: containerLoading,
  keyResultIds,
}) => {
  const { okrTermId } = useContext(OkrTermIdContext)

  const { data, loading, fetchMore } = useActivitiesQuery({
    variables: {
      keyResultIds: keyResultIds ?? [],
      first: ACTIVITIES_PER_PAGE,
    },
    skip: keyResultIds == null || keyResultIds.length === 0,
  })

  const activities = useMemo(
    () => data?.findActivitiesByKeyResultIds.edges.map((edge) => edge.node) ?? [],
    [data],
  )

  const endCursor = data?.findActivitiesByKeyResultIds.pageInfo.endCursor
  const hasNextPage = data?.findActivitiesByKeyResultIds?.pageInfo.hasNextPage
  const canFetchMore = endCursor != null && hasNextPage
  const handleFetchMore = useCallback(() => {
    if (!canFetchMore) return
    tracker.UserClickShowMoreActivityByObjectiveDrawer(
      findScreen(window.location.pathname, window.location.search),
    )
    fetchMore({
      variables: {
        first: ACTIVITIES_PER_PAGE,
        after: endCursor,
      },
    })
  }, [canFetchMore, endCursor, fetchMore])

  if (okrTermId == null) return null

  return (
    <Activities
      loading={containerLoading || (loading && data == null)}
      termId={okrTermId}
      activities={activities}
      showMore={canFetchMore}
      showTitle
      onClickMore={handleFetchMore}
    />
  )
}
