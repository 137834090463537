import { FC, Fragment } from 'react'

import { ApplicationProps } from '../../components/domain/ApplicationCard'
import { Checkbox } from '../../components/ui/Checkbox'
import { Icon } from '../../components/ui/Icon'
import { StyledText } from '../../components/ui/StyledText'
import { useTranslation } from '../../i18n'

const Thumbnail: JSX.Element = (
  <div
    css={{
      display: 'flex',
      alignItems: 'center',
    }}
  >
    <Icon type="slackLogo" css={{ width: 32, height: 32 }} />
    <Icon type="slack" css={{ marginLeft: 14, width: 'auto', height: 14 }} />
  </div>
)

type OptionProps = {
  slackNotifyBulkUpdateEnabled: boolean
  updateSlackNotifyBulkUpdateEnabled: (enabled: boolean) => void
}
const Option: FC<OptionProps> = ({
  slackNotifyBulkUpdateEnabled,
  updateSlackNotifyBulkUpdateEnabled,
}) => {
  const { t } = useTranslation()
  return (
    <Fragment>
      <StyledText
        color="text-bk-50"
        size="small"
        oneline
        css={{ display: 'block', marginBottom: '8px' }}
      >
        {t('OPTION')}
      </StyledText>
      <Checkbox
        checked={slackNotifyBulkUpdateEnabled}
        onChange={() => updateSlackNotifyBulkUpdateEnabled(!slackNotifyBulkUpdateEnabled)}
      >
        <StyledText color="text-bk-100" size="small" oneline>
          {t('NOTIFY_ALL_USERS_OF_KR_BATCH_UPDATE')}
        </StyledText>
      </Checkbox>
    </Fragment>
  )
}

Option.displayName = 'Option'

export type Props = {
  slackIntegrationEnabled: boolean
  slackNotifyBulkUpdateEnabled: boolean
  enableOption: boolean
  integrateSlack: () => void
  segregateSlack: () => void
  updateSlackNotifyBulkUpdateEnabled: (enabled: boolean) => void
}
export const SlackApp = ({
  slackIntegrationEnabled,
  slackNotifyBulkUpdateEnabled,
  integrateSlack,
  segregateSlack,
  enableOption,
  updateSlackNotifyBulkUpdateEnabled,
}: Props): ApplicationProps => ({
  appName: 'Slack',
  applicationUrl: 'https://slack.com',
  thumbnail: Thumbnail,
  enabled: slackIntegrationEnabled,
  option: enableOption ? (
    <Option
      slackNotifyBulkUpdateEnabled={slackNotifyBulkUpdateEnabled}
      updateSlackNotifyBulkUpdateEnabled={updateSlackNotifyBulkUpdateEnabled}
    />
  ) : undefined,
  handleIntegrate: integrateSlack,
  handleSegregate: segregateSlack,
})
