import { useCallback, useMemo } from 'react'

import { ActivityKeyResultEvent } from '../../../../graphql'
import { useTranslation } from '../../../../i18n'

export const useCalcDiffValue = (
  keyResultEvent?: ActivityKeyResultEvent | null,
): {
  progressRate?: number
  actualValue?: number
  targetValue?: number
  initialValue?: number
  confidenceLevel?: number
  unit?: string
} => {
  const { t } = useTranslation()

  const calcDiffValue = useCallback((before?: number | null, after?: number | null) => {
    if (before == null || after == null) return undefined

    return after - before
  }, [])

  const calcDiffUnit = useCallback(
    (before?: string | null, after?: string | null) => {
      if (before == null) return undefined
      if (after === before) return ''
      if (before === '') return t('UNIT_NOT_SET')

      return before
    },
    [t],
  )

  const progressRateDiffValue = useMemo(
    () => calcDiffValue(keyResultEvent?.beforeProgressRate, keyResultEvent?.afterProgressRate),
    [calcDiffValue, keyResultEvent?.afterProgressRate, keyResultEvent?.beforeProgressRate],
  )

  const actualValueDiffValue = useMemo(
    () => calcDiffValue(keyResultEvent?.beforeActualValue, keyResultEvent?.afterActualValue),
    [calcDiffValue, keyResultEvent?.afterActualValue, keyResultEvent?.beforeActualValue],
  )

  const targetValueDiffValue = useMemo(
    () => calcDiffValue(keyResultEvent?.beforeTargetValue, keyResultEvent?.afterTargetValue),
    [calcDiffValue, keyResultEvent?.afterTargetValue, keyResultEvent?.beforeTargetValue],
  )

  const initialValueDiffValue = useMemo(
    () => calcDiffValue(keyResultEvent?.beforeInitialValue, keyResultEvent?.afterInitialValue),
    [calcDiffValue, keyResultEvent?.afterInitialValue, keyResultEvent?.beforeInitialValue],
  )

  const confidenceLevelDiffValue = useMemo(
    () =>
      calcDiffValue(keyResultEvent?.beforeConfidenceLevel, keyResultEvent?.afterConfidenceLevel),
    [calcDiffValue, keyResultEvent?.afterConfidenceLevel, keyResultEvent?.beforeConfidenceLevel],
  )

  const diffUnit = useMemo(
    () => calcDiffUnit(keyResultEvent?.beforeUnit, keyResultEvent?.afterUnit),
    [calcDiffUnit, keyResultEvent?.afterUnit, keyResultEvent?.beforeUnit],
  )

  return {
    progressRate: progressRateDiffValue,
    actualValue: actualValueDiffValue,
    targetValue: targetValueDiffValue,
    initialValue: initialValueDiffValue,
    confidenceLevel: confidenceLevelDiffValue,
    unit: diffUnit,
  }
}
