import { useCallback, useState } from 'react'
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  ResponderProvided,
} from 'react-beautiful-dnd'

import { newDisplayOrder, reOrderingItems } from '../../../../../../../lib/domain/okr'
import { useDraggableInPortal } from '../../../../../../../lib/reactBeautifulDnd/hooks/useDraggableInPortal'
import { color } from '../../../../../../../styles/newColors'

import { KeyResultsTableItemsDescription } from './KeyResultsTableItemsDescription'
import { KeyResultFragment } from './graphql'

export type Props = {
  items: ReadonlyArray<KeyResultFragment>
  onReOrder: (keyResultId: KeyResultFragment['id'], displayOrder: number) => void
  updateKeyResultName: (keyResultId: string, name: string) => Promise<void>
}

export const DraggableKeyResultsTableDescription: React.FC<Props> = ({
  items,
  onReOrder,
  updateKeyResultName,
}) => {
  const [state, setState] = useState<ReadonlyArray<KeyResultFragment>>(items)
  const renderDraggable = useDraggableInPortal()

  const handleDragEnd = useCallback(
    (result: DropResult, _: ResponderProvided) => {
      if (!result.destination) return

      const { draggableId } = result
      const source = result.source.index
      const destination = result.destination.index

      setState((prev) => {
        const reOrderingKeyResults = reOrderingItems(prev, source, destination)
        if (!reOrderingKeyResults) return prev

        onReOrder(draggableId, newDisplayOrder(prev, source, destination))
        return reOrderingKeyResults
      })
    },
    [onReOrder],
  )

  const handleUpdateKeyResultName = useCallback(
    async (keyResultId: string, name: string) => {
      await updateKeyResultName(keyResultId, name)
      setState((keyResults) =>
        keyResults.map((kr) => ({ ...kr, name: kr.id === keyResultId ? name : kr.name })),
      )
    },
    [updateKeyResultName],
  )

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <table
            ref={provided.innerRef}
            css={{
              width: '100%',
              borderCollapse: 'collapse',
              tbody: {
                tr: {
                  td: {
                    borderBottom: `1px solid ${color('border-bk-10')}`,
                  },
                },
              },
              'tr:hover': {
                backgroundColor: color('hover-background-bk-5'),
              },
            }}
          >
            <tbody>
              {state.map((item, index) => (
                <Draggable
                  key={item.id}
                  draggableId={item.id}
                  index={index}
                  css={{
                    cursor: 'auto',
                  }}
                >
                  {renderDraggable((p, s) => (
                    <KeyResultsTableItemsDescription
                      ref={p.innerRef}
                      keyResult={item}
                      updateKeyResultName={handleUpdateKeyResultName}
                      style={{
                        backgroundColor: s.isDragging
                          ? color('hover-background-bk-5')
                          : color('white-100'),
                      }}
                      {...p.draggableProps}
                      {...p.dragHandleProps}
                    />
                  ))}
                </Draggable>
              ))}
              {provided.placeholder}
            </tbody>
          </table>
        )}
      </Droppable>
    </DragDropContext>
  )
}

DraggableKeyResultsTableDescription.displayName = 'DraggableKeyResultsTableDescription'
