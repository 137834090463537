import { css } from '@emotion/react'

export const okrTermsTableWrapperCss = css({
  minHeight: '300px',
})

export const createButtonCss = css({
  position: 'absolute',
  top: '90px',
  right: '36px',
  marginRight: '12px',
})
