import { css } from '@emotion/react'

import { color } from '../../../../../../../styles/newColors'

export const rootCss = css({
  overflow: 'auto',
  '> *:last-child': {
    minHeight: '100%',
  },
})

export const krWrapperCss = css({
  padding: '12px 32px 32px',
  borderBottom: `1px solid`,
  borderColor: color('border-bk-10'),
})

export const okrTitleWrapperCss = css({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  padding: '12px 0px',
  marginBottom: '20px',
})

export const progressFieldWrapperCss = css({
  marginBottom: '16px',
})

export const progressControllerAndFormFieldWrapperCss = css({
  display: 'flex',
  alignItems: 'center',
})

export const progressRateAndConfidenceLabelCss = css({
  fontSize: 'bold',
  lineHeight: '20px',
  color: color('text-bk-50'),
  marginRight: '24px',
})

export const unitCss = css({
  marginLeft: '4px',
  marginRight: '16px',
})

export const percentUnitCss = css({
  marginLeft: '4px',
  marginRight: '16px',
})

export const confidenceSelectWrapperCss = css({
  marginLeft: 'auto',
  width: '134px',
})

export const promptMessageWrapperCss = css({
  marginLeft: '100px',
  marginBottom: '16px',
  display: 'flex',
  alignItems: 'start',
})

export const promptMessageIconCss = css({
  marginTop: '2px',
  marginRight: '8px',
  color: color('tag-red'),
})

export const promptMessageCss = css({
  whiteSpace: 'pre-wrap',
  lineHeight: '16px',
})

export const reasonInputWrapperCss = css({
  marginLeft: '100px',
  marginBottom: '32px',
})
