import { forwardRef, PropsWithRef } from 'react'

import { fontSize as fontSizeFunc, FontSizeAlias } from '../../../styles/font'
import * as newColor from '../../../styles/newColors'
import * as iconUi from '../Icon'

export type Props = PropsWithRef<JSX.IntrinsicElements['button']> & {
  color?: newColor.ColorAlias
  hoverColor?: newColor.ColorAlias
  size?: FontSizeAlias
  icon?: iconUi.Type
  iconSize?: number
  padding?: string
  hasUnderline?: boolean
}

export const TextButton = forwardRef<HTMLButtonElement, Props>(
  (
    {
      color = 'text-bk-100',
      hoverColor = 'resily-orange-100',
      size = 'medium',
      children,
      icon,
      iconSize = 14,
      padding = '12px 16px',
      hasUnderline,
      ...props
    },
    ref,
  ) => {
    const usualColor = newColor.color(color)
    const onhoverColor = newColor.color(hoverColor)
    const { fontSize } = fontSizeFunc(size)

    return (
      <button
        type="button"
        ref={ref}
        css={{
          '&:focus': {
            outline: 'none',
          },
          '&:disabled': {
            span: {
              color: newColor.color('text-bk-10'),
              ':hover': {
                color: newColor.color('text-bk-10'),
                'svg + span': { color: newColor.color('text-bk-10') },
              },
            },
            /* stylelint-disable-next-line */
            '.text': hasUnderline
              ? {
                  borderBottom: `1px solid ${newColor.color('text-bk-30')}`,
                  ':hover': { borderBottom: `1px solid ${newColor.color('text-bk-30')}` },
                }
              : {},
          },
          padding,
          borderRadius: 4,
          display: 'inherit',
        }}
        {...props}
      >
        <span
          css={{
            display: 'flex',
            alignItems: 'center',
            color: usualColor,
            ':hover': { color: onhoverColor },
          }}
        >
          {icon && (
            <iconUi.Icon
              type={icon}
              width={iconSize}
              height={iconSize}
              css={{
                margin: 'auto 8px auto 0',
                width: '12px',
                height: '15px',
              }}
            />
          )}
          <span
            className="text"
            css={{
              fontSize,
              lineHeight: 1,
              ...(hasUnderline
                ? {
                    borderBottom: `1px solid ${usualColor}`,
                    ':hover': { borderBottom: `1px solid ${onhoverColor}` },
                  }
                : {}),
            }}
          >
            {children}
          </span>
        </span>
      </button>
    )
  },
)

TextButton.displayName = 'TextButton'
