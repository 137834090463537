import { useCallback, useMemo } from 'react'
import { DropResult } from 'react-beautiful-dnd'

import {
  useOkrRefetchQueriesWithOkrModalInitQuery,
  newDisplayOrder,
  reOrderingItems,
} from '../../../../../../../lib/domain/okr'
import { KeyResultAreaKeyResultFragment } from '../../../../graphql'
import { useReOrderingKeyResultMutation, ReOrderingKeyResultMutationVariables } from '../graphql'

export type ReorderKeyResultReturnType = {
  orderedKeyResults: ReadonlyArray<KeyResultAreaKeyResultFragment>
  handleReOrderingKeyResult: (result: DropResult) => void
}

export const useReOrderingKeyResult = (
  okrNodeId: ReOrderingKeyResultMutationVariables['nodeID'] | null | undefined,
  keyResults: ReadonlyArray<KeyResultAreaKeyResultFragment>,
): ReorderKeyResultReturnType => {
  const [reOrderKerResult] = useReOrderingKeyResultMutation({
    refetchQueries: useOkrRefetchQueriesWithOkrModalInitQuery(),
  })

  // 一時的に存在するdisplayOrder順になってないキャッシュをdisplayOrder順に並び替える
  const orderedKeyResults = useMemo<ReorderKeyResultReturnType['orderedKeyResults']>(
    () => keyResults.concat().sort((a, b) => a.displayOrder - b.displayOrder),
    [keyResults],
  )

  // ドラッグ＆ドロップで並び替えた後に一瞬、元の順番に戻ってしまうのを防ぐため、stateを用意する
  const handleReOrderingKeyResult = useCallback<
    ReorderKeyResultReturnType['handleReOrderingKeyResult']
  >(
    (result: DropResult) => {
      if (!result.destination || !okrNodeId) return

      const { draggableId } = result
      const source = result.source.index
      const destination = result.destination.index

      const reOrderingKeyResults = reOrderingItems(orderedKeyResults, source, destination)
      if (!reOrderingKeyResults) return

      reOrderKerResult({
        variables: {
          nodeID: okrNodeId,
          keyResultID: draggableId,
          displayOrder: newDisplayOrder(orderedKeyResults, source, destination),
        },
        optimisticResponse: {
          reOrderingKeyResult: reOrderingKeyResults,
        },
      })
    },
    [orderedKeyResults, okrNodeId, reOrderKerResult],
  )

  return {
    orderedKeyResults,
    handleReOrderingKeyResult,
  }
}
