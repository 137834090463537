import { useTranslation } from '../../../../../i18n'
import { ActionPlanCard } from '../../../../domain/ActionPlanCard'
import { TextButton } from '../../../../ui/TextButton'

import { useStyles } from './ActionPlan.styles'
import { EmptyView } from './EmptyView'
import { ActionPlanSkeleton } from './Skeleton'
import { ActionPlanFragment } from './graphql'

export type ActionPlanProps = {
  loading: boolean
  actionPlans: ReadonlyArray<ActionPlanFragment> | null | undefined
  onClickCreateActionPlan: () => void
}

export const ActionPlan: React.VFC<ActionPlanProps> = ({
  loading,
  actionPlans,
  onClickCreateActionPlan,
}) => {
  const styles = useStyles()
  const { t } = useTranslation()

  if (loading || actionPlans == null) {
    return <ActionPlanSkeleton />
  }

  if (actionPlans.length === 0) {
    return <EmptyView onClickCreateActionPlan={onClickCreateActionPlan} />
  }

  return (
    <>
      <div css={styles.createButtonArea}>
        <TextButton icon="plus" color="text-bk-30" padding="0" onClick={onClickCreateActionPlan}>
          {t('CREATE_ACTION_PLAN')}
        </TextButton>
      </div>
      {actionPlans.map((item) => (
        <div key={item.id} css={styles.actionPlanCard}>
          <ActionPlanCard actionPlan={item} avatarLinkMethod="newTab" />
        </div>
      ))}
    </>
  )
}
