import { useModal } from '@resily/geisha'
import { useCallback, useContext, useMemo } from 'react'

import { OkrTermIdContext } from '../../../contexts/OkrTermIdContext'
import { useCurrentUser } from '../../../contexts/UserContext'
import { useOkrRefetchQueriesWithOkrModalInitQuery } from '../../../lib/domain/okr'
import {
  InitQueryContextProvider,
  useKeyResultEditFieldsFetch,
} from '../../domain/KeyResultEditFields/hooks'

import { OkrCreateModal, Props as OkrCreateModalProps } from './OkrCreateModal'
import {
  AddOkrNodeInput,
  CreateKeyResultInput,
  OkrNodeFragmentDoc,
  OkrNodesDocument,
  OkrNodesQuery,
  OkrNodesQueryVariables,
  useCreateKeyResultsMutation,
  useCreateOkrNodeMutation,
} from './graphql'

export type Props = Omit<
  OkrCreateModalProps,
  | 'termId'
  | 'currentUserId'
  | 'initialObjectiveState'
  | 'generateInitialKeyResultState'
  | 'onClickSaveObjective'
  | 'onClickSaveKeyResults'
> & {
  // 子OKR作成時に指定される値
  initialObjectiveStateOverride?: Partial<
    Pick<
      OkrCreateModalProps['initialObjectiveState'],
      'owner' | 'selectedGroups' | 'parentOkrNode' | 'parentKeyResultIds'
    >
  >
}

export const OkrCreateModalContainerInner: React.VFC<
  Props & { modalComponent: ReturnType<typeof useModal>[0] }
> = ({ initialObjectiveStateOverride, ...props }) => {
  const { okrTermId } = useContext(OkrTermIdContext)
  const currentUser = useCurrentUser()

  const initialObjectiveState = useMemo<OkrCreateModalProps['initialObjectiveState']>(
    () => ({
      name: '',
      owner: currentUser ?? undefined,
      description: { plainText: '', body: '' },
      attachmentViews: [],
      isDescriptionChanged: false,
      parentKeyResultIds: [],
      selectedGroups: [],
      slackChannelIds: [],
      ...initialObjectiveStateOverride,
    }),
    [currentUser, initialObjectiveStateOverride],
  )

  const [createOkrNode] = useCreateOkrNodeMutation({
    refetchQueries: useOkrRefetchQueriesWithOkrModalInitQuery(),
    update: (cache, { data }) => {
      if (!data) return

      cache.modify({
        fields: {
          okrNodes(existingOkrNodeRefs = []) {
            const newOkrNodeRef = cache.writeFragment({
              data: data.addOkrNode[0],
              fragment: OkrNodeFragmentDoc,
              fragmentName: data.addOkrNode[0].__typename,
            })
            return [...existingOkrNodeRefs, newOkrNodeRef]
          },
        },
      })
    },
  })
  const saveObjective = useCallback(
    async (input: AddOkrNodeInput) =>
      (await createOkrNode({ variables: { input } })).data?.addOkrNode[0],
    [createOkrNode],
  )

  const [createKeyResultsMutation] = useCreateKeyResultsMutation({
    refetchQueries: useOkrRefetchQueriesWithOkrModalInitQuery(),
    update: (cache, { data }) => {
      if (!data || !okrTermId) return

      cache.modify({
        fields: {
          okrNodes(existingOkrNodeRefs = []) {
            const cacheOkrNodes = cache.readQuery<OkrNodesQuery, OkrNodesQueryVariables>({
              query: OkrNodesDocument,
              variables: { okrTermId },
            })
            if (!cacheOkrNodes?.okrNodes) return existingOkrNodeRefs

            const updatedOkrNode = cacheOkrNodes.okrNodes.map((okrNode) => {
              if (okrNode.id === data.createKeyResults[0].node.id) {
                const updatedKeyResults = [...okrNode.keyResults, ...data.createKeyResults]
                return {
                  ...okrNode,
                  keyResult: updatedKeyResults,
                }
              }
              return okrNode
            })
            return [...existingOkrNodeRefs, ...updatedOkrNode]
          },
        },
      })
    },
  })
  const saveKeyResults = useCallback(
    async (inputs: ReadonlyArray<CreateKeyResultInput>) =>
      (await createKeyResultsMutation({ variables: { inputs } })).data?.createKeyResults[0].node.id,
    [createKeyResultsMutation],
  )

  const loadingKrFieldsData = useKeyResultEditFieldsFetch()

  if (!okrTermId || !currentUser || loadingKrFieldsData) {
    return null
  }

  return (
    <OkrCreateModal
      termId={okrTermId}
      currentUserId={currentUser.id}
      initialObjectiveState={initialObjectiveState}
      onClickSaveObjective={saveObjective}
      onClickSaveKeyResults={saveKeyResults}
      {...props}
    />
  )
}

export const OkrCreateModalContainer: typeof OkrCreateModalContainerInner = ({ ...props }) => (
  <InitQueryContextProvider>
    <OkrCreateModalContainerInner {...props} />
  </InitQueryContextProvider>
)
