import { css } from '@emotion/react'
import { useProfiler } from '@sentry/react'
import { Size, Colors } from 'basicprimitives'
import { Collapsible } from 'grommet'
import React, { Fragment, memo, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'

import {
  ObjectiveAvatar,
  KeyResultAvatarWithContributor,
} from '../../../components/domain/AvatarWithContributor'
import { KeyResultProgressRateBar } from '../../../components/domain/KeyResultProgressRateBar'
import { ProgressRateTooltip } from '../../../components/domain/ProgressRateTooltip'
import { OkrModalReturnType } from '../../../components/standalone/OkrModal/hooks/useOkrModal'
import { FloatDrawerIconButton } from '../../../components/ui/FloatDrawerIconButton'
import { Icon } from '../../../components/ui/Icon'
import { IntegrationTag } from '../../../components/ui/IntegrationTag'
import { Link } from '../../../components/ui/Link'
import { DefaultBarWidth, ProgressRate } from '../../../components/ui/Meter'
import { Props as TagProps, Tag } from '../../../components/ui/Tag'
import { Tooltip } from '../../../components/ui/Tooltip'
import { KrFilterContext } from '../../../contexts/KrFilterContext'
import { useTranslation } from '../../../i18n'
import { useClickOutside } from '../../../lib/clickOutside'
import { isEqual } from '../../../lib/collections/node'
import { formatDateInput } from '../../../lib/date'
import { isIncludedUser as isIncludedUserKeyResult } from '../../../lib/domain/keyResult/keyResult'
import { isIncludedUser as isIncludedUserObjective } from '../../../lib/domain/objective'
import { Screen } from '../../../lib/screen'
import { tracker } from '../../../lib/tracking'
import { border } from '../../../styles/border'
import { color, hex2rgba } from '../../../styles/newColors'
import { useOkrCardIsFoldLocal } from '../hooks/useOkrCardIsFoldLocal'
import { KrFoldButton } from '../ui/KrFoldButton'
import { TrendButton } from '../ui/TrendButton'

import { AddAnchor } from './AddAnchor'
import { SelectKeyResultModal } from './SelectKeyResultModal'
import { TargetSettingIcon } from './TargetSettingIcon'
import { KeyResult, Objective, OkrNode as OkrNodeType, OrgDiagramConfigItem } from './types'
import { SetCursorItem } from './useCenteringNode'
import { conversionMapPx } from './util'

const CONNECT_DOT_WIDTH = 40

const NODE_WIDTH = 524
const O_KR_HEIGHT = 64
const GROUPS_TAG_HEIGHT = 20
const GROUPS_HORIZONTAL_MARGIN = 20
const GROUPS_VERTICAL_MARGIN = 16
const GROUP_BETWEEN_MARGIN = 8
const GROUP_INTEGRATION_BETWEEN_MARGIN = 10
const INTEGRATION_TAG_HEIGHT = 20
const INTEGRATION_BETWEEN_MARGIN = 8

const OBJECTIVE_CLASS_NAME = 'map_card_objective'
const KEYRESULT_CLASS_NAME = 'map_card_keyresult'
const TAGS_CONTAINER_CLASS_NAME = 'map_card_tags_container'
const KEYRESULT_FILTER_CLASS_NAME = 'map_card_keyresult_filter'
const DATA_ATTR_CHILD_O_HOVER = 'data-child-o-hover'
const DATA_ATTR_PARENT_KR_HOVER = 'data-parent-kr-hover'
const DATA_ATTR_FILTER_KR = 'data-kr-filter'

export const generateObjectiveDomId = <T extends string>(id: T): `map_card_o_${T}` =>
  `map_card_o_${id}`
export const generateKeyResultDomId = <T extends string>(id: T): `map_card_kr_${T}` =>
  `map_card_kr_${id}`

export const generateCollapseTemplateName = <T extends string>(id: T): `${T}-collapse` =>
  `${id}-collapse`
export const generateExpandTemplateName = <T extends string>(id: T): `${T}-expand` => `${id}-expand`

export const highlightObjective = (id: string): boolean => {
  const e = document.getElementById(generateObjectiveDomId(id))
  if (e) {
    e.setAttribute(DATA_ATTR_PARENT_KR_HOVER, 'true')
  }
  return !!e
}

export const highlightKeyResult = (id: string): boolean => {
  const e = document.getElementById(generateKeyResultDomId(id))
  if (e) {
    e.setAttribute(DATA_ATTR_CHILD_O_HOVER, 'true')
  }
  return !!e
}

export const resetAllHighlights = (): void => {
  document
    .querySelectorAll(`.${OBJECTIVE_CLASS_NAME}[${DATA_ATTR_PARENT_KR_HOVER}="true"]`)
    .forEach((e) => {
      if (e instanceof HTMLElement) {
        e.setAttribute(DATA_ATTR_PARENT_KR_HOVER, 'false')
      }
    })
  document
    .querySelectorAll(`.${KEYRESULT_CLASS_NAME}[${DATA_ATTR_CHILD_O_HOVER}="true"]`)
    .forEach((e) => {
      if (e instanceof HTMLElement) {
        e.setAttribute(DATA_ATTR_CHILD_O_HOVER, 'false')
      }
    })
}

const getGroupTagsAreaHeight = (groupCount: number) =>
  Math.ceil(groupCount / 4) * GROUPS_TAG_HEIGHT +
  Math.max(Math.ceil(groupCount / 4) - 1, 0) * GROUP_BETWEEN_MARGIN // group間の縦方向のmargin

const getTagsAreaMarginsHeight = (existsGroup: boolean, existsIntegration: boolean) => {
  if (!existsGroup && !existsIntegration) return 0

  const areaMargin = GROUPS_VERTICAL_MARGIN * 2
  const betweenMargin = existsGroup && existsIntegration ? GROUP_INTEGRATION_BETWEEN_MARGIN : 0
  return areaMargin + betweenMargin
}

// レンダリング前にDOMのサイズを渡す必要があるため、事前にレンダリング時のDOMのサイズを計算する
export const getNodeSize = (node: OkrNodeType, isFoldOkrCardKr: boolean): Size => {
  const krHeight = isFoldOkrCardKr ? 0 : node.keyResults.length * O_KR_HEIGHT
  const groupHeight = getGroupTagsAreaHeight(node.groups.length)
  const integrationHeight =
    node.slackIntegrated || node.chatworkIntegrated || node.teamsIntegrated
      ? INTEGRATION_TAG_HEIGHT
      : 0
  const footerMargins = getTagsAreaMarginsHeight(!!groupHeight, !!integrationHeight)
  const tagsHeight = groupHeight + integrationHeight + footerMargins
  const height = O_KR_HEIGHT + krHeight + tagsHeight

  return { width: NODE_WIDTH, height }
}

const GroupTag: React.FC<TagProps> = (props) => (
  <Tag foregroundColor={color('text-bk-80')} backgroundColor={color('border-bk-10')} {...props} />
)

GroupTag.displayName = 'GroupTag'

const cardRootCss = css({
  position: 'absolute',
  left: 0,
  top: 0,
  width: NODE_WIDTH,
  boxSizing: 'border-box',
  paddingBottom: '20px',
})

const cardBodyCss = css({
  background: color('white-100'),
  outline: border('simple-30'),
  boxSizing: 'border-box',
  borderRadius: '12px',
  transition: 'background 0.2s ease-out',
  ':hover': {
    boxShadow: `0 4px 16px ${hex2rgba(color('text-bk-100'), 16)}`,
  },
  '&[data-selected="true"]': {
    outlineColor: color('resily-orange-100'),
    '&[data-filtering="true"][data-filter-target="false"]': {
      outlineColor: hex2rgba(color('resily-orange-100'), 70),
    },
  },
  '&[data-filtering="true"]': {
    '&[data-filter-target="true"]': {
      outlineWidth: 6,
      outlineColor: color('text-bk-80'),
      '&[data-selected="true"]': {
        outlineColor: color('resily-orange-100'),
      },
    },
    '&[data-filter-target="false"]': {
      background: color('background-bk-5'),
      // カード全体のopacityを変えると、背景が透過してしまう・列毎にopacityを変えられなくなるので個別に指定する
      [`${OBJECTIVE_CLASS_NAME}, ${KEYRESULT_CLASS_NAME}, ${TAGS_CONTAINER_CLASS_NAME}`]: {
        opacity: 0.7,
      },
      ':hover, &[data-callout="true"]': {
        background: color('white-100'),
        [`${OBJECTIVE_CLASS_NAME}, ${KEYRESULT_CLASS_NAME}, ${TAGS_CONTAINER_CLASS_NAME}`]: {
          opacity: 1,
        },
      },
    },
  },
})

const okrRowCss = css({
  height: O_KR_HEIGHT,
  display: 'flex',
  alignItems: 'center',
  ':hover': {
    cursor: 'pointer',
    background: color('hover-background-bk-10'),
    boxShadow: '0px 1px 0px 0px #E9EAEC',
  },
})

const oRowCss = css(okrRowCss, {
  borderRadius: '12px 12px 0 0',
  transition: 'background 0.2s ease-out, border-radius 0.2s ease-out',
  '&[data-is-alone="true"]': { borderRadius: '12px' },
  [`&[${DATA_ATTR_PARENT_KR_HOVER}="true"], &[${DATA_ATTR_FILTER_KR}="true"]`]: {
    background: color('white-100'),
    outline: `3px solid ${color('resily-orange-100')}`,
    outlineOffset: '-2px',
    opacity: 1,
    ':hover': {
      background: color('hover-background-bk-10'),
    },
  },
  '& > *:nth-child(1)': {
    flex: '0 0 40px',
  },
  '& > *:nth-child(2)': {
    flex: '0 0 52px',
  },
  '& > *:nth-child(3)': {
    flex: '1 1 272px',
    marginRight: '12px',
  },
  '& > *:nth-child(4)': {
    flex: `0 0 ${DefaultBarWidth}px`,
  },
  '& > *:nth-child(5)': {
    flex: '0 0 64px',
    paddingLeft: '4px',
  },
})

const krRowCss = css(okrRowCss, {
  paddingLeft: '40px',
  transition: 'background 0.2s ease-out',
  borderTop: border('simple-10'),
  '&[data-is-tail="false"]': {
    borderRadius: '0 0 12px 12px',
  },
  [`&[${DATA_ATTR_CHILD_O_HOVER}="true"], &[${DATA_ATTR_FILTER_KR}="true"]`]: {
    background: color('white-100'),
    outline: `3px solid ${color('resily-orange-100')}`,
    outlineOffset: '-2px',
    opacity: 1,
    ':hover': {
      background: color('hover-background-bk-10'),
    },
    [`& > .${KEYRESULT_FILTER_CLASS_NAME}`]: {
      visibility: 'visible',
    },
    [`[${DATA_ATTR_FILTER_KR}="false"]`]: {
      display: 'block',
    },
  },
  '& > *:nth-child(1)': {
    flex: '0 0 52px',
  },
  '& > *:nth-child(2)': {
    flex: '1 1 272px',
    marginRight: '12px',
  },
  '& > *:nth-child(3)': {
    flex: `0 0 ${DefaultBarWidth}px`,
  },
  '& > *:nth-child(4)': {
    flex: '0 0 64px',
  },
})

const krTitleCss = css({
  // stylelint-disable-next-line
  display: '-webkit-box',
  overflow: 'hidden',
  color: color('text-bk-100'),
  width: '272px',
  fontSize: '14px',
  lineHeight: '20px',
  wordBreak: 'break-all',
  wordWrap: 'break-word',
  // stylelint-disable-next-line
  WebkitLineClamp: 2,
  // stylelint-disable-next-line
  WebkitBoxOrient: 'vertical',
  '&[data-disabled="true"]': {
    textDecoration: 'line-through',
    opacity: 0.5,
  },
  '&[data-selected="true"]': {
    color: color('resily-orange-100'),
    textDecoration: `underline ${color('resily-orange-100')}`,
  },
})

const oTitleCss = css(krTitleCss, { fontWeight: 700, lineHeight: '22px' })

const oProgressRateCss = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '4px',
})

const progressRateUpdatedDateCss = css({ color: color('text-bk-50'), fontSize: '10px' })

const tagsContainerCss = css({
  borderTop: border('simple-10'),
  padding: `${GROUPS_VERTICAL_MARGIN}px ${GROUPS_HORIZONTAL_MARGIN}px`,
  display: 'flex',
  flexDirection: 'column',
  gap: GROUP_INTEGRATION_BETWEEN_MARGIN,
})

const groupCss = css({
  display: 'inline-block',
  marginRight: GROUP_BETWEEN_MARGIN,
  marginBottom: GROUP_BETWEEN_MARGIN,
})

const groupNameCss = css({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  maxWidth: 480,
  width: 'auto',
})

const integrationCss = css({
  display: 'inline',
  marginRight: INTEGRATION_BETWEEN_MARGIN,
})

const hoverMenuCss = css({
  position: 'absolute',
  top: -43,
  width: '100%',
  background: 'transparent',
})

const hoverMenuContainerCss = css({
  display: 'inline-flex',
  alignItems: 'center',
  background: color('white-100'),
  border: border('simple-30'),
  borderRadius: 4,
  marginBottom: '11px',
})

const okrPageLinkCss = css({
  height: '32px',
  padding: '9px 12px',
  display: 'flex',
  alignItems: 'center',
})

export type Props = JSX.IntrinsicElements['div'] & {
  node: OrgDiagramConfigItem
  userId: string | null | undefined
  isCallout: boolean // フィルタ中かつフィルタ対象じゃない場合の効果がcallout見づらい対策
  isFiltering: boolean
  isFilterTarget: boolean
  isSelected: boolean
  isSelectedObjective: boolean
  selectedKeyResultId: string | false
  relationLineColor: string
  okrPageUrl: string
  onAddChildNode: (parentNodeId: string) => void
  onAddChildNodeConnectKR: (parentNodeId: string, _parentKeyResultId: string) => void
  onClickOKR: SetCursorItem
  onObjectiveEnter: (o: Objective) => void
  onObjectiveLeave: (o: Objective) => void
  onKeyResultEnter: (kr: KeyResult) => void
  onKeyResultLeave: (kr: KeyResult) => void
  onKeyResultFilterClick: (kr: KeyResult) => void
  onOkrFloatDrawerOpen: (objectiveId: string) => void
  openOkrModal: OkrModalReturnType['openOkrModal']
  openOkrModalWithKeyResultDrawer: OkrModalReturnType['openOkrModalWithKeyResultDrawer']
}

export const OkrNode: React.VFC<Props> = memo<Props>(
  ({
    node,
    userId,
    isCallout,
    isFiltering,
    isFilterTarget,
    isSelected,
    isSelectedObjective,
    selectedKeyResultId,
    relationLineColor = Colors.Silver,
    okrPageUrl,
    onClickOKR,
    onAddChildNode,
    onAddChildNodeConnectKR,
    onObjectiveEnter,
    onObjectiveLeave,
    onKeyResultEnter,
    onKeyResultLeave,
    onKeyResultFilterClick,
    onOkrFloatDrawerOpen,
    openOkrModal,
    openOkrModalWithKeyResultDrawer,
    ...props
  }) => {
    useProfiler('OkrNode')
    const { t } = useTranslation()
    const [showSelectKrModal, setShowSelectKrModal] = useState(false)

    const [isFoldOkrCardKrLocal, setIsFoldOkrCardKrLocal] = useOkrCardIsFoldLocal(node.id)

    useEffect(() => {
      // 一括折り畳み時にcalloutの吹き出しが折り畳み前の最大サイズ前提だとはみ出る問題の対策
      node.calloutTemplateName = isFoldOkrCardKrLocal
        ? generateCollapseTemplateName(node.id)
        : generateExpandTemplateName(node.id)
    }, [isFoldOkrCardKrLocal, node])

    const okrNodeRef = useRef<HTMLElement>(null)
    const okrNodeCardRef = useRef<HTMLElement>(null)
    const hoverMenuRef = useRef<HTMLElement>(null)
    const objectiveRef = useRef<HTMLDivElement>(null)
    const selectKrRef = useRef<HTMLDivElement>(null)
    const anchorAreaRef = useRef<HTMLDivElement>(null)

    const { hasObjective, hasKeyResult } = useContext(KrFilterContext)

    useClickOutside({
      callback: () => setShowSelectKrModal(false),
      ref: selectKrRef,
    })

    useEffect(() => {
      if (isCallout || !okrNodeRef.current) {
        return () => {}
      }

      const { current: n } = okrNodeRef

      if (n.parentElement) {
        // マップ画面でフォントがおかしい問題の対策
        // OrgDiagramが各Nodeの親にfont-familyを自前で定義していて詳細度がglobal styleを上回っているので潰す
        n.parentElement.style.fontFamily = ''
      }

      const enter = () => {
        if (hoverMenuRef.current) {
          hoverMenuRef.current.style.visibility = 'visible'
        }
      }
      const leave = () => {
        if (hoverMenuRef.current) {
          hoverMenuRef.current.style.visibility = 'collapse'
        }
      }
      n.addEventListener('mouseenter', enter)
      n.addEventListener('mouseleave', leave)

      return () => {
        n.removeEventListener('mouseenter', enter)
        n.removeEventListener('mouseleave', leave)
      }
    }, [isCallout, node, okrNodeRef])

    const isAssignedToObjective = isIncludedUserObjective(
      node.objective,
      userId ? { id: userId } : null,
    )

    const onClickObjective = () => {
      onClickOKR(node.id)
      openOkrModal(node.objective.id, Screen.OkrMapTree, isAssignedToObjective)
    }

    const generateOnClickKR = (kr: KeyResult) => () => {
      onClickOKR(node.id)
      openOkrModalWithKeyResultDrawer(
        node.objective.id,
        kr.id,
        Screen.OkrMapTree,
        isIncludedUserKeyResult(kr, userId ? { id: userId } : null),
      )
    }

    /**
     * OKRカードホバー時
     */
    const handleMoveEnterOkrCard = useCallback(() => {
      if (okrNodeCardRef.current) {
        okrNodeCardRef.current.style.boxShadow = `0 4px 16px ${hex2rgba(color('text-bk-100'), 16)}`
      }
      // +アイコン表示
      if (anchorAreaRef.current) {
        anchorAreaRef.current.style.display = 'flex'
      }
    }, [])

    /**
     * OKRカードホバー離脱時
     */
    const handleMoveLeaveOkrCard = useCallback(() => {
      if (okrNodeCardRef.current) {
        okrNodeCardRef.current.style.boxShadow = ''
      }
      // +アイコン非表示
      if (anchorAreaRef.current) {
        anchorAreaRef.current.style.display = 'none'
      }
    }, [])

    /**
     * 「KRに紐づけて下位OKRを作成する」ポップアップモーダルを開く
     */
    const handleOpenSelectKrModal = useCallback(() => setShowSelectKrModal(true), [])

    const existsKrs = node.keyResults.length > 0
    const existsGroup = node.groups.length > 0
    const existsIntegration =
      node.slackIntegrated || node.chatworkIntegrated || node.teamsIntegrated

    return (
      <article
        ref={okrNodeRef}
        css={cardRootCss}
        onMouseEnter={handleMoveEnterOkrCard}
        onMouseLeave={handleMoveLeaveOkrCard}
        {...props}
      >
        {/* OKR card */}
        <section
          ref={okrNodeCardRef}
          css={cardBodyCss}
          data-callout={isCallout}
          data-selected={isSelected}
          data-filtering={isFiltering}
          data-filter-target={isFilterTarget}
        >
          {/* Objective */}
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <div
            ref={objectiveRef}
            id={generateObjectiveDomId(node.objective.id)}
            className={OBJECTIVE_CLASS_NAME}
            css={oRowCss}
            data-kr-filter={hasObjective(node.objective.id)}
            data-is-alone={
              (!existsKrs || isFoldOkrCardKrLocal) && !existsGroup && !existsIntegration
            }
            onMouseEnter={isCallout ? undefined : () => onObjectiveEnter(node.objective)}
            onMouseLeave={isCallout ? undefined : () => onObjectiveLeave(node.objective)}
            onClick={isCallout ? undefined : onClickObjective}
          >
            <KrFoldButton
              isFold={isFoldOkrCardKrLocal}
              onClick={(e) => {
                e.stopPropagation()
                setIsFoldOkrCardKrLocal(!isFoldOkrCardKrLocal)
              }}
            />
            <ObjectiveAvatar
              termId={node.term.id}
              owner={node.objective.owner}
              size="okr-map-tree-page"
            />
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link
              as="h1"
              css={oTitleCss}
              data-disabled={node.objective.isDisabled}
              data-selected={isSelectedObjective}
            >
              {node.objective.name}
            </Link>
            <div css={oProgressRateCss}>
              <ProgressRate
                color={color('objective-blue-100')}
                rate={node.objective.progressRate || 0}
              />
              <span css={progressRateUpdatedDateCss}>
                {node.objective.progressRateUpdatedAt
                  ? `${formatDateInput(node.objective.progressRateUpdatedAt, 'YYYY/MM/DD')} ${t(
                      'UPDATION',
                    )}`
                  : '-'}
              </span>
            </div>
            <TrendButton
              progressRateTrend={node.objective.progressRateTrend}
              upConfidenceScoreNum={node.keyResults.reduce(
                (sum, kr) => sum + Number(kr.confidenceTrend > 0),
                0,
              )}
              downConfidenceScoreNum={node.keyResults.reduce(
                (sum, kr) => sum + Number(kr.confidenceTrend < 0),
                0,
              )}
              onClick={(e) => {
                e.stopPropagation()
                openOkrModal(
                  node.objective.id,
                  Screen.OkrMapTree,
                  isAssignedToObjective,
                  'm-o-activity',
                )
              }}
            />
          </div>

          {/* KeyResults */}
          <Collapsible open={existsKrs && !isFoldOkrCardKrLocal}>
            <ul>
              {node.keyResults.map((kr, i) => (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
                <li
                  key={kr.id}
                  id={generateKeyResultDomId(kr.id)}
                  className={KEYRESULT_CLASS_NAME}
                  css={krRowCss}
                  data-kr-filter={hasKeyResult(kr.id)}
                  data-is-tail={
                    i === node.keyResults.length - 1 ? existsGroup || existsIntegration : undefined
                  }
                  onMouseEnter={isCallout ? undefined : () => onKeyResultEnter(kr)}
                  onMouseLeave={isCallout ? undefined : () => onKeyResultLeave(kr)}
                  onClick={isCallout ? undefined : generateOnClickKR(kr)}
                >
                  <KeyResultAvatarWithContributor
                    termId={node.term.id}
                    owner={kr.owner}
                    contributors={kr.members}
                    size="okr-map-tree-page"
                    keyResult={kr}
                  />
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <Link
                    as="h2"
                    css={krTitleCss}
                    data-disabled={kr.isDisabled}
                    data-selected={selectedKeyResultId === kr.id}
                  >
                    <TargetSettingIcon type={kr.targetSetting} />
                    {kr.name}
                  </Link>
                  <ProgressRateTooltip keyResult={kr}>
                    {/* confidenceTrend を渡すとマップ上のKRの進捗率の下にもトレンド表記が出現するので undefined を渡す */}
                    <KeyResultProgressRateBar keyResult={{ ...kr, confidenceTrend: undefined }} />
                  </ProgressRateTooltip>
                  <button
                    type="button"
                    className={KEYRESULT_FILTER_CLASS_NAME}
                    css={{
                      height: '100%',
                      'path[fill]': {
                        fill: color('text-bk-30'),
                      },
                      'path[stroke]': {
                        stroke: color('text-bk-30'),
                      },
                      '&:hover path[stroke]': {
                        stroke: color('resily-orange-100'),
                      },
                    }}
                    onClick={(e) => {
                      e.stopPropagation()
                      onKeyResultFilterClick(kr)
                    }}
                  >
                    <Tooltip
                      title={t('KR_FILTER_ON')}
                      css={{ display: hasKeyResult(kr.id) ? 'block' : 'none' }}
                    >
                      <Icon
                        type="filterOn"
                        css={{
                          'path[stroke]': {
                            stroke: color('resily-orange-100'),
                          },
                          'path[fill]': {
                            fill: color('resily-orange-100'),
                          },
                        }}
                      />
                    </Tooltip>
                    <Tooltip
                      data-kr-filter={hasKeyResult(kr.id)}
                      title={t('KR_FILTER_OFF')}
                      css={{ display: 'none' }}
                    >
                      <Icon type="filterOff" />
                    </Tooltip>
                  </button>
                </li>
              ))}
            </ul>
          </Collapsible>

          {existsGroup || existsIntegration ? (
            <div className={TAGS_CONTAINER_CLASS_NAME} css={tagsContainerCss}>
              {/* Groups */}
              {existsGroup && (
                <ul>
                  {node.groups.map((group) => (
                    <li key={group.id} css={groupCss}>
                      <GroupTag>
                        <p css={groupNameCss}>{group.name}</p>
                      </GroupTag>
                    </li>
                  ))}
                </ul>
              )}
              {/* Integrations */}
              {existsIntegration && (
                <ul>
                  {node.slackIntegrated && (
                    <li css={integrationCss}>
                      <IntegrationTag service="slack" />
                    </li>
                  )}
                  {node.chatworkIntegrated && (
                    <li css={integrationCss}>
                      <IntegrationTag service="chatwork" />
                    </li>
                  )}
                  {node.teamsIntegrated && (
                    <li css={integrationCss}>
                      <IntegrationTag service="teams" />
                    </li>
                  )}
                </ul>
              )}
            </div>
          ) : null}
        </section>
        {!isCallout && (
          <Fragment>
            {/* hover menu */}
            <nav ref={hoverMenuRef} css={hoverMenuCss} style={{ visibility: 'collapse' }}>
              <div css={hoverMenuContainerCss}>
                <Link
                  href={okrPageUrl}
                  method="newTab"
                  label={t('X_PAGE', { x: t('OKR') })}
                  css={okrPageLinkCss}
                  onClick={() =>
                    tracker.OkrMapClickOkrPage(
                      Screen.OkrMapTree,
                      isAssignedToObjective,
                      node.objective.useWeighting,
                    )
                  }
                />
                <FloatDrawerIconButton
                  css={{
                    borderLeft: border('simple-30'),
                    padding: '11px 12px',
                  }}
                  onClick={() => onOkrFloatDrawerOpen(node.objective.id)}
                />
              </div>
            </nav>

            {/* bottom anchor */}
            <div
              css={{
                position: 'absolute',
                bottom: '-26px',
                left: '0',
                right: '0',
                margin: '0 auto',
                height: '40px',
                width: `${CONNECT_DOT_WIDTH}px`,
              }}
            />
            <div
              ref={anchorAreaRef}
              css={{
                display: 'none',
                position: 'absolute',
                bottom: '-26px',
                left: '0',
                right: '0',
                margin: '0 auto',
                height: '40px',
                justifyContent: 'center',
                width: `${CONNECT_DOT_WIDTH}px`,
                zIndex: 1,
              }}
            >
              <AddAnchor onClick={handleOpenSelectKrModal} />
            </div>

            {/* KRに紐付けて下位OKRを作成するモーダル */}
            {showSelectKrModal &&
              createPortal(
                <article
                  ref={selectKrRef}
                  css={{
                    position: 'absolute',
                    right: 0,
                    left: 0,
                    margin: `${conversionMapPx(8)} auto 0`,
                    width: `${conversionMapPx(300)}`,
                    zIndex: 11,
                  }}
                >
                  <SelectKeyResultModal
                    nodeId={node.id}
                    keyResults={node.keyResults}
                    onAddChildNode={onAddChildNode}
                    onAddChildNodeConnectKR={onAddChildNodeConnectKR}
                  />
                </article>,
                okrNodeRef.current || document.body,
              )}

            {/* KRを閉じると下に余白ができて線が途切れるのでカードの裏に自前で線を書く */}
            {node.childNodeIds.length ? (
              <svg
                style={{
                  zIndex: -1,
                  position: 'absolute',
                  // stylelint-disable-next-line
                  top: getNodeSize(node, true).height,
                  left: NODE_WIDTH / 2,
                  width: 1,
                  height: node.keyResults.length * O_KR_HEIGHT + 10,
                  filter: 'drop-shadow(0 0 0.25px gray) blur(0.25px)',
                }}
              >
                <line y2={node.keyResults.length * O_KR_HEIGHT + 10} stroke={relationLineColor} />
              </svg>
            ) : null}
          </Fragment>
        )}
      </article>
    )
  },
  (prevProps, nextProps) => {
    if (prevProps.isCallout !== nextProps.isCallout) {
      return false
    }
    if (prevProps.isFiltering !== nextProps.isFiltering) {
      return false
    }
    if (prevProps.isFilterTarget !== nextProps.isFilterTarget) {
      return false
    }
    if (prevProps.isSelected !== nextProps.isSelected) {
      return false
    }
    if (prevProps.isSelectedObjective !== nextProps.isSelectedObjective) {
      return false
    }
    if (prevProps.selectedKeyResultId !== nextProps.selectedKeyResultId) {
      return false
    }
    if (prevProps.relationLineColor !== nextProps.relationLineColor) {
      return false
    }
    if (prevProps.okrPageUrl !== nextProps.okrPageUrl) {
      return false
    }
    if (prevProps.userId !== nextProps.userId) {
      return false
    }
    if (!isEqual(prevProps.node, nextProps.node)) {
      return false
    }
    if (prevProps.onAddChildNode !== nextProps.onAddChildNode) {
      return false
    }
    if (prevProps.onAddChildNodeConnectKR !== nextProps.onAddChildNodeConnectKR) {
      return false
    }
    return true
  },
)
OkrNode.displayName = 'OkrNode'
