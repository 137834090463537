import { KeyResultAreaTable, Props as KeyResultAreaTableProps } from './KeyResultAreaTable'
import { ReOrderingKeyResultMutationVariables } from './graphql'
import { useFilterKeyResults } from './hooks/useFilterKeyResults'
import { useReOrderingKeyResult } from './hooks/useReOrderingKeyResult'

export type Props = Omit<KeyResultAreaTableProps, 'updateKeyResultDisplayOrder'> & {
  okrNodeId: ReOrderingKeyResultMutationVariables['nodeID'] | null | undefined
}

export const KeyResultAreaTableContainer: React.VFC<Props> = ({
  termId,
  keyResults,
  okrNodeId,
  onClickKeyResultName,
  openKeyResultCreateModal,
}) => {
  const filteredKeyResults = useFilterKeyResults(keyResults)

  const { orderedKeyResults, handleReOrderingKeyResult } = useReOrderingKeyResult(
    okrNodeId,
    filteredKeyResults,
  )

  return (
    <KeyResultAreaTable
      termId={termId}
      keyResults={orderedKeyResults}
      onClickKeyResultName={onClickKeyResultName}
      openKeyResultCreateModal={openKeyResultCreateModal}
      updateKeyResultDisplayOrder={handleReOrderingKeyResult}
    />
  )
}
KeyResultAreaTableContainer.displayName = 'KeyResultAreaTableContainer'
