import { css } from '@emotion/react'
import { useMemo } from 'react'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useStyles = () =>
  useMemo(
    () =>
      ({
        root: css({
          display: 'flex',
          flexWrap: 'wrap',
          listStyle: 'none',
          maxHeight: '100%',
          maxWidth: '100%',
          gap: 8,
        }),
        detector: css({
          display: 'inline-flex',
          alignItems: 'center',
          '> *': { display: 'inline-flex' },
        }),
      } as const),
    [],
  )
