import { forwardRef, PropsWithoutRef } from 'react'

import * as colors from '../../../styles/colors'

export type Props = PropsWithoutRef<JSX.IntrinsicElements['button']> & {
  color?: 'main' | 'sub' | 'warning'
  size?: 'm'
}

export const Button = forwardRef<HTMLButtonElement, Props>(
  ({ color = 'main', size = 'm', type = 'button', ...props }, ref) => {
    const colorStyle = () => {
      switch (color) {
        case 'main':
          return {
            color: colors.buttonTextMain,
            backgroundColor: colors.buttonFillMain,
          }
        case 'sub':
          return {
            color: colors.buttonTextSub,
            backgroundColor: colors.buttonFillSub,
          }
        case 'warning':
          return {
            color: colors.buttonTextWarning,
            backgroundColor: colors.buttonFillWarning,
          }
        default:
          return null
      }
    }

    return (
      <button
        ref={ref}
        // eslint-disable-next-line react/button-has-type
        type={type}
        css={[
          {
            verticalAlign: 'bottom',
            whiteSpace: 'nowrap',
            fontWeight: 'bold',
            fontSize: 14,
            outline: 'none',
            transition: 'filter 0.1s ease-in',
            '&:hover:enabled, &:focus:enabled': {
              filter: 'brightness(1.2)',
            },
            boxSizing: 'border-box',
            borderRadius: 4,
            '&:disabled': {
              color: colors.inactiveText,
              backgroundColor: colors.inactiveFill,
            },
          },
          size === 'm'
            ? {
                height: 48,
                lineHeight: '48px',
                paddingLeft: 32,
                paddingRight: 32,
              }
            : null,
          colorStyle(),
        ]}
        {...props}
      />
    )
  },
)

Button.displayName = 'ButtonDeprecated'
