import { useTranslation } from '../../../../../i18n'
import { Icon } from '../../../../ui/Icon'

import { useStyles } from './Activities.styles'
import { ActivityCard } from './ActivityCard'
import { EmptyView } from './EmptyView'
import { ActivityCardFragment } from './graphql'

export type ActivitiesProps = {
  loading: boolean
  termId: string
  activities: ReadonlyArray<ActivityCardFragment>
  showTitle?: boolean
  showMore?: boolean
  onClickMore: () => void
}

// Presentational
export const Activities: React.VFC<ActivitiesProps> = ({
  loading,
  termId,
  activities,
  showTitle = false,
  showMore,
  onClickMore,
}) => {
  const { t } = useTranslation()
  const styles = useStyles()

  if (loading) {
    return (
      <>
        {new Array(3).fill(undefined).map(() => (
          <ActivityCard.Skeleton />
        ))}
      </>
    )
  }

  if (activities.length === 0) {
    return <EmptyView />
  }

  return (
    <>
      {activities.map((activity) => (
        <ActivityCard key={activity.id} termId={termId} activity={activity} showTitle={showTitle} />
      ))}
      {showMore && (
        <button type="button" onClick={onClickMore} css={styles.showMoreButton}>
          {t('SHOW_MORE')}
          <Icon type="keyboardArrowDown" />
        </button>
      )}
    </>
  )
}
