import { useTranslation } from '../../../i18n'
import { fontSize } from '../../../styles/font'
import { color } from '../../../styles/newColors'

export const OkrIcon: React.FC = () => {
  const { t } = useTranslation()

  const size = fontSize('small').fontSize

  return (
    <div
      css={{
        display: 'inline',
        padding: '0px 2px',
        height: '14px',
        lineHeight: '14px',
        fontSize: size,
        borderRadius: '2px',
        backgroundColor: color('objective-blue-100'),
        color: color('white-100'),
      }}
    >
      {t('OKR')}
    </div>
  )
}

OkrIcon.displayName = 'OkrIcon'
