import { useContext, VFC } from 'react'

import { AlertModalContext } from '../../../contexts/AlertModalContext'
import { useTranslation } from '../../../i18n'
import { Modal } from '../../ui/Modal'
import { StyledText } from '../../ui/StyledText'

import { KeyResultWeightSettingAlertModal } from './KeyResultWeightSettingAlertModal'

export type Props = {
  onClickDelete: () => void
  onClickCancel: () => void
}

const fail = (message: never): never => {
  throw new Error(message)
}

export const AlertModal: React.VFC = () => {
  const { status, changeEditingType } = useContext(AlertModalContext)

  if (!status.isOpen) {
    return null
  }

  if (status.type === 'WEIGHT') {
    return (
      <KeyResultWeightSettingAlertModal
        onClickDiscard={status.onClickDiscard}
        onClickCancel={() => changeEditingType('WEIGHT')}
      />
    )
  }

  if (status.type === 'DESCRIPTION') {
    return (
      <EditDescriptionAlertModal
        onClickDelete={status.onClickDiscard}
        onClickCancel={() => changeEditingType('DESCRIPTION')}
      />
    )
  }

  return fail(status.type)
}

AlertModal.displayName = 'AlertModal'

const EditDescriptionAlertModal: VFC<Props> = ({ onClickDelete, onClickCancel }) => {
  const { t } = useTranslation()

  return (
    <Modal
      isOpened
      title={t('X_EDITING_DRAWER_DESCRIPTION_DISCARD', { x: t('CHANGE') })}
      executeText={t('DISCARD')}
      cancelText={t('BACK_TO_PAGE', { page: t('EDITION') })}
      onClickExecute={onClickDelete}
      onClickCancel={onClickCancel}
      onClickOutside={onClickCancel}
    >
      <StyledText>
        {t('X_EDITING_CONFIRMATION_OF_DISCARD', { x: t('OBJECTIVE_BACKGROUND') })}
        {t('WARNING_DISCARD')}
      </StyledText>
    </Modal>
  )
}

EditDescriptionAlertModal.displayName = 'EditDescriptionAlertModal'
