import { OkrTitle } from '@resily/geisha'

import { convertCheckInput } from '../../../../lib/convertCheckInput'
import { convertFullNumberToHalf } from '../../../../lib/convertFullNumberToHalf'
import { fontSize } from '../../../../styles/font'
import { KeyResultAvatarWithContributor } from '../../../domain/AvatarWithContributor'
import { KeyResultProgressRateFormInput } from '../../../domain/KeyResultProgressRateFormInput'
import { KeyResultAreaKeyResultFragment } from '../graphql'

import { useStyles } from './WeightSettingKeyResultList.styles'

export type KeyResult = Pick<
  KeyResultAreaKeyResultFragment,
  'id' | 'name' | 'weight' | 'owner' | 'members' | 'isDisabled'
>

type HasWeightKeyResult = Omit<KeyResult, 'weight'> & {
  readonly weight: NonNullable<KeyResult['weight']>
}

export type Props = {
  keyResults: ReadonlyArray<HasWeightKeyResult>
  onWeightChange: (id: string, weight: number) => void
}

export const WeightSettingKeyResultList: React.VFC<Props> = ({ keyResults, onWeightChange }) => {
  const styles = useStyles()

  return (
    <ul css={styles.root}>
      {keyResults.map((kr) => (
        <li key={kr.id}>
          <div css={styles.krNameArea}>
            <KeyResultAvatarWithContributor
              popoverDisabled
              size="small"
              avatarLinkMethod="newTab"
              owner={kr.owner}
              contributors={kr.members}
            />
            <OkrTitle
              withBorder
              type="keyResult"
              title={kr.name}
              fontWeight="regular"
              size="medium"
            />
          </div>
          <div css={styles.input}>
            {/* HACK: ↓このdivがないとスタイルが崩れる */}
            <div>
              <KeyResultProgressRateFormInput
                inputSize="s"
                allowDecimal={false}
                defaultValue={kr.weight}
                onChange={(e) => {
                  const convertValue = convertFullNumberToHalf(e.target.value)
                  onWeightChange(
                    kr.id,
                    convertCheckInput(convertValue, true) ? Number(convertValue) : kr.weight,
                  )
                }}
              />
            </div>
            <span css={fontSize('small')}>%</span>
          </div>
        </li>
      ))}
    </ul>
  )
}
WeightSettingKeyResultList.displayName = 'WeightSettingKeyResultList'
