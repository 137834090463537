import { css } from '@emotion/react'
import { useMemo } from 'react'

import { color } from '../../../styles/newColors'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useStyles = () =>
  useMemo(
    () =>
      ({
        keyResultList: css({
          listStyle: 'none',
          '> li:not(:first-of-type)': {
            borderTop: `1px solid ${color('border-bk-30')}`,
          },
        }),
        deleteKeyResultButtonArea: css({ textAlign: 'right' }),
        deleteKeyResultButton: css({
          display: 'inline',
          position: 'relative',
          bottom: -12,
        }),
      } as const),
    [],
  )
