import { useProfiler } from '@sentry/react'
import { Box, BoxTypes, Main, Text } from 'grommet'
import { useEffect, useState, useMemo, Fragment, useRef, useCallback } from 'react'
import { matchPath, useLocation } from 'react-router-dom'

import { ActionPlanListCard } from '../../components/domain/ActionPlanListCard'
import { ObjectiveAvatar } from '../../components/domain/AvatarWithContributor'
import { Breadcrumb } from '../../components/domain/Breadcrumb'
import { OkrFloatDrawerList } from '../../components/domain/OkrFloatDrawerList'
import { OkrPageCheckinMeetingCard } from '../../components/domain/OkrPageCheckinMeetingCard'
import { OkrPageCheckinSummaryListCard } from '../../components/domain/OkrPageCheckinSummaryListCard'
import { ProgressChart } from '../../components/domain/ProgressChart'
import { MAIN_CONTENT_ID } from '../../components/layout/GloNavAndMain'
import { SIDE_NAV_ID } from '../../components/layout/GloNavAndMain/SideNav'
import { Section } from '../../components/layout/Section'
import { PageContent } from '../../components/pageContent'
import { useOkrModal } from '../../components/standalone/OkrModal'
import { Avatar } from '../../components/ui/Avatar'
import { TitleLink } from '../../components/ui/DataTable/TitleLink'
import { Link } from '../../components/ui/Link'
import { ProgressRate } from '../../components/ui/Meter'
import { StyledText } from '../../components/ui/StyledText'
import {
  useGetFloatDrawerItems,
  useSetFloatDrawerItems,
} from '../../contexts/OkrFloatDrawerContext'
import { useTranslation } from '../../i18n'
import { isIncludedUser } from '../../lib/domain/keyResult/keyResult'
import { isIncludedUser as isIncludedUserObjective } from '../../lib/domain/objective'
import { useBlocker } from '../../lib/prompt'
import { Screen } from '../../lib/screen'
import { scrollHash } from '../../lib/scrollHash'
import { useTracking } from '../../lib/tracking'
import { color } from '../../styles/newColors'
import {
  CHECKIN_SUMMARY_HASH,
  generateObjectiveHash,
  generateHome,
  matchObjectiveDomId,
  okr as okrPath,
  OKR_MEETING_HASH,
} from '../../urls'

import { DraggableKeyResultsTable } from './DraggableKeyResultsTable'
import { OkrNoteListView } from './OkrNoteList'
import {
  CheckinSummaryFragment,
  FindUserFragment,
  NodeFragment,
  ReOrderingKeyResultMutationVariables,
  UpdateActionPlanMutationVariables,
  UserFragment,
  DocumentInput,
  KeyResultFragment,
  KeyResultSharedItemType,
} from './graphql'

export type Props = BoxTypes & {
  isPrimary: boolean
  node: NodeFragment
  user: FindUserFragment
  checkinSummary: CheckinSummaryFragment | undefined
  reOrder: (val: Omit<ReOrderingKeyResultMutationVariables, 'nodeID'>) => void
  createNote: () => void
  deleteNote: (noteId: string) => void
  checkinSummaries: Array<CheckinSummaryFragment>
  fetchCheckinSummaries: () => void
  updateKeyResultSharedItem: (
    checkinKeyResultId: string,
    itemType: KeyResultSharedItemType,
    body: DocumentInput,
  ) => void
  updateActionPlan: (value: UpdateActionPlanMutationVariables) => void
  onClickSave: (treeJson: string, plainText: string) => void
}

export const Okr: React.FC<Props> = ({
  isPrimary,
  node,
  user,
  checkinSummary,
  reOrder,
  createNote,
  deleteNote,
  checkinSummaries,
  fetchCheckinSummaries,
  updateKeyResultSharedItem,
  updateActionPlan,
  onClickSave,
}) => {
  useProfiler('Okr')
  const { t } = useTranslation()
  useTracking(
    t('OKR_STATUS_PAGE_TITLE', {
      objectiveName: node.objective?.name ? node.objective.name : '',
    }),
    Screen.Okr,
  )

  const { OkrModal, openOkrModal, openOkrModalWithKeyResultDrawer } = useOkrModal()

  const keyResults = useMemo(
    () => node.keyResults.filter(({ isDisabled }) => !isDisabled),
    [node.keyResults],
  )
  const notes = useMemo(() => node.notes, [node.notes])

  useEffect(() => {
    scrollHash({
      scrollContentId: MAIN_CONTENT_ID,
      matchDomId: matchObjectiveDomId,
      top: -48,
    })
  }, [])

  const okrMeetingRef = useRef<HTMLDivElement>(null)
  const summariesRef = useRef<HTMLDivElement>(null)
  const location = useLocation()
  useEffect(() => {
    if (checkinSummary && location.hash === OKR_MEETING_HASH) {
      okrMeetingRef.current?.scrollIntoView()
    }
    if (location.hash === CHECKIN_SUMMARY_HASH) {
      summariesRef.current?.scrollIntoView()
    }
  }, [checkinSummary, okrMeetingRef, summariesRef, location.hash])

  const isAssignedToObjective = isIncludedUserObjective(node.objective, user)

  // 有効なKRから以下を全て取得し、IDの重複を排除、その後で無効なユーザーを後方に表示する
  // 1. Objectiveのowner
  // 2. Key Resultのowner, member
  // 3. 無効owner, member
  const allMembers = node.keyResults
    .map((kr) => kr.members.concat([kr.owner]))
    .flat()
    .filter((value, index, array) => array.findIndex((target) => target.id === value.id) === index)
    .sort((a, b) => (a.lastName > b.lastName ? 1 : -1))
  const members = allMembers
    .filter((m) => !m.isDisabled)
    .concat(allMembers.filter((m) => m.isDisabled))

  const actionPlans = keyResults.flatMap((keyResult) => keyResult.actionPlans)

  const floatDrawerItems = useGetFloatDrawerItems()
  const okrFloatDrawerListContainerRef = useRef<HTMLDivElement>(null)
  const [navWidth, setNavWidth] = useState<number>(0)
  useEffect(() => {
    const element = document.querySelector(`#${SIDE_NAV_ID}`)
    if (!element) {
      return
    }

    const resizeObserver = new ResizeObserver(
      (entries: Array<{ target: { id: string }; contentRect: { width: number } }>) => {
        if (entries[0].target.id === SIDE_NAV_ID) {
          setNavWidth(entries[0].contentRect.width)
        }
      },
    )
    resizeObserver.observe(element)

    // eslint-disable-next-line consistent-return
    return () => resizeObserver.disconnect()
  }, [])

  const [needBlock, setNeedBlock] = useState(false)
  const setFloatDrawerItems = useSetFloatDrawerItems()
  useBlocker((tx) => {
    if (matchPath(okrPath, tx.location.pathname) || !needBlock) return
    setFloatDrawerItems([])
    setNeedBlock(false)
    tx.retry()
  }, needBlock)

  useEffect(() => {
    // FIXME: floatDrawerItemsを削除していない、サイト全体として設計・管理できていない。
    setNeedBlock(floatDrawerItems.length !== 0)
  }, [floatDrawerItems])

  const onClickKeyResult = useCallback(
    (item: KeyResultFragment) => {
      openOkrModalWithKeyResultDrawer(
        item.objective.id,
        item.id,
        Screen.Okr,
        isIncludedUser(item, user),
      )
    },
    [openOkrModalWithKeyResultDrawer, user],
  )

  return (
    <PageContent breadcrumbs={undefined} layout={{ css: { position: 'relative' } }}>
      <div css={{ minWidth: '1196px' }}>
        {/* ヘッダー */}
        <Box
          direction="row"
          justify="between"
          css={{
            backgroundColor: '#FFF',
            paddingTop: 16,
            paddingBottom: 16,
            borderBottom: `1px solid ${color('border-bk-30')}`,
          }}
        >
          <Text
            css={{ fontWeight: 'bold', fontSize: '24px', lineHeight: '33px', marginLeft: '80px' }}
          >
            {t('X_PAGE', { x: t('OKR') })}
          </Text>
          <Box direction="row">
            <Box css={{ marginRight: '118px' }}>
              <StyledText color="text-bk-50" css={{ marginBottom: '4px' }}>
                {t('OWNER')}
              </StyledText>
              <Link href={generateHome(node.term.id)(node.objective.owner.id)}>
                <StyledText size="large">
                  {!node.objective.owner.isDisabled ? (
                    <span>
                      {t('FULL_NAME', {
                        firstName: node.objective.owner.firstName,
                        lastName: node.objective.owner.lastName,
                      })}
                    </span>
                  ) : (
                    <span>
                      ({t('DISABLING')})
                      <span css={{ textDecoration: 'line-through' }}>
                        {t('FULL_NAME', {
                          firstName: node.objective.owner.firstName,
                          lastName: node.objective.owner.lastName,
                        })}
                      </span>
                    </span>
                  )}
                </StyledText>
              </Link>
            </Box>
            <Box direction="row" css={{ marginRight: '70px' }}>
              {members.length <= 5
                ? members.map((member) => (
                    <MemberIcon
                      key={member.id}
                      nodeTermId={node.term.id}
                      member={member}
                      opacity={member.isDisabled ? 0.1 : 1}
                      isUserDisabled={member.isDisabled}
                    />
                  ))
                : members
                    .slice(0, 5)
                    .map((member) => (
                      <MemberIcon
                        key={member.id}
                        nodeTermId={node.term.id}
                        member={member}
                        opacity={member.isDisabled ? 0.1 : 1}
                        isUserDisabled={member.isDisabled}
                      />
                    ))
                    .concat(
                      <Avatar
                        key="other"
                        firstName={(members.length - 5).toString()}
                        lastName="+"
                        avatarUrl={undefined}
                        tooltipDisabled
                        size="okr-page"
                        backgroundColor="#C4C4C4"
                        css={{ marginRight: '8px' }}
                        // そもそもこのアバターは実在するユーザーではないため明示的にfalseとする
                        isUserDisabled={false}
                      />,
                    )}
            </Box>
          </Box>
        </Box>

        <div
          css={{
            backgroundColor: color('background-bk-5'),
            paddingTop: '28px',
            paddingLeft: '86px',
          }}
        >
          <Breadcrumb
            breadcrumbs={{
              url: okrPath,
              items: [
                {
                  breadcrumbName: 'treeAndNode',
                  ...node,
                },
                {
                  breadcrumbName: 'okr',
                  ...node,
                },
              ],
            }}
          />
        </div>

        <Main
          css={{ backgroundColor: color('background-bk-5'), padding: '28px 85px' }}
          align="center"
        >
          {/* OKR Card Header */}
          <Section fill="horizontal">
            <div
              css={{
                padding: '4px 0 28px',
                borderRadius: '16px',
                border: `1px solid ${color('border-bk-30')}`,
                backgroundColor: color('white-100'),
              }}
            >
              <Box
                direction="row"
                align="center"
                justify="between"
                css={{
                  padding: '36px 48px',
                  borderBottom: `1px solid ${color('border-bk-30')}`,
                }}
              >
                <div css={{ display: 'flex' }}>
                  <div css={{ margin: 'auto 0' }}>
                    <ObjectiveAvatar
                      size="medium"
                      termId={node.term.id}
                      owner={node.objective.owner}
                    />
                  </div>
                  <TitleLink
                    titleProps={{
                      id: generateObjectiveHash(node.objective.id),
                      title: node.objective?.name,
                      onClick: () => {
                        openOkrModal(node.objective.id, Screen.Okr, isAssignedToObjective)
                      },
                      css: {
                        fontSize: 24,
                        lineHeight: '32px',
                        fontWeight: 'bold',
                      },
                    }}
                    margin="0 0 0 24px"
                    direction="column"
                  />
                </div>
                <Box direction="row" margin={{ left: '32px' }} flex={{ shrink: 0 }}>
                  <Box align="center" margin={{ right: '54px' }}>
                    <StyledText
                      size="xsmall"
                      css={{
                        marginBottom: '8px',
                        lineHeight: '14px',
                        fontSize: '10px',
                      }}
                    >
                      {t('PROGRESS_RATE')}
                    </StyledText>
                    <ProgressRate
                      color={color('objective-blue-100')}
                      rate={node.objective?.progressRate || 0}
                      size="large"
                    />
                  </Box>
                </Box>
              </Box>
              <DraggableKeyResultsTable
                termId={node.term.id}
                items={keyResults}
                reOrder={(k, displayOrder) => reOrder({ keyResultID: k.id, displayOrder })}
                onClickItem={onClickKeyResult}
              />
            </div>
          </Section>
          <Fragment>
            {checkinSummary && (
              <div ref={okrMeetingRef} css={{ width: '100%' }}>
                <Section css={{ marginTop: '32px', width: '100%' }}>
                  <OkrPageCheckinMeetingCard
                    termId={node.term.id}
                    user={user}
                    isShowLink={
                      keyResults.some((k) => isIncludedUser(k, user)) &&
                      !user.checkinKeyResultsState.isBulkUpdated &&
                      isPrimary
                    }
                    summary={checkinSummary}
                    keyResults={keyResults}
                    onSaveKeyResultSharedItem={updateKeyResultSharedItem}
                    onClickSave={onClickSave}
                  />
                </Section>
              </div>
            )}
            <div ref={summariesRef} css={{ width: '100%' }}>
              <Section css={{ marginTop: '32px', width: '100%' }}>
                <OkrPageCheckinSummaryListCard
                  okrNode={node}
                  checkinSummaries={checkinSummaries}
                  onReachEnd={fetchCheckinSummaries}
                />
              </Section>
            </div>
          </Fragment>

          {/* ノート */}
          <Section fill="horizontal" css={{ marginTop: '36px' }}>
            <OkrNoteListView
              notes={notes.concat().sort((a, b) => (a.updatedAt < b.updatedAt ? 1 : -1))}
              objective={node.objective}
              keyResults={keyResults}
              createNote={createNote}
              deleteNote={deleteNote}
            />
          </Section>

          {/* OKR進捗チャート */}
          <Section fill="horizontal" css={{ marginTop: '36px' }}>
            <ProgressChart term={node.term} objective={node.objective} keyResults={keyResults} />
          </Section>
          <Section fill="horizontal" css={{ marginTop: '36px' }}>
            <ActionPlanListCard
              actionPlans={actionPlans}
              termId={node.term.id}
              onUpdateActionPlanStatus={({ actionPlanId, status: actionPlanStatus }) => {
                const originActionPlan = actionPlans.find((a) => a.id === actionPlanId)
                if (!originActionPlan) return
                updateActionPlan({
                  id: actionPlanId,
                  keyResultId: originActionPlan.keyResult.id,
                  updateType: originActionPlan.updateType,
                  memberIds: originActionPlan.members.map(({ id }) => id),
                  status: actionPlanStatus,
                })
              }}
            />
          </Section>
        </Main>
      </div>
      <div
        ref={okrFloatDrawerListContainerRef}
        css={{
          position: 'fixed',
          width: `calc(100vw - ${navWidth}px)`,
          height: '100vh',
          pointerEvents: 'none',
          top: '0',
          left: `${navWidth}px`,
          zIndex: 6,
        }}
      >
        <OkrFloatDrawerList
          addItems={floatDrawerItems}
          parentRef={okrFloatDrawerListContainerRef}
        />
      </div>
      {OkrModal}
    </PageContent>
  )
}

Okr.displayName = 'Okr'

// Components: OKRページヘッダ部のアイコン
type MemberIconProps = {
  member: UserFragment
  nodeTermId: string
  opacity?: number
  isUserDisabled: boolean
}

const MemberIcon = ({ member, nodeTermId, opacity = 1, isUserDisabled }: MemberIconProps) => (
  <Link
    href={generateHome(nodeTermId)(member.id)}
    method="newTab"
    css={{
      textDecoration: 'none',

      ':hover': {
        textDecoration: 'none',
      },
    }}
  >
    <Avatar
      key={member.id}
      firstName={member.firstName}
      lastName={member.lastName}
      avatarUrl={member.avatar?.url}
      size="okr-page"
      backgroundColor="#C4C4C4"
      css={{ marginRight: '8px', opacity }}
      isUserDisabled={isUserDisabled}
    />
  </Link>
)
