import { css } from '@emotion/react'
import { useMemo } from 'react'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useStyles = () =>
  useMemo(
    () =>
      ({
        root: css({
          display: 'flex',
          alignItems: 'start',
          marginTop: '8px',
          gap: 8,
        }),
        attachmentsList: css({
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'start',
          gap: '8px 4px',
          listStyle: 'none',
          width: '100%',
          '> li > *': {
            height: 28,
            paddingTop: 4,
            paddingBottom: 4,
            borderRadius: 12,
            svg: { verticalAlign: 'middle' },
            '> svg': {
              left: 4,
              width: 18,
              height: 18,
            },
            a: { fontSize: '14px' },
          },
        }),
        attachment: css({ maxWidth: '100%' }),
      } as const),
    [],
  )
