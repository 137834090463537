import { Dispatch, SetStateAction } from 'react'
import { useLocalStorage } from 'react-use'

type Ret = [number, Dispatch<SetStateAction<number | undefined>>]

/**
 * 1 on 1詳細のアジェンダリストの縦幅を保持しているlocalStorageのhooks
 */

const key = 'one-on-one-agenda-list-height'

export const useAgendaListHeight = (initialHeight: number): Ret => {
  const [agendaListHeight, setAgendaListHeight] = useLocalStorage<number>(key, initialHeight)

  return [agendaListHeight ?? initialHeight, setAgendaListHeight]
}
