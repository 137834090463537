import { Box } from 'grommet'
import React from 'react'

import { color } from '../../../styles/newColors'
import { StyledText } from '../StyledText'

export type Props = {
  label?: string
  isCheck: boolean
  disabled?: boolean
  handleCheckboxClick: (isCheck: boolean, e?: React.ChangeEvent<HTMLInputElement>) => void
}

export const Switch: React.FC<Props> = ({
  label,
  isCheck,
  handleCheckboxClick,
  disabled = false,
}) => (
  <Box direction="row" align="center">
    {label && (
      <StyledText
        lineHeight="14px"
        size="small"
        fontStyle="bold"
        color={isCheck ? 'resily-orange-100' : 'text-bk-50'}
        margin={{ right: '9px' }}
      >
        {label}
      </StyledText>
    )}
    <label
      css={{
        width: '40px',
        position: 'relative',
        display: 'inline-block',
        cursor: disabled ? 'none' : 'pointer',
      }}
    >
      <input
        type="checkbox"
        name="checkbox"
        checked={isCheck}
        disabled={disabled}
        css={{
          display: 'none',
        }}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          handleCheckboxClick(event.target.checked, event)
        }
      />
      <span
        css={{
          display: ' block',
          cursor: 'pointer',
          position: 'relative',
          borderRadius: '32px',
          width: '42px',
          height: '20px',
          backgroundColor: 'rgb(34 31 31 / 26%)',
          transition: 'all .1s .2s',
          overflow: 'hidden',
          '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            display: 'block',
            width: isCheck ? '100%' : 0,
            height: '100%',
            content: '""',
            backgroundColor: isCheck ? color('resily-orange-100') : color('background-bk-5'),
          },
        }}
      />
      <span
        css={{
          display: 'block',
          top: '3px',
          position: 'absolute',
          width: '14px',
          height: '14px',
          borderRadius: '10px',
          transition: 'all .3s',
          boxShadow: '0 2px 2px #ccc',
          left: isCheck ? '25px' : '3px',
          backgroundColor: color('white-100'),
        }}
      />
    </label>
  </Box>
)

Switch.displayName = 'Switch'
