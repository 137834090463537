import { css } from '@emotion/react'
import { memo, ReactNode } from 'react'
import isEqual from 'react-fast-compare'

import { Breadcrumb, BreadcrumbParams } from '../domain/Breadcrumb'
import { AlertModal } from '../standalone/AlertModal'

import { ContentLayout, Props as ContentLayoutProps } from './ContentLayout'

const pageContentBox = css({
  display: 'flex', // flexレイアウトにしないとoverflow: hiddenの影響でheaderが隠れてしまう
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  overflow: 'hidden',
})
const headerCss = css({
  width: '100%',
})
const contentCss = css({
  height: '100%',
  width: '100%',
  overflow: 'auto',
})

export type Props = {
  header?: JSX.Element
  breadcrumbs?: BreadcrumbParams
  layout?: ContentLayoutProps
  children?: ReactNode
  contentBackgroundColor?: string
}

export const PageContent: React.FC<Props> = memo<Props>(
  ({ header, children, breadcrumbs, layout, contentBackgroundColor }) => (
    <div css={pageContentBox}>
      <div css={headerCss}>{header}</div>
      <div
        css={[contentCss, contentBackgroundColor && { backgroundColor: contentBackgroundColor }]}
      >
        <ContentLayout {...layout}>
          {breadcrumbs && (
            <div css={{ paddingBottom: '28px' }}>
              <Breadcrumb breadcrumbs={breadcrumbs} />
            </div>
          )}
          {children}
          <AlertModal />
        </ContentLayout>
      </div>
    </div>
  ),
  (prevProps, nextProps) => {
    if (prevProps.contentBackgroundColor !== nextProps.contentBackgroundColor) {
      return false
    }
    if (!isEqual(prevProps.breadcrumbs, nextProps.breadcrumbs)) {
      return false
    }
    if (!isEqual(prevProps.layout, nextProps.layout)) {
      return false
    }
    if (!isEqual(prevProps.header, nextProps.header)) {
      return false
    }
    if (!isEqual(prevProps.children, nextProps.children)) {
      return false
    }
    return true
  },
)
PageContent.displayName = 'PageContent'
