import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { DateInput as GrommetDateInput, DateInputProps, Grommet } from 'grommet'
import { useMemo } from 'react'
import { FieldError } from 'react-hook-form'

import { border } from '../../../styles/border'
import { color } from '../../../styles/newColors'
import { Icon } from '../Icon'

import { LocaleType, localeType } from './types'

dayjs.extend(utc)

export type Props = Omit<DateInputProps, 'value'> & {
  value?: string
  locale?: LocaleType
  icon?: JSX.Element | false
  placeholder?: string
  fieldError?: FieldError
}

export const DateInput: React.VFC<Props> = ({
  value,
  locale = localeType.ja,
  icon = <Icon type="calendar" />,
  placeholder,
  fieldError,
  onChange,
  inputProps,
  ...props
}) => {
  const calendarInputProps = useMemo(() => {
    const iconProps = icon === false ? <></> : icon
    return {
      size: 'xsmall',
      icon: iconProps,
      ...inputProps,
    } as const
  }, [icon, inputProps])

  return (
    <Grommet
      theme={{
        global: {
          size: {
            small: '208px',
          },
        },
        calendar: {
          small: {
            fontSize: '12px',
            daySize: '22px',
          },
          day: {
            extend: ({ isSelected }: { isSelected: boolean }) => ({
              backgroundColor: isSelected ? color('resily-orange-100') : undefined,
              borderRadius: '2px',
            }),
          },
          extend: {
            h4: {
              fontSize: '14px',
            },
            div: {
              boxShadow: 'none',
            },
          },
        },
        dateInput: {
          icon: {
            size: '16px',
          },
        },
      }}
    >
      <GrommetDateInput
        value={value}
        format="yyyy/mm/dd"
        placeholder={placeholder}
        style={{
          borderColor: fieldError ? color('tag-red') : color('border-bk-10'),
        }}
        css={{
          fontSize: '14px',
          fontWeight: 'normal',
          cursor: 'pointer',
          ':focus': {
            backgroundColor: color('hover-background-bk-5'),
            borderColor: color('resily-orange-100'),
            boxShadow: 'none',
          },
          '::placeholder': {
            color: color('text-bk-30'),
          },
        }}
        calendarProps={{
          margin: 'xsmall',
          size: 'small',
          locale,
          date: value || new Date().toISOString(),
          css: {
            padding: '16px',
            backgroundColor: color('white-100'),
            border: border('simple-30'),
            borderRadius: '4px',
            boxShadow: 'none',
          },
        }}
        onChange={({ value: v }) => {
          const selectedDate = dayjs(v.toString()).format('YYYY/MM/DD')
          if (onChange != null) {
            onChange({ value: dayjs.utc(selectedDate).format() })
          }
        }}
        inputProps={calendarInputProps}
        {...props}
      />
    </Grommet>
  )
}

DateInput.displayName = 'DateInput'
