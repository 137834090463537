import localforage from 'localforage'

import { removeCurrentTerm } from './okrTerm'

// ログアウト or セッション有効期限切れの際にcallされる後処理
export const destruct = (): void => {
  // sessionStorageに保存しているcurrentのOKR期間情報をクリア
  removeCurrentTerm()

  // localForageで利用しているApollo Clientのキャッシュをクリア
  localforage.clear().catch((err) => {
    // eslint-disable-next-line no-console
    console.error(err)
  })
}
