import { css, SerializedStyles, CSSObject } from '@emotion/react'
import { VFC, useMemo, useRef, useEffect, useState } from 'react'

import { TooltipNew } from '../TooltipNew'

export type Props = {
  /** 省略せずに表示する最大行数 */
  lineClamp?: number
  /** 表示するテキスト */
  text: string
  /** 最大幅 */
  maxWidth: number | `${number}px` | `${number}%`
}

const baseCss: CSSObject = {
  display: 'inline-block',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  // stylelint-disable-next-line
  WebkitBoxOrient: 'vertical',
}

/**
 * テキストの省略表示を行うUIコンポーネント
 * 省略された際にテキストにホバーするとツールチップで全文表示される
 */
export const WrapText: VFC<Props> = ({ lineClamp = 1, text, maxWidth, ...props }) => {
  const ref = useRef<HTMLSpanElement>(null)
  const [isShort, setIsShort] = useState(false)

  // NOTE: refがnullにならないようにmount後にisShortをセットする
  useEffect(() => {
    setIsShort(!!ref.current && ref.current.offsetWidth < ref.current.scrollWidth)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const wrapCss = useMemo<SerializedStyles>(
    () =>
      css({
        ...baseCss,
        maxWidth,
        // stylelint-disable-next-line
        WebkitLineClamp: lineClamp,
      }),
    [lineClamp, maxWidth],
  )

  return isShort ? (
    <TooltipNew title={text}>
      <span ref={ref} css={wrapCss} {...props}>
        {text}
      </span>
    </TooltipNew>
  ) : (
    <span ref={ref} {...props} css={wrapCss}>
      {text}
    </span>
  )
}

WrapText.displayName = 'WrapText'
