import { rootCss } from './styles'

type Props = {
  ['data-testid']?: string
  children: React.ReactNode
}

export const CheckinModalGrid: React.VFC<Props> = ({ children, ...rest }) => (
  <div css={rootCss} {...rest}>
    {children}
  </div>
)
