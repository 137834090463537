import { useModal, ModalProps } from '@resily/geisha'
import { FC } from 'react'

import { useTranslation } from '../../../../../i18n'
import { StyledText } from '../../../../ui/StyledText'

export type Props = Pick<ModalProps, 'isOpen' | 'onClose'> & {
  onClickConfirm: () => void
}

export const DiscardCheckinModal: FC<Props> = ({ isOpen, onClose, onClickConfirm }) => {
  const { t } = useTranslation()
  const [Modal, ,] = useModal()

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="medium">
      <Modal.Header title={t('DISCARD_CHECKIN_INPUT')} hideCloseButton />
      <Modal.Content>
        <StyledText>{t('CONFIRMATION_OF_DISCARDING_CHECKIN_INPUT')}</StyledText>
      </Modal.Content>
      <Modal.Footer
        cancelType="tertiary"
        cancelLabel={t('CANCEL')}
        confirmLabel={t('DISCARD')}
        onCancel={onClose}
        onConfirm={onClickConfirm}
      />
    </Modal>
  )
}

DiscardCheckinModal.displayName = 'DiscardCheckinModal'
