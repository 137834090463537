import { css, CSSObject } from '@emotion/react'

import { useTranslation } from '../../../i18n'
import { FontSizeAlias, FontStyleAlias, fontSize } from '../../../styles/font'

type Props = {
  datetime: Date
  size?: FontSizeAlias
  fontStyle?: FontStyleAlias
  withoutTime?: boolean
  style?: CSSObject
}

export const DateTime: React.FC<Props> = ({
  datetime,
  size = 'medium',
  fontStyle,
  withoutTime,
  style,
}) => {
  const { t } = useTranslation()
  const timeCss =
    typeof style === 'undefined'
      ? {
          display: 'block',
          marginBottom: '4px',
          ...fontSize(size, fontStyle),
        }
      : css(style)

  return (
    <time css={timeCss}>
      {withoutTime
        ? t('DATE', { date: new Date(datetime) })
        : t('DATE_TIME', { date: new Date(datetime) })}
    </time>
  )
}

DateTime.displayName = 'DateTime'
