import { useTranslation } from '../../../../../../i18n'
import {
  CheckinButtonProps,
  UseCheckinButtonProps,
  useCheckinButton,
} from '../../../../../domain/CheckinButton'
import { StyledText } from '../../../../../ui/StyledText'

import { useStyles } from './CheckinAreaHeader.styles'

export type CheckinAreaHeaderProps = {
  checkinButtonProps?: Pick<CheckinButtonProps, 'onClick'> &
    UseCheckinButtonProps & {
      showCheckinButton: boolean
    }
}

export const CheckinAreaHeader: React.VFC<CheckinAreaHeaderProps> = ({ checkinButtonProps }) => {
  const { t } = useTranslation()
  const styles = useStyles()
  const [CheckinButton] = useCheckinButton({
    keyResults: checkinButtonProps?.keyResults,
    okrTerms: checkinButtonProps?.okrTerms,
  })

  return (
    <div css={styles.root}>
      <StyledText size="large" fontStyle="bold" lineHeight="24px">
        {t('CHECKIN')}
      </StyledText>
      {checkinButtonProps?.showCheckinButton && (
        <CheckinButton onClick={checkinButtonProps.onClick} />
      )}
    </div>
  )
}
