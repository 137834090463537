import { useCallback } from 'react'

import { useCheckEditingOnClose } from '../../../../../contexts/EditingStateContext'
import { useOkrModalQueryParams } from '../../hooks/useOkrModalQueryParams'
import { KeyResultTab } from '../../types'

export type KeyResultDrawerReturnType = {
  isOpened: boolean
  open: (objectiveId: string, keyResultId: string, tab?: KeyResultTab) => void
  close: (onClose?: () => void) => void
}

export const useKeyResultDrawer = (): KeyResultDrawerReturnType => {
  const {
    keyResultId: queryParamsKeyResultId,
    setObjectiveIdAndKeyResultId,
    setKeyResultTab,
    resetKeyResultId,
  } = useOkrModalQueryParams()

  const checkEditingOnClose = useCheckEditingOnClose()
  const open = useCallback<KeyResultDrawerReturnType['open']>(
    (objectiveId, keyResultId, tab) => {
      checkEditingOnClose(() => {
        setObjectiveIdAndKeyResultId(objectiveId, keyResultId)
        if (tab) setKeyResultTab(tab)
      })
    },
    [checkEditingOnClose, setKeyResultTab, setObjectiveIdAndKeyResultId],
  )

  const close = useCallback<KeyResultDrawerReturnType['close']>(
    (onClose) => {
      if (queryParamsKeyResultId == null) {
        onClose?.()
        return
      }
      checkEditingOnClose(() => {
        resetKeyResultId()
        onClose?.()
      })
    },
    [checkEditingOnClose, queryParamsKeyResultId, resetKeyResultId],
  )

  return { isOpened: !!queryParamsKeyResultId, open, close }
}
