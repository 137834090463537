import { useTranslation } from '../../../i18n'
import { color } from '../../../styles/newColors'
import { Icon } from '../../ui/Icon'
import { StyledText } from '../../ui/StyledText'
import { TooltipNew } from '../../ui/TooltipNew'

export type Props = {
  type?: 'subtree' | 'focus'
  name: string
  width?: number
  onClickCancel: () => void
}

export const OkrName: React.FC<Props> = ({ type, name, width, onClickCancel }) => {
  const { t } = useTranslation()

  return (
    <div
      css={{
        borderRadius: '4px',
        backgroundColor: '#E9EDF7',
        padding: '12px 8px',
        display: 'inline-flex',
        width: width ? width - 16 : undefined,
      }}
    >
      {type && type === 'subtree' && (
        <Icon type="subTree" color={color('text-bk-50')} css={{ marginRight: '4px' }} />
      )}
      {type && type === 'focus' && (
        <Icon type="focus" color={color('text-bk-50')} css={{ marginRight: '4px' }} />
      )}
      <StyledText
        color="objective-blue-100"
        size="small"
        css={{
          flexBasis: 'auto',
          display: 'inline-block',
          lineHeight: '16px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {name}
      </StyledText>
      <div
        css={{
          marginLeft: '4px',
          cursor: 'pointer',
          display: 'inline-flex',
          ':hover': {
            color: color('resily-orange-100'),
          },
        }}
      >
        <TooltipNew title={t('CLEAR_X', { x: t('SELECTED_X', { x: t('OKR') }) })}>
          <Icon type="mdiClear" onClick={onClickCancel} color={color('text-bk-20')} />
        </TooltipNew>
      </div>
    </div>
  )
}

OkrName.displayName = 'OkrName'
