import { css } from '@emotion/react'
import { useProfiler } from '@sentry/react'
import { VFC, useEffect, useRef } from 'react'
import { animated, useSpring, SpringConfig } from 'react-spring'

import { Icon } from '../../../components/ui/Icon'
import { useTranslation } from '../../../i18n'
import { fontSize } from '../../../styles/font'
import { color } from '../../../styles/newColors'
import { MapView } from '../../../urls'

export type Props = { tab: MapView; onClickTab: (tab: MapView) => void }

const tabContainerCss = css({
  position: 'relative',
  display: 'flex',
  width: 'max-content',
  padding: '2px',
  justifyContent: 'center',
  alignItems: 'center',
  background: color('background-bk-5'),
  borderRadius: '10px',
})
const inputCss = css({
  display: 'none',

  '+ label': {
    ':hover': {
      color: color('text-bk-100'),

      svg: {
        color: color('text-bk-100'),
      },
    },
  },

  '&:checked + label': {
    ':hover': {
      color: color('text-bk-80'),

      svg: {
        color: color('text-bk-80'),
      },
    },
  },
})
const size = `${fontSize('medium').fontSize}px`
const labelCss = css({
  zIndex: 1,
  padding: '4px 8px',
  color: color('text-bk-80'),
  width: 'max-content',
  cursor: 'pointer',
  fontSize: size,
  lineHeight: size,
  alignItems: 'center',
})
const iconCss = css({
  marginRight: '6px',
  width: '20px',
  height: '20px',
  verticalAlign: 'sub',
})
const TAB_WIDTH = 110 as const
const TAB_HEIGHT = 28 as const
const tabCss = css({
  position: 'absolute',
  zIndex: 0,
  width: TAB_WIDTH,
  height: TAB_HEIGHT,
  backgroundColor: color('white-100'),
  borderRadius: '8px',
})
const calculateTabStyle = (
  tab: MapView,
): { width: number; left: number; config?: SpringConfig } => {
  const config: SpringConfig = {
    tension: 280,
    friction: 40,
  }
  switch (tab) {
    case 'tree':
      return {
        config,
        left: 2,
        width: TAB_WIDTH,
      }
    case 'list':
      return {
        config,
        left: 116,
        width: TAB_WIDTH,
      }
    default:
      return { left: 0, width: 0 }
  }
}
export const MapViewMode: VFC<Props> = ({ tab, onClickTab }) => {
  useProfiler('MapViewMode')
  const { t } = useTranslation()
  const treeRef = useRef<HTMLInputElement>(null)
  const listRef = useRef<HTMLInputElement>(null)
  const tabStyle = useSpring(calculateTabStyle(tab))

  // html要素のcheckedを使ってしまうとpropsから受け取ったtabが変更されるまでUIが変更されない
  // クリック後にUIだけは即時反映させたいので、propsから受け取った値に対しては非同期で変更する
  useEffect(() => {
    if (!treeRef.current || !listRef.current) return
    treeRef.current.checked = false
    listRef.current.checked = false
    if (tab === 'tree') {
      treeRef.current.checked = true
    }
    if (tab === 'list') {
      listRef.current.checked = true
    }
  }, [tab])

  const onClickTree = () => onClickTab('tree')
  const onClickList = () => onClickTab('list')

  return (
    <div css={tabContainerCss}>
      <animated.div css={tabCss} style={tabStyle} />
      <input
        ref={treeRef}
        type="radio"
        id="tree"
        name="viewMode"
        value="tree"
        css={inputCss}
        onClick={onClickTree}
      />
      <label htmlFor="tree" css={labelCss}>
        <Icon type="treeView" color={color('text-bk-80')} css={iconCss} />
        {t('X_VIEW', { x: t('TREE') })}
      </label>
      <input
        ref={listRef}
        type="radio"
        id="list"
        name="viewMode"
        value="list"
        css={inputCss}
        onClick={onClickList}
      />
      <label htmlFor="list" css={labelCss}>
        <Icon type="mdiList" color={color('text-bk-80')} css={iconCss} />
        {t('X_VIEW', { x: t('LIST') })}
      </label>
    </div>
  )
}
MapViewMode.displayName = 'MapViewMode'
