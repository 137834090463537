import { Box, Select, Spinner } from 'grommet'
import React, { forwardRef, HTMLAttributes, ReactNode, useState, useCallback } from 'react'

import { useTranslation } from '../../i18n'
import { filterString } from '../../lib/array'
import { bySearchTextOrValue } from '../../lib/domain/user/searchText'

export type SearchSelectOption<TValue = string> = {
  value: TValue
  label: ReactNode
  searchText?: string
}

export type NewSearchSelectProps<TValue = string> = {
  isLoading?: boolean
  options: ReadonlyArray<SearchSelectOption<TValue>>
  value?: TValue
  isDisabled?: boolean
  onChange: (userId: string) => void
} & Omit<HTMLAttributes<HTMLDivElement>, 'onChange'>

export const SelectUser = forwardRef<HTMLButtonElement, NewSearchSelectProps<string>>(
  ({ isLoading = false, options, value, isDisabled, onChange, ...rest }, ref) => {
    const { t } = useTranslation()

    const currentOption = options.find((o) => o.value === value)
    const [filteredOptions, setFilteredOptions] = useState<
      ReadonlyArray<SearchSelectOption<string>>
    >(options.filter((o) => o.value !== value))

    const handleOnOpen = () => {
      setFilteredOptions(options.filter((o) => o.value !== value))
    }

    const handleOnChange = useCallback(
      (v: string | undefined) => {
        setFilteredOptions(options.filter((o) => o.value !== v))
        const selectedUserIds = options.filter((o) => o.value === v)
        setFilteredOptions(selectedUserIds)
        onChange(selectedUserIds.length ? selectedUserIds[0].value : '')
      },
      [onChange, options],
    )

    const loadingElement = [
      <div
        style={{
          display: 'flex',
          padding: '10px 0',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '4px',
        }}
      >
        <Spinner />
      </div>,
    ]

    return (
      <Box justify="start">
        <Select
          ref={ref}
          disabled={isDisabled}
          options={isLoading ? loadingElement : filteredOptions.map((o) => o.value)}
          value={value}
          valueLabel={
            currentOption ? (
              <Box direction="row" align="center" width="100%" justify="between" pad="8px">
                <div css={{ width: '100%' }}>{currentOption.label}</div>
              </Box>
            ) : null
          }
          searchPlaceholder={`${t('INPUT_X', { x: t('USER_NAME') })}`}
          emptySearchMessage={t('NOT_APPLICABLE')}
          onChange={(o) => handleOnChange(o.value)}
          onOpen={handleOnOpen}
          onSearch={(query) => {
            setFilteredOptions(
              filterString<SearchSelectOption<string>>(
                query,
                options.filter((o) => o.value !== value),
                bySearchTextOrValue,
              ),
            )
          }}
          {...rest}
        >
          {(option) => {
            const op = filteredOptions.find((o) => o.value === option)
            return <Box pad="xsmall">{op?.label ?? option}</Box>
          }}
        </Select>
      </Box>
    )
  },
)

SelectUser.displayName = 'SelectUser'
