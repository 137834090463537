import { css } from '@emotion/react'
import dayjs from 'dayjs'
import { CSSProperties, forwardRef, memo } from 'react'
import { areEqual } from 'react-window'

import { GroupTags } from '../../../../components/domain/GroupTags'
import { Avatar } from '../../../../components/ui/Avatar'
import { Link } from '../../../../components/ui/Link'
import { StyledText } from '../../../../components/ui/StyledText'
import { TooltipNew } from '../../../../components/ui/TooltipNew'
import { useTranslation } from '../../../../i18n'
import { color } from '../../../../styles/newColors'
import { UserFragment } from '../../graphql'

import { ProgressRate } from './ProgressRate'

export type Props = {
  user: UserFragment
  style: CSSProperties
  href: string
}

export const UsersTableRow = memo(
  forwardRef<HTMLDivElement, Props>(
    ({ user, style, href }: Props, ref: React.Ref<HTMLDivElement>) => {
      const { t } = useTranslation()
      const cssTableCellBody = css({
        display: 'flex',
        alignItems: 'center',
        padding: '8px 0 6px',
        borderBottom: `1px solid ${color('border-bk-10')}`,
      })

      return (
        <div
          style={style}
          css={{
            display: 'flex',
          }}
          ref={ref}
        >
          <div
            css={[
              cssTableCellBody,
              {
                flex: 'auto',
                paddingLeft: '8px',
              },
            ]}
          >
            <div
              css={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Avatar
                firstName={user.firstName}
                lastName={user.lastName}
                avatarUrl={user.avatar?.url}
                size="mediumShort"
                isUserDisabled={user.isDisabled}
                css={{ marginRight: '8px' }}
              />
              <div
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                  maxWidth: '400px',
                }}
              >
                <TooltipNew
                  title={t('FULL_NAME', { firstName: user.firstName, lastName: user.lastName })}
                  dropProps={{
                    align: {
                      top: 'bottom',
                      left: 'left',
                    },
                    stretch: false,
                    plain: true,
                  }}
                  css={{
                    display: 'inline-block',
                    maxWidth: '100%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  <Link
                    href={href}
                    css={{
                      ':hover': {
                        color: color('resily-orange-100'),
                        textDecoration: 'underline',
                      },
                    }}
                  >
                    <StyledText size="medium">
                      {t('FULL_NAME', { firstName: user.firstName, lastName: user.lastName })}
                    </StyledText>
                  </Link>
                </TooltipNew>
                <GroupTags
                  css={{ maxHeight: '28px' }}
                  tagProps={{
                    color: 'text-bk-80',
                  }}
                  groups={user.groups}
                />
              </div>
            </div>
          </div>
          <div
            css={[
              cssTableCellBody,
              {
                flex: '0 0 136px',
                justifyContent: 'end',
                paddingRight: '24px',
              },
            ]}
          >
            <ProgressRate
              resources={user.ownerObjectives}
              mainColor={color('objective-blue-100')}
            />
          </div>
          <div
            css={[
              cssTableCellBody,
              {
                flex: '0 0 140px',
                justifyContent: 'end',
                paddingRight: '28px',
              },
            ]}
          >
            <ProgressRate resources={user.ownerKeyResults} mainColor={color('kr-green-100')} />
          </div>
          <div
            css={[
              cssTableCellBody,
              {
                flex: '0 0 164px',
                justifyContent: 'end',
                paddingRight: '52px',
              },
            ]}
          >
            <ProgressRate resources={user.memberKeyResults} mainColor={color('kr-green-100')} />
          </div>
          <div
            css={[
              cssTableCellBody,
              {
                flex: '0 0 124px',
                justifyContent: 'center',
                paddingRight: '36px',
              },
            ]}
          >
            {user.currentSignInAt ? (
              <StyledText size="medium">
                {dayjs(user.currentSignInAt).format('YYYY/MM/DD')}
              </StyledText>
            ) : (
              <StyledText size="medium">-</StyledText>
            )}
          </div>
        </div>
      )
    },
  ),
  areEqual,
)

UsersTableRow.displayName = 'UsersTableRow'
