import { keyResultToUserConfidenceLevel } from '../../../lib/domain/confidence'

import { KeyResultFragment, UserFragment } from './graphql'

import { ConfidenceTag, Props as ConfidenceTagProps } from './index'

export type Props = Omit<ConfidenceTagProps, 'confidenceLevel'> & {
  keyResult: KeyResultFragment
  user: UserFragment
}

export const UserConfidenceTag: React.VFC<Props> = ({ keyResult, user, ...props }) => {
  const userConfidenceLevel = keyResultToUserConfidenceLevel(keyResult, user)

  return <ConfidenceTag confidenceLevel={userConfidenceLevel} {...props} />
}

UserConfidenceTag.displayName = 'UserConfidenceTag'
