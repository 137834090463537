import React from 'react'

import { LoadableProps } from '../../types'

import { DescriptionArea, Props as DescriptionAreaProps } from './DescriptionArea'
import { HeaderArea, DataProps as HeaderDataProps, Props as HeaderProps } from './HeaderArea'

type DataProps = HeaderDataProps

export type Props = LoadableProps<DataProps> &
  Pick<HeaderProps, 'updateAutoAggregate' | 'onUpdateParentObjective' | 'onDelete'> &
  Pick<DescriptionAreaProps, 'saveDescription'>

export const KeyResultDescription: React.VFC<Props> = ({
  loading,
  keyResult,
  saveDescription,
  updateAutoAggregate,
  onUpdateParentObjective,
  onDelete,
}) => (
  <>
    {/* FIXME: neverにキャストしてるのやめる */}
    <HeaderArea
      loading={loading as never}
      keyResult={keyResult as never}
      updateAutoAggregate={updateAutoAggregate}
      onUpdateParentObjective={onUpdateParentObjective}
      onDelete={onDelete}
    />
    <DescriptionArea
      loading={loading as never}
      keyResultId={keyResult?.id as never}
      description={keyResult?.description}
      attachmentViews={keyResult?.attachments as never}
      saveDescription={saveDescription}
    />
  </>
)
