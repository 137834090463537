import dayjs from 'dayjs'
import { useContext } from 'react'

import { OrganizationContext } from '../../../contexts/OrganizationContext'

type SelfServeState = 'trial' | 'trial-expired' | 'subscription' | 'subscription-expired'

type UseSelfServeReturn =
  | {
      isSelfServe: false
      state?: never
      trialExpiresAt?: never
      stripeCustomerID?: never
      remainingLicenses?: never
    }
  | {
      // セルフサーブのOrganizationかどうか
      isSelfServe: true
      // セルフサーブの状態
      state: SelfServeState
      // トライアル期間の有効期限
      trialExpiresAt: Date
      // Stripe上の顧客ID
      stripeCustomerID: string | undefined
      // 残りのライセンス数
      remainingLicenses: number | undefined
    }

export const useSelfServeConfig = (): UseSelfServeReturn => {
  const ss = useContext(OrganizationContext)?.selfServe
  if (ss == null) {
    return { isSelfServe: false }
  }

  const state: SelfServeState = (() => {
    // トライアル期間中でもサブスク優先
    if (ss.stripeCustomerID != null) {
      if (ss.stripeExpiredAt == null) {
        // サブスク中
        return 'subscription'
      }
      // サブスク期間終了
      return 'subscription-expired'
    }

    if (dayjs().isBefore(ss.trialExpiresAt)) {
      // トライアル期間中
      return 'trial'
    }
    // トライアル期間終了かつ一度もサブスクしてない
    return 'trial-expired'
  })()

  return {
    isSelfServe: true,
    state,
    trialExpiresAt: ss.trialExpiresAt,
    stripeCustomerID: ss.stripeCustomerID ?? undefined,
    remainingLicenses: ss.remainingLicenses ?? undefined,
  }
}
