import { Group } from 'grommet-icons'
import React, { useMemo, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { useWindowSize } from 'react-use'

import { useTranslation } from '../../../../../i18n'
import { useClickOutside } from '../../../../../lib/clickOutside'
import { getUserSearchText } from '../../../../../lib/domain/user/searchText'
import { Avatar } from '../../../../ui/Avatar'
import { MultiSelect, Option } from '../../../../ui/MultiSelect'
import { TextButton } from '../../../../ui/TextButton'
import { getCandidateContributors } from '../../../Contributor'
import { UserTag } from '../../../UserTag'
import { UserFragment, GroupFragment } from '../../graphql'

import { useStyles } from './MemberSelect.styles'

export type Props = {
  selectedUserIds: ReadonlyArray<string>
  members: ReadonlyArray<UserFragment>
  groups: ReadonlyArray<GroupFragment>
  onClickUserOption: (next: Array<string>) => void
  isLoading?: boolean
  onOpen?: () => void
}

export const MemberSelect: React.FC<Props> = ({
  selectedUserIds,
  members,
  groups,
  onClickUserOption,
  isLoading = false,
  onOpen,
}) => {
  const { t } = useTranslation()
  const styles = useStyles()
  const [open, setOpen] = useState<boolean>(false)
  const multiSelectRef = useRef<HTMLDivElement>(null)
  const addContributorButtonRef = useRef<HTMLButtonElement>(null)
  const addContributorRef = useRef<HTMLDivElement>(null)
  const {
    left: addContLeft,
    top: addContTop,
    bottom: addContBottom,
  } = addContributorButtonRef.current
    ? addContributorButtonRef.current.getBoundingClientRect()
    : addContributorRef.current?.getBoundingClientRect() || {}

  const { height: windowHeight } = useWindowSize()

  useClickOutside({ callback: () => setOpen(false), ref: multiSelectRef })

  const optionList = useMemo<ReadonlyArray<Option>>(() => {
    const candidateContributors = getCandidateContributors(
      members.filter((m) => selectedUserIds.includes(m.id)),
      members.filter((m) => !selectedUserIds.includes(m.id)),
      '',
    )
    const userList = candidateContributors.map<Option>(
      ({ id, firstName, lastName, email, avatar, isDisabled }) => ({
        id,
        name: t('FULL_NAME', { firstName, lastName }),
        searchText: getUserSearchText({ firstName, lastName, email }),
        selected: selectedUserIds.some((value) => value === id),
        isDisabled,
        icon: (
          <Avatar
            tooltipDisabled
            firstName={firstName}
            lastName={lastName}
            avatarUrl={avatar?.url}
            size="xsmall"
            css={{ marginRight: '8px' }}
            isUserDisabled={isDisabled}
          />
        ),
      }),
    )

    const groupList = groups
      .map<Option>(({ id, name }) => ({
        id,
        name,
        searchText: name,
        selected: selectedUserIds.some((value) => value === id),
        // Groupは無効化表示をしないため明示的にfalseを渡す
        isDisabled: false,
        icon: <Group css={{ marginRight: '8px' }} size="14px" />,
      }))
      .sort((a, b) => (b.selected ? 1 : -1) - (a.selected ? 1 : -1))

    return userList.concat(groupList)
  }, [groups, members, selectedUserIds, t])

  return (
    <div css={styles.root}>
      {open &&
        createPortal(
          <div
            ref={multiSelectRef}
            css={styles.multiSelectWrapper}
            style={{
              // stylelint-disable-next-line function-whitespace-after
              left: addContLeft,
              top: windowHeight >= 950 && addContBottom ? addContBottom + 4 : undefined,
              bottom: windowHeight < 950 && addContTop ? windowHeight + 4 - addContTop : undefined,
            }}
          >
            <MultiSelect
              canSearch
              options={optionList}
              searchPlaceholder={t('INPUT_X', { x: t('USER_NAME') })}
              isLoading={isLoading}
              onClickOption={async (id) => {
                const group = groups.find((g) => g.id === id)
                // グループを選択した場合はグループのメンバーを全て選択
                if (group) {
                  const targetAdminIds = group.admins.filter((a) => !a.isDisabled).map((a) => a.id)
                  const targetMemberIds = group.members
                    .filter((m) => !m.isDisabled)
                    .map((m) => m.id)

                  const set = new Set([...selectedUserIds, ...targetAdminIds, ...targetMemberIds])
                  onClickUserOption([...set])
                  return
                }

                // 既に選択済みの場合は削除
                if (selectedUserIds.indexOf(id) >= 0) {
                  onClickUserOption(selectedUserIds.filter((c) => c !== id))
                  return
                }

                // 選択済みでない場合は追加
                onClickUserOption(selectedUserIds.concat(id))
              }}
            />
          </div>,
          document.body,
        )}
      {selectedUserIds.length === 0 ? (
        <TextButton
          ref={addContributorButtonRef}
          icon="plus"
          color="text-bk-30"
          padding="none"
          onClick={() => {
            if (onOpen) {
              onOpen()
            }
            setOpen(true)
          }}
        >
          {t('ADD_X', { x: t('CONTRIBUTOR') })}
        </TextButton>
      ) : (
        <div
          ref={addContributorRef}
          css={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          {members
            .filter((m) => selectedUserIds.includes(m.id))
            .map((m) => (
              <UserTag key={`member_${m.id}`} isUserDisabled={m.isDisabled} {...m} />
            ))}
          <TextButton
            icon="editSimple"
            color="text-bk-30"
            hoverColor="resily-orange-100"
            padding="none"
            onClick={() => {
              if (onOpen) {
                onOpen()
              }
              setOpen(true)
            }}
          />
        </div>
      )}
    </div>
  )
}

MemberSelect.displayName = 'MemberSelect'
