import { useMemo } from 'react'

import { useCurrentUser } from '../../../contexts/UserContext'
import { convertToFileInput } from '../../../lib/fileInput'
import { Option } from '../../ui/MultiSelect'

import { NoteOption } from './NoteOption'
import {
  useAttachNoteLabelMutation,
  useDetachNoteLabelMutation,
  useUpdateNoteMutation,
  useFindNoteByIdQuery,
  NoteDetailKeyResultFragment,
  NoteDetailObjectiveFragment,
  FindNoteByIdDocument,
  FindNoteByIdQuery,
  FindNoteByIdQueryVariables,
  NoteDetailViewNoteDetailFragment,
  useFindUserNotePermissionQuery,
  useNoteLabelsQuery,
} from './graphql'

type Resource = NoteDetailObjectiveFragment | NoteDetailKeyResultFragment

const isObjective = (resource: Resource): resource is NoteDetailObjectiveFragment =>
  resource.__typename === 'Objective'
const isKeyResult = (resource: Resource): resource is NoteDetailKeyResultFragment =>
  resource.__typename === 'KeyResult'

export type Props = {
  noteId: string
}

export const NoteOptionContainer: React.FC<Props> = ({ noteId }) => {
  const user = useCurrentUser()

  const { data: noteLabelsData } = useNoteLabelsQuery()

  const res = useFindNoteByIdQuery({
    variables: { id: noteId },
  })

  const usersRes = useFindUserNotePermissionQuery({
    variables: { id: noteId },
  })

  const [attachNoteLabel] = useAttachNoteLabelMutation()
  const [detachNoteLabel] = useDetachNoteLabelMutation()
  const [updateNote] = useUpdateNoteMutation()

  const labelOptions: ReadonlyArray<Option> = useMemo(
    () =>
      noteLabelsData?.noteLabels
        .map<Option>(({ id, name, color: backgroundColor }) => ({
          id,
          name,
          isDisabled: false, // Labelは無効化表示をしないため明示的にfalseを渡す
          searchText: name,
          selected: res.data?.findNoteById.labels.some((l) => l.id === id) ?? false,
          icon: (
            <div
              css={{
                backgroundColor,
                width: '14px',
                height: '14px',
                borderRadius: '50%',
                marginRight: '8px',
              }}
            />
          ),
        }))
        .sort((a, b) => (b.selected ? 1 : -1) - (a.selected ? 1 : -1)) ?? [],
    [noteLabelsData?.noteLabels, res.data?.findNoteById],
  )

  if (!res.data || !usersRes.data || !noteLabelsData) return null

  const note = res.data.findNoteById
  const users = usersRes.data.findUserNotePermission

  const updateNoteMutation = async (
    value: Pick<NoteDetailViewNoteDetailFragment, 'title' | 'body' | 'attachments' | 'permission'>,
  ) => {
    if (!res.data) {
      return
    }
    await updateNote({
      variables: {
        id: note.id,
        title: value.title,
        body: {
          treeJson: value.body?.treeJson || '',
          plainText: value.body?.plainText || '',
        },
        attachments: value.attachments.map((attachment) => convertToFileInput(attachment)),
        objectiveId: isObjective(note.resource) ? note.resource.id : null,
        keyResultId: isKeyResult(note.resource) ? note.resource.id : null,
        permission: value.permission,
      },
    })
  }

  const attachNoteLabelMutation = async (noteLabelId: string) => {
    await attachNoteLabel({
      variables: { noteId, noteLabelId },
      update(cache) {
        const option = { query: FindNoteByIdDocument, variables: { id: noteId } }
        const { findNoteById } =
          cache.readQuery<FindNoteByIdQuery, FindNoteByIdQueryVariables>(option) || {}
        if (!findNoteById) return

        const label = noteLabelsData?.noteLabels.find((l) => l.id === noteLabelId)
        if (!label) return
        if (findNoteById.labels.find((l) => l.id === label.id) != null) return

        cache.writeQuery<FindNoteByIdQuery, FindNoteByIdQueryVariables>({
          ...option,
          data: {
            findNoteById: {
              ...findNoteById,
              labels: [...findNoteById.labels, label],
            },
          },
        })
      },
    }).catch(() => {})
  }

  const detachNoteLabelMutation = async (noteLabelId: string) => {
    await detachNoteLabel({
      variables: { noteId, noteLabelId },
      update(cache) {
        const option = { query: FindNoteByIdDocument, variables: { id: noteId } }
        const { findNoteById } =
          cache.readQuery<FindNoteByIdQuery, FindNoteByIdQueryVariables>(option) || {}
        if (!findNoteById) {
          return
        }

        cache.writeQuery<FindNoteByIdQuery, FindNoteByIdQueryVariables>({
          ...option,
          data: {
            findNoteById: {
              ...findNoteById,
              labels: findNoteById.labels.filter((e) => e.id !== noteLabelId),
            },
          },
        })
      },
    }).catch(() => {})
  }

  const onClickLabelOption = (id: string) => {
    if (note.labels.some((label) => label.id === id)) {
      detachNoteLabelMutation(id)
    } else {
      attachNoteLabelMutation(id)
    }
  }

  return (
    <NoteOption
      note={note}
      userId={user?.id}
      permissionUsers={users}
      labelOptions={labelOptions}
      onClickLabelOption={onClickLabelOption}
      updateNoteMutation={updateNoteMutation}
    />
  )
}

NoteOptionContainer.displayName = 'NoteOptionContainer'
