import { RefObject, useEffect, useState } from 'react'
import { useIsomorphicLayoutEffect } from 'react-use'
import { Editor, Text } from 'slate'
import { ReactEditor } from 'slate-react'

const DELTA_Y = 0
const DELTA_X = -8

const calcSelectionPosition = (editor: Editor): { top: number; left: number } => {
  if (!editor.selection) {
    return { top: DELTA_Y, left: DELTA_X }
  }
  try {
    const selected = editor.children[editor.selection.anchor.path[0]]
    const selection = ReactEditor.toDOMNode(editor, selected)
    return { top: selection.offsetTop + DELTA_Y, left: DELTA_X }
  } catch (e) {
    return { top: DELTA_Y, left: DELTA_X }
  }
}

const setPositionAtSelection = (
  targetRef: RefObject<HTMLDivElement>,
  editor: Editor | undefined,
): void => {
  if (!targetRef.current || !editor || !editor.selection) {
    return
  }
  const domSelection = window.getSelection()
  if (!domSelection || domSelection.rangeCount < 1) {
    return
  }
  const { top, left } = calcSelectionPosition(editor)
  targetRef.current.style.top = `${top}px`
  targetRef.current.style.left = `${left}px`
}

export const useToolbarMove = ({
  editor,
  ref,
}: {
  editor: Editor | undefined
  ref: RefObject<HTMLDivElement>
}): void => {
  const [previousRect, setPreviousRect] = useState<readonly [number, number]>([0, 0])

  useEffect(() => {
    if (!ref.current) {
      return
    }

    // 1文字でも入力されていたら非表示になるためそもそも計算しない
    if (!editor?.selection) {
      return
    }

    try {
      const [node] = Editor.node(editor, editor.selection)
      if (Text.isText(node) && node.text !== '') {
        return
      }

      const domSelection = window.getSelection()
      if (!domSelection || domSelection.rangeCount < 1) {
        return
      }
      const { top, left } = calcSelectionPosition(editor)
      setPreviousRect([top, left])
    } catch (e) {
      // nothing to do
    }
  }, [editor, editor?.selection, ref])

  useEffect(() => {
    if (!ref.current) {
      return
    }

    const [top, left] = previousRect
    ref.current.style.top = `${top}px`
    ref.current.style.left = `${left}px`
  }, [ref, previousRect])

  useIsomorphicLayoutEffect(() => {
    const handleScroll = () => setPositionAtSelection(ref, editor)
    window.addEventListener('scroll', handleScroll, true)
    return () => window.removeEventListener('scroll', handleScroll, true)
  }, [ref, editor])
}
