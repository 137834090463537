import { useCallback } from 'react'

import { useTranslation } from '../../../../../i18n'
import { findScreen } from '../../../../../lib/screen'
import { tracker } from '../../../../../lib/tracking'
import { generateNoteEdit } from '../../../../../urls'
import { NotesWithCreate } from '../../Common/NotesWithCreate'
import { useOkrModalQueryParams } from '../../hooks/useOkrModalQueryParams'

import { NotePermission, useCreateObjectiveNoteMutation, useFetchOkrNotesQuery } from './graphql'

export const NoteContainer: React.VFC = () => {
  const { t } = useTranslation()

  const { objectiveId } = useOkrModalQueryParams()

  const [createNote] = useCreateObjectiveNoteMutation()
  const handleCreateObjectiveNote = useCallback(async () => {
    const result = await createNote({
      variables: {
        input: {
          title: t('EMPTY_NOTE'),
          objectiveId,
          permission: NotePermission.Public,
          attachments: [],
          labelIds: [],
          userIds: [],
        },
      },
    })
    if (!result.data) return
    window.open(generateNoteEdit(result.data.createNote.id))
    tracker.UserClickCreatePersonalNoteByObjectiveDrawer(
      findScreen(window.location.pathname, window.location.search),
    )
  }, [createNote, objectiveId, t])

  const { data, loading } = useFetchOkrNotesQuery({
    variables: { objectiveId },
    skip: objectiveId == null,
  })

  return (
    <NotesWithCreate
      loading={loading && data == null}
      notes={data?.okrNode.notes}
      onClickCreateNote={handleCreateObjectiveNote}
    />
  )
}
NoteContainer.displayName = 'NoteContainer'
