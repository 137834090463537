import React from 'react'

import { LoadableProps } from '../../types'

import { DescriptionArea, DataProps as DescriptionAreaProps } from './DescriptionArea'
import { HeaderArea, DataProps as HeaderDataProps, Props as HeaderProps } from './HeaderArea'

export type DataProps = HeaderDataProps

export type Props = LoadableProps<DataProps> &
  Pick<HeaderProps, 'onDelete' | 'onDisable'> &
  Pick<DescriptionAreaProps, 'saveDescription'>

export const ObjectiveDescription: React.VFC<Props> = ({
  loading,
  objective,
  groups,
  saveDescription,
  onDelete,
  onDisable,
}) => (
  <>
    {/* FIXME: neverにキャストしてるのやめる */}
    <HeaderArea
      loading={loading as never}
      objective={objective as never}
      groups={groups as never}
      onDelete={onDelete}
      onDisable={onDisable}
    />
    <DescriptionArea
      loading={loading as never}
      objectiveId={objective?.id as never}
      description={objective?.description}
      attachmentViews={objective?.attachments as never}
      saveDescription={saveDescription as never}
    />
  </>
)
