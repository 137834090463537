import { useTranslation } from '../../../i18n'
import { formatDateInput } from '../../../lib/date'
import { color, ColorAlias } from '../../../styles/newColors'
import { generateNote } from '../../../urls'
import { AvatarWithName } from '../../ui/AvatarWithName'
import { Icon, Type as IconType } from '../../ui/Icon'
import { Link, LinkMethod } from '../../ui/Link'
import { StyledText } from '../../ui/StyledText'

import { useStyles } from './NoteCard.styles'
import { NoteCardSkeleton } from './NoteCardSkeleton'
import { NoteFragment, NotePermission } from './graphql'

const getIconType = (value: NotePermission): IconType => {
  switch (value) {
    case NotePermission.Public:
      return 'mdiPublic'

    case NotePermission.Private:
      return 'group'

    case NotePermission.OwnerOnly:
      return 'mdiLock'

    default:
      return fail(value)
  }
}

export type Props = {
  note: NoteFragment
  lineColor: ColorAlias | undefined
  avatarLinkMethod?: LinkMethod
}

export type NoteCardComposition = {
  Skeleton: typeof NoteCardSkeleton
}

export const NoteCard: React.FC<Props> & NoteCardComposition = ({
  note: { id, title, permission, updatedAt, author: user, okrTerm },
  lineColor,
  avatarLinkMethod,
}) => {
  const { t } = useTranslation()
  const styles = useStyles({ lineColor })

  return (
    <div css={styles.root}>
      <div css={styles.wrapper}>
        {lineColor && <div css={styles.lineColor} />}
        <div css={styles.content}>
          <Link href={generateNote(id)} method="newTab" css={styles.title}>
            <StyledText isWrap css={styles.titleText}>
              {title}
            </StyledText>
          </Link>
          <div css={styles.permissionWrapper}>
            <Icon
              color={color('text-bk-50')}
              type={getIconType(permission)}
              css={styles.permissionIcon}
            />
            <StyledText size="xsmall" color="text-bk-50" lineHeight="14px">
              {t(permission)}
            </StyledText>
          </div>
          <div css={styles.avatarWrapper}>
            <AvatarWithName
              size="xsmall"
              nameFontSize="small"
              firstName={user.firstName}
              lastName={user.lastName}
              avatarUrl={user.avatar?.url}
              linkage={{
                userId: user.id,
                termId: okrTerm.id,
                method: avatarLinkMethod,
              }}
              isUserDisabled={user.isDisabled}
              linkTextMaxWidth="300px"
              fontStyle={{
                fontSize: '12px',
                lineHeight: '14px',
              }}
            />

            <StyledText color="text-bk-50" size="xsmall" css={styles.updatedAt}>
              {`${t('UPDATION')} ${formatDateInput(updatedAt)}`}
            </StyledText>
          </div>
        </div>
      </div>
    </div>
  )
}

NoteCard.Skeleton = NoteCardSkeleton
NoteCard.displayName = 'NoteCard'
