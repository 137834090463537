import { Image, ImageProps } from '@resily/geisha'
import {
  findNodePath,
  getImageElementStyles,
  getRootProps,
  ImageElementProps as OrgImageElementProps,
  Box,
  PlatePluginComponent,
  select,
  setNodes,
  TImageElement,
} from '@udecode/plate'
import { Resizable, ResizableProps } from 're-resizable'
import { useCallback, useEffect, useState } from 'react'
import { useFocused, useReadOnly, useSelected } from 'slate-react'

export type ImageElementProps = Pick<ImageProps, 'maxWidth' | 'maxHeight'>

export const ImageElement: PlatePluginComponent = (props: OrgImageElementProps) => {
  const {
    attributes,
    children,
    element,
    nodeProps,
    resizableProps = {
      minWidth: 92,
    },
    styles,
    align = 'center',
    draggable,
    editor,
    ignoreReadOnly = false,
  } = props

  const rootProps = getRootProps(props)

  const { url, width: nodeWidth = '100%' } = element

  const focused = useFocused()
  const selected = useSelected()
  const readOnly = useReadOnly()
  const [width, setWidth] = useState(nodeWidth)

  const resizeProps: ResizableProps =
    !ignoreReadOnly && readOnly
      ? {
          ...resizableProps,
          enable: {
            left: false,
            right: false,
            top: false,
            bottom: false,
            topLeft: false,
            bottomLeft: false,
            topRight: false,
            bottomRight: false,
          },
        }
      : { ...resizableProps }

  useEffect(() => {
    setWidth(nodeWidth)
  }, [nodeWidth])

  const imageElementStyles = getImageElementStyles({ ...props, align, focused, selected })

  const setNodeWidth = useCallback(
    (w: number) => {
      const path = findNodePath(editor, element)
      if (!path) return

      if (w === nodeWidth) {
        // Focus the node if not resized
        select(editor, path)
      } else {
        setNodes<TImageElement>(editor, { width: w }, { at: path })
      }
    },
    [editor, element, nodeWidth],
  )

  return (
    <div {...attributes} css={styles?.root} {...nodeProps} {...rootProps}>
      <div contentEditable={false}>
        <Resizable
          css={imageElementStyles.resizable?.css}
          className={imageElementStyles.resizable?.className}
          size={{ width, height: '100%' }}
          maxWidth="100%"
          lockAspectRatio
          resizeRatio={align === 'center' ? 2 : 1}
          enable={{
            left: ['center', 'left'].includes(align),
            right: ['center', 'right'].includes(align),
          }}
          handleComponent={{
            left: (
              <Box
                css={[imageElementStyles.handleLeft?.css]}
                className={imageElementStyles.handleLeft?.className}
              />
            ),
            right: (
              <Box
                css={imageElementStyles.handleRight?.css}
                className={imageElementStyles.handleRight?.className}
              />
            ),
          }}
          handleStyles={{
            left: { left: 0 },
            right: { right: 0 },
          }}
          onResize={(e, direction, ref) => {
            setWidth(ref.offsetWidth)
          }}
          onResizeStop={(e, direction, ref) => setNodeWidth(ref.offsetWidth)}
          {...resizeProps}
        >
          {readOnly ? (
            <Image
              type="enlarged"
              src={url}
              maxWidth={styles?.img.maxWidth}
              maxHeight={styles?.img.maxHeight}
            />
          ) : (
            <Image
              type="focused"
              src={url}
              focused={selected}
              draggable={draggable}
              maxWidth={styles?.img.maxWidth}
              maxHeight={styles?.img.maxHeight}
            />
          )}
        </Resizable>
      </div>
      {children}
    </div>
  )
}
