import { focusEditor, usePlateEditorState, useEventEditorSelectors } from '@udecode/plate'
import { Box, Button } from 'grommet'
import { useCallback } from 'react'

export type Props = {
  disabled?: boolean
  isActive: boolean
  toggleNode: () => void
}

export const ToolbarButton: React.FC<Props> = ({
  children,
  disabled = false,
  isActive,
  toggleNode,
}) => {
  const focusId = useEventEditorSelectors.focus()
  const editor = usePlateEditorState(focusId != null ? focusId : undefined)

  return (
    <Box
      data-disabled={disabled}
      align="center"
      justify="center"
      css={{
        '&[data-disabled="false"]:hover': {
          background: 'rgb(119 125 129)',
        },
        transition: 'background 120ms ease-in 0s',
      }}
    >
      <Button
        disabled={disabled}
        data-isactive={isActive}
        css={{
          padding: '8px',
          color: 'rgb(255 255 255 / 90%)',
          '&[data-isactive="true"]': { color: 'rgb(46 170 220)' },
        }}
        onMouseDown={useCallback(
          (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            e.preventDefault()

            toggleNode()

            if (editor?.selection) {
              focusEditor(editor)
            }
          },
          [editor, toggleNode],
        )}
      >
        {children}
      </Button>
    </Box>
  )
}
