import { css } from '@emotion/react'
import { Box, Button } from 'grommet'
import React, { memo, useCallback, useMemo } from 'react'
import isEqual from 'react-fast-compare'

import { BorderdButton } from '../../../components/ui/BorderdButton'
import { Icon } from '../../../components/ui/Icon'
import { StyledText } from '../../../components/ui/StyledText'
import {
  ThreePointsWithMenu,
  Props as ThreePointsWithMenuProps,
} from '../../../components/ui/ThreePointsWithMenu'
import { useTranslation } from '../../../i18n'
import { findScreen } from '../../../lib/screen'
import { tracker } from '../../../lib/tracking'
import { useNetworkStatus } from '../../../lib/useNetworkStatus'
import { color } from '../../../styles/newColors'
import { ExternalUrls } from '../../../urls'

import { MeetingAvatars, Props as MeetingAvatarsProps } from './MeetingAvatars'
import { MeetingTitleSelect, Props as MeetingTitleSelectProps } from './MeetingTitleSelect'

export const HEIGHT = 90

const POPUP_WIDTH = 420
const POPUP_HEIGHT = 680
const POPUP_SCREEN_X = window.screenX + (window.screen.availWidth - POPUP_WIDTH) - 40
const POPUP_SCREEN_Y = 240

const headerCss = css({
  position: 'relative',
  zIndex: 1,
  height: `${HEIGHT}px`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0 43px 0 32px',
  boxShadow: '0px 1px 0px rgb(0 0 0 / 10%)',
})

const oneOnOneHistoryLinkCss = css({
  padding: '16px',
  display: 'flex',
  alignItems: 'center',
  gap: '5.75px',
  ':hover': {
    span: {
      color: color('resily-orange-100'),
    },
    svg: {
      path: {
        fill: color('resily-orange-100'),
      },
    },
  },
})

export type Props = {
  currentMeeting: MeetingTitleSelectProps['currentMeeting']
  meetings: MeetingTitleSelectProps['meetings']
  users: MeetingAvatarsProps['users']
  isShowOneOnOneHistoryLink: boolean
  oneOnOneHistoryUrl: string
  onClickAvatar: MeetingAvatarsProps['onClickAvatar']
  onClickTitleItem: MeetingTitleSelectProps['onClickSelectItem']
  onClickDeleteMeeting: () => void
  onTitleSelectReachEnd: () => void
  onClickUpdateMeeting: () => void
}

export const Header: React.VFC<Props> = memo(
  ({
    currentMeeting,
    meetings,
    users,
    isShowOneOnOneHistoryLink,
    oneOnOneHistoryUrl,
    onClickTitleItem,
    onClickAvatar,
    onClickDeleteMeeting,
    onTitleSelectReachEnd,
    onClickUpdateMeeting,
  }) => {
    const { t } = useTranslation()

    const menuItems: ThreePointsWithMenuProps['popoverItems'] = useMemo(
      () => [
        {
          label: t('DELETION'),
          icon: <Icon width={12} height={12} type="garbage" />,
          onItemClicked: () => {
            onClickDeleteMeeting()
          },
        },
        {
          label: t('ABOUT_VIEW_PERMISSION'),
          icon: <Icon width={12} height={12} type="help" />,
          onItemClicked: () => {
            window.open(ExternalUrls.ONE_ON_ONE_VIEW_PERMISSION)
          },
        },
      ],
      [onClickDeleteMeeting, t],
    )

    const openOneOnOneHistory = useCallback(
      (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation()
        tracker.UserClickOneOnOneHistoryLink(findScreen(window.location.pathname))
        const option = `width=${POPUP_WIDTH},height=${POPUP_HEIGHT},screenX=${POPUP_SCREEN_X},screenY=${POPUP_SCREEN_Y}`
        window.open(oneOnOneHistoryUrl, '_blank', option)
      },
      [oneOnOneHistoryUrl],
    )

    return (
      <header css={headerCss}>
        <MeetingTitleSelect
          currentMeeting={currentMeeting}
          meetings={meetings}
          width="320px"
          onClickSelectItem={onClickTitleItem}
          onReachEnd={onTitleSelectReachEnd}
        />
        {isShowOneOnOneHistoryLink && (
          <Button onClick={openOneOnOneHistory} css={oneOnOneHistoryLinkCss}>
            <Icon type="openInNew" width={10.5} height={10.5} />
            <StyledText size="medium" color="text-bk-50">
              {t('ONEONONE_HISTORY')}
            </StyledText>
          </Button>
        )}
        <div css={{ flexGrow: 1, margin: '0 44px' }}>
          <OfflineCaution />
        </div>
        <Box direction="row" gap="10px">
          <MeetingAvatars users={users} onClickAvatar={onClickAvatar} />
          <BorderdButton
            text={t('CREATE_NEXT_ONEONONE')}
            isShow
            css={{ padding: '10px 16px' }}
            onClick={onClickUpdateMeeting}
          />
          <ThreePointsWithMenu
            popoverItems={menuItems}
            popoverAlign={{ top: 'bottom', right: 'right' }}
          />
        </Box>
      </header>
    )
  },
  (prev, next) => {
    if (!isEqual(prev.currentMeeting, next.currentMeeting)) {
      return false
    }
    if (!isEqual(prev.meetings, next.meetings)) {
      return false
    }
    if (!isEqual(prev.users, next.users)) {
      return false
    }
    return true
  },
)

Header.displayName = 'Header'

const OfflineCaution: React.VFC = () => {
  const { online } = useNetworkStatus()
  const { t } = useTranslation()

  return !online ? (
    <div css={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
      <div css={{ width: 16 }}>
        <Icon type="offline" />
      </div>
      <StyledText size="small" fontStyle="regular" color="text-bk-50">
        {t('COLLABORATION_OFFLINE_CAUTION')}
      </StyledText>
    </div>
  ) : null
}

OfflineCaution.displayName = 'OfflineCaution'
