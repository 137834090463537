import { useTranslation } from '../../../../../../i18n'
import { DisabledUserTableFragment } from '../../../../api/graphql'
import { DisabledUserTableDatum } from '../types'

export const useMakeDisabledUserData = (
  users: DisabledUserTableFragment['disableUsers'],
): Array<DisabledUserTableDatum> => {
  const { t } = useTranslation()

  return users.map(
    (u): DisabledUserTableDatum => ({
      id: u.id,
      fullName: t('FULL_NAME', { firstName: u.firstName, lastName: u.lastName }),
      email: u.email,
      admin: u.admin,
      // タイムスタンプで正確にソートを行えるようにunix timestampに変換してDataTableに渡す
      // レンダリング時にunix timestamp to Date型へ変換する
      lastLogin: u.currentSignInAt ? Math.floor(new Date(u.currentSignInAt).getTime() / 1000) : 0,
    }),
  )
}
