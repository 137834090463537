import { ActionPlanStatus } from '../../../graphql'
import { useTranslation } from '../../../i18n/index'
import { border } from '../../../styles/border'
import { color } from '../../../styles/newColors'
import { StyledText } from '../../ui/StyledText'
import { ActionPlanStatusBadge } from '../ActionPlanStatusBadge/index'

export type Props = {
  onSelect: (
    status: ActionPlanStatus,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void
  isDisplayTitle?: boolean
}

export const ActionPlanStatusSelect: React.FC<Props> = ({ onSelect, isDisplayTitle = true }) => {
  const { t } = useTranslation()
  return (
    <div
      css={[
        {
          backgroundColor: color('white-100'),
          width: '300px',
          border: border('simple-30'),
          boxShadow: '0px 8px 16px rgb(34 41 67 / 8%)',
          borderRadius: '4px',
          textAlign: 'center',
        },
        !isDisplayTitle && {
          padding: '12px 0',
        },
      ]}
    >
      {isDisplayTitle && (
        <div
          css={{
            height: '44px',
            borderBottom: border('simple-30'),
          }}
        >
          <StyledText css={{ lineHeight: '44px' }}>{t('STATUS')}</StyledText>
        </div>
      )}
      <div>
        {[
          ActionPlanStatus.NotStarted,
          ActionPlanStatus.Started,
          ActionPlanStatus.Completed,
          ActionPlanStatus.Held,
        ].map((status) => (
          <button
            type="button"
            onClick={(e) => onSelect(status, e)}
            css={{
              height: '40px',
              width: '100%',
              textAlign: 'left',
              paddingLeft: '16px',
              margin: '0 auto',
              ':hover': { backgroundColor: color('hover-background-bk-5') },
            }}
          >
            <ActionPlanStatusBadge status={status} />
          </button>
        ))}
      </div>
    </div>
  )
}

ActionPlanStatusSelect.displayName = 'ActionPlanStatusSelect'
