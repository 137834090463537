import { css } from '@emotion/react'

import { StyledText } from '../../../../components/ui/StyledText'
import { color } from '../../../../styles/newColors'

const titleCss = css({
  fontSize: '12px',
  fontWeight: 'bold',
  lineHeight: '40px',
})
const requiredMark = css({
  fontSize: '12px',
  fontWeight: 'bold',
  lineHeight: '40px',
  color: color('resily-orange-100'),
})

export const SectionTitle: React.FC = ({ children }) => (
  <StyledText css={titleCss}>
    {children}
    <span css={requiredMark}>*</span>
  </StyledText>
)

SectionTitle.displayName = 'SectionTitle'
