import { TFunction } from '../../../i18n'

import { HistoryCommentFragment, KeyResultProgressRateItem } from './graphql'

export const message = (t: TFunction, history: HistoryCommentFragment): string => {
  if (history.item === KeyResultProgressRateItem.ProgressRateAggregation) {
    return t('PROGRESS_RATE_AGGREGATION_COMMENT')
  }
  return history.message || ''
}
