import { File } from '../../../graphql'

import { Error as ProgressRateInputError } from './ProgressRateInput/types'
import { CreateKeyResultInput, UpdateKeyResultInfoInput } from './graphql'

export const keyResultEditTypeObj = {
  create: 'create',
  update: 'update',
} as const
export type KeyResultEditType = keyof typeof keyResultEditTypeObj

export type ExtendedCreateKeyResultInput = Omit<CreateKeyResultInput, 'attachments'> & {
  keyResultId: string
  // mutationのattachmentsだと表示用オブジェクトとして必要なURLが不足しているため、除外した上で追加
  attachmentViews: ReadonlyArray<File>
}

export type ExtendedUpdateKeyResultInfoInput = Omit<UpdateKeyResultInfoInput, 'attachments'> & {
  // attachmentsだと表示用オブジェクトとして必要なURLが不足しているため、除外した上で追加
  attachmentViews: ReadonlyArray<File>
}

export type KeyResultEditFieldsInput =
  | ExtendedCreateKeyResultInput
  | ExtendedUpdateKeyResultInfoInput

export type CreateKeyResultError = Omit<ProgressRateInputError, 'actualValue'>

export type UpdateKeyResultError = ProgressRateInputError

export type Error = ProgressRateInputError | UpdateKeyResultError
