import {
  ELEMENT_BLOCKQUOTE,
  ELEMENT_CODE_BLOCK,
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_H4,
  ELEMENT_H5,
  ELEMENT_H6,
  ELEMENT_HR,
  ELEMENT_IMAGE,
  ELEMENT_LIC,
  ELEMENT_LINK,
  ELEMENT_OL,
  ELEMENT_PARAGRAPH,
  ELEMENT_UL,
  MARK_BOLD,
  MARK_CODE,
  MARK_ITALIC,
  MARK_STRIKETHROUGH,
  Value,
} from '@udecode/plate'
import { serialize, InputNodeTypes, NodeTypes } from 'remark-plate'

// NOTE: NodeTypesのキーはこちらを参照
//       https://github.com/hanford/remark-slate/blob/master/src/ast-types.ts
const nodeTypes: InputNodeTypes = {
  paragraph: ELEMENT_PARAGRAPH,
  block_quote: ELEMENT_BLOCKQUOTE,
  code_block: ELEMENT_CODE_BLOCK,
  link: ELEMENT_LINK,
  // FIXME: typeとして利用するキーを渡すだけではIndentableListに対応出来ないためremark-slateに対して改善が必要
  ul_list: ELEMENT_UL,
  // FIXME: typeとして利用するキーを渡すだけではIndentableListに対応出来ないためremark-slateに対して改善が必要
  ol_list: ELEMENT_OL,
  // FIXME: typeとして利用するキーを渡すだけではIndentableListに対応出来ないためremark-slateに対して改善が必要
  listItem: ELEMENT_LIC,
  heading: {
    1: ELEMENT_H1,
    2: ELEMENT_H2,
    3: ELEMENT_H3,
    4: ELEMENT_H4,
    5: ELEMENT_H5,
    6: ELEMENT_H6,
  },
  emphasis_mark: MARK_ITALIC,
  strong_mark: MARK_BOLD,
  delete_mark: MARK_STRIKETHROUGH,
  inline_code_mark: MARK_CODE,
  thematic_break: ELEMENT_HR,
  image: ELEMENT_IMAGE,
}

// See: https://github.com/hanford/remark-slate/issues/25
type Chunk = Parameters<typeof serialize>[0]

export const serializeMarkdown = (nodes: Value): string =>
  nodes.map((v) => serialize(v as Chunk, { nodeTypes: nodeTypes as NodeTypes })).join('\n')
