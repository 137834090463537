import { useMemo, useState, VFC } from 'react'

import { Button } from '../../../../../components/ui/Button'
import { Icon } from '../../../../../components/ui/Icon'
import {
  ThreePointsWithMenu,
  Props as ThreePointsWithMenuProps,
} from '../../../../../components/ui/ThreePointsWithMenu'
import { useTranslation } from '../../../../../i18n'

type Props = {
  isCopyButtonLoading: boolean
  onClickEdit: () => void
  onClickCopy: () => void
  onClickFileExport: () => Promise<void>
  onClickDelete: () => void
}

export const AdminOkrTermsPageTermTableButtonCell: VFC<Props> = ({
  isCopyButtonLoading,
  onClickEdit,
  onClickCopy,
  onClickFileExport,
  onClickDelete,
}) => {
  const { t } = useTranslation()

  const [, setIsDownloadButtonLoading] = useState(false)

  const menuItems: ThreePointsWithMenuProps['popoverItems'] = useMemo(
    () => [
      {
        label: t('EXPORT_FILE'),
        icon: <Icon width={12} height={12} type="download" />,
        onItemClicked: () => {
          setIsDownloadButtonLoading(true)
          onClickFileExport().finally(() => {
            setIsDownloadButtonLoading(false)
          })
        },
      },
      {
        label: t('DELETION'),
        icon: <Icon width={12} height={12} type="deleteOutline" />,
        onItemClicked: () => {
          onClickDelete()
        },
      },
    ],
    [onClickDelete, onClickFileExport, t],
  )

  return (
    <div
      css={{
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center',
        gap: '16px',
      }}
    >
      <Button
        className="show-done-button"
        newColor="white-100"
        weight="normal"
        size="ss"
        disableFocusStyle
        onClick={(e) => {
          e.stopPropagation()
          onClickEdit()
        }}
        css={{
          position: 'static',
        }}
      >
        {t('EDITION')}
      </Button>
      <Button
        className="show-done-button"
        newColor="white-100"
        weight="normal"
        size="ss"
        disableFocusStyle
        isLoading={isCopyButtonLoading}
        onClick={(e) => {
          e.stopPropagation()
          onClickCopy()
        }}
        css={{
          position: 'static',
        }}
      >
        {t('DUPLICATION')}
      </Button>
      <ThreePointsWithMenu
        popoverItems={menuItems}
        popoverAlign={{ top: 'bottom', right: 'right' }}
      />
    </div>
  )
}

AdminOkrTermsPageTermTableButtonCell.displayName = 'AdminOkrTermsPageTermTableButtonCell'
