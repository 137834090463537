import React from 'react'

import { Icon } from '../../../../../../../components/ui/Icon'
import { StyledText } from '../../../../../../../components/ui/StyledText'
import { useTranslation } from '../../../../../../../i18n'
import { labelRed } from '../../../../../../../styles/colors'
import { color } from '../../../../../../../styles/newColors'

export const KeyResultWeightSettingErrorMessage: React.VFC = () => {
  const { t } = useTranslation()

  return (
    <div
      css={{
        background: color('hover-background-bk-5'),
        border: `1px solid ${color('border-bk-30')}`,
        padding: '10px 16px',
      }}
    >
      <div css={{ display: 'flex', alignItems: 'center' }}>
        <div>
          <Icon type="warning" color={labelRed} css={{ verticalAlign: 'baseline' }} />
        </div>
        <StyledText
          size="small"
          css={{
            lineHeight: '20px',
            color: labelRed,
            fontWeight: 'bold',
            marginTop: '-4px',
            marginLeft: '5px',
          }}
        >
          {t('SETTING_NOT_COMPLETED')}
        </StyledText>
      </div>
      <div>
        <StyledText size="small" css={{ lineHeight: '20px', color: color('text-bk-100') }}>
          {t('SETTING_100_PERCENT')}
        </StyledText>
      </div>
    </div>
  )
}

KeyResultWeightSettingErrorMessage.displayName = 'KeyResultWeightSettingErrorMessage'
