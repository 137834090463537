/* eslint-disable rulesdir/require-files */
import { useCallback, useRef } from 'react'
import { v1 as uuid } from 'uuid'

import { useTranslation } from '../../../i18n'
import { progressRateVariables } from '../../../lib/domain/keyResult/keyResult'
import { convertToFileInput } from '../../../lib/fileInput'
import { StrictPropertyCheck } from '../../../lib/type'
import { TextButton } from '../../ui/TextButton'
import { isFilledRequiredFields } from '../KeyResultEditFields/functions'
import { CreateKeyResultInput, TargetSettingType } from '../KeyResultEditFields/graphql'
import { useKeyResultEditFields } from '../KeyResultEditFields/hooks'
import { ExtendedCreateKeyResultInput } from '../KeyResultEditFields/types'
import { ParentOkrBar, Props as ParentOkrBarProps } from '../ParentOkrBar'

import { Errors, useFormValidation } from './hooks/useFormValidation'
import { useStyles } from './index.styles'

export const generateInitialKeyResultState = (
  ownerId: string,
  okrTermId: string,
  parentObjectiveId: string,
): ExtendedCreateKeyResultInput => ({
  okrTermId,
  parentObjectiveId,
  keyResultId: uuid(),
  name: '',
  ownerId,
  targetSetting: TargetSettingType.None,
  confidence: 0,
  memberIds: [],
  attachmentViews: [],
})

export type KeyResultCreateModalContentProps = {
  objective: ParentOkrBarProps['currentObjective']
  keyResultStates: ReadonlyArray<ExtendedCreateKeyResultInput>
  onChangeKeyResults: (krs: ReadonlyArray<ExtendedCreateKeyResultInput>) => void
  errors: Errors
}

export type ReturnObj = {
  KeyResultCreateModalContent: React.VFC<KeyResultCreateModalContentProps>
  validate: ReturnType<typeof useFormValidation>['validate']
  canSave: (krState: ReadonlyArray<ExtendedCreateKeyResultInput>) => boolean
  convertStateToInput: (
    krState: ReadonlyArray<ExtendedCreateKeyResultInput>,
  ) => ReadonlyArray<CreateKeyResultInput>
  AddKRButton: React.VFC<Pick<JSX.IntrinsicElements['button'], 'disabled' | 'onClick'>>
  errors: Errors
}

export const useKeyResultCreateModalContent = (): ReturnObj => {
  const { t } = useTranslation()
  const styles = useStyles()

  const lastKeyResultItemRef = useRef<HTMLLIElement | null>()
  const KeyResultEditFields = useKeyResultEditFields()

  const { validate, errors: validateErrors } = useFormValidation()

  const KeyResultCreateModalContent = useCallback<ReturnObj['KeyResultCreateModalContent']>(
    ({ objective, keyResultStates, onChangeKeyResults, errors }) => (
      <>
        <ParentOkrBar currentObjective={objective} />
        <ul css={styles.keyResultList}>
          {keyResultStates.map((kr, i) => (
            <li
              key={kr.keyResultId}
              ref={(r) => {
                if (i === keyResultStates.length - 1) {
                  lastKeyResultItemRef.current = r
                }
              }}
            >
              {keyResultStates.length > 1 && (
                <div css={styles.deleteKeyResultButtonArea}>
                  <TextButton
                    icon="deleteOutline"
                    color="text-bk-30"
                    padding="0 48px 0"
                    css={styles.deleteKeyResultButton}
                    onClick={() =>
                      onChangeKeyResults(
                        keyResultStates.filter((pKr) => pKr.keyResultId !== kr.keyResultId),
                      )
                    }
                  >
                    {t('DELETE_X', { x: t('KEY_RESULT') })}
                  </TextButton>
                </div>
              )}
              <KeyResultEditFields
                type="create"
                initialKeyResult={kr}
                error={errors[i]}
                onChangeKeyResult={(newKr) => {
                  const next: Array<typeof keyResultStates[0]> = [...keyResultStates]
                  next[i] = {
                    ...newKr,
                    ...progressRateVariables({
                      initialValue: newKr.initialValue,
                      targetValue: newKr.targetValue,
                      unit: newKr.unit,
                    }),
                  }
                  onChangeKeyResults(next)
                }}
              />
            </li>
          ))}
        </ul>
      </>
    ),
    [
      KeyResultEditFields,
      styles.deleteKeyResultButton,
      styles.deleteKeyResultButtonArea,
      styles.keyResultList,
      t,
    ],
  )

  const canSave = useCallback(
    (krState: ReadonlyArray<ExtendedCreateKeyResultInput>) =>
      krState.every((kr) => isFilledRequiredFields(kr, kr.parentObjectiveId)) && validate(krState),
    [validate],
  )

  const convertStateToInput = useCallback(
    (krState: ReadonlyArray<ExtendedCreateKeyResultInput>) =>
      krState.map<CreateKeyResultInput>((kr) => {
        // NOTE: keyResultIdは内部用の値なので削る
        // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
        const { keyResultId: _, attachmentViews, ...rest } = kr
        const result = {
          ...rest,
          attachments:
            attachmentViews.map<
              StrictPropertyCheck<
                ReturnType<typeof convertToFileInput>,
                CreateKeyResultInput['attachments'][0]
              >
            >(convertToFileInput),
        }
        const checkResult: StrictPropertyCheck<typeof result, CreateKeyResultInput> = result
        return checkResult
      }),
    [],
  )

  const AddKRButton = useCallback<ReturnObj['AddKRButton']>(
    ({ disabled, onClick }) => (
      <TextButton
        color="text-bk-30"
        icon="plus"
        iconSize={14}
        padding="0"
        style={{ display: 'flex', alignItems: 'center' }}
        disabled={disabled}
        onClick={(e) => {
          onClick?.(e)
          // DOM更新後に実行するため0msを指定
          setTimeout(() => {
            lastKeyResultItemRef.current?.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
              inline: 'start',
            })
          }, 0)
        }}
      >
        {t('CONTINUE_CREATE_KEY_RESULT')}
      </TextButton>
    ),
    [t],
  )

  return {
    KeyResultCreateModalContent,
    canSave,
    validate,
    convertStateToInput,
    AddKRButton,
    errors: validateErrors,
  }
}
