import { Box } from 'grommet'
import React, { useContext, useMemo } from 'react'

import { Breadcrumb, BreadcrumbParams } from '../../components/domain/Breadcrumb'
import { PageContent } from '../../components/pageContent'
import { OrganizationContext } from '../../contexts/OrganizationContext'
import { OrganizationFragment } from '../../contexts/graphql'
import { useTranslation } from '../../i18n'
import { Screen } from '../../lib/screen'
import { useTracking } from '../../lib/tracking'
import { color } from '../../styles/newColors'
import { AuthRouteProps } from '../../types/authRouteProps'
import { oneOnOneAdmin } from '../../urls'

import { Card } from './components/Card'
import { FixedAgendaContent } from './components/FixedAgendaContent'
import { OneOnOneEnabledContent } from './components/OneOnOneEnabledContent'
import { SectionTitle } from './components/SectionTitle'
import { useFixedAgendaContent } from './hooks/useFixedAgendaContent'
import { useOneOnOneEnabledContent } from './hooks/useOneOnOneEnabledContent'
import { adminOneOnOneContainerCss, contentsWrapperCss } from './styles'

export const AdminOneOnOneContainer: React.FC<AuthRouteProps> = () => {
  const { t } = useTranslation()
  const organization = useContext(OrganizationContext)

  useTracking(t('ADMIN_ONE_ON_ONE_PAGE_TITLE'), Screen.OneOnOneSetting)

  if (!organization) {
    return null
  }

  return <AdminOneOnOne organization={organization} />
}

AdminOneOnOneContainer.displayName = 'AdminOneOnOneContainer'

type Props = {
  organization: OrganizationFragment
}

export const AdminOneOnOne: React.FC<Props> = ({ organization }) => {
  const { t } = useTranslation()

  const breadcrumbs = useMemo<BreadcrumbParams>(
    () => ({
      url: oneOnOneAdmin,
      items: [
        {
          breadcrumbName: 'setting',
        },
        {
          breadcrumbName: 'settingOneOnOnes',
        },
      ],
    }),
    [],
  )
  const oneOnOneEnabledCardContentProps = useOneOnOneEnabledContent({ organization })
  const fixedAgendaContentProps = useFixedAgendaContent()

  return (
    <PageContent contentBackgroundColor={color('background-bk-5')}>
      <Box direction="column" css={adminOneOnOneContainerCss}>
        <Breadcrumb breadcrumbs={breadcrumbs} />
        <Box direction="column" margin={{ top: '32px' }}>
          <SectionTitle> {t('ADMIN_ONE_ON_ONE_PAGE_TITLE')}</SectionTitle>
        </Box>
        <div css={contentsWrapperCss}>
          <Card>
            <OneOnOneEnabledContent {...oneOnOneEnabledCardContentProps} />
          </Card>
          <Card>
            <FixedAgendaContent {...fixedAgendaContentProps} />
          </Card>
        </div>
      </Box>
    </PageContent>
  )
}

AdminOneOnOne.displayName = 'AdminOneOnOne'
