import { TextLink } from '@resily/geisha'
import React from 'react'

import { GeneralTitle } from '../../../../../components/ui/GeneralTitle'
import { Icon } from '../../../../../components/ui/Icon'
import { StyledText } from '../../../../../components/ui/StyledText'
import { Trans, useTranslation } from '../../../../../i18n'
import { ExternalUrls } from '../../../../../urls'

import { ActionPlanTableHead } from './actionPlanTableHead'
import { ActionPlanTableRow, ActionPlanTableRowSkeleton } from './actionPlanTableRow'
import { ActionPlanFieldFragment, ActionPlanStatus } from './graphql'
import { Sort } from './types'

export type Props = {
  loading: boolean
  actionPlans: ReadonlyArray<ActionPlanFieldFragment>
  isOwnActionPlanTab: boolean
  sort: Sort
  onClickSortBy: (sortBy: Sort['sortBy']) => void
  onUpdateActionPlanStatus: (data: { actionPlanId: string; status: ActionPlanStatus }) => void
}

export const ActionPlan: React.VFC<Props> = ({
  loading,
  actionPlans,
  isOwnActionPlanTab,
  sort,
  onClickSortBy,
  onUpdateActionPlanStatus,
}) => {
  const { t } = useTranslation()
  return (
    <section css={{ padding: '24px 32px' }}>
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '20px',
        }}
      >
        <div css={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
          <GeneralTitle title={t('ACTION_PLAN')} />
          <StyledText
            css={{
              '> a': {
                verticalAlign: 'baseline',
              },
            }}
            size="small"
            fontStyle="regular"
          >
            <Trans
              i18nKey={
                isOwnActionPlanTab
                  ? 'HOME_MY_ACTION_PLAN_DESCRIPTION'
                  : 'HOME_USER_ACTION_PLAN_DESCRIPTION'
              }
              components={[
                <TextLink
                  type="primary"
                  onClick={() => window.open(ExternalUrls.HOW_TO_USE_ACTION_PLAN)}
                />,
              ]}
            />
          </StyledText>
        </div>
      </div>
      <ActionPlanTableHead sort={sort} onClickSortBy={onClickSortBy} />
      {loading && (
        <ul css={{ listStyle: 'none' }}>
          {new Array(4).fill(undefined).map(() => (
            <ActionPlanTableRowSkeleton />
          ))}
        </ul>
      )}
      {!loading && actionPlans.length > 0 && (
        <ul css={{ listStyle: 'none' }}>
          {actionPlans.map((actionPlan) => (
            <ActionPlanTableRow
              key={actionPlan.id}
              actionPlan={actionPlan}
              isOwnActionPlanTab={isOwnActionPlanTab}
              onUpdateActionPlanStatus={onUpdateActionPlanStatus}
            />
          ))}
        </ul>
      )}
      {!loading && actionPlans.length < 1 && (
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            margin: 40,
            gap: 35,
          }}
        >
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 16,
            }}
          >
            <StyledText size="large" lineHeight="16px">
              {t('X_IS_EMPTY', { x: t('ACTION_PLAN') })}
            </StyledText>
          </div>
          <Icon width={302} height={154} type="mailboxCat" />
        </div>
      )}
    </section>
  )
}
