import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { useCurrentUser } from '../../contexts/UserContext'
import { useSelfServeConfig } from '../../lib/domain/organization/useSelfServeConfig'
import { AuthRouteProps } from '../../types/authRouteProps'
import { usersAdmin } from '../../urls'

import {
  UpdateUserMutationVariables,
  useUserQuery,
  useUpdateUserMutation,
  useUpdateStatusMutation,
  useResendInvitationMutation,
  useDeleteUserByIdMutation,
} from './api/graphql'
import { AdminUser } from './components/AdminUser'

export const AdminUserContainer: React.FC<AuthRouteProps> = ({ onOkrTermLoaded }) => {
  const navigate = useNavigate()
  const { userId: pageUserId } = useParams()
  const userQuery = useUserQuery({
    variables: {
      id: pageUserId ?? '',
    },
    skip: pageUserId == null,
  })
  const { remainingLicenses } = useSelfServeConfig()
  const [updateUserMutation] = useUpdateUserMutation()
  const [updateStatusMutation] = useUpdateStatusMutation()
  const [resendInvitationMutation] = useResendInvitationMutation()
  const [deleteUserByIdMutation] = useDeleteUserByIdMutation()
  const currentUser = useCurrentUser()
  useEffect(() => {
    onOkrTermLoaded(null)
  }, [onOkrTermLoaded])

  if (!currentUser?.admin) {
    return null
  }
  if (!userQuery.data?.findUserById) {
    return null
  }

  const { organization: organizationData } = userQuery.data.findUserById
  const updateUser = (val: Omit<UpdateUserMutationVariables, 'id'>) => {
    updateUserMutation({
      variables: {
        id: pageUserId ?? '',
        ...val,
        samlEnabled: organizationData.isSamlEnabled && val.samlEnabled,
      },
    })
  }

  const updateStatus = (id: string) => {
    updateStatusMutation({
      variables: {
        id,
      },
    })
  }

  const resendInvitation = (id: string) => {
    resendInvitationMutation({
      variables: {
        id,
      },
    })
  }

  const deleteUser = async (id: string): Promise<void> => {
    await deleteUserByIdMutation({
      variables: {
        id,
      },
    }).then(() => {
      navigate(usersAdmin)
    })
  }

  return (
    <AdminUser
      user={userQuery.data.findUserById}
      isOwnPage={currentUser.id === pageUserId}
      remainingLicenses={remainingLicenses}
      onUpdate={updateUser}
      onUpdateActivateStatus={updateStatus}
      onResendInvitation={resendInvitation}
      onDeleteUser={deleteUser}
      onCancel={() => navigate(usersAdmin)}
    />
  )
}

AdminUserContainer.displayName = 'AdminUserContainer'
