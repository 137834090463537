import { useCallback } from 'react'

import { useCurrentUser } from '../../../contexts/UserContext'
import {
  useCreateUserBookmarkMutation,
  useUpdatePersonalVisibleOkrNodeLevelMutation,
} from '../graphql'
import { useSetFilter } from '../hooks/useSetFilter'

import { OkrMapHeader, Props as OkrMapHeaderProps } from './OkrMapHeader'

export type Props = Omit<
  OkrMapHeaderProps,
  | 'existsTerm'
  | 'personalVisibleNodeLevel'
  | 'onChangePersonalVisibleNodeLevel'
  | 'onFilterChange'
  | 'onChangeViewType'
  | 'createBookmark'
> & {
  termId: string | null
}

export const OkrMapHeaderContainer: React.VFC<Props> = ({
  termId,
  selectedGroupIds,
  selectedUserIds,
  ...props
}) => {
  const user = useCurrentUser()
  const [changePersonalVisibleNodeLevelMutation] = useUpdatePersonalVisibleOkrNodeLevelMutation()
  const changePersonalVisibleNodeLevel = useCallback(
    async (level: number) => {
      await changePersonalVisibleNodeLevelMutation({
        variables: { level },
        optimisticResponse: user?.userSetting
          ? {
              updatePersonalVisibleOkrNodeLevel: {
                __typename: 'UserSetting',
                id: user.userSetting.id,
                minimumVisibleOkrNodeLevel: level,
              },
            }
          : undefined,
      })
    },
    [changePersonalVisibleNodeLevelMutation, user],
  )

  const [createBookmarkMutation] = useCreateUserBookmarkMutation()
  const createBookmark = useCallback(
    async (name: string) => {
      if (!termId) {
        return
      }

      await createBookmarkMutation({
        variables: {
          okrTermId: termId,
          name,
          filterGroupIds: selectedGroupIds,
          filterUserIds: selectedUserIds,
          foldNodeIds: [],
        },
      })
    },
    [createBookmarkMutation, selectedGroupIds, selectedUserIds, termId],
  )

  const { changeFiltering, changeViewType } = useSetFilter()

  return (
    <OkrMapHeader
      existsTerm={!!termId}
      personalVisibleNodeLevel={user?.userSetting.minimumVisibleOkrNodeLevel || 3}
      selectedGroupIds={selectedGroupIds}
      selectedUserIds={selectedUserIds}
      createBookmark={createBookmark}
      onChangeViewType={changeViewType}
      onChangePersonalVisibleNodeLevel={changePersonalVisibleNodeLevel}
      onFilterChange={changeFiltering}
      {...props}
    />
  )
}

OkrMapHeaderContainer.displayName = 'OkrMapHeaderContainer'
