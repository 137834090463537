import { VFC, useCallback, useMemo } from 'react'

import { OkrGlossaryForm, OkrGlossaryUpdateArgs } from '../../components/domain/OkrGlossaryForm'
import { PageContent } from '../../components/pageContent'
import { useTranslation } from '../../i18n'
import { updateOkrGlossaryResource } from '../../i18n/okrGlossary'
import { Screen } from '../../lib/screen'
import { useTracking } from '../../lib/tracking'
import { color } from '../../styles/newColors'
import { AuthRouteProps } from '../../types/authRouteProps'
import { okrGlossaries } from '../../urls'

import {
  CustomOkrGlossaryFragment,
  useCustomOkrGlossariesQuery,
  useUpdateCustomOkrGlossariesMutation,
  CustomOkrGlossaryInput,
} from './graphql'

export const AdminOkrGlossaryContainer: VFC<AuthRouteProps> = () => {
  const { t, i18n } = useTranslation()
  useTracking(t('DEFAULT_TEXT_'), Screen.OkrGlossarySetting)

  const [updateCustomOkrGlossariesMutation, { loading: updateLoading }] =
    useUpdateCustomOkrGlossariesMutation()
  const { data: res } = useCustomOkrGlossariesQuery()

  const customOkrGlossaries = useMemo<ReadonlyArray<CustomOkrGlossaryFragment>>(
    () => res?.customOkrGlossaries || [],
    [res?.customOkrGlossaries],
  )

  const updateOkrGlossary = useCallback(
    async (values: Array<OkrGlossaryUpdateArgs>, onUpdated: () => void) => {
      try {
        const { data } = await updateCustomOkrGlossariesMutation({
          variables: {
            inputs: values.map(
              (v): CustomOkrGlossaryInput => ({
                okrGlossary: v.okrGlossary,
                customTextJa: v.customTextJa,
                customTextEn: v.customTextEn,
              }),
            ),
          },
        })
        const result: ReadonlyArray<CustomOkrGlossaryFragment> =
          data?.updateCustomOkrGlossaries || []
        updateOkrGlossaryResource(i18n, result)
        onUpdated()
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
      }
    },
    [i18n, updateCustomOkrGlossariesMutation],
  )

  return (
    <AdminOkrGlossary
      updateLoading={updateLoading}
      customOkrGlossaries={customOkrGlossaries}
      onUpdateOkrGlossary={updateOkrGlossary}
    />
  )
}

AdminOkrGlossaryContainer.displayName = 'AdminOkrGlossaryContainer'

type Props = {
  updateLoading: boolean
  customOkrGlossaries: ReadonlyArray<CustomOkrGlossaryFragment>
  onUpdateOkrGlossary: (val: Array<OkrGlossaryUpdateArgs>, onUpdated: () => void) => void
}

const AdminOkrGlossary: VFC<Props> = ({
  updateLoading,
  customOkrGlossaries,
  onUpdateOkrGlossary,
}) => (
  <PageContent
    breadcrumbs={{
      url: okrGlossaries,
      items: [{ breadcrumbName: 'setting' }, { breadcrumbName: 'okrGlossaries' }],
    }}
    layout={{ css: { padding: '48px 32px' } }}
    contentBackgroundColor={color('background-bk-5')}
  >
    <OkrGlossaryForm
      updateLoading={updateLoading}
      customOkrGlossaries={customOkrGlossaries}
      onUpdate={onUpdateOkrGlossary}
    />
  </PageContent>
)

AdminOkrGlossary.displayName = 'AdminOkrGlossary'
