import { Box } from 'grommet'

import { StyledText } from '../../../../../components/ui/StyledText'
import { useTranslation } from '../../../../../i18n'
import { InvitingUserTable } from '../../UserTable/InvitingUserTable'
import { InvitingUserTableProps } from '../../UserTable/InvitingUserTable/types'
import { emptyCss } from '../styles'

import { InvitingUserTabProps } from './types'

export const InvitingUserTab: React.FC<InvitingUserTabProps> = ({ invitingUsers, onRowClick }) => {
  const { t } = useTranslation()
  return (
    <Box>
      <InvitingUserTable
        users={invitingUsers}
        onClickRow={onRowClick as InvitingUserTableProps['onClickRow']}
      />
      {invitingUsers.length < 1 && (
        <StyledText css={emptyCss}>{t('TARGET_USER_IS_EMPTY')}</StyledText>
      )}
    </Box>
  )
}
