import { ApolloError } from '@apollo/client'
import { PropsWithoutRef, useEffect, useRef, useState } from 'react'

import { useTranslation } from '../../../i18n'
import { filterString } from '../../../lib/array'
import { byUserName } from '../../../lib/domain/user/searchText'
import { fontSize } from '../../../styles/font'
import { color, ColorAliasObj } from '../../../styles/newColors'
import { Avatar } from '../../ui/Avatar'
import { Button } from '../../ui/ButtonDeprecated'
import { Checkbox } from '../../ui/Checkbox'
import {
  FindUserNotePermissionDocument,
  FindUserNotePermissionQuery,
  FindUserNotePermissionQueryVariables,
} from '../NoteOption/graphql'

import {
  useFindUserNotePermissionQuery,
  UserNotePermission,
  UserPersonalNotePermission,
  useUpdateNoteUsersMutation,
} from './graphql'

export type ContainerProps = PropsWithoutRef<JSX.IntrinsicElements['section']> & {
  noteId: string
  close: () => void
  shouldExpireCache: boolean
  onUpdated: () => void
}

export const NoteUserDialogContainer: React.FC<ContainerProps> = ({
  noteId,
  close,
  shouldExpireCache,
  onUpdated,
}) => {
  const [visibleList, setVisibleList] = useState<ReadonlyArray<string>>([])
  const [optionList, setOptionList] = useState<ReadonlyArray<UserNotePermission>>([])
  const [searchText, setSearchText] = useState<string>('')
  const [searchedOptions, setSearchedOptions] =
    useState<ReadonlyArray<UserNotePermission>>(optionList)

  const { data, error } = useFindUserNotePermissionQuery({
    fetchPolicy: shouldExpireCache ? 'network-only' : 'cache-first',
    variables: { id: noteId },
    onCompleted: (result) =>
      setVisibleList(
        result.findUserNotePermission.reduce<ReadonlyArray<string>>((ids, u) => {
          if (u && u.noteVisible) {
            return [...ids, u.id]
          }
          return ids
        }, []),
      ),
  })

  useEffect(() => {
    if (data?.findUserNotePermission) {
      setOptionList(data.findUserNotePermission.filter((u): u is UserNotePermission => !!u))
    }
  }, [data])

  useEffect(() => {
    setSearchedOptions(filterString(searchText, optionList, byUserName))
  }, [optionList, searchText])
  const [updateNoteUsers, { loading }] = useUpdateNoteUsersMutation({
    onCompleted: () => onUpdated(),
  })

  const handleUpdate = (userIds: ReadonlyArray<string>) => {
    updateNoteUsers({
      variables: { noteId, userIds },
      update: (cache) => {
        const option = { query: FindUserNotePermissionDocument, variables: { id: noteId } }

        const { findUserNotePermission } =
          cache.readQuery<FindUserNotePermissionQuery, FindUserNotePermissionQueryVariables>(
            option,
          ) || {}
        if (!findUserNotePermission) {
          return
        }

        cache.writeQuery<FindUserNotePermissionQuery, FindUserNotePermissionQueryVariables>({
          ...option,
          data: {
            findUserNotePermission: findUserNotePermission.map((u) => {
              if (!u) return null
              return {
                ...u,
                noteVisible: userIds.includes(u.id),
              }
            }),
          },
        })
      },
    })
  }

  return (
    <NoteUserDialog
      searchedOptions={searchedOptions}
      searchText={searchText}
      visibleList={visibleList}
      existsData={!!data?.findUserNotePermission}
      loading={loading}
      error={error}
      close={close}
      handleUpdate={handleUpdate}
      setSearchText={setSearchText}
      setVisibleList={setVisibleList}
    />
  )
}

NoteUserDialogContainer.displayName = 'NoteUserDialogContainer'

export type Props = {
  searchedOptions: ReadonlyArray<UserNotePermission | UserPersonalNotePermission>
  searchText: string
  visibleList: ReadonlyArray<string>
  existsData: boolean
  loading: boolean
  error: ApolloError | undefined
  close: () => void
  handleUpdate: (userIds: ReadonlyArray<string>) => void
  setSearchText: (text: string) => void
  setVisibleList: (list: ReadonlyArray<string>) => void
}

export const NoteUserDialog: React.FC<Props> = ({
  searchedOptions,
  searchText,
  visibleList,
  existsData,
  loading,
  error,
  close,
  handleUpdate,
  setSearchText,
  setVisibleList,
}) => {
  const { t } = useTranslation()

  const ref = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (ref.current) {
      ref.current.focus()
    }
  })

  const isSelectable = (user: UserNotePermission | UserPersonalNotePermission) => {
    if (!user.isDisabled) return true
    return visibleList.includes(user.id)
  }

  return (
    <div
      css={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      {error && <div>{error}</div>}
      {existsData && (
        <div css={{ padding: 20 }}>
          <input
            ref={ref}
            type="text"
            placeholder={t('INPUT_X', { x: t('USER_NAME') })}
            value={searchText}
            onChange={(event) => setSearchText(event.currentTarget.value)}
            css={{
              ...fontSize('medium'),
              height: '50px',
              width: '100%',
              color: color('text-bk-100'),
              paddingLeft: '16px',
              paddingRight: '16px',
              marginBottom: '1px',
              boxShadow: '0px 1px 0px #E9EAEC',
              '::placeholder': {
                color: color('text-bk-30'),
              },
              ':focus': {
                backgroundColor: color('background-bk-5'),
                borderRadius: '4px 4px 0 0',
                outline: 'none',
                boxShadow: '0px 1px 0px #DC440A',
              },
            }}
          />
          <table css={{ width: '100%' }}>
            <thead>
              <tr>
                <th css={{ width: 48 }} />
                <th css={{ width: 240, padding: 10 }}>{t('USER')}</th>
                <th css={{ width: 60, whiteSpace: 'nowrap' }}>{t('VISIBLE')}</th>
              </tr>
            </thead>
            <tbody>
              {[...searchedOptions]
                .sort((a, b) => {
                  const nameOrder =
                    `${a.lastName}${a.firstName}` > `${b.lastName}${b.firstName}` ? 1 : -1
                  if (a.isDisabled && b.isDisabled) return nameOrder
                  if (a.isDisabled) return 1
                  if (b.isDisabled) return -1
                  return nameOrder
                })
                .reduce<Array<JSX.Element>>((nodes, user) => {
                  if (isSelectable(user)) {
                    nodes.push(
                      <tr key={user.id}>
                        <td css={{ textAlign: 'center' }}>
                          <Avatar
                            firstName={user.firstName}
                            lastName={user.lastName}
                            avatarUrl={user.avatar?.url}
                            isUserDisabled={user.isDisabled}
                          />
                        </td>
                        <td
                          css={{
                            padding: 5,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            maxWidth: 0,
                          }}
                        >
                          {user.isDisabled ? (
                            <span css={{ color: ColorAliasObj['text-bk-20'] }}>
                              ({t('DISABLING')})
                              <span css={{ textDecoration: 'line-through' }}>
                                {t('FULL_NAME', {
                                  firstName: user.firstName,
                                  lastName: user.lastName,
                                })}
                              </span>
                            </span>
                          ) : (
                            <span>
                              {t('FULL_NAME', {
                                firstName: user.firstName,
                                lastName: user.lastName,
                              })}
                            </span>
                          )}
                        </td>
                        <td css={{ padding: 5, textAlign: 'center' }}>
                          <Checkbox
                            checked={!!visibleList.find((id) => user.id === id)}
                            onChange={(e) => {
                              const newList = e.target.checked
                                ? visibleList.concat(user.id)
                                : visibleList.filter((id) => id !== user.id)
                              handleUpdate(newList)
                              setVisibleList(newList)
                            }}
                          />
                        </td>
                      </tr>,
                    )
                  }
                  return nodes
                }, [])}
            </tbody>
          </table>
        </div>
      )}
      <div css={{ marginBottom: 20 }}>
        <Button
          type="button"
          color="main"
          disabled={loading}
          onClick={() => {
            close()
          }}
        >
          {t('CLOSE')}
        </Button>
      </div>
    </div>
  )
}

NoteUserDialog.displayName = 'NoteUserDialog'
