import { css } from '@emotion/react'
import { Skeleton } from '@resily/geisha'

import { Avatar } from '../../../../../components/ui/Avatar'
import { Icon } from '../../../../../components/ui/Icon'
import { Link } from '../../../../../components/ui/Link'
import { StyledText } from '../../../../../components/ui/StyledText'
import { NewTitle } from '../../../../../components/ui/Title'
import { TooltipNew } from '../../../../../components/ui/TooltipNew'
import { useTranslation } from '../../../../../i18n'
import { formatDateInput } from '../../../../../lib/date'
import { fontSize } from '../../../../../styles/font'
import { color } from '../../../../../styles/newColors'
import { generateNote, generatePersonalNote } from '../../../../../urls'

import {
  NoteFieldFragment,
  PersonalNoteFieldFragment,
  NoteOrPersonalNoteFieldFragment,
  NotePermission,
  ObjectiveFieldFragment,
  KeyResultFieldFragment,
} from './graphql'

export type Props = {
  note: NoteOrPersonalNoteFieldFragment
  userHref: string
}

const cssTableEntire = css({
  display: 'flex',
  cursor: 'pointer',
  ':hover': {
    color: color('resily-orange-100'),
    backgroundColor: color('hover-background-bk-5'),
  },
})

const cssTableCommonCellBody = css({
  display: 'flex',
  alignItems: 'center',
  padding: '16px 0',
  borderBottom: `1px solid ${color('border-bk-10')}`,
  justifyContent: 'start',
})

const cssTableTitleCellBody = css([
  cssTableCommonCellBody,
  {
    flex: 'auto',
    paddingLeft: '12px',
    paddingRight: '24px',
    alignItems: 'center',
    ':hover': {
      color: color('resily-orange-100'),
    },
  },
])

const cssTableOKRCellBody = css([
  cssTableCommonCellBody,
  { flex: '0 0 348px', paddingRight: '24px' },
])

const cssTablePermissionCellBody = css([
  cssTableCommonCellBody,
  {
    flex: '0 0 90px',
    color: color('text-bk-50'),
  },
])

const cssTableAuthorCellBody = css([
  cssTableCommonCellBody,
  {
    flex: '0 0 136px',
    paddingRight: '24px',
  },
])

const cssTableUpdatedAtCellBody = css([
  cssTableCommonCellBody,
  {
    flex: '0 0 114px',
    paddingRight: '12px',
    color: color('text-bk-100'),
  },
])

const cssNoteTitle = css({
  fontSize: '14px',
  lineHeight: '22px',
  fontWeight: 'bold',
  wordBreak: 'break-all',
  // stylelint-disable-next-line
  display: '-webkit-box',
  overflow: 'hidden',
  // stylelint-disable-next-line
  WebkitLineClamp: 2,
  // stylelint-disable-next-line
  WebkitBoxOrient: 'vertical',
})

const cssOkrTitle = css({
  fontWeight: 'normal',
  color: color('text-bk-100'),
  ...fontSize('small'),
  padding: 0,
})

const cssAuthorTooltip = css({
  display: 'inline-block',
  maxWidth: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  ':hover': {
    color: color('resily-orange-100'),
  },
})

const permissionIcon = (permission: NotePermission) => {
  switch (permission) {
    case NotePermission.OwnerOnly:
      return 'lock'
    case NotePermission.Private:
      return 'group'
    case NotePermission.Public:
      return 'mdiPublic'
    default:
      return fail(permission)
  }
}

export const isNote = (
  note: NoteFieldFragment | PersonalNoteFieldFragment,
): note is NoteFieldFragment => note.__typename === 'Note'

const isNoteResourceKeyResult = (
  resource: KeyResultFieldFragment | ObjectiveFieldFragment,
): resource is KeyResultFieldFragment => resource.__typename === 'KeyResult'

export const NotesTableRow: React.VFC<Props> = ({ note, userHref }) => {
  const { t } = useTranslation()

  return (
    <li>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div
        onClick={() =>
          window.open(
            isNote(note) ? generateNote(note.id) : generatePersonalNote(note.id),
            '_blank',
          )
        }
        css={{
          color: color('text-bk-100'),
          textDecoration: 'none',
        }}
      >
        <div css={cssTableEntire}>
          <div css={cssTableTitleCellBody}>
            <TooltipNew
              title={note.title}
              dropProps={{
                align: {
                  top: 'bottom',
                  left: 'left',
                },
                stretch: false,
                plain: true,
              }}
            >
              <span css={cssNoteTitle}>{note.title}</span>
            </TooltipNew>
          </div>
          <div css={cssTableOKRCellBody}>
            {isNote(note) && (
              <TooltipNew
                title={note.resource.name}
                dropProps={{
                  align: {
                    top: 'bottom',
                    left: 'left',
                  },
                  stretch: false,
                  plain: true,
                }}
              >
                <h2 css={cssOkrTitle}>
                  <NewTitle
                    color={
                      isNoteResourceKeyResult(note.resource) ? 'kr-green-100' : 'objective-blue-100'
                    }
                    title={note.resource.name}
                    targetSetting={
                      isNoteResourceKeyResult(note.resource)
                        ? note.resource.targetSetting
                        : undefined
                    }
                    readonly
                    isWrap
                    isPointer
                    sideBarHeight={20}
                    css={{ padding: 0 }}
                  />
                </h2>
              </TooltipNew>
            )}
          </div>
          <div css={cssTablePermissionCellBody}>
            <Icon type={permissionIcon(note.permission)} css={{ marginTop: 2 }} />
            <StyledText size="small" css={{ marginLeft: 6 }}>
              {t(note.permission)}
            </StyledText>
          </div>
          <div css={cssTableAuthorCellBody}>
            <Link
              href={userHref}
              onClick={(e) => {
                e.stopPropagation()
              }}
              css={{ textDecoration: 'none' }}
            >
              <Avatar
                firstName={note.author.firstName}
                lastName={note.author.lastName}
                avatarUrl={note.author.avatar?.url}
                size="small"
                isUserDisabled={note.author.isDisabled}
                css={{ marginRight: '8px' }}
              />
            </Link>
            <div
              css={{
                display: 'flex',
                flexDirection: 'column',
                maxWidth: '84px',
              }}
            >
              <TooltipNew
                title={t('FULL_NAME', {
                  firstName: note.author.firstName,
                  lastName: note.author.lastName,
                })}
                dropProps={{
                  align: {
                    top: 'bottom',
                    left: 'left',
                  },
                  stretch: false,
                  plain: true,
                }}
                css={cssAuthorTooltip}
              >
                <Link
                  href={userHref}
                  onClick={(e) => {
                    e.stopPropagation()
                  }}
                >
                  <StyledText size="small">
                    {t('FULL_NAME', {
                      firstName: note.author.firstName,
                      lastName: note.author.lastName,
                    })}
                  </StyledText>
                </Link>
              </TooltipNew>
            </div>
          </div>
          <div css={cssTableUpdatedAtCellBody}>
            <StyledText size="small">
              {formatDateInput(note.updatedAt, 'YYYY/MM/DD HH:mm')}
            </StyledText>
          </div>
        </div>
      </div>
    </li>
  )
}

export const NoteTableRowSkeleton: React.FC = () => (
  <li>
    <div css={{ display: 'flex' }}>
      <div css={cssTableTitleCellBody}>
        <Skeleton text height={14} />
      </div>
      <div css={cssTableOKRCellBody}>
        <Skeleton text width={322} height={12} />
      </div>
      <div css={cssTablePermissionCellBody}>
        <Skeleton text width={66} height={12} />
      </div>
      <div css={[cssTableAuthorCellBody, { gap: 8 }]}>
        <Skeleton circle height={20} />
        <Skeleton text width={84} height={12} />
      </div>
      <div css={cssTableUpdatedAtCellBody}>
        <Skeleton text width={96} height={12} />
      </div>
    </div>
  </li>
)
