import { Skeleton } from '@resily/geisha'

import { useTranslation } from '../../../../../i18n'
import { GeneralTitle } from '../../../../ui/GeneralTitle'
import { useStyles as useMainStyles } from '../Main.styles'

import { useStyles } from './ObjectiveArea.styles'

export const ObjectiveAreaSkeleton: React.VFC = () => {
  const { t } = useTranslation()
  const mainStyles = useMainStyles()
  const styles = useStyles()

  return (
    <div css={mainStyles.areaRoot}>
      <GeneralTitle title={t('OBJECTIVE')} />
      <div css={styles.button}>
        <div css={styles.nameArea}>
          <Skeleton circle width={20} height={20} />
          <Skeleton text height={14} />
        </div>
        <Skeleton text width={84} height={16} />
      </div>
    </div>
  )
}
