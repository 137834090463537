import { Box, BoxProps } from 'grommet'

import { color } from '../../../styles/newColors'
import { Link, Props as LinkProps } from '../Link'
import { StyledText } from '../StyledText'

export type Props = Omit<BoxProps, 'onClick'> & {
  titleProps: LinkProps & { title: string }
  ownerProps?: LinkProps & { owner: string }
}

export const TitleLink: React.FC<Props> = ({
  titleProps,
  ownerProps,
  margin = '15px 16px',
  ...props
}) => (
  <Box margin={margin} {...props}>
    <StyledText wordBreak="break-word">
      <Link
        css={{
          marginBottom: 8,
          ':hover': {
            color: color('resily-orange-100'),
            textDecorationLine: 'underline',
          },
        }}
        {...titleProps}
      >
        {titleProps.title}
      </Link>
    </StyledText>
    {ownerProps && (
      <StyledText size="small" css={{ opacity: 0.5 }}>
        <Link {...ownerProps}>{ownerProps.owner}</Link>
      </StyledText>
    )}
  </Box>
)

TitleLink.displayName = 'TitleLink'
