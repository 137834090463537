import { Box, Heading } from 'grommet'
import React from 'react'

import { PageContent } from '../../components/pageContent'
import { CsvDownloadLink } from '../../components/ui/CsvDownloadLink'
import { CsvUploadForm } from '../../components/ui/CsvUploadForm'
import { Link } from '../../components/ui/Link'
import { StyledText } from '../../components/ui/StyledText'
import { useTranslation, Trans } from '../../i18n'
import { config } from '../../lib/client'
import { Screen } from '../../lib/screen'
import { useTracking } from '../../lib/tracking'
import { color } from '../../styles/newColors'
import { AuthRouteProps } from '../../types/authRouteProps'
import * as urls from '../../urls'

const GROUP_EXPORT_URL = `${config.base}/export/groups`
const GROUP_IMPORT_URL = `${config.base}/import/groups`

export type Props = AuthRouteProps

export const GroupsImportContainer: React.FC<Props> = () => {
  const { t } = useTranslation()
  useTracking(t('CSV_IMPORT_PAGE_TITLE'), Screen.GroupsImportAdmin)

  return (
    <PageContent
      breadcrumbs={{
        url: urls.groupsImportAdmin,
        items: [
          { breadcrumbName: 'setting' },
          { breadcrumbName: 'settingGroups' },
          { breadcrumbName: 'importGroups' },
        ],
      }}
      layout={{ css: { padding: '48px 36px' } }}
      contentBackgroundColor={color('background-bk-5')}
    >
      <Heading level="3" margin={{ bottom: '8px' }} css={{ fontSize: '24px' }}>
        {t('CSV_IMPORT_PAGE_TITLE')}
      </Heading>
      <p>
        <StyledText lineHeight="22px">{t('GROUP_CSV_IMPORT_DESCRIPTION')}</StyledText>
      </p>
      <Box
        as="ol"
        margin={{ top: '24px' }}
        css={{
          background: color('white-100'),
          padding: '24px 32px',
          borderRadius: '8px',
        }}
      >
        <Box as="li" height={{ min: 'auto' }}>
          <Heading level="4" css={{ fontSize: '16px' }}>
            1. {t('DOWNLOAD_CSV_FILE')}
          </Heading>
          <div css={{ marginTop: '8px' }}>
            <StyledText lineHeight="22px">
              <Trans i18nKey="DOWNLOAD_CSV_FILE_DESCRIPTION">
                <Link
                  method="newTab"
                  href={urls.ExternalUrls.GROUPS_WITH_CSV}
                  css={{
                    color: color('resily-orange-100'),
                    textDecoration: 'underline',
                  }}
                >
                  here
                </Link>
                .
              </Trans>
            </StyledText>
          </div>
          <CsvDownloadLink
            margin={{ top: 'large' }}
            exportUrl={GROUP_EXPORT_URL}
            exportOptions={[
              {
                key: 'users-csv',
                value: 'true',
                text: t('GROUP_CSV_EXPORT_INCLUDE_USER_CSV'),
                hint: t('GROUP_CSV_EXPORT_INCLUDE_USER_CSV_HINT'),
              },
            ]}
          />
        </Box>
        <Box as="li" margin={{ top: '40px' }} height={{ min: 'auto' }} gap="8px">
          <Heading level="4" css={{ fontSize: '16px' }}>
            2. {t('UPLOAD_CSV_FILE')}
          </Heading>
          <StyledText lineHeight="22px">{t('UPLOAD_CSV_FILE_DESCRIPTION')}</StyledText>
          <CsvUploadForm fileName="groups.csv" importUrl={GROUP_IMPORT_URL} postBodyKey="groups" />
        </Box>
      </Box>
    </PageContent>
  )
}

GroupsImportContainer.displayName = 'GroupsImportContainer'
