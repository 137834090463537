import { AdminIpWhitelistContainer } from '../AdminIpWhitelist'
import { AdminDomainWhitelistContainer } from '../AdminOauthWhitelist'
import { AdminSamlContainer } from '../AdminSaml'

export type ContentContainerProps = {
  tab: string
}

export const ContentContainer: React.FC<ContentContainerProps> = ({ tab }) => {
  if (tab === 'ip') {
    return <AdminIpWhitelistContainer />
  }
  if (tab === 'oauth') {
    return <AdminDomainWhitelistContainer />
  }
  return <AdminSamlContainer />
}
