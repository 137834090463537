import { css } from '@emotion/react'
import { useProfiler } from '@sentry/react'
import { useMemo, useCallback, VFC } from 'react'

import { useTranslation } from '../../../../i18n'
import { useFlags } from '../../../../lib/featureToggle'
import { color } from '../../../../styles/newColors'
import { DateTime } from '../../../ui/DateTime'
import { ProgressRate } from '../../../ui/Meter'
import { Select, Option } from '../../../ui/Select'
import { StyledText } from '../../../ui/StyledText'

import { TermFragment } from './graphql'

export const GLOBAL_NAV_BOX_CLASS_NAME = 'glonav-box'
export const GLOBAL_NAV_SELECT_BOX = 'glonav-select-box'
export const GLOBAL_NAV_TERM_DATE_BOX = 'glonav-term-date-box'
export const GLOBAL_NAV_TERM_RATE_BOX = 'glonav-term-rate-box'
export const GLOBAL_NAV_RATE_TEXT_CLASS_NAME = 'glonav-rate-text'

const glonavBox = css({
  overflowX: 'hidden',
  minHeight: 249,
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
})
const selectBox = css({
  whiteSpace: 'nowrap',
})
const selectTitleCss = css({
  display: 'block',
  marginBottom: '12px',
  lineHeight: '16px',
})
const dateBox = css({
  display: 'flex',
  marginTop: '20px',
})
const dateCss = css({
  width: '100%',
})
const dateTextCss = css({
  whiteSpace: 'nowrap',
  display: 'block',
  marginBottom: '12px',
  lineHeight: '16px',
})
const termRateBox = css({
  marginTop: '24px',
})
const rateCss = css({
  display: 'block',
  marginTop: '8px',
  textAlign: 'right',
})

export type Props = {
  okrTerms: ReadonlyArray<TermFragment>
  term: TermFragment
  onOkrTermChange: (termId: string) => void
  setIsOpenedSelectTerm: (isOpened: boolean) => void
}

export const GloNav: VFC<Props> = ({ term, okrTerms, onOkrTermChange, setIsOpenedSelectTerm }) => {
  useProfiler('GloNav')
  const { t } = useTranslation()
  const { moneyForward } = useFlags()

  const changeTerm = useCallback(
    (id: string) => {
      onOkrTermChange(id)
    },
    [onOkrTermChange],
  )
  const openSelect = useCallback(() => {
    setIsOpenedSelectTerm(true)
  }, [setIsOpenedSelectTerm])
  const closeSelect = useCallback(() => {
    setIsOpenedSelectTerm(false)
  }, [setIsOpenedSelectTerm])

  const termOptions = useMemo<Array<Option>>(
    () =>
      okrTerms.map(({ id, name }) => ({
        value: id,
        text: name,
      })),
    [okrTerms],
  )

  return (
    <div className={GLOBAL_NAV_BOX_CLASS_NAME} css={glonavBox}>
      {/* 期間選択UI */}
      <div className={GLOBAL_NAV_SELECT_BOX} css={selectBox}>
        <StyledText color="text-bk-50" size="small" css={selectTitleCss}>
          {moneyForward ? `${t('OKR')}${t('TREE')}` : t('OKR_TERM')}
        </StyledText>
        <Select
          dropAlign={{ top: 'bottom', left: 'left' }}
          options={termOptions}
          onClickOption={changeTerm}
          value={term.id}
          onOpen={openSelect}
          onClose={closeSelect}
        />
        {/* 期間UI */}
        <div className={GLOBAL_NAV_TERM_DATE_BOX} css={dateBox}>
          <div css={dateCss}>
            <StyledText size="small" color="text-bk-50" css={dateTextCss}>
              {t('TERM_START_DATE')}
            </StyledText>
            <DateTime datetime={term.startDate} withoutTime style={{ fontSize: 14 }} />
          </div>
          <div css={dateCss}>
            <StyledText size="small" color="text-bk-50" css={dateTextCss}>
              {t('TERM_END_DATE')}
            </StyledText>
            <DateTime datetime={term.endDate} withoutTime style={{ fontSize: 14 }} />
          </div>
        </div>
      </div>
      {/* 完了日数率 */}
      <div className={GLOBAL_NAV_TERM_RATE_BOX} css={termRateBox}>
        <StyledText size="small" color="text-bk-50" css={dateTextCss}>
          {t('TERM_PROGRESS_RATE')}
        </StyledText>
        <ProgressRate
          withoutText
          color={color('resily-orange-100')}
          rate={term.progressRate}
          barWidth="100%"
          style={{ borderRadius: 4, height: 13 }}
        />
        <StyledText
          className={GLOBAL_NAV_RATE_TEXT_CLASS_NAME}
          color="resily-orange-100"
          weight="bold"
          lineHeight="14px"
          css={rateCss}
        >
          {term.progressRate}%
        </StyledText>
      </div>
    </div>
  )
}

GloNav.displayName = 'GloNav'
