import { NoLeftLicensesForAdminModal } from './NoLeftLicensesForAdminModal'
import { NoLeftLicensesForGeneralModal } from './NoLeftLicensesForGeneralModal'

export type Props = {
  isOpened: boolean
  isAdmin: boolean
  onClickCheckPlan: () => void
}

export const NoLeftLicensesModal: React.FC<Props> = ({ isOpened, isAdmin, onClickCheckPlan }) =>
  isAdmin ? (
    <NoLeftLicensesForAdminModal isOpened={isOpened} onClickCheckPlan={onClickCheckPlan} />
  ) : (
    <NoLeftLicensesForGeneralModal isOpened={isOpened} />
  )

NoLeftLicensesModal.displayName = 'NoLeftLicensesModal'
