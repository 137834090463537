import { Box, BoxTypes } from 'grommet'

import { useTranslation } from '../../../i18n'
import * as colors from '../../../styles/colors'
import { Button } from '../Button'

type Props = BoxTypes & {
  confirmation: string
  resources: ReadonlyArray<string>
  warning: string | Array<string>
  onCancel: () => void
  handleDelete: () => void
}

export const DeleteDialog: React.FC<Props> = ({
  confirmation,
  resources,
  warning,
  onCancel,
  handleDelete,
  ...props
}) => {
  const { t } = useTranslation()
  return (
    <Box {...props}>
      <div css={{ padding: '8px', textAlign: 'center', fontSize: '20px' }}>
        <div>{confirmation}</div>
        <div css={{ padding: '8px' }}>
          <div css={{ fontSize: '16px' }}>{t('RESOURCE_LIST')}</div>
          <ul css={{ textAlign: 'left', marginLeft: '20px', padding: '8px' }}>
            {resources.map((resource, i) => (
              <li
                key={`${resource}_${i.toString()}`}
                css={{ fontSize: '14px', overflowWrap: 'anywhere' }}
              >
                {resource}
              </li>
            ))}
          </ul>
        </div>
        <div css={{ fontSize: '14px', color: `${colors.negative}`, overflowWrap: 'break-word' }}>
          {typeof warning === 'string'
            ? warning
            : warning.map((_warning) => <p css={{ marginBottom: '8px' }}>{_warning}</p>)}
        </div>
      </div>
      <Box pad={{ top: 'medium' }}>
        <Box pad={{ bottom: 'small' }}>
          <Button
            onClick={() => {
              handleDelete()
            }}
            color="warning"
          >
            {t('YES')}
          </Button>
        </Box>
        <Box>
          <Button onClick={() => onCancel()}>{t('NO')}</Button>
        </Box>
      </Box>
    </Box>
  )
}

DeleteDialog.displayName = 'DeleteDialog'
