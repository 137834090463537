import { createContext, useState } from 'react'

export type AlertModalStatus = Pick<Status, 'type'>['type']
type OnClickDiscard = () => void

// alert pattern
const EDITING_TYPE_LIST = ['DESCRIPTION', 'WEIGHT'] as const

type EditingType = typeof EDITING_TYPE_LIST[number]

type Status =
  | {
      isOpen: true
      type: EditingType
      onClickDiscard: OnClickDiscard
    }
  | {
      isOpen: false
      type: EditingType | 'NONE'
    }

type ContextType = {
  status: Status
  changeEditingType: (type: EditingType) => void
  closeAlertModal: () => void
  checkOpenAlertModal: (args: { onClickDiscard: OnClickDiscard }) => void
}

export const AlertModalContext = createContext<ContextType>({
  status: {
    isOpen: false,
    type: 'NONE',
  },
  changeEditingType: () => {
    throw new Error('Should use AlertModalContext.Provider')
  },
  closeAlertModal: () => {
    throw new Error('Should use AlertModalContext.Provider')
  },
  checkOpenAlertModal: () => {
    throw new Error('Should use AlertModalContext.Provider')
  },
})

export const AlertModalContextProvider: React.FC = ({ children }) => {
  const [status, updateStatus] = useState<Status>({
    isOpen: false,
    type: 'NONE',
  })
  return (
    <AlertModalContext.Provider
      value={{
        status: status.isOpen
          ? {
              isOpen: true,
              type: status.type,
              onClickDiscard: () => {
                status.onClickDiscard()
                updateStatus({ isOpen: false, type: 'NONE' })
              },
            }
          : {
              isOpen: false,
              type: status.type,
            },
        changeEditingType: (type: EditingType) => updateStatus({ isOpen: false, type }),
        closeAlertModal: () => updateStatus({ isOpen: false, type: 'NONE' }),
        checkOpenAlertModal: ({ onClickDiscard }) => {
          if (status.type !== 'NONE') {
            updateStatus({
              isOpen: true,
              type: status.type,
              onClickDiscard,
            })
            return
          }
          onClickDiscard()
        },
      }}
    >
      {children}
    </AlertModalContext.Provider>
  )
}

AlertModalContextProvider.displayName = 'AlertModalContextProvider'
