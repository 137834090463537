import { css } from '@emotion/react'
import { useProfiler } from '@sentry/react'
import { defaultStyles } from '@visx/tooltip'
import {
  Axis,
  Grid,
  AreaSeries,
  XYChart,
  Tooltip,
  TooltipData,
  buildChartTheme,
  Margin,
} from '@visx/xychart'
import dayjs from 'dayjs'
import { VFC, useMemo, useCallback } from 'react'

import { color, hex2rgba } from '../../../styles/newColors'
import { DateTime } from '../../ui/DateTime'
import { StyledText } from '../../ui/StyledText'

import { DrawerTooltip } from './DrawerTooltip'
import { ObjectiveFragment, TermFragment, KeyResultForecastFragment } from './graphql'

/**
 * 時間の切り捨て
 * @return Date
 */
const truncateTime = (date: Date) => new Date(date.toDateString())

/**
 * 日付が同じものが存在するか
 * @return boolean
 */
const hasDateInArray = (dates: ReadonlyArray<Date>, target: Date): boolean =>
  dates.some((d) => d.toDateString() === target.toDateString())

/**
 * 日付の配列を日付単位でユニークにする（時間は切り捨て）
 * @return Array<Date>
 */
const uniqByDates = (datesItems: ReadonlyArray<ReadonlyArray<Date>>) => {
  const allDates = datesItems.reduce((pre, cur) => pre.concat(cur), [])
  const removedAllDates = allDates.map((d) => truncateTime(d))
  return removedAllDates.reduce(
    (pre: Array<Date>, cur) => (hasDateInArray(pre, cur) ? pre : pre.concat(cur)),
    [],
  )
}

/** 時間の昇順 */
const sortDate = (dates: Array<Date>) => dates.sort((a, b) => a.getTime() - b.getTime())

/**
 * 0 ~ 100に収まるように丸める処理
 * @param 丸める対象の値
 * @return number
 */
const roundProgressRate = (rate: number) => {
  if (rate > 100) return 100
  if (rate < 0) return 0
  return rate
}

/**
 * 同じ日付の中で最も遅い時間のものを取得する
 *
 * @param dates ReadonlyArray<T extends { date: Date }> 検索する日付が入った配列
 * @return Date Date 検索の比較対象
 */
const findDateAndLateTime = <T extends { date: Date }>(
  dates: ReadonlyArray<T>,
  target: Date,
): T | undefined =>
  dates.reduce((pre: T | undefined, date) => {
    const isSameDate = date.date.toDateString() === target.toDateString()
    if (!isSameDate) return pre
    if (pre && date.date.getTime() < pre.date.getTime()) {
      return pre
    }
    return date
  }, undefined)

/**
 * 履歴情報からチャートを生成するのに必要な型情報
 */
export type Progress = {
  id?: string
  resourceId?: string
  name: string
  progressRate: number
  date: Date
}

/**
 * KRの進捗率履歴を基準となる日付の一覧で作成する
 *
 * 注意: XYChartはそれぞれのグラフで間の日付データが欠損しているとグラフが作成できない
 * 基準となる日付の一覧で履歴が存在しない場合は値を埋める必要がある
 *
 * @param baseDates ReadonlyArray<Date> 基準の日付の配列
 * @param originKrProgressies ReadonlyArray<T extends KrProgress> KRの進捗率履歴の配列
 * @return Progressの配列
 */
const progressRatesToProgress = <T extends Progress>(
  baseDates: ReadonlyArray<Date>,
  originKrProgressies: ReadonlyArray<T>,
) => {
  const name = originKrProgressies[0]?.name || ''
  const id = originKrProgressies[0].id || ''
  const resourceId = originKrProgressies[0].resourceId || ''

  return baseDates.reduce((pre: Array<Progress>, baseDate): Array<Progress> => {
    // historyで同じ日付のものが複数見つかる場合がある。その場合は時間が一番遅いものを採用する
    const history = findDateAndLateTime(originKrProgressies, baseDate)
    const previousHistory: Progress | undefined = pre[pre.length - 1]

    // 対象日付でKRのhistoryがなく、直前の日付もないときは進捗率: 0とする
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!history && !previousHistory) {
      return pre.concat({
        id,
        resourceId,
        name,
        date: baseDate,
        progressRate: 0,
      })
    }
    // 対象日付でKRのhistoryがなく直前の日付はある場合、同じ進捗率とする
    if (!history) {
      return pre.concat({
        id,
        resourceId,
        name,
        date: baseDate,
        progressRate: previousHistory.progressRate,
      })
    }
    return pre.concat({
      id,
      resourceId,
      name,
      date: baseDate,
      progressRate: history.progressRate,
    })
  }, [])
}

/**
 * チャートのレンダリングに必要な型情報
 */
type Series = {
  id?: string
  resourceId?: string
  label: string
  x: string
  y: number | null
}

const truncateName = (str: string, len = 46) =>
  str.length <= len ? str : `${str.substr(0, len)}...`

/**
 * progressToSeries が返す進捗率に負数があるかどうかを返す
 *
 * チャートのY軸に負数を表示するかどうかを制御している
 */
let hasNegativeProgress = false
/**
 * Progress情報からチャートのレンダリングに必要な型に変換
 *
 * 日付ごとに表示するために時間を切り取る
 * 進捗率は0 ~ 100で丸めるが、 allowNegativeValue が true のときは負数は丸めずそのまま返却する
 */
const progressToSeries = (
  progressies: Array<Progress>,
  allowNegativeValue = false,
): Array<Series> => {
  const getY = (p: Progress) => {
    if (new Date().getTime() < p.date.getTime()) return null
    if (allowNegativeValue && p.progressRate < 0) {
      hasNegativeProgress = true
      return Math.floor(p.progressRate)
    }
    return Math.floor(roundProgressRate(p.progressRate))
  }
  return progressies.map((p) => ({
    id: p.id,
    resourceId: p.resourceId,
    label: truncateName(p.name),
    x: dayjs(p.date).format('YYYY-MM-DD'),
    y: getY(p),
  }))
}

const tooltipDatum = (datum?: unknown): Series => {
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (!datum)
    return {
      label: '',
      x: '',
      y: 0,
    }
  return datum as Series
}

const accessors = {
  xAccessor: (d: { x: string }) => d.x,
  yAccessor: (d: { y: number | null }) => d.y,
}

const generateKey = (text: string, index: number): string => `${text + index}`
const BASE_SERIES = 'base series'
const tooltipStyle = {
  ...defaultStyles,
  zIndex: 1000, // OKRページで表示した時にツールチップが表示されない
}
const drawerTooltipStyle = {
  ...defaultStyles,
  border: `solid 1px ${color('border-bk-20')}`,
  borderRadius: '4px',
  padding: '4px 8px',
  zIndex: 1000, // OKRページで表示した時にツールチップが表示されない
}
const tooltipCss = css({
  fontWeight: 'normal',
  width: '268px',
  padding: '10px 14px',
})
const tooltipDateCss = css({ display: 'block' })
const tooltipKRNameCss = css({
  marginTop: '4px',
  padding: '8px 0px 8px 12px',
  borderLeft: `4px solid ${color('kr-green-100')}`,
  display: 'block',
  marginBottom: '8px',
})
const glyphStyle = css({
  fill: color('objective-blue-100'),
  stroke: hex2rgba(color('objective-blue-100'), 30),
  strokeWidth: 4,
})
const baseSeriesCss = {
  stroke: 'transparent',
}
const krSeriesCss = {
  stroke: color('kr-green-100'),
} as const
const futureKrSeriesCss = {
  stroke: color('kr-green-100'),
  strokeDasharray: '4 4',
} as const
const xyChartMargin: Margin = {
  top: 10,
  right: 40,
  bottom: 20,
  left: 38,
}
const xyChartXScale = { type: 'point', padding: 0.1 } as const
const xyChartYScale = { type: 'linear' } as const

const customTheme = buildChartTheme({
  backgroundColor: color('white-100'),
  colors: [color('objective-blue-100')],
  gridColor: color('border-bk-10'),
  gridColorDark: '#222831',
  svgLabelSmall: { fill: color('text-bk-50') },
  svgLabelBig: { fill: color('text-bk-50') },
  tickLength: 3,
})

type XAxisTickLabelType = { value: string; index: number }
/**
 * x軸のラベルの生成
 * 年が同じであれば日付だけを表示する
 *
 * @param v date string
 * @param index number
 * @param values { value: string; index: number }, all label values
 */
const xAxisTickLabel = (
  v: string,
  index: number,
  values: ReadonlyArray<XAxisTickLabelType>,
): string => {
  const pre = values[index - 1] as XAxisTickLabelType | undefined
  const curDate = new Date(v)
  if (!pre) {
    return dayjs(curDate).format('YYYY/MM/DD')
  }
  const preDate = new Date(pre.value)
  if (preDate.getFullYear() !== curDate.getFullYear()) {
    return dayjs(curDate).format('YYYY/MM/DD')
  }
  return dayjs(curDate).format('MM/DD')
}

const hideTickLabel = () => ''

const yAxisTickLabel = (v: number) => `${v}%`

type PartiallyPartial<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>

export type Props = {
  objective: PartiallyPartial<ObjectiveFragment, 'progressRateHistory'>
  progressies: ReadonlyArray<ReadonlyArray<Progress>>
  term?: TermFragment
  termStartDate?: Date
  width: number
  height: number
  fromDrawer?: boolean
  fromObjectiveDrawer?: boolean
  keyResultForecast?: Array<KeyResultForecastFragment>
  hideXTicks?: boolean
}

export const Chart: VFC<Props> = ({
  objective,
  progressies: beforeFormat,
  term,
  termStartDate,
  width,
  height,
  fromDrawer,
  fromObjectiveDrawer,
  keyResultForecast,
  hideXTicks,
}) => {
  useProfiler('Chart')
  const today = new Date(dayjs().format('YYYY-MM-DD')).valueOf()
  const isFutureOkrTerm =
    term && today < new Date(dayjs(term.startDate).format('YYYY-MM-DD')).valueOf()
  const isPrevOkrTerm = term && today > new Date(dayjs(term.endDate).format('YYYY-MM-DD')).valueOf()
  const currentOkrTermFinalDay =
    term && today === new Date(dayjs(term.endDate).format('YYYY-MM-DD')).valueOf()
  const progressies: Array<Array<Progress>> = useMemo(
    () =>
      beforeFormat.map((ps) =>
        ps.map((p) => ({
          ...p,
          date: new Date(p.date), // FIXME: date stringを日付objectに変換しておく
        })),
      ),
    [beforeFormat],
  )
  // 本日とOKR期間最終日をグラフに追加する
  const futureDates = useMemo(() => (term ? [new Date(), new Date(term.endDate)] : []), [term])

  const baseDates: ReadonlyArray<Date> = useMemo(() => {
    const allProgressDates = progressies.map((ps) =>
      ps.reduce((pre: Array<Date>, cur) => pre.concat(cur.date), []),
    )
    allProgressDates.map((d) => d.push(...futureDates))
    return sortDate(uniqByDates(allProgressDates))
  }, [futureDates, progressies])

  const multiKrProgressRatesToProgress = progressies.map((ps) =>
    progressRatesToProgress(baseDates, ps),
  )

  const multiSeries: ReadonlyArray<ReadonlyArray<Series>> = useMemo(() => {
    // 未来のOKR期間は未来の開始日と未来の終了日のみを表示
    if (term && new Date().valueOf() < new Date(term.startDate).valueOf()) {
      // FIXME: KRが1つも無い場合にx軸を生成するため空のKRを作る
      if (!progressies.length) {
        return [
          [new Date(term.startDate), new Date(term.endDate)].map((bd) => ({
            label: '',
            x: dayjs(bd).format('YYYY-MM-DD'),
            y: null,
          })),
        ]
      }
      return multiKrProgressRatesToProgress.map((ps) =>
        progressToSeries(ps).filter((pr) => new Date(pr.x) >= new Date(term.startDate)),
      )
    }
    // 過去のOKR期間は開始日と終了日のチャートを表示
    if (term && new Date().valueOf() > new Date(term.endDate).valueOf()) {
      // FIXME: KRが1つも無い場合にx軸を生成するため空のKRを作る
      if (!progressies.length) {
        return [
          [new Date(term.startDate), new Date(term.endDate)].map((bd) => ({
            label: '',
            x: dayjs(bd).format('YYYY-MM-DD'),
            y: null,
          })),
        ]
      }
      return multiKrProgressRatesToProgress.map((ps) =>
        progressToSeries(ps).filter((pr) => new Date(pr.x) <= new Date(term.endDate)),
      )
    }
    // 現在のOKR期間
    // FIXME: KRが1つも無い場合にx軸を生成するため空のKRを作る
    if (!progressies.length && term) {
      return [
        [new Date(term.startDate), new Date(), new Date(term.endDate)].map((bd) => ({
          label: '',
          x: dayjs(bd).format('YYYY-MM-DD'),
          y: null,
        })),
      ]
    }
    return multiKrProgressRatesToProgress.map((ps) => progressToSeries(ps, true))
  }, [progressies, term, multiKrProgressRatesToProgress])

  const drawerKrSeries = useMemo(() => {
    // 未来のOKR期間は未来の開始日と未来の終了日のみを表示
    if (term && new Date().valueOf() < new Date(term.startDate).valueOf()) {
      return multiKrProgressRatesToProgress.map((ps) =>
        ps
          .map((p) => ({
            id: p.id,
            label: truncateName(p.name),
            x: dayjs(p.date).format('YYYY-MM-DD'),
            y: Math.floor(roundProgressRate(p.progressRate)),
          }))
          .filter((pr) => new Date(pr.x) >= new Date(term.startDate)),
      )
    }

    // 過去のOKR期間は開始日と終了日のチャートを表示
    if (term && new Date().valueOf() > new Date(term.endDate).valueOf()) {
      return multiKrProgressRatesToProgress.map((ps) =>
        ps
          .map((p) => ({
            id: p.id,
            label: truncateName(p.name),
            x: dayjs(p.date).format('YYYY-MM-DD'),
            y: Math.floor(roundProgressRate(p.progressRate)),
          }))
          .filter((pr) => new Date(pr.x) <= new Date(term.endDate)),
      )
    }
    return multiKrProgressRatesToProgress.map((ps) =>
      ps.map((p) => {
        const targetKeyResultForecast = keyResultForecast?.find((kr) => kr.id === p.resourceId)
        if (targetKeyResultForecast) {
          return {
            id: p.id,
            label: truncateName(p.name),
            x: dayjs(p.date).format('YYYY-MM-DD'),
            y:
              today < new Date(dayjs(p.date).format('YYYY-MM-DD')).valueOf()
                ? targetKeyResultForecast.progressForecast
                : Math.floor(roundProgressRate(p.progressRate)),
          }
        }
        return {
          id: p.id,
          label: truncateName(p.name),
          x: dayjs(p.date).format('YYYY-MM-DD'),
          y: Math.floor(roundProgressRate(p.progressRate)),
        }
      }),
    )
  }, [term, multiKrProgressRatesToProgress, keyResultForecast, today])

  // 到達予測のKrSeries
  const futureKrSeries: Array<Array<Series>> | null = useMemo(() => {
    // 現在〜OKR終了日までフィルタリング
    const krSeries = multiKrProgressRatesToProgress.map((ps) =>
      progressToSeries(ps).filter(
        (p) =>
          new Date(dayjs().format('YYYY-MM-DD')).valueOf() <=
          new Date(dayjs(p.x).format('YYYY-MM-DD')).valueOf(),
      ),
    )

    if (!keyResultForecast || isFutureOkrTerm || isPrevOkrTerm || currentOkrTermFinalDay) {
      return null
    }

    // ObjectiveドロワーのkrSeries
    if (krSeries.length > 1) {
      return krSeries.map((ps, i) =>
        ps.map((p) => ({
          label: p.label,
          x: p.x,
          y: p.y !== null ? p.y : keyResultForecast[i].progressForecast,
        })),
      )
    }
    // KeyResultドロワーのkrSeries
    return krSeries.map((ps) =>
      ps.map((p) => {
        const targetKeyResult = keyResultForecast.find((kr) => kr.id === p?.resourceId)
        return {
          label: p.label,
          x: p.x,
          y: p.y !== null ? p.y : targetKeyResult?.progressForecast ?? null,
        }
      }),
    )
  }, [
    multiKrProgressRatesToProgress,
    keyResultForecast,
    currentOkrTermFinalDay,
    isFutureOkrTerm,
    isPrevOkrTerm,
  ])
  // ライブラリ側のy軸の値を基準となる最大値にするために必要
  const baseSeries: Array<Series> = useMemo(
    () => [
      {
        label: truncateName(objective.name),
        x: dayjs(term === undefined ? termStartDate : term.startDate).format('YYYY-MM-DD'),
        y: 100, // 進捗率の最大値
      },
    ],
    [objective.name, term, termStartDate],
  )

  const keys = useMemo(() => progressies.map((_, i) => generateKey('stack-', i)), [progressies])

  const progressForecastKeys = useMemo(
    () => progressies.map((_, i) => generateKey('second-stack-', i)),
    [progressies],
  )

  const renderTooltip = useCallback(
    ({ tooltipData }: { tooltipData: TooltipData }) => {
      if (tooltipData?.nearestDatum?.key === BASE_SERIES) return null

      if (fromDrawer && !isFutureOkrTerm) {
        const nearestDatum = tooltipData?.nearestDatum?.datum as Series
        return (
          <DrawerTooltip
            tooltipData={tooltipData}
            objective={objective}
            krSeries={drawerKrSeries}
            keys={keys}
            progressForecastKeys={progressForecastKeys}
            fromObjectiveDrawer={fromObjectiveDrawer}
            displayProgressForecast={!isPrevOkrTerm && today < new Date(nearestDatum.x).valueOf()}
          />
        )
      }
      return (
        <div css={tooltipCss}>
          {/* 日付 */}
          <StyledText size="xsmall" color="text-bk-50" css={tooltipDateCss}>
            <DateTime
              datetime={
                new Date(accessors.xAccessor(tooltipDatum(tooltipData?.nearestDatum?.datum)))
              }
              withoutTime
            />
          </StyledText>
          {
            // Key Results
            keys.map((key: string) => {
              const datum = tooltipData?.datumByKey[key].datum as Series | undefined
              if (!datum) return ''
              return (
                <StyledText
                  key={key}
                  size="small"
                  color="text-bk-100"
                  weight={tooltipData?.nearestDatum?.key === key ? 'bold' : 'normal'}
                  css={tooltipKRNameCss}
                >
                  {datum.label}
                  {`: `}
                  {datum.y === null ? '-' : `${datum.y}%`}
                </StyledText>
              )
            })
          }
        </div>
      )
    },
    [
      keys,
      fromDrawer,
      drawerKrSeries,
      objective,
      fromObjectiveDrawer,
      today,
      progressForecastKeys,
      isFutureOkrTerm,
      isPrevOkrTerm,
    ],
  )

  return (
    <div style={{ width, height }}>
      {/* 座標を示すsvg要素のstyleが指定できないため以下で指定 */}
      <style>{'.visx-tooltip-glyph > svg { width: 10px; height: 10px;}'}</style>
      <XYChart
        height={height}
        width={width}
        xScale={xyChartXScale}
        yScale={{ ...xyChartYScale, domain: [hasNegativeProgress ? -100 : 0, 100] }}
        theme={customTheme}
        margin={xyChartMargin}
      >
        <Axis
          orientation="bottom"
          stroke="transparent"
          tickStroke="transparent"
          tickFormat={hideXTicks ? hideTickLabel : xAxisTickLabel}
          hideTicks={hideXTicks}
        />
        <Axis
          orientation="left"
          stroke="transparent"
          tickStroke="transparent"
          tickValues={hasNegativeProgress ? [-50, 0, 50, 100] : [0, 50, 100]}
          tickFormat={yAxisTickLabel}
        />
        <Grid key="grid" rows columns={false} numTicks={3} />
        {multiSeries.map((data, i) => (
          <AreaSeries
            key={generateKey('stack-', i)}
            dataKey={generateKey('stack-', i)}
            fill={hex2rgba(color('kr-green-100'), 10)}
            data={data as Array<Series>}
            lineProps={krSeriesCss}
            {...accessors}
          />
        ))}
        {/* 到達予測の点線 */}
        {fromDrawer &&
          futureKrSeries &&
          futureKrSeries.map((data, i) => (
            <AreaSeries
              key={generateKey('second-stack-', i)}
              dataKey={generateKey('second-stack-', i)}
              fill={hex2rgba(color('white-100'), 10)}
              data={data}
              lineProps={futureKrSeriesCss}
              {...accessors}
            />
          ))}
        <Tooltip
          snapTooltipToDatumX
          snapTooltipToDatumY
          showVerticalCrosshair
          verticalCrosshairStyle={
            fromDrawer
              ? {
                  stroke: color('resily-orange-100'),
                  strokeDasharray: '4 4',
                }
              : undefined
          }
          showDatumGlyph
          glyphStyle={glyphStyle}
          style={fromDrawer ? drawerTooltipStyle : tooltipStyle}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          renderTooltip={renderTooltip as any}
        />
        {/* Y軸の最大値を100にするためのダミーseries, tickValuesで指定できるが実際の値がないと線が崩れる */}
        <AreaSeries
          aria-hidden
          dataKey={BASE_SERIES}
          fill="transparent"
          data={baseSeries as Array<Series>}
          lineProps={baseSeriesCss}
          {...accessors}
        />
      </XYChart>
    </div>
  )
}

Chart.displayName = 'Chart'
