import { css } from '@emotion/react'
import { useProfiler } from '@sentry/react'
import {
  CSSProperties,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  VFC,
} from 'react'
import { useNavigate } from 'react-router-dom'

import { AlertModalContext } from '../../../../contexts/AlertModalContext'
import { OkrTermIdContext } from '../../../../contexts/OkrTermIdContext'
import { useCurrentUser } from '../../../../contexts/UserContext'
import { useTranslation } from '../../../../i18n'
import { updateOkrGlossaryResource } from '../../../../i18n/okrGlossary'
import { useFlags } from '../../../../lib/featureToggle'
import { useSideNavLocked } from '../../../../lib/localStorage/useSideNavLocked'
import { tracker } from '../../../../lib/tracking'
import { color } from '../../../../styles/newColors'
import {
  generateHome,
  notifications as urlNotification,
  oneOnOne as urlOneOnOne,
  OkrMapType,
  generateUsers,
  usersInCurrentTerm,
  myHome,
} from '../../../../urls'
import * as urls from '../../../../urls'
import { DeleteModal } from '../../../ui/DeleteModal'
import { Icon, Type as IconType } from '../../../ui/Icon'
import { Link } from '../../../ui/Link'
import { Logo } from '../../../ui/Logo'
import { StyledText } from '../../../ui/StyledText'
import { TooltipNew } from '../../../ui/TooltipNew'

import {
  GloNav,
  GLOBAL_NAV_BOX_CLASS_NAME,
  GLOBAL_NAV_SELECT_BOX,
  GLOBAL_NAV_TERM_DATE_BOX,
  GLOBAL_NAV_RATE_TEXT_CLASS_NAME,
  GLOBAL_NAV_TERM_RATE_BOX,
} from './GloNav'
import { NavLink, NAV_LINK_TEXT_CLASS_NAME } from './NavLink'
import {
  SideNavBottom,
  USER_ICON_CLASS_NAME,
  COLLAPSE_CLASS_NAME,
  SIDE_NAV_BOTTOM_CLASS_NAME,
} from './SideNavBottom'
import {
  BookmarkFragment,
  TermFragment,
  useDeleteUserBookmarkMutation,
  useInitialQueryQuery,
} from './graphql'
import { useHistoryForMap } from './hooks'
import { useSideNavWidth } from './useSideNavWidth'

export const SIDE_NAV_ID = 'SideNav'
const LOGO_ICON_CLASS_NAME = 'side-nav-logo-icon'
const LOGO_TEXT_CLASS_NAME = 'side-nav-logo-text'
const GLONAV_BORDER_CLASS_NAME = 'glonav-border'
const SIDE_NAV_MAP_ITEM_LABEL = 'side-nav-map-item-label'
const SIDE_NAV_OPEN_WIDTH = 240
const SIDE_NAV_CLOSE_WIDTH = 56

// グロナビ内部のアニメーション全体の共通プロパティ
const COMMON_TRANSITION: CSSProperties = {
  transitionDuration: '200ms',
  transitionTimingFunction: 'cubic-bezier(0.820, 0.085, 0.395, 0.895)',
}

const hideAnimation: CSSProperties = {
  ...COMMON_TRANSITION,
  transitionProperty: 'opacity',
  opacity: 0,
  visibility: 'collapse',
}
const showAnimation: CSSProperties = {
  ...COMMON_TRANSITION,
  transitionProperty: 'opacity',
  opacity: 1,
  visibility: 'visible',
}
const sideNavShowingStyle = css({
  ...COMMON_TRANSITION,
  transitionProperty: 'max-width',
  maxWidth: SIDE_NAV_OPEN_WIDTH,
  [`.${SIDE_NAV_BOTTOM_CLASS_NAME}`]: {
    maxWidth: SIDE_NAV_OPEN_WIDTH,
  },
  [`.${LOGO_TEXT_CLASS_NAME}`]: {
    ...showAnimation,
  },
  [`.${NAV_LINK_TEXT_CLASS_NAME}`]: {
    ...showAnimation,
  },
  [`.${USER_ICON_CLASS_NAME}`]: {
    ...showAnimation,
  },
  [`.${COLLAPSE_CLASS_NAME}`]: {
    ...showAnimation,
  },
  [`.${GLONAV_BORDER_CLASS_NAME}`]: {
    maxWidth: '100vw',
  },
  [`.${GLOBAL_NAV_BOX_CLASS_NAME}`]: {
    ...COMMON_TRANSITION,
    padding: '20px',
    transitionProperty: 'padding',
    'span:first-of-type': {
      ...showAnimation,
    },
    [`.${GLOBAL_NAV_RATE_TEXT_CLASS_NAME}`]: {
      width: 'initial',
      textAlign: 'right',
      whiteSpace: 'nowrap',
    },
  },
  [`.${GLOBAL_NAV_TERM_RATE_BOX}`]: {
    marginTop: '24px',
  },
  [`.${GLOBAL_NAV_TERM_DATE_BOX}`]: {
    ...showAnimation,
  },
  [`.${GLOBAL_NAV_SELECT_BOX}`]: {
    ...showAnimation,
  },
  [`.${SIDE_NAV_MAP_ITEM_LABEL}`]: {
    ...showAnimation,
    maxHeight: '100vh',
    overflow: 'hidden',
    transitionProperty: `${showAnimation.transitionProperty}, max-height`,
    willChange: `${showAnimation.transitionProperty}, max-height`,
  },
  [`.${SIDE_NAV_MAP_ITEM_LABEL} + *`]: {
    marginTop: 'revert',
  },
})

const sideNavAreaCss = css({
  height: '100%',
  transition: 'width 300ms cubic-bezier(0.820, 0.085, 0.395, 0.895)',
  width: SIDE_NAV_CLOSE_WIDTH,
  '&[data-is-locked="true"]': {
    width: SIDE_NAV_OPEN_WIDTH,
  },
})
const sideNavCss = css({
  position: 'absolute',
  zIndex: 10,
  top: 0,
  left: 0,
  borderRight: `1px solid ${color('border-bk-10')}`,
  backgroundColor: color('white-100'),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
  width: '100%',
  ...COMMON_TRANSITION,
  transitionProperty: 'max-width',
  maxWidth: SIDE_NAV_CLOSE_WIDTH,
  [`.${SIDE_NAV_BOTTOM_CLASS_NAME}`]: {
    width: '100%',
    ...COMMON_TRANSITION,
    maxWidth: SIDE_NAV_CLOSE_WIDTH,
    transitionProperty: 'max-width',
  },
  [`.${GLONAV_BORDER_CLASS_NAME}`]: {
    ...COMMON_TRANSITION,
    width: '100%',
    maxWidth: 0,
    height: '1px',
    backgroundColor: color('border-bk-10'),
    transitionProperty: 'max-width',
  },
  [`.${GLOBAL_NAV_BOX_CLASS_NAME}`]: {
    ...COMMON_TRANSITION,
    padding: '20px 12px',
    transitionProperty: 'padding',
    'span:first-of-type': {
      ...hideAnimation,
    },
    [`.${GLOBAL_NAV_RATE_TEXT_CLASS_NAME}`]: {
      textAlign: 'center',
      whiteSpace: 'nowrap',
    },
  },
  [`.${GLOBAL_NAV_TERM_RATE_BOX}`]: {
    marginTop: 'auto',
  },
  [`.${GLOBAL_NAV_TERM_DATE_BOX}`]: {
    ...hideAnimation,
  },
  [`.${GLOBAL_NAV_SELECT_BOX}`]: {
    ...hideAnimation,
  },
  [`.${USER_ICON_CLASS_NAME}`]: {
    ...hideAnimation,
  },
  [`.${COLLAPSE_CLASS_NAME}`]: {
    ...hideAnimation,
  },
  [`.${NAV_LINK_TEXT_CLASS_NAME}`]: {
    ...hideAnimation,
  },
  [`.${SIDE_NAV_MAP_ITEM_LABEL}`]: {
    ...hideAnimation,
    maxHeight: 0,
    overflow: 'hidden',
    transitionProperty: `${hideAnimation.transitionProperty}, max-height`,
    willChange: `${showAnimation.transitionProperty}, max-height`,
  },
  [`.${SIDE_NAV_MAP_ITEM_LABEL} + *`]: {
    marginTop: -16,
  },
  '&[data-is-locked="true"]': {
    ...sideNavShowingStyle,
  },
  ':hover': {
    ...sideNavShowingStyle,
  },
})
const navLinkCss = css({
  width: 228,
  height: 28,
})
const notificationNumberCircleCss = css({
  display: 'block',
  '&[data-is-unread-count="0"]': {
    display: 'none',
  },
  backgroundColor: color('resily-orange-100'),
  borderRadius: '18px',
  width: '18px',
  height: '18px',
  position: 'absolute',
  top: -4,
  left: 28,
  boxShadow: '0px 1px 1px rgb(0 0 0 / 10%)',
})
const notificationNumberCss = css({
  display: 'block',
  fontSize: 8,
  lineHeight: '18px',
  textAlign: 'center',
  fontWeight: 'bold',
})

const mapItemBaseCss = css({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  whiteSpace: 'nowrap',
  fontSize: 14,
  ':disabled': {
    cursor: 'default',
  },
})

const mapItemActiveCss = css({
  color: color('resily-orange-100'),
  'path[fill]': { fill: color('resily-orange-100') },
  [`svg.${SIDE_NAV_MAP_ITEM_LABEL}`]: {
    'path[fill]': { fill: color('text-bk-100') },
  },
})

const mapItemCss = css(mapItemBaseCss, {
  ':hover': {
    color: color('resily-orange-100'),
    'path[fill]': {
      fill: color('resily-orange-100'),
    },
  },
  ':disabled': {
    ...mapItemActiveCss,
  },
  '&[data-is-active="true"]': {
    ...mapItemActiveCss,
  },
})

const mapItemIconCss = css({
  marginRight: 16,
  minWidth: 16,
})

const openIconCss = css({
  flex: '0 0 16px',
  '> path[fill]': { fill: color('text-bk-30') },
  ...COMMON_TRANSITION,
  transitionProperty: 'transform',
  transform: 'scaleY(1)',
  '&[data-is-open="true"]': {
    transform: 'scaleY(-1)',
  },
})

const bookmarkButtonCss = css({
  display: 'inline-flex',
  width: 'calc(100% - 44px)',
  maxWidth: 'calc(100% - 44px)',
  height: 20,
  marginLeft: 32,
  marginRight: 12,
  padding: '0 4px',
  alignItems: 'center',
  justifyContent: 'space-between',
  svg: { visibility: 'hidden' },
  '> div': {
    maxWidth: 'calc(100% - 20px)',
    overflow: 'hidden',
    color: color('text-bk-100'),
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  ':hover': {
    '> div': { color: color('resily-orange-100') },
    backgroundColor: color('hover-background-bk-5'),
    svg: { visibility: 'visible' },
  },
  '&[data-is-active="true"]': {
    '> div': { color: color('resily-orange-100') },
  },
})

const bookmarkClearButtonCss = css({
  color: color('text-bk-30'),
  ':hover': {
    color: color('resily-orange-100'),
  },
})

type OkrMapItem = {
  label: string
  type: OkrMapType
  icon: IconType
  onClick: () => void
}

export const SideNav: VFC = ({ ...props }) => {
  useProfiler('SideNav')

  const { t, i18n } = useTranslation()
  const navigate = useNavigate()

  const [sideNavLocked, setSideNavLocked] = useSideNavLocked(true)
  const [isOpenedProfile, setIsPopoverOpen] = useState(false)
  const [isMessenger, setIsMessenger] = useState(false)
  const [isOpenedSelectTerm, setIsOpenedSelectTerm] = useState(false)
  const isOpenedSideNav = useMemo(
    () => sideNavLocked || isOpenedProfile || isOpenedSelectTerm || isMessenger,
    [isMessenger, isOpenedProfile, isOpenedSelectTerm, sideNavLocked],
  )
  const [isOpenedBookmark, setIsOpenedBookmark] = useState(true)
  const onClickBookmarkHandler = useCallback(() => setIsOpenedBookmark((prev) => !prev), [])

  const [openBookmarkId, setOpenBookmarkId] = useState<string | null>(null)
  const onOpenBookmarkDeleteModalHandler = useCallback((id: string) => setOpenBookmarkId(id), [])
  const onCloseBookmarkDeleteModalHandler = useCallback(() => setOpenBookmarkId(null), [])

  const { okrTermId, updateOkrTermId } = useContext(OkrTermIdContext)
  const user = useCurrentUser()
  const { oneOnOne } = useFlags()

  const ref = useRef<HTMLDivElement>(null)
  const [, setSideNavWidth] = useSideNavWidth(
    isOpenedSideNav ? SIDE_NAV_OPEN_WIDTH : SIDE_NAV_CLOSE_WIDTH,
  )
  useEffect(() => {
    // sideNavの開閉状態変更されたら、sideNavWidthを更新する
    setSideNavWidth(isOpenedSideNav ? SIDE_NAV_OPEN_WIDTH : SIDE_NAV_CLOSE_WIDTH)
  }, [setSideNavWidth, isOpenedSideNav])

  const { status: alertModalStatus, checkOpenAlertModal } = useContext(AlertModalContext)
  const onOkrTermChange = useCallback(
    (id: string) => {
      checkOpenAlertModal({
        onClickDiscard: () => {
          updateOkrTermId(id)
        },
      })
    },
    [checkOpenAlertModal, updateOkrTermId],
  )

  const { data: initialData } = useInitialQueryQuery()

  if (initialData?.customOkrGlossaries) {
    // OKR用語の文言変更の反映
    updateOkrGlossaryResource(i18n, initialData.customOkrGlossaries)
  }

  const okrTerms: ReadonlyArray<TermFragment> = useMemo(
    () => initialData?.okrTerms || [],
    [initialData?.okrTerms],
  )
  const term = useMemo(() => okrTerms.find((v) => v.id === okrTermId), [okrTermId, okrTerms])

  const toggleSideNavLocked = useCallback(() => {
    setSideNavLocked(!sideNavLocked)
  }, [setSideNavLocked, sideNavLocked])

  const bookmarks: ReadonlyArray<BookmarkFragment> = useMemo(
    () => okrTerms.find((okrTerm) => okrTerm.id === okrTermId)?.userBookmarks ?? [],
    [okrTermId, okrTerms],
  )

  const { pushMapType, pushMapBookmark, queryParamsOkrList, mapLink, bookmarkLink } =
    useHistoryForMap({
      user,
      isLoadedBookmark: okrTermId != null && okrTerms.length > 0,
      bookmarks,
      termId: okrTermId,
    })

  useEffect(() => {
    if (bookmarks.some((bookmark) => bookmark.id === queryParamsOkrList)) {
      setIsOpenedBookmark(true)
    }
  }, [bookmarks, queryParamsOkrList])

  const [deleteBookmarkMutation] = useDeleteUserBookmarkMutation()

  const mapLinks = useMemo<ReadonlyArray<OkrMapItem>>(() => {
    const onClickMapTypeHandler = (type: OkrMapType) => {
      if (alertModalStatus.type !== 'NONE') {
        checkOpenAlertModal({
          onClickDiscard: () => {
            pushMapType(type)
          },
        })
        return
      }
      pushMapType(type)
    }

    return [
      {
        label: t('MY_OKR'),
        type: 'self',
        icon: 'myPage',
        onClick: () => {
          onClickMapTypeHandler('self')
          tracker.GlobalNavTransitionToMyTree()
        },
      },
      {
        label: t('GROUP_OKR'),
        type: 'group',
        icon: 'group2',
        onClick: () => {
          onClickMapTypeHandler('group')
          tracker.GlobalNavTransitionToGroupTree()
        },
      },
      {
        label: t('COMPANY_OKR'),
        type: 'all',
        icon: 'company',
        onClick: () => {
          onClickMapTypeHandler('all')
          tracker.GlobalNavTransitionToAllTree()
        },
      },
    ]
  }, [alertModalStatus.type, checkOpenAlertModal, pushMapType, t])

  const homeUrl = okrTermId && user?.id ? generateHome(okrTermId)(user.id) : myHome
  const usersUrl = okrTermId ? generateUsers(okrTermId) : usersInCurrentTerm

  const unreadNotificationCount = 0

  return (
    <div>
      {/* sideNavの領域として確保するエリア */}
      <div
        id={SIDE_NAV_ID}
        data-is-locked={sideNavLocked}
        css={sideNavAreaCss}
        ref={ref}
        className="sideNav"
      />
      <div data-is-locked={isOpenedSideNav} {...props} css={sideNavCss} className="sideNav">
        <div
          css={{
            padding: '28px 0',
            height: '100%',
            overflowY: 'auto',
            overflowX: 'hidden',
          }}
        >
          <a
            href={urls.root}
            css={{ display: 'flex', gap: 2, marginLeft: 14, '> *': { flexShrink: 0 } }}
          >
            <Logo iconOnly className={LOGO_ICON_CLASS_NAME} width={32} height={32} />
            <Logo
              className={LOGO_TEXT_CLASS_NAME}
              css={{ opacity: 0, visibility: 'collapse' }}
              width={121}
              height={32}
              viewBox="30 0 106 28"
            />
          </a>
          <div
            css={{
              width: '100%',
              marginTop: 24,
              display: 'flex',
              flexDirection: 'column',
              gap: 24,
            }}
          >
            <NavLink
              icon="home"
              title={t('HOME')}
              href={homeUrl}
              trackingEvent={tracker.GlobalNavTransitionToHome}
              css={navLinkCss}
              end={false}
            />
            <NavLink
              icon="treeView"
              title={t('OKR_MAP')}
              // ここのhrefはマップ画面のどれかを開いている時にスタイルをactiveにするために定義している
              // 実際のクリック時の遷移先はonClickで制御
              href={
                okrTermId
                  ? urls.generatePathWithCurrentTerm(urls.trees, { termId: okrTermId })
                  : urls.treesInCurrentTerm
              }
              css={navLinkCss}
              end={false}
              tabIndex={-1}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                navigate(mapLink(mapLinks[0].type), { replace: true })
                mapLinks[0].onClick()
              }}
            />
            <div
              css={{
                paddingLeft: 40,
                marginTop: -8,
                display: 'flex',
                flexDirection: 'column',
                gap: 12,
              }}
              className={SIDE_NAV_MAP_ITEM_LABEL}
            >
              {mapLinks.map(({ label, type, icon, onClick }) => (
                <Link
                  key={type}
                  href={mapLink(type)}
                  css={{ ':hover': { textDecoration: 'none' } }}
                >
                  <button
                    type="button"
                    css={mapItemCss}
                    onClick={onClick}
                    data-is-active={queryParamsOkrList === type}
                  >
                    <Icon css={mapItemIconCss} type={icon} />
                    <StyledText size="large" fontStyle="regular">
                      {label}
                    </StyledText>
                  </button>
                </Link>
              ))}
              {bookmarks.length !== 0 && (
                <div css={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
                  <button
                    type="button"
                    css={[
                      mapItemCss,
                      { justifyContent: 'space-between', marginRight: 16, marginBottom: 4 },
                    ]}
                    onClick={onClickBookmarkHandler}
                    data-is-active={bookmarks.some(
                      (bookmark) => bookmark.id === queryParamsOkrList,
                    )}
                  >
                    <div>
                      <Icon css={mapItemIconCss} type="bookmark2" />
                      <StyledText size="large" fontStyle="regular">
                        {t('BOOKMARK_OKR')}
                      </StyledText>
                    </div>
                    <Icon type="closed" css={openIconCss} data-is-open={isOpenedBookmark} />
                  </button>
                  {isOpenedBookmark &&
                    bookmarks.map((bookmark) => (
                      <Link
                        key={bookmark.id}
                        href={bookmarkLink(bookmark.id)}
                        css={{
                          ':hover': {
                            textDecoration: 'none',
                          },
                        }}
                      >
                        <button
                          type="button"
                          css={bookmarkButtonCss}
                          data-is-active={bookmark.id === queryParamsOkrList}
                          onClick={() => {
                            pushMapBookmark(bookmark.id)
                            tracker.GlobalNavTransitionToBookmarkTree()
                          }}
                        >
                          <TooltipNew title={bookmark.name}>
                            <StyledText size="small" fontStyle="regular">
                              {bookmark.name}
                            </StyledText>
                          </TooltipNew>
                          <TooltipNew title={t('DELETION')}>
                            <Icon
                              css={bookmarkClearButtonCss}
                              type="clear"
                              width={12}
                              height={12}
                              onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                onOpenBookmarkDeleteModalHandler(bookmark.id)
                              }}
                            />
                          </TooltipNew>
                        </button>
                      </Link>
                    ))}
                </div>
              )}
            </div>
            {oneOnOne && (
              <NavLink
                icon="oneOnOne"
                title={t('ONEONONE')}
                href={urlOneOnOne}
                trackingEvent={tracker.GlobalNavTransitionToOneOnOne}
                css={navLinkCss}
              />
            )}
            <NavLink
              icon="userList"
              title={t('OKR_SETTING_STATUS')}
              href={usersUrl}
              trackingEvent={tracker.GlobalNavTransitionToUsers}
              css={navLinkCss}
            />
            <NavLink
              icon="notification"
              title={t('NOTIFICATION')}
              href={urlNotification}
              trackingEvent={tracker.GlobalNavTransitionToNotification}
              css={navLinkCss}
            />
            <div data-is-unread-count={unreadNotificationCount} css={notificationNumberCircleCss}>
              <StyledText size="xxsmall" color="white-100" css={notificationNumberCss}>
                {unreadNotificationCount}
              </StyledText>
            </div>
          </div>
        </div>
        {!!term && (
          <Fragment>
            <div className={GLONAV_BORDER_CLASS_NAME} />
            <GloNav
              okrTerms={okrTerms}
              term={term}
              onOkrTermChange={onOkrTermChange}
              setIsOpenedSelectTerm={setIsOpenedSelectTerm}
            />
          </Fragment>
        )}
        <SideNavBottom
          sideNavLocked={sideNavLocked}
          isPopoverOpen={isOpenedProfile}
          onClickCollapse={toggleSideNavLocked}
          setIsPopoverOpen={setIsPopoverOpen}
          setIsMessenger={setIsMessenger}
        />
        <DeleteModal
          isOpened={openBookmarkId != null}
          deleteItemName={t('BOOKMARK')}
          onClickDelete={() => {
            if (openBookmarkId) {
              deleteBookmarkMutation({ variables: { bookmarkId: openBookmarkId } })
            }
            onCloseBookmarkDeleteModalHandler()
          }}
          onClickCancel={onCloseBookmarkDeleteModalHandler}
          onClickOutside={onCloseBookmarkDeleteModalHandler}
        />
      </div>
    </div>
  )
}

SideNav.displayName = 'SideNav'
