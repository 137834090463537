import { BreadcrumbParams } from '../../components/domain/Breadcrumb'
import { ObjectiveDetailEditPageCard } from '../../components/domain/ObjectiveDetailEditPageCard'
import { ObjectiveDetailOption } from '../../components/domain/ObjectiveDetailOption'
import { PageContent } from '../../components/pageContent'
import { useTranslation } from '../../i18n'
import { Screen } from '../../lib/screen'
import { useTracking } from '../../lib/tracking'
import { color } from '../../styles/newColors'
import { AuthRouteProps } from '../../types/authRouteProps'

import { OkrDetailObjectiveFragment, DocumentFragment } from './graphql'
import { useInjection } from './hooks'

export const ObjectiveDetailEditContainer: React.FC<AuthRouteProps> = (props) => {
  const data = useInjection(props)

  if (!data) {
    return null
  }

  return <ObjectiveDetailEdit {...data} />
}

ObjectiveDetailEditContainer.displayName = 'ObjectiveDetailEditContainer'

export type Props = {
  objective: OkrDetailObjectiveFragment
  breadcrumbs: BreadcrumbParams
  onUpdateObjective: (
    objective: OkrDetailObjectiveFragment,
    beforeDescription: DocumentFragment,
    redirect: boolean,
  ) => void
  onClickCancel: () => void
  setNeedBlock: (value: boolean) => void
}

export const ObjectiveDetailEdit: React.FC<Props> = ({
  objective,
  breadcrumbs,
  onUpdateObjective,
  onClickCancel,
  setNeedBlock,
}) => {
  const { t } = useTranslation()

  useTracking(
    t('OBJECTIVE_EDIT_PAGE_TITLE', { objectiveName: objective?.name || '' }),
    Screen.ObjectiveDetailInfo,
  )

  if (!objective?.id) return null

  return (
    <PageContent
      breadcrumbs={breadcrumbs}
      layout={{
        css: {
          backgroundColor: color('background-bk-5'),
          paddingTop: '60px',
          paddingLeft: '76px',
        },
      }}
    >
      <div css={{ display: 'flex' }}>
        <div
          css={{
            flex: '4 1 0',
          }}
        >
          <ObjectiveDetailEditPageCard
            objective={objective}
            updateObjective={onUpdateObjective}
            onClickCancel={onClickCancel}
            setNeedBlock={setNeedBlock}
          />
        </div>
        <div css={{ marginTop: '9px', marginRight: '77px', flex: '1 1 0' }}>
          <ObjectiveDetailOption objective={objective} />
        </div>
      </div>
    </PageContent>
  )
}

ObjectiveDetailEdit.displayName = 'ObjectiveDetailEdit'
