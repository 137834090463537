import { VFC } from 'react'

import { useTranslation } from '../../../i18n'
import { color } from '../../../styles/newColors'
import { okrTermsAdmin } from '../../../urls'
import { Button } from '../../ui/Button'
import { Icon } from '../../ui/Icon'
import { Link } from '../../ui/Link'
import { StyledText } from '../../ui/StyledText'

export type Props = {
  onClose: () => void
}

export const CreateNewOkrTermAlert: VFC<Props> = ({ onClose }) => {
  const { t } = useTranslation()

  return (
    <div
      css={{
        position: 'fixed',
        bottom: '44px',
        left: '50%',
        whiteSpace: 'nowrap',
        transform: 'translateX(-50%)',
        display: 'flex',
        justifyContent: 'center',
        zIndex: 10,
      }}
    >
      <div
        css={{
          boxShadow: '0px 16px 32px rgb(34 41 67 / 16%)',
          borderRadius: '4px',
          display: 'inline-block',
          backgroundColor: color('white-100'),
        }}
      >
        <div
          css={{
            height: '64px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '0 16px',
            width: '616px',
          }}
        >
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Icon type="notes" css={{ width: '40px', height: '40px' }} />
            <div
              css={{
                display: 'flex',
                flexDirection: 'column',
                marginLeft: '12px',
              }}
            >
              <StyledText weight="bold" size="medium">
                {t('CREATE_NEW_OKR_TERM_TITLE')}
              </StyledText>
              <StyledText size="small" color="text-bk-50">
                {t('CREATE_NEW_OKR_TERM_MESSAGE')}
              </StyledText>
            </div>
          </div>
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Link href={okrTermsAdmin}>
              <Button
                newColor="resily-orange-100"
                weight="normal"
                size="ss"
                css={{ padding: '0 16px' }}
              >
                {t('CREATE_X', { x: t('OKR_TERM') })}
              </Button>
            </Link>
            <button
              type="button"
              css={{
                '&:focus': {
                  outline: 'none',
                },
                cursor: 'pointer',
                marginLeft: '16px',
              }}
              onClick={onClose}
            >
              <Icon
                type="clear"
                css={{
                  ':hover': { color: color('text-bk-100') },
                  color: color('border-bk-30'),
                  width: '24px',
                  height: '24px',
                }}
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

CreateNewOkrTermAlert.displayName = 'CreateNewOkrTermAlert'
