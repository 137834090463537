import { css } from '@emotion/react'
import { Button as GrommetButton, Spinner } from 'grommet'
import { forwardRef, PropsWithRef } from 'react'

import { border } from '../../../styles/border'
import * as colors from '../../../styles/colors'

import {
  buttonColor,
  ButtonColorAlias,
  DisabledButtonColorAlias,
  disabledButtonColor,
} from './buttonColor'

type Size = 'm' | 's' | 'ss'

export type Props = PropsWithRef<JSX.IntrinsicElements['button']> & {
  color?: 'main' | 'sub' | 'warning'
  newColor?: ButtonColorAlias // colorより優先される
  disabledColor?: DisabledButtonColorAlias // colorより優先される
  disableFocusStyle?: boolean
  size?: Size
  weight?: 'normal' | 'bold'
  isLoading?: boolean
}

export const Button = forwardRef<HTMLButtonElement, Props>(
  (
    {
      color = 'main',
      newColor,
      disabledColor,
      size = 'm',
      type = 'button',
      weight = 'bold',
      disableFocusStyle = false,
      isLoading = false,
      children,
      disabled = false,
      ...props
    },
    ref,
  ) => {
    const { color: loadingColor } = colorStyle(color, newColor)

    return (
      <GrommetButton
        ref={ref}
        type={type}
        css={[
          baseCss({ newColor, weight, size }),
          hoverCss(newColor),
          disabledCss(disabledColor),
          focusCss(disableFocusStyle, newColor),
          sizeCss(size),
          fontSizeCss(size),
          colorStyle(color, newColor),
        ]}
        disabled={disabled || isLoading}
        {...props}
      >
        <div css={[textCss(!isLoading)]}>{children}</div>
        {isLoading && (
          <Spinner color={loadingColor} size={loadingSize(size)} css={[loadingCss()]} />
        )}
      </GrommetButton>
    )
  },
)

Button.displayName = 'Button'

const fontSizeCss = (size?: Size) => ({
  fontSize: size === 'ss' ? 12 : 14,
})

const loadingSize = (size?: Size) => (size === 'ss' ? 'xsmall' : 'small')

const baseCss = ({
  newColor,
  weight,
}: {
  newColor?: ButtonColorAlias
  weight?: 'normal' | 'bold'
  size?: Size
}) =>
  css({
    position: 'relative',
    textAlign: 'center',
    verticalAlign: 'bottom',
    whiteSpace: 'nowrap',
    fontWeight: weight,
    outline: 'none',
    transition: 'filter 0.1s ease-in',
    boxSizing: 'border-box',
    borderRadius: 4,
    border: newColor === 'white-100' ? border('simple-30') : '',
  })

const hoverCss = (newColor?: ButtonColorAlias) => ({
  '&:hover:enabled': {
    filter: newColor === 'white-100' ? '' : 'brightness(1.2)',
    backgroundColor: newColor === 'white-100' ? 'rgba(0, 0, 0, 0.1)' : '',
  },
})

const disabledStyle = (disabledColor?: DisabledButtonColorAlias) => {
  if (disabledColor) {
    const bColor = disabledButtonColor(disabledColor)
    return {
      color: bColor?.textColor,
      backgroundColor: bColor?.backgroundColor,
      border: bColor?.border,
      opacity: 1,
    }
  }
  return {
    color: colors.inactiveText,
    backgroundColor: colors.inactiveFill,
  }
}

const disabledCss = (disabledColor?: DisabledButtonColorAlias) => ({
  '&:disabled': disabledStyle(disabledColor),
})

const colorStyle = (color?: 'main' | 'sub' | 'warning', newColor?: ButtonColorAlias) => {
  if (newColor) {
    const bColor = buttonColor(newColor)
    return {
      color: bColor?.textColor,
      backgroundColor: bColor?.backgroundColor,
    }
  }
  switch (color) {
    case 'main':
      return {
        color: colors.buttonTextMain,
        backgroundColor: colors.buttonFillMain,
      }
    case 'sub':
      return {
        color: colors.buttonTextSub,
        backgroundColor: colors.buttonFillSub,
      }
    case 'warning':
      return {
        color: colors.buttonTextWarning,
        backgroundColor: colors.buttonFillWarning,
      }
    default:
      return {
        color: colors.buttonTextMain,
        backgroundColor: colors.buttonFillMain,
      }
  }
}

const sizeCss = (size?: Size) => {
  switch (size) {
    case 'm':
      return {
        height: 48,
        lineHeight: '48px',
        paddingLeft: 32,
        paddingRight: 32,
      }
    case 's':
      return {
        height: 40,
        lineHeight: '40px',
        paddingLeft: 16,
        paddingRight: 16,
      }
    case 'ss':
      return {
        lineHeight: '32px',
        paddingLeft: 16,
        paddingRight: 16,
      }
    default:
      return null
  }
}

const focusCss = (disableFocusStyle?: boolean, newColor?: ButtonColorAlias) => {
  if (disableFocusStyle) return {}
  return {
    '&:focus:enabled': {
      backgroundColor: newColor === 'white-100' ? 'rgba(0, 0, 0, 0.1)' : '',
      filter: newColor === 'white-100' ? '' : 'brightness(1.2)',
    },
  }
}

const loadingCss = () =>
  css({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    margin: 'auto',
  })

const textCss = (isShow: boolean) =>
  css({
    visibility: isShow ? 'initial' : 'hidden',
  })
