import { useState, useEffect } from 'react'

import { PageContent } from '../../components/pageContent'
import { Button } from '../../components/ui/ButtonDeprecated'
import { useTranslation } from '../../i18n'
import { skipNotifyCallbackContextKey } from '../../lib/apollo'
import { Screen } from '../../lib/screen'
import { useTracking } from '../../lib/tracking'
import * as colors from '../../styles/colors'
import { AuthRouteProps } from '../../types/authRouteProps'

import { NotificationListItem } from './NotificationListItem'
import { useReadNotificationsMutation, useNotificationsLazyQuery } from './graphql'

const COUNT_PER_PAGE = 20

export const NotificationListContainer: React.FC<AuthRouteProps> = ({ onOkrTermLoaded }) => {
  const { t } = useTranslation()
  const [unreadOnly, setUnreadOnly] = useState(true)
  const [page, setPage] = useState(0)
  const [readNotificationsMutation] = useReadNotificationsMutation()
  const [queryExecution, { called, data }] = useNotificationsLazyQuery()
  useTracking(t('NOTIFICATION_LIST_PAGE_TITLE'), Screen.Notification)
  useEffect(() => {
    onOkrTermLoaded(null)
  }, [onOkrTermLoaded])

  useEffect(() => {
    if (!called) {
      queryExecution({ variables: { unreadOnly, first: 20, after: null } })
    }
  }, [called, queryExecution, unreadOnly])

  useEffect(() => {
    if (called && data) {
      readNotificationsMutation({
        context: { [skipNotifyCallbackContextKey]: true },
      })
    }
  }, [called, data, readNotificationsMutation])

  return (
    <PageContent layout={{ css: { padding: '20px' } }} breadcrumbs={undefined}>
      <nav
        css={{
          marginBottom: 10,

          '& > button': {
            width: '100px',
            paddingTop: 15,
            paddingBottom: 15,
            cursor: 'pointer',
            backgroundColor: '#eff3f6',
            borderTop: '1px solid #ccc',
            borderBottom: '1px solid #ccc',
            outline: 'none',
          },
        }}
      >
        <button
          css={{
            borderLeft: '1px solid #ccc',
            borderRight: '1px solid #ccc',
            borderTopLeftRadius: '4px',
            borderBottomLeftRadius: '4px',
            boxShadow: unreadOnly ? 'inset 0 0.15em 0.3em rgba(27,31,35,.15)' : '',
          }}
          type="button"
          onClick={() => {
            setUnreadOnly(true)
            queryExecution({ variables: { unreadOnly: true, first: COUNT_PER_PAGE } })
            setPage(0)
          }}
        >
          {t('UNREAD')}
        </button>
        <button
          css={{
            borderRight: '1px solid #ccc',
            borderTopRightRadius: '4px',
            borderBottomRightRadius: '4px',
            boxShadow: !unreadOnly ? 'inset 0 0.15em 0.3em rgba(27,31,35,.15)' : '',
          }}
          type="button"
          onClick={() => {
            setUnreadOnly(false)
            queryExecution({ variables: { unreadOnly: false, first: COUNT_PER_PAGE } })
            setPage(0)
          }}
        >
          {t('ALL')}
        </button>
      </nav>
      <ul
        css={{
          border: `1px solid ${colors.border}`,
          borderBottom: 0,
          listStyleType: 'none',
        }}
      >
        {data &&
          data.notifications.edges.map((e) => (
            <NotificationListItem key={e.node.id} node={e.node} />
          ))}
      </ul>
      <nav
        css={{
          marginTop: '10px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',

          '& > button': {
            '&:last-child': {
              marginLeft: '5px',
            },
          },
        }}
      >
        {data && (
          <div css={{ fontSize: '14px', marginRight: '10px' }}>
            <span>{page * COUNT_PER_PAGE + 1}</span>
            <span css={{ margin: '2px' }}>~</span>
            <span>{page * COUNT_PER_PAGE + data.notifications.edges.length}</span>
            <span css={{ margin: '5px' }}>/</span>
            <span>{data.notifications.totalCount}</span>
          </div>
        )}
        <Button
          type="button"
          color="main"
          disabled={!data || !data.notifications.pageInfo.hasPreviousPage}
          onClick={() => {
            queryExecution({
              variables: {
                unreadOnly,
                last: COUNT_PER_PAGE,
                before: data?.notifications.pageInfo.startCursor,
              },
            })
            setPage(page - 1)
          }}
        >
          {t('PREV')}
        </Button>
        <Button
          type="button"
          color="main"
          disabled={!data || !data.notifications.pageInfo.hasNextPage}
          onClick={() => {
            queryExecution({
              variables: {
                unreadOnly,
                first: COUNT_PER_PAGE,
                after: data?.notifications.pageInfo.endCursor,
              },
            })
            setPage(page + 1)
          }}
        >
          {t('NEXT')}
        </Button>
      </nav>
    </PageContent>
  )
}

NotificationListContainer.displayName = 'NotificationListContainer'
