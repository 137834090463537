import { useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { ArrayParam, StringParam, useQueryParams } from 'use-query-params'

import { User } from '../../../../contexts/UserContext'
import {
  OKR_LIST_TAB_QUERY,
  OkrMapType,
  OKR_LIST_TAB,
  treesInCurrentTerm,
  generateTrees,
} from '../../../../urls'

import { BookmarkFragment } from './graphql'

const includeOkrMapTypes = (type: string): type is OkrMapType =>
  OKR_LIST_TAB.some((t) => t === type)

export const useHistoryForMap = ({
  user,
  isLoadedBookmark,
  bookmarks,
  termId,
}: {
  user: User | null
  isLoadedBookmark: boolean
  bookmarks: ReadonlyArray<BookmarkFragment>
  termId: string | null
}): {
  pushMapType: (type: OkrMapType) => void
  pushMapBookmark: (type: string) => void
  queryParamsOkrList?: string | null
  mapLink: (type: OkrMapType) => string
  bookmarkLink: (type: string) => string
} => {
  const [queryParams] = useQueryParams({
    group: ArrayParam,
    user: ArrayParam,
    [OKR_LIST_TAB_QUERY]: StringParam,
  })

  const queryParamsOkrList = queryParams[OKR_LIST_TAB_QUERY]

  const navigate = useNavigate()

  const bookmarkLink = useCallback(
    (bookmarkId: string) => {
      const found = bookmarks.find(({ id }) => id === bookmarkId)
      // 存在しないbookmarkIDを指定した場合は全てのOKRに変更する
      if (termId == null || found == null) {
        return treesInCurrentTerm
      }
      return generateTrees({
        termId,
        userIds: found.filterUserIds,
        groupIds: found.filterGroupIds,
        [OKR_LIST_TAB_QUERY]: bookmarkId,
      })
    },
    [bookmarks, termId],
  )

  const pushMapBookmark = useCallback(
    (bookmarkId: string) => navigate(bookmarkLink(bookmarkId)),
    [navigate, bookmarkLink],
  )

  const mapLink = useCallback(
    (type: OkrMapType) => {
      if (!termId) {
        return treesInCurrentTerm
      }

      const groupIds = user?.groups.map((g) => g.id) ?? []
      const userIds = user?.id ? [user.id] : []

      switch (type) {
        case 'all':
          return generateTrees({ termId, userIds: [], groupIds: [], [OKR_LIST_TAB_QUERY]: 'all' })
        case 'self':
          return generateTrees({ termId, userIds, groupIds: [], [OKR_LIST_TAB_QUERY]: 'self' })
        case 'group':
          return generateTrees({ termId, userIds: [], groupIds, [OKR_LIST_TAB_QUERY]: 'group' })
        default:
          return treesInCurrentTerm
      }
    },
    [termId, user],
  )

  const pushMapType = useCallback(
    (type: OkrMapType) => navigate(mapLink(type)),
    [navigate, mapLink],
  )

  useEffect(() => {
    const okrListQuery = queryParams[OKR_LIST_TAB_QUERY]
    if (!okrListQuery || !isLoadedBookmark) return

    if (okrListQuery === 'self' && !queryParams.user && termId && user?.id) {
      navigate(
        generateTrees({ termId, userIds: [user.id], groupIds: [], [OKR_LIST_TAB_QUERY]: 'self' }),
        { replace: true },
      )
    }

    const existBookmark = bookmarks.some((b) => b.id === okrListQuery)
    if (existBookmark || includeOkrMapTypes(okrListQuery)) return

    pushMapType('all')
  }, [bookmarks, pushMapType, isLoadedBookmark, queryParams, navigate, termId, user])

  return {
    pushMapType,
    pushMapBookmark,
    queryParamsOkrList,
    mapLink,
    bookmarkLink,
  }
}
