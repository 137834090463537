import { Box, Button, Text, TextInput } from 'grommet'
import { Add, Trash } from 'grommet-icons'
import { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { v1 as id } from 'uuid'

import { useTranslation } from '../../../../i18n'
import { Screen } from '../../../../lib/screen'
import { useTracking } from '../../../../lib/tracking'
import * as colors from '../../../../styles/colors'

const DOMAIN_NAME_PATTERN =
  '^(?!\\-)(?:(?:[a-zA-Z\\d][a-zA-Z\\d\\-]{0,61})?[a-zA-Z\\d]\\.){1,126}(?!\\d+)[a-zA-Z\\d]{1,63}$'

type Item = {
  key: string
  value: string
}

export type Props = {
  defaultList: ReadonlyArray<string>
  onClickSave: (list: ReadonlyArray<string>) => void
}

export const DomainTableList: React.FC<Props> = ({ defaultList, onClickSave }) => {
  const { t } = useTranslation()
  useTracking(t('ADMIN_DOMAIN_WHITELIST_PAGE_TITLE'), Screen.DomainWhiteListSetting)
  const [data, setData] = useState<ReadonlyArray<Item>>(
    defaultList.map((value) => ({ key: id(), value })),
  )
  const {
    register,
    control,
    formState: { errors },
    getValues,
    handleSubmit,
  } = useForm()

  const append = () => setData([...data, { key: id(), value: '' }])
  const remove = (index: number) => setData([...data.slice(0, index), ...data.slice(index + 1)])

  const onSubmit = () => {
    const uniqueList = Array.from(
      new Map(Object.entries<string>(getValues()).map(([key, value]) => [value, key])),
    ).map<Item>(([value, key]) => ({ key, value }))

    const validatedUniqueList = uniqueList
      .filter(({ value }) => value !== '')
      .filter(({ value }) => value.match(DOMAIN_NAME_PATTERN) !== null)
      .sort()

    setData(validatedUniqueList)
    onClickSave(validatedUniqueList.map(({ value }) => value))
  }

  const onReset = () => setData(defaultList.map((value) => ({ key: id(), value })))

  return (
    <Box
      margin={{ vertical: '48px' }}
      pad={{ bottom: '48px' }}
      border={{ color: colors.border, side: 'bottom', size: '1px' }}
      css={{
        '&:last-of-type': {
          borderBottom: 'none',
        },
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          direction="row"
          border={{
            color: colors.buttonsBorder,
            side: 'bottom',
          }}
          pad="8px"
          margin={{ bottom: '24px' }}
        >
          <Text weight="bold" css={{ display: 'inline-block' }}>
            {t('ALLOW_DOMAIN')}
          </Text>
          <Box direction="row" justify="end" flex="grow">
            <Button hoverIndicator icon={<Add />} onClick={append} />
            <Button
              type="submit"
              color={colors.buttonsBorder}
              label={t('SAVE')}
              margin={{ horizontal: '5px' }}
            />
            <Button
              color={colors.buttonsBorder}
              label={t('RESET')}
              margin={{ horizontal: '5px' }}
              onClick={onReset}
            />
          </Box>
        </Box>

        {data.map((item, index) => (
          <Box key={item.key} direction="column" pad="8px">
            <Box direction="row">
              <Controller
                render={() => (
                  <TextInput {...register(`${item.key}`)} pattern={DOMAIN_NAME_PATTERN} />
                )}
                name={item.key}
                control={control}
                defaultValue={item.value}
                rules={{
                  required: {
                    value: true,
                    message: t('REQUIRED_ERROR'),
                  },
                  pattern: {
                    value: new RegExp(DOMAIN_NAME_PATTERN),
                    message: t('INVALID_DOMAIN_NAME_FORMAT'),
                  },
                }}
              />
              <Button hoverIndicator icon={<Trash />} onClick={() => remove(index)} />
            </Box>
            <Text color={colors.error}>{errors[item.key]?.message}</Text>
          </Box>
        ))}
      </form>
    </Box>
  )
}

DomainTableList.displayName = 'DomainTableList'
