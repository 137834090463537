import { css } from '@emotion/react'
import { useMemo } from 'react'

import { color } from '../../../../../styles/newColors'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useStyles = () =>
  useMemo(
    () =>
      ({
        root: css({
          display: 'flex',
          alignItems: 'center',
          padding: '12px 8px',
          backgroundColor: color('white-100'),
          boxShadow: `0px -1px 0px ${color('border-bk-10')}, 0px 1px 0px ${color('border-bk-10')}`,
          ':hover': {
            backgroundColor: color('hover-background-bk-5'),
          },
        }),
        draggableIcon: css({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }),
        avatar: css({
          margin: '0 8px 0 4px',
        }),
        title: css({
          minWidth: '0',
        }),
      } as const),
    [],
  )
