import { useCallback } from 'react'
import { useLocation } from 'react-router-dom'

import { toggle } from '../../../lib/array'
import { toggleSelectedAndNormalize } from '../../../lib/domain/group'
import { findScreen, Screen } from '../../../lib/screen'

import { GroupFragment } from './graphql'

export const useFilterHandleFactory = ({
  selectedGroupIds,
  selectedUserIds,
  groups,
  onFilterChange,
  onReflectGroupTracker,
  onReflectUserTracker,
}: {
  selectedGroupIds: ReadonlyArray<string>
  selectedUserIds: ReadonlyArray<string>
  groups: ReadonlyArray<GroupFragment>
  onFilterChange: (filter: {
    groupIds?: ReadonlyArray<string>
    userIds?: ReadonlyArray<string>
  }) => void
  onReflectGroupTracker: (screen: Screen) => void
  onReflectUserTracker: (screen: Screen) => void
}): {
  clearGroups: () => void
  clearUsers: () => void
  addGroupId: (id: string) => void
  addUserId: (id: string) => void
} => {
  const location = useLocation()
  const clearGroups = useCallback(() => onFilterChange({ groupIds: [] }), [onFilterChange])
  const clearUsers = useCallback(() => onFilterChange({ userIds: [] }), [onFilterChange])
  const addGroupId = useCallback(
    (id: string) => {
      onReflectGroupTracker(findScreen(location.pathname))
      const newIds = toggleSelectedAndNormalize(groups, selectedGroupIds, id)
      onFilterChange({ groupIds: newIds })
    },
    [groups, location.pathname, onFilterChange, onReflectGroupTracker, selectedGroupIds],
  )
  const addUserId = useCallback(
    (id: string) => {
      onReflectUserTracker(findScreen(location.pathname))
      onFilterChange({ userIds: toggle(selectedUserIds, id) })
    },
    [location.pathname, onFilterChange, onReflectUserTracker, selectedUserIds],
  )
  return { clearGroups, clearUsers, addGroupId, addUserId }
}
