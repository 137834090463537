import { Dispatch, useCallback, useReducer } from 'react'
// import { useLocation } from 'react-router-dom'
import { createContext, useContext, useContextSelector } from 'use-context-selector'

import { findScreen } from '../lib/screen'
import { tracker } from '../lib/tracking'

// import { useTranslation } from '../../../../i18n'
// import { usePrompt } from '../../../../lib/prompt'

// 編集開始前の初期状態に戻す関数
type ResetFunc = () => void
// 離脱モーダルが表示されて編集継続が確定したら呼び出される、現在の編集を続行する関数
type OnClickCancelFunc = () => void
// 離脱モーダルが表示されて破棄が確定したら呼び出される、現在編集中の内容を破棄する関数
type OnClickDiscardFunc = () => void
// 同時編集がない事が確認された後に実行され、離脱モーダルが表示された場合は破棄ボタンが押された時に呼ばれる関数を返す関数
type StartEditCallback = () => ResetFunc
// 何を編集中なのかを表す
type EditingTarget =
  | 'OKR_CREATE_MODAL'
  | 'OBJECTIVE_UPDATE_MODAL'
  | 'OBJECTIVE_DESCRIPTION_IN_OKR_MODAL'
  | 'KEY_RESULT_CREATE_MODAL'
  | 'KEY_RESULT_UPDATE_MODAL'
  | 'KEY_RESULT_DESCRIPTION_IN_OKR_MODAL'
  | 'QUICK_UPDATE_MODAL'
  | 'WEIGHT_SETTING_MODAL'
  | 'CHILD_OKR_RE_ORDERING_MODAL'

/**
 * @description 状態遷移図
 *                       (initial)
 *                           ↓
 *                    ---------------
 *                    | NON_EDITING |<--------------+
 *                    ---------------               |
 *                           ↓ start                |
 *                -----------------------   abort   |
 *          +---->|                     |   finish  |
 *          | +---|      EDIT_START     |-----------+
 *          | |   -----------------------           |
 *          | |     replace ↑ |                     |
 *          | |     to_init | ↓ to_dirty            |
 *          | |    ----------------------   finish  |
 *          | |    |      EDITING       |-----------+
 *          | |    ----------------------           |
 *  replace | ↓ choice ↓ ↑ continue ↑ ↓ confirm     |
 *          --------------      -------------       |
 *          |  CONFLICT  |      | INTERRUPT |-------+ abort
 *          --------------      -------------
 */
type State =
  // 未編集状態
  | { state: 'NON_EDITING' }
  // 編集開始直後の、離脱確認が必要ない状態
  | { state: 'EDIT_START'; reset: ResetFunc; target: EditingTarget }
  // 編集開始後に実際の編集操作が行われ、離脱確認が必要な状態
  | { state: 'EDITING'; reset: ResetFunc; target: EditingTarget }
  | {
      // 離脱確認中
      state: 'INTERRUPT'
      resetForOldEdit: ResetFunc
      continue: OnClickCancelFunc
      abort: OnClickDiscardFunc
      target: EditingTarget
    }
  | {
      // 既に編集中にも関わらず、新しく別の編集を開始しようとしている状態
      state: 'CONFLICT'
      resetForOldEdit: ResetFunc
      pickOldEdit: OnClickCancelFunc
      pickNewEdit: OnClickDiscardFunc
      prevTarget: EditingTarget
    }

type Action =
  // 編集開始
  | { type: 'start'; reset: ResetFunc; target: EditingTarget }
  // 編集が終了し、新しい値がセットされる場合
  | { type: 'finish' }
  // 編集開始後に編集操作が行われた
  | { type: 'to_dirty' }
  // 編集開始後に編集操作が行われたが初期値に戻った
  | { type: 'to_init' }
  // 要離脱時に離脱しようとしたため
  | { type: 'confirm'; continue: OnClickCancelFunc; abort: OnClickDiscardFunc }
  // 離脱確認/新旧編集選択で現在の編集を維持
  | { type: 'continue' }
  // 編集を離脱して初期値に戻す場合
  | { type: 'abort' }
  // 新旧編集選択の要求
  | {
      type: 'choice'
      pickOldEdit: OnClickCancelFunc
      pickNewEdit: OnClickDiscardFunc
    }
  // 新旧編集選択で新規編集を開始
  | { type: 'replace'; resetForNewEdit: ResetFunc; nextTarget: EditingTarget }

class InvalidActionError extends Error {
  constructor(message: string) {
    super(`${message}::${ToStringHistory()}`)
  }
}

const reducer = (prevState: State, action: Action): State => {
  switch (action.type) {
    case 'finish':
      // 利便性のため NON_EDITING -> NON_EDITING を許容している
      if (!['NON_EDITING', 'EDIT_START', 'EDITING'].includes(prevState.state))
        throw new InvalidActionError(`${action.type}:${prevState.state} -> NON_EDITING`)
      if (prevState.state === 'NON_EDITING') return prevState
      return { state: 'NON_EDITING' }
    case 'abort':
      if (prevState.state !== 'EDIT_START' && prevState.state !== 'INTERRUPT')
        throw new InvalidActionError(`${action.type}:${prevState.state} -> NON_EDITING`)
      if (prevState.state === 'INTERRUPT') {
        prevState.resetForOldEdit()
      } else {
        prevState.reset()
      }
      return { state: 'NON_EDITING' }
    case 'start':
      if (prevState.state !== 'NON_EDITING')
        throw new InvalidActionError(`${action.type}:${prevState.state} -> EDIT_START`)
      return { state: 'EDIT_START', reset: action.reset, target: action.target }
    case 'to_init':
      // 利便性のため EDIT_START -> EDIT_START を許容している
      if (prevState.state !== 'EDIT_START' && prevState.state !== 'EDITING')
        throw new InvalidActionError(`${action.type}:${prevState.state} -> EDIT_START`)
      if (prevState.state === 'EDIT_START') return prevState
      return { state: 'EDIT_START', reset: prevState.reset, target: prevState.target }
    case 'to_dirty':
      // 利便性のため EDITING -> EDITING を許容している
      if (prevState.state !== 'EDIT_START' && prevState.state !== 'EDITING')
        throw new InvalidActionError(`${action.type}:${prevState.state} -> EDITING`)
      if (prevState.state === 'EDITING') return prevState
      return { state: 'EDITING', reset: prevState.reset, target: prevState.target }
    case 'replace':
      if (prevState.state !== 'EDIT_START' && prevState.state !== 'CONFLICT')
        throw new InvalidActionError(`${action.type}:${prevState.state} -> EDIT_START`)
      if (prevState.state === 'CONFLICT') {
        prevState.resetForOldEdit()
      } else {
        prevState.reset()
      }
      return { state: 'EDIT_START', reset: action.resetForNewEdit, target: action.nextTarget }
    case 'continue':
      if (prevState.state !== 'INTERRUPT' && prevState.state !== 'CONFLICT')
        throw new InvalidActionError(`${action.type}:${prevState.state} -> EDITING`)
      return {
        state: 'EDITING',
        reset: prevState.resetForOldEdit,
        target: prevState.state === 'INTERRUPT' ? prevState.target : prevState.prevTarget,
      }
    case 'confirm':
      if (prevState.state !== 'EDITING')
        throw new InvalidActionError(`${action.type}:${prevState.state} -> INTERRUPT`)
      return {
        state: 'INTERRUPT',
        resetForOldEdit: prevState.reset,
        continue: action.continue,
        abort: action.abort,
        target: prevState.target,
      }
    case 'choice':
      if (prevState.state !== 'EDITING')
        throw new InvalidActionError(`${action.type}:${prevState.state} -> CONFLICT`)
      return {
        state: 'CONFLICT',
        resetForOldEdit: prevState.reset,
        pickOldEdit: action.pickOldEdit,
        pickNewEdit: action.pickNewEdit,
        prevTarget: prevState.target,
      }
    default: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/naming-convention
      const _: never = action
      throw new InvalidActionError('undefined action type!!!')
    }
  }
}

const EditingStateContext = createContext<State>({ state: 'NON_EDITING' })
const EditingStateDispatchContext = createContext<Dispatch<Action>>(() => {
  throw new Error('Should use EditingStateContextProvider')
})

// エラートラッキング用の、レンダリングライフサイクル外の履歴テーブル
const StateHistory: Array<string> = []
const ToStringHistory = () => `StateHistory:[${['', ...StateHistory].join('\n  ')}\n]`

export const EditingStateContextProvider: React.FC = ({ children }) => {
  const wrapReducer = useCallback<typeof reducer>((...args) => {
    const nextState = reducer(...args)
    // Omit function field
    StateHistory.push(JSON.stringify(nextState))
    while (StateHistory.length > 15) StateHistory.shift()
    return nextState
  }, [])
  const [state, dispatch] = useReducer(wrapReducer, { state: 'NON_EDITING' })

  return (
    <EditingStateDispatchContext.Provider value={dispatch}>
      <EditingStateContext.Provider value={state}>{children}</EditingStateContext.Provider>
    </EditingStateDispatchContext.Provider>
  )
}
EditingStateContextProvider.displayName = 'EditingStateContextProvider'

// 離脱アラートモーダル専用
// TODO: 離脱モーダル返すhooksと合体させる
export const useGetDiscardAlertModalParam = ():
  | {
      isRequired: true
      onClickCancel: OnClickCancelFunc
      onClickDiscard: OnClickDiscardFunc
    }
  | {
      isRequired: false
      onClickCancel?: never
      onClickDiscard?: never
    } => {
  const state = useContext(EditingStateContext)
  const onClickDiscardWhenInterrupt = useCallback(() => {
    if (state.state !== 'INTERRUPT') return
    state.abort()
    trackFunc(state.target)
  }, [state])
  const onClickDiscardWhenConflict = useCallback(() => {
    if (state.state !== 'CONFLICT') return
    state.pickNewEdit()
    trackFunc(state.prevTarget)
  }, [state])
  switch (state.state) {
    case 'INTERRUPT':
      return {
        isRequired: true,
        onClickCancel: state.continue,
        onClickDiscard: onClickDiscardWhenInterrupt,
      }
    case 'CONFLICT':
      return {
        isRequired: true,
        onClickCancel: state.pickOldEdit,
        onClickDiscard: onClickDiscardWhenConflict,
      }
    default:
      return { isRequired: false }
  }
}

type CheckEditingFunc = (onClose: () => void) => void

// 編集コンポーネントを直接持たない祖先を閉じる際に呼ぶべき関数を返すhooks
export const useCheckEditingOnClose = (): CheckEditingFunc => {
  const state = useContextSelector(EditingStateContext, (stateObj) => stateObj.state)
  const dispatch = useContext(EditingStateDispatchContext)

  // 編集中なコンポーネントがあるかをチェックし、あるならcontextを離脱モーダルを表示する必要がある事を示すstateに変化させる
  return useCallback<CheckEditingFunc>(
    (onClose) => {
      switch (state) {
        case 'NON_EDITING':
          onClose()
          dispatch({ type: 'finish' })
          return
        case 'EDIT_START':
          onClose()
          dispatch({ type: 'abort' })
          return
        case 'EDITING':
          dispatch({
            type: 'confirm',
            continue: () => dispatch({ type: 'continue' }),
            abort: () => {
              onClose()
              dispatch({ type: 'abort' })
            },
          })
          return
        default:
          // ここには来ないで欲しい
          throw new Error('invalid case!!!!!!!!!!!!')
      }
    },
    [dispatch, state],
  )
}

// 編集コンポーネントの編集開始/終了をラップすることで、
// 同時に複数のコンポーネントが編集状態にならないようにする関数を返すhooks
export const useEditingStateMachine = (): {
  // 編集開始時に毎回呼ぶ
  // 編集開始が確定するとcallbackが呼ばれる
  requestStartEdit: (onStartEdit: StartEditCallback, target: EditingTarget) => void
  // 編集開始して、入力内容が初期値から変化した/初期値に戻った際に呼ぶ関数
  // updateIsDirtyを受け取らない=編集中が存在しない=即時反映系
  updateIsDirty: (isDirty: boolean) => void
  // 正常終了時以外に呼ぶ
  discardEdit: () => void
  // 正常終了時に呼ぶ
  finishEdit: () => void
} => {
  // TODO: URLの変更時やブラウザの進む/戻る/閉じるへの対応
  // const { t } = useTranslation()
  // const location = useLocation()
  // const originPath = window.location.pathname + window.location.search + window.location.hash
  // const [, setNeedBlock] = usePrompt({ message: t('LEAVE_WARNING'), originPath })

  const state = useContext(EditingStateContext)
  const dispatch = useContext(EditingStateDispatchContext)

  // 編集コンポーネントを未編集状態から編集状態に移行するのをラップして、
  // 同時に複数の編集状態を保持しないように制御する関数
  const requestStartEdit = useCallback(
    (onStartEdit: StartEditCallback, target: EditingTarget) => {
      // 他のコンポーネントを編集中に新しく別の編集コンポーネントを開こうとしたら
      if (state.state === 'EDITING') {
        // どっちを編集するのか聞く
        dispatch({
          type: 'choice',
          pickOldEdit: () => dispatch({ type: 'continue' }),
          pickNewEdit: () => {
            // setNeedBlock(true)
            dispatch({ type: 'replace', resetForNewEdit: onStartEdit(), nextTarget: target })
          },
        })
        return
      }

      // setNeedBlock(true)
      if (state.state === 'NON_EDITING') {
        dispatch({ type: 'start', reset: onStartEdit(), target })
      } else if (state.state === 'EDIT_START') {
        // 別のコンポーネントを編集中だが初期値のままの場合は確認せずに新しい編集を開始する
        dispatch({ type: 'replace', resetForNewEdit: onStartEdit(), nextTarget: target })
      } else {
        throw new Error(`called requestStartEdit by state:${state.state}`)
      }
    },
    [dispatch, state.state],
  )

  const updateIsDirty = useCallback(
    (isDirty: boolean) => {
      if (state.state !== 'EDIT_START' && state.state !== 'EDITING') return

      if (isDirty) {
        if (state.state !== 'EDITING') {
          dispatch({ type: 'to_dirty' })
        }
      } else {
        dispatch({ type: 'to_init' })
      }
    },
    [dispatch, state.state],
  )

  const checkEditingStateOnClose = useCheckEditingOnClose()
  const discardEdit = useCallback(() => {
    if (state.state !== 'EDIT_START' && state.state !== 'EDITING') {
      throw new Error(`called discardEdit by state:${state.state}`)
    }

    checkEditingStateOnClose(() => {
      // setNeedBlock(false)
      state.reset()
    })
  }, [checkEditingStateOnClose, state])

  // チェックが不要な編集終了(キャンセルや保存ボタンの押下時等)
  const finishEdit = useCallback(() => {
    if (state.state !== 'EDIT_START' && state.state !== 'EDITING') {
      throw new Error(`called finishEdit by state:${state.state}`)
    }

    // setNeedBlock(false)
    dispatch({ type: 'finish' })
  }, [dispatch, state.state])

  return { requestStartEdit, updateIsDirty, discardEdit, finishEdit }
}

const trackFunc = (target: EditingTarget) => {
  switch (target) {
    case 'OBJECTIVE_UPDATE_MODAL':
      tracker.UserClickCancelEditObjectiveUpdateModal(findScreen(window.location.pathname))
      break
    case 'KEY_RESULT_UPDATE_MODAL':
      tracker.UserClickCancelEditKeyResultUpdateModal(findScreen(window.location.pathname))
      break
    case 'OBJECTIVE_DESCRIPTION_IN_OKR_MODAL':
      tracker.UserClickCancelEditObjectiveDescriptionInObjectiveDrawer(
        findScreen(window.location.pathname),
      )
      break
    case 'KEY_RESULT_DESCRIPTION_IN_OKR_MODAL':
      tracker.UserClickCancelEditKeyResultDescriptionInKeyResultDrawer(
        findScreen(window.location.pathname),
      )
      break
    default:
  }
}
