import React, { useMemo, useContext, useCallback } from 'react'
import { useParams } from 'react-router'

import { OkrTermIdContext } from '../../../../../contexts/OkrTermIdContext'
import { useCurrentUser } from '../../../../../contexts/UserContext'
import { useTranslation } from '../../../../../i18n'
import { Screen } from '../../../../../lib/screen'
import { useTracking } from '../../../../../lib/tracking'
import { color } from '../../../../../styles/newColors'
import { AuthRouteProps } from '../../../../../types/authRouteProps'

import { ActionPlan } from './actionPlan'
import {
  useFindUserByIdQuery,
  useUpdateActionPlanMutation,
  UpdateActionPlanMutationVariables,
} from './graphql'
import { useSort } from './hooks/useSort'

export const ActionPlanContainer: React.VFC<AuthRouteProps> = () => {
  const { t } = useTranslation()
  useTracking(t('ACTION_PLAN'), Screen.HomeActivity)
  const { userId: pageUserId } = useParams<{ userId: string }>()
  const { okrTermId: pageTermId } = useContext(OkrTermIdContext)
  const currentUser = useCurrentUser()
  const isOwnActionPlanTab = !!currentUser && currentUser.id === pageUserId

  const userResp = useFindUserByIdQuery({
    variables: { termId: pageTermId || '', userId: pageUserId || '' },
    skip: !pageTermId || !pageUserId,
  })

  const [updateActionPlan] = useUpdateActionPlanMutation()
  const updateActionPlanMutation = useCallback(
    async (variables: UpdateActionPlanMutationVariables) => {
      await updateActionPlan({
        variables,
      })
    },
    [updateActionPlan],
  )

  const pageUser = userResp.data?.findUserById
  const actionPlans = useMemo(() => {
    const userActionPlans = pageUser?.ownerActionPlans.concat(pageUser?.memberActionPlans)
    if (!userActionPlans) return []
    return userActionPlans
  }, [pageUser?.memberActionPlans, pageUser?.ownerActionPlans])
  const { sortedActionPlans, sort, onClickSortBy } = useSort(actionPlans)

  const handleUpdateActionPlanStatus = useCallback(
    ({ actionPlanId, status: actionPlanStatus }) => {
      const originActionPlan = sortedActionPlans.find((a) => a.id === actionPlanId)
      if (!originActionPlan) return
      updateActionPlanMutation({
        id: actionPlanId,
        keyResultId: originActionPlan.keyResult.id,
        memberIds: originActionPlan.members.map(({ id }) => id),
        updateType: originActionPlan.updateType,
        status: actionPlanStatus,
      })
    },
    [sortedActionPlans, updateActionPlanMutation],
  )

  return (
    <div
      css={{
        backgroundColor: color('white-100'),
        width: '100%',
        borderRadius: '8px',
      }}
    >
      <ActionPlan
        loading={pageUser == null && userResp.loading}
        actionPlans={sortedActionPlans}
        isOwnActionPlanTab={isOwnActionPlanTab}
        sort={sort}
        onClickSortBy={onClickSortBy}
        onUpdateActionPlanStatus={handleUpdateActionPlanStatus}
      />
    </div>
  )
}
