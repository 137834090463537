import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useWindowSize } from 'react-use'
import { FixedSizeList as List } from 'react-window'

import { useTranslation } from '../../../../i18n'
import { color } from '../../../../styles/newColors'
import * as urls from '../../../../urls'
import { UserFragment } from '../../graphql'
import { Sort } from '../types'

import { UsersTableHead } from './UsersTableHead'
import { UsersTableRow } from './UsersTableRow'
import { UsersTableTitleSection } from './UsersTableTitleSection'

type Props = {
  users: ReadonlyArray<UserFragment>
  expectTableHeight: number
  onClickSortBy: (sortBy: Sort['sortBy']) => void
  sort: Sort
}

export const UsersTableContainer: React.VFC<Props> = ({
  users,
  expectTableHeight,
  onClickSortBy,
  sort,
}) => {
  const { t } = useTranslation()

  const tableWrapperRef = useRef<HTMLElement>(null)
  const [tableWrapperHeight, setTableWrapperHeight] = useState(0)
  const [tableHeight, setTableHeight] = useState(0)

  const tableTitleRef = useRef<HTMLDivElement>(null)
  const tableTitleHeight = tableTitleRef.current
    ? tableTitleRef.current.getBoundingClientRect().height
    : 0

  const tableHeadRef = useRef<HTMLTableSectionElement>(null)
  const tableHeadHeight = tableHeadRef.current
    ? tableHeadRef.current.getBoundingClientRect().height
    : 0
  const tableRowHeight = 64
  const tableRowsHeight = tableRowHeight * users.length
  const { width, height } = useWindowSize()

  useEffect(() => {
    if (tableWrapperRef.current) {
      setTableWrapperHeight(tableWrapperRef.current.getBoundingClientRect().height)
      const calcTableHeight = tableWrapperHeight - tableHeadHeight - tableTitleHeight
      setTableHeight(calcTableHeight < tableRowsHeight ? calcTableHeight : tableRowsHeight)
    }
  }, [
    width,
    height,
    users,
    tableRowsHeight,
    tableHeadHeight,
    tableWrapperHeight,
    tableWrapperRef,
    tableTitleHeight,
  ])

  const { termId } = useParams<{ termId: string }>()

  // スクロールバーによってヘッダーとボディの位置ズレを調整する
  const tableBodyRef = useRef<HTMLDivElement>(null)
  const tableBodyWidth = tableBodyRef.current ? tableBodyRef.current.scrollWidth : 0
  const tableRowRef = useRef<HTMLDivElement>(null)
  const tableRowWidth = tableRowRef.current ? tableRowRef.current.scrollWidth : 0
  const scrollbarWidth = tableBodyWidth - tableRowWidth

  return (
    <section
      ref={tableWrapperRef}
      css={{
        position: 'relative',
        height: `calc(100% - ${expectTableHeight}px)`,
        minWidth: '1072px',
        padding: '0 32px',
        borderRadius: '4px',
        backgroundColor: color('white-100'),
      }}
    >
      <UsersTableTitleSection ref={tableTitleRef} />
      <UsersTableHead
        ref={tableHeadRef}
        sort={sort}
        onClickSortBy={onClickSortBy}
        shiftPositionLeft={scrollbarWidth}
      />
      <div
        ref={tableBodyRef}
        css={{
          paddingTop: '26px',
        }}
      >
        {users.length > 0 ? (
          <List
            itemCount={users.length}
            itemData={users}
            itemSize={tableRowHeight}
            height={tableHeight}
            width="100%"
          >
            {({ index, data, style }) => (
              <UsersTableRow
                ref={tableRowRef}
                key={data[index].id}
                user={data[index]}
                style={style}
                href={urls.generateHome(termId ?? '')(data[index].id)}
              />
            )}
          </List>
        ) : (
          <div
            ref={tableRowRef}
            css={{
              padding: '16px 0 16px 8px',
            }}
          >
            {t('X_EMPTY', { x: t('DATA') })}
          </div>
        )}
      </div>
    </section>
  )
}

UsersTableContainer.displayName = 'UsersTableContainer'
