import { useState, useMemo } from 'react'

import { State as DefaultState } from '../../../components/domain/OkrCreateModalContent'
import { useCurrentUser } from '../../../contexts/UserContext'
import { Node } from '../../../lib/collections/node'
import { UserFragment, GroupFragment } from '../graphql'
import { OkrNode } from '../trees/types'

export const useHandlerForOkrCreation = ({
  users,
  groups,
  nodes,
  selectedUserIds,
  selectedGroupIds,
}: {
  users: ReadonlyArray<UserFragment>
  groups: ReadonlyArray<GroupFragment>
  nodes: ReadonlyArray<Node<OkrNode>>
  selectedUserIds: ReadonlyArray<string>
  selectedGroupIds: ReadonlyArray<string>
}): {
  setParentOkrNodeId: (id: string | null) => void
  setParentKeyResultIds: (ids: ReadonlyArray<string>) => void
  defaultValueForOkrCreation: DefaultState
} => {
  const user = useCurrentUser()
  const [parentOkrNodeId, setParentOkrNodeId] = useState<string | null>(null)
  const [parentKeyResultIds, setParentKeyResultIds] = useState<ReadonlyArray<string>>([])

  const defaultValueForOkrCreation = useMemo<DefaultState>(
    () => ({
      name: '',
      parentOkrNode: parentOkrNodeId
        ? nodes.find((node) => node.id === parentOkrNodeId)
        : undefined,
      owner:
        selectedUserIds.length >= 1
          ? users.find((e) => e.id === selectedUserIds[0]) ?? user ?? undefined
          : user ?? undefined,
      // Mapの複数選択UIで選択されているグループのみを初期値に設定する
      // ただし複数選択UIが空であれば自身のグループを初期値に設定する
      selectedGroups:
        selectedGroupIds.length > 0
          ? groups.filter((group) => selectedGroupIds.some((id) => group.id === id))
          : user?.groups.map<GroupFragment>((e) => e) || [],
      description: { plainText: '', body: '' },
      isDescriptionChanged: false,
      parentKeyResultIds,
      slackChannelIds: [],
      attachmentViews: [],
    }),
    [
      nodes,
      selectedUserIds,
      users,
      user,
      groups,
      parentOkrNodeId,
      parentKeyResultIds,
      selectedGroupIds,
    ],
  )
  return {
    setParentOkrNodeId,
    setParentKeyResultIds,
    defaultValueForOkrCreation,
  }
}
