import { BreadcrumbParams } from '../../components/domain/Breadcrumb'
import { KeyResultDetailEditPageCard } from '../../components/domain/KeyResultDetailEditPageCard'
import { KeyResultDetailOption } from '../../components/domain/KeyResultDetailOption'
import { PageContent } from '../../components/pageContent'
import { useTranslation } from '../../i18n'
import { Screen } from '../../lib/screen'
import { useTracking } from '../../lib/tracking'
import { color } from '../../styles/newColors'
import { AuthRouteProps } from '../../types/authRouteProps'

import { OkrDetailKeyResultFragment } from './graphql'
import { useInjection } from './hooks'

export const KeyResultDetailEditContainer: React.FC<AuthRouteProps> = (props) => {
  const data = useInjection(props)

  if (!data) {
    return null
  }

  return <KeyResultDetailEdit {...data} />
}

KeyResultDetailEditContainer.displayName = 'KeyResultDetailEditContainer'

export type Props = {
  breadcrumbs: BreadcrumbParams
  keyResult: OkrDetailKeyResultFragment
  onUpdateKeyResult: (keyResult: OkrDetailKeyResultFragment, redirect: boolean) => void
  onClickCancel: () => void
  setNeedBlock: (value: boolean) => void
}

export const KeyResultDetailEdit: React.FC<Props> = ({
  breadcrumbs,
  keyResult,
  onUpdateKeyResult,
  onClickCancel,
  setNeedBlock,
}) => {
  const { t } = useTranslation()
  useTracking(
    t('KEY_RESULT_EDIT_PAGE_TITLE', { keyResultName: keyResult.name }),
    Screen.KeyResultEdit,
  )

  return (
    <PageContent
      breadcrumbs={breadcrumbs}
      layout={{
        css: {
          backgroundColor: color('background-bk-5'),
          padding: '60px 76px',
        },
      }}
    >
      <div css={{ display: 'flex' }}>
        <div
          css={{
            flex: '4 1 0',
          }}
        >
          <KeyResultDetailEditPageCard
            keyResult={keyResult}
            updateKeyResult={onUpdateKeyResult}
            onClickCancel={onClickCancel}
            setNeedBlock={setNeedBlock}
          />
        </div>
        <div css={{ marginTop: '9px', marginRight: '77px', flex: '1 1 0' }}>
          <KeyResultDetailOption keyResult={keyResult} />
        </div>
      </div>
    </PageContent>
  )
}

KeyResultDetailEdit.displayName = 'KeyResultDetailEdit'
