import {
  Popover as GeishaPopover,
  PopoverListProps as GeishaPopoverListProps,
} from '@resily/geisha'

import { Icon } from '../../Icon'
import { Link } from '../../Link'
import { StyledText } from '../../StyledText'

import { useStyles } from './index.styles'

export type Props = {
  title?: GeishaPopoverListProps['children']
  text: GeishaPopoverListProps['children']
  link?: {
    href: string
    text: string
    onClick?: () => void
  }
}
export const DropdownContent: React.FC<Props> = ({ title, text, link }) => {
  const styles = useStyles()

  return (
    <GeishaPopover.List>
      {title != null && (
        <GeishaPopover.ListItem>
          <StyledText size="small" weight="bold" css={styles.title}>
            {title}
          </StyledText>
        </GeishaPopover.ListItem>
      )}
      <GeishaPopover.ListItem>
        <StyledText size="small">{text}</StyledText>
      </GeishaPopover.ListItem>
      {link != null && (
        <GeishaPopover.ListItem>
          <Link method="newTab" href={link.href} css={styles.link} onClick={link.onClick}>
            {link.text}
            <Icon type="external" css={styles.icon} />
          </Link>
        </GeishaPopover.ListItem>
      )}
    </GeishaPopover.List>
  )
}
