import { useQueryParams, StringParam } from 'use-query-params'

import { KeyResultTab, KeyResultTabParam, ObjectiveTab, ObjectiveTabParam } from '../types'

import type { OkrModalQueryParamsType } from './useOkrModalQueryParams'

type ReturnType =
  | {
      type: 'close'
      objectiveId?: never
      keyResultId?: never
      tab?: never
    }
  | {
      type: 'objective'
      objectiveId: string
      keyResultId?: never
      tab: ObjectiveTab
    }
  | {
      type: 'keyResult'
      objectiveId: string
      keyResultId: string
      tab: KeyResultTab
    }
export const useOkrModalStatus = (): ReturnType => {
  const [queryParams] = useQueryParams<OkrModalQueryParamsType>({
    'm-o-id': StringParam,
    'm-o-tab': ObjectiveTabParam,
    'm-kr-id': StringParam,
    'm-kr-tab': KeyResultTabParam,
  })

  if (queryParams['m-o-id'] == null) {
    return { type: 'close' }
  }

  if (queryParams['m-kr-id'] != null) {
    return {
      type: 'keyResult',
      objectiveId: queryParams['m-o-id'],
      keyResultId: queryParams['m-kr-id'],
      tab: queryParams['m-kr-tab'] ?? 'm-kr-description',
    }
  }

  return {
    type: 'objective',
    objectiveId: queryParams['m-o-id'],
    tab: queryParams['m-o-tab'] ?? 'm-o-description',
  }
}
