import { useCurrentUser } from '../../../contexts/UserContext'
import { OKRTree } from '../../../lib/domain/okr/tree'
import { OkrMapType } from '../../../urls'
import { OkrNode } from '../trees/types'

export const useEmptyState = ({
  selectedUserIds,
  selectedGroupIds,
  filteredOkrTree,
  queryParamsOkrList,
}: {
  selectedUserIds: ReadonlyArray<string>
  selectedGroupIds: ReadonlyArray<string>
  filteredOkrTree: OKRTree<OkrNode>
  queryParamsOkrList: OkrMapType
}): {
  isMyOkrEmpty: boolean
  isNoGroupBelonging: boolean
  isGroupOkrEmpty: boolean
  isAllOkrEmpty: boolean
  isFilteredOkrEmpty: boolean
  hasEmptyState: boolean
} => {
  const user = useCurrentUser()

  const isFilteredResultEmpty =
    (selectedUserIds.length > 0 || selectedGroupIds.length > 0) && filteredOkrTree.isEmpty()

  const isMyOkrEmpty = queryParamsOkrList === 'self' && isFilteredResultEmpty

  const isNoGroupBelonging =
    queryParamsOkrList === 'group' && selectedGroupIds.length === 0 && user?.groups.length === 0

  const includesSelfInSelectedGroups =
    user?.groups.some((group) => selectedGroupIds.includes(group.id)) ?? false

  const isGroupOkrEmpty =
    queryParamsOkrList === 'group' &&
    isFilteredResultEmpty &&
    includesSelfInSelectedGroups &&
    selectedUserIds.length === 0

  const isFilteredOkrEmpty =
    isFilteredResultEmpty && !isMyOkrEmpty && !isNoGroupBelonging && !isGroupOkrEmpty

  const isAllOkrEmpty =
    queryParamsOkrList === 'all' &&
    selectedUserIds.length === 0 &&
    selectedGroupIds.length === 0 &&
    filteredOkrTree.isEmpty()

  const hasEmptyState =
    isMyOkrEmpty || isNoGroupBelonging || isGroupOkrEmpty || isAllOkrEmpty || isFilteredOkrEmpty

  return {
    isMyOkrEmpty,
    isNoGroupBelonging,
    isGroupOkrEmpty,
    isAllOkrEmpty,
    isFilteredOkrEmpty,
    hasEmptyState,
  }
}
