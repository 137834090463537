import { Popover, BadgePopover } from '@resily/geisha'
import { memo, useCallback, useRef, useState } from 'react'
import { useIsomorphicLayoutEffect } from 'react-use'

import { OverflowDetector } from '../../../../../../lib/OverflowDetector'
import { AvatarWithName, Props as AvatarWithNameProps } from '../../../Common/AvatarWithName'

import { useStyles } from './Contributors.styles'

export type Props = Omit<JSX.IntrinsicElements['ul'], 'css'> & {
  contributors: ReadonlyArray<AvatarWithNameProps['user']>
  termId: string
}

export const Contributors = memo<Props>(({ contributors, termId, ...props }) => {
  const styles = useStyles()

  const contributorsRef = useRef<HTMLUListElement>(null)
  const [hiddenContributorIds, setHiddenContributorIds] = useState<ReadonlySet<string>>(new Set())

  const hideLastContributor = useCallback(
    (isHidden: boolean) => {
      if (!isHidden) return
      const visibleLastContributorId = contributors
        .filter((g) => !hiddenContributorIds.has(g.id))
        .pop()
      if (visibleLastContributorId) {
        // BadgePopoverが見えるようになるまでそれ以前のタグを後ろから順に非表示にする
        setHiddenContributorIds((prev) => new Set([...prev, visibleLastContributorId.id]))
      }
    },
    [contributors, hiddenContributorIds],
  )

  useIsomorphicLayoutEffect(() => {
    if (!contributorsRef.current) {
      return () => {}
    }

    const resizeObserver = new ResizeObserver(() => setHiddenContributorIds(new Set()))
    resizeObserver.observe(contributorsRef.current)
    return () => resizeObserver.disconnect()
  }, [])

  return (
    <ul ref={contributorsRef} css={styles.root} {...props}>
      {contributors
        .filter((c) => !hiddenContributorIds.has(c.id))
        .map((user) => (
          <OverflowDetector
            key={user.id}
            as="li"
            containerRef={contributorsRef}
            css={styles.detector}
            onChangeOverflow={(isHidden) => {
              setHiddenContributorIds((prev) => {
                if (isHidden) {
                  return new Set([...prev, user.id])
                }
                const next = new Set(prev)
                return next.delete(user.id) ? next : prev
              })
            }}
          >
            <AvatarWithName size="xsmall" nameFontSize="xsmall" user={user} termId={termId} />
          </OverflowDetector>
        ))}
      {hiddenContributorIds.size > 0 && (
        <OverflowDetector
          as="li"
          containerRef={contributorsRef}
          css={styles.detector}
          onChangeOverflow={hideLastContributor}
        >
          <BadgePopover
            value={hiddenContributorIds.size}
            dropdownContent={
              <Popover.List>
                {contributors
                  .filter((c) => hiddenContributorIds.has(c.id))
                  .map((c) => (
                    <Popover.ListItem key={c.id}>
                      <AvatarWithName
                        size="xxsmall"
                        nameFontSize="xsmall"
                        user={c}
                        termId={termId}
                      />
                    </Popover.ListItem>
                  ))}
              </Popover.List>
            }
            width="fit-content"
            maxHeight={260}
          />
        </OverflowDetector>
      )}
    </ul>
  )
})
Contributors.displayName = 'Contributors'
