import { useModal } from '@resily/geisha'
import { useCallback, useMemo } from 'react'

import { useEditingStateMachine } from '../../../../../contexts/EditingStateContext'
import {
  ChildOkrReOrderingModalContainer,
  ChildOkrReOrderingModalContainerProps,
} from '../ChildOkrReOrderingModalContainer'

export type ChildOkrReOrderingModalReturnType = {
  ChildOkrReOrderingModal: React.ReactNode
  openChildOkrReOrderingModal: () => void
}

export const useChildOkrReOrderingModal = (
  okrNodeId: ChildOkrReOrderingModalContainerProps['okrNodeId'],
): ChildOkrReOrderingModalReturnType => {
  const [Modal, isOpened, { open, close }] = useModal(false)

  const { requestStartEdit, finishEdit } = useEditingStateMachine()

  const openChildOkrReOrderingModal = useCallback<
    ChildOkrReOrderingModalReturnType['openChildOkrReOrderingModal']
  >(() => {
    requestStartEdit(() => {
      open()
      return close
    }, 'CHILD_OKR_RE_ORDERING_MODAL')
  }, [close, open, requestStartEdit])

  const closeChildOkrReOrderingModal = useCallback<
    ChildOkrReOrderingModalContainerProps['onClose']
  >(() => {
    finishEdit()
    close()
  }, [close, finishEdit])

  const ChildOkrReOrderingModal = useMemo(
    () =>
      isOpened && (
        <ChildOkrReOrderingModalContainer
          modalComponent={Modal}
          okrNodeId={okrNodeId}
          isOpened
          onClose={closeChildOkrReOrderingModal}
        />
      ),
    [Modal, closeChildOkrReOrderingModal, isOpened, okrNodeId],
  )

  return {
    ChildOkrReOrderingModal,
    openChildOkrReOrderingModal,
  }
}
