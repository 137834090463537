import React, { RefObject } from 'react'
import { createPortal } from 'react-dom'

import { OkrFloatDrawer } from '../OkrFloatDrawer'

import { useInjection } from './hooks'
import { OkrFloatDrawerItem, OkrFloatDrawerListItem } from './types'

export type Props = {
  addItems: ReadonlyArray<OkrFloatDrawerListItem>
  parentRef?: RefObject<HTMLElement>
  baseZIndex?: number
  isParentRoot?: boolean
}

export const OkrFloatDrawerList: React.FC<Props> = ({
  addItems,
  parentRef,
  baseZIndex = 0,
  isParentRoot,
}) => {
  const {
    list,
    parentClientRect,
    onRemoved,
    onDragStart,
    onDragStop,
    onClick,
    onResizeStart,
    onResizeStop,
    onMouseEnter,
    onMouseLeave,
  } = useInjection(addItems, baseZIndex, parentRef)

  if (!list.length) {
    return null
  }

  const listJsx = list.map((item: OkrFloatDrawerItem) => (
    <OkrFloatDrawer
      key={item.objectiveId}
      parentClientRect={parentClientRect}
      objectiveId={item.objectiveId}
      selectedObjectiveId={item.selectedObjectiveId}
      selectedKeyResultIds={item.selectedKeyResultIds}
      position={item.position}
      onRemoved={onRemoved}
      onDragStart={onDragStart}
      onDragStop={onDragStop}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      onResizeStart={onResizeStart}
      onResizeStop={onResizeStop}
      zIndex={item.zIndex}
    />
  ))

  return isParentRoot ? createPortal(listJsx, document.body) : <>{listJsx}</>
}

OkrFloatDrawerList.displayName = 'OkrFloatDrawerList'
