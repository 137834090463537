import { Box, DropButton, Calendar, Button, ThemeContext } from 'grommet'
import { Previous, Next } from 'grommet-icons'
import { forwardRef, useState } from 'react'

import { Icon } from '../../../components/ui/Icon'
import { StyledText } from '../../../components/ui/StyledText'
import { color } from '../../../styles/newColors'

export type Props = {
  id: string
  name: string
  value: string
  disabled?: boolean
  bounds?: { startDate: Date; endDate: Date }
  hasError?: boolean
  onChange: (date: Date) => void
}

// 日付のフォーマットをyyyy-mm-ddに統一するための変数。
const locale = 'ja'

export const OneOnOneModalDateParts = forwardRef<HTMLInputElement, Props>(
  ({ id, name, value = '', disabled = false, hasError = false, bounds, onChange }, ref) => {
    const [date, setDate] = useState<string>(
      (value ? new Date(value) : new Date()).toLocaleString(locale),
    )
    const [isShowCalendar, setIsShowCalendar] = useState(false)
    // WARNING: timeZone は指定しない。JSTに固定すると海外在住ユーザーとの時差が無視されて1日ズレる。
    const [dateReference, setDateReference] = useState(date)

    // TODO: ここArrayになることはない！ しかもArrayの際の処理が記述されていない。
    const onSelect = (selectedDate: string | Array<string>) => {
      if (!Array.isArray(selectedDate)) {
        setDate(new Date(selectedDate).toLocaleString(locale))
        onChange(new Date(selectedDate))
      }
      setIsShowCalendar(false)
    }

    const calenderBounds = bounds
      ? [bounds.startDate.toString(), bounds.endDate.toString()]
      : undefined

    return (
      <Box>
        <ThemeContext.Extend value={{}}>
          <DropButton
            disabled={disabled}
            css={{
              alignItems: 'center',
              border: hasError ? '1px solid #D42922' : '1px solid #DADADA',
              borderRadius: '4px',
              display: 'flex',
              justifyContent: date ? 'space-between' : 'flex-end',
              height: '40px',
              width: '132px',
              padding: '0 12px 0 8px',
            }}
            color="#DADADA"
            dropAlign={{
              top: 'bottom',
              left: 'left',
            }}
            dropProps={{
              round: '4px',
            }}
            id={id}
            open={isShowCalendar}
            onClose={() => setIsShowCalendar(false)}
            onOpen={() => setIsShowCalendar(true)}
            dropContent={
              <ThemeContext.Extend
                value={{
                  global: {
                    size: {
                      small: `${524 / 2}px`,
                    },
                  },
                  calendar: {
                    day: {
                      extend: ({ isSelected }: { isSelected: boolean }) => ({
                        backgroundColor: isSelected ? color('resily-orange-100') : undefined,
                        borderRadius: '50%',
                        marginBottom: '8px',
                      }),
                    },
                    extend: {
                      button: {
                        borderRadius: '50%',
                      },
                    },
                  },
                }}
              >
                {/* TODO: 余白の大きさの確認 */}
                <Box
                  justify="center"
                  direction="row"
                  pad={{
                    vertical: '16px',
                    horizontal: '10px',
                  }}
                  width="288px"
                  height="339px"
                >
                  <Calendar
                    animate={false}
                    date={new Date(date).toISOString()}
                    locale="ja"
                    fill
                    reference={dateReference}
                    size="small"
                    showAdjacentDays={false}
                    bounds={calenderBounds}
                    css={{
                      borderRadius: '4px',
                      boxShadow: 'none',

                      button: {
                        ':focus': {
                          boxShadow: 'none',
                        },
                      },

                      div: {
                        boxShadow: 'none',
                      },
                    }}
                    header={({ date: currentDate, onPreviousMonth, onNextMonth }) => (
                      <Box
                        direction="row"
                        align="center"
                        margin={{
                          bottom: '16px',
                        }}
                      >
                        <Button icon={<Previous />} onClick={onPreviousMonth} />
                        <StyledText css={{ marginLeft: 'auto', marginRight: 'auto' }} weight="bold">
                          {currentDate.toLocaleDateString(locale, {
                            month: 'long',
                            year: 'numeric',
                          })}
                        </StyledText>
                        <Button icon={<Next />} onClick={onNextMonth} />
                      </Box>
                    )}
                    onSelect={onSelect}
                    onReference={(reference) => {
                      setDateReference(new Date(reference).toLocaleString(locale))
                    }}
                  />
                </Box>
              </ThemeContext.Extend>
            }
          >
            <StyledText color="text-bk-100">{new Date(date).toLocaleDateString(locale)}</StyledText>
            <input
              value={date ? new Date(date).toLocaleDateString(locale) : ''}
              name={name}
              ref={ref}
              hidden
            />
            {isShowCalendar ? (
              <Icon
                type="selectDown"
                color={color('border-bk-20')}
                css={{ width: '8px', transform: 'rotate(-180deg)' }}
              />
            ) : (
              <Icon type="selectDown" color={color('border-bk-20')} css={{ width: '8px' }} />
            )}
          </DropButton>
        </ThemeContext.Extend>
      </Box>
    )
  },
)
