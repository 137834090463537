import { css } from '@emotion/react'
import { PlatePluginComponent } from '@udecode/plate'
import { Drop, Box } from 'grommet'
import { useRef, useState } from 'react'
import { useReadOnly } from 'slate-react'

const linkCss = css({
  textDecoration: 'underline',
  color: '#DC440A',
  ':hover, :visited:hover': {
    color: '#F4602E',
  },
})

const dropBoxCss = css({
  background: 'rgb(63 68 71)',
  borderRadius: 3,
  transition: 'top 75ms ease-out, left 75ms ease-out',
  boxShadow:
    'rgb(15 15 15 / 10%) 0px 0px 0px 1px, rgb(15 15 15 / 20%) 0px 3px 6px, rgb(15 15 15 / 40%) 0px 9px 24px',
})

const dropBoxLinkCss = css({
  color: 'rgb(255 255 255 / 90%)',
})

export const LinkElement: PlatePluginComponent = ({ attributes, element: { url }, children }) => {
  const ref = useRef<HTMLAnchorElement>(null)
  const [visibility, setVisibility] = useState<boolean>(false)
  const readOnly = useReadOnly()

  return (
    <>
      {readOnly ? (
        <a {...attributes} href={url} target="_blank" rel="noopener noreferrer" css={linkCss}>
          {children}
        </a>
      ) : (
        <span
          {...attributes}
          ref={ref}
          css={linkCss}
          onFocus={() => {}} // これがないと onMouse~が機能しない
          onMouseOver={() => setVisibility(true)}
          onMouseLeave={() => setVisibility(false)}
        >
          {visibility && (
            <Drop target={ref?.current || undefined} align={{ top: 'bottom' }} plain>
              <Box css={dropBoxCss} pad="small" round="small">
                <a
                  className="editor-link-popup"
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                  css={dropBoxLinkCss}
                >
                  {url}
                </a>
              </Box>
            </Drop>
          )}
          {children}
        </span>
      )}
    </>
  )
}
