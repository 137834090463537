import { FC, useContext, useEffect, useState } from 'react'

import { ApolloContext } from '../../../contexts/ApolloContext'
import { FetchContext } from '../../../lib/client'
import { GlobalCSS } from '../../../styles/global'
import { Grommet } from '../../../styles/grommetTheme'
import { ProgressBar } from '../../ui/ProgressBar'
import { ErrorSnackbar, SuccessSnackbar } from '../../ui/Snackbar'

export const Container: FC = ({ children }) => {
  const apolloContext = useContext(ApolloContext)
  const fetchContext = useContext(FetchContext)

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [errors, setErrors] = useState<ReadonlyArray<string>>([])

  useEffect(() => {
    setLoading(apolloContext.loading || fetchContext.loading)
  }, [apolloContext.loading, fetchContext.loading])

  useEffect(() => {
    const s = apolloContext.success || fetchContext.success
    setSuccess(s)
    if (s) {
      setErrors([])
    }
  }, [apolloContext.success, fetchContext.success])

  useEffect(() => {
    const errs = [...apolloContext.errors, ...(fetchContext.error ? [fetchContext.error] : [])]
    setErrors(errs)
    if (errors.length > 0) {
      setSuccess(false)
    }
  }, [apolloContext.errors, errors.length, fetchContext.error])

  return (
    <Grommet full>
      <GlobalCSS />
      {children}
      {loading ? (
        <ProgressBar
          css={{
            position: 'fixed',
            top: 0,
            zIndex: 9999,
          }}
        />
      ) : null}
      <SuccessSnackbar open={success}>Success</SuccessSnackbar>
      <ErrorSnackbar open={errors.length > 0}>
        <ul>
          {Array.from(new Set(errors)).map((message) => (
            <li key={message}>{message}</li>
          ))}
        </ul>
      </ErrorSnackbar>
    </Grommet>
  )
}

Container.displayName = 'Container'
