import { forwardRef, KeyboardEvent, useRef, ChangeEvent } from 'react'

import { convertNumber } from '../../../lib/convertNumber'
import { CountInput, CountInputProps } from '../CountInput'

export type Props = Pick<
  CountInputProps,
  | 'name'
  | 'autoFocus'
  | 'inputSize'
  | 'fontSize'
  | 'fieldStyle'
  | 'isError'
  | 'width'
  | 'radius'
  | 'onFocus'
> & {
  minWidth?: number
  maxWidth?: number
  growCharacterNumber?: number
  defaultValue?: number | string
  onChangeInputValue?: (value: string) => void
  setValueOnChange: (type: 'up' | 'down') => void
  allowDecimal?: boolean
  positive?: boolean
}

export const ProgressNumberInput = forwardRef<HTMLInputElement, Props>(
  (
    {
      minWidth = 60,
      maxWidth = 107,
      growCharacterNumber = 3,
      defaultValue = 0,
      setValueOnChange,
      onChangeInputValue,
      name,
      allowDecimal = true,
      positive = false,
      ...props
    },
    ref,
  ) => {
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    const preValue = useRef(String(defaultValue))

    const getInputWidth = (text: string): string => {
      if (!ctx) {
        return `${minWidth}px`
      }
      const textWidth = ctx.measureText(text).width
      if (text.length <= growCharacterNumber) {
        return `${minWidth}px`
      }
      const styleWidth = Math.floor(minWidth + textWidth - 7)
      if (styleWidth > maxWidth) {
        return `${maxWidth}px`
      }
      return `${styleWidth}px`
    }

    const changeWidthByTextLength = (e: ChangeEvent<HTMLInputElement>) => {
      const t = e.currentTarget
      t.style.width = getInputWidth(t.value)
    }

    const filterFloatNegativeNumber = (e: KeyboardEvent<HTMLInputElement>) => {
      const convertValue = convertNumber(e.currentTarget.value)
      if (
        (allowDecimal && Number.isFinite(Number(convertValue))) ||
        (!allowDecimal && Number.isInteger(Number(convertValue))) ||
        (!positive && convertValue === '-')
      ) {
        preValue.current = convertValue
        e.currentTarget.value = convertValue
      } else {
        e.currentTarget.value = preValue.current
      }
      if (onChangeInputValue) onChangeInputValue(e.currentTarget.value)
    }

    return (
      <CountInput
        required
        type="text"
        fontSize="medium"
        fieldStyle="bottomLine"
        ref={ref}
        name={name}
        value={defaultValue}
        width={getInputWidth(String(defaultValue))}
        onInput={filterFloatNegativeNumber}
        onChange={changeWidthByTextLength}
        onCountUp={() => setValueOnChange('up')}
        onCountDown={() => setValueOnChange('down')}
        {...props}
      />
    )
  },
)

ProgressNumberInput.displayName = 'ProgressNumberInput'
