import { keyResultsKeyName } from '../../formSchema'

import { InitialValueSection } from './InitialValueSection'
import { SelectUnitSection } from './SelectUnitSection'
import { TargetValueSection } from './TargetValueSection'
import { containerCss } from './styles'

type ProgressRateSettingFormProps = {
  index: number
}

export const ProgressRateSettingForm: React.FC<ProgressRateSettingFormProps> = ({ index }) => (
  <div css={containerCss}>
    <div id="ProgressAndConfidence" css={{ display: 'flex', alignItems: 'center', gap: 16 }}>
      <TargetValueSection index={index} valueType="targetValue" />
      <SelectUnitSection name={`${keyResultsKeyName}.${index}.unit`} />
      <InitialValueSection index={index} valueType="initialValue" />
    </div>
  </div>
)
