import { Fragment, useMemo, VFC } from 'react'
import { FieldErrors, UseFormRegister } from 'react-hook-form'

import { useTranslation } from '../../../i18n'
import { Langs } from '../../../i18n/language'
import { okrGlossaryKey, defaultOkrGlossary } from '../../../i18n/okrGlossary'
import { ErrorMessage } from '../../ui/ErrorMessage'
import { Input } from '../../ui/Input'
import { ListCard, TableRow, TableHeader } from '../../ui/ListCard'
import { StyledText } from '../../ui/StyledText'

import { CustomOkrGlossaryFragment, OkrGlossary } from './graphql'

export type OkrGlossaryUpdateArgs = {
  okrGlossary: OkrGlossary
  customTextJa: string
  customTextEn: string
}

type FormValue = {
  [key in OkrGlossary]: OkrGlossaryUpdateArgs
}

export type Props = {
  mode: 'view' | 'edit'
  customOkrGlossaries: ReadonlyArray<CustomOkrGlossaryFragment>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegister<FormValue>
  errors: FieldErrors<{
    [key in OkrGlossary]: {
      okrGlossary: OkrGlossary
      customTextJa: string
      customTextEn: string
    }
  }>
}

export const OkrGlossaryListCard: VFC<Props> = ({
  mode,
  customOkrGlossaries,
  register,
  errors,
  ...props
}) => {
  const { t, i18n } = useTranslation()
  const isEdit = mode === 'edit'

  const headers = useMemo<Array<TableHeader>>(
    () => [
      {
        title: t('DEFAULT_TEXT'),
        minWidth: 270,
        css: {
          width: '100%',
          paddingLeft: 8,
        },
      },
      {
        title: t('TARGET_TEXT_JA'),
        css: {
          width: '100%',
          maxWidth: 306,
        },
      },
      {
        title: t('TARGET_TEXT_EN'),
        css: {
          width: '100%',
          maxWidth: 306,
          marginLeft: 66,
        },
      },
    ],
    [t],
  )
  const glossariesForRows = useMemo(
    () =>
      customOkrGlossaries
        // NOTE: 定義されていないものがapiから返却されたときにエラーになるのを防ぐため
        //       i18nで定義されているものだけを表示する
        .filter((g) => okrGlossaryKey[g.okrGlossary as OkrGlossary])
        .map((g) => {
          const targetOkrGlossary = g.okrGlossary as OkrGlossary
          const i18nOkrGlossary = okrGlossaryKey[targetOkrGlossary]
          const jaGlossary = g.customTextJa || defaultOkrGlossary(i18nOkrGlossary, 'ja')
          const enGlossary = g.customTextEn || defaultOkrGlossary(i18nOkrGlossary, 'en')
          return {
            jaGlossary,
            enGlossary,
            i18nOkrGlossary,
            okrGlossary: targetOkrGlossary,
          }
        }),
    [customOkrGlossaries],
  )

  // NOTE: react-hook-formの影響なのかエラー時に再レンダリングされないためメモ化しない
  const rows: Array<TableRow> = glossariesForRows.map(
    ({ okrGlossary, jaGlossary, enGlossary, i18nOkrGlossary }) => ({
      key: okrGlossary,
      css: {
        alignItems: 'start',
        borderBottom: 'none',
      },
      cells: [
        {
          key: `${okrGlossary}-default`,
          cell: (
            <Fragment>
              <StyledText size="large">
                {
                  // 初期表示文言ではマネフォさんもjaとして表示する
                  i18n.language === 'moneyForward'
                    ? defaultOkrGlossary(i18nOkrGlossary, 'ja')
                    : defaultOkrGlossary(i18nOkrGlossary, i18n.language as Langs)
                }
              </StyledText>
              <Input
                {...register(`${okrGlossary}.okrGlossary`)}
                type="hidden"
                value={okrGlossary}
              />
            </Fragment>
          ),
          minWidth: 270,
          css: {
            width: '100%',
            paddingLeft: 8,
            alignSelf: 'center',
          },
        },
        {
          key: `${okrGlossary}-ja`,
          cell: isEdit ? (
            <Fragment>
              <Input
                {...register(`${okrGlossary}.customTextJa`)}
                fontSize="large"
                isError={!!errors[okrGlossary]?.customTextJa}
                width="inherit"
                fieldStyle="bottomLine"
                type="text"
                defaultValue={jaGlossary}
              />
              <ErrorMessage
                css={{
                  display: errors[okrGlossary]?.customTextJa ? 'block' : 'none',
                }}
              >
                * {errors[okrGlossary]?.customTextJa?.message}
              </ErrorMessage>
            </Fragment>
          ) : (
            <StyledText size="large">{jaGlossary}</StyledText>
          ),
          css: {
            width: '100%',
            maxWidth: 306,
            paddingTop: isEdit ? 8 : 20,
          },
        },
        {
          key: `${okrGlossary}-en`,
          cell: isEdit ? (
            <Fragment>
              <Input
                {...register(`${okrGlossary}.customTextEn`)}
                fontSize="large"
                isError={!!errors[okrGlossary]?.customTextEn}
                width="inherit"
                fieldStyle="bottomLine"
                type="text"
                defaultValue={enGlossary}
              />
              <ErrorMessage
                css={{
                  display: errors[okrGlossary]?.customTextEn ? 'block' : 'none',
                }}
              >
                * {errors[okrGlossary]?.customTextEn?.message}
              </ErrorMessage>
            </Fragment>
          ) : (
            <StyledText size="large">{enGlossary}</StyledText>
          ),
          css: {
            width: '100%',
            maxWidth: 306,
            marginLeft: 66,
            paddingTop: isEdit ? 8 : 20,
          },
        },
      ],
    }),
  )

  return (
    <ListCard
      borderless
      header={<Fragment />}
      table={{
        headers,
        data: {
          rows,
          rowMinHeight: 56,
        },
      }}
      drawborderBottom={false}
      {...props}
    />
  )
}

OkrGlossaryListCard.displayName = 'OkrGlossaryListCard'
