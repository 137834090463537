import React, { useRef, useState } from 'react'

import { useTranslation } from '../../../i18n'
import { useClickOutside } from '../../../lib/clickOutside'
import { ExternalUrls } from '../../../urls'
import { MultiSelect } from '../../ui/MultiSelect'
import { Popover } from '../../ui/Popover'
import { TextButton } from '../../ui/TextButton'
import { GroupTag, Props as GroupTagProps } from '../GroupTag'

import { useStyles } from './GroupSelect.styles'
import { GroupFragment } from './graphql'

export type Props = {
  selectedGroups: ReadonlyArray<GroupTagProps>
  groups: ReadonlyArray<GroupFragment>
  isLoading?: boolean
  onOpen?: () => void
  onClickGroupOption: (id: string) => void
}

export const GroupSelect: React.FC<Props> = ({
  selectedGroups,
  groups,
  isLoading = false,
  onOpen,
  onClickGroupOption,
}) => {
  const { t } = useTranslation()
  const styles = useStyles()
  const [open, setOpen] = useState<boolean>(false)
  const multiSelectRef = useRef<HTMLDivElement>(null)

  const options = groups
    .map(({ id, name }) => ({
      id,
      name,
      searchText: name,
      selected: selectedGroups.some((g) => g.id === id),
      // Groupは無効化表示をしないため明示的にfalseを渡す
      isDisabled: false,
    }))
    .sort((a, b) => (b.selected ? 1 : -1) - (a.selected ? 1 : -1))

  useClickOutside({ callback: () => setOpen(false), ref: multiSelectRef })

  return (
    <div css={styles.root}>
      {open && (
        <div ref={multiSelectRef} css={styles.multiSelectRoot}>
          {/* TODO: 0件の場合の表示 */}
          <MultiSelect
            canSearch
            searchPlaceholder={t('INPUT_X', { x: t('GROUP_NAME') })}
            options={options}
            isLoading={isLoading}
            onClickOption={onClickGroupOption}
          />
        </div>
      )}

      {selectedGroups.length !== 0 && (
        <div css={styles.groupTagsRoot}>
          {selectedGroups.map((group) => (
            <GroupTag key={`group_${group.id}`} name={group.name} />
          ))}
          <TextButton
            icon="editOutline"
            color="text-bk-30"
            padding="none"
            onClick={() => {
              if (onOpen) {
                onOpen()
              }
              setOpen(true)
            }}
          >
            {t('EDITION')}
          </TextButton>
        </div>
      )}
      {selectedGroups.length === 0 && groups.length === 0 && (
        <Popover
          targetContent={
            <TextButton icon="plus" padding="none" disabled>
              {t('ADD_X', { x: t('GROUP_TAG') })}
            </TextButton>
          }
          dropdownContent={
            <Popover.DropdownContent
              text={t('GROUP_SELECT_HELP')}
              link={{
                text: t('MORE_DETAILS'),
                href: ExternalUrls.ADD_GROUP_TAGS,
              }}
            />
          }
        />
      )}
      {selectedGroups.length === 0 && groups.length !== 0 && (
        <TextButton
          icon="plus"
          color="text-bk-30"
          padding="none"
          onClick={() => {
            if (onOpen) {
              onOpen()
            }
            setOpen(true)
          }}
        >
          {t('ADD_X', { x: t('GROUP_TAG') })}
        </TextButton>
      )}
    </div>
  )
}

GroupSelect.displayName = 'GroupSelect'
