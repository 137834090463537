import { useRef, useEffect, useCallback } from 'react'
import { ArrayParam, createEnumParam, StringParam, useQueryParams } from 'use-query-params'

import { withDrawerParams } from '../../../components/domain/OkrDrawer/withDrawerParams'
import { useCurrentUser } from '../../../contexts/UserContext'
import { MapView, OKR_LIST_TAB_QUERY } from '../../../urls'

/**
 * filterのstateを変更する関数を提供
 */
export const useSetFilter = (): {
  changeFiltering: (data: {
    groupIds?: ReadonlyArray<string>
    userIds?: ReadonlyArray<string>
  }) => void
  changeViewType: (v: MapView) => void
} => {
  const user = useCurrentUser()
  const [queryParams, setQueryParams] = useQueryParams(
    withDrawerParams({
      group: ArrayParam,
      user: ArrayParam,
      node: ArrayParam,
      view: createEnumParam<MapView>(['tree', 'list', 'activity']),
      [OKR_LIST_TAB_QUERY]: StringParam,
    }),
  )

  const changeFiltering = useCallback(
    ({
      groupIds,
      userIds,
    }: {
      groupIds?: ReadonlyArray<string>
      userIds?: ReadonlyArray<string>
    }) => {
      const tabList =
        (queryParams[OKR_LIST_TAB_QUERY] === 'self' &&
          userIds &&
          user &&
          !userIds.includes(user.id)) ||
        (queryParams[OKR_LIST_TAB_QUERY] === 'group' &&
          (groupIds?.length === 0 || (!groupIds && !queryParams.group?.length)))
          ? 'all'
          : queryParams[OKR_LIST_TAB_QUERY]
      setQueryParams(
        {
          ...(groupIds ? { group: [...groupIds] } : {}),
          ...(userIds ? { user: [...userIds] } : {}),
          [OKR_LIST_TAB_QUERY]: tabList,
        },
        'replaceIn',
      )
    },
    [setQueryParams, user, queryParams],
  )

  const previousQueryParams = useRef(queryParams)
  useEffect(() => {
    previousQueryParams.current = queryParams
  }, [queryParams])
  const changeViewType = useCallback(
    (v: MapView) => {
      setQueryParams(
        {
          ...previousQueryParams.current,
          view: v,
          // リストビューからそれ以外に遷移する際はサブツリー/フォーカスを引き継がない
          ...(v !== 'list' ? { listViewTarget: undefined, listViewType: undefined } : {}),
        },
        'push',
      )
    },
    [previousQueryParams, setQueryParams],
  )

  return {
    changeViewType,
    changeFiltering,
  }
}
