import { useCallback, useMemo } from 'react'
import { DropResult } from 'react-beautiful-dnd'

import { useCurrentUser } from '../../../../../contexts/UserContext'
import {
  newDisplayOrder,
  reOrderingItems,
  useOkrRefetchQueriesWithOkrModalInitQuery,
} from '../../../../../lib/domain/okr'
import {
  OkrNodeFragment,
  ReOrderingOkrNodeMutationVariables,
  useReOrderingOkrNodeMutation,
} from '../graphql'

export type ReOrderingChildrenOkrReturnType = {
  orderedChildrenOkrNodes: ReadonlyArray<OkrNodeFragment>
  handleReOrderingChildrenOkrNodes: (result: DropResult) => void
}

/** @private */
export const useReOrderingChildrenOkr = (
  parentOkrNodeId: ReOrderingOkrNodeMutationVariables['parentOkrNodeId'] | null | undefined,
  childrenOkrNodes: ReadonlyArray<OkrNodeFragment>,
): ReOrderingChildrenOkrReturnType => {
  const [reOrderingOkrNode] = useReOrderingOkrNodeMutation({
    refetchQueries: useOkrRefetchQueriesWithOkrModalInitQuery(),
  })

  const currentUser = useCurrentUser()
  const isShowDisabledOkr = !!currentUser?.userSetting.showDisabledOkrs

  const orderAndFilteredChildrenOkrNodes = useMemo<
    ReOrderingChildrenOkrReturnType['orderedChildrenOkrNodes']
  >(
    () =>
      (isShowDisabledOkr
        ? childrenOkrNodes
        : childrenOkrNodes.filter((node) => !node.objective.isDisabled)
      )
        // 一時的に存在するdisplayOrder順になってないキャッシュをdisplayOrder順に並び替える
        .concat()
        .sort((a, b) => a.displayOrder - b.displayOrder),
    [childrenOkrNodes, isShowDisabledOkr],
  )

  const handleReOrderingChildrenOkrNodes = useCallback<
    ReOrderingChildrenOkrReturnType['handleReOrderingChildrenOkrNodes']
  >(
    (result: DropResult) => {
      if (!result.destination || !parentOkrNodeId) return

      const { draggableId } = result
      const source = result.source.index
      const destination = result.destination.index

      const reOrderingChildrenOkrNodes = reOrderingItems(
        orderAndFilteredChildrenOkrNodes,
        source,
        destination,
      )
      if (!reOrderingChildrenOkrNodes) return

      reOrderingOkrNode({
        variables: {
          id: draggableId,
          parentOkrNodeId,
          displayOrder: newDisplayOrder(orderAndFilteredChildrenOkrNodes, source, destination),
        },
        optimisticResponse: {
          reOrderingOkrNode: reOrderingChildrenOkrNodes,
        },
      })
    },
    [orderAndFilteredChildrenOkrNodes, parentOkrNodeId, reOrderingOkrNode],
  )

  return {
    orderedChildrenOkrNodes: orderAndFilteredChildrenOkrNodes,
    handleReOrderingChildrenOkrNodes,
  }
}
