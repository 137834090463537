import { ActionPlanStatus } from '../../../graphql'
import { useTranslation } from '../../../i18n/index'
import { color } from '../../../styles/newColors'
import { StyledText } from '../../ui/StyledText'

const tagBlue = { backgroundColor: 'rgba(57, 94, 191, 0.1);', color: '#395EBF' }
const tagLightgreen = { backgroundColor: 'rgba(50, 185, 136, 0.1)', color: '#32B988' }
const tagOrange = { backgroundColor: 'rgba(244, 178, 50, 0.1)', color: '#F4B232' }
const tagGray = { backgroundColor: color('background-bk-5'), color: color('text-bk-50') }

const ColorAliasObj: { [key in ActionPlanStatus]: { backgroundColor: string; color: string } } = {
  [ActionPlanStatus.NotStarted]: tagGray,
  [ActionPlanStatus.Completed]: tagLightgreen,
  [ActionPlanStatus.Discarded]: tagGray,
  [ActionPlanStatus.Held]: tagOrange,
  [ActionPlanStatus.Started]: tagBlue,
}

export type Props = { status: ActionPlanStatus }

export const ActionPlanStatusBadge: React.FC<Props> = ({ status }) => {
  const { t } = useTranslation()

  const label = t(`ACTION_PLAN_STATUS_${status}` as const)
  const { color: c, backgroundColor } = ColorAliasObj[status]

  return (
    <div
      css={{
        display: 'inline-flex',
        minHeight: '18px',
        padding: '2px',
        backgroundColor,
      }}
    >
      <StyledText css={{ color: c, whiteSpace: 'nowrap' }}>{label}</StyledText>
    </div>
  )
}

ActionPlanStatusBadge.displayName = 'ActionPlanStatusBadge'
