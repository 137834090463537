import { useTranslation } from '../../../../../../i18n'
import { InvitingUserTableFragment } from '../../../../api/graphql'
import { InvitingUserTableDatum } from '../types'

export const useMakeInvitingUserData = (
  users: InvitingUserTableFragment['invitingUsers'],
): Array<InvitingUserTableDatum> => {
  const { t } = useTranslation()

  return users.map(
    (u): InvitingUserTableDatum => ({
      id: u.id,
      fullName: t('FULL_NAME', { firstName: u.firstName, lastName: u.lastName }),
      email: u.email,
      admin: u.admin,
      confirmationSentAt: u.confirmationSentAt,
    }),
  )
}
