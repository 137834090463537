import { css } from '@emotion/react'
import { useMemo } from 'react'

import { color } from '../../../../styles/newColors'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useStyles = () =>
  useMemo(
    () =>
      ({
        root: css({
          display: 'flex',
          alignItems: 'center',
          gap: '24px',
          marginBottom: '24px',
        }),
        label: css({
          width: '108px',
          '&::after': {
            color: color('resily-orange-100'),
            content: '"*"',
          },
        }),
      } as const),
    [],
  )
