import { Box, Tip } from 'grommet'
import React from 'react'

import { color, ColorAlias } from '../../../styles/newColors'
import { StyledText, Props as StyledTextProps } from '../StyledText'

const isAncestorsNamesFlatArray = (
  ancestorsNames: Props['ancestorNames'],
): ancestorsNames is ReadonlyArray<string> =>
  ancestorsNames.length > 0 && typeof ancestorsNames[0] === 'string'

export type Props = StyledTextProps & {
  name: string
  backgroundColor?: ColorAlias
  tooltipDisabled?: boolean
} & (
    | {
        hiddenGroupsTreeTag?: never
        ancestorNames: ReadonlyArray<string>
        // TODO: 上位グループ名を3文字に短縮する処理を実装
        // truncate?: boolean
      }
    | {
        // HiddenGroupsTreeTag用
        hiddenGroupsTreeTag: true
        ancestorNames: ReadonlyArray<ReadonlyArray<string>>
      }
  )

export const GroupTreeTag: React.FC<Props> = ({
  name,
  ancestorNames,
  backgroundColor = 'background-bk-5',
  color: fontColor = 'text-bk-50',
  tooltipDisabled = false,
  ...props
}) => {
  let ancestorsNames: ReadonlyArray<ReadonlyArray<string>> = []
  if (isAncestorsNamesFlatArray(ancestorNames)) {
    ancestorsNames = [ancestorNames]
  } else {
    ancestorsNames = ancestorNames
  }

  return (
    <GroupTreeTip
      ancestorsNames={ancestorsNames}
      tooltipDisabled={
        tooltipDisabled ||
        ancestorsNames.length === 0 ||
        (ancestorsNames.length === 1 && ancestorsNames[0].length === 0)
      }
      css={{
        display: 'flex',
        alignItems: 'center',
        backgroundColor: color(backgroundColor),
        borderRadius: '2px',
        padding: '4px',
        color: color(fontColor),
      }}
    >
      <StyledText
        css={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: '100%',
        }}
        size="small"
        lineHeight="12px"
        {...props}
      >
        {name}
      </StyledText>
    </GroupTreeTip>
  )
}

GroupTreeTag.displayName = 'GroupTreeTag'

type GroupTreeTipProps = JSX.IntrinsicElements['div'] & {
  ancestorsNames: ReadonlyArray<ReadonlyArray<string>>
  tooltipDisabled?: boolean
}

const GroupTreeTip: React.FC<GroupTreeTipProps> = ({
  children,
  ancestorsNames,
  tooltipDisabled = false,
  ...props
}) =>
  tooltipDisabled ? (
    <div {...props}>{children}</div>
  ) : (
    <Tip
      plain
      dropProps={{
        align: {
          top: 'bottom',
          left: 'left',
        },
        stretch: false,
        plain: true,
      }}
      content={
        <Box
          as="ul"
          background={color('text-bk-80')}
          margin={{ top: '2px' }}
          width={{ max: '196px' }}
          overflow="hidden"
          pad="4px"
        >
          {ancestorsNames.map((ancestorNames) =>
            ancestorsNames.some((an) => an.length > 1) ? (
              <li css={{ '&:not(:last-child)': { marginBottom: '4px' } }}>
                <ul>
                  {ancestorNames.map((ancestorName, depth) => (
                    <GroupTreeTipContentRow depth={depth} ancestorName={ancestorName} />
                  ))}
                </ul>
              </li>
            ) : (
              ancestorNames.map((ancestorName, depth) => (
                <GroupTreeTipContentRow depth={depth} ancestorName={ancestorName} />
              ))
            ),
          )}
        </Box>
      }
    >
      <div {...props}>{children}</div>
    </Tip>
  )

GroupTreeTip.displayName = 'GroupTreeTip'

type GroupTreeTipContentRowProps = {
  depth: number
  ancestorName: string
}

const GroupTreeTipContentRow: React.FC<GroupTreeTipContentRowProps> = ({ depth, ancestorName }) => (
  <li css={{ display: 'flex', alignItems: 'center', height: '16px' }}>
    <StyledText
      oneline
      css={{
        display: 'inline-block',
        textOverflow: 'ellipsis',
        width: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        paddingLeft: depth > 0 ? -4 + (depth + 1) * 8 : undefined,
      }}
      size="small"
    >
      {depth > 0 ? `- ${ancestorName}` : ancestorName}
    </StyledText>
  </li>
)

GroupTreeTipContentRow.displayName = 'GroupTreeTipContentRow'
