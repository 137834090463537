import { Box, Button } from 'grommet'
import { Pin } from 'grommet-icons'
import * as React from 'react'

import { StyledText } from '../../components/ui/StyledText'

export type Props = {
  id: string
}

export const FixedAgendaTemplate: React.FC<Props> = ({ children, id }) => (
  <Box
    direction="row"
    align="center"
    css={{
      minHeight: 'initial',
    }}
  >
    <Button
      icon={
        <Pin
          css={{
            width: '14px',
            height: '14px',
          }}
        />
      }
      css={{
        padding: '8px',
        cursor: 'default',
      }}
    />
    <a href={`#${id}`} style={{ textDecoration: 'none', color: 'black' }}>
      <StyledText>{children}</StyledText>
    </a>
  </Box>
)

FixedAgendaTemplate.displayName = 'FixedAgendaTemplate'
