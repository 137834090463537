import { useProfiler } from '@sentry/react'
import { Box, Button, Collapsible } from 'grommet'
import { FormNext } from 'grommet-icons'
import { Fragment, useContext, useEffect, useRef, useState } from 'react'

import { GroupTags } from '../../../../../../../components/domain/GroupTags'
import type { OkrFloatDrawerListItem } from '../../../../../../../components/domain/OkrFloatDrawerList/types'
import { OkrTags } from '../../../../../../../components/domain/OkrTags'
import { UserSelect } from '../../../../../../../components/domain/UserSelect'
import { useKeyResultCreateModal } from '../../../../../../../components/standalone/KeyResultCreateModal'
import { Card } from '../../../../../../../components/ui/Card'
import { Icon } from '../../../../../../../components/ui/Icon'
import { MultiSelect, Option } from '../../../../../../../components/ui/MultiSelect'
import { Popover } from '../../../../../../../components/ui/Popover/deprecated'
import {
  isChangeDefaultTemplate,
  changeTreeJsonToPlainText,
  createDefaultObjectiveDescriptionTreeJson,
} from '../../../../../../../components/ui/RichTextEditor/defaultTemplates'
import { StyledText } from '../../../../../../../components/ui/StyledText'
import { TextButton } from '../../../../../../../components/ui/TextButton'
import { NewTitle } from '../../../../../../../components/ui/Title'
import { TooltipNew } from '../../../../../../../components/ui/TooltipNew'
import { AlertModalContext } from '../../../../../../../contexts/AlertModalContext'
import { useSetFloatDrawerItems } from '../../../../../../../contexts/OkrFloatDrawerContext'
import { useCurrentUser } from '../../../../../../../contexts/UserContext'
import { useTranslation } from '../../../../../../../i18n'
import { useClickOutside } from '../../../../../../../lib/clickOutside'
import { isIncludedUser } from '../../../../../../../lib/domain/objective/index'
import { useParentOkrNodesLazyQuery } from '../../../../../../../lib/domain/okr/graphql'
import { getCandidateUsers, getOwnerOptions } from '../../../../../../../lib/domain/users'
import { convertToFileInput } from '../../../../../../../lib/fileInput'
import { Screen } from '../../../../../../../lib/screen'
import { tracker } from '../../../../../../../lib/tracking'
import { fontSize } from '../../../../../../../styles/font'
import { color } from '../../../../../../../styles/newColors'
import { admin, generateObjective, groupsSettingAdmin } from '../../../../../../../urls'
import { OkrDrawerEditor } from '../OkrDrawerEditor'
import type { OkrDrawerRef } from '../OkrDrawerEditor/types'
import { OkrDrawerEmptyContent } from '../OkrDrawerEmptyContent'
import { OkrDrawerLoadingContent } from '../OkrDrawerLoadingContent'

import { DraggableKeyResultsTableDescription } from './DraggableKeyResultsTableDescription'
import { DraggableOkrNodesTableDescription } from './DraggableOkrNodesTableDescription'
import { KeyResultItem } from './KeyResultItem'
import {
  FileFragment,
  KeyResultFragment,
  OkrNodeFragment,
  NodeGroupFragment,
  ObjectiveFragment,
  UserFragment,
  useUsersLazyQuery,
  useGroupsLazyQuery,
  useOkrNodeQuery,
  useChildrenOkrNodesLazyQuery,
  useUpdateKeyResultNameMutation,
  useUpdateObjectiveOwnerMutation,
  useUpdateObjectiveParentKeyResultsMutation,
  UpdateObjectiveParentKeyResultsMutationVariables,
  useUpdateObjectiveDescriptionMutation,
  useUpdateObjectiveNameMutation,
  useAttachGroupToObjectiveMutation,
  useDetachGroupFromObjectiveMutation,
  UpdateObjectiveOwnerMutationVariables,
  ReOrderingKeyResultMutationVariables,
  useReOrderingKeyResultMutation,
  ReOrderingOkrNodeMutationVariables,
  useReOrderingOkrNodeMutation,
  useUpdateKeyResultOrderLazyQuery,
} from './graphql'

export type ContainerProps = {
  objectiveId: string
  isShownDisabledOkr: boolean
  onClickWeightSetting: () => void
}

export const ObjectiveDescriptionContainer: React.VFC<ContainerProps> = ({
  objectiveId,
  isShownDisabledOkr,
  onClickWeightSetting,
}) => {
  useProfiler('ObjectiveDescriptionContainer')
  const { t } = useTranslation()

  // FIXME: 不要なはずので消したい
  const [nodeId, setNodeID] = useState<string>('')
  const { data, loading, called, refetch } = useOkrNodeQuery({
    variables: { objectiveId },
    onCompleted: (fetchData) => {
      setNodeID(fetchData.okrNode.id)
      // TODO: 下記で発火させるとモーダルのsave時にもqueryが発行されてしまう
      childrenOkrNodesQuery({
        variables: { okrNodeId: fetchData.okrNode.id },
      })
    },
  })
  const [parentOkrNodesQuery] = useParentOkrNodesLazyQuery()
  const [childrenOkrNodesQuery, { data: childrenOkeNodeData }] = useChildrenOkrNodesLazyQuery()

  const [updateObjectiveName] = useUpdateObjectiveNameMutation()
  const [updateKeyResultName] = useUpdateKeyResultNameMutation()
  const [updateObjectiveOwner] = useUpdateObjectiveOwnerMutation()
  const [updateObjectiveDescription] = useUpdateObjectiveDescriptionMutation()
  const [updateKeyResultOrder] = useUpdateKeyResultOrderLazyQuery()
  const [updateObjectiveParentKeyResults] = useUpdateObjectiveParentKeyResultsMutation({
    onCompleted: () => {
      if (data?.okrNode) {
        parentOkrNodesQuery({ variables: { nodeId: data.okrNode.id } })
      }
    },
  })

  const [reOrderKR] = useReOrderingKeyResultMutation({
    onCompleted: (reOrderData) => {
      updateKeyResultOrder({ variables: { keyResultId: reOrderData.reOrderingKeyResult[0].id } })
    },
  })
  const [reOrderOKR] = useReOrderingOkrNodeMutation()

  const [attachGroup] = useAttachGroupToObjectiveMutation()
  const [detachGroup] = useDetachGroupFromObjectiveMutation()

  // 「目標の背景」のテンプレート文言
  const defaultObjectiveDescriptionTreeJson = createDefaultObjectiveDescriptionTreeJson([
    `${t('TARGET_BACKGROUND_DEFAULT_TEXT_FIRST')}`,
    `${t('DOT')}`,
    `${t('TARGET_BACKGROUND_DEFAULT_TEXT_SECOND')}`,
    `${t('DOT')}`,
  ])
  const defaultObjectiveDescriptionPlainText = changeTreeJsonToPlainText(
    defaultObjectiveDescriptionTreeJson,
  )

  useEffect(() => {
    if (!data?.okrNode.keyResults) return
    const keyResults = data?.okrNode.keyResults

    if (keyResults.length && keyResults.find((kr) => kr.displayOrder === null)) {
      reOrderKR({
        variables: {
          nodeID: nodeId,
          keyResultID: keyResults[0].id,
          displayOrder: 0,
        },
      })
    }
  }, [data?.okrNode.keyResults, nodeId, reOrderKR])

  const user = useCurrentUser()

  const setFloatDrawerItems = useSetFloatDrawerItems()

  const [KeyResultCreateModal, openKeyResultCreateModal] = useKeyResultCreateModal()

  if (!called || loading) {
    return <OkrDrawerLoadingContent />
  }

  const { okrNode } = data || {}
  if (okrNode == null) {
    return <OkrDrawerEmptyContent />
  }

  const { objective, parentNode, keyResults, groups } = okrNode

  const { childrenOkrNodes } = childrenOkeNodeData || {}

  const updateName = async (name: string) => {
    await updateObjectiveName({
      variables: {
        id: objective.id,
        name,
      },
    })
  }

  const updateKeyResultNameMutation = async (id: string, name: string) => {
    await updateKeyResultName({
      variables: {
        id,
        name,
      },
    })
  }

  const updateObjectiveOwnerMutation = async ({
    ownerId,
  }: Pick<UpdateObjectiveOwnerMutationVariables, 'ownerId'>) => {
    await updateObjectiveOwner({
      variables: {
        id: objective.id,
        ownerId,
      },
    }).catch(() => {})
  }

  const updateObjectiveParentKeyResultsMutation = async ({
    parentKeyResultIds,
  }: Pick<UpdateObjectiveParentKeyResultsMutationVariables, 'parentKeyResultIds'>) => {
    await updateObjectiveParentKeyResults({
      variables: {
        id: objective.id,
        parentKeyResultIds,
      },
    }).catch(() => {})
  }

  const updateObjectiveDescriptionMutation = async (
    treeJson: string,
    plainText: string,
    beforeTreeJson: string,
    beforePlainText: string,
    attachments: ReadonlyArray<FileFragment>,
  ) => {
    await updateObjectiveDescription({
      variables: {
        input: {
          id: objective.id,
          description: {
            treeJson,
            plainText,
          },
          beforeDescription: {
            treeJson: beforeTreeJson,
            plainText: beforePlainText,
          },
          attachments: attachments.map((attachment) => convertToFileInput(attachment)),
          isDescriptionChanged: isChangeDefaultTemplate(
            treeJson,
            defaultObjectiveDescriptionPlainText,
          ),
        },
      },
    })
  }
  const attachGroupMutation = async (id: string, ownerId: string, groupId: string) => {
    await attachGroup({
      variables: {
        id,
        ownerId,
        groupId,
      },
    }).catch(() => {})
  }

  const detachGroupMutation = async (id: string, ownerId: string, groupId: string) => {
    await detachGroup({
      variables: {
        id,
        ownerId,
        groupId,
      },
    }).catch(() => {})
  }

  const reOrderKeyResult = async (val: Omit<ReOrderingKeyResultMutationVariables, 'nodeID'>) => {
    reOrderKR({
      variables: {
        nodeID: nodeId,
        ...val,
      },
    })
  }

  const reOrderOkrNode = async (
    val: Omit<ReOrderingOkrNodeMutationVariables, 'parentOkrNodeID'>,
  ) => {
    reOrderOKR({
      variables: {
        parentOkrNodeID: nodeId,
        ...val,
      },
    })
  }

  const filteredKeyResults = isShownDisabledOkr
    ? keyResults
    : keyResults.filter((kr) => !kr.isDisabled)

  const filteredOkrNodes = isShownDisabledOkr
    ? childrenOkrNodes
    : childrenOkrNodes?.filter((o) => !o.objective.isDisabled)

  const openOkrFloatDrawer = () => {
    const parentItem: Array<OkrFloatDrawerListItem> = []
    // 上位KRに紐付いている場合
    if (objective.parentKeyResults.length) {
      const keyResultIds = objective.parentKeyResults.map((v) => v.id)
      parentItem.push({
        objectiveId: objective.parentKeyResults[0].objective.id,
        selectedKeyResultIds: keyResultIds,
      })
      // 上位Oに紐付いている場合
    } else if (parentNode) {
      parentItem.push({
        objectiveId: parentNode.objective.id,
        selectedObjectiveId: parentNode.objective.id,
      })
    }
    setFloatDrawerItems([
      ...parentItem,
      {
        objectiveId: objective.id,
        selectedObjectiveId: objective.id,
      },
    ])
  }

  return (
    <Fragment>
      <ObjectiveDescription
        okrNodeId={okrNode.id}
        objective={objective}
        keyResultItems={filteredKeyResults}
        keyResultOptions={parentNode?.keyResults || []}
        childrenOkrNodes={filteredOkrNodes || []}
        updateName={updateName}
        attachedGroups={groups}
        updateObjectiveOwner={updateObjectiveOwnerMutation}
        updateObjectiveParentKeyResults={updateObjectiveParentKeyResultsMutation}
        updateObjectiveDescription={updateObjectiveDescriptionMutation}
        updateKeyResultName={updateKeyResultNameMutation}
        attachGroupMutation={attachGroupMutation}
        detachGroupMutation={detachGroupMutation}
        onClickAddKeyResult={() => {
          openKeyResultCreateModal(
            {
              objectiveId: objective.id,
              ownerId: user?.id,
              onCompletedSave: () => refetch(),
              useWeighting: objective.useWeighting,
            },
            Screen.OneOnOneDetail,
          )
        }}
        onClickWeightSetting={() => {
          tracker.UserClickObjectiveDrawerWeightTab(
            Screen.OneOnOneDetail,
            isIncludedUser(objective, user),
          )
          onClickWeightSetting()
        }}
        reOrderKR={reOrderKeyResult}
        reOrderOKR={reOrderOkrNode}
        openOkrFloatDrawer={openOkrFloatDrawer}
      />
      {KeyResultCreateModal}
    </Fragment>
  )
}

ObjectiveDescriptionContainer.displayName = 'ObjectiveDescriptionContainer'

export type Props = {
  okrNodeId: string
  objective: ObjectiveFragment
  keyResultItems: ReadonlyArray<KeyResultFragment>
  keyResultOptions: ReadonlyArray<Pick<KeyResultFragment, 'id' | 'name' | 'isDisabled'>>
  childrenOkrNodes: ReadonlyArray<OkrNodeFragment>
  attachedGroups: ReadonlyArray<NodeGroupFragment>
  updateName: (name: string) => void
  updateKeyResultName: (keyResultId: string, name: string) => Promise<void>
  updateObjectiveOwner: (ownerId: Pick<UpdateObjectiveOwnerMutationVariables, 'ownerId'>) => void
  updateObjectiveParentKeyResults: (
    parentKeyResults: Pick<UpdateObjectiveParentKeyResultsMutationVariables, 'parentKeyResultIds'>,
  ) => void
  updateObjectiveDescription: (
    description: string,
    plainText: string,
    beforeTreeJson: string,
    beforePlainText: string,
    attachments: ReadonlyArray<FileFragment>,
  ) => void
  attachGroupMutation: (id: string, ownerId: string, groupId: string) => void
  detachGroupMutation: (id: string, ownerId: string, groupId: string) => void
  onClickAddKeyResult: () => void
  onClickWeightSetting: () => void
  reOrderKR: (val: Omit<ReOrderingKeyResultMutationVariables, 'nodeID'>) => void
  reOrderOKR: (val: Omit<ReOrderingOkrNodeMutationVariables, 'parentOkrNodeID'>) => void
  openOkrFloatDrawer: () => void
}

export const ObjectiveDescription: React.VFC<Props> = ({
  okrNodeId,
  objective,
  keyResultItems,
  keyResultOptions,
  childrenOkrNodes,
  attachedGroups,
  updateName,
  updateObjectiveOwner,
  updateObjectiveParentKeyResults,
  updateObjectiveDescription,
  updateKeyResultName,
  attachGroupMutation,
  detachGroupMutation,
  onClickAddKeyResult,
  onClickWeightSetting,
  reOrderKR,
  reOrderOKR,
  openOkrFloatDrawer,
}) => {
  useProfiler('ObjectiveDescription')
  const { t } = useTranslation()
  const user = useCurrentUser()
  const { checkOpenAlertModal } = useContext(AlertModalContext)
  const multiSelectRef = useRef<HTMLDivElement>(null)
  const okrDrawerEditorRef = useRef<OkrDrawerRef>(null)

  const [openGroupMultiSelect, setOpenGroupMultiSelect] = useState(false)
  const [groupOptionList, setGroupOptionList] = useState<ReadonlyArray<Option>>([])
  const [usersQuery, usersResp] = useUsersLazyQuery()
  const [groupsQuery, groupsResp] = useGroupsLazyQuery()
  const [openReOrderOkr, setOpenReOrderOkr] = useState(false)

  useClickOutside({ callback: () => setOpenGroupMultiSelect(false), ref: multiSelectRef })

  useEffect(() => {
    if (groupsResp.data?.groups) {
      setGroupOptionList(
        groupsResp.data.groups
          .map<Option>(({ id, name }) => ({
            id,
            name,
            searchText: name,
            selected: attachedGroups.some((item) => item.id === id),
            // Groupは無効化表示をしないため明示的にfalseを渡す
            isDisabled: false,
          }))
          .sort((a, b) => (b.selected ? 1 : -1) - (a.selected ? 1 : -1)),
      )
    }
  }, [groupsResp.data?.groups, setGroupOptionList, openGroupMultiSelect, attachedGroups])

  const candidates: ReadonlyArray<UserFragment> = usersResp.data?.users
    ? getCandidateUsers<UserFragment>(usersResp.data?.users)
    : []

  const ownerOptions: ReadonlyArray<UserFragment> = usersResp.data?.users
    ? getOwnerOptions(candidates, objective.owner)
    : ([objective.owner] as ReadonlyArray<UserFragment>)

  return (
    <Box>
      {/* objective name */}
      <Section>
        <div
          css={{
            width: '100%',
            display: 'flex',
          }}
        >
          <div css={{ marginRight: 'auto', flex: '1 1 auto' }}>
            <StyledText color="text-bk-50" size="small" weight="bold">
              {t('OBJECTIVE')}
            </StyledText>
            <h2
              css={{
                flex: '1 1 auto',
                marginTop: '8px',
                fontWeight: 'normal',
                color: color('text-bk-100'),
                ...fontSize('medium'),
              }}
            >
              <NewTitle
                color="objective-blue-100"
                title={objective.name || ''}
                onUpdate={updateName}
              />
            </h2>
          </div>
        </div>
      </Section>
      <Section>
        <Box margin={{ top: '20px' }} pad={{ bottom: 'small' }}>
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '8px',
              justifyContent: 'space-between',
            }}
          >
            <Box>
              <StyledText color="text-bk-50" size="small" weight="bold">
                {t('KEY_RESULT')}
              </StyledText>
            </Box>
            <div css={{ display: 'flex' }}>
              {keyResultItems.some((kr) => !kr.isDisabled) ? (
                <TextButton
                  size="small"
                  css={{ lineHeight: '12px', textDecoration: 'underline' }}
                  color="text-bk-80"
                  padding="0px"
                  onClick={onClickWeightSetting}
                >
                  {t(objective.useWeighting ? 'USE_WEIGHTING_ON' : 'USE_WEIGHTING_OFF')}
                </TextButton>
              ) : (
                <StyledText
                  size="small"
                  css={{ lineHeight: '12px', display: 'block' }}
                  color="text-bk-30"
                >
                  {t('USE_WEIGHTING_CANNOT')}
                </StyledText>
              )}
              <Popover
                icon="help"
                title={t('USE_WEIGHTING')}
                text={t('USE_WEIGHTING_HELP_MESSAGE')}
                height="12px"
                margin={{ left: '4px', right: '6px' }}
                position={{ left: '-280px' }}
                hasGap={false}
              />
            </div>
          </div>
          <div css={{ height: '1px', backgroundColor: color('border-bk-10') }} />

          <DraggableKeyResultsTableDescription
            items={keyResultItems}
            onReOrder={(keyResultId, displayOrder) =>
              reOrderKR({ keyResultID: keyResultId, displayOrder })
            }
            updateKeyResultName={updateKeyResultName}
          />
          <KeyResultItem
            create
            onClickAdd={() => {
              checkOpenAlertModal({
                onClickDiscard: () => {
                  if (okrDrawerEditorRef.current) {
                    okrDrawerEditorRef.current.onCancel()
                  }
                  onClickAddKeyResult()
                },
              })
            }}
          />
        </Box>
      </Section>
      {childrenOkrNodes.length > 0 ? (
        <Section>
          <Box margin={{ top: '20px' }} pad={{ bottom: 'small' }}>
            <button
              css={{
                color: color('border-bk-50'),
                ':hover': {
                  color: color('resily-orange-100'),
                  button: {
                    svg: {
                      stroke: color('resily-orange-100'),
                    },
                  },
                },
              }}
              onClick={() => setOpenReOrderOkr(!openReOrderOkr)}
              type="button"
            >
              <div
                css={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '8px',
                  justifyContent: 'space-between',
                }}
              >
                <Box>
                  <div
                    css={{
                      display: 'flex',
                      alignItems: 'center',
                      button: {
                        padding: 0,
                      },
                    }}
                  >
                    <Button
                      icon={
                        <FormNext
                          css={{
                            width: '20px',
                            height: '20px',
                            stroke: color('text-bk-50'),
                          }}
                        />
                      }
                      css={[openReOrderOkr && { transform: 'rotate(90deg)' }]}
                    />
                    <StyledText size="small" weight="bold">
                      {t('SORT_CHILD_OKR')}
                    </StyledText>
                  </div>
                </Box>
              </div>
            </button>

            <Collapsible open={openReOrderOkr}>
              <DraggableOkrNodesTableDescription
                items={childrenOkrNodes}
                onReOrder={(nodeId, displayOrder) => reOrderOKR({ ID: nodeId, displayOrder })}
              />
            </Collapsible>
          </Box>
        </Section>
      ) : (
        <Box margin={{ top: '20px' }} pad={{ bottom: 'small' }}>
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
              color: color('border-bk-50'),
              button: {
                padding: 0,
              },
            }}
          >
            <Button
              icon={
                <FormNext
                  css={{
                    width: '20px',
                    height: '20px',
                    stroke: color('text-bk-50'),
                  }}
                />
              }
            />
            <StyledText size="small" weight="bold">
              {t('NO_CHILD_OKR')}
            </StyledText>
          </div>
        </Box>
      )}
      <Section>
        <Box margin={{ top: '24px', bottom: '12px' }}>
          <StyledText color="text-bk-50" size="small" weight="bold">
            {t('OWNER')}
          </StyledText>
        </Box>
        <Box css={{ fontSize: '14px' }}>
          <UserSelect
            value={objective.owner.id}
            options={ownerOptions}
            onOpen={() => {
              if (!usersResp.called) {
                usersQuery()
              }
            }}
            isLoading={!usersResp.data?.users && (!usersResp.called || usersResp.loading)}
            onChange={(id) => {
              if (id === objective.owner.id) {
                return
              }
              const newOwner = ownerOptions.find((o) => o.id === id)
              if (newOwner) {
                updateObjectiveOwner({ ownerId: newOwner.id })
              }
            }}
            css={{ width: '306px' }}
          />
        </Box>
      </Section>
      <Section>
        <Box margin={{ top: '24px', bottom: '12px' }}>
          <StyledText color="text-bk-50" size="small" weight="bold">
            {t('SET_PARENT_KEY_RESULT')}
          </StyledText>
        </Box>
        <OkrTags
          width="306px"
          value={objective.parentKeyResults}
          candidates={keyResultOptions.filter(
            (c) => !objective.keyResults.find((k) => k.id === c.id) && !c.isDisabled,
          )}
          onChange={(keyResults) =>
            updateObjectiveParentKeyResults({ parentKeyResultIds: keyResults.map((pk) => pk.id) })
          }
        />
      </Section>
      <Section>
        <Box margin={{ top: '24px', bottom: '12px' }}>
          <div css={{ position: 'relative' }}>
            <div>
              <StyledText color="text-bk-50" size="small" weight="bold">
                {t('GROUP_TAG')}
              </StyledText>
              {attachedGroups.length > 0 && (
                <TooltipNew
                  title={t('EDITION')}
                  css={{ display: 'inline-block', marginLeft: '24px' }}
                >
                  <Button
                    onClick={() => {
                      setOpenGroupMultiSelect(!openGroupMultiSelect)
                      if (!groupsResp.called) {
                        groupsQuery()
                      }
                    }}
                  >
                    <Icon
                      type="editSimple"
                      color={color('text-bk-50')}
                      hoverColor="resily-orange-100"
                      css={{
                        width: '14px',
                        height: '14px',
                      }}
                    />
                  </Button>
                </TooltipNew>
              )}
            </div>
            {groupsResp.called && !groupsResp.loading && openGroupMultiSelect ? (
              <div ref={multiSelectRef} css={{ position: 'absolute', zIndex: 10 }}>
                {groupsResp.data && groupsResp.data.groups.length === 0 ? (
                  <Card css={{ width: '300px', textAlign: 'center' }}>
                    <StyledText
                      size="small"
                      color="text-bk-100"
                      weight="normal"
                      css={{ marginBottom: '28px' }}
                    >
                      {t('X_IS_EMPTY', { x: t('GROUP_TAG') })}
                      <br />
                      {t('CREATE_A_NEW_GROUP')}
                    </StyledText>
                    <a
                      href={user?.admin ? groupsSettingAdmin : admin}
                      target="_blank"
                      rel="noopener noreferrer"
                      css={{
                        textDecoration: 'none',
                        color: color('text-bk-50'),
                        ':hover': { color: color('resily-orange-100') },
                      }}
                    >
                      <div
                        css={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Icon
                          type="plus"
                          css={{ height: '12px', width: '12px', marginRight: '8px' }}
                        />
                        <StyledText>{t('CREATE_GROUP')}</StyledText>
                      </div>
                    </a>
                  </Card>
                ) : (
                  <MultiSelect
                    canSearch
                    onBlurFromMutliSelect={() => setOpenGroupMultiSelect(false)}
                    searchPlaceholder={t('INPUT_X', { x: t('GROUP_NAME') })}
                    options={groupOptionList}
                    onClickOption={(id: string) => {
                      if (attachedGroups.some((g) => g.id === id)) {
                        detachGroupMutation(objective.id, objective.owner.id, id)
                      } else {
                        attachGroupMutation(objective.id, objective.owner.id, id)
                      }
                    }}
                  >
                    <a
                      href={user?.admin ? groupsSettingAdmin : admin}
                      target="_blank"
                      rel="noopener noreferrer"
                      css={{
                        textDecoration: 'none',
                        height: '44px',
                        paddingLeft: '32px',
                        display: 'flex',
                        alignItems: 'center',
                        color: color('text-bk-50'),
                        ':hover': {
                          color: color('resily-orange-100'),
                        },
                      }}
                    >
                      <StyledText>{t('ADMIN_GROUPS_SETTING_PAGE_TITLE')}</StyledText>
                    </a>
                  </MultiSelect>
                )}
              </div>
            ) : null}
          </div>
        </Box>
        {attachedGroups.length === 0 ? (
          <div css={{ flexDirection: 'row' }}>
            <TextButton
              icon="plus"
              color="text-bk-30"
              padding="none"
              onClick={() => {
                setOpenGroupMultiSelect(!openGroupMultiSelect)
                if (!groupsResp.called) {
                  groupsQuery()
                }
              }}
            >
              {t('ADD_X', { x: t('GROUP_TAG') })}
            </TextButton>
          </div>
        ) : (
          <GroupTags groups={attachedGroups} />
        )}
      </Section>
      {/* objective description */}
      <Section>
        <div
          css={{
            marginTop: 8,
          }}
        >
          <OkrDrawerEditor
            css={{ margin: 8 }}
            editorId={`drawer-o-${objective.id}`}
            treeJson={objective.description?.treeJson || ''}
            plainText={objective.description?.plainText || ''}
            attachments={objective.attachments}
            onUpdateDescription={updateObjectiveDescription}
            referUrl={generateObjective(okrNodeId, objective.id)}
            label={t('OBJECTIVE_BACKGROUND')}
            ref={okrDrawerEditorRef}
            onOkrFloatDrawerOpen={openOkrFloatDrawer}
            helpText={t('TARGET_BACKGROUND_HELP_MESSAGE')}
          />
        </div>
      </Section>
    </Box>
  )
}
ObjectiveDescription.displayName = 'ObjectiveDescription'

const Section: React.FC = ({ children }) => <Box flex={false}>{children}</Box>
