import { useParams, useLocation, useNavigate } from 'react-router-dom'

import { BreadcrumbParams } from '../../components/domain/Breadcrumb'
import { useTranslation } from '../../i18n'
import { useOkrTermLoadable } from '../../lib/domain/useOkrTermLoadable'
import { Screen } from '../../lib/screen'
import { useTracking } from '../../lib/tracking'
import { AuthRouteProps } from '../../types/authRouteProps'
import {
  actionPlan as actionPlanPath,
  generateActionPlan,
  generateActionPlanEdit,
  generateKeyResultHash,
  generateOkr,
  makeDrawerSearchParams,
} from '../../urls'

import {
  ActionPlanFragment,
  useDeleteActionPlanMutation,
  useFindActionPlanByIdQuery,
} from './graphql'

export const useInjection = ({
  onOkrTermLoaded,
}: AuthRouteProps): null | {
  actionPlan: ActionPlanFragment
  deleteActionPlan: (id: string) => void
  breadcrumbs: BreadcrumbParams
  onActionPlanEdit: () => void
} => {
  const { actionPlanId } = useParams()
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const { data } = useFindActionPlanByIdQuery({
    variables: { id: actionPlanId ?? '' },
    skip: actionPlanId == null,
  })
  const [deleteActionPlan] = useDeleteActionPlanMutation()
  useTracking(t('ACTION_PLAN_PAGE_TITLE'), Screen.ActionPlanDetail)

  useOkrTermLoadable(
    onOkrTermLoaded,
    undefined,
    data?.findActionPlanById.keyResult.objective.node.term.id,
  )

  if (!data) {
    return null
  }

  // FIXME: 古いURLサポートのためのリダイレクト処理
  if (location.pathname.match(/^\/action-plans\/.*/)) {
    navigate(
      generateActionPlan(
        data.findActionPlanById.keyResult.objective.node.id,
        data.findActionPlanById.keyResult.id,
        actionPlanId ?? '',
      ),
      { replace: true },
    )
  }

  const deleteActionPlanMutation = async (id: string) => {
    await deleteActionPlan({ variables: { id } })
    const { keyResult } = data.findActionPlanById
    const params = makeDrawerSearchParams('keyResult', 'actionPlan', keyResult.id).toString()
    navigate(
      `${generateOkr(keyResult.objective.node.id)}?${params}#${generateKeyResultHash(
        keyResult.id,
      )}`,
    )
  }

  const actionPlan = data.findActionPlanById

  const breadcrumbs: BreadcrumbParams = {
    url: actionPlanPath,
    items: [
      {
        breadcrumbName: 'okr',
        id: actionPlan.keyResult.objective.node.id,
        objective: {
          id: actionPlan.keyResult.objective.id,
          name: actionPlan.keyResult.objective.name,
        },
      },
      {
        breadcrumbName: 'keyResult',
        id: actionPlan.keyResult.id,
        name: actionPlan.keyResult.name,
        node: {
          id: actionPlan.keyResult.objective.node.id,
        },
      },
      {
        breadcrumbName: 'actionPlans',
        ...actionPlan.keyResult,
        id: actionPlan.keyResult.id,
        node: {
          id: actionPlan.keyResult.objective.node.id,
        },
      },
      {
        breadcrumbName: 'actionPlan',
        ...actionPlan,
        keyResult: {
          ...actionPlan.keyResult,
          id: actionPlan.keyResult.id,
          node: {
            id: actionPlan.keyResult.objective.node.id,
          },
        },
      },
    ],
  }

  const onActionPlanEdit = () => {
    navigate(
      generateActionPlanEdit(
        actionPlan.keyResult.objective.node.id,
        actionPlan.keyResult.id,
        actionPlan.id,
      ),
    )
  }

  return {
    actionPlan,
    deleteActionPlan: deleteActionPlanMutation,
    breadcrumbs,
    onActionPlanEdit,
  }
}
