import { HocuspocusProvider } from '@hocuspocus/provider'
import { withCursors, withYHistory, withYjs, YjsEditor } from '@slate-yjs/core'
import {
  createTEditor,
  PlateEditor,
  PlatePlugin,
  PlateProps,
  serializeHtml,
  usePlateEditorRef,
  usePlateSelectors,
  Value,
  withPlate,
} from '@udecode/plate'
import randomColor from 'randomcolor'
import { FocusEventHandler, ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { useDebounce, usePrevious } from 'react-use'
import { Editor } from 'slate'
import * as Y from 'yjs'

import { isProduction } from '../../../config'
import { useCurrentUser } from '../../../contexts/UserContext'
import { OpenReplayTrackerSingleton as OpenReplayTracker } from '../../../lib/openReplay/openReplay'

import { RemoteCursorOverlay } from './PlateEditor/components/RemoteCursorOverlay'
import { CursorData } from './PlateEditor/plate.extension'
import { serializeMarkdown } from './PlateEditor/plugins/serializer/markdown'
import { serializeText } from './PlateEditor/plugins/serializer/text'

export type CollaborationPlugin = {
  id: string
  plugins: Array<PlatePlugin>
  initialValue: Value
  onlineMode?: {
    // WebSocketのエンドポイントURL
    webSocketEndpoint: string
    // 共同編集時に表示する他者のカーソル位置のユーザー名
    userName: string
  }
  autoSave: boolean
  autoFocus: boolean
  onBlur?: HandleBlurFn
  onSave: (json: string, plainText: string, html: string, markdown: string) => void
}

// 戻り値のrenderEditableのシグネチャ
type RenderEditableFn = PlateProps['renderEditable']

// 戻り値のonBlurのシグネチャ
type HandleBlurFn = FocusEventHandler<HTMLDivElement>

/**
 * リッチテキストエディタに共同編集機能を提供するためのカスタムhooks
 *
 * @returns onlineModeが有効であれば共同編集モードのエディタインスタンスを返却
 * onlineModeがundefinedあれば共同編集モードでないエディタインスタンスを返却します
 */
export const useCollaboration = ({
  id,
  plugins,
  initialValue,
  onlineMode,
  autoSave,
  onBlur,
  onSave,
}: CollaborationPlugin): [PlateEditor | null, boolean, boolean, RenderEditableFn, HandleBlurFn] => {
  const { webSocketEndpoint, userName } = onlineMode || {}
  const [connected, setConnected] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const plateEditor = usePlateEditorRef(id)
  const currentUser = useCurrentUser()
  const tracker = OpenReplayTracker.getTracker()

  const provider = useMemo(
    () =>
      webSocketEndpoint
        ? new HocuspocusProvider({
            url: webSocketEndpoint,
            name: id,
            token: btoa(encodeURIComponent(JSON.stringify(initialValue))),
            connect: false,
            quiet: isProduction(),
            onAuthenticationFailed: (data) => {
              const { reason } = data
              tracker?.logEvent(`ws-auth-failed-${id}`, {
                url: webSocketEndpoint,
                reason,
              })
            },
            onConnect: () => {
              tracker?.logEvent(`ws-connect-${id}`, { url: webSocketEndpoint })
            },
            onDisconnect: (data) => {
              const { event } = data
              tracker?.logEvent(`ws-disconnect-${id}`, {
                url: webSocketEndpoint,
                code: event.code,
                reason: event.reason,
              })
            },
          })
        : undefined,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id, initialValue, webSocketEndpoint],
  )

  /**
   * 共同編集か否かでエディタのインスタンス生成を変更する
   * 共同編集時はYjsにbindingされたエディタインスタンスを生成する
   */
  const editor = useMemo(() => {
    if (!provider) {
      return plateEditor
    }

    const cursorData: CursorData = {
      color: randomColor({
        luminosity: 'dark',
        alpha: 1,
        format: 'hex',
      }),
      id: currentUser?.id ?? '',
      name: userName ?? '',
    }
    const sharedRoot = provider.document.get('content', Y.XmlText) as Y.XmlText
    return withPlate(
      withYHistory(
        withCursors(
          withYjs(createTEditor() as Editor, sharedRoot, { autoConnect: false }),
          provider.awareness,
          {
            data: cursorData,
          },
        ),
      ) as PlateEditor,
      {
        id,
        plugins,
        disableCorePlugins: {
          history: true,
        },
      },
    )
  }, [currentUser, id, plateEditor, plugins, provider, userName])

  provider?.on('connect', () => {
    setConnected(true)
  })

  provider?.on('disconnect', () => {
    setConnected(false)
    // NOTE: 現在tokenを用いて共同編集用サーバーにエディタのinitialValueを渡しているが
    //       再接続時もtokenを渡すため、サーバー側のインメモリに存在するドキュメント情報が空だった場合、
    //       e.g.) 共同編集用サーバーのrestartやスケールアウト時
    //       ドキュメント情報無しとしてinitialValueを再セットしてしまいドキュメントのコンテンツが重複してしまう
    //       そのため接続が切れた際にはproviderに渡しているtokenを一度空とすることで共同編集用サーバー側で発生する
    //       上記問題を解消している。
    //       しかし、この対応はtokenによって認証する実装が未実装が故に出来ているため、tokenによる認証が実装されたタイミングで
    //       この修正方法も変更する必要がある。
    provider.setConfiguration({ token: btoa(encodeURIComponent('none')) })
  })

  /**
   * RemoteCursorOverlayを差し込みたいので
   * Plateに対してrenderEditableを渡せるようにする
   */
  const renderEditable = useCallback(
    (editable: ReactNode) => {
      if (!provider) {
        return editable
      }
      return <RemoteCursorOverlay>{editable}</RemoteCursorOverlay>
    },
    [provider],
  )

  /**
   * 共同編集時の自動保存機能
   * デバウンスしている
   */
  const val = usePlateSelectors(id).value()
  const prevVal = usePrevious(val)
  useDebounce(
    () => {
      if (
        (provider != null || autoSave) &&
        val &&
        val.length > 0 &&
        prevVal &&
        prevVal.length > 0 &&
        val !== prevVal &&
        editor
      ) {
        setIsSubmitting(true)
        onSave(
          JSON.stringify(val),
          serializeText(val),
          serializeHtml(editor, { nodes: val }),
          serializeMarkdown(val),
        )
        setIsSubmitting(false)
      }
    },
    1000,
    [val, provider, autoSave],
  )

  const handleBlur = useCallback<HandleBlurFn>((e) => {
    if (onBlur) {
      onBlur(e)
    }
    // 共同編集時でない場合もあるのでawarenessの存在確認は行う
    if (editor?.awareness != null) {
      editor.awareness.setLocalStateField(editor.selectionStateField, null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!provider) {
      return () => {}
    }
    provider.connect()
    return () => provider.disconnect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!provider || !YjsEditor.isYjsEditor(editor)) {
      return () => {}
    }
    YjsEditor.connect(editor)
    return () => YjsEditor.disconnect(editor)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return [editor, connected, isSubmitting, renderEditable, handleBlur]
}
