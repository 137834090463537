import { Spinner } from 'grommet'

export const OkrDrawerLoadingContent: React.VFC = () => (
  <div
    style={{
      display: 'flex',
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Spinner />
  </div>
)

OkrDrawerLoadingContent.displayName = 'OkrDrawerLoadingContent'
