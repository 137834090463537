import { FormValues } from '../../../../../form/formSchema'
import { TargetSettingType } from '../../../../../graphql/graphql'
import { Props as ActivityProps } from '../Activity/types'

type Args = {
  keyResults: FormValues['keyResults']
}

type Return = ReadonlyArray<{
  keyResultId: string
  objectiveName: string
  keyResultName: string
  targetSetting: TargetSettingType
  keyResultEvents: ActivityProps['keyResultEvents']
  keyResultComments: ActivityProps['keyResultComments']
  lastKeyResultComments: ActivityProps['keyResultComments']
}>

export const transformFormValues = ({ keyResults }: Args): Return =>
  keyResults.map<Return[number]>(
    ({
      keyResultId,
      objectiveName,
      keyResultName,
      actualValue,
      level,
      initialValue,
      targetValue,
      targetSetting,
      unit,
      beforeActualValue,
      beforeLevel,
      beforeInitialValue,
      beforeTargetValue,
      beforeUnit,
      updateReason,
      priority,
      problem,
      winSession,
      other,
      lastPriority,
      lastProblem,
      lastWinSession,
      lastOther,
      isCurrentPriorityComment,
      isCurrentProblemComment,
      isCurrentWinSessionComment,
      isCurrentOtherComment,
    }) => ({
      keyResultId,
      objectiveName,
      keyResultName,
      targetSetting,
      keyResultEvents: {
        afterActualValue: actualValue,
        afterConfidenceLevel: level,
        afterInitialValue: initialValue,
        afterTargetValue: targetValue,
        afterUnit: unit,
        beforeActualValue,
        beforeConfidenceLevel: beforeLevel,
        beforeInitialValue,
        beforeTargetValue,
        beforeUnit,
        updateReason,
      },
      keyResultComments: {
        priority: {
          plainText: priority?.plainText ?? '',
          treeJson: priority?.treeJson ?? '',
          isCurrentComment: isCurrentPriorityComment,
        },
        winSession: {
          plainText: winSession?.plainText ?? '',
          treeJson: winSession?.treeJson ?? '',
          isCurrentComment: isCurrentWinSessionComment,
        },
        problem: {
          plainText: problem?.plainText ?? '',
          treeJson: problem?.treeJson ?? '',
          isCurrentComment: isCurrentProblemComment,
        },
        other: {
          plainText: other?.plainText ?? '',
          treeJson: other?.treeJson ?? '',
          isCurrentComment: isCurrentOtherComment,
        },
      },
      lastKeyResultComments: {
        priority: {
          plainText: lastPriority?.plainText ?? '',
          treeJson: lastPriority?.treeJson ?? '',
          isCurrentComment: isCurrentPriorityComment,
        },
        problem: {
          plainText: lastProblem?.plainText ?? '',
          treeJson: lastProblem?.treeJson ?? '',
          isCurrentComment: isCurrentProblemComment,
        },
        winSession: {
          plainText: lastWinSession?.plainText ?? '',
          treeJson: lastWinSession?.treeJson ?? '',
          isCurrentComment: isCurrentWinSessionComment,
        },
        other: {
          plainText: lastOther?.plainText ?? '',
          treeJson: lastOther?.treeJson ?? '',
          isCurrentComment: isCurrentOtherComment,
        },
      },
    }),
  )
