import { css } from '@emotion/react'
import { t } from 'i18next'
import { v1 as uuid } from 'uuid'

import { useCurrentUser } from '../../../contexts/UserContext'
import { color } from '../../../styles/newColors'

import { CheckinCommentItem } from './CheckinCommentItem'
import { DocumentInput, KeyResultSharedItemType } from './graphql'
import { CheckinCommentWithUser } from './types'

const checkinCommentsWrapperCss = css({
  width: '100%',
  padding: '10px 0 10px 28px',
  fontSize: '14px',
  lineHeight: '14px',
  backgroundColor: color('hover-background-bk-5'),
  fontWeight: 'bold',
  borderTop: `1px solid ${color('border-bk-10')}`,
  borderBottom: `1px solid ${color('border-bk-10')}`,
})

const commentDividerCss = css({
  '&:not(:last-child)': {
    borderBottom: `1px solid ${color('border-bk-10')}`,
  },
})

type Props = {
  termId: string
  commentType: KeyResultSharedItemType
  comments: ReadonlyArray<CheckinCommentWithUser>
  drawer?: boolean
  onClickSave: (
    checkinKeyResultId: string,
    itemType: KeyResultSharedItemType,
    comment: DocumentInput,
  ) => void
  onClickCancel: (checkinKeyResultId: string) => void
}

const translateCommentType = (commentType: KeyResultSharedItemType): string => {
  switch (commentType) {
    case KeyResultSharedItemType.Priority:
      return t('PRIORITY')
    case KeyResultSharedItemType.Problem:
      return t('PROBLEM')
    case KeyResultSharedItemType.WinSession:
      return t('WIN_SESSION')
    case KeyResultSharedItemType.Other:
      return t('OTHER')
    default:
      throw new Error(`Unknown comment type: ${commentType}`)
  }
}

export const CheckinCommentsWrapper: React.FC<Props> = ({
  termId,
  commentType,
  comments,
  drawer,
  onClickSave,
  onClickCancel,
}) => {
  const user = useCurrentUser()

  return (
    <div>
      <div css={checkinCommentsWrapperCss}>{translateCommentType(commentType)}</div>
      {comments.map((comment) => (
        <div key={comment.id} css={commentDividerCss}>
          <CheckinCommentItem
            id={comment.id || uuid()}
            termId={termId}
            checkinUser={comment.checkinUser}
            createdAt={comment.createdAt}
            comment={comment.body}
            canEdit={user?.id === comment.checkinUser.userId}
            onClickSave={(name: DocumentInput) =>
              onClickSave(comment.checkinKeyResultId, commentType, name)
            }
            onClickCancel={() => onClickCancel(comment.checkinKeyResultId)}
            drawer={drawer}
          />
        </div>
      ))}
    </div>
  )
}
