import { Matrix } from './matrix.d'
import { Point as PointOrig } from './point.d'

export type Point = PointOrig

export const isPoint = (p: Record<string, unknown>): p is Point =>
  p.x !== undefined && p.y !== undefined

export const zero = (): Point => ({ x: 0, y: 0 })

export const isZero = ({ x, y }: Point): boolean => x === 0 && y === 0

export const isEqual = (p1: Point, p2: Point): boolean => p1.x === p2.x && p1.y === p2.y

export const add = (p1: Point, p2: number | Point): Point =>
  typeof p2 === 'number'
    ? {
        x: p1.x + p2,
        y: p1.y + p2,
      }
    : {
        x: p1.x + p2.x,
        y: p1.y + p2.y,
      }

export const sub = (p1: Point, p2: number | Point): Point =>
  typeof p2 === 'number'
    ? {
        x: p1.x - p2,
        y: p1.y - p2,
      }
    : {
        x: p1.x - p2.x,
        y: p1.y - p2.y,
      }

export const mul = (p1: Point, p2: number | Point): Point =>
  typeof p2 === 'number'
    ? {
        x: p1.x * p2,
        y: p1.y * p2,
      }
    : {
        x: p1.x * p2.x,
        y: p1.y * p2.y,
      }

export const div = (p1: Point, p2: number | Point): Point =>
  typeof p2 === 'number'
    ? {
        x: p1.x / p2,
        y: p1.y / p2,
      }
    : {
        x: p1.x / p2.x,
        y: p1.y / p2.y,
      }

export const mod = (p1: Point, p2: number | Point): Point =>
  typeof p2 === 'number'
    ? {
        x: p1.x % p2,
        y: p1.y % p2,
      }
    : {
        x: p1.x % p2.x,
        y: p1.y % p2.y,
      }

export const pow = (p1: Point, p2: number | Point): Point =>
  typeof p2 === 'number'
    ? {
        x: p1.x ** p2,
        y: p1.y ** p2,
      }
    : {
        x: p1.x ** p2.x,
        y: p1.y ** p2.y,
      }

export const inc = (p: Point): Point => add(p, 1)
export const dec = (p: Point): Point => sub(p, 1)

export const interp = (from: Point, to: Point, ratio: number): PointOrig =>
  add(from, mul(sub(to, from), ratio))

export const center = (p1: Point, p2: Point): Point => interp(p1, p2, 0.5)

export const ceil = ({
  x,
  y,
}: Point): {
  x: number
  y: number
} => ({
  x: Math.ceil(x),
  y: Math.ceil(y),
})

export const round = ({
  x,
  y,
}: Point): {
  x: number
  y: number
} => ({
  x: Math.round(x),
  y: Math.round(y),
})

export const floor = ({
  x,
  y,
}: Point): {
  x: number
  y: number
} => ({
  x: Math.floor(x),
  y: Math.floor(y),
})

const Max = { x: Infinity, y: Infinity }
const Min = { x: -Infinity, y: -Infinity }

export const min = (...pts: ReadonlyArray<Point>): PointOrig =>
  pts.reduce(
    (m, p) => ({
      x: Math.min(m.x, p.x),
      y: Math.min(m.y, p.y),
    }),
    Max,
  )

export const max = (...pts: ReadonlyArray<Point>): PointOrig =>
  pts.reduce(
    (m, p) => ({
      x: Math.max(m.x, p.x),
      y: Math.max(m.y, p.y),
    }),
    Min,
  )

export const toString = ({ x, y }: Point): string => `${x} ${y}`

export const toArray = ({ x, y }: Point): [number, number] => [x, y]

/**
 * affine は座標 p を行列 m でアフィン変換します。
 *
 * | a c e | | x |   | a*x+c*y+e |
 * | b d f | | y | = | b*x+d*y+f |
 * | 0 0 1 | | 1 |   | 1         |
 */
export const affine = (p: Point, m: Matrix): Point => ({
  x: m.a * p.x + m.c * p.y + m.e,
  y: m.b * p.x + m.d * p.y + m.f,
})
