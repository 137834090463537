import { PlatePluginComponent } from '@udecode/plate'
import { useFocused, useSelected } from 'slate-react'

import { HrElement } from '../../types'

export const HorizontalElement: PlatePluginComponent<
  HrElement & {
    attributes: Record<string, unknown>
  }
> = ({ attributes, children }) => {
  const selected = useSelected()
  const focused = useFocused()

  return (
    <div {...attributes} contentEditable={false}>
      <hr
        data-focused={selected && focused}
        css={{
          width: '100%',
          height: '1px',
          margin: '16px 0',
          border: 'none',
          backgroundColor: '#E9EAEC',
          boxShadow: 'none',
          '&[data-focused="true"]': { boxShadow: '0 0 0 3px #B4D5FF' },
        }}
      />
      {children}
    </div>
  )
}
