import { Fragment, useRef } from 'react'
import { Editor } from 'slate'
import { ReactEditor } from 'slate-react'

import { Type as IconType } from '../../../../../Icon'
import { insertImage } from '../../plugins/elements/Image/transforms'

import { MenuButton } from './MenuButton'

type Props = {
  editor: Editor
  imageUploader: (file: File) => Promise<string>
  text: string
  icon: IconType
  onClose: () => void
}

export const ImageButton: React.FC<Props> = ({ editor, imageUploader, text, icon, onClose }) => {
  const uploaderRef = useRef<HTMLInputElement>(null)

  return (
    <Fragment>
      <input
        type="file"
        accept="image/*"
        ref={uploaderRef}
        onChange={(e) => {
          if (e.currentTarget.files && e.currentTarget.files.length > 0) {
            for (let i = 0; i < e.currentTarget.files.length; i += 1) {
              imageUploader(e.currentTarget.files[i]).then((url) => {
                insertImage(editor, url)
              })
            }
          }
          onClose()

          // TODO: エディタ外をクリックした際、フォーカスを復元するためのバグ回避
          // see: https://github.com/ianstormtaylor/slate/issues/3412
          if (editor.selection) {
            ReactEditor.focus(editor)
          }
        }}
        style={{ display: 'none' }}
      />
      <MenuButton
        onMouseDown={(e) => {
          e.preventDefault()
          if (uploaderRef.current) {
            uploaderRef.current.click()
          }
        }}
        icon={icon}
        text={text}
      />
    </Fragment>
  )
}

ImageButton.displayName = 'ImageButton'
