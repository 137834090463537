import {
  CaretPosition,
  CursorOverlayState,
  useRemoteCursorOverlayPositions,
} from '@slate-yjs/react'
import { FC, useRef, VFC } from 'react'

import { useCurrentUser } from '../../../../../../contexts/UserContext'
import { CursorData } from '../../plate.extension'

type CaretProps = {
  position: CaretPosition
  data: CursorData
}

const Caret: VFC<CaretProps> = ({ position, data }) => (
  <div
    css={{
      ...position,
      position: 'absolute',
      width: '0.125rem',
      background: data.color,
    }}
  >
    <div
      css={{
        position: 'absolute',
        top: 0,
        fontSize: '0.75rem',
        lineHeight: '1rem',
        whiteSpace: 'nowrap',
        color: 'white',
        background: data.color,
        transform: 'translateY(-100%)',
        padding: '0.125rem 0.375rem',
        borderRadius: '0.25rem',
        borderBottomLeftRadius: 0,
      }}
    >
      {data.name}
    </div>
  </div>
)

const RemoteSelection: VFC<CursorOverlayState<CursorData>> = ({
  data,
  selectionRects,
  caretPosition,
}) => {
  if (data == null) {
    return null
  }

  return (
    <>
      {selectionRects.map((position, i) => (
        <div
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          css={{
            position: 'absolute',
            pointerEvents: 'none',
            backgroundColor: `${data.color}66`,
            ...position,
          }}
        />
      ))}
      {caretPosition != null && <Caret position={caretPosition} data={data} />}
    </>
  )
}

export const RemoteCursorOverlay: FC = ({ children }) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const { cursors } = useRemoteCursorOverlayPositions<CursorData>({
    containerRef,
  })
  const currentUser = useCurrentUser()

  return (
    <div ref={containerRef} css={{ position: 'relative' }}>
      {children}
      {cursors.map((cursor) => {
        if (cursor.data?.id === currentUser?.id) {
          return null
        }
        return <RemoteSelection key={cursor.clientId} {...cursor} />
      })}
    </div>
  )
}
