import { useReducer, Dispatch } from 'react'

import { File } from '../../../../graphql'
import { TargetSettingType } from '../graphql'
import { KeyResultEditFieldsInput } from '../types'

export type Action =
  | {
      type: 'name'
      payload: string
    }
  | {
      type: 'ownerId'
      payload: string
    }
  | {
      type: 'targetValue'
      payload: number
    }
  | {
      type: 'actualValue'
      payload: number
    }
  | {
      type: 'initialValue'
      payload: number
    }
  | {
      type: 'unit'
      payload: string
    }
  | {
      type: 'message'
      payload: string
    }
  | {
      type: 'confidence'
      payload: number
    }
  | {
      type: 'description'
      payload: {
        treeJson: string
        plainText: string
      }
    }
  | {
      type: 'attachmentViews'
      payload: ReadonlyArray<File>
    }
  | {
      type: 'memberIds'
      payload: ReadonlyArray<string>
    }
  | {
      type: 'targetSetting'
      payload: TargetSettingType
    }

const changeKeyResult =
  <T extends KeyResultEditFieldsInput>(onChangeKeyResult: (keyResult: T) => void) =>
  (state: T, action: Action) => {
    let nextState = state
    switch (action.type) {
      case 'name':
      case 'ownerId':
      case 'targetValue':
      case 'actualValue':
      case 'initialValue':
      case 'unit':
      case 'message':
      case 'confidence':
      case 'description':
      case 'attachmentViews':
      case 'memberIds':
      case 'targetSetting':
        nextState = { ...state, [action.type]: action.payload }
        break
      default:
        break
    }
    onChangeKeyResult(nextState)
    return nextState
  }

export const useKeyResultReducer = <T extends KeyResultEditFieldsInput>(
  initialKeyResult: T,
  onChangeKeyResult: (keyResult: T) => void,
): [T, Dispatch<Action>] => useReducer(changeKeyResult(onChangeKeyResult), initialKeyResult)
