import { useCallback, useContext } from 'react'

import { OrganizationContext } from '../../../../contexts/OrganizationContext'

import { DomainTableList } from './DomainTableList'
import { useUpdateOauthWhitelistMutation } from './graphql'

export const AdminDomainWhitelistContainer: React.FC = () => {
  const organization = useContext(OrganizationContext)
  const [updateOauthWhitelist] = useUpdateOauthWhitelistMutation()
  const onClickSave = useCallback(
    (oauthWhitelist) => updateOauthWhitelist({ variables: { whitelist: oauthWhitelist } }),
    [updateOauthWhitelist],
  )

  if (!organization) return null

  return <DomainTableList defaultList={organization?.oauthWhitelist} onClickSave={onClickSave} />
}

AdminDomainWhitelistContainer.displayName = 'AdminDomainWhitelistContainer'
