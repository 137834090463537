export const DRAWER_TAB_LIST = [
  'info',
  'weight',
  'note',
  'actionPlan',
  'confidence',
  'event',
  'setting',
  'okrMeeting',
  'empty',
] as const

export type DrawerTab = typeof DRAWER_TAB_LIST[number]
export const isDrawerTab = (str: string): str is DrawerTab =>
  DRAWER_TAB_LIST.some((drawerTabItem) => drawerTabItem === str)

export type DrawerType = 'objective' | 'keyResult'
export const isDrawerType = (str: unknown): str is DrawerType =>
  str === 'objective' || str === 'keyResult'
