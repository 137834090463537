import { useModal } from '@resily/geisha'
import { useCallback, useMemo } from 'react'

import { useTranslation } from '../../../i18n'
import { StyledText } from '../../ui/StyledText'

export type Type = 'CREATE' | 'ACTIVATE' | 'DEACTIVATE'

type ModalFC = React.VFC<{ onConfirm: () => void }>

export const useWeightSettingResetAlertModal = (
  type: Type,
  DO_NOT_USE_EXPECT_FOR_TEST?: boolean,
): [ModalFC, () => void] => {
  const { t } = useTranslation()

  const texts: { [key in Type]: { title: string; message: string; confirmLabel: string } } =
    useMemo(
      () => ({
        CREATE: {
          title: t('ADD_X', { x: t('KEY_RESULT') }),
          message: t('CONFIRMATION_OF_ADD_KEY_RESULT'),
          confirmLabel: t('ADD'),
        },
        ACTIVATE: {
          title: t('ACTIVATING_X', { x: t('KEY_RESULT') }),
          message: t('CONFIRMATION_OF_ENABLE_KEY_RESULT'),
          confirmLabel: t('ACTIVATION'),
        },
        DEACTIVATE: {
          title: t('DISABLING_X', { x: t('KEY_RESULT') }),
          message: t('CONFIRMATION_OF_DISABLE_KEY_RESULT'),
          confirmLabel: t('DISABLING'),
        },
      }),
      [t],
    )

  const [Modal, isOpen, { open, close }] = useModal(DO_NOT_USE_EXPECT_FOR_TEST)

  const WeightSettingResetAlertModal = useCallback<ModalFC>(
    ({ onConfirm }) => (
      <Modal size="medium" isOpen={isOpen} onClose={close}>
        <Modal.Header title={texts[type].title} />
        <Modal.Content>
          <StyledText fontStyle="regular">{texts[type].message}</StyledText>
        </Modal.Content>
        <Modal.Footer
          cancelType="tertiary"
          cancelLabel={t('CANCEL')}
          confirmLabel={texts[type].confirmLabel}
          onCancel={close}
          onConfirm={() => {
            onConfirm()
            close()
          }}
        />
      </Modal>
    ),
    [Modal, close, isOpen, t, texts, type],
  )

  return [WeightSettingResetAlertModal, open]
}
