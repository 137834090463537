import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
  ResponderProvided,
} from 'react-beautiful-dnd'

import { useTranslation } from '../../../../../../i18n'
import { FixedAgendaListFragment } from '../../../../api/graphql'

import { FixedAgendaListLoading } from './components/FixedAgendaListLoading'
import {
  FixedAgendaListRow,
  Props as FixedAgendaListRowProps,
} from './components/FixedAgendaListRow'
import { fixedAgendaRowsWrapperCss, emptyCss } from './styles'

export type Props = {
  loading: boolean
  data?: ReadonlyArray<FixedAgendaListFragment>
  reOrder: (id: string, input: number) => void
  onDeleteFixedAgenda: FixedAgendaListRowProps['onDeleteFixedAgenda']
  onCopyFixedAgenda: FixedAgendaListRowProps['onCopyFixedAgenda']
}

export const FixedAgendaList: React.VFC<Props> = ({
  loading,
  data: fixedAgendasData,
  reOrder,
  onDeleteFixedAgenda,
  onCopyFixedAgenda,
}) => {
  const { t } = useTranslation()
  if (loading && !fixedAgendasData) {
    return <FixedAgendaListLoading />
  }

  const handleOnDragEnd = (result: DropResult, _: ResponderProvided) => {
    if (!result.destination || !fixedAgendasData) return
    // 動かしたアイテム(元の場所)
    const source = result.source.index
    // 動かしたアイテム(移動先)
    const destination = result.destination.index
    if (source === destination) return

    reOrder(result.draggableId, fixedAgendasData[destination].index)
  }

  return fixedAgendasData && fixedAgendasData.length > 0 ? (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps} css={fixedAgendaRowsWrapperCss}>
            {fixedAgendasData.map((fixedAgenda, index) => (
              <Draggable key={fixedAgenda.id} draggableId={fixedAgenda.id} index={index}>
                {(p) => (
                  <FixedAgendaListRow
                    fixedAgenda={fixedAgenda}
                    onDeleteFixedAgenda={onDeleteFixedAgenda}
                    onCopyFixedAgenda={onCopyFixedAgenda}
                    draggableProvided={p}
                  />
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  ) : (
    <p css={emptyCss}>{t('EMPTY_ONEONONE_FIXED_AGENDA')}</p>
  )
}
