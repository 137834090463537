import { css } from '@emotion/react'
import { FC } from 'react'

import { Icon } from '../../../components/ui/Icon'
import { color } from '../../../styles/newColors'

const iconCss = css({
  transition: 'transform 0.2s ease-out',
  ':hover': {
    path: {
      fill: color('resily-orange-100'),
      stroke: color('resily-orange-100'),
    },
  },
  '&[data-isfold="true"]': {
    transform: 'rotate(-90deg)',
  },
})

export type Props = JSX.IntrinsicElements['button'] & {
  isFold: boolean
}

export const KrFoldButton: FC<Props> = ({ isFold, children, ...props }) => (
  <button type="button" {...props}>
    <div css={iconCss} data-isfold={isFold}>
      <Icon type="mdiKeyboardArrowDown" color={color('text-bk-30')} width={24} height={24} />
    </div>
    {children}
  </button>
)

KrFoldButton.displayName = 'KrFoldButton'
