import { css } from '@emotion/react'
import React, { FormHTMLAttributes } from 'react'

import { SubmitButton } from './components/FormSubmitButton'
import { TitleSection } from './components/FormTitleSection'

const formCss = css({
  '> button[type=submit]': {
    marginTop: '12px',
  },
})

type FormChildrenTypes = {
  TitleSection: typeof TitleSection
  SubmitButton: typeof SubmitButton
}

type Props = {
  action: FormHTMLAttributes<HTMLFormElement>['action']
  children: React.ReactNode
}

export const Form: React.VFC<Props> & FormChildrenTypes = ({ action, children }) => (
  <form method="POST" action={action} css={formCss}>
    {children}
  </form>
)

Form.TitleSection = TitleSection
Form.SubmitButton = SubmitButton
