import { TFunction, useTranslation } from '../../../i18n'
import { fontSize } from '../../../styles/font'
import { color } from '../../../styles/newColors'
import * as urls from '../../../urls'
import { Link } from '../../ui/Link'
import { KeyResultNameBreadcrumb } from '../KeyResultNameBreadcrumb'
import { ObjectiveName } from '../ObjectiveName'

import {
  TermFragment,
  OkrNodeFragment,
  OkrNodeOkrTermFragment,
  ObjectiveFragment,
  KeyResultFragment,
  ActionPlanFragment,
  UserFragment,
  GroupFragment,
  NoteFragment,
  PersonalNoteFragment,
} from './graphql'

const fail = (message: never): never => {
  throw new Error(message)
}

type BreadcrumbName =
  | 'tree'
  | 'treeAndNode'
  | 'users'
  | 'user'
  | 'homeNote'
  | 'okr'
  | 'objective'
  | 'objectiveDetail'
  | 'objectiveEdit'
  | 'keyResult'
  | 'keyResultDetail'
  | 'keyResultEdit'
  | 'actionPlan'
  | 'actionPlans'
  | 'setting'
  | 'settingUser'
  | 'settingUsers'
  | 'importUsers'
  | 'group'
  | 'settingGroups'
  | 'importGroups'
  | 'notesOfObjective'
  | 'notesOfKeyResult'
  | 'note'
  | 'me'
  | 'personalNote'
  | 'organizationSettings'
  | 'integrationSettings'
  | 'smarthr'
  | 'okrTerms'
  | 'settingOneOnOnes'
  | 'createFixedAgenda'
  | 'editFixedAgenda'
  | 'accountSetting'
  | 'organization'
  | 'okrGlossaries'
  | 'integrations'
  | 'security'
  | 'billing'

type BreadcrumbNames<T extends BreadcrumbName> = { breadcrumbName: T }

type TreeBreadCrumb = TermFragment & BreadcrumbNames<'tree'>
type TreeAndNodeBreadCrumb = OkrNodeOkrTermFragment & BreadcrumbNames<'treeAndNode'>
type UsersBreadCrumb = TermFragment & BreadcrumbNames<'users'>
type UserBreadCrumb = UserFragment & BreadcrumbNames<'user'>
type HomeNoteBreadCrumb = { okrTermId: TermFragment['id'] } & UserFragment &
  BreadcrumbNames<'homeNote'>
type OkrBreadCrumb = OkrNodeFragment & BreadcrumbNames<'okr'>
type ObjectiveBreadCrumb = ObjectiveFragment & BreadcrumbNames<'objective'>
type ObjectiveDetailBreadCrumb = ObjectiveFragment & BreadcrumbNames<'objectiveDetail'>
type ObjectiveEditBreadCrumb = ObjectiveFragment & BreadcrumbNames<'objectiveEdit'>
type KeyResultBreadCrumb = KeyResultFragment & BreadcrumbNames<'keyResult'>
type ActionPlanBreadCrumb = ActionPlanFragment & BreadcrumbNames<'actionPlan'>
type ActionPlansBreadCrumb = KeyResultFragment & BreadcrumbNames<'actionPlans'>
type KeyResultDetailBreadCrumb = KeyResultFragment & BreadcrumbNames<'keyResultDetail'>
type KeyResultEditBreadCrumb = KeyResultFragment & BreadcrumbNames<'keyResultEdit'>
type SettingBreadCrumb = BreadcrumbNames<'setting'>
type SettingUserBreadCrumb = UserFragment & BreadcrumbNames<'settingUser'>
type SettingUsersBreadCrumb = BreadcrumbNames<'settingUsers'>
type SettingUsersImportBreadCrumb = BreadcrumbNames<'importUsers'>
type SettingGroupBreadCrumb = GroupFragment & BreadcrumbNames<'group'>
type SettingGroupsBreadCrumb = BreadcrumbNames<'settingGroups'>
type SettingGroupsImportBreadCrumb = BreadcrumbNames<'importGroups'>
type NoteBreadCrumb = NoteFragment & BreadcrumbNames<'note'>
type NotesOfObjectiveBreadCrumb = ObjectiveFragment & BreadcrumbNames<'notesOfObjective'>
type NotesOfKeyResultBreadCrumb = KeyResultFragment & BreadcrumbNames<'notesOfKeyResult'>
type PersonalNoteOfMeBreadCrumb = PersonalNoteFragment & BreadcrumbNames<'me'>
type PersonalNoteBreadCrumb = PersonalNoteFragment & BreadcrumbNames<'personalNote'>
type OrganizationSettingsBreadCrumb = BreadcrumbNames<'organizationSettings'>
type IntegrationSettingsBreadCrumb = BreadcrumbNames<'integrationSettings'>
type SmartHRSettingBreadCrumb = BreadcrumbNames<'smarthr'>
type OkrTermsBreadCrumb = BreadcrumbNames<'okrTerms'>
type SettingOneOnOneBreadCrumb = BreadcrumbNames<'settingOneOnOnes'>
type CreateFixedAgendaBreadCrumb = BreadcrumbNames<'createFixedAgenda'>
type EditFixedAgendaBreadCrumb = BreadcrumbNames<'editFixedAgenda'>
type AccountSettingBreadCrumb = BreadcrumbNames<'accountSetting'>
type OrganizationBreadCrumb = BreadcrumbNames<'organization'>
type OkrGlossariesBreadCrumb = BreadcrumbNames<'okrGlossaries'>
type IntegrationsBreadCrumb = BreadcrumbNames<'integrations'>
type SecurityBreadCrumb = BreadcrumbNames<'security'>
type BillingBreadCrumb = BreadcrumbNames<'billing'>

type BreadcrumbType =
  | TreeBreadCrumb
  | TreeAndNodeBreadCrumb
  | UsersBreadCrumb
  | UserBreadCrumb
  | HomeNoteBreadCrumb
  | OkrBreadCrumb
  | ObjectiveBreadCrumb
  | ObjectiveDetailBreadCrumb
  | ObjectiveEditBreadCrumb
  | KeyResultBreadCrumb
  | ActionPlanBreadCrumb
  | ActionPlansBreadCrumb
  | KeyResultDetailBreadCrumb
  | KeyResultEditBreadCrumb
  | SettingBreadCrumb
  | SettingUserBreadCrumb
  | SettingUsersBreadCrumb
  | SettingUsersImportBreadCrumb
  | SettingGroupBreadCrumb
  | SettingGroupsBreadCrumb
  | SettingGroupsImportBreadCrumb
  | NoteBreadCrumb
  | NotesOfObjectiveBreadCrumb
  | NotesOfKeyResultBreadCrumb
  | PersonalNoteOfMeBreadCrumb
  | PersonalNoteBreadCrumb
  | OrganizationSettingsBreadCrumb
  | IntegrationSettingsBreadCrumb
  | SmartHRSettingBreadCrumb
  | OkrTermsBreadCrumb
  | SettingOneOnOneBreadCrumb
  | CreateFixedAgendaBreadCrumb
  | EditFixedAgendaBreadCrumb
  | AccountSettingBreadCrumb
  | OrganizationBreadCrumb
  | OkrGlossariesBreadCrumb
  | IntegrationsBreadCrumb
  | SecurityBreadCrumb
  | BillingBreadCrumb

export type BreadcrumbParams =
  | {
      url: typeof urls.okr
      items: [TreeAndNodeBreadCrumb, OkrBreadCrumb]
    }
  | {
      url: typeof urls.user
      items: [TreeBreadCrumb, UsersBreadCrumb, UserBreadCrumb]
    }
  | {
      url: typeof urls.users
      items: [TreeBreadCrumb, UsersBreadCrumb]
    }
  | {
      url: typeof urls.objective
      items: [OkrBreadCrumb, ObjectiveDetailBreadCrumb]
    }
  | {
      url: typeof urls.objectiveEdit
      items: [OkrBreadCrumb, ObjectiveEditBreadCrumb]
    }
  | {
      url: typeof urls.keyResult
      items: [OkrBreadCrumb, KeyResultBreadCrumb, KeyResultDetailBreadCrumb]
    }
  | {
      url: typeof urls.keyResultEdit
      items: [OkrBreadCrumb, KeyResultBreadCrumb, KeyResultEditBreadCrumb]
    }
  | {
      url: typeof urls.actionPlan
      items: [OkrBreadCrumb, KeyResultBreadCrumb, ActionPlansBreadCrumb, ActionPlanBreadCrumb]
    }
  | {
      url: typeof urls.actionPlanEdit
      items: [OkrBreadCrumb, KeyResultBreadCrumb, ActionPlansBreadCrumb, ActionPlanBreadCrumb]
    }
  | {
      url: typeof urls.userAdmin
      items: [SettingBreadCrumb, SettingUsersBreadCrumb, SettingUserBreadCrumb]
    }
  | {
      url: typeof urls.usersImportAdmin
      items: [SettingBreadCrumb, SettingUsersBreadCrumb, SettingUsersImportBreadCrumb]
    }
  | {
      url: typeof urls.group
      items: [SettingBreadCrumb, SettingGroupsBreadCrumb, SettingGroupBreadCrumb]
    }
  | {
      url: typeof urls.groupsImportAdmin
      items: [SettingBreadCrumb, SettingGroupsBreadCrumb, SettingGroupsImportBreadCrumb]
    }
  | {
      url: typeof urls.note
      items:
        | [OkrBreadCrumb, NotesOfObjectiveBreadCrumb, NoteBreadCrumb]
        | [OkrBreadCrumb, KeyResultBreadCrumb, NotesOfKeyResultBreadCrumb, NoteBreadCrumb]
    }
  | {
      url: typeof urls.noteEdit
      items:
        | [OkrBreadCrumb, NotesOfObjectiveBreadCrumb, NoteBreadCrumb]
        | [OkrBreadCrumb, KeyResultBreadCrumb, NotesOfKeyResultBreadCrumb, NoteBreadCrumb]
    }
  | {
      url: typeof urls.personalNote
      items: [HomeNoteBreadCrumb, PersonalNoteBreadCrumb]
    }
  | {
      url: typeof urls.integrationsSmartHREmployees
      items: [
        OrganizationSettingsBreadCrumb,
        IntegrationSettingsBreadCrumb,
        SmartHRSettingBreadCrumb,
      ]
    }
  | {
      url: typeof urls.okrTermsAdmin
      items: [SettingBreadCrumb, OkrTermsBreadCrumb]
    }
  | {
      url: typeof urls.usersAdmin
      items: [SettingBreadCrumb, SettingUsersBreadCrumb]
    }
  | {
      url: typeof urls.oneOnOneAdmin
      items: [SettingBreadCrumb, SettingOneOnOneBreadCrumb]
    }
  | {
      url: typeof urls.createFixedAgendaAdmin
      items: [SettingBreadCrumb, SettingOneOnOneBreadCrumb, CreateFixedAgendaBreadCrumb]
    }
  | {
      url: typeof urls.editFixedAgendaAdmin
      items: [SettingBreadCrumb, SettingOneOnOneBreadCrumb, EditFixedAgendaBreadCrumb]
    }
  | {
      url: typeof urls.accountSetting
      items: [SettingBreadCrumb, AccountSettingBreadCrumb]
    }
  | {
      url: typeof urls.groupsSettingAdmin
      items: [SettingBreadCrumb, SettingGroupsBreadCrumb]
    }
  | {
      url: typeof urls.organizationAdmin
      items: [SettingBreadCrumb, OrganizationBreadCrumb]
    }
  | {
      url: typeof urls.okrGlossaries
      items: [SettingBreadCrumb, OkrGlossariesBreadCrumb]
    }
  | {
      url: typeof urls.integrationsAdmin
      items: [SettingBreadCrumb, IntegrationsBreadCrumb]
    }
  | {
      url: typeof urls.security
      items: [SettingBreadCrumb, SecurityBreadCrumb]
    }
  | {
      url: typeof urls.billingAdmin
      items: [SettingBreadCrumb, BillingBreadCrumb]
    }

type BreadcrumbFunctionParams = BreadcrumbParams extends {
  url: string
  items: Array<BreadcrumbType>
}
  ? BreadcrumbParams
  : never

const generateHref = (type: BreadcrumbType): string => {
  switch (type.breadcrumbName) {
    case 'tree':
      return urls.generateTrees({ termId: type.id })
    case 'treeAndNode':
      return urls.generateUrlWithQueryParams(urls.generateTrees({ termId: type.term.id }), {
        center: type.id,
      })
    case 'users':
      return urls.generateUsers(type.id)
    case 'user':
      return urls.generateUser(type.id)
    case 'okr':
      return urls.generateOkr(type.id)
    case 'objective':
      return urls.generateObjective(type.node.id, type.id)
    case 'objectiveDetail': {
      const params = urls.makeDrawerSearchParams('objective', 'info', type.id).toString()
      return `${urls.generateOkr(type.node.id)}?${params}#${urls.generateObjectiveHash(type.id)}`
    }
    case 'objectiveEdit': {
      const params = urls.makeDrawerSearchParams('objective', 'info', type.id).toString()
      return `${urls.generateOkr(type.node.id)}?${params}#${urls.generateObjectiveHash(type.id)}`
    }
    case 'keyResult': {
      const params = urls.makeDrawerSearchParams('keyResult', 'info', type.id).toString()
      return `${urls.generateOkr(type.node.id)}?${params}#${urls.generateKeyResultHash(type.id)}`
    }
    case 'keyResultDetail': {
      const params = urls.makeDrawerSearchParams('keyResult', 'info', type.id).toString()
      return `${urls.generateOkr(type.node.id)}?${params}#${urls.generateKeyResultHash(type.id)}`
    }
    case 'keyResultEdit':
      return urls.generateKeyResultEdit(type.node.id, type.id)
    case 'actionPlan':
      return urls.generateActionPlan(type.keyResult.node.id, type.keyResult.id, type.id)
    case 'actionPlans': {
      const params = urls.makeDrawerSearchParams('keyResult', 'actionPlan', type.id).toString()
      return `${urls.generateOkr(type.node.id)}?${params}#${urls.generateKeyResultHash(type.id)}`
    }
    case 'setting':
      return urls.setting
    case 'settingUser':
      return urls.generateAdminUser(type.id)
    case 'settingUsers':
      return urls.usersAdmin
    case 'importUsers':
      return urls.usersImportAdmin
    case 'settingGroups':
      return urls.groupsSettingAdmin
    case 'importGroups':
      return urls.groupsImportAdmin
    case 'group':
      return urls.generateGroup(type.id)
    case 'note':
      return urls.generateGroup(type.id)
    case 'notesOfObjective': {
      const params = urls.makeDrawerSearchParams('objective', 'note', type.id).toString()
      return `${urls.generateOkr(type.node.id)}?${params}#${urls.generateObjectiveHash(type.id)}`
    }
    case 'notesOfKeyResult': {
      const params = urls.makeDrawerSearchParams('keyResult', 'note', type.id).toString()
      return `${urls.generateOkr(type.node.id)}?${params}#${urls.generateKeyResultHash(type.id)}`
    }
    case 'me':
      return urls.meInCurrentTerm
    case 'personalNote':
      return urls.generatePersonalNote(type.id)
    case 'organizationSettings':
      return urls.admin
    case 'integrationSettings':
      return urls.integrationsAdmin
    case 'smarthr':
      return urls.integrationsSmartHREmployees
    case 'okrTerms':
      return urls.okrTermsAdmin
    case 'settingOneOnOnes':
      return urls.oneOnOneAdmin
    case 'createFixedAgenda':
      return urls.createFixedAgendaAdmin
    case 'editFixedAgenda':
      return urls.editFixedAgendaAdmin
    case 'accountSetting':
      return urls.accountSetting
    case 'organization':
      return urls.organizationAdmin
    case 'okrGlossaries':
      return urls.okrGlossaries
    case 'integrations':
      return urls.integrationsAdmin
    case 'security':
      return urls.security
    case 'billing':
      return urls.billingAdmin
    case 'homeNote':
      return urls.generateHomeNote(type.okrTermId)(type.id)
    default:
      return fail(type)
  }
}

const generateLabel = (type: BreadcrumbType, t: TFunction): JSX.Element | string => {
  switch (type.breadcrumbName) {
    case 'tree':
      return t('X_OF_Y', { x: t('OKR_MAP'), y: type.name })
    case 'treeAndNode':
      return t('X_OF_Y', { x: t('OKR_MAP'), y: type.term.name })
    case 'users':
      return t('OKR_SETTING_STATUS')
    case 'user':
      return t('FULL_NAME', type)
    case 'okr':
      return t('X_OF_Y', {
        x: t('OKR'),
        y: type.objective.name,
      })
    case 'objective':
      return <ObjectiveName>{type.name}</ObjectiveName>
    case 'objectiveDetail':
      return t('OBJECTIVE_BACKGROUND')
    case 'objectiveEdit':
      return t('OBJECTIVE_BACKGROUND')
    case 'keyResult':
      return <KeyResultNameBreadcrumb>{type.name}</KeyResultNameBreadcrumb>
    case 'keyResultDetail':
      return t('ACHIEVEMENT_CRITERIA_AND_METRICS')
    case 'keyResultEdit':
      return t('ACHIEVEMENT_CRITERIA_AND_METRICS')
    case 'actionPlan':
      return type.title
    case 'actionPlans':
      return t('ACTION_PLAN_LIST')
    case 'setting':
      return t('SETTING')
    case 'settingUser':
      return t('FULL_NAME', { firstName: type.firstName, lastName: type.lastName })
    case 'settingUsers':
      return t('USER')
    case 'importUsers':
      return t('CSV_IMPORT_PAGE_TITLE')
    case 'settingGroups':
      return t('GROUP')
    case 'importGroups':
      return t('CSV_IMPORT_PAGE_TITLE')
    case 'group':
      return type.name
    case 'note':
      return type.title
    case 'notesOfObjective':
      return t('NOTE_LIST')
    case 'notesOfKeyResult':
      return t('NOTE_LIST')
    case 'me':
      return t('FULL_NAME', {
        firstName: type.author.firstName,
        lastName: type.author.lastName,
      })
    case 'personalNote':
      return type.title
    case 'organizationSettings':
      return t('ORGANIZATION_SETTINGS')
    case 'integrationSettings':
      return t('INTEGRATIONS')
    case 'smarthr':
      return `${t('SMARTHR')} ${t('SETTING')}`
    case 'okrTerms':
      return t('OKR_TERM')
    case 'settingOneOnOnes':
      return t('ADMIN_ONE_ON_ONE_PAGE_TITLE')
    case 'createFixedAgenda':
      return t('ADMIN_FIXED_AGENDA_DETAIL_PAGE_TITLE')
    case 'editFixedAgenda':
      return t('ADMIN_FIXED_AGENDA_DETAIL_PAGE_TITLE')
    case 'accountSetting':
      return t('ACCOUNT_SETTING')
    case 'organization':
      return t('ORGANIZATION_NAME')
    case 'okrGlossaries':
      return t('DEFAULT_TEXT_')
    case 'integrations':
      return t('INTEGRATIONS')
    case 'security':
      return t('IP_RESTRICTION_AND_AUTH_SETTING')
    case 'billing':
      return 'プラン・お支払い'
    case 'homeNote':
      return t('NOTE_LIST')
    default:
      return fail(type)
  }
}

type Item = {
  label: string | JSX.Element
  href: string
}

const generateBreadcrumbItems = (
  params: BreadcrumbFunctionParams,
  t: TFunction,
): ReadonlyArray<Item> =>
  params.items.map((type) => ({
    label: generateLabel(type, t),
    href: generateHref(type),
  }))

export type Props = {
  breadcrumbs: BreadcrumbParams
}

export const Breadcrumb: React.FC<Props> = ({ breadcrumbs }) => {
  const { t } = useTranslation()
  const items = generateBreadcrumbItems(breadcrumbs, t)
  const last = items.length - 1

  return (
    <ol
      css={{
        color: color('text-bk-50'),
        ...fontSize('small'),
        listStyle: 'none',
        display: 'flex',
        flexWrap: 'wrap',
      }}
    >
      {items.map(({ label, href }, i) => (
        <li
          key={typeof label === 'string' ? label : href || i}
          css={[
            {
              display: 'flex',
              minHeight: fontSize('small').fontSize,
            },
            i !== 0
              ? {
                  '&:before': {
                    display: 'block',
                    margin: '0 8px',
                    fontWeight: 'normal',
                    content: '" > "',
                  },
                }
              : null,
            i === last
              ? {
                  fontWeight: 'normal',
                }
              : null,
          ]}
        >
          {i === last ? (
            <p
              css={{
                textOverflow: 'ellipsis',
                maxWidth: '250px',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              {label}
            </p>
          ) : (
            <Link
              href={href}
              css={{
                color: color('text-bk-50'),
                ':hover': {
                  color: color('resily-orange-100'),
                  textDecorationLine: 'underline',
                },
                textOverflow: 'ellipsis',
                maxWidth: '250px',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              {label}
            </Link>
          )}
        </li>
      ))}
    </ol>
  )
}

Breadcrumb.displayName = 'Breadcrumb'
