import { css } from '@emotion/react'

import { fontSize } from '../../../../../styles/font'

export const rootCss = css({
  display: 'flex',
  columnGap: '11px',
  alignItems: 'center',
})

export const titleCss = css({
  ...fontSize('medium', 'bold'),
})

export const descriptionCss = css({
  marginTop: '4px',
  ...fontSize('small', 'regular'),
})
