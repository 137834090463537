import { useTranslation } from '../../../i18n'
import { color } from '../../../styles/newColors'
import { DateTime } from '../../ui/DateTime'

import { KeyResultFragment } from './graphql'

export type Props = {
  keyResult: KeyResultFragment
}

export const KeyResultDetailOption: React.FC<Props> = ({ keyResult }) => {
  const { t } = useTranslation()

  return (
    <div
      css={{
        marginLeft: '32px',
        width: '200px',
      }}
    >
      <dl
        css={{
          dt: {
            marginBottom: '12px',
            fontSize: '12px',
            fontWeight: 'bold',
            color: color('text-bk-50'),
          },
          dd: {
            marginBottom: '24px',
            fontSize: '14px',
            color: color('text-bk-100'),
          },
        }}
      >
        <dt>{t('LAST_UPDATED_AT')}</dt>
        <dd>
          <DateTime datetime={keyResult.updatedAt} withoutTime />
        </dd>
      </dl>
    </div>
  )
}

KeyResultDetailOption.displayName = 'KeyResultDetailOption'
