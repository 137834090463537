import { css } from '@emotion/react'

import { StyledText } from '../../../../../components/ui/StyledText'
import { useTranslation } from '../../../../../i18n'
import { color } from '../../../../../styles/newColors'

import { SortButton } from './sortButton'
import { Sort } from './types'

export type Props = {
  sort: Sort
  onClickSortBy: (sortBy: Sort['sortBy']) => void
}

export const NotesTableHead: React.VFC<Props> = ({ sort, onClickSortBy }) => {
  const { t } = useTranslation()

  const cssTableCellHeader = css({
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '12px',
    borderBottom: `1px solid ${color('border-bk-10')}`,
    backgroundColor: color('white-100'),
    justifyContent: 'start',
  })

  return (
    <div
      css={{
        display: 'flex',
      }}
    >
      <div
        css={[
          cssTableCellHeader,
          {
            flex: 'auto',
            paddingLeft: '12px',
          },
        ]}
      >
        <SortButton sortBy="title" sort={sort} onClick={onClickSortBy}>
          <StyledText size="small" fontStyle="bold" lineHeight="1">
            {t('TITLE')}
          </StyledText>
        </SortButton>
      </div>
      <div
        css={[
          cssTableCellHeader,
          {
            flex: '0 0 348px',
          },
        ]}
      >
        <SortButton sortBy="OKR" sort={sort} onClick={onClickSortBy}>
          <StyledText size="small" fontStyle="bold">
            {t('OKR')}
          </StyledText>
        </SortButton>
      </div>
      <div
        css={[
          cssTableCellHeader,
          {
            flex: '0 0 90px',
          },
        ]}
      >
        <SortButton sortBy="permission" sort={sort} onClick={onClickSortBy}>
          <StyledText size="small" fontStyle="bold">
            {t('OPEN_RANGE')}
          </StyledText>
        </SortButton>
      </div>
      <div
        css={[
          cssTableCellHeader,
          {
            flex: '0 0 136px',
          },
        ]}
      >
        <SortButton sortBy="author" sort={sort} onClick={onClickSortBy}>
          <StyledText size="small" fontStyle="bold">
            {t('AUTHOR')}
          </StyledText>
        </SortButton>
      </div>
      <div
        css={[
          cssTableCellHeader,
          {
            flex: '0 0 114px',
          },
        ]}
      >
        <SortButton sortBy="updatedAt" sort={sort} onClick={onClickSortBy}>
          <StyledText size="small" fontStyle="bold">
            {t('UPDATED_AT')}
          </StyledText>
        </SortButton>
      </div>
    </div>
  )
}
