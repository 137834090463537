import { useMemo } from 'react'

import { useTranslation } from '../../../../i18n'
import { generateTestId, featureNames, componentNames } from '../../../../lib/testId'
import { TabsWithChildren, useTab, TabType } from '../Common/TabsWithChildren'
import { ObjectiveRightSectionFragment } from '../graphql'
import { useOkrModalQueryParams } from '../hooks/useOkrModalQueryParams'
import { LoadableProps, ObjectiveTab, ObjectiveTabObj } from '../types'

import { ActivityContainer } from './Activity'
import { NoteContainer } from './Note'
import {
  ObjectiveDescriptionContainer,
  Props as ObjectiveDescriptionContainerProps,
} from './ObjectiveDescription'
import { RightSection, Props as RightSectionProps } from './RightSection'

export type DataProps = {
  rightSectionData: ObjectiveRightSectionFragment
}

export type Props = RightSectionProps &
  LoadableProps<DataProps> &
  Pick<ObjectiveDescriptionContainerProps, 'onDelete' | 'onDisable'>

export const RightSectionContainer: React.VFC<Props> = ({
  showRightSection,
  loading,
  rightSectionData,
  onDelete,
  onDisable,
}) => {
  const { t } = useTranslation()
  const { objectiveTab, setObjectiveTab } = useOkrModalQueryParams()
  const [tab, handleClickTab] = useTab<ObjectiveTab>(
    objectiveTab ?? ObjectiveTabObj['m-o-description'],
    setObjectiveTab,
  )

  // FIXME: OkrModalContainerからデータを加工せずに渡したいのでこのあたりでスケルトンの調整をしてください
  const tabs = useMemo<ReadonlyArray<TabType<ObjectiveTab>>>(
    () => [
      {
        value: ObjectiveTabObj['m-o-description'],
        name: `${t('X_DETAIL', { x: t('OBJECTIVE') })}`,
        children: (
          <ObjectiveDescriptionContainer
            loading={loading as never}
            objective={rightSectionData?.objective as never}
            groups={rightSectionData?.groups as never}
            onDelete={onDelete}
            onDisable={onDisable}
          />
        ),
      },
      {
        value: ObjectiveTabObj['m-o-activity'],
        name: t('ACTIVITY'),
        children: (
          <ActivityContainer
            loading={loading as never}
            keyResultIds={rightSectionData?.keyResults.map((kr) => kr.id)}
          />
        ),
      },
      {
        value: ObjectiveTabObj['m-o-note'],
        name: t('NOTE'),
        children: <NoteContainer />,
      },
    ],
    [loading, onDelete, onDisable, rightSectionData, t],
  )

  return (
    <RightSection showRightSection={showRightSection}>
      <TabsWithChildren<ObjectiveTab>
        tab={tab}
        tabs={tabs}
        tabName="m-o-tab"
        data-testid={generateTestId(featureNames.okrModal, componentNames.objectiveTab)}
        tabContentTestId={generateTestId(featureNames.okrModal, componentNames.objectiveTabContent)}
        onClickTab={handleClickTab}
      />
    </RightSection>
  )
}
RightSectionContainer.displayName = 'RightSectionContainer'
