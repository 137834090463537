import { css } from '@emotion/react'
import { useMemo } from 'react'

import { border } from '../../../../../styles/border'
import { color } from '../../../../../styles/newColors'

const TAB_HEIGHT = 43

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useStyles = () =>
  useMemo(
    () =>
      ({
        root: css({
          overflowY: 'hidden',
          height: '100%',
        }),
        tabWrapper: css({
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: TAB_HEIGHT,
          padding: '0 24px',
          backgroundColor: color('white-100'),
          borderBottom: border('simple-10'),
        }),
        tab: css({
          borderBottom: 0,
        }),
        tabContent: css({
          overflowY: 'auto',
          height: `calc(100% - ${TAB_HEIGHT}px)`,
        }),
      } as const),
    [],
  )
