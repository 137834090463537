import { generateTestId, featureNames, componentNames } from '../../../../../lib/testId'
import { useStyles as useMainStyles } from '../Main.styles'

import { CheckinAreaEmptyView } from './CheckinAreaEmptyView'
import { CheckinAreaHeaderContainer } from './CheckinAreaHeader'
import { CheckinAreaList } from './CheckinAreaList'
import { CheckinAreaSkeleton } from './CheckinAreaSkeleton'

export type CheckinAreaComposition = {
  Header: typeof CheckinAreaHeaderContainer
  List: typeof CheckinAreaList
  EmptyView: typeof CheckinAreaEmptyView
  Skeleton: typeof CheckinAreaSkeleton
}

export const CheckinArea: React.FC & CheckinAreaComposition = ({ children }) => {
  const mainStyles = useMainStyles()

  return (
    <div
      css={mainStyles.areaRoot}
      data-testid={generateTestId(featureNames.okrModal, componentNames.checkinArea)}
    >
      {children}
    </div>
  )
}
CheckinArea.displayName = 'CheckinArea'
CheckinArea.Header = CheckinAreaHeaderContainer
CheckinArea.List = CheckinAreaList
CheckinArea.EmptyView = CheckinAreaEmptyView
CheckinArea.Skeleton = CheckinAreaSkeleton
