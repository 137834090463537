import { css } from '@emotion/react'
import { TextInput, TextInputProps } from 'grommet'
import React, { PropsWithRef, forwardRef, useRef, MouseEvent } from 'react'

import { mergeRefs } from '../../../lib/reactRef'
import { border } from '../../../styles/border'
import * as colors from '../../../styles/colors'
import * as font from '../../../styles/font'
import { color } from '../../../styles/newColors'
import { Icon } from '../Icon'

type Size = 's' | 'm'
type FieldStyle = 'bottomLine' | 'outline'

export type CountInputProps = PropsWithRef<TextInputProps> & {
  inputSize?: Size
  fontSize?: font.FontSizeAlias
  fieldStyle?: FieldStyle
  isError?: boolean
  width?: string
  radius?: string
  onCountUp: (e: MouseEvent<HTMLButtonElement>, ref: React.RefObject<HTMLInputElement>) => void
  onCountDown: (e: MouseEvent<HTMLButtonElement>, ref: React.RefObject<HTMLInputElement>) => void
}

const sizes: { [key in Size]: number } = {
  s: 32,
  m: 40,
}

export const CountInput = forwardRef<HTMLInputElement, CountInputProps>(
  (
    {
      inputSize = 'm',
      fontSize,
      fieldStyle = 'outline',
      width = '80px',
      radius = '4px',
      isError,
      onCountUp,
      onCountDown,
      ...props
    }: CountInputProps,
    ref: React.Ref<HTMLInputElement>,
  ) => {
    const localRef = useRef<HTMLInputElement>(null)
    const disabled = props.disabled || false
    const outlineStyle = css({
      border: border('simple-10'),
      borderRadius: radius,
    })
    const bottomLineStyle = css({
      borderTop: 'none',
      borderLeft: 'none',
      borderRight: 'none',
      borderRadius: '0px',
      borderBottom: border('simple-10'),
    })
    const fieldStyleCss = () => {
      switch (fieldStyle) {
        case 'bottomLine':
          return bottomLineStyle
        default:
          return outlineStyle
      }
    }
    const baseCss = css({
      flexGrow: 1,
      // stylelint-disable-next-line function-whitespace-after
      fontSize: fontSize && font.fontSize(fontSize).fontSize,
      border: 0,
      color: color('text-bk-100'),
      width,
      height: sizes[inputSize],
      padding: '0 24px 0 8px',
      fontWeight: 'normal',
      lineHeight: `${sizes[inputSize]}px`,
      backgroundColor: 'transparent',
      outline: 'none',
      '&:disabled': {
        color: colors.inactive,
        opacity: 1,
      },
    })
    const csss = [baseCss]

    const countInputWrapperBaseCss = css({
      backgroundColor: colors.baseFill,
      position: 'relative',
      transition: 'border-color 0.1s ease-out',
    })
    const countInputWrapperStateCss = disabled
      ? css({
          backgroundColor: colors.background,
          borderColor: colors.inactive,
          opacity: 0.3,
        })
      : css({
          '&:hover, &:focus': {
            backgroundColor: color('hover-background-bk-5'),
            borderColor: colors.main,
          },
        })
    const countInputWrapperErrorCss = isError ? { borderColor: colors.negative } : null
    const countInputCss = [
      countInputWrapperBaseCss,
      fieldStyleCss(),
      countInputWrapperStateCss,
      countInputWrapperErrorCss,
    ]

    const countWrapperCss = css({
      position: 'absolute',
      bottom: '6px',
      right: '4px',
      top: '6px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    })
    const countButtonCss = css({
      width: 16,
      height: 10,
      padding: '3px 4px',
      display: 'flex !important',
      alignItems: 'center',
      justifyContent: 'center',
      '&:active': {
        backgroundColor: color('border-bk-30'),
      },
    })
    const countIconCss = css({
      width: 8,
      height: 4,
    })

    const onCountUpButtonClick = (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      localRef?.current?.focus()
      onCountUp(e, localRef)
    }

    const onCountDownButtonClick = (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      localRef?.current?.focus()
      onCountDown(e, localRef)
    }

    return (
      <div css={countInputCss}>
        <TextInput ref={mergeRefs([localRef, ref])} css={csss} {...props} />
        <div css={countWrapperCss}>
          <button
            type="button"
            css={countButtonCss}
            onClick={onCountUpButtonClick}
            disabled={disabled}
          >
            <Icon type="countUp" css={countIconCss} />
          </button>
          <button
            type="button"
            css={countButtonCss}
            onClick={onCountDownButtonClick}
            disabled={disabled}
          >
            <Icon type="countDown" css={countIconCss} />
          </button>
        </div>
      </div>
    )
  },
)

CountInput.displayName = 'CountInput'
