import { KeyResultFragment, UserFragment } from '../../components/domain/ConfidenceTag/graphql'

type KeyResult = Pick<KeyResultFragment, 'owner'> & {
  readonly confidences: ReadonlyArray<
    Pick<KeyResultFragment['confidences'][number], 'user' | 'level'>
  >
}

export const confidenceList = [
  [2, 'CONFIDENCE_LEVEL_VERY_CONFIDENT'],
  [1, 'CONFIDENCE_LEVEL_FAIRLY_CONFIDENT'],
  [0, 'CONFIDENCE_LEVEL_NEITHER'],
  [-1, 'CONFIDENCE_LEVEL_LESS_CONFIDENT'],
  [-2, 'CONFIDENCE_LEVEL_NOT_CONFIDENT'],
] as const

const levelList = confidenceList.map(([key]) => key)
export type Level = typeof levelList[number]

const labelList = confidenceList.map(([, value]) => value)
type Label = typeof labelList[number]

export const isLevel = (value: number): value is Level => levelList.some((l) => l === value)

export const numberToConfidence = (value: number): Level => {
  if (isLevel(value)) return value

  if (value > 2) return 2
  if (value < -2) return -2

  return Math.round(value) as -1 | 0 | 1
}

export const levelToLabel = (value: Level): Label =>
  confidenceList.find(([key]) => key === value)?.[1] || 'CONFIDENCE_LEVEL_NEITHER'

export const keyResultToConfidenceLevel = (keyResult: KeyResult): number | undefined =>
  keyResult.confidences.find((val) => val.user.id === keyResult.owner.id)?.level

export const keyResultToUserConfidenceLevel = (
  keyResult: Omit<KeyResult, 'owner'>,
  user: UserFragment,
): number | undefined => keyResult.confidences.find((val) => val.user.id === user.id)?.level
