import { css } from '@emotion/react'
import { VFC, useMemo, useCallback } from 'react'
import { Controller } from 'react-hook-form'

import { Button } from '../../components/ui/Button'
import { Checkbox } from '../../components/ui/Checkbox'
import { DateTime } from '../../components/ui/DateTime'
import { ListCard, Table, TableRow, TableHeader } from '../../components/ui/ListCard'
import { StyledText } from '../../components/ui/StyledText'
import { useTranslation } from '../../i18n'
import { useDisclosure } from '../../lib/useDisclosure'
import { color } from '../../styles/newColors'

import { SmartHrEmployeeFragment } from './graphql'
import { useEmployeeForm } from './hooks/useEmployeeForm'
import { PaginatedSmartHREmployeeReturn } from './hooks/useSmarthrEmployeeHandler'
import { ListBottom } from './listBottom'
import { SegregatedUserModal } from './segregatedUserModal'
import { UsingStartDateModal } from './usingStartDateModal'

type Props = {
  // TODO: 命名をどうするか考える
  usingStartDateSettingText: string
  // TODO: 命名をどうするか考える
  canSegregation: boolean
  pagination: PaginatedSmartHREmployeeReturn
  onClickBulkUpdateDisabled: (
    employees: ReadonlyArray<Pick<SmartHrEmployeeFragment, 'id'>>,
    onSucceeded: () => void,
  ) => void
  onClickBulkUpdateStartDate: (
    args: {
      employees: ReadonlyArray<Pick<SmartHrEmployeeFragment, 'id'>>
      startDate: Date
    },
    onSucceeded: () => void,
  ) => void
}

// TODO: submitして一括更新できる
export const List: VFC<Props> = ({
  usingStartDateSettingText,
  canSegregation,
  pagination,
  onClickBulkUpdateDisabled,
  onClickBulkUpdateStartDate,
}) => {
  const { t } = useTranslation()
  const { control, toggleSelectedAll, selectedAll, selectedItems, handleSelecteItems } =
    useEmployeeForm({ items: pagination.items })

  const isShowSettingHandler: boolean = selectedItems.length > 0

  const rows = useMemo<Array<TableRow>>(
    () =>
      pagination.items.map((item, i) => ({
        key: item.id,
        cells: [
          {
            key: `${item.id}-checkbox`,
            minWidth: 48,
            cell: (
              <Controller
                name={`items.${i}.checked`}
                control={control}
                defaultValue={false}
                render={({ field: { onChange } }) => (
                  <Checkbox
                    css={styles.headers.checkbox.title}
                    checked={selectedItems.some(({ id }) => id === item.id)}
                    onChange={(e) => {
                      onChange(e.target.checked)
                      handleSelecteItems(e.target.checked, item)
                    }}
                  />
                )}
              />
            ),
            css: styles.table.checkbox.cell,
          },
          {
            key: `${item.id}-user`,
            minWidth: 184,
            cell: (
              <StyledText color="text-bk-100">
                {t('FULL_NAME', { firstName: item.firstName, lastName: item.lastName })}
              </StyledText>
            ),
            css: styles.table.user.cell,
          },
          {
            key: `${item.id}-group`,
            minWidth: 300,
            cell: '仮',
            css: styles.table.group.cell,
          },
          {
            key: `${item.id}-emp-type`,
            minWidth: 128,
            cell: <StyledText color="text-bk-100">{item.employmentType}</StyledText>,
            css: styles.table.empType.cell,
          },
          {
            key: `${item.id}-email`,
            minWidth: 154,
            css: styles.table.email.cell,
            cell: <StyledText color="text-bk-100">{item.email}</StyledText>,
          },
          {
            key: `${item.id}-start-date`,
            minWidth: 74,
            css: styles.table.startState.cell,
            cell: item.startDate ? <DateTime withoutTime datetime={item.startDate} /> : '',
          },
        ],
      })),
    [control, handleSelecteItems, pagination.items, selectedItems, t],
  )

  const tableHeaders = useMemo<Array<TableHeader>>(
    () => [
      {
        title: (
          <Checkbox
            css={styles.headers.checkbox.title}
            checked={selectedAll}
            onChange={toggleSelectedAll}
          />
        ),
        minWidth: 48,
        css: styles.table.checkbox.cell,
      },
      {
        title: t('USER'),
        minWidth: 184,
        css: styles.table.user.cell,
      },
      {
        title: t('GROUP'),
        minWidth: 300,
        css: styles.table.group.cell,
      },
      {
        title: '雇用形態',
        minWidth: 128,
        css: styles.table.empType.cell,
      },
      {
        title: 'メールアドレス',
        minWidth: 154,
        css: styles.table.email.cell,
      },
      {
        title: '利用開始日',
        minWidth: 74,
        css: styles.table.startState.cell,
      },
    ],
    [t, toggleSelectedAll, selectedAll],
  )

  const table = useMemo<Table>(
    (): Table => ({
      headers: tableHeaders,
      data: {
        rows,
        rowMinHeight: 40,
        maxHeight: 436,
        css: {
          height: '100%',
        },
      },
    }),
    [rows, tableHeaders],
  )

  const header = useMemo(() => <></>, [])

  const {
    open: openSegregationModal,
    close: closeSegregationModal,
    isOpen: isOpenedSegregationModal,
  } = useDisclosure()
  const {
    open: openUsingStartDateModal,
    close: closeUsingStartDateModal,
    isOpen: isOpenedUsingStartDate,
  } = useDisclosure()

  const handleClickSegregation = useCallback(() => {
    onClickBulkUpdateDisabled(selectedItems, closeSegregationModal)
  }, [closeSegregationModal, selectedItems, onClickBulkUpdateDisabled])

  const handleClickUsingStartDate = useCallback(
    (startDate: Date) => {
      onClickBulkUpdateStartDate({ employees: selectedItems, startDate }, closeUsingStartDateModal)
    },
    [onClickBulkUpdateStartDate, selectedItems, closeUsingStartDateModal],
  )

  return (
    <>
      <div data-is-expand={isShowSettingHandler} css={styles.settingBox}>
        <StyledText color="text-bk-50">{selectedItems.length}件の選択</StyledText>
        <Button
          newColor="white-100"
          weight="normal"
          size="s"
          onClick={openUsingStartDateModal}
          css={styles.usingStartDateButton}
        >
          {usingStartDateSettingText}
        </Button>

        <Button
          newColor="white-100"
          weight="normal"
          size="s"
          data-is-display={canSegregation}
          onClick={openSegregationModal}
          css={styles.segregatedUserButton}
        >
          非連携ユーザーに変更
        </Button>
      </div>
      <ListCard borderless header={header} table={table} css={styles.list} />
      <ListBottom pagination={pagination} />
      <SegregatedUserModal
        isOpened={isOpenedSegregationModal}
        onClickSegration={handleClickSegregation}
        onClickCancel={closeSegregationModal}
      />
      <UsingStartDateModal
        isOpened={isOpenedUsingStartDate}
        onClickSetting={handleClickUsingStartDate}
        onClickCancel={closeUsingStartDateModal}
      />
    </>
  )
}

const styles = {
  listBottom: css({
    marginTop: 12,
  }),
  usingStartDateButton: css({
    marginLeft: 18,
  }),
  segregatedUserButton: css({
    display: 'none',
    '&[data-is-display="true"]': {
      display: 'block',
    },
    marginLeft: 8,
  }),
  settingBox: css({
    display: 'flex',
    alignItems: 'center',
    backgroundColor: color('background-bk-5'),
    borderRadius: 8,
    padding: '8px 16px',
    overflowY: 'hidden', // for animation
    marginTop: 0, // for animation
    maxHeight: 0, // for animation
    opacity: 0, // for animation
    '&[data-is-expand="true"]': {
      maxHeight: 56,
      marginTop: 12,
      opacity: 1,
      transition: 'all 150ms cubic-bezier(0.820, 0.085, 0.395, 0.895)',
    },
    '&[data-is-expand="false"]': {
      maxHeight: 0,
      marginTop: 0,
      opacity: 0,
      transition: 'all 150ms cubic-bezier(0.820, 0.085, 0.395, 0.895)',
    },
  }),
  list: css({
    padding: 0,
    marginTop: 24,
  }),
  table: {
    checkbox: {
      cell: css({ width: 'auto' }),
    },
    user: {
      cell: css({
        paddingRight: 20,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: 184,
      }),
    },
    group: {
      cell: css({
        paddingRight: 20,
      }),
    },
    empType: {
      cell: css({
        paddingRight: 20,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }),
    },
    email: {
      cell: css({
        paddingRight: 20,
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }),
    },
    startState: {
      cell: css({ width: 74 }),
    },
  },
  headers: {
    checkbox: {
      cell: css({ width: 'auto' }),
      title: css({ padding: '0 12px', verticalAlign: 'middle' }),
    },
    email: {
      cell: css({ width: '100%' }),
    },
    startState: {
      cell: css({ width: 74 }),
    },
  },
} as const

List.displayName = 'List'
