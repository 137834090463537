import { Box, Button, Spinner } from 'grommet'
import { memo, MouseEventHandler, useCallback, useEffect, useMemo, useState } from 'react'
import isEqual from 'react-fast-compare'

import { Avatar } from '../../components/ui/Avatar/index'
import { Icon } from '../../components/ui/Icon'
import { StyledText } from '../../components/ui/StyledText'
import { Switch } from '../../components/ui/Switch'
import { TFunction, useTranslation } from '../../i18n'
import { DeepNonNullable } from '../../lib/type'
import { border } from '../../styles/border'
import { color } from '../../styles/newColors'

import { useRecommendAgendasLazyQuery, UserFragment, RecommendAgendaFragment } from './graphql'
import { useEnableRecommendAgenda } from './hooks/useEnableRecommendAgenda'

type User = Pick<UserFragment, 'id' | 'firstName' | 'lastName' | 'avatar' | 'isDisabled'>
type RecommendAgendas = RecommendAgendaFragment['recommendAgendas']
type RecommendAgendasWithUserId = { userId: string; recommendAgendas: RecommendAgendas }
type RecommendAgendasPair = {
  current: RecommendAgendasWithUserId
  partner: RecommendAgendasWithUserId
}
type RegisteredAgendaIds = ReadonlyArray<string>

type MeetingAt = {
  startTime: Date
  endTime: Date
}

export type Props = {
  currentUser: User
  partnerUser: User
  termId?: string
  previousMeetingAt?: MeetingAt
  currentMeetingAt: MeetingAt
  changeStatusAgendaModal: () => void
  handleRegisterRecommendAgenda: (title: string) => Promise<void>
}

export const RecommendAgenda: React.FC<Props> = ({
  currentUser,
  partnerUser,
  termId,
  previousMeetingAt,
  currentMeetingAt,
  changeStatusAgendaModal,
  handleRegisterRecommendAgenda: registerRecommendAgenda,
}) => {
  const { t } = useTranslation()
  const [selectedUserId, setSelectedUserId] = useState(currentUser.id)
  const [registeredAgendaIds, setRegisteredAgendaIds] = useState<RegisteredAgendaIds>([])
  const [enabledNotification, setEnabledNotification] = useEnableRecommendAgenda(termId, false)

  const handleRegisterRecommendAgenda = useCallback(
    (id: string, title: string) => {
      registerRecommendAgenda(title).then(() => setRegisteredAgendaIds((prev) => [...prev, id]))
    },
    [registerRecommendAgenda],
  )

  const [recommendAgendasQuery, { data, called, loading, refetch }] = useRecommendAgendasLazyQuery()

  useEffect(() => {
    if (enabledNotification) {
      recommendAgendasQuery({
        variables: {
          currentUserId: currentUser.id,
          partnerUserId: partnerUser.id,
          previousMeetingAt: previousMeetingAt?.endTime,
          currentMeetingAt: currentMeetingAt.endTime,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // ミーティングが変更された時には再度おすすめアジェンダを取得する
  useEffect(() => {
    if (enabledNotification && refetch) {
      refetch({
        currentUserId: currentUser.id,
        partnerUserId: partnerUser.id,
        previousMeetingAt: previousMeetingAt?.endTime,
        currentMeetingAt: currentMeetingAt.endTime,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentMeetingAt.endTime,
    currentUser.id,
    partnerUser.id,
    previousMeetingAt?.endTime,
    refetch,
  ])

  const handleCheckboxClick = useCallback(() => {
    setEnabledNotification(!enabledNotification)

    if (!called && !enabledNotification) {
      recommendAgendasQuery({
        variables: {
          currentUserId: currentUser.id,
          partnerUserId: partnerUser.id,
          previousMeetingAt: previousMeetingAt?.endTime,
          currentMeetingAt: currentMeetingAt.endTime,
        },
      })
    }
  }, [
    called,
    currentMeetingAt.endTime,
    currentUser.id,
    enabledNotification,
    partnerUser.id,
    previousMeetingAt?.endTime,
    recommendAgendasQuery,
    setEnabledNotification,
  ])

  const users = useMemo<ReadonlyArray<User>>(
    () => [currentUser, partnerUser],
    [currentUser, partnerUser],
  )

  const recommendAgendasPair = useMemo<RecommendAgendasPair | null>(() => {
    if (!called || loading) {
      return null
    }

    if (!data?.currentUserRecommendAgenda || !data?.partnerUserRecommendAgenda) {
      return {
        current: {
          userId: currentUser.id,
          recommendAgendas: [],
        },
        partner: { userId: partnerUser.id, recommendAgendas: [] },
      }
    }

    return {
      current: {
        userId: data.currentUserRecommendAgenda.id,
        recommendAgendas: data.currentUserRecommendAgenda.recommendAgendas,
      },
      partner: {
        userId: partnerUser.id,
        recommendAgendas: data.partnerUserRecommendAgenda.recommendAgendas,
      },
    }
  }, [
    called,
    loading,
    data?.currentUserRecommendAgenda,
    data?.partnerUserRecommendAgenda,
    partnerUser.id,
    currentUser.id,
  ])

  return (
    <Box width="530px" css={{ position: 'relative', minHeight: '300px' }}>
      {!enabledNotification && (
        <Box width="100%" height="calc( 100% - 42px )" css={{ position: 'absolute', zIndex: 1 }}>
          <QuitButton changeStatusAgendaModal={changeStatusAgendaModal} />
          <Box
            pad="24px"
            width="374px"
            border={{ color: color('background-bk-100'), size: '1px', style: 'solid' }}
            css={{
              textAlign: 'center',
              borderRadius: '8px',
              position: 'absolute',
              top: '25%',
              left: '14%',
            }}
          >
            <StyledText fontStyle="bold" css={{ whiteSpace: 'pre-wrap' }}>
              {t('OKR_NOTIFICATION_CAUTION')}
            </StyledText>
            <StyledText margin={{ top: '16px' }}>{t('OKR_SETTING_COMPLETE_USE')}</StyledText>
          </Box>
          <RecommendPopup />
        </Box>
      )}

      <Box
        css={{
          opacity: !enabledNotification ? 0.6 : 1,
          filter: !enabledNotification ? 'blur(3px)' : 'blur(0px)',
        }}
      >
        <QuitButton changeStatusAgendaModal={changeStatusAgendaModal} />
        <Box
          direction="row"
          css={{
            minHeight: '43px',
            borderBottom: border('simple-10'),
            paddingLeft: '14px',
            pointerEvents: !enabledNotification ? 'none' : 'auto',
          }}
        >
          {users.map((v) => (
            <Box
              key={v.id}
              css={{
                boxShadow: 'none',
                padding: '8px',
                '&:last-child': { marginLeft: '27px' },
                borderBottom:
                  v.id === selectedUserId ? `2px solid ${color('text-bk-100')}` : undefined,
              }}
              onClick={() => {
                setSelectedUserId(v.id)
              }}
            >
              <Box direction="row" align="center">
                <Avatar
                  firstName={v.firstName}
                  lastName={v.lastName}
                  avatarUrl={v.avatar?.url}
                  size="user-list-page-resource-view"
                  isUserDisabled={v.isDisabled}
                />
                <Box direction="row" margin={{ left: '8px' }}>
                  <StyledText size="large" weight="bold" css={{ display: 'inline-block' }}>
                    {v.lastName}
                  </StyledText>
                  <StyledText size="large" weight="bold" css={{ display: 'inline-block' }}>
                    {v.firstName}
                  </StyledText>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>

        <Box css={{ minHeight: '180px', maxHeight: '383px', overflowY: 'auto' }}>
          <RecommendAgendasArea
            enabledNotification={enabledNotification}
            recommendAgendasPair={recommendAgendasPair}
            selectedUserId={selectedUserId}
            registeredAgendaIds={registeredAgendaIds}
            handleRegisterRecommendAgenda={handleRegisterRecommendAgenda}
          />
        </Box>
      </Box>
      <Box
        direction="row"
        align="center"
        justify="between"
        background={color('border-bk-10')}
        pad={{ vertical: '14px', left: '21px', right: '37px' }}
        css={{ minHeight: '40px' }}
      >
        <StyledText size="small" lineHeight="12px" color="text-bk-50">
          {t('OKR_NOTIFICATION')}
        </StyledText>
        <Switch
          label={t('ON_OFF')}
          isCheck={enabledNotification}
          handleCheckboxClick={handleCheckboxClick}
        />
      </Box>
    </Box>
  )
}

RecommendAgenda.displayName = 'RecommendAgenda'

const Loading: React.VFC = () => (
  <div
    style={{
      display: 'flex',
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      margin: 'auto 0',
    }}
  >
    <Spinner size="medium" color={color('resily-orange-100')} />
  </div>
)

Loading.displayName = 'Loading'

type RecommendAgendasAreaProps = {
  recommendAgendasPair: RecommendAgendasPair | null
  selectedUserId: string
  registeredAgendaIds: RegisteredAgendaIds
  handleRegisterRecommendAgenda: RecommendAgendaItemProps['handleRegisterRecommendAgenda']
  enabledNotification: boolean
}

const RecommendAgendasArea: React.VFC<RecommendAgendasAreaProps> = ({
  recommendAgendasPair,
  selectedUserId,
  registeredAgendaIds,
  handleRegisterRecommendAgenda,
  enabledNotification,
}) => {
  if (recommendAgendasPair) {
    return (
      <RecommendAgendasContent
        recommendAgendasPair={recommendAgendasPair}
        selectedUserId={selectedUserId}
        registeredAgendaIds={registeredAgendaIds}
        handleRegisterRecommendAgenda={handleRegisterRecommendAgenda}
      />
    )
  }

  if (enabledNotification) {
    return <Loading />
  }

  return null
}

RecommendAgendasArea.displayName = 'RecommendAgendasArea'

type RecommendAgendasContentProps = Omit<
  DeepNonNullable<RecommendAgendasAreaProps>,
  'enabledNotification'
>

const RecommendAgendasContent: React.VFC<RecommendAgendasContentProps> = memo(
  ({
    recommendAgendasPair,
    selectedUserId,
    registeredAgendaIds,
    handleRegisterRecommendAgenda,
  }) => {
    const { t } = useTranslation()

    return (
      <>
        {Object.values(recommendAgendasPair).map((recommendAgendasWithUserId) => (
          <div
            key={recommendAgendasWithUserId.userId}
            style={{
              display: recommendAgendasWithUserId.userId === selectedUserId ? 'block' : 'none',
            }}
          >
            {recommendAgendasWithUserId.recommendAgendas.length > 0 ? (
              recommendAgendasWithUserId.recommendAgendas.map((recommendAgenda) => (
                <div
                  key={recommendAgenda.id}
                  css={{
                    '> *': {
                      padding: '24px',
                      borderBottom: border('simple-10'),
                    },
                  }}
                >
                  <RecommendAgendaItem
                    item={recommendAgenda}
                    isRegistered={registeredAgendaIds.includes(recommendAgenda.id)}
                    handleRegisterRecommendAgenda={handleRegisterRecommendAgenda}
                  />
                </div>
              ))
            ) : (
              <Box>
                <StyledText
                  margin={{ top: '24px', left: '24px' }}
                  size="medium"
                  weight="bold"
                  lineHeight="12px"
                  color="text-bk-50"
                >
                  {t('DOSE_NOT_EXISTS_RECOMMEND_AGENDA')}
                </StyledText>
              </Box>
            )}
          </div>
        ))}
      </>
    )
  },
  (prev, next) => {
    if (!isEqual(prev.recommendAgendasPair, next.recommendAgendasPair)) {
      return false
    }
    if (prev.selectedUserId !== next.selectedUserId) {
      return false
    }
    if (!isEqual(prev.registeredAgendaIds, next.registeredAgendaIds)) {
      return false
    }
    if (!isEqual(prev.handleRegisterRecommendAgenda, next.handleRegisterRecommendAgenda)) {
      return false
    }
    return true
  },
)

RecommendAgendasContent.displayName = 'RecommendAgendasContent'

const QuitButton: React.FC<Pick<Props, 'changeStatusAgendaModal'>> = ({
  changeStatusAgendaModal,
}) => (
  <Button
    margin={{ top: '16px', right: '16px', left: 'auto' }}
    css={{ zIndex: 1, position: 'relative' }}
    onClick={() => changeStatusAgendaModal()}
    color={color('text-bk-20')}
  >
    <Icon type="mdiClear" css={{ width: '24px', height: '24px' }} />
  </Button>
)

QuitButton.displayName = 'QuitButton'

const RecommendPopup: React.FC = () => {
  const { t } = useTranslation()
  return (
    <>
      <Box
        pad={{ horizontal: '16px', vertical: '10px' }}
        border={{ color: color('border-bk-10'), size: '1px', style: 'solid' }}
        css={{
          position: 'absolute',
          bottom: '4px',
          right: '16px',
          borderRadius: '8px',
          boxShadow: '0px 1px 16px rgb(0 0 0 / 10%)',
        }}
      >
        <StyledText>{t('OKR_NOTIFICATION_NAVIGATE')}</StyledText>
      </Box>
      <Box
        width="20px"
        height="13px"
        border={{ color: color('white-100'), size: '10px', style: 'solid' }}
        css={{
          position: 'absolute',
          bottom: '-5px',
          right: '50px',
          borderBottom: '10px solid transparent',
          borderLeft: '10px solid transparent',
        }}
      />
    </>
  )
}

RecommendPopup.displayName = 'RecommendPopup'

const makeRecommendAgenda = (
  t: TFunction,
  item: RecommendAgendaItemProps['item'],
): { title: string; description: string } | null => {
  if (item.__typename === 'NoProgressKeyResult') {
    return {
      title: t('NO_PROGRESS_KEY_RESULT_TITLE', {
        keyResultName: item.keyResult.name,
      }),
      description: t('NO_PROGRESS_KEY_RESULT_DESCRIPTION', {
        days: item.days,
      }),
    }
  }

  if (item.__typename === 'NeverProgressKeyResult') {
    return {
      title: t('NEVER_PROGRESS_KEY_RESULT_TITLE', {
        keyResultName: item.keyResult.name,
      }),
      description: t('NEVER_PROGRESS_KEY_RESULT_DESCRIPTION', {
        days: item.createdDaysAgo,
      }),
    }
  }

  if (item.__typename === 'RiseKeyResultProgressRate') {
    return {
      title: t('RISE_KEY_RESULT_PROGRESS_RATE_TITLE', {
        keyResultName: item.keyResult.name,
      }),
      description: t('RISE_KEY_RESULT_PROGRESS_RATE_DESCRIPTION', {
        delta: item.progressRateDelta,
      }),
    }
  }

  if (item.__typename === 'OverKeyResultProgressRate') {
    return {
      title: t('OVER_KEY_RESULT_PROGRESS_RATE_TITLE', {
        keyResultName: item.keyResult.name,
        overProgressRate: item.over,
      }),
      description: t('OVER_KEY_RESULT_PROGRESS_RATE_DESCRIPTION', {
        overProgressRate: item.over,
      }),
    }
  }

  if (item.__typename === 'LowKeyResultConfidence') {
    if (!item.keyResult.latestConfidenceScore) {
      return null
    }
    return {
      title: t('LOW_KEY_RESULT_CONFIDENCE_TITLE', {
        keyResultName: item.keyResult.name,
      }),
      description: t('LOW_KEY_RESULT_CONFIDENCE_DESCRIPTION'),
    }
  }

  if (item.__typename === 'BasicRecommendAgenda') {
    if (item.type === 'FOLLOW_GOAL') {
      return { title: t('FOLLOW_GOAL_TITLE'), description: t('FOLLOW_GOAL_DESCRIPTION') }
    }
    if (item.type === 'LOOK_BACK_OKR_TERM') {
      return {
        title: t('LOOK_BACK_OKR_TERM_TITLE'),
        description: t('LOOK_BACK_OKR_TERM_DESCRIPTION'),
      }
    }
    if (item.type === 'CONVERSATION_NEXT_OKR_TERM') {
      return {
        title: t('CONVERSATION_NEXT_OKR_TERM_TITLE'),
        description: t('CONVERSATION_NEXT_OKR_TERM_DESCRIPTION'),
      }
    }
  }

  return null
}

type RecommendAgendaItemProps = {
  item: RecommendAgendas[number]
  isRegistered: boolean
  handleRegisterRecommendAgenda: (id: string, title: string) => void
}

const RecommendAgendaItem: React.VFC<RecommendAgendaItemProps> = memo(
  ({ item, isRegistered, handleRegisterRecommendAgenda }) => {
    const { t } = useTranslation()

    const recommendAgenda = makeRecommendAgenda(t, item)
    const handleClickRegisterButton = useCallback<MouseEventHandler<HTMLButtonElement>>(
      (e) => {
        e.stopPropagation()
        if (isRegistered || !recommendAgenda) {
          return
        }
        handleRegisterRecommendAgenda(item.id, recommendAgenda.title)
      },
      [handleRegisterRecommendAgenda, isRegistered, item.id, recommendAgenda],
    )

    return recommendAgenda ? (
      <Box direction="row" justify="between" gap="32px" css={{ maxWidth: '100%' }}>
        <div>
          <div
            css={{
              marginBottom: '8px',
              fontSize: '14px',
              fontWeight: 600,
              color: isRegistered ? color('text-bk-50') : color('text-bk-100'),
            }}
          >
            {recommendAgenda.title}
          </div>
          <Box direction="row" align="start">
            <Icon type="speechBalloon" />
            <Box
              css={{
                fontSize: '12px',
                lineHeight: '18px',
                color: color('text-bk-50'),
                marginLeft: '10px',
              }}
            >
              {recommendAgenda.description}
            </Box>
          </Box>
        </div>
        <div>
          <Button
            style={{
              background: isRegistered ? color('white-100') : color('resily-orange-100'),
              color: isRegistered ? color('text-bk-100') : color('white-100'),
              border: `1px solid ${
                isRegistered ? color('border-bk-30') : color('resily-orange-100')
              }`,
              pointerEvents: isRegistered ? 'none' : 'auto',
            }}
            css={{
              borderRadius: '4px',
              fontSize: '14px',
              padding: '14px 16px',
              width: '102px',
              height: '40px',
              fontWeight: 600,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onClick={handleClickRegisterButton}
          >
            {isRegistered ? t('REGISTERED') : t('REGISTRATION')}
          </Button>
        </div>
      </Box>
    ) : null
  },
  (prev, next) => {
    if (!isEqual(prev.item, next.item)) {
      return false
    }
    if (prev.isRegistered !== next.isRegistered) {
      return false
    }
    if (!isEqual(prev.handleRegisterRecommendAgenda, next.handleRegisterRecommendAgenda)) {
      return false
    }
    return true
  },
)

RecommendAgendaItem.displayName = 'RecommendAgendaItem'
