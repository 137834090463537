import { TextProps } from 'grommet'

import { useTranslation } from '../../../i18n'
import { barColorConfidence } from '../../../lib/confidence'
import { levelToLabel, numberToConfidence } from '../../../lib/domain/confidence'
import { color } from '../../../styles/newColors'
import { Props as StyledTextProps, StyledText } from '../../ui/StyledText'

type Size = 'small' | 'medium' | 'activity-confidence-card'

export type Props = {
  size: Size
  fontColor?: Parameters<typeof color>[0]
  weight?: TextProps['weight']
  confidenceLevel: number | undefined
}

const styles: {
  [key in Size]: {
    fontSize: StyledTextProps['size']
    lineHeight: `${number}px`
    margin: `${number}px`
    dotSize: `${number}px`
  }
} = {
  small: {
    fontSize: 'xsmall',
    lineHeight: '14px',
    margin: '2px',
    dotSize: '6px',
  },
  medium: {
    fontSize: 'small',
    lineHeight: '20px',
    margin: '4px',
    dotSize: '10px',
  },
  'activity-confidence-card': {
    fontSize: 'small',
    lineHeight: '24px',
    margin: '4px',
    dotSize: '10px',
  },
}

export const ConfidenceTag: React.VFC<Props> = ({
  size,
  fontColor = 'text-bk-100',
  weight,
  confidenceLevel,
}) => {
  const { t } = useTranslation()

  const confidenceColor = barColorConfidence(confidenceLevel)
  const confidenceLabel = levelToLabel(numberToConfidence(confidenceLevel || 0))

  const { fontSize, lineHeight, margin, dotSize } = styles[size]

  return (
    <div css={{ display: 'flex', alignItems: 'center' }}>
      <div
        css={{
          backgroundColor: color(confidenceColor),
          width: dotSize,
          height: dotSize,
          borderRadius: '50%',
        }}
      />
      <StyledText
        size={fontSize}
        lineHeight={lineHeight}
        color={fontColor}
        css={{ marginLeft: margin, whiteSpace: 'nowrap' }}
        weight={weight}
      >
        {t(confidenceLabel)}
      </StyledText>
    </div>
  )
}

ConfidenceTag.displayName = 'ConfidenceTag'
