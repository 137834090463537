import { TextLink, Tooltip } from '@resily/geisha'

import { useTranslation } from '../../../i18n'
import { Button } from '../../ui/Button'
import { Icon } from '../../ui/Icon'

import { useStyles } from './CheckinButton.styles'

export type CheckinButtonProps = {
  isNotOkrPeriod?: boolean | null | undefined
  isNotAssignee?: boolean | null | undefined
  alreadyCheckin?: boolean | null | undefined
  onClick?: () => void
}

export const CheckinButton: React.VFC<CheckinButtonProps> = ({
  isNotOkrPeriod = false,
  isNotAssignee = false,
  alreadyCheckin = false,
  onClick,
}) => {
  const { t } = useTranslation()
  const styles = useStyles()

  if (isNotOkrPeriod || isNotAssignee) {
    // チェックイン期間外の場合 or 責任者・担当者ではない場合
    return (
      <Tooltip
        label={
          <span>
            {isNotOkrPeriod ? t('CHECKIN_IN_THIS_WEEK_TOOLTIP') : t('ONLY_ASSIGNEE_TOOLTIP')}
          </span>
        }
        position="bottom"
      >
        <span>
          <Button
            size="ss"
            newColor="resily-orange-100"
            disabledColor="resily-orange-100"
            weight="normal"
            disabled
          >
            {t('CHECKIN')}
          </Button>
        </span>
      </Tooltip>
    )
  }

  if (alreadyCheckin) {
    // チェックイン済みの場合
    return (
      <TextLink type="secondary" css={styles.editThisWeeksPost} onClick={onClick}>
        <Icon type="editSimple" width={14} height={14} />
        {t('EDIT_X', { x: t('THIS_WEEKS_POST') })}
      </TextLink>
    )
  }

  return (
    <Button
      size="ss"
      newColor="resily-orange-100"
      disabledColor="resily-orange-100"
      weight="normal"
      onClick={onClick}
    >
      {t('CHECKIN')}
    </Button>
  )
}
