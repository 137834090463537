import { Icon } from '../../../../../../components/ui/Icon'
import { Modal } from '../../../../../../components/ui/Modal'
import { StyledText } from '../../../../../../components/ui/StyledText'
import { useTranslation } from '../../../../../../i18n'
import { color } from '../../../../../../styles/newColors'
import { ExternalUrls } from '../../../../../../urls'

type Props = {
  isOpened: boolean
  loading: boolean
  onClickExecute: () => void
  onClickCancel: () => void
}

export const TwoFactorAuthCreateModal: React.FC<Props> = ({
  isOpened,
  loading,
  onClickExecute,
  onClickCancel,
}) => {
  const { t } = useTranslation()

  return (
    <Modal
      isOpened={isOpened}
      title={t('ENABLE_TWO_FACTOR_AUTHENTICATION')}
      onClickCancel={onClickCancel}
      onClickExecute={onClickExecute}
      disabledCancel={loading}
      disabledExecute={loading}
      executeText={t('ACTIVATION')}
    >
      <StyledText lineHeight="22px" color="text-bk-100">
        {t('CAUTION_ENABLE_TWO_FACTOR_AUTHENTICATION')}
      </StyledText>
      <div css={{ display: 'flex', marginTop: '24px' }}>
        <StyledText color="text-bk-100" css={{ width: '50px' }}>
          {t('NOTE_X', { x: 1 })}
        </StyledText>
        <StyledText css={{ width: '430px' }}>
          {t('CAUTION_ENABLE_TWO_FACTOR_AUTHENTICATION_NOT_LOGIN')}
        </StyledText>
      </div>
      <div css={{ display: 'flex', marginTop: '12px' }}>
        <StyledText color="text-bk-100" css={{ width: '50px' }}>
          {t('NOTE_X', { x: 2 })}
        </StyledText>
        <StyledText css={{ width: '430px' }}>
          {t('CAUTION_ENABLE_TWO_FACTOR_AUTHENTICATION_SELF_SETTING')}
        </StyledText>
      </div>
      <div css={{ marginTop: '24px' }}>
        <a
          css={{
            color: color('resily-orange-100'),
            textDecoration: 'none',
            fontSize: '12px',
            lineHeight: '20px',
          }}
          href={ExternalUrls.TWO_FACTOR_AUTHENTICATION_SET_UP_GUIDE}
          rel="noopener noreferrer"
          target="_blank"
        >
          <span>{t('HELP_LINK_TWO_FACTOR_AUTHENTICATION')}</span>
          <span css={{ marginLeft: '2px' }}>
            <Icon type="linkPage" width={14} height={14} css={{ verticalAlign: 'middle' }} />
          </span>
        </a>
      </div>
    </Modal>
  )
}

TwoFactorAuthCreateModal.displayName = 'TwoFactorAuthCreateModal'
