import { css } from '@emotion/react'

import { border } from '../../../../../../../styles/border'
import { fontSize } from '../../../../../../../styles/font'

export const fixedAgendaRowsWrapperCss = css({
  borderTop: border('simple-10'),
  '> *': {
    borderBottom: border('simple-10'),
  },
})

export const emptyCss = css({
  padding: '40px 0',
  textAlign: 'center',
  borderTop: border('simple-10'),
  borderBottom: border('simple-10'),
  ...fontSize('medium', 'regular'),
})
