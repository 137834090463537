import { useModal } from '@resily/geisha'
import { useCallback, useMemo } from 'react'

import { useEditingStateMachine } from '../../../../contexts/EditingStateContext'
import { Screen } from '../../../../lib/screen'
import { tracker } from '../../../../lib/tracking'
import {
  OkrCreateModalContainer,
  Props as OkrCreateModalContainerProps,
} from '../OkrCreateModalContainer'

export type Props = Partial<
  Pick<
    OkrCreateModalContainerProps,
    'initialObjectiveStateOverride' | 'onCompletedCreate' | 'onClose'
  >
>

type OpenFunc = (
  screen: Screen,
  trackerName?: Extract<keyof typeof tracker, 'ClickAddOKR' | 'ClickAddOKRAsChild'>,
) => void

export const useOkrCreateModal = ({ onClose, ...props }: Props): [React.ReactNode, OpenFunc] => {
  const [Modal, isOpen, { open, close }] = useModal()

  const { requestStartEdit, updateIsDirty, discardEdit, finishEdit } = useEditingStateMachine()

  const OkrCreateModal = useMemo(
    () =>
      isOpen ? (
        <OkrCreateModalContainer
          modalComponent={Modal}
          isOpen
          onEdit={updateIsDirty}
          close={() => {
            close()
            finishEdit()
          }}
          onClose={() => {
            onClose?.()
            discardEdit()
          }}
          {...props}
        />
      ) : null,
    [Modal, close, discardEdit, finishEdit, isOpen, onClose, props, updateIsDirty],
  )

  const openOkrCreateModal = useCallback<OpenFunc>(
    (screen, trackerName = 'ClickAddOKR') => {
      tracker[trackerName](screen)
      requestStartEdit(() => {
        open()
        return close
      }, 'OKR_CREATE_MODAL')
    },
    [close, open, requestStartEdit],
  )

  return [OkrCreateModal, openOkrCreateModal]
}
