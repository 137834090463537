import { css, SerializedStyles } from '@emotion/react'
import { ChangeEvent, Fragment, ReactElement, ReactNode, useCallback, useMemo } from 'react'

import { fontSize, FontSizeAlias } from '../../../styles/font'
import { color } from '../../../styles/newColors'

const tabBox = css({ borderBottom: `1px solid ${color('border-bk-10')}` })
const tabListBox = css({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
})
const tabListInner = css({
  display: 'flex',
})
const tabCss = css({
  display: 'none',
  '+ label': {
    ':hover': {
      color: color('resily-orange-100'),
      backgroundColor: color('hover-background-bk-5'),
    },
  },
  '&:checked + label': {
    color: color('resily-orange-100'),
    borderBottom: `2px solid ${color('resily-orange-100')}`,
  },
})
const rightSectionCss = css({
  display: 'inline-block',
  paddingLeft: 4,
})

export type TabType<T extends string = string> = {
  value: T
  name: string
  rightSection?: ReactNode
}

export type Props<T extends string = string> = {
  tabs: ReadonlyArray<TabType<T>>
  /** 'tabName' is radio type name of input tag */
  tabName?: string
  /** For displaying the active tab */
  tab: T | null
  size?: Extract<FontSizeAlias, 'medium' | 'small'>
  onClickTab: (tab: TabType<T>) => void
  css?: SerializedStyles
}

export const Tabs = <T extends string = string>({
  tabs,
  tabName = 'tab-name',
  tab,
  size = 'medium',
  onClickTab,
  css: tabBoxCss = {} as SerializedStyles,
  ...props
}: Props<T>): ReactElement => {
  const labelCss = useMemo(
    () =>
      css({
        cursor: 'pointer',
        color: color('text-bk-50'),
        ...fontSize(size, 'regular'),
        padding: size === 'medium' ? '12px 16px' : '10px',
      }),
    [size],
  )

  const onChange = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement> & { target: { id: string } }) => {
      const findTab = tabs.find((t) => t.value === target.id)
      if (!findTab) return
      onClickTab(findTab)
    },
    [onClickTab, tabs],
  )

  const boxCss = useMemo(() => css([tabBox, tabBoxCss]), [tabBoxCss])

  return (
    <div css={boxCss} {...props}>
      <div css={tabListBox}>
        <div css={tabListInner}>
          {tabs.map(({ name, value, rightSection }) => (
            <Fragment key={value}>
              <input
                type="radio"
                id={value}
                name={tabName}
                checked={value === tab}
                css={tabCss}
                onChange={onChange}
              />
              <label htmlFor={value} css={labelCss}>
                {name}
                {rightSection != null && <div css={rightSectionCss}>{rightSection}</div>}
              </label>
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  )
}
