import { config } from '../../../lib/client'
import {
  OkrTermsDocument,
  OkrTermsQuery,
  OkrTermsQueryVariables,
  TermFragment,
  useCopyOkrTermMutation,
  useCreateOkrTermMutation,
  useDeleteOkrTermMutation,
  useOkrTermsQuery,
  useUpdateOkrTermMutation,
} from '../api/graphql'

export const useInjection = (): null | {
  okrTerms: ReadonlyArray<TermFragment>
  deleteOkrTerm: (variables: { id: string }) => Promise<void>
  createOkrTerm: (variables: { startDate: Date; endDate: Date; name: string }) => Promise<void>
  updateOkrTerm: (variables: {
    id: string
    startDate: Date
    endDate: Date
    name: string
  }) => Promise<void>
  copyOkrTerm: (variables: {
    id: string
    startDate: Date
    endDate: Date
    name: string
  }) => Promise<void>
  downloadOkrTerm: (termId: string) => Promise<void>
} => {
  const { data, refetch } = useOkrTermsQuery()

  const [deleteOKRTerm] = useDeleteOkrTermMutation({
    onCompleted() {
      refetch()
    },
    onError: (err) => {
      throw err
    },
  })
  const deleteOkrTermMutation = async (variables: { id: string }) => {
    await deleteOKRTerm({ variables })
  }

  const [createOKRTerm] = useCreateOkrTermMutation({
    update(cache, { data: createOkrTermData }) {
      if (!createOkrTermData) {
        return
      }

      cache.writeQuery<OkrTermsQuery, OkrTermsQueryVariables>({
        query: OkrTermsDocument,
        data: { okrTerms: createOkrTermData.createOkrTerm.organization.okrTerms },
      })
    },
    onError: (err) => {
      throw err
    },
  })

  const createOKRTermMutation = async (variables: {
    startDate: Date
    endDate: Date
    name: string
  }) => {
    await createOKRTerm({ variables })
  }

  const [updateOKRTerm] = useUpdateOkrTermMutation({
    update(cache, { data: updateOkrTermData }) {
      if (!updateOkrTermData) {
        return
      }

      cache.writeQuery<OkrTermsQuery, OkrTermsQueryVariables>({
        query: OkrTermsDocument,
        data: { okrTerms: updateOkrTermData.updateOkrTerm.organization.okrTerms },
      })
    },
    onError: (err) => {
      throw err
    },
  })

  const updateOkrTermMutation = async (variables: {
    id: string
    startDate: Date
    endDate: Date
    name: string
  }) => {
    await updateOKRTerm({ variables })
  }

  const [copyOKRTerm] = useCopyOkrTermMutation({
    update(cache, { data: copyOkrTermData }) {
      if (!copyOkrTermData) {
        return
      }

      cache.writeQuery<OkrTermsQuery, OkrTermsQueryVariables>({
        query: OkrTermsDocument,
        data: { okrTerms: copyOkrTermData.copyOkrTerm.organization.okrTerms },
      })
    },
    onError: (err) => {
      throw err
    },
  })
  const copyOKRTermMutation = async (variables: {
    id: string
    startDate: Date
    endDate: Date
    name: string
  }) => {
    await copyOKRTerm({ variables })
  }

  const downloadOkrTerm = async (termId: string) => {
    const a = document.createElement('a')
    document.body.appendChild(a)
    a.href = `${config.base}/export/okr/terms/${termId}`
    a.click()
    a.remove()
  }

  if (!data) return null

  return {
    okrTerms: data.okrTerms,
    deleteOkrTerm: deleteOkrTermMutation,
    createOkrTerm: createOKRTermMutation,
    updateOkrTerm: updateOkrTermMutation,
    copyOkrTerm: copyOKRTermMutation,
    downloadOkrTerm,
  }
}
