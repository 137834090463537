import { Box, ColumnConfig } from 'grommet'
import { Checkmark } from 'grommet-icons'
import { useContext } from 'react'

import { DateTime } from '../../../../../../components/ui/DateTime'
import { PopoverPortal } from '../../../../../../components/ui/PopoverPortal'
import { StyledText } from '../../../../../../components/ui/StyledText'
import { WrapText } from '../../../../../../components/ui/WrapText'
import { OrganizationContext } from '../../../../../../contexts/OrganizationContext'
import { useTranslation } from '../../../../../../i18n'
import { fontSize } from '../../../../../../styles/font'
import { color } from '../../../../../../styles/newColors'
import { ExternalUrls } from '../../../../../../urls'
import { titleCss } from '../../styles'
import { ActivatedUserTableDatum } from '../types'

export const useDataTableColumns = (): Array<ColumnConfig<ActivatedUserTableDatum>> => {
  const { t } = useTranslation()
  const organization = useContext(OrganizationContext)

  const column: Array<ColumnConfig<ActivatedUserTableDatum>> = [
    {
      property: 'fullName',
      header: (
        <StyledText size="small" fontStyle="bold" css={titleCss}>
          {t('USER')}
        </StyledText>
      ),
      search: true,
      sortable: true,
      size: '200px',
      render: (datum: ActivatedUserTableDatum) => (
        <WrapText
          css={{
            ...fontSize('medium', 'regular'),
          }}
          text={datum.fullName}
          maxWidth="199px"
        />
      ),
    },
    {
      property: 'email',
      header: (
        <StyledText size="small" fontStyle="bold" css={titleCss}>
          {t('EMAIL')}
        </StyledText>
      ),
      primary: true,
      search: true,
      sortable: true,
      render: (datum: ActivatedUserTableDatum) => (
        <WrapText
          css={{
            ...fontSize('medium', 'regular'),
          }}
          text={datum.email}
          maxWidth="291px"
        />
      ),
    },
    {
      property: 'admin',
      header: (
        <Box direction="row" gap="4px">
          <StyledText size="small" fontStyle="bold" css={titleCss}>
            {t('ADMINISTRATOR')}
          </StyledText>
          <PopoverPortal
            portalPosition={{
              top: 13,
              left: -200,
            }}
            icon="help"
            iconCss={{
              color: color('text-bk-50'),
              ':hover': {
                color: color('resily-orange-100'),
              },
            }}
            text={t('ADMINISTRATOR_HELP')}
            link={{
              text: t('SUPPORT_SITE'),
              href: ExternalUrls.ADMIN_PERMISSION,
            }}
            css={{
              display: 'flex',
              justifyContent: 'center',
            }}
            hasGap={false}
          />
        </Box>
      ),
      align: 'center',
      sortable: true,
      size: '110px',
      render: (datum: ActivatedUserTableDatum) => (
        <Box align="center">{datum.admin && <Checkmark />}</Box>
      ),
    },
    {
      property: 'twoFa',
      header: (
        <StyledText size="small" fontStyle="bold" css={titleCss}>
          {t('TWO_FACTOR_AUTH')}
        </StyledText>
      ),
      align: 'center',
      sortable: true,
      size: '110px',
      render: (datum: ActivatedUserTableDatum) => (
        <Box align="center">{datum.twoFa && <Checkmark />}</Box>
      ),
    },
    {
      property: 'isSamlEnabled',
      header: (
        <StyledText size="small" fontStyle="bold" css={titleCss}>
          {t('SAML_AUTH')}
        </StyledText>
      ),
      align: 'center',
      sortable: true,
      size: '110px',
      render: (datum: ActivatedUserTableDatum) => (
        <Box align="center">{datum.isSamlEnabled && <Checkmark />}</Box>
      ),
    },
    {
      property: 'createdAt',
      header: (
        <StyledText size="small" fontStyle="bold" css={titleCss}>
          {t('STARTED_AT')}
        </StyledText>
      ),
      sortable: true,
      size: '110px',
      render: (datum: ActivatedUserTableDatum) => {
        if (!datum.createdAt) {
          return null
        }
        return (
          <DateTime fontStyle="regular" datetime={new Date(datum.createdAt * 1000)} withoutTime />
        )
      },
    },
    {
      property: 'lastLogin',
      header: (
        <StyledText size="small" fontStyle="bold" css={titleCss}>
          {t('LAST_LOGIN_AT')}
        </StyledText>
      ),
      sortable: true,
      size: '170px',
      render: (datum: ActivatedUserTableDatum) => {
        if (!datum.lastLogin) {
          return null
        }
        return <DateTime fontStyle="regular" datetime={new Date(datum.lastLogin * 1000)} />
      },
    },
  ]

  // 組織でsaml認証が有効になっていない場合は「saml認証」カラムを取り除く
  if (!organization?.isSamlEnabled) {
    column.splice(
      column.findIndex((el) => el.property === 'isSamlEnabled'),
      1,
    )
  }

  return column
}
