import { useProfiler } from '@sentry/react'
import { Box } from 'grommet'
import { VFC, useLayoutEffect, useRef } from 'react'

import { useTranslation } from '../../../i18n'
import { useClickOutside } from '../../../lib/clickOutside'
import { color, hex2rgba } from '../../../styles/newColors'

export type Props = React.PropsWithoutRef<JSX.IntrinsicElements['ul']> & {
  overrideParentZindex?: number
  onClickOutside: () => void
  onClickOkrPage: () => void
  onClickExpandTree: () => void
  onClickLookAtInfo: () => void
}

export const DotMenu: VFC<Props> = ({
  overrideParentZindex,
  onClickOutside,
  onClickOkrPage,
  onClickExpandTree,
  onClickLookAtInfo,
  ...props
}) => {
  useProfiler('DotMenu')
  const { t } = useTranslation()

  const ref = useRef<HTMLUListElement>(null)

  useClickOutside({ callback: onClickOutside, ref })

  useLayoutEffect(() => {
    if (!overrideParentZindex || !ref.current || !ref.current.parentElement) {
      return
    }
    ref.current.parentElement.style.zIndex = overrideParentZindex.toString()
  }, [overrideParentZindex, ref.current?.parentElement])

  return (
    <ul
      ref={ref}
      css={{
        backgroundColor: color('white-100'),
        border: `1px solid ${color('border-bk-30')}`,
        borderRadius: '4px',
        boxShadow: `0 4px 16px 0 ${hex2rgba(color('text-bk-100'), 16)}`,

        li: {
          padding: '8px 10px',
          lineHeight: '14px',
          textAlign: 'center',
          ':hover': { color: color('resily-orange-100') },
          ':not(:last-of-type)': { borderBottom: `1px solid ${color('border-bk-30')}` },
        },
      }}
      {...props}
    >
      <Box as="li" onClick={onClickOkrPage}>
        {t('X_PAGE', { x: t('OKR') })}
      </Box>
      <Box as="li" onClick={onClickExpandTree}>
        {t('DISPLAY_X', { x: t('OKR_CARD') })}
      </Box>
      <Box as="li" onClick={onClickLookAtInfo}>
        {t('LOOK_AT_X', { x: t('INFO') })}
      </Box>
      {/* TODO: 後で実装 */}
      {/* <Box as="li">リンクを共有</Box> */}
    </ul>
  )
}

DotMenu.displayName = 'DotMenu'
