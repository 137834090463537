import { useState, useCallback } from 'react'

type WrapFunc = <T>(apiFunc: Promise<T>) => Promise<T>

export const useApiProcessing = (): [boolean, WrapFunc] => {
  const [isApiProcessing, setIsApiProcessing] = useState<boolean>(false)
  const wrapApiPromise = useCallback<WrapFunc>((apiFunc) => {
    setIsApiProcessing(true)
    return apiFunc.finally(() => setIsApiProcessing(false))
  }, [])

  return [isApiProcessing, wrapApiPromise]
}
