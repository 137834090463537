import { css } from '@emotion/react'
import { useMemo } from 'react'

import { color } from '../../../../../styles/newColors'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useStyles = () =>
  useMemo(
    () =>
      ({
        root: css({ margin: '0 24px 24px' }),
        header: css({
          display: 'flex',
          justifyContent: 'space-between',
          margin: '12px 0 8px',
        }),
        editButton: css({
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 4,
          ':hover': {
            svg: { color: color('resily-orange-100') },
            span: { color: color('resily-orange-100') },
          },
        }),
        headerButtons: css({ display: 'flex', alignItems: 'center', gap: 8 }),
        saveButton: css({
          fontSize: 12,
          height: 32,
          lineHeight: '32px',
          paddingLeft: 12,
          paddingRight: 12,
        }),
      } as const),
    [],
  )
