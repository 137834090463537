import { useParams, useNavigate } from 'react-router-dom'

import { BreadcrumbParams } from '../../components/domain/Breadcrumb'
import { useTranslation } from '../../i18n'
import { useOkrTermLoadable } from '../../lib/domain/useOkrTermLoadable'
import { convertToFileInput } from '../../lib/fileInput'
import { usePrompt } from '../../lib/prompt'
import { AuthRouteProps } from '../../types/authRouteProps'
import { generateObjective, objectiveEdit as objectiveEditPath } from '../../urls'

import {
  FileFragment,
  OkrDetailObjectiveFragment,
  useOkrNodeQuery,
  useUpdateObjectiveDescriptionMutation,
  useUpdateObjectiveNameMutation,
  DocumentFragment,
} from './graphql'

import type { Props } from './index'

export const useInjection = ({ onOkrTermLoaded }: AuthRouteProps): null | Props => {
  const { objectiveId } = useParams()
  const { data } = useOkrNodeQuery({
    variables: { objectiveId },
    // NOTE: 古い情報が表示されてしまうためキャッシュを無効にする
    fetchPolicy: 'network-only',
  })
  const [updateObjectiveName] = useUpdateObjectiveNameMutation()
  const [updateObjectiveDescription] = useUpdateObjectiveDescriptionMutation()

  useOkrTermLoadable(onOkrTermLoaded, undefined, data?.okrNode.term.id)

  const navigate = useNavigate()
  const { t } = useTranslation()
  const originPath = window.location.pathname + window.location.search + window.location.hash
  const [, setNeedBlock] = usePrompt({ message: t('LEAVE_WARNING'), originPath })

  const { objective } = data?.okrNode || {}
  if (!objective || !data?.okrNode) {
    return null
  }

  const updateName = async (name: string) => {
    await updateObjectiveName({
      variables: {
        id: objective.id,
        name,
      },
    })
  }

  const updateDescription = async (
    description: string,
    plainText: string,
    beforeDescription: string,
    beforePlainText: string,
    attachments: ReadonlyArray<FileFragment>,
  ) => {
    await updateObjectiveDescription({
      variables: {
        input: {
          id: objective.id,
          description: {
            treeJson: description,
            plainText,
          },
          beforeDescription: {
            treeJson: beforeDescription,
            plainText: beforePlainText,
          },
          attachments: attachments.map((attachment) => convertToFileInput(attachment)),
        },
      },
    })
  }

  const { okrNode } = data

  const breadcrumbs: BreadcrumbParams = {
    url: objectiveEditPath,
    items: [
      {
        breadcrumbName: 'okr',
        ...okrNode,
      },
      {
        breadcrumbName: 'objectiveEdit',
        ...okrNode.objective,
        node: {
          id: okrNode.id,
        },
      },
    ],
  }

  const onUpdateObjective = async (
    { name, description, attachments }: OkrDetailObjectiveFragment,
    beforeDescription: DocumentFragment,
    redirect: boolean,
  ) => {
    await updateName(name)
    await updateDescription(
      description?.treeJson || '',
      description?.plainText || '',
      beforeDescription?.treeJson || '',
      beforeDescription?.plainText || '',
      attachments,
    )
    setNeedBlock(false)
    if (redirect) {
      navigate(generateObjective(okrNode.id, objective.id))
    }
  }

  const onClickCancel = () => {
    navigate(generateObjective(okrNode.id, objective.id))
  }

  return {
    objective,
    breadcrumbs,
    onUpdateObjective,
    onClickCancel,
    setNeedBlock,
  }
}
