import { Text } from 'grommet'
import { useRef, useEffect, useState } from 'react'

import { useTranslation } from '../../../i18n/index'
import { border } from '../../../styles/border'
import { fontSize } from '../../../styles/font'
import { color, ColorAliasObj } from '../../../styles/newColors'
import { generateHome } from '../../../urls'
import { Avatar, sizes as AvatarSizes } from '../../ui/Avatar'
import { Link } from '../../ui/Link'
import { UserTooltipNew } from '../../ui/TooltipNew'

import { UserFragment } from './graphql'

export type Props = UserFragment & {
  termId?: string
  onClick?: () => void
  hoverBackground?: string
  maxWidth?: string
  isUserDisabled: boolean
}
const defaultHoverBackground = color('hover-background-bk-5')

export const UserTag: React.FC<Props> = ({
  termId,
  id,
  firstName,
  lastName,
  avatar,
  onClick,
  hoverBackground,
  maxWidth,
  isUserDisabled,
}) => {
  const { t } = useTranslation()
  const nameFontSizeCss = { ...fontSize('medium') }
  const nameTextRef = useRef<HTMLSpanElement>(null)
  const boxRef = useRef<HTMLDivElement>(null)
  const [tooltipDisabled, setTooltipDisabled] = useState(false)

  const disabledStyle = !isUserDisabled ? { opacity: '1' } : { opacity: '0.1' }

  // domのレンダリング後にテキストが省略されているか判定してツールチップ表示設定を行う
  useEffect(() => {
    if (!nameTextRef.current || !boxRef.current) return
    const boxWidth = boxRef.current.getBoundingClientRect().width
    const { width: avatarWidth } = AvatarSizes.xsmall
    const nameTextWidth = boxWidth - avatarWidth
    if (maxWidth) {
      const matches = maxWidth.match(/\d+/)
      const maxWidthValue = matches?.length ? matches[0] : 0
      setTooltipDisabled(maxWidthValue > nameTextWidth)
      return
    }
    setTooltipDisabled(nameTextRef.current.getBoundingClientRect().width <= nameTextWidth)
  }, [nameTextRef, boxRef, maxWidth])

  const common = (
    <div
      css={{
        display: 'inline-block',
        maxWidth: '100%',
        border: border('simple-10'),
        borderRadius: '12px',
        padding: '4px',
        ':hover': {
          color: color('text-bk-100'),
          backgroundColor: hoverBackground === undefined ? defaultHoverBackground : hoverBackground,
        },
      }}
    >
      <div
        ref={boxRef}
        css={{
          display: 'flex',
          alignItems: 'center',
          maxWidth: '100%',
        }}
      >
        <Avatar
          size="xsmall"
          firstName={firstName}
          lastName={lastName}
          avatarUrl={avatar?.url}
          tooltipDisabled={tooltipDisabled}
          hoverStyle={{ backgroundColor: 'none' }}
          disabledStyle={disabledStyle}
          isUserDisabled={isUserDisabled}
        />
        <UserTooltipNew
          title={t('FULL_NAME', { firstName, lastName })}
          disabled={tooltipDisabled}
          css={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            maxWidth: maxWidth || 'none',
          }}
          isUserDisabled={isUserDisabled}
        >
          <Text
            ref={nameTextRef}
            css={{
              ...nameFontSizeCss,
              color: color('text-bk-100'),
              lineHeight: '20px',
              margin: '0 6px',
              whiteSpace: 'nowrap',
            }}
          >
            {!isUserDisabled ? (
              <span>{t('FULL_NAME', { firstName, lastName })}</span>
            ) : (
              <span css={{ color: ColorAliasObj['text-bk-20'] }}>
                ({t('DISABLING')})
                <span css={{ textDecoration: 'line-through' }}>
                  {t('FULL_NAME', { firstName, lastName })}
                </span>
              </span>
            )}
          </Text>
        </UserTooltipNew>
      </div>
    </div>
  )

  if (!termId) return common

  return (
    <Link
      method="newTab"
      onClick={onClick}
      href={onClick ? undefined : generateHome(termId)(id)}
      css={{
        maxWidth: '100%',
      }}
    >
      {common}
    </Link>
  )
}

UserTag.displayName = 'UserTag'
