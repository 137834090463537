import { css } from '@emotion/react'
import { Box } from 'grommet'
import React from 'react'

import { border } from '../../../styles/border'
import { color as colorCode, ColorAlias } from '../../../styles/newColors'

export type Props = {
  padding?: string | number
  children: React.ReactNode
  color?: ColorAlias
}

export const Card: React.FC<Props> = ({
  children,
  padding = '32px',
  color = 'white-100',
  ...props
}) => {
  const cssCard = css({
    backgroundColor: colorCode(color),
    padding,
    border: border('simple-30'),
    borderRadius: '4px',
  })
  return (
    <Box css={cssCard} {...props}>
      {children}
    </Box>
  )
}

Card.displayName = 'Card'
