import { PropsWithoutRef } from 'react'

import { fontSize, FontSizeAlias } from '../../../styles/font'
import * as color from '../../../styles/newColors'
import { ErrorMessage } from '../ErrorMessage'

export type Props = PropsWithoutRef<JSX.IntrinsicElements['div']> & {
  label?: string
  subLabel?: string
  errorMessage?: string
  labelColor?: color.ColorAlias
  labelFontSize?: FontSizeAlias
  touched?: boolean // 編集済みかつ未反映のフラグ
}

// FIXME: 変更済み未反映項目のスタイル
const touchedStyle = {
  color: '#666',
}

export const FormField: React.FC<Props> = ({
  children,
  label,
  subLabel,
  errorMessage,
  labelColor,
  labelFontSize = 'medium',
  touched,
  ...props
}) => {
  const fs = fontSize(labelFontSize)
  return (
    <div
      css={{
        marginTop: 18,
        marginBottom: 18,
      }}
      {...props}
    >
      {label ? (
        <div
          className="form-label"
          css={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 8,
            ...(touched ? touchedStyle : {}),
          }}
        >
          <label
            css={{
              color: labelColor && color.color(labelColor),
              fontSize: fs.fontSize,
            }}
          >
            {label}
          </label>
          <span>{subLabel}</span>
        </div>
      ) : null}
      <div
        css={{
          display: 'flex',
        }}
      >
        {children}
      </div>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </div>
  )
}

FormField.displayName = 'FormField'
