import React from 'react'

import { TargetSettingType } from '../../../graphql'
import { Icon } from '../../ui/Icon'

type Props = {
  readonly targetSetting: TargetSettingType
  margin?: string
}

export const KeyResultTargetSettingIcon: React.VFC<Props> = ({ targetSetting, margin }) => {
  switch (targetSetting) {
    case TargetSettingType.Moon:
      return <Icon type="moon" css={{ minWidth: '16px', margin }} />

    case TargetSettingType.Roof:
      return <Icon type="roof" css={{ minWidth: '16px', margin }} />

    default:
      return <div css={{ width: '16px', margin }} />
  }
}

KeyResultTargetSettingIcon.displayName = 'KeyResultTargetSettingIcon'
