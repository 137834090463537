import { Controller } from 'react-hook-form'

import { ReasonInputFormFieldProps } from '../../../components/CheckinModalForm/InputsArea/components/ReasonInputFormField'
import { reasonInputWrapperCss } from '../../../components/CheckinModalForm/InputsArea/styles'
import { useGetFormMethods } from '../../contexts/FormProvider'
import { keyResultsKeyName } from '../../formSchema'

type ReasonInputControllerProps = {
  name: `${typeof keyResultsKeyName}.${number}.updateReason`
  children: (args: ReasonInputFormFieldProps) => React.ReactNode
}

export const ReasonInputController: React.FC<ReasonInputControllerProps> = ({ name, children }) => {
  const { control } = useGetFormMethods()

  return (
    <div css={reasonInputWrapperCss}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <>{children({ message: value || '', onChangeMessage: (str) => onChange(str) })}</>
        )}
      />
    </div>
  )
}
ReasonInputController.displayName = 'ReasonInputController'
