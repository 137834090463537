import { FC } from 'react'

import { useTranslation } from '../../../i18n'
import { Modal } from '../../ui/Modal'
import { StyledText } from '../../ui/StyledText'

export type Props = {
  onClickDiscard: () => void
  onClickCancel: () => void
}

export const KeyResultWeightSettingAlertModal: FC<Props> = ({ onClickDiscard, onClickCancel }) => {
  const { t } = useTranslation()

  return (
    <Modal
      isOpened
      title={t('DISCARD_UNSAVED_CHANGE')}
      executeText={t('DISCARD')}
      cancelText={t('BACK_TO_EDITING')}
      onClickExecute={onClickDiscard}
      onClickCancel={onClickCancel}
      onClickOutside={onClickCancel}
    >
      <StyledText>{t('X_EDITING_CONFIRMATION_OF_DISCARD', { x: t('USE_WEIGHTING') })}</StyledText>
    </Modal>
  )
}

KeyResultWeightSettingAlertModal.displayName = 'KeyResultWeightSettingAlertModal'
