import { TextLink } from '@resily/geisha'
import { useCallback } from 'react'

import { useTranslation } from '../../../../i18n'
import { findScreen } from '../../../../lib/screen'
import { tracker } from '../../../../lib/tracking'
import { ExternalUrls } from '../../../../urls'
import { StyledText } from '../../../ui/StyledText'

import { useStyles } from './index.styles'

export const Header: React.FC = () => {
  const { t } = useTranslation()
  const styles = useStyles()

  const handleOnClick = useCallback(() => {
    tracker.UserClickShowExternalSampleOkrPageByObjectiveUpdateModal(
      findScreen(window.location.pathname, window.location.search),
    )
    window.open(ExternalUrls.SAMPLE_OKR, '_blank', 'noopener, noreferrer')
  }, [])

  return (
    <div css={styles.root}>
      <span>{t('EDIT_X', { x: t('OBJECTIVE') })}</span>
      <TextLink type="primary" isExternalLink onClick={handleOnClick} css={styles.link}>
        <StyledText size="small" weight="normal">
          {t('SEE_SAMPLE')}
        </StyledText>
      </TextLink>
    </div>
  )
}
