import { css } from '@emotion/react'
import { useMemo } from 'react'

import { color } from '../../../../../styles/newColors'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useStyles = () =>
  useMemo(
    () =>
      ({
        createButtonArea: css({
          padding: '16px 24px 8px',
        }),
        note: css({
          boxShadow: `0px 1px 0px ${color('border-bk-10')}`,
        }),
      } as const),
    [],
  )
