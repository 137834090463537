import { css, CSSObject, SerializedStyles } from '@emotion/react'
import { Box } from 'grommet'
import { v1 as uuid } from 'uuid'

import { border } from '../../../styles/border'
import { fontSize } from '../../../styles/font'
import { color } from '../../../styles/newColors'
import { InfiniteScroll } from '../InfiniteScroll'
import { StyledText } from '../StyledText'

export type TableHeader = {
  title: string | JSX.Element
  minWidth?: number
  css?: CSSObject | SerializedStyles
}

export type TableCell = {
  key: string
  cell: string | JSX.Element
  minWidth?: number
  css?: CSSObject | SerializedStyles
}

export type TableRow = {
  key: string
  cells: Array<TableCell>
  css?: CSSObject | SerializedStyles
  onClick?: () => void
}

export type Table = {
  headers: Array<TableHeader>
  data: {
    rows: Array<TableRow>
    css?: CSSObject | SerializedStyles
    maxHeight?: number
    rowMinHeight?: number
    disabledScroll?: boolean
  }
}

export type Props = {
  borderless?: boolean
  header: JSX.Element
  table: Table
  footer?: JSX.Element
  drawborderBottom?: boolean
  emptyMessage?: string
  onReachEnd?: () => void
}

const CELL_WIDTH = 84
const ROW_HEIGHT = 54

export const ListCard: React.FC<Props> = ({
  borderless = false,
  header,
  table,
  footer,
  drawborderBottom = true,
  emptyMessage,
  onReachEnd,
  ...props
}) => {
  const { headers, data } = table

  return (
    <div
      {...props}
      css={{
        width: '100%',
        padding: '28px 0',
        backgroundColor: color('white-100'),
        border: borderless ? undefined : border('simple-30'),
        borderRadius: '8px',
      }}
    >
      <div css={{ overflowY: 'auto', height: '100%', padding: '0 32px' }}>
        <div
          css={{
            width: '100%',
          }}
        >
          {header}
        </div>
        {/* table */}
        <div css={{ width: '100%' }}>
          {/* header */}
          <div
            css={{
              ...fontSize('small'),
              fontWeight: 'bold',
              display: 'flex',
              alignItems: 'center',
              paddingBottom: '10px',
              color: color('text-bk-50'),
              borderBottom: border('simple-30'),
              position: 'sticky',
              top: '0',
              backgroundColor: color('white-100'),
            }}
          >
            {headers.map(({ title, minWidth, css: headerCss }, i) => (
              <div
                key={uuid()}
                css={css([
                  {
                    minWidth: minWidth ? `${minWidth}px` : `${CELL_WIDTH}px`,
                    width: i === 0 ? '100%' : undefined,
                  },
                  headerCss,
                ])}
              >
                {title}
              </div>
            ))}
          </div>
          {/* data */}
          {data.rows.length > 0 ? (
            <InfiniteScroll
              onReachEnd={onReachEnd}
              height={data.maxHeight ? `${data.maxHeight}px` : '100%'}
              css={css([
                {
                  borderBottom: drawborderBottom ? border('simple-30') : undefined,
                  maxHeight: data.maxHeight ? `${data.maxHeight}px` : '100%',
                  overflow: data.disabledScroll ? 'visible' : 'auto',
                },
                data.css,
              ])}
            >
              {data.rows.map(({ key, cells, onClick }, i) => (
                <SwitchButtonRow
                  key={key}
                  onClick={onClick}
                  css={css([
                    fontSize('small'),
                    {
                      color: color('text-bk-100'),
                      borderBottom:
                        data.rows.length - 1 !== i && drawborderBottom
                          ? border('simple-30')
                          : undefined,
                      minHeight: data.rowMinHeight ? `${data.rowMinHeight}px` : `${ROW_HEIGHT}px`,
                      alignItems: 'center',
                      textAlign: 'left',
                      display: 'flex',
                      width: '100%',
                    },
                  ])}
                >
                  {cells.map(({ key: cellKey, cell, css: cellCss, minWidth }, j) => (
                    <div
                      key={cellKey}
                      css={css([
                        {
                          minWidth: minWidth ? `${minWidth}px` : `${CELL_WIDTH}px`,
                          width: j === 0 ? '100%' : undefined,
                        },
                        cellCss,
                      ])}
                    >
                      {cell}
                    </div>
                  ))}
                </SwitchButtonRow>
              ))}
            </InfiniteScroll>
          ) : (
            <Box align="center" margin={{ top: '40px' }}>
              <StyledText
                size="medium"
                css={{
                  margin: 'auto',
                }}
              >
                {emptyMessage}
              </StyledText>
            </Box>
          )}
        </div>
        {footer && (
          <div
            css={{
              width: '100%',
            }}
          >
            {footer}
          </div>
        )}
      </div>
    </div>
  )
}

ListCard.displayName = 'ListCard'

type SwitchButtonRowProps = {
  onClick?: () => void
}

const SwitchButtonRow: React.FC<SwitchButtonRowProps> = ({ onClick, children, ...props }) =>
  onClick ? (
    <button type="button" onClick={onClick} {...props}>
      {children}
    </button>
  ) : (
    <div {...props}>{children}</div>
  )

SwitchButtonRow.displayName = 'SwitchButtonRow'
