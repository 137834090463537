import { Drop, ThemeContext } from 'grommet'
import { useCallback, useRef, useState } from 'react'

import { useTranslation } from '../../../i18n'
import { componentNames, featureNames, generateTestId } from '../../../lib/testId'
import { color } from '../../../styles/newColors'
import { Icon } from '../../ui/Icon'
import { TooltipNew } from '../../ui/TooltipNew'
import { ParentOkrSelectOptions } from '../ParentOkrSelectOptions'
import { ObjectiveName } from '../ParentOkrSelectOptions/ObjectiveName'

import { useStyles } from './ParentOkrSelect.styles'
import { OkrNodeFragment } from './graphql'

export type Props<T extends OkrNodeFragment> = {
  loading?: boolean
  clear?: boolean
  fullWidth?: boolean
  nodes: ReadonlyArray<T>
  value?: T
  onOpen?: () => void
  onChange: (v: T | undefined) => void
}

export const ParentOkrSelect = <T extends OkrNodeFragment>({
  loading,
  clear = false,
  fullWidth = false,
  nodes,
  value,
  onOpen,
  onChange,
}: Props<T>): JSX.Element => {
  const { t } = useTranslation()
  const styles = useStyles(fullWidth)

  const rootRef = useRef<HTMLButtonElement>(null)
  const [showDrop, setShowDrop] = useState(false)
  const hideDrop = useCallback(() => setShowDrop(false), [])

  return (
    <>
      <button
        type="button"
        ref={rootRef}
        css={styles.root}
        data-testid={generateTestId(featureNames.okrCreateModal, componentNames.parentOkrSelect)}
        onMouseDown={() => {
          if (showDrop) return // トグル用
          setShowDrop(true)
          onOpen?.()
        }}
      >
        {value ? (
          <div css={styles.selectedValue}>
            <ObjectiveName objective={value.objective} />

            {clear && (
              <TooltipNew
                style={{ display: 'inline-flex' }}
                title={t('CLEAR_X', { x: t('PARENT_OKR') })}
              >
                <Icon
                  type="clear"
                  color={color('text-bk-50')}
                  width={14}
                  height={14}
                  hoverColor="resily-orange-100"
                  onMouseDown={(e) => {
                    // Xボタンで削除時に背後のSelectが押されてDropが表示される対策
                    e.stopPropagation()
                    onChange(undefined)
                  }}
                />
              </TooltipNew>
            )}
          </div>
        ) : (
          <span css={styles.placeholder}>{t('SELECT_PARENT_OKR')}</span>
        )}

        <span css={styles.rightIcon}>
          <Icon
            type="selectDown"
            color={color('border-bk-20')}
            width={8}
            height={8}
            data-show-drop={showDrop}
          />
        </span>
      </button>

      {showDrop && (
        <ThemeContext.Extend value={{ global: { drop: { zIndex: '201' } } }}>
          <Drop
            target={rootRef.current || undefined}
            round="xsmall"
            align={styles.dropAlign}
            css={styles.drop}
            // style={{ width: rootRef.current?.scrollWidth }}
            onEsc={hideDrop}
            onClickOutside={hideDrop}
          >
            <ParentOkrSelectOptions
              loading={loading}
              options={nodes.filter((n) => n.id !== value?.id)}
              onSelect={(v) => {
                onChange(v)
                setShowDrop(false)
              }}
            />
          </Drop>
        </ThemeContext.Extend>
      )}
    </>
  )
}
