import React, { useCallback, useMemo } from 'react'

import { Select, Option } from '../../../../components/ui/Select'
import { useTranslation } from '../../../../i18n'
import { Language } from '../../api/graphql'

import * as constants from './constants'
import * as styles from './styles'

export type Props = {
  loading: boolean
  language: string
  updateLanguage: (language: Language) => Promise<void>
}

export const languageMap: { [key: string]: Language } = {
  ja: Language.Japanese,
  en: Language.English,
}

export const LanguageSetting: React.FC<Props> = ({ loading, language, updateLanguage }) => {
  const { t, i18n } = useTranslation()
  const options = useMemo<Array<Option>>(
    () => [
      {
        value: 'ja',
        text: t(Language.Japanese),
      },
      {
        value: 'en',
        text: t(Language.English),
      },
    ],
    [t],
  )
  const clickOption = useCallback(
    async (v: string) => {
      await updateLanguage(languageMap[v] || Language.Japanese)
      i18n.changeLanguage(v)
    },
    [i18n, updateLanguage],
  )

  return (
    <div css={styles.rootCss}>
      <p css={styles.titleCss}>{t('DISPLAY_LANGUAGE')}</p>
      <Select
        dropAlign={constants.DROP_ALIGN}
        disabled={loading}
        options={options}
        value={language}
        onClickOption={clickOption}
        valueButtonCss={styles.valueButtonCss}
      />
    </div>
  )
}

LanguageSetting.displayName = 'TwoFactorAuthSetting'
