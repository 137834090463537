import {
  createPlugins,
  serializeHtml as serializeHtmlOrig,
  createBlockquotePlugin,
  createBoldPlugin,
  createCodeBlockPlugin,
  createCodePlugin,
  createHeadingPlugin,
  createHighlightPlugin,
  createHorizontalRulePlugin,
  createIndentPlugin,
  createIndentListPlugin,
  createItalicPlugin,
  createLinkPlugin,
  createParagraphPlugin,
  createStrikethroughPlugin,
  createTodoListPlugin,
  createUnderlinePlugin,
  createImagePlugin,
  createPlateEditor,
  createPlateUI,
  Value,
} from '@udecode/plate'

export const serializeHtml: typeof serializeHtmlOrig = (
  editor,
  { stripDataAttributes = true, stripWhitespace = false, ...params },
) => serializeHtmlOrig(editor, { ...params, stripDataAttributes, stripWhitespace })

const plateEditor = createPlateEditor({
  plugins: createPlugins([
    // Elements
    createParagraphPlugin(),
    createBlockquotePlugin(),
    createCodeBlockPlugin(),
    createHeadingPlugin(),
    createImagePlugin(),
    createLinkPlugin(),
    createHorizontalRulePlugin(),
    createTodoListPlugin(),
    createIndentListPlugin(),

    // Marks
    createBoldPlugin(),
    createItalicPlugin(),
    createUnderlinePlugin(),
    createStrikethroughPlugin(),
    createCodePlugin(),
    createHighlightPlugin(),

    createIndentPlugin(),
  ]),
  components: createPlateUI(),
})

export const serializeHtmlStatic = (nodes: Value): string => serializeHtml(plateEditor, { nodes })
