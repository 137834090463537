import { Fragment, useCallback, useRef, useState } from 'react'

import { useCurrentUser } from '../../../contexts/UserContext'
import { useTranslation } from '../../../i18n'
import { color } from '../../../styles/newColors'
import { AttachFile } from '../../ui/AttachFile'
import { AvatarWithName } from '../../ui/AvatarWithName'
import { Button } from '../../ui/Button'
import { DateTime } from '../../ui/DateTime'
import { DeleteDialog } from '../../ui/DeleteDialog'
import { DeleteModal } from '../../ui/DeleteModal'
import { Drawer } from '../../ui/Drawer'
import { Icon } from '../../ui/Icon'
import { IconButton } from '../../ui/IconButton'
import { MediaFile } from '../../ui/MediaFile'
import { EditorRef, RichTextEditor } from '../../ui/RichTextEditor'
import { StyledText } from '../../ui/StyledText'
import { TextButton } from '../../ui/TextButton'
import { TooltipNew } from '../../ui/TooltipNew'
import { Reaction } from '../Reaction'

import { File } from './graphql'
import { CommonCommentItemNoteFragment } from './type'

export type Props<T extends CommonCommentItemNoteFragment> = {
  comment: T
  update: (comment: T) => void
  deleteComment: (commentId: string) => void
  createReaction: (commentId: string, emoji: string) => void
  deleteReaction: (commentId: string, reactedId: string) => void
  onChanged: (changed: boolean) => void
}

export const NoteDetailCommentItem = <T extends CommonCommentItemNoteFragment>({
  comment,
  update,
  deleteComment,
  createReaction,
  deleteReaction,
  onChanged,
  ...props
}: Props<T>): JSX.Element => {
  const { t } = useTranslation()
  const editorRef = useRef<EditorRef>(null)
  const user = useCurrentUser()
  const [body, setBody] = useState<{ treeJson: string; plainText: string }>({
    treeJson: comment.body.treeJson,
    plainText: comment.body.plainText || '',
  })
  const [isOpened, setIsOpened] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [attachmentDrawerVisibility, setAttachmentDrawerVisibility] = useState(false)
  const [attachmentRemovalCandidate, setAttachmentRemovalCandidate] = useState<File | null>(null)
  const [commentDrawerVisibility, setCommentDrawerVisibility] = useState(false)
  const [commentRemovalCandidate, setCommentRemovalCandidate] =
    useState<CommonCommentItemNoteFragment | null>(null)
  const editable = user?.id === comment.author.id

  const handleUploadFile = (file: File) => {
    update({
      ...comment,
      body: { treeJson: comment.body.treeJson, plainText: comment.body.plainText },
      attachments: [...comment.attachments, file],
    })
  }
  const handleDeleteAttachment = () => {
    update({
      ...comment,
      body: { treeJson: comment.body.treeJson, plainText: comment.body.treeJson },
      attachments: [...comment.attachments.filter((a) => a !== attachmentRemovalCandidate)],
    })
  }

  const onEditCancel = () => {
    if (editorRef.current) {
      editorRef.current.setValue(comment.body.treeJson)
    }

    setBody({
      treeJson: comment.body.treeJson,
      plainText: comment.body.plainText || '',
    })
    onChanged(false)
    setIsEditing(false)
  }

  const onSave = () => {
    update({
      ...comment,
      body,
    })
    setIsEditing(false)
    onChanged(false)
  }

  return (
    <Fragment>
      <div {...props}>
        <div css={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <AvatarWithName
            size="small"
            firstName={comment.author.firstName}
            lastName={comment.author.lastName}
            avatarUrl={comment.author.avatar?.url}
            linkage={{
              userId: comment.author.id,
            }}
            linkTextMaxWidth="calc(100% - 180px)"
            css={{ fontWeight: 'bold' }}
            isUserDisabled={comment.author.isDisabled}
          />
          <div css={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <StyledText color="text-bk-30" css={{ paddingTop: 4 }}>
              <DateTime datetime={comment.createdAt} />
            </StyledText>
            {editable && (
              <Fragment>
                <TooltipNew title={t('EDITION')}>
                  <IconButton
                    css={{ marginLeft: 16 }}
                    border={false}
                    size={12}
                    onClick={() => {
                      setIsEditing(true)
                    }}
                  >
                    <Icon
                      type="editOutline"
                      hoverColor="resily-orange-100"
                      color={isEditing ? color('border-bk-10') : color('text-bk-30')}
                    />
                  </IconButton>
                </TooltipNew>
                <TooltipNew title={t('DELETION')}>
                  <IconButton
                    css={{ marginLeft: 12 }}
                    border={false}
                    size={12}
                    onClick={() => {
                      setIsOpened(true)
                    }}
                  >
                    <Icon
                      type="deleteOutline"
                      color={color('text-bk-30')}
                      hoverColor="resily-orange-100"
                    />
                  </IconButton>
                </TooltipNew>
              </Fragment>
            )}
          </div>
        </div>
        <div css={{ marginTop: 8 }}>
          <RichTextEditor
            ref={editorRef}
            id={comment.id}
            initialValueJSON={body.treeJson}
            editorProps={{
              readOnly: !isEditing,
            }}
            onChange={useCallback(
              (json, text) => {
                setBody({
                  treeJson: json,
                  plainText: text,
                })
                onChanged(body.treeJson !== comment.body.treeJson)
              },
              [body.treeJson, comment.body.treeJson, onChanged],
            )}
          />
          <div
            css={{
              display: 'flex',
              justifyContent: 'space-between',
              margin: '16px 0',
            }}
          >
            <div css={{ display: 'flex', flexWrap: 'wrap', overflow: 'hidden' }}>
              {isEditing && (
                <div css={{ margin: '3px 8px 8px 0' }}>
                  <AttachFile onChange={handleUploadFile} />
                </div>
              )}
              {comment.attachments.map((attachment) => (
                <div css={{ fontSize: '12px', margin: '0 8px 8px 0' }}>
                  <MediaFile
                    filename={attachment.name}
                    url={attachment.url}
                    readOnly={!isEditing}
                    onClickDelete={() => {
                      setAttachmentDrawerVisibility(true)
                      setAttachmentRemovalCandidate(attachment)
                    }}
                    key={attachment.name}
                  />
                </div>
              ))}
              <Drawer
                open={attachmentDrawerVisibility}
                onClose={() => {
                  setAttachmentDrawerVisibility(false)
                  setAttachmentRemovalCandidate(null)
                }}
              >
                <DeleteDialog
                  confirmation={t('CONFIRMATION_OF_DELETE')}
                  resources={[attachmentRemovalCandidate?.name || '']}
                  warning={t('WARNING')}
                  onCancel={() => setAttachmentDrawerVisibility(false)}
                  handleDelete={() => {
                    setAttachmentDrawerVisibility(false)
                    handleDeleteAttachment()
                  }}
                />
              </Drawer>
              <Drawer
                open={commentDrawerVisibility}
                onClose={() => {
                  setCommentDrawerVisibility(false)
                  setCommentRemovalCandidate(null)
                }}
              >
                <DeleteDialog
                  confirmation={t('CONFIRMATION_OF_DELETE')}
                  resources={[t('COMMENT')]}
                  warning={t('WARNING')}
                  onCancel={() => setCommentDrawerVisibility(false)}
                  handleDelete={() => {
                    if (!commentRemovalCandidate) return
                    setCommentDrawerVisibility(false)
                    deleteComment(commentRemovalCandidate.id)
                  }}
                />
              </Drawer>
            </div>
            {isEditing && (
              <div
                css={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginBottom: 16,
                  minWidth: 170,
                }}
              >
                <TextButton color="text-bk-50" onClick={onEditCancel} css={{ marginRight: '8px' }}>
                  {t('CANCEL')}
                </TextButton>
                <Button newColor="resily-orange-100" weight="normal" size="s" onClick={onSave}>
                  {t('SAVE')}
                </Button>
              </div>
            )}
          </div>
        </div>
        {isEditing ? null : (
          <div css={{ marginBottom: 16 }}>
            <Reaction
              reactions={comment.reactions}
              createReaction={(emoji) => createReaction(comment.id, emoji)}
              deleteReaction={(reactedId) => deleteReaction(comment.id, reactedId)}
            />
          </div>
        )}
      </div>
      <DeleteModal
        isOpened={isOpened}
        deleteItemName={t('COMMENT')}
        onClickDelete={() => {
          deleteComment(comment.id)
          setIsOpened(false)
        }}
        onClickCancel={() => setIsOpened(false)}
        onClickOutside={() => setIsOpened(false)}
      />
    </Fragment>
  )
}
