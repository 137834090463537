import { css } from '@emotion/react'
import { useMemo } from 'react'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useStyles = () =>
  useMemo(
    () =>
      ({
        root: css({
          fontSize: '16px',
          lineHeight: '24px',
          fontWeight: 'bold',
        }),
      } as const),
    [],
  )
