import { css } from '@emotion/react'
import React from 'react'

import { barColorConfidence } from '../../../lib/confidence'
import { keyResultToConfidenceLevel } from '../../../lib/domain/confidence'
import { color } from '../../../styles/newColors'
import { Icon } from '../../ui/Icon'
import { ProgressRate } from '../../ui/Meter'

import { KeyResultFragment, HasTrendKeyResultFragment } from './graphql'

const fontCss = css({
  fontSize: '10px',
  fontWeight: 700,
  lineHeight: '10px',
})

const upNumCss = css(fontCss, {
  color: color('tag-darkgreen'),
  '&::before': { content: '"↑"' },
})

const downNumCss = css(fontCss, {
  color: color('tag-red'),
  '&::before': { content: '"↓"' },
})

const trendRowCss = css({
  width: '84px',
  display: 'flex',
  marginTop: '6px',
  textAlign: 'start',
  '& > span:first-of-type': { flex: '0 0 42px' },
})

const hasNumberTypeProperty = <T extends Record<string, unknown>, K extends string>(
  obj: T,
  property: K,
): obj is T & { readonly [P in K]: number } => property in obj && typeof obj[property] === 'number'

export type Props = {
  keyResult: KeyResultFragment | HasTrendKeyResultFragment
}

export const KeyResultProgressRateBar: React.FC<Props> = ({ keyResult }) => {
  const ownerConfidenceLevel = keyResultToConfidenceLevel(keyResult)

  const RateBar = (
    <ProgressRate
      color={color(barColorConfidence(ownerConfidenceLevel))}
      rate={keyResult.progressRate || 0}
    />
  )

  return !hasNumberTypeProperty(keyResult, 'progressRateTrend') &&
    !hasNumberTypeProperty(keyResult, 'confidenceTrend') ? (
    RateBar
  ) : (
    <div>
      {RateBar}
      <div css={trendRowCss}>
        {hasNumberTypeProperty(keyResult, 'progressRateTrend') &&
          keyResult.progressRateTrend !== 0 && (
            <span css={Math.sign(keyResult.progressRateTrend) > 0 ? upNumCss : downNumCss}>
              {Math.min(Math.abs(keyResult.progressRateTrend), 999)}pt
            </span>
          )}
        {hasNumberTypeProperty(keyResult, 'confidenceTrend') && keyResult.confidenceTrend !== 0 && (
          <>
            <Icon type="confidence" width={10} height={10} />
            <span css={keyResult.confidenceTrend > 0 ? upNumCss : downNumCss} />
          </>
        )}
      </div>
    </div>
  )
}

KeyResultProgressRateBar.displayName = 'KeyResultProgressRateBar'
