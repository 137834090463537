import { css } from '@emotion/react'

export const rowWrapperCss = css({
  display: 'flex',
  height: '56px',
  columnGap: '16px',
  padding: '0 14px',
  alignItems: 'center',
  justifyContent: 'space-between',
})

export const statusCss = css({
  padding: '4px',
})

export const titleAndStatusWrapperCss = css({
  flexGrow: 1,
  display: 'flex',
  columnGap: '12px',
  alignItems: 'center',
})

export const titleCss = css({
  fontSize: '14px',
  lineHeight: '20px',
})

export const publicStatusCss = css({
  color: '#32B988',
  // stylelint-disable-next-line color-function-notation
  backgroundColor: 'rgba(50, 185, 136, 10%)',
})

export const privateStatusCss = css({
  color: '#9194A1',
  backgroundColor: '#E9EAEC',
})

export const buttonAreaCss = css({
  display: 'flex',
  columnGap: '19px',
  alignItems: 'center',
})
