import { VFC } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { RichTextEditor } from '../../components/ui/RichTextEditor'

import { PrivateNoteFragment } from './graphql'

type FormValues = {
  body: {
    treeJson: string
    plainText?: string
  }
}

export type OnSubmitFn = (data: FormValues) => Promise<void>
export type Props = {
  oneOnOneMeetingId: string
  privateNote: PrivateNoteFragment
  onSubmit: OnSubmitFn
}

export const PrivateNotes: VFC<Props> = ({ oneOnOneMeetingId, privateNote, onSubmit }) => {
  const { handleSubmit, control } = useForm<FormValues>({
    defaultValues: {
      body: {
        treeJson: privateNote.meetingNote,
      },
    },
  })

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      css={{
        display: 'block',
      }}
    >
      <Controller
        name="body"
        control={control}
        render={({ field: { onChange } }) => (
          <RichTextEditor
            id={oneOnOneMeetingId}
            initialValueJSON={privateNote.meetingNote}
            autoFocus={false}
            autoSave
            onChange={(treeJson, plainText) => onChange({ treeJson, plainText })}
            onSave={(treeJson, plainText) => {
              onSubmit({
                body: {
                  treeJson,
                  plainText,
                },
              })
            }}
            css={{
              backgroundColor: 'white',
              border: 'none',
              margin: '0 24px',
            }}
          />
        )}
      />
    </form>
  )
}

PrivateNotes.displayName = 'PrivateNotes'
