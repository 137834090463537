import { useCallback, useContext, useEffect, useImperativeHandle } from 'react'

import { AlertModalContext } from '../../../../../../../contexts/AlertModalContext'

import type { OkrDrawerRef } from './types'

export const useInjection = (
  ref: React.Ref<OkrDrawerRef>,
  onCancel: () => void,
): {
  onDescriptionChange: () => void
  editingDescription: boolean
  setEditingDescription: (value: boolean) => void
} => {
  const { status, closeAlertModal, changeEditingType } = useContext(AlertModalContext)

  useImperativeHandle(ref, () => ({
    onCancel: () => onCancel(),
  }))

  // Tab Close or Reload Page
  useEffect(() => {
    if (status.type === 'DESCRIPTION') {
      onbeforeunload = (event) => {
        event.preventDefault()
        event.returnValue = ''
      }
    } else {
      onbeforeunload = null
    }
    return () => {
      onbeforeunload = null
    }
  }, [status.type])

  const onDescriptionChange = useCallback(
    () => changeEditingType('DESCRIPTION'),
    [changeEditingType],
  )

  return {
    onDescriptionChange,
    editingDescription: status.type === 'DESCRIPTION',
    setEditingDescription: (isEditing: boolean) => {
      if (isEditing) {
        changeEditingType('DESCRIPTION')
      } else {
        closeAlertModal()
      }
    },
  }
}
