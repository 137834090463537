import { withScope, Scope, captureException } from '@sentry/react'
import { Value } from '@udecode/plate'
import { Box, Text } from 'grommet'
import React, { Component, ErrorInfo, ReactNode } from 'react'
// eslint-disable-next-line no-restricted-imports
import { withTranslation, WithTranslation } from 'react-i18next'

import { convertFragmentFromSlateToPlate } from './plugins/converter/convertFragmentFromSlateToPlate'
import { serializeMarkdown } from './plugins/serializer/markdown'
import { serializeText } from './plugins/serializer/text'

type Props = WithTranslation & {
  prevValue: Value | undefined | null
  onSave: (json: string, plainText: string, html: string, markdown: string) => void
}

type State = {
  hasError: boolean
}

class PlateErrorBoundaryComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      hasError: false,
    }
  }

  static getDerivedStateFromError(_: Error): State {
    return { hasError: true }
  }

  override componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    const { prevValue, onSave } = this.props
    // フォールバックプランとしてクラッシュが起きる前のstateを保存しておく
    if (prevValue && prevValue.length > 0) {
      const normalizedPrevValue = convertFragmentFromSlateToPlate(prevValue)
      onSave(
        JSON.stringify(normalizedPrevValue),
        serializeText(normalizedPrevValue),
        '',
        serializeMarkdown(normalizedPrevValue),
      )
    }
    withScope((scope) => {
      scope.setExtras(errorInfo as unknown as Parameters<Scope['setExtras']>[0])
      captureException(error)
    })
  }

  override render(): ReactNode {
    const { children, t } = this.props
    const { hasError } = this.state

    if (hasError) {
      return (
        <Box fill="vertical" justify="center" alignSelf="center">
          <Text weight="bold" size="xlarge">
            {t('ERROR_DETECTED')}
          </Text>
        </Box>
      )
    }

    return children
  }
}

export const PlateErrorBoundary = withTranslation()(PlateErrorBoundaryComponent)

PlateErrorBoundary.displayName = 'PlateErrorBoundary'
