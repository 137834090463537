import { Skeleton } from '@resily/geisha'

import { useTranslation } from '../../../../../../i18n'
import { StyledText } from '../../../../../ui/StyledText'
import { useStyles } from '../../../KeyResultDrawer/KeyResultDescription/HeaderArea/index.styles'

export const HeaderAreaSkeleton: React.VFC = () => {
  const styles = useStyles()
  const { t } = useTranslation()

  return (
    <div css={styles.root}>
      <div css={styles.titleArea}>
        <Skeleton text height={12} />
      </div>
      <div css={styles.detailArea}>
        <div css={styles.userArea}>
          <div css={styles.owner}>
            <StyledText size="xsmall" fontStyle="regular" lineHeight="14px" color="text-bk-50">
              {t('OWNER')}
            </StyledText>
            <Skeleton circle height={16} />
            <Skeleton text height={10} width={58} />
          </div>
          <div css={styles.progressRateArea}>
            <StyledText size="xsmall" fontStyle="regular" color="text-bk-50">
              {t('PROGRESS')}
            </StyledText>
            <Skeleton text height={12} width={84} />
          </div>
        </div>
        <div style={{ display: 'flex', gap: 8 }}>
          {new Array(5).fill(undefined).map((_, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <Skeleton key={i} height={20} width={68} />
          ))}
        </div>
      </div>
    </div>
  )
}
