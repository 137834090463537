import {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

import { KeyResultEditFields, Props as KeyResultEditFieldsProps } from '..'
import { InitQuery, useInitQuery } from '../graphql'
import { KeyResultEditFieldsInput } from '../types'

type ReturnFC = <T extends KeyResultEditFieldsInput>(props: Props<T>) => JSX.Element | null

export type Props<T extends KeyResultEditFieldsInput> = Omit<
  KeyResultEditFieldsProps<T>,
  'users' | 'groups' | 'disableUsers'
>

const InitQueryContext = createContext<InitQuery | undefined>(undefined)
const InitQueryDispatchContext = createContext<Dispatch<SetStateAction<InitQuery | undefined>>>(
  () => {
    throw new Error('Should use InitQueryDispatchContext.Provider')
  },
)
export const InitQueryContextProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<InitQuery>()

  return (
    <InitQueryContext.Provider value={data}>
      <InitQueryDispatchContext.Provider value={setData}>
        {children}
      </InitQueryDispatchContext.Provider>
    </InitQueryContext.Provider>
  )
}

export const useKeyResultEditFieldsFetch = (): boolean => {
  const setData = useContext(InitQueryDispatchContext)
  const { data, loading } = useInitQuery()
  useEffect(() => setData(data), [data, setData])
  return loading
}

export const useKeyResultEditFields = (): ReturnFC => {
  const data = useContext(InitQueryContext)

  return useCallback<ReturnFC>(
    ({ type, initialKeyResult, onChangeKeyResult, error }) => {
      if (data == null) return null
      return (
        <KeyResultEditFields
          type={type}
          initialKeyResult={initialKeyResult}
          onChangeKeyResult={onChangeKeyResult}
          users={data.users}
          disableUsers={data.organization.disableUsers}
          groups={data.groups}
          error={error}
        />
      )
    },
    [data],
  )
}
