import { PropsWithoutRef } from 'react'

import { color } from '../../../styles/newColors'
import { Link } from '../Link'

type Item = {
  label: string | JSX.Element
  href?: string
}

export type Props = PropsWithoutRef<JSX.IntrinsicElements['ol']> & {
  items: ReadonlyArray<Item | null>
}

export const Breadcrumb: React.FC<Props> = ({ items, ...props }) => {
  const is = items.filter((item) => item) as ReadonlyArray<Item>
  const last = is.length - 1
  return (
    <ol
      css={{
        color: color('text-bk-50'),
        lineHeight: '14px',
        fontSize: '12px',
        listStyle: 'none',
        display: 'flex',
        flexWrap: 'wrap',
      }}
      {...props}
    >
      {is.map(({ label, href }, i) => (
        <li
          key={typeof label === 'string' ? label : href || i}
          css={[
            {
              display: 'flex',
            },
            i !== 0
              ? {
                  '&:before': {
                    display: 'block',
                    margin: '0 8px',
                    fontWeight: 'normal',
                    content: '" > "',
                  },
                }
              : null,
            i === last
              ? {
                  fontWeight: 'normal',
                }
              : null,
          ]}
        >
          {href ? (
            <Link
              href={href}
              css={{
                color: color('text-bk-50'),
                ':hover': {
                  color: color('resily-orange-100'),
                  textDecorationLine: 'underline',
                },
                textOverflow: 'ellipsis',
                maxWidth: '250px',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              {label}
            </Link>
          ) : (
            <p
              css={{
                textOverflow: 'ellipsis',
                maxWidth: '250px',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              {label}
            </p>
          )}
        </li>
      ))}
    </ol>
  )
}

Breadcrumb.displayName = 'Breadcrumb'
