import { Box, Form, FormField } from 'grommet'
import i18next from 'i18next'
import { useContext } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { Section } from '../../../../components/layout/Section'
import { Checkbox } from '../../../../components/ui/Checkbox'
import { ErrorMessage } from '../../../../components/ui/ErrorMessage'
import { Input } from '../../../../components/ui/Input'
import { PopoverPortal } from '../../../../components/ui/PopoverPortal'
import { StyledText } from '../../../../components/ui/StyledText'
import { OrganizationContext } from '../../../../contexts/OrganizationContext'
import { useTranslation } from '../../../../i18n'
import { color } from '../../../../styles/newColors'
import { ExternalUrls } from '../../../../urls'
import { UpdateUserMutationVariables } from '../../api/graphql'
import { EditButtonSection } from '../EditButtonSection'

import { PrimaryInformationSectionProps } from './types'

export const PrimaryInformationSection: React.FC<PrimaryInformationSectionProps> = ({
  user,
  isEdit,
  onUpdate,
  onClickInput,
  onClickCancelButton,
  onClickUpdateButton,
}) => {
  const { t } = useTranslation()
  const organization = useContext(OrganizationContext)

  const {
    handleSubmit,
    formState: { errors },
    control,
    register,
  } = useForm<Omit<UpdateUserMutationVariables, 'id'>>({
    mode: 'onChange',
  })

  return (
    <Section
      gridArea="main"
      css={{ background: color('white-100'), padding: '24px 32px', borderRadius: '8px' }}
    >
      <Form
        onSubmit={handleSubmit((data) => {
          onUpdate(data)
          onClickUpdateButton()
        })}
      >
        {i18next.language === 'ja' ? (
          <Box direction="row" gap="24px">
            <FormField
              label={
                <StyledText size="small" fontStyle="bold" color="text-bk-50">
                  {t('LAST_NAME')}
                </StyledText>
              }
              error={
                errors.lastName ? <ErrorMessage>{errors.lastName.message}</ErrorMessage> : null
              }
            >
              <Controller
                render={() => (
                  <Input
                    fieldStyle="bottomLine"
                    width="228px"
                    height="40px"
                    placeholder={t('INPUT_X', { x: t('LAST_NAME') })}
                    onClick={onClickInput}
                    {...register('lastName')}
                  />
                )}
                control={control}
                name="lastName"
                defaultValue={user.lastName}
                rules={{ required: t('REQUIRED_ERROR') }}
              />
            </FormField>
            <FormField
              label={
                <StyledText size="small" fontStyle="bold" color="text-bk-50">
                  {t('FIRST_NAME')}
                </StyledText>
              }
              error={
                errors.firstName ? <ErrorMessage>{errors.firstName.message}</ErrorMessage> : null
              }
            >
              <Controller
                render={() => (
                  <Input
                    fieldStyle="bottomLine"
                    width="228px"
                    height="40px"
                    placeholder={t('INPUT_X', { x: t('FIRST_NAME') })}
                    onClick={onClickInput}
                    {...register('firstName')}
                  />
                )}
                control={control}
                name="firstName"
                defaultValue={user.firstName}
                rules={{ required: t('REQUIRED_ERROR') }}
              />
            </FormField>
          </Box>
        ) : (
          <Box direction="row" gap="24px">
            <FormField
              label={
                <StyledText size="small" fontStyle="bold" color="text-bk-50">
                  {t('FIRST_NAME')}
                </StyledText>
              }
              error={
                errors.firstName ? <ErrorMessage>{errors.firstName.message}</ErrorMessage> : null
              }
            >
              <Controller
                render={() => (
                  <Input
                    fieldStyle="bottomLine"
                    width="100%"
                    height="40px"
                    placeholder={t('INPUT_X', { x: t('FIRST_NAME') })}
                    onClick={onClickInput}
                    {...register('firstName')}
                  />
                )}
                control={control}
                name="firstName"
                defaultValue={user.firstName}
                rules={{ required: t('REQUIRED_ERROR') }}
              />
            </FormField>
            <FormField
              label={
                <StyledText size="small" fontStyle="bold" color="text-bk-50">
                  {t('LAST_NAME')}
                </StyledText>
              }
              error={
                errors.lastName ? <ErrorMessage>{errors.lastName.message}</ErrorMessage> : null
              }
            >
              <Controller
                render={() => (
                  <Input
                    fieldStyle="bottomLine"
                    width="100%"
                    height="40px"
                    placeholder={t('INPUT_X', { x: t('LAST_NAME') })}
                    onClick={onClickInput}
                    {...register('lastName')}
                  />
                )}
                control={control}
                name="lastName"
                defaultValue={user.lastName}
                rules={{ required: t('REQUIRED_ERROR') }}
              />
            </FormField>
          </Box>
        )}
        <FormField
          label={
            <StyledText size="small" fontStyle="bold" color="text-bk-50">
              {t('EMAIL')}
            </StyledText>
          }
          error={errors.email ? <ErrorMessage>{errors.email.message}</ErrorMessage> : null}
        >
          <Controller
            render={() => (
              <Input
                fieldStyle="bottomLine"
                width="100%"
                height="40px"
                placeholder={t('INPUT_X', { x: t('EMAIL') })}
                onClick={onClickInput}
                {...register('email', {
                  pattern: {
                    value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                    message: t('INVALID_EMAIL_FORMAT'),
                  },
                })}
              />
            )}
            control={control}
            name="email"
            defaultValue={user.email}
            rules={{ required: t('REQUIRED_ERROR') }}
          />
        </FormField>
        <Box css={{ marginTop: '24px', display: 'flex', flexDirection: 'column', gap: '12px' }}>
          <Controller
            control={control}
            name="isAdmin"
            render={({ field: { value, onChange, ...props } }) => (
              <Checkbox
                checked={value}
                onChange={(e) => onChange(e.target.checked)}
                onClick={onClickInput}
                iconColor="resily-orange-100"
                {...props}
              >
                <Box direction="row" gap="4px" align="center">
                  <StyledText size="small">
                    {t('SETTING_TO_X', { x: t('ADMINISTRATOR') })}
                  </StyledText>
                  <PopoverPortal
                    portalPosition={{
                      top: 20,
                      left: -125,
                    }}
                    icon="help"
                    iconCss={{
                      color: color('text-bk-50'),
                      ':hover': {
                        color: color('resily-orange-100'),
                      },
                    }}
                    text={t('ADMINISTRATOR_HELP')}
                    link={{
                      text: t('SUPPORT_SITE'),
                      href: ExternalUrls.HELP_PAGE,
                    }}
                    css={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  />
                </Box>
              </Checkbox>
            )}
            defaultValue={user.admin}
          />
          {organization?.isSamlEnabled && (
            <Controller
              control={control}
              name="samlEnabled"
              render={({ field: { value, onChange, ...props } }) => (
                <Checkbox
                  checked={value}
                  onChange={(e) => onChange(e.target.checked)}
                  onClick={onClickInput}
                  iconColor="resily-orange-100"
                  {...props}
                >
                  <Box direction="row" gap="4px" align="center">
                    <StyledText size="small">{t('USE_X', { x: t('SAML_AUTH') })}</StyledText>
                    <PopoverPortal
                      portalPosition={{
                        top: 20,
                        left: -125,
                      }}
                      icon="help"
                      iconCss={{
                        color: color('text-bk-50'),
                        ':hover': {
                          color: color('resily-orange-100'),
                        },
                      }}
                      text={t('SAML_HELP')}
                      link={{
                        text: t('SUPPORT_SITE'),
                        href: ExternalUrls.HELP_PAGE,
                      }}
                      css={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    />
                  </Box>
                </Checkbox>
              )}
              defaultValue={user.samlEnabled}
            />
          )}
        </Box>
        {isEdit && <EditButtonSection onClick={onClickCancelButton} />}
      </Form>
    </Section>
  )
}
