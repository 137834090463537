import { css } from '@emotion/react'
import { OkrTitle, useModal } from '@resily/geisha'
import { useCallback, useState } from 'react'

import { useTranslation } from '../../../i18n'
import { StyledText } from '../../ui/StyledText'

import { ObjectiveFragment } from './graphql'

export type Objective = Pick<ObjectiveFragment, 'id' | 'name' | 'useWeighting'>
type OpenProps = {
  oldObjective: Objective
  newObjective: Objective
}

type ModalFC = React.VFC<{ onConfirm: (objectiveId: string) => void }>
type ReturnType = {
  AlertModal: ModalFC
  isOpen: boolean
  open: (props: OpenProps) => void
}

const rootCss = css({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
})

export const useAlertModal = (): ReturnType => {
  const { t } = useTranslation()

  const [oldObjective, setOldObjective] = useState<Objective | undefined>()
  const [newObjective, setNewObjective] = useState<Objective | undefined>()

  const [Modal, isOpen, { open, close }] = useModal()
  const openAlertModal = useCallback(
    ({ oldObjective: oldObjectiveArg, newObjective: newObjectiveArg }: OpenProps) => {
      open()
      setOldObjective(oldObjectiveArg)
      setNewObjective(newObjectiveArg)
    },
    [open],
  )

  const AlertModal = useCallback<ModalFC>(
    ({ onConfirm }) => (
      <Modal size="medium" isOpen={isOpen} onClose={close}>
        <Modal.Header title={t('CHANGE_OBJECTIVE_FOR_KEY_RESULT')} />
        <Modal.Content>
          <div css={rootCss}>
            <StyledText fontStyle="regular">
              {t('WARNING_CONNECTION_KR')}
              <br />
              {t('EDITING_INPUT_DISCARD')}
            </StyledText>
            <OkrTitle
              type="objective"
              title={oldObjective?.name ?? ''}
              size="medium"
              withBorder
              fontWeight="regular"
            />
            {oldObjective?.useWeighting && (
              <StyledText fontStyle="regular" size="small" color="text-bk-30">
                {t('X_WEIGHT_RESET_CONFIRMATION', { x: t('OKR') })}
              </StyledText>
            )}
            <StyledText fontStyle="regular">{t('CHANGES_TO')}</StyledText>
            <OkrTitle
              type="objective"
              title={newObjective?.name ?? ''}
              size="medium"
              withBorder
              fontWeight="regular"
            />
            {newObjective?.useWeighting && (
              <StyledText fontStyle="regular" size="small" color="text-bk-30">
                {t('X_WEIGHT_RESET_CONFIRMATION', { x: t('OKR') })}
              </StyledText>
            )}
          </div>
        </Modal.Content>
        <Modal.Footer
          cancelType="tertiary"
          cancelLabel={t('CANCEL')}
          confirmLabel={t('CHANGE')}
          onCancel={close}
          onConfirm={() => {
            if (newObjective?.id == null) return
            onConfirm(newObjective?.id)
            close()
          }}
        />
      </Modal>
    ),
    [
      Modal,
      close,
      isOpen,
      newObjective?.id,
      newObjective?.name,
      newObjective?.useWeighting,
      oldObjective?.name,
      oldObjective?.useWeighting,
      t,
    ],
  )

  return { AlertModal, isOpen, open: openAlertModal }
}
