import { css, SerializedStyles } from '@emotion/react'

import { color } from '../../../../../../styles/newColors'

export const extendedValue = { global: { drop: { zIndex: 111 } } }

export const rootCss = css({ position: 'relative' })

export const editorWrapperCss = css({ marginTop: '20px', display: 'block' })

export const commentTabsWrapperCss = css({
  display: 'flex',
})

export const previousCommentsWrapperCss = css({
  flexGrow: 1,
  borderBottom: `1px solid ${color('border-bk-10')}`,
  borderRadius: 'none',
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'center',
})

export const previousCommentsBtnCss = (): SerializedStyles =>
  css({
    display: 'flex',
    padding: '14px 16px',
  })
