import { useCallback, useMemo } from 'react'
import { useQueryParams } from 'use-query-params'

import { Screen } from '../../../../../../lib/screen'
import { tracker } from '../../../../../../lib/tracking'
import { not } from '../../../../../../lib/union'
import { ActionPlanFieldFragment } from '../graphql'
import { SortEnumParam, OrderEnumParam, Sort, SortObject } from '../types'

const orderList: Readonly<['asc', 'desc']> = ['asc', 'desc']

type SortingActionPlanType = ActionPlanFieldFragment & {
  dueDateTime: number
  updatedAtTime: number
}

const actionPlanStatus = {
  /** 未着手 */
  NotStarted: 'NOT_STARTED',
  /** 着手 */
  Started: 'STARTED',
  /** 完了 */
  Completed: 'COMPLETED',
  /** 保留 */
  Held: 'HELD',
  /** 破棄 */
  Discarded: 'DISCARDED',
} as const

const actionPlanStatusOrder = Object.values(actionPlanStatus)

const compareForSorting = (
  a: SortingActionPlanType,
  b: SortingActionPlanType,
  sort: Sort,
): number => {
  let result: boolean
  switch (sort.sortBy) {
    case SortObject.title:
      result = a.title < b.title
      break
    case SortObject.keyResult:
      result = a.keyResult.name < b.keyResult.name
      break
    case SortObject.status:
      result = actionPlanStatusOrder.indexOf(a.status) < actionPlanStatusOrder.indexOf(b.status)
      break
    case SortObject.dueDate:
      result = a.dueDateTime < b.dueDateTime
      break
    default:
      result = a.updatedAtTime < b.updatedAtTime
      break
  }
  const comparisonResult = sort.order === 'asc' ? result : !result
  return comparisonResult ? -1 : 1
}

export const useSort = (
  actionPlansResp: ReadonlyArray<ActionPlanFieldFragment>,
): {
  sortedActionPlans: ReadonlyArray<ActionPlanFieldFragment>
  sort: Sort
  onClickSortBy: (sortBy: Sort['sortBy']) => void
} => {
  const [queryParams, setQueryParams] = useQueryParams({
    sort: SortEnumParam,
    order: OrderEnumParam,
  })

  const sort = useMemo<Sort>(
    () => ({ sortBy: queryParams.sort, order: queryParams.order }),
    [queryParams.sort, queryParams.order],
  )

  const sortedActionPlans = useMemo(() => {
    const mappedActionPlans = actionPlansResp.map((actionPlan) => {
      const dueDateTime = actionPlan.dueDate ? new Date(actionPlan.dueDate).getTime() : 0 // 日時がISOStringでDate型にされているため、Date型に変換
      const updatedAtTime = new Date(actionPlan.updatedAt).getTime() // 日時がISOStringでDate型にされているため、Date型に変換
      return {
        ...actionPlan,
        dueDateTime,
        updatedAtTime,
      }
    })
    return mappedActionPlans.sort((a, b) => compareForSorting(a, b, sort))
  }, [actionPlansResp, sort])

  const onClickSortBy = useCallback(
    (sortBy: Sort['sortBy']) => {
      const newOrder = sort.order && sort.sortBy === sortBy ? not(orderList)(sort.order) : 'desc'

      tracker.UserClickSortButtonInHome(Screen.HomeActionPlan, sortBy as string, newOrder)

      setQueryParams({
        sort: sortBy,
        order: newOrder,
      })
    },
    [setQueryParams, sort.order, sort.sortBy],
  )

  return { sortedActionPlans, sort, onClickSortBy }
}
