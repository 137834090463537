import { css } from '@emotion/react'
import { useMemo } from 'react'

import { fontSize } from '../../../../styles/font'
import { color } from '../../../../styles/newColors'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useStyles = (
  isUpdateMemoRendered: boolean,
  isQualitative: boolean,
  isSetTargetValue: boolean,
  isMeasurementInvalid: boolean,
) =>
  useMemo(
    () =>
      ({
        root: css({
          display: 'flex',
          gap: '24px',
          marginBottom:
            isUpdateMemoRendered || isQualitative || isSetTargetValue || isMeasurementInvalid
              ? undefined
              : '32px',
        }),
        column: css({
          display: 'flex',
          flexDirection: 'column',
        }),
        label: css({
          width: '108px',
          paddingTop: '18px',
          alignItems: 'start',
        }),
        field: css({
          display: 'flex',
          gap: '32px',
        }),
        progressFieldWrapper: css({
          display: 'flex',
          gap: '16px',
          minWidth: '304px',
        }),
        progressFieldLabel: css({
          display: 'block',
        }),
        progressFieldInputWrapper: css({
          display: 'flex',
          alignItems: 'center',
          gap: '4px',
          input: { textOverflow: 'ellipsis' },
        }),
        error: css({
          color: color('tag-red'),
          ...fontSize('small', 'regular'),
        }),
        progressFieldUnitLabel: css({
          ...fontSize('small', 'regular'),
          maxWidth: '65px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          wordBreak: 'keep-all',
        }),
        initial: css({
          paddingTop: '24px',
          display: 'flex',
          gap: '8px',
          alignItems: 'center',
          height: '60px',
        }),
        confidenceSelect: css({
          width: '134px',
        }),
        confidenceInputMessage: css({
          display: 'block',
        }),
        updateMemoArea: css({
          minWidth: '594px',
          marginBottom: '22px',
        }),
        updateMemoLabel: css({
          display: 'block',
          padding: '12px 0 4px',
        }),
        updateMemoInput: css({
          margin: '0',
        }),
        promptMessageWrapper: css({
          display: 'flex',
          alignItems: 'start',
          maxWidth: '304px',
          marginBottom: isUpdateMemoRendered ? undefined : '24px',
        }),
        promptMessageIcon: css({
          marginTop: '4px',
          marginRight: '8px',
          color: color('tag-red'),
        }),
        promptMessage: css({
          marginTop: '2px',
          whiteSpace: 'pre-wrap',
          lineHeight: '14px',
        }),
        link: css({
          color: color('resily-orange-100'),
        }),
      } as const),
    [isUpdateMemoRendered, isQualitative, isSetTargetValue, isMeasurementInvalid],
  )
