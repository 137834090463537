import { stringify } from 'querystring'

import { Box, Heading } from 'grommet'
import React, { PropsWithoutRef, useMemo, useState, useCallback, useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import { ApplicationCard } from '../../components/domain/ApplicationCard'
import { PageContent } from '../../components/pageContent'
import { Link } from '../../components/ui/Link'
import { StyledText } from '../../components/ui/StyledText'
import { configureEndpoint, configureIntegration } from '../../config'
import {
  OrganizationContext,
  OrganizationDispatchContext,
} from '../../contexts/OrganizationContext'
import { Trans, useTranslation } from '../../i18n'
import { useFlags } from '../../lib/featureToggle'
import { Screen } from '../../lib/screen'
import { useTracking } from '../../lib/tracking'
import { color } from '../../styles/newColors'
import { AuthRouteProps } from '../../types/authRouteProps'
import { ExternalUrls, integrationsAdmin, integrationsSmartHREmployees } from '../../urls'

import { SlackApp } from './SlackApp'
import { SmartHRApp } from './SmartHRApp'
import { SmartHRSettingModal } from './SmartHRModal'
import {
  IntegateSmartHrInput,
  useIntegrateSmartHrMutation,
  useSegregateSmartHrMutation,
  useSegregateSlackIntegrationMutation,
  useUpdateBulkUpdateNotificationEnabledMutation,
} from './graphql'

const { base } = configureEndpoint({
  protocol: window.location.protocol,
  host: window.location.host,
})

const { slack } = configureIntegration()

export const AdminIntegrationsContainer: React.FC<AuthRouteProps> = ({ ...props }) => {
  const organization = useContext(OrganizationContext)
  const setOrganization = useContext(OrganizationDispatchContext)
  const [segregateSlackIntegrationMutation] = useSegregateSlackIntegrationMutation()
  const [updateBulkUpdateNotificationEnabledMutation] =
    useUpdateBulkUpdateNotificationEnabledMutation()
  const updateSlackNotifyBulkUpdateEnabled = useCallback(
    async (enabled: boolean) => {
      await updateBulkUpdateNotificationEnabledMutation({ variables: { enabled } })
    },
    [updateBulkUpdateNotificationEnabledMutation],
  )

  const scopeString = slack.scope.join(',')
  const integrateSlack = useCallback(() => {
    // 1. SlackのAPIサーバににリダイレクト
    window.location.href = `https://slack.com/oauth/v2/authorize?${stringify({
      client_id: slack.clientId,
      scope: scopeString,
      // 2. SlackのAPIサーバからResilyのAPIサーバにリダイレクト
      redirect_uri: `${base}/slack/auth?${stringify({
        // 3. ResilyのAPIのサーバからフロントのViewにリダイレクト
        redirect_uri: window.location.href,
      })}`,
    })}`
  }, [scopeString])

  const [integrationsSmartHREmployeesMutation] = useIntegrateSmartHrMutation()
  const [segregateSmartHrMutation] = useSegregateSmartHrMutation()

  const integrateSmartHR = useCallback(
    async (input: IntegateSmartHrInput) => {
      const { data } = await integrationsSmartHREmployeesMutation({
        variables: {
          input,
        },
      })
      if (!data?.integrateSmartHR || !organization) return
      setOrganization({
        ...organization,
        smartHRIntegrationEnabled: data.integrateSmartHR.smartHRIntegrationEnabled,
      })
    },
    [integrationsSmartHREmployeesMutation, organization, setOrganization],
  )

  const segregateSmartHR = useCallback(async () => {
    const { data } = await segregateSmartHrMutation()
    if (!data?.segregateSmartHR || !organization) return
    setOrganization({
      ...organization,
      smartHRIntegrationEnabled: data.segregateSmartHR.smartHRIntegrationEnabled,
    })
  }, [organization, segregateSmartHrMutation, setOrganization])

  if (!organization) {
    return null
  }

  return (
    <AdminIntegrations
      smarthrIntegrationEnabled={organization.smartHRIntegrationEnabled}
      slackIntegrationEnabled={organization.slackIntegrationEnabled}
      slackNotifyBulkUpdateEnabled={organization.bulkUpdateNotificationEnabled}
      integrateSlack={integrateSlack}
      segregateSlack={segregateSlackIntegrationMutation}
      integrateSmartHR={integrateSmartHR}
      segregateSmartHR={segregateSmartHR}
      updateSlackNotifyBulkUpdateEnabled={updateSlackNotifyBulkUpdateEnabled}
      {...props}
    />
  )
}

AdminIntegrationsContainer.displayName = 'AdminIntegrationsContainer'

type Props = PropsWithoutRef<JSX.IntrinsicElements['section']> & {
  smarthrIntegrationEnabled: boolean
  slackIntegrationEnabled: boolean
  slackNotifyBulkUpdateEnabled: boolean
  integrateSlack: () => void
  segregateSlack: () => void
  integrateSmartHR: (arg: IntegateSmartHrInput) => Promise<void>
  segregateSmartHR: () => void
  updateSlackNotifyBulkUpdateEnabled: (enabled: boolean) => void
}

export const AdminIntegrations: React.FC<Props> = ({
  slackIntegrationEnabled,
  smarthrIntegrationEnabled,
  slackNotifyBulkUpdateEnabled,
  integrateSlack,
  segregateSlack,
  integrateSmartHR,
  segregateSmartHR,
  updateSlackNotifyBulkUpdateEnabled,
}) => {
  const { t } = useTranslation()
  const { bulkUpdateSlackDm, smarthr } = useFlags()
  const [isOpened, setIsOpend] = useState(false)
  const [isSmartHRIntegrationLoading, setIsSmartHRIntegrationLoading] = useState(false)
  const navigate = useNavigate()

  const closeSmartHRModal = useCallback(() => {
    setIsOpend(false)
  }, [setIsOpend])

  const moveSmartHRSetting = useCallback(() => {
    navigate(integrationsSmartHREmployees)
    // NOTE: admin containerの構成上画面が切り替わらない対策
    navigate(integrationsSmartHREmployees)
  }, [navigate])

  const handleIntegrateSmartHR = useCallback(
    async (value: IntegateSmartHrInput) => {
      try {
        setIsSmartHRIntegrationLoading(true)
        await integrateSmartHR(value)
        setIsSmartHRIntegrationLoading(false)
        moveSmartHRSetting()
      } catch (e) {
        setIsSmartHRIntegrationLoading(false)
        throw new Error('integration smarthr error')
      }
    },
    [integrateSmartHR, moveSmartHRSetting],
  )

  useTracking(t('ADMIN_INTEGRATION_PAGE_TITLE'), Screen.IntegrationSetting)

  const apps = useMemo(() => {
    const values = [
      SlackApp({
        integrateSlack,
        segregateSlack,
        enableOption: bulkUpdateSlackDm,
        updateSlackNotifyBulkUpdateEnabled,
        slackIntegrationEnabled,
        slackNotifyBulkUpdateEnabled,
      }),
    ]
    if (smarthr) {
      return values.concat(
        SmartHRApp({
          enabled: smarthrIntegrationEnabled,
          onClickSetting: moveSmartHRSetting,
          onClickIntegrate: () => {
            setIsOpend(true)
          },
          onClickSegregate: segregateSmartHR,
        }),
      )
    }
    return values
  }, [
    integrateSlack,
    segregateSlack,
    bulkUpdateSlackDm,
    smarthr,
    updateSlackNotifyBulkUpdateEnabled,
    slackIntegrationEnabled,
    slackNotifyBulkUpdateEnabled,
    smarthrIntegrationEnabled,
    moveSmartHRSetting,
    segregateSmartHR,
  ])

  return (
    <PageContent
      breadcrumbs={{
        url: integrationsAdmin,
        items: [{ breadcrumbName: 'setting' }, { breadcrumbName: 'integrations' }],
      }}
      layout={{ css: { padding: '48px 32px' } }}
      contentBackgroundColor={color('background-bk-5')}
    >
      <Box gap="16px">
        <div css={{ display: 'flex', flexFlow: 'column', gap: '8px' }}>
          <Heading level="3" css={{ fontSize: '20px' }}>
            {t('INTEGRATIONS')}
          </Heading>
          <StyledText>
            <Trans
              i18nKey="INTEGRATIONS_DESCRIPTION"
              components={[
                <Link
                  href={ExternalUrls.HOW_TO_INTEGRATE}
                  method="newTab"
                  css={{
                    color: color('resily-orange-100'),
                    textDecoration: 'underline',
                  }}
                />,
              ]}
            />
          </StyledText>
        </div>
        <ApplicationCard apps={apps} />
        <SmartHRSettingModal
          isOpened={isOpened}
          integrationLoading={isSmartHRIntegrationLoading}
          handleIntegrate={handleIntegrateSmartHR}
          onClickClose={closeSmartHRModal}
        />
      </Box>
    </PageContent>
  )
}

AdminIntegrations.displayName = 'AdminIntegrations'
