import { useModal } from '@resily/geisha'
import { useCallback, useEffect, useMemo } from 'react'

import { useGetDiscardAlertModalParam } from '../../../contexts/EditingStateContext'
import { useCurrentUser } from '../../../contexts/UserContext'
import { useDiscardAlertModal } from '../DiscardAlertModal'

import { OkrModal, OkrModalProps } from './OkrModal'
import { useChildrenOkrNodesQuery, useInitQuery } from './graphql'
import { useOkrModalQueryParams } from './hooks/useOkrModalQueryParams'

export type OkrModalContainerProps = Pick<
  OkrModalProps,
  'isOpened' | 'close' | 'objective' | 'keyResult'
> & {
  keyResultDrawer: Omit<OkrModalProps['keyResultDrawer'], 'keyResults'>
}

export const OkrModalContainer: React.VFC<
  OkrModalContainerProps & { modalComponent: ReturnType<typeof useModal>[0] }
> = ({ modalComponent: Modal, close, ...rest }) => {
  const { objectiveId } = useOkrModalQueryParams()

  // 無効なOKRを表示しない設定の場合、Oが無効化されたらOKR詳細モーダルを閉じる
  const user = useCurrentUser()
  const isShowDisabledOkr = !!user?.userSetting.showDisabledOkrs
  const onDisabledObjective = useCallback(
    () => !isShowDisabledOkr && close(),
    [close, isShowDisabledOkr],
  )

  const { data, loading } = useInitQuery({
    variables: { objectiveId },
    skip: objectiveId == null,
  })

  // 無効なOKRを表示しない設定の場合は無効な子OKRの存在チェックをする
  const { data: childrenOkrNodesData } = useChildrenOkrNodesQuery({
    variables: { okrNodeId: data?.okrNode.id ?? '' },
    skip: data?.okrNode.id == null,
  })
  const existsChildOkr = useMemo(() => {
    const childrenOkrNodes = childrenOkrNodesData?.childrenOkrNodes ?? []
    const filteredChildrenOkrNodes = isShowDisabledOkr
      ? childrenOkrNodes
      : childrenOkrNodes.filter((node) => !node.objective.isDisabled)
    return filteredChildrenOkrNodes.length > 0
  }, [childrenOkrNodesData?.childrenOkrNodes, isShowDisabledOkr])

  // TODO: グローバルに置きたいAlertModalだが暫定的にここに置いている
  const { isRequired, onClickCancel, onClickDiscard } = useGetDiscardAlertModalParam()
  const { DiscardAlertModal: GlobalDiscardAlertModal, open: openGlobalDiscardAlertModal } =
    useDiscardAlertModal({ onCancel: onClickCancel, onConfirm: onClickDiscard })
  useEffect(() => {
    if (isRequired) {
      openGlobalDiscardAlertModal()
    }
  }, [isRequired, openGlobalDiscardAlertModal])

  return (
    <>
      <OkrModal
        modalComponent={Modal}
        close={close}
        loading={(loading && data == null) as never}
        objectiveAreaData={data?.okrNode.objective}
        keyResultAreaData={useMemo(
          () => ({
            okrNodeId: data?.okrNode.id,
            keyResults: data?.okrNode.keyResults,
            useWeighting: data?.okrNode.objective.useWeighting,
            disabledReOrderingChildOkr: !existsChildOkr,
          }),
          [
            data?.okrNode.id,
            data?.okrNode.keyResults,
            data?.okrNode.objective.useWeighting,
            existsChildOkr,
          ],
        )}
        checkinAreaData={useMemo(
          () => ({ okrNodeId: data?.okrNode.id, keyResults: data?.okrNode.keyResults }),
          [data?.okrNode.id, data?.okrNode.keyResults],
        )}
        rightSection={useMemo(() => ({ onDisabledObjective }), [onDisabledObjective])}
        rightSectionData={data?.okrNode}
        keyResultDrawerData={useMemo(
          () => ({ keyResults: data?.okrNode.keyResults }),
          [data?.okrNode.keyResults],
        )}
        {...rest}
      />
      <GlobalDiscardAlertModal />
    </>
  )
}
