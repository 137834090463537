import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'

import { PageContent } from '../../components/pageContent'
import { Button } from '../../components/ui/ButtonDeprecated'
import { FormField } from '../../components/ui/FormField'
import { Input } from '../../components/ui/InputDeprecated'
import { useTranslation } from '../../i18n'
import { formSchema } from '../../lib/domain/password'
import { Screen } from '../../lib/screen'
import { useTracking } from '../../lib/tracking'

import { Wrapper } from './Wrapper'

const title = {
  lineHeight: 1.5,
}

const label = {
  display: 'inline-block',
  marginRight: '8px',
}
const input = {
  width: '300px',
}

type FormValue = {
  newPassword: string
  passwordConfirmation: string
}

export type Props = {
  token: string
  onSubmit: (values: { newPassword: string; passwordConfirmation: string; token: string }) => void
}

export const PasswordResetConfirm: React.VFC<Props> = ({ token, onSubmit }) => {
  const { t } = useTranslation()
  useTracking(t('PASSWORD_RESET_CONFIRM_PAGE_TITLE'), Screen.ResetPasswordConfirmation)
  const {
    register,
    handleSubmit,
    formState: { errors },
    formState,
  } = useForm<FormValue>({
    resolver: yupResolver(formSchema(t)),
    defaultValues: {
      newPassword: '',
      passwordConfirmation: '',
    },
  })

  return (
    <PageContent
      breadcrumbs={undefined}
      layout={{
        height: '100%',
        align: 'center',
        justify: 'center',
      }}
    >
      <Wrapper>
        <h1 css={title}>{t('RESET_PASSWORD_CONFIRM')}</h1>
        <form onSubmit={handleSubmit((values: FormValue) => onSubmit({ ...values, token }))}>
          <FormField
            label={t('PASSWORD')}
            errorMessage={errors.newPassword ? `${errors.newPassword.message}` : ''}
            css={label}
          >
            <Input
              css={input}
              type="password"
              autoComplete="new-password"
              {...register('newPassword')}
            />
          </FormField>
          <FormField
            label={t('PASSWORD_CONFIRMATION')}
            errorMessage={
              errors.passwordConfirmation ? `${errors.passwordConfirmation.message}` : ''
            }
            css={label}
          >
            <Input
              css={input}
              type="password"
              autoComplete="new-password"
              {...register('passwordConfirmation')}
            />
          </FormField>
          <div
            css={{
              marginTop: '24px',
              textAlign: 'center',
            }}
          >
            <Button type="submit" disabled={formState.isSubmitting}>
              {t('SIGN_UP')}
            </Button>
          </div>
        </form>
      </Wrapper>
    </PageContent>
  )
}

PasswordResetConfirm.displayName = 'PasswordResetConfirm'
