import { useCallback, useContext, useMemo } from 'react'

import { OkrTermIdContext } from '../../../../../contexts/OkrTermIdContext'
import { findScreen } from '../../../../../lib/screen'
import { tracker } from '../../../../../lib/tracking'
import { Activities } from '../../Common/Activities'
import { useOkrModalQueryParams } from '../../hooks/useOkrModalQueryParams'

import { useActivitiesQuery } from './graphql'

const ACTIVITIES_PER_PAGE = 10

export const ActivityContainer: React.VFC = () => {
  const { okrTermId } = useContext(OkrTermIdContext)
  const { keyResultId } = useOkrModalQueryParams()

  const { data, loading, fetchMore } = useActivitiesQuery({
    variables: {
      keyResultIds: keyResultId ?? [],
      first: ACTIVITIES_PER_PAGE,
    },
    skip: keyResultId == null,
  })

  const activities = useMemo(
    () => data?.findActivitiesByKeyResultIds.edges.map((edge) => edge.node) ?? [],
    [data?.findActivitiesByKeyResultIds.edges],
  )

  const endCursor = data?.findActivitiesByKeyResultIds.pageInfo.endCursor
  const hasNextPage = data?.findActivitiesByKeyResultIds?.pageInfo.hasNextPage
  const canFetchMore = endCursor != null && hasNextPage
  const handleFetchMore = useCallback(() => {
    if (!canFetchMore) return
    fetchMore({
      variables: {
        first: ACTIVITIES_PER_PAGE,
        after: endCursor,
      },
    })
    tracker.UserClickShowMoreActivityByKeyResultDrawer(
      findScreen(window.location.pathname, window.location.search),
    )
  }, [canFetchMore, endCursor, fetchMore])

  if (okrTermId == null) return null

  return (
    <Activities
      loading={loading && data == null}
      termId={okrTermId}
      activities={activities}
      showMore={canFetchMore}
      onClickMore={handleFetchMore}
    />
  )
}
