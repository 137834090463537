import { useGetFormMethods } from '../../form/contexts/FormProvider'
import { IndexArea } from '../IndexArea'
import { useExtractKeyResultsFragment } from '../hooks/useExtractKeyResultsFragment'
import { useSelectedKeyResultIdState } from '../hooks/useSelectedKeyResultIdState'
import { CheckinModalGrid } from '../layout/CheckInModalGrid'

import { ConfirmationArea } from './components/ConfirmationArea'

export const CheckinModalConfirmation: React.VFC = () => {
  const { control } = useGetFormMethods()
  const indexAreaKeyResults = useExtractKeyResultsFragment({ control })

  const [selectedKeyResultId, setSelectedKeyResultId] = useSelectedKeyResultIdState({
    keyResults: indexAreaKeyResults,
  })

  return (
    <CheckinModalGrid>
      {/** Indexエリア */}
      <IndexArea keyResults={indexAreaKeyResults} selectedKeyResultId={selectedKeyResultId} />
      {/** Confirmationエリア */}
      <ConfirmationArea onSelectKeyResult={setSelectedKeyResultId} />
    </CheckinModalGrid>
  )
}

CheckinModalConfirmation.displayName = 'CheckinModalConfirmation'
