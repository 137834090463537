import { css } from '@emotion/react'
import { useMemo } from 'react'

import { color } from '../../../styles/newColors'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useStyles = (fullWidth: boolean) =>
  useMemo(
    () =>
      ({
        root: css({
          minWidth: 250,
          width: fullWidth ? '100%' : undefined,
          height: 40,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '12px 24px 12px 16px',
          gap: 8,
          border: `1px solid ${color('border-bk-30')}`,
          borderRadius: 4,
          userSelect: 'none',
        }),
        rightIcon: css({
          width: 16,
          height: 16,
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          '> svg': {
            transition: 'transform 0.1s',
            '&[data-show-drop="true"]': { transform: 'scaleY(-1)' },
          },
        }),
        placeholder: css({
          color: color('text-bk-30'),
          fontSize: '14px',
          lineHeight: '14px',
        }),
        selectedValue: css({
          width: '100%',
          height: 16,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }),
        // HACK: grommetのDropの高さを制御する手段が無いので強制的に上限を指定
        drop: css({ maxHeight: '60vh !important' }),
        dropAlign: { top: 'bottom', left: 'left' },
      } as const),
    [fullWidth],
  )
