import { keyframes } from '@emotion/react'
import { PropsWithoutRef } from 'react'

import * as colors from '../../../styles/colors'

export type Props = PropsWithoutRef<JSX.IntrinsicElements['div']>

const bar1 = keyframes({
  '0%': {
    left: '-35%',
    right: '100%',
  },
  '60%': {
    left: '100%',
    right: '-90%',
  },
  '100%': {
    left: '100%',
    right: '-90%',
  },
})
const bar2 = keyframes({
  '0%': {
    left: '-200%',
    right: '100%',
  },
  '60%': {
    left: '107%',
    right: '-8%',
  },
  '100%': {
    left: '107%',
    right: '-8%',
  },
})
const timing = 'cubic-bezier(0.65, 0.815, 0.735, 0.395)'

export const ProgressBar: React.FC<Props> = (props) => (
  <div
    css={{
      width: '100%',
      height: 3,
      overflow: 'hidden',
      position: 'relative',
      '&::before': {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        display: 'block',
        width: 'auto',
        content: `""`,
        background: colors.main,
        animation: `${bar1} 2.1s ${timing} infinite`,
      },
      '&::after': {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        display: 'block',
        width: 'auto',
        content: `""`,
        background: colors.main,
        animation: `${bar2} 2.1s ${timing} 1.15s infinite`,
      },
    }}
    {...props}
  />
)

ProgressBar.displayName = 'ProgressBar'
