import { Drop } from 'grommet'
import { Fragment, useEffect, useRef, useState } from 'react'

import { useTranslation } from '../../../i18n'
import { getUserSearchText } from '../../../lib/domain/user/searchText'
import { getCandidateUsers } from '../../../lib/domain/users'
import { color, color as newColor } from '../../../styles/newColors'
import { Avatar } from '../../ui/Avatar'
import { AvatarWithName } from '../../ui/AvatarWithName'
import { Calendar } from '../../ui/Calendar/index'
import { DateTime } from '../../ui/DateTime'
import { Icon } from '../../ui/Icon'
import { MultiSelect, Option } from '../../ui/MultiSelect'
import { StyledText } from '../../ui/StyledText'
import { TooltipNew } from '../../ui/TooltipNew'
import { ActionPlanStatusBadge } from '../ActionPlanStatusBadge/index'
import { ActionPlanStatusSelect } from '../ActionPlanStatusSelect'
import { getCandidateContributors, isCandidateGroup, Contributor } from '../Contributor'
import { UserTag } from '../UserTag'

import {
  ActionPlanFragment,
  useFindActionPlanByIdQuery,
  UserFragment,
  useUpdateActionPlanMutation,
} from './graphql'

export type Props = {
  actionPlanId: string
}

type SelectedOwnerProps = {
  users: ReadonlyArray<UserFragment>
  ownerId: string
  onClose: () => void
  onClickUserOption: (id: string) => void
}

const SelectedOwner: React.FC<SelectedOwnerProps> = ({
  onClose,
  users,
  ownerId,
  onClickUserOption,
}) => {
  const { t } = useTranslation()
  const [optionList, setOptionList] = useState<ReadonlyArray<Option>>([])

  useEffect(() => {
    const userList = users
      .map(({ id, firstName, lastName, email, avatar, isDisabled }) => ({
        id,
        name: t('FULL_NAME', { firstName, lastName }),
        searchText: getUserSearchText({ firstName, lastName, email }),
        selected: users.some((value) => value.id === ownerId),
        isDisabled,
        icon: (
          <Avatar
            tooltipDisabled
            firstName={firstName}
            lastName={lastName}
            avatarUrl={avatar?.url}
            size="xsmall"
            css={{ marginRight: '8px' }}
            isUserDisabled={isDisabled}
          />
        ),
      }))
      .sort((a, b) => (b.selected ? 1 : -1) - (a.selected ? 1 : -1))
    setOptionList(userList)
  }, [ownerId, setOptionList, t, users])

  return (
    <Fragment>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        css={{
          position: 'fixed',
          width: '100vw',
          height: '100vh',
          top: 0,
          left: 0,
          backgroundColor: 'transparent',
          zIndex: 1,
        }}
        onClick={onClose}
      />
      <div css={{ position: 'absolute', zIndex: 2 }}>
        <MultiSelect
          canSearch
          hideSelectedCheck
          options={optionList}
          searchPlaceholder={t('INPUT_X', { x: t('USER_NAME') })}
          onClickOption={onClickUserOption}
        />
      </div>
    </Fragment>
  )
}

SelectedOwner.displayName = 'SelectedOwner'

export const ActionPlanOption: React.FC<Props> = ({ actionPlanId }) => {
  const { t } = useTranslation()
  const [ownerOpen, setOwnerOpen] = useState(false)
  const [contributorOpen, setContributorOpen] = useState(false)
  const [contributorIds, setContributorIds] = useState<ReadonlyArray<string>>([])
  const [actionPlanStatusOpen, setActionPlanStatusOpen] = useState<boolean>(false)
  const [dueDateOpen, setDueDateOpen] = useState<boolean>(false)

  const actionPlanStatusButtonRef = useRef<HTMLButtonElement>(null)
  const dueDateButtonRef = useRef<HTMLButtonElement>(null)

  const res = useFindActionPlanByIdQuery({
    variables: { id: actionPlanId },
  })

  const [updateActionPlanMutation] = useUpdateActionPlanMutation()

  const actionPlan = res?.data?.findActionPlanById

  useEffect(() => {
    if (!res.data?.findActionPlanById) return
    setContributorIds(res.data.findActionPlanById.members.map((e) => e.id))
  }, [actionPlanId, res])

  if (!actionPlan) return null

  const ownerId = actionPlan.owner.id
  const candidateMembers = getCandidateUsers<UserFragment>(
    actionPlan.keyResult.objective.node.term.organization.users,
  )
  const candidateOwners = candidateMembers.filter((o) => contributorIds.indexOf(o.id) < 0)

  const candidateContributors = getCandidateContributors(
    actionPlan.members,
    candidateMembers,
    actionPlan.owner.id,
  )

  const isOwnerDisabled = actionPlan.owner.isDisabled

  const candidateGroups = actionPlan.keyResult.objective.node.term.organization.groups.filter((g) =>
    isCandidateGroup(g, ownerId, actionPlan.members),
  )

  const updateActionPlan = async (value: Partial<ActionPlanFragment>) => {
    const variables: ActionPlanFragment = {
      ...actionPlan,
      ...value,
    }
    await updateActionPlanMutation({
      variables: {
        id: variables.id,
        ownerId: variables.owner.id,
        keyResultId: variables.keyResult.id,
        memberIds: variables.members.map((e) => e.id),
        updateType: variables.updateType,
        status: variables.status,
        dueDate: variables.dueDate,
      },
    }).catch(() => {})
  }

  return (
    <div
      css={{
        marginLeft: '32px',
        width: '200px',
      }}
    >
      <dl
        css={{
          dt: {
            marginBottom: '12px',
            fontSize: '12px',
            fontWeight: 'bold',
            color: newColor('text-bk-50'),
          },
          dd: {
            marginBottom: '24px',
            fontSize: '14px',
            color: newColor('text-bk-100'),
          },
        }}
      >
        <dt>{t('STATUS')}</dt>
        <dd>
          <div css={{ display: 'flex', alignItems: 'center' }}>
            <ActionPlanStatusBadge status={actionPlan.status} />
            <button
              type="button"
              ref={actionPlanStatusButtonRef}
              onClick={() => setActionPlanStatusOpen(true)}
            >
              <Icon
                type="selectDown"
                css={{
                  display: 'block',
                  width: '8px',
                  height: '8px',
                  margin: '8px',
                  color: color('text-bk-50'),
                }}
              />
            </button>
            {actionPlanStatusOpen && (
              <Drop
                target={actionPlanStatusButtonRef.current || undefined}
                align={{
                  top: 'bottom',
                }}
                onClickOutside={() => setActionPlanStatusOpen(false)}
              >
                <ActionPlanStatusSelect
                  onSelect={async (status) => {
                    await updateActionPlan({ status })
                    setActionPlanStatusOpen(false)
                  }}
                />
              </Drop>
            )}
          </div>
        </dd>

        <dt>{t('OWNER')}</dt>
        <dd>
          <div css={{ position: 'relative' }}>
            <div
              css={{
                cursor: 'pointer',
                paddingLeft: 8,
                justifyContent: 'space-between',
                height: 40,
                display: 'flex',
                alignItems: 'center',
                border: `1px solid ${color('border-bk-30')}`,
                boxSizing: 'border-box',
                borderRadius: 3,
                a: {
                  width: 'calc(100% - 32px)',
                },
              }}
              onClick={() => {
                setOwnerOpen(true)
              }}
              onKeyDown={() => {}}
              role="button"
              tabIndex={0}
            >
              <AvatarWithName
                size="small"
                nameFontSize="small"
                firstName={actionPlan.owner.firstName}
                lastName={actionPlan.owner.lastName}
                avatarUrl={actionPlan.owner.avatar?.url}
                isUserDisabled={isOwnerDisabled}
              />
              <div css={{ padding: 8 }}>
                <Icon type="polygon" />
              </div>
            </div>
            {ownerOpen ? (
              <SelectedOwner
                users={candidateOwners}
                ownerId={actionPlan.owner.id}
                onClose={() => setOwnerOpen(false)}
                onClickUserOption={async (id) => {
                  if (id === actionPlan.owner.id) {
                    setOwnerOpen(false)
                    return
                  }
                  const newOwner = candidateOwners.find((o) => o.id === id)
                  if (newOwner) {
                    await updateActionPlan({
                      owner: newOwner,
                    })
                  }
                  setOwnerOpen(false)
                }}
                css={{ height: '32px', width: '100%' }}
              />
            ) : (
              isOwnerDisabled && (
                <StyledText
                  size="xsmall"
                  css={{
                    color: '#D42922',
                  }}
                >
                  {t('ALREADY_DISABLED_USER')}
                </StyledText>
              )
            )}
          </div>
        </dd>
        <dt>{t('CREATED_ON')}</dt>
        <dd>
          <DateTime datetime={actionPlan.createdAt} withoutTime />
        </dd>
        <Fragment>
          <dt
            css={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {t('DUE_DATE')}
            <TooltipNew title={t('EDITION')}>
              <button
                type="button"
                ref={dueDateButtonRef}
                css={{
                  paddingRight: 5,
                }}
                onClick={() => setDueDateOpen(true)}
              >
                <Icon
                  type="editSimple"
                  hoverColor="resily-orange-100"
                  css={{ color: color('text-bk-30') }}
                />
              </button>
            </TooltipNew>
            {dueDateOpen && (
              <Drop
                target={dueDateButtonRef.current || undefined}
                align={{ top: 'bottom', right: 'right' }}
                onClickOutside={() => setDueDateOpen(false)}
              >
                <Calendar
                  locale="ja"
                  onSelectDate={async (date) => {
                    await updateActionPlan({ dueDate: date })
                    setDueDateOpen(false)
                  }}
                />
              </Drop>
            )}
          </dt>
          <dd>{actionPlan.dueDate && <DateTime datetime={actionPlan.dueDate} withoutTime />}</dd>
        </Fragment>
        <dt>
          <div
            css={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <span>{t('CONTRIBUTOR')}</span>
            {!!contributorIds.length && (
              <TooltipNew title={t('EDITION')}>
                <button
                  type="button"
                  css={{
                    paddingRight: 5,
                  }}
                  onClick={() => setContributorOpen(true)}
                >
                  <Icon
                    type="editSimple"
                    hoverColor="resily-orange-100"
                    css={{ color: color('text-bk-30') }}
                  />
                </button>
              </TooltipNew>
            )}
          </div>
        </dt>
        <dd>
          {!contributorIds.length && (
            <button
              type="button"
              css={{ display: 'flex', alignItems: 'center', outline: 'none' }}
              onClick={() => setContributorOpen(true)}
            >
              <Icon
                type="plus"
                css={{
                  width: 12,
                  height: 16,
                  color: color('border-bk-40'),
                }}
              />
              <span
                css={{
                  marginLeft: 8,
                  lineHeight: '19px',
                  fontSize: 14,
                  color: color('border-bk-40'),
                }}
              >
                {t('ADD_X', { x: t('CONTRIBUTOR') })}
              </span>
            </button>
          )}
          {contributorOpen && (
            <Contributor
              groups={candidateGroups}
              users={candidateContributors}
              selectedUserIds={contributorIds}
              onClose={() => setContributorOpen(false)}
              onClickUserOption={async (next) => {
                setContributorIds(next)
                await updateActionPlan({
                  members: candidateContributors.filter((m) => next.includes(m.id)),
                })
              }}
            />
          )}
          {contributorIds
            .map((c) => actionPlan.members.find((o) => o.id === c))
            .filter((c): c is Exclude<typeof c, undefined> => !!c)
            .map((c) => (
              <div css={{ marginBottom: '8px' }}>
                <UserTag
                  termId={actionPlan.keyResult.objective.node.term.id}
                  id={c.id}
                  firstName={c.firstName}
                  lastName={c.lastName}
                  avatar={c.avatar}
                  hoverBackground={color('border-bk-10')}
                  isUserDisabled={c.isDisabled}
                />
              </div>
            ))}
        </dd>
      </dl>
    </div>
  )
}

ActionPlanOption.displayName = 'ActionPlanOption'
