import { Box } from 'grommet'

import { Icon } from '../../../../../components/ui/Icon'
import { color } from '../../../../../styles/newColors'

import { Sort } from './types'

type Props = {
  sortBy: Sort['sortBy']
  sort: Sort
  onClick: (sortBy: Sort['sortBy']) => void
}

export const SortButton: React.FC<Props> = ({ sortBy, sort, onClick, children }) => (
  <Box
    direction="row"
    align="center"
    onClick={() => {
      onClick(sortBy)
    }}
    css={{
      color: sort.sortBy === sortBy ? color('resily-orange-100') : color('text-bk-50'),
    }}
  >
    {children}
    <Icon
      type="mdiKeyboardArrowDown"
      color={sort.sortBy === sortBy ? color('resily-orange-100') : color('text-bk-50')}
      css={{
        width: 16,
        height: 16,
        transform: sort.sortBy === sortBy && sort.order === 'asc' ? 'scaleY(-1)' : undefined,
        transition: 'transform 0.5s',
      }}
    />
  </Box>
)
