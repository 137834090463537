import { Box, Button, Text, TextInput } from 'grommet'
import { Add, Trash } from 'grommet-icons'
import { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { v1 as id } from 'uuid'

import { Modal } from '../../../../components/ui/Modal'
import { StyledText } from '../../../../components/ui/StyledText'
import { useTranslation } from '../../../../i18n'
import { Screen } from '../../../../lib/screen'
import { useTracking } from '../../../../lib/tracking'
import * as colors from '../../../../styles/colors'

const IP_ADDRESS_PATTERN =
  /^(([1-9]?[0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([1-9]?[0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/

type Item = {
  key: string
  value: string
}

export type Props = {
  defaultList: ReadonlyArray<string>
  onClickSave: (list: ReadonlyArray<string>) => void
}

export const IpTableList: React.FC<Props> = ({ defaultList, onClickSave }) => {
  const { t } = useTranslation()
  useTracking(t('ADMIN_IP_WHITELIST_PAGE_TITLE'), Screen.IpWhiteListSetting)
  const [data, setData] = useState<ReadonlyArray<Item>>(
    defaultList.map((value) => ({ key: id(), value })),
  )
  const {
    register,
    control,
    formState: { errors },
    getValues,
    handleSubmit,
  } = useForm()
  const [isShowCreateIPAddressRestrictionModal, setIsShowCreateIPAddressRestrictionModal] =
    useState<boolean>(false)

  const append = () => setData([...data, { key: id(), value: '' }])
  const remove = (index: number) => setData([...data.slice(0, index), ...data.slice(index + 1)])

  const onSubmit = () => {
    // https://stackoverflow.com/questions/48618635/require-sorting-on-ip-address-using-js
    const ipEvalFn = (ip: string) =>
      Number(
        ip
          .split('.')
          .map((num) => `000${num}`.slice(-3))
          .join(''),
      )

    const uniqueList = Array.from(
      new Map(Object.entries<string>(getValues()).map(([key, value]) => [value, key])),
    ).map<Item>(([value, key]) => ({ key, value }))

    const validatedUniqueList = uniqueList
      .filter(({ value }) => value !== '')
      .filter(({ value }) => IP_ADDRESS_PATTERN.test(value))
      .sort((lhs, rhs) => ipEvalFn(lhs.value) - ipEvalFn(rhs.value))

    setData(validatedUniqueList)
    setIsShowCreateIPAddressRestrictionModal(true)
  }

  const onReset = () => defaultList.map((value) => ({ key: id(), value }))

  return (
    <Box
      margin={{ vertical: '48px' }}
      pad={{ bottom: '48px' }}
      border={{ color: colors.border, side: 'bottom', size: '1px' }}
      css={{
        '&:last-of-type': {
          borderBottom: 'none',
        },
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          direction="row"
          border={{
            color: colors.buttonsBorder,
            side: 'bottom',
          }}
          pad="8px"
          margin={{ bottom: '24px' }}
        >
          <Text weight="bold" css={{ display: 'inline-block' }}>
            {t('IP_RESTRICTION')}
          </Text>
          <Box direction="row" justify="end" flex="grow">
            <Button hoverIndicator icon={<Add />} onClick={append} />
            <IpAddressRestrictionsModal
              isShowCreateIPAddressRestrictionModal={isShowCreateIPAddressRestrictionModal}
              setIsShowCreateIPAddressRestrictionModal={setIsShowCreateIPAddressRestrictionModal}
              onClickSave={onClickSave}
              data={data}
            />
            <Button
              type="submit"
              color={colors.buttonsBorder}
              label={t('SAVE')}
              margin={{ horizontal: '5px' }}
            />
            <Button
              color={colors.buttonsBorder}
              label={t('RESET')}
              margin={{ horizontal: '5px' }}
              onClick={onReset}
            />
          </Box>
        </Box>

        {data.map((item, index) => (
          <Box key={item.key} direction="column" pad="8px">
            <Box direction="row">
              <Controller
                render={() => (
                  <TextInput
                    {...register(`${item.key}`)}
                    pattern={IP_ADDRESS_PATTERN.source}
                    maxLength={15}
                  />
                )}
                name={item.key}
                control={control}
                defaultValue={item.value}
                rules={{
                  required: {
                    value: true,
                    message: t('REQUIRED_ERROR'),
                  },
                  maxLength: 15,
                  pattern: {
                    value: IP_ADDRESS_PATTERN,
                    message: t('INVALID_IP_ADDRESS_FORMAT'),
                  },
                }}
              />
              <Button hoverIndicator icon={<Trash />} onClick={() => remove(index)} />
            </Box>
            <Text color={colors.error}>{errors[item.key]?.message}</Text>
          </Box>
        ))}
      </form>
    </Box>
  )
}

IpTableList.displayName = 'IpTableList'

type IpAddressRestrictionsModalProps = {
  isShowCreateIPAddressRestrictionModal: boolean
  setIsShowCreateIPAddressRestrictionModal: (v: boolean) => void
  onClickSave: (list: ReadonlyArray<string>) => void
  data: ReadonlyArray<Item>
}

const IpAddressRestrictionsModal: React.FC<IpAddressRestrictionsModalProps> = ({
  isShowCreateIPAddressRestrictionModal,
  setIsShowCreateIPAddressRestrictionModal,
  onClickSave,
  data,
}) => {
  const { t } = useTranslation()

  return (
    <Modal
      isOpened={isShowCreateIPAddressRestrictionModal}
      title={t('SAVE_IP_ADDRESS_RESTRICTIONS')}
      onClickCancel={() => setIsShowCreateIPAddressRestrictionModal(false)}
      onClickOutside={() => setIsShowCreateIPAddressRestrictionModal(false)}
      onClickExecute={() => {
        onClickSave(data.map(({ value }) => value))
        setIsShowCreateIPAddressRestrictionModal(false)
      }}
      executeText={t('SAVE')}
    >
      <StyledText lineHeight="22px" color="text-bk-100" css={{ whiteSpace: 'pre-wrap' }}>
        {t('SAVE_IP_ADDRESS_RESTRICTIONS_DESCRIPION')}
      </StyledText>
    </Modal>
  )
}

IpAddressRestrictionsModal.displayName = 'IpAddressRestrictionsModal'
