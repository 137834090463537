import { FC } from 'react'

import { useTranslation, Trans } from '../../../i18n'
import { color } from '../../../styles/newColors'
import { Avatar } from '../../ui/Avatar'
import { Icon } from '../../ui/Icon'
import { Link } from '../../ui/Link'
import { StyledText } from '../../ui/StyledText'

import { CheckinUserFragment } from './graphql'

export type Props = {
  link?: string
  isCheckined: boolean
  checkinUsers: Array<CheckinUserFragment>
  drawer?: boolean
}

export const Header: FC<Props> = ({ link, isCheckined, checkinUsers, drawer = false }) => {
  const { t } = useTranslation()
  return (
    <div>
      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <div>
            <StyledText size="xxlarge" css={{ lineHeight: '32px' }}>
              {t('CHECKIN_MEETING')}
            </StyledText>
          </div>
          <div css={{ marginTop: '8px' }}>
            <StyledText color="text-bk-80" lineHeight="24px">
              {t('CHECKIN_MEETING_DESCRIPTION')}
            </StyledText>
          </div>
          <div>
            {checkinUsers.length >= 1 && (
              <div
                css={{
                  display: 'flex',
                  marginBottom: '8px',
                  marginTop: '24px',
                }}
              >
                {checkinUsers
                  .filter((_, i) => i <= 19)
                  .map((u) => (
                    <Avatar
                      key={u.id}
                      tooltipDisabled
                      firstName={u.firstName}
                      lastName={u.lastName}
                      avatarUrl={u.avatar?.url}
                      size="small"
                      linkage={
                        u.userId
                          ? {
                              userId: u.userId,
                            }
                          : undefined
                      }
                      css={{ marginRight: '4px' }}
                      // TODO: https://github.com/Resily/resily-new/issues/7372 で検討
                      // 現在は暫定的に無効化ユーザー表示をさせないため明示的にfalseを渡す
                      isUserDisabled={false}
                    />
                  ))}
                {checkinUsers.length - 20 > 0 && (
                  <StyledText color="text-bk-50" weight="bold">
                    +{checkinUsers.length - 20}
                  </StyledText>
                )}
              </div>
            )}
          </div>
        </div>
        <div css={{ alignSelf: 'flex-start', marginRight: drawer ? '0' : '27px' }}>
          {isCheckined ? (
            <Icon
              type="checkinMeeting"
              color={color('resily-orange-100')}
              width={257}
              height={120}
            />
          ) : (
            <Icon
              type="preCheckinMeeting"
              color={color('resily-orange-100')}
              width={272}
              height={149}
            />
          )}
        </div>
      </div>
      <StyledText color="text-bk-80" css={{ paddingLeft: '4px', lineHeight: '28px' }}>
        {checkinUsers.length >= 1 ? (
          <div>
            {t('X_PEOPLE_HAVE_COMPLETED_THE_BATCH_UPDATE', { x: checkinUsers.length })}
            {link && (
              <Trans i18nKey="CLICK_HERE_IF_YOU_HAVE_NOT_UPDATED_YOUR_PROGRESS_YET">
                Click
                <Link
                  href={link}
                  method={drawer ? 'newTab' : undefined}
                  css={{
                    color: color('resily-orange-100'),
                    textDecoration: 'underline',
                  }}
                >
                  here
                </Link>
                if you haven.
              </Trans>
            )}
          </div>
        ) : (
          <div>
            {t('NO_ONE_IS_CHECKING_IN')}
            {link && (
              <Trans
                i18nKey="CLICK_HERE_IF_YOU_HAVE_NOT_UPDATED_YOUR_PROGRESS_YET"
                components={[
                  <Link
                    href={link}
                    method={drawer ? 'newTab' : undefined}
                    css={{
                      color: color('resily-orange-100'),
                      textDecoration: 'underline',
                    }}
                  >
                    here
                  </Link>,
                ]}
              />
            )}
          </div>
        )}
      </StyledText>
    </div>
  )
}

Header.displayName = 'Header'
