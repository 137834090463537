import { useContext } from 'react'

import { OkrTermIdContext } from '../../../../../contexts/OkrTermIdContext'
import { useOkrModalQueryParams } from '../../hooks/useOkrModalQueryParams'
import { LoadableProps } from '../../types'

import { ObjectiveArea, Props as ObjectiveAreaProps } from './ObjectiveArea'
import { ObjectiveAreaSkeleton } from './ObjectiveAreaSkeleton'

export type DataProps = Pick<ObjectiveAreaProps, 'objective'>

export type Props = LoadableProps<DataProps> & Pick<ObjectiveAreaProps, 'onClickObjective'>

export const ObjectiveAreaContainer: React.VFC<Props> = ({
  loading,
  objective,
  onClickObjective,
}) => {
  const { okrTermId } = useContext(OkrTermIdContext)
  const { keyResultId } = useOkrModalQueryParams()

  const isSelected = keyResultId == null

  if (loading || !okrTermId || !objective) return <ObjectiveAreaSkeleton />

  return (
    <ObjectiveArea
      isSelected={isSelected}
      termId={okrTermId}
      objective={objective}
      onClickObjective={onClickObjective}
    />
  )
}
