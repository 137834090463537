import { ActivityCard as GeishaActivityCard, ConfidenceTag } from '@resily/geisha'
import { FC, useRef, useEffect } from 'react'

import { EditorRef, RichTextEditor } from '../../../../../components/ui/RichTextEditor'
import { useTranslation } from '../../../../../i18n'
import { useActivityUtil } from '../../../../../lib/domain/activity/hooks/useActivityUtil'
import * as urls from '../../../../../urls'

import { ActivityEdgeFragment, ActivityType, Document } from './graphql'
import { styles } from './styles/activityCard.styles'

export type Props = {
  termId: string
  activityEdge: ActivityEdgeFragment
  onClickKeyResultName: (objectiveId: string, keyResultId: string) => void
}

export const ActivityCard: FC<Props> = ({ termId, activityEdge, onClickKeyResultName }) => {
  const { t } = useTranslation()
  const { node: activity } = activityEdge

  const priorityEditorRef = useRef<EditorRef>(null)
  const winSessionEditorRef = useRef<EditorRef>(null)
  const problemEditorRef = useRef<EditorRef>(null)
  const otherEditorRef = useRef<EditorRef>(null)
  useEffect(() => {
    if (!priorityEditorRef.current) return
    priorityEditorRef.current?.resetInitialValue()
  }, [activity.keyResultComment?.priority])
  useEffect(() => {
    if (!winSessionEditorRef.current) return
    winSessionEditorRef.current?.resetInitialValue()
  }, [activity.keyResultComment?.winSession])
  useEffect(() => {
    if (!problemEditorRef.current) return
    problemEditorRef.current?.resetInitialValue()
  }, [activity.keyResultComment?.problem])
  useEffect(() => {
    if (!otherEditorRef.current) return
    otherEditorRef.current?.resetInitialValue()
  }, [activity.keyResultComment?.other])

  const Notifications = {
    [ActivityType.KrAutoAggregation]: t('UPDATED_THIS_KEY_RESULT'),
    [ActivityType.KrBulkUpdate]: t('MADE_CHECKIN_ON_THIS_KEY_RESULT'),
    [ActivityType.KrDrawerUpdate]: t('UPDATED_THIS_KEY_RESULT'),
    [ActivityType.KrQuickUpdate]: t('UPDATED_THIS_KEY_RESULT'),
    [ActivityType.KrModalUpdate]: t('UPDATED_THIS_KEY_RESULT'),
  }

  const {
    diffValue,
    confidenceText,
    beforeConfidenceLevel,
    afterConfidenceLevel,
    getDiffValueProps,
    getDiffValueByUpdateUnitProps,
    getDiffUnitProps,
    getDiffConfidenceLevelProps,
  } = useActivityUtil(activity)

  const editorValue = (v: Document) => {
    if (v.treeJson != null && v.treeJson !== '') return v.treeJson
    if (v.plainText != null && v.plainText !== '') return v.plainText
    return ''
  }

  return (
    <GeishaActivityCard size="large">
      <GeishaActivityCard.Header
        avatarProps={{
          url: activity.user.avatar?.url,
          linkTo: urls.generateHome(termId)(activity.user.id),
        }}
        lastName={activity.user.lastName}
        firstName={activity.user.firstName}
        notification={Notifications[activity.activityType]}
        title={activity.keyResult.name}
        onTitleClick={() => {
          onClickKeyResultName(activity.keyResult.node.objective.id, activity.keyResult.id)
        }}
        timestamp={activity.updatedAt}
      />
      <GeishaActivityCard.Body>
        {activity.keyResultEvent && (
          <GeishaActivityCard.DataGroup>
            <GeishaActivityCard.DataItemGroup>
              {activity.keyResultEvent.afterProgressRate != null && (
                <GeishaActivityCard.DataItem
                  label={t('PROGRESS_RATE')}
                  value={activity.keyResultEvent.afterProgressRate}
                  valueStyles={{ weight: 'bold' }}
                  unit="%"
                  {...getDiffValueProps(diffValue.progressRate)}
                />
              )}
              {activity.keyResultEvent.afterActualValue != null && (
                <GeishaActivityCard.DataItem
                  label={t('ACTUAL_VALUE')}
                  value={activity.keyResultEvent.afterActualValue}
                  valueStyles={{ weight: 'bold' }}
                  unit={activity.keyResultEvent.updatedUnit ?? undefined}
                  {...getDiffValueProps(diffValue.actualValue)}
                />
              )}
              {activity.keyResultEvent.afterTargetValue != null && (
                <GeishaActivityCard.DataItem
                  label={t('TARGET_VALUE')}
                  value={activity.keyResultEvent.afterTargetValue}
                  valueStyles={{ weight: 'bold' }}
                  unit={activity.keyResultEvent.updatedUnit ?? undefined}
                  {...getDiffValueByUpdateUnitProps(diffValue.targetValue)}
                />
              )}
              {activity.keyResultEvent.afterInitialValue != null && (
                <GeishaActivityCard.DataItem
                  label={t('INITIAL_VALUE')}
                  value={activity.keyResultEvent.afterInitialValue}
                  valueStyles={{ weight: 'bold' }}
                  unit={activity.keyResultEvent.updatedUnit ?? undefined}
                  {...getDiffValueByUpdateUnitProps(diffValue.initialValue)}
                />
              )}
              {activity.keyResultEvent.afterUnit != null && (
                <GeishaActivityCard.DataItem
                  label={t('TARGET_VALUE_UNIT')}
                  value={activity.keyResultEvent.afterUnit || t('UNIT_NOT_SET')}
                  valueStyles={{ weight: 'bold' }}
                  {...getDiffUnitProps(diffValue.unit)}
                />
              )}
              {afterConfidenceLevel != null && (
                <GeishaActivityCard.DataItem
                  label={t('CONFIDENCE')}
                  value={
                    <ConfidenceTag level={afterConfidenceLevel}>
                      {confidenceText[afterConfidenceLevel]}
                    </ConfidenceTag>
                  }
                  {...getDiffConfidenceLevelProps(diffValue.confidenceLevel, beforeConfidenceLevel)}
                />
              )}
            </GeishaActivityCard.DataItemGroup>
            {(activity.keyResultEvent.updateReason ||
              activity.activityType === ActivityType.KrAutoAggregation) && (
              <GeishaActivityCard.DataItem
                label={t('UPDATE_MEMO')}
                value={
                  activity.activityType === ActivityType.KrAutoAggregation
                    ? t('PROGRESS_RATE_AUTO_AGGREGATED')
                    : activity.keyResultEvent.updateReason
                }
                valueStyles={{
                  color:
                    activity.activityType === ActivityType.KrAutoAggregation
                      ? 'foreground-black-bk50'
                      : 'foreground-black-bk100',
                }}
              />
            )}
          </GeishaActivityCard.DataGroup>
        )}
        {activity.keyResultComment && (
          <>
            <GeishaActivityCard.Grid>
              {activity.keyResultComment.priority?.body != null && (
                <GeishaActivityCard.Col label={t('PRIORITY')}>
                  <RichTextEditor
                    id={`activity-priority-${activity.id}`}
                    ref={priorityEditorRef}
                    initialValueJSON={editorValue(activity.keyResultComment.priority.body)}
                    editorProps={{ readOnly: true }}
                    css={styles.editor}
                  />
                </GeishaActivityCard.Col>
              )}
              {activity.keyResultComment.winSession?.body != null && (
                <GeishaActivityCard.Col label={t('WIN_SESSION')}>
                  <RichTextEditor
                    id={`activity-winSession-${activity.id}`}
                    ref={winSessionEditorRef}
                    initialValueJSON={editorValue(activity.keyResultComment.winSession.body)}
                    editorProps={{ readOnly: true }}
                    css={styles.editor}
                  />
                </GeishaActivityCard.Col>
              )}
              {activity.keyResultComment.problem?.body != null && (
                <GeishaActivityCard.Col label={t('PROBLEM')}>
                  <RichTextEditor
                    id={`activity-problem-${activity.id}`}
                    ref={problemEditorRef}
                    initialValueJSON={editorValue(activity.keyResultComment.problem.body)}
                    editorProps={{ readOnly: true }}
                    css={styles.editor}
                  />
                </GeishaActivityCard.Col>
              )}
              {activity.keyResultComment.other?.body != null && (
                <GeishaActivityCard.Col label={t('OTHER')}>
                  <RichTextEditor
                    id={`activity-other-${activity.id}`}
                    ref={otherEditorRef}
                    initialValueJSON={editorValue(activity.keyResultComment.other.body)}
                    editorProps={{ readOnly: true }}
                    css={styles.editor}
                  />
                </GeishaActivityCard.Col>
              )}
            </GeishaActivityCard.Grid>
          </>
        )}
        {activity.createdAt !== activity.updatedAt && (
          <GeishaActivityCard.EditedLabel value={t('EDITED')} />
        )}
      </GeishaActivityCard.Body>
    </GeishaActivityCard>
  )
}
