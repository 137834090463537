import { useProfiler } from '@sentry/react'
import { VFC, useCallback, useState } from 'react'

import { OkrLoadingState } from '../../../components/ui/OkrLoadingState'

import { ListView, Props as ListViewProps } from './ListView'

export type Props = Omit<ListViewProps, 'onRendered'>

export const ListContainer: VFC<Props> = ({
  termId,
  okrNodes,
  onOkrFloatDrawerOpen,
  openOkrModal,
  openOkrModalWithKeyResultDrawer,
}) => {
  useProfiler('ListContainer')
  // ローディング表示用
  const [isRendered, setIsRendered] = useState<boolean>(false)
  const onRendered = useCallback(() => setIsRendered(true), [setIsRendered])

  return (
    <div style={{ overflow: 'auto' }}>
      {okrNodes.length > 0 ? (
        <ListView
          termId={termId}
          okrNodes={okrNodes}
          onRendered={onRendered}
          onOkrFloatDrawerOpen={onOkrFloatDrawerOpen}
          openOkrModal={openOkrModal}
          openOkrModalWithKeyResultDrawer={openOkrModalWithKeyResultDrawer}
        />
      ) : (
        // (isLoading || !isRendered) && <OkrLoadingState />
        !isRendered && <OkrLoadingState />
      )}
    </div>
  )
}
ListContainer.displayName = 'ListContainer'
