import { OkrTitle } from '@resily/geisha'
import { t } from 'i18next'
import { useState } from 'react'
import { useFieldArray } from 'react-hook-form'

import { castTargetSetting } from '../../../../../../lib/geisha/OkrTitle'
import { componentNames, featureNames, generateTestId } from '../../../../../../lib/testId'
import { StyledText } from '../../../../../ui/StyledText'
import { CommentTabsController } from '../../../form/components/CommentTabsController'
import { ConfidenceSelectController } from '../../../form/components/ConfidenceSelectController'
import { ReasonInputController } from '../../../form/components/ReasonInputController'
import { useGetFormMethods } from '../../../form/contexts/FormProvider'
import { keyResultsKeyName } from '../../../form/formSchema'
import { generateKeyResultLinkId } from '../../../utils'
import { CommentTabs } from '../../CommentTabs'
import { KeyResultSelectionHandler } from '../../KeyResultSelectionHandler'

import { ConfidenceSelectFormField } from './components/ConfidenceSelectFormField'
import { ProgressControllerAndFormFieldWrapper } from './components/ProgressControllerAndFormFieldWrapper'
import {
  SetTargetValuePrompt,
  QualitativeGoalPrompt,
  NoMeasurePrompt,
} from './components/PromptMessage'
import { ReasonInputFormField } from './components/ReasonInputFormField'
import {
  confidenceSelectWrapperCss,
  krWrapperCss,
  okrTitleWrapperCss,
  progressRateAndConfidenceLabelCss,
  progressControllerAndFormFieldWrapperCss,
  rootCss,
  progressFieldWrapperCss,
} from './styles'

type Props = {
  onSelectKeyResult: (id: string) => void
}

export const InputsArea: React.VFC<Props> = ({ onSelectKeyResult }) => {
  const { control } = useGetFormMethods()
  const { fields: keyResults } = useFieldArray({ name: keyResultsKeyName, control })

  // NOTE: refに値が入っても、再レンダリングされないので、KeyResultSelectionHandlerのrootElementにはuseStateを使う
  const [rootElement, setRootElement] = useState<HTMLElement | null>(null)

  return (
    <div
      ref={setRootElement}
      css={rootCss}
      data-testid={generateTestId(featureNames.checkinModal, componentNames.form)}
    >
      {keyResults.map((kr, index) => (
        <KeyResultSelectionHandler
          key={kr.id}
          id={generateKeyResultLinkId(kr.keyResultId)}
          data-testid={generateTestId(featureNames.checkinModal, componentNames.listItem, {
            suffix: kr.keyResultId,
          })}
          css={krWrapperCss}
          rootElement={rootElement}
          keyResultId={kr.keyResultId}
          onSelectKeyResult={onSelectKeyResult}
        >
          <div css={okrTitleWrapperCss}>
            <OkrTitle type="objective" size="xsmall" title={kr.objectiveName} withBorder />
            <OkrTitle
              type="keyResult"
              size="large"
              leftIconProps={castTargetSetting(kr.targetSetting)}
              title={kr.keyResultName}
              withBorder
            />
          </div>

          {/* 進捗・自信度 */}
          <div css={progressFieldWrapperCss}>
            <div css={progressControllerAndFormFieldWrapperCss}>
              <StyledText size="small" css={progressRateAndConfidenceLabelCss}>
                {t('PROGRESS_RATE_AND_CONFIDENCE')}
              </StyledText>
              <ProgressControllerAndFormFieldWrapper index={index} keyResult={kr} />

              {/* 自信度のセレクト */}
              <div css={confidenceSelectWrapperCss}>
                <ConfidenceSelectController name={`${keyResultsKeyName}.${index}.level`}>
                  {(confidenceSelectFormFieldProps) => (
                    <ConfidenceSelectFormField {...confidenceSelectFormFieldProps} />
                  )}
                </ConfidenceSelectController>
              </div>
            </div>

            {/* 目標値入力を催促するメッセージ */}
            <SetTargetValuePrompt index={index} keyResult={kr} />

            {/* 定性的な目標から変更することを催促するメッセージ */}
            <QualitativeGoalPrompt index={index} keyResult={kr} />

            {/* 進捗率を測定可能な状態に変更することを催促するメッセージ */}
            <NoMeasurePrompt index={index} keyResult={kr} />
          </div>

          {/* 更新理由 */}
          <ReasonInputController name={`${keyResultsKeyName}.${index}.updateReason`}>
            {(reasonInputFormFieldProps) => <ReasonInputFormField {...reasonInputFormFieldProps} />}
          </ReasonInputController>

          {/* コメント */}
          <CommentTabsController name={`${keyResultsKeyName}.${index}`}>
            {(commentTabsProps) => <CommentTabs {...commentTabsProps} />}
          </CommentTabsController>
        </KeyResultSelectionHandler>
      ))}
    </div>
  )
}
