import { useStyles } from './GeneralTitle.styles'

export type GeneralTitleProps = {
  title: string
}

export const GeneralTitle: React.FC<GeneralTitleProps> = ({ title }) => {
  const styles = useStyles()

  return <div css={styles.root}>{title}</div>
}
