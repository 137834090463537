import { Matrix } from './matrix.d'
import { sub, min, max, affine as affinePoint } from './point'
import { Point } from './point.d'
import { Rect as RectOrig } from './rect.d'

export type Rect = RectOrig

export const isRect = (r: Record<string, unknown>): r is Rect =>
  r.x !== undefined && r.y !== undefined && r.width !== undefined && r.height !== undefined

export const zero = (): Rect => ({ x: 0, y: 0, width: 0, height: 0 })

export const clone = ({ x, y, width, height }: Rect): Rect => ({ x, y, width, height })

export const isZero = ({ x, y, width, height }: Rect): boolean =>
  x === 0 && y === 0 && width === 0 && height === 0

export const isZeroSize = ({ width, height }: Rect): boolean => width === 0 && height === 0

export const isEqual = (r1: Rect, r2: Rect): boolean =>
  r1.x === r2.x && r1.y === r2.y && r1.width === r2.width && r1.height === r2.height

export const left = ({ x }: Rect): number => x
export const centerX = ({ x, width }: Rect): number => x + width / 2
export const right = ({ x, width }: Rect): number => x + width

export const top = ({ y }: Rect): number => y
export const centerY = ({ y, height }: Rect): number => y + height / 2
export const bottom = ({ y, height }: Rect): number => y + height

export const sides = ({
  x,
  y,
  width,
  height,
}: Rect): { left: number; right: number; top: number; bottom: number } => ({
  left: x,
  right: x + width,
  top: y,
  bottom: y + height,
})

export const leftTop = ({ x, y }: Rect): Point => ({ x, y })
export const leftCenter = ({ x, y, height }: Rect): Point => ({ x, y: y + height / 2 })
export const leftBottom = ({ x, y, height }: Rect): Point => ({ x, y: y + height })

export const rightTop = ({ x, y, width }: Rect): Point => ({ x: x + width, y })
export const rightCenter = ({ x, y, width, height }: Rect): Point => ({
  x: x + width,
  y: y + height / 2,
})
export const rightBottom = ({ x, y, width, height }: Rect): Point => ({
  x: x + width,
  y: y + height,
})

export const centerTop = ({ x, y, width }: Rect): Point => ({ x: x + width / 2, y })
export const center = ({ x, y, width, height }: Rect): Point => ({
  x: x + width / 2,
  y: y + height / 2,
})
export const centerBottom = ({ x, y, width, height }: Rect): Point => ({
  x: x + width / 2,
  y: y + height,
})

export const translate = ({ x, y, width, height }: Rect, p: Point): Rect => ({
  x: x + p.x,
  y: y + p.y,
  width,
  height,
})

export const position = ({ x, y }: Rect): Point => ({ x, y })

export const setPosition = ({ width, height }: Rect, { x, y }: Point): Rect => ({
  x,
  y,
  width,
  height,
})

export const size = ({ width, height }: Rect): Point => ({ x: width, y: height })

export const enlarge = ({ x, y, width, height }: Rect, p: Point): Rect => ({
  x,
  y,
  width: width + p.x,
  height: height + p.y,
})

export const ensmall = ({ x, y, width, height }: Rect, p: Point): Rect => ({
  x,
  y,
  width: width - p.x,
  height: height - p.y,
})

export const inflate = ({ x, y, width, height }: Rect, p: Point): Rect => ({
  x: x - p.x / 2,
  y: y - p.y / 2,
  width: width + p.x,
  height: height + p.y,
})

export const deflate = ({ x, y, width, height }: Rect, p: Point): Rect => ({
  x: x + p.x / 2,
  y: y + p.y / 2,
  width: width - p.x,
  height: height - p.y,
})

export const fromPositionAndSize = (pos: Point, s: Point): Rect => ({
  ...pos,
  width: s.x,
  height: s.y,
})

export const fromPoints = (...pts: ReadonlyArray<Point>): Rect => {
  const i = min(...pts)
  const a = max(...pts)
  const s = sub(a, i)
  return {
    x: i.x,
    y: i.y,
    width: s.x,
    height: s.y,
  }
}

export const union = (...rects: ReadonlyArray<Rect>): Rect =>
  fromPoints(min(...rects.map(leftTop)), max(...rects.map(rightBottom)))

export const isIncluded = (r: Rect, target: Rect | Point): boolean => {
  if (isRect(target)) {
    const lt = leftTop(target)
    const rb = rightBottom(target)
    return isIncluded(r, lt) && isIncluded(r, rb)
  }
  const lt = leftTop(r)
  const rb = rightBottom(r)
  return lt.x <= target.x && target.x <= rb.x && lt.y <= target.y && target.y <= rb.y
}

export const isIntersected = (viewport: Rect, target: Rect): boolean => {
  const { x: vl, y: vt } = leftTop(viewport)
  const { x: vr, y: vb } = rightBottom(viewport)
  const { x: tl, y: tt } = leftTop(target)
  const { x: tr, y: tb } = rightBottom(target)
  return vl <= tr && tl <= vr && vt <= tb && tt <= vb
}

export const include = (r: Rect, target: Rect): Rect => {
  const t = clone(target)
  if (r.width > t.width) {
    if (r.x > t.x) {
      t.x = r.x
    } else if (right(r) < right(t)) {
      t.x = right(r) - t.width
    }
  }
  if (r.height > t.height) {
    if (r.y > t.y) {
      t.y = r.y
    } else if (bottom(r) < bottom(t)) {
      t.y = bottom(r) - t.height
    }
  }
  return t
}

export const toPoints = (r: Rect): Array<Point> => [
  leftTop(r),
  rightTop(r),
  rightBottom(r),
  leftBottom(r),
]

export const affine = (r: Rect, m: Matrix): Array<Point> =>
  toPoints(r).map((p) => affinePoint(p, m))

// アスペクト比を変えずに片方のRectをもう片方のRectに収められるような倍率を計算する
type WH = Pick<Rect, 'width' | 'height'>
export const getRectInRectScaleKeepAspect = (innerRect: WH, outerRect: WH): number => {
  if (
    (innerRect.width < outerRect.width && innerRect.height < outerRect.height) ||
    (innerRect.width > outerRect.width && innerRect.height > outerRect.height)
  ) {
    // 一方がもう一方を内包している場合は、外側に合わせて縮小/拡大する
    return Math.min(outerRect.width / innerRect.width, outerRect.height / innerRect.height)
  }

  if (innerRect.width > outerRect.width) {
    // 横長
    return outerRect.width / innerRect.width
  }

  // 縦長
  return outerRect.height / innerRect.height
}
