import { css } from '@emotion/react'
import { useMemo } from 'react'

import { border } from '../../../../styles/border'
import { color } from '../../../../styles/newColors'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useStyles = () =>
  useMemo(
    () =>
      ({
        root: css({
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          overflowY: 'auto',
          gap: 16,
          flexGrow: 1,
          padding: '24px',
          borderRight: `1px solid ${color('border-bk-10')}`,
        }),
        areaRoot: css({
          display: 'flex',
          flexDirection: 'column',
          gap: 12,
          padding: '16px 20px',
          backgroundColor: color('white-100'),
          border: border('simple-30'),
          borderRadius: 8,
        }),
      } as const),
    [],
  )
