import { Button } from '@resily/geisha'

import { useTranslation } from '../../../../i18n'

import { useStyles } from './index.styles'

export type Props = {
  isDisabledKr: boolean
  disabledButton: boolean
  onClickToggleStatusButton: () => void
  onClickDeleteButton: () => void
}

export const Footer: React.VFC<Props> = ({
  isDisabledKr,
  disabledButton,
  onClickToggleStatusButton,
  onClickDeleteButton,
}) => {
  const { t } = useTranslation()
  const styles = useStyles()

  return (
    <div css={styles.root}>
      <Button
        size="large"
        type="secondary"
        disabled={disabledButton}
        onClick={onClickToggleStatusButton}
      >
        {t(isDisabledKr ? 'ENABLE_X' : 'DISABLE_X', { x: t('KEY_RESULT') })}
      </Button>
      {isDisabledKr && (
        <div css={styles.deleteButton}>
          <Button
            size="large"
            type="tertiary"
            disabled={disabledButton}
            onClick={onClickDeleteButton}
          >
            {t('DELETE_X', { x: t('KEY_RESULT') })}
          </Button>
        </div>
      )}
    </div>
  )
}
