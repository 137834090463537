import { StyledText } from '../../../../../components/ui/StyledText'
import { useTranslation } from '../../../../../i18n'

import { styles } from './styles/activityTitleSection.styles'

type Props = {
  isOwnPage: boolean
}

export const ActivityTitleSection: React.FC<Props> = ({ isOwnPage }) => {
  const { t } = useTranslation()

  return (
    <div css={styles.root}>
      <div css={styles.title}>{t('ACTIVITY')}</div>
      <StyledText size="small" fontStyle="regular">
        {t(isOwnPage ? 'HOME_MY_ACTIVITY_DESCRIPTION' : 'HOME_USER_ACTIVITY_DESCRIPTION')}
      </StyledText>
    </div>
  )
}
