import { useCallback, useEffect, RefObject } from 'react'

type Args<T> = {
  callback: () => void
  ref: RefObject<T> extends { current: { contains: (other: Node | null) => boolean } | null }
    ? RefObject<T>
    : never
}

export const useClickOutside = <T>({ callback, ref }: Args<T>): void => {
  const onClickDocument = useCallback(
    (e) => {
      if (ref?.current?.contains(e.target)) return
      callback()
    },
    [callback, ref],
  )

  useEffect(() => {
    document.addEventListener('click', onClickDocument, true)
    return (): void => document.removeEventListener('click', onClickDocument, true)
  })
}
