import { useCallback, VFC } from 'react'

import { Icon } from '../../../components/ui/Icon'
import { useTranslation } from '../../../i18n'
import { color } from '../../../styles/newColors'
import { useIsFoldOkrCardGlobal, useBulkUpdateIsFoldOkrCard } from '../OkrCardIsFoldGlobalContext'

import { ControllerBase, Props as ControllerBaseProps } from './ControllerBase'

export type ContainerProps = Omit<ControllerBaseProps, 'onClick'>

export const OkrCardKrFoldControllerContainer: VFC<ContainerProps> = ({ ...props }) => {
  const isFold = useIsFoldOkrCardGlobal()
  const setIsFold = useBulkUpdateIsFoldOkrCard()
  const toggle = useCallback(() => setIsFold((prev) => !prev), [setIsFold])

  return <OkrCardKrFoldController isFold={isFold} onClick={toggle} {...props} />
}

OkrCardKrFoldControllerContainer.displayName = 'OkrCardKrFoldControllerContainer'

export type Props = ControllerBaseProps & {
  isFold: boolean
}

export const OkrCardKrFoldController: VFC<Props> = ({ isFold, onClick, ...props }) => {
  const { t } = useTranslation()

  return (
    <ControllerBase {...props} onClick={onClick}>
      <button
        type="button"
        css={{
          display: 'flex',
          alignItems: 'center',
          fontSize: '14px',
          fontWeight: 500,
          padding: '6px 12px',
          '&:hover': {
            color: color('resily-orange-100'),
            'path[stroke]': {
              stroke: color('resily-orange-100'),
            },
            'path[fill]': {
              fill: color('resily-orange-100'),
            },
          },
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginRight: '4px',
          }}
        >
          <Icon
            type="closed"
            style={{
              marginBottom: -4,
              transform: `translateY(${isFold ? '12' : '0'}px)`,
              transition: 'transform 0.2s ease-out',
            }}
          />
          <Icon
            type="opened"
            style={{
              transform: `translateY(${isFold ? '-12' : '0'}px)`,
              transition: 'transform 0.2s ease-out',
            }}
          />
        </div>
        <span>
          {t('DO_X', {
            x: isFold
              ? t('DISPLAY_X', { x: t('KEY_RESULT') })
              : t('X_VIEW', { x: t('X_ONLY', { x: t('OBJECTIVE') }) }),
          })}
        </span>
      </button>
    </ControllerBase>
  )
}

OkrCardKrFoldController.displayName = 'OkrCardKrFoldController'
