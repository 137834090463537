import { Text, TextProps } from 'grommet'

import { fontSize, FontSizeAlias, FontStyleAlias } from '../../../styles/font'
import { color as colorStyle, ColorAlias } from '../../../styles/newColors'

export type Props = TextProps & {
  className?: string
  /**
   * @default "medium"
   */
  size?: FontSizeAlias
  /**
   * @default "narrow"
   */
  fontStyle?: FontStyleAlias
  lineHeight?: string
  color?: ColorAlias
  oneline?: boolean
  isWrap?: boolean
  numberOfOmittedLines?: number | 'unset'
}

export const StyledText: React.FC<Props> = ({
  className,
  size = 'medium',
  fontStyle = 'narrow',
  lineHeight,
  color,
  oneline = false,
  isWrap = false,
  numberOfOmittedLines = 2,
  children,
  ...props
}) => {
  const fontSizeCss = fontSize(size, fontStyle)
  const fs = oneline
    ? {
        fontSize: fontSizeCss.fontSize,
        lineHeight: lineHeight || fontSizeCss.lineHeight || 1,
        fontWeight: fontSizeCss.fontWeight,
      }
    : {
        ...fontSizeCss,
        lineHeight,
      }

  return (
    <Text
      className={className}
      css={[
        { ...fs, color: typeof color !== 'undefined' ? colorStyle(color) : undefined },
        isWrap && {
          wordBreak: 'break-all',
          display: '-webkit-box',
          overflow: 'hidden',
          // stylelint-disable-next-line
          WebkitLineClamp: numberOfOmittedLines,
          // stylelint-disable-next-line
          WebkitBoxOrient: 'vertical',
        },
      ]}
      {...props}
    >
      {children}
    </Text>
  )
}

StyledText.displayName = 'StyledText'
