import { yupResolver } from '@hookform/resolvers/yup'
import dayjs from 'dayjs'
import { Box } from 'grommet'
import * as React from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'

import { Button } from '../../../../components/ui/Button'
import { ErrorMessage } from '../../../../components/ui/ErrorMessage'
import { Input } from '../../../../components/ui/Input'
import { TextButton } from '../../../../components/ui/TextButton'
import { useTranslation } from '../../../../i18n'
import { FormValue } from '../../types'
import { isConveredOtherTerms, isInOtherTerms } from '../AdminOkrTermsModal/functions'
import { DateInput } from '../DateInput'
import { SectionTitle } from '../SectionTitle'

import { cancelTextButtonCss, inputCss, saveButtonCss } from './styles'
import { OkrTermFormProps } from './types'

export const OkrTermForm: React.FC<OkrTermFormProps> = ({
  term,
  onChange,
  mode,
  onCancel,
  terms,
}) => {
  const { t } = useTranslation()

  const {
    handleSubmit,
    register,
    control,
    formState: { errors, dirtyFields },
    trigger,
    watch,
    setValue,
    getValues,
  } = useForm<FormValue>({
    mode: 'onChange',
    resolver: yupResolver(
      yup.object().shape({
        name: yup.string().required(t('FIELD_EMPTY_ERROR', { field: t('NAME') })),
        startDate: yup
          .date()
          .typeError(t('FIELD_SET_ERROR', { field: t('TERM_START_DATE') }))
          .required()
          .test('overlap okr term startDate', (value, testContext) => {
            if (!value) return false

            const otherTerms =
              mode === 'edit' ? terms.filter((otherTerm) => otherTerm.id !== term?.id) : terms

            const overlapTerms = isInOtherTerms(value, otherTerms)
            const { endDate } = testContext.parent

            const coveredTerms =
              Number.isNaN(endDate.getTime()) || dayjs(new Date(endDate)).isBefore(new Date(value))
                ? []
                : isConveredOtherTerms(value, endDate, otherTerms)

            if (coveredTerms?.length) {
              return testContext.createError({
                path: 'startDate',
                message: t('OVERLAP_WITH_OTHER_OKR_TERM', {
                  termName: coveredTerms.map((v) => v.name).join(','),
                }),
              })
            }
            if (overlapTerms?.length) {
              return testContext.createError({
                path: 'startDate',
                message: t('OVERLAP_WITH_OTHER_OKR_TERM', {
                  termName: overlapTerms.map((v) => v.name).join(','),
                }),
              })
            }
            return true
          }),
        endDate: yup
          .date()
          .typeError(t('FIELD_SET_ERROR', { field: t('TERM_END_DATE') }))
          .required()
          .test('overlap okr term endDate', (value, testContext) => {
            if (!value) return false
            const otherTerms =
              mode === 'edit' ? terms.filter((otherTerm) => otherTerm.id !== term?.id) : terms

            const overlapTerms = isInOtherTerms(value, otherTerms)
            const { startDate } = testContext.parent

            const coveredTerms =
              Number.isNaN(startDate.getTime()) ||
              dayjs(new Date(value)).isBefore(new Date(startDate))
                ? []
                : isConveredOtherTerms(startDate, value, otherTerms)

            if (overlapTerms?.length) {
              return testContext.createError({
                path: 'endDate',
                message: t('OVERLAP_WITH_OTHER_OKR_TERM', {
                  termName: overlapTerms.map((v) => v.name).join('・'),
                }),
              })
            }
            if (coveredTerms?.length) {
              return testContext.createError({
                path: 'endDate',
                message: t('OVERLAP_WITH_OTHER_OKR_TERM', {
                  termName: coveredTerms.map((v) => v.name).join('・'),
                }),
              })
            }
            return true
          })
          .test('okr term endDate', t('END_DATE_BEFORE_START_DATE_ERROR'), (value, testContext) => {
            const { startDate } = testContext.parent
            if (!value || Number.isNaN(startDate.getTime())) {
              return true
            }
            return dayjs(new Date(startDate)).isBefore(new Date(value))
          }),
      }),
    ),
    defaultValues: term
      ? {
          id: term.id,
          name: term.name,
          startDate: term.startDate.toString().substring(0, 10),
          endDate: term.endDate.toString().substring(0, 10),
        }
      : {
          id: '',
          name: '',
          startDate: '',
          endDate: '',
        },
  })

  const [watchEndDate] = watch(['endDate'])

  const isRemainDirtyFields =
    !(dirtyFields.name && dirtyFields.startDate && dirtyFields.endDate) &&
    !(getValues('name') && getValues('startDate') && getValues('endDate'))

  return (
    <form
      onSubmit={handleSubmit((values: FormValue) => {
        if (onChange) {
          onChange(values)
        }
      })}
    >
      <Controller control={control} name="id" render={() => <Input type="hidden" />} />
      <Box direction="column" gap="24px">
        <Box direction="row" gap="60px">
          <SectionTitle>{t('NAME')}</SectionTitle>
          <Box>
            <Input
              placeholder={t('INPUT_X', { x: t('TERM_NAME') })}
              fontSize="medium"
              fieldStyle="bottomLine"
              {...register('name')}
              css={inputCss}
            />
            <ErrorMessage>{errors.name?.message}</ErrorMessage>
          </Box>
        </Box>
        <Box direction="row" gap="47px">
          <SectionTitle>{t('TERM_START_DATE')}</SectionTitle>
          <Controller
            control={control}
            name="startDate"
            render={({ field: { ref, value, onChange: controllerOnChange } }) => (
              <Box>
                <DateInput
                  ref={ref}
                  value={value}
                  onChange={async (v) => {
                    const startDate = getValues('startDate')
                    const endDate = getValues('endDate')
                    // 開始日が終了日以降になったら元々の間隔分終了日をずらす
                    if (startDate && endDate && typeof v === 'string') {
                      const updatedStartDateDayjs = dayjs(v)
                      const endDateDayjs = dayjs(endDate)
                      if (!endDateDayjs.isAfter(updatedStartDateDayjs, 'day')) {
                        const diffDays = endDateDayjs.diff(dayjs(startDate), 'day')
                        setValue(
                          'endDate',
                          updatedStartDateDayjs.add(diffDays, 'day').toISOString(),
                        )
                      }
                    }
                    controllerOnChange(v)
                    if (watchEndDate) await trigger('endDate')
                  }}
                  errors={errors.startDate}
                />
                <ErrorMessage>{errors.startDate?.message}</ErrorMessage>
              </Box>
            )}
          />
        </Box>
        <Box direction="row" gap="47px">
          <SectionTitle>{t('TERM_END_DATE')}</SectionTitle>
          <Controller
            control={control}
            name="endDate"
            render={({ field: { ref, value, onChange: controllerOnChange } }) => (
              <Box>
                <DateInput
                  ref={ref}
                  value={value}
                  onChange={async (v) => {
                    const startDate = getValues('startDate')
                    const endDate = getValues('endDate')
                    // 終了日が開始日以前になったら元々の間隔分開始日をずらす
                    if (startDate && endDate && typeof v === 'string') {
                      const startDateDayjs = dayjs(startDate)
                      const updatedEndDateDayjs = dayjs(v)
                      if (!updatedEndDateDayjs.isAfter(startDateDayjs, 'day')) {
                        const diffDays = dayjs(endDate).diff(dayjs(startDate), 'day')
                        setValue(
                          'startDate',
                          updatedEndDateDayjs.subtract(diffDays, 'day').toISOString(),
                        )
                      }
                    }
                    controllerOnChange(v)
                    if (watchEndDate) await trigger('startDate')
                  }}
                  errors={errors.endDate}
                />
                <ErrorMessage>{errors.endDate?.message}</ErrorMessage>
              </Box>
            )}
          />
        </Box>
        <Box direction="row-reverse" gap="9px">
          <Button
            size="s"
            color={mode === 'delete' ? 'warning' : 'main'}
            type="submit"
            disabled={Object.keys(errors).length > 0 || isRemainDirtyFields}
            css={saveButtonCss}
          >
            {t('SAVE')}
          </Button>
          <TextButton onClick={onCancel} color="text-bk-50" css={cancelTextButtonCss}>
            {t('CANCEL')}
          </TextButton>
        </Box>
      </Box>
    </form>
  )
}

OkrTermForm.displayName = 'OkrTermForm'
