import { css } from '@emotion/react'
import React from 'react'

import { Icon } from '../../../components/ui/Icon'
import { StyledText } from '../../../components/ui/StyledText'
import { TooltipNew } from '../../../components/ui/TooltipNew'
import { useTranslation } from '../../../i18n'
import { color } from '../../../styles/newColors'
import { ControllerBase, Props as ControllerBaseProps } from '../ui/ControllerBase'

const hoverCss = css({
  ':hover': {
    rect: {
      fill: color('resily-orange-100'),
    },
  },
})

export type Zoomable = {
  up: boolean
  down: boolean
}

export type Props = ControllerBaseProps & {
  zoomable: Zoomable
  zoomPercentage: number
  onZoomDown: () => void
  onZoomUp: () => void
}

export const ScaleController: React.FC<Props> = ({
  zoomable,
  zoomPercentage,
  onZoomDown,
  onZoomUp,
  ...props
}) => {
  const { t } = useTranslation()

  return (
    <ControllerBase {...props}>
      <ul
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          listStyle: 'none',
          li: { margin: '0px 8px' },
        }}
      >
        <li>
          <TooltipNew title={t('PAN')}>
            <button type="button" disabled={!zoomable.down} onClick={onZoomDown}>
              <Icon type="minus" width={24} height={24} css={hoverCss} />
            </button>
          </TooltipNew>
        </li>
        <li>
          <StyledText>{zoomPercentage}%</StyledText>
        </li>
        <li>
          <TooltipNew title={t('ZOOM')}>
            <button type="button" disabled={!zoomable.up} onClick={onZoomUp}>
              <Icon type="miniPlus" width={24} height={24} css={hoverCss} />
            </button>
          </TooltipNew>
        </li>
      </ul>
    </ControllerBase>
  )
}

ScaleController.displayName = 'ScaleController'
