import React from 'react'

import { Modal } from '../../../../components/ui/Modal'
import { StyledText } from '../../../../components/ui/StyledText'
import { useTranslation } from '../../../../i18n'

import { CancelEditModalProps } from './types'

export const CancelEditModal: React.FC<CancelEditModalProps> = ({
  children,
  isOpened,
  onClickDelete,
  onClickCancel,
  onClickOutside,
}) => {
  const { t } = useTranslation()
  return (
    <Modal
      isOpened={isOpened}
      title={t('CONFIRMATION_OF_CANCEL_INPUT')}
      executeText={t('DISCARD')}
      onClickExecute={onClickDelete}
      onClickCancel={onClickCancel}
      onClickOutside={onClickOutside}
    >
      <StyledText>{t('CONFIRMATION_OF_CANCEL_INPUT_DESCRIPTION_X', { x: t('GROUP') })}</StyledText>
      {children}
    </Modal>
  )
}

CancelEditModal.displayName = 'CancelEditModal'
