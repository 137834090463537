import React, { useEffect, useRef } from 'react'

export type Props<T extends keyof JSX.IntrinsicElements> = JSX.IntrinsicElements[T] & {
  as?: T
  side?: 'top' | 'bottom' | 'left' | 'right'
  children: React.ReactNode
  containerRef: React.RefObject<HTMLElement>
  onChangeOverflow: (isHidden: boolean) => void
}

export const OverflowDetector = <T extends keyof JSX.IntrinsicElements = 'div'>({
  as,
  side = 'bottom',
  children,
  containerRef,
  onChangeOverflow,
  ...props
}: Props<T>): React.ReactElement => {
  const ref = useRef<HTMLElement>(null)

  useEffect(() => {
    if (!ref.current || !containerRef.current) {
      return
    }

    const selfEdge = ref.current.getBoundingClientRect()[side]
    const parentEdge = containerRef.current.getBoundingClientRect()[side]
    const isOverflowEdge = selfEdge - parentEdge > 0

    onChangeOverflow(isOverflowEdge)
  }, [containerRef, onChangeOverflow, side])

  return React.createElement(as || 'div', { ref, ...props }, children)
}
