import {
  getPluginType,
  isMarkActive,
  toggleMark,
  useEventEditorSelectors,
  usePlateEditorState,
} from '@udecode/plate'
import React, { useCallback } from 'react'

import { MarkType } from '../../types'

import { ButtonTooltip } from './ButtonTooltip'
import { ToolbarButton } from './ToolbarButton'

export type Props = {
  type: MarkType
  clear?: string | Array<string>
  tooltipMessage: string
}

export const ToolbarMark: React.FC<Props> = ({ children, type, clear, tooltipMessage }) => {
  const focusId = useEventEditorSelectors.focus()
  const editor = usePlateEditorState(focusId != null ? focusId : undefined)
  const innerMarkType = editor != null && getPluginType(editor, type)
  const isActive =
    !!editor?.selection && innerMarkType !== false && isMarkActive(editor, innerMarkType)

  return (
    <ButtonTooltip message={tooltipMessage}>
      <ToolbarButton
        isActive={isActive}
        toggleNode={useCallback(
          () =>
            editor && innerMarkType !== false && toggleMark(editor, { key: innerMarkType, clear }),
          [clear, editor, innerMarkType],
        )}
      >
        {children}
      </ToolbarButton>
    </ButtonTooltip>
  )
}
