import { useCallback, useState } from 'react'
import { DraggableProvided } from 'react-beautiful-dnd'
import { useNavigate } from 'react-router-dom'

import { Button } from '../../../../../../../../components/ui/Button'
import { DeleteModal } from '../../../../../../../../components/ui/DeleteModal'
import { Icon } from '../../../../../../../../components/ui/Icon'
import { ThreePointsWithMenu } from '../../../../../../../../components/ui/ThreePointsWithMenu'
import { WrapText } from '../../../../../../../../components/ui/WrapText'
import { useTranslation } from '../../../../../../../../i18n'
import { color } from '../../../../../../../../styles/newColors'
import { generateAdminEditFixedAgenda } from '../../../../../../../../urls'
import { FixedAgendaListFragment, FixedAgendaStatus } from '../../../../../../api/graphql'

import {
  rowWrapperCss,
  statusCss,
  titleAndStatusWrapperCss,
  titleCss,
  publicStatusCss,
  privateStatusCss,
  buttonAreaCss,
} from './styles'

export type Props = {
  draggableProvided: DraggableProvided
  fixedAgenda: FixedAgendaListFragment
  onDeleteFixedAgenda: (id: string) => Promise<void>
  onCopyFixedAgenda: (id: string) => Promise<void>
}

export const FixedAgendaListRow: React.VFC<Props> = ({
  draggableProvided,
  fixedAgenda,
  onCopyFixedAgenda,
  onDeleteFixedAgenda,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [isShowDeleteModal, setIsShowDeleteModal] = useState<boolean>(false)
  const [disabledButton, setDisabledButton] = useState<boolean>(false)

  const handleClickEditButton = useCallback(() => {
    navigate(generateAdminEditFixedAgenda(fixedAgenda.id))
  }, [fixedAgenda.id, navigate])

  const handleFixedAgendaDelete = useCallback<() => void>(() => {
    onDeleteFixedAgenda(fixedAgenda.id).finally(() => {
      setIsShowDeleteModal(false)
    })
  }, [fixedAgenda.id, onDeleteFixedAgenda])

  return (
    <>
      <div
        ref={draggableProvided.innerRef}
        {...draggableProvided.draggableProps}
        css={rowWrapperCss}
      >
        <div {...draggableProvided.dragHandleProps}>
          <Icon type="dragIndicator" color={color('text-bk-30')} />
        </div>
        <div css={titleAndStatusWrapperCss}>
          <WrapText maxWidth="90%" text={fixedAgenda.title} css={titleCss} />
          <StatusLabel status={fixedAgenda.status} />
        </div>
        <div css={buttonAreaCss}>
          <Button
            newColor="white-100"
            disabled={disabledButton}
            weight="normal"
            size="s"
            onClick={handleClickEditButton}
          >
            {t('EDITION')}
          </Button>
          <ThreePointsWithMenu
            popoverItems={[
              {
                label: t('DUPLICATE_'),
                disabled: disabledButton,
                icon: <Icon width={12} height={12} type="duplicate" />,
                onItemClicked: () => {
                  setDisabledButton(true)
                  onCopyFixedAgenda(fixedAgenda.id).finally(() => setDisabledButton(false))
                },
              },
              {
                label: t('DELETION'),
                disabled: disabledButton,
                icon: <Icon width={12} height={12} type="garbage" />,
                onItemClicked: () => setIsShowDeleteModal(true),
              },
            ]}
            popoverAlign={{ top: 'bottom', left: 'left' }}
          />
        </div>
      </div>
      {isShowDeleteModal && (
        <DeleteModal
          isOpened
          deleteItemName={t('ONEONONE') + t('ONEONONE_FIXED_AGENDA')}
          deleteItemNameForBody={fixedAgenda.title}
          onClickCancel={() => setIsShowDeleteModal(false)}
          onClickOutside={() => setIsShowDeleteModal(false)}
          onClickDelete={handleFixedAgendaDelete}
        />
      )}
    </>
  )
}

FixedAgendaListRow.displayName = 'FixedAgendaListRow'

type StatusLabelProps = {
  status: FixedAgendaStatus
}

const StatusLabel: React.VFC<StatusLabelProps> = ({ status }) => {
  const { t } = useTranslation()
  const isPublicStatus = status === FixedAgendaStatus.Public

  return (
    <div css={[statusCss, isPublicStatus ? publicStatusCss : privateStatusCss]}>
      {isPublicStatus ? t('PUBLIC_GROUP') : t('PRIVATE_GROUP')}
    </div>
  )
}

StatusLabel.displayName = 'StatusLabel'
