import { Icon } from '../../../../../../../components/ui/Icon'
import { StyledText } from '../../../../../../../components/ui/StyledText'
import { useTranslation } from '../../../../../../../i18n'
import { border } from '../../../../../../../styles/border'
import { color } from '../../../../../../../styles/newColors'

export type Props = {
  onClickBackFromWeight: () => void
}
export const OkrDrawerSubMenu: React.FC<Props> = ({ onClickBackFromWeight }) => (
  <div
    css={{
      backgroundColor: color('white-100'),
      borderBottom: border('simple-30'),
      padding: '16px 22px 16px 24px',
      width: '100%',
      height: '46px',
      display: 'flex',
      justifyContent: 'space-between',
    }}
  >
    <WeightHeader onClickBackFromWeight={onClickBackFromWeight} />
  </div>
)

OkrDrawerSubMenu.displayName = 'OkrDrawerSubMenu'

// FIXME: サブメニューの種類(現状weightだけ)が増えたらPropsを追加して対応してください
const WeightHeader: React.VFC<Pick<Props, 'onClickBackFromWeight'>> = ({
  onClickBackFromWeight,
}) => {
  const { t } = useTranslation()

  return (
    <div css={{ alignItems: 'center', display: 'flex' }}>
      <Icon
        height={16}
        width={16}
        css={{ cursor: 'pointer' }}
        onClick={onClickBackFromWeight}
        type="arrowBack"
      />
      <StyledText weight="bold" css={{ marginLeft: '8px' }}>
        {t('USE_WEIGHTING')}
      </StyledText>
    </div>
  )
}

WeightHeader.displayName = 'WeightHeader'
