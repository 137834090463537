/**
 * user-guidingのevent発火をID毎に保持するためのstorage
 */
const key = 'user-guiding'

export const updateUserGuidingEventFired = (id: number): void => {
  const payload = localStorage.getItem(key)
  if (payload && payload !== 'undefined') {
    const json = JSON.parse(payload)
    localStorage.setItem(key, JSON.stringify({ ...(json ?? {}), [id]: true }))
    return
  }
  localStorage.setItem(key, JSON.stringify({ [id]: true }))
}

export const getUserGuidingEventFired = (id: number): boolean => {
  const payload = localStorage.getItem(key)
  if (payload && payload !== 'undefined') {
    const json = JSON.parse(payload)
    return json[id] != null ? json[id] : false
  }
  return false
}
