import { Button, ButtonProps } from 'grommet'

import { color } from '../../../styles/newColors'
import { StyledText } from '../StyledText'

export type Props = ButtonProps & { text: string; isShow?: boolean; className?: string } & Omit<
    JSX.IntrinsicElements['button'],
    'color'
  >

export const BorderdButton: React.FC<Props> = ({ text, isShow, className, ...props }) => (
  <Button
    className={className}
    css={{
      border: `1px solid ${color('border-bk-10')}`,
      borderRadius: 4,
      boxSizing: 'border-box',
      padding: '2.5px 8px',
      display: isShow ? undefined : 'none',
    }}
    {...props}
  >
    <StyledText>{text}</StyledText>
  </Button>
)

BorderdButton.displayName = 'BorderdButton'
