/* stylelint-disable nt-family-no-missing-generic-family-keyword */
// eslint-disable-next-line no-restricted-imports
import { css, Global } from '@emotion/react'
import { Fragment } from 'react'
import ress from 'ress'

import { color } from './newColors'

export const GlobalCSS: React.FC = () => (
  <Fragment>
    <Global
      styles={[
        css`
          ${ress}
        `,
        {
          '@font-face': {
            fontFamily: 'Noto Color Emoji',
            src: 'url("https://cdn.resily.com/fonts/NotoColorEmoji.ttf")',
            fontDisplay: 'swap',
          },
        },
        {
          'svg:not([fill])': {
            fill: 'currentColor',
          },
        },
      ]}
    />
    <Global
      styles={{
        html: {
          // ress により scroll として初期化されるが、
          // モーダル等のUI実装時に body のスクロールを止めても html のスクロールが有効になってしまうため visible で上書きする。
          overflowY: 'visible',
        },
        body: {
          color: color('text-bk-100'),
          overflowWrap: 'break-word',
          'img.emoji': {
            width: '16px',
            height: '16px',
          },
          button: {
            outline: 'none',
          },

          '.ch-desk-messenger': {
            left: '20px !important',
            bottom: '58px !important',
          },
        },
      }}
    />
  </Fragment>
)

GlobalCSS.displayName = 'GlobalCSS'
