import { FC, useCallback, useMemo, useRef, useState } from 'react'
import { v1 as uuid } from 'uuid'

import { useTranslation } from '../../../i18n'
import { AttachFile } from '../../ui/AttachFile'
import { Avatar } from '../../ui/Avatar'
import { Button } from '../../ui/Button'
import { MediaFile } from '../../ui/MediaFile'
import { EditorRef, RichTextEditor } from '../../ui/RichTextEditor'
import { TextButton } from '../../ui/TextButton'

import { File } from './graphql'

export type Props = {
  author: {
    userId: string
    firstName: string
    lastName: string
    avatarUrl: string
    isDisabled: boolean
  }
  create: (body: string, plainText: string, attachments: ReadonlyArray<File>) => void
  onChanged: (changed: boolean) => void
}

export const NewCommentEditor: FC<Props> = ({ author, create, onChanged, ...props }) => {
  const { t } = useTranslation()
  const editorRef = useRef<EditorRef>(null)
  const [body, setBody] = useState<{ treeJson: string; plainText: string }>({
    treeJson: '',
    plainText: '',
  })
  const [attachments, setAttachments] = useState<ReadonlyArray<File>>([])

  const onClear = useCallback(() => {
    if (editorRef.current) {
      editorRef.current.setValue()
    }
    setBody({
      treeJson: '',
      plainText: '',
    })
    onChanged(false)
    setAttachments([])
  }, [onChanged])

  const onSave = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (!body.treeJson) {
        return
      }
      create(body.treeJson, body.plainText || '', attachments)
      onClear()
      e.currentTarget.blur()
    },
    [attachments, body.plainText, body.treeJson, create, onClear],
  )

  return (
    <div {...props}>
      <div css={{ display: 'flex' }}>
        <Avatar
          size="small"
          firstName={author.firstName}
          lastName={author.lastName}
          avatarUrl={author.avatarUrl}
          isUserDisabled={author.isDisabled}
        />
        <div css={{ width: 'calc(100% - 32px)', marginLeft: 12 }}>
          <RichTextEditor
            ref={editorRef}
            id={useMemo(uuid, [])}
            autoFocus={false}
            initialValueJSON={body.treeJson}
            onChange={useCallback(
              (json: string, text: string) => {
                setBody({
                  treeJson: json,
                  plainText: text,
                })
                onChanged(!!json)
              },
              [onChanged],
            )}
            onSave={useCallback(
              (json, text) => {
                create(json, text, attachments)
                onClear()
              },
              [attachments, create, onClear],
            )}
            css={[
              {
                button: {
                  svg: {
                    width: 16,
                    height: 16,
                  },
                },
              },
              !body.plainText
                ? {
                    '&[data-slate-editor="true"]': {
                      '> p:first-of-type': {
                        marginBottom: 0,
                      },
                    },
                  }
                : undefined,
            ]}
          />
          <div
            css={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: 16,
              gap: 8,
              minWidth: 170,
            }}
          >
            <div css={{ display: 'flex' }}>
              <div css={{ marginRight: 8, marginLeft: -8 }}>
                <AttachFile onChange={(file) => setAttachments([...attachments, file])} />
              </div>
              <div css={{ display: 'flex', flexWrap: 'wrap', gap: 8 }}>
                {attachments.map((mediaFile) => (
                  <div key={mediaFile.url}>
                    <MediaFile
                      filename={mediaFile.name}
                      url={mediaFile.url}
                      readOnly={false}
                      onClickDelete={() => {
                        setAttachments(attachments.filter((a) => a !== mediaFile))
                      }}
                      key={mediaFile.name}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div css={{ display: 'flex', justifyContent: 'flex-end', minWidth: 170 }}>
              {body.treeJson && (
                <TextButton color="text-bk-50" onClick={onClear} css={{ marginRight: '8px' }}>
                  {t('CANCEL')}
                </TextButton>
              )}
              <Button
                newColor="resily-orange-100"
                weight="normal"
                size="s"
                onClick={(e) => {
                  onSave(e)
                }}
              >
                {t('ADD')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

NewCommentEditor.displayName = 'NewCommentEditor'
