import { Blocker, History, Transition } from 'history'
import {
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  useContext,
  ContextType,
  useCallback,
  useRef,
  MutableRefObject,
} from 'react'
// history.blockなどのblocking処理がv6で削除されたので、NavigationContextを利用して修正した。
// https://github.com/remix-run/react-router/issues/8139#issuecomment-1147980133
import {
  Navigator as BaseNavigator,
  UNSAFE_NavigationContext as NavigationContext,
} from 'react-router-dom'

interface Navigator extends BaseNavigator {
  block: History['block']
}

type NavigationContextWithBlock = ContextType<typeof NavigationContext> & {
  navigator: Navigator
}

export const useBlocker = (blocker: Blocker, when: boolean): MutableRefObject<() => void> => {
  const { navigator } = useContext(NavigationContext) as NavigationContextWithBlock
  const unblockRef = useRef<() => void>(() => {})

  useEffect(() => {
    if (!when) return

    const unblock = navigator.block((tx: Transition) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          // Automatically unblock the transition so it can play all the way
          // through before retrying it. TODO: Figure out how to re-enable
          // this block if the transition is cancelled for some reason.
          unblock()
          tx.retry()
        },
      }
      unblockRef.current = unblock

      blocker(autoUnblockingTx)
    })
    // eslint-disable-next-line consistent-return
    return unblock
  })

  return unblockRef
}

export const usePrompt = ({
  message,
  originPath,
}: {
  message: string
  originPath: string
}): [boolean, Dispatch<SetStateAction<boolean>>] => {
  const [needBlock, setNeedBlock] = useState(false)
  // needBlockに依存しているuseEffectが行われたことを外部に伝えるためのstate
  const [block, setBlock] = useState(needBlock)

  const blocker = useCallback<Blocker>(
    (tx) => {
      if (!needBlock) return
      // eslint-disable-next-line no-alert
      if (window.confirm(message)) {
        setNeedBlock(false)
        tx.retry()
        return
      }
      const nextPath = window.location.pathname + window.location.search + window.location.hash
      // blockのURLの書き換えと競合しないように遅らせる
      setTimeout(() => {
        if (originPath !== nextPath) {
          window.history.pushState('', '', originPath)
        }
      }, 100)
    },
    [message, needBlock, originPath],
  )
  useBlocker(blocker, needBlock)

  useEffect(() => {
    const unload = (event: BeforeUnloadEvent) => {
      event.preventDefault()
      event.returnValue = ''
    }

    if (needBlock) {
      window.addEventListener('beforeunload', unload)
    } else {
      window.removeEventListener('beforeunload', unload)
    }
    setBlock(needBlock)

    return () => window.removeEventListener('beforeunload', unload)
  }, [needBlock])

  return [block, setNeedBlock]
}
