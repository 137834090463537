import { css } from '@emotion/react'
import { TextInput, TextInputProps } from 'grommet'
import { PropsWithRef, forwardRef } from 'react'

import { border } from '../../../styles/border'
import * as font from '../../../styles/font'
import { color } from '../../../styles/newColors'

type Size = 's' | 'm'
type FieldStyle = 'bottomLine' | 'outline'

export type InputProps = PropsWithRef<TextInputProps> & {
  inputSize?: Size
  fontSize?: font.FontSizeAlias
  fieldStyle?: FieldStyle
  isError?: boolean
  width?: string
  radius?: string
  ['data-testid']?: string
}

const sizes: { [key in Size]: number } = {
  s: 32,
  m: 40,
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      inputSize = 'm',
      fontSize,
      fieldStyle = 'outline',
      width = '256px',
      radius = '4px',
      isError,
      'data-testid': dataTestId,
      ...props
    }: InputProps,
    ref: React.Ref<HTMLInputElement>,
  ) => {
    const outlineStyle = css({
      border: border('simple-10'),
      borderRadius: radius,
    })
    const bottomLineStyle = css({
      borderTop: 'none',
      borderLeft: 'none',
      borderRight: 'none',
      borderRadius: '0px',
      borderBottom: border('simple-10'),
    })
    const fieldStyleCss = () => {
      switch (fieldStyle) {
        case 'bottomLine':
          return bottomLineStyle
        default:
          return outlineStyle
      }
    }
    const baseCss = css({
      flexGrow: 1,
      width,
      // stylelint-disable-next-line function-whitespace-after
      fontSize: fontSize && font.fontSize(fontSize).fontSize,
      color: color('text-bk-100'),
      height: sizes[inputSize],
      padding: '0 16px',
      fontWeight: 'normal',
      lineHeight: `${sizes[inputSize]}px`,
      backgroundColor: color('white-100'),
      outline: 'none',
      transition: 'border-color 0.1s ease-out',
      '&:hover, &:focus': {
        backgroundColor: color('hover-background-bk-5'),
        borderColor: color('resily-orange-100'),
      },
      '&:disabled': {
        color: color('text-bk-50'),
        backgroundColor: color('hover-background-bk-5'), // デザインシステム側でカラー管理する様んあるので、ここはもうこのままでOK。
        borderColor: color('background-bk-30'),
        opacity: 1, // disabledの時にブラウザ側で勝手にopacityが設定されるので上書きしてる。
      },
    })
    const csss = [baseCss, fieldStyleCss(), isError ? { borderColor: color('negative-100') } : null]

    return <TextInput ref={ref} css={csss} {...props} data-testid={dataTestId} />
  },
)

Input.displayName = 'Input'
