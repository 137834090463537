import { border } from '../../../styles/border'
import { color, ColorAlias } from '../../../styles/newColors'

export type ButtonColorObj = { textColor: string; backgroundColor: string; border?: string }

export type ButtonColorAlias = Extract<ColorAlias, 'resily-orange-100' | 'white-100'>

export const ButtonColorAliasObj: { [key in ButtonColorAlias]: ButtonColorObj } = {
  'resily-orange-100': {
    textColor: color('white-100'),
    backgroundColor: color('resily-orange-100'),
  },
  'white-100': {
    textColor: color('text-bk-100'),
    backgroundColor: color('white-100'),
  },
}

export type DisabledButtonColorAlias = Extract<
  ColorAlias,
  'resily-orange-100' | 'white-100' | 'background-bk-100'
>

export const DisabledButtonColorAliasObj: { [key in DisabledButtonColorAlias]: ButtonColorObj } = {
  'resily-orange-100': {
    textColor: color('white-100'),
    backgroundColor: color('disabled-100'),
  },
  'white-100': {
    textColor: color('text-bk-100'),
    backgroundColor: color('white-100'),
    border: border('simple-30'),
  },
  'background-bk-100': {
    textColor: color('white-100'),
    backgroundColor: color('background-bk-100'),
  },
}

export const buttonColor = (alias: keyof typeof ButtonColorAliasObj): ButtonColorObj | undefined =>
  ButtonColorAliasObj[alias]

export const disabledButtonColor = (
  alias: keyof typeof DisabledButtonColorAliasObj,
): ButtonColorObj | undefined => DisabledButtonColorAliasObj[alias]
