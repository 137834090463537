import { ActionPlanStatus, ActionPlan } from '../../graphql'

// 着手→未着手→保留→完了
const getStatusOrderNumber = (status: ActionPlanStatus): number => {
  switch (status) {
    case ActionPlanStatus.Started:
      return 5
    case ActionPlanStatus.NotStarted:
      return 4
    case ActionPlanStatus.Held:
      return 3
    case ActionPlanStatus.Completed:
      return 2
    case ActionPlanStatus.Discarded:
      return 1
    default:
      return fail(status)
  }
}

export const sortActionPlansByStatus = <T extends Pick<ActionPlan, 'status'>>(
  actionPlans: Array<T>,
  isAsc = false,
): Array<T> =>
  actionPlans.concat().sort((a, b) => {
    if (isAsc) return getStatusOrderNumber(a.status) - getStatusOrderNumber(b.status)
    return getStatusOrderNumber(b.status) - getStatusOrderNumber(a.status)
  })

export const sortActionPlansByDueDate = <T extends Pick<ActionPlan, 'dueDate'>>(
  actionPlans: Array<T>,
  isAsc = false,
): Array<T> =>
  actionPlans.concat().sort((a, b) => {
    if (isAsc && !b.dueDate) return 1
    if (isAsc && !a.dueDate) return -1
    if (isAsc && a.dueDate && b.dueDate)
      return new Date(b.dueDate).getTime() - new Date(a.dueDate).getTime()
    if (!a.dueDate) return 1
    if (!b.dueDate) return -1
    return new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime()
  })
