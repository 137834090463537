import { PlaceHolderType } from 'grommet/utils'
import { forwardRef, ChangeEventHandler } from 'react'
import { FieldError } from 'react-hook-form'

import { Input } from '../../components/ui/Input'
import { color } from '../../styles/newColors'

export type FormValue = {
  organizationName: string
  firstName: string
  lastName: string
  email: string
  password: string
  termName: string
  termStartDate: string
  termEndDate: string
}

type Props = {
  type: string
  placeholder: PlaceHolderType
  autoComplete?: string
  value?: string
  fieldError?: FieldError
  disabled?: boolean
  onChange: ChangeEventHandler<HTMLInputElement>
}

export const AccountSetupInput = forwardRef<HTMLInputElement, Props>(
  ({ type, placeholder, autoComplete, fieldError, disabled = false, onChange, value }, ref) => (
    <Input
      ref={ref}
      disabled={disabled}
      type={type}
      placeholder={placeholder}
      autoComplete={autoComplete}
      value={value}
      fontSize="medium"
      fieldStyle="bottomLine"
      width="100%"
      style={{
        borderColor: fieldError ? color('tag-red') : color('border-bk-10'),
      }}
      css={{
        padding: '0 8px',
        '::placeholder': { color: color('text-bk-30') },
      }}
      onChange={onChange}
    />
  ),
)

AccountSetupInput.displayName = 'AccountSetupInput'
