import { ReactNode } from 'react'

import { color } from '../../../styles/newColors'
import { Card } from '../Card'
import { Icon, Type as IconType } from '../Icon'
import { IconButton } from '../IconButton'
import { RichTextEditor } from '../RichTextEditor'
import { StyledText } from '../StyledText'
import { TooltipNew } from '../TooltipNew'

export type Props<T extends IconType = never> = {
  editorId: string
  description?: string
  icons?: Array<{ type: T; tooltip: string }>
  subtitle?: ReactNode
  subfooter?: ReactNode
  onClickIcon?: (icon: T) => void
}

export const DetailCard = <T extends IconType>({
  editorId,
  description = '',
  icons = [],
  onClickIcon = () => {},
  subtitle = null,
  subfooter = null,
}: Props<T>): JSX.Element => (
  <Card>
    <div
      css={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <div>{subtitle}</div>
      <div css={{ display: 'flex', alignSelf: 'flex-end', paddingBottom: '8px' }}>
        {icons.map(({ type, tooltip }) => (
          <TooltipNew key={type} title={tooltip}>
            <IconButton
              css={{ marginLeft: 12 }}
              border={false}
              size={12}
              onClick={() => {
                onClickIcon(type)
              }}
            >
              <Icon type={type} color={color('text-bk-30')} hoverColor="resily-orange-100" />
            </IconButton>
          </TooltipNew>
        ))}
      </div>
    </div>
    <StyledText css={{ marginTop: 20, marginBottom: 20 }}>
      <RichTextEditor
        id={editorId}
        initialValueJSON={description}
        editorProps={{
          readOnly: true,
        }}
      />
    </StyledText>
    {subfooter}
  </Card>
)
