import { forwardRef, PropsWithoutRef } from 'react'

import * as colors from '../../../styles/colors'

export type Props = PropsWithoutRef<JSX.IntrinsicElements['button']> & {
  /**
   * @default true
   */
  border?: boolean
  size?: number
}

export const IconButton = forwardRef<HTMLButtonElement, Props>(
  ({ type = 'button', size = 36, children, border = true, ...props }, ref) => (
    <button
      ref={ref}
      // eslint-disable-next-line react/button-has-type
      type={type}
      css={{
        boxSizing: 'border-box',
        outline: 'none',
        position: 'relative',
        width: size,
        height: size,
        border: border ? `1px solid ${colors.iconButtonColor}` : 'none',
        borderRadius: '100%',
        background: colors.iconButtonFill,
        color: colors.iconButtonColor,
      }}
      {...props}
    >
      {children}
    </button>
  ),
)

IconButton.displayName = 'IconButton'
