import React, { useCallback } from 'react'

import { useTranslation } from '../../../i18n'
import { border } from '../../../styles/border'
import { color } from '../../../styles/newColors'

import { TargetSettingIcon } from './TargetSettingIcon'
import { OrgDiagramConfigItem } from './types'
import { conversionMapPx } from './util'

type Props = {
  nodeId: OrgDiagramConfigItem['id']
  keyResults: OrgDiagramConfigItem['keyResults']
  onAddChildNode: (parentNodeId: string) => void
  onAddChildNodeConnectKR: (parentNodeId: string, parentKeyResultId: string) => void
}

export const SelectKeyResultModal: React.VFC<Props> = ({
  nodeId,
  keyResults,
  onAddChildNode,
  onAddChildNodeConnectKR,
}: Props) => {
  const { t } = useTranslation()

  /**
   * 上位KRに紐づけずにObjective作成モーダルを開く
   */
  const handleOpenCreateOkrModalUnConnectParentKR = useCallback(
    () => onAddChildNode(nodeId),
    [nodeId, onAddChildNode],
  )

  /**
   * 上位KRを紐付けてObjective作成モーダルを開く
   */
  const handleCreateOkrModalConnectParentKR = useCallback(
    (parentKeyResultId: string) => onAddChildNodeConnectKR(nodeId, parentKeyResultId),
    [nodeId, onAddChildNodeConnectKR],
  )

  return (
    <section
      css={{
        background: color('white-100'),
        outline: border('simple-30'),
        boxSizing: 'border-box',
        borderRadius: '4px',
        boxShadow: `0px 8px 16px ${color('border-bk-10')}`,
        width: '100%',
      }}
    >
      <div
        css={{
          height: conversionMapPx(40),
          boxShadow: `0px 1px 0px ${color('border-bk-10')}`,
        }}
      >
        <h3
          css={{
            fontSize: conversionMapPx(12),
            fontWeight: 'bold',
            paddingTop: conversionMapPx(14),
            paddingLeft: conversionMapPx(16),
          }}
        >
          {t('CREATE_CHILD_OKR_CONNECT_KR')}
        </h3>
      </div>

      {keyResults.map((kr) => (
        <div
          css={{
            height: conversionMapPx(40),
            ':hover': {
              cursor: 'pointer',
              backgroundColor: `${color('hover-background-bk-5')}`,
            },
          }}
        >
          <div
            css={{
              paddingTop: conversionMapPx(10),
              paddingLeft: conversionMapPx(16),
              height: conversionMapPx(30),
              boxSizing: 'border-box',
            }}
          >
            <button
              type="button"
              css={{
                display: 'flex',
                width: '100%',
                height: '100%',
                textAlign: 'left',
              }}
              onClick={() => handleCreateOkrModalConnectParentKR(kr.id)}
            >
              <div
                css={{
                  color: color('kr-green-100'),
                  backgroundColor: color('kr-green-100'),
                  width: '5px',
                  height: '28px',
                  borderRadius: '5.6px',
                  marginRight: conversionMapPx(10),
                }}
              />
              <div
                css={{
                  display: 'flex',
                  width: '364px',
                  height: '100%',
                  fontSize: conversionMapPx(12),
                  lineHeight: conversionMapPx(16),
                }}
              >
                <div>
                  <TargetSettingIcon type={kr.targetSetting} />
                </div>
                <span
                  css={{
                    display: 'block',
                    width: '100%',
                    height: '100%',
                    paddingTop: conversionMapPx(3),
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {kr.name}
                </span>
              </div>
            </button>
          </div>
        </div>
      ))}

      <div
        css={{
          height: conversionMapPx(40),
          boxShadow: `0px 1px 0px ${color('border-bk-10')}`,
          ':hover': {
            cursor: 'pointer',
            backgroundColor: `${color('hover-background-bk-5')}`,
          },
        }}
      >
        <button
          type="button"
          css={{
            '&:focus': {
              outline: 'none',
            },
            textAlign: 'left',
            display: 'inherit',
            width: '100%',
            height: '100%',
          }}
          onClick={handleOpenCreateOkrModalUnConnectParentKR}
        >
          <span
            css={{
              paddingTop: conversionMapPx(14),
              paddingLeft: conversionMapPx(16),
              color: `${color('text-bk-100')}`,
              fontSize: conversionMapPx(12),
              lineHeight: 1,
            }}
          >
            {t('CREATE_CHILD_OKR_UN_CONNECT_KR')}
          </span>
        </button>
      </div>
    </section>
  )
}

SelectKeyResultModal.displayName = 'SelectKeyResultModal'
