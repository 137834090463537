import { useTranslation } from '../../../../../../i18n'
import { ActivatedUserTableFragment } from '../../../../api/graphql'
import { ActivatedUserTableDatum } from '../types'

export const useMakeActivatedUserData = (
  users: ActivatedUserTableFragment['users'],
): Array<ActivatedUserTableDatum> => {
  const { t } = useTranslation()

  return users.map(
    (u): ActivatedUserTableDatum => ({
      id: u.id,
      fullName: t('FULL_NAME', { firstName: u.firstName, lastName: u.lastName }),
      email: u.email,
      admin: u.admin,
      twoFa: u.otpRequiredForLogin,
      isSamlEnabled: u.samlEnabled,
      // タイムスタンプで正確にソートを行えるようにunix timestampに変換してDataTableに渡す
      // レンダリング時にunix timestamp to Date型へ変換する
      createdAt: u.createdAt ? Math.floor(new Date(u.createdAt).getTime() / 1000) : 0,
      lastLogin: u.currentSignInAt ? Math.floor(new Date(u.currentSignInAt).getTime() / 1000) : 0,
    }),
  )
}
