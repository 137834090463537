import { OrgDiagramRef } from 'basicprimitivesreact'
import { RefObject, useLayoutEffect, useContext } from 'react'

import { useOkrModalStatus } from '../../../components/standalone/OkrModal/hooks/useOkrModalStatus'
import { KrFilterContext } from '../../../contexts/KrFilterContext'

import { generateKeyResultDomId, generateObjectiveDomId } from './OkrNode'
import { DotColorPair } from './types'

// const isDivElement = (elm: Node): elm is HTMLDivElement => elm.nodeName === 'DIV'
const isSVGSVGElement = (elm: Node): elm is SVGSVGElement => elm.nodeName === 'svg'
const isSVGPathElement = (elm: Node): elm is SVGPathElement => elm.nodeName === 'path'

// OrgDiagramの生成した、少し下にあるsvgを取得する
const getChildSvgList = (elm: Element, depth = 0): Array<SVGSVGElement> => {
  // OkrNodeの方までは見に行かない
  if (depth > 1) return []

  const result: Array<SVGSVGElement> = []
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < elm.children.length; i++) {
    const child = elm.children[i]
    if (isSVGSVGElement(child)) {
      result.push(child)
    } else {
      result.push(...getChildSvgList(child, depth + 1))
    }
  }
  return result
}

// basicprimitivesの動作を改変する系のhooksの寄せ集め
export const useMonkeyPatch = (
  diagramRef: RefObject<OrgDiagramRef>,
  dotColorPairs: ReadonlyArray<DotColorPair>,
): void => {
  // キーイベントがOrgDiagramに取られるので止める
  useLayoutEffect(() => {
    if (!diagramRef.current || !diagramRef.current.controlPanelRef.current) {
      return () => {}
    }

    const { current } = diagramRef.current.controlPanelRef
    const stopPropagater = (e: KeyboardEvent) => e.stopPropagation()

    current.addEventListener('keydown', stopPropagater, true)
    return () => current.removeEventListener('keydown', stopPropagater, true)
  }, [diagramRef])

  // FIXME: 初回Dotクリック時まで変化しない
  // dotにhoverした際のマウスカーソルの見た目を変える
  useLayoutEffect(() => {
    if (!diagramRef.current || !diagramRef.current.placeholderRef.current) {
      return
    }
    const { current } = diagramRef.current.placeholderRef

    getChildSvgList(current)
      .filter((n) => n.childElementCount === dotColorPairs.length)
      .flatMap((n) => [...n.children])
      .filter(isSVGPathElement)
      .filter(
        (path) =>
          path.style.fill !== '' &&
          dotColorPairs
            .filter((cp) => cp.fill === path.style.fill)
            .some((cp) => cp.stroke === path.style.stroke),
      )
      .forEach((p) => {
        p.style.cursor = 'pointer'
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [diagramRef.current?.placeholderRef.current, dotColorPairs])

  // calloutとDotMenuが重なるとDotMenuが押せないのを解決する
  useLayoutEffect(() => {
    if (!diagramRef.current || !diagramRef.current.calloutPlaceholderRef.current) {
      return
    }
    const { current } = diagramRef.current.calloutPlaceholderRef

    current.style.pointerEvents = 'none'
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [diagramRef.current?.calloutPlaceholderRef.current])

  // OKR詳細モーダルが表示されたときに、表示対象のOKRを中央に表示させるための処理
  const {
    type: modalType,
    objectiveId: modalObjectiveId,
    keyResultId: modalKeyResultId,
  } = useOkrModalStatus()
  useLayoutEffect(() => {
    if (!diagramRef.current?.scrollPanelRef.current) return
    if (modalType === 'close') return

    const id =
      modalType === 'objective'
        ? generateObjectiveDomId(modalObjectiveId)
        : generateKeyResultDomId(modalKeyResultId)

    const element = document.getElementById(id)
    if (!element) return

    const { width: scrollAreaWidth } =
      diagramRef.current.scrollPanelRef.current.getBoundingClientRect()
    const { x, width } = element.getBoundingClientRect()
    const adjust = scrollAreaWidth > width ? (scrollAreaWidth - width) / 2 : 0
    const sideNavWidth = window.innerWidth - scrollAreaWidth

    diagramRef.current.scrollPanelRef.current.scrollBy(x - adjust - sideNavWidth, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [diagramRef.current?.scrollPanelRef.current, modalType])

  const { lastFiltering } = useContext(KrFilterContext)
  useLayoutEffect(() => {
    if (!diagramRef.current?.scrollPanelRef.current) return
    const id = generateKeyResultDomId(lastFiltering.id)

    const element = document.getElementById(id)
    if (!element) return

    const { width: scrollAreaWidth } =
      diagramRef.current.scrollPanelRef.current.getBoundingClientRect()
    const { x, width } = element.getBoundingClientRect()
    const adjust = scrollAreaWidth > width ? (scrollAreaWidth - width) / 2 : 0
    const sideNavWidth = window.innerWidth - scrollAreaWidth

    diagramRef.current.scrollPanelRef.current.scrollBy(x - adjust - sideNavWidth, 0)
  }, [lastFiltering, diagramRef])

  // // LevelAnnotationを保持するDOMを取得
  // const LevelAnnotationsRootRef = useRef<HTMLDivElement>()
  // useLayoutEffect(() => {
  //   if (!diagramRef.current || !diagramRef.current.titlesPlaceholderRef.current) {
  //     return
  //   }

  //   const { current } = diagramRef.current.titlesPlaceholderRef

  //   const levelTitle = current.querySelector<HTMLTableDataCellElement>(
  //     'div > div > div > table > tbody > tr > td',
  //   )

  //   const root =
  //     levelTitle?.parentElement?.parentElement?.parentElement?.parentElement?.parentElement
  //       ?.parentElement

  //   if (root != null && isDivElement(root)) {
  //     LevelAnnotationsRootRef.current = root
  //   }
  // }, [diagramRef, diagramRef.current?.titlesPlaceholderRef.current?.children])

  // // LevelAnnotation内の文字の向きを反転させる
  // useLayoutEffect(() => {
  //   if (!LevelAnnotationsRootRef.current) {
  //     return () => {}
  //   }

  //   const observer = new MutationObserver((mutations) => {
  //     let done = false
  //     mutations.forEach((rec) => {
  //       if (done) return
  //       rec.addedNodes.forEach((n) => {
  //         if (done) return
  //         if (isDivElement(n) && n.parentElement && isDivElement(n.parentElement)) {
  //           n.parentElement
  //             .querySelectorAll<HTMLTableDataCellElement>('div > div > table > tbody > tr > td')
  //             .forEach((titleElm) => {
  //               titleElm.style.transform = 'rotateZ(180deg)'
  //               titleElm.style.direction = 'rtl'
  //               titleElm.style.unicodeBidi = 'bidi-override'
  //               done = true
  //             })
  //         }
  //       })
  //     })
  //   })

  //   observer.observe(LevelAnnotationsRootRef.current, { childList: true })
  //   return () => observer.disconnect()
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [LevelAnnotationsRootRef.current])
}
