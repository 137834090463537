import { useProfiler } from '@sentry/react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { PageContent } from '../../components/pageContent'
import { useCurrentUser } from '../../contexts/UserContext'
import { useTranslation } from '../../i18n'
import { Screen } from '../../lib/screen'
import { useTracking } from '../../lib/tracking'
import { color } from '../../styles/newColors'
import { AuthRouteProps } from '../../types/authRouteProps'

import { MenuCard } from './components/MenuCard'
import { SectionTitle } from './components/SectionTitle'
import { useTitleWithMenus } from './hooks/useTitleWithMenus'
import * as styles from './styles'
import { TitleWithMenus } from './types'

export const SettingContainer: React.FC<AuthRouteProps> = ({ onOkrTermLoaded }) => {
  const { t } = useTranslation()
  useTracking(t('SETTING_PAGE_TITLE'), Screen.Setting)
  useProfiler('SettingContainer')
  const navigate = useNavigate()
  const isAdmin = !!useCurrentUser()?.admin

  const titleWithMenus = useTitleWithMenus({ isAdmin, t, navigate })

  useEffect(() => {
    onOkrTermLoaded(null)
  }, [onOkrTermLoaded])

  return <Setting titleWithMenus={titleWithMenus} />
}

SettingContainer.displayName = 'SettingContainer'

export type Props = {
  titleWithMenus: TitleWithMenus
}

export const Setting: React.FC<Props> = ({ titleWithMenus }) => (
  <PageContent contentBackgroundColor={color('background-bk-5')}>
    <div css={styles.containerCss}>
      {titleWithMenus.map(({ key, title, menuCards }) => (
        <div key={key} css={styles.menuSectionWrapperCss}>
          <SectionTitle>{title}</SectionTitle>
          <div css={styles.menuCardsWrapperCss}>
            {menuCards.map((m) => (
              <MenuCard {...m} />
            ))}
          </div>
        </div>
      ))}
    </div>
  </PageContent>
)

Setting.displayName = 'Setting'
