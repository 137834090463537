import { TextButton } from '../../../../../../../components/ui/TextButton'
import { NewTitle } from '../../../../../../../components/ui/Title'
import { useTranslation } from '../../../../../../../i18n'
import { border } from '../../../../../../../styles/border'
import { fontSize } from '../../../../../../../styles/font'
import { color } from '../../../../../../../styles/newColors'

import { KeyResultFragment } from './graphql'

export type Props = {
  keyResult?: KeyResultFragment
  create?: boolean
  onClickAdd?: () => void
  onSave?: (name: string) => void
}

export const KeyResultItem: React.FC<Props> = ({
  keyResult,
  create,
  onClickAdd,
  onSave = () => {},
}) => {
  const { t } = useTranslation()

  const keyResultName = keyResult?.name || ''
  const keyResultDisabled = keyResult?.isDisabled

  return create ? (
    <button
      type="button"
      css={{
        display: 'inline-flex',
        padding: '8px 0',
        width: '100%',
        borderBottom: border('simple-10'),
        ':hover': {
          backgroundColor: color('hover-background-bk-5'),
          '> button > span': {
            color: color('resily-orange-100'),
          },
          '> button > svg > path': {
            fill: color('resily-orange-100'),
          },
        },
      }}
      onClick={onClickAdd}
    >
      <TextButton icon="plus" color="text-bk-50">
        {t('ADD_X', { x: t('KEY_RESULT') })}
      </TextButton>
    </button>
  ) : (
    <div
      css={{
        color: color('text-bk-100'),
        width: '100%',
        ...fontSize('medium'),
        outline: 'none',
        margin: '8px 8px 8px 0',
        '&:hover': {
          backgroundColor: color('background-bk-5'),
          outline: 'none',
        },
      }}
    >
      <NewTitle
        color="kr-green-100"
        title={keyResultName}
        onUpdate={onSave}
        targetSetting={keyResult?.targetSetting}
        disabled={keyResultDisabled}
      />
    </div>
  )
}

KeyResultItem.displayName = 'KeyResultItem'
