import { css } from '@emotion/react'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import utc from 'dayjs/plugin/utc'

import { useCurrentUser } from '../../../contexts/UserContext'
import { useTranslation } from '../../../i18n'
import { summaryTitle } from '../../../lib/domain/checkinSummary'
import { Screen } from '../../../lib/screen'
import { useTracking } from '../../../lib/tracking'
import { border } from '../../../styles/border'
import { color } from '../../../styles/newColors'
import { PageContent } from '../../pageContent'
import { CheckinComment } from '../CheckinComment/index'
import { CheckInSummaryDetail } from '../CheckinSummaryDetail/index'
import { CheckinSummaryPageHeader } from '../CheckinSummaryPageHeader'

import { CheckinTitleAndButton } from './CheckinTitleAndButton'
import { KeyResultSharedItemType, CheckinSummaryFragment, DocumentInput } from './graphql'

export type Props = {
  checkinSummary: CheckinSummaryFragment
  onClickUpdateSharedItem: (
    checkinKeyResultId: string,
    itemType: KeyResultSharedItemType,
    comment: DocumentInput,
  ) => void
  onClickDetailEdit: () => void
  onClickCreateReaction: (emoji: string) => void
  onClickDeleteReaction: (reactedId: string) => void
}

dayjs.extend(utc)
dayjs.extend(isBetween)

const checkinTitleAndButtonCss = css({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '60px 32px 0',
})

const borderCss = css({ borderBottom: border('simple-20'), margin: '12px 0 18px' })

export const CheckinSummaryPageView: React.FC<Props> = ({
  checkinSummary,
  onClickUpdateSharedItem,
  onClickDetailEdit,
  onClickCreateReaction,
  onClickDeleteReaction,
}) => {
  const user = useCurrentUser()
  const { t } = useTranslation()
  useTracking(summaryTitle(t, checkinSummary), Screen.Summary)

  // 該当するKRの責任者・協力者かどうか
  const isOwnerOrContributor =
    checkinSummary.checkinSummaryKeyResults
      .flatMap((kr) => kr.checkinKeyResults)
      .filter(
        (ckr) =>
          ckr.keyResult.owner.id === user?.id ||
          ckr.keyResult.members.some((member) => member.id === user?.id),
      ).length > 0

  // 現在のチェックイン期間かどうか
  const isCheckinPeriod = dayjs
    .utc()
    .isBetween(dayjs.utc(checkinSummary.startDate), dayjs.utc(checkinSummary.endDate))

  return (
    <PageContent
      breadcrumbs={undefined}
      layout={{ background: color('background-bk-5') }}
      header={
        <div css={{ width: '100%' }}>
          <CheckinSummaryPageHeader okrNode={checkinSummary.node} checkinSummary={checkinSummary} />
        </div>
      }
    >
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '28px 78px 0px',
        }}
      >
        <div
          css={{
            backgroundColor: color('white-100'),
            width: '100%',
            border: border('simple-20'),
            borderRadius: '4px',
          }}
        >
          <CheckInSummaryDetail
            okrNodeId={checkinSummary.node.id}
            summary={checkinSummary}
            onClickEdit={onClickDetailEdit}
            onClickCreateReaction={onClickCreateReaction}
            onClickDeleteReaction={onClickDeleteReaction}
          />
          {checkinSummary.checkinSummaryKeyResults.length > 0 && (
            <div css={checkinTitleAndButtonCss}>
              <CheckinTitleAndButton
                okrNodeId={checkinSummary.node.id}
                alreadyCheckin={user?.checkinKeyResultsState?.isBulkUpdated}
                isCheckinPeriod={isCheckinPeriod}
                isOwnerOrContributor={isOwnerOrContributor}
              />
            </div>
          )}
          <div css={borderCss} />
          {checkinSummary.checkinSummaryKeyResults
            .concat()
            .sort((a, b) => a.displayOrder - b.displayOrder)
            .map((item) => (
              <div key={item.id} css={{ margin: '28px 32px' }}>
                <CheckinComment
                  termId={checkinSummary.node.term.id}
                  checkinSummaryKeyResult={item}
                  onClickSave={onClickUpdateSharedItem}
                />
              </div>
            ))}
        </div>
      </div>
    </PageContent>
  )
}

CheckinSummaryPageView.displayName = 'CheckinSummaryPageView'
