import { useModal } from '@resily/geisha'
import { useCallback } from 'react'

import { useCurrentUser } from '../../../contexts/UserContext'
import { useActivityRefetchQueries } from '../../../lib/domain/activity/hooks/useActivityRefetchQueries'
import {
  useOkrRefetchQueries,
  useOkrRefetchQueriesWithOkrModalInitQuery,
} from '../../../lib/domain/okr'
import { convertToFileInput } from '../../../lib/fileInput'
import { StrictPropertyCheck } from '../../../lib/type'
import {
  InitQueryContextProvider,
  useKeyResultEditFieldsFetch,
} from '../../domain/KeyResultEditFields/hooks'
import { ExtendedUpdateKeyResultInfoInput } from '../../domain/KeyResultEditFields/types'

import { KeyResultUpdateModal, Props as KeyResultUpdateModalProps } from './KeyResultUpdateModal'
import {
  UpdateKeyResultInfoInput,
  useDeleteMutation,
  useInitKeyResultQuery,
  useToggleStatusMutation,
  useUpdateKeyResultMutation,
  useUpdateKeyResultParentObjectiveMutation,
} from './graphql'

export type Props = Pick<KeyResultUpdateModalProps, 'isOpened' | 'close' | 'onClose' | 'onEdit'> & {
  keyResultId: string
  onUpdateParentObjective: (objectiveId: string) => void
  onDelete?: () => void
}

const KeyResultUpdateModalContainerInner: React.VFC<
  Props & { modalComponent: ReturnType<typeof useModal>[0] }
> = ({ keyResultId, onUpdateParentObjective, onDelete, close, ...rest }) => {
  const user = useCurrentUser()

  // 初回query
  const { data } = useInitKeyResultQuery({
    variables: { keyResultId },
    skip: keyResultId == null,
  })

  // KRの有効/無効切り替えmutation
  const [toggleStatusMutation] = useToggleStatusMutation({
    variables: { keyResultId },
    refetchQueries: useActivityRefetchQueries().concat(useOkrRefetchQueries()),
    optimisticResponse: data
      ? {
          updateKeyResultActivateStatus: {
            id: data.keyResult.id,
            isDisabled: !data.keyResult.isDisabled,
            objective: {
              id: data.keyResult.objective.id,
              progressRate: data.keyResult.objective.progressRate,
            },
          },
        }
      : undefined,
  })

  // KR削除mutation
  const [deleteKeyResultMutation] = useDeleteMutation({
    variables: { keyResultId },
    refetchQueries: useOkrRefetchQueriesWithOkrModalInitQuery(),
    onCompleted: onDelete,
  })

  // KR更新mutation
  const [updateKeyResultMutation] = useUpdateKeyResultMutation({
    refetchQueries: useActivityRefetchQueries(),
  })
  const updateKeyResult = useCallback(
    async (kr: ExtendedUpdateKeyResultInfoInput | undefined) => {
      if (kr == null) return
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { message, attachmentViews, confidence, ...restKr } = kr
      const isAssigned: boolean =
        !!user && (user.id === restKr.ownerId || restKr.memberIds.includes(user.id))

      const result = {
        ...restKr,
        confidence: isAssigned ? confidence : undefined,
        message: message !== '' ? message : undefined,
        attachments:
          attachmentViews.map<
            StrictPropertyCheck<
              ReturnType<typeof convertToFileInput>,
              UpdateKeyResultInfoInput['attachments'][0]
            >
          >(convertToFileInput),
      }
      const checkResult: StrictPropertyCheck<typeof result, UpdateKeyResultInfoInput> = result
      await updateKeyResultMutation({
        variables: {
          input: checkResult,
        },
      })
    },
    [updateKeyResultMutation, user],
  )

  // 紐付くObjective変更mutation
  const [updateParentObjectiveMutation] = useUpdateKeyResultParentObjectiveMutation({
    refetchQueries: [
      ...useOkrRefetchQueriesWithOkrModalInitQuery(),
      ...useActivityRefetchQueries(),
    ],
  })
  const updateParentObjective = useCallback(
    (objectiveId: string) =>
      updateParentObjectiveMutation({
        variables: {
          id: keyResultId,
          parentObjectiveId: objectiveId,
        },
      }).then(() => onUpdateParentObjective(objectiveId)),
    [keyResultId, onUpdateParentObjective, updateParentObjectiveMutation],
  )

  const loadingKrFieldsData = useKeyResultEditFieldsFetch()

  if (data == null || loadingKrFieldsData) {
    return null
  }

  return (
    <KeyResultUpdateModal
      keyResult={data.keyResult}
      updateParentObjective={updateParentObjective}
      updateKeyResult={updateKeyResult}
      toggleStatus={toggleStatusMutation}
      deleteKeyResult={deleteKeyResultMutation}
      close={close}
      {...rest}
    />
  )
}

export const KeyResultUpdateModalContainer: typeof KeyResultUpdateModalContainerInner = ({
  ...props
}) => (
  <InitQueryContextProvider>
    <KeyResultUpdateModalContainerInner {...props} />
  </InitQueryContextProvider>
)
