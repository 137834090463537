import { useProfiler } from '@sentry/react'
import { Box } from 'grommet'
import React, { useMemo } from 'react'
import { SubmitHandler } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { Breadcrumb, BreadcrumbParams } from '../../components/domain/Breadcrumb'
import { FixedAgendaDetailCard } from '../../components/domain/FixedAgendaDetailCard'
import { PageContent } from '../../components/pageContent'
import { Link } from '../../components/ui/Link'
import { StyledText } from '../../components/ui/StyledText/index'
import { Trans, useTranslation } from '../../i18n/index'
import { Screen } from '../../lib/screen'
import { useTracking } from '../../lib/tracking'
import { color } from '../../styles/newColors'
import { AuthRouteProps } from '../../types/authRouteProps'
import { createFixedAgendaAdmin, ExternalUrls, oneOnOneAdmin } from '../../urls'

import { useCreateFixedAgendaMutation, FixedAgendaStatus } from './api/graphql'
import { createFixedAgendaAdminCss } from './styles'

export type FormValue = {
  title: string
  body: {
    treeJson: string
    plainText: string
  }
  status: FixedAgendaStatus
}

export const AdminFixedAgendaCreateContainer: React.FC<AuthRouteProps> = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  useProfiler('AdminFixedAgendaCreateContainer')
  useTracking(t('ADMIN_CREATE_FIXED_AGENDA_PAGE_TITLE'), Screen.FixedAgendaCreateSetting)

  const breadcrumbs = useMemo<BreadcrumbParams>(
    () => ({
      url: createFixedAgendaAdmin,
      items: [
        {
          breadcrumbName: 'setting',
        },
        {
          breadcrumbName: 'settingOneOnOnes',
        },
        {
          breadcrumbName: 'createFixedAgenda',
        },
      ],
    }),
    [],
  )

  const onSubmit: SubmitHandler<FormValue> = async (value: FormValue) => {
    await createFixedAgenda(
      value.title,
      value.body.treeJson === undefined ? '' : value.body.treeJson,
      value.body.plainText === undefined ? '' : value.body.plainText,
      value.status,
    )
  }

  const [createAgenda] = useCreateFixedAgendaMutation()
  const createFixedAgenda = async (
    title: string,
    body: string,
    plainText: string,
    status: FixedAgendaStatus,
  ) => {
    const result = await createAgenda({
      variables: {
        input: {
          title,
          body,
          plainText,
          status,
        },
      },
    })
    if (!result.data) return
    navigate(oneOnOneAdmin)
  }

  const backToTemplateList = () => {
    navigate(oneOnOneAdmin)
  }

  return (
    <PageContent contentBackgroundColor={color('background-bk-5')}>
      <Box direction="column" css={createFixedAgendaAdminCss}>
        <Breadcrumb breadcrumbs={breadcrumbs} />
        <Box direction="column" margin={{ top: '32px' }} gap="8px">
          <StyledText size="xlarge" css={{ fontWeight: 'bold', lineHeight: '28px' }}>
            {t('ADMIN_FIXED_AGENDA_DETAIL_PAGE_TITLE')}
          </StyledText>
          <StyledText
            fontStyle="regular"
            size="medium"
            color="text-bk-80"
            css={{ whiteSpace: 'pre-wrap' }}
          >
            <Trans
              i18nKey="ADMIN_FIXED_AGENDA_DETAIL_DESCRIPTION"
              components={[
                <Link
                  method="newTab"
                  href={ExternalUrls.EDIT_FIXED_AGENDA}
                  css={{
                    color: color('resily-orange-100'),
                    textDecoration: 'underline',
                  }}
                >
                  support site
                </Link>,
              ]}
            />
          </StyledText>
        </Box>
        <FixedAgendaDetailCard
          css={{ marginTop: '24px' }}
          onClickSave={onSubmit}
          onClickCancel={backToTemplateList}
        />
      </Box>
    </PageContent>
  )
}

AdminFixedAgendaCreateContainer.displayName = 'AdminFixedAgendaCreateContainer'
