// 改行と全角半角スペースを取り除く
export const trimInvalidCharacter = (s: string | undefined | null): string => {
  // nullエラーを回避するために返り値をstringで固定したいため以下を記載
  if (!s) return ''
  return s.replace(/\s|\n/g, '')
}

export const includes = (src: string, search: string): boolean =>
  src.trim().toLowerCase().includes(search.trim().toLowerCase())

/**
 *全角文字を2バイト、半角を1バイトとして合計値を返す
 * @param str string
 * @return number
 */
export const bytes = (str: string): number => {
  let len = 0
  for (let i = 0; i < str.length; i += 1) {
    const c = str.charCodeAt(i) // utf-16
    const width =
      (c >= 0x0 && c < 0x81) ||
      c === 0xf8f0 ||
      (c >= 0xff61 && c < 0xffa0) ||
      (c >= 0xf8f1 && c < 0xf8f4)
        ? 1
        : 2
    len += width
  }
  return len
}
