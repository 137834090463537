import { css } from '@emotion/react'
import { useMemo } from 'react'

import { color } from '../../../../styles/newColors'

export type RightSectionParams = {
  showRightSection: boolean
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useStyles = ({ showRightSection }: RightSectionParams) =>
  useMemo(
    () =>
      ({
        root: css({
          display: showRightSection ? 'block' : 'none',
          flex: '0 0 472px',
          maxWidth: '472px',
          background: color('white-100'),
        }),
      } as const),
    [showRightSection],
  )
