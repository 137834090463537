import { yupResolver } from '@hookform/resolvers/yup'
import { Fragment, useState, useRef, MouseEvent, useMemo, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'

import { useCurrentUser, useSetCurrentUser } from '../../../contexts/UserContext'
import { useTranslation } from '../../../i18n'
import { formatDateInput } from '../../../lib/date'
import { useActivityRefetchQueries } from '../../../lib/domain/activity/hooks/useActivityRefetchQueries'
import { Level, numberToConfidence } from '../../../lib/domain/confidence'
import { isIncludedUser } from '../../../lib/domain/keyResult/keyResult'
import { isNotFilledRequiredTargetValue } from '../../../lib/domain/keyResult/keyResultProgress'
import { useOkrRefetchQueries } from '../../../lib/domain/okr'
import { KeyResultEventScreen } from '../../../lib/domain/okr/graphql'
import { componentNames, featureNames, generateTestId } from '../../../lib/testId'
import {
  KeyResultDescriptionProgressRateForm,
  Props as KeyResultDescriptionProgressRateFormProps,
} from '../../../pages/OneOnOnesDetail/Drawer/FloatDrawer/Content/components/KeyResultDescription/KeyResultDescriptionProgressRateForm'
import type { FormValue } from '../../../pages/OneOnOnesDetail/Drawer/FloatDrawer/Content/components/KeyResultDescription/KeyResultDescriptionProgressRateFormSetting'
import { ExternalUrls } from '../../../urls'
import { AutoResizeTextArea } from '../../ui/AutoResizeTextArea'
import { Modal, Props as ModalProps } from '../../ui/Modal'
import { PopoverPortal } from '../../ui/PopoverPortal'
import { StyledText } from '../../ui/StyledText'
import { WrapText } from '../../ui/WrapText'
import { KeyResultAvatarWithContributor } from '../AvatarWithContributor'
import { ConfidenceList } from '../ConfidenceList'
import { OwnerConfidenceTag } from '../ConfidenceTag/OwnerConfidenceTag'
import { KeyResultProgressRateBar } from '../KeyResultProgressRateBar'
import { KeyResultQuickUpdateModalCancelModal } from '../KeyResultQuickUpdateModalCancelModal'
import { KeyResultTargetSettingIcon } from '../KeyResultTargetSettingIcon'
import { OkrFloatDrawerList } from '../OkrFloatDrawerList'
import { OkrFloatDrawerListItem } from '../OkrFloatDrawerList/types'
import { QuickUpdateKeyResultViewProgressRateForm } from '../QuickUpdateKeyResultViewProgressRateForm'

import {
  KeyResultFragment,
  KeyResultUpdateType,
  ProgressRateAndConfidenceInput,
  ProgressRateInput,
  useKeyResultQuery,
  useUpdateKeyResultGoalSettingMutation,
  useUpdateKeyResultProgressRateAndConfidenceMutation,
} from './graphql'
import { useModalHeaderStyles, useStyles } from './index.styles'

export type Props = {
  withGeishaModal?: boolean
  keyResult: KeyResultFragment
}

export const KeyResultQuickUpdateModalContainer: React.VFC<{
  withGeishaModal?: boolean
  keyResultId: string
  onClickCancel: (event: MouseEvent<HTMLButtonElement>) => void
  onClickExecute: (event: MouseEvent<HTMLButtonElement>) => void
}> = ({ withGeishaModal, keyResultId, onClickCancel, onClickExecute }) => {
  const { data, called, loading } = useKeyResultQuery({ variables: { id: keyResultId } })
  const user = useCurrentUser()
  const updateUser = useSetCurrentUser()

  const refetchQueries = [...useActivityRefetchQueries(), ...useOkrRefetchQueries()]

  const [
    updateKeyResultProgressRateAndConfidenceMutation,
    { loading: isRunningProgressRateAndConfidence },
  ] = useUpdateKeyResultProgressRateAndConfidenceMutation({ refetchQueries })

  const [updateKeyResultGoalSettingMutation, { loading: isRunningGoadSetting }] =
    useUpdateKeyResultGoalSettingMutation({
      refetchQueries,
    })

  const [showCancelModal, setShowCancelModal] = useState<boolean>(false)

  const [addOkrFloatDrawerItems, setAddOkrFloatDrawerItems] = useState<
    ReadonlyArray<OkrFloatDrawerListItem>
  >([])

  const execute = async (
    event: MouseEvent<HTMLButtonElement>,
    input: ProgressRateAndConfidenceInput,
  ) => {
    if (!data?.keyResult) return

    await updateKeyResultProgressRateAndConfidenceMutation({
      variables: {
        id: keyResultId,
        input: {
          ...input,
          actualValue: Number(input.actualValue),
        },
      },
    })

    if (user && !user.checkinKeyResultsState.isBulkUpdated) {
      updateUser({
        ...user,
        checkinKeyResultsState: { ...user.checkinKeyResultsState, isBulkUpdated: true },
      })
    }

    onClickExecute(event)
  }

  const onUpdateGoalSetting = async (input: FormValue) => {
    if (!data?.keyResult) return
    await updateKeyResultGoalSettingMutation({
      variables: {
        id: keyResultId,
        input: {
          targetValue: input.targetValue,
          initialValue: input.initialValue,
          unit: input.unit,
          message: input.message,
          screen: KeyResultEventScreen.Quick,
        },
      },
    })
  }

  const onOpenOkrFloatDrawer = () => {
    if (!data?.keyResult) return

    setAddOkrFloatDrawerItems([
      {
        objectiveId: data.keyResult.objective.id,
        selectedKeyResultIds: [data.keyResult.id],
      },
    ])
  }

  if (!called || loading || !data?.keyResult) return null

  return (
    <Fragment>
      <div // eslint-disable-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
        // fixme: map(β)側にイベントが伝わってしまうと、クリックでフォーカスが外れるため対応
        onClick={(e) => e.stopPropagation()}
        // fixme: map(β)側にイベントが伝わってしまうと、enterで改行ができなくなるため対応
        onKeyDown={(e) => e.stopPropagation()}
      >
        <KeyResultQuickUpdateModal
          withGeishaModal={withGeishaModal}
          keyResult={data.keyResult}
          modalProps={{
            isRunning: isRunningProgressRateAndConfidence || isRunningGoadSetting,
            onClickCancel: () => setShowCancelModal(true),
          }}
          keyResultDescriptionProgressRateFormSettingProps={{
            buttonProps: {
              isLoading: isRunningGoadSetting,
            },
          }}
          onClickExecute={async (event, input) => {
            await execute(event, input)
          }}
          onUpdateGoalSetting={onUpdateGoalSetting}
          onOpenOkrFloatDrawer={onOpenOkrFloatDrawer}
        />
      </div>
      {showCancelModal && (
        <KeyResultQuickUpdateModalCancelModal
          withGeishaModal={withGeishaModal}
          onClickCancel={(e) => {
            e.stopPropagation()
            setShowCancelModal(false)
          }}
          onClickExecute={(e) => {
            setShowCancelModal(false)
            onClickCancel(e)
          }}
        />
      )}
      <OkrFloatDrawerList
        addItems={addOkrFloatDrawerItems}
        baseZIndex={withGeishaModal ? 120 : 20}
        isParentRoot
      />
    </Fragment>
  )
}

KeyResultQuickUpdateModalContainer.displayName = 'KeyResultQuickUpdateModalContainer'

export type QuickModalProps = Props & {
  modalProps: Pick<ModalProps, 'isRunning' | 'onClickCancel'>
  keyResultDescriptionProgressRateFormSettingProps: KeyResultDescriptionProgressRateFormProps['keyResultDescriptionProgressRateFormSettingProps']
  onClickExecute: (
    event: MouseEvent<HTMLButtonElement>,
    input: ProgressRateAndConfidenceInput,
  ) => void
  onUpdateGoalSetting: (input: FormValue) => void
  onOpenOkrFloatDrawer: () => void
}

export const KeyResultQuickUpdateModal: React.VFC<QuickModalProps> = ({
  withGeishaModal,
  keyResult,
  modalProps,
  keyResultDescriptionProgressRateFormSettingProps,
  onClickExecute,
  onUpdateGoalSetting,
}) => {
  const { t } = useTranslation()
  const styles = useStyles()
  const user = useCurrentUser()

  const top: number = (window.innerHeight - 440) / 2

  const [isEditingInitialSetting, setIsEditingInitialSetting] = useState<boolean>(false)

  const updateMemoRef = useRef<HTMLTextAreaElement>(null)
  const isGoalSetting = useMemo(() => isNotFilledRequiredTargetValue(keyResult), [keyResult])

  // updateTypeがManual or 自動吸い上げがONの場合は、％入力を表示する
  const isManualForm =
    keyResult.updateType === KeyResultUpdateType.Manual || keyResult.isAutoAggregate

  const initialValue = isManualForm ? 0 : keyResult.initialValue ?? 0
  const targetValue = isManualForm ? 100 : keyResult.targetValue ?? 0
  const unit = isManualForm ? t('PERCENTAGE') : keyResult.unit

  const [confidence, setConfidence] = useState<Level>(
    numberToConfidence(
      (user && keyResult.confidences.find((val) => val.user.id === user.id)?.level) || 0,
    ),
  )

  const onChangeConfidence = (level: Level) => {
    setConfidence(level)
  }

  const {
    register,
    getValues,
    setValue,
    formState: { errors, isValid },
  } = useForm<Pick<ProgressRateInput, 'actualValue'>>({
    mode: 'onChange',
    defaultValues: { actualValue: isManualForm ? keyResult.progressRate : keyResult.actualValue },
    resolver: yupResolver(
      Yup.object().shape({
        actualValue: Yup.number().typeError(t('ONLY_NUMBER')),
      }),
    ),
  })
  const { ref: inputRef, ...field } = register('actualValue')

  const handleGoalSetting = useCallback(
    (value: FormValue) => {
      onUpdateGoalSetting(value)

      // 目標設定時に初期値を変更する場合、実績値も初期値と同じ値に変更する
      setValue('actualValue', value.initialValue)
    },
    [setValue, onUpdateGoalSetting],
  )

  const canUpdateConfidence: boolean = user ? isIncludedUser(keyResult, user) : false

  const disabledExecute = (isGoalSetting && isEditingInitialSetting) || !isValid

  if (!user) return null

  return (
    <Modal
      isOpened
      isRunning={modalProps.isRunning}
      withGeishaModal={withGeishaModal}
      title={<ModalHeader keyResult={keyResult} />}
      onClickCancel={modalProps.onClickCancel}
      onClickExecute={(e) => {
        onClickExecute(e, {
          actualValue: getValues().actualValue,
          confidenceLevel: canUpdateConfidence ? confidence : undefined,
          message: updateMemoRef.current?.value,
          screen: KeyResultEventScreen.Quick,
        })
      }}
      executeText={t('UPDATION')}
      disabledExecute={disabledExecute}
      autoWidth
      css={{ width: '600px' }}
      position="top"
      animation="fadeIn"
      margin={{ top: `${top}px` }}
      dataTestIdFeatureName={featureNames.quickUpdateModal}
    >
      <Fragment>
        <div css={styles.modalWrapper}>
          <div>
            <Title> {t('PROGRESS_RATE')} </Title>

            <div css={styles.progressRate.root}>
              <div css={styles.progressRate.currentArea.root}>
                <SubTitle>{t('CURRENT_PROGRESS_RATE')}</SubTitle>
                <div css={styles.progressRate.currentArea.progressRateBar.root}>
                  <KeyResultProgressRateBar
                    // progressRateTrend を渡すとモーダル上のKRの進捗率の下にもトレンド表記が出現するので undefined を渡す
                    keyResult={{ ...keyResult, progressRateTrend: undefined }}
                  />
                  {keyResult.progressRateUpdatedAt && (
                    <div css={styles.progressRate.currentArea.progressRateBar.updatedAt.root}>
                      <StyledText
                        size="xsmall"
                        lineHeight="10px"
                        color="text-bk-30"
                        css={styles.progressRate.currentArea.progressRateBar.updatedAt.text}
                      >
                        {t('UPDATION')}
                        {formatDateInput(keyResult.progressRateUpdatedAt, 'YYYY/MM/DD HH:mm')}
                      </StyledText>
                    </div>
                  )}
                </div>
              </div>
              <div>
                <SubTitle>{t('INPUT_X', { x: t('PROGRESS_RATE') })}</SubTitle>
                {isGoalSetting ? (
                  <div css={styles.progressRate.inputArea.targetArea.root}>
                    <KeyResultDescriptionProgressRateForm
                      updateType={keyResult.updateType}
                      progressRateInput={{ actualValue: keyResult.progressRate }}
                      updateProgressRate={handleGoalSetting}
                      onChangeIsEditing={setIsEditingInitialSetting}
                      data-testid-feature-name={featureNames.quickUpdateModal}
                      keyResultDescriptionProgressRateFormSettingProps={
                        keyResultDescriptionProgressRateFormSettingProps
                      }
                    />
                  </div>
                ) : (
                  <div css={styles.progressRate.inputArea.progressRateArea.root}>
                    <QuickUpdateKeyResultViewProgressRateForm
                      {...field}
                      inputRef={inputRef}
                      errors={errors}
                      initialValue={initialValue}
                      targetValue={targetValue}
                      unit={unit}
                      size="small"
                      css={styles.progressRate.inputArea.progressRateArea.form}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          <div>
            <div css={styles.confidence.currentArea.root}>
              <Title>{t('CONFIDENCE')}</Title>
              <PopoverPortal
                icon="help"
                title={t('CONFIDENCE')}
                text={t('CONFIDENCE_DESCRIPTION')}
                link={{
                  text: t('HELP_SITE'),
                  href: ExternalUrls.CONFIDENCE_GUIDE,
                }}
                margin={{ top: '8px', left: '8px' }}
                portalPosition={{
                  top: 17,
                  left: -45,
                }}
              />
            </div>

            <div css={styles.confidence.selectArea.root}>
              <div css={styles.confidence.selectArea.titleArea.root}>
                <SubTitle>
                  <WrapText text={t('KR_OWNER_CONFIDENCE')} maxWidth={124} />
                </SubTitle>
                <div css={styles.confidence.selectArea.titleArea.ownerConfidence}>
                  <OwnerConfidenceTag size="medium" keyResult={keyResult} weight="bold" />
                </div>
              </div>
              <div>
                <SubTitle>{t('YOUR_X', { x: t('CONFIDENCE') })}</SubTitle>
                <div css={styles.confidence.selectArea.yourSelect.root}>
                  {canUpdateConfidence ? (
                    <div css={styles.confidence.selectArea.yourSelect.selectWrapper}>
                      <ConfidenceList
                        confidence={confidence}
                        onChangeConfidence={onChangeConfidence}
                      />
                    </div>
                  ) : (
                    <StyledText color="text-bk-30" size="small" lineHeight="20px">
                      {t('CONFIDENCE_INPUT_MESSAGE')}
                    </StyledText>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div css={styles.updateMemo.root}>
            <div css={styles.updateMemo.title}>
              <Title>{t('UPDATE_MEMO')}</Title>
            </div>
            <div css={styles.updateMemo.input}>
              <AutoResizeTextArea
                ref={updateMemoRef}
                data-testid={generateTestId(
                  featureNames.quickUpdateModal,
                  componentNames.updateMemoInput,
                )}
                placeholder={t('UPDATE_MEMOS')}
              />
            </div>
          </div>
        </div>
      </Fragment>
    </Modal>
  )
}

KeyResultQuickUpdateModal.displayName = 'KeyResultQuickUpdateModal'

const ModalHeader: React.VFC<Props> = ({ keyResult }) => {
  const styles = useModalHeaderStyles()
  return (
    <div css={styles.root}>
      <div css={styles.avatar}>
        <KeyResultAvatarWithContributor
          size="small"
          keyResult={keyResult}
          owner={keyResult.owner}
          contributors={keyResult.members}
          termId={keyResult.node.term.id}
          avatarGap={3.33}
        />
      </div>

      <div css={styles.border} />

      <StyledText css={styles.name}>
        <KeyResultTargetSettingIcon targetSetting={keyResult.targetSetting} />
        {keyResult.name}
      </StyledText>
    </div>
  )
}

ModalHeader.displayName = 'ModalHeader'

const Title: React.VFC<{ children: React.ReactNode }> = ({ children }) => (
  <StyledText size="small" lineHeight="20px" weight="bold">
    {children}
  </StyledText>
)

Title.displayName = 'Title'

const SubTitle: React.VFC<{ children: React.ReactNode }> = ({ children }) => (
  <StyledText size="xsmall" weight="bold" color="text-bk-50" lineHeight="1">
    {children}
  </StyledText>
)

SubTitle.displayName = 'SubTitle'
