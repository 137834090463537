import { css } from '@emotion/react'
import { Box } from 'grommet'
import React, { VFC } from 'react'

import { RichTextEditor } from '../../components/ui/RichTextEditor'
import { StyledText } from '../../components/ui/StyledText'
import { useTranslation } from '../../i18n'
import { color } from '../../styles/newColors'

type LocationAndUrlSpaceProps = {
  editorId: string
  description: {
    json: string
    plainText: string
  }
}

const editorCss = css({
  margin: '0px',
  p: {
    marginBottom: '0',
  },
  '*[class$="LinkElement"]': {
    color: color('resily-orange-100'),
    ':hover, :visited:hover': {
      color: color('resily-orange-100'),
    },
  },
})

const spaceCss = css({
  width: '316px',
  marginBottom: '24px',
  boxShadow: '0px 0px 8px rgb(0 0 0 / 10%)',
  borderRadius: '8px',
  background: color('white-100'),
  padding: '20px',
})

const noDescriptionCss = css({
  fontSize: '14px',
  color: color('text-bk-30'),
  marginTop: '8px',
})

const editorProps = {
  readOnly: true,
}

export const LocationAndUrlSpace: VFC<LocationAndUrlSpaceProps> = ({ editorId, description }) => {
  const { t } = useTranslation()

  return (
    <Box direction="column" css={spaceCss}>
      <StyledText weight="bold">{t('LOCATION_AND_URL')}</StyledText>
      {description.plainText ? (
        <RichTextEditor
          id={editorId}
          initialValueJSON={description.json}
          css={editorCss}
          editorProps={editorProps}
          autoFocus={false}
        />
      ) : (
        <StyledText css={noDescriptionCss}>{t('NO_DESCRIPTION')}</StyledText>
      )}
    </Box>
  )
}
