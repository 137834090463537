import {
  HasChildObjectiveProgressRateKeyResultFragment,
  HasChildObjectiveKeyResultFragment,
  IsIncludedUserUserFragment,
  IsIncludedUserKeyResultFragment,
  KeyResultUpdateType,
  CreateKeyResultInput,
  KeyResult,
  KeyResultEventItem,
} from './graphql'

// KRの責任者 or メンバーに含まれいているか否か
export const isIncludedUser = (
  keyResult: IsIncludedUserKeyResultFragment,
  user: IsIncludedUserUserFragment | null,
  includeDisabled = false,
): boolean => {
  if (includeDisabled) {
    return (
      keyResult.isDisabled === false &&
      (keyResult.members.some((m) => m.id === user?.id) || keyResult.owner.id === user?.id)
    )
  }
  return keyResult.members.some((m) => m.id === user?.id) || keyResult.owner.id === user?.id
}

// 複数KR内の責任者 or メンバーに含まれいているか否か
export const isIncludedUserInKeyResults = (
  keyResults: ReadonlyArray<IsIncludedUserKeyResultFragment> | null | undefined,
  user: IsIncludedUserUserFragment | null,
  includeDisabled = false,
): boolean =>
  keyResults
    ? keyResults.length !== 0 &&
      keyResults.some((keyResult) => isIncludedUser(keyResult, user, includeDisabled))
    : false

export const DEFAULT_UNIT_VALUE = '0'
export const isSettingUnit = (keyResult: Partial<Pick<KeyResult, 'unit'>>): boolean =>
  Boolean(keyResult.unit && keyResult.unit !== DEFAULT_UNIT_VALUE)

export const isSettingInitialValue = (
  keyResult: Partial<Pick<KeyResult, 'initialValue'>>,
): boolean => Boolean(keyResult.initialValue || keyResult.initialValue === 0)

export const isSettingProgressRate = (
  keyResult: Partial<Pick<KeyResult, 'progressRate'>>,
): boolean => Boolean(keyResult.progressRate || keyResult.progressRate === 0)

export const isSettingTargetValue = (keyResult: Partial<Pick<KeyResult, 'targetValue'>>): boolean =>
  Boolean(keyResult.targetValue || keyResult.targetValue === 0)

export const isSettingAutomate = (
  keyResult: Pick<KeyResult, 'updateType' | 'targetValue' | 'unit'>,
): boolean => {
  if (keyResult.updateType === KeyResultUpdateType.Manual) return false
  return isSettingUnit(keyResult) && isSettingTargetValue(keyResult)
}

export const progressRateVariables = (
  variables: Pick<CreateKeyResultInput, 'targetValue' | 'initialValue' | 'unit'>,
): null | {
  initialValue: number
  targetValue: number
  unit?: string
} => {
  // 全て未入力の場合は、進捗率の設定をしない
  if (
    !isSettingTargetValue(variables) &&
    !isSettingUnit(variables) &&
    !isSettingInitialValue({ initialValue: variables.initialValue || undefined })
  ) {
    return null
  }

  return {
    initialValue: variables.initialValue ?? 0,
    targetValue: variables.targetValue ?? 0,
    unit: variables.unit ?? undefined,
  }
}

export const isSettingManual = (keyResult: Pick<KeyResult, 'updateType'>): boolean =>
  keyResult.updateType === KeyResultUpdateType.Manual

export const hasChildObjective = (keyResult: HasChildObjectiveKeyResultFragment): boolean =>
  keyResult.childObjectives.filter(({ isDisabled }) => !isDisabled).length > 0

export const childObjectiveProgressRate = (
  keyResult: HasChildObjectiveProgressRateKeyResultFragment,
): number => {
  const childObjectives = keyResult.childObjectives.filter((o) => !o.isDisabled)
  if (!childObjectives.length) return 0
  return Math.trunc(
    childObjectives.reduce((acc, o) => acc + (o.progressRate || 0), 0) / childObjectives.length,
  )
}

export const ActivityEventItems: ReadonlyArray<KeyResultEventItem> = [
  KeyResultEventItem.ProgressRate,
  KeyResultEventItem.ProgressRateAggregation,
  KeyResultEventItem.ActualValue,
  KeyResultEventItem.TargetValue,
  KeyResultEventItem.TargetValueUnit,
  KeyResultEventItem.Confidence,
  KeyResultEventItem.InitialValue,
]

export const KeyResultLogEventItems = ActivityEventItems.filter(
  (ei) => ei !== KeyResultEventItem.Confidence,
)
