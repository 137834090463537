import { css } from '@emotion/react'
import React from 'react'

import { Icon } from '../../../components/ui/Icon'
import { TargetSettingType } from '../graphql'

type Props = {
  type: TargetSettingType
}

export const TargetSettingIcon: React.VFC<Props> = ({ type }: Props) => {
  switch (type) {
    case TargetSettingType.Moon:
      return <Icon type="moon" css={krIconCss} />
    case TargetSettingType.Roof:
      return <Icon type="roof" css={krIconCss} />
    default:
      return null
  }
}

TargetSettingIcon.displayName = 'TargetSettingIcon'

const krIconCss = css({ marginRight: '4px', verticalAlign: 'text-bottom' })
