import { useCallback, useMemo, useState } from 'react'
import * as Yup from 'yup'

import { useTranslation } from '../../../../i18n'
import { ExtendedUpdateKeyResultInfoInput } from '../../../domain/KeyResultEditFields/types'

type Value = {
  initialValue?: number | null
  targetValue?: number | null
}

type Error = {
  [key in keyof Value]?: string
}

type Return = {
  validate: (value: Value) => boolean
  error: Error
}

const VALIDATE_OPTIONS = { abortEarly: false }

export const useFormValidation = (keyResultState: ExtendedUpdateKeyResultInfoInput): Return => {
  const { t } = useTranslation()

  const schema = useMemo(
    () =>
      Yup.object().shape({
        actualValue: Yup.number().typeError(t('ONLY_NUMBER')).nullable(),
        initialValue: Yup.number().typeError(t('ONLY_NUMBER')).nullable(),
        targetValue: Yup.number().typeError(t('ONLY_NUMBER')).nullable(),
      }),
    [t],
  )

  const validate = useCallback(
    (values: Value): Error => {
      try {
        // バリデーション実行
        schema.validateSync(values, VALIDATE_OPTIONS)
      } catch (err) {
        if (Yup.ValidationError.isError(err)) {
          return parseError(err)
        }
      }
      return [] as Error
    },
    [schema],
  )

  const handleValidation = useCallback(
    (values: Value): boolean => {
      const validationError = validate(values)
      setError(validationError)

      // エラーがない場合はtrue
      return Object.values(validationError).every((error) => !error)
    },
    [validate],
  )

  const [error, setError] = useState<Error>(() => validate(keyResultState))
  return { validate: handleValidation, error }
}

const parseError = (errors: Yup.ValidationError): Error => {
  let validationError: Error = {}
  errors.inner.forEach(({ path, message }) => {
    if (!path) return
    validationError = { ...validationError, [path]: message }
  })
  return validationError as Error
}
