import { css } from '@emotion/react'
import { Box, Collapsible } from 'grommet'
import { createRef, useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  KeyResultAvatarWithContributor,
  ObjectiveAvatar,
} from '../../../components/domain/AvatarWithContributor'
import { GroupTags } from '../../../components/domain/GroupTags'
import { KeyResultName } from '../../../components/domain/KeyResultName'
import { KeyResultProgressRateBar } from '../../../components/domain/KeyResultProgressRateBar'
import { ProgressRateTooltip } from '../../../components/domain/ProgressRateTooltip'
import { OkrModalReturnType } from '../../../components/standalone/OkrModal/hooks/useOkrModal'
import { FloatDrawerTextButton } from '../../../components/ui/FloatDrawerTextButton'
import { IntegrationTag } from '../../../components/ui/IntegrationTag'
import { Link } from '../../../components/ui/Link'
import { DefaultBarWidth, ProgressRate } from '../../../components/ui/Meter'
import { StyledText } from '../../../components/ui/StyledText'
import { useCurrentUser } from '../../../contexts/UserContext'
import { useTranslation } from '../../../i18n'
import { Node } from '../../../lib/collections/node'
import { formatDateInput } from '../../../lib/date'
import { isIncludedUser as isIncludedUserKeyResult } from '../../../lib/domain/keyResult/keyResult'
import { isIncludedUser as isIncludedUserObjective } from '../../../lib/domain/objective'
import { Screen } from '../../../lib/screen'
import { tracker } from '../../../lib/tracking'
import { border } from '../../../styles/border'
import { color } from '../../../styles/newColors'
import { generateOkr } from '../../../urls'
import { useOkrCardIsFoldLocal } from '../hooks/useOkrCardIsFoldLocal'
import { OkrNode } from '../trees/types'
import { KrFoldButton } from '../ui/KrFoldButton'
import { TrendButton } from '../ui/TrendButton'

const okrCardCss = css({
  borderRadius: '16px',
  marginBottom: '17px',
  backgroundColor: color('white-100'),
  border: border('simple-30'),
})

const oRowCss = css({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  borderRadius: '16px 16px 0 0',
  ':hover': {
    backgroundColor: color('hover-background-bk-5'),
  },
  '& > *:nth-child(1)': {
    flex: '0 0 48px',
  },
  '& > *:nth-child(2)': {
    flex: '0 0 52px',
  },
  '& > *:nth-child(3)': {
    flex: '1 1 auto',
  },
  '& > *:nth-child(4)': {
    flex: `0 0 ${DefaultBarWidth}px`,
  },
  '& > *:nth-child(5)': {
    flex: '0 0 78px',
    paddingLeft: '4px',
  },
})

const krRowCss = css({
  display: 'flex',
  alignItems: 'center',
  padding: '22px 82px 22px 48px',
  borderTop: border('simple-10'),
  ':hover': {
    backgroundColor: color('hover-background-bk-5'),
  },
  '& > *:nth-child(1)': {
    flex: '0 0 52px',
  },
  '& > *:nth-child(2)': {
    flex: '1 1 auto',
    marginRight: '32px',
  },
  '& > *:nth-child(3)': {
    flex: `0 0 ${DefaultBarWidth}px`,
  },
})

export type Props = {
  termId: string
  isMoveScroll: boolean
  item: Node<OkrNode>
  onOkrFloatDrawerOpen: (objectiveId: string) => void
  openOkrModal: OkrModalReturnType['openOkrModal']
  openOkrModalWithKeyResultDrawer: OkrModalReturnType['openOkrModalWithKeyResultDrawer']
}

export const ListViewPageOkrCard: React.VFC<Props> = ({
  termId,
  item,
  isMoveScroll,
  onOkrFloatDrawerOpen,
  openOkrModal,
  openOkrModalWithKeyResultDrawer,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [isFoldOkrCardKrLocal, setIsFoldOkrCardKrLocal] = useOkrCardIsFoldLocal(item.id)

  const ref = createRef<HTMLDivElement>()
  const user = useCurrentUser()
  const existsIntegration = item.slackIntegrated || item.chatworkIntegrated || item.teamsIntegrated
  const existsGroup = item.groups.length > 0
  const isAssignedToObjective = isIncludedUserObjective(item.objective, user)

  const onClickObjectiveName = useCallback(() => {
    openOkrModal(item.objective.id, Screen.OkrMapList, isAssignedToObjective)
  }, [isAssignedToObjective, item.objective.id, openOkrModal])

  const onClickKeyResultName = useCallback(
    (keyResultId: string) => {
      const kr = item.keyResults.find((k) => k.id === keyResultId)

      openOkrModalWithKeyResultDrawer(
        item.objective.id,
        keyResultId,
        Screen.OkrMapList,
        kr ? isIncludedUserKeyResult(kr, user) : false,
      )
    },
    [item.keyResults, item.objective.id, openOkrModalWithKeyResultDrawer, user],
  )

  useEffect(() => {
    if (isMoveScroll) {
      if (!ref?.current) return
      ref.current.scrollIntoView({
        behavior: 'smooth',
      })
    }
  }, [isMoveScroll, ref])

  return (
    <article css={okrCardCss}>
      <div css={oRowCss}>
        <KrFoldButton
          isFold={isFoldOkrCardKrLocal}
          onClick={() => setIsFoldOkrCardKrLocal(!isFoldOkrCardKrLocal)}
        />

        <ObjectiveAvatar size="okr-map-list-page" termId={termId} owner={item.objective.owner} />

        <Link
          data-disabled={item.objective.isDisabled}
          onClick={onClickObjectiveName}
          css={{
            padding: '36px 0',
            fontSize: '24px',
            fontWeight: 'bold',
            lineHeight: '32px',
            width: 'calc(100% - 298px)',
            color: color('text-bk-100'),
            ':hover': {
              color: color('resily-orange-100'),
              textDecoration: 'underline',
              '&[data-disabled="true"]': {
                textDecoration: 'underline line-through',
              },
            },
            '&[data-disabled="true"]': {
              textDecoration: 'line-through',
              opacity: 0.2,
            },
          }}
        >
          {item.objective.name}
        </Link>

        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '8px',
            marginLeft: '32px',
          }}
        >
          <StyledText size="xsmall">{t('PROGRESS_RATE')}</StyledText>

          <ProgressRate
            color={color('objective-blue-100')}
            rate={item.objective?.progressRate || 0}
          />

          <span css={{ color: color('text-bk-50'), fontSize: '10px', marginTop: '-4px' }}>
            {item.objective.progressRateUpdatedAt
              ? `${formatDateInput(item.objective.progressRateUpdatedAt, 'YYYY/MM/DD')} ${t(
                  'UPDATION',
                )}`
              : '-'}
          </span>
        </div>

        <TrendButton
          progressRateTrend={item.objective.progressRateTrend}
          upConfidenceScoreNum={item.keyResults.reduce(
            (sum, kr) => sum + Number(kr.confidenceTrend > 0),
            0,
          )}
          downConfidenceScoreNum={item.keyResults.reduce(
            (sum, kr) => sum + Number(kr.confidenceTrend < 0),
            0,
          )}
          onClick={(e) => {
            e.stopPropagation()
            openOkrModal(
              item.objective.id,
              Screen.OkrMapList,
              isAssignedToObjective,
              'm-o-activity',
            )
          }}
        />
      </div>

      {item.keyResults.length > 0 ? (
        <Collapsible open={!isFoldOkrCardKrLocal}>
          <ul>
            {item.keyResults.map((keyResult) => (
              <li key={keyResult.id} css={krRowCss}>
                <KeyResultAvatarWithContributor
                  size="okr-map-list-page"
                  termId={termId}
                  owner={keyResult.owner}
                  contributors={keyResult.members}
                  keyResult={keyResult}
                />
                <KeyResultName
                  keyResult={keyResult}
                  fontSize="large"
                  onClick={() => onClickKeyResultName(keyResult.id)}
                />
                <ProgressRateTooltip keyResult={keyResult}>
                  <KeyResultProgressRateBar
                    // confidenceTrend を渡すとリスト上のKRの進捗率の下にもトレンド表記が出現するので undefined を渡す
                    keyResult={{ ...keyResult, confidenceTrend: undefined }}
                  />
                </ProgressRateTooltip>
              </li>
            ))}
          </ul>
        </Collapsible>
      ) : null}

      {existsGroup && (
        <div
          css={{
            borderTop: border('simple-10'),
            padding: '8px 69px 9px 48px',
            height: '38px',
          }}
        >
          <GroupTags groups={item.groups} />
        </div>
      )}

      <div
        css={{
          borderTop: border('simple-10'),
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingRight: 24,
        }}
      >
        <Box direction="row">
          <button
            type="button"
            css={{
              borderRight: border('simple-10'),
              color: color('text-bk-50'),
              height: '34px',
              paddingLeft: '47px',
              paddingRight: '16px',
              ':hover': { color: color('resily-orange-100') },
            }}
            onClick={() => {
              tracker.OkrMapClickOkrPage(
                Screen.OkrMapList,
                isIncludedUserObjective(item.objective, user),
                item.objective.useWeighting,
              )
              navigate(generateOkr(item.id))
            }}
          >
            <StyledText>{t('X_PAGE', { x: t('OKR') })}</StyledText>
          </button>
          <FloatDrawerTextButton
            showIcon={false}
            onClick={() => onOkrFloatDrawerOpen(item.objective.id)}
            css={{ color: color('text-bk-50'), padding: '0 17px' }}
            isShorterLabel
          />
        </Box>
        {existsIntegration && (
          <Box direction="row" gap="8px" height="20px">
            {item.slackIntegrated && <IntegrationTag service="slack" />}
            {item.chatworkIntegrated && <IntegrationTag service="chatwork" />}
            {item.teamsIntegrated && <IntegrationTag service="teams" />}
          </Box>
        )}
      </div>
    </article>
  )
}

ListViewPageOkrCard.displayName = 'ListViewPageOkrCard'
