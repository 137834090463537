import { useContext } from 'react'

import { OkrTermIdContext } from '../../../../../../../contexts/OkrTermIdContext'
import { useOkrNodesLazyQuery } from '../../../../../../OkrMap/graphql'
import { KeyResultWeightSetting } from '../KeyResultWeightSetting'

import {
  KeyResultsWeightInput,
  useOkrNodeQuery,
  useUpdateKeyResultsWeightMutation,
} from './graphql'

type Props = {
  objectiveId: string
}

export const ObjectiveWeightSettingContainer: React.VFC<Props> = ({ objectiveId }) => {
  const { okrTermId } = useContext(OkrTermIdContext)
  const [okrNodesQuery] = useOkrNodesLazyQuery()

  const okrNode = useOkrNodeQuery({ variables: { objectiveId } })
  const [updateKeyResultsWeight] = useUpdateKeyResultsWeightMutation()

  const onUpdate = async (keyResults: Array<KeyResultsWeightInput>) => {
    await updateKeyResultsWeight({
      variables: {
        objectiveId: okrNode.data?.okrNode.objective.id || '',
        inputs: keyResults.map((kr) => ({ id: kr.id, weight: kr.weight })),
      },
    })

    if (okrTermId) {
      okrNodesQuery({ variables: { okrTermId } })
    }
  }

  if (!okrNode.data) return null

  return (
    <KeyResultWeightSetting
      useWeighting={okrNode.data.okrNode.objective.useWeighting}
      keyResults={okrNode.data.okrNode.keyResults.filter((kr) => !kr.isDisabled)}
      onUpdate={onUpdate}
    />
  )
}

ObjectiveWeightSettingContainer.displayName = 'ObjectiveWeightSettingContainer'
