import { Box, BoxProps } from 'grommet'
import React, { forwardRef } from 'react'

import { Icon, Type as IconType } from '../../../../components/ui/Icon'

import * as styles from './styles'

export type Props = {
  icon: IconType
  title: string
  description: string
  onClick: BoxProps['onClick']
}

export const MenuBox = forwardRef<HTMLDivElement, Props>(
  ({ icon, title, description, onClick }: Props, ref: React.Ref<HTMLDivElement>) => (
    <Box ref={ref} onClick={onClick} css={styles.rootCss}>
      <div css={styles.contentsWrapperCss}>
        <Icon type={icon} css={styles.iconCss} />
        <p css={styles.titleCss}>{title}</p>
        <p css={styles.descriptionCss}>{description}</p>
      </div>
    </Box>
  ),
)
