import { i18n as i18next } from 'i18next'

import { OkrGlossary, CustomOkrGlossary } from '../graphql'

import { Langs } from './language'
import en from './locales/en.json'
import ja from './locales/ja.json'
import { TKeys, OkrGlossaryI18nKey } from './type'

import { UpdateGlossary, updateResoruce } from '.'

/** graphqlで定義されているOkrGlossaryからi18nのkeyを返却 */
export const okrGlossaryKey: { [glossary in OkrGlossary]: OkrGlossaryI18nKey } = {
  OBJECTIVE: 'OBJECTIVE',
  KEY_RESULT: 'KEY_RESULT',
  KR: 'KR',
  OKR: 'OKR',
  OKR_MEETING: 'OKR_MEETING',
  WIN_SESSIONS: 'WIN_SESSION',
  ACTION_PLAN: 'ACTION_PLAN',
  CHALLENGES_AND_OBSTACLES: 'PROBLEM',
  CONFIDENCE: 'CONFIDENCE',
  CONTRIBUTORS: 'CONTRIBUTOR',
  ONE_ON_ONE: 'ONEONONE',
  OTHER: 'OTHER',
  PRIORITY_ITEM: 'PRIORITY',
  ASSIGNEE: 'OWNER',
  CHECK_IN: 'CHECKIN',
}

/** 言語ごとのデフォルトのOKR用語を返却 */
export const defaultOkrGlossary = (key: OkrGlossaryI18nKey, lang: Langs): string => {
  switch (lang) {
    case 'ja':
      return ja[key]
    case 'en':
      return en[key]
    default:
      return ''
  }
}

/** i18nのOKR用語の設定を更新する */
export const updateOkrGlossaryResource = <
  T extends Pick<CustomOkrGlossary, 'okrGlossary' | 'customTextJa' | 'customTextEn'>,
>(
  i18n: i18next,
  glossaries: ReadonlyArray<T>,
): void => {
  const newGlossaries: UpdateGlossary<TKeys> = glossaries.reduce((pre, v) => {
    if (!v.customTextJa || !v.customTextEn) return pre
    return {
      ...pre,
      [okrGlossaryKey[v.okrGlossary as OkrGlossary]]:
        i18n.language === 'ja' ? v.customTextJa : v.customTextEn,
    }
  }, {} as UpdateGlossary<TKeys>)
  updateResoruce(i18n.language as Langs, newGlossaries)
}
