import { FieldError } from 'react-hook-form'

import * as colors from '../../styles/colors'

type Props = {
  fieldError?: FieldError
}

export const AccountSetupErrorMessage: React.VFC<Props> = ({ fieldError }) => (
  <div
    style={{
      opacity: fieldError ? 1 : 0,
    }}
    css={{
      color: colors.labelRed,
      fontSize: '14px',
      lineHeight: '20px',
      margin: '2px 0',
    }}
  >{`* ${fieldError?.message}`}</div>
)

AccountSetupErrorMessage.displayName = 'AccountSetupErrorMessage'
