import { css } from '@emotion/react'
import { BoxProps, Button, Box } from 'grommet'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { useCurrentUser } from '../../../contexts/UserContext'
import { useTranslation } from '../../../i18n'
import { fail } from '../../../lib/common'
import { formatDateInput } from '../../../lib/date'
import { border } from '../../../styles/border'
import { color } from '../../../styles/newColors'
import {
  generatePersonalNote,
  generatePersonalNoteEdit,
  generateUser,
  generateHome,
} from '../../../urls'
import { DeleteNoteModal } from '../../ui/DeleteNoteModal'
import { Icon } from '../../ui/Icon'
import { Link } from '../../ui/Link'
import { ListCard } from '../../ui/ListCard'
import { StyledText } from '../../ui/StyledText'
import { TextButton } from '../../ui/TextButton'
import { NoteThreePoints } from '../NoteThreePoints'

import { PersonalNoteFragment, NotePermission } from './graphql'

export type Props = BoxProps & {
  userId?: string
  termId?: string
  personalNotes: ReadonlyArray<PersonalNoteFragment>
  createPersonalNote?: () => void
  deletePersonalNote: (noteId: string) => void
}

export const PersonalNotesCard: React.FC<Props> = ({
  userId,
  termId,
  personalNotes,
  createPersonalNote,
  deletePersonalNote,
}) => {
  const { t } = useTranslation()
  const user = useCurrentUser()
  const isOwnCard = !!user && user.id === userId

  return (
    <ListCard
      header={
        <Box direction="row" align="center" justify="between" css={{ marginBottom: '37px' }}>
          <StyledText
            size="xxlarge"
            css={{
              borderLeft: `4px solid ${color('border-bk-10')}`,
              paddingLeft: 12,
              paddingBottom: 4,
            }}
          >
            {t('USER') + t('NOTES')}
          </StyledText>
          {isOwnCard && createPersonalNote && personalNotes.length > 0 ? (
            <Box direction="row" align="center">
              <TextButton
                icon="plus"
                color="text-bk-50"
                onClick={createPersonalNote}
                css={{ paddingTop: '8px', paddingBottom: '8px' }}
              >
                {t('CREATE_NOTE')}
              </TextButton>
            </Box>
          ) : null}
        </Box>
      }
      table={{
        headers: [
          {
            title: t('NOTE_NAME'),
          },
          {
            title: t('OPEN_RANGE'),
            minWidth: 82,
            css: {
              marginRight: 45,
            },
          },
          {
            title: t('CREATED_ON'),
            minWidth: 75,
            css: {
              marginRight: 32,
            },
          },
          {
            title: t('UPDATED_ON'),
            minWidth: 75,
            css: {
              marginRight: 32,
            },
          },
          {
            title: t('NOTE_AUTHOR'),
            minWidth: 114,
            css: {
              marginRight: 22,
            },
          },
          {
            title: '',
            minWidth: 32,
            css: {
              marginRight: 14,
            },
          },
        ],
        data: {
          rows: [
            {
              key: 'note-list',
              cells: [
                {
                  key: 'note-list-cell',
                  cell:
                    personalNotes.length === 0 ? (
                      <NoNoteList
                        allowCreateNote={isOwnCard}
                        createPersonalNote={createPersonalNote}
                      />
                    ) : (
                      <NoteList
                        termId={termId}
                        personalNotes={personalNotes
                          .concat()
                          .sort((a, b) => (a.updatedAt < b.updatedAt ? 1 : -1))}
                        deletePersonalNote={deletePersonalNote}
                      />
                    ),
                },
              ],
            },
          ],
          css: {
            overflow: 'auto',
            ':hover': {
              background: color('hover-background-bk-5'),
            },
          },
        },
      }}
    />
  )
}

PersonalNotesCard.displayName = 'PersonalNotesCard'

type NoteListProps = BoxProps & {
  termId?: string
  personalNotes: ReadonlyArray<PersonalNoteFragment>
  deletePersonalNote: (noteId: string) => void
}

const cssTitle = css({
  display: 'flex',
  width: '100%',
  flex: 'auto',
  marginRight: '16px',
  alignItems: 'center',
})

const cssDate = css({
  flex: '0 0 75px',
  minWidth: 75,
  alignItems: 'end',
  marginRight: '32px',
})

const cssPermission = css({
  flex: '0 0 82px',
  minWidth: 82,
  alignItems: 'end',
  marginRight: '45px',
})

const cssAuthor = css({
  flex: '0 0 114px',
  minWidth: 114,
  maxWidth: 114,
  alignItems: 'end',
  marginRight: '22px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
})

const cssThreePoints = css({
  flex: '0 0 32px',
  minWidth: 32,
  alignItems: 'end',
  marginRight: '14px',
})

const NoteList: React.FC<NoteListProps> = ({ termId, personalNotes, deletePersonalNote }) => (
  <section
    css={{
      backgroundColor: color('white-100'),
      cursor: 'auto',
    }}
  >
    <div
      css={{
        overflow: 'auto',
        height: '338px',
        borderBottom: border('simple-10'),
      }}
    >
      {personalNotes.map((note) => (
        <NoteListItem
          key={note.id}
          termId={termId}
          note={note}
          deletePersonalNote={deletePersonalNote}
        />
      ))}
    </div>
  </section>
)

NoteList.displayName = 'NoteList'

type NoteListItemProps = {
  termId?: string
  note: PersonalNoteFragment
  deletePersonalNote: (noteId: string) => void
}

export const NoteListItem: React.FC<NoteListItemProps> = ({ termId, note, deletePersonalNote }) => {
  const navigate = useNavigate()
  const [isOpened, setIsOpend] = React.useState(false)
  const { t } = useTranslation()

  const permissionIcon = () => {
    switch (note.permission) {
      case NotePermission.OwnerOnly:
        return 'lock'
      case NotePermission.Private:
        return 'group'
      case NotePermission.Public:
        return 'mdiPublic'
      default:
        return fail(note.permission)
    }
  }

  return (
    <div
      key={note.id}
      css={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        padding: '8px 0px',
        borderBottom: border('simple-10'),
        ':hover': {
          backgroundColor: color('hover-background-bk-5'),
        },
      }}
    >
      <div css={{ display: 'flex', justifyContent: 'space-between', ...cssTitle }}>
        <div
          css={{
            padding: '8px 0 8px 16px',
          }}
        >
          <Link
            href={generatePersonalNote(note.id)}
            css={{
              cursor: 'pointer',
              ':hover': {
                color: color('resily-orange-100'),
                textDecorationLine: 'underline',
              },
            }}
          >
            <StyledText isWrap>{note.title}</StyledText>
          </Link>
        </div>
      </div>
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <div css={{ display: 'flex', ...cssPermission }}>
          <Icon type={permissionIcon()} color={color('text-bk-50')} css={{ marginTop: 2 }} />
          <StyledText css={{ marginLeft: 6 }}>{t(note.permission)}</StyledText>
        </div>
        <div css={cssDate}>
          <StyledText>{formatDateInput(note.createdAt)}</StyledText>
        </div>
        <div css={cssDate}>
          <StyledText>{formatDateInput(note.updatedAt)}</StyledText>
        </div>
        <div css={cssAuthor}>
          <Link
            href={termId ? generateHome(termId)(note.author.id) : generateUser(note.author.id)}
            css={{
              cursor: 'pointer',
              ':hover': {
                color: color('resily-orange-100'),
                textDecorationLine: 'underline',
              },
            }}
          >
            <StyledText>
              {t('FULL_NAME', { firstName: note.author.firstName, lastName: note.author.lastName })}
            </StyledText>
          </Link>
        </div>
        <div css={cssThreePoints}>
          <NoteThreePoints
            selectMenu={(menu) => {
              if (menu === 'edit') {
                navigate(generatePersonalNoteEdit(note.id))
                return
              }
              if (menu === 'delete') {
                setIsOpend(true)
                return
              }
              fail(menu)
            }}
          />
          <DeleteNoteModal
            isOpened={isOpened}
            noteType="PERSONAL_NOTE"
            noteTitle={note.title}
            onClickDelete={() => {
              deletePersonalNote(note.id)
              setIsOpend(false)
            }}
            onClickCancel={() => setIsOpend(false)}
            onClickOutside={() => setIsOpend(false)}
          />
        </div>
      </div>
    </div>
  )
}

NoteListItem.displayName = 'NoteListItem'

type NotDataProps = {
  allowCreateNote: boolean
  createPersonalNote?: () => void
}

const NoNoteList: React.FC<NotDataProps> = ({ allowCreateNote, createPersonalNote }) => {
  const { t } = useTranslation()
  return (
    <Box align="center" css={{ marginTop: '54px', marginBottom: '64px' }}>
      <StyledText size="large" css={{ marginBottom: '20px' }}>
        {t('X_NOTE_IS_EMPTY', { x: t('USER') })}
      </StyledText>
      {allowCreateNote && createPersonalNote && (
        <Button
          css={{
            border: border('simple-30'),
            borderRadius: '4px',
            padding: '10px 16px',
          }}
          onClick={createPersonalNote}
        >
          <StyledText>{t('CREATE_OF_X', { x: t('NOTE') })}</StyledText>
        </Button>
      )}
    </Box>
  )
}

NoNoteList.displayName = 'NoNoteList'
