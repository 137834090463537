import dayjs from 'dayjs'
import React, { useCallback, useMemo } from 'react'
import { InView } from 'react-intersection-observer'
import { useParams } from 'react-router'

import { PageContent } from '../../components/pageContent'
import { StyledText } from '../../components/ui/StyledText'
import { useTranslation } from '../../i18n'
import { Screen } from '../../lib/screen'
import { useTracking } from '../../lib/tracking'
import { color } from '../../styles/newColors'

import { MeetingHistoryCard } from './MeetingHistoryCard'
import { PAGING_COUNT, VARIABLES } from './constants'
import { useOneOnOneMeetingsQuery } from './graphql'
import { oneOnOneHistoryContainerCss, partnerUserCss } from './styles'

export const OneOnOneHistoryContainer: React.FC = () => {
  const now = useMemo<Date>(() => dayjs().startOf('minute').toDate(), [])
  const { oneOnOneId = '' } = useParams()
  const { t } = useTranslation()
  useTracking(t('ONEONONE_HISTORY'), Screen.OneOnOneHistory)

  const { data: oneOnOneMeetings, fetchMore } = useOneOnOneMeetingsQuery({
    variables: {
      userOneOnOneId: oneOnOneId,
      now,
      ...VARIABLES,
    },
    fetchPolicy: 'network-only',
  })

  const handleReachEndMeetings = useCallback(() => {
    const endCursor = oneOnOneMeetings?.meetings.meetings.pageInfo.endCursor
    const hasNextPage = oneOnOneMeetings?.meetings.meetings.pageInfo.hasNextPage
    if (!endCursor || !hasNextPage) {
      return
    }

    fetchMore({
      variables: {
        first: PAGING_COUNT,
        after: endCursor,
        last: null,
        before: null,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResult
        }

        const previousEdges = previousResult.meetings.meetings.edges
        const fetchMoreEdges = fetchMoreResult.meetings.meetings.edges

        return {
          ...fetchMoreResult,
          meetings: {
            ...fetchMoreResult.meetings,
            meetings: {
              ...fetchMoreResult.meetings.meetings,
              edges: [...previousEdges, ...fetchMoreEdges],
            },
          },
        }
      },
    })
  }, [fetchMore, oneOnOneMeetings?.meetings?.meetings.pageInfo])

  return (
    <PageContent breadcrumbs={undefined} contentBackgroundColor={color('background-bk-5')}>
      <div css={oneOnOneHistoryContainerCss}>
        <StyledText weight="bold" size="xlarge" css={partnerUserCss}>
          {t('X_WITH_Y', {
            x: t('ONEONONE'),
            y: t('COMPELLATION', {
              fullName: t('FULL_NAME', {
                firstName: oneOnOneMeetings?.meetings.partnerUser.firstName as string,
                lastName: oneOnOneMeetings?.meetings.partnerUser.lastName as string,
              }),
            }),
          })}
        </StyledText>
        {oneOnOneMeetings?.meetings.meetings.edges?.map((meeting) => (
          <MeetingHistoryCard
            dateOfMeeting={meeting.node.dateOfMeeting}
            oneOnOneMeetingId={meeting.node.id}
            startTime={meeting.node.startTime}
            endTime={meeting.node.endTime}
          />
        ))}
        <InView
          as="div"
          onChange={(_, entry) => {
            if (entry.isIntersecting) {
              handleReachEndMeetings()
            }
          }}
        />
      </div>
    </PageContent>
  )
}
