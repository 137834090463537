import { css } from '@emotion/react'

import { border } from '../../../../../../../styles/border'
import { color } from '../../../../../../../styles/newColors'

export const rootCss = css({
  display: 'flex',
  listStyle: 'none',
  'li:first-of-type': {
    paddingRight: '8px',
    borderRight: border('simple-10'),
  },
  'li:last-of-type': {
    paddingLeft: '8px',
  },
  'li:not(li:first-of-type,li:last-of-type)': {
    paddingRight: '8px',
    paddingLeft: '8px',
    borderRight: border('simple-10'),
  },
})

export const toggleButtonCss = css({
  fontSize: '14px',
  color: color('text-bk-50'),
  ':hover': {
    cursor: 'pointer',
  },
  ':has(input:checked)': {
    color: color('resily-orange-100'),
  },
})
