import { useCallback, useMemo } from 'react'

import { useTranslation } from '../../../../../i18n'
import { findScreen } from '../../../../../lib/screen'
import { tracker } from '../../../../../lib/tracking'
import { generateNoteEdit } from '../../../../../urls'
import { NotesWithCreate } from '../../Common/NotesWithCreate'
import { useOkrModalQueryParams } from '../../hooks/useOkrModalQueryParams'

import {
  NotePermission,
  useCreateKeyResultNoteMutation,
  useFetchKeyResultNotesQuery,
} from './graphql'

export const NoteContainer: React.VFC = () => {
  const { t } = useTranslation()

  const { keyResultId } = useOkrModalQueryParams()

  const { data, loading } = useFetchKeyResultNotesQuery({
    variables: { keyResultId },
    skip: keyResultId == null,
  })

  const [createNote] = useCreateKeyResultNoteMutation()
  const handleCreateKeyResultNote = useCallback(async () => {
    tracker.UserClickCreatePersonalNoteByKeyResultDrawer(
      findScreen(window.location.pathname, window.location.search),
    )
    const result = await createNote({
      variables: {
        input: {
          title: t('EMPTY_NOTE'),
          keyResultId,
          permission: NotePermission.Public,
          attachments: [],
          labelIds: [],
          userIds: [],
        },
      },
    })
    if (!result.data) return
    window.open(generateNoteEdit(result.data.createNote.id))
  }, [createNote, keyResultId, t])

  const notes = useMemo(
    () => data?.okrNode.keyResults.find((kr) => kr.id === keyResultId)?.notes,
    [data?.okrNode.keyResults, keyResultId],
  )

  return (
    <NotesWithCreate
      notes={notes}
      loading={loading && data == null}
      onClickCreateNote={handleCreateKeyResultNote}
    />
  )
}
NoteContainer.displayName = 'NoteContainer'
