import { css } from '@emotion/react'

import { color } from '../../../styles/newColors'

type User = {
  reactedId: string
  userId: string
  lastName: string
  firstName: string
}

type Props = {
  emoji: string
  userId: string
  reactedUsers: ReadonlyArray<User>
  createReaction: (emoji: string) => void
  deleteReaction: (reactedId: string) => void
}

const getReactedId = (
  emoji: string,
  userId: string,
  reactedUsers: ReadonlyArray<User>,
): false | string | null => {
  if (reactedUsers.length === 0) return false

  const item = reactedUsers.find((user) => user.userId === userId)
  return item ? item.reactedId : null
}

export const EmojiButton: React.FC<Props> = ({
  emoji,
  userId,
  reactedUsers,
  createReaction,
  deleteReaction,
}) => {
  const reactedId = getReactedId(emoji, userId, reactedUsers)
  const onClick =
    typeof reactedId === 'string' ? () => deleteReaction(reactedId) : () => createReaction(emoji)
  const optionalCss =
    typeof reactedId === 'string'
      ? css({
          backgroundColor: color('resily-orange-5'),
          border: `1px solid ${color('resily-orange-100')}`,
        })
      : css({ border: '1px solid transparent' })
  return (
    <button
      type="button"
      css={[
        {
          borderRadius: '4px',
          margin: '2px',
          height: '32px',
          width: '34px',
          cursor: 'pointer',
          padding: 5,
          fontSize: '1.2rem',
          outline: 'none',
          '&:hover': {
            backgroundColor: color('resily-orange-5'),
          },
        },
        optionalCss,
      ]}
      onClick={onClick}
    >
      {emoji}
    </button>
  )
}

EmojiButton.displayName = 'EmojiButton'
