import { Box } from 'grommet'
import React, { useCallback, useEffect, useState } from 'react'
import { DraggableData } from 'react-rnd'

import { useTranslation } from '../../../i18n'
import { fontSize } from '../../../styles/font'
import { color, ColorAlias } from '../../../styles/newColors'
import { Avatar, sizes } from '../../ui/Avatar'
import { FloatDrawer, Position, Props as FloatDrawerProps } from '../../ui/FloatDrawer'
import { Icon } from '../../ui/Icon'
import { RichTextEditor } from '../../ui/RichTextEditor'
import { StyledText } from '../../ui/StyledText'

import {
  useOkrNodeLazyQuery,
  UserFragment,
  DocumentFragment,
  ObjectiveFragment,
  KeyResultFragment,
} from './graphql'

export type Props = Pick<FloatDrawerProps, 'show' | 'position' | 'parentClientRect' | 'zIndex'> & {
  objectiveId: string
  selectedObjectiveId?: string
  selectedKeyResultIds?: Array<string>
  onRemoved?: (objectiveId: string) => void
  onDragStart?: (data: DraggableData, objectiveId: string) => void
  onDragStop?: (data: DraggableData, objectiveId: string) => void
  onMouseEnter?: (e: MouseEvent, objectiveId: string) => void
  onMouseLeave?: (e: MouseEvent, objectiveId: string) => void
  onClick?: (e: MouseEvent, objectiveId: string) => void
  onResizeStart?: (ref: HTMLElement, objectiveId: string) => void
  onResizeStop?: (ref: HTMLElement, position: Position, objectiveId: string) => void
}

export const OkrFloatDrawer: React.FC<Props> = ({
  objectiveId,
  selectedObjectiveId,
  selectedKeyResultIds,
  show = true,
  position,
  parentClientRect,
  zIndex,
  onDragStart,
  onDragStop,
  onRemoved,
  onMouseEnter,
  onMouseLeave,
  onClick,
  onResizeStart,
  onResizeStop,
}) => {
  const { t } = useTranslation()
  const [okrNodeQuery, { loading, called, data }] = useOkrNodeLazyQuery()

  useEffect(() => {
    okrNodeQuery({ variables: { objectiveId } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const bindOnRemoved = useCallback(() => {
    if (onRemoved) {
      onRemoved(objectiveId)
    }
  }, [objectiveId, onRemoved])

  const bindOnDragStart = useCallback(
    (draggableData) => {
      if (onDragStart) {
        onDragStart(draggableData, objectiveId)
      }
    },
    [objectiveId, onDragStart],
  )

  const bindOnDragStop = useCallback(
    (draggableData) => {
      if (onDragStop) {
        onDragStop(draggableData, objectiveId)
      }
    },
    [objectiveId, onDragStop],
  )

  const bindOnMouseEnter = useCallback(
    (e) => {
      if (onMouseEnter) {
        onMouseEnter(e, objectiveId)
      }
    },
    [objectiveId, onMouseEnter],
  )

  const bindOnMouseLeave = useCallback(
    (e) => {
      if (onMouseLeave) {
        onMouseLeave(e, objectiveId)
      }
    },
    [objectiveId, onMouseLeave],
  )

  const bindOnClick = useCallback(
    (e) => {
      if (onClick) {
        onClick(e, objectiveId)
      }
    },
    [objectiveId, onClick],
  )

  const bindOnResizeStop = useCallback(
    (ref, pos) => {
      if (onResizeStop) {
        onResizeStop(ref, pos, objectiveId)
      }
    },
    [objectiveId, onResizeStop],
  )

  const bindOnResizeStart = useCallback(
    (ref) => {
      if (onResizeStart) {
        onResizeStart(ref, objectiveId)
      }
    },
    [objectiveId, onResizeStart],
  )

  if (!called || loading) {
    return null
  }

  const { okrNode } = data || {}
  if (okrNode == null) {
    return null
  }

  const { objective, keyResults } = okrNode
  const renderKeyResults = keyResults.filter((v) => !v.isDisabled)

  return (
    <FloatDrawer
      show={show}
      position={position}
      parentClientRect={parentClientRect}
      zIndex={zIndex}
      onDragStart={bindOnDragStart}
      onDragStop={bindOnDragStop}
      onRemoved={bindOnRemoved}
      onMouseEnter={bindOnMouseEnter}
      onMouseLeave={bindOnMouseLeave}
      onClick={bindOnClick}
      onResizeStop={bindOnResizeStop}
      onResizeStart={bindOnResizeStart}
      header={
        <Box direction="row" align="center">
          <Avatar
            size="xsmall"
            firstName={objective.owner.firstName}
            lastName={objective.owner.lastName}
            avatarUrl={objective.owner.avatar ? objective.owner.avatar.url : undefined}
            tooltipDisabled
            isUserDisabled={objective.owner.isDisabled}
            hoverStyle={{ backgroundColor: 'none' }}
            avatarStyle={{ display: 'flex' }}
          />
          <StyledText
            color="text-bk-80"
            size="small"
            oneline
            isWrap
            numberOfOmittedLines={1}
            weight="bold"
            css={{
              marginLeft: '8px',
            }}
          >
            {objective.name}
          </StyledText>
        </Box>
      }
    >
      <StyledText color="text-bk-50" size="small" weight="bold" lineHeight="12px">
        {t('OBJECTIVE')}
      </StyledText>
      <div css={{ marginTop: '8px' }}>
        <ObjectiveContent open={objective.id === selectedObjectiveId} objective={objective} />
      </div>
      <StyledText color="text-bk-50" size="small" weight="bold" lineHeight="12px">
        {t('KEY_RESULT')}
      </StyledText>
      <div css={{ marginTop: '8px' }}>
        {renderKeyResults.map((kr, i) => (
          <KeyResultContent
            key={kr.id}
            open={selectedKeyResultIds?.includes(kr.id)}
            keyResult={kr}
            isLast={i + 1 === keyResults.length}
          />
        ))}
        {!renderKeyResults.length && (
          <p css={{ padding: '9px 0' }}>
            <StyledText lineHeight="20px">{t('NO_KEY_RESULT')}</StyledText>
          </p>
        )}
      </div>
    </FloatDrawer>
  )
}

OkrFloatDrawer.displayName = 'OkrFloatDrawer'

type OpenProps = {
  open?: boolean
}

type ObjectiveContentProps = OpenProps & {
  objective: ObjectiveFragment
}

const ObjectiveContent: React.FC<ObjectiveContentProps> = ({ objective, open = false }) => (
  <OkrContent
    type="objective"
    id={objective.id}
    owner={objective.owner}
    name={objective.name}
    description={objective.description}
    open={open}
  />
)

ObjectiveContent.displayName = 'ObjectiveContent'

type KeyResultContentProps = OpenProps & {
  keyResult: KeyResultFragment
  isLast: boolean
}

const KeyResultContent: React.FC<KeyResultContentProps> = ({ keyResult, open = false, isLast }) => (
  <OkrContent
    type="keyresult"
    id={keyResult.id}
    owner={keyResult.owner}
    members={keyResult.members}
    name={keyResult.name}
    description={keyResult.description}
    open={open}
    isLast={isLast}
  />
)

KeyResultContent.displayName = 'KeyResultContent'

type OkrContentType = 'objective' | 'keyresult'

type OkrContentProps = OpenProps & {
  type: OkrContentType
  id: string
  owner: UserFragment
  members?: ReadonlyArray<UserFragment>
  name: string
  description?: DocumentFragment | null
  isLast?: boolean
}

const newTitleColor: { [key in OkrContentType]: ColorAlias } = {
  objective: 'objective-blue-100',
  keyresult: 'kr-green-100',
}

const DISPLAY_MEMBER_AVATAR_NUM = 2

const OkrContent: React.FC<OkrContentProps> = ({
  type,
  id,
  owner,
  members,
  name,
  description,
  open = false,
  isLast = false,
}) => {
  const { t } = useTranslation()
  const avatarSize = 'small'

  const [isOpen, setIsOpen] = useState<boolean>(false)
  useEffect(() => {
    setIsOpen(open)
  }, [open])

  return (
    <div>
      <Box direction="row" align="center" gap="12px" onClick={() => setIsOpen(!isOpen)}>
        <Icon
          data-is-opened={isOpen}
          type="keyboardArrowDown"
          width={16}
          height={16}
          color={color('text-bk-50')}
          hoverColor="text-bk-100"
          css={{
            '&[data-is-opened="false"]': {
              transform: 'rotate(270deg)',
            },
          }}
        />
        <div
          css={{
            position: 'relative',
            display: 'inline-block',
          }}
        >
          <div
            css={{
              position: 'relative',
              width: sizes[avatarSize].width,
              height: sizes[avatarSize].height,
            }}
          >
            {/* コントリビュータは2人まで表示 */}
            {members
              ?.slice(0, DISPLAY_MEMBER_AVATAR_NUM)
              .map((m, i) => (
                <Avatar
                  key={m.id}
                  size={avatarSize}
                  firstName={m.firstName}
                  lastName={m.lastName}
                  avatarUrl={m.avatar ? m.avatar.url : undefined}
                  tooltipDisabled
                  css={{
                    position: 'absolute',
                    left: `${(i + 1) * 3}px`,
                  }}
                  avatarStyle={{
                    border: `1px solid ${color('white-100')}`,
                    display: 'flex',
                  }}
                  isUserDisabled={m.isDisabled}
                  hoverStyle={{
                    backgroundColor: 'none',
                  }}
                />
              ))
              .reverse()}
            {/* 責任者アバター */}
            <Avatar
              size={avatarSize}
              firstName={owner.firstName}
              lastName={owner.lastName}
              avatarUrl={owner.avatar ? owner.avatar.url : undefined}
              tooltipDisabled
              css={{
                position: 'absolute',
              }}
              avatarStyle={{
                border: `1px solid ${color('white-100')}`,
                display: 'flex',
              }}
              isUserDisabled={owner.isDisabled}
              hoverStyle={{
                backgroundColor: 'none',
              }}
            />
          </div>
        </div>
        <Title color={newTitleColor[type]} title={name} />
      </Box>
      <div
        data-is-opened={isOpen}
        css={{
          marginTop: '16px',
          '&[data-is-opened="false"]': {
            display: 'none',
          },
        }}
      >
        <StyledText color="text-bk-50" size="small" weight="bold" lineHeight="12px">
          {type === 'keyresult' ? t('ACHIEVEMENT_CRITERIA_AND_METRICS') : t('OBJECTIVE_BACKGROUND')}
        </StyledText>
        <div css={{ padding: '16px 8px 0' }}>
          <RichTextEditor
            id={`okr-float-drawer-${id}`}
            css={{
              color: color('text-bk-100'),
            }}
            initialValueJSON={description?.treeJson}
            editorProps={{
              readOnly: true,
            }}
          />
        </div>
      </div>
      <hr
        css={{
          background: color('border-bk-10'),
          border: 'none',
          height: '1px',
          margin: `20px 6.5px ${isLast ? '0' : '20px'} 8px`,
        }}
      />
    </div>
  )
}

OkrContent.displayName = 'OkrContent'

type TitleProps = {
  title: string
  color: ColorAlias
}

const Title: React.FC<TitleProps> = ({ title, color: colorAlias, ...props }) => (
  <div
    css={{
      position: 'relative',
      ...fontSize('medium'),
      paddingLeft: 16,
      paddingRight: 12,
      width: '100%',
      lineHeight: '20px',
      '&::before': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: 4,
        height: '100%',
        content: '""',
        backgroundColor: color(colorAlias),
        borderRadius: 2,
      },
    }}
    {...props}
  >
    <div
      css={{
        width: '100%',
        padding: '8px 0',
        outline: 'none',
        cursor: 'pointer',
      }}
    >
      <p
        css={{
          textAlign: 'left',
          overflowWrap: 'anywhere',
          wordBreak: 'break-all',
        }}
      >
        {title}
      </p>
    </div>
  </div>
)

Title.displayName = 'Title'
