import React from 'react'

import { useTranslation } from '../../../i18n'
import { Modal, Props as ModalProps } from '../Modal'
import { StyledText } from '../StyledText'

export type Props = Pick<
  ModalProps,
  'isOpened' | 'withGeishaModal' | 'onClickCancel' | 'onClickOutside'
> & {
  deleteItemName: string
  deleteItemNameForBody?: string
  onClickDelete?: ModalProps['onClickExecute']
}

export const DeleteModal: React.FC<Props> = ({
  children,
  isOpened,
  withGeishaModal,
  deleteItemName,
  deleteItemNameForBody,
  onClickDelete,
  onClickCancel,
  onClickOutside,
}) => {
  const { t } = useTranslation()
  return (
    <Modal
      isOpened={isOpened}
      withGeishaModal={withGeishaModal}
      title={t('DELETION_X', { x: deleteItemName })}
      executeText={t('DELETION')}
      onClickExecute={onClickDelete}
      onClickCancel={onClickCancel}
      onClickOutside={onClickOutside}
    >
      <StyledText>
        {t('X_CONFIRMATION_OF_DELETE', { x: deleteItemNameForBody || deleteItemName }) +
          t('WARNING')}
      </StyledText>
      {children}
    </Modal>
  )
}

DeleteModal.displayName = 'DeleteModal'
