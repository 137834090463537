import { Box, TextInput, ThemeContext } from 'grommet'
import { deepMerge } from 'grommet/utils'
import React, {
  useMemo,
  MouseEventHandler,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import { v1 as uuid } from 'uuid'

import { FixedAgendaStatus } from '../../../graphql'
import { useTranslation } from '../../../i18n/index'
import { theme } from '../../../styles/grommetTheme'
import { color } from '../../../styles/newColors'
import { Button } from '../../ui/Button'
import { ErrorMessage } from '../../ui/ErrorMessage'
import { EditorRef, RichTextEditor } from '../../ui/RichTextEditor/index'
import { Select } from '../../ui/Select'
import { StyledText } from '../../ui/StyledText/index'

import { CancelModal } from './components/CancelModal'

const DEFAULT_FORM_VALUE = {
  title: undefined,
  body: {
    treeJson: '',
    plainText: '',
  },
  status: FixedAgendaStatus.Public,
}

export type FormValue = {
  title: string
  body: {
    treeJson: string
    plainText: string
  }
  status: FixedAgendaStatus
}

export type Props = {
  formValue?: FormValue
  disabled?: boolean
  onClickSave: SubmitHandler<FormValue>
  onClickCancel: MouseEventHandler<HTMLButtonElement>
} & React.PropsWithoutRef<JSX.IntrinsicElements['div']>

export type FixedAgendaDetailCardRef = {
  setValues: (values: FormValue) => void
}

export const FixedAgendaDetailCard = forwardRef<FixedAgendaDetailCardRef, Props>(
  (
    { formValue = DEFAULT_FORM_VALUE, disabled = false, onClickSave, onClickCancel, ...rest },
    ref,
  ) => {
    const { t } = useTranslation()
    const [isShowCancelModal, setIsShowCancelModal] = useState<boolean>(false)
    const editorUniqId = useMemo(uuid, [])
    const editorRef = useRef<EditorRef>(null)

    const {
      control,
      handleSubmit,
      reset,
      formState: { errors, isValid, isDirty, isSubmitting },
    } = useForm<FormValue>({
      mode: 'onChange',
      defaultValues: formValue,
    })

    const agendaStatusOptions = [
      {
        text: t('PUBLIC_GROUP'),
        value: FixedAgendaStatus.Public,
      },
      {
        text: t('PRIVATE_GROUP'),
        value: FixedAgendaStatus.Private,
      },
    ]

    useImperativeHandle(ref, () => ({
      setValues: (values: FormValue) => {
        reset(values)
        editorRef?.current?.setValue(values.body.treeJson)
      },
    }))

    return (
      <>
        <div
          css={{
            backgroundColor: color('white-100'),
            borderRadius: '8px',
            padding: '32px 24px',
          }}
          {...rest}
        >
          <form onSubmit={handleSubmit(onClickSave)}>
            <StyledText
              fontStyle="bold"
              size="medium"
              css={{
                display: 'block',
                marginBottom: '8px',
                '&::after': {
                  color: color('resily-orange-100'),
                  content: '"*"',
                },
              }}
            >
              {t('AGENDA')}
            </StyledText>
            <StyledText
              fontStyle="regular"
              size="medium"
              css={{
                display: 'block',
                marginBottom: '12px',
                color: color('text-bk-50'),
              }}
            >
              {t('TEMPLATE_HELP')}
            </StyledText>

            <ThemeContext.Extend
              value={deepMerge(theme, {
                textInput: {
                  extend: () => `
                height: 40px;
                font-size: 14px;
                line-height: 22px;
                font-weight: normal;
                background: ${color('hover-background-bk-5')};
                margin: 0 auto;
                border: 1px solid #D3D4D9;
            `,
                },
              })}
            >
              <Controller
                name="title"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <TextInput
                    placeholder={t('INPUT_X', { x: t('TITLE') })}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
              <ErrorMessage css={{ marginTop: '12px' }}>
                {errors.title && t('REQUIRED_ERROR')}
              </ErrorMessage>
            </ThemeContext.Extend>
            <StyledText
              fontStyle="bold"
              size="medium"
              css={{
                display: 'block',
                marginTop: '24px',
                marginBottom: '8px',
              }}
            >
              {t('BODY_TEXT_TITLE')}
            </StyledText>
            <StyledText
              fontStyle="regular"
              size="medium"
              css={{
                display: 'block',
                marginBottom: '12px',
                color: color('text-bk-50'),
              }}
            >
              {t('BODY_DESCRIPTION')}
            </StyledText>
            <Controller
              name="body"
              control={control}
              render={({ field: { onChange, value } }) => (
                <RichTextEditor
                  id={editorUniqId}
                  ref={editorRef}
                  initialValueJSON={value.treeJson}
                  css={{
                    minHeight: '180px',
                    maxHeight: '443px',
                    overflowX: 'hidden',
                    overflowY: 'scroll',
                    paddingRight: '28px',
                  }}
                  onChange={(treeJson: string, plainText: string) => {
                    onChange({ treeJson, plainText })
                  }}
                />
              )}
            />
            <StyledText
              fontStyle="bold"
              size="medium"
              css={{
                display: 'block',
                marginTop: '24px',
              }}
            >
              {t('OPEN_RANGE')}
            </StyledText>
            <Box width="105px" height="40px" margin={{ top: '12px' }}>
              <Controller
                name="status"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    dropAlign={{ top: 'bottom', left: 'left' }}
                    options={agendaStatusOptions}
                    value={value}
                    onClickOption={(v) => onChange(v)}
                    valueButtonCss={{ padding: '13px 16px', width: 130, height: 40 }}
                    popoverCss={{ width: '100%' }}
                  />
                )}
              />
            </Box>
            <Box
              direction="row"
              justify="end"
              gap="8px"
              css={{ flexBasis: '218px', marginTop: '20px' }}
            >
              <Button
                newColor="white-100"
                onClick={(e) => {
                  if (isDirty) {
                    setIsShowCancelModal(true)
                    return
                  }
                  onClickCancel(e)
                }}
                weight="normal"
                size="s"
              >
                {t('CANCEL')}
              </Button>
              <Button
                type="submit"
                disabled={!isDirty || !isValid || isSubmitting || disabled}
                newColor="resily-orange-100"
                weight="normal"
                size="s"
              >
                {t('SAVE')}
              </Button>
            </Box>
          </form>
        </div>
        <CancelModal
          isOpened={isShowCancelModal}
          onClickCancel={() => {
            setIsShowCancelModal(false)
          }}
          onClickOutside={() => {
            setIsShowCancelModal(false)
          }}
          onClickDelete={(e) => {
            setIsShowCancelModal(false)
            onClickCancel(e)
          }}
        />
      </>
    )
  },
)

FixedAgendaDetailCard.displayName = 'FixedAgendaDetailCard'
