import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { StringParam, useQueryParams } from 'use-query-params'

import { useTranslation } from '../../i18n'
import { client } from '../../lib/client'
import { useConfirmationToken } from '../../lib/domain/useConfirmationToken'
import { tokenConfirmation } from '../../types/tokenConfirmation'

import { InvalidLink } from './InvalidLink'
import { SignUp } from './SignUp'

export const SignUpContainer: React.FC = () => {
  const navigate = useNavigate()
  const { i18n } = useTranslation()

  const [{ confirmation_token: token, lang }] = useQueryParams({
    confirmation_token: StringParam,
    lang: StringParam,
  })

  const { loading, confirmedToken } = useConfirmationToken({
    type: tokenConfirmation.USER_INVITATION,
    token,
  })

  useEffect(() => {
    if (lang == null) return
    i18n.changeLanguage(lang)
  }, [i18n, lang])

  const handleSignUp = async (values: {
    newPassword: string
    passwordConfirmation: string
    token: string
    lang: string
  }) => {
    try {
      await client.post<void>(
        '/users/confirmation',
        {
          password: values.newPassword,
          password_confirmation: values.passwordConfirmation,
          confirmation_token: values.token,
          lang: values.lang,
        },
        {
          error: true,
        },
      )
      navigate('/signin')
    } catch (e) {
      // nothing to do
    }
  }

  if (typeof token !== 'string' || typeof lang !== 'string' || loading) {
    return null
  }

  if (!confirmedToken) {
    return <InvalidLink />
  }

  return <SignUp token={token} lang={lang} onSubmit={handleSignUp} />
}

SignUpContainer.displayName = 'SignUpContainer'
