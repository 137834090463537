import { useCallback } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import {
  ONE_ON_ONE_DRAWER_QUERY_TERM_ID,
  ONE_ON_ONE_DRAWER_QUERY_USER_ID,
  ONE_ON_ONE_DRAWER_QUERY_ONE_ON_ONE_ID,
} from '../../../../urls'

const noop = () => {}

type Params = {
  termId: string
  userId: string
  oneOnOneId: string
}

type State =
  | {
      openable: true
      params: Params
    }
  | {
      openable: false
      params: null
    }

export const useDrawerState = (): State => {
  const location = useLocation()

  const queryParams = new window.URLSearchParams(location.search)
  const userId = queryParams.get(ONE_ON_ONE_DRAWER_QUERY_USER_ID)
  const oneOnOneId = queryParams.get(ONE_ON_ONE_DRAWER_QUERY_ONE_ON_ONE_ID)
  const termId = queryParams.get(ONE_ON_ONE_DRAWER_QUERY_TERM_ID)

  // URLのクエリパラメーターにuserIdが存在していれば、ドロワーをOpenできる
  if (userId && oneOnOneId && termId) {
    return { openable: true, params: { termId, userId, oneOnOneId } }
  }
  return { openable: false, params: null }
}

// ドロワーの状態を変更する
export const useReplaceDrawerState = (): ((params: Partial<Params>) => void) => {
  const location = useLocation()
  const openDrawer = useOpenDrawer()

  return (params: Partial<Params>) => {
    // 引数の値がすべてnullの場合は、何も処理しない
    if (!(params.termId || params.oneOnOneId || params.userId)) {
      return noop
    }

    const queryParams = new window.URLSearchParams(location.search)
    const termId = params.termId ?? queryParams.get(ONE_ON_ONE_DRAWER_QUERY_TERM_ID)
    const oneOnOneId = params.oneOnOneId ?? queryParams.get(ONE_ON_ONE_DRAWER_QUERY_ONE_ON_ONE_ID)
    const userId = params.userId ?? queryParams.get(ONE_ON_ONE_DRAWER_QUERY_USER_ID)

    // ドロワーを開くためのパラメーターが一つでも欠けていなければ、ドロワーの状態（URLのクエリ）を変更
    if (userId && oneOnOneId && termId) {
      openDrawer({ userId, oneOnOneId, termId })
    }

    return noop
  }
}

// 1 on 1のドロワーを開く
export const useOpenDrawer = (): ((params: Params) => void) => {
  const navigate = useNavigate()
  const location = useLocation()

  const openDrawer = useCallback(
    (params: Params) => {
      const { termId, userId, oneOnOneId } = params
      const queryParams = new window.URLSearchParams()

      queryParams.append(ONE_ON_ONE_DRAWER_QUERY_USER_ID, userId)
      queryParams.append(ONE_ON_ONE_DRAWER_QUERY_ONE_ON_ONE_ID, oneOnOneId)
      queryParams.append(ONE_ON_ONE_DRAWER_QUERY_TERM_ID, termId)

      const queryString = Array.from(queryParams).length > 0 ? `?${queryParams.toString()}` : ''

      const nextPath = `${location.pathname}${queryString}`
      navigate(nextPath, { replace: true })
    },
    [navigate, location.pathname],
  )

  return openDrawer
}

// 1 on 1のドロワーを閉じる
export const useCloseDrawer = (): (() => void) => {
  const navigate = useNavigate()
  const location = useLocation()

  const closeDrawer = useCallback(() => {
    const queryParams = new window.URLSearchParams()

    queryParams.delete(ONE_ON_ONE_DRAWER_QUERY_USER_ID)

    const queryString = Array.from(queryParams).length > 0 ? `?${queryParams.toString()}` : ''

    const nextPath = `${location.pathname}${queryString}`
    navigate(nextPath, { replace: true })
  }, [navigate, location.pathname])

  return closeDrawer
}
