import { useCallback, useEffect, useState, useMemo } from 'react'
import { DraggableData } from 'react-rnd'

import { defaultWidth, Position } from '../../ui/FloatDrawer'

import { OkrFloatDrawerItem, OkrFloatDrawerListItem } from './types'

const defaultPosition = {
  top: 56,
  right: 64,
}

const drawerGap = {
  top: 40,
  right: -16,
}

const reassignZIndex = (
  currentList: ReadonlyArray<OkrFloatDrawerItem>,
  addItems: ReadonlyArray<OkrFloatDrawerListItem>,
  baseZIndex: number,
): Array<OkrFloatDrawerItem> => {
  const addItemObjectiveIds = addItems.map((v) => v.objectiveId)
  const existedItems = currentList.filter((v) => addItemObjectiveIds.includes(v.objectiveId))
  if (!existedItems.length) {
    return [...currentList]
  }

  const noExistedSortedItems = currentList
    .filter((v) => !addItemObjectiveIds.includes(v.objectiveId))
    .sort((a, b) => a.zIndex - b.zIndex)
    .map((v, i) => ({ ...v, zIndex: baseZIndex + i }))
  const existedSortedItems = existedItems
    .sort(
      (a, b) =>
        addItemObjectiveIds.indexOf(a.objectiveId) - addItemObjectiveIds.indexOf(b.objectiveId),
    )
    .map((v, i) => ({ ...v, zIndex: baseZIndex + noExistedSortedItems.length + i }))
  const sortedItems = [...noExistedSortedItems, ...existedSortedItems]
  const newList = currentList.map((v) => {
    const item = sortedItems.find((sortedItem) => sortedItem.objectiveId === v.objectiveId)
    return {
      ...v,
      zIndex: item?.zIndex ?? v.zIndex,
    }
  })
  return newList
}

const updateZIndexByThresholdZIndex = (
  currentList: Array<OkrFloatDrawerItem>,
  thresholdZIndex: number,
  baseZIndex: number,
) =>
  currentList.map((v) => {
    let { zIndex } = v
    if (v.zIndex > thresholdZIndex) {
      zIndex -= 1
    } else if (v.zIndex === thresholdZIndex) {
      zIndex = baseZIndex + currentList.length - 1
    }
    return {
      ...v,
      zIndex,
    }
  })

const isForefront = (currentList: Array<OkrFloatDrawerItem>, zIndex: number, baseZIndex: number) =>
  zIndex === baseZIndex + currentList.length - 1

const moveElementToForefront = (
  currentList: Array<OkrFloatDrawerItem>,
  objectiveId: string,
  element: HTMLElement,
  baseZIndex: number,
) => {
  const clickedItem = currentList.find((v) => v.objectiveId === objectiveId)
  if (!clickedItem || isForefront(currentList, clickedItem.zIndex, baseZIndex)) return

  element.style.zIndex = baseZIndex + currentList.length.toString()
}

const getPositionIndex = (currentList: Array<OkrFloatDrawerItem>, basePosition: Position) => {
  let positionIndex = 0
  const startIndex = currentList.findIndex(
    (v) => v.position.x === basePosition.x && v.position.y === basePosition.y,
  )
  if (startIndex === -1) {
    return positionIndex
  }

  // 初期位置に存在する場合、連続した位置にも存在するかどうか確認し追加分の開始位置を決める
  positionIndex = 1
  for (let index = startIndex + 1; index < currentList.length; index += 1) {
    const item = currentList[index]

    const expectedPosition = {
      x: basePosition.x + drawerGap.right * positionIndex,
      y: basePosition.y + drawerGap.top * positionIndex,
    }
    if (item.position.x !== expectedPosition.x || item.position.y !== expectedPosition.y) {
      break
    }
    positionIndex += 1
  }

  return positionIndex
}

const updatePosition = (
  currentList: Array<OkrFloatDrawerItem>,
  objectiveId: string,
  position: Position,
) =>
  currentList.map((v) => {
    const newPosition =
      v.objectiveId === objectiveId
        ? {
            x: position.x,
            y: position.y,
          }
        : v.position
    return {
      ...v,
      position: newPosition,
    }
  })

export const useInjection = (
  addItems: ReadonlyArray<OkrFloatDrawerListItem>,
  baseZIndex: number,
  parentRef?: React.RefObject<HTMLElement>,
): {
  list: Array<OkrFloatDrawerItem>
  parentClientRect: DOMRect
  onRemoved: (objectiveId: string) => void
  onDragStart: (data: DraggableData, objectiveId: string) => void
  onDragStop: (data: DraggableData, objectiveId: string) => void
  onClick: (e: MouseEvent, objectiveId: string) => void
  onResizeStart: (ref: HTMLElement, objectiveId: string) => void
  onResizeStop: (ref: HTMLElement, position: Position, objectiveId: string) => void
  onMouseEnter: (e: MouseEvent, objectiveId: string) => void
  onMouseLeave: (e: MouseEvent, objectiveId: string) => void
} => {
  const [list, setList] = useState<Array<OkrFloatDrawerItem>>([])
  const [parentClientRect, setParentClientRect] = useState<DOMRect>(
    new DOMRect(0, 0, window.innerWidth, window.innerHeight),
  )

  // 親要素の指定がない場合の parentClientRect リサイズ対応
  const browserResize = useCallback(() => {
    if (parentRef?.current) return

    setParentClientRect(new DOMRect(0, 0, window.innerWidth, window.innerHeight))
  }, [parentRef])

  useEffect(() => {
    window.addEventListener('resize', browserResize)
    return () => {
      window.removeEventListener('resize', browserResize)
    }
  }, [browserResize])

  // 親要素の指定がある場合の parentClientRect リサイズ対応
  useEffect(() => {
    if (!parentRef?.current) return

    const resizeObserver = new ResizeObserver(
      (entries: Array<{ target: Element; contentRect: DOMRect }>) => {
        if (parentRef.current && entries[0].target.id === parentRef.current.id) {
          setParentClientRect(entries[0].target.getBoundingClientRect())
        }
      },
    )
    resizeObserver.observe(parentRef.current)

    // eslint-disable-next-line consistent-return
    return () => resizeObserver.disconnect()
  }, [parentRef])

  const initialPosition = useMemo(
    () => ({
      x: parentClientRect.width - defaultWidth - defaultPosition.right,
      y: defaultPosition.top,
    }),
    [parentClientRect],
  )

  useEffect(() => {
    if (!addItems.length) return

    // 新規作成
    if (!list.length) {
      const newList = addItems.map((v, i) => ({
        ...v,
        position: {
          x: initialPosition.x + drawerGap.right * i,
          y: initialPosition.y + drawerGap.top * i,
        },
        zIndex: baseZIndex + i,
      }))
      setList(newList)
      return
    }

    // 既存リスト内の z-index の再設定
    const newList = reassignZIndex(list, addItems, baseZIndex)

    const addItemObjectiveIds = addItems.map((v) => v.objectiveId)
    const existedItemObjectiveIds = list
      .filter((v) => addItemObjectiveIds.includes(v.objectiveId))
      .map((v) => v.objectiveId)
    if (existedItemObjectiveIds.length === addItems.length) {
      setList(newList)
      return
    }

    // 新規作成分
    const diff = existedItemObjectiveIds.length
      ? addItems.filter((v) => !existedItemObjectiveIds.includes(v.objectiveId))
      : [...addItems]
    const positionIndex = getPositionIndex(list, initialPosition)
    const newItems = diff.map((v, i) => ({
      ...v,
      position: {
        x: initialPosition.x + drawerGap.right * (positionIndex + i),
        y: initialPosition.y + drawerGap.top * (positionIndex + i),
      },
      zIndex: baseZIndex + newList.length + i,
    }))

    setList([...newList, ...newItems])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addItems])

  const onRemoved = useCallback(
    (objectiveId: string) => {
      const newList = list.filter((v) => v.objectiveId !== objectiveId)
      setList(newList)
    },
    [list],
  )

  const onDragStart = useCallback(
    (data: DraggableData, objectiveId: string) => {
      moveElementToForefront(list, objectiveId, data.node, baseZIndex)
    },
    [list, baseZIndex],
  )

  const onDragStop = useCallback(
    (data: DraggableData, objectiveId: string) => {
      data.node.style.zIndex = ''

      const clickedItem = list.find((v) => v.objectiveId === objectiveId)
      if (!clickedItem) return

      const newList = updatePosition(list, clickedItem.objectiveId, data)
      if (isForefront(list, clickedItem.zIndex, baseZIndex)) {
        setList(newList)
        return
      }
      setList(updateZIndexByThresholdZIndex(newList, clickedItem.zIndex, baseZIndex))
    },
    [list, baseZIndex],
  )

  const onClick = useCallback(
    (e: MouseEvent, objectiveId: string) => {
      e.stopPropagation()
      const clickedItem = list.find((v) => v.objectiveId === objectiveId)
      if (!clickedItem || isForefront(list, clickedItem.zIndex, baseZIndex)) return

      setList(updateZIndexByThresholdZIndex(list, clickedItem.zIndex, baseZIndex))
    },
    [list, baseZIndex],
  )

  const onResizeStart = useCallback(
    (ref: HTMLElement, objectiveId: string) => {
      moveElementToForefront(list, objectiveId, ref, baseZIndex)
    },
    [list, baseZIndex],
  )

  const onResizeStop = useCallback(
    (ref: HTMLElement, position: Position, objectiveId: string) => {
      ref.style.zIndex = ''

      const clickedItem = list.find((v) => v.objectiveId === objectiveId)
      if (!clickedItem) return

      const newList = updatePosition(list, clickedItem.objectiveId, position)
      if (isForefront(list, clickedItem.zIndex, baseZIndex)) {
        setList(newList)
        return
      }
      setList(updateZIndexByThresholdZIndex(newList, clickedItem.zIndex, baseZIndex))
    },
    [list, baseZIndex],
  )

  const onMouseEnter = useCallback((e: MouseEvent, _) => {
    const target = e.target as HTMLDivElement
    target.style.willChange = 'transform'
  }, [])

  const onMouseLeave = useCallback((e: MouseEvent, _) => {
    const target = e.target as HTMLDivElement
    target.style.willChange = 'auto'
  }, [])

  return {
    list,
    parentClientRect,
    onRemoved,
    onDragStart,
    onDragStop,
    onClick,
    onResizeStart,
    onResizeStop,
    onMouseEnter,
    onMouseLeave,
  }
}
