import { Box } from 'grommet'

import { StyledText } from '../../../../StyledText'
import { Tooltip } from '../../../../Tooltip'

type Props = {
  disabled?: boolean
  message: string
}

export const ButtonTooltip: React.FC<Props> = ({ children, disabled, message }) => (
  <Tooltip
    disabled={disabled}
    dropAlign={{ bottom: 'top' }}
    customized
    restrictFocus={false}
    title={
      <Box
        css={{
          background: '#EBEBEC',
          borderColor: 'transparent',
          borderRadius: 4,
          borderWidth: 1,
          transition: 'top 75ms ease-out, left 75ms ease-out',
          boxShadow: 'rgb(255 255 255 / 7%) 1px 0px 0px',
          marginBottom: '4px',
          padding: '4px 8px',
          width: 'auto',
          maxWidth: 200,
          marginTop: '4px',
          lineHeight: '16px',
          fontSize: '12px',
        }}
      >
        <StyledText>{message}</StyledText>
      </Box>
    }
  >
    {children}
  </Tooltip>
)
