import { yupResolver } from '@hookform/resolvers/yup'
import queryString from 'query-string'
import { PropsWithoutRef } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useLocation } from 'react-router-dom'
import * as Yup from 'yup'

import { PageContent } from '../../components/pageContent'
import { Button } from '../../components/ui/ButtonDeprecated'
import { FormField } from '../../components/ui/FormField'
import { Input } from '../../components/ui/InputDeprecated'
import { Logo } from '../../components/ui/Logo'
import { useTranslation } from '../../i18n'
import { client } from '../../lib/client'
import { Screen } from '../../lib/screen'
import { useTracking } from '../../lib/tracking'
import * as colors from '../../styles/colors'

type ContainerProps = PropsWithoutRef<JSX.IntrinsicElements['form']>

export const ResetEmailContainer: React.FC<ContainerProps> = ({ ...props }) => {
  const location = useLocation()
  const navigate = useNavigate()

  const updateEmail = async (values: {
    email: string
    password: string
    passwordConfirmation: string
    token: string
  }) => {
    try {
      await client.post<void>(
        '/users/email',
        {
          email: values.email,
          password: values.password,
          password_confirmation: values.passwordConfirmation,
          confirmation_token: values.token,
        },
        {
          error: true,
        },
      )
      navigate('/signin')
    } catch (err) {
      // nothing to do
    }
  }

  const { confirmation_token: token } = queryString.parse(location.search)
  if (typeof token !== 'string') {
    return null
  }
  return <ResetEmail token={token} updateEmail={updateEmail} {...props} />
}

ResetEmailContainer.displayName = 'ResetEmailContainer'

type FormValue = {
  email: string
  password: string
  passwordConfirmation: string
  token: string
}

export type Props = {
  token: string
  updateEmail: (values: {
    email: string
    password: string
    passwordConfirmation: string
    token: string
  }) => void
}

export const ResetEmail: React.FC<Props> = ({ token, updateEmail, ...props }) => {
  const { t } = useTranslation()
  useTracking(t('EMAIL_RESET_PAGE_TITLE'), Screen.ResetEmail)
  const {
    register,
    handleSubmit,
    formState: { errors },
    formState,
  } = useForm<FormValue>({
    resolver: yupResolver(
      Yup.object().shape({
        email: Yup.string().required(t('REQUIRED_ERROR')).email(t('INVALID_EMAIL_FORMAT')),
        password: Yup.string().required(t('REQUIRED_ERROR')),
      }),
    ),
    defaultValues: {
      email: '',
      password: '',
      passwordConfirmation: '',
      token,
    },
  })
  const onSubmit = (values: FormValue) => {
    updateEmail({ ...values, token })
  }

  return (
    <PageContent
      breadcrumbs={undefined}
      layout={{
        height: '100%',
        align: 'center',
        justify: 'center',
      }}
    >
      <Logo css={{ width: '156px', height: '44px' }} />
      <div
        css={{
          width: '500px',
          padding: '24px 48px',
          marginTop: '24px',
          backgroundColor: colors.baseFill,
          border: `1px solid ${colors.inactive}`,
          boxShadow: '0px 4px 8px rgb(0 0 0 / 14%)',
          borderRadius: '4px',
        }}
      >
        <h1>{t('CONFIRM_EMAIL')}</h1>
        <form onSubmit={handleSubmit(onSubmit)} {...props}>
          <section>
            <div
              css={{
                margin: '16px 0',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <FormField
                errorMessage={errors.email ? `${errors.email.message}` : ''}
                css={{ display: 'inline-block', width: '100px', marginRight: '8px' }}
              >
                {t('EMAIL')}
              </FormField>
              <Input type="email" {...register('email')} placeholder={t('INPUT_NEW_EMAIL')} />
            </div>
            <div
              css={{
                margin: '16px 0',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <FormField
                errorMessage={errors.password ? `${errors.password.message}` : ''}
                css={{ display: 'inline-block', width: '100px', marginRight: '8px' }}
              >
                {t('PASSWORD')}
              </FormField>
              <Input type="password" {...register('password')} />
            </div>
            <div
              css={{
                margin: '16px 0',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <FormField
                errorMessage={
                  errors.passwordConfirmation ? `${errors.passwordConfirmation.message}` : ''
                }
                css={{ display: 'inline-block', width: '100px', marginRight: '8px' }}
              >
                {t('PASSWORD_CONFIRMATION')}
              </FormField>
              <Input type="password" {...register('passwordConfirmation')} />
            </div>
          </section>
          <div
            css={{
              marginTop: '24px',
              textAlign: 'center',
            }}
          >
            <Button
              type="submit"
              disabled={formState.isSubmitting}
              css={{ backgroundColor: colors.buttonFillMain }}
            >
              {t('UPDATE')}
            </Button>
          </div>
        </form>
      </div>
    </PageContent>
  )
}

ResetEmail.displayName = 'ResetEmail'
