import { css } from '@emotion/react'

import { Props as ContentLayoutProps } from '../../../components/pageContent/ContentLayout'

export const pageContentLayout: ContentLayoutProps = {
  pad: '36px 48px',
}

export const accountSettingWrapperCss = css({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
})
