import { useCallback, useEffect, useMemo } from 'react'

import { OKR_MEETING_HASH, generateCheckinSummary, generateOkr } from '../../../../../urls'
import { CheckinAreaKeyResultFragment } from '../../graphql'

import { CheckinArea } from './CheckinArea'
import { CHECKIN_FETCH_COUNT } from './constants'
import {
  FindCheckinSummariesByOkrNodeIdQueryVariables,
  useFindCheckinSummariesByOkrNodeIdLazyQuery,
} from './graphql'

export type CheckinAreaContainerProps = {
  okrNodeId: FindCheckinSummariesByOkrNodeIdQueryVariables['okrNodeId'] | null | undefined
  keyResults: ReadonlyArray<CheckinAreaKeyResultFragment> | null | undefined
}

export const CheckinAreaContainer: React.VFC<CheckinAreaContainerProps> = ({
  okrNodeId,
  keyResults,
}) => {
  const [checkinSummariesQuery, { data, loading, fetchMore }] =
    useFindCheckinSummariesByOkrNodeIdLazyQuery()

  useEffect(() => {
    if (okrNodeId) {
      checkinSummariesQuery({
        variables: {
          okrNodeId,
          first: CHECKIN_FETCH_COUNT,
        },
      })
    }
  }, [checkinSummariesQuery, okrNodeId])

  const checkinSummaries = useMemo(
    () => data?.findCheckinSummariesByOkrNodeId.edges.map(({ node }) => node) ?? [],
    [data?.findCheckinSummariesByOkrNodeId.edges],
  )

  const handleFetchMore = useCallback(() => {
    if (!data || !fetchMore) return

    fetchMore({
      variables: {
        okrNodeId,
        after: data?.findCheckinSummariesByOkrNodeId.pageInfo.endCursor,
      },
    })
  }, [data, fetchMore, okrNodeId])

  const handleOpenCheckinSummary = useCallback(
    (id: string, isOkrMeeting: boolean) => {
      if (okrNodeId == null) return undefined
      if (isOkrMeeting) {
        return `${generateOkr(okrNodeId)}${OKR_MEETING_HASH}`
      }
      return generateCheckinSummary(okrNodeId, id)
    },
    [okrNodeId],
  )

  if (data == null || loading) {
    return (
      <>
        <CheckinArea>
          <CheckinArea.Header />
          <CheckinArea.Skeleton />
        </CheckinArea>
      </>
    )
  }

  if (checkinSummaries.length === 0) {
    return (
      <CheckinArea>
        <CheckinArea.Header />
        <CheckinArea.EmptyView />
      </CheckinArea>
    )
  }

  return (
    <CheckinArea>
      <CheckinArea.Header showCheckinButton keyResults={keyResults} okrNodeId={okrNodeId} />
      <CheckinArea.List
        data={checkinSummaries}
        showFetchMore={data.findCheckinSummariesByOkrNodeId.pageInfo.hasNextPage}
        onClickFetchMore={handleFetchMore}
        onClickOpenCheckinSummary={handleOpenCheckinSummary}
      />
    </CheckinArea>
  )
}
CheckinAreaContainer.displayName = 'CheckinAreaContainer'
