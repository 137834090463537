import { Box } from 'grommet'

import { AvatarWithName } from '../../../../components/ui/AvatarWithName'
import { Button } from '../../../../components/ui/Button'
import { useTranslation } from '../../../../i18n'
import { color } from '../../../../styles/newColors'
import { RemainingLicensesArea } from '../../../AdminUsers/components/RemainingLicensesArea'

import { AvatarAndButtonSectionProps } from './types'

export const AvatarAndButtonSection: React.FC<AvatarAndButtonSectionProps> = ({
  user,
  isOwnPage,
  remainingLicenses,
  onUpdateActivateStatus,
  onResendInvitation,
  setDeleteModalVisibility,
  setDisabledModalVisibility,
}) => {
  const { t } = useTranslation()
  return (
    <Box width="100%" direction="row" gap="8px" align="center" justify="between">
      <div css={{ minWidth: 0 }}>
        <AvatarWithName
          firstName={user.firstName}
          lastName={user.lastName}
          avatarUrl={user.avatar?.url}
          linkage={{ userId: user.id }}
          isUserDisabled={user.isDisabled}
          size="mediumShort"
          fontStyle={{ fontSize: '20px', lineHeight: '28px', fontWeight: 700 }}
        />
      </div>
      {user.isDisabled && remainingLicenses != null && (
        <RemainingLicensesArea
          remainingLicenses={remainingLicenses}
          backgroundColor={color('white-100')}
        />
      )}
      <div css={{ width: 'fit-content' }}>
        {!user.confirmedAt ? (
          <div css={{ display: 'flex', gap: '8px' }}>
            <Button
              size="s"
              weight="normal"
              newColor="white-100"
              onClick={() => onResendInvitation(user.id)}
            >
              {t('RESEND_X', { x: t('INVITATION') })}
            </Button>
            <Button
              size="s"
              weight="normal"
              newColor="resily-orange-100"
              onClick={() => setDeleteModalVisibility(true)}
            >
              {t('DELETION_X', { x: t('USER') })}
            </Button>
          </div>
        ) : (
          <Button
            newColor="resily-orange-100"
            weight="normal"
            size="s"
            disabled={
              // 自身のページ or 無効化されているユーザーで付与できるライセンス数ない場合
              isOwnPage || (user.isDisabled && remainingLicenses != null && remainingLicenses < 1)
            }
            onClick={() => {
              if (user.isDisabled) {
                onUpdateActivateStatus(user.id)
              } else {
                setDisabledModalVisibility(true)
              }
            }}
          >
            {user.isDisabled
              ? t('ACTIVATING_X', { x: t('USER') })
              : t('DISABLING_X', { x: t('USER') })}
          </Button>
        )}
      </div>
    </Box>
  )
}
