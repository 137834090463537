import { ConfidenceSelect, ConfidenceSelectProps, IconButton, Tooltip } from '@resily/geisha'
import { useMemo, useState } from 'react'

import { Trans, useTranslation } from '../../../../i18n'
import { confidenceList } from '../../../../lib/domain/confidence'
import {
  isQualitativeGoal as isQualitativeGoalKeyResult,
  isMeasurementInvalid as isMeasurementInvalidKeyResult,
} from '../../../../lib/domain/keyResult/keyResultProgress'
import { newToOldConfidence, old2NewConfidence } from '../../../../lib/geisha/confidence'
import { componentNames, featureNames, generateTestId } from '../../../../lib/testId'
import { ExternalUrls } from '../../../../urls'
import { FormField } from '../../../ui/FormField'
import { Icon } from '../../../ui/Icon'
import { Link } from '../../../ui/Link'
import { NumberInput } from '../../../ui/NumberInput'
import { StyledText } from '../../../ui/StyledText'
import { Textarea } from '../../../ui/Textarea'
import { SelectUnit } from '../../SelectUnit'
import { KeyResultUpdateType } from '../graphql'
import { KeyResultEditType, keyResultEditTypeObj } from '../types'

import { useStyles } from './index.styles'
import { Error } from './types'

export type Props = {
  type: KeyResultEditType
  targetValue: number | null | undefined
  setTargetValue: (value: number) => void
  isAlreadySetTargetValue: boolean
  actualValue: number | null | undefined
  setActualValue: (value: number) => void
  initialValue: number | null | undefined
  setInitialValue: (value: number) => void
  unit: string | null | undefined
  setUnit: (unit: string) => void
  setUpdateMemo: (message: string) => void
  confidence: number | null | undefined
  setConfidence: (confident: number) => void
  isCurrentUserAssigned: boolean
  error?: Error
}

export const ProgressRateInput: React.FC<Props> = ({
  type,
  targetValue,
  setTargetValue,
  isAlreadySetTargetValue,
  actualValue,
  setActualValue,
  initialValue,
  setInitialValue,
  unit,
  setUnit,
  setUpdateMemo,
  confidence,
  setConfidence,
  isCurrentUserAssigned,
  error,
}) => {
  const { t } = useTranslation()
  const [openInitialValue, setOpenInitialValue] = useState<boolean>(false)

  // TODO
  const options = useMemo(
    () =>
      confidenceList.map<ConfidenceSelectProps['options'][0]>(([level, i18nKey]) => ({
        value: old2NewConfidence(level) ?? 3,
        label: t(i18nKey),
      })) as ConfidenceSelectProps['options'],
    [t],
  )

  const isUpdateMemoInputEnabled = type === keyResultEditTypeObj.update

  const isQualitative = isQualitativeGoalKeyResult(t, targetValue, initialValue, unit)
  const isMeasurementInvalid = isMeasurementInvalidKeyResult({
    id: '',
    updateType: KeyResultUpdateType.Automate,
    targetValue,
    initialValue: initialValue ?? 0,
  })

  const styles = useStyles(
    isUpdateMemoInputEnabled,
    isQualitative,
    !targetValue,
    isMeasurementInvalid,
  )

  return (
    <div css={styles.root}>
      <StyledText size="small" fontStyle="bold" css={styles.label}>
        {t('PROGRESS_RATE_AND_CONFIDENCE')}
      </StyledText>
      <div css={styles.column}>
        <div css={styles.field}>
          <div>
            <div css={styles.progressFieldWrapper}>
              {type === keyResultEditTypeObj.update && isAlreadySetTargetValue && (
                <div>
                  <StyledText size="xsmall" color="text-bk-50" css={styles.progressFieldLabel}>
                    {t('ACTUAL_VALUE')}
                  </StyledText>
                  <div css={styles.progressFieldInputWrapper}>
                    <NumberInput
                      defaultValue={actualValue || 0}
                      isError={!!error?.actualValue}
                      onChange={(e) => setActualValue(Number(e.target.value))}
                    />
                    {unit && (
                      <Tooltip position="bottom" label={unit}>
                        <span css={styles.progressFieldUnitLabel}>{unit}</span>
                      </Tooltip>
                    )}
                  </div>
                  {error?.actualValue && <p css={styles.error}>{error.actualValue}</p>}
                </div>
              )}
              <div>
                <StyledText size="xsmall" color="text-bk-50" css={styles.progressFieldLabel}>
                  {t('TARGET_VALUE')}
                </StyledText>
                <NumberInput
                  defaultValue={targetValue || 0}
                  isError={!!error?.targetValue}
                  onChange={(e) => setTargetValue(Number(e.target.value))}
                />
                {error?.targetValue && <p css={styles.error}>{error.targetValue}</p>}
              </div>
              <div>
                <StyledText size="xsmall" color="text-bk-50" css={styles.progressFieldLabel}>
                  {t('TARGET_VALUE_UNIT')}
                </StyledText>
                <SelectUnit
                  data-testid={generateTestId(
                    featureNames.keyResultCreateModal,
                    componentNames.unitSelect,
                  )}
                  width={70}
                  height={14}
                  value={unit}
                  onChange={setUnit}
                />
              </div>
              {openInitialValue ? (
                <div>
                  <StyledText size="xsmall" color="text-bk-50" css={styles.progressFieldLabel}>
                    {t('INITIAL_VALUE')}
                  </StyledText>
                  <NumberInput
                    defaultValue={initialValue || 0}
                    isError={!!error?.initialValue}
                    onChange={(e) => setInitialValue(Number(e.target.value))}
                  />
                  {error?.initialValue && <p css={styles.error}>{error.initialValue}</p>}
                </div>
              ) : (
                <div css={styles.initial}>
                  <StyledText size="xsmall" color="text-bk-50" css={styles.progressFieldLabel}>
                    {t('INITIAL_VALUE')}: {initialValue ?? 0}
                  </StyledText>
                  <IconButton type="edit" size="small" onClick={() => setOpenInitialValue(true)} />
                </div>
              )}
            </div>
            {/* 定性的な目標から変更することを催促するメッセージ */}
            {isQualitative && (
              <div css={styles.promptMessageWrapper}>
                <Icon type="info" width={12} height={12} css={styles.promptMessageIcon} />
                <StyledText size="xsmall" css={styles.promptMessage}>
                  <Trans
                    i18nKey="QUALITATIVE_GOAL_CAUTION_MESSAGE"
                    components={[
                      <Link method="newTab" href={ExternalUrls.FAQ_WORRY_OKR} css={styles.link}>
                        こちらのページ
                      </Link>,
                    ]}
                  />
                </StyledText>
              </div>
            )}
            {/* 目標値入力を催促するメッセージ */}
            {targetValue == null && (
              <div css={styles.promptMessageWrapper}>
                <Icon type="info" width={12} height={12} css={styles.promptMessageIcon} />
                <StyledText size="xsmall" css={styles.promptMessage}>
                  {t('SET_TARGET_VALUE_TO_MEASURE_PROGRESS_RATE')}
                </StyledText>
              </div>
            )}
            {/* 進捗率を測定可能な状態に変更することを催促するメッセージ */}
            {isMeasurementInvalid && (
              <div css={styles.promptMessageWrapper}>
                <Icon type="info" width={12} height={12} css={styles.promptMessageIcon} />
                <StyledText size="xsmall" css={styles.promptMessage}>
                  {t('NO_MEASURE_CAUTION_MESSAGE')}
                </StyledText>
              </div>
            )}
          </div>
          <div>
            <StyledText size="xsmall" color="text-bk-50" css={styles.progressFieldLabel}>
              {t('CONFIDENCE')}
            </StyledText>
            {isCurrentUserAssigned ? (
              <div css={styles.confidenceSelect}>
                <ConfidenceSelect
                  options={options}
                  value={old2NewConfidence(confidence) ?? 3}
                  onSelect={(level) => {
                    setConfidence(newToOldConfidence(level) ?? 0)
                  }}
                  disabled={!isCurrentUserAssigned}
                />
              </div>
            ) : (
              <StyledText
                size="small"
                color="text-bk-50"
                fontStyle="regular"
                css={styles.confidenceInputMessage}
              >
                {t('CONFIDENCE_INPUT_MESSAGE')}
              </StyledText>
            )}
          </div>
        </div>
        {/* 更新メモの入力 */}
        {isUpdateMemoInputEnabled && (
          <div css={styles.updateMemoArea}>
            <StyledText
              size="xsmall"
              color="text-bk-50"
              lineHeight="12px"
              css={styles.updateMemoLabel}
            >
              {t('UPDATE_MEMO')}
            </StyledText>
            <FormField css={styles.updateMemoInput}>
              <Textarea
                name="message"
                disableNewline
                placeholder={t('SHARE_THE_UPDATE_MEMO')}
                onInput={(e) => setUpdateMemo(e.currentTarget.value)}
                height={40}
              />
            </FormField>
          </div>
        )}
      </div>
    </div>
  )
}
