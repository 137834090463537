import { css } from '@emotion/react'
import { useMemo } from 'react'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useStyles = () =>
  useMemo(
    () =>
      ({
        root: css({
          padding: '12px 48px 24px',
          display: 'flex',
          flexDirection: 'column',
        }),
      } as const),
    [],
  )
