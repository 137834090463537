import { Box, Button } from 'grommet'
import { useCallback, useEffect, useRef, useState } from 'react'

import { useTranslation } from '../../../i18n'
import { useClickOutside } from '../../../lib/clickOutside'
import { getUserSearchText } from '../../../lib/domain/user/searchText'
import { color } from '../../../styles/newColors'
import { ExternalUrls } from '../../../urls'
import { Avatar } from '../../ui/Avatar'
import { Icon } from '../../ui/Icon'
import { MultiSelect, Option } from '../../ui/MultiSelect'
import { PopoverPortal } from '../../ui/PopoverPortal'
import { StyledText } from '../../ui/StyledText'
import { TooltipNew } from '../../ui/TooltipNew'
import { UserTag } from '../UserTag'

import { UserFragment } from './graphql'

export type Props = {
  title: string
  termId: string
  isAdmin?: boolean
  userOptions: ReadonlyArray<UserFragment>
  selectedUsers: ReadonlyArray<UserFragment>
  onAdd: (user: UserFragment) => void
  onRemove: (user: UserFragment) => void
}

export const SelectedUserTags: React.FC<Props> = ({
  title,
  termId,
  isAdmin,
  userOptions,
  selectedUsers,
  onAdd,
  onRemove,
}) => {
  const { t } = useTranslation()
  const multiSelectRef = useRef<HTMLDivElement>(null)
  const selectUserRef = useRef<HTMLDivElement>(null)
  const [isOpenMemberMultiSelect, setIsOpenMemberMultiSelect] = useState(false)
  const [optionList, setOptionList] = useState<ReadonlyArray<Option>>([])

  useClickOutside({ callback: () => setIsOpenMemberMultiSelect(false), ref: multiSelectRef })

  const isSelectedUser = useCallback(
    (user: UserFragment) => selectedUsers.some((u) => u.id === user.id),
    [selectedUsers],
  )

  useEffect(() => {
    setOptionList(
      userOptions
        .map<Option>((user) => ({
          id: user.id,
          name: t('FULL_NAME', { firstName: user.firstName, lastName: user.lastName }),
          searchText: getUserSearchText(user),
          selected: isSelectedUser(user),
          isDisabled: user.isDisabled,
          icon: (
            <Avatar
              tooltipDisabled
              firstName={user.firstName}
              lastName={user.lastName}
              avatarUrl={user.avatar?.url}
              size="xsmall"
              css={{ marginRight: '8px' }}
              isUserDisabled={user.isDisabled}
            />
          ),
        }))
        .sort((a, b) => (b.selected ? 1 : -1) - (a.selected ? 1 : -1)),
    )
  }, [isSelectedUser, t, userOptions])

  return (
    <Box gap="4px">
      <Box direction="row" gap="25px">
        <Box direction="row" gap="4px">
          <StyledText color="text-bk-50" fontStyle="bold">
            {title}
          </StyledText>
          {isAdmin && (
            <PopoverPortal
              portalPosition={{
                top: 20,
                left: -100,
              }}
              icon="help"
              iconCss={{
                color: color('text-bk-50'),
                ':hover': {
                  color: color('resily-orange-100'),
                },
              }}
              text={t('GROUP_ADMINISTRATOR_HELP')}
              link={{
                text: t('SUPPORT_SITE'),
                href: ExternalUrls.HELP_PAGE,
              }}
              css={{
                paddingTop: '5px',
              }}
            />
          )}
        </Box>
        <TooltipNew title={t('EDITION')}>
          <Button
            onClick={() => {
              setIsOpenMemberMultiSelect(!isOpenMemberMultiSelect)
            }}
          >
            <Icon
              type="editSimple"
              color={color('text-bk-50')}
              hoverColor="resily-orange-100"
              css={{
                width: '14px',
                height: '14px',
              }}
            />
          </Button>
        </TooltipNew>
        {isOpenMemberMultiSelect && (
          <div
            ref={multiSelectRef}
            css={{
              position: 'absolute',
              top: `${selectUserRef.current?.offsetTop}px`,
              zIndex: 10,
            }}
          >
            <MultiSelect
              canSearch
              onBlurFromMutliSelect={() => setIsOpenMemberMultiSelect(false)}
              searchPlaceholder={t('INPUT_X', { x: t('USER_NAME') })}
              options={optionList}
              onClickOption={(id) => {
                const user = userOptions.find((u) => u.id === id)
                if (!user) return
                if (isSelectedUser(user)) {
                  onRemove(user)
                } else {
                  onAdd(user)
                }
              }}
            />
          </div>
        )}
      </Box>
      <div
        ref={selectUserRef}
        css={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '8px',
        }}
      >
        {selectedUsers.map((user) => (
          <UserTag key={user.id} termId={termId} isUserDisabled={user.isDisabled} {...user} />
        ))}
      </div>
    </Box>
  )
}

SelectedUserTags.displayName = 'SelectedUserTags'
