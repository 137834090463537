import { useCallback, useState } from 'react'

import { useTranslation } from '../../../i18n'
import { fontSize } from '../../../styles/font'
import { color } from '../../../styles/newColors'
import { Icon } from '../../ui/Icon'
import { Modal } from '../../ui/Modal'
import { StyledText } from '../../ui/StyledText'

import { useGetAdminUsers } from './useGetAdminUsers'

export type Props = {
  isOpened: boolean
}

export const NoLeftLicensesForGeneralModal: React.FC<Props> = ({ isOpened }) => {
  const { t } = useTranslation()
  const [isOpenModal, setIsOpenModal] = useState<boolean>(isOpened)
  const adminUsers = useGetAdminUsers()
  const onClose = useCallback(() => setIsOpenModal(false), [])

  return (
    <Modal
      isOpened={isOpenModal}
      title="ライセンス数が不足しています"
      onClickClose={onClose}
      onClickOutside={onClose}
    >
      <div css={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 24 }}>
        <Icon type="noLeftLicense" width={300} height={148} />
        <div css={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
          <span css={{ ...fontSize('medium', 'regular'), whiteSpace: 'pre-wrap' }}>
            現在Resilyを使用しているユーザー数に対してライセンス数が足りません。
            Resilyを利用するためには管理者の方へライセンスの購入、またはユーザー
            数の調整を行うよう依頼してください。
          </span>
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: color('background-bk-5'),
              borderRadius: 4,
              padding: '8px 16px',
            }}
          >
            <StyledText color="text-bk-50" size="medium">
              管理者
            </StyledText>
            {adminUsers?.map((user, _) => (
              <StyledText>
                {`${t('FULL_NAME', { lastName: user.lastName, firstName: user.firstName })}：${
                  user.email
                }`}
              </StyledText>
            ))}
          </div>
        </div>
      </div>
    </Modal>
  )
}
