import React, { PropsWithoutRef, useRef } from 'react'
import { useDeepCompareEffect } from 'react-use'

type Props = PropsWithoutRef<JSX.IntrinsicElements['div']> & {
  height?: string
  onReachEnd?: () => void
  needMore?: boolean
  children: React.ReactNodeArray
}

export const InfiniteScroll: React.FC<Props> = ({
  height = '100%',
  needMore = false,
  onReachEnd,
  children,
  ...props
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const refInner = useRef<HTMLDivElement>(null)

  useDeepCompareEffect(() => {
    if (!needMore) return
    if (!onReachEnd) return

    const { current } = ref
    if (!current) return
    const { current: inner } = refInner
    if (!inner) return

    if (inner.clientHeight <= current.clientHeight) {
      onReachEnd()
    }
  }, [needMore, ref, refInner, onReachEnd])

  const handleOnScroll = (e: React.UIEvent<HTMLElement>) => {
    if (!onReachEnd) {
      return
    }

    const element = e.target as HTMLDivElement
    const { scrollHeight, scrollTop, clientHeight } = element
    if (scrollTop && scrollHeight - scrollTop <= clientHeight) {
      onReachEnd()
    }
  }

  return (
    <div
      ref={ref}
      onScroll={handleOnScroll}
      css={{ height, overflowY: 'scroll', width: '100%' }}
      {...props}
    >
      <div ref={refInner}>{children}</div>
    </div>
  )
}

InfiniteScroll.displayName = 'InfiniteScroll'
