import { MouseEventHandler } from 'react'

import { color } from '../../../../../styles/newColors'
import { Icon } from '../../../../ui/Icon'

import { useStyles } from './CloseButton.styles'

export type CloseButtonProps = {
  onClick: MouseEventHandler<HTMLButtonElement>
}

export const CloseButton: React.VFC<CloseButtonProps> = ({ onClick }) => {
  const styles = useStyles()
  return (
    <button type="button" onClick={onClick} css={styles.closeButton}>
      <Icon type="clear" color={color('text-bk-30')} width={20} height={20} />
    </button>
  )
}
CloseButton.displayName = 'CloseButton'
