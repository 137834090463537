import { css } from '@emotion/react'
import React from 'react'

import { StyledText } from '../../../../../components/ui/StyledText'

const formWrapperCss = css({ display: 'flex', flexDirection: 'column', gap: '8px' })

type TitleSectionChildrenTypes = {
  Title: typeof FormTitle
  Description: typeof FormDescription
}

type Props = {
  children: React.ReactNode
}

export const TitleSection: React.VFC<Props> & TitleSectionChildrenTypes = ({ children }) => (
  <div css={formWrapperCss}>{children}</div>
)

type FormTitleProps = {
  children: React.ReactNode
}

const FormTitle: React.VFC<FormTitleProps> = ({ children }) => (
  <StyledText weight="bold" size="large">
    {children}
  </StyledText>
)

type FormDescriptionProps = {
  children: React.ReactNode
}

const FormDescription: React.VFC<FormDescriptionProps> = ({ children }) => (
  <StyledText size="medium">{children}</StyledText>
)

TitleSection.Title = FormTitle
TitleSection.Description = FormDescription
