import { useCallback, useContext, useMemo, useState, VFC } from 'react'

import { Breadcrumb, BreadcrumbParams } from '../../components/domain/Breadcrumb'
import { PageContent } from '../../components/pageContent'
import { Link } from '../../components/ui/Link'
import { StyledText } from '../../components/ui/StyledText'
import { OrganizationContext } from '../../contexts/OrganizationContext'
import { useCurrentUser } from '../../contexts/UserContext'
import { useTranslation } from '../../i18n'
import { useSelfServeConfig } from '../../lib/domain/organization/useSelfServeConfig'
import { Screen } from '../../lib/screen'
import { useTracking } from '../../lib/tracking'
import { color } from '../../styles/newColors'
import { AuthRouteProps } from '../../types/authRouteProps'
import * as urls from '../../urls'

import { BeforeSubscriptionContent } from './components/BeforeSubscriptionContent'
import { Card } from './components/Card'
import { ChangeLicenseTutorialModal } from './components/ChangeLicenseTutorialModal'
import { ExpiredContent } from './components/ExpiredContent'
import { SubscriptionContent } from './components/SubscriptionContent'
import { contentWrapperCss, linkCss, titleSectionCss, trialEndWarningCss } from './styles'

export const AdminBillingContainer: VFC<AuthRouteProps> = () => {
  const organization = useContext(OrganizationContext)
  const selfServeConfig = useSelfServeConfig()
  const user = useCurrentUser()
  if (!organization || !user || !user.admin || !selfServeConfig.isSelfServe) {
    return null
  }

  return (
    <PageContent
      layout={{ css: { padding: '48px 36px 40px' } }}
      contentBackgroundColor={color('background-bk-5')}
    >
      <AdminBilling
        stripePublicApiKey={process.env.REACT_APP_STRIPE_PUBLIC_API_KEY}
        stripe1stPricingTableId={process.env.REACT_APP_STRIPE_1ST_PRICING_TABLE_ID}
        organizationId={organization.id}
        email={user.email}
        isStripeCustomer={selfServeConfig.stripeCustomerID != null}
        stripeExpired={selfServeConfig.state === 'subscription-expired'}
      />
    </PageContent>
  )
}
AdminBillingContainer.displayName = 'AdminBillingContainer'

export type Props = {
  stripePublicApiKey: string
  stripe1stPricingTableId: string
  organizationId: string
  email: string
  isStripeCustomer: boolean
  stripeExpired: boolean
}

export const AdminBilling: VFC<Props> = ({
  stripePublicApiKey,
  stripe1stPricingTableId,
  organizationId,
  email,
  isStripeCustomer,
  stripeExpired,
}) => {
  const { t } = useTranslation()
  useTracking(t('ADMIN_BILLING'), Screen.Billing)
  const [isShowTutorialModal, setIsShowTutorialModal] = useState<boolean>(false)
  const handleOpenTutorialModal = useCallback(() => {
    setIsShowTutorialModal(true)
  }, [])
  const handleCloseTutorialModal = useCallback(() => {
    setIsShowTutorialModal(false)
  }, [])

  const breadcrumbs = useMemo<BreadcrumbParams>(
    () => ({
      url: urls.billingAdmin,
      items: [{ breadcrumbName: 'setting' }, { breadcrumbName: 'billing' }],
    }),
    [],
  )

  return (
    <>
      <ChangeLicenseTutorialModal
        isOpened={isShowTutorialModal}
        onClose={handleCloseTutorialModal}
      />
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <div css={contentWrapperCss}>
        <div css={titleSectionCss}>
          <StyledText weight="bold" size="xlarge">
            プラン・お支払い
          </StyledText>
          {isStripeCustomer ? (
            <StyledText size="medium">プランの確認とお支払い履歴を確認します。</StyledText>
          ) : (
            <>
              <StyledText size="medium">
                以下からプランを選択し登録を進めてください。招待可能なライセンス数の設定もできます、ライセンス数の変更方法は
                <Link onClick={handleOpenTutorialModal} css={linkCss}>
                  こちら
                </Link>
              </StyledText>
              <div css={trialEndWarningCss}>
                <StyledText color="tag-red" fontStyle="regular">
                  有償プランをお申込みの場合、招待可能な上限ユーザー数は購入された「数量」と同じになります。
                </StyledText>
                <StyledText color="tag-red" fontStyle="regular">
                  現在ご利用中の無料トライアルでは上限ユーザー数が設定されていないため、プラン契約のときは十分な数量が選択されているかご確認ください。
                </StyledText>
              </div>
            </>
          )}
        </div>
        <Card>
          {/* stripeで未購入のユーザーに見せる */}
          {!isStripeCustomer && (
            <BeforeSubscriptionContent
              stripePublicApiKey={stripePublicApiKey}
              stripe1stPricingTableId={stripe1stPricingTableId}
              organizationId={organizationId}
              email={email}
            />
          )}

          {/* サブスク契約中のユーザーに見せる */}
          {isStripeCustomer && !stripeExpired && <SubscriptionContent />}

          {/* 一度サブスクを契約し、それが終了したユーザーに見せる */}
          {stripeExpired && stripeExpired && <ExpiredContent />}
        </Card>
      </div>
    </>
  )
}
AdminBilling.displayName = 'AdminBilling'
