import { PropsWithoutRef } from 'react'

import * as NewColor from '../../../styles/newColors'

import { ReactComponent as SvgActionPlan } from './action_plan.svg'
import { ReactComponent as SvgActivity } from './activity.svg'
import { ReactComponent as SvgAddPhoto } from './add_photo.svg'
import { ReactComponent as SvgAgenda } from './agenda.svg'
import { ReactComponent as SvgArrowBack } from './arrow_back.svg'
import { ReactComponent as SvgAttachFile } from './attach_file.svg'
import { ReactComponent as SvgBell } from './bell.svg'
import { ReactComponent as SvgBlock } from './block.svg'
import { ReactComponent as SvgBlockQuote } from './block_quote.svg'
import { ReactComponent as SvgBold } from './bold.svg'
import { ReactComponent as SvgBookmark } from './bookmark.svg'
import { ReactComponent as SvgBookmark2 } from './bookmark2.svg'
import { ReactComponent as SvgBuild } from './build.svg'
import { ReactComponent as BulkUpdateClose } from './bulkUpdateClose.svg'
import { ReactComponent as SvgBulletedList } from './bulleted_list.svg'
import { ReactComponent as SvgCallMade } from './call_made.svg'
import { ReactComponent as SvgCamera } from './camera.svg'
import { ReactComponent as SvgChannelTalk } from './channel_talk.svg'
import { ReactComponent as SvgChannelTalkNotification } from './channel_talk_notification.svg'
import { ReactComponent as SvgChart } from './chart.svg'
import { ReactComponent as SvgChatworkLogo } from './chatwork_logo.svg'
import { ReactComponent as SvgCheck } from './check.svg'
import { ReactComponent as SvgCheckbox } from './check_box.svg'
import { ReactComponent as SvgCheckCircle } from './check_circle.svg'
import { ReactComponent as SvgCheckDocument } from './check_document.svg'
import { ReactComponent as CheckinImage } from './checkin_image.svg'
import { ReactComponent as SvgCheckinMeeting } from './checkin_meeting.svg'
import { ReactComponent as SvgCheckinSummary } from './checkin_summary.svg'
import { ReactComponent as SvgChevronDoubleLeft } from './chevron_double_left.svg'
import { ReactComponent as SvgChevronDoubleRight } from './chevron_double_right.svg'
import { ReactComponent as SvgClear } from './clear.svg'
import { ReactComponent as SvgClock } from './clock.svg'
import { ReactComponent as SvgClosed } from './closed.svg'
import { ReactComponent as SvgCodeBlock } from './code_block.svg'
import { ReactComponent as SvgCodeInline } from './code_inline.svg'
import { ReactComponent as SvgCollapse } from './collapse.svg'
import { ReactComponent as SvgCollapseGroup } from './collapse_group.svg'
import { ReactComponent as SvgCollapseUnderline } from './collapse_underline.svg'
import { ReactComponent as SvgComment } from './comment.svg'
import { ReactComponent as SvgCompany } from './company.svg'
import { ReactComponent as SvgConfidence } from './confidence.svg'
import { ReactComponent as SvgConfig } from './config.svg'
import { ReactComponent as SvgConnectAddCircle } from './connect_add_circle.svg'
import { ReactComponent as SvgConnectCircle } from './connect_circle.svg'
import { ReactComponent as SvgConnectSetting } from './connect_setting.svg'
import { ReactComponent as SvgCopy } from './copy.svg'
import { ReactComponent as SvgCountDown } from './count_down.svg'
import { ReactComponent as SvgCountUp } from './count_up.svg'
import { ReactComponent as SvgDate } from './date.svg'
import { ReactComponent as SvgDelete } from './delete.svg'
import { ReactComponent as SvgDeleteOutline } from './delete_outline.svg'
import { ReactComponent as SvgDivider } from './divider.svg'
import { ReactComponent as SvgDocument } from './document.svg'
import { ReactComponent as SvgDots } from './dots.svg'
import { ReactComponent as SvgDownload } from './download.svg'
import { ReactComponent as SvgDuplicate } from './duplicate.svg'
import { ReactComponent as SvgEdit } from './edit.svg'
import { ReactComponent as SvgEditCircle } from './edit_circle.svg'
import { ReactComponent as SvgEditOutline } from './edit_outline.svg'
import { ReactComponent as SvgEditSimple } from './edit_simple.svg'
import { ReactComponent as SvgEmotionGray } from './emotion_gray.svg'
import { ReactComponent as SvgEmptyCheckinSummary } from './empty_checkin_summary.svg'
import { ReactComponent as SvgError } from './error.svg'
import { ReactComponent as SvgExpand } from './expand.svg'
import { ReactComponent as SvgExpandFold } from './expand_fold.svg'
import { ReactComponent as SvgExpandGroup } from './expand_group.svg'
import { ReactComponent as SvgExpandUnderline } from './expand_underline.svg'
import { ReactComponent as SvgExternal } from './external.svg'
import { ReactComponent as SvgExternalLink } from './external_link.svg'
import { ReactComponent as SvgFilter } from './filter.svg'
import { ReactComponent as SvgFlag } from './flag.svg'
import { ReactComponent as SvgFocus } from './focus.svg'
import { ReactComponent as SvgFold } from './fold.svg'
import { ReactComponent as SvgGarbage } from './garbage.svg'
import { ReactComponent as SvgGroup } from './group.svg'
import { ReactComponent as SvgGroup2 } from './group2.svg'
import { ReactComponent as SvgGroupAdd } from './group_add.svg'
import { ReactComponent as SvgH1 } from './h1.svg'
import { ReactComponent as SvgH2 } from './h2.svg'
import { ReactComponent as SvgH3 } from './h3.svg'
import { ReactComponent as SvgH4 } from './h4.svg'
import { ReactComponent as SvgH5 } from './h5.svg'
import { ReactComponent as SvgH6 } from './h6.svg'
import { ReactComponent as SvgHelp } from './help.svg'
import { ReactComponent as SvgHighlight } from './highlight.svg'
import { ReactComponent as SvgHistory } from './history.svg'
import { ReactComponent as SvgHome } from './home.svg'
import { ReactComponent as SvgImage } from './image.svg'
import { ReactComponent as SvgInfo } from './info.svg'
import { ReactComponent as SvgInfoCircle } from './info_circle.svg'
import { ReactComponent as SvgItalic } from './italic.svg'
import { ReactComponent as SvgKeyboardArrowDown } from './keyboard_arrow_down.svg'
import { ReactComponent as SvgFilterOff } from './kr_filter_off.svg'
import { ReactComponent as SvgFilterOn } from './kr_filter_on.svg'
import { ReactComponent as SvgLink } from './link.svg'
import { ReactComponent as SvgLinkPage } from './link_page.svg'
import { ReactComponent as Lock } from './lock.svg'
import { ReactComponent as LockOpen } from './lock_open.svg'
import { ReactComponent as SvgMailboxCat } from './mailbox_cat.svg'
import { ReactComponent as SvgMap } from './map.svg'
import { ReactComponent as SvgMapCircle } from './map_circle.svg'
import { ReactComponent as SvgCalendarBlank } from './mdi_calendar-blank.svg'
import { ReactComponent as SvgMdiCancel } from './mdi_cancel.svg'
import { ReactComponent as SvgMdiCheck } from './mdi_check.svg'
import { ReactComponent as SvgMdiCheckBox } from './mdi_check_box.svg'
import { ReactComponent as SvgMdiCheckBoxOutlineBlank } from './mdi_check_box_outline_blank.svg'
import { ReactComponent as SvgMdiClear } from './mdi_clear.svg'
import { ReactComponent as SvgDragIndicator } from './mdi_drag_indicator.svg'
import { ReactComponent as SvgMdiIndeterminateCheckBox } from './mdi_indeterminate_check_box.svg'
import { ReactComponent as SvgMdiInsertEmoticon } from './mdi_insert_emoticon.svg'
import { ReactComponent as SvgMdiKeyboardArrowDown } from './mdi_keyboard_arrow_down.svg'
import { ReactComponent as SvgMdiList } from './mdi_list.svg'
import { ReactComponent as SvgMdiLock } from './mdi_lock.svg'
import { ReactComponent as SvgPersonAdd } from './mdi_person_add.svg'
import { ReactComponent as SvgMdiPublic } from './mdi_public.svg'
import { ReactComponent as SvgRadioButtonChecked } from './mdi_radio_button_checked.svg'
import { ReactComponent as SvgRadioButtonUnchecked } from './mdi_radio_button_unchecked.svg'
import { ReactComponent as SvgMenu } from './menu.svg'
import { ReactComponent as SvgMiniPlus } from './mini_plus.svg'
import { ReactComponent as SvgMinus } from './minus.svg'
import { ReactComponent as SvgMoon } from './moon.svg'
import { ReactComponent as SvgMoreCircle } from './more_circle.svg'
import { ReactComponent as SvgMoreDouble } from './more_double.svg'
import { ReactComponent as SvgMountain } from './mountain.svg'
import { ReactComponent as SvgMyPage } from './my_page.svg'
import { ReactComponent as SvgNewTab } from './new_tab.svg'
import { ReactComponent as SvgNoLeftLicense } from './no_left_license.svg'
import { ReactComponent as SvgNode } from './node.svg'
import { ReactComponent as SvgNote } from './note.svg'
import { ReactComponent as SvgNotes } from './notes.svg'
import { ReactComponent as SvgNotification } from './notification.svg'
import { ReactComponent as SvgNumberedList } from './numbered_list.svg'
import { ReactComponent as SvgOffline } from './offline.svg'
import { ReactComponent as SvgOkrEmpty } from './okr_empty.svg'
import { ReactComponent as SvgOkrLoading } from './okr_loading.svg'
import { ReactComponent as SvgOneOnOne } from './one_on_one.svg'
import { ReactComponent as SvgOpenInNew } from './open_in_new.svg'
import { ReactComponent as SvgOpened } from './opened.svg'
import { ReactComponent as SvgOutlineSearch } from './outline_search.svg'
import { ReactComponent as SvgPerson } from './person.svg'
import { ReactComponent as SvgPersonWhite } from './person_white.svg'
import { ReactComponent as SvgPieChart } from './pie_chart.svg'
import { ReactComponent as SvgPinOff } from './pin_off.svg'
import { ReactComponent as SvgPinOn } from './pin_on.svg'
import { ReactComponent as SvgPlus } from './plus.svg'
import { ReactComponent as SvgPolygon } from './polygon.svg'
import { ReactComponent as SvgPreCheckinMeeting } from './pre_checkin_meeting.svg'
import { ReactComponent as SvgProgressRate } from './progress_rate.svg'
import { ReactComponent as SvgReaction } from './reaction.svg'
import { ReactComponent as SvgRegisterTemplate } from './register_template.svg'
import { ReactComponent as SvgRemove } from './remove.svg'
import { ReactComponent as SvgRocket } from './rocket.svg'
import { ReactComponent as SvgRoof } from './roof.svg'
import { ReactComponent as SvgSelectDown } from './select_down.svg'
import { ReactComponent as SvgSetting } from './setting.svg'
import { ReactComponent as SvgSettingOutline } from './setting_outline.svg'
import { ReactComponent as SvgSignOut } from './sign_out.svg'
import { ReactComponent as SvgSlack } from './slack.svg'
import { ReactComponent as SvgSlackLogo } from './slack_logo.svg'
import { ReactComponent as SvgSmartHR } from './smarthr.svg'
import { ReactComponent as SvgSmartHRLogo } from './smarthr_logo.svg'
import { ReactComponent as SvgSpeechBalloon } from './speech_balloon.svg'
import { ReactComponent as SvgSpeechBubble } from './speech_bubble.svg'
import { ReactComponent as SvgSplit } from './split.svg'
import { ReactComponent as SvgStar } from './star.svg'
import { ReactComponent as SvgStrike } from './strikethrough.svg'
import { ReactComponent as SvgSubTree } from './sub_tree.svg'
import { ReactComponent as SvgSync } from './sync.svg'
import { ReactComponent as SvgTask } from './task.svg'
import { ReactComponent as SvgTeamsLogo } from './teams_logo.svg'
import { ReactComponent as SvgTree } from './tree.svg'
import { ReactComponent as SvgTreeView } from './tree_view.svg'
import { ReactComponent as SvgUnderline } from './underline.svg'
import { ReactComponent as User } from './user.svg'
import { ReactComponent as SvgUser2 } from './user2.svg'
import { ReactComponent as SvgUserList } from './user_list.svg'
import { ReactComponent as UserSetting } from './user_setting.svg'
import { ReactComponent as SvgWarning } from './warning.svg'
import { ReactComponent as SvgWin } from './win.svg'

const svgMap = {
  addPhoto: SvgAddPhoto,
  attachFile: SvgAttachFile,
  arrowBack: SvgArrowBack,
  activity: SvgActivity,
  agenda: SvgAgenda,
  bell: SvgBell,
  block: SvgBlock,
  build: SvgBuild,
  camera: SvgCamera,
  calendar: SvgCalendarBlank,
  channelTalk: SvgChannelTalk,
  channelTalkNotification: SvgChannelTalkNotification,
  notification: SvgNotification,
  chart: SvgChart,
  check: SvgCheck,
  checkCircle: SvgCheckCircle,
  checkDocument: SvgCheckDocument,
  clear: SvgClear,
  collapse: SvgCollapse,
  collapseUnderline: SvgCollapseUnderline,
  copy: SvgCopy,
  countDown: SvgCountDown,
  countUp: SvgCountUp,
  delete: SvgDelete,
  deleteOutline: SvgDeleteOutline,
  document: SvgDocument,
  dots: SvgDots,
  download: SvgDownload,
  duplicate: SvgDuplicate,
  edit: SvgEdit,
  editSimple: SvgEditSimple,
  editCircle: SvgEditCircle,
  editOutline: SvgEditOutline,
  emotionGray: SvgEmotionGray,
  error: SvgError,
  expand: SvgExpand,
  externalLink: SvgExternalLink,
  external: SvgExternal,
  filter: SvgFilter,
  focus: SvgFocus,
  garbage: SvgGarbage,
  group: SvgGroup,
  group2: SvgGroup2,
  groupAdd: SvgGroupAdd,
  note: SvgNote,
  lock: Lock,
  lockOpen: LockOpen,
  mailboxCat: SvgMailboxCat,
  map: SvgMap,
  mapCircle: SvgMapCircle,
  menu: SvgMenu,
  bookmark: SvgBookmark,
  minus: SvgMinus,
  moreCircle: SvgMoreCircle,
  moreDouble: SvgMoreDouble,
  mountain: SvgMountain,
  noLeftLicense: SvgNoLeftLicense,
  node: SvgNode,
  notes: SvgNotes,
  person: SvgPerson,
  plus: SvgPlus,
  progressRate: SvgProgressRate,
  miniPlus: SvgMiniPlus,
  config: SvgConfig,
  setting: SvgSetting,
  speechBalloon: SvgSpeechBalloon,
  speechBubble: SvgSpeechBubble,
  split: SvgSplit,
  star: SvgStar,
  tree: SvgTree,
  warning: SvgWarning,
  win: SvgWin,
  reaction: SvgReaction,
  registerTemplate: SvgRegisterTemplate,
  rocket: SvgRocket,
  mdiIndeterminateCheckBox: SvgMdiIndeterminateCheckBox,
  dragIndicator: SvgDragIndicator,
  mdiCheck: SvgMdiCheck,
  mdiCheckBox: SvgMdiCheckBox,
  mdiCheckBoxOutlineBlank: SvgMdiCheckBoxOutlineBlank,
  mdiInsertEmoticon: SvgMdiInsertEmoticon,
  mdiPublic: SvgMdiPublic,
  selectDown: SvgSelectDown,
  user: User,
  user2: SvgUser2,
  newTab: SvgNewTab,
  userSetting: UserSetting,
  h1: SvgH1,
  h2: SvgH2,
  h3: SvgH3,
  h4: SvgH4,
  h5: SvgH5,
  h6: SvgH6,
  bulletedList: SvgBulletedList,
  numberedList: SvgNumberedList,
  codeBlock: SvgCodeBlock,
  codeInline: SvgCodeInline,
  divider: SvgDivider,
  image: SvgImage,
  checkbox: SvgCheckbox,
  blockQuote: SvgBlockQuote,
  bold: SvgBold,
  italic: SvgItalic,
  strikeThrough: SvgStrike,
  underline: SvgUnderline,
  highlight: SvgHighlight,
  link: SvgLink,
  linkPage: SvgLinkPage,
  subTree: SvgSubTree,
  treeView: SvgTreeView,
  mdiClear: SvgMdiClear,
  mdiList: SvgMdiList,
  mdiKeyboardArrowDown: SvgMdiKeyboardArrowDown,
  mdiCancel: SvgMdiCancel,
  mdiLock: SvgMdiLock,
  radioButtonChecked: SvgRadioButtonChecked,
  radioButtonUnchecked: SvgRadioButtonUnchecked,
  personAdd: SvgPersonAdd,
  history: SvgHistory,
  home: SvgHome,
  help: SvgHelp,
  info: SvgInfo,
  infoCircle: SvgInfoCircle,
  settingOutline: SvgSettingOutline,
  actionPlan: SvgActionPlan,
  confidence: SvgConfidence,
  polygon: SvgPolygon,
  okrEmpty: SvgOkrEmpty,
  okrLoading: SvgOkrLoading,
  openInNew: SvgOpenInNew,
  opened: SvgOpened,
  closed: SvgClosed,
  fold: SvgFold,
  flag: SvgFlag,
  expandFold: SvgExpandFold,
  expandUnderline: SvgExpandUnderline,
  connectCircle: SvgConnectCircle,
  connectAddCircle: SvgConnectAddCircle,
  connectSetting: SvgConnectSetting,
  checkinImage: CheckinImage,
  checkinSummary: SvgCheckinSummary,
  emptyCheckinSummary: SvgEmptyCheckinSummary,
  checkinMeeting: SvgCheckinMeeting,
  preCheckinMeeting: SvgPreCheckinMeeting,
  signOut: SvgSignOut,
  chevronDoubleLeft: SvgChevronDoubleLeft,
  chevronDoubleRight: SvgChevronDoubleRight,
  bulkUpdateClose: BulkUpdateClose,
  moon: SvgMoon,
  roof: SvgRoof,
  slack: SvgSlack,
  slackLogo: SvgSlackLogo,
  smarthr: SvgSmartHR,
  smarthrLogo: SvgSmartHRLogo,
  filterOn: SvgFilterOn,
  filterOff: SvgFilterOff,
  remove: SvgRemove,
  keyboardArrowDown: SvgKeyboardArrowDown,
  callMade: SvgCallMade,
  comment: SvgComment,
  chatworkLogo: SvgChatworkLogo,
  teamsLogo: SvgTeamsLogo,
  pieChart: SvgPieChart,
  pinOn: SvgPinOn,
  pinOff: SvgPinOff,
  clock: SvgClock,
  task: SvgTask,
  company: SvgCompany,
  myPage: SvgMyPage,
  oneOnOne: SvgOneOnOne,
  userList: SvgUserList,
  bookmark2: SvgBookmark2,
  search: SvgOutlineSearch,
  offline: SvgOffline,
  sync: SvgSync,
  date: SvgDate,
  personWhite: SvgPersonWhite,
  expandGroup: SvgExpandGroup,
  collapseGroup: SvgCollapseGroup,
}

export type Type = keyof typeof svgMap

export const getClassName = <T extends Type>(type: T): `icon-${T}` => `icon-${type}`

export type Props = PropsWithoutRef<JSX.IntrinsicElements['svg']> & {
  type: Type
  hoverColor?: NewColor.ColorAlias
  width?: number
  height?: number
}

export const Icon: React.FC<Props> = ({
  width = 16,
  height = 16,
  type,
  color,
  hoverColor,
  ...props
}) => {
  const SvgComponent = svgMap[type]
  return (
    <SvgComponent
      className={getClassName(type)}
      css={{
        ':hover': {
          color: hoverColor && NewColor.color(hoverColor),
          'path[fill]': {
            color: hoverColor && NewColor.color(hoverColor),
            fill: hoverColor && NewColor.color(hoverColor),
          },
          'path[stroke]': {
            stroke: hoverColor && NewColor.color(hoverColor),
          },
          circle: {
            stroke: hoverColor && NewColor.color(hoverColor),
          },
        },
        verticalAlign: 'bottom',
        width,
        height,
        color,
      }}
      {...props}
    />
  )
}

Icon.displayName = 'Icon'

export const iconTypesForStorybook = Object.keys(svgMap) as ReadonlyArray<Type>
