import { css } from '@emotion/react'

import { fontSize } from '../../../../../../styles/font'
import { color } from '../../../../../../styles/newColors'

export const styles = {
  radioButtonWrapper: css({
    marginBottom: 16,
  }),
  activityCardWrapper: css({
    marginBottom: 12,
  }),
  fetchMoreButton: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: '16px 0',
    backgroundColor: color('white-100'),
    borderRadius: '8px',
    color: color('text-bk-50'),
    ...fontSize('medium', 'regular'),
    '&:hover': {
      color: color('resily-orange-100'),
    },
  }),
  emptyWrapper: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '60px 0',
    gap: 36,
  }),
  emptyInner: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 16,
  }),
}
