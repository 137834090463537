import { ActionPlanCardSkeleton } from './ActionPlanCardSkeleton'

export const ActionPlanSkeleton: React.VFC = () => (
  <>
    {new Array(3).fill(undefined).map(() => (
      <>
        <ActionPlanCardSkeleton />
        <ActionPlanCardSkeleton titleRows={2} />
      </>
    ))}
  </>
)
