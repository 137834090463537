import { useCallback, useLayoutEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { OKRTree } from '../../../lib/domain/okr/tree'

import { OkrNode } from './types'

export type SetCursorItem = (nodeId: string, centering?: boolean) => void

type Ret = [string, SetCursorItem, boolean]

export const useCenteringNode = (okrTree: OKRTree<OkrNode>): Ret => {
  const location = useLocation()
  const navigate = useNavigate()

  /**
   * ハッシュパラメータnodeIdの指定による中央寄せ
   * 最後にcenteringされたnodeIdが設定されている
   * cursorItemで指定されるアクティブなOkrNodeと一致しているとは限らない
   */
  const lastCenterNodeId = useMemo(
    () => new URLSearchParams(location.hash.substr(1)).get('nodeId'),
    [location.hash],
  )
  const [cursorItem, setCursorItem] = useState<string>(
    lastCenterNodeId ?? okrTree.getMaxSizeTree()?.id ?? '',
  )
  const [isCentering, setIsCentering] = useState<boolean>(
    (() => {
      setTimeout(() => setIsCentering(false), 0)
      return !!lastCenterNodeId
    })(),
  )
  const changeHashNodeId = useCallback(
    (nodeId: string) => {
      // NOTE: useQueryParamのsetQueryParamと同時に実行されると、queryParamの反映が打ち消されるためwindow.locationを参照しています
      const hash = new URLSearchParams(window.location.hash.substr(1))
      if (nodeId) {
        hash.set('nodeId', nodeId)
      } else {
        hash.delete('nodeId')
      }
      navigate(
        {
          ...window.location,
          hash: hash.toString(),
        },
        { replace: true },
      )
    },
    [navigate],
  )

  // NOTE: onCursorItemCenterがtrueの場合、ノードの再レンダリングで
  //       強制的にスクロール位置がリセットされてしまうためそれの回避処置
  const destruct = () => {
    setTimeout(() => {
      setIsCentering(false)
    }, 0)
  }

  /**
   * OKRカードにカーソルを当てるためのsetter
   * centering指定時は対象OKRカードを起点にセンタリングします
   */
  const wrappedSetCursorItem = useCallback<SetCursorItem>(
    (nodeId: string, centering?: boolean) => {
      setCursorItem(nodeId)
      changeHashNodeId(nodeId) // HashNodeIdはアクティブなNodeを表し、URLで復元できるようにする
      if (centering) {
        setIsCentering(true)
        destruct()
      }
    },
    [changeHashNodeId],
  )

  // 初回レンダリング時は子孫の数が多いもので中央寄せにする
  useLayoutEffect(() => {
    if (lastCenterNodeId == null) {
      setCursorItem(okrTree.getMaxSizeTree()?.id || '')
      return
    }

    setIsCentering(true)
    destruct()
  }, [lastCenterNodeId, okrTree])

  return [cursorItem, wrappedSetCursorItem, isCentering]
}
