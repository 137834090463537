import { OkrTitle } from '@resily/geisha'
import { useState } from 'react'

import { castTargetSetting } from '../../../../../../../lib/geisha/OkrTitle'
import { componentNames, featureNames, generateTestId } from '../../../../../../../lib/testId'
import { useGetFormMethods } from '../../../../form/contexts/FormProvider'
import { keyResultsKeyName } from '../../../../form/formSchema'
import { generateKeyResultLinkId } from '../../../../utils'
import { KeyResultSelectionHandler } from '../../../KeyResultSelectionHandler'

import { Activity } from './Activity'
import { krWrapperCss, okrTitleWrapperCss, rootCss } from './styles'
import { transformFormValues } from './utils/transformFormValues'

type Props = {
  onSelectKeyResult: (id: string) => void
}

export const ConfirmationArea: React.VFC<Props> = ({ onSelectKeyResult }) => {
  const { getValues } = useGetFormMethods()

  const keyResults = transformFormValues({ keyResults: getValues(keyResultsKeyName) })

  // NOTE: refに値が入っても、再レンダリングされないので、KeyResultSelectionHandlerのrootElementにはuseStateを使う
  const [rootElement, setRootElement] = useState<HTMLElement | null>(null)

  return (
    <div ref={setRootElement} css={rootCss}>
      {keyResults.map(
        ({
          keyResultId,
          objectiveName,
          keyResultName,
          targetSetting,
          keyResultEvents,
          keyResultComments,
          lastKeyResultComments,
        }) => (
          <KeyResultSelectionHandler
            key={keyResultId}
            id={generateKeyResultLinkId(keyResultId)}
            data-testid={generateTestId(featureNames.checkinModal, componentNames.listItem, {
              suffix: keyResultId,
            })}
            css={krWrapperCss}
            rootElement={rootElement}
            keyResultId={keyResultId}
            onSelectKeyResult={onSelectKeyResult}
          >
            {/** OKRタイトル */}
            <div css={okrTitleWrapperCss}>
              <OkrTitle type="objective" size="xsmall" title={objectiveName} withBorder />
              <OkrTitle
                type="keyResult"
                size="large"
                title={keyResultName}
                leftIconProps={castTargetSetting(targetSetting)}
                withBorder
              />
            </div>
            {/** Activity */}
            <Activity
              keyResultEvents={keyResultEvents}
              keyResultComments={keyResultComments}
              lastKeyResultComments={lastKeyResultComments}
            />
          </KeyResultSelectionHandler>
        ),
      )}
    </div>
  )
}

ConfirmationArea.displayName = 'ConfirmationArea'
