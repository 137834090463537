import { css } from '@emotion/react'

import { color } from '../../../../../../../../styles/newColors'

export const rootCss = css({
  overflowY: 'hidden',
})

export const formSkeletonItemWrapperCss = css({
  padding: '24px 32px 32px',
  borderBottom: `1px solid ${color('border-bk-10')}`,
  display: 'flex',
  flexDirection: 'column',
  gap: '36px',
})

export const okrTitleWrapperCss = css({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
})

export const objectiveTitleWrapperCss = css({ display: 'flex', alignItems: 'center' })

export const keyResultTitleWrapperCss = css({ display: 'flex', alignItems: 'center' })

export const keyResultTitleSkeletonWrapperCss = css({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
})

export const progressRateWrapperCss = css({
  display: 'flex',
  ':nth-of-type(2)': {
    div: { marginLeft: '24px' },
  },
  ':nth-of-type(3)': {
    div: { marginLeft: '18px' },
  },
  ':nth-of-type(4)': {
    div: { marginLeft: '16px' },
  },
})
