import { Tooltip, TooltipProps } from '@resily/geisha'
import { AnchorProps, Box, Anchor } from 'grommet'

import { InterpolationWithTheme } from '../../../lib/emotion'
import { color } from '../../../styles/newColors'
import { Icon, Props as IconProps } from '../Icon'
import { TooltipNew, TooltipNewProps } from '../TooltipNew'

export type Props = AnchorProps & {
  icon?: React.ReactNode
  rootCss?: InterpolationWithTheme
  iconToolTip?: TooltipNewProps & Pick<IconProps, 'type' | 'hoverColor'>
  disabled?: boolean
  tooltipLabel?: TooltipProps['label']
  onClick?: () => void
}

export const MenuItem: React.FC<Props> = ({
  disabled = false,
  rootCss,
  icon,
  iconToolTip,
  tooltipLabel,
  onClick,
  ...props
}) => {
  const menuList = (
    <Box
      as="li"
      direction="row"
      align="center"
      hoverIndicator={{ color: disabled ? '' : color('hover-background-bk-5') }}
      style={{ cursor: disabled ? 'default' : 'pointer' }}
      css={rootCss}
      onClick={() => {
        if (!disabled && onClick) {
          onClick()
        }
      }} // hoverIndicatorを機能させるにはonClickがtruthyである必要がある
    >
      {icon && <div css={{ marginRight: '12px' }}>{icon}</div>}
      <Anchor
        disabled={disabled}
        css={{
          display: 'block',
          opacity: 1,
        }}
        target="_blank"
        rel="noopener noreferrer"
        color={disabled ? color('text-bk-30') : color('text-bk-100')}
        {...props}
      />
      {iconToolTip && (
        <TooltipNew title={iconToolTip.title}>
          <Icon
            css={{ marginLeft: '8px' }}
            width={12}
            height={12}
            type={iconToolTip.type}
            hoverColor={iconToolTip.hoverColor}
          />
        </TooltipNew>
      )}
    </Box>
  )

  return tooltipLabel != null ? (
    // TODO: geishaのtooltipが親要素より大きくならないので、maxWidthからwidthに変更する必要がある
    <Tooltip label={tooltipLabel} position="bottom">
      {menuList}
    </Tooltip>
  ) : (
    menuList
  )
}

MenuItem.displayName = 'MenuItem'
