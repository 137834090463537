import { Box, Button, Spinner } from 'grommet'
import { Resizable, ResizeDirection, Size as ResizeSize } from 're-resizable'
import React, { useMemo, useCallback, useContext, useEffect } from 'react'

import { Icon } from '../../../components/ui/Icon'
import { OkrTermIdContext } from '../../../contexts/OkrTermIdContext'
import { useTranslation } from '../../../i18n'
import { border } from '../../../styles/border'
import { color } from '../../../styles/newColors'
import { Tab, Props as TabProps, TabProps as TabContentProps } from '../Tab'

import { AvatarArea } from './AvatarArea'
// import { OkrMeetingTabContent } from './OkrMeetingTabContent'
import { OkrTabContent, Props as OkrTabContentProps } from './OkrTabContent'
import {
  OneOnOneUserFragment,
  OkrNodeFragment,
  useInitialDataQuery,
  useOkrTermsQuery,
  useUserAssignOkrQuery,
} from './graphql'
import { useCloseDrawer, useDrawerState, useReplaceDrawerState } from './hooks/useDrawerState'
import { useDrawerWidth } from './hooks/useDrawerWidth'

const DRAWER_MIN_WIDTH = 380
const DRAWER_WIDTH = 560
const DRAWER_MAX_WIDTH = 1500
const DRAWER_HEADER_HEIGHT = 52

const ENABLE_RESIZING = {
  top: false,
  right: false,
  bottom: false,
  left: true,
  topRight: false,
  bottomRight: false,
  bottomLeft: false,
  topLeft: false,
} as const

export const OneOnOneDrawer: React.VFC = () => {
  const { updateOkrTermId } = useContext(OkrTermIdContext)
  const { openable, params } = useDrawerState()
  const [width, setWidth] = useDrawerWidth(DRAWER_WIDTH)

  const defaultSize: ResizeSize = { width, height: `100%` }
  const minWidth: number = useMemo(() => (openable ? DRAWER_MIN_WIDTH : 0), [openable])

  const handleDrawerResizeStop = useCallback(
    (e: MouseEvent | TouchEvent, _: ResizeDirection, resizeDrawerRef: HTMLElement) => {
      e.preventDefault()
      e.stopPropagation()

      if (e instanceof MouseEvent) {
        setWidth(resizeDrawerRef.offsetWidth)
      }
    },
    [setWidth],
  )

  useEffect(() => {
    if (params?.termId) {
      updateOkrTermId(params.termId)
    }
  }, [params?.termId, updateOkrTermId])

  return (
    <Resizable
      defaultSize={defaultSize}
      minWidth={minWidth}
      maxWidth={DRAWER_MAX_WIDTH}
      style={{
        display: openable ? undefined : 'none',
        boxShadow: '-1px 0px 0px rgb(0 0 0 / 10%)',
        backgroundColor: color('white-100'),
      }}
      enable={ENABLE_RESIZING}
      handleComponent={{
        left: <ResizeHandler />,
      }}
      handleStyles={{ left: { left: 0, width: '8px' } }}
      onResizeStop={handleDrawerResizeStop}
    >
      <Box
        css={{
          height: defaultSize.height,
        }}
      >
        <OneOnOneDrawerHeader />
        {params ? (
          <OneOnOneDrawerContent
            termId={params.termId}
            oneOnOneId={params.oneOnOneId}
            userId={params.userId}
            drawerWidth={width}
          />
        ) : null}
      </Box>
    </Resizable>
  )
}

OneOnOneDrawer.displayName = 'OneOnOneDrawer'

export const OneOnOneDrawerHeader: React.VFC = () => {
  const closeDrawer = useCloseDrawer()

  return (
    <div
      css={{
        display: 'flex',
        justifyContent: 'space-between',
        height: `${DRAWER_HEADER_HEIGHT}px`,
        alignItems: 'center',
        paddingLeft: '28px',
        paddingRight: '16px',
      }}
    >
      <Button
        onClick={() => {
          closeDrawer()
        }}
      >
        <Icon
          type="clear"
          css={{
            width: '20px',
            height: '20px',
            color: color('text-bk-50'),
            ':hover': { color: color('text-bk-100') },
          }}
        />
      </Button>
    </div>
  )
}

OneOnOneDrawerHeader.displayName = 'OneOnOneDrawerHeader'

type ContentProps = {
  termId: string
  oneOnOneId: string
  userId: string
  drawerWidth: number
}

const OneOnOneDrawerContent: React.VFC<ContentProps> = ({
  termId,
  oneOnOneId,
  userId,
  drawerWidth,
}) => {
  const { t } = useTranslation()

  const { data: initialData } = useInitialDataQuery({
    variables: { id: oneOnOneId },
    nextFetchPolicy: 'cache-only',
  })

  const selectedUser = useMemo<OneOnOneUserFragment | undefined>(() => {
    if (!initialData) {
      return undefined
    }
    return initialData.userOneOnOne.me.id === userId
      ? initialData.userOneOnOne.me
      : initialData.userOneOnOne.partnerUser
  }, [initialData, userId])

  const { data: okrTermsData } = useOkrTermsQuery()
  const terms = useMemo(() => (okrTermsData ? okrTermsData.okrTerms : []), [okrTermsData])

  const replaceDrawerState = useReplaceDrawerState()
  const onClickTermOption = useCallback<OkrTabContentProps['onClickTermOption']>(
    (selectedTermId) => {
      replaceDrawerState({ termId: selectedTermId })
    },
    [replaceDrawerState],
  )

  const { loading: loadingUserAssignOkr, data: userAssignOkr } = useUserAssignOkrQuery({
    variables: {
      userId,
      okrTermId: termId,
      historyLength: 1000, // FIXME: 初期何件取るのか精査が必要
    },
  })

  const okrNodes = useMemo<ReadonlyArray<OkrNodeFragment>>(() => {
    if (!userAssignOkr) {
      return []
    }
    const okrNodeMap = new Map<string, OkrNodeFragment>()
    if (userAssignOkr.findUserById) {
      userAssignOkr.findUserById?.ownerObjectives.forEach((objective) => {
        okrNodeMap.set(objective.node.id, objective.node)
      })

      userAssignOkr.findUserById?.keyResults.forEach((keyResult) => {
        okrNodeMap.set(keyResult.node.id, keyResult.node)
      })
    }

    // ソートして返す：ソートロジック → OKRノードの階層、OKRノードのdisplayOrder
    return Array.from(okrNodeMap.values()).sort((a: OkrNodeFragment, b: OkrNodeFragment) =>
      a.depth - b.depth !== 0 ? a.depth - b.depth : a.displayOrder - b.displayOrder,
    )
  }, [userAssignOkr])

  const tabs: Pick<TabProps, 'tabs'>['tabs'] = new Map<string, TabContentProps>([
    [
      'assigned-okr',
      {
        name: t('ASSIGNED_OKR'),
        content: (() =>
          loadingUserAssignOkr && !userAssignOkr?.findUserById ? (
            <OneOnOneDrawerLoadingContent />
          ) : (
            <div
              css={{
                height: '100%',
                padding: '24px 32px 0',
              }}
            >
              <OkrTabContent
                userId={userId}
                drawerWidth={drawerWidth}
                selectedTermId={termId}
                okrNodes={okrNodes}
                terms={terms}
                onClickTermOption={onClickTermOption}
              />
            </div>
          ))(),
      },
    ],
    /** TODO: OKR掲示板のリリース後にリリース
    [
      'okr-board',
      {
        name: '掲示板',
        content: <OkrMeetingTabContent />,
      },
    ],
    */
  ])

  return (
    <div
      style={{
        overflowY: 'auto',
        height: `calc(100% - ${DRAWER_HEADER_HEIGHT}px`,
      }}
    >
      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: border('simple-10'),
          padding: '0 56px 24px 32px',
        }}
      >
        <AvatarArea user={selectedUser} />
      </div>
      <Tab css={{ marginTop: '24px' }} tabs={tabs} />
    </div>
  )
}

OneOnOneDrawerContent.displayName = 'OneOnOneDrawerContent'

export const OneOnOneDrawerLoadingContent: React.VFC = () => (
  <div
    style={{
      display: 'flex',
      width: '100%',
      height: '100vh',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Spinner size="medium" color={color('resily-orange-100')} />
  </div>
)

OneOnOneDrawerLoadingContent.displayName = 'OneOnOneDrawerLoadingContent'

const ResizeHandler: React.FC = () => (
  <div
    css={{
      position: 'absolute',
      width: '8px',
      height: '24px',
      top: '50%',
      left: '0',
      borderRight: `2px solid ${color('border-bk-30')}`,
      '&:hover': {
        borderRight: `2px solid ${color('border-bk-50')}`,
      },
    }}
  />
)

ResizeHandler.displayName = 'ResizeHandler'
