import { css } from '@emotion/react'
import { Badge } from '@resily/geisha'
import { Box, Form, FormField, Heading } from 'grommet'
import i18next from 'i18next'
import { useCallback, useMemo, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { Section } from '../../../../components/layout/Section'
import { Button as UiButton } from '../../../../components/ui/Button'
import { Checkbox } from '../../../../components/ui/Checkbox'
import { ErrorMessage } from '../../../../components/ui/ErrorMessage'
import { Input } from '../../../../components/ui/Input'
import { Link } from '../../../../components/ui/Link'
import { Modal } from '../../../../components/ui/Modal'
import { PopoverPortal } from '../../../../components/ui/PopoverPortal'
import { StyledText } from '../../../../components/ui/StyledText'
import { Tabs, TabType } from '../../../../components/ui/Tabs'
import { Trans, useTranslation } from '../../../../i18n'
import { Screen } from '../../../../lib/screen'
import { useTracking } from '../../../../lib/tracking'
import { color } from '../../../../styles/newColors'
import { billingAdmin, ExternalUrls, usersImportAdmin } from '../../../../urls'
import { saveButtonCss } from '../../../AdminOkrTerms/components/OkrTermForm/styles'
import { CreateUserMutationVariables } from '../../api/graphql'
import { RemainingLicensesArea } from '../RemainingLicensesArea'
import { TableContentsMap } from '../TableContentsMap'

import { tabBoxCss } from './styles'
import { UsersProps, UsersTab } from './types'

export const AdminUsers: React.FC<UsersProps> = ({
  isSamlEnabled,
  usersTableLoading,
  users,
  invitingUsers,
  disableUsers,
  onRowClick,
  handleCreateUser,
  isSelfServe,
  remainingLicenses,
}) => {
  const { t } = useTranslation()
  useTracking(t('ADMIN_USERS_PAGE_TITLE'), Screen.UsersSetting)
  const navigate = useNavigate()
  const [isEditorOpen, setIsEditorOpen] = useState<boolean>(false)
  const [tab, setTab] = useState<UsersTab>('InviteUser')

  const tabs = useMemo<Array<TabType<UsersTab>>>(
    () => [
      {
        value: 'InviteUser',
        name: t('JOIN', { a: t('ENABLED'), b: t('USER') }),
        rightSection: (
          <Badge
            type="rounded"
            value={users.length}
            color={tab === 'InviteUser' ? 'resily-orange-100' : 'foreground-black-bk10'}
          />
        ),
      },
      {
        value: 'InvitingUser',
        name: t('JOIN', { a: t('INVITING'), b: t('USER') }),
        rightSection: (
          <Badge
            type="rounded"
            value={invitingUsers.length}
            color={tab === 'InvitingUser' ? 'resily-orange-100' : 'foreground-black-bk10'}
          />
        ),
      },
      {
        value: 'DisabledUser',
        name: t('JOIN', { a: t('DISABLED'), b: t('USER') }),
        rightSection: (
          <Badge
            type="rounded"
            value={disableUsers.length}
            color={tab === 'DisabledUser' ? 'resily-orange-100' : 'foreground-black-bk10'}
          />
        ),
      },
    ],
    [disableUsers.length, invitingUsers.length, t, tab, users.length],
  )

  const {
    handleSubmit,
    formState: { errors, dirtyFields, isSubmitting },
    control,
    register,
    reset,
    getValues,
  } = useForm<CreateUserMutationVariables>({
    mode: 'onChange',
  })
  const submitButtonRef = useRef<HTMLButtonElement>(null)

  const onCloseModal = useCallback(() => {
    reset()
    setIsEditorOpen(false)
  }, [reset])

  const isRemainDirtyFields =
    !(dirtyFields.firstName && dirtyFields.lastName && dirtyFields.email) &&
    !(getValues('firstName') && getValues('lastName') && getValues('email'))

  return (
    <>
      <Section css={{ height: '100%' }} margin={{ top: '32px', bottom: 'medium' }}>
        <Box gap="24px">
          <div css={{ display: 'flex', justifyContent: 'space-between', gap: '32px' }}>
            <Box gap="8px" css={{ height: '100%' }}>
              <Box direction="row" align="center" gap="24px">
                <Heading level="3" css={{ fontSize: '24px' }}>
                  {t('USER')}
                </Heading>
                {isSelfServe && remainingLicenses != null && (
                  <RemainingLicensesArea
                    remainingLicenses={remainingLicenses}
                    backgroundColor={color('white-100')}
                  />
                )}
              </Box>
              {isSelfServe ? (
                <StyledText css={{ maxWidth: '968px' }}>
                  <Trans
                    i18nKey="USERS_DESCRIPTION_FOR_SELF_SERVE"
                    components={[
                      <Link
                        href={billingAdmin}
                        method="newTab"
                        css={{
                          color: color('resily-orange-100'),
                          textDecoration: 'underline',
                        }}
                      >
                        here
                      </Link>,
                    ]}
                  />
                </StyledText>
              ) : (
                <StyledText>{t('USERS_DESCRIPTION')}</StyledText>
              )}
            </Box>
            <div css={{ display: 'flex', alignItems: 'flex-start', gap: 12 }}>
              <UiButton
                newColor="white-100"
                weight="normal"
                size="s"
                onClick={() => navigate(usersImportAdmin)}
              >
                {t('CSV_IMPORT_PAGE_TITLE')}
              </UiButton>
              <UiButton
                newColor="resily-orange-100"
                weight="normal"
                size="s"
                onClick={() => setIsEditorOpen(true)}
              >
                {t('INVITE_USER')}
              </UiButton>
            </div>
          </div>
          <Box css={tabBoxCss} gap="8px">
            <Tabs<UsersTab>
              css={css({ width: 'fit-content' })}
              tabs={tabs}
              tab={tab}
              onClickTab={(newTab: TabType<UsersTab>) => setTab(newTab.value)}
            />
            <TableContentsMap
              tab={tab}
              onRowClick={onRowClick}
              loading={usersTableLoading}
              users={users}
              invitingUsers={invitingUsers}
              disableUsers={disableUsers}
            />
          </Box>
        </Box>
      </Section>
      <Modal
        isOpened={isEditorOpen}
        title={t('INVITE_USER')}
        onClickClose={onCloseModal}
        onClickCancel={onCloseModal}
        onClickExecute={() => submitButtonRef?.current?.click()}
        cancelText={t('CANCEL')}
        executeText={t('SEND')}
        disabledExecute={
          Object.keys(errors).length > 0 ||
          isRemainDirtyFields ||
          isSubmitting ||
          (isSelfServe && remainingLicenses != null && remainingLicenses < 1)
        }
        executeButtonProps={{ css: saveButtonCss }}
      >
        <Box width="xxlarge">
          {isSelfServe && (
            <>
              {remainingLicenses != null && (
                <RemainingLicensesArea
                  remainingLicenses={remainingLicenses}
                  backgroundColor={color('background-bk-5')}
                />
              )}
              <StyledText>
                <Trans
                  i18nKey="INVITE_USER_SELF_SERVE"
                  components={[
                    <Link
                      href={billingAdmin}
                      method="newTab"
                      css={{
                        color: color('resily-orange-100'),
                        textDecoration: 'underline',
                      }}
                    >
                      here
                    </Link>,
                  ]}
                />
              </StyledText>
            </>
          )}
          <Form
            onSubmit={handleSubmit(async (data) => {
              await handleCreateUser(data)
              reset()
              setIsEditorOpen(false)
            })}
          >
            <button
              type="submit"
              ref={submitButtonRef}
              css={{
                display: 'none',
              }}
            />
            {i18next.language === 'ja' ? (
              <Box direction="row" gap="24px" justify="between">
                <FormField
                  label={
                    <StyledText fontStyle="bold" color="text-bk-50">
                      {t('LAST_NAME')}
                    </StyledText>
                  }
                  error={
                    errors.lastName ? <ErrorMessage>{errors.lastName.message}</ErrorMessage> : null
                  }
                >
                  <Controller
                    render={() => (
                      <Input
                        fieldStyle="bottomLine"
                        {...register('lastName')}
                        width="228px"
                        height="40px"
                        placeholder={t('INPUT_X', { x: t('LAST_NAME') })}
                      />
                    )}
                    control={control}
                    name="lastName"
                    rules={{ required: t('REQUIRED_ERROR') }}
                  />
                </FormField>
                <FormField
                  label={
                    <StyledText fontStyle="bold" color="text-bk-50">
                      {t('FIRST_NAME')}
                    </StyledText>
                  }
                  error={
                    errors.firstName ? (
                      <ErrorMessage>{errors.firstName.message}</ErrorMessage>
                    ) : null
                  }
                >
                  <Controller
                    render={() => (
                      <Input
                        fieldStyle="bottomLine"
                        {...register('firstName')}
                        width="228px"
                        height="40px"
                        placeholder={t('INPUT_X', { x: t('FIRST_NAME') })}
                      />
                    )}
                    control={control}
                    name="firstName"
                    rules={{ required: t('REQUIRED_ERROR') }}
                  />
                </FormField>
              </Box>
            ) : (
              <Box direction="row" gap="24px" justify="between">
                <FormField
                  label={
                    <StyledText fontStyle="bold" color="text-bk-50">
                      {t('FIRST_NAME')}
                    </StyledText>
                  }
                  error={
                    errors.firstName ? (
                      <ErrorMessage>{errors.firstName.message}</ErrorMessage>
                    ) : null
                  }
                >
                  <Controller
                    render={() => (
                      <Input
                        fieldStyle="bottomLine"
                        {...register('firstName')}
                        width="100%"
                        height="40px"
                        placeholder={t('INPUT_X', { x: t('FIRST_NAME') })}
                      />
                    )}
                    control={control}
                    name="firstName"
                    rules={{ required: t('REQUIRED_ERROR') }}
                  />
                </FormField>
                <FormField
                  label={
                    <StyledText fontStyle="bold" color="text-bk-50">
                      {t('LAST_NAME')}
                    </StyledText>
                  }
                  error={
                    errors.lastName ? <ErrorMessage>{errors.lastName.message}</ErrorMessage> : null
                  }
                >
                  <Controller
                    render={() => (
                      <Input
                        fieldStyle="bottomLine"
                        {...register('lastName')}
                        width="100%"
                        height="40px"
                        placeholder={t('INPUT_X', { x: t('LAST_NAME') })}
                      />
                    )}
                    control={control}
                    name="lastName"
                    rules={{ required: t('REQUIRED_ERROR') }}
                  />
                </FormField>
              </Box>
            )}
            <FormField
              label={
                <StyledText fontStyle="bold" color="text-bk-50">
                  {t('EMAIL')}
                </StyledText>
              }
              error={errors.email ? <ErrorMessage>{errors.email.message}</ErrorMessage> : null}
            >
              <Controller
                render={() => (
                  <Input
                    fieldStyle="bottomLine"
                    {...register('email', {
                      pattern: {
                        value:
                          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                        message: t('INVALID_EMAIL_FORMAT'),
                      },
                    })}
                    width="100%"
                    height="40px"
                    placeholder={t('INPUT_X', { x: t('EMAIL') })}
                  />
                )}
                control={control}
                name="email"
                rules={{ required: t('REQUIRED_ERROR') }}
              />
            </FormField>
            <Box gap="4px">
              <Controller
                control={control}
                name="isAdmin"
                render={({ field: { value, onChange, ...props } }) => (
                  <Checkbox
                    checked={value}
                    onChange={(e) => onChange(e.target.checked)}
                    css={{
                      marginTop: '24px',
                    }}
                    iconColor="resily-orange-100"
                    {...props}
                  >
                    <Box direction="row" gap="4px" align="center">
                      <StyledText size="small">
                        {t('SETTING_TO_X', { x: t('ADMINISTRATOR') })}
                      </StyledText>
                      <PopoverPortal
                        portalPosition={{
                          top: 25,
                          left: -125,
                        }}
                        icon="help"
                        iconCss={{
                          color: color('text-bk-50'),
                          ':hover': {
                            color: color('resily-orange-100'),
                          },
                        }}
                        text={t('ADMINISTRATOR_HELP')}
                        link={{
                          text: t('SUPPORT_SITE'),
                          href: ExternalUrls.SUPPORT_GUIDE_FOR_ADMIN,
                        }}
                      />
                    </Box>
                  </Checkbox>
                )}
                defaultValue={false}
              />
              {isSamlEnabled && (
                <Controller
                  control={control}
                  name="samlEnabled"
                  render={({ field: { value, onChange, ...props } }) => (
                    <Checkbox
                      checked={value}
                      onChange={(e) => onChange(e.target.checked)}
                      {...props}
                    >
                      <StyledText size="small">{t('SAML_AUTH')}</StyledText>
                    </Checkbox>
                  )}
                  defaultValue={false}
                />
              )}
            </Box>
          </Form>
        </Box>
      </Modal>
    </>
  )
}

AdminUsers.displayName = 'AdminUsers'
