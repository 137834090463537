import { css } from '@emotion/react'
import { useMemo } from 'react'

import { fontSize } from '../../../../styles/font'
import { color } from '../../../../styles/newColors'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useStyles = () =>
  useMemo(
    () =>
      ({
        title: css({
          display: 'block',
          marginBottom: '4px',
        }),
        link: css({
          ...fontSize('small', 'regular'),
          color: color('resily-orange-100'),
          textDecoration: 'underline',
          paddingTop: '4px',
        }),
        icon: css({
          verticalAlign: 'baseline',
          width: '10.5px',
          height: '10.5px',
          marginLeft: '5.75px',
        }),
      } as const),
    [],
  )
