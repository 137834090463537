import { useRef, useMemo, useEffect, Dispatch, SetStateAction } from 'react'

import { useTranslation } from '../../../i18n/index'
import { border } from '../../../styles/border'
import { color } from '../../../styles/newColors'
import { Icon } from '../../ui/Icon'
import { StyledText } from '../../ui/StyledText'
import { TooltipNew } from '../../ui/TooltipNew'
import { GroupTag } from '../GroupTag'
import { MultiSelectGroupTree } from '../MultiSelectGroupTree'

import { GroupFragment } from './graphql'

export type Props = {
  zIndex?: number
  trimCount: number
  setTrimCount: Dispatch<SetStateAction<number>>
  fixTrimCount?: number
  resetTrimCount?: number
  maxWidth?: number
  openMultiSelect: boolean
  onClickOpenMultiSelect: () => void
  onClickOption: (id: string) => void
  groups: ReadonlyArray<GroupFragment>
  selectedGroupIds: ReadonlyArray<string>
  onClear: () => void
}

export const SelectedGroups: React.FC<Props> = ({
  zIndex,
  trimCount,
  setTrimCount,
  fixTrimCount,
  resetTrimCount,
  maxWidth,
  openMultiSelect,
  onClickOpenMultiSelect,
  onClickOption,
  groups,
  selectedGroupIds,
  onClear,
}) => {
  const { t } = useTranslation()
  const groupsRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (maxWidth === undefined) {
      setTrimCount(0)
    }
  }, [maxWidth, setTrimCount])

  useEffect(() => {
    setTrimCount(0)
  }, [resetTrimCount, setTrimCount])

  useEffect(() => {
    if (!groupsRef.current || !maxWidth) return
    if (groupsRef.current.scrollWidth > maxWidth && trimCount + 1 < selectedGroupIds.length) {
      setTrimCount((prev) => prev + 1)
    }
  }, [groupsRef, maxWidth, trimCount, selectedGroupIds, setTrimCount])

  const selectedGroups: ReadonlyArray<GroupFragment> = useMemo(
    () =>
      selectedGroupIds
        .map((gid) => groups.find(({ id }) => id === gid))
        .filter((g): g is Exclude<typeof g, undefined> => g !== undefined),
    [selectedGroupIds, groups],
  )

  return (
    <div css={{ zIndex }}>
      {selectedGroupIds.filter((value) => groups.some(({ id }) => value === id)).length === 0 ? (
        <TooltipNew title={t('SELECT_X', { x: t('GROUP_TAG') })}>
          <button
            type="button"
            css={{
              display: 'inline-flex',
              alignItems: 'center',
              border: border('simple-30'),
              borderRadius: '4px',
              padding: '10px 8px',
              height: '40px',
              cursor: 'pointer',
              outline: 'none',
              backgroundColor: openMultiSelect ? color('background-bk-5') : undefined,
              ':hover': {
                backgroundColor: color('background-bk-5'),
              },
            }}
            onClick={onClickOpenMultiSelect}
          >
            <div>
              <Icon type="group2" css={{ width: '14px', height: '14px', marginRight: '4px' }} />
            </div>
            <StyledText css={{ lineHeight: '14px', marginLeft: '4px', whiteSpace: 'nowrap' }}>
              {t('GROUP_TAG')}
            </StyledText>
            <Icon
              type="selectDown"
              color={color('border-bk-20')}
              css={{
                width: '8px',
                marginLeft: '8px',
                transform: openMultiSelect ? 'rotate(-180deg)' : undefined,
              }}
            />
          </button>
        </TooltipNew>
      ) : (
        <div
          ref={groupsRef}
          css={{
            display: 'inline-flex',
            maxWidth,
            alignItems: 'center',
            border: border('simple-30'),
            borderRadius: '4px',
            padding: '10px 8px',
            height: '40px',
            backgroundColor: openMultiSelect ? color('hover-background-bk-5') : undefined,
            ':hover': {
              backgroundColor: color('hover-background-bk-5'),
            },
          }}
        >
          <div>
            <Icon type="group2" css={{ width: '14px', height: '14px', marginRight: '4px' }} />
          </div>
          {selectedGroups
            .slice(
              0,
              groups.filter((group) => selectedGroupIds.includes(group.id)).length -
                (fixTrimCount !== undefined ? fixTrimCount : trimCount),
            )
            .map((group) => (
              <TooltipNew
                key={group.id}
                css={
                  selectedGroupIds.length -
                    (fixTrimCount !== undefined ? fixTrimCount : trimCount) ===
                  1
                    ? {
                        display: 'block',
                        overflowX: 'hidden',
                      }
                    : undefined
                }
                title={t('SELECT_X', { x: t('GROUP_TAG') })}
              >
                <GroupTag tooltipDisabled name={group.name} onClick={onClickOpenMultiSelect} />
              </TooltipNew>
            ))}
          {(fixTrimCount !== undefined ? fixTrimCount : trimCount) > 0 && (
            <StyledText size="small" css={{ width: '12px', margin: '4px' }}>
              ...
            </StyledText>
          )}
          <TooltipNew title={t('CLEAR_X', { x: t('GROUP_TAG') })}>
            <div css={{ minWidth: '24px' }}>
              <Icon
                type="clear"
                color={color('border-bk-10')}
                css={{
                  marginLeft: '4px',
                  cursor: 'pointer',
                  ':hover': {
                    color: color('resily-orange-100'),
                  },
                }}
                onClick={onClear}
              />
            </div>
          </TooltipNew>
        </div>
      )}
      {openMultiSelect && (
        <div css={{ position: 'absolute', zIndex: 10 }}>
          <MultiSelectGroupTree
            groups={groups}
            searchPlaceholder={t('INPUT_X', { x: t('GROUP_NAME') })}
            selectedIds={selectedGroupIds}
            onClickOption={onClickOption}
          />
        </div>
      )}
    </div>
  )
}

SelectedGroups.displayName = 'SelectedGroups'
