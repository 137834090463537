import { useTranslation } from '../../../../../../../i18n'
import { componentNames, featureNames, generateTestId } from '../../../../../../../lib/testId'
import { SelectUnit } from '../../../../../../domain/SelectUnit'
import { StyledText } from '../../../../../../ui/StyledText'
import { keyResultsKeyName } from '../../../formSchema'
import { SelectUnitController } from '../../SelectUnitController'
import { boxCss } from '../styles'

type SelectUnitSectionProps = {
  name: `${typeof keyResultsKeyName}.${number}.unit`
}

export const SelectUnitSection: React.FC<SelectUnitSectionProps> = ({ name }) => {
  const { t } = useTranslation()

  return (
    <section css={boxCss}>
      <StyledText size="xxsmall" color="border-bk-50">
        {t('TARGET_VALUE_UNIT')}
      </StyledText>
      <SelectUnitController name={name}>
        {(selectUnitProps) => (
          <SelectUnit
            data-testid={generateTestId(featureNames.checkinModal, componentNames.unitSelect)}
            {...selectUnitProps}
          />
        )}
      </SelectUnitController>
    </section>
  )
}
