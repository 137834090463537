import { OkrTitle, Skeleton } from '@resily/geisha'
import { useMemo } from 'react'
import { v1 as uuid } from 'uuid'

import { CommentTabs } from '../../../CommentTabs'

import {
  formSkeletonItemWrapperCss,
  keyResultTitleSkeletonWrapperCss,
  keyResultTitleWrapperCss,
  objectiveTitleWrapperCss,
  okrTitleWrapperCss,
  progressRateWrapperCss,
  rootCss,
} from './styles'

export const FormSkeleton: React.VFC = () => (
  <div css={rootCss}>
    <FormSkeletonItem />
    <FormSkeletonItem />
  </div>
)

const FormSkeletonItem: React.VFC = () => (
  <div css={formSkeletonItemWrapperCss}>
    {/** OKRタイトル */}
    <div css={okrTitleWrapperCss}>
      <div css={objectiveTitleWrapperCss}>
        <OkrTitle type="objective" size="xsmall" title="" withBorder />
        <Skeleton text width={340} height={10} />
      </div>
      <div css={keyResultTitleWrapperCss}>
        <OkrTitle type="keyResult" size="large" title="" withBorder />
        <div css={keyResultTitleSkeletonWrapperCss}>
          <Skeleton text width={473} height={16} />
          <Skeleton text width={232} height={16} />
        </div>
      </div>
    </div>
    {/** 進捗率・自信度 */}
    <div css={progressRateWrapperCss}>
      <Skeleton text width={72} height={12} />
      <Skeleton text width={48} height={12} />
      <Skeleton text width={32} height={12} />
      <Skeleton text width={80} height={12} />
    </div>
    {/** コメント */}
    <div>
      <CommentTabs
        keyResultId={useMemo(uuid, [])}
        comments={{
          priority: { initialValueJSON: '' },
          problem: { initialValueJSON: '' },
          winSession: { initialValueJSON: '' },
          other: { initialValueJSON: '' },
        }}
        noPreviousComments
      />
    </div>
  </div>
)
