import { css } from '@emotion/react'
import { useMemo } from 'react'

import { color } from '../../../styles/newColors'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useStyles = () =>
  useMemo(
    () =>
      ({
        root: css({ lineHeight: 1.5 }),
        title: css({ marginBottom: 16 }),
        text: css({ marginBottom: 8, whiteSpace: 'pre-line' }),
        bold: css({ fontWeight: 600 }),
        linkArea: css({
          display: 'flex',
          gap: 16,
          justifyContent: 'center',
          marginTop: 24,
          lineHeight: '20px',
        }),
        link: css({
          color: color('text-bk-50'),
          textDecoration: 'none',
          fontSize: '12px',
          fontWeight: 'bold',
          '&:hover': {
            color: color('resily-orange-100'),
          },
        }),
      } as const),
    [],
  )
