import { Box, Button, Drop } from 'grommet'
import { More } from 'grommet-icons'
import { Fragment, useRef, useState } from 'react'

import { MenuListItem } from '../../../pages/OneOnOnesDetail/MenuListItem'
import * as colors from '../../../styles/colors'
import { color } from '../../../styles/newColors'

export type PopoverItem = {
  label: string
  icon: JSX.Element
  disabled?: boolean
  onItemClicked: () => void
}

export type Props = {
  popoverItems: ReadonlyArray<PopoverItem>
}

export const Menus: React.FC<Props> = ({ popoverItems }) => {
  const threePointsRef = useRef<HTMLButtonElement>(null)
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  return (
    <Fragment>
      <Button
        css={{
          '&:focus': {
            outline: 'none',
          },
          cursor: 'pointer',
          width: '40px',
          height: '40px',
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        ref={threePointsRef}
        onClick={() => {
          setIsPopoverOpen(!isPopoverOpen)
        }}
        icon={<More color={color('text-bk-50')} />}
      />
      {isPopoverOpen && (
        <Drop
          target={threePointsRef.current || undefined}
          align={{ top: 'bottom', right: 'right' }}
          onClickOutside={() => setIsPopoverOpen(false)}
          round="small"
          css={{ marginTop: '4px' }}
        >
          <Box
            as="ul"
            direction="column"
            elevation="large"
            css={{
              minWidth: '201px',
              '> :first-of-type': {
                paddingTop: '12px',
              },
              '> :last-of-type': {
                paddingBottom: '12px',
              },
            }}
            background={{ color: colors.baseFill }}
            round="small"
          >
            {popoverItems.map((item: PopoverItem) => (
              <MenuListItem
                size="14px"
                key={item.label}
                label={item.label}
                icon={item.icon}
                disabled={item.disabled}
                onClick={() => {
                  setIsPopoverOpen(false)
                  item.onItemClicked()
                }}
              />
            ))}
          </Box>
        </Drop>
      )}
    </Fragment>
  )
}

Menus.displayName = 'Menus'
