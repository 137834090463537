import { useState, useRef, useCallback } from 'react'
import { useDeepCompareEffect } from 'react-use'

import { useTranslation } from '../../../i18n'
import { formatDateInput } from '../../../lib/date'
import { color } from '../../../styles/newColors'
import { AvatarWithName } from '../../ui/AvatarWithName'
import { Button } from '../../ui/Button'
import { Icon } from '../../ui/Icon'
import { RichTextEditor, EditorRef } from '../../ui/RichTextEditor'
import { StyledText } from '../../ui/StyledText'
import { TextButton } from '../../ui/TextButton'

import { KeyResultSharedItem, DocumentInput, CheckinUserFragment } from './graphql'

export type Props = {
  id: string
  termId: string
  checkinUser: CheckinUserFragment
  createdAt: Date
  comment: KeyResultSharedItem['body']
  canEdit: boolean
  onClickSave: (comment: DocumentInput) => void
  onClickCancel: () => void
  drawer?: boolean
}

export const CheckinCommentItem: React.FC<Props> = ({
  id,
  termId,
  checkinUser,
  createdAt,
  comment,
  canEdit,
  onClickSave,
  onClickCancel,
  drawer = false,
}) => {
  const { t } = useTranslation()
  const [isEditing, setIsEditing] = useState(false)
  const [commentTreeJson, setCommentTreeJson] = useState(comment?.treeJson ?? '')
  const [commentPlainText, setCommentPlainText] = useState(comment?.plainText ?? '')
  const editorRef = useRef<EditorRef>(null)
  const resetInitialValue = useCallback(() => {
    if (!editorRef.current) {
      return
    }
    editorRef.current.resetInitialValue()
  }, [])

  // commentが変更されたら、Editorに反映させる
  useDeepCompareEffect(() => {
    if (comment) {
      resetInitialValue()
    }
  }, [comment, resetInitialValue])

  return (
    <div css={{ padding: '20px' }}>
      <div css={{ display: 'flex' }}>
        <AvatarWithName
          size="small"
          firstName={checkinUser.firstName}
          lastName={checkinUser.lastName}
          linkTextMaxWidth={drawer ? '70%' : '85%'}
          linkage={checkinUser.userId ? { termId, userId: checkinUser.userId } : undefined}
          avatarUrl={checkinUser.avatar?.url}
          isUserDisabled={checkinUser.user ? checkinUser.user.isDisabled : true}
        />
        <StyledText color="text-bk-30" css={{ marginLeft: '8px', whiteSpace: 'nowrap' }}>
          {formatDateInput(createdAt, 'YYYY/MM/DD HH:mm')}
        </StyledText>
        {canEdit && !isEditing && (
          <Icon
            type="editOutline"
            height={16}
            width={16}
            color={color('text-bk-30')}
            css={{ marginLeft: 'auto', cursor: 'pointer' }}
            onClick={() => setIsEditing(true)}
          />
        )}
      </div>

      <div css={{ marginTop: '8px' }}>
        <RichTextEditor
          id={drawer ? `drawer-${id}` : id}
          ref={editorRef}
          editorProps={{
            readOnly: !isEditing,
          }}
          initialValueJSON={
            comment?.treeJson != null && comment.treeJson !== ''
              ? comment.treeJson
              : comment?.plainText ?? ''
          }
          onChange={(treeJson, plainText) => {
            setCommentTreeJson(treeJson)
            setCommentPlainText(plainText)
          }}
        />
      </div>
      {isEditing && (
        <div css={{ display: 'flex', marginTop: '24px' }}>
          <TextButton
            color="text-bk-50"
            type="button"
            onClick={() => {
              setCommentTreeJson(comment?.treeJson ?? '')
              setCommentPlainText(comment?.plainText ?? '')
              setIsEditing(false)
              resetInitialValue()
              onClickCancel()
            }}
            css={{ marginLeft: 'auto', marginRight: '16px' }}
          >
            {t('CANCEL')}
          </TextButton>

          <Button
            newColor="resily-orange-100"
            weight="normal"
            size="s"
            onClick={() => {
              setIsEditing(false)
              onClickSave({ treeJson: commentTreeJson, plainText: commentPlainText })
            }}
          >
            {t('SAVE')}
          </Button>
        </div>
      )}
    </div>
  )
}

CheckinCommentItem.displayName = 'CheckinCommentItem'
