import { BoxProps } from 'grommet'
import React from 'react'

import { Link } from '../../../components/ui/Link'
import { StyledText } from '../../../components/ui/StyledText'
import { useTranslation, Trans } from '../../../i18n'
import { color } from '../../../styles/newColors'

import { OkrEmptyState } from './OkrEmptyState'

export type Props = BoxProps & {
  onClickCreatingOkr: () => void
}

export const MyOkrEmptyState: React.VFC<Props> = ({ onClickCreatingOkr, ...props }) => {
  const { t } = useTranslation()
  const description = (
    <StyledText key="MyOkrEmptyState" size="large" lineHeight="16px" color="text-bk-100">
      <Trans
        i18nKey="MY_OKR_EMPTY_DESCRIPTION"
        components={[
          <Link
            onClick={onClickCreatingOkr}
            css={{
              color: color('resily-orange-100'),
            }}
          >
            here
          </Link>,
        ]}
      />
    </StyledText>
  )
  return <OkrEmptyState title={t('MY_OKR_EMPTY_TITLE')} description={description} {...props} />
}

MyOkrEmptyState.displayName = 'MyOkrEmptyState'
