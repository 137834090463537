import { css } from '@emotion/react'
import React from 'react'

import {
  ProgressRateTooltip,
  Props as ProgressRateTooltipProps,
} from '../../../../../components/domain/ProgressRateTooltip'
import { ProgressRate } from '../../../../../components/ui/Meter'
import { useCurrentUser } from '../../../../../contexts/UserContext'
import { barColorConfidence } from '../../../../../lib/confidence'
import { keyResultToConfidenceLevel } from '../../../../../lib/domain/confidence'
import { isIncludedUser } from '../../../../../lib/domain/keyResult/keyResult'
import { Screen } from '../../../../../lib/screen'
import { componentNames, featureNames, generateTestId } from '../../../../../lib/testId'
import { tracker } from '../../../../../lib/tracking'
import { fontSize } from '../../../../../styles/font'
import { color } from '../../../../../styles/newColors'

import { TrendButton } from './TrendButton'
import { KeyResultFragment } from './graphql'

const baseCss = css({
  width: 148,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
})

const buttonCss = css({
  paddingRight: 8,
  display: 'inline-flex',
  alignItems: 'center',
  flex: '0 0 112px',
  ':hover': {
    backgroundColor: 'white',
    borderRadius: 12,
    outline: `1px solid ${color('border-bk-30')}`,
  },
})

const barCss = css({
  width: 68,
  paddingLeft: '12px',
  paddingRight: '8px',
  '> div': { height: 6 },
})

export type Props = Omit<JSX.IntrinsicElements['div'], 'css'> & {
  keyResult: ProgressRateTooltipProps['keyResult'] &
    Pick<KeyResultFragment, 'progressRate' | 'progressRateTrend'> & {
      objective: Pick<KeyResultFragment['objective'], 'id'>
    }
  onClickTrend: (objectiveId: string, keyResultId: string, isAssigned: boolean) => void
}

export const ThinKeyResultProgressRateBar: React.FC<Props> = ({
  keyResult,
  onClickTrend,
  ...props
}) => {
  const ownerConfidenceLevel = keyResultToConfidenceLevel(keyResult)
  const rate = keyResult.progressRate || 0
  const currentUser = useCurrentUser()

  return (
    <div css={baseCss} {...props}>
      <ProgressRateTooltip
        keyResult={keyResult}
        onUpdated={() => {
          tracker.UpdateProgressRate(Screen.HomeOkr, keyResult.id, keyResult.objective.id)
        }}
        data-testid={generateTestId(featureNames.homeOkr, componentNames.percentageBar)}
      >
        <div css={buttonCss}>
          <div css={barCss}>
            <ProgressRate
              withoutText
              color={color(barColorConfidence(ownerConfidenceLevel))}
              rate={rate}
              barWidth="48px"
            />
          </div>
          <span css={fontSize('small', 'regular')}>{rate}%</span>
        </div>
      </ProgressRateTooltip>
      {keyResult.progressRateTrend !== 0 && (
        <TrendButton
          progressRateTrend={keyResult.progressRateTrend}
          onClick={(e) => {
            e.stopPropagation()
            onClickTrend(
              keyResult.objective.id,
              keyResult.id,
              isIncludedUser(keyResult, currentUser),
            )
          }}
        />
      )}
    </div>
  )
}
