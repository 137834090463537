import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { generateAdminEditFixedAgenda } from '../../../../urls'
import {
  FixedAgendaStatus,
  useFixedAgendasQuery,
  useUpdateFixedAgendaIndexMutation,
  FixedAgendasQuery,
  FixedAgendasDocument,
  useDeleteFixedAgendaMutation,
  useCopyFixedAgendaMutation,
} from '../../api/graphql'
import { Props as FixedAgendaListProps } from '../../components/FixedAgendaContent/components/FixedAgendaList'

export const useFixedAgendaList = (
  filteredFixedAgendaStatus?: FixedAgendaStatus,
): FixedAgendaListProps => {
  const navigate = useNavigate()

  const { data: fixedAgendasData, loading } = useFixedAgendasQuery()

  const filteredFixedAgenda = useMemo<FixedAgendaListProps['data']>(
    () =>
      filteredFixedAgendaStatus && fixedAgendasData
        ? fixedAgendasData.fixedAgendas.filter(
            (fixedAgenda) => fixedAgenda?.status === filteredFixedAgendaStatus,
          )
        : fixedAgendasData?.fixedAgendas,
    [filteredFixedAgendaStatus, fixedAgendasData],
  )

  const [updateFixedAgendaIndexMutation] = useUpdateFixedAgendaIndexMutation()

  const updateFixedAgendaIndex = async (id: string, index: number) => {
    await updateFixedAgendaIndexMutation({
      variables: {
        id,
        input: {
          index,
        },
      },
      optimisticResponse: ({ id: updateAgendaId, input }) => {
        if (!fixedAgendasData?.fixedAgendas) {
          return {
            updateFixedAgendaIndex: [],
          }
        }
        const { fixedAgendas } = fixedAgendasData

        // 並び順を変更した固定アジェンダ
        const updatedFixedAgenda = fixedAgendas.find((f) => f.id === updateAgendaId)
        if (!updatedFixedAgenda) {
          return {
            updateFixedAgendaIndex: fixedAgendas,
          }
        }

        // 並び順を変更した固定アジェンダを除いた固定アジェンダのList
        const excludedFixedAgendas = fixedAgendas.filter((f) => f.id !== updateAgendaId)

        const nextIndex =
          excludedFixedAgendas.findIndex((fixedAgenda) => fixedAgenda.index === input.index) +
          (input.index > updatedFixedAgenda.index ? 1 : 0)

        if (nextIndex === -1) {
          return {
            updateFixedAgendaIndex: fixedAgendas,
          }
        }

        const newFixedAgendas = [
          ...excludedFixedAgendas.slice(0, nextIndex),
          { ...updatedFixedAgenda, index: input.index },
          ...excludedFixedAgendas.slice(nextIndex),
        ]

        return {
          updateFixedAgendaIndex: newFixedAgendas.map((f, i) => ({ ...f, index: i + 1 })),
        }
      },
      update: (cache, { data }) => {
        if (!data || !fixedAgendasData?.fixedAgendas) {
          return
        }

        cache.writeQuery<FixedAgendasQuery>({
          query: FixedAgendasDocument,
          data: {
            fixedAgendas: [
              ...fixedAgendasData.fixedAgendas
                .map((value) => {
                  const fixedAgenda = {
                    ...value,
                  }
                  const updatedIndex = data.updateFixedAgendaIndex.find(
                    (updateFixedAgendaIndexRes) => updateFixedAgendaIndexRes.id === value.id,
                  )?.index

                  if (updatedIndex !== undefined) {
                    return {
                      ...fixedAgenda,
                      index: updatedIndex,
                    }
                  }
                  return fixedAgenda
                })
                .sort((a, b) => a.index - b.index),
            ],
          },
        })
      },
    })
  }

  const [deleteAgenda] = useDeleteFixedAgendaMutation()
  const deleteFixedAgenda = async (id: string) => {
    await deleteAgenda({
      variables: { id },
      update(cache, { data: deletedData }) {
        const option = {
          query: FixedAgendasDocument,
        }
        const { fixedAgendas } = cache.readQuery<FixedAgendasQuery>(option) || {}

        if (!fixedAgendas || !deletedData) {
          return
        }
        const { deleteFixedAgenda: deletedFixedAgendaId } = deletedData

        cache.writeQuery<FixedAgendasQuery>({
          ...option,
          data: {
            fixedAgendas: [...fixedAgendas.filter((f) => f.id !== deletedFixedAgendaId)],
          },
        })
      },
    })
  }

  const [copyAgenda] = useCopyFixedAgendaMutation({
    onCompleted: (data) => {
      if (data?.copyFixedAgenda.id) {
        navigate(generateAdminEditFixedAgenda(data.copyFixedAgenda.id))
      }
    },
  })
  const copyFixedAgenda = async (id: string) => {
    await copyAgenda({ variables: { id } })
  }

  return {
    loading,
    data: filteredFixedAgenda,
    reOrder: updateFixedAgendaIndex,
    onDeleteFixedAgenda: deleteFixedAgenda,
    onCopyFixedAgenda: copyFixedAgenda,
  }
}
