import { focusEditor, PlateEditor } from '@udecode/plate'

import { Type as IconType } from '../../../../Icon'
import { MenuButton } from '../../SlateEditor/components/Toolbar/MenuButton'
import { ElementType } from '../../types'
import { useToggleElement } from '../useToggleElement'

type Props<T extends ElementType> = {
  editor: PlateEditor
  type: T
  inactiveType?: Exclude<ElementType, T>
  text: string
  icon: IconType
  onClose: () => void
}

export const BlockButton = <T extends ElementType>({
  editor,
  type,
  inactiveType,
  text,
  icon,
  onClose,
}: Props<T>): JSX.Element => {
  const toggleElement = useToggleElement<T>(editor)

  return (
    <MenuButton
      onMouseDown={(e) => {
        e.preventDefault()
        toggleElement(type, inactiveType)
        onClose()

        if (editor.selection) {
          focusEditor(editor)
        }
      }}
      icon={icon}
      text={text}
    />
  )
}
