import { useCallback } from 'react'

import { User } from '../../../contexts/UserContext'
import { tracker } from '../../../lib/tracking'
import { useUpdateUserSettingMutation, OrganizationFragment } from '../api/graphql'
import { Props as NotificationSettingProps } from '../components/NotificationSetting'

export const useNotificationSetting = ({
  user,
  organization,
}: {
  organization: OrganizationFragment
  user: User
}): NotificationSettingProps => {
  const [updateUserSetting, { loading }] = useUpdateUserSettingMutation()

  const updateNotifyEmailEnabled = useCallback(
    (notifyEmailEnabled: boolean) => {
      tracker.NotificationSettingToggleSubscribe(user.userSetting.notifyEmailEnabled)
      updateUserSetting({
        variables: {
          notifyEmailEnabled,
          notifyBulkUpdateEnabledOnSlack: user.userSetting.notifyBulkUpdateEnabledOnSlack,
        },
      })
    },
    [user.userSetting, updateUserSetting],
  )
  const updateNotifyBulkUpdateBySlack = useCallback(
    (notifyBulkUpdateEnabledOnSlack: boolean) => {
      updateUserSetting({
        variables: {
          notifyEmailEnabled: user.userSetting.notifyEmailEnabled,
          notifyBulkUpdateEnabledOnSlack,
        },
      })
    },
    [user.userSetting, updateUserSetting],
  )

  return {
    loading,
    userSetting: {
      notifyEmailEnabled: user.userSetting.notifyEmailEnabled,
      notifyBulkUpdateEnabledOnSlack: user.userSetting.notifyBulkUpdateEnabledOnSlack,
    },
    organizationSlackNotification: organization.bulkUpdateNotificationEnabled,
    updateNotifyEmailEnabled,
    updateNotifyBulkUpdateBySlack,
  }
}
