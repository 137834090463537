import { useMemo } from 'react'

import { addDescendantIds } from '../../../lib/domain/group'
import { GroupFragment } from '../graphql'

export const useParseFilterConditions = ({
  queryParamsGroupIds,
  queryParamsUserIds,
  groups,
}: {
  /**
   * urlのquery paramsに設定されているグループidの配列
   */
  queryParamsGroupIds: Array<string | null> | null | undefined
  /**
   * urlのquery paramsに設定されているユーザーidの配列
   */
  queryParamsUserIds: Array<string | null> | null | undefined
  /**
   * queryから取得された組織に紐付く全てのgroup
   */
  groups: ReadonlyArray<GroupFragment>
}): {
  /**
   * フィルタ処理によって選択されているグループidの配列
   */
  selectedGroupIds: ReadonlyArray<string>
  /**
   * フィルタ処理によって選択されているユーザーidの配列
   */
  selectedUserIds: ReadonlyArray<string>
  /**
   * 選択されいるグループとその祖先を含めたグループIdの配列
   * フィルタで表示するときに選択しているものだけではなく、その祖先を表示する必要がある
   */
  requiredGroupIds: ReadonlyArray<string>
  isFiltering: boolean
} => {
  const selectedGroupIds = useMemo<ReadonlyArray<string>>(
    () => queryParamsGroupIds?.filter((g): g is string => g != null) ?? [],
    [queryParamsGroupIds],
  )
  const selectedUserIds = useMemo<ReadonlyArray<string>>(
    () => queryParamsUserIds?.filter((u): u is string => u != null) ?? [],
    [queryParamsUserIds],
  )
  // 選択されたグループとそれに所属するグループをフィルター結果に含む
  // selectedGroupIdsにemptyが含まれている場合、検索結果を0件にする
  const requiredGroupIds = useMemo<ReadonlyArray<string>>(
    () =>
      selectedGroupIds.includes('empty') ? ['empty'] : addDescendantIds(groups, selectedGroupIds),
    [groups, selectedGroupIds],
  )

  const isFiltering = selectedGroupIds.length > 0 || selectedUserIds.length > 0

  return {
    selectedGroupIds,
    selectedUserIds,
    requiredGroupIds,
    isFiltering,
  }
}
