import { createEnumParam, DecodedValueMap, QueryParamConfig, SetQuery } from 'use-query-params'

type QueryParams = {
  sort: QueryParamConfig<SortType | null | undefined>
  order: QueryParamConfig<OrderType | null | undefined>
}

export type QueryParamsType = DecodedValueMap<QueryParams>

export type SetQueryParamsType = SetQuery<QueryParams>

export const SortObject = {
  title: 'title',
  keyResult: 'keyResult',
  status: 'status',
  dueDate: 'dueDate',
  updatedAt: 'updatedAt',
} as const

export type SortType = typeof SortObject[keyof typeof SortObject]
export const AllSortType = Object.values(SortObject)
export const SortEnumParam = createEnumParam<SortType>(AllSortType as unknown as Array<SortType>)

export const OrderObject = {
  asc: 'asc',
  desc: 'desc',
} as const
export type OrderType = typeof OrderObject[keyof typeof OrderObject]
export const AllOrderType = Object.values(OrderObject)
export const OrderEnumParam = createEnumParam<OrderType>(
  AllOrderType as unknown as Array<OrderType>,
)

export type Sort = {
  sortBy: QueryParamsType['sort']
  order: QueryParamsType['order']
}
