import { css } from '@emotion/react'
import { useMemo } from 'react'

import { fontSize } from '../../../styles/font'
import { color } from '../../../styles/newColors'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useStyles = () =>
  useMemo(
    () =>
      ({
        root: css({
          display: 'flex',
          flexDirection: 'column',
        }),
        input: css({
          height: 44,
          padding: '12px 16px',
          position: 'sticky',
          top: 0,
          backgroundColor: color('white-100'),
          borderBottom: `1px solid ${color('border-bk-10')}`,
          ':focus': {
            backgroundColor: color('background-bk-5'),
            borderBottom: `1px solid ${color('resily-orange-100')}`,
          },
          '::placeholder': {
            ...fontSize('medium'),
            color: color('text-bk-30'),
          },
        }),
        layerList: css({
          listStyle: 'none',
          display: 'flex',
          flexDirection: 'column',
        }),
        option: css({
          height: 40,
          padding: '10px 16px',
          ':hover': {
            background: color('background-bk-10'),
          },
        }),
        loading: css({
          padding: '10px 0',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }),
        notFound: css({
          height: 40,
          padding: 8,
          opacity: 0.3,
          display: 'inline-flex',
          alignItems: 'center',
        }),
      } as const),
    [],
  )
