import { Dispatch, SetStateAction, useCallback, useState } from 'react'
import { createContext, useContext, useContextSelector } from 'use-context-selector'

export type IsFoldByIdStateObject = {
  [nodeId: string]: boolean | undefined
}

export const OkrCardIsFoldByIdContext = createContext<IsFoldByIdStateObject>({})
export const UpdateOkrCardIsFoldByIdContext = createContext<
  Dispatch<SetStateAction<IsFoldByIdStateObject>>
>(() => {
  throw new Error('Should use UpdateOkrCardIsFoldByIdContext.Provider')
})

export const OkrCardIsFoldByIdContextProvider: React.FC = ({ children }) => {
  const [stateObject, setStateObject] = useState<IsFoldByIdStateObject>({})
  return (
    <UpdateOkrCardIsFoldByIdContext.Provider value={setStateObject}>
      <OkrCardIsFoldByIdContext.Provider value={stateObject}>
        {children}
      </OkrCardIsFoldByIdContext.Provider>
    </UpdateOkrCardIsFoldByIdContext.Provider>
  )
}

OkrCardIsFoldByIdContextProvider.displayName = 'OkrCardIsFoldByIdContextProvider'

export const useIsFoldOkrCardById = (nodeId: string): boolean | undefined =>
  useContextSelector(OkrCardIsFoldByIdContext, (obj) => obj[nodeId])
export const useSetIsFoldOkrCardById = (nodeId: string): ((isFold: boolean) => void) => {
  const update = useContext(UpdateOkrCardIsFoldByIdContext)
  return useCallback(
    (isFold: boolean) => update((obj) => ({ ...obj, [nodeId]: isFold })),
    [nodeId, update],
  )
}
