import { css } from '@emotion/react'

export const styles = {
  root: css({
    marginBottom: '16px',
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  }),
  title: css({
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 'bold',
  }),
}
