import { css } from '@emotion/react'

import { color } from '../../../../../../../styles/newColors'

export const rootCss = css({
  display: 'grid',
  gridTemplateColumns: '224px auto',
  gridTemplateRows: '100%',
  height: '100%',
  '> *:not(:last-of-type)': { borderRight: `1px solid ${color('border-bk-30')}` },
})
