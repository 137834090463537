import { useCallback } from 'react'

import { convertToFileInput } from '../../../../../lib/fileInput'
import { useOkrModalQueryParams } from '../../hooks/useOkrModalQueryParams'
import { LoadableProps } from '../../types'

import {
  ObjectiveDescription,
  DataProps as ObjectiveDescriptionDataProps,
  Props as ObjectiveDescriptionProps,
} from './ObjectiveDescription'
import { File, useUpdateObjectiveDescriptionMutation } from './graphql'

export type DataProps = ObjectiveDescriptionDataProps

export type Props = LoadableProps<DataProps> &
  Pick<ObjectiveDescriptionProps, 'onDelete' | 'onDisable'>

export const ObjectiveDescriptionContainer: React.VFC<Props> = ({
  loading,
  objective,
  groups,
  onDelete,
  onDisable,
}) => {
  const { objectiveId } = useOkrModalQueryParams()

  const [updateObjectiveDescription] = useUpdateObjectiveDescriptionMutation()
  const updateObjectiveDescriptionMutation = useCallback(
    async (
      treeJson: string,
      plainText: string,
      beforeTreeJson: string,
      beforePlainText: string,
      newAttachments: ReadonlyArray<File>,
    ) => {
      if (!objectiveId) return
      await updateObjectiveDescription({
        variables: {
          input: {
            id: objectiveId,
            beforeDescription: {
              treeJson: beforeTreeJson,
              plainText: beforePlainText,
            },
            description: { treeJson, plainText },
            attachments: newAttachments.map(convertToFileInput),
            // TODO: isDescriptionChangedフラグの扱いを確認する
            // isDescriptionChanged: true,
          },
        },
      })
    },
    [objectiveId, updateObjectiveDescription],
  )

  return (
    <ObjectiveDescription
      loading={(loading || objective == null || groups == null) as never}
      objective={objective as never}
      groups={groups as never}
      saveDescription={updateObjectiveDescriptionMutation}
      onDelete={onDelete}
      onDisable={onDisable}
    />
  )
}
