import React from 'react'

import { useTranslation } from '../../../../../i18n'
import { Modal } from '../../../../ui/Modal'
import { StyledText } from '../../../../ui/StyledText'

import { CancelModalProps } from './types'

export const CancelModal: React.FC<CancelModalProps> = ({
  children,
  isOpened,
  onClickDelete,
  onClickCancel,
  onClickOutside,
}) => {
  const { t } = useTranslation()
  return (
    <Modal
      isOpened={isOpened}
      title={t('CONFIRMATION_OF_CANCEL_INPUT')}
      executeText={t('DISCARD')}
      onClickExecute={onClickDelete}
      onClickCancel={onClickCancel}
      onClickOutside={onClickOutside}
    >
      <StyledText>
        {t('CONFIRMATION_OF_CANCEL_INPUT_DESCRIPTION_X', {
          x: t('ONEONONE') + t('ONEONONE_FIXED_AGENDA'),
        })}
      </StyledText>
      {children}
    </Modal>
  )
}

CancelModal.displayName = 'CancelModal'
