import { css } from '@emotion/react'
import { ThemeType } from 'grommet'

import { color } from '../../../styles/newColors'
import { Icon } from '../../ui/Icon'

export const extendValue: ThemeType = {
  global: {
    drop: { zIndex: '100' },
    colors: {
      selected: color('text-bk-10'),
    },
    focus: {
      shadow: {
        size: '0',
      },
    },
  },
  select: {
    options: {
      container: {
        pad: '8px',
      },
      text: { color: color('text-bk-100'), size: '16px' },
    },
    icons: {
      margin: '0 8px 0 4px',
      down: (
        <Icon
          type="selectDown"
          color={color('border-bk-20')}
          css={{
            marginLeft: '4px',
            marginRight: '4px',
            width: '8px',
            height: '4px',
            zIndex: -1,
          }}
        />
      ),
      up: (
        <Icon
          type="selectDown"
          color={color('border-bk-20')}
          css={{
            marginLeft: '4px',
            marginRight: '4px',
            width: '8px',
            height: '4px',
            transform: 'rotate(-180deg)',
            zIndex: -1,
          }}
        />
      ),
    },
    control: {
      extend: {
        border: `1px solid ${color('border-bk-30')}`,
        borderRadius: '4px',
        fontSize: '14px',
      },
    },
  },
}

export const selectCss = css({
  fontWeight: 500,
  margin: '12px 0 12px 8px',
  padding: 0,
})
