import { css, CSSObject } from '@emotion/react'
import { useMemo } from 'react'

import * as colors from '../../../styles/colors'

export type Props = {
  width: number
  height: number
  fontSize: number
  backgroundColor: string | undefined
  avatarStyle: CSSObject
  hoverStyle: CSSObject
  disabledStyle: CSSObject
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useStyles = ({
  width,
  height,
  fontSize,
  backgroundColor,
  avatarStyle,
  hoverStyle,
  disabledStyle,
}: Props) =>
  useMemo(
    () =>
      ({
        root: css({
          ':hover': {
            textDecoration: 'none',
          },
        }),
        tooltip: css({
          display: 'flex',
          alignItems: 'center',
        }),
        wrapper: css({
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
          verticalAlign: 'bottom',
          fontWeight: 'bold',
          color: colors.baseFill,
          textTransform: 'uppercase',
          backgroundColor: backgroundColor || colors.inactive,
          borderRadius: '50%',
          whiteSpace: 'nowrap',
          fontSize,
          width,
          height,
          ...avatarStyle,
          userSelect: 'none',
          ':hover': {
            ...hoverStyle,
          },
          ...disabledStyle,
        }),
        image: css({
          width: '100%',
        }),
      } as const),
    [avatarStyle, backgroundColor, disabledStyle, fontSize, height, hoverStyle, width],
  )
