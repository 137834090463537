import {
  ELEMENT_OL,
  ELEMENT_UL,
  getPluginType,
  ListStyleType,
  someNode,
  TElement,
  usePlateEditorState,
  useEventEditorSelectors,
} from '@udecode/plate'
import React, { useCallback } from 'react'

import { ElementType } from '../../types'
import { useToggleElement } from '../useToggleElement'

import { ButtonTooltip } from './ButtonTooltip'
import { ToolbarButton } from './ToolbarButton'

export type Props<T extends ElementType> = {
  children: React.ReactNode
  type: T
  inactiveType?: Exclude<ElementType, T>
  tooltipMessage: string
}

export const ToolbarElement = <T extends ElementType>({
  children,
  type,
  inactiveType,
  tooltipMessage,
}: Props<T>): JSX.Element => {
  const focusId = useEventEditorSelectors.focus()
  const editor = usePlateEditorState(focusId != null ? focusId : undefined)
  const isActive =
    !!editor?.selection &&
    someNode<TElement>(editor, {
      match:
        type === ELEMENT_OL || type === ELEMENT_UL
          ? {
              listStyleType: type === ELEMENT_OL ? ListStyleType.Decimal : ListStyleType.Disc,
            }
          : { type: getPluginType(editor, type) },
    })
  const toggleElement = useToggleElement<T>(editor)

  return (
    <ButtonTooltip message={tooltipMessage}>
      <ToolbarButton
        isActive={isActive}
        toggleNode={useCallback(
          () => toggleElement(type, inactiveType),
          [toggleElement, type, inactiveType],
        )}
      >
        {children}
      </ToolbarButton>
    </ButtonTooltip>
  )
}
