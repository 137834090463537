import { Fragment, useState, useMemo, useRef, useEffect } from 'react'

import { useClickOutside } from '../../../lib/clickOutside'
import { Screen } from '../../../lib/screen'

import { Bookmark } from './Bookmark'
import { OkrName } from './OkrName'
import { SelectedGroups } from './SelectedGroups'
import { SelectedUsers } from './SelectedUsers'
import { GroupFragment, UserFragment } from './graphql'
import { useFilterHandleFactory } from './useFilterHandleFactory'

export type Props = {
  canFilter: boolean
  maxWidth: number
  groups: ReadonlyArray<GroupFragment>
  users: ReadonlyArray<UserFragment>
  selectedGroupIds: ReadonlyArray<string>
  selectedUserIds: ReadonlyArray<string>
  onFilterChange: (filter: {
    groupIds?: ReadonlyArray<string>
    userIds?: ReadonlyArray<string>
  }) => void
  onReflectGroupTracker: (screen: Screen) => void
  onReflectUserTracker: (screen: Screen) => void
  type?: 'subtree' | 'focus'
  name?: string
  createBookmark?: (name: string) => Promise<void>
  onClickNameCancel?: () => void
}

export const GroupAndUserFilter: React.FC<Props> = ({
  canFilter,
  maxWidth,
  groups,
  users,
  selectedUserIds,
  selectedGroupIds,
  onFilterChange,
  onReflectGroupTracker,
  onReflectUserTracker,
  type,
  name,
  createBookmark,
  onClickNameCancel,
}) => {
  const [openGroup, setOpenGroup] = useState<boolean>(false)
  const [openUser, setOpenUser] = useState<boolean>(false)
  const [groupTrimCount, setGroupTrimCount] = useState<number | undefined>(undefined)
  const [userTrimCount, setUserTrimCount] = useState<number | undefined>(undefined)
  const [state, setState] = useState<State>({ type: 'reset' })
  const [resetTrimCount, setResetTrimCount] = useState(0)

  const [groupMaxWidth, setGroupMaxWidth] = useState<number | undefined>(undefined)
  const [userMaxWidth, setUserMaxWidth] = useState<number | undefined>(undefined)

  const filterRef = useRef<HTMLDivElement>(null)
  const selectedUsersRef = useRef<HTMLDivElement>(null)
  const selectedGroupsRef = useRef<HTMLDivElement>(null)

  useClickOutside({
    callback: () => setOpenUser(false),
    ref: selectedUsersRef,
  })

  useClickOutside({
    callback: () => setOpenGroup(false),
    ref: selectedGroupsRef,
  })

  const selectedGroups: ReadonlyArray<GroupFragment> = useMemo(
    () =>
      selectedGroupIds
        .map((gid) => {
          const group = groups.find(({ id }) => id === gid)
          return group
        })
        .flatMap((g) => (g ? [g] : [])),
    [selectedGroupIds, groups],
  )

  const selectedUsers: ReadonlyArray<UserFragment> = useMemo(
    () =>
      selectedUserIds
        .map((uid) => {
          const user = users.find(({ id }) => id === uid)
          return user
        })
        .flatMap((u) => (u ? [u] : [])),
    [selectedUserIds, users],
  )

  useEffect(() => {
    if (state.type === 'done') {
      setResetTrimCount((prev) => prev + 1)
      setGroupTrimCount(state.groupTrimCount)
      setUserTrimCount(state.userTrimCount)
      setGroupMaxWidth(state.groupMaxWidth)
      setUserMaxWidth(state.userMaxWidth)
    }
  }, [state])

  const { clearGroups, clearUsers, addGroupId, addUserId } = useFilterHandleFactory({
    selectedGroupIds,
    selectedUserIds,
    groups,
    onFilterChange,
    onReflectGroupTracker,
    onReflectUserTracker,
  })

  return (
    <Fragment>
      <div css={{ position: 'absolute', top: -200 }}>
        <DummyComponent
          type={type}
          canFilter={canFilter}
          maxWidth={maxWidth}
          name={name}
          groups={groups}
          users={users}
          selectedGroupIds={selectedGroupIds}
          selectedUserIds={selectedUserIds}
          state={state}
          setState={setState}
        />
      </div>
      <div css={{ display: 'flex', zIndex: 9 }}>
        {name && onClickNameCancel && (
          <Fragment>
            <OkrName type={type} name={name} onClickCancel={onClickNameCancel} />
          </Fragment>
        )}

        {canFilter && (
          <div ref={filterRef} css={{ display: 'flex', alignItems: 'center' }}>
            <div ref={selectedGroupsRef} css={{ marginLeft: name ? '8px' : undefined }}>
              <SelectedGroups
                trimCount={0}
                setTrimCount={() => {}}
                fixTrimCount={groupTrimCount}
                resetTrimCount={resetTrimCount}
                maxWidth={groupMaxWidth}
                zIndex={20}
                openMultiSelect={openGroup}
                onClickOpenMultiSelect={() => setOpenGroup(true)}
                onClickOption={(id) => {
                  addGroupId(id)
                  setState({ type: 'reset' })
                }}
                groups={groups}
                selectedGroupIds={selectedGroupIds}
                onClear={clearGroups}
              />
            </div>
            <div ref={selectedUsersRef} css={{ marginLeft: '8px' }}>
              <SelectedUsers
                trimCount={0}
                setTrimCount={() => {}}
                fixTrimCount={userTrimCount}
                resetTrimCount={resetTrimCount}
                maxWidth={userMaxWidth}
                zIndex={20}
                openMultiSelect={openUser}
                onClickOpenMultiSelect={() => setOpenUser(true)}
                onClickOption={(id) => {
                  addUserId(id)
                  setState({ type: 'reset' })
                }}
                users={users}
                selectedUserIds={selectedUserIds}
                onClear={clearUsers}
              />
            </div>
            {createBookmark && (
              <div css={{ marginLeft: '8px' }}>
                <Bookmark
                  selectedGroups={selectedGroups}
                  selectedUsers={selectedUsers}
                  onAdd={createBookmark}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </Fragment>
  )
}

GroupAndUserFilter.displayName = 'GroupAndUserFilter'

const noop = () => {}

type State =
  | { type: 'reset' }
  | {
      type: 'done'
      groupTrimCount: number
      userTrimCount: number
      groupMaxWidth: number
      userMaxWidth: number
    }

type DummyComponentProps = Pick<
  Props,
  | 'type'
  | 'canFilter'
  | 'maxWidth'
  | 'name'
  | 'groups'
  | 'users'
  | 'selectedGroupIds'
  | 'selectedUserIds'
> & {
  state: State
  setState: (state: State) => void
}

const DummyComponent: React.FC<DummyComponentProps> = ({
  type,
  canFilter,
  maxWidth,
  name,
  groups,
  users,
  selectedUserIds,
  selectedGroupIds,
  state,
  setState,
}) => {
  const [maxGroupWidth, setMaxGroupWidth] = useState<number>(maxWidth)
  const [maxUserWidth, setMaxUserWidth] = useState<number>(maxWidth)
  const [groupTrimCount, setGroupTrimCount] = useState(0)
  const [userTrimCount, setUserTrimCount] = useState(0)

  const [count, setCount] = useState(0)

  const nameRef = useRef<HTMLDivElement>(null)
  const groupRef = useRef<HTMLDivElement>(null)
  const userRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (state.type === 'reset') {
      setCount((prev) => prev + 1)
    }
  }, [state.type])

  useEffect(() => {
    if (state.type === 'reset') {
      setMaxGroupWidth(maxWidth)
      setMaxUserWidth(maxWidth)
    }
  }, [setMaxGroupWidth, setMaxUserWidth, maxWidth, state])

  useEffect(() => {
    const groupWidth: number | undefined = groupRef.current?.scrollWidth ?? 0
    const userWidth: number | undefined = userRef.current?.scrollWidth ?? 0

    if (groupWidth === undefined) return
    if (userWidth === undefined) return

    const value = 20
    if (groupWidth + userWidth > maxWidth) {
      if (groupWidth > userWidth) {
        setMaxGroupWidth((prev) => (prev > value ? prev - value : prev))
        return
      }
      setMaxUserWidth((prev) => (prev > value ? prev - value : prev))
      return
    }

    setState({
      type: 'done',
      groupTrimCount,
      userTrimCount,
      groupMaxWidth: maxGroupWidth,
      userMaxWidth: maxUserWidth,
    })
  }, [
    groupRef,
    userRef,
    maxWidth,
    maxGroupWidth,
    maxUserWidth,
    groupTrimCount,
    userTrimCount,
    setState,
    selectedUserIds,
    selectedGroupIds,
  ])

  return (
    <div css={{ display: 'flex', zIndex: 10 }} key={count}>
      {name && (
        <div ref={nameRef}>
          <OkrName type={type} name={name} onClickCancel={noop} />
        </div>
      )}

      {canFilter && (
        <Fragment>
          <div ref={groupRef} css={{ marginLeft: name ? '8px' : undefined }}>
            <SelectedGroups
              trimCount={groupTrimCount}
              setTrimCount={setGroupTrimCount}
              maxWidth={typeof maxGroupWidth === 'number' ? maxGroupWidth : undefined}
              zIndex={20}
              openMultiSelect={false}
              onClickOpenMultiSelect={noop}
              onClickOption={noop}
              groups={groups} // dummy componentは外観の大きさが分かれば良いのでメニューを開いた際に必要になる完全なデータは必要ない
              selectedGroupIds={selectedGroupIds}
              onClear={noop}
            />
          </div>
          <div ref={userRef} css={{ marginLeft: '8px' }}>
            <SelectedUsers
              trimCount={userTrimCount}
              setTrimCount={setUserTrimCount}
              maxWidth={typeof maxUserWidth === 'number' ? maxUserWidth : undefined}
              zIndex={20}
              openMultiSelect={false}
              onClickOpenMultiSelect={noop}
              onClickOption={noop}
              users={users}
              selectedUserIds={selectedUserIds}
              onClear={noop}
            />
          </div>
        </Fragment>
      )}
    </div>
  )
}

DummyComponent.displayName = 'DummyComponent'
