import { State, HasKeyResult } from '../../../contexts/KrFilterContext'
import { OkrNode } from '../../../graphql'
import { some } from '../../array'
import { Node, descendants } from '../../collections/node'
import { TreeList } from '../../collections/treeList'

/**
 * GraphQLに結合したtype
 */
type OKRUser = Pick<
  | OkrNode['objective']['owner']
  | OkrNode['keyResults'][number]['owner']
  | OkrNode['keyResults'][number]['members'][number],
  'id'
>
export type OKR = Pick<OkrNode, 'id' | 'depth' | 'childNodeIds'> & {
  readonly groups: ReadonlyArray<Pick<OkrNode['groups'][number], 'id'>>
  readonly objective: Pick<OkrNode['objective'], 'id' | 'isDisabled' | 'useWeighting'> & {
    readonly owner: OKRUser
  }
  readonly keyResults: ReadonlyArray<
    Pick<OkrNode['keyResults'][number], 'id' | 'isDisabled'> & {
      readonly owner: OKRUser
      readonly members: ReadonlyArray<OKRUser>
    }
  >
}

export type FilterArguments = {
  readonly showingNodeIds?: ReadonlyArray<string> // グループやユーザーのフィルタ対象では無いが表示させたいNode
} & (
  | {
      readonly requiredGroupIds: ReadonlyArray<string>
      readonly selectedUserIds?: ReadonlyArray<string>
    }
  | {
      readonly requiredGroupIds?: ReadonlyArray<string>
      readonly selectedUserIds: ReadonlyArray<string>
    }
)

export type FilterKrArguments = {
  readonly showingNodeIds?: ReadonlyArray<string> // KeyResultのフィルタ対象では無いが表示させたいNode
  readonly filters?: State['filters']
  hasKeyResult: HasKeyResult
}

/**
 * OKRツリーのデータモデルを包括的に扱うためのモデル
 */
export class OKRTree<T extends OKR> extends TreeList<T> {
  /**
   * ダウンキャスト用コンストラクタ
   * @param nodes ノード配列
   * @param tree ダウンキャスト用TreeListインスタンス
   */
  public constructor(nodes?: ReadonlyArray<T> | undefined, tree?: TreeList<T>) {
    super(nodes)

    if (tree) {
      // ダウンキャスト的なもの
      /* eslint-disable @typescript-eslint/dot-notation */
      this.roots = tree['roots']
      this.levelLayerMap = tree['levelLayerMap']
      this.nodeMap = tree['nodeMap']
      /* eslint-enable @typescript-eslint/dot-notation */
    }
  }

  /**
   * mapした結果でfilterする
   * パフォーマンスの観点から同時に処理する関数を用意
   * Array.prototype.flatMap の同類
   * @param cb コールバック
   * @returns ノード配列
   */
  public filterMap(cb: (n: Node<T>) => Node<T> | null): Array<Node<T>> {
    const nodes: Array<Node<T>> = []
    this.traversal((n) => {
      const newNode = cb(n)
      if (newNode != null) {
        nodes.push(newNode)
      }
    })
    return nodes
  }

  /**
   * 全てのノードから抽出したユニークなグループ一覧を返却
   * @returns グループの配列
   */
  public getGroups(): Node<T>['groups'] {
    const groups = this.getAllNodes().flatMap((n) => n.groups)
    return [...new Map(groups.map((g) => [g.id, g])).values()]
  }

  /**
   * OKRツリーをフィルタし新しいOKRツリーを構築します
   * @param nodes フィルタ対象ノード
   * @param param1 フィルタ対象とするノードの属性
   * @returns フィルタ済みノード
   */
  public static filterOkrNodes = <FT extends OKR>(
    nodes: ReadonlyArray<Node<FT>>,
    { requiredGroupIds = [], selectedUserIds = [], showingNodeIds = [] }: FilterArguments,
  ): ReadonlyArray<Node<FT>> => {
    if (
      requiredGroupIds.length === 0 &&
      selectedUserIds.length === 0 &&
      showingNodeIds.length === 0
    ) {
      // フィルター未選択の場合は全件抽出
      return nodes
    }

    const idFilter = (
      selectedIds: ReadonlyArray<string>,
      searchTargetIds: ReadonlyArray<string>,
    ): boolean =>
      selectedIds.length === 0
        ? true // 未選択の場合は全件抽出
        : some(selectedIds, searchTargetIds) // 検索条件を指定した場合は、検索条件のいずれかと一致するものを抽出

    return nodes.filter((n) => {
      // 指定したnodeIdsのいずれかに一致した場合は抽出
      if (showingNodeIds.includes(n.id)) {
        return true
      }

      const isHitGroup = idFilter(
        requiredGroupIds,
        n.groups.map((g) => g.id),
      )
      const isHitUser = idFilter(selectedUserIds, [
        n.objective.owner.id,
        ...n.keyResults.flatMap((kr) => [kr.owner.id, ...kr.members.map((u) => u.id)]),
      ])

      return isHitGroup && isHitUser // groupとuserはand検索
    })
  }

  /**
   * OKRツリーを非表示にするための除外リストを返す
   * @param nodes 対象ノード
   * @param param1 フィルタ対象とするノードの属性
   * @returns 除外リスト(node id)
   */
  public static getExcludeIds = <FT extends OKR>(
    nodes: ReadonlyArray<Node<FT>>,
    { showingNodeIds = [], filters, hasKeyResult }: FilterKrArguments,
  ): ReadonlyArray<string> => {
    if (!filters || filters?.size === 0) {
      // フィルター未選択の場合は、何も除外しない
      return []
    }

    return nodes
      .filter((n) => {
        // 指定したnodeIdsのいずれかに一致した場合は除外リストに追加しない
        if (showingNodeIds.includes(n.id)) return false

        // KRフィルタのフィルタ対象の場合、除外リストに追加しない
        if (filters.has(n.objective.id)) return false

        // 親のKeyResultのKRフィルタが有効なのにフィルタ対象ではない場合、除外リストに入る
        return n.parent?.get(n)?.keyResults.some((kr) => hasKeyResult(kr.id)) ?? false
      }) // 子孫も除外リストに含める
      .flatMap((n) => [n, ...descendants(n)].map((node) => node.id))
  }
}
