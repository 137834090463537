import { css } from '@emotion/react'
import { useMemo } from 'react'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useStyles = () =>
  useMemo(
    () =>
      ({
        root: css({ position: 'relative' }),
        multiSelectRoot: css({
          position: 'absolute',
          zIndex: 501,
        }),
        groupTagsRoot: css({
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          gap: '8px',
        }),
      } as const),
    [],
  )
