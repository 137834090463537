import { Box, Card, CardBody, Image, Paragraph } from 'grommet'
import React from 'react'

import { Icon } from '../../../components/ui/Icon'
import { Link } from '../../../components/ui/Link'
import { StyledText } from '../../../components/ui/StyledText/index'
import { color } from '../../../styles/newColors'

import { TipsItem } from './TipsList'
// FIXME: TipsList から fallback を import できるようにしたい（fallback の責務を TipsList に限定したい）。
import fallback from './assets/fallback.svg'

export type Props = {
  item?: TipsItem
}

export const Tips: React.FC<Props> = ({ item }) =>
  item ? (
    <Link
      method="newTab"
      href={item.link}
      css={{
        ':hover': {
          color: color('text-bk-100'),
          textDecoration: 'none',
        },
      }}
    >
      <Card
        css={{
          width: '316px',
        }}
      >
        <Box
          css={{
            position: 'relative',
          }}
        >
          <Image fit="cover" src={item.image} width="316" height="178" fallback={fallback} />
          <Box
            pad={{ vertical: '6px', horizontal: '10px' }}
            background={color('resily-orange-100')}
            css={{
              position: 'absolute',
              top: 0,
              right: 0,
              borderRadius: '0 0 0 8px',
            }}
          >
            <StyledText
              size="small"
              lineHeight="16px"
              weight={700}
              css={{
                color: color('white-100'),
              }}
            >
              {item.kinds}
            </StyledText>
          </Box>
        </Box>
        <CardBody pad="24px" responsive={false} css={{ background: color('white-100') }}>
          <Box
            margin={{ bottom: '10px' }}
            css={{
              fontWeight: 'bold',
              fontSize: '12px',
              color: '#DC440A',
            }}
          >
            {item.category}
          </Box>
          <StyledText
            css={{
              fontSize: '14px',
              fontWeight: 700,
              marginBottom: '12px',
            }}
          >
            {item.title}
          </StyledText>
          <Paragraph
            size="small"
            margin={{ bottom: '24px' }}
            css={{
              lineHeight: '21px',
              whiteSpace: 'pre-wrap',
            }}
          >
            {item.body}
          </Paragraph>
          {item.externalArticleTitle ? (
            <Box direction="row" justify="start">
              <div css={{ paddingRight: '8px' }}>
                <Icon
                  width={10}
                  height={10}
                  color={color('text-bk-50')}
                  type="externalLink"
                  css={{ marginTop: '3px' }}
                />
              </div>
              <Box
                width="250px"
                css={{ fontSize: '10px', lineHeight: '15px', color: color('text-bk-50') }}
              >
                {item.externalArticleTitle}
              </Box>
            </Box>
          ) : (
            <Box direction="row" justify="start">
              <StyledText
                size="small"
                color="resily-orange-100"
                css={{
                  fontWeight: 'bold',
                  paddingRight: '8px',
                }}
              >
                read more
              </StyledText>
              <Icon
                type="opened"
                width={12}
                height={12}
                color={color('resily-orange-100')}
                css={{ transform: 'rotate(90deg)', marginTop: '5px' }}
              />
            </Box>
          )}
        </CardBody>
      </Card>
    </Link>
  ) : null

Tips.displayName = 'Tips'
