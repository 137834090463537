import { OkrTitle } from '@resily/geisha'
import { Drop, ThemeContext } from 'grommet'
import { useCallback, useContext, useMemo, useRef, useState } from 'react'

import { OkrTermIdContext } from '../../../contexts/OkrTermIdContext'
import { useTranslation } from '../../../i18n'
import { StyledText } from '../../ui/StyledText'
import { TextButton } from '../../ui/TextButton'
import { ParentOkrSelectOptions } from '../ParentOkrSelectOptions'

import { useOkrNodesLazyQuery } from './graphql'
import { useStyles } from './index.styles'
import { Objective, useAlertModal } from './useAlertModal'

export type Props = {
  currentObjective: Objective
  updateParentObjective?: (objectiveId: string) => void
}

export const ParentOkrBar: React.VFC<Props> = ({ currentObjective, updateParentObjective }) => {
  const { t } = useTranslation()
  const styles = useStyles()

  const { okrTermId } = useContext(OkrTermIdContext)

  const rootRef = useRef<HTMLDivElement>(null)
  const [okrNodesQuery, { data, loading, called }] = useOkrNodesLazyQuery()
  const nodes = useMemo(
    () => data?.okrNodes.filter((n) => n.objective.id !== currentObjective.id) ?? [],
    [currentObjective.id, data?.okrNodes],
  )

  // 親Objective変更AlertModal
  const { AlertModal, isOpen: isOpenAlertModal, open: openAlertModal } = useAlertModal()
  const handleSelect = useCallback(
    (v: typeof nodes[0]) => {
      openAlertModal({
        oldObjective: currentObjective,
        newObjective: v.objective,
      })
    },
    [currentObjective, openAlertModal],
  )

  // 親Objective変更開閉
  const [showDrop, setShowDrop] = useState<boolean>(false)
  const hideDrop = useCallback(() => !isOpenAlertModal && setShowDrop(false), [isOpenAlertModal])

  // 親Objective変更ボタン
  const onClickChangeParentObjective = useCallback(() => {
    if (showDrop) return // トグル用
    setShowDrop(true)
    if (!called && okrTermId) {
      okrNodesQuery({ variables: { okrTermId } })
    }
  }, [called, okrNodesQuery, okrTermId, showDrop])

  // 親Objective変更決定
  const changeParentObjective = useCallback(
    (objectiveId: string) => {
      if (updateParentObjective == null) return
      updateParentObjective(objectiveId)
      setShowDrop(false)
    },
    [updateParentObjective],
  )

  return (
    <>
      <div ref={rootRef} css={styles.root}>
        <div css={styles.title}>
          <StyledText size="small" fontStyle="bold">
            {t('OBJECTIVE')}:
          </StyledText>
          <OkrTitle
            type="objective"
            size="small"
            withBorder
            title={currentObjective.name}
            lineClamp={1}
          />
        </div>
        {updateParentObjective != null && (
          <TextButton
            hasUnderline
            color="text-bk-50"
            size="small"
            padding="0"
            css={styles.updateParentObjectiveButton}
            onMouseDown={onClickChangeParentObjective}
          >
            {t('CHANGE')}
          </TextButton>
        )}
      </div>

      {showDrop && (
        // TODO: z-indexが競合してDropがAlertModalの手前に出てる対策、根本修正にはgeisha要修正
        <ThemeContext.Extend value={{ global: { drop: { zIndex: isOpenAlertModal ? 20 : 201 } } }}>
          <Drop
            target={rootRef.current || undefined}
            round="xsmall"
            stretch={false}
            margin={styles.dropMargin}
            align={styles.dropAlign}
            css={styles.drop}
            onEsc={hideDrop}
            onClickOutside={hideDrop}
          >
            <ParentOkrSelectOptions loading={loading} options={nodes} onSelect={handleSelect} />
          </Drop>
        </ThemeContext.Extend>
      )}

      <AlertModal onConfirm={changeParentObjective} />
    </>
  )
}
