import { useTranslation } from '../../../../../../i18n'
import { StyledText } from '../../../../../ui/StyledText'

import { useStyles } from './EmptyView.styles'

export const EmptyView: React.VFC = () => {
  const { t } = useTranslation()
  const styles = useStyles()

  return (
    <div css={styles.root}>
      <StyledText color="text-bk-100" size="small" fontStyle="regular">
        {t('X_IS_EMPTY', { x: t('ACTIVITY') })}
      </StyledText>
    </div>
  )
}
EmptyView.displayName = 'EmptyView'
