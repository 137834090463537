import { Box } from 'grommet'
import React from 'react'

import { Icon } from '../../components/ui/Icon'
import { StyledText } from '../../components/ui/StyledText'
import { useTranslation } from '../../i18n'
import { color } from '../../styles/newColors'

export const GettingStartedCard: React.FC = () => {
  const { t } = useTranslation()

  return (
    <Box
      border={{
        color: color('border-bk-10'),
      }}
      direction="row"
      margin={{
        top: '32px',
        bottom: '16px',
      }}
      round={{
        size: '8px',
      }}
    >
      <Box
        margin={{
          left: '48px',
        }}
      >
        <Icon height={202} type="checkinImage" width={282} />
      </Box>
      <Box
        align="start"
        margin={{
          left: '60px',
          top: '40px',
        }}
      >
        <StyledText size="xlarge" weight="bold">
          {t('GETTING_STARTED')}
        </StyledText>
        <StyledText css={{ marginTop: '16px' }} weight="bold">
          {t('HOW_TO_START_ONEONONE')}
        </StyledText>
        {/** TODO：ユーザーガイディング仕込むまで非表示
          <Button css={{ marginTop: '24px' }} newColor="resily-orange-100" size="s">
            {t('HOW_TO_USE')}
          </Button>
         */}
      </Box>
    </Box>
  )
}

GettingStartedCard.displayName = 'GettingStartedCard'
