import { Tooltip } from '@resily/geisha'

import { useTranslation } from '../../../../../i18n'
import { componentNames, featureNames, generateTestId } from '../../../../../lib/testId'
import { color } from '../../../../../styles/newColors'
import { ObjectiveAvatar } from '../../../../domain/AvatarWithContributor'
import { ProgressRate } from '../../../../ui/Meter'
import { StyledText } from '../../../../ui/StyledText'
import { ObjectiveAreaObjectiveFragment } from '../../graphql'
import { useStyles as useMainStyles } from '../Main.styles'

import { useStyles } from './ObjectiveArea.styles'

export type Props = {
  isSelected: boolean
  termId: string
  objective: ObjectiveAreaObjectiveFragment
  onClickObjective: () => void
}

export const ObjectiveArea: React.VFC<Props> = ({
  isSelected,
  termId,
  objective,
  onClickObjective,
}) => {
  const { t } = useTranslation()
  const mainStyles = useMainStyles()
  const styles = useStyles()

  return (
    <div
      css={mainStyles.areaRoot}
      data-testid={generateTestId(featureNames.okrModal, componentNames.objectiveArea)}
    >
      <StyledText size="large" fontStyle="bold" lineHeight="24px">
        {t('OBJECTIVE')}
      </StyledText>
      <div
        css={styles.button}
        tabIndex={0}
        role="button"
        onClick={onClickObjective}
        onKeyDown={onClickObjective}
        data-is-selected={isSelected}
      >
        <div css={styles.nameArea}>
          <ObjectiveAvatar size="small" termId={termId} owner={objective.owner} />
          <Tooltip label={objective.name} position="bottom-start">
            <span
              css={styles.name}
              className="o-name"
              data-is-selected={isSelected}
              data-is-disabled={objective.isDisabled}
            >
              {objective.name}
            </span>
          </Tooltip>
        </div>
        <ProgressRate color={color('objective-blue-100')} rate={objective.progressRate ?? 0} />
      </div>
    </div>
  )
}
