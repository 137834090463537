/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { css } from '@emotion/react'
import { useMemo } from 'react'

import { color, ColorAlias } from '../../../styles/newColors'

export type NoteCardStyleProps = {
  lineColor: ColorAlias | undefined
}

export const useStyles = ({ lineColor }: NoteCardStyleProps) =>
  useMemo(
    () =>
      ({
        root: css({ minHeight: '72px', padding: '12px 24px' }),
        wrapper: css({ display: 'flex' }),
        lineColor: css({
          minHeight: '100%',
          paddingLeft: '12px',
          borderLeft: lineColor && `4px solid ${color(lineColor)}`,
        }),
        content: css({ marginTop: '4px', marginBottom: '4px', width: '100%' }),
        title: css({
          ':hover': {
            color: color('resily-orange-100'),
            textDecoration: 'underline',
          },
        }),
        titleText: css({ overflow: 'hidden', maxHeight: `${2 * 20}px` }),
        permissionWrapper: css({ display: 'flex', alignItems: 'center', marginTop: '4px' }),
        permissionIcon: css({ width: '12px', height: '12px', marginRight: '4px' }),
        avatarWrapper: css({
          marginTop: '12px',
          display: 'flex',
          alignItems: 'center',
          lineHeight: 1,
        }),
        updatedAt: css({ lineHeight: '10px', marginLeft: '8px', whiteSpace: 'nowrap' }),
      } as const),
    [lineColor],
  )
