import { css } from '@emotion/react'
import { useMemo } from 'react'

import { color } from '../../../../../../styles/newColors'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useStyles = () =>
  useMemo(
    () =>
      ({
        root: css({
          padding: '16px 24px',
          boxShadow: `0px 1px 0px ${color('border-bk-10')}`,
        }),
        status: css({
          marginBottom: '8px',
        }),
        title: css({
          display: 'flex',
          flexDirection: 'column',
          gap: '12px',
          marginBottom: '16px',
        }),
        user: css({
          display: 'flex',
          alignItems: 'center',
        }),
        name: css({
          marginLeft: '4px',
        }),
        date: css({
          marginLeft: '4px',
        }),
      } as const),
    [],
  )
