import { Fragment, useRef, useState } from 'react'

import { useEditingStateMachine } from '../../../contexts/EditingStateContext'
import { KeyResultUpdateType } from '../../../graphql'
import { useTranslation } from '../../../i18n'
import { keyResultToConfidenceLevel } from '../../../lib/domain/confidence'
import { isNotFilledRequiredTargetValue } from '../../../lib/domain/keyResult/keyResultProgress'
import { color } from '../../../styles/newColors'
import { StyledText } from '../../ui/StyledText'
import { TipBox } from '../../ui/TooltipNew'
import { ConfidenceTag } from '../ConfidenceTag'
import { KeyResultQuickUpdateModalContainer } from '../KeyResultQuickUpdateModal'

import { KeyResultFragment } from './graphql'

export type Props = {
  withGeishaModal?: boolean
  keyResult: KeyResultFragment
  hoverActionDisabled?: boolean
  ['data-testid']?: string
  onUpdated?: () => void
}

export const ProgressRateTooltip: React.FC<Props> = ({
  withGeishaModal,
  keyResult,
  children,
  hoverActionDisabled,
  'data-testid': dataTestId,
  onUpdated = () => {},
}) => {
  const [isOpened, setIsOpened] = useState(false)
  const buttonRef = useRef<HTMLButtonElement>(null)

  return (
    <Fragment>
      <TipBox
        boxChildren={<ProgressRateSignalBoxChildren keyResult={keyResult} />}
        disabled={hoverActionDisabled}
      >
        <button
          type="button"
          ref={buttonRef}
          onClick={(e) => {
            e.stopPropagation()
            buttonRef.current?.blur()
            setIsOpened(true)
          }}
          css={{ outline: 'none', display: 'flex', alignItems: 'center' }}
          data-testid={dataTestId}
        >
          {children}
        </button>
      </TipBox>

      {isOpened && (
        <KeyResultQuickUpdateModalContainer
          withGeishaModal={withGeishaModal}
          keyResultId={keyResult.id}
          onClickCancel={(e) => {
            e.stopPropagation()
            setIsOpened(false)
          }}
          onClickExecute={(e) => {
            e.stopPropagation()
            setIsOpened(false)
            onUpdated()
          }}
        />
      )}
    </Fragment>
  )
}

ProgressRateTooltip.displayName = 'ProgressRateTooltip'

export const ProgressRateTooltipWithContext: React.FC<Props> = ({
  withGeishaModal,
  keyResult,
  children,
  hoverActionDisabled,
  onUpdated = () => {},
}) => {
  const [isOpened, setIsOpened] = useState(false)
  const buttonRef = useRef<HTMLButtonElement>(null)

  const { requestStartEdit, updateIsDirty, finishEdit } = useEditingStateMachine()
  const open = () => {
    requestStartEdit(() => {
      setIsOpened(true)
      // TODO: クイック更新モーダルで常に離脱モーダルが出てるのをやめたい
      setTimeout(() => updateIsDirty(true), 0)
      return () => setIsOpened(false)
    }, 'QUICK_UPDATE_MODAL')
  }
  const close = () => {
    setIsOpened(false)
    finishEdit()
  }

  return (
    <Fragment>
      <TipBox
        boxChildren={<ProgressRateSignalBoxChildren keyResult={keyResult} />}
        disabled={hoverActionDisabled}
      >
        <button
          type="button"
          ref={buttonRef}
          onClick={(e) => {
            e.stopPropagation()
            buttonRef.current?.blur()
            open()
          }}
          css={{ outline: 'none', display: 'flex', alignItems: 'center' }}
        >
          {children}
        </button>
      </TipBox>

      {isOpened && (
        <KeyResultQuickUpdateModalContainer
          withGeishaModal={withGeishaModal}
          keyResultId={keyResult.id}
          onClickCancel={(e) => {
            e.stopPropagation()
            close()
          }}
          onClickExecute={(e) => {
            e.stopPropagation()
            close()
            onUpdated()
          }}
        />
      )}
    </Fragment>
  )
}
ProgressRateTooltipWithContext.displayName = 'ProgressRateTooltipWithContext'

export const ProgressRateSignalBoxChildren: React.VFC<Pick<Props, 'keyResult'>> = ({
  keyResult,
}) => {
  const { t } = useTranslation()

  const showProgressRate =
    isNotFilledRequiredTargetValue(keyResult) ||
    keyResult.updateType === KeyResultUpdateType.Manual ||
    keyResult.isAutoAggregate

  return (
    <div css={{ color: color('text-bk-20'), textAlign: 'center' }}>
      <StyledText color="white-100" size="small" lineHeight="20px">
        {t('UPDATE_PROGRESS_RATE')}
      </StyledText>
      <br />
      <Fragment>
        {showProgressRate ? (
          <StyledText size="xsmall" lineHeight="20px">
            {t('PROGRESS_RATE')}: {keyResult.progressRate || 0}%
          </StyledText>
        ) : (
          <span css={{ fontSize: '0' }}>
            <StyledText
              size="xsmall"
              lineHeight="20px"
              css={{
                maxWidth: '192px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                display: 'inline-block',
              }}
            >
              {t('ACTUAL_VALUE')}: {keyResult.actualValue} {keyResult.unit}&nbsp;
            </StyledText>
            <StyledText
              size="xsmall"
              lineHeight="20px"
              css={{
                maxWidth: '192px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                display: 'inline-block',
              }}
            >
              {t('TARGET_VALUE')}: {keyResult.targetValue}&nbsp;
            </StyledText>
            <StyledText
              size="xsmall"
              lineHeight="20px"
              css={{
                maxWidth: '192px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                display: 'inline-block',
              }}
            >
              {t('INITIAL_VALUE')}: {keyResult.initialValue}
            </StyledText>
          </span>
        )}
      </Fragment>
      <div css={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <StyledText size="xsmall" lineHeight="16px">
          {t('CONFIDENCE_LEVEL')}:
        </StyledText>
        <span css={{ marginLeft: 4, lineHeight: 14 }}>
          <ConfidenceTag
            size="small"
            fontColor="text-bk-20"
            confidenceLevel={keyResultToConfidenceLevel(keyResult)}
          />
        </span>
      </div>
    </div>
  )
}

ProgressRateSignalBoxChildren.displayName = 'ProgressRateSignalBoxChildren'
