import {
  createBlockquotePlugin,
  createBoldPlugin,
  createCodeBlockPlugin,
  createCodePlugin,
  createDeserializeAstPlugin,
  createExitBreakPlugin,
  createHeadingPlugin,
  createHighlightPlugin,
  createHorizontalRulePlugin,
  createIndentPlugin,
  createIndentListPlugin,
  createItalicPlugin,
  createLinkPlugin,
  createParagraphPlugin,
  createResetNodePlugin,
  createSelectOnBackspacePlugin,
  createSoftBreakPlugin,
  createStrikethroughPlugin,
  createTodoListPlugin,
  createUnderlinePlugin,
  createImagePlugin,
  ELEMENT_BLOCKQUOTE,
  ELEMENT_CODE_BLOCK,
  ELEMENT_CODE_LINE,
  ELEMENT_HR,
  ELEMENT_IMAGE,
  ELEMENT_PARAGRAPH,
  ELEMENT_TODO_LI,
  getBlockAbove,
  isAncestorEmpty,
  isBlockAboveEmpty,
  isSelectionAtBlockStart,
  KEYS_HEADING,
  TEditor,
  unwrapCodeBlock,
  createPlugins,
  ELEMENT_H1,
  StyledElement,
  withProps,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_H4,
  ELEMENT_H5,
  ELEMENT_H6,
  createAutoformatPlugin,
  createDeserializeMdPlugin,
  createDeserializeHtmlPlugin,
  createDeserializeDocxPlugin,
  BlockquoteElement,
  createPlateUI,
  ELEMENT_LINK,
  ELEMENT_DEFAULT,
  MARK_HIGHLIGHT,
  outdentList,
  StyledLeaf,
  getParentNode,
  PlateEditor,
  isElement,
  withPlaceholders,
  MARK_CODE,
  CodeBlockElement,
  TodoListElement,
  deserializeMd,
  // useMentionPlugin,
} from '@udecode/plate'
import { useMemo } from 'react'

import { useTranslation } from '../../../../../i18n'
import { color } from '../../../../../styles/newColors'
import { INDENTABLE_ELEMENT_TYPES, isIndentedElement } from '../types'

import { autoFormatRules } from './autoformat/autoFormatRules'
import { createConvertFragmentFromSlateToPlatePlugin } from './converter/convertFragmentFromSlateToPlate'
import { createEditorProtocolPlugin } from './editorProtocol/createEditorProtocolPlugin'
import { HorizontalElement } from './hr/HorizontalElement'
import { ImageElement, ImageElementProps } from './image/ImageElement'
import { withImageUpload } from './image/withImageUpload'
import { LinkElement } from './link/LinkElement'

const isEmptyCodeBlockAbove = (editor: TEditor): boolean => {
  const blockEntry = getBlockAbove(editor)
  if (!blockEntry) {
    return false
  }

  const [block, path] = blockEntry
  if (!isElement(block) || block.type !== ELEMENT_CODE_LINE) {
    return false
  }

  const parentBlock = getParentNode(editor, path)?.[0]
  if (!isElement(parentBlock) || parentBlock.type !== ELEMENT_CODE_BLOCK) {
    return false
  }

  return isAncestorEmpty(editor, parentBlock)
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const usePlatePlugins = (
  imageUploader: (file: File) => Promise<string>,
  imageElementProps?: ImageElementProps,
) => {
  const { t } = useTranslation()
  // NOTE: メンション機能を追加する際に使う
  // const { getMentionSelectProps, plugin: mentionPlugin } = useMentionPlugin(optionsMentionPlugin)

  return useMemo(() => {
    const defaultComponents = withPlaceholders(createPlateUI(), [
      {
        key: ELEMENT_PARAGRAPH,
        placeholder: t('INPUT_TEXT'),
        hideOnBlur: true,
        as: 'p',
        styles: {
          root: {
            padding: '4px 0',
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '22px',
            wordBreak: 'break-word',
            // HACK: 幅が狭い箇所で外から指定された長いplaceholderを表示すると横スクロールバーが表示されてしまう対策
            'span[data-slate-placeholder="true"][contenteditable="false"]': {
              width: 'auto !important',
            },
          },
        },
      },
    ])
    return createPlugins(
      [
        // Elements
        createParagraphPlugin(),
        createBlockquotePlugin(),
        createCodeBlockPlugin(),
        createHeadingPlugin(),
        createImagePlugin({
          withOverrides: withImageUpload({ imageUploader }),
        }),
        // FIXME: Plateのバグにより、Listプラグインより先にプラグインスタックにCreateResetNodePlugin()をpushしておかないと
        // リストの要素が削除されないケースがある
        // https://github.com/udecode/plate/issues/2025
        createResetNodePlugin({
          options: {
            rules: [
              {
                hotkey: 'Enter',
                defaultType: ELEMENT_PARAGRAPH,
                types: [ELEMENT_BLOCKQUOTE, ELEMENT_TODO_LI],
                predicate: isBlockAboveEmpty,
              },
              // インデントされた行での連続改行時に空行ならインデントのネストを1段解除する挙動に変更する
              {
                hotkey: 'Enter',
                defaultType: ELEMENT_DEFAULT,
                types: INDENTABLE_ELEMENT_TYPES as unknown as Array<string>,
                predicate: (editor: PlateEditor) => {
                  const block = getBlockAbove(editor)?.[0]
                  if (!block || !isElement(block)) {
                    return false
                  }

                  return isIndentedElement(block) && isBlockAboveEmpty(editor)
                },
                onReset: outdentList,
              },
              {
                hotkey: 'Backspace',
                defaultType: ELEMENT_PARAGRAPH,
                types: [ELEMENT_BLOCKQUOTE, ELEMENT_TODO_LI],
                predicate: isSelectionAtBlockStart,
              },
              {
                hotkey: 'Backspace',
                defaultType: ELEMENT_PARAGRAPH,
                types: [ELEMENT_CODE_BLOCK, ELEMENT_CODE_LINE],
                predicate: (editor: PlateEditor) =>
                  isSelectionAtBlockStart(editor) && isEmptyCodeBlockAbove(editor),
                onReset: (editor: PlateEditor) => unwrapCodeBlock(editor),
              },
            ],
          },
        }),

        createLinkPlugin(),
        createHorizontalRulePlugin(),
        createTodoListPlugin(),
        createIndentListPlugin({
          inject: {
            props: {
              validTypes: [ELEMENT_IMAGE],
            },
          },
        }),

        // Marks
        createBoldPlugin({ options: { hotkey: 'mod+b' } }),
        createItalicPlugin({ options: { hotkey: 'mod+i' } }),
        createUnderlinePlugin({ options: { hotkey: 'mod+u' } }),
        createStrikethroughPlugin(),
        createCodePlugin(),
        createHighlightPlugin(),

        // Behaviors
        createAutoformatPlugin({ options: { rules: autoFormatRules } }),
        createSoftBreakPlugin({
          options: {
            rules: [
              {
                hotkey: 'shift+enter',
                query: { exclude: [ELEMENT_BLOCKQUOTE, ELEMENT_CODE_BLOCK, ELEMENT_CODE_LINE] },
              },
            ],
          },
        }),
        createExitBreakPlugin({
          options: {
            rules: [
              {
                hotkey: 'mod+enter',
              },
              {
                hotkey: 'mod+shift+enter',
                before: true,
              },
              {
                hotkey: 'enter',
                query: {
                  start: true,
                  end: true,
                  allow: KEYS_HEADING,
                },
              },
              {
                hotkey: 'enter',
                query: {
                  allow: [ELEMENT_CODE_BLOCK, ELEMENT_CODE_LINE],
                },
              },
            ],
          },
        }),
        createSelectOnBackspacePlugin({
          options: { query: { allow: [ELEMENT_HR, ELEMENT_IMAGE] } },
        }),
        createIndentPlugin({
          inject: {
            props: {
              validTypes: [
                ELEMENT_BLOCKQUOTE,
                ELEMENT_CODE_BLOCK,
                ELEMENT_H1,
                ELEMENT_H2,
                ELEMENT_H3,
                ELEMENT_H4,
                ELEMENT_H5,
                ELEMENT_H6,
                ELEMENT_LINK,
                ELEMENT_IMAGE,
                ELEMENT_TODO_LI,
                ELEMENT_PARAGRAPH,
              ],
            },
          },
        }),
        createEditorProtocolPlugin(),

        // Deserializers
        // FIXME: Plateのバージョン18でマークダウンのdeserialize修正処理が入っているため
        //        この処理は暫定的に対応中。バージョンアップ後は不要になるためthen以降のオーバーライドは不要
        createDeserializeMdPlugin({
          then: (editor) => ({
            editor: {
              insertData: {
                format: 'text/plain',
                query: () => true,
                getFragment: ({ data }) => deserializeMd(editor, data),
              },
            },
          }),
        }),
        createDeserializeHtmlPlugin(),
        createDeserializeDocxPlugin(),
        createDeserializeAstPlugin(),

        // 必ず最後に置く
        createConvertFragmentFromSlateToPlatePlugin(),
      ],
      {
        components: {
          ...defaultComponents,
          ...{
            [ELEMENT_H1]: withProps(StyledElement, {
              as: 'h1',
              styles: {
                root: {
                  padding: '16px 0 8px',
                  fontSize: '24px',
                  fontWeight: '700',
                  lineHeight: '32px',
                  ':first-child': {
                    paddingTop: '8px',
                  },
                },
              },
            }),
            [ELEMENT_H2]: withProps(StyledElement, {
              as: 'h2',
              styles: {
                root: {
                  padding: '12px 0 4px',
                  fontSize: '20px',
                  fontWeight: '700',
                  lineHeight: '28px',
                  ':first-child': {
                    paddingTop: '8px',
                  },
                },
              },
            }),
            [ELEMENT_H3]: withProps(StyledElement, {
              as: 'h3',
              styles: {
                root: {
                  padding: '8px 0 0',
                  fontSize: '16px',
                  fontWeight: '700',
                  lineHeight: '24px',
                },
              },
            }),
            [ELEMENT_H4]: withProps(StyledElement, {
              as: 'h4',
              styles: {
                root: {
                  fontSize: '14px',
                  fontWeight: '600',
                  lineHeight: '2.2',
                },
              },
            }),
            [ELEMENT_H5]: withProps(StyledElement, {
              as: 'h5',
              styles: {
                root: {
                  fontSize: '14px',
                  fontWeight: '400',
                  lineHeight: '2.2',
                },
              },
            }),
            [ELEMENT_H6]: withProps(StyledElement, {
              as: 'h6',
              styles: {
                root: {
                  fontSize: '12px',
                  fontWeight: '400',
                  lineHeight: '2',
                },
              },
            }),
            [ELEMENT_BLOCKQUOTE]: withProps(BlockquoteElement, {
              as: 'blockquote',
              styles: {
                root: {
                  margin: '4px 0',
                  borderLeft: `2px solid ${color('text-bk-30')}`,
                  padding: '0px 0px 0px 16px',
                  color: '#222943',
                  fontWeight: '700',
                  lineHeight: '22px',
                },
              },
            }),
            [ELEMENT_HR]: HorizontalElement,
            [ELEMENT_IMAGE]: withProps(ImageElement, {
              styles: {
                root: {
                  padding: '4px 0',
                },
                img: {
                  maxWidth: imageElementProps?.maxWidth,
                  maxHeight: imageElementProps?.maxHeight,
                },
              },
            }),
            [ELEMENT_LINK]: LinkElement,
            [MARK_HIGHLIGHT]: withProps(StyledLeaf, {
              as: 'mark',
              styles: {
                root: {
                  // リンクをハイライトすると文字が青くならない対策
                  color: 'inherit',
                  backgroundColor: '#FEF3B7',
                },
              },
            }),
            [ELEMENT_TODO_LI]: withProps(TodoListElement, {
              styles: {
                root: {
                  padding: '2px 0',
                  fontSize: '14px',
                  lineHeight: '22px',
                },
                checkboxWrapper: {
                  display: 'block',
                  marginRight: '12px',
                },
                checkbox: {
                  width: '12px',
                  height: '12px',
                  accentColor: color('resily-orange-100'),
                },
                text: {
                  wordBreak: 'break-word',
                },
              },
            }),
            [MARK_CODE]: withProps(StyledLeaf, {
              as: 'code',
              styles: {
                root: {
                  whiteSpace: 'pre-wrap',
                  padding: '2px 8px',
                  borderRadius: '4px',
                  backgroundColor: color('text-bk-20'),
                },
              },
            }),
            [ELEMENT_CODE_BLOCK]: withProps(CodeBlockElement, {
              as: 'pre',
              styles: {
                root: {
                  margin: '4px 0',
                  fontSize: '14px',
                  lineHeight: '22px',
                  select: {
                    '-moz-appearance': 'revert',
                    '-webkit-appearance': 'revert',
                    backgroundColor: 'revert',
                    borderStyle: 'revert',
                    font: 'revert',
                  },
                },
              },
            }),
          },
        },
      },
    )
  }, [imageUploader, imageElementProps, t])
}
