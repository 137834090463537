import { yupResolver } from '@hookform/resolvers/yup'
import dayjs from 'dayjs'
import { Grid, Box, Form } from 'grommet'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { Button } from '../../components/ui/Button'
import { DateInput } from '../../components/ui/DateInput'
import { FormSubmitField } from '../../components/ui/FormSubmitField'
import { StyledText } from '../../components/ui/StyledText'
import { useTranslation } from '../../i18n'
import { color } from '../../styles/newColors'

import { AccountSetupErrorMessage } from './AccountSetupErrorMessage'
import { AccountSetupInput } from './AccountSetupInput'
import { formSchema } from './formSchema'

export type FormValue = {
  organizationName: string
  firstName: string
  lastName: string
  phoneNumber: string
  email: string
  password: string
  termStartDate: string
  termEndDate: string
}

type Props = {
  email: string
  onSubmit: (values: FormValue) => void
}
const DATE_FORMAT = 'YYYY/MM/DD'
const now = dayjs.utc(dayjs().format(DATE_FORMAT))

// 当月の15日より前かどうか
const isBeforeHalfDate = now.isBefore(now.set('date', 15))

const defaultTermStartDate: string = (isBeforeHalfDate ? now : now.add(1, 'month'))
  .startOf('month')
  .toISOString()

const defaultTermEndDate: string = dayjs(defaultTermStartDate)
  .add(2, 'month')
  .endOf('month')
  .toISOString()

export const AccountSetupForm: React.VFC<Props> = ({ email, onSubmit }) => {
  const { t } = useTranslation()
  const {
    handleSubmit,
    control,
    trigger,
    getValues,
    setValue,
    formState: { errors, isValid },
  } = useForm<FormValue>({
    mode: 'all',
    defaultValues: { email, termStartDate: defaultTermStartDate, termEndDate: defaultTermEndDate },
    resolver: yupResolver(formSchema(t)),
  })

  // useFormのdefaultValuesに対するバリデーションは走らないので、triggerを使用して渡されたemailの形式チェックをする
  useEffect(() => {
    trigger('email')
  }, [email, trigger])

  return (
    <Form onSubmit={handleSubmit(onSubmit)} css={{ width: '100%' }}>
      <Controller
        name="organizationName"
        control={control}
        render={({ field: { onChange } }) => (
          <AccountSetupInput
            type="text"
            placeholder={t('COMPANY_NAME')}
            fieldError={errors.organizationName}
            onChange={(e) => onChange(e.target.value)}
          />
        )}
      />
      <AccountSetupErrorMessage fieldError={errors.organizationName} />
      <Grid
        rows={['small', 'small']}
        columns={['fit', 'fit']}
        gap={{
          row: 'none',
          column: '32px',
        }}
        areas={[
          { name: 'lastName', start: [0, 0], end: [0, 0] },
          { name: 'firstName', start: [1, 0], end: [1, 0] },
        ]}
      >
        <Box gridArea="lastName">
          <Controller
            name="lastName"
            control={control}
            render={({ field: { onChange } }) => (
              <AccountSetupInput
                type="text"
                placeholder={t('LAST_NAME')}
                fieldError={errors.lastName}
                onChange={(e) => onChange(e.target.value)}
              />
            )}
          />
          <AccountSetupErrorMessage fieldError={errors.lastName} />
        </Box>
        <Box gridArea="firstName">
          <Controller
            name="firstName"
            control={control}
            render={({ field: { onChange } }) => (
              <AccountSetupInput
                type="text"
                placeholder={t('FIRST_NAME')}
                fieldError={errors.firstName}
                onChange={(e) => onChange(e.target.value)}
              />
            )}
          />
          <AccountSetupErrorMessage fieldError={errors.firstName} />
        </Box>
      </Grid>
      <Controller
        name="phoneNumber"
        control={control}
        render={({ field: { value, onChange } }) => (
          <AccountSetupInput
            type="tel"
            value={value}
            autoComplete="tel"
            placeholder={t('PHONE_NUMBER')}
            fieldError={errors.phoneNumber}
            onChange={(e) => onChange(e.target.value)}
          />
        )}
      />
      <AccountSetupErrorMessage fieldError={errors.phoneNumber} />
      <Controller
        name="email"
        control={control}
        render={({ field: { value, onChange }, fieldState: { error, isDirty } }) => (
          <AccountSetupInput
            // プリセットされるEmailの形式が正しくない場合は、Inputを常にdisabled状態にさせない
            disabled={!error && !isDirty}
            type="email"
            value={value}
            autoComplete="email"
            placeholder={t('EMAIL')}
            fieldError={errors.email}
            onChange={(e) => onChange(e.target.value)}
          />
        )}
      />
      <AccountSetupErrorMessage fieldError={errors.email} />
      <Controller
        name="password"
        control={control}
        render={({ field: { onChange } }) => (
          <AccountSetupInput
            type="password"
            autoComplete="new-password"
            placeholder={t('PASSWORD')}
            fieldError={errors.password}
            onChange={(e) => onChange(e.target.value)}
          />
        )}
      />
      <AccountSetupErrorMessage fieldError={errors.password} />
      <div css={{ marginBottom: '32px', display: 'flex' }}>
        <div css={{ width: '164px' }}>
          <Controller
            control={control}
            name="termStartDate"
            render={({ field: { value, onChange: controllerOnChange } }) => (
              <>
                <StyledText
                  as="p"
                  size="xsmall"
                  color="text-bk-30"
                  css={{
                    margin: '0px 0px 4px 8px',
                    lineHeight: '14px',
                  }}
                >
                  {t('OKR_TERM_START_DATE')}
                </StyledText>
                <DateInput
                  value={value}
                  onChange={({ value: v }) => {
                    const termStartDate = getValues('termStartDate')
                    const termEndDate = getValues('termEndDate')

                    // 開始日が終了日以降になったら元々の間隔分終了日をずらす
                    if (termStartDate && termEndDate && typeof v === 'string') {
                      const termStartDateDayjs = dayjs(v)
                      const termEndDateDayjs = dayjs(termEndDate)
                      if (!termEndDateDayjs.isAfter(termStartDateDayjs, 'day')) {
                        const diffDays = termEndDateDayjs.diff(dayjs(termStartDate), 'day')
                        setValue(
                          'termEndDate',
                          termStartDateDayjs.add(diffDays, 'day').toISOString(),
                        )
                      }
                    }

                    controllerOnChange(v)
                    if (termEndDate) {
                      trigger('termEndDate')
                    }
                  }}
                  inputProps={{
                    onBlur: (e) => {
                      if (e.relatedTarget !== null) {
                        return
                      }
                      const termStartDate = getValues('termStartDate')
                      if (!termStartDate) trigger('termStartDate')
                    },
                  }}
                  placeholder={t('OKR_TERM_START_DATE')}
                  fieldError={errors.termStartDate}
                />
              </>
            )}
          />
        </div>
        <StyledText
          as="p"
          size="xsmall"
          color="text-bk-30"
          css={{ width: '32px', color: 'text-bk-30', textAlign: 'center', marginTop: '30px' }}
        >
          ~
        </StyledText>
        <div css={{ width: '164px' }}>
          <Controller
            control={control}
            name="termEndDate"
            render={({ field: { value, onChange: controllerOnChange } }) => (
              <>
                <StyledText
                  as="p"
                  size="xsmall"
                  color="text-bk-30"
                  css={{
                    margin: '0px 0px 4px 8px',
                    lineHeight: '14px',
                  }}
                >
                  {t('OKR_TERM_END_DATE')}
                </StyledText>
                <DateInput
                  value={value}
                  onChange={({ value: v }) => {
                    const termStartDate = getValues('termStartDate')
                    const termEndDate = getValues('termEndDate')

                    // 終了日が開始日以前になったら元々の間隔分開始日をずらす
                    if (termStartDate && termEndDate && typeof v === 'string') {
                      const termStartDateDayjs = dayjs(termStartDate)
                      const termEndDateDayjs = dayjs(v)
                      if (!termEndDateDayjs.isAfter(termStartDateDayjs, 'day')) {
                        const diffDays = dayjs(termEndDate).diff(termStartDateDayjs, 'day')
                        setValue(
                          'termStartDate',
                          termEndDateDayjs.subtract(diffDays, 'day').toISOString(),
                        )
                      }
                    }

                    controllerOnChange(v)
                    if (termStartDate) trigger('termStartDate')
                  }}
                  inputProps={{
                    onBlur: (e) => {
                      if (e.relatedTarget !== null) {
                        return
                      }
                      const termEndDate = getValues('termEndDate')
                      if (!termEndDate) trigger('termEndDate')
                    },
                  }}
                  placeholder={t('OKR_TERM_END_DATE')}
                  fieldError={errors.termEndDate}
                />
              </>
            )}
          />
        </div>
      </div>
      {errors.termStartDate ? <AccountSetupErrorMessage fieldError={errors.termStartDate} /> : null}
      {errors.termEndDate ? <AccountSetupErrorMessage fieldError={errors.termEndDate} /> : null}
      <FormSubmitField css={{ marginTop: '16px' }}>
        <Button
          type="submit"
          disabled={!isValid}
          newColor="resily-orange-100"
          size="s"
          weight="normal"
          css={{
            width: '100%',
            '&:disabled': {
              backgroundColor: color('resily-orange-16'),
              opacity: 1,
            },
          }}
        >
          {t('ACCOUNT_SETUP')}
        </Button>
      </FormSubmitField>
    </Form>
  )
}

AccountSetupForm.displayName = 'AccountSetupForm'
