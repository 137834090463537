import { css, CSSObject } from '@emotion/react'
import { Box, Button, BoxProps } from 'grommet'
import { Fragment, ReactElement, ReactNode } from 'react'

import { color } from '../../../../styles/newColors'
import { Icon } from '../../Icon'
import { Link } from '../../Link'
import { StyledText } from '../../StyledText'

export type PopoverIcon = 'help' | 'minus'

export type PopoverPosition = {
  top?: string
  right?: string
  bottom?: string
  left?: string
}

export type PopoverLink = {
  text: string
  href: string
}

export type Props = BoxProps & {
  icon?: PopoverIcon
  iconCss?: CSSObject
  title?: string
  text: string | ReactElement
  link?: PopoverLink
  position?: PopoverPosition
  hasGap?: boolean
  zIndex?: number
  children?: ReactNode
}

const POPOVER_WIDTH = 300
const GAP_HEIGHT = 4

export const Popover: React.FC<Props> = ({
  icon = 'help',
  iconCss = {} as CSSObject,
  title,
  text,
  link,
  position,
  hasGap = true,
  zIndex = 1,
  children,
  ...props
}) => (
  <Box
    css={{
      display: 'inline',
      ':hover': {
        '.popover': {
          display: 'block',
        },
      },
    }}
    {...props}
  >
    {children ?? (
      <Button
        icon={<Icon type={icon} css={css([{ width: 12, height: 12, ...iconCss }, iconCss])} />}
        css={{ padding: 0 }}
      />
    )}
    <Content
      icon={icon}
      iconCss={iconCss}
      title={title}
      text={text}
      link={link}
      position={position}
      hasGap={hasGap}
      zIndex={zIndex}
    />
  </Box>
)

Popover.displayName = 'Popover'

export const Content: React.FC<Props> = ({
  title,
  text,
  link,
  position,
  hasGap = true,
  zIndex,
}) => (
  <Fragment>
    <div css={{ position: 'relative' }}>
      {/* hover時のiconとpopoverの隙間を埋める */}
      {hasGap && (
        <div
          css={{
            width: `${POPOVER_WIDTH}px`,
            height: `${GAP_HEIGHT}px`,
            position: 'absolute',
            top: position?.top ?? `-${GAP_HEIGHT}px`,
            right: position?.right ?? 'auto',
            bottom: position?.bottom ?? 'auto',
            left: position?.left ?? '0',
          }}
        />
      )}
      <Box
        className="popover"
        pad={{ vertical: '16px', horizontal: '12px' }}
        width={{ min: `${POPOVER_WIDTH}px`, max: `${POPOVER_WIDTH}px` }}
        css={{
          backgroundColor: color('white-100'),
          border: `1px solid ${color('border-bk-20')}`,
          borderRadius: '4px',
          boxShadow: '0px 8px 16px rgb(34 41 67 / 8%)',
          top: position?.top ?? '0',
          right: position?.right ?? 'auto',
          bottom: position?.bottom ?? 'auto',
          left: position?.left ?? '0',
          position: 'absolute',
          display: 'none',
          zIndex,
        }}
      >
        {title && (
          <StyledText size="small" weight="bold" css={{ display: 'block', marginBottom: '8px' }}>
            {title}
          </StyledText>
        )}
        <StyledText size="small">{text}</StyledText>
        {link && (
          <Link
            method="newTab"
            href={link.href}
            css={{
              color: color('resily-orange-100'),
              display: 'block',
              marginTop: '12px',
            }}
          >
            {link.text}
            <Icon type="external" css={{ width: '10.5px', height: '10.5px' }} />
          </Link>
        )}
      </Box>
    </div>
  </Fragment>
)

Content.displayName = 'Content'
