import { css } from '@emotion/react'
import { Box, Layer, LayerProps, ThemeContext, ThemeValue } from 'grommet'
import { forwardRef, MouseEvent, useMemo } from 'react'

import { useTranslation } from '../../../i18n'
import { FeatureName, componentNames, generateTestId } from '../../../lib/testId'
import { layer } from '../../../styles/layer'
import { color } from '../../../styles/newColors'
import { Button, Props as ButtonProps } from '../Button'
import { Icon } from '../Icon'
import { TextButton, Props as TextButtonProps } from '../TextButton'

export type Props = LayerProps & {
  isOpened: boolean
  isMultiContent?: boolean
  isOkrContent?: boolean
  isRunning?: boolean
  withGeishaModal?: boolean
  title: string | React.ReactNode
  themeValue?: ThemeValue
  children?: React.ReactNode
  cancelText?: string
  hasCancelButtonUnderline?: boolean
  executeText?: string
  autoWidth?: boolean
  disabledCancel?: boolean
  disabledExecute?: boolean
  executeButtonProps?: ButtonProps
  cancelButtonProps?: TextButtonProps
  isResourceValue?: React.ReactNode
  dataTestIdFeatureName?: FeatureName
  onClickExecute?: (event: MouseEvent<HTMLButtonElement>) => void
  onClickCancel?: (event: MouseEvent<HTMLButtonElement> | MouseEvent<HTMLDivElement>) => void
  onClickOutside?: (event: MouseEvent<HTMLButtonElement>) => void
  onClickClose?: (event: MouseEvent<SVGSVGElement>) => void
}

const MODAL_GENERAL_PADDING_RIGHT_LEFT = css({
  paddingLeft: '32px',
  paddingRight: '32px',
})

export const Modal = forwardRef<HTMLDivElement, Props>(
  (
    {
      isOpened,
      isMultiContent,
      isOkrContent,
      isRunning = false,
      withGeishaModal,
      title,
      themeValue = {},
      cancelText,
      hasCancelButtonUnderline,
      executeText,
      autoWidth,
      disabledCancel,
      disabledExecute,
      executeButtonProps,
      cancelButtonProps,
      isResourceValue = false,
      dataTestIdFeatureName,
      onClickOutside,
      onClickExecute,
      onClickCancel,
      onClickClose,
      children,
      ...props
    },
    ref,
  ) => {
    const { t } = useTranslation()

    const setModalSize = useMemo(() => {
      if (isMultiContent && isOkrContent) return { width: '914px', height: '720px' }
      if (isMultiContent) return { width: '100%', maxWidth: '914px' }
      return undefined
    }, [isMultiContent, isOkrContent])

    const setPaddingTopContent = useMemo(() => {
      if (isMultiContent && isOkrContent) return '28px'
      if (isMultiContent) return '18px'
      return undefined
    }, [isMultiContent, isOkrContent])

    const footerWithBorder = isMultiContent || isResourceValue
    const footerExists = onClickCancel || (executeText && onClickExecute) || footerWithBorder

    const footerStyle = useMemo(() => {
      if (!footerExists) return { height: 0 }
      if (footerWithBorder)
        return { height: '64px', borderTop: `1px solid ${color('border-bk-30')}` }
      return { height: '88px' }
    }, [footerExists, footerWithBorder])

    if (!isOpened) return null

    return (
      <ThemeContext.Extend
        value={{
          layer: {
            ...(withGeishaModal ? { zIndex: '100' } : layer('modal')),
            ...themeValue,
          },
        }}
      >
        <Layer ref={ref} onClickOutside={onClickOutside} css={setModalSize} {...props}>
          <Box
            direction="column"
            justify="between"
            css={{
              width: autoWidth ? undefined : '544px',
              height: '100%',
              borderRadius: '0 4px',
            }}
          >
            {/* ヘッダー */}
            <Box
              direction="row"
              justify="between"
              align="center"
              css={[
                {
                  height: isMultiContent ? '88px' : '76px',
                  borderBottom: isMultiContent ? `1px solid ${color('border-bk-30')}` : undefined,
                },
                MODAL_GENERAL_PADDING_RIGHT_LEFT,
              ]}
              data-testid={
                dataTestIdFeatureName &&
                generateTestId(dataTestIdFeatureName, componentNames.header)
              }
            >
              <Title title={title} />
              {onClickClose && (
                <Icon
                  type="mdiClear"
                  css={{
                    minWidth: '24px',
                    minHeight: '24px',
                    cursor: 'pointer',
                  }}
                  color={color('text-bk-20')}
                  onClick={onClickClose}
                />
              )}
            </Box>
            {/* コンテンツ */}
            <div
              css={[
                {
                  height: '100%',
                  maxHeight: '568px',
                  overflowY: 'auto',
                  marginBottom: isMultiContent ? '1px' : undefined,
                },
              ]}
              data-testid={
                dataTestIdFeatureName &&
                generateTestId(dataTestIdFeatureName, componentNames.content)
              }
            >
              <div
                css={[
                  {
                    ...(isResourceValue ? undefined : MODAL_GENERAL_PADDING_RIGHT_LEFT),
                  },
                  {
                    paddingTop: setPaddingTopContent,
                  },
                ]}
              >
                {children}
              </div>
            </div>
            {/* フッター */}
            <Box
              direction="row"
              justify="end"
              align="center"
              css={[
                {
                  ...footerStyle,
                  paddingTop: '12px',
                  paddingBottom: '12px',
                },
                MODAL_GENERAL_PADDING_RIGHT_LEFT,
              ]}
              data-testid={
                dataTestIdFeatureName &&
                generateTestId(dataTestIdFeatureName, componentNames.footer)
              }
            >
              {onClickCancel && (
                <TextButton
                  type="button"
                  disabled={disabledCancel}
                  color={hasCancelButtonUnderline ? 'text-bk-80' : 'text-bk-50'}
                  onClick={onClickCancel}
                  css={{ marginRight: '8px' }}
                  hasUnderline={hasCancelButtonUnderline}
                  data-testid={
                    dataTestIdFeatureName &&
                    generateTestId(dataTestIdFeatureName, componentNames.cancelButton)
                  }
                  {...cancelButtonProps}
                >
                  {cancelText || t('CANCEL')}
                </TextButton>
              )}
              {isResourceValue}
              {executeText && onClickExecute && (
                <Button
                  disabled={disabledExecute}
                  newColor="resily-orange-100"
                  disabledColor="resily-orange-100"
                  weight="normal"
                  size="s"
                  isLoading={isRunning}
                  onClick={onClickExecute}
                  data-testid={
                    dataTestIdFeatureName &&
                    generateTestId(dataTestIdFeatureName, componentNames.executeButton)
                  }
                  {...executeButtonProps}
                >
                  {executeText}
                </Button>
              )}
            </Box>
          </Box>
        </Layer>
      </ThemeContext.Extend>
    )
  },
)

Modal.displayName = 'Modal'

const Title: React.VFC<Pick<Props, 'title'>> = ({ title }) => {
  if (typeof title !== 'string') {
    return <div>{title}</div>
  }

  return (
    <div
      color={color('text-bk-100')}
      css={{
        fontWeight: 'bold',
        fontSize: '20px',
        lineHeight: '27px',
        // stylelint-disable-next-line
        display: '-webkit-box',
        webkitBoxOrient: 'vertical',
        webkitLineClamp: '2',
        overflow: 'hidden',
      }}
    >
      {title}
    </div>
  )
}

Title.displayName = 'Title'
