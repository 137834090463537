import { t } from 'i18next'
import { FieldArrayWithId } from 'react-hook-form'

import { isMeasurementInvalid } from '../../../../../../../../../../lib/domain/keyResult/keyResultProgress'
import { useGetFormMethods } from '../../../../../../../form/contexts/FormProvider'
import { FormValues, keyResultsKeyName } from '../../../../../../../form/formSchema'
import { KeyResultUpdateType } from '../../../../../../../graphql/graphql'
import { initialSettingsCompleted, isPercentageInput } from '../../../../utils'
import { PromptMessageBase as PromptMessage } from '../Base'

type SetTargetValueProps = {
  keyResult: FieldArrayWithId<FormValues, 'keyResults', 'id'>
  index: number
}

export const NoMeasure: React.FC<SetTargetValueProps> = ({ keyResult, index }) => {
  const { watch } = useGetFormMethods()

  const [targetValue, initialValue] = watch([
    `${keyResultsKeyName}.${index}.targetValue`,
    `${keyResultsKeyName}.${index}.initialValue`,
  ])

  if (
    isPercentageInput(keyResult) &&
    !initialSettingsCompleted(keyResult) &&
    isMeasurementInvalid({
      id: '',
      updateType: KeyResultUpdateType.Automate,
      targetValue,
      initialValue: initialValue ?? 0,
    })
  ) {
    return <PromptMessage>{t('NO_MEASURE_CAUTION_MESSAGE')}</PromptMessage>
  }
  return null
}
