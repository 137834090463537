import { useModal } from '@resily/geisha'
import { useCallback } from 'react'

import { useTranslation } from '../../../../i18n'
import { StyledText } from '../../../ui/StyledText'

type Props = { onCancel: (() => void) | undefined; onConfirm: (() => void) | undefined }

type ReturnType = {
  DiscardAlertModal: React.VFC
  open: () => void
}

export const useDiscardAlertModal = ({ onCancel, onConfirm }: Props): ReturnType => {
  const { t } = useTranslation()

  const [Modal, isOpen, { open, close }] = useModal()
  const DiscardAlertModal = useCallback<React.VFC>(
    () => (
      <Modal
        size="medium"
        isOpen={isOpen}
        onClose={() => {
          onCancel?.()
          close()
        }}
      >
        <Modal.Header title={t('CONFIRMATION_OF_CANCEL_INPUT')} />
        <Modal.Content>
          <StyledText fontStyle="regular">
            {t('CONFIRMATION_OF_CANCEL_INPUT_DESCRIPTION')}
          </StyledText>
        </Modal.Content>
        <Modal.Footer
          cancelType="tertiary"
          cancelLabel={t('CANCEL')}
          confirmLabel={t('DISCARD')}
          onCancel={() => {
            onCancel?.()
            close()
          }}
          onConfirm={() => {
            onConfirm?.()
            close()
          }}
        />
      </Modal>
    ),
    [Modal, close, isOpen, onCancel, onConfirm, t],
  )

  return { DiscardAlertModal, open }
}
