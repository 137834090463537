import { yupResolver } from '@hookform/resolvers/yup'
import React, { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'

import { Button } from '../../../../components/ui/Button'
import { FormField } from '../../../../components/ui/FormField'
import { FormSubmitField } from '../../../../components/ui/FormSubmitField'
import { Input } from '../../../../components/ui/Input'
import { TextButton } from '../../../../components/ui/TextButton'
import { useTranslation } from '../../../../i18n'
import { formSchema } from '../../../../lib/domain/password'

import * as styles from './styles'

export type FormValues = {
  currentPassword: string
  newPassword: string
  passwordConfirmation: string
}

export type Props = {
  updatePassword: (values: FormValues) => Promise<void>
}

const generateDefaultValues: () => FormValues = () => ({
  currentPassword: '',
  newPassword: '',
  passwordConfirmation: '',
})

export const PasswordSetting: React.FC<Props> = ({ updatePassword }) => {
  const { t } = useTranslation()
  const [isEdit, setIsEdit] = useState<boolean>(false)

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors, isSubmitting, isValid, touchedFields },
  } = useForm<FormValues>({
    mode: 'onChange',
    resolver: yupResolver(formSchema(t)),
    defaultValues: {
      ...generateDefaultValues(),
    },
  })

  const handleChangePassword = useCallback(() => {
    setIsEdit(true)
  }, [])

  const handleEditCancel = useCallback(() => {
    reset(generateDefaultValues())
    setIsEdit(false)
  }, [reset])

  return (
    <div css={styles.rootCss}>
      <p css={styles.sectionCss}>{t('PASSWORD')}</p>
      {!isEdit ? (
        /** パスワードを変更のテキストボタン */
        <TextButton
          type="button"
          size="medium"
          color="resily-orange-100"
          padding="12px 6px 12px 0"
          onClick={handleChangePassword}
          hasUnderline
        >
          {t('CHANGE_PASSWORD')}
        </TextButton>
      ) : (
        /** パスワード変更フォーム */
        <form
          onSubmit={handleSubmit((values: FormValues) => {
            updatePassword(values).then(() => setIsEdit(false))
          })}
          css={styles.formCss}
        >
          <FormField
            labelColor="text-bk-50"
            labelFontSize="small"
            label={t('CURRENT_PASSWORD')}
            errorMessage={errors.currentPassword ? `${errors.currentPassword.message}` : ''}
            touched={!!touchedFields.currentPassword}
            css={styles.currentPasswordFieldCss}
          >
            <Input
              type="password"
              autoComplete="current-password"
              fontSize="medium"
              fieldStyle="bottomLine"
              css={styles.inputCss}
              {...register('currentPassword')}
            />
          </FormField>
          <div css={styles.newPasswordFieldWrapperCss}>
            <FormField
              labelColor="text-bk-50"
              labelFontSize="small"
              label={t('NEW_PASSWORD')}
              errorMessage={errors.newPassword ? `${errors.newPassword.message}` : ''}
              touched={!!touchedFields.newPassword}
              css={styles.newPasswordFieldCss}
            >
              <Input
                fontSize="medium"
                fieldStyle="bottomLine"
                type="password"
                autoComplete="new-password"
                css={styles.inputCss}
                {...register('newPassword')}
              />
            </FormField>
            <FormField
              labelColor="text-bk-50"
              labelFontSize="small"
              label={t('PASSWORD_CONFIRMATION')}
              errorMessage={
                errors.passwordConfirmation ? `${errors.passwordConfirmation.message}` : ''
              }
              touched={!!touchedFields.passwordConfirmation}
              css={styles.newPasswordFieldCss}
            >
              <Input
                fontSize="medium"
                fieldStyle="bottomLine"
                type="password"
                autoComplete="new-password"
                css={styles.inputCss}
                {...register('passwordConfirmation')}
              />
            </FormField>
          </div>
          <FormSubmitField css={styles.formSubmitFieldCss}>
            <TextButton
              type="button"
              size="small"
              color="text-bk-50"
              padding="12px 6px"
              onClick={handleEditCancel}
            >
              {t('CANCEL')}
            </TextButton>
            <Button
              type="submit"
              disabled={isSubmitting || !isValid}
              newColor="resily-orange-100"
              size="ss"
              weight="normal"
            >
              {t('SAVE')}
            </Button>
          </FormSubmitField>
        </form>
      )}
    </div>
  )
}

PasswordSetting.displayName = 'PasswordSetting'
