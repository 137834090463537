import React, { useState } from 'react'

import { Icon } from '../../ui/Icon'

export type Props = Omit<
  JSX.IntrinsicElements['button'],
  'defaultChecked' | 'onClick' | 'onMouseOver' | 'onMouseLeave'
> & {
  isRowHover?: boolean
  onClick?: () => void
} & (
    | {
        defaultChecked?: boolean
        checked?: never
      }
    | {
        defaultChecked?: never
        checked?: boolean
      }
  )

export const BorderlessCheckBox: React.FC<Props> = ({
  defaultChecked,
  checked,
  isRowHover,
  onClick,
  ...props
}) => {
  const [innerChecked, setInnerChecked] = useState<boolean>(!!defaultChecked)
  const [isHover, setIsHover] = useState<boolean>(false)

  return (
    // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
    <button
      type="button"
      {...props}
      onClick={(e) => {
        e.stopPropagation()
        setInnerChecked(!(checked || innerChecked))
        if (onClick) onClick()
      }}
      onMouseOver={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <Icon
        type="mdiCheck"
        css={{
          '&[data-checked="true"]': { opacity: 1 },
          '&[data-checked="false"]': {
            opacity: isRowHover || isHover ? 0.3 : 0,
            // stylelint-disable-next-line property-no-unknown
            path: isRowHover && !isHover ? { fill: 'gray' } : undefined,
          },
        }}
        data-checked={checked || innerChecked}
      />
    </button>
  )
}

BorderlessCheckBox.displayName = 'BorderlessCheckBox'
