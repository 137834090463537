import { useTranslation } from '../../../../../../../i18n'
import { componentNames, featureNames, generateTestId } from '../../../../../../../lib/testId'
import { ProgressNumberInput } from '../../../../../../ui/ProgressNumberInput'
import { StyledText } from '../../../../../../ui/StyledText'
import { ProgressNumberInputController } from '../../ProgressNumberInputController'
import { boxCss } from '../styles'

type TargetValueSectionProps = {
  index: number
  valueType: 'targetValue' | 'actualValue' | 'initialValue'
}

export const TargetValueSection: React.FC<TargetValueSectionProps> = ({ index, valueType }) => {
  const { t } = useTranslation()
  return (
    <section css={boxCss}>
      <StyledText size="xxsmall" color="border-bk-50">
        {t('TARGET_VALUE')}
      </StyledText>
      <ProgressNumberInputController index={index} valueType={valueType}>
        {(progressNumberInputProps) => (
          <ProgressNumberInput
            data-testid={generateTestId(featureNames.checkinModal, componentNames.targetValueInput)}
            {...progressNumberInputProps}
          />
        )}
      </ProgressNumberInputController>
    </section>
  )
}
