import { KeyResultEditFieldsInput } from '../types'

export const isFilledRequiredFields = (
  variable: Omit<KeyResultEditFieldsInput, 'keyResultId'>,
  objectiveId: string,
): boolean => {
  // タイトル, objectiveId, ownerIdのいずれかが未入力の場合は、登録不可
  if (!variable.name || !objectiveId || !variable.ownerId) return false

  return true
}
