import { css } from '@emotion/react'
import { VFC, useMemo, useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'

import { Icon } from '../../components/ui/Icon'
import { Input } from '../../components/ui/Input'
import { Tabs, TabType } from '../../components/ui/Tabs'
import { border } from '../../styles/border'
import { color } from '../../styles/newColors'

import { TAB } from './hooks/useSmartHRTab'

const styles = {
  listController: css({ display: 'flex', justifyContent: 'space-between' }), // フィルタ処理やタブ切り替え
  search: css({ width: 324, position: 'relative' }),
  searchInput: css({
    padding: '0 0 0 40px',
    '::placeholder': {
      fontSize: 14,
    },
  }),
  tabs: css({
    borderBottom: border('simple-10'),
  }),

  // NOTE: grommetのtext inputのiconはpointer-event: noneが指定されいて装飾しかできない
  searchClearIcon: css({
    cursor: 'pointer',
    position: 'absolute',
    top: '40%',
    right: 12,
    transform: 'translateY(-50%)',
  }),
} as const

type Props = {
  onChangeTab: (tab: TAB) => void
  onSearch: (query: string) => void
  onClickClearSeach: () => void
}

type FormValue = { query: string }

// TODO: Enterをした時にonSearchを呼び出す
export const ListController: VFC<Props> = ({ onChangeTab, onSearch, onClickClearSeach }) => {
  const [tab, setTab] = useState<TAB>('integratedUser')
  const tabs = useMemo<Array<TabType<TAB>>>(
    () => [
      {
        value: 'integratedUser',
        name: '連携ユーザー',
      },
      {
        value: 'segregatedUser',
        name: '非連携ユーザー',
      },
      {
        value: 'group',
        name: 'グループ',
      },
    ],
    [],
  )

  const {
    reset,
    register,
    handleSubmit,
    formState: { isDirty },
  } = useForm<FormValue>({
    mode: 'onChange',
    defaultValues: {
      query: '',
    },
  })

  const handleClickTab = useCallback(
    (t: TabType<TAB>) => {
      setTab(t.value)
      onChangeTab(t.value)
    },
    [onChangeTab],
  )

  const onSubmit = useCallback(
    ({ query }: FormValue) => {
      onSearch(query)
    },
    [onSearch],
  )

  const handleClickClear = useCallback(() => {
    reset()
    onClickClearSeach()
  }, [onClickClearSeach, reset])

  return (
    <form onSubmit={handleSubmit(onSubmit)} css={styles.listController}>
      <Tabs<TAB> size="small" tabs={tabs} tab={tab} onClickTab={handleClickTab} css={styles.tabs} />
      <div css={styles.search}>
        <Input
          type="text"
          fontSize="medium"
          {...register('query')}
          icon={<Icon color={color('text-bk-30')} type="search" />}
          placeholder="名前やグループ、メールアドレスから検索"
          width="324px"
          css={styles.searchInput}
        />
        {isDirty && (
          <button type="button" onClick={handleClickClear} css={styles.searchClearIcon}>
            <Icon type="clear" />
          </button>
        )}
      </div>
    </form>
  )
}

ListController.displayName = 'ListController'
