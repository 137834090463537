import { css } from '@emotion/react'
import { Anchor } from 'grommet'
import { VFC, useMemo, useCallback, useState, Fragment } from 'react'

import { useTranslation } from '../../../i18n'
import { Button } from '../../ui/Button'
import { ListCard, Table, TableRow } from '../../ui/ListCard'
import { Modal } from '../../ui/Modal'
import { StyledText } from '../../ui/StyledText'

const applicationCardBox = css({
  padding: '24px 0',
})
const modalDescCss = css({
  display: 'block',
  marginBottom: 24,
})

export type ApplicationProps = {
  appName: string
  applicationUrl: string
  thumbnail: JSX.Element
  enabled: boolean
  option?: JSX.Element
  onClickSetting?: () => void
  handleIntegrate: () => void
  handleSegregate: () => void
}

export type Props = {
  apps: Array<ApplicationProps>
}

export const ApplicationCard: VFC<Props> = ({ apps }) => {
  const { t } = useTranslation()
  const [modal, setModal] = useState<{
    name: string
    onClickExecute: () => void
  } | null>(null)

  const close = useCallback(() => setModal(null), [setModal])

  const handleClickExecute = useCallback(
    ({
      appName,
      enabled,
      integrate,
      segregate,
    }: {
      appName: string
      enabled: boolean
      integrate: () => void
      segregate: () => void
    }) =>
      enabled
        ? () =>
            setModal({
              name: appName,
              onClickExecute: () => {
                segregate()
                close()
              },
            })
        : integrate,
    [close],
  )

  const applications = useMemo<Array<TableRow>>(
    () =>
      apps.map(
        (
          {
            appName,
            onClickSetting,
            applicationUrl,
            option,
            thumbnail,
            enabled,
            handleIntegrate,
            handleSegregate,
          },
          i,
        ) => ({
          key: `${applicationUrl}--${i}`,
          cells: [
            {
              key: 'url',
              cell: <Anchor href={applicationUrl}>{thumbnail}</Anchor>,
            },
            {
              key: 'blank',
              cell: enabled && option ? option : '',
              minWidth: 310,
            },
            {
              key: 'integrate',
              css: { display: 'flex', justifyContent: 'flex-end', gap: 16 },
              cell: (
                <Fragment>
                  {enabled && onClickSetting ? (
                    <Button newColor="white-100" size="s" weight="normal" onClick={onClickSetting}>
                      {t('SETTING')}
                    </Button>
                  ) : undefined}
                  <Button
                    newColor={enabled ? 'white-100' : 'resily-orange-100'}
                    size="s"
                    weight="normal"
                    onClick={handleClickExecute({
                      appName,
                      enabled,
                      integrate: handleIntegrate,
                      segregate: handleSegregate,
                    })}
                  >
                    {t(enabled ? 'UNLOCK_SEGREGATE' : 'INTEGRATE')}
                  </Button>
                </Fragment>
              ),
              minWidth: 134,
            },
          ],
        }),
      ),
    [apps, handleClickExecute, t],
  )

  const table = useMemo<Table>(
    () => ({
      headers: [],
      data: {
        rowMinHeight: 72,
        rows: applications,
        css: {
          paddingLeft: '12px',
          paddingRight: '20px',
        },
      },
    }),
    [applications],
  )

  return (
    <Fragment>
      <Modal
        title={t('SEGREGATE')}
        executeText={t('UNLOCK')}
        onClickExecute={modal?.onClickExecute}
        isOpened={!!modal}
        onClickCancel={close}
        onClickOutside={close}
      >
        <StyledText color="text-bk-100" css={modalDescCss}>
          {t('CONFIRM_SEGREGATE')}
        </StyledText>
        <StyledText color="text-bk-100" weight="bold">
          {modal?.name}
        </StyledText>
      </Modal>
      <ListCard
        borderless
        header={
          <StyledText size="small" color="text-bk-50" weight={600}>
            {t('APPLICATION_NAME')}
          </StyledText>
        }
        table={table}
        css={applicationCardBox}
      />
    </Fragment>
  )
}

ApplicationCard.displayName = 'ApplicationCard'
