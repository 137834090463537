import { Box } from 'grommet'

import { Icon } from '../../../../components/ui/Icon'
import { color } from '../../../../styles/newColors'
import { Sort } from '../types'

type SortButtonProps = {
  sortBy: Sort['sortBy']
  onClick: (sortBy: Sort['sortBy']) => void
  sort: Sort
}

export const SortButton: React.FC<SortButtonProps> = ({ sortBy, sort, onClick, children }) => (
  <Box
    direction="row"
    align="center"
    onClick={() => {
      onClick(sortBy)
    }}
    css={{
      color: sort.sortBy === sortBy ? color('resily-orange-100') : color('text-bk-50'),
    }}
  >
    {children}
    <Icon
      type="mdiKeyboardArrowDown"
      color={sort.sortBy === sortBy ? color('resily-orange-100') : color('text-bk-50')}
      css={{
        width: 16,
        height: 16,
        transform: sort.sortBy === sortBy && sort.order === 'asc' ? 'scaleY(-1)' : undefined,
        transition: 'transform 0.5s',
      }}
    />
  </Box>
)

SortButton.displayName = 'SortButton'
