import { useTranslation } from '../../../../../../i18n'
import { StyledText } from '../../../../../ui/StyledText'

import { useStyles } from './KeyResultAreaTable.styles'

export const KeyResultAreaTableHeader: React.VFC = () => {
  const { t } = useTranslation()
  const styles = useStyles()

  return (
    <div css={styles.header}>
      <div css={styles.titleColumn}>
        <StyledText fontStyle="bold" size="small" color="text-bk-50">
          {t('KEY_RESULT')}
        </StyledText>
      </div>
      <div css={styles.progressColumn}>
        <StyledText fontStyle="bold" size="small" color="text-bk-50">
          {t('PROGRESS')} ({t('CONFIDENCE')})
        </StyledText>
      </div>
      <div css={styles.valuesColumn}>
        <StyledText fontStyle="bold" size="small" color="text-bk-50">
          {t('ACTUAL_AND_TARGET_VALUE')}
        </StyledText>
      </div>
      <div css={styles.lastUpdateColumn}>
        <StyledText fontStyle="bold" size="small" color="text-bk-50">
          {t('LAST_UPDATED_AT')}
        </StyledText>
      </div>
    </div>
  )
}
KeyResultAreaTableHeader.displayName = 'KeyResultAreaTableHeader'
