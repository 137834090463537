import { useMemo } from 'react'

import { UserFragment, GroupFragment } from '../../graphql'

type Args = {
  users: ReadonlyArray<UserFragment>
  groups: ReadonlyArray<GroupFragment>
  ownerId: string
  memberIds: ReadonlyArray<string>
}

type Return = {
  users: ReadonlyArray<UserFragment>
  groups: ReadonlyArray<GroupFragment>
}

export const useFilteredMemberOption = ({ users, groups, ownerId, memberIds }: Args): Return => {
  // ユーザーのメンバーには、オーナーと既にメンバーになっているユーザーを除外する
  const filteredUsers = useMemo(
    () =>
      users.filter((u) => u.id !== ownerId).map((u) => ({ ...u, isUserDisabled: u.isDisabled })),
    [ownerId, users],
  )

  // グループのメンバーには、オーナーと既にメンバーになっているユーザーを除外する
  const filteredGroups = useMemo(
    () =>
      groups
        .map((g) => ({
          ...g,
          admins: g.admins.filter(
            ({ id, isDisabled }) =>
              isDisabled === false && id !== ownerId && memberIds.includes(id) === false,
          ),
          members: g.members.filter(
            ({ id, isDisabled }) =>
              isDisabled === false && id !== ownerId && memberIds.includes(id) === false,
          ),
        }))
        // メンバーがいないグループは除外する
        .filter((g) => g.admins.length > 0 || g.members.length > 0),
    [groups, memberIds, ownerId],
  )

  return { users: filteredUsers, groups: filteredGroups }
}
