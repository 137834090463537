import { css } from '@emotion/react'
import { VFC, useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { Breadcrumb, BreadcrumbParams } from '../../components/domain/Breadcrumb'
import { Avatar } from '../../components/ui/Avatar'
import { Button } from '../../components/ui/Button'
import { DateTime } from '../../components/ui/DateTime'
import { StyledText } from '../../components/ui/StyledText'
import { TextButton } from '../../components/ui/TextButton'
import { color } from '../../styles/newColors'
import { meInCurrentTerm, integrationsSmartHREmployees } from '../../urls'

const styles = {
  header: css({ display: 'flex', justifyContent: 'space-between' }),
  headerRight: css({ display: 'flex' }),
  historyButton: css({
    marginRight: 24,
    display: 'inline-block',
    padding: 0,
    height: 40,
    span: {
      verticalAlign: 'sub',
    },
    svg: {
      width: 20,
      height: 20,
      path: {
        fill: color('text-bk-30'),
        stroke: color('text-bk-30'),
      },
    },
    ':hover': {
      svg: {
        path: {
          fill: color('resily-orange-100'),
          stroke: color('resily-orange-100'),
        },
      },
      span: {
        color: color('resily-orange-100'),
      },
    },
  }),
  lastIntegrated: css({
    display: 'flex',
    color: color('text-bk-30'),
    justifyContent: 'flex-end',
    marginTop: 4,
  }),
  integratedText: css({ margin: '0 4px', display: 'inline-block' }),
  avatar: css({ marginTop: 3 }),
} as const

type Props = {
  loadingIntegration: boolean
  lastIntegrated: {
    date: Date
    // TODO: graphqlを定義してuserFragmentからとってくる
    user: {
      id: string
      firstName: string
      lastName: string
      avatar?: {
        url: string
      }
    }
  }
  onClickIntegration: () => void
}

export const Header: VFC<Props> = ({ loadingIntegration, lastIntegrated, onClickIntegration }) => {
  const navigate = useNavigate()

  const handleClickHisotry = useCallback(() => {
    // FIXME: 現状、urls.smarthrHistoriesへの遷移先がないので暫定的にマイページに遷移
    navigate(meInCurrentTerm)
  }, [navigate])

  const breadcrumbs = useMemo<BreadcrumbParams>(
    () => ({
      url: integrationsSmartHREmployees,
      items: [
        {
          breadcrumbName: 'organizationSettings',
        },
        {
          breadcrumbName: 'integrationSettings',
        },
        {
          breadcrumbName: 'smarthr',
        },
      ],
    }),
    [],
  )

  return (
    <div css={styles.header}>
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <div>
        <div css={styles.headerRight}>
          <TextButton
            color="text-bk-30"
            type="button"
            onClick={handleClickHisotry}
            icon="history"
            iconSize={20}
            disabled={loadingIntegration}
            css={styles.historyButton}
          >
            更新履歴
          </TextButton>
          <Button
            isLoading={loadingIntegration}
            newColor="white-100"
            weight="normal"
            size="s"
            onClick={onClickIntegration}
          >
            SmartHRと同期
          </Button>
        </div>
        <div css={styles.lastIntegrated}>
          <StyledText size="small">
            <DateTime datetime={lastIntegrated.date} />
            <span css={styles.integratedText}>同期</span>
          </StyledText>
          <Avatar
            size="xxsmall"
            firstName={lastIntegrated.user.firstName}
            lastName={lastIntegrated.user.lastName}
            avatarUrl={lastIntegrated.user.avatar?.url}
            linkage={{ userId: lastIntegrated.user.id }}
            isUserDisabled={false}
            css={styles.avatar}
          />
        </div>
      </div>
    </div>
  )
}

Header.displayName = 'Header'
