import { PropsWithoutRef } from 'react'

import * as colors from '../../../styles/colors'

export type Props = PropsWithoutRef<JSX.IntrinsicElements['div']>

export const ErrorMessage: React.FC<Props> = (props) => (
  <div
    css={{
      lineHeight: '1.6',
      color: colors.negative,
    }}
    {...props}
  />
)

ErrorMessage.displayName = 'ErrorMessage'
