import { PropsWithoutRef, forwardRef } from 'react'

import { color, ColorAlias } from '../../../styles/newColors'
import { Icon, Props as IconProps } from '../Icon'

export type Props = PropsWithoutRef<JSX.IntrinsicElements['input']> & {
  children?: React.ReactNode
  iconSize?: 's' | 'm'
  iconColor?: ColorAlias
  iconProps?: Omit<IconProps, 'type' | 'color'>
  isError?: boolean
}

export const Checkbox = forwardRef<HTMLInputElement, Props>(
  (
    {
      className,
      checked,
      disabled,
      children,
      iconSize = 'm',
      iconColor = 'resily-orange-100',
      iconProps,
      isError,
      ...props
    }: Props,
    ref: React.Ref<HTMLInputElement>,
  ) => {
    const sizeStyle = () => {
      switch (iconSize) {
        case 'm':
          return '16px'
        case 's':
          return '12px'
        default:
          return '16px'
      }
    }
    const iconColorStyle = () => {
      if (disabled) {
        return color('text-bk-30')
      }
      if (!checked) {
        return color('background-bk-30')
      }
      if (isError) {
        return color('negative-100')
      }
      return color(iconColor)
    }
    return (
      <label
        className={className}
        css={{
          cursor: 'pointer',
          position: 'relative',
          display: 'inline-flex',
          alignItems: 'center',
        }}
      >
        <input
          ref={ref}
          css={{
            position: 'absolute',
            zIndex: -1,
            opacity: '0',
          }}
          type="checkbox"
          checked={checked}
          disabled={disabled}
          {...props}
        />
        <Icon
          css={{
            display: 'block',
            width: sizeStyle(),
            height: sizeStyle(),
            rect: {
              fill: disabled ? color('background-bk-5') : 'inherit',
            },
          }}
          color={iconColorStyle()}
          type={checked ? 'mdiCheckBox' : 'mdiCheckBoxOutlineBlank'}
          {...iconProps}
        />
        {typeof children !== 'undefined' ? (
          <span
            css={{
              marginLeft: '8px',
              paddingBottom: iconSize === 's' ? '2px' : '0',
            }}
          >
            {children}
          </span>
        ) : null}
      </label>
    )
  },
)

Checkbox.displayName = 'Checkbox'
