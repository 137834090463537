import { PropsWithoutRef } from 'react'

export type Props = PropsWithoutRef<JSX.IntrinsicElements['div']>

export const FormSubmitField: React.FC<Props> = (props) => (
  <div
    css={{
      marginTop: 24,
      marginBottom: 24,
    }}
    {...props}
  />
)

FormSubmitField.displayName = 'FormSubmitField'
