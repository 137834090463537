import { useCallback } from 'react'

import { User } from '../../../contexts/UserContext'
import { useUpdateSecuritySettingMutation } from '../api/graphql'
import { Props as TwoFactorAuthSettingProps } from '../components/TwoFactorAuthSetting'

export const useTwoFactorAuthSetting = ({ user }: { user: User }): TwoFactorAuthSettingProps => {
  const [updateSecuritySetting, { loading }] = useUpdateSecuritySettingMutation()

  const handleUpdateSecuritySetting = useCallback<
    TwoFactorAuthSettingProps['updateSecuritySetting']
  >(
    async (otpRequiredForLogin: boolean) => {
      await updateSecuritySetting({
        variables: { otpRequiredForLogin },
      })
    },
    [updateSecuritySetting],
  )

  return {
    loading,
    otpRequiredForLogin: user.otpRequiredForLogin,
    updateSecuritySetting: handleUpdateSecuritySetting,
  }
}
