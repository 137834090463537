import {
  QueryParamConfigMap,
  QueryParamConfig,
  StringParam,
  createEnumParam,
} from 'use-query-params'

import { DRAWER_QUERY_TYPE, DRAWER_QUERY_TAB, DRAWER_QUERY_ID } from '../../../urls'

import { DrawerType, DrawerTab, DRAWER_TAB_LIST } from './types'

// OkrDrawerを使っていたページでuseQueryParamsする場合に呼び出す
export const withDrawerParams = <QPCMap extends QueryParamConfigMap>(
  paramConfigMap: QPCMap,
): QPCMap & {
  [DRAWER_QUERY_TYPE]: QueryParamConfig<DrawerType | null | undefined>
  [DRAWER_QUERY_TAB]: QueryParamConfig<DrawerTab | null | undefined>
  [DRAWER_QUERY_ID]: typeof StringParam
} => ({
  ...paramConfigMap,
  [DRAWER_QUERY_TYPE]: createEnumParam<DrawerType>(['objective', 'keyResult']),
  [DRAWER_QUERY_TAB]: createEnumParam<DrawerTab>(DRAWER_TAB_LIST as unknown as Array<DrawerTab>),
  [DRAWER_QUERY_ID]: StringParam,
})
