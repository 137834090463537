import { css } from '@emotion/react'

import { color } from '../../../../../styles/newColors'

export const tabBoxCss = css({
  minHeight: '178px',
  background: color('white-100'),
  borderRadius: '8px',
  padding: '20px 32px',
})

export const saveButtonCss = css({
  backgroundColor: color('resily-orange-100'),
  fontWeight: 'normal',
  '&:disabled': {
    backgroundColor: color('disabled-100'),
    opacity: 1,
  },
})
