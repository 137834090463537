import { css } from '@emotion/react'
import { useMemo } from 'react'

import { border } from '../../../styles/border'
import { color } from '../../../styles/newColors'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useStyles = () =>
  useMemo(
    () =>
      ({
        modalWrapper: css({
          display: 'flex',
          flexDirection: 'column',
          gap: 16,
          border: border('simple-30'),
          borderRadius: '8px',
          padding: '16px',
        }),
        progressRate: {
          root: css({
            display: 'flex',
            margin: '12px 12px 0',
            gap: 64,
          }),
          currentArea: {
            root: css({
              width: '118px',
            }),
            progressRateBar: {
              root: css({
                marginTop: 16,
              }),
              updatedAt: {
                root: css({
                  marginTop: 6,
                }),
                text: css({
                  whiteSpace: 'nowrap',
                }),
              },
            },
          },
          inputArea: {
            targetArea: {
              root: css({
                minWidth: 258,
              }),
            },
            progressRateArea: {
              root: css({
                width: 298,
              }),
              form: css({
                marginTop: 12,
              }),
            },
          },
        },
        confidence: {
          currentArea: {
            root: css({
              display: 'flex',
              alignItems: 'center',
            }),
          },
          selectArea: {
            root: css({
              display: 'flex',
              margin: '12px 12px 0',
              gap: 56,
            }),
            titleArea: {
              root: css({
                display: 'flex',
                flexDirection: 'column',
                width: 124,
              }),
              ownerConfidence: css({
                marginTop: 16,
              }),
            },
            yourSelect: {
              root: css({
                marginTop: 16,
              }),
              selectWrapper: css({
                paddingTop: 3,
              }),
            },
          },
        },
        updateMemo: {
          root: css({
            paddingTop: 8,
            borderTop: border('simple-10'),
          }),
          title: css({
            marginBottom: 8,
          }),
          input: css({
            padding: '0 12px',
          }),
        },
      } as const),
    [],
  )

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useModalHeaderStyles = () =>
  useMemo(
    () =>
      ({
        root: css({
          display: 'flex',
          alignItems: 'stretch',
        }),
        avatar: css({
          width: '28px',
        }),
        border: css({
          backgroundColor: color('kr-green-100'),
          width: '4px',
          borderRadius: '2px',
          marginLeft: '8px',
          marginRight: '8px',
        }),
        name: css({
          maxWidth: '490px',
          fontSize: '14px',
          lineHeight: '22px',
          fontWeight: 'bold',
          // stylelint-disable-next-line
          display: '-webkit-box',
          // stylelint-disable-next-line
          '-webkit-box-orient': 'vertical',
          // stylelint-disable-next-line
          '-webkit-line-clamp': '2',
          overflow: 'hidden',
        }),
      } as const),
    [],
  )
