import { Box, TextInput, ThemeContext } from 'grommet'
import { deepMerge } from 'grommet/utils'
import { useState, useEffect } from 'react'

import { File as GraphqlFile } from '../../../graphql'
import { useTranslation } from '../../../i18n'
import { theme } from '../../../styles/grommetTheme'
import { color } from '../../../styles/newColors'
import { AttachFile } from '../AttachFile'
import { Button } from '../Button'
import { MediaFile } from '../MediaFile'
import { RichTextEditor } from '../RichTextEditor'
import { TextButton } from '../TextButton'

export type Props = {
  editorId: string
  title?: string
  titlePlaceholder?: string
  body?: string
  plainText?: string
  attachments?: ReadonlyArray<Pick<GraphqlFile, 'name' | 'path' | 'url'>>
  onClickCancel: () => void
  onClickDeleteAttachments?: (
    files: ReadonlyArray<Pick<GraphqlFile, 'name' | 'path' | 'url'>>,
  ) => void
  onUpdateFile?: (file: GraphqlFile) => void
  onClickSave: (
    title: string,
    treeJson: string,
    text: string,
    beforeTreeJson: string,
    beforePlainText: string,
  ) => void
  onEdit: (isEditing: boolean) => void
}

export const DetailEditCard: React.FC<Props> = ({
  editorId,
  title: defaultTitle = '',
  body: defaultBody = '',
  plainText: defaultPlainText = '',
  titlePlaceholder = '',
  attachments,
  onClickCancel,
  onClickDeleteAttachments,
  onUpdateFile,
  onClickSave,
  onEdit,
  ...props
}) => {
  const { t } = useTranslation()
  const [title, setTitle] = useState(defaultTitle)
  const [body, setBody] = useState(defaultBody)
  const [plainText, setPlainText] = useState(defaultPlainText)

  useEffect(() => {
    if (body === defaultBody && title === defaultTitle) {
      onEdit(false)
      return
    }

    onEdit(true)
  }, [defaultTitle, defaultBody, body, title, onEdit])

  return (
    <div
      css={{
        backgroundColor: color('white-100'),
        borderRadius: '4px',
        border: '1px solid #D3D4D9',
        padding: '24px',
      }}
      {...props}
    >
      <ThemeContext.Extend
        value={deepMerge(theme, {
          textInput: {
            extend: () => `
        font-size: 20px;
        font-weight: normal;
        line-height: 27px;
        background: ${color('hover-background-bk-5')};
        margin: 0 auto;
        border: 1px solid #D3D4D9;
      `,
          },
        })}
      >
        <TextInput
          name="name"
          value={titlePlaceholder === title ? '' : title}
          placeholder={title === '' || titlePlaceholder === title ? titlePlaceholder : ''}
          onChange={(event) => setTitle(event.target.value)}
          css={{ marginBottom: '12px' }}
        />
      </ThemeContext.Extend>

      <RichTextEditor
        id={editorId}
        css={{
          marginTop: 8,
          marginLeft: 4,
          height: '443px',
          overflowX: 'hidden',
          overflowY: 'scroll',
        }}
        initialValueJSON={body}
        onChange={(json, text) => {
          setBody(json)
          setPlainText(text)
        }}
        onSave={(json, text) => {
          onClickSave(
            title || titlePlaceholder,
            json || '',
            text || '',
            defaultBody,
            defaultPlainText,
          )
        }}
      />
      <div css={{ display: 'flex', justifyContent: 'space-between', marginTop: 20, gap: 8 }}>
        <div
          css={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: 8,
          }}
        >
          {attachments && (
            <>
              <AttachFile
                onChange={(file) => {
                  if (onUpdateFile) onUpdateFile(file)
                }}
              />
              {attachments.map((mediaFile) => (
                <div key={mediaFile.url} css={{ display: 'flex' }}>
                  <MediaFile
                    filename={mediaFile.name}
                    url={mediaFile.url}
                    readOnly={false}
                    onClickDelete={() => {
                      if (onClickDeleteAttachments) {
                        onClickDeleteAttachments(attachments.filter((a) => a !== mediaFile))
                      }
                    }}
                    key={mediaFile.name}
                  />
                </div>
              ))}
            </>
          )}
        </div>
        <Box direction="row" justify="end" css={{ minWidth: 170 }}>
          <TextButton color="text-bk-50" onClick={onClickCancel} css={{ marginRight: '8px' }}>
            {t('CANCEL')}
          </TextButton>
          <Button
            newColor="resily-orange-100"
            weight="normal"
            size="s"
            onClick={() => {
              onClickSave(title || titlePlaceholder, body, plainText, defaultBody, defaultPlainText)
            }}
          >
            {t('SAVE')}
          </Button>
        </Box>
      </div>
    </div>
  )
}

DetailEditCard.displayName = 'DetailEditCard'
