import { TKeys } from '../../../../i18n/type'

// チェックインコメント
export type CheckinCommentNames = typeof CHECKIN_COMMENT_NAMES[number]

export const CHECKIN_COMMENT_NAMES = ['priority', 'winSession', 'problem', 'other'] as const

export const CHECKIN_COMMENT_NAMES_I18N_KEY: {
  [key in CheckinCommentNames]: TKeys
} = {
  priority: 'PRIORITY',
  winSession: 'WIN_SESSION',
  problem: 'PROBLEM',
  other: 'OTHER',
}
