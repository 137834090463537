import { Box } from 'grommet'
import { Fragment, useRef, useState } from 'react'

import { useTranslation } from '../../../i18n'
import { useClickOutside } from '../../../lib/clickOutside'
import { color, color as newColor } from '../../../styles/newColors'
import {
  generateLabeledNoteList,
  generateLabeledPersonalNoteList,
  noteLabels,
  personalNoteLabels,
} from '../../../urls'
import { AvatarWithName } from '../../ui/AvatarWithName'
import { Card } from '../../ui/Card'
import { DateTime } from '../../ui/DateTime'
import { Drawer } from '../../ui/Drawer'
import { Icon } from '../../ui/Icon'
import { Link } from '../../ui/Link'
import { MultiSelect, Option } from '../../ui/MultiSelect'
import { NoteLabel } from '../../ui/NoteLabel'
import { Radio } from '../../ui/Radio'
import { StyledText } from '../../ui/StyledText'
import { NoteUserDialogContainer } from '../NoteUserDialog'
import {
  PersonalNoteDetailFragment,
  PersonalNoteDetailViewNoteDetailFragment,
  useFindUserPersonalNotePermissionQuery,
} from '../PersonalNoteOption/graphql'
import { PersonalNoteUserDialogContainer } from '../PersonalNoteUserDialog'

import {
  NoteDetailFragment,
  NoteDetailViewNoteDetailFragment,
  NotePermission,
  useFindUserNotePermissionQuery,
} from './graphql'

const isPersonalNote = (
  note: NoteDetailFragment | PersonalNoteDetailFragment,
): note is PersonalNoteDetailFragment => note.__typename === 'PersonalNote'

type CommonNotePermissionUser =
  | NonNullable<
      ReturnType<typeof useFindUserNotePermissionQuery>['data']
    >['findUserNotePermission'][number]
  | NonNullable<
      ReturnType<typeof useFindUserPersonalNotePermissionQuery>['data']
    >['findUserPersonalNotePermission'][number]

type CommonNoteDetailViewNoteDetailFragment<
  T extends NoteDetailViewNoteDetailFragment | PersonalNoteDetailViewNoteDetailFragment,
  U extends
    | NoteDetailViewNoteDetailFragment
    | PersonalNoteDetailViewNoteDetailFragment = T['__typename'] extends 'PersonalNote'
    ? PersonalNoteDetailViewNoteDetailFragment
    : NoteDetailViewNoteDetailFragment,
> = Pick<U, 'id' | 'title' | 'body' | 'attachments' | 'permission'>

export type Props<T extends NoteDetailFragment | PersonalNoteDetailFragment> = {
  note: T
  userId: string | undefined
  ownerUserId?: string
  permissionUsers: ReadonlyArray<CommonNotePermissionUser>
  labelOptions: ReadonlyArray<Option>
  onClickLabelOption: (id: string) => void
  updateNoteMutation: (value: CommonNoteDetailViewNoteDetailFragment<T>) => Promise<void>
}

export const NoteOption = <T extends NoteDetailFragment | PersonalNoteDetailFragment>({
  note,
  userId,
  ownerUserId,
  permissionUsers,
  labelOptions,
  onClickLabelOption,
  updateNoteMutation,
}: Props<T>): JSX.Element => {
  const { t } = useTranslation()
  const multiSelectRef = useRef<HTMLDivElement>(null)

  const [shouldExpireCache, setShouldExpireCache] = useState(true)
  const [noteUserVisibility, setNoteUserVisibility] = useState(false)
  const [openLabelMultiSelect, setOpenLabelMultiSelect] = useState(false)

  useClickOutside({ callback: () => setOpenLabelMultiSelect(false), ref: multiSelectRef })

  const handleUpdateNote = async (permission: NotePermission) => {
    await updateNoteMutation({
      ...note,
      permission,
    })
    window.setTimeout(() => {
      setNoteUserVisibility(permission === NotePermission.Private)
    }, 700)
  }

  const editable = ownerUserId ? userId === ownerUserId : true

  return (
    <div css={{ marginLeft: '32px', maxWidth: '200px' }}>
      <Drawer open={noteUserVisibility} onClose={() => setNoteUserVisibility(false)}>
        {isPersonalNote(note) ? (
          <PersonalNoteUserDialogContainer
            personalNoteId={note.id}
            shouldExpireCache={shouldExpireCache}
            onUpdated={() => setShouldExpireCache(true)}
            close={() => setNoteUserVisibility(false)}
          />
        ) : (
          <NoteUserDialogContainer
            noteId={note.id}
            shouldExpireCache={shouldExpireCache}
            onUpdated={() => setShouldExpireCache(true)}
            close={() => setNoteUserVisibility(false)}
          />
        )}
      </Drawer>

      <dl
        css={{
          dt: {
            marginBottom: '12px',
            fontSize: '12px',
            fontWeight: 'bold',
            color: newColor('text-bk-50'),
          },
          dd: {
            marginBottom: '24px',
            fontSize: '14px',
            color: newColor('text-bk-100'),
          },
        }}
      >
        <dt>{t('AUTHOR')}</dt>
        <dd>
          <AvatarWithName
            size="small"
            firstName={note.author.firstName}
            lastName={note.author.lastName}
            avatarUrl={note.author.avatar?.url}
            isUserDisabled={note.author.isDisabled}
            linkage={{
              termId: isPersonalNote(note) ? undefined : note.okrTerm.id,
              userId: note.author.id,
            }}
          />
        </dd>
        <dt>{t('CREATED_ON')}</dt>
        <dd>
          <DateTime datetime={note.createdAt} withoutTime />
        </dd>
        <dt>{t('UPDATED_ON')}</dt>
        <dd>
          <DateTime datetime={note.updatedAt} withoutTime />
        </dd>
        {userId === note.author.id && (
          <Fragment>
            <dt>{t('OPEN_RANGE')}</dt>
            <dd style={{ display: 'flex', flexDirection: 'column', gap: '14px' }}>
              <PermissionRadio
                note={note}
                targetPermission={NotePermission.Public}
                handleUpdateNote={handleUpdateNote}
                label={t('PUBLIC')}
                disabled={!editable}
              />
              <div style={{ display: 'flex' }}>
                <PermissionRadio
                  note={note}
                  targetPermission={NotePermission.Private}
                  handleUpdateNote={handleUpdateNote}
                  label={t('PRIVATE')}
                  disabled={!editable}
                />
                <Box
                  direction="row"
                  align="center"
                  css={{
                    backgroundColor: newColor('border-bk-10'),
                    marginLeft: '9px',
                    padding: '2px 3px',
                  }}
                  onClick={
                    note.permission !== NotePermission.Private
                      ? undefined
                      : () => setNoteUserVisibility(true)
                  }
                >
                  <Icon
                    type="personAdd"
                    css={{ width: '16px', height: '16px', marginRight: '4px' }}
                  />
                  <StyledText size="small" color="text-bk-50">
                    {permissionUsers.filter((u) => u?.noteVisible).length}
                  </StyledText>
                </Box>
              </div>
              <PermissionRadio
                note={note}
                targetPermission={NotePermission.OwnerOnly}
                handleUpdateNote={handleUpdateNote}
                label={t('OWNER_ONLY')}
                disabled={!editable}
              />
            </dd>
          </Fragment>
        )}
        <dt>
          <Box css={{ position: 'relative', marginTop: '37px' }}>
            <Box>{t('LABEL')}</Box>

            <div css={{ position: 'relative' }}>
              <Box
                direction="row"
                align="center"
                css={{
                  color: newColor('text-bk-30'),
                  fontWeight: 'normal',
                  cursor: 'pointer',
                  marginTop: '12px',
                  ':hover': {
                    color: newColor('resily-orange-100'),
                  },
                }}
                onClick={() => setOpenLabelMultiSelect(!openLabelMultiSelect)}
              >
                <Icon type="plus" css={{ width: '12px', height: '12px', marginRight: '8px' }} />
                <StyledText>{t('ADD_X', { x: t('LABEL') })}</StyledText>
              </Box>
              {openLabelMultiSelect ? (
                <div ref={multiSelectRef} css={{ position: 'absolute', zIndex: 10 }}>
                  {labelOptions.length === 0 ? (
                    <Card css={{ width: '300px', textAlign: 'center' }}>
                      <StyledText
                        size="small"
                        color="text-bk-100"
                        weight="normal"
                        css={{ marginBottom: '28px' }}
                      >
                        {t('X_IS_EMPTY', { x: t('LABEL') })}
                        <br />
                        {t('CREATE_A_NEW_LABEL')}
                      </StyledText>
                      <a
                        href={isPersonalNote(note) ? personalNoteLabels : noteLabels}
                        target="_blank"
                        rel="noopener noreferrer"
                        css={{
                          textDecoration: 'none',
                          color: color('text-bk-50'),
                          ':hover': { color: color('resily-orange-100') },
                        }}
                      >
                        <div
                          css={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <Icon
                            type="plus"
                            css={{ height: '12px', width: '12px', marginRight: '8px' }}
                          />
                          <StyledText>{t('CREATE_LABEL')}</StyledText>
                        </div>
                      </a>
                    </Card>
                  ) : (
                    <MultiSelect
                      canSearch
                      onBlurFromMutliSelect={() => setOpenLabelMultiSelect(false)}
                      searchPlaceholder={t('INPUT_X', { x: t('LABEL_NAME') })}
                      options={labelOptions}
                      onClickOption={onClickLabelOption}
                    >
                      <a
                        href={isPersonalNote(note) ? personalNoteLabels : noteLabels}
                        target="_blank"
                        rel="noopener noreferrer"
                        css={{
                          textDecoration: 'none',
                          height: '44px',
                          paddingLeft: '32px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Icon
                          type="editOutline"
                          css={{ height: '16px', width: '16px', marginRight: '8px' }}
                          color={color('text-bk-30')}
                        />
                        <StyledText color="text-bk-50">{t('EDIT_LABEL')}</StyledText>
                      </a>
                    </MultiSelect>
                  )}
                </div>
              ) : null}
            </div>
          </Box>
        </dt>
        <dd>
          <ul css={{ listStyle: 'none' }}>
            {note.labels.map((label) => (
              <li key={`label-${label.id}`} css={{ margin: '2px', maxWidth: '200px' }}>
                <Link
                  href={
                    isPersonalNote(note)
                      ? generateLabeledPersonalNoteList(label.id)
                      : generateLabeledNoteList(note.okrTerm.id, label.id)
                  }
                >
                  <NoteLabel backgroundColor={label.color}>{label.name}</NoteLabel>
                </Link>
              </li>
            ))}
          </ul>
        </dd>
      </dl>
    </div>
  )
}

type PermissionRadioProps<T extends NoteDetailFragment | PersonalNoteDetailFragment> = {
  note: T
  targetPermission: NotePermission
  handleUpdateNote: (permission: NotePermission) => void
  label: string
  disabled: boolean
}

export const PermissionRadio = <T extends NoteDetailFragment | PersonalNoteDetailFragment>({
  note,
  targetPermission,
  handleUpdateNote,
  label,
  disabled,
}: PermissionRadioProps<T>): JSX.Element => (
  <Radio
    round
    name={targetPermission.toLowerCase()}
    checked={note.permission === targetPermission}
    onClick={() => note.permission !== targetPermission && handleUpdateNote(targetPermission)}
    disabled={disabled}
  >
    <span
      css={{
        marginLeft: 8,
        display: 'flex',
        alignItems: 'center',
        fontSize: 12,
      }}
    >
      {label}
    </span>
  </Radio>
)
