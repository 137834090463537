import { useProfiler } from '@sentry/react'
import { VFC } from 'react'

import { ActivityView } from './ActivityView'

export type Props = {
  termId: string
  okrNodeIds: ReadonlyArray<string>
  selectedGroupIds: ReadonlyArray<string>
  selectedUserIds: ReadonlyArray<string>
}

export const ActivityContainer: VFC<Props> = ({
  termId,
  okrNodeIds,
  selectedGroupIds,
  selectedUserIds,
}) => {
  useProfiler('ActivityContainer')
  return (
    <div css={{ marginTop: '34px' }}>
      <ActivityView
        key={selectedGroupIds.join('-') + selectedUserIds.join('-') + okrNodeIds.join('-')} // 条件に変更がある場合にコンポーネントを初期化する
        termId={termId}
        okrNodeIds={okrNodeIds}
        selectedGroupIds={selectedGroupIds}
        selectedUserIds={selectedUserIds}
      />
    </div>
  )
}
ActivityContainer.displayName = 'ActivityContainer'
