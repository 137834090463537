import { Button as GrommetButton } from 'grommet'
import { Pin } from 'grommet-icons'
import { FC, VFC } from 'react'

import { RichTextEditor } from '../../components/ui/RichTextEditor'

import { FixedAgendasFragment, UserAgendasFragment } from './graphql'
import { editorCss, labelCss } from './styles'
import { FixedAgendasFragments, UserAgendasFragments } from './types'

export type MeetingHistoryNotesProps = {
  oneOnOneMeetingId: string
  userAgendas: UserAgendasFragments
  fixedAgendas: FixedAgendasFragments
}

const isFixedAgendaType = (
  arg: UserAgendasFragment | FixedAgendasFragment,
): arg is FixedAgendasFragment => arg.__typename === 'FixedAgenda'

export const MeetingHistoryNotes: VFC<MeetingHistoryNotesProps> = ({
  oneOnOneMeetingId,
  fixedAgendas,
  userAgendas,
}) => (
  <>
    {userAgendas.map((e) => (
      <MeetingNoteItem key={e.id} oneOnOneMeetingId={oneOnOneMeetingId} agenda={e} />
    ))}
    {fixedAgendas.map((e) => (
      <MeetingNoteItem key={e.id} oneOnOneMeetingId={oneOnOneMeetingId} agenda={e} />
    ))}
  </>
)

type MeetingNoteItemProps = {
  oneOnOneMeetingId: string
  agenda: UserAgendasFragment | FixedAgendasFragment
}
const MeetingNoteItem: VFC<MeetingNoteItemProps> = ({ oneOnOneMeetingId, agenda }) => {
  const isFixedAgenda = isFixedAgendaType(agenda)

  // 固定アジェンダの場合、固定アジェンダから手を加えられていなければ
  // 固定アジェンダのテンプレート文字列をそのまま埋め込む
  const initialValueJSON =
    isFixedAgenda && (agenda.meetingNote === '' || agenda.body === agenda.meetingNote)
      ? agenda.body
      : agenda.meetingNote

  return (
    <>
      <AgendaLabel>
        {isFixedAgenda && (
          <GrommetButton
            icon={
              <Pin
                css={{
                  width: '14px',
                  height: '14px',
                }}
              />
            }
            css={{
              padding: '8px 8px 8px 0',
              cursor: 'default',
            }}
          />
        )}
        {agenda.title}
      </AgendaLabel>
      <div data-testid="richTextEditor">
        <RichTextEditor
          // 固定アジェンダの場合は固定アジェンダのリソースIDだけでは一意とならないためMTG IDも付与してIDとする
          id={isFixedAgenda ? `${oneOnOneMeetingId}-${agenda.id}` : agenda.id}
          autoFocus={false}
          initialValueJSON={initialValueJSON}
          css={editorCss}
          editorProps={{
            readOnly: true,
          }}
        />
      </div>
    </>
  )
}

// アジェンダのラベル
const AgendaLabel: FC = ({ children }) => (
  <div
    css={[
      labelCss,
      {
        display: 'flex',
      },
    ]}
  >
    {children}
  </div>
)
