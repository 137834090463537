import { css } from '@emotion/react'
import { useMemo } from 'react'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useStyles = () =>
  useMemo(
    () =>
      ({
        root: css({
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: 60,
        }),
      } as const),
    [],
  )
