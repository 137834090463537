import { yupResolver } from '@hookform/resolvers/yup'
import { useForm as useFormOrigin, UseFormReturn } from 'react-hook-form'

import { useTranslation } from '../../../../../i18n'
import { keyResultToUserConfidenceLevel } from '../../../../../lib/domain/confidence'
import { DEFAULT_INITIAL_VALUE_JSON_STRING } from '../../../../ui/RichTextEditor'
import {
  Document,
  DocumentInput,
  KeyResultUpdateType,
  useCheckinableKeyResultsQuery,
  UserWithCheckinableKeyResultsFragment,
} from '../../graphql/graphql'
import { formSchema, FormValues, keyResultsKeyName } from '../formSchema'

export type UseCheckinModalFormReturn = {
  loading: boolean
  formMethods: UseFormReturn<FormValues>
}

export const useForm = (okrNodeId?: string): UseCheckinModalFormReturn => {
  const { t } = useTranslation()
  const formMethods = useFormOrigin<FormValues>({
    mode: 'onChange',
    defaultValues: { [keyResultsKeyName]: [] },
    resolver: yupResolver(formSchema(t)),
  })

  const { loading } = useCheckinableKeyResultsQuery({
    onCompleted: (data) => {
      const { reset } = formMethods
      const { checkinableKeyResults } = data.user
      const filteredCheckinableKeyResults = checkinableKeyResults.filter(
        (kr) => !okrNodeId || kr.checkinSummaryKeyResult.keyResult.node.id === okrNodeId,
      )

      reset({
        [keyResultsKeyName]: transformRemoteData(filteredCheckinableKeyResults, data.user.id),
      })

      // 今週コメント済みであれば、各コメントをフォームに反映する
      filteredCheckinableKeyResults.forEach(
        ({ lastComments, checkinSummaryKeyResult: { id: checkinSummaryKeyResultId } }, index) => {
          // 今週コメント済みでなければ、何もしない
          if (
            !isCurrentCheckinSummaryKeyResultComment(
              checkinSummaryKeyResultId,
              lastComments?.checkinSummaryKeyResult.id,
            )
          ) {
            return
          }
          // 優先事項
          if (lastComments?.priority?.body) {
            formMethods.setValue(
              `${keyResultsKeyName}.${index}.priority`,
              transformCheckinComment(lastComments.priority.body),
              { shouldDirty: true },
            )
            formMethods.setValue(`${keyResultsKeyName}.${index}.isCurrentPriorityComment`, true)
          }
          // 困りごと
          if (lastComments?.problem?.body) {
            formMethods.setValue(
              `${keyResultsKeyName}.${index}.problem`,
              transformCheckinComment(lastComments.problem.body),
              { shouldDirty: true },
            )
            formMethods.setValue(`${keyResultsKeyName}.${index}.isCurrentProblemComment`, true)
          }
          // ウィン
          if (lastComments?.winSession?.body) {
            formMethods.setValue(
              `${keyResultsKeyName}.${index}.winSession`,
              transformCheckinComment(lastComments.winSession.body),
              { shouldDirty: true },
            )
            formMethods.setValue(`${keyResultsKeyName}.${index}.isCurrentWinSessionComment`, true)
          }
          // その他
          if (lastComments?.other?.body) {
            formMethods.setValue(
              `${keyResultsKeyName}.${index}.other`,
              transformCheckinComment(lastComments.other.body),
              { shouldDirty: true },
            )
            formMethods.setValue(`${keyResultsKeyName}.${index}.isCurrentOtherComment`, true)
          }
        },
      )
    },
  })

  return { loading, formMethods }
}

const transformRemoteData = (
  data: UserWithCheckinableKeyResultsFragment['checkinableKeyResults'],
  userId: string,
): FormValues[typeof keyResultsKeyName] => {
  const user = { id: userId }

  return data.map<FormValues[typeof keyResultsKeyName][number]>(
    ({ lastComments, checkinSummaryKeyResult: { keyResult } }) => ({
      keyResultId: keyResult.id,
      objectiveName: keyResult.objective.name,
      keyResultName: keyResult.name,
      node: keyResult.node,
      level: keyResultToUserConfidenceLevel(keyResult, user) ?? 0,
      beforeLevel: keyResultToUserConfidenceLevel(keyResult, user),
      actualValue:
        keyResult.updateType === KeyResultUpdateType.Manual || keyResult.isAutoAggregate
          ? keyResult.progressRate
          : keyResult.actualValue,
      initialValue:
        keyResult.updateType === KeyResultUpdateType.Manual || keyResult.isAutoAggregate
          ? 0
          : keyResult.initialValue,
      targetValue:
        keyResult.updateType === KeyResultUpdateType.Manual || keyResult.isAutoAggregate
          ? 100
          : keyResult.targetValue,
      unit:
        keyResult.updateType === KeyResultUpdateType.Manual || keyResult.isAutoAggregate
          ? '%'
          : keyResult.unit,
      beforeActualValue:
        keyResult.updateType === KeyResultUpdateType.Manual || keyResult.isAutoAggregate
          ? keyResult.progressRate
          : keyResult.actualValue,
      beforeInitialValue:
        keyResult.updateType === KeyResultUpdateType.Manual || keyResult.isAutoAggregate
          ? 0
          : keyResult.initialValue,
      beforeTargetValue:
        keyResult.updateType === KeyResultUpdateType.Manual || keyResult.isAutoAggregate
          ? 100
          : keyResult.targetValue,
      beforeUnit:
        keyResult.updateType === KeyResultUpdateType.Manual || keyResult.isAutoAggregate
          ? '%'
          : keyResult.unit,
      updateType: keyResult.isAutoAggregate ? KeyResultUpdateType.Manual : keyResult.updateType,
      updateReason: '',
      priority: DEFAULT_CHECKIN_COMMENT,
      lastPriority: lastComments?.priority?.body
        ? transformCheckinComment(lastComments.priority.body)
        : DEFAULT_CHECKIN_COMMENT,
      problem: DEFAULT_CHECKIN_COMMENT,
      lastProblem: lastComments?.problem?.body
        ? transformCheckinComment(lastComments.problem.body)
        : DEFAULT_CHECKIN_COMMENT,
      winSession: DEFAULT_CHECKIN_COMMENT,
      lastWinSession: lastComments?.winSession?.body
        ? transformCheckinComment(lastComments.winSession.body)
        : DEFAULT_CHECKIN_COMMENT,
      other: DEFAULT_CHECKIN_COMMENT,
      lastOther: lastComments?.other?.body
        ? transformCheckinComment(lastComments.other.body)
        : DEFAULT_CHECKIN_COMMENT,
      isCurrentPriorityComment: false,
      isCurrentProblemComment: false,
      isCurrentWinSessionComment: false,
      isCurrentOtherComment: false,
      targetSetting: keyResult.targetSetting,
    }),
  )
}

const DEFAULT_CHECKIN_COMMENT: DocumentInput = {
  treeJson: DEFAULT_INITIAL_VALUE_JSON_STRING,
  plainText: '',
}

// 現在のCheckinSummaryKeyResultに対してのコメントかどうか
const isCurrentCheckinSummaryKeyResultComment = (
  currentCheckinSummaryKeyResultId: string,
  lastCommentCheckinSummaryKeyResultID?: string,
): boolean => lastCommentCheckinSummaryKeyResultID === currentCheckinSummaryKeyResultId

const transformCheckinComment = ({ treeJson, plainText }: Document): DocumentInput => ({
  treeJson,
  plainText: plainText ?? '',
})
