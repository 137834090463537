import dayjs from 'dayjs'
import { Box, Drop } from 'grommet'
import { Resizable, ResizeDirection } from 're-resizable'
import { useCallback, useLayoutEffect, useRef, useState } from 'react'
import { useDeepCompareEffect } from 'react-use'

import { Popover } from '../../components/ui/Popover/deprecated'
import { StyledText } from '../../components/ui/StyledText'
import { TextButton } from '../../components/ui/TextButton'
import { useTranslation } from '../../i18n'
import { color } from '../../styles/newColors'

import { FixedAgendaTemplate } from './FixedAgendaTemplate'
import { MemoList } from './Memolist'
import { RecommendAgenda, Props as RecommendAgendaProps } from './RecommendAgenda'
import { FixedAgendasFragment, UserAgendasFragment } from './graphql'
import { useAgendaListHeight } from './hooks/useAgendaListHeight'

export type AgendaContent = {
  id: string
  text: string
  memo: string
  firstName: string
  lastName: string
  isUserDisabled: boolean
  avatarUrl: undefined
}

export type DeleteItem = {
  id: string
  title: string
}

export type Props = {
  userAgendasQuery: ReadonlyArray<UserAgendasFragment>
  fixedAgendaQuery: ReadonlyArray<FixedAgendasFragment>
  onCreateUserAgenda: (title: string) => Promise<void>
  onUpdateUserAgenda: (id: string, title: string) => Promise<void>
  onDeleteUserAgenda: (id: string) => Promise<void>
  onUpdateUserAgendaIndex: (id: string, input: number) => void
} & Pick<
  RecommendAgendaProps,
  'currentUser' | 'partnerUser' | 'currentMeetingAt' | 'previousMeetingAt' | 'termId'
>

const AGENDA_LIST_MIN_HEIGHT = 68

const ENABLE_RESIZING = {
  top: false,
  right: false,
  bottom: true,
  left: false,
  topRight: false,
  bottomRight: false,
  bottomLeft: false,
  topLeft: false,
} as const

export const OneOnOneAgendaMemo: React.FC<Props> = ({
  currentMeetingAt,
  previousMeetingAt,
  currentUser,
  partnerUser,
  termId,
  userAgendasQuery,
  fixedAgendaQuery,
  onDeleteUserAgenda,
  onCreateUserAgenda,
  onUpdateUserAgenda,
  onUpdateUserAgendaIndex,
}) => {
  const { t } = useTranslation()
  const isPastMeeting = dayjs(currentMeetingAt.endTime).isBefore(dayjs().startOf('minute'))
  const tooltipText = isPastMeeting
    ? t('RECOMMEND_AGENDA_HELP_WHEN_DISABLED')
    : t('RECOMMEND_AGENDA_HELP_WHEN_ENABLED')
  const threePointsRef = useRef<HTMLButtonElement>(null)
  const [isShowRecommendAgenda, setIsShowRecommendAgenda] = useState(false)

  const changeStatusAgendaModal = () => {
    setIsShowRecommendAgenda(false)
  }

  const [agendaListHeight, setAgendaListHeight] = useAgendaListHeight(AGENDA_LIST_MIN_HEIGHT)
  const handleAgendaListResizeStop = useCallback(
    (e: MouseEvent | TouchEvent, _: ResizeDirection, resizeAgendaListRef: HTMLElement) => {
      e.preventDefault()
      e.stopPropagation()

      if (e instanceof MouseEvent) {
        setAgendaListHeight(resizeAgendaListRef.offsetHeight)
      }
    },
    [setAgendaListHeight],
  )

  const userAgendaListRef = useRef<HTMLDivElement>(null)
  const fixedAgendaListRef = useRef<HTMLDivElement>(null)
  const [agendaMinHeight, setAgendaListMinHeight] = useState<number>(AGENDA_LIST_MIN_HEIGHT)

  useLayoutEffect(() => {
    const fixedAgendaListHeight = fixedAgendaListRef.current?.scrollHeight ?? 0
    setAgendaListMinHeight(fixedAgendaListHeight)
    if (AGENDA_LIST_MIN_HEIGHT >= agendaListHeight - fixedAgendaListHeight)
      setAgendaListHeight(agendaListHeight + fixedAgendaListHeight)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fixedAgendaQuery, fixedAgendaListRef.current?.scrollHeight, setAgendaListHeight])

  useDeepCompareEffect(() => {
    // ユーザーアジェンダに変更があったら、
    if (userAgendasQuery.length > 0) {
      // DOMが描画された後に、追加されたユーザーアジェンダ分アジェンダエリアの高さを増やす
      setTimeout(() => {
        if (userAgendaListRef.current) {
          const height =
            userAgendaListRef.current.scrollHeight + (fixedAgendaListRef.current?.scrollHeight ?? 0)
          if (agendaListHeight <= height) {
            setAgendaListHeight(height)
          }
        }
      }, 0)
    }
  }, [userAgendasQuery])

  return (
    <div
      css={{
        margin: '32px',
        minWidth: '120px',
        borderRadius: '12px',
        boxShadow: 'rgb(0 0 0 / 20%) 0px 2px 4px',
      }}
    >
      <Box
        direction="row"
        align="center"
        justify="between"
        pad={{ horizontal: '24px', vertical: '20px' }}
        css={{
          borderRadius: '12px 12px 0 0',
          boxShadow: '0px 0px 8px rgb(34 41 67 / 10%)',
        }}
      >
        <StyledText
          css={{
            fontSize: '20px',
            fontWeight: 'bold',
            lineHeight: '20px',
          }}
        >
          {t('AGENDA')}
        </StyledText>
        <Box
          direction="row"
          align="center"
          css={{
            lineHeight: '6px',
          }}
        >
          <TextButton
            color="resily-orange-100"
            padding="0"
            ref={threePointsRef}
            onClick={() => {
              setIsShowRecommendAgenda(!isShowRecommendAgenda)
            }}
            css={{
              lineHeight: '14px',
            }}
            disabled={isPastMeeting}
          >
            {t('RECOMMEND_AGENDA')}
          </TextButton>
          <Drop
            target={threePointsRef.current || undefined}
            align={{ top: 'top', left: 'left' }}
            onClickOutside={() => changeStatusAgendaModal()}
            style={{ display: isShowRecommendAgenda ? 'block' : 'none' }}
            css={{
              minWidth: '530px',
              boxShadow: '0px 1px 16px rgb(0 0 0 / 10%)',
              borderRadius: '8px',
            }}
          >
            <RecommendAgenda
              currentMeetingAt={currentMeetingAt}
              previousMeetingAt={previousMeetingAt}
              currentUser={currentUser}
              partnerUser={partnerUser}
              termId={termId}
              handleRegisterRecommendAgenda={onCreateUserAgenda}
              changeStatusAgendaModal={changeStatusAgendaModal}
            />
          </Drop>
          <Popover
            title={t('RECOMMEND_AGENDA')}
            position={{ left: '-280px' }}
            icon="help"
            iconCss={{ color: color('text-bk-100') }}
            text={tooltipText}
            margin={{ left: '8px', right: '10px' }}
            css={{
              paddingTop: '5px',
              zIndex: 5,
              whiteSpace: 'pre-wrap',
            }}
          />
        </Box>
      </Box>

      <Resizable
        size={{ height: agendaListHeight, width: '100%' }}
        minHeight={agendaMinHeight}
        enable={ENABLE_RESIZING}
        style={{ display: 'flex', flexDirection: 'column' }}
        handleComponent={{
          bottom: <ResizeHandler />,
        }}
        handleStyles={{ bottom: { left: '50%', bottom: '8px', width: '24px' } }}
        onResizeStop={handleAgendaListResizeStop}
      >
        <Box
          css={{
            overflowY: 'scroll',
            width: '100%',
            flexGrow: 1,
          }}
        >
          <div css={{ padding: '18px 12px' }} ref={userAgendaListRef}>
            <MemoList
              readonly={false}
              userAgendasQuery={userAgendasQuery}
              onCreateUserAgenda={onCreateUserAgenda}
              onUpdateUserAgenda={onUpdateUserAgenda}
              onDeleteUserAgenda={onDeleteUserAgenda}
              onUpdateUserAgendaIndex={onUpdateUserAgendaIndex}
            />
          </div>
        </Box>
        {fixedAgendaQuery.length > 0 && (
          <Box
            direction="row"
            justify="between"
            align="center"
            style={{
              minHeight: agendaMinHeight,
              maxHeight: agendaMinHeight,
            }}
            css={{
              backgroundColor: color('border-bk-10'),
              padding: '0 24px 0 16px',
              borderRadius: '0 0 12px 12px',
            }}
          >
            <Box
              ref={fixedAgendaListRef}
              width="100%"
              css={{
                padding: '10px 0',
                fontWeight: 600,
              }}
            >
              {fixedAgendaQuery.map((fixedAgenda) => (
                <FixedAgendaTemplate key={fixedAgenda.id} id={fixedAgenda.id}>
                  {fixedAgenda.title}
                </FixedAgendaTemplate>
              ))}
            </Box>
          </Box>
        )}
      </Resizable>
    </div>
  )
}

OneOnOneAgendaMemo.displayName = 'OneOnOneAgendaMemo'

const ResizeHandler: React.FC = () => (
  <div
    css={{
      position: 'absolute',
      width: '24px',
      height: '6px',
      bottom: '0',
      left: '0',
      right: '0',
      margin: 'auto',
      borderTop: `1px solid ${color('border-bk-30')}`,
      borderBottom: `1px solid ${color('border-bk-30')}`,
      '&:hover': {
        borderTop: `1px solid ${color('border-bk-50')}`,
        borderBottom: `1px double ${color('border-bk-50')}`,
      },
    }}
  />
)

ResizeHandler.displayName = 'ResizeHandler'
