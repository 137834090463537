import { css, SerializedStyles } from '@emotion/react'
import { FieldError } from 'react-hook-form'

export const calenderCss = css({
  borderRadius: '4px',
  boxShadow: 'none',
  button: {
    ':focus': {
      boxShadow: 'none',
    },
  },
  div: {
    boxShadow: 'none',
  },
})

export const makeDateInputCss = (errors: FieldError | undefined): SerializedStyles =>
  css({
    alignItems: 'center',
    border: errors ? '1px solid #D42922' : '1px solid #DADADA',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'space-between',
    height: '40px',
    width: '132px',
    padding: '0 12px 0 8px',
  })
