import { css } from '@emotion/react'
import dayjs from 'dayjs'
import React, { useState, useCallback, MouseEventHandler, useRef, useEffect } from 'react'

import { Icon } from '../../../components/ui/Icon'
import { StyledText } from '../../../components/ui/StyledText'
import { useClickOutside } from '../../../lib/clickOutside'
import { handleOnScroll } from '../../../lib/onScroll'
import { color } from '../../../styles/newColors'
import { UserOneOnOneMeetingEdgeFragment, UserOneOnOneMeetingFragment } from '../graphql'

const titleSelectButtonCss = css({
  boxShadow: '0px 1px 8px rgb(0 0 0 / 10%)',
  height: '100%',
  width: '100%',
  position: 'relative',
  zIndex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0 26px 0 24px',
  backgroundColor: color('white-100'),
})

const iconWrapperCss = css({
  'svg path': {
    fill: color('border-bk-50'),
  },
})

const optionsCss = css({
  maxHeight: '280px',
  overflowY: 'auto',
})

const titleSelectScrollButtonCss = css({
  height: '24px',
  lineHeight: '24px',
  backgroundColor: color('white-100'),
  textAlign: 'center',
  cursor: 'default',
})

const meetingDateCss = css({
  textAlign: 'left',
  fontWeight: 'bold',
  size: '16px',
  marginRight: '8px',
  fontFamily: 'Roboto, "Noto Sans JP", sans-serif',
})

const meetingTimeCss = css({
  textAlign: 'left',
  fontWeight: 'normal',
  size: '16px',
  marginRight: '8px',
  fontFamily: 'Roboto, "Noto Sans JP", sans-serif',
})

const titleWrapperCss = css({
  paddingRight: '8px',
  display: 'flex',
})

const optionsWrapperCss = css({
  borderRadius: '8px',
  marginTop: '-24px',
  position: 'relative',
  zIndex: 2,
  boxShadow: '0px 2px 2px rgba(0 0 0 / 10%)',
})

const closedIconWrapperCss = css({
  flexBasis: '16px',
})

const DATE_FORMAT = 'YYYY/MM/DD'
const TIME_FORMAT = 'HH:mm'

export type Props = {
  currentMeeting?: UserOneOnOneMeetingFragment
  meetings: ReadonlyArray<UserOneOnOneMeetingEdgeFragment>
  onClickSelectItem: (meetingId: string) => void
  height?: string
  width?: number | `${number}px` | `${number}%`
  onReachEnd?: () => void
}

let intervalIds: Array<NodeJS.Timeout> = []

export const MeetingTitleSelect: React.VFC<Props> = ({
  currentMeeting,
  meetings,
  onClickSelectItem,
  height = '56px',
  width = '100%',
  onReachEnd,
}) => {
  const [openTitleList, setOpenTitleList] = useState(false)
  const handleClickTitleSelectableButton = useCallback<MouseEventHandler<HTMLButtonElement>>(() => {
    setOpenTitleList((prev) => !prev)
  }, [])

  const titleSelectRef = useRef<HTMLDivElement>(null)
  useClickOutside({
    callback: () => {
      if (openTitleList) {
        setOpenTitleList(false)
      }
    },
    ref: titleSelectRef,
  })

  const optionsRef = useRef<HTMLDivElement>(null)
  const scrollBottom = useCallback<MouseEventHandler<HTMLDivElement>>(() => {
    const intervalId = setInterval(() => {
      optionsRef.current?.scrollBy({ top: 50, behavior: 'smooth' })
    }, 100)
    intervalIds.push(intervalId)
  }, [])

  const scrollTop = useCallback<MouseEventHandler<HTMLDivElement>>(() => {
    const intervalId = setInterval(() => {
      optionsRef.current?.scrollBy({ top: -50, behavior: 'smooth' })
    }, 100)
    intervalIds.push(intervalId)
  }, [])

  const stopScroll = useCallback<MouseEventHandler<HTMLDivElement>>(() => {
    intervalIds.forEach((intervalId) => {
      clearInterval(intervalId)
    })
    intervalIds = []
  }, [])

  const scrollBottomButtonRef = useRef<HTMLDivElement>(null)
  const scrollTopButtonRef = useRef<HTMLDivElement>(null)
  const handleToggleVisibilityScrollButton = useCallback(() => {
    if (!optionsRef.current || !scrollBottomButtonRef.current || !scrollTopButtonRef.current) {
      return
    }
    const {
      clientHeight: optionsHeight,
      scrollTop: optionsScrollTop,
      scrollHeight: optionsScrollHeight,
    } = optionsRef.current

    // スクロール領域がない場合は、スクロールボタンを表示しない
    if (optionsHeight === optionsScrollHeight) {
      scrollBottomButtonRef.current.dataset.visible = 'false'
      scrollTopButtonRef.current.dataset.visible = 'false'
      return
    }

    // スクロール位置が最上部の場合、トップのスクロールボタンは表示しない
    if (optionsScrollTop === 0) {
      scrollBottomButtonRef.current.dataset.visible = 'true'
      scrollTopButtonRef.current.dataset.visible = 'false'
      return
    }

    // スクロール位置が最下部の場合、ボトムのスクロールボタンは表示しない
    if (optionsScrollHeight - optionsScrollTop === optionsHeight) {
      scrollBottomButtonRef.current.dataset.visible = 'false'
      scrollTopButtonRef.current.dataset.visible = 'true'
      return
    }

    scrollBottomButtonRef.current.dataset.visible = 'true'
    scrollTopButtonRef.current.dataset.visible = 'true'
  }, [])

  useEffect(() => {
    if (openTitleList) {
      handleToggleVisibilityScrollButton()
    }
  }, [openTitleList, handleToggleVisibilityScrollButton])

  return (
    <div style={{ height, width }} ref={titleSelectRef}>
      <button
        type="button"
        css={[titleSelectButtonCss, { borderRadius: 8 }]}
        style={{ height, width }}
        onClick={handleClickTitleSelectableButton}
      >
        <div css={titleWrapperCss}>
          <StyledText css={meetingDateCss} oneline isWrap numberOfOmittedLines={1}>
            {currentMeeting ? dayjs(currentMeeting.dateOfMeeting).format(DATE_FORMAT) : ''}
          </StyledText>
          <StyledText>
            {currentMeeting
              ? `${dayjs(currentMeeting.startTime).format(TIME_FORMAT)} ~ ${dayjs(
                  currentMeeting.endTime,
                ).format(TIME_FORMAT)}`
              : ''}
          </StyledText>
        </div>
        <div css={[closedIconWrapperCss, iconWrapperCss]}>
          <Icon type={openTitleList ? 'opened' : 'closed'} />
        </div>
      </button>
      {openTitleList && (
        <div css={optionsWrapperCss}>
          <div
            ref={scrollTopButtonRef}
            data-visible={false}
            onMouseEnter={scrollTop}
            onMouseLeave={stopScroll}
            css={[
              titleSelectScrollButtonCss,
              iconWrapperCss,
              {
                borderBottom: `1px solid rgba(0, 0, 0, 0.1)`,
                borderRadius: '8px 8px 0 0',
                '&[data-visible="true"]': { visibility: 'visible' },
                '&[data-visible="false"]': { visibility: 'hidden' },
              },
            ]}
          >
            <Icon type="opened" />
          </div>
          <div
            ref={optionsRef}
            css={[optionsCss, { width }]}
            onScroll={(e) => {
              handleToggleVisibilityScrollButton()
              if (onReachEnd) {
                handleOnScroll(e, onReachEnd)
              }
            }}
          >
            {meetings.map((meeting) => (
              <SelectItem
                key={meeting.node.id}
                selected={currentMeeting?.id === meeting.node.id}
                meetingId={meeting.node.id}
                date={dayjs(meeting.node.dateOfMeeting).format(DATE_FORMAT)}
                startTime={dayjs(meeting.node.startTime).format(TIME_FORMAT)}
                endTime={dayjs(meeting.node.endTime).format(TIME_FORMAT)}
                width={width}
                height={height}
                onClickSelectItem={(meetingId: string) => {
                  onClickSelectItem(meetingId)
                  setOpenTitleList(false)
                }}
              />
            ))}
          </div>
          <div
            ref={scrollBottomButtonRef}
            data-visible={false}
            onMouseEnter={scrollBottom}
            onMouseLeave={stopScroll}
            css={[
              titleSelectScrollButtonCss,
              iconWrapperCss,
              {
                borderTop: `1px solid rgba(0, 0, 0, 0.1)`,
                borderRadius: '0 0 8px 8px',
                '&[data-visible="true"]': { visibility: 'visible' },
                '&[data-visible="false"]': { visibility: 'hidden', height: '0' },
              },
            ]}
          >
            <Icon type="closed" />
          </div>
        </div>
      )}
    </div>
  )
}

MeetingTitleSelect.displayName = 'MeetingTitleSelect'

type SelectItemProps = {
  selected: boolean
  meetingId: string
  date: string
  startTime: string
  endTime: string
  height?: string
  width?: number | `${number}px` | `${number}%`
  onClickSelectItem: (meetingId: string) => void
}

const SelectItem: React.VFC<SelectItemProps> = ({
  selected,
  meetingId,
  date,
  startTime,
  endTime,
  height,
  onClickSelectItem,
}) => (
  <button
    style={{ height }}
    type="button"
    css={[
      titleSelectButtonCss,
      selected && { backgroundColor: color('border-bk-10'), pointerEvents: 'none' },
      { ':hover': { backgroundColor: color('hover-background-bk-5') } },
    ]}
    onClick={() => {
      onClickSelectItem(meetingId)
    }}
  >
    <div css={titleWrapperCss}>
      <StyledText css={meetingDateCss} oneline isWrap numberOfOmittedLines={1}>
        {date}
      </StyledText>
      <StyledText css={meetingTimeCss} oneline isWrap numberOfOmittedLines={1}>
        {startTime && endTime ? `${startTime} ~ ${endTime}` : ''}
      </StyledText>
    </div>
  </button>
)

SelectItem.displayName = 'SelectItem'
