import { useEffect } from 'react'
import { useQueryParams, StringParam } from 'use-query-params'

import { ContentLayout } from '../../components/pageContent/ContentLayout'
import { Logo } from '../../components/ui/Logo'
import { StyledText } from '../../components/ui/StyledText'
import { useTranslation } from '../../i18n'
import { color } from '../../styles/newColors'
import { ExternalUrls } from '../../urls'

export const AccountSetupInvalidLink: React.FC = () => {
  const [{ lang }] = useQueryParams({
    lang: StringParam,
  })

  const { t, i18n } = useTranslation()
  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang)
    }
  }, [i18n, lang])

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
      }}
    >
      <div
        css={{
          height: '100%',
          width: '100%',
          overflow: 'auto',
          backgroundColor: color(`background-bk-5`),
        }}
      >
        <ContentLayout height="100%" align="center" justify="center">
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '520px',
              borderRadius: '16px',
              boxShadow: '0 8px 16px rgb(34 41 67 / 8%)',
              padding: '64px 40px 62px',
              backgroundColor: color('white-100'),
            }}
          >
            <Logo width="154px" height="44px" style={{ marginBottom: '176px' }} />
            <div css={{ marginBottom: '228px' }}>
              <StyledText
                as="h1"
                size="xxlarge"
                css={{
                  marginBottom: '36px',
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}
              >
                {t('INVALID_LINK_TITLE')}
              </StyledText>
              <StyledText
                as="p"
                size="medium"
                lineHeight="1.86"
                css={{
                  marginBottom: '36px',
                  textAlign: 'center',
                  whiteSpace: 'pre-wrap',
                }}
              >
                {t('INVALID_LINK_DESCRIPTION')}
              </StyledText>
            </div>
            <div css={{ lineHeight: '20px' }}>
              <a
                css={{
                  color: color('text-bk-50'),
                  textDecoration: 'none',
                  fontSize: '12px',
                }}
                href={ExternalUrls.HELP_PAGE}
                rel="noopener noreferrer"
                target="_blank"
              >
                {t('HELP')}
              </a>
              <a
                css={{
                  color: color('text-bk-50'),
                  textDecoration: 'none',
                  fontSize: '12px',
                  marginLeft: '16px',
                }}
                href={ExternalUrls.INQUIRY_PAGE}
                rel="noopener noreferrer"
                target="_blank"
              >
                {t('CONTACT_US')}
              </a>
            </div>
          </div>
        </ContentLayout>
      </div>
    </div>
  )
}

AccountSetupInvalidLink.displayName = 'AccountSetupInvalidLink'
