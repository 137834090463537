import { keyframes, css, CSSObject } from '@emotion/react'
import { Drop } from 'grommet'
import { VFC, Fragment, useRef, useCallback, useState, useMemo } from 'react'

import { color } from '../../../styles/newColors'
import { Icon } from '../Icon'
import { StyledText } from '../StyledText'

import { OPTION_CLASSNAME, OPTION_FOCUS_CLASSNAME, useKeyboardCursor } from './hooks'

const selectPopoverBox = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  width: '300px',
  backgroundColor: color('white-100'),
  padding: '12px 0',
  border: `1px solid ${color('border-bk-30')}`,
  borderRadius: '4px',
})
const selectButtonCss = css({
  width: '100%',
  textAlign: 'left',
  fontSize: '14px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  color: color('text-bk-100'),
  padding: '10px 22px',
  ':hover': {
    backgroundColor: color('hover-background-bk-5'),
  },
  '&[data-is-active="true"]': {
    backgroundColor: color('border-bk-10'),
  },
  [`&.${OPTION_FOCUS_CLASSNAME}`]: {
    backgroundColor: color('hover-background-bk-5'),
  },
})
const valueButtonCss = css({
  padding: '6px 8px',
  height: '32px',
  width: '200px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: color('white-100'),
  border: `1px solid ${color('border-bk-10')}`,
  borderRadius: '4px',
  '&:disabled': {
    backgroundColor: color('background-bk-5'),
  },
})
const valueButtonTextCss = css({
  display: 'block',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  fontSize: '14px',
})
const placeholderTextCss = css({
  width: '100%',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  fontSize: '14px',
  lineHeight: '16px',
  textAlign: 'left',
})
const iconBox = css({
  width: '16px',
  height: '16px',
})
const fadein = keyframes({
  from: { opacity: 0, transform: 'scale(0.9, 0.9)' },
  to: { opacity: 1, transform: 'scale(1, 1)' },
})
const dropCss = css({
  transformOrigin: 'center',
  boxShadow: '0px 8px 16px rgb(34 41 67 / 8%)',
  animation: `${fadein} 150ms cubic-bezier(0.820, 0.085, 0.395, 0.895)`,
  opacity: 1,
  borderRadius: '4px',
  display: 'none',
  '&[data-is-open="true"]': {
    display: 'block',
  },
})

const noop = () => {}

type ValueType = string
export type Option = {
  value: ValueType
  text: string | JSX.Element
}
export type DropAlign = {
  top?: 'top' | 'bottom'
  bottom?: 'top' | 'bottom'
  right?: 'left' | 'right'
  left?: 'left' | 'right'
}

// 単数選択のUIコンポーネント
export type Props = {
  value: ValueType
  placeholder?: string
  options: Array<Option>
  onClickOption: (value: ValueType) => void
  disabled?: boolean
  dropAlign?: DropAlign
  valueButtonCss?: CSSObject
  popoverCss?: CSSObject
  onOpen?: () => void
  onClose?: () => void
}

export const Select: VFC<Props> = ({
  value,
  placeholder,
  options,
  disabled = false,
  onClickOption,
  dropAlign,
  valueButtonCss: valueButtonPropsCss,
  popoverCss,
  onOpen = noop,
  onClose = noop,
}) => {
  const [open, setOpen] = useState(false)
  const valueComponentRef = useRef<HTMLButtonElement | null>(null)
  const dropRef = useRef<HTMLDivElement | null>(null)

  useKeyboardCursor(valueComponentRef, dropRef)

  const toggle = useCallback(() => {
    setOpen(!open)
    if (!open) {
      onOpen()
    } else {
      onClose()
    }
  }, [open, onOpen, onClose])
  const close = useCallback(() => {
    setOpen(false)
    onClose()
  }, [setOpen, onClose])
  const clickOption = useCallback(
    (v: ValueType) => {
      onClickOption(v)
      close()
    },
    [close, onClickOption],
  )
  const displayText = useMemo(() => options.find((o) => o.value === value)?.text, [options, value])

  return (
    <Fragment>
      <button
        type="button"
        ref={valueComponentRef}
        disabled={disabled}
        onClick={toggle}
        css={css([valueButtonCss, valueButtonPropsCss])}
      >
        <StyledText color={disabled ? 'text-bk-50' : 'text-bk-100'} css={valueButtonTextCss}>
          {displayText}
        </StyledText>
        {typeof displayText === 'undefined' && Boolean(placeholder) && (
          <p css={placeholderTextCss}>{placeholder}</p>
        )}
        <div css={iconBox}>
          <Icon width={8} height={16} type="selectDown" color={color('border-bk-20')} />
        </div>
      </button>
      {valueComponentRef?.current && (
        <Drop
          ref={dropRef}
          elevation="none"
          stretch={false}
          target={valueComponentRef.current}
          align={dropAlign}
          onClickOutside={close}
          css={dropCss}
          data-is-open={open}
        >
          <SelectPopover
            value={value}
            options={options}
            onClickOption={clickOption}
            popoverCss={popoverCss}
          />
        </Drop>
      )}
    </Fragment>
  )
}

Select.displayName = 'Select'

export type SelectPopoverProps = {
  value: ValueType
  options: Array<Option>
  onClickOption: (value: ValueType) => void
  popoverCss?: CSSObject
}
export const SelectPopover: VFC<SelectPopoverProps> = ({
  value,
  options,
  onClickOption,
  popoverCss,
}) => {
  const optionsComponent = useMemo(
    () =>
      options.map((option) => (
        <button
          type="button"
          key={option.value}
          className={OPTION_CLASSNAME}
          data-is-active={value === option.value}
          onClick={() => onClickOption(option.value)}
          css={selectButtonCss}
        >
          {option.text}
        </button>
      )),
    [onClickOption, options, value],
  )

  return <div css={css([selectPopoverBox, popoverCss])}>{optionsComponent}</div>
}

SelectPopover.displayName = 'SelectPopover'
