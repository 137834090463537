import { VFC, useContext } from 'react'

import { Icon } from '../../../components/ui/Icon'
import { KrFilterContext } from '../../../contexts/KrFilterContext'
import { useTranslation } from '../../../i18n'
import { color } from '../../../styles/newColors'

import { ControllerBase, Props as ControllerBaseProps } from './ControllerBase'

export type Props = Omit<ControllerBaseProps, 'onClick'>

export const KrFilterController: VFC<Props> = (props) => {
  const { t } = useTranslation()
  const { filters, clearKrFilters } = useContext(KrFilterContext)

  if (filters.size === 0) return null

  return (
    <ControllerBase {...props} onClick={clearKrFilters}>
      <button
        type="button"
        css={{
          fontSize: '14px',
          fontWeight: 500,
          padding: '4px 8px 4px 12px',
          '&:hover': {
            color: color('resily-orange-100'),
            'path[stroke]': {
              stroke: color('resily-orange-100'),
            },
            'path[fill]': {
              fill: color('resily-orange-100'),
            },
          },
        }}
      >
        <Icon type="filterOn" style={{ marginRight: '8px' }} />
        {t('CANCEL_KR_FILTER')}
      </button>
    </ControllerBase>
  )
}

KrFilterController.displayName = 'KrFilterController'
