import { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import { useCurrentUser, useSetCurrentUser } from '../../contexts/UserContext'
import { useTranslation } from '../../i18n'
import { onError } from '../../lib/client'
import { AuthRouteProps } from '../../types/authRouteProps'
import * as urls from '../../urls'

import {
  useUpdateGroupMutation,
  useFindGroupByIdQuery,
  useDisableGroupMutation,
  useAttachAdminToGroupMutation,
  useDetachAdminFromGroupMutation,
  useAttachMemberToGroupMutation,
  useDetachMemberFromGroupMutation,
} from './api/graphql'
import { GroupDetail } from './components/GroupDetail'

export const GroupDetailContainer: React.FC<AuthRouteProps> = ({ onOkrTermLoaded }) => {
  const { groupId } = useParams()
  const navigate = useNavigate()
  const user = useCurrentUser()
  const updateUser = useSetCurrentUser()
  const [updateGroupMutation] = useUpdateGroupMutation()
  const [disableGroupMutation] = useDisableGroupMutation()
  const [attachAdminMutation] = useAttachAdminToGroupMutation()
  const [detachAdminMutation] = useDetachAdminFromGroupMutation()
  const [attachMemberMutation] = useAttachMemberToGroupMutation()
  const [detachMemberMutation] = useDetachMemberFromGroupMutation()

  useEffect(() => {
    onOkrTermLoaded(null)
  }, [onOkrTermLoaded])

  const { t } = useTranslation()
  const { data } = useFindGroupByIdQuery({
    variables: { id: groupId ?? '' },
    skip: groupId == null,
  })

  const addGroupToCurrentUser = (userId: string) => {
    if (!data || !user || userId !== user.id) return
    const newGroup = (({ __typename, id, name, depth, childGroupIds }) => ({
      __typename,
      id,
      name,
      depth,
      childGroupIds,
    }))(data.findGroupById)
    updateUser({ ...user, groups: [...user.groups, { ...newGroup }] })
  }

  const removeGroupFromCurrentUser = (userId: string) => {
    if (
      !data ||
      !user ||
      userId !== user.id ||
      !user.groups.some((group) => group.id === data.findGroupById.id)
    )
      return
    const newGroups = user?.groups.filter((group) => group.id !== data.findGroupById.id)
    updateUser({ ...user, groups: newGroups })
  }

  const deleteGroupFromCurrentUser = () => {
    if (!data || !user || !user.groups.some((group) => group.id === data.findGroupById.id)) return
    const newGroups = user?.groups.filter((group) => group.id !== data.findGroupById.id)
    updateUser({ ...user, groups: newGroups })
  }

  const updateGroup = async (name: string) => {
    if (!data) return
    updateGroupMutation({
      variables: {
        id: data.findGroupById.id,
        name,
      },
    })
  }

  const deleteGroup = async () => {
    if (!data) return
    await disableGroupMutation({
      variables: {
        id: data.findGroupById.id,
      },
    })
    deleteGroupFromCurrentUser()
    navigate(urls.groupsSettingAdmin)
  }

  const attachAdmin = async (id: string) => {
    if (!data) return
    await attachAdminMutation({
      variables: {
        id: data.findGroupById.id,
        adminUserId: id,
      },
    })
    addGroupToCurrentUser(id)
  }

  const detachAdmin = async (id: string) => {
    if (!data) return
    if (data.findGroupById.admins.length <= 1) {
      onError(t('DETACH_ADMIN_ERROR'))
      return
    }
    await detachAdminMutation({
      variables: {
        id: data.findGroupById.id,
        adminUserId: id,
      },
    })
    removeGroupFromCurrentUser(id)
  }

  const attachMember = async (id: string) => {
    if (!data) return
    await attachMemberMutation({
      variables: {
        id: data.findGroupById.id,
        memberUserId: id,
      },
    })
    addGroupToCurrentUser(id)
  }

  const detachMember = async (id: string) => {
    if (!data) return
    await detachMemberMutation({
      variables: {
        id: data.findGroupById.id,
        memberUserId: id,
      },
    })
    removeGroupFromCurrentUser(id)
  }

  if (!data) {
    return null
  }

  return (
    <GroupDetail
      group={data.findGroupById}
      updateGroup={updateGroup}
      deleteGroup={deleteGroup}
      attachAdmin={attachAdmin}
      detachAdmin={detachAdmin}
      attachMember={attachMember}
      detachMember={detachMember}
    />
  )
}

GroupDetailContainer.displayName = 'GroupDetailContainer'
