import { css } from '@emotion/react'
import { VFC, useCallback } from 'react'
import { useForm, Controller } from 'react-hook-form'

import { DateInput } from '../../components/ui/DateInput'
import { LocaleType } from '../../components/ui/DateInput/types'
import { Modal } from '../../components/ui/Modal'
import { StyledText } from '../../components/ui/StyledText'
import { useTranslation } from '../../i18n'

type Props = {
  isOpened: boolean
  onClickSetting: (startDate: Date) => void
  onClickCancel: () => void
}

type FormValue = {
  startDate: Date
}

// Formを設定してsubmitできるようにする
export const UsingStartDateModal: VFC<Props> = ({ isOpened, onClickSetting, onClickCancel }) => {
  const { t, i18n } = useTranslation()

  const { getValues, control } = useForm<FormValue>({
    defaultValues: {
      startDate: new Date(),
    },
  })

  const handleClickExecute = useCallback(() => {
    const startDate = getValues('startDate')
    onClickSetting(new Date(startDate))
  }, [getValues, onClickSetting])

  return (
    <Modal
      title={t('SETTING_X', { x: '利用開始日' })}
      isOpened={isOpened}
      executeText={t('SETTING')}
      onClickExecute={handleClickExecute}
      onClickCancel={onClickCancel}
    >
      <StyledText css={styles.description}>
        選択したユーザーのResily利用開始日を設定してください。
      </StyledText>
      <StyledText color="text-bk-50" size="small" weight="bold">
        利用開始日
      </StyledText>
      <Controller
        name="startDate"
        control={control}
        render={({ field: { value, onChange } }) => (
          <DateInput
            value={value.toString().substring(0, 10)}
            locale={i18n.language as LocaleType}
            onChange={({ value: newValue }) => onChange(newValue)}
            icon={false}
            css={styles.input}
          />
        )}
      />
    </Modal>
  )
}

const styles = {
  description: css({
    display: 'block',
    marginBottom: 24,
  }),
  input: css({
    width: 260,
    marginTop: 12,
  }),
}

UsingStartDateModal.displayName = 'UsingStartDateModal'
