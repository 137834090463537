import { useState } from 'react'

import { ObjectiveFragment } from '../../graphql'

import { ObjectiveDescriptionContainer } from './components/ObjectiveDescription'
import { ObjectiveWeightSettingContainer } from './components/ObjectiveWeightSetting'
import { OkrDrawerSubMenu } from './components/OkrDrawerSubMenu'

type Props = {
  objective: ObjectiveFragment
}

export const UpdateObjectiveContent: React.VFC<Props> = ({ objective }) => {
  const [tab, setTab] = useState<'info' | 'weight'>('info')

  return tab === 'weight' ? (
    <div css={{ width: '100%' }}>
      <OkrDrawerSubMenu onClickBackFromWeight={() => setTab('info')} />
      <div css={{ overflowY: 'auto', padding: '32px 24px 0px' }}>
        <ObjectiveWeightSettingContainer objectiveId={objective.id} />
      </div>
    </div>
  ) : (
    <div css={{ overflowY: 'auto', padding: '24px 0', margin: '0 24px' }}>
      <ObjectiveDescriptionContainer
        objectiveId={objective.id}
        isShownDisabledOkr={false}
        onClickWeightSetting={() => setTab('weight')}
      />
    </div>
  )
}
