export const featureNames = {
  krDrawer: 'kr-drawer',
  checkinModal: 'checkin-modal',
  quickUpdateModal: 'quick-update-modal',
  okrModal: 'okr-modal',
  okrCreateModal: 'okr-create-modal',
  keyResultCreateModal: 'key-result-create-modal',
  homeOkr: 'home-okr',
} as const

export type FeatureName = typeof featureNames[keyof typeof featureNames]

export const componentNames = {
  // header
  header: 'header',
  // content
  content: 'content',
  // footer
  footer: 'footer',
  // スクロールエリア
  scrollable: 'scrollable',
  // フォーム
  form: 'form',
  // アイコン
  icon: 'icon',
  // ボタン
  button: 'button',
  // 実行ボタン
  executeButton: 'execute-button',
  // 更新ボタン 進捗更新
  updateProgressButton: 'update-progress-button',
  // 更新ボタン 目標設定
  updateTargetSettingButton: 'update-target-setting-button',
  // キャンセルボタン
  cancelButton: 'cancel-button',
  // キャンセルボタン 目標設定
  cancelTargetSettingButton: 'cancel-target-setting-button',
  // スケルトン
  skeleton: 'skeleton',
  // 目次メニューの項目
  indexItem: 'index-item',
  // リストアイテム
  listItem: 'list-item',
  // KRのタイトル
  krTitle: 'kr-title',
  // エディター
  editor: 'editor',
  // 進捗バー
  percentageBar: 'percentage-bar',
  // 親OKRの選択プルダウン
  parentOkrSelect: 'parent-okr-select',
  // 親OKRの選択プルダウンの選択肢
  parentOkrSelectOptions: 'parent-okr-select-options',
  // KRの紐付きを追加ボタン
  addConnectionKrButton: 'add-connection-kr-button',
  // 親KRの選択プルダウン
  parentKeyResultSelect: 'parent-key-result-button',
  // 進捗率入力
  progressRateInput: 'progress-rate-input',
  // 実績値入力
  actualValueInput: 'actual-value-input',
  // 目標値入力
  targetValueInput: 'target-value-input',
  // 単位入力
  unitSelect: 'unit-select',
  // 初期値入力
  initialValueInput: 'initial-value-input',
  // 更新理由入力
  updateMemoInput: 'update-memo-input',
  // 更新理由入力 目標設定
  updateGoalSettingMemoInput: 'update-goal-setting-memo-input',
  // 責任者の選択プルダウン
  ownerSelect: 'owner-select',
  // グループの選択プルダウン
  groupSelect: 'group-select',
  // 自信度のラジオボタン
  confidenceRadio: 'confidence-radio',
  // 進捗率
  progressRate: 'progress-rate',
  // 実績値
  actualValue: 'actual-value',
  // 目標値
  targetValue: 'target-value',
  // 単位
  unit: 'unit',
  // 初期値
  initialValue: 'initial-value',
  // 更新理由
  updateReason: 'update-reason',
  // 自信度
  confidence: 'confidence',
  // Objective Area
  objectiveArea: 'objective-area',
  // KR Area
  krArea: 'kr-area',
  // Checkin Area
  checkinArea: 'checkin-area',
  // Right Section
  rightSection: 'right-section',
  // KR Drawer
  krDrawer: 'kr-drawer',
  // Objective タブ
  objectiveTab: 'o-tab',
  // Objective タブ コンテンツ部位
  objectiveTabContent: 'o-tab-content',
  // KR タブ
  krTab: 'kr-tab',
  // KR タブ コンテンツ部位
  krTabContent: 'kr-tab-content',
} as const

export type ComponentName = typeof componentNames[keyof typeof componentNames]
type Options = {
  prefix?: string
  suffix?: string
}

export const generateTestId = (
  featureName: FeatureName,
  componentName: ComponentName,
  options: Options = { prefix: '', suffix: '' },
): string => {
  const { prefix, suffix } = options
  const pre = prefix ? `${prefix}-` : ''
  const suf = suffix ? `-${suffix}` : ''
  return `${pre}${featureName}-${componentName}${suf}`
}
