import { useTranslation } from '../../../../../i18n'
import { StyledText } from '../../../../ui/StyledText'
import { UserFragment, GroupFragment } from '../../graphql'

import { MemberSelect } from './MemberSelect'
import { useStyles } from './index.styles'
import { useFilteredMemberOption } from './useFilteredMemberOption'

export type Props = {
  users: ReadonlyArray<UserFragment>
  groups: ReadonlyArray<GroupFragment>
  ownerId: string
  memberIds: ReadonlyArray<string>
  setMembers: (memberIds: ReadonlyArray<string>) => void
}

export const MembersInput: React.FC<Props> = ({
  users,
  groups,
  ownerId,
  memberIds,
  setMembers,
}) => {
  const { t } = useTranslation()
  const styles = useStyles()

  const { groups: filteredGroups, users: filteredUsers } = useFilteredMemberOption({
    users,
    groups,
    ownerId,
    memberIds,
  })

  return (
    <div css={styles.form}>
      <StyledText size="small" fontStyle="bold" css={styles.label}>
        {t('CONTRIBUTOR')}
      </StyledText>
      <MemberSelect
        selectedUserIds={memberIds}
        members={filteredUsers}
        groups={filteredGroups}
        onClickUserOption={setMembers}
      />
    </div>
  )
}
