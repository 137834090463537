import { NoteDetailEditPageCard } from '../../components/domain/NoteDetailEditPageCard'
import { PersonalNoteOptionContainer } from '../../components/domain/PersonalNoteOption'
import { PageContent } from '../../components/pageContent'
import { color } from '../../styles/newColors'
import { AuthRouteProps } from '../../types/authRouteProps'

import { useInjection } from './hooks'

export const PersonalNoteDetailEditContainer: React.FC<AuthRouteProps> = () => {
  const data = useInjection()

  if (!data) {
    return null
  }

  const { breadcrumbs, personalNote, onUpdatePersonalNote, onClickCancel, setNeedBlock } = data

  return (
    <PageContent
      breadcrumbs={breadcrumbs}
      layout={{
        css: {
          backgroundColor: color('background-bk-5'),
          padding:
            '60px max(40px, calc((100% - 1045px)/2)) 0 max(40px, calc((100% - 1045px)/2) - 50px)',
          minWidth: '1175px',
        },
      }}
    >
      <div css={{ display: 'flex', width: '1095px' }}>
        <div
          css={{
            minWidth: '855px',
          }}
        >
          <NoteDetailEditPageCard
            note={personalNote}
            updateNote={onUpdatePersonalNote}
            onClickCancel={onClickCancel}
            setNeedBlock={setNeedBlock}
          />
        </div>
        <div css={{ marginTop: '9px', marginLeft: '8px', minWidth: '232px' }}>
          <PersonalNoteOptionContainer personalNoteId={personalNote.id} />
        </div>
      </div>
    </PageContent>
  )
}

PersonalNoteDetailEditContainer.displayName = 'PersonalNoteDetailEditContainer'
