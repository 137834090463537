import { PageContent } from '../../../../components/pageContent'
import { useTranslation } from '../../../../i18n'
import { Screen } from '../../../../lib/screen'
import { useTracking } from '../../../../lib/tracking'
import { color } from '../../../../styles/newColors'
import { organizationAdmin } from '../../../../urls'
import { EditOrganizationNameFormSection } from '../EditOrganizationNameFormSection'

import { OrganizationAdminProps } from './types'

export const OrganizationAdmin: React.FC<OrganizationAdminProps> = ({
  organization,
  updateOrganization,
}) => {
  const { t } = useTranslation()
  useTracking(t('ADMIN_ORGANIZATION_PAGE_TITLE'), Screen.OrganizationSetting)

  return (
    <PageContent
      breadcrumbs={{
        url: organizationAdmin,
        items: [{ breadcrumbName: 'setting' }, { breadcrumbName: 'organization' }],
      }}
      layout={{ css: { padding: '48px 32px' } }}
      contentBackgroundColor={color('background-bk-5')}
    >
      <div
        css={{
          background: color('white-100'),
          padding: '24px 32px',
          borderRadius: '8px',
        }}
      >
        <EditOrganizationNameFormSection
          organization={organization}
          updateOrganization={updateOrganization}
        />
      </div>
    </PageContent>
  )
}

OrganizationAdmin.displayName = 'OrganizationAdmin'
