import { DeleteModal } from '../../components/ui/DeleteModal'
import { StyledText } from '../../components/ui/StyledText'
import { color } from '../../styles/newColors'

export type Props = {
  title?: string
  id: string
  deleteItemName: string
  deleteItemNameForBody?: string
  isShowDeleteModal: boolean
  setIsShowDelete: React.Dispatch<React.SetStateAction<boolean>>
  onDeleteCommonFunction: (id: string) => void
  children?: React.ReactNode
}

export const OneOnOneDeleteModal: React.VFC<Props> = ({
  title,
  id,
  deleteItemName,
  deleteItemNameForBody,
  isShowDeleteModal,
  setIsShowDelete,
  onDeleteCommonFunction,
  children,
}) => (
  <DeleteModal
    isOpened={isShowDeleteModal}
    deleteItemName={deleteItemName}
    deleteItemNameForBody={deleteItemNameForBody}
    onClickDelete={() => {
      onDeleteCommonFunction(id)
      setIsShowDelete(false)
    }}
    onClickCancel={() => setIsShowDelete(false)}
    onClickOutside={() => setIsShowDelete(false)}
  >
    {title && (
      <div
        css={{
          marginTop: '24px',
          padding: '16px',
          borderRadius: '8px',
          border: `solid 1px ${color('border-bk-30')}`,
        }}
      >
        <StyledText weight="bold">{title}</StyledText>
      </div>
    )}
    {typeof children !== 'undefined' && <div css={{ marginTop: '24px' }}>{children}</div>}
  </DeleteModal>
)

OneOnOneDeleteModal.displayName = 'OneOnOneDeleteModal'
