import { Box } from 'grommet'
import { forwardRef } from 'react'

import { Icon } from '../../../../../../../components/ui/Icon'
import { NewTitle } from '../../../../../../../components/ui/Title'
import { fontSize } from '../../../../../../../styles/font'
import { color } from '../../../../../../../styles/newColors'

import { OkrNodeFragment } from './graphql'

export type Props = {
  okrNode: OkrNodeFragment
}

export const OkrNodesTableItemsDescription = forwardRef<HTMLTableRowElement, Props>(
  ({ okrNode, ...props }, ref) => (
    <tr ref={ref} {...props}>
      <td css={{ width: '32px' }}>
        <Box css={{ padding: '0 8px' }}>
          <Icon type="dragIndicator" color={color('text-bk-30')} />
        </Box>
      </td>
      <td
        id={okrNode.id}
        style={{
          width: 'auto',
        }}
      >
        <h2
          css={{
            flex: '1 1 auto',
            marginTop: '8px',
            padding: '8px 0',
            fontWeight: 'normal',
            color: color('text-bk-100'),
            ...fontSize('medium'),
          }}
        >
          <NewTitle
            color="objective-blue-100"
            title={okrNode.objective.name || ''}
            disabled={okrNode.objective.isDisabled}
            readonly
          />
        </h2>
      </td>
    </tr>
  ),
)

OkrNodesTableItemsDescription.displayName = 'OkrNodesTableItemsDescription'
