import React from 'react'

import { PageContent } from '../../../components/pageContent'
import { useTranslation } from '../../../i18n'
import { ExternalUrls } from '../../../urls'
import { Wrapper } from '../Wrapper'

import { useStyles } from './index.styles'

export const InvalidLink: React.VFC = () => {
  const styles = useStyles()
  const { t } = useTranslation()

  return (
    <PageContent
      breadcrumbs={undefined}
      layout={{
        height: '100%',
        align: 'center',
        justify: 'center',
      }}
    >
      <Wrapper>
        <div css={styles.root}>
          <h1 css={styles.title}>{t('INVALID_LINK_TITLE')}</h1>
          <p css={styles.text}>{t('INVALID_LINK_RESET_PASSWORD_DESCRIPTION1')}</p>
          <p css={[styles.text, styles.bold]}>{t('INVALID_LINK_RESET_PASSWORD_DESCRIPTION2')}</p>
          <p css={styles.text}>{t('INVALID_LINK_PLEASE_CONTACT_TO_RESILY')}</p>
        </div>
      </Wrapper>
      <div css={styles.linkArea}>
        <a
          css={styles.link}
          href={ExternalUrls.HELP_PAGE}
          rel="noopener noreferrer"
          target="_blank"
        >
          {t('HELP')}
        </a>
        <a
          css={styles.link}
          href={ExternalUrls.INQUIRY_PAGE}
          rel="noopener noreferrer"
          target="_blank"
        >
          {t('CONTACT_US')}
        </a>
      </div>
    </PageContent>
  )
}
