import { Box } from 'grommet'
import * as React from 'react'
import { useCallback, useState } from 'react'

import { Icon, Type as IconType } from '../../components/ui/Icon'
import { StyledText } from '../../components/ui/StyledText'
import { border } from '../../styles/border'
import { color } from '../../styles/newColors'

export type AgendaContent = {
  id: string
  text: string
  memo: string
  firstName: string
  lastName: string
  isUserDisabled: boolean
  avatarUrl: undefined
}

export type Props = {
  tabs: Map<string, TabProps>
}

export type TabProps = {
  name: string
  icon?: IconType
  content: React.ReactNode
}

export const Tab: React.FC<Props> = ({ tabs, ...props }) => {
  const tabsKeys = [...tabs.keys()]

  const [selectedTab, setSelectedTab] = useState<string>(tabsKeys.length > 0 ? tabsKeys[0] : '')

  const onClickTabName = useCallback(
    (tabId: string) => {
      setSelectedTab(tabId)
    },
    [setSelectedTab],
  )

  return (
    <Box {...props}>
      <div
        css={{
          display: 'flex',
          borderBottom: border('simple-10'),
          paddingLeft: '48px',
          '> div': {
            margin: '0 16px',
          },
          '> div:first-of-type': {
            marginRight: '16px',
          },
          '> div:last-of-type': {
            marginLeft: '16px',
          },
        }}
      >
        {[...tabs.entries()].map(([tabId, tab]) => (
          <Box
            pad={{ top: '8px' }}
            key={`tab-${tabId}`}
            css={{
              lineHeight: '16px',
              fontSize: '16px',
              fontWeight: 'bold',
              borderBottom: selectedTab === tabId ? `2px solid ${color('text-bk-100')}` : undefined,
            }}
            onClick={() => {
              onClickTabName(tabId)
            }}
          >
            <Box direction="row" pad={{ bottom: '14px' }}>
              {tab.icon && (
                <Icon
                  type={tab.icon}
                  opacity={selectedTab === tabId ? '1' : '0.5'}
                  css={{
                    width: '18px',
                    height: '18px',
                    marginRight: '5px',
                  }}
                />
              )}
              <StyledText
                size="large"
                color={selectedTab === tabId ? 'text-bk-100' : 'text-bk-50'}
                weight="bold"
                lineHeight="16px"
              >
                {tab.name}
              </StyledText>
            </Box>
          </Box>
        ))}
      </div>
      {[...tabs.entries()].map(([tabId, tab]) => (
        <div
          key={`tab-content-${tabId}`}
          style={{ display: selectedTab === tabId ? 'block' : 'none' }}
        >
          {tab.content}
        </div>
      ))}
    </Box>
  )
}

Tab.displayName = 'Tab'
