import { css } from '@emotion/react'
import { Button, Skeleton, TextLink } from '@resily/geisha'
import { useNavigate } from 'react-router-dom'

import { Icon } from '../../../../../components/ui/Icon'
import { useTranslation } from '../../../../../i18n'
import { color } from '../../../../../styles/newColors'
import * as urls from '../../../../../urls'

import { CheckinMessageWithIcon } from './CheckinMessageWithIcon'

const rootCss = css({
  display: 'flex',
  alignItems: 'center',
  height: 88,
  gap: 12,
  '& > *:nth-child(1)': {
    marginLeft: '24px',
  },
  '& > *:nth-child(2)': {
    flex: '1 1 auto',
  },
  '& > *:nth-child(3)': {
    flex: '0 0 auto',
    marginRight: '36px',
  },
})
const rootWithBorderCss = css(rootCss, {
  border: `2px solid ${color('resily-orange-100')}`,
})

const messageAreaCss = css({ display: 'flex', flexDirection: 'column', gap: 12 })
const messageMainCss = css({
  fontSize: '16px',
  lineHeight: '16px',
  fontWeight: '700',
})
const checkinMessageMainCss = css(messageMainCss, { color: color('resily-orange-100') })
const completeCheckinMessageMainCss = css(messageMainCss, { color: color('text-bk-100') })

const messageSubCss = css({
  fontSize: '12px',
  lineHeight: '12px',
  color: color('text-bk-80'),
})

const checkinButtonCommonCss = css({
  height: 40,
  padding: '10px 16px',
  borderRadius: 4,
  fontSize: '14px',
  lineHeight: '14px',
})

const checkinMeritTextCss = css({
  display: 'flex',
  flexDirection: 'row',
  gap: 12,
})

const editThisWeeksPostWrapperCss = css({
  display: 'flex',
  gap: '16px',
})

const editThisWeeksPostCss = css({
  display: 'flex',
  alignItems: 'center',
  gap: 4,
  color: color('text-bk-30'),
  fontSize: '14px',
  ':hover': {
    color: color('resily-orange-100'),
  },
})

export type Props = {
  termId: string
  userId: string
  isCheckin: boolean
  onClickCheckin: () => void
}

export const CheckinArea: React.FC<Props> = ({ termId, userId, isCheckin, onClickCheckin }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return isCheckin ? (
    <div css={rootCss}>
      <Icon type="checkDocument" width={56} height={56} />
      <div css={messageAreaCss}>
        <span css={completeCheckinMessageMainCss}>{t('COMPLETE_CHECKIN')}</span>
        <span css={messageSubCss}>{t('COMPLETE_CHECKIN2')}</span>
      </div>
      <div css={editThisWeeksPostWrapperCss}>
        <TextLink type="secondary" css={editThisWeeksPostCss} onClick={onClickCheckin}>
          <Icon type="editSimple" width={14} height={14} />
          {t('EDIT_X', { x: t('THIS_WEEKS_POST') })}
        </TextLink>
        <Button
          size="large"
          type="secondary"
          onClick={() => {
            navigate(urls.generateHomeActivity(termId)(userId))
          }}
        >
          {t('CONFIRMATION_OF_X', { x: t('ACTIVITY') })}
        </Button>
      </div>
    </div>
  ) : (
    <div css={rootWithBorderCss}>
      <Icon type="mountain" width={56} height={56} />
      <div css={messageAreaCss}>
        <span css={checkinMessageMainCss}>{t('DO_CHECKIN')}</span>
        <div css={checkinMeritTextCss}>
          <CheckinMessageWithIcon message={t('DO_CHECKIN_MERIT1')} />
          <CheckinMessageWithIcon message={t('DO_CHECKIN_MERIT2')} />
          <CheckinMessageWithIcon message={t('DO_CHECKIN_MERIT3')} />
        </div>
      </div>
      <button
        type="button"
        css={css(checkinButtonCommonCss, {
          color: 'white',
          backgroundColor: color('resily-orange-100'),
        })}
        onClick={onClickCheckin}
      >
        {t('CHECKIN')}
      </button>
    </div>
  )
}

export const CheckinAreaSkeleton: React.FC = () => (
  <div css={rootCss}>
    <Skeleton circle height={48} />
    <div css={messageAreaCss}>
      <Skeleton text height={16} width={320} />
      <Skeleton text height={12} width={200} />
    </div>
    <Skeleton height={40} width={104} radius={4} />
  </div>
)
