import { useEffect, useRef } from 'react'

import { useIsFoldOkrCardById, useSetIsFoldOkrCardById } from '../OkrCardIsFoldByIdContext'
import { useIsFoldOkrCardGlobal } from '../OkrCardIsFoldGlobalContext'

export const useOkrCardIsFoldLocal = (nodeId: string): [boolean, (isFold: boolean) => void] => {
  const isFirstRender = useRef(true)
  const isFoldOkrCardGlobal = useIsFoldOkrCardGlobal()
  const isFoldOkrCardLocal = useIsFoldOkrCardById(nodeId)
  const setIsFoldOkrCardLocal = useSetIsFoldOkrCardById(nodeId)

  useEffect(() => {
    // レンダリング範囲外に行き、再度範囲内に戻ったためレンダリングするような状況下では、
    // 全体の状態をローカルに反映する処理を行うと常に全体の状態が優先されてしまうのを回避する
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }

    setIsFoldOkrCardLocal(isFoldOkrCardGlobal)
  }, [isFoldOkrCardGlobal, setIsFoldOkrCardLocal])

  return [isFoldOkrCardLocal ?? isFoldOkrCardGlobal, setIsFoldOkrCardLocal]
}
