import { Link } from '../../../../components/ui/Link'
import { StyledText } from '../../../../components/ui/StyledText'
import { color } from '../../../../styles/newColors'
import { billingAdmin } from '../../../../urls'

export type Props = {
  remainingLicenses: number
  backgroundColor: string
}

export const RemainingLicensesArea: React.VFC<Props> = ({ remainingLicenses, backgroundColor }) => (
  <div
    style={{
      backgroundColor: remainingLicenses < 1 ? color('resily-orange-5') : backgroundColor,
      borderRadius: 4,
      height: 28,
      maxWidth: 550,
      padding: '2px 12px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: 24,
    }}
  >
    {remainingLicenses < 1 ? (
      <StyledText color="tag-red" fontStyle="regular">
        ユーザーを招待/有効化するためのライセンス数が足りません
      </StyledText>
    ) : (
      <span style={{ display: 'inline-flex', gap: 4 }}>
        <StyledText color="text-bk-50" fontStyle="regular">
          ユーザーを招待/有効化できる残りライセンス数:
        </StyledText>
        <StyledText color="text-bk-100" fontStyle="regular" size="large">
          {remainingLicenses}
        </StyledText>
      </span>
    )}
    <Link href={billingAdmin}>ライセンスを購入</Link>
  </div>
)
