import { stringify } from 'querystring'

import { createContext, useState, useEffect } from 'react'
import { useDebounce } from 'react-use'

import { configureEndpoint } from '../config'

import { timezoneOffset } from './date'

const noop = (): void => {}
// eslint-disable-next-line import/no-mutable-exports
export let onLoadingChange: (loading: boolean) => void = noop
// eslint-disable-next-line import/no-mutable-exports
export let onSuccess: (success: boolean) => void = noop
// eslint-disable-next-line import/no-mutable-exports
export let onError: (error: string) => void = noop

let count = 0
const loading = () => count > 0

const onStart = () => {
  count += 1
  onLoadingChange(loading())
}

const onNetworkError = (err: Error) => {
  onError(err.message)
  count -= 1
  onLoadingChange(loading())
}

const onComplete = () => {
  count -= 1
  onLoadingChange(loading())
}

export const config = configureEndpoint({
  protocol: window.location.protocol,
  host: window.location.host,
})

type ValueType = string | number | boolean

const createBody = <T,>(body?: T) => {
  if (typeof body === 'undefined') {
    return undefined
  }
  if (body instanceof FormData) {
    return body
  }
  return JSON.stringify(body)
}

type Publish = {
  error?: boolean
  success?: boolean
}

const customFetch = async <T, R>({
  url,
  method,
  body,
  publish,
  multiPart = false,
}: {
  url: string
  method: 'GET' | 'POST' | 'PUT'
  body?: T
  publish?: Publish
  multiPart?: boolean
}): Promise<R | null> => {
  const { error: shouldPublishError, success: shouldPublishSuccess } = {
    error: true,
    success: true,
    ...publish,
  }

  onStart()

  let res!: Response
  const defaultHeaders = {
    'x-client-timezone-offset': timezoneOffset().toString(),
    'x-request-client-source-url': window.location.href,
  }
  try {
    const headers = multiPart
      ? defaultHeaders
      : {
          'Content-Type': 'application/json',
          ...defaultHeaders,
        }

    res = await fetch(config.base + url, {
      method,
      credentials: 'include',
      mode: 'cors',
      headers,
      body: createBody<T>(body),
    })
  } catch (err) {
    if (err instanceof Error) {
      onNetworkError(err)
    }
    Promise.reject(err)
  }

  if (!res.ok) {
    let errorMessage = String(res.status)
    try {
      const errorRes = await res.json()
      errorMessage = errorRes.message
    } catch (err) {
      // nothing to do
    }

    if (shouldPublishError) {
      onError(errorMessage)
    }
    onComplete()
    return Promise.reject(new Error(errorMessage))
  }

  if (method !== 'GET' && shouldPublishSuccess) {
    onSuccess(true)
  }

  const text = await res.text()
  if (text) {
    onComplete()
    return JSON.parse(text) as Promise<R>
  }

  onComplete()
  return Promise.resolve(null)
}

export const client = {
  get<R>(url: string, query?: { [key: string]: string }, publish?: Publish): Promise<R | null> {
    return customFetch<undefined, R>({
      url: `${url}${query ? `?${stringify(query)}` : ''}`,
      method: 'GET',
      publish,
    })
  },
  post<R>(url: string, body: { [key: string]: ValueType }, publish?: Publish): Promise<R | null> {
    return customFetch<typeof body, R>({ url, method: 'POST', body, publish })
  },
  put<R>(url: string, body: { [key: string]: ValueType }, publish?: Publish): Promise<R | null> {
    return customFetch<typeof body, R>({ url, method: 'PUT', body, publish })
  },
  uploadFiles<R>(
    url: string,
    files: { [key: string]: File },
    publish?: Publish,
  ): Promise<R | null> {
    const body = new FormData()
    Object.keys(files).forEach((key) => {
      body.append(key, files[key])
    })
    return customFetch<FormData, R>({ url, method: 'POST', body, publish, multiPart: true })
  },
}

type ContextType = {
  loading: boolean
  success: boolean
  error?: string
}

export const FetchContext = createContext<ContextType>({
  loading: false,
  success: false,
  error: undefined,
})

export const FetchContextProvider: React.FC = ({ children }) => {
  const [isLoading, setIsLoading] = useState(loading())
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState<string | undefined>(undefined)

  // 初期化時に
  // 1. ローディング状態を state にセットする
  // 2. コールバックを上書きする
  useEffect(() => {
    setIsLoading(loading())
    onLoadingChange = (l) => setIsLoading(l)
    onSuccess = (s) => setSuccess(s)
    onError = (err) => setError(err)
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [])
  /* eslint-enable react-hooks/exhaustive-deps */

  useDebounce(
    () => {
      if (success) {
        setSuccess(false)
      }
    },
    6000,
    [success],
  )
  useDebounce(
    () => {
      if (error) {
        setError(undefined)
      }
    },
    6000,
    [error],
  )

  return (
    <FetchContext.Provider value={{ loading: isLoading, success, error }}>
      {children}
    </FetchContext.Provider>
  )
}

FetchContextProvider.displayName = 'FetchContextProvider'

export const uploadImage = async (file: File): Promise<string> => {
  try {
    const res = await client.uploadFiles<{ name: string; path: string; url: string }>(
      '/upload/images',
      {
        media: file,
      },
    )

    const { base } = configureEndpoint({
      protocol: window.location.protocol,
      host: window.location.host,
    })
    return res ? `${base}/${res.path}` : ''
  } catch (err) {
    return Promise.reject(err)
  }
}
