import { useMemo } from 'react'

import { useCurrentUser } from '../../../contexts/UserContext'
import { convertToFileInput } from '../../../lib/fileInput'
import { Option } from '../../ui/MultiSelect'
import { NoteOption } from '../NoteOption/NoteOption'

import {
  useAttachPersonalNoteLabelMutation,
  useDetachPersonalNoteLabelMutation,
  useUpdatePersonalNoteMutation,
  useFindPersonalNoteByIdQuery,
  FindPersonalNoteByIdDocument,
  FindPersonalNoteByIdQuery,
  FindPersonalNoteByIdQueryVariables,
  PersonalNoteDetailViewNoteDetailFragment,
  useFindUserPersonalNotePermissionQuery,
  usePersonalNoteLabelsQuery,
} from './graphql'

export type Props = {
  personalNoteId: string
}

export const PersonalNoteOptionContainer: React.FC<Props> = ({ personalNoteId }) => {
  const user = useCurrentUser()

  const { data: personalNoteLabelsData } = usePersonalNoteLabelsQuery()

  const res = useFindPersonalNoteByIdQuery({
    variables: { id: personalNoteId },
  })

  const usersRes = useFindUserPersonalNotePermissionQuery({
    variables: { id: personalNoteId },
  })

  const [attachPersonalNoteLabel] = useAttachPersonalNoteLabelMutation()
  const [detachPersonalNoteLabel] = useDetachPersonalNoteLabelMutation()
  const [updatePersonalNote] = useUpdatePersonalNoteMutation()

  const labelOptions: ReadonlyArray<Option> = useMemo(
    () =>
      personalNoteLabelsData?.noteLabels
        .map<Option>(({ id, name, color: backgroundColor }) => ({
          id,
          name,
          isDisabled: false, // Labelは無効化表示をしないため明示的にfalseを渡す
          searchText: name,
          selected: res.data?.findPersonalNoteById
            ? res.data.findPersonalNoteById.labels.some((l) => l.id === id)
            : false,
          icon: (
            <div
              css={{
                backgroundColor,
                width: '14px',
                height: '14px',
                borderRadius: '50%',
                marginRight: '8px',
              }}
            />
          ),
        }))
        .sort((a, b) => (b.selected ? 1 : -1) - (a.selected ? 1 : -1)) ?? [],
    [personalNoteLabelsData?.noteLabels, res.data?.findPersonalNoteById],
  )

  if (!res.data || !usersRes.data || !personalNoteLabelsData) return null

  const personalNote = res.data.findPersonalNoteById
  const users = usersRes.data.findUserPersonalNotePermission

  const updatePersonalNoteMutation = async (
    value: Pick<
      PersonalNoteDetailViewNoteDetailFragment,
      'title' | 'body' | 'attachments' | 'permission'
    >,
  ) => {
    if (!res.data) {
      return
    }
    await updatePersonalNote({
      variables: {
        id: personalNote.id,
        title: value.title,
        body: {
          treeJson: value.body?.treeJson || '',
          plainText: value.body?.plainText || '',
        },
        attachments: value.attachments.map((attachment) => convertToFileInput(attachment)),
        permission: value.permission,
      },
    })
  }

  const attachPersonalNoteLabelMutation = async (noteLabelId: string) => {
    await attachPersonalNoteLabel({
      variables: { personalNoteId, noteLabelId },
      update(cache) {
        const option = { query: FindPersonalNoteByIdDocument, variables: { id: personalNoteId } }
        const { findPersonalNoteById } =
          cache.readQuery<FindPersonalNoteByIdQuery, FindPersonalNoteByIdQueryVariables>(option) ||
          {}
        if (!findPersonalNoteById) return

        const label = personalNoteLabelsData?.noteLabels.find((l) => l.id === noteLabelId)
        if (!label) return
        if (findPersonalNoteById.labels.find((l) => l.id === label.id) != null) return

        cache.writeQuery<FindPersonalNoteByIdQuery, FindPersonalNoteByIdQueryVariables>({
          ...option,
          data: {
            findPersonalNoteById: {
              ...findPersonalNoteById,
              labels: [...findPersonalNoteById.labels, label],
            },
          },
        })
      },
    }).catch(() => {})
  }

  const detachPersonalNoteLabelMutation = async (noteLabelId: string) => {
    await detachPersonalNoteLabel({
      variables: { personalNoteId, noteLabelId },
      update(cache) {
        const option = { query: FindPersonalNoteByIdDocument, variables: { id: personalNoteId } }
        const { findPersonalNoteById } =
          cache.readQuery<FindPersonalNoteByIdQuery, FindPersonalNoteByIdQueryVariables>(option) ||
          {}
        if (!findPersonalNoteById) {
          return
        }

        cache.writeQuery<FindPersonalNoteByIdQuery, FindPersonalNoteByIdQueryVariables>({
          ...option,
          data: {
            findPersonalNoteById: {
              ...findPersonalNoteById,
              labels: findPersonalNoteById.labels.filter((e) => e.id !== noteLabelId),
            },
          },
        })
      },
    }).catch(() => {})
  }

  const onClickLabelOption = (id: string) => {
    if (personalNote.labels.some((label) => label.id === id)) {
      detachPersonalNoteLabelMutation(id)
    } else {
      attachPersonalNoteLabelMutation(id)
    }
  }

  return (
    <NoteOption
      note={personalNote}
      userId={user?.id}
      ownerUserId={personalNote.user.id}
      permissionUsers={users}
      labelOptions={labelOptions}
      onClickLabelOption={onClickLabelOption}
      updateNoteMutation={updatePersonalNoteMutation}
    />
  )
}

PersonalNoteOptionContainer.displayName = 'PersonalNoteOptionContainer'
