import { OrgDiagramRef } from 'basicprimitivesreact'
import { RefObject, useCallback, useEffect, useReducer, useState } from 'react'

import { useOkrTreeScaleConfig } from '../../../lib/localStorage/useOkrTreeScaleConfig'
import { clamp } from '../../../lib/number'

import { Zoomable } from './ScaleController'
import { INITIAL_SCALE } from './const'

const SCALE_DELTA = 0.1
const ZOOM_LIMIT = { up: 9.7, down: 0.1 } as const

// 1000% ~ 40% の範囲内に収める
const zoomClamp = (zoom: number): number => clamp(zoom, ZOOM_LIMIT.down, ZOOM_LIMIT.up)

type Ret = [number, number, Zoomable, () => void, () => void]

type Action =
  | {
      type: 'up'
      value: number
    }
  | {
      type: 'down'
      value: number
    }

export const useScaleAndScroll = (ref: RefObject<OrgDiagramRef>, okrTermId: string): Ret => {
  const [scaleConfig, setScaleConfig] = useOkrTreeScaleConfig(INITIAL_SCALE, okrTermId)
  const [zoomable, setZoomable] = useState<Zoomable>({ up: true, down: true })

  const scaleReducer = useCallback(
    (prevScale: number, action: Action): number => {
      let nextScale = prevScale
      switch (action.type) {
        case 'up':
          nextScale = zoomClamp(prevScale + action.value)
          break
        case 'down':
          nextScale = zoomClamp(prevScale - action.value)
          break
        default:
          return prevScale
      }
      setScaleConfig(nextScale)
      setZoomable({ up: nextScale < ZOOM_LIMIT.up, down: nextScale > ZOOM_LIMIT.down })
      return nextScale
    },
    [setScaleConfig, setZoomable],
  )
  const [scale, scaleDispatch] = useReducer(scaleReducer, scaleConfig ?? INITIAL_SCALE)

  const zoomUp = useCallback(() => scaleDispatch({ type: 'up', value: SCALE_DELTA }), [])
  const zoomDown = useCallback(() => scaleDispatch({ type: 'down', value: SCALE_DELTA }), [])

  const percentage = Math.round((scale - INITIAL_SCALE) * 100) + 100

  // スワイプによるヒストリバックの防止とスクロール処理
  const onWheel = useCallback(
    (e: WheelEvent) => {
      if (!ref.current || !ref.current.scrollPanelRef.current) {
        return
      }

      const { current } = ref.current.scrollPanelRef
      const nextX = current.scrollLeft + e.deltaX
      const nextY = current.scrollTop + e.deltaY
      const maxX = current.scrollWidth - current.offsetWidth
      const maxY = current.scrollHeight - current.offsetHeight
      if (nextX < 0 || nextX > maxX || nextY < 0 || nextY > maxY) {
        e.preventDefault()
        current.scrollLeft = clamp(nextX, 0, maxX)
        current.scrollTop = clamp(nextY, 0, maxY)
      }
    },
    [ref],
  )

  useEffect(() => {
    if (!ref.current || !ref.current.scrollPanelRef.current) {
      return () => {}
    }
    const { current } = ref.current.scrollPanelRef

    current.addEventListener('wheel', onWheel, { passive: false })
    return () => {
      current.removeEventListener('wheel', onWheel)
    }
  }, [onWheel, ref])

  return [scale, percentage, zoomable, zoomUp, zoomDown]
}
