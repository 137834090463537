import { css } from '@emotion/react'

export const containerCss = css({
  display: 'flex',
  flexDirection: 'column',
  margin: '48px 116px',
  gap: '24px',
})

export const menuSectionWrapperCss = css({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
})

export const menuCardsWrapperCss = css({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
})
