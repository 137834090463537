import { yupResolver } from '@hookform/resolvers/yup'
import { Box } from 'grommet'
import { Fragment, useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import * as Yup from 'yup'

import { SelectUnit } from '../../../../../../../components/domain/SelectUnit'
import { Button, Props as ButtonProps } from '../../../../../../../components/ui/Button'
import { FormField } from '../../../../../../../components/ui/FormField'
import { NumberInput } from '../../../../../../../components/ui/NumberInput'
import { StyledText } from '../../../../../../../components/ui/StyledText'
import { TextButton } from '../../../../../../../components/ui/TextButton'
import { Textarea } from '../../../../../../../components/ui/Textarea'
import { useTranslation } from '../../../../../../../i18n'
import {
  FeatureName,
  componentNames,
  featureNames,
  generateTestId,
} from '../../../../../../../lib/testId'

import { ProgressRateInput } from './graphql'

export type FormValue = Pick<
  ProgressRateInput,
  'initialValue' | 'targetValue' | 'actualValue' | 'unit' | 'message'
>

export type Props = {
  progressRateInput: FormValue
  buttonProps: Pick<ButtonProps, 'isLoading'>
  updateProgressRate: (values: FormValue) => void
  onChangeIsEditing?: (value: boolean) => void
  ['data-testid-feature-name']?: FeatureName
}

export const KeyResultDescriptionProgressRateFormSetting: React.FC<Props> = ({
  progressRateInput,
  buttonProps,
  updateProgressRate,
  onChangeIsEditing = () => {},
  'data-testid-feature-name': dataTestIdFeatureName,
}) => {
  const { t } = useTranslation()
  const [isEditingState, setIsEditingState] = useState<boolean>(false)
  const setIsEditing = (value: boolean) => {
    setIsEditingState(value)
    onChangeIsEditing(value)
  }

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm<FormValue>({
    mode: 'onChange',
    defaultValues: {
      ...progressRateInput,
      // targetValueがsetされていない時にinitialValueが0であっても表示しない
      initialValue:
        progressRateInput.targetValue != null ? progressRateInput.initialValue ?? null : null,
    },
    resolver: yupResolver(
      Yup.object().shape({
        initialValue: Yup.number().typeError(t('ONLY_NUMBER')),
        targetValue: Yup.number().typeError(t('ONLY_NUMBER')),
        actualValue: Yup.number().typeError(t('ONLY_NUMBER')),
      }),
    ),
  })

  useEffect(() => {
    reset({
      initialValue: progressRateInput.initialValue || 0,
      targetValue: progressRateInput.targetValue || 0,
      unit: progressRateInput.unit,
      message: progressRateInput.message,
    })
  }, [
    progressRateInput.initialValue,
    progressRateInput.targetValue,
    progressRateInput.unit,
    progressRateInput.message,
    reset,
  ])

  const update = (values: FormValue) => {
    updateProgressRate({
      ...values,
      actualValue: progressRateInput.actualValue ?? values.initialValue,
    })
    setValue('message', undefined)
    setIsEditing(false)
  }

  const resetValue = (): void => {
    setValue('initialValue', progressRateInput.initialValue)
    setValue('targetValue', progressRateInput.targetValue)
    setValue('unit', progressRateInput.unit)
    setValue('message', undefined)
    setIsEditing(false)
  }

  const [unit] = watch(['unit'])

  return (
    <Fragment>
      <form onSubmit={handleSubmit(update)}>
        <Box>
          <Box direction="row" wrap css={{ width: '100%', position: 'relative' }}>
            <div
              css={{
                display: 'flex',
                flexWrap: 'wrap',
                columnGap: '16px',
              }}
            >
              <FormField
                label={t('TARGET_VALUE')}
                labelColor="text-bk-50"
                labelFontSize="xsmall"
                errorMessage={errors.targetValue?.message}
                css={{
                  marginTop: '16px',
                  marginBottom: '0px',
                  maxWidth: 139,
                }}
              >
                <Controller
                  name="targetValue"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <NumberInput
                      defaultValue={value || 0}
                      onFocus={() => {
                        setIsEditing(true)
                      }}
                      onChange={onChange}
                      data-testid={
                        dataTestIdFeatureName &&
                        generateTestId(dataTestIdFeatureName, componentNames.targetValueInput)
                      }
                    />
                  )}
                />
                <StyledText
                  alignSelf="center"
                  oneline
                  size="small"
                  css={{
                    top: '20px',
                    marginLeft: 4,
                    textOverflow: 'ellipsis',
                    overflowX: 'hidden',
                    whiteSpace: 'nowrap',
                    lineHeight: 'normal',
                  }}
                >
                  {unit}
                </StyledText>
              </FormField>
              <FormField
                label={t('TARGET_VALUE_UNIT')}
                labelColor="text-bk-50"
                labelFontSize="xsmall"
                errorMessage={errors.unit?.message}
                css={{ marginTop: '16px', marginBottom: '0px' }}
              >
                <Controller
                  name="unit"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <SelectUnit
                      value={value}
                      onChange={(e) => {
                        onChange(e)
                        setIsEditing(true)
                      }}
                      height={14}
                      data-testid={
                        dataTestIdFeatureName &&
                        generateTestId(dataTestIdFeatureName, componentNames.unitSelect)
                      }
                    />
                  )}
                />
              </FormField>
              <FormField
                label={t('INITIAL_VALUE')}
                labelColor="text-bk-50"
                labelFontSize="xsmall"
                errorMessage={errors.initialValue?.message}
                css={{
                  marginTop: '16px',
                  marginBottom: '0px',
                  maxWidth: 139,
                }}
              >
                <Controller
                  name="initialValue"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <NumberInput
                      defaultValue={value || 0}
                      onFocus={() => {
                        setIsEditing(true)
                      }}
                      onChange={onChange}
                      data-testid={
                        dataTestIdFeatureName &&
                        generateTestId(dataTestIdFeatureName, componentNames.initialValueInput)
                      }
                    />
                  )}
                />
                <StyledText
                  alignSelf="center"
                  oneline
                  size="small"
                  css={{
                    top: '20px',
                    marginLeft: 4,
                    textOverflow: 'ellipsis',
                    overflowX: 'hidden',
                    whiteSpace: 'nowrap',
                    lineHeight: 'normal',
                  }}
                >
                  {unit}
                </StyledText>
              </FormField>
            </div>

            {isEditingState && (
              <Fragment>
                <FormField css={{ width: '100%' }} errorMessage={errors.message?.message}>
                  <Controller
                    name="message"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Textarea
                        value={value || ''}
                        placeholder={t('REASON_FOR_SETTING')}
                        onChange={(e) => onChange(e.target.value || undefined)}
                        data-testid={
                          dataTestIdFeatureName &&
                          generateTestId(
                            dataTestIdFeatureName,
                            componentNames.updateGoalSettingMemoInput,
                          )
                        }
                      />
                    )}
                  />
                </FormField>
                <Box direction="row" justify="end" wrap width="100%">
                  <TextButton
                    color="text-bk-50"
                    type="button"
                    onClick={resetValue}
                    css={{ marginRight: '8px' }}
                    data-testid={generateTestId(
                      featureNames.quickUpdateModal,
                      componentNames.cancelTargetSettingButton,
                    )}
                  >
                    {t('CANCEL')}
                  </TextButton>
                  <Button
                    isLoading={buttonProps.isLoading}
                    newColor="resily-orange-100"
                    disabledColor="resily-orange-100"
                    disabled={Object.values(errors).length > 0}
                    weight="normal"
                    size="s"
                    type="submit"
                    css={{ alignSelf: 'flex-end' }}
                    data-testid={generateTestId(
                      featureNames.quickUpdateModal,
                      componentNames.updateTargetSettingButton,
                    )}
                  >
                    {t('UPDATION')}
                  </Button>
                </Box>
              </Fragment>
            )}
          </Box>
        </Box>
      </form>
    </Fragment>
  )
}

KeyResultDescriptionProgressRateFormSetting.displayName =
  'KeyResultDescriptionProgressRateFormSetting'
