import { Value } from '@udecode/plate'
import { createEnumParam, DecodedValueMap, QueryParamConfig, SetQuery } from 'use-query-params'

type QueryParams = {
  user: QueryParamConfig<Array<string | null> | null | undefined>
  group: QueryParamConfig<Array<string | null> | null | undefined>
  sort: QueryParamConfig<SortType | null | undefined>
  order: QueryParamConfig<OrderType | null | undefined>
}
export type QueryParamsType = DecodedValueMap<QueryParams>
export type SetQueryParamsType = SetQuery<QueryParams>

export type DefaultContentType = {
  title: string
  description: Value
}

export type SendNotification = {
  mail: boolean
  slack: boolean
}

export enum DisplayViewType {
  Show = 'show',
  Hide = 'hide',
}
export const DisplayViewTypeEnumParam = createEnumParam<DisplayViewType>([
  DisplayViewType.Show,
  DisplayViewType.Hide,
])

export enum SendMethodType {
  Mail = 'mail',
  Slack = 'slack',
  Teams = 'teams',
}

export const SortObject = {
  user: 'user',
  objective: 'objective',
  keyResult: 'keyResult',
  contributor: 'contributor',
  lastLoggedIn: 'lastLoggedIn',
} as const
export type SortType = typeof SortObject[keyof typeof SortObject]
export const AllSortType = Object.values(SortObject)
export const SortEnumParam = createEnumParam<SortType>(AllSortType as unknown as Array<SortType>)

export const OrderObject = {
  asc: 'asc',
  desc: 'desc',
} as const
export type OrderType = typeof OrderObject[keyof typeof OrderObject]
export const AllOrderType = Object.values(OrderObject)
export const OrderEnumParam = createEnumParam<OrderType>(
  AllOrderType as unknown as Array<OrderType>,
)

export type Sort = {
  sortBy: QueryParamsType['sort']
  order: QueryParamsType['order']
}
