import React from 'react'

import { Modal } from '../../../../components/ui/Modal'
import { StyledText } from '../../../../components/ui/StyledText'
import { useTranslation } from '../../../../i18n'

export type Props = {
  isOpened: boolean
  deleteItemName: string
  deleteItemNameForBody?: string
  onClickDelete: () => void
  onClickCancel: () => void
  onClickOutside: () => void
}

export const DeleteGroupModal: React.FC<Props> = ({
  children,
  isOpened,
  deleteItemName,
  deleteItemNameForBody,
  onClickDelete,
  onClickCancel,
  onClickOutside,
}) => {
  const { t } = useTranslation()
  return (
    <Modal
      isOpened={isOpened}
      title={t('DELETE_X', { x: deleteItemName })}
      executeText={t('DELETION')}
      onClickExecute={onClickDelete}
      onClickCancel={onClickCancel}
      onClickOutside={onClickOutside}
    >
      <StyledText>
        {t('X_CONFIRMATION_OF_DELETE', { x: deleteItemNameForBody || deleteItemName }) +
          t('WARNING') +
          t('GROUP_DELETE_TEXT')}
      </StyledText>
      {children}
    </Modal>
  )
}

DeleteGroupModal.displayName = 'DeleteGroupModal'
