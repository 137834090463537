import { TFunction } from '../../../../../../i18n'
import { CheckinCommentNames } from '../../../constants'
import { CommentsTabValue } from '../types'

export const generateTabValue = (name: CheckinCommentNames, id: string): CommentsTabValue =>
  `${name}-${id}`

export const generateTooltipMsg = (
  t: TFunction,
  isEditedComments: boolean,
  noPreviousComments: boolean,
): string => {
  if (isEditedComments) {
    return t('COMMENTS_ARE_EDITED_TOOLTIP_MESSAGE')
  }
  if (noPreviousComments) {
    return t('NO_PREVIOUS_COMMENTS_TOOLTIP_MESSAGE')
  }
  return ''
}
