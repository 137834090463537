import { WrapText } from '../../../../../../ui/WrapText'

import { rootCss } from './styles'

type Props = {
  children: string
}
export const IndexTitle: React.VFC<Props> = ({ children, ...rest }) => (
  <WrapText text={children} maxWidth="100%" css={rootCss} {...rest} />
)
