import { Fragment, useState } from 'react'

import { Button } from '../../../../components/ui/Button'
import { useTranslation } from '../../../../i18n'
import { Screen } from '../../../../lib/screen'
import { tracker } from '../../../../lib/tracking'
import { TermFragment } from '../../api/graphql'
import { ModalMode } from '../../types'
import { AdminOkrTermsModal } from '../AdminOkrTermsModal'

import { AdminOkrTermsPageTermTable } from './AdminOkrTermsPageTermTable'
import { okrTermsTableWrapperCss, createButtonCss } from './styles'
import { AdminOkrTermsProps } from './types'

export const AdminOkrTerms: React.FC<AdminOkrTermsProps> = ({
  terms,
  onUpdate,
  onCreate,
  onDelete,
  onCopy,
  onDownload,
}) => {
  const [modalMode, setModalMode] = useState<ModalMode | undefined>(undefined)
  const [targetTerm, setTargetTerm] = useState<TermFragment | undefined>(undefined)
  const findTerm = (termId: string) => terms.find((term) => term.id === termId)
  const { t } = useTranslation()

  const showModal = (termId: string, mode: ModalMode) => {
    const term = findTerm(termId)
    if (!term) return
    setTargetTerm(term)
    setModalMode(mode)
  }

  return (
    <Fragment>
      <div css={okrTermsTableWrapperCss}>
        <AdminOkrTermsPageTermTable
          terms={terms}
          onClickEdit={(termId) => {
            showModal(termId, 'edit')
          }}
          onClickCopy={(termId) => {
            showModal(termId, 'copy')
          }}
          onClickDelete={(termId) => {
            showModal(termId, 'delete')
          }}
          onClickFileExport={async (termId) => {
            tracker.UserClickExportTsvFile(Screen.OkrTermSetting)
            await onDownload(termId)
          }}
        />
      </div>
      <AdminOkrTermsModal
        mode={modalMode}
        term={targetTerm}
        terms={terms}
        onClose={() => setModalMode(undefined)}
        onChanged={(values) => {
          const closeModal = () => {
            setModalMode(undefined)
          }
          if (modalMode === 'edit' && onUpdate) {
            onUpdate(
              {
                ...values,
                startDate: new Date(values.startDate),
                endDate: new Date(values.endDate),
              },
              closeModal,
            )
          } else if (modalMode === 'copy' && onCopy) {
            onCopy(
              {
                ...values,
                startDate: new Date(values.startDate),
                endDate: new Date(values.endDate),
              },
              closeModal,
            )
          } else if (modalMode === 'create' && onCreate) {
            onCreate(
              {
                name: values.name,
                startDate: new Date(values.startDate),
                endDate: new Date(values.endDate),
              },
              closeModal,
            )
          } else if (modalMode === 'delete' && onDelete) {
            onDelete(values.id, closeModal)
          }
        }}
      />
      <Button
        className="show-done-button"
        newColor="resily-orange-100"
        weight="normal"
        size="s"
        disableFocusStyle
        onClick={(e) => {
          e.stopPropagation()
          setTargetTerm(undefined)
          setModalMode('create')
        }}
        css={createButtonCss}
      >
        {t('CREATE_OF_X', { x: t('OKR_TERM') })}
      </Button>
    </Fragment>
  )
}

AdminOkrTerms.displayName = 'AdminOkrTerms'
