import { ApolloCache } from '@apollo/client'

import {
  InvitingUserTableFragmentDoc,
  InvitingUserTableFieldFragment,
  InvitingUserTableFragment,
} from '../graphql'

const INVITING_USER_TABLE_FRAGMENT_NAME = 'InvitingUserTable'
export const writeInvitingUserTableFragment = (
  cache: ApolloCache<unknown>,
  organizationId: string,
  user: InvitingUserTableFieldFragment,
): void => {
  const cacheId = `Organization:${organizationId}`
  const fragmentCacheData = cache.readFragment<InvitingUserTableFragment>({
    id: cacheId,
    fragmentName: INVITING_USER_TABLE_FRAGMENT_NAME,
    fragment: InvitingUserTableFragmentDoc,
  })
  if (fragmentCacheData == null) {
    return
  }

  cache.writeFragment<InvitingUserTableFragment>({
    id: cacheId,
    fragmentName: INVITING_USER_TABLE_FRAGMENT_NAME,
    fragment: InvitingUserTableFragmentDoc,
    data: {
      ...fragmentCacheData,
      invitingUsers: [...fragmentCacheData.invitingUsers, user],
    },
  })
}
