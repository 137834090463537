import { useModal } from '@resily/geisha'
import { ThemeContext } from 'grommet'
import { useCallback, useRef, useState } from 'react'
import isEqual from 'react-fast-compare'

import { useTranslation } from '../../../i18n'
import { isFilledRequiredFieldsForCreateAndUpdateOkr } from '../../../lib/domain/okr'
import { convertToFileInput } from '../../../lib/fileInput'
import { findScreen } from '../../../lib/screen'
import { tracker } from '../../../lib/tracking'
import { StrictPropertyCheck } from '../../../lib/type'
import { useApiProcessing } from '../../../lib/useApiProcessing'
import {
  useKeyResultCreateModalContent,
  KeyResultCreateModalContentProps,
  generateInitialKeyResultState,
} from '../../domain/KeyResultCreateModalContent'
import { ExtendedCreateKeyResultInput } from '../../domain/KeyResultEditFields/types'
import { OkrCreateModalContent, State as OkrContentState } from '../../domain/OkrCreateModalContent'

import { Header } from './Header'
import { AddOkrNodeInput, CreateKeyResultInput, OkrNodeFragment } from './graphql'

export type Props = {
  isOpen: boolean
  termId: string
  currentUserId: string
  initialObjectiveState: OkrContentState
  close: () => void
  onClose: () => void
  onEdit: (isDirty: boolean) => void
  onClickSaveObjective: (input: AddOkrNodeInput) => Promise<OkrNodeFragment | undefined>
  onClickSaveKeyResults: (
    inputs: ReadonlyArray<CreateKeyResultInput>,
  ) => Promise<string | undefined>
  onCompletedCreate?: (nodeId: string) => void
}

export const OkrCreateModal: React.VFC<
  Props & { modalComponent: ReturnType<typeof useModal>[0] }
> = ({
  modalComponent: Modal,
  isOpen,
  termId,
  currentUserId,
  initialObjectiveState,
  close,
  onClose,
  onEdit,
  onClickSaveObjective,
  onClickSaveKeyResults,
  onCompletedCreate,
}) => {
  const { t } = useTranslation()

  // ↓ここから共通の状態
  const [isApiProcessing, wrapApiPromise] = useApiProcessing()

  const [newObjectiveInfo, setNewObjectiveInfo] = useState<{ id: string; name: string }>()
  const isObjectiveMode = newObjectiveInfo == null

  const [keyResultStates, setKeyResultStates] = useState<
    KeyResultCreateModalContentProps['keyResultStates']
  >([])

  const {
    KeyResultCreateModalContent,
    canSave: canKeyResultSave,
    validate: validateKeyResult,
    errors: keyResultErrors,
    convertStateToInput: convertKRStateToKRInput,
    AddKRButton,
  } = useKeyResultCreateModalContent()

  const generateInitialKeyResult = useRef<() => ExtendedCreateKeyResultInput>()
  const handelSetKeyResultStates = useCallback<typeof setKeyResultStates>(
    (arg) => {
      setKeyResultStates((prevState) => {
        const nextState = typeof arg === 'function' ? arg(prevState) : arg
        validateKeyResult(nextState)

        if (nextState.length !== 1) {
          onEdit(true)
        } else {
          const initialKR = convertKRStateToKRInput([generateInitialKeyResult.current!()])[0]
          const isDirty = !convertKRStateToKRInput(nextState).every((kr) => isEqual(kr, initialKR))
          onEdit(isDirty)
        }

        return nextState
      })
    },
    [convertKRStateToKRInput, onEdit, validateKeyResult],
  )

  // ↓ここからO専用の状態

  const [objectiveState, setObjectiveState] = useState<OkrContentState>(initialObjectiveState)
  const handelSetObjectiveState = useCallback<typeof setObjectiveState>(
    (arg) => {
      setObjectiveState((prevState) => {
        const nextState = typeof arg === 'function' ? arg(prevState) : arg
        onEdit(!isEqual(nextState, initialObjectiveState))
        return nextState
      })
    },
    [initialObjectiveState, onEdit],
  )

  const [slackNotifyFlg, setSlackNotifyFlg] = useState<boolean>(false)
  const toggleSlackNotifyFlg = useCallback(
    () => setSlackNotifyFlg(!slackNotifyFlg),
    [slackNotifyFlg],
  )

  const [chatworkNotifyFlg, setChatworkNotifyFlg] = useState<boolean>(false)
  const toggleChatworkNotifyFlg = useCallback(
    () => setChatworkNotifyFlg(!chatworkNotifyFlg),
    [chatworkNotifyFlg],
  )

  const [teamsNotifyFlg, setTeamsNotifyFlg] = useState<boolean>(false)
  const toggleTeamsNotifyFlg = useCallback(
    () => setTeamsNotifyFlg(!teamsNotifyFlg),
    [teamsNotifyFlg],
  )

  const convertObjectiveStateToInput = useCallback(
    (state: OkrContentState): AddOkrNodeInput => {
      const result = {
        name: state.name,
        description: state.description.body
          ? {
              treeJson: state.description.body,
              plainText: state.description.plainText,
            }
          : undefined,
        attachments:
          state.attachmentViews.map<
            StrictPropertyCheck<
              ReturnType<typeof convertToFileInput>,
              NonNullable<Required<AddOkrNodeInput>['attachments']>[0]
            >
          >(convertToFileInput),
        isDescriptionChanged: state.description.plainText !== '',
        okrTermId: termId,
        ownerId: state.owner?.id ?? currentUserId,
        groupIds: state.selectedGroups.map(({ id }) => id),
        parentOkrNodeId: state.parentOkrNode?.id,
        parentKeyResultIds: state.parentKeyResultIds,
        slackChannelIds: slackNotifyFlg ? state.slackChannelIds : null,
        chatworkApiToken: chatworkNotifyFlg ? state.chatworkApiToken : null,
        chatworkRoomId: chatworkNotifyFlg ? state.chatworkRoomId : null,
        teamsWebhookUrl: teamsNotifyFlg ? state.teamsWebhookURL : null,
      }
      const checkResult: StrictPropertyCheck<typeof result, AddOkrNodeInput> = result
      return checkResult
    },
    [chatworkNotifyFlg, currentUserId, slackNotifyFlg, teamsNotifyFlg, termId],
  )

  const saveObjectiveOnly = useCallback(() => {
    tracker.UserClickCreateObjectiveByObjectiveCreateModal(
      findScreen(window.location.pathname, window.location.search),
      false,
    )
    wrapApiPromise(onClickSaveObjective(convertObjectiveStateToInput(objectiveState)))
      .then((node) => {
        if (node && onCompletedCreate) onCompletedCreate(node.id)
      })
      .then(close)
  }, [
    close,
    convertObjectiveStateToInput,
    objectiveState,
    onClickSaveObjective,
    onCompletedCreate,
    wrapApiPromise,
  ])

  const saveObjectiveAndNext = useCallback(() => {
    tracker.UserClickCreateObjectiveByObjectiveCreateModal(
      findScreen(window.location.pathname, window.location.search),
      true,
    )
    wrapApiPromise(onClickSaveObjective(convertObjectiveStateToInput(objectiveState))).then(
      (newNode) => {
        if (!newNode) return
        setNewObjectiveInfo(newNode.objective)
        generateInitialKeyResult.current = () =>
          generateInitialKeyResultState(currentUserId, termId, newNode.objective.id)
        handelSetKeyResultStates([generateInitialKeyResult.current()])
      },
    )
  }, [
    convertObjectiveStateToInput,
    currentUserId,
    handelSetKeyResultStates,
    objectiveState,
    onClickSaveObjective,
    termId,
    wrapApiPromise,
  ])

  const saveObjectiveAndKRs = useCallback(() => {
    tracker.UserClickCreateKeyResultByKeyResultCreateModal(
      findScreen(window.location.pathname),
      true,
    )

    const isValid = validateKeyResult(keyResultStates)
    if (!isValid) return

    wrapApiPromise(onClickSaveKeyResults(convertKRStateToKRInput(keyResultStates)))
      .then((nodeId) => {
        if (nodeId && onCompletedCreate) onCompletedCreate(nodeId)
      })
      .then(close)
  }, [
    close,
    convertKRStateToKRInput,
    keyResultStates,
    onClickSaveKeyResults,
    onCompletedCreate,
    validateKeyResult,
    wrapApiPromise,
  ])

  const cannotCreateOkr = !isFilledRequiredFieldsForCreateAndUpdateOkr(
    convertObjectiveStateToInput(objectiveState),
    slackNotifyFlg,
    chatworkNotifyFlg,
    teamsNotifyFlg,
  )

  const handleOnCloseOkrCreateModal = useCallback(() => {
    tracker.UserClickCloseObjectiveCreateModal(
      findScreen(window.location.pathname, window.location.search),
    )
    onClose()
  }, [onClose])

  return (
    <ThemeContext.Extend value={{ global: { drop: { zIndex: '201' } } }}>
      <Modal
        isOpen={isOpen}
        contentMargin={isObjectiveMode}
        // NOTE: 非geishaのモーダルを上に重ねて表示すると、その内部のinput等にfocusが当たらない問題の対策
        // geishaモーダルへの置き換えが完了した場合は不要
        restrictFocus={false}
        size="large"
        onClose={() => !isApiProcessing && handleOnCloseOkrCreateModal()}
      >
        <Modal.Header title={<Header isObjectiveMode={isObjectiveMode} />} />
        <Modal.Content>
          {isObjectiveMode ? (
            <OkrCreateModalContent
              state={objectiveState}
              setState={handelSetObjectiveState}
              slackNotifyFlg={slackNotifyFlg}
              handleSlackNotifyFlg={toggleSlackNotifyFlg}
              chatworkNotifyFlg={chatworkNotifyFlg}
              handleChatworkNotifyFlg={toggleChatworkNotifyFlg}
              teamsNotifyFlg={teamsNotifyFlg}
              handleTeamsNotifyFlg={toggleTeamsNotifyFlg}
            />
          ) : (
            <KeyResultCreateModalContent
              objective={{ ...newObjectiveInfo, useWeighting: false }}
              keyResultStates={keyResultStates}
              onChangeKeyResults={handelSetKeyResultStates}
              errors={keyResultErrors}
            />
          )}
        </Modal.Content>
        {isObjectiveMode ? (
          <Modal.Footer
            cancelType="tertiary"
            cancelLabel={t('SAVE_ONLY_OBJECTIVE')}
            confirmLabel={t('SAVE_AND_ADD_KR')}
            cancelDisabled={isApiProcessing || cannotCreateOkr}
            confirmDisabled={isApiProcessing || cannotCreateOkr}
            onCancel={saveObjectiveOnly}
            onConfirm={saveObjectiveAndNext}
          />
        ) : (
          <Modal.Footer
            confirmLabel={t('SAVE')}
            confirmDisabled={isApiProcessing || !canKeyResultSave(keyResultStates)}
            onConfirm={saveObjectiveAndKRs}
          >
            <AddKRButton
              disabled={isApiProcessing}
              onClick={() =>
                handelSetKeyResultStates((prev) => prev.concat(generateInitialKeyResult.current!()))
              }
            />
          </Modal.Footer>
        )}
      </Modal>
    </ThemeContext.Extend>
  )
}
