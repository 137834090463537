import { StyledText } from '../../../../../../components/ui/StyledText'
import { useTranslation } from '../../../../../../i18n'

export const OkrDrawerEmptyContent: React.VFC = () => {
  const { t } = useTranslation()
  return (
    <div
      css={{
        width: '100%',
        height: '100%',
        paddingTop: '48px',
        textAlign: 'center',
      }}
    >
      <StyledText size="small" color="text-bk-100">
        {t('OBJECTIVE_OR_KEY_RESULT_NOT_SET')}
      </StyledText>
    </div>
  )
}

OkrDrawerEmptyContent.displayName = 'OkrDrawerEmptyContent'
