import { useFieldArray } from 'react-hook-form'

import { useGetFormMethods } from '../../../contexts/FormProvider'
import { keyResultsKeyName } from '../../../formSchema'

type ReturnType = {
  totalCount: number
  editedCount: number
}

export const useGetEditedKeyResultCount = (): ReturnType => {
  const { control, formState } = useGetFormMethods()
  const { fields: totalKeyResults } = useFieldArray({ name: keyResultsKeyName, control })
  const editedKeyResults = formState.dirtyFields[keyResultsKeyName]?.filter(
    (fields) => fields !== undefined,
  )
  return {
    totalCount: totalKeyResults.length,
    editedCount: editedKeyResults?.length ?? 0,
  }
}
