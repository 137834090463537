import { Dispatch, SetStateAction, useState } from 'react'

import { IndexAreaKeyResultsFragment } from '../IndexArea'

type Args = {
  keyResults: IndexAreaKeyResultsFragment
}

type Return = [string, Dispatch<SetStateAction<string>>]

export const useSelectedKeyResultIdState = ({ keyResults }: Args): Return =>
  // NOTE: チェックイン対象のKeyResultsが0件の場合は例外だが、ErrorBoundaryされるほどクリティカルではないので空文字を挿入する
  useState<string>(keyResults.length > 0 ? keyResults[0].id : '')
