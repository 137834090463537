import { css } from '@emotion/react'
import { PropsWithoutRef } from 'react'

import { useTranslation } from '../../../i18n'
import * as colors from '../../../styles/colors'
import { generateNote } from '../../../urls'
import { AvatarWithName } from '../../ui/AvatarWithName'
import { Button } from '../../ui/ButtonDeprecated'
import { DateTime } from '../../ui/DateTime'
import { Icon } from '../../ui/Icon'
import { Link } from '../../ui/Link'
import { NoteLabel } from '../../ui/NoteLabel'

import { NotePermission, NoteFragment } from './graphql'

export type Props = PropsWithoutRef<JSX.IntrinsicElements['section']> & {
  notes: ReadonlyArray<NoteFragment>
  createNote?: () => void
}

export const NotesTable: React.FC<Props> = ({ notes, createNote, ...props }) => {
  const { t } = useTranslation()
  const cssName = css({
    flex: '1 1 auto',
    marginRight: '16px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  })
  const cssPermission = css({
    flex: '0 0 50px',
    marginRight: '16px',
  })
  const cssAuthor = css({
    display: 'flex',
    flex: '0 0 200px',
    alignItems: 'center',
    marginRight: '16px',
  })
  const cssCreatedAt = css({
    flex: '0 0 100px',
    marginRight: '16px',
  })
  const cssUpdatedAt = css({
    flex: '0 0 100px',
  })
  return (
    <section
      css={{
        width: '100%',
      }}
      {...props}
    >
      {createNote && (
        <div
          css={{ width: '100%', marginBottom: '16px', display: 'flex', justifyContent: 'flex-end' }}
        >
          <Button
            onClick={() => {
              createNote()
            }}
          >
            {t('CREATE_NOTE')}
          </Button>
        </div>
      )}
      <header
        css={{
          display: 'flex',
          alignItems: 'center',
          padding: '8px',
          paddingBottom: '16px',
          fontSize: '14px',
          fontWeight: 'bold',
          textAlign: 'left',
          color: colors.textTableHeader,
        }}
      >
        <span css={cssName}>{t('NOTE_NAME')}</span>
        <span css={cssPermission}>{t('PERMISSION')}</span>
        <span css={cssAuthor}>{t('AUTHOR')}</span>
        <span css={cssCreatedAt}>{t('CREATED_ON')}</span>
        <span css={cssUpdatedAt}>{t('UPDATED_ON')}</span>
      </header>
      {notes.map((note) => (
        <Link
          key={note.id}
          href={generateNote(note.id)}
          css={{
            display: 'flex',
            alignItems: 'center',
            padding: '8px',
            fontSize: '16px',
            lineHeight: '1.5',
            color: colors.textMain,
            transition: 'background-color linear 0.1s',
            '&:hover, &:focus': {
              backgroundColor: colors.background,
            },
          }}
        >
          <div css={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <div css={{ display: 'flex', width: '100%' }}>
              <span css={cssName}>{note.title}</span>
              <span css={cssPermission}>
                {note.permission === NotePermission.OwnerOnly ||
                note.permission === NotePermission.Private ? (
                  <Icon type="lock" />
                ) : (
                  <Icon type="lockOpen" />
                )}
              </span>
              <AvatarWithName
                css={cssAuthor}
                firstName={note.author.firstName}
                lastName={note.author.lastName}
                avatarUrl={note.author.avatar?.url}
                linkage={{ userId: note.author.id }}
                isUserDisabled={note.author.isDisabled}
              />
              <span css={cssCreatedAt}>
                <DateTime datetime={note.createdAt} withoutTime />
              </span>
              <span css={cssUpdatedAt}>
                <DateTime datetime={note.updatedAt} withoutTime />
              </span>
            </div>
            <span>
              {note.labels.map((label) => (
                <NoteLabel fontSize={10} lineHeight={14} backgroundColor={label.color}>
                  {label.name}
                </NoteLabel>
              ))}
            </span>
          </div>
        </Link>
      ))}
    </section>
  )
}

NotesTable.displayName = 'NotesTable'
