import { componentNames, featureNames, generateTestId } from '../../../../../lib/testId'
import { CheckinModalGrid } from '../layout/CheckInModalGrid'

import { FormSkeleton } from './components/FormSkeleton'
import { IndexSkeleton } from './components/IndexSkeleton'

export const CheckinModalSkeleton: React.VFC = () => (
  <CheckinModalGrid
    data-testid={generateTestId(featureNames.checkinModal, componentNames.skeleton)}
  >
    <IndexSkeleton />
    <FormSkeleton />
  </CheckinModalGrid>
)
