import { Tooltip } from '@resily/geisha'
import { useMemo, createRef, useState, forwardRef, useImperativeHandle, useCallback } from 'react'

import { useTranslation } from '../../../../../i18n'
import { componentNames, featureNames, generateTestId } from '../../../../../lib/testId'
import { Icon } from '../../../../ui/Icon'
import {
  RichTextEditor,
  Props as RichTextEditorProps,
  DEFAULT_INITIAL_VALUE_JSON_STRING,
  EditorRef,
} from '../../../../ui/RichTextEditor'
import { Tabs as TabsComponent, TabType } from '../../../../ui/Tabs'
import { TextButton } from '../../../../ui/TextButton'
import {
  CHECKIN_COMMENT_NAMES,
  CHECKIN_COMMENT_NAMES_I18N_KEY,
  CheckinCommentNames,
} from '../../constants'
import { isDefaultJsonComment } from '../../utils'

import {
  rootCss,
  editorWrapperCss,
  previousCommentsWrapperCss,
  previousCommentsBtnCss,
  commentTabsWrapperCss,
} from './styles'
import { Comments, CommentsTabValue, Tabs } from './types'
import { generateTabValue, generateTooltipMsg } from './utils'

export type Props = {
  keyResultId: string
  comments: {
    [key in keyof Comments]: {
      initialValueJSON: Comments[key]
      onChange?: RichTextEditorProps['onChange']
    }
  }
  noPreviousComments: boolean
  onClickSetPreviousComments?: () => void
}

export type CommentTabsRef = {
  setValues: (comments: Comments) => void
}

export const CommentTabs = forwardRef<CommentTabsRef, Props>(
  ({ keyResultId, comments, noPreviousComments, onClickSetPreviousComments }, ref) => {
    const { t } = useTranslation()
    const tabsName = useMemo(() => `comment-tabs-${keyResultId}`, [keyResultId])

    const tabs = useMemo<Tabs>(
      () =>
        CHECKIN_COMMENT_NAMES.map((tabName) => ({
          editorId: `${tabName}-${keyResultId}`,
          editorRef: createRef<EditorRef>(),
          tabName,
          value: generateTabValue(tabName, keyResultId),
          name: t(CHECKIN_COMMENT_NAMES_I18N_KEY[tabName]),
          rightSection:
            comments[tabName].initialValueJSON &&
            comments[tabName].initialValueJSON !== DEFAULT_INITIAL_VALUE_JSON_STRING ? (
              <Icon
                data-testid={generateTestId(featureNames.checkinModal, componentNames.icon, {
                  suffix: 'comment',
                })}
                type="comment"
              />
            ) : null,
        })),
      [comments, keyResultId, t],
    )

    const [currentTab, setCurrentTab] = useState<CommentsTabValue>(tabs[0].value)

    const isEditedComments = Object.values(comments).some(
      ({ initialValueJSON }) => !isDefaultJsonComment(initialValueJSON),
    )
    const isDisabled = isEditedComments || noPreviousComments

    useImperativeHandle(ref, () => ({
      setValues: (lastComments: Comments) => {
        Object.values(tabs).forEach(({ tabName, editorRef }) =>
          editorRef.current?.setValue(lastComments[tabName]),
        )
      },
    }))

    const focus = (editorRef: EditorRef) => editorRef.focus()

    const setPlaceholder = useCallback(
      (tabName: CheckinCommentNames): string => {
        if (tabName === 'priority') return t('PRIORITY_COMMENT_PLACEHOLDER')
        if (tabName === 'winSession') return t('WIN_SESSION_COMMENT_PLACEHOLDER')
        if (tabName === 'problem') return t('PROBLEM_COMMENT_PLACEHOLDER')
        return t('OTHER_COMMENT_PLACEHOLDER')
      },
      [t],
    )

    return (
      <div css={rootCss}>
        <div css={commentTabsWrapperCss}>
          <TabsComponent<CommentsTabValue>
            tabName={tabsName}
            tabs={tabs}
            tab={currentTab}
            onClickTab={(newTab: TabType<CommentsTabValue>) => {
              setCurrentTab(newTab.value)
              const editorRef = tabs.find((tab) => tab?.value === newTab?.value)?.editorRef?.current
              if (!editorRef) {
                return
              }
              focus(editorRef)
            }}
          />
          <div css={previousCommentsWrapperCss}>
            {isDisabled ? (
              <Tooltip
                label={<span>{generateTooltipMsg(t, isEditedComments, noPreviousComments)}</span>}
                position="bottom"
              >
                <div>
                  <PreviousCommentsBtn isDisabled={isDisabled} />
                </div>
              </Tooltip>
            ) : (
              <PreviousCommentsBtn
                isDisabled={isDisabled}
                handleOnClick={onClickSetPreviousComments}
              />
            )}
          </div>
        </div>
        {tabs.map(({ editorId, editorRef, tabName, value }) => {
          if (currentTab !== value) {
            return null
          }

          return (
            <div key={tabName} className={tabName} css={editorWrapperCss}>
              <RichTextEditor
                id={editorId}
                data-testid={generateTestId(featureNames.checkinModal, componentNames.editor, {
                  suffix: tabName,
                })}
                ref={editorRef}
                initialValueJSON={comments[tabName].initialValueJSON}
                onChange={comments[tabName].onChange}
                autoFocus={false}
                editorProps={{ placeholder: setPlaceholder(tabName) }}
              />
            </div>
          )
        })}
      </div>
    )
  },
)

type PreviousCommentsBtnProps = {
  isDisabled: boolean
  handleOnClick?: () => void
}
const PreviousCommentsBtn: React.FC<PreviousCommentsBtnProps> = ({ isDisabled, handleOnClick }) => {
  const { t } = useTranslation()

  return (
    <TextButton
      icon="sync"
      data-testid={generateTestId(featureNames.checkinModal, componentNames.button, {
        suffix: 'previous-comments',
      })}
      iconSize={16}
      disabled={isDisabled}
      css={previousCommentsBtnCss}
      onClick={handleOnClick}
      color={isDisabled ? 'text-bk-10' : 'text-bk-30'}
      hoverColor={isDisabled ? 'text-bk-10' : 'resily-orange-100'}
    >
      {t('INPUT_X', { x: t('PREVIOUS_X', { x: t('CONTENT') }) })}
    </TextButton>
  )
}
