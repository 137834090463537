import { createContext, Dispatch, SetStateAction, useContext, useState } from 'react'

import { UserFragment } from './graphql'

// organizationの情報が欲しい場合はOrganizationContextを使うこと
export type User = Omit<UserFragment, 'organization'>

export const UserContext = createContext<User | null>(null)
export const SetUserContext = createContext<Dispatch<SetStateAction<User | null>>>(() => {
  throw new Error('Should use UserContext.Provider')
})

export const useCurrentUser = (): User | null => useContext(UserContext)
export const useSetCurrentUser = (): Dispatch<SetStateAction<User | null>> =>
  useContext(SetUserContext)

export const UserContextProvider: React.FC = ({ children }) => {
  const [user, updateUser] = useState<User | null>(null)

  return (
    <SetUserContext.Provider value={updateUser}>
      <UserContext.Provider value={user}>{children}</UserContext.Provider>
    </SetUserContext.Provider>
  )
}

UserContextProvider.displayName = 'UserContextProvider'
