import { css } from '@emotion/react'
import { withProfiler } from '@sentry/react'
import { ThemeValue } from 'grommet'
import { VFC, useState, useCallback, useRef, Fragment } from 'react'

import { useTranslation } from '../../../i18n'
import { color } from '../../../styles/newColors'
import { Icon } from '../../ui/Icon'
import { Modal } from '../../ui/Modal'
import { StyledText } from '../../ui/StyledText'
import { TooltipNew } from '../../ui/TooltipNew'
import { GroupTag } from '../GroupTag'
import { UserTag } from '../UserTag'

import { GroupFragment, UserFragment } from './graphql'

const iconButtonCss = css({
  outline: 'none',
  width: '28px',
  height: '28px',
  padding: 'auto',
  ':hover': {
    backgroundColor: color('background-bk-5'),
    borderRadius: '8px',
    path: {
      stroke: color('resily-orange-100'),
    },
  },
})
const iconCss = css({
  width: '12px',
  height: '14px',
})
const inputCss = css({
  marginTop: 5,
  width: '100%',
  height: 40,
  padding: 5,
  borderBottom: `1px solid ${color('border-bk-10')}`,
  ':focus': {
    backgroundColor: color('hover-background-bk-5'),
    borderBottom: `1px solid ${color('resily-orange-100')}`,
    outline: 'none',
  },
})
const modalOverlayValue: ThemeValue = {
  layer: {
    overlay: {
      background: 'rgba(0, 0, 0, 0.3)',
    },
  },
}
const selectedBoxCss = css({
  marginTop: '28px',
})
const selectedLabelBox = css({
  marginTop: '12px',
})
const selectedGroupLabelCss = css({
  display: 'inline',
  marginBottom: '8px',
  span: {
    fontSize: '12px',
  },
})
const selectedUserLabelCss = css({
  display: 'inline-block',
  marginRight: '8px',
  marginBottom: '8px',
  span: {
    fontSize: '12px',
  },
})
const modalContentCss = css({ width: '248px' })

export type Props = {
  selectedGroups: ReadonlyArray<Pick<GroupFragment, 'id' | 'name'>>
  selectedUsers: ReadonlyArray<
    Pick<UserFragment, 'id' | 'firstName' | 'lastName' | 'avatar' | 'isDisabled'>
  >
  onAdd: (label: string) => Promise<void>
}

export const Bookmark: VFC<Props> = withProfiler(({ selectedUsers, selectedGroups, onAdd }) => {
  const { t } = useTranslation()
  const [isOpened, setIsOpened] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [canAdd, setCanAdd] = useState<boolean>(false)
  const showModal = useCallback(() => setIsOpened(true), [setIsOpened])
  const closeModal = useCallback(() => setIsOpened(false), [setIsOpened])
  const labelName = useRef<string>('')

  const add = useCallback(async () => {
    setIsLoading(true)
    await onAdd(labelName.current)
    closeModal()
    setIsLoading(false)
  }, [onAdd, closeModal])

  const onChageLabel = useCallback((event: { target: { value: string } }) => {
    labelName.current = event.target.value
    setCanAdd(labelName.current.length > 0)
  }, [])

  return (
    <Fragment>
      <TooltipNew title={t('ADD_BOOKMARK_TITLE')} css={{ display: 'inline-block' }}>
        <button type="button" css={iconButtonCss} onClick={showModal}>
          <Icon type="bookmark" color={color('text-bk-100')} css={iconCss} />
        </button>
      </TooltipNew>
      <Modal
        title={t('ADD_BOOKMARK')}
        themeValue={modalOverlayValue}
        autoWidth
        isRunning={isLoading}
        isOpened={isOpened}
        disabledExecute={!canAdd}
        executeText={t('ADD')}
        onClickOutside={closeModal}
        onClickCancel={closeModal}
        onClickExecute={add}
      >
        <div css={modalContentCss}>
          <StyledText size="small" weight="bold" color="text-bk-50">
            {t('LABEL_NAME')}
          </StyledText>
          <input css={inputCss} onChange={onChageLabel} />
          {selectedGroups.length > 0 && (
            <div css={selectedBoxCss}>
              <StyledText size="small" weight="bold" color="text-bk-50">
                {t('GROUP_TAG')}
              </StyledText>
              <div css={selectedLabelBox}>
                {selectedGroups.map((group) => (
                  <GroupTag key={group.id} {...group} css={selectedGroupLabelCss} />
                ))}
              </div>
            </div>
          )}
          {selectedUsers.length > 0 && (
            <div css={selectedBoxCss}>
              <StyledText size="small" weight="bold" color="text-bk-50">
                {t('USER')}
              </StyledText>
              <div css={selectedLabelBox}>
                {selectedUsers.map(({ id, firstName, lastName, avatar, isDisabled }) => (
                  <div css={selectedUserLabelCss}>
                    <UserTag
                      key={id}
                      id={id}
                      firstName={firstName}
                      lastName={lastName}
                      avatar={avatar}
                      maxWidth="166px"
                      isUserDisabled={isDisabled}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </Modal>
    </Fragment>
  )
})

Bookmark.displayName = 'Bookmark'
