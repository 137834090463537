import { useMemo } from 'react'

import { useCurrentUser } from '../../../../../../../contexts/UserContext'
import { KeyResultAreaKeyResultFragment } from '../../../../graphql'

export const useFilterKeyResults = (
  keyResults: ReadonlyArray<KeyResultAreaKeyResultFragment>,
): ReadonlyArray<KeyResultAreaKeyResultFragment> => {
  const currentUser = useCurrentUser()
  const isShowDisabledOkr = !!currentUser?.userSetting.showDisabledOkrs

  // 無効なOKRの表示のtrue / falseによって、KR一覧をフィルタする
  const filteredKeyResults = useMemo(() => {
    // 無効なOKRの表示がtrueの場合は、フィルタしない
    if (isShowDisabledOkr) return keyResults

    return keyResults.filter((keyResult) => !keyResult.isDisabled)
  }, [isShowDisabledOkr, keyResults])

  return filteredKeyResults
}
