import { Skeleton } from '@resily/geisha'

import { useStyles } from './ActionPlanCardSkeleton.styles'

type ActionPlanCardSkeletonProps = {
  titleRows?: number
}

export const ActionPlanCardSkeleton: React.VFC<ActionPlanCardSkeletonProps> = ({
  titleRows = 1,
}) => {
  const styles = useStyles()

  return (
    <div css={styles.root}>
      <Skeleton width={40} height={16} radius={2} css={styles.status} />
      <div css={styles.title}>
        {new Array(titleRows).fill(undefined).map(() => (
          <Skeleton width="100%" height={12} radius={12} />
        ))}
      </div>
      <div css={styles.user}>
        <Skeleton circle width={14} height={14} />
        <Skeleton width={92} height={12} radius={12} css={styles.name} />
        <Skeleton width={40} height={10} radius={12} css={styles.date} />
      </div>
    </div>
  )
}
