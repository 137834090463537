import { Skeleton } from '@resily/geisha'
import { v1 as uuid } from 'uuid'

import { rootCss, skeletonWrapperCss } from './styles'

export const IndexSkeleton: React.VFC = () => (
  <div css={rootCss}>
    {new Array(4).fill(undefined).map(() => (
      <div key={uuid()} css={skeletonWrapperCss}>
        <Skeleton circle width={6} height={6} />
        <Skeleton text width={138} height={12} />
      </div>
    ))}
  </div>
)
