import { useState } from 'react'
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  ResponderProvided,
} from 'react-beautiful-dnd'

import { newDisplayOrder, reOrderingItems } from '../../../../../../../lib/domain/okr'
import { color } from '../../../../../../../styles/newColors'

import { OkrNodesTableItemsDescription } from './OkrNodesTableItemsDescription'
import { OkrNodeFragment } from './graphql'

export type Props = {
  items: ReadonlyArray<OkrNodeFragment>
  onReOrder: (okrNodeId: OkrNodeFragment['id'], displayOrder: number) => void
}

export const DraggableOkrNodesTableDescription: React.VFC<Props> = ({ items, onReOrder }) => {
  const [state, setState] = useState<ReadonlyArray<OkrNodeFragment>>(items)

  const handleDragEnd = (result: DropResult, _: ResponderProvided) => {
    if (!result.destination) return

    const { draggableId } = result
    const source = result.source.index
    const destination = result.destination.index

    setState((prev) => {
      const reOrderingOkrNodes = reOrderingItems(prev, source, destination)
      if (!reOrderingOkrNodes) return prev

      onReOrder(draggableId, newDisplayOrder(prev, source, destination))
      return reOrderingOkrNodes
    })
  }

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <table
            ref={provided.innerRef}
            css={{
              width: '100%',
              borderCollapse: 'collapse',
              tbody: {
                tr: {
                  td: {
                    borderBottom: `1px solid ${color('border-bk-10')}`,
                  },
                },
              },
              'tr:hover': {
                backgroundColor: color('hover-background-bk-5'),
              },
            }}
          >
            <tbody>
              {state.map((item, index) => (
                <Draggable
                  key={item.id}
                  draggableId={item.id}
                  index={index}
                  css={{
                    cursor: 'auto',
                  }}
                >
                  {(p, s) => (
                    <OkrNodesTableItemsDescription
                      ref={p.innerRef}
                      okrNode={item}
                      style={{
                        backgroundColor: s.isDragging
                          ? color('hover-background-bk-5')
                          : color('white-100'),
                      }}
                      {...p.draggableProps}
                      {...p.dragHandleProps}
                    />
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </tbody>
          </table>
        )}
      </Droppable>
    </DragDropContext>
  )
}

DraggableOkrNodesTableDescription.displayName = 'DraggableOkrNodesTableDescription'
