import { createContext, useState, Dispatch, SetStateAction } from 'react'

import { OrganizationFragment } from './graphql'

export type Organization = OrganizationFragment

export const OrganizationContext = createContext<Organization | null>(null)

// Organizationのプロパティの一部を変更する場合
export const OrganizationDispatchContext = createContext<
  Dispatch<SetStateAction<Organization | null>>
>(() => {
  throw new Error('Should use OrganizationContext.Provider')
})

export const OrganizationContextProvider: React.FC = ({ children }) => {
  const [organization, setOrganization] = useState<Organization | null>(null)

  return (
    <OrganizationDispatchContext.Provider value={setOrganization}>
      <OrganizationContext.Provider value={organization}>{children}</OrganizationContext.Provider>
    </OrganizationDispatchContext.Provider>
  )
}

OrganizationContextProvider.displayName = 'OrganizationContextProvider'
