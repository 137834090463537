import { css } from '@emotion/react'
import { PropsWithoutRef, forwardRef } from 'react'

import { border } from '../../../styles/border'
import { fontSize, FontSizeAlias } from '../../../styles/font'
import { ColorAlias, color as newColor } from '../../../styles/newColors'

type Props = PropsWithoutRef<JSX.IntrinsicElements['textarea']> & {
  value?: string
  size?: FontSizeAlias
  color?: ColorAlias
  disableNewline?: boolean
  height?: number
  isError?: boolean
}

export const Textarea = forwardRef<HTMLTextAreaElement, Props>(
  (
    {
      value,
      size = 'medium',
      color = 'text-bk-100',
      disableNewline = false,
      height,
      ...props
    }: Props,
    ref: React.Ref<HTMLTextAreaElement>,
  ) => {
    const setHeight = (element: HTMLTextAreaElement) => {
      // 以下の処理は両方必要です
      element.style.height = `${fontSize(size).fontSize}px` // 文字削除した時に高さを元に戻す
      element.style.height = `${element.scrollHeight}px` // 文字削除した時に高さを元に戻した後にscrollHeightを計算し、設定する
    }

    const titleCss = css({
      width: '100%',
      borderBottom: border('simple-10'),
      '&:focus-within': {
        backgroundColor: newColor('background-bk-5'),
        borderBottom: `1px solid ${newColor('resily-orange-100')}`,
        outline: 'none',
      },
      '&:not(:has(> textarea:disabled)):hover': {
        backgroundColor: newColor('hover-background-bk-5'),
        borderBottom: `1px solid ${newColor('resily-orange-100')}`,
        outline: 'none',
      },
      height,
    })

    return (
      <div css={titleCss}>
        <textarea
          defaultValue={value}
          rows={1}
          onInput={(e) => {
            const t = e.currentTarget
            setHeight(t)
          }}
          ref={ref}
          onFocus={(e) => {
            const t = e.currentTarget
            setHeight(t)
            t.setSelectionRange(t.value.length, t.value.length)
          }}
          onKeyDown={(e) => {
            // Enterによる改行を許可しない
            if (
              disableNewline &&
              e.currentTarget != null &&
              e.code === 'Enter' &&
              !e.nativeEvent.isComposing
            ) {
              e.preventDefault()
            }
          }}
          css={{
            ...fontSize(size),
            color: newColor(color),
            padding: '10px 8px',
            width: '100%',
            outline: 'none',
            resize: 'none',
            '::placeholder': {
              color: newColor('text-bk-30'),
            },
          }}
          {...props}
        />
      </div>
    )
  },
)

Textarea.displayName = 'Textarea'
