import { Popover as GeishaPopover, PopoverProps as GeishaPopoverProps } from '@resily/geisha'

import { DropdownContent } from './DropdownContent'
import { Help } from './TargetContent'

type PopoverComposition = {
  TargetContent: {
    Help: typeof Help
  }
  DropdownContent: typeof DropdownContent
}

export type Props = GeishaPopoverProps

export const Popover: React.FC<Props> & PopoverComposition = ({
  position = 'bottom',
  width = 300,
  ...rest
}) => <GeishaPopover width={width} position={position} {...rest} />

Popover.displayName = 'Popover'
Popover.TargetContent = {
  Help,
}
Popover.DropdownContent = DropdownContent
