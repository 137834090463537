import React, { Fragment } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { useTranslation } from '../../../i18n'
import { ConfidenceLevel } from '../../../lib/confidence'
import { confidenceList } from '../../../lib/domain/confidence'
import { componentNames, featureNames, generateTestId } from '../../../lib/testId'
import { color } from '../../../styles/newColors'
import { Radio } from '../../ui/Radio'

export type Props = {
  confidence: number
  onChangeConfidence: (confidence: ConfidenceLevel) => void
}

const labelingConfidenceLevels = [-2, 0, 2]
const minWidth = 46
const radioHalfWidth = 8

export const ConfidenceList: React.VFC<Props> = ({ confidence, onChangeConfidence }) => {
  const { t } = useTranslation()

  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  const getPaddingWidth = (text: string): number => {
    if (!ctx) {
      return minWidth
    }
    ctx.font = '10px'
    const textWidth = ctx.measureText(text).width
    return Math.floor(textWidth / 2) - radioHalfWidth
  }

  const paddingLeft = getPaddingWidth(t(confidenceList[confidenceList.length - 1][1]))
  const paddingRight = getPaddingWidth(t(confidenceList[0][1]))

  return (
    <div
      css={{
        display: 'flex',
        paddingLeft,
        paddingRight,
      }}
    >
      {confidenceList
        .map(([level, label]) => (
          <Fragment key={uuidv4()}>
            <div
              css={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '15px',
              }}
            >
              <Radio
                round
                roundIconHeight="16px"
                roundIconWidth="16px"
                roundIconColor={color('text-bk-100')}
                checked={level === confidence}
                onClick={() => onChangeConfidence(level)}
                data-testid={generateTestId(
                  featureNames.quickUpdateModal,
                  componentNames.confidenceRadio,
                  {
                    suffix: level.toString(),
                  },
                )}
              />
              {labelingConfidenceLevels.includes(level) && (
                <div
                  css={{
                    width: '100px',
                    textAlign: 'center',
                    fontSize: '10px',
                    lineHeight: '14px',
                    marginTop: '5px',
                  }}
                >
                  {t(label)}
                </div>
              )}
            </div>
            {level !== 2 && (
              <div
                css={{
                  width: '32px',
                  height: '1px',
                  backgroundColor: color('border-bk-10'),
                  margin: '8px 0 0 1px',
                }}
              />
            )}
          </Fragment>
        ))
        .reverse()}
    </div>
  )
}

ConfidenceList.displayName = 'ConfidenceList'
