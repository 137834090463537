import React from 'react'

import { KeyResultWeightSettingListItem } from './KeyResultWeightSettingListItem'
import type { KeyResult } from './types'

export type Props = {
  keyResults: Array<KeyResult>
  onKeyResultWeightChange: (index: number) => (weight: number) => void
}

export const KeyResultWeightSettingList: React.VFC<Props> = ({
  keyResults,
  onKeyResultWeightChange,
}) => (
  <>
    {keyResults.map((keyResult, index) =>
      index === 0 ? (
        <div key={keyResult.id}>
          <KeyResultWeightSettingListItem
            keyResult={keyResult}
            onWeightChange={onKeyResultWeightChange(index)}
          />
        </div>
      ) : (
        <div css={{ marginTop: '20px' }} key={keyResult.id}>
          <KeyResultWeightSettingListItem
            keyResult={keyResult}
            onWeightChange={onKeyResultWeightChange(index)}
          />
        </div>
      ),
    )}
  </>
)

KeyResultWeightSettingList.displayName = 'KeyResultWeightSettingList'
