import { css } from '@emotion/react'
import { CSSProperties, MouseEventHandler, useCallback, VFC } from 'react'
import { NavLink as OriginNavLink, NavLinkProps } from 'react-router-dom'

import { color } from '../../../../styles/newColors'
import { Icon, Type as IconType } from '../../../ui/Icon'
import { StyledText } from '../../../ui/StyledText'

export const NAV_LINK_CLASS_NAME = 'nav-link'
export const NAV_LINK_TEXT_CLASS_NAME = 'nav-link-text'

const navLinkActiveStyle: CSSProperties = {
  borderLeft: `4px solid ${color('resily-orange-100')}`,
  paddingLeft: 16,
  color: color('resily-orange-100'),
}

const originNavLinkCss = css({
  display: 'flex',
  alignItems: 'center',
  gap: 20,
  paddingLeft: '20px',
  textDecoration: 'none',
  ':any-link': {
    color: color('text-bk-100'),
  },
  ':hover span, :hover svg, &.active span, &.active svg': {
    color: color('resily-orange-100'),
    'path[fill]': {
      fill: color('resily-orange-100'),
    },
    'path[stroke]': {
      stroke: color('resily-orange-100'),
    },
  },
})

export type Props = {
  icon: IconType
  title: string
  href: string
  onClick?: MouseEventHandler<HTMLAnchorElement>
  trackingEvent?: () => void
} & Omit<NavLinkProps, 'to'>

export const NavLink: VFC<Props> = ({
  icon,
  title,
  href,
  onClick,
  trackingEvent,
  end = true,
  ...props
}) => {
  const onClickCommon = useCallback<MouseEventHandler<HTMLAnchorElement>>(
    (e) => {
      if (trackingEvent) {
        trackingEvent()
      }
      if (onClick) {
        onClick(e)
      }
    },
    [onClick, trackingEvent],
  )

  return (
    <OriginNavLink
      className={NAV_LINK_CLASS_NAME}
      to={{ pathname: href }}
      end={end}
      css={originNavLinkCss}
      style={({ isActive }) => (isActive ? navLinkActiveStyle : {})}
      onClick={onClickCommon}
      {...props}
    >
      <Icon type={icon} width={16} height={16} />
      <StyledText className={NAV_LINK_TEXT_CLASS_NAME} size="large" fontStyle="regular">
        {title}
      </StyledText>
    </OriginNavLink>
  )
}

NavLink.displayName = 'NavLink'
