import React from 'react'

import { StyledText } from '../../../../../../../components/ui/StyledText'
import { useTranslation } from '../../../../../../../i18n'
import { labelRed } from '../../../../../../../styles/colors'

export type Props = {
  totalWeight: number
  hasError: boolean
}

export const KeyResultWeightSettingTotal: React.VFC<Props> = ({ totalWeight, hasError }) => {
  const { t } = useTranslation()

  const weightDiff = totalWeight - 100
  const errorMessage =
    weightDiff < 0
      ? t('WEIGHT_SHORTAGE', { weight: -weightDiff })
      : t('WEIGHT_OVER', { weight: weightDiff })

  return (
    <div css={{ display: 'flex', flexDirection: 'column' }}>
      <div css={{ display: 'flex', justifyContent: 'flex-end' }}>
        <StyledText size="large" css={{ lineHeight: '20px', marginTop: '4px' }} color="text-bk-50">
          {t('TOTAL')}：
        </StyledText>
        <StyledText
          size="large"
          css={{ lineHeight: '20px', marginTop: '4px', fontWeight: 'bold' }}
          color="text-bk-80"
        >
          {totalWeight} %
        </StyledText>
      </div>
      {hasError && (
        <div css={{ display: 'flex', justifyContent: 'flex-end' }}>
          <StyledText
            size="small"
            css={{ lineHeight: '20px', marginTop: '4px', color: labelRed, fontWeight: 'bold' }}
          >
            {errorMessage}
          </StyledText>
        </div>
      )}
    </div>
  )
}

KeyResultWeightSettingTotal.displayName = 'KeyResultWeightSettingTotal'
