import { useModal } from '@resily/geisha'
import { useCallback, useMemo, useRef, useState } from 'react'
import isEqual from 'react-fast-compare'

import { useCurrentUser } from '../../../contexts/UserContext'
import { useTranslation } from '../../../i18n'
import { findScreen } from '../../../lib/screen'
import { tracker } from '../../../lib/tracking'
import { useApiProcessing } from '../../../lib/useApiProcessing'
import { isFilledRequiredFields } from '../../domain/KeyResultEditFields/functions'
import { useKeyResultEditFields } from '../../domain/KeyResultEditFields/hooks'
import {
  ExtendedUpdateKeyResultInfoInput,
  keyResultEditTypeObj,
} from '../../domain/KeyResultEditFields/types'
import { ParentOkrBar } from '../../domain/ParentOkrBar'
import { useWeightSettingResetAlertModal } from '../WeightSettingResetAlertModal'

import { useDeleteAlertModal } from './DeleteAlertModal'
import { useDisabledAlertModal } from './DisableAlertModal'
import { Footer } from './Footer'
import { Header } from './Header'
import { KeyResultFragment, KeyResultUpdateType } from './graphql'
import { useFormValidation } from './hooks/useFormValidation'

export type Props = {
  keyResult: KeyResultFragment
  updateParentObjective: (objectiveId: string) => Promise<unknown>
  updateKeyResult: (kr: ExtendedUpdateKeyResultInfoInput | undefined) => Promise<unknown>
  toggleStatus: () => Promise<unknown>
  deleteKeyResult: () => Promise<unknown>
  isOpened: boolean
  close: () => void
  onClose: () => void
  onEdit: (isDirty: boolean) => void
}

export const KeyResultUpdateModal: React.VFC<
  Props & { modalComponent: ReturnType<typeof useModal>[0] }
> = ({
  keyResult,
  updateParentObjective,
  updateKeyResult,
  toggleStatus,
  deleteKeyResult,
  modalComponent: Modal,
  isOpened,
  close,
  onClose,
  onEdit,
}) => {
  const { t } = useTranslation()
  const user = useCurrentUser()

  const [isApiProcessing, wrapApiPromise] = useApiProcessing()

  // KR作成/編集共通コンポーネント
  const KeyResultEditFields = useKeyResultEditFields()

  // 各種AlertModal
  const [DisableAlertModalWithWeightReset, openDisableAlertModalWithWeightReset] =
    useWeightSettingResetAlertModal('DEACTIVATE')
  const [EnableAlertModalWithWeightReset, openEnableAlertModalWithWeightReset] =
    useWeightSettingResetAlertModal('ACTIVATE')
  const { DisableAlertModal, open: openDisableAlertModal } = useDisabledAlertModal()
  const { DeleteAlertModal, open: openDeleteAlertModal } = useDeleteAlertModal()

  // KeyResultFragmentをUpdateKeyResultInfoInputに詰め替える
  const initialKeyResult = useMemo<ExtendedUpdateKeyResultInfoInput>(
    () => ({
      keyResultId: keyResult.id,
      name: keyResult.name,
      description: keyResult.description
        ? {
            treeJson: keyResult.description.treeJson,
            plainText: keyResult.description?.plainText ?? '',
          }
        : undefined,
      isDescriptionChanged: false,
      ownerId: keyResult.owner.id,
      memberIds: keyResult.members.map((m) => m.id),
      targetValue:
        keyResult.updateType === KeyResultUpdateType.Manual || keyResult.isAutoAggregate
          ? 100
          : keyResult.targetValue,
      initialValue:
        keyResult.updateType === KeyResultUpdateType.Manual || keyResult.isAutoAggregate
          ? 0
          : keyResult.initialValue,
      actualValue:
        keyResult.updateType === KeyResultUpdateType.Manual || keyResult.isAutoAggregate
          ? keyResult.progressRate
          : keyResult.actualValue,
      unit:
        keyResult.updateType === KeyResultUpdateType.Manual || keyResult.isAutoAggregate
          ? '%'
          : keyResult.unit,
      confidence:
        (user && keyResult.confidences.find((val) => val.user.id === user.id)?.level) || 0,
      message: undefined,
      targetSetting: keyResult.targetSetting,
      attachmentViews: keyResult.attachments,
    }),
    [keyResult, user],
  )

  // 子要素からの変更イベントをハンドリングする処理
  const [krState, setKrState] = useState<ExtendedUpdateKeyResultInfoInput>(initialKeyResult)
  const isDirty = useRef<boolean>(false)
  const { validate, error } = useFormValidation(krState)
  const handleChangeKeyResult = useCallback(
    (kr: ExtendedUpdateKeyResultInfoInput) => {
      setKrState(kr)
      validate(kr)
      isDirty.current = !isEqual(kr, initialKeyResult)
      onEdit(isDirty.current)
    },
    [initialKeyResult, onEdit, validate],
  )

  // 無効化/有効化
  const onToggleStatus = useCallback(async () => {
    // 無効化後はモーダルを閉じる / 有効化後は閉じない
    if (!keyResult.isDisabled) {
      tracker.UserClickDisableKeyResultByKeyResultUpdateModal(findScreen(window.location.pathname))
      close()
    } else {
      tracker.UserClickEnableKeyResultByKeyResultUpdateModal(findScreen(window.location.pathname))
    }
    await wrapApiPromise(toggleStatus())
  }, [close, keyResult.isDisabled, toggleStatus, wrapApiPromise])

  // 無効化/有効化の切り替えボタン押下時
  const onClickToggleStatusButton = useCallback(() => {
    // 重み付け設定のあるOKRの有効化
    if (keyResult.isDisabled && keyResult.objective.useWeighting) {
      openEnableAlertModalWithWeightReset()
    }
    // 重み付け設定のないOKRの有効化
    if (keyResult.isDisabled && !keyResult.objective.useWeighting) {
      onToggleStatus()
    }
    // 重み付け設定のあるOKRの無効化
    if (!keyResult.isDisabled && keyResult.objective.useWeighting) {
      openDisableAlertModalWithWeightReset()
    }
    // 重み付け設定のないOKRの無効化
    if (!keyResult.isDisabled && !keyResult.objective.useWeighting) {
      openDisableAlertModal()
    }
  }, [
    keyResult.isDisabled,
    onToggleStatus,
    openDisableAlertModal,
    openDisableAlertModalWithWeightReset,
    openEnableAlertModalWithWeightReset,
    keyResult.objective.useWeighting,
  ])

  // KR削除
  const onClickDeleteAlertModalConfirmButton = useCallback(() => {
    tracker.UserClickDeleteKeyResultByKeyResultUpdateModal(findScreen(window.location.pathname))
    wrapApiPromise(deleteKeyResult()).then(close)
  }, [close, deleteKeyResult, wrapApiPromise])

  // KR更新
  const onClickConfirmButton = useCallback(() => {
    tracker.UserClickUpdateKeyResultByKeyResultUpdateModal(findScreen(window.location.pathname))
    if (!isDirty.current) {
      // 初期値ならmutation発行せず閉じる
      close()
      return
    }

    if (!validate(krState)) {
      return
    }
    wrapApiPromise(updateKeyResult(krState)).then(close)
  }, [close, krState, updateKeyResult, validate, wrapApiPromise])

  return (
    <Modal
      size="large"
      contentMargin={false}
      // NOTE: 非geishaのモーダルを上に重ねて表示すると、その内部のinput等にfocusが当たらない問題の対策
      // geishaモーダルへの置き換えが完了した場合は不要
      restrictFocus={false}
      isOpen={isOpened}
      onClose={useCallback(() => {
        tracker.UserClickCloseKeyResultUpdateModal(findScreen(window.location.pathname))
        if (isApiProcessing) return
        onClose()
      }, [isApiProcessing, onClose])}
    >
      <Modal.Header title={<Header />} />
      <Modal.Content>
        <ParentOkrBar
          currentObjective={keyResult.objective}
          updateParentObjective={useCallback(
            (objectiveId) => wrapApiPromise(updateParentObjective(objectiveId)),
            [updateParentObjective, wrapApiPromise],
          )}
        />
        {initialKeyResult != null && (
          <KeyResultEditFields
            type={keyResultEditTypeObj.update}
            initialKeyResult={initialKeyResult}
            error={error}
            onChangeKeyResult={handleChangeKeyResult}
          />
        )}
      </Modal.Content>
      <Modal.Footer
        confirmLabel={t('SAVE')}
        confirmDisabled={
          isApiProcessing ||
          !isFilledRequiredFields(krState, keyResult.objective.id) ||
          Object.values(error).some((e) => e)
        }
        onConfirm={onClickConfirmButton}
      >
        <Footer
          isDisabledKr={keyResult.isDisabled}
          disabledButton={isApiProcessing}
          onClickToggleStatusButton={onClickToggleStatusButton}
          onClickDeleteButton={openDeleteAlertModal}
        />
      </Modal.Footer>
      {keyResult.objective.useWeighting ? (
        <>
          <DisableAlertModalWithWeightReset onConfirm={onToggleStatus} />
          <EnableAlertModalWithWeightReset onConfirm={onToggleStatus} />
        </>
      ) : (
        <DisableAlertModal onConfirm={onToggleStatus} />
      )}
      <DeleteAlertModal onConfirm={onClickDeleteAlertModalConfirmButton} />
    </Modal>
  )
}
