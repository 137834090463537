import {
  AvatarWithName as OrigAvatarWithName,
  Props as AvatarWithNameProps,
} from '../../../../ui/AvatarWithName'
import { CommonUserFragment } from '../../graphql'

import { useStyles } from './index.styles'

export type Props = Pick<AvatarWithNameProps, 'size' | 'nameFontSize' | 'linkTextMaxWidth'> & {
  user: CommonUserFragment
  termId: string
}

export const AvatarWithName: React.VFC<Props> = ({
  user,
  termId,
  size,
  nameFontSize,
  linkTextMaxWidth = '200px',
}) => (
  <div css={useStyles().root}>
    <OrigAvatarWithName
      size={size}
      nameFontSize={nameFontSize}
      nameFontStyle="regular"
      firstName={user.firstName}
      lastName={user.lastName}
      avatarUrl={user.avatar?.url}
      isUserDisabled={user.isDisabled}
      linkage={{ termId, userId: user.id, method: 'newTab' }}
      linkTextMaxWidth={linkTextMaxWidth}
    />
  </div>
)
AvatarWithName.displayName = 'AvatarWithName'
