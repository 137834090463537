import { Tooltip } from '@resily/geisha'
import { ThemeContext } from 'grommet'

import { useTranslation } from '../../../../../../i18n'
import { findScreen } from '../../../../../../lib/screen'
import { color } from '../../../../../../styles/newColors'
import { GroupTags } from '../../../../../domain/GroupTags'
import { Icon } from '../../../../../ui/Icon'
import { IconButton } from '../../../../../ui/IconButton'
import { ProgressRate } from '../../../../../ui/Meter'
import { StyledText } from '../../../../../ui/StyledText'
import { useOkrUpdateModal, Props as OkrUpdateModalProps } from '../../../../OkrUpdateModal'
import { AvatarWithName } from '../../../Common/AvatarWithName'
import { useStyles } from '../../../KeyResultDrawer/KeyResultDescription/HeaderArea/index.styles'
import { ObjectiveRightSectionOkrBackboneFragment } from '../../../graphql'
import { LoadableProps } from '../../../types'

import { HeaderAreaSkeleton } from './HeaderAreaSkeleton'

export type DataProps = Pick<ObjectiveRightSectionOkrBackboneFragment, 'objective' | 'groups'>

export type Props = LoadableProps<DataProps> & Required<OkrUpdateModalProps>

export const HeaderArea: React.VFC<Props> = ({
  loading,
  objective,
  groups,
  onDelete,
  onDisable,
}) => {
  const { t } = useTranslation()
  const styles = useStyles()

  const { OkrUpdateModal, openOkrUpdateModal } = useOkrUpdateModal({ onDelete, onDisable })

  return (
    <>
      {loading ? (
        <HeaderAreaSkeleton />
      ) : (
        <ThemeContext.Extend value={{ global: { drop: { zIndex: '201' } } }}>
          <div css={styles.root}>
            <div css={styles.titleArea}>
              <StyledText size="small" fontStyle="bold" isWrap numberOfOmittedLines="unset">
                {objective.name}
              </StyledText>
              <Tooltip label={t('EDIT_X', { x: t('OBJECTIVE') })}>
                <IconButton
                  border={false}
                  size={20}
                  css={styles.krSettings}
                  onClick={() => {
                    openOkrUpdateModal(
                      objective.id,
                      findScreen(window.location.pathname, window.location.search),
                    )
                  }}
                >
                  <Icon type="setting" width={20} height={20} />
                </IconButton>
              </Tooltip>
            </div>
            <div css={styles.detailArea}>
              <div css={styles.userArea}>
                <div css={styles.owner}>
                  <StyledText
                    size="xsmall"
                    fontStyle="regular"
                    lineHeight="14px"
                    color="text-bk-50"
                  >
                    {t('OWNER')}
                  </StyledText>
                  <AvatarWithName
                    size="xsmall"
                    nameFontSize="xsmall"
                    user={objective.owner}
                    termId={objective.node.term.id}
                  />
                </div>
                <div css={styles.progressRateArea}>
                  <StyledText size="xsmall" fontStyle="regular" color="text-bk-50">
                    {t('PROGRESS')}
                  </StyledText>
                  <ProgressRate
                    color={color('objective-blue-100')}
                    rate={objective.progressRate ?? 0}
                  />
                </div>
              </div>
              <GroupTags groups={groups} />
            </div>
          </div>
        </ThemeContext.Extend>
      )}
      {OkrUpdateModal}
    </>
  )
}
