import { Box } from 'grommet'
import { useEffect, useMemo, useState } from 'react'

import { getAncestors, walk } from '../../../lib/domain/group'
import { color } from '../../../styles/newColors'
import { StyledText } from '../../ui/StyledText'
import { TooltipNew } from '../../ui/TooltipNew'

import { BorderlessCheckBox } from './BorderlessCheckBox'
import {
  OPTION_ROOT_CLASSNAME,
  OPTION_CONTAINER_CLASSNAME,
  OPTION_CONTAINER_FOCUS_CLASSNAME,
  OPTION_CLASSNAME,
} from './hooks'
import { GroupNode } from './types'

type SearchGroup = {
  id: string
  name: string
  selected: boolean
  ancestorsName: string | null
}

export type Props = {
  groups: ReadonlyArray<GroupNode>
  selectedIds: ReadonlyArray<string>
  searchText: string
  onClickOption: (id: string) => void
}

export const SearchGroupTree: React.FC<Props> = ({
  groups,
  selectedIds,
  searchText,
  onClickOption,
}) => {
  const result = useMemo<ReadonlyArray<SearchGroup>>(() => {
    const hits: Array<GroupNode> = []
    walk(groups, (g) => {
      if (g.name.toLowerCase().includes(searchText.trim().toLowerCase())) {
        hits.push(g)
      }
    })

    return hits.map<SearchGroup>((g) => {
      const ancestorsName = getAncestors(g)
        .map((a) => a.name)
        .join(' / ')
      return {
        id: g.id,
        name: g.name,
        selected: selectedIds.some((id) => id === g.id),
        ancestorsName: ancestorsName !== '' ? ancestorsName : null,
      }
    })
  }, [groups, searchText, selectedIds])

  return (
    <ul>
      {result.map((g) => (
        <SearchGroupTreeRow key={g.id} group={g} onClick={onClickOption} />
      ))}
    </ul>
  )
}

SearchGroupTree.displayName = 'SearchGroupTree'

type SearchGroupTreeRowProps = {
  group: SearchGroup
  onClick: (id: string) => void
}

const SearchGroupTreeRow: React.FC<SearchGroupTreeRowProps> = ({ group, onClick }) => {
  const [checked, setChecked] = useState<boolean>(false)
  const [isHover, setIsHover] = useState<boolean>(false)

  useEffect(() => setChecked(group.selected), [group.selected])

  return (
    <li className={OPTION_ROOT_CLASSNAME}>
      <TooltipNew title={group.name} dropProps={{ stretch: false, plain: true }}>
        <Box
          direction="column"
          background={{
            // eslint-disable-next-line no-nested-ternary
            color: checked
              ? color('border-bk-10')
              : isHover
              ? color('hover-background-bk-5')
              : undefined,
          }}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
          className={OPTION_CONTAINER_CLASSNAME}
          css={{
            [`&.${OPTION_CONTAINER_FOCUS_CLASSNAME}`]: {
              backgroundColor: color('hover-background-bk-5'),
            },
          }}
        >
          <Box flex direction="row" pad={{ horizontal: '12px' }}>
            <BorderlessCheckBox
              className={OPTION_CLASSNAME}
              css={{ marginTop: '6px' }}
              defaultChecked={group.selected}
              isRowHover={isHover}
              onClick={() => onClick(group.id)}
            />
            <Box flex margin={{ left: '12px', top: '10px', bottom: '6px' }} justify="center">
              <StyledText
                css={{
                  lineHeight: '20px',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
                color="text-bk-100"
              >
                {group.name}
              </StyledText>
            </Box>
          </Box>
          {group.ancestorsName ? (
            <StyledText
              css={{
                display: 'flex',
                alignItems: 'center',
                marginLeft: '40px',
                marginRight: '12px',
                marginBottom: '4px',
                minHeight: '28px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
              color="text-bk-50"
              size="xsmall"
            >
              {group.ancestorsName}
            </StyledText>
          ) : null}
        </Box>
      </TooltipNew>
    </li>
  )
}

SearchGroupTreeRow.displayName = 'SearchGroupTreeRow'
