import { PropsWithoutRef } from 'react'

import * as colors from '../../../styles/colors'
import { Icon, Type as IconType } from '../Icon'

export type Props = PropsWithoutRef<JSX.IntrinsicElements['span']> & {
  icon?: IconType
  foregroundColor?: string
  backgroundColor?: string
  fontSize?: number
  truncate?: boolean
}

export const Tag: React.FC<Props> = ({
  icon,
  foregroundColor = colors.textMain,
  backgroundColor = colors.border,
  fontSize = 12,
  truncate = false,
  children,
  ...props
}) => (
  <span
    css={[
      {
        backgroundColor,
        padding: 4,
        borderRadius: 2,
        alignItems: 'center',
      },
      truncate
        ? {
            display: 'inline-block',
            textOverflow: 'ellipsis',
            width: '100%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            color: `${foregroundColor}`,
          }
        : {
            display: 'inline-flex',
          },
    ]}
    {...props}
  >
    {icon ? (
      <Icon
        type={icon}
        color={foregroundColor}
        css={{
          width: 16,
          height: 16,
          marginRight: 4,
          color: foregroundColor,
        }}
      />
    ) : null}
    <span
      css={{
        color: foregroundColor,
        lineHeight: '12px',
        fontSize,
      }}
    >
      {children}
    </span>
  </span>
)

Tag.displayName = 'Tag'
