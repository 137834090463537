import { CSSProperties } from 'react'

export type TextStyle = Pick<CSSProperties, 'fontSize' | 'lineHeight'> & {
  // FIXME: Pick<CSSProperties, 'fontWeight'>するとemotionの型と合わずビルドエラーになってしまう
  //        一旦unknown型にしたが本来は上記のようなコードで型定義を参照したい
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fontWeight?: any
}

export type FontSizeAlias =
  | 'xxsmall'
  | 'xsmall'
  | 'small'
  | 'medium'
  | 'large'
  | 'xlarge'
  | 'xxlarge'

export type FontStyleAlias = 'narrow' | 'regular' | 'bold'

// regular
export const regularFontStyle: { [key in FontSizeAlias]: TextStyle } = {
  xxsmall: { fontSize: 8, lineHeight: '12px' },
  xsmall: { fontSize: 10, lineHeight: '14px' },
  small: { fontSize: 12, lineHeight: '20px' },
  medium: { fontSize: 14, lineHeight: '22px' },
  large: { fontSize: 16, lineHeight: '24px' },
  xlarge: { fontSize: 24, lineHeight: '32px' },
  xxlarge: { fontSize: 28, lineHeight: '36px' },
}
// bold
// OKRタイトルなど
export const boldFontStyle: { [key in FontSizeAlias]: TextStyle } = {
  xxsmall: { fontSize: 8, lineHeight: '12px', fontWeight: 'bold' },
  xsmall: { fontSize: 10, lineHeight: '14px', fontWeight: 'bold' },
  small: { fontSize: 12, lineHeight: '20px', fontWeight: 'bold' },
  medium: { fontSize: 14, lineHeight: '22px', fontWeight: 'bold' },
  large: { fontSize: 16, lineHeight: '24px', fontWeight: 'bold' },
  xlarge: { fontSize: 24, lineHeight: '32px', fontWeight: 'bold' },
  xxlarge: { fontSize: 28, lineHeight: '36px', fontWeight: 'bold' },
}
// narrow (default)
// ツリービューなど罫線で囲われているときに
// 利用する
export const narrowFontStyle: { [key in FontSizeAlias]: TextStyle } = {
  xxsmall: { fontSize: 8, lineHeight: '12px' },
  xsmall: { fontSize: 10, lineHeight: '14px' },
  small: { fontSize: 12, lineHeight: '16px' },
  medium: { fontSize: 14, lineHeight: '19px' },
  large: { fontSize: 16, lineHeight: '22px' },
  xlarge: { fontSize: 20, lineHeight: '28px' },
  xxlarge: { fontSize: 24, lineHeight: '28px' },
}

export const fontSize = (size: FontSizeAlias, style?: FontStyleAlias): TextStyle => {
  if (style === 'regular') {
    return regularFontStyle[size]
  }
  if (style === 'bold') {
    return boldFontStyle[size]
  }
  return narrowFontStyle[size]
}
