import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Grid, Form } from 'grommet'
import * as React from 'react'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import * as Yup from 'yup'

import { PageContent } from '../../components/pageContent'
import { Button } from '../../components/ui/Button'
import { FormField } from '../../components/ui/FormField'
// import { GoogleLoginButton } from '../../ui/GoogleLoginButton'
import { Input } from '../../components/ui/Input'
import { Link } from '../../components/ui/Link'
import { Logo } from '../../components/ui/Logo'
import { useTranslation } from '../../i18n'
import { client } from '../../lib/client'
import { Screen } from '../../lib/screen'
import { useTracking } from '../../lib/tracking'
import * as colors from '../../styles/colors'
import { color } from '../../styles/newColors'
import { config, root as rootUrl, ExternalUrls, passwordReset } from '../../urls'

type LoginResponse = {
  RequiredTwoFactorLogin?: boolean
  SAMLOrganizationID: string
  IDProviderURL?: string
}

type Login2FAResponse = Record<string, unknown>

export const SignInContainer: React.FC = () => {
  const login = async (
    values: {
      email: string
      password: string
    },
    onSuccess: (required2FA?: boolean, idProviderURL?: string, organizationId?: string) => void,
  ) => {
    try {
      const res = await client.post<LoginResponse>(
        '/login',
        {
          email: values.email,
          password: values.password,
        },
        {
          error: true,
        },
      )
      onSuccess(res?.RequiredTwoFactorLogin, res?.IDProviderURL, res?.SAMLOrganizationID)
    } catch (e) {
      // nothing to do
    }
  }

  const check2FA = async (
    values: {
      code: string
      email: string
      password: string
    },
    onSuccess: (passed2FA: boolean) => void,
  ) => {
    try {
      await client.post<Login2FAResponse>(
        '/login/two_factor',
        {
          email: values.email,
          password: values.password,
          code: values.code,
        },
        {
          error: true,
        },
      )
      onSuccess(true)
    } catch (err) {
      onSuccess(false)
    }
  }

  return <SignIn login={login} check2FA={check2FA} />
}

SignInContainer.displayName = 'SignInContainer'

type SignInFormValue = {
  email: string
  password: string
}

export type SignInFormProps = {
  onSubmit: SubmitHandler<SignInFormValue>
}

export const SignInForm: React.FC<SignInFormProps> = ({ onSubmit }) => {
  const { t } = useTranslation()
  const {
    handleSubmit,
    formState: { errors },
    control,
    register,
  } = useForm<SignInFormValue>({
    resolver: yupResolver(
      Yup.object().shape({
        email: Yup.string().required(t('REQUIRED_ERROR')).email(t('INVALID_EMAIL_FORMAT')),
        password: Yup.string().required(t('REQUIRED_ERROR')),
      }),
    ),
  })

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="email"
        render={() => (
          <Input
            {...register('email')}
            type="email"
            autoComplete="username"
            fontSize="medium"
            fieldStyle="bottomLine"
            placeholder={t('EMAIL')}
            css={{ padding: '0 8px', '::placeholder': { color: color('text-bk-30') } }}
            width="100%"
          />
        )}
        control={control}
        defaultValue=""
      />
      <span
        css={{
          color: colors.labelRed,
          display: 'inline-block',
          fontSize: '14px',
          lineHeight: '20px',
          marginTop: '4px',
          marginBottom: '12px',
          opacity: errors.email ? 1 : 0,
        }}
      >
        {`* ${errors.email?.message}`}
      </span>
      <Controller
        name="password"
        render={() => (
          <Input
            {...register('password')}
            type="password"
            autoComplete="current-password"
            fontSize="medium"
            fieldStyle="bottomLine"
            placeholder={t('PASSWORD')}
            css={{ padding: '0 8px', '::placeholder': { color: color('text-bk-30') } }}
            width="100%"
          />
        )}
        control={control}
        defaultValue=""
      />
      <span
        css={{
          color: colors.labelRed,
          display: 'inline-block',
          fontSize: '14px',
          lineHeight: '20px',
          marginTop: '4px',
          marginBottom: '12px',
          opacity: errors.password ? 1 : 0,
        }}
      >
        {`* ${errors.password?.message}`}
      </span>
      <Button
        type="submit"
        css={{
          backgroundColor: color('resily-orange-100'),
          display: 'block',
          height: '40px',
          lineHeight: '14px',
          width: '361px',
        }}
      >
        {t('LOGIN')}
      </Button>
    </Form>
  )
}

SignInForm.displayName = 'SignInForm'

type CodeFormValue = {
  code: string
}

export type CodeFormProps = {
  onSubmit: (values: CodeFormValue) => void
}

export const CodeForm: React.FC<CodeFormProps> = ({ onSubmit }) => {
  const { t } = useTranslation()
  const {
    handleSubmit,
    formState: { errors },
    control,
    register,
  } = useForm<CodeFormValue>({
    resolver: yupResolver(
      Yup.object().shape({
        code: Yup.string().required(t('REQUIRED_ERROR')),
      }),
    ),
  })

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box margin={{ bottom: 'medium', top: 'medium' }}>{t('TWO_FA_LOGIN_INSTRUCTION')}</Box>
        <Grid columns={['auto', 'flex']} margin="medium">
          <FormField
            errorMessage={errors.code ? `${errors.code.message}` : ''}
            css={{ display: 'inline-block', minWidth: '100px', marginRight: '8px' }}
          >
            {t('TWO_FA_CODE')}
          </FormField>
          <Controller
            name="code"
            render={() => <Input {...register('code')} type="text" autoFocus />}
            control={control}
            defaultValue=""
          />
        </Grid>
        <Box align="center" margin={{ top: 'medium' }}>
          <Button type="submit" css={{ backgroundColor: '#15C97D' }}>
            {t('SIGN_IN')}
          </Button>
        </Box>
      </form>
    </Box>
  )
}

CodeForm.displayName = 'CodeForm'

export type SignInProps = {
  login: (
    values: {
      email: string
      password: string
    },
    onSuccess: (required2FA?: boolean) => void,
  ) => void
  check2FA: (
    values: {
      email: string
      password: string
      code: string
    },
    on2FAResponse: (passed: boolean) => void,
  ) => void
}

const goNext = () => {
  const param = new URLSearchParams(window.location.search)
  const from = param.get('from')
  if (from) {
    window.location.href = decodeURIComponent(from)
  } else {
    window.location.href = rootUrl
  }
}

const { useState } = React

export const SignIn: React.FC<SignInProps> = ({ login, check2FA }) => {
  const { t } = useTranslation()
  useTracking(t('SIGN_IN_PAGE_TITLE'), Screen.SignIn)
  const [signInValue, setSignInValue] = useState<SignInFormValue>({ email: '', password: '' })
  const [require2FA, setRequire2FA] = useState<boolean>(false)

  const onSuccess = (required2FA?: boolean, idProviderURL?: string, organizationId?: string) => {
    if (idProviderURL) {
      window.location.href = idProviderURL
      return
    }
    if (required2FA) {
      setRequire2FA(true)
    } else if (organizationId) {
      // ここには、API Gatewayが稼働した時のみ入ってきます。
      window.location.href = `${config.base}/saml/redirect/${organizationId}`
    } else {
      goNext()
    }
  }

  const on2FAResponse = (passed: boolean) => {
    if (passed) {
      goNext()
    }
  }

  const onSignInSubmit: SubmitHandler<SignInFormValue> = (values): void => {
    setSignInValue(values)
    login(values, onSuccess)
  }

  const on2FASubmit = (values: CodeFormValue): void => {
    check2FA({ ...signInValue, ...values }, on2FAResponse)
  }

  return (
    <PageContent
      breadcrumbs={undefined}
      layout={{
        height: '100%',
        align: 'center',
        justify: 'center',
      }}
      contentBackgroundColor={color('background-bk-5')}
    >
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '520px',
          height: '480px',
          borderRadius: '16px',
          boxShadow: '0 8px 16px rgb(34 41 67 / 8%)',
          padding: '64px 80px 62px',
          backgroundColor: color('white-100'),
        }}
      >
        <Logo width="154px" height="44px" style={{ marginBottom: '54px' }} />
        {require2FA ? (
          <CodeForm onSubmit={on2FASubmit} />
        ) : (
          <SignInForm onSubmit={onSignInSubmit} />
        )}
        {!require2FA && (
          <React.Fragment>
            <div
              css={{
                marginTop: '48px',
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
              }}
            >
              <Link
                css={{
                  color: color('resily-orange-100'),
                  fontSize: '14px',
                  lineHeight: '20px',
                  textDecorationLine: 'underline',
                }}
                href={passwordReset}
              >
                {t('FORGOT_PASSWORD')}
              </Link>
              <div css={{ lineHeight: '20px' }}>
                <a
                  css={{
                    color: color('text-bk-50'),
                    textDecoration: 'none',
                    fontSize: '12px',
                  }}
                  href={ExternalUrls.HELP_PAGE}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {t('HELP')}
                </a>
                <a
                  css={{
                    color: color('text-bk-50'),
                    textDecoration: 'none',
                    fontSize: '12px',
                    marginLeft: '16px',
                  }}
                  href={ExternalUrls.INQUIRY_PAGE}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {t('CONTACT_US')}
                </a>
              </div>
            </div>
            {/*
            <div
              css={{
                paddingTop: '48px',
              }}
            >
              <GoogleLoginButton
                onClick={() => {
                  window.location.href = loginWithGoogle
                }}
              />
            </div>
            */}
          </React.Fragment>
        )}
      </div>
    </PageContent>
  )
}

SignIn.displayName = 'SignIn'
