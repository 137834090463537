/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-eval */
/* eslint-disable no-useless-escape */
/* eslint-disable no-control-regex */

/**
 * This file is clone Public Domain.
 * @see https://github.com/douglascrockford/JSON-js/blob/master/cycle.js
 */

// Make a deep copy of an object or array, assuring that there is at most
// one instance of each object or array in the resulting structure. The
// duplicate references (which might be forming cycles) are replaced with
// an object of the form

//      {"$ref": PATH}

// where the PATH is a JSONPath string that locates the first occurance.

// So,

//      var a = [];
//      a[0] = a;
//      return JSON.stringify(JSON.decycle(a));

// produces the string '[{"$ref":"$"}]'.

// If a replacer function is provided, then it will be called for each value.
// A replacer function receives a value and returns a replacement value.

// JSONPath is used to locate the unique object. $ indicates the top level of
// the object or array. [NUMBER] or [STRING] indicates a child element or
// property.

export type JSONPath = string

export const decycle = (object: unknown, replacer?: (o: unknown) => unknown): unknown => {
  const objects = new WeakMap<object, JSONPath>() // object to path mappings

  // The derez function recurses through the object, producing the deep copy.
  const derez = (value: unknown, path: JSONPath): unknown => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    let old_path: JSONPath | undefined // The path of an earlier occurance of value
    let nu: Record<string | number, unknown> // The new object or array

    // If a replacer function was provided, then call it to get a replacement value.
    if (replacer !== undefined) {
      // eslint-disable-next-line no-param-reassign
      value = replacer(value)
    }

    // typeof null === "object", so go on if this value is really an object but not
    // one of the weird builtin objects.

    if (
      typeof value === 'object' &&
      value !== null &&
      !(value instanceof Boolean) &&
      !(value instanceof Date) &&
      !(value instanceof Number) &&
      !(value instanceof RegExp) &&
      !(value instanceof String)
    ) {
      // If the value is an object or array, look to see if we have already
      // encountered it. If so, return a {"$ref":PATH} object. This uses an
      // ES6 WeakMap.

      old_path = objects.get(value)
      if (old_path !== undefined) {
        return { $ref: old_path }
      }

      // Otherwise, accumulate the unique value and its path.
      objects.set(value, path)

      if (Array.isArray(value)) {
        // If it is an array, replicate the array.
        // @ts-expect-error
        nu = []
        value.forEach((element, i) => {
          nu[i] = derez(element, `${path}[${i}]`)
        })
      } else {
        // If it is an object, replicate the object.
        nu = {}
        Object.keys(value).forEach((name) => {
          // @ts-expect-error
          nu[name] = derez(value[name], `${path}[${JSON.stringify(name)}]`)
        })
      }
      return nu
    }
    return value
  }

  return derez(object, '$')
}

// Restore an object that was reduced by decycle. Members whose values are
// objects of the form
//      {$ref: PATH}
// are replaced with references to the value found by the PATH. This will
// restore cycles. The object will be mutated.

// The eval function is used to locate the values described by a PATH. The
// root object is kept in a $ variable. A regular expression is used to
// assure that the PATH is extremely well formed. The regexp contains nested
// * quantifiers. That has been known to have extremely bad performance
// problems on some browsers for very long strings. A PATH is expected to be
// reasonably short. A PATH is allowed to belong to a very restricted subset of
// Goessner's JSONPath.

// So,
//      var s = '[{"$ref":"$"}]';
//      return JSON.retrocycle(JSON.parse(s));
// produces an array containing a single element which is the array itself.
export const retrocycle = ($: unknown): unknown => {
  const px = /^\$(?:\[(?:\d+|"(?:[^\\"\u0000-\u001f]|\\(?:[\\"\/bfnrt]|u[0-9a-zA-Z]{4}))*")\])*$/

  // The rez function walks recursively through the object looking for $ref
  // properties. When it finds one that has a value that is a path, then it
  // replaces the $ref object with a reference to the value that is found by
  // the path.
  const rez = (value: unknown): void => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (value && typeof value === 'object') {
      if (Array.isArray(value)) {
        value.forEach((element, i) => {
          if (typeof element === 'object' && element !== null) {
            const path: unknown | JSONPath = element.$ref
            if (typeof path === 'string' && px.test(path)) {
              value[i] = eval(path)
            } else {
              rez(element)
            }
          }
        })
      } else {
        Object.keys(value).forEach((name) => {
          // @ts-expect-error
          const item = value[name]
          if (typeof item === 'object' && item !== null) {
            const path: unknown | JSONPath = item.$ref
            if (typeof path === 'string' && px.test(path)) {
              // @ts-expect-error
              value[name] = eval(path)
            } else {
              rez(item)
            }
          }
        })
      }
    }
  }

  rez($)
  return $
}
