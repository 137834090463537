import dayjs from 'dayjs'

import { TermFragment } from '../../../api/graphql'

export const isInOtherTerms = (
  targetDate: Date,
  otherTerms: ReadonlyArray<TermFragment>,
): ReadonlyArray<TermFragment> | undefined =>
  otherTerms.filter((otherTerm) =>
    dayjs(new Date(targetDate)).isBetween(
      new Date(otherTerm.startDate),
      new Date(otherTerm.endDate),
      'day',
      '[]',
    ),
  )

export const isConveredOtherTerms = (
  startDate: Date,
  endDate: Date,
  otherTerms: ReadonlyArray<TermFragment>,
): ReadonlyArray<TermFragment> | undefined =>
  otherTerms.filter(
    (otherTerm) =>
      dayjs(new Date(otherTerm.startDate)).isBetween(
        new Date(startDate),
        new Date(endDate),
        'day',
        '()',
      ) ||
      dayjs(new Date(otherTerm.endDate)).isBetween(
        new Date(startDate),
        new Date(endDate),
        'day',
        '()',
      ),
  )
