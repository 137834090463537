import { css } from '@emotion/react'

export const adminOneOnOneContainerCss = css({
  width: '100%',
  padding: '48px 36px',
})

export const contentsWrapperCss = css({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  marginTop: '24px',
})
