import { useModal } from '@resily/geisha'
import { useCallback, useMemo } from 'react'

import {
  useOkrRefetchQueries,
  useOkrRefetchQueriesWithOkrModalInitQuery,
} from '../../../lib/domain/okr'

import { OkrUpdateModal, Props as OkrUpdateModalProps } from './OkrUpdateModal'
import {
  UpdateOkrNodeInput,
  useDeleteOkrNodeMutation,
  useObjectiveQuery,
  useUpdateObjectiveActivateStatusMutation,
  useUpdateOkrNodeMutation,
} from './graphql'

export type Props = Pick<OkrUpdateModalProps, 'isOpened' | 'close' | 'onClose' | 'onEdit'> & {
  objectiveId: string
  onDelete?: () => void
  onDisable?: () => void
}

export const OkrUpdateModalContainer: React.VFC<
  Props & { modalComponent: ReturnType<typeof useModal>[0] }
> = ({ modalComponent: Modal, objectiveId, onDelete, onDisable, ...rest }) => {
  const { data } = useObjectiveQuery({ variables: { objectiveId } })

  const defaultValueForOkrUpdate = useMemo<OkrUpdateModalProps['defaultValue'] | undefined>(() => {
    if (!data) return undefined

    return {
      name: data.objective.name,
      parentOkrNode: data.objective.node.parentNode ?? undefined,
      owner: data.objective.owner ?? undefined,
      selectedGroups: data.objective.node.groups,
      description: {
        plainText: data.objective.description?.plainText ?? '',
        body: data.objective.description?.treeJson,
      },
      parentOkrNodeId: data.objective.node.parentNode?.id,
      attachmentViews: data.objective.attachments,
      isDescriptionChanged: !!data.objective.description?.plainText,
      parentKeyResultIds: data.objective.parentKeyResults.map(({ id }) => id),
      slackChannelIds: data.objective.node.slackIntegratedChannelList.map(({ id }) => id),
      chatworkApiToken: data.objective.node.chatworkApiToken ?? undefined,
      chatworkRoomId: data.objective.node.chatworkRoomId ?? undefined,
      teamsWebhookURL: data.objective.node.teamsWebhookUrl ?? undefined,
    }
  }, [data])

  const [updateOkrNode] = useUpdateOkrNodeMutation({
    refetchQueries: useOkrRefetchQueriesWithOkrModalInitQuery(),
  })
  const confirmObjectiveEdit = useCallback(
    (input: UpdateOkrNodeInput) => updateOkrNode({ variables: { input } }),
    [updateOkrNode],
  )

  const [updateObjectiveActivateStatusMutation] = useUpdateObjectiveActivateStatusMutation({
    variables: { objectiveId },
    onCompleted: (result) => {
      const newNode = result.updateObjectiveActivateStatus.find(
        (n) => n.objective.id === objectiveId,
      )
      if (newNode?.objective.isDisabled) {
        onDisable?.()
      }
    },
  })

  const [deleteOkrNodeMutation] = useDeleteOkrNodeMutation({
    refetchQueries: useOkrRefetchQueries(),
  })

  const onClickOkrDeleteButton = useCallback(async () => {
    if (data?.objective.node.id == null) return
    await deleteOkrNodeMutation({ variables: { id: data.objective.node.id } })
    onDelete?.()
  }, [data?.objective.node, deleteOkrNodeMutation, onDelete])

  if (!data || !defaultValueForOkrUpdate) return null

  return (
    <OkrUpdateModal
      modalComponent={Modal}
      okrNodeId={data.objective.node.id}
      objectiveId={data.objective.id}
      isDisabledOkr={data.objective.isDisabled}
      isSlackIntegrated={data.objective.node.slackIntegrated}
      slackIntegratedChannelList={data.objective.node.slackIntegratedChannelList}
      isChatworkIntegrated={data.objective.node.chatworkIntegrated}
      isTeamsIntegrated={data.objective.node.teamsIntegrated}
      defaultValue={defaultValueForOkrUpdate}
      onConfirm={confirmObjectiveEdit}
      onClickOkrActivateToggleButton={updateObjectiveActivateStatusMutation}
      onClickOkrDeleteButton={onClickOkrDeleteButton}
      {...rest}
    />
  )
}
