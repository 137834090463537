import { ApolloLink } from '@apollo/client'
import trackerFetch from '@openreplay/tracker-fetch'
import trackerGraphQL from '@openreplay/tracker-graphql'

import { OpenReplayTrackerSingleton as OpenReplayTracker } from './openReplay'

export const createTrackerApolloLink = (): ApolloLink | undefined => {
  const tracker = OpenReplayTracker.getTracker()
  if (!tracker) {
    return undefined
  }
  const recordGraphQL = tracker.getOpenReplayTracker().use(trackerGraphQL())
  return new ApolloLink((operation, forward) =>
    forward(operation).map((result) => {
      const operationDefinition = operation.query.definitions[0]
      return recordGraphQL(
        operationDefinition.kind === 'OperationDefinition'
          ? operationDefinition.operation
          : 'unknown?',
        operation.operationName,
        operation.variables,
        result,
      )
    }),
  )
}

const TRACKER_FETCH_OPTIONS: Parameters<typeof trackerFetch>[0] = {
  overrideGlobal: true,
  failuresOnly: true,
}

export const overrideGlobalFetch = (): typeof fetch | null => {
  const tracker = OpenReplayTracker.getTracker()
  if (!tracker) {
    return null
  }
  return tracker.getOpenReplayTracker().use(trackerFetch(TRACKER_FETCH_OPTIONS))
}
