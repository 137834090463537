import isEqual from 'react-fast-compare'
import { Ancestor, Editor, Range, Element, Node, Transforms, Path, Point } from 'slate'

import {
  ElementType,
  isListTypes,
  ListItemElement,
  ListTypes,
  ParagraphType,
} from '../plugins/elements/types'
import { MarksType } from '../plugins/marks/types'

import { EMPTY_PARAGRAPH } from './constants'
import { getNextNode, getPreviousNode, isCollapsed } from './query'

type WrapListArg = {
  editor: Editor
  nodePath: Path
  listItemNode: ListItemElement
  listItemPath: Path
}

// wrap list
export const wrapList = ({ editor, nodePath, listItemNode, listItemPath }: WrapListArg): void => {
  const { selection } = editor
  if (!selection) {
    return
  }
  if (!isCollapsed(selection)) {
    Transforms.delete(editor)
  }

  const start = Editor.start(editor, nodePath)
  const end = Editor.end(editor, nodePath)
  const selectionStart = Range.start(selection)
  const isStart = Point.equals(selectionStart, start)
  const isEnd = Point.equals(selectionStart, end)
  const nextParagraphPath = Path.next(nodePath)
  const nextListItemPath = Path.next(listItemPath)

  if (isStart) {
    Transforms.insertNodes(
      editor,
      {
        type: ListTypes.LI,
        children: [EMPTY_PARAGRAPH],
      },
      { at: listItemPath },
    )
    return
  }

  if (!isEnd) {
    Transforms.splitNodes(editor)
    Transforms.wrapNodes(
      editor,
      {
        type: ListTypes.LI,
        children: [],
      },
      { at: nextParagraphPath },
    )
    Transforms.moveNodes(editor, {
      at: nextParagraphPath,
      to: nextListItemPath,
    })
  } else {
    Transforms.insertNodes(
      editor,
      {
        type: ListTypes.LI,
        children: [EMPTY_PARAGRAPH],
      },
      { at: nextListItemPath },
    )
    Transforms.select(editor, nextListItemPath)
  }
  if (listItemNode.children.length > 1) {
    Transforms.moveNodes(editor, {
      at: nextParagraphPath,
      to: nextListItemPath.concat(1),
    })
  }
}

// wrap of Transforms.unwrapNodes
export const unwrapNodesByType = (
  editor: Editor,
  types: ReadonlyArray<ElementType>,
  options: Omit<Parameters<typeof Transforms.unwrapNodes>[1], 'match'> = {},
): void => {
  Transforms.unwrapNodes(editor, {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    match: (n) => (Element.isElement(n) && n.type ? types.includes(n.type) : false),
    ...options,
  })
}

// merge List (list += anotherList)
export const mergeList = (editor: Editor, listPath: Path, anotherListPath: Path): void => {
  const [listNode] = Editor.node(editor, listPath)
  if (
    !Element.isElement(listNode) ||
    (listNode.type !== ListTypes.OL && listNode.type !== ListTypes.UL)
  ) {
    return
  }
  const [anotherListNode] = Editor.node(editor, anotherListPath)
  if (
    !Element.isElement(anotherListNode) ||
    (anotherListNode.type !== ListTypes.OL && anotherListNode.type !== ListTypes.UL)
  ) {
    return
  }

  const listItemEntries = Array.from(
    Editor.nodes(editor, {
      at: listPath,
      match: (n) => Element.isElement(n) && n.type === ListTypes.LI,
      mode: 'highest',
    }),
  )
  const anotherListItemEntries = Array.from(
    Editor.nodes(editor, {
      at: anotherListPath,
      match: (n) => Element.isElement(n) && n.type === ListTypes.LI,
      mode: 'highest',
    }),
  )
  if (listItemEntries.length === 0 || anotherListItemEntries.length === 0) {
    return
  }

  const anotherListItemNodes = anotherListItemEntries.map(([n]) => n)
  const listTailItemPath = listItemEntries.slice(-1)[0][1]
  const appendPath = Path.next(listTailItemPath)
  Transforms.insertNodes(editor, anotherListItemNodes, { at: appendPath })
  Transforms.removeNodes(editor, { at: anotherListPath })
}

// 1つ上の同ListTypesのListと現在地のListを結合する
export const mergePreviousList = (editor: Editor): void => {
  const blockEntry = Editor.above(editor, {
    match: (n) => Editor.isBlock(editor, n),
  })
  if (!blockEntry) return

  const [paragraphNode, paragraphPath] = blockEntry
  if (!Element.isElement(paragraphNode) || paragraphNode.type !== ParagraphType.PARAGRAPH) return

  const [listItemNode, listItemPath] = Editor.parent(editor, paragraphPath)
  if (!Element.isElement(listItemNode) || listItemNode.type !== ListTypes.LI) return

  const [listNode, listPath] = Editor.parent(editor, listItemPath)
  if (
    !Element.isElement(listNode) ||
    (listNode.type !== ListTypes.OL && listNode.type !== ListTypes.UL)
  ) {
    return
  }

  const prevNode = getPreviousNode(editor, listPath)
  if (!prevNode) return

  const [prevListNode, prevListPath] = prevNode
  if (!Element.isElement(prevListNode) || prevListNode.type !== listNode.type) return

  mergeList(editor, prevListPath, listPath)
}

// 1つ下の同ListTypesのListと現在地のListを結合する
export const mergeNextList = (editor: Editor): void => {
  const blockEntry = Editor.above(editor, {
    match: (n) => Editor.isBlock(editor, n),
  })
  if (!blockEntry) return

  const [paragraphNode, paragraphPath] = blockEntry
  if (!Element.isElement(paragraphNode) || paragraphNode.type !== ParagraphType.PARAGRAPH) return

  const [listItemNode, listItemPath] = Editor.parent(editor, paragraphPath)
  if (!Element.isElement(listItemNode) || listItemNode.type !== ListTypes.LI) return

  const [listNode, listPath] = Editor.parent(editor, listItemPath)
  if (
    !Element.isElement(listNode) ||
    (listNode.type !== ListTypes.OL && listNode.type !== ListTypes.UL)
  ) {
    return
  }

  const nextNode = getNextNode(editor, listPath)
  if (!nextNode) return

  const [nextListNode, nextListPath] = nextNode
  if (!Element.isElement(nextListNode) || nextListNode.type !== listNode.type) return

  mergeList(editor, listPath, nextListPath)
}

// transform list
export const transformList = (editor: Editor, listType: ListTypes): void => {
  const [match] = Editor.nodes(editor, {
    match: (n) => Element.isElement(n) && n.type === ListTypes.LI,
  })

  unwrapNodesByType(editor, [ListTypes.LI])
  unwrapNodesByType(editor, [ListTypes.UL, ListTypes.OL], { split: true })
  Transforms.wrapNodes(
    editor,
    { type: listType, children: [] },
    {
      match: (n) => Element.isElement(n) && n.type === ListTypes.LI,
    },
  )
  Transforms.setNodes(editor, {
    type: ParagraphType.PARAGRAPH,
  })
  if (typeof match === 'undefined') {
    const list: Element = { type: listType, children: [] }
    Transforms.wrapNodes(editor, list)
    const nodes = Array.from(
      Editor.nodes(editor, {
        match: (n) => Element.isElement(n) && n.type === ParagraphType.PARAGRAPH,
      }),
    )
    const listItem: Element = { type: ListTypes.LI, children: [] }
    nodes.forEach(([, nPath]) => {
      Transforms.wrapNodes(editor, listItem, {
        at: nPath,
      })
    })
  }

  mergePreviousList(editor)
  mergeNextList(editor)
}

// remove all mark
export const removeAllMark = (editor: Editor): void => {
  Object.values(MarksType).forEach((value) => {
    Editor.removeMark(editor, value)
  })
}

// insert empty paragraph
export const insertEmptyParagraph = (editor: Editor, path?: Path): void => {
  removeAllMark(editor)
  Transforms.insertNodes(editor, EMPTY_PARAGRAPH, { at: path })
}

// NOTE: コピペ時に先頭に空白行が挿入される問題の対応として、コピペ先頭の空paragraphを削除する版のinsertNodesを用意
// See: https://github.com/Resily/resily-new/issues/7557
export const insertNodesWithTrim = (
  editor: Editor,
  fragment: Parameters<typeof Transforms.insertNodes>[1],
): void => {
  const insertAnchor = editor.selection?.anchor
  Transforms.insertNodes(editor, fragment)

  try {
    if (insertAnchor) {
      const maybeEmptyParagraphPath = Path.parent(insertAnchor.path)
      const maybeEmptyParagraphNode = Node.get(editor, maybeEmptyParagraphPath)
      if (
        isEqual(maybeEmptyParagraphNode, EMPTY_PARAGRAPH) ||
        isEqual(maybeEmptyParagraphNode, [EMPTY_PARAGRAPH])
      ) {
        Transforms.removeNodes(editor, { at: maybeEmptyParagraphPath })
      }
    }
  } catch (e: unknown) {
    // 何もしない
  }
}

// unwrap list
export const unWrapList = (editor: Editor): void => {
  unwrapNodesByType(editor, [ListTypes.LI], { split: true })
  unwrapNodesByType(editor, [ListTypes.UL, ListTypes.OL], { split: true })
}

// unWrapList recursively
export const unWrapListRecursive = (editor: Editor, block: Ancestor, path: Path): void => {
  if (Element.isElement(block) && isListTypes(block.type)) {
    unWrapList(editor)
    const [parentBlock, parentPath] = Editor.parent(editor, path)
    if (Element.isElement(parentBlock) && isListTypes(parentBlock.type)) {
      unWrapListRecursive(editor, parentBlock, parentPath)
    }
  }
}

// transform Block to Paragraph
export const transformToParagraph = (editor: Editor): void => {
  const blockEntry = Editor.above(editor, {
    match: (n) => Editor.isBlock(editor, n),
  })
  if (blockEntry) {
    const [, blockPath] = blockEntry
    const parent = Editor.parent(editor, blockPath)
    const [parentBlock, parentPath] = parent
    if (Element.isElement(parentBlock) && parentBlock.type === ListTypes.LI) {
      unWrapListRecursive(editor, parentBlock, parentPath)
      return
    }
  }

  Transforms.setNodes(editor, {
    type: ParagraphType.PARAGRAPH,
    // 不要なelement要素の削除
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    checked: undefined,
    url: undefined,
    width: undefined,
    height: undefined,
  })
}

// transform Block Element
export const transformToBlockElement = (editor: Editor, type: ElementType): void => {
  if (ListTypes.OL === type || ListTypes.UL === type) {
    const blockEntry = Editor.above(editor, {
      match: (n) => Editor.isBlock(editor, n),
    })
    if (blockEntry) {
      const [, blockPath] = blockEntry
      const parent = Editor.parent(editor, blockPath)
      const [parentBlock, parentPath] = parent
      if (Element.isElement(parentBlock) && parentBlock.type === ListTypes.LI) {
        unWrapListRecursive(editor, parentBlock, parentPath)
        return
      }
    }
    transformList(editor, type)
  } else {
    Transforms.setNodes(editor, { type }, { split: false })
  }
}
