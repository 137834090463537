import { useCallback } from 'react'

import { User } from '../../../contexts/UserContext'
import { useUpdateProfileMutation, useUpdateProfileWithoutEmailMutation } from '../api/graphql'
import {
  FormValues as ProfileSettingFormValues,
  Props as ProfileSettingProps,
  ModalSubmitHandler,
} from '../components/ProfileSetting'

export const useProfileSetting = ({
  user,
}: {
  user: User
}): {
  firstName: ProfileSettingFormValues['firstName']
  lastName: ProfileSettingFormValues['lastName']
  avatarPath: ProfileSettingFormValues['avatarPath']
  avatarUrl: ProfileSettingProps['avatarUrl']
  email: ProfileSettingFormValues['email']
  handleUpdateProfile: ModalSubmitHandler
} => {
  const [updateProfile] = useUpdateProfileMutation()
  const [updateProfileWithoutEmail] = useUpdateProfileWithoutEmailMutation()

  const handleUpdateProfile = useCallback<ModalSubmitHandler>(
    async (values: ProfileSettingFormValues) => {
      if (user.email === values.email) {
        await updateProfileWithoutEmail({
          variables: values,
        })
        return
      }

      await updateProfile({
        variables: values,
      })
    },
    [updateProfile, updateProfileWithoutEmail, user.email],
  )

  return {
    firstName: user.firstName,
    lastName: user.lastName,
    avatarPath: user.avatar?.path,
    avatarUrl: user.avatar?.url,
    email: user.email,
    handleUpdateProfile,
  }
}
