import { FC } from 'react'

import { Modal } from '../../../components/ui/Modal'
import { StyledText } from '../../../components/ui/StyledText'
import { useTranslation } from '../../../i18n'

export type Props = {
  onClickDelete: () => void
  onClickCancel: () => void
}

export const OneOnOneCancelModal: FC<Props> = ({ onClickDelete, onClickCancel }) => {
  const { t } = useTranslation()

  return (
    <Modal
      isOpened
      withGeishaModal
      title={t('DISCARD_CREATE_EDITING_X', { x: t('ONEONONE') })}
      executeText={t('DISCARD')}
      cancelText={t('CONTINUE_EDITING')}
      onClickExecute={onClickDelete}
      onClickCancel={onClickCancel}
      onClickOutside={onClickCancel}
    >
      <StyledText>{t('X_EDITING_CONFIRMATION_OF_DISCARD', { x: t('ONEONONE') })}</StyledText>
    </Modal>
  )
}

OneOnOneCancelModal.displayName = 'OneOnOneCancelModal'
