import { useTranslation } from '../../../../../../i18n'
import { Description, Props as DescriptionProps } from '../../../Common/Description'
import { LoadableProps } from '../../../types'

export type DataProps = Pick<DescriptionProps, 'description' | 'attachmentViews'> & {
  keyResultId: string
}

export type Props = LoadableProps<DataProps> & Pick<DescriptionProps, 'saveDescription'>

export const DescriptionArea: React.VFC<Props> = ({
  loading,
  keyResultId,
  description,
  attachmentViews,
  saveDescription,
}) => {
  const { t } = useTranslation()

  return loading ? (
    <Description.Skeleton title={t('KR_DESCRIPTION')} />
  ) : (
    <Description
      key={`m-kr-${keyResultId}`}
      title={t('KR_DESCRIPTION')}
      editorId={`m-kr-${keyResultId}`}
      editorPlaceholder={t('KR_DESCRIPTION_PLACEHOLDER')}
      emptyPlaceholder={`${t('KR_DESCRIPTION_EMPTY')}\n\n${t('KR_DESCRIPTION_PLACEHOLDER')}`}
      description={description}
      attachmentViews={attachmentViews}
      saveDescription={saveDescription}
    />
  )
}
