import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { StringParam, useQueryParams } from 'use-query-params'

import { client } from '../../lib/client'
import { useConfirmationToken } from '../../lib/domain/useConfirmationToken'
import { tokenConfirmation } from '../../types/tokenConfirmation'
import { root } from '../../urls'

import { AccountSetup } from './AccountSetup'
import { FormValue } from './AccountSetupForm'
import { AccountSetupInvalidLink } from './AccountSetupInvalidLink'

export const AccountSetupContainer: React.FC = () => {
  const [{ token }] = useQueryParams({
    token: StringParam,
  })

  // ユーザータイムゾーンの20日後の23時59分59秒までがお試し期間だが、DBに保存するtrialExpiresAtはUTC。ローカルタイムゾーンでのtrialExpiresAtを設定してからUTCに変換するために必要。
  dayjs.extend(utc)
  dayjs.extend(timezone)
  const tz = dayjs.tz.guess()

  const { loading, confirmedToken, email } = useConfirmationToken({
    type: tokenConfirmation.ACCOUNT_SETUP,
    token,
  })

  if (token == null || token === '') {
    return null
  }

  const handleSubmit = async (values: FormValue) => {
    try {
      await client.post<void>(
        '/account/setup',
        {
          organization_name: values.organizationName,
          first_name: values.firstName,
          last_name: values.lastName,
          phone_number: values.phoneNumber,
          email: values.email,
          password: values.password,
          term_name: `${dayjs(values.termStartDate).format('YYYY/MM/DD')} - ${dayjs(
            values.termEndDate,
          ).format('YYYY/MM/DD')}`,
          term_start_date: values.termStartDate,
          term_end_date: values.termEndDate,
          confirmation_token: token,
          time_zone: tz,
        },
        {
          error: true,
        },
      )
      window.location.href = root
    } catch (err) {
      // something to do.
    }
  }

  if (loading) {
    return null
  }

  if (!confirmedToken) {
    return <AccountSetupInvalidLink />
  }

  return <AccountSetup email={email ?? ''} token={token} onSubmit={handleSubmit} />
}

AccountSetupContainer.displayName = 'AccountSetupContainer'
