import { useCallback, useMemo } from 'react'
import { useQueryParams } from 'use-query-params'

import { useTranslation } from '../../../../../../i18n'
import { Screen } from '../../../../../../lib/screen'
import { tracker } from '../../../../../../lib/tracking'
import { not } from '../../../../../../lib/union'
import { NoteOrPersonalNoteFieldFragment } from '../graphql'
import { isNote } from '../noteTableRow'
import { SortEnumParam, OrderEnumParam, Sort, SortObject } from '../types'

const orderList: Readonly<['asc', 'desc']> = ['asc', 'desc']

type SortingNoteType = NoteOrPersonalNoteFieldFragment & {
  fullAuthorName: string
  resourceName: string
  updatedAtTime: number
}

const compareForSorting = (a: SortingNoteType, b: SortingNoteType, sort: Sort): number => {
  let result: boolean
  switch (sort.sortBy) {
    case SortObject.title:
      result = a.title < b.title
      break
    case SortObject.OKR:
      result = a.resourceName < b.resourceName
      break
    case SortObject.permission:
      result = a.permission < b.permission
      break
    case SortObject.author:
      result = a.fullAuthorName < b.fullAuthorName
      break
    default:
      result = a.updatedAtTime < b.updatedAtTime
      break
  }
  const comparisonResult = sort.order === 'asc' ? result : !result
  return comparisonResult ? -1 : 1
}

export const useSort = (
  notesResp: ReadonlyArray<NoteOrPersonalNoteFieldFragment> | undefined,
): {
  sortedNotes: ReadonlyArray<NoteOrPersonalNoteFieldFragment>
  sort: Sort
  handleClickSortBy: (sortBy: Sort['sortBy']) => void
} => {
  const { t } = useTranslation()

  const [queryParams, setQueryParams] = useQueryParams({
    sort: SortEnumParam,
    order: OrderEnumParam,
  })

  const sort = useMemo<Sort>(
    () => ({ sortBy: queryParams.sort, order: queryParams.order }),
    [queryParams.sort, queryParams.order],
  )

  const mappedNotes = useMemo<Array<SortingNoteType>>(() => {
    if (!notesResp) return []
    return notesResp.map((note) => {
      const fullAuthorName = t('FULL_NAME', {
        firstName: note.author.firstName,
        lastName: note.author.lastName,
      })
      const resourceName = isNote(note) ? note.resource.name : ''
      const updatedAtTime = new Date(note.updatedAt).getTime() // 日時がISOStringでDate型にされているため、Date型に変換
      return {
        ...note,
        fullAuthorName,
        resourceName,
        updatedAtTime,
      }
    })
  }, [notesResp, t])

  const sortedNotes = useMemo(
    () => mappedNotes.sort((a, b) => compareForSorting(a, b, sort)),
    [mappedNotes, sort],
  )

  const handleClickSortBy = useCallback(
    (sortBy: Sort['sortBy']) => {
      const newOrder = sort.order && sort.sortBy === sortBy ? not(orderList)(sort.order) : 'desc'

      tracker.UserClickSortButtonInHome(Screen.HomeNote, sortBy as string, newOrder)

      setQueryParams({
        sort: sortBy,
        order: newOrder,
      })
    },
    [setQueryParams, sort.order, sort.sortBy],
  )

  return { sortedNotes, sort, handleClickSortBy }
}
