import { useTranslation } from '../../../i18n'
import {
  File,
  NoteDetailFragment,
  NoteDetailViewNoteDetailFragment,
} from '../../../pages/NoteDetail/graphql'
import {
  PersonalNoteDetailFragment,
  PersonalNoteDetailViewNoteDetailFragment,
} from '../../../pages/PersonalNoteDetail/graphql'
import { DetailEditCard } from '../../ui/DetailEditCard'

export type Props<
  T extends NoteDetailViewNoteDetailFragment | PersonalNoteDetailViewNoteDetailFragment,
> = {
  note: T
  updateNote: (note: T, redirect: boolean) => void
  onClickCancel: () => void
  setNeedBlock: (value: boolean) => void
}

export const NoteDetailEditPageCard = <T extends NoteDetailFragment | PersonalNoteDetailFragment>({
  note,
  updateNote,
  onClickCancel,
  setNeedBlock,
}: Props<T>): JSX.Element => {
  const { t } = useTranslation()
  const handleUpdateNoteDetail = (noteTitle: string, treeJson: string, text: string) => {
    setNeedBlock(false)
    updateNote(
      {
        ...note,
        title: noteTitle,
        body: {
          treeJson,
          plainText: text,
        },
      },
      true,
    )
  }

  const handleUploadFile = (file: File) => {
    updateNote(
      {
        ...note,
        body: { treeJson: note.body?.treeJson || '', plainText: note.body?.plainText || '' },
        attachments: [...note.attachments, file],
      },
      false,
    )
  }

  const handleDeleteAttachment = (newFiles: ReadonlyArray<File>) => {
    updateNote(
      {
        ...note,
        body: { treeJson: note.body?.treeJson || '', plainText: note.body?.plainText || '' },
        attachments: newFiles,
      },
      false,
    )
  }

  return (
    <DetailEditCard
      editorId={`detailedit-note-${note.id}`}
      title={note.title}
      titlePlaceholder={t('EMPTY_NOTE')}
      body={note.body?.treeJson}
      plainText={note.body?.plainText || ''}
      attachments={note.attachments}
      onClickCancel={onClickCancel}
      onClickDeleteAttachments={handleDeleteAttachment}
      onClickSave={handleUpdateNoteDetail}
      onUpdateFile={handleUploadFile}
      onEdit={setNeedBlock}
    />
  )
}
