import { css } from '@emotion/react'
import { useMemo } from 'react'

import { fontSize } from '../../../../../../styles/font'
import { color } from '../../../../../../styles/newColors'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useStyles = ({ isViewEmptyPlaceholder }: { isViewEmptyPlaceholder: boolean }) =>
  useMemo(
    () =>
      ({
        placeholder: css({
          display: 'inline-block',
          padding: '12px 16px',
          whiteSpace: 'pre-wrap',
          color: color('text-bk-50'),
          ...fontSize('medium', 'regular'),
        }),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        editor: css({
          display: isViewEmptyPlaceholder ? 'none' : undefined,
          minHeight: 150,
          'div[data-slate-editor="true"]': {
            padding: '12px 16px !important',
          },
          'span[data-slate-placeholder="true"][contenteditable="false"]': {
            // HACK: editorPlaceholder表示時にKRだけ何故かエディタの高さがおかしくなる対応
            position: 'initial !important',
            display: 'initial !important',
          },
        }),
      } as const),
    [isViewEmptyPlaceholder],
  )
