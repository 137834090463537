import { Spinner } from 'grommet'
import { useMemo, useState } from 'react'

import { useTranslation } from '../../../i18n'
import { filterString } from '../../../lib/array'
import { bySearchTextOrValue } from '../../../lib/domain/user/searchText'
import { componentNames, featureNames, generateTestId } from '../../../lib/testId'
import { StyledText } from '../../ui/StyledText'
import { OkrNodeFragment } from '../ParentOkrSelect/graphql'

import { Layer } from './Layer'
import { ObjectiveName } from './ObjectiveName'
import { useStyles } from './index.styles'

export type Props<T extends OkrNodeFragment> = {
  loading?: boolean
  options: ReadonlyArray<T>
  onSelect: (
    value: T,
    event: Parameters<NonNullable<JSX.IntrinsicElements['button']['onClick']>>[0],
  ) => void
}

export const ParentOkrSelectOptions = <T extends OkrNodeFragment>({
  loading,
  options,
  onSelect,
}: Props<T>): JSX.Element => {
  const { t } = useTranslation()
  const styles = useStyles()

  const [query, setQuery] = useState<string>()
  const layersMap = useMemo(() => {
    const searchedOptions = (() => {
      const filteredOptions = options
        .filter((n) => !n.objective.isDisabled)
        .sort((prev, next) => prev.depth - next.depth)
      if (!query) {
        return filteredOptions
      }

      return filterString(query, filteredOptions, (v) =>
        bySearchTextOrValue({
          value: v.id,
          searchText: `${v.objective.name} ${v.objective.owner.firstName} ${v.objective.owner.lastName}`,
        }),
      )
    })()

    const map = new Map<number, ReadonlyArray<T>>()
    searchedOptions.forEach((o) => {
      const prev = map.get(o.depth)
      if (prev) {
        map.set(o.depth, prev.concat(o))
      } else {
        map.set(o.depth, [o])
      }
    })
    return map
  }, [options, query])

  if (loading) {
    return (
      <div css={styles.loading}>
        <Spinner />
      </div>
    )
  }

  return (
    <div css={styles.root}>
      <input
        ref={(r) => r?.focus()}
        css={styles.input}
        type="search"
        placeholder={t('SEARCH_PARENT_OKR')}
        onChange={(e) => setQuery(e.currentTarget.value)}
      />
      {layersMap.size > 0 ? (
        <ul>
          {[...layersMap.entries()].map(([depth, layer]) => (
            <li key={depth} css={styles.layerList}>
              <Layer depth={depth} />
              {layer.map((node) => (
                <button
                  key={node.id}
                  type="button"
                  css={styles.option}
                  data-testid={generateTestId(
                    featureNames.okrCreateModal,
                    componentNames.parentOkrSelectOptions,
                  )}
                  onClick={(e) => onSelect(node, e)}
                >
                  <ObjectiveName objective={node.objective} />
                </button>
              ))}
            </li>
          ))}
        </ul>
      ) : (
        <div css={styles.notFound}>
          <StyledText size="large">{t('NOT_APPLICABLE')}</StyledText>
        </div>
      )}
    </div>
  )
}
