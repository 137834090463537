// eslint-disable-next-line max-classes-per-file
import OpenReplayTracker from '@openreplay/tracker'
import trackerAssist from '@openreplay/tracker-assist'
import { configureScope } from '@sentry/react'

import { configureOpenReplay, configureSentry, openReplayDomain } from '../../config'

type StartArgs = {
  userParam: {
    userId: number
    name: string
    email: string
    Organization: string
    OrganizationId: number
    TrialAccount: boolean
    SelfServeAccount: boolean
  }
}

class Tracker {
  private openReplayTracker: OpenReplayTracker

  public constructor(openReplayTracker: OpenReplayTracker) {
    this.openReplayTracker = openReplayTracker
  }

  public start(args: StartArgs): void {
    this.openReplayTracker.start({
      userID: args.userParam.userId.toString(),
      metadata: {
        name: args.userParam.name,
        email: args.userParam.email,
        Organization: args.userParam.Organization,
        OrganizationId: args.userParam.OrganizationId.toString(),
        TrialAccount: args.userParam.TrialAccount.toString(),
        SelfServeAccount: args.userParam.SelfServeAccount.toString(),
      },
    })
  }

  public logEvent(eventName: string, data?: Record<string, unknown>): void {
    this.openReplayTracker.event(eventName, data)
  }

  public getOpenReplayTracker() {
    return this.openReplayTracker
  }
}

export class OpenReplayTrackerSingleton {
  private static tracker: Tracker | undefined

  private constructor() {}

  private static createTracker(): Tracker | undefined {
    const openReplayConf = configureOpenReplay()
    if (openReplayConf.projectKey !== '') {
      const sentryConf = configureSentry()
      const tracker = new Tracker(
        new OpenReplayTracker({
          projectKey: openReplayConf.projectKey,
          onStart: ({ sessionToken, sessionID }) => {
            // Integrate with Sentry
            if (sentryConf.dsn !== '') {
              configureScope((scope) => {
                scope.setTag('openReplaySessionToken', sessionToken)
              })
              if (sessionID && openReplayConf.projectId) {
                const sessionURL = `https://${openReplayDomain}/${openReplayConf.projectId}/session/${sessionID}`
                configureScope((scope) => {
                  scope.setExtra('sessionURL', sessionURL)
                })
              }
            }
          },
        }),
      )
      tracker.getOpenReplayTracker().use(trackerAssist())
      return tracker
    }
    return undefined
  }

  public static getTracker(): Tracker | undefined {
    if (!this.tracker) {
      this.tracker = this.createTracker()
    }

    return this.tracker
  }
}
