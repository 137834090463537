import { Button, useModal } from '@resily/geisha'
import { ThemeContext } from 'grommet'
import { useCallback, useRef, useState } from 'react'
import isEqual from 'react-fast-compare'

import { useTranslation } from '../../../i18n'
import { isFilledRequiredFieldsForCreateAndUpdateOkr } from '../../../lib/domain/okr'
import { convertToFileInput } from '../../../lib/fileInput'
import { findScreen } from '../../../lib/screen'
import { tracker } from '../../../lib/tracking'
import { StrictPropertyCheck } from '../../../lib/type'
import { useApiProcessing } from '../../../lib/useApiProcessing'
import { OkrCreateModalContent, State as OkrContentState } from '../../domain/OkrCreateModalContent'

import { Header } from './Header'
import { OkrDeleteAlertModal } from './OkrDeleteAlertModal'
import { OkrDisableAlertModal } from './OkrDisableAlertModal'
import { useStyles } from './OkrUpdateModal.styles'
import { UpdateOkrNodeInput, SlackChannelFieldsFragment } from './graphql'

export type Props = {
  okrNodeId: string
  objectiveId: string
  isDisabledOkr: boolean
  isSlackIntegrated: boolean
  slackIntegratedChannelList: ReadonlyArray<SlackChannelFieldsFragment>
  isChatworkIntegrated: boolean
  isTeamsIntegrated: boolean
  defaultValue: OkrContentState
  isOpened: boolean
  close: () => void
  onClose: () => void
  onEdit: (isDirty: boolean) => void
  onConfirm: (input: UpdateOkrNodeInput) => Promise<unknown>
  onClickOkrActivateToggleButton: () => Promise<unknown>
  onClickOkrDeleteButton: () => Promise<unknown>
}

export const OkrUpdateModal: React.VFC<
  Props & { modalComponent: ReturnType<typeof useModal>[0] }
> = ({
  modalComponent: Modal,
  isOpened,
  close,
  onClose,
  onEdit,
  onConfirm,
  okrNodeId,
  objectiveId,
  isDisabledOkr,
  isSlackIntegrated,
  slackIntegratedChannelList,
  isChatworkIntegrated,
  isTeamsIntegrated,
  defaultValue,
  onClickOkrActivateToggleButton,
  onClickOkrDeleteButton,
}) => {
  const styles = useStyles()
  const { t } = useTranslation()

  const isDirty = useRef<boolean>(false)
  const [okrState, setOkrState] = useState<OkrContentState>(defaultValue)
  const handelSetState = useCallback<typeof setOkrState>(
    (arg) => {
      setOkrState((prevState) => {
        const nextState = typeof arg === 'function' ? arg(prevState) : arg
        isDirty.current = !isEqual(nextState, defaultValue)
        onEdit(isDirty.current)
        return nextState
      })
    },
    [defaultValue, onEdit],
  )

  const [isApiProcessing, wrapApiPromise] = useApiProcessing()

  const [showDisableDialog, setShowDisableDialog] = useState<boolean>(false)
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false)

  const [slackNotifyFlg, setSlackNotifyFlg] = useState<boolean>(isSlackIntegrated)
  const toggleSlackNotifyFlg = useCallback(
    () => setSlackNotifyFlg(!slackNotifyFlg),
    [slackNotifyFlg],
  )

  const [chatworkNotifyFlg, setChatworkNotifyFlg] = useState<boolean>(isChatworkIntegrated)
  const toggleChatworkNotifyFlg = useCallback(
    () => setChatworkNotifyFlg(!chatworkNotifyFlg),
    [chatworkNotifyFlg],
  )

  const [teamsNotifyFlg, setTeamsNotifyFlg] = useState<boolean>(isTeamsIntegrated)
  const toggleTeamsNotifyFlg = useCallback(
    () => setTeamsNotifyFlg(!teamsNotifyFlg),
    [teamsNotifyFlg],
  )

  const convertObjectiveStateToInput = useCallback(
    (state: OkrContentState): UpdateOkrNodeInput => {
      const result = {
        okrNodeId,
        objectiveId,
        name: state.name,
        ownerId: state.owner?.id || '',
        groupIds: state.selectedGroups.length > 0 ? state.selectedGroups.map((e) => e.id) : null,
        parentOkrNodeId: state.parentOkrNode?.id,
        parentKeyResultIds: state.parentKeyResultIds.length > 0 ? state.parentKeyResultIds : null,
        before: {
          name: defaultValue.name,
          description: defaultValue.description.body
            ? {
                treeJson: defaultValue.description.body,
                plainText: defaultValue.description.plainText,
              }
            : null,
          ownerId: defaultValue.owner?.id || '',
          parentOkrNodeId: defaultValue.parentOkrNode?.id,
          parentKeyResultIds: defaultValue.parentKeyResultIds,
        },
        attachments:
          state.attachmentViews.map<
            StrictPropertyCheck<
              ReturnType<typeof convertToFileInput>,
              NonNullable<Required<UpdateOkrNodeInput>['attachments']>[0]
            >
          >(convertToFileInput),
        description: state.description.body
          ? {
              treeJson: state.description.body,
              plainText: state.description.plainText,
            }
          : undefined,
        isDescriptionChanged: state.description.plainText !== '',
        chatworkApiToken: chatworkNotifyFlg ? state.chatworkApiToken : null,
        chatworkRoomId: chatworkNotifyFlg ? state.chatworkRoomId : null,
        teamsWebhookUrl: teamsNotifyFlg ? state.teamsWebhookURL : null,
        slackChannelIds: state.slackChannelIds.length > 0 ? state.slackChannelIds : null,
      }

      const checkResult: StrictPropertyCheck<typeof result, UpdateOkrNodeInput> = result
      return checkResult
    },
    [
      chatworkNotifyFlg,
      defaultValue.name,
      defaultValue.description.body,
      defaultValue.description.plainText,
      defaultValue.owner?.id,
      defaultValue.parentKeyResultIds,
      defaultValue.parentOkrNode?.id,
      objectiveId,
      okrNodeId,
      teamsNotifyFlg,
    ],
  )

  const handleConfirm = useCallback(() => {
    if (!isDirty.current) {
      close()
      return
    }
    wrapApiPromise(onConfirm(convertObjectiveStateToInput(okrState))).then(() => {
      close()
      tracker.UserClickUpdateObjectiveByObjectiveUpdateModal(
        findScreen(window.location.pathname, window.location.search),
      )
    })
  }, [close, convertObjectiveStateToInput, okrState, onConfirm, wrapApiPromise])

  const handleOnCloseModal = useCallback(() => {
    onClose()
    tracker.UserClickCloseObjectiveUpdateModal(
      findScreen(window.location.pathname, window.location.search),
    )
  }, [onClose])

  const handleOnClickDisable = useCallback(() => {
    // 無効化時はO編集モーダル閉じる
    wrapApiPromise(onClickOkrActivateToggleButton()).then(close)
    setShowDisableDialog(false)
    tracker.UserClickDisableObjectiveByObjectiveUpdateModal(
      findScreen(window.location.pathname, window.location.search),
    )
  }, [close, onClickOkrActivateToggleButton, wrapApiPromise])

  const handleOnClickDelete = useCallback(() => {
    wrapApiPromise(onClickOkrDeleteButton()).then(close)
    setShowDeleteDialog(false)
    tracker.UserClickDeleteObjectiveByObjectiveUpdateModal(
      findScreen(window.location.pathname, window.location.search),
    )
  }, [close, onClickOkrDeleteButton, wrapApiPromise])

  return (
    <Modal
      size="large"
      // NOTE: 非geishaのモーダルを上に重ねて表示すると、その内部のinput等にfocusが当たらない問題の対策
      // geishaモーダルへの置き換えが完了した場合は不要
      restrictFocus={false}
      isOpen={isOpened}
      onClose={() => !isApiProcessing && handleOnCloseModal()}
    >
      <Modal.Header title={<Header />} />
      <Modal.Content>
        <ThemeContext.Extend value={{ global: { drop: { zIndex: '201' } } }}>
          <OkrCreateModalContent
            editingObjectiveId={objectiveId}
            state={okrState}
            setState={handelSetState}
            slackNotifyFlg={slackNotifyFlg}
            handleSlackNotifyFlg={toggleSlackNotifyFlg}
            slackIntegratedChannelList={slackIntegratedChannelList}
            chatworkNotifyFlg={chatworkNotifyFlg}
            handleChatworkNotifyFlg={toggleChatworkNotifyFlg}
            teamsNotifyFlg={teamsNotifyFlg}
            handleTeamsNotifyFlg={toggleTeamsNotifyFlg}
          />
        </ThemeContext.Extend>
      </Modal.Content>
      <Modal.Footer
        confirmLabel={t('SAVE')}
        confirmDisabled={
          isApiProcessing ||
          !isFilledRequiredFieldsForCreateAndUpdateOkr(
            convertObjectiveStateToInput(okrState),
            slackNotifyFlg,
            chatworkNotifyFlg,
            teamsNotifyFlg,
          )
        }
        onConfirm={handleConfirm}
      >
        <div css={styles.activateButtonArea}>
          <Button
            size="large"
            type="secondary"
            disabled={isApiProcessing}
            onClick={() =>
              // 有効化時は確認モーダル出さない＆O編集モーダル閉じない
              isDisabledOkr
                ? wrapApiPromise(onClickOkrActivateToggleButton()).then(() =>
                    tracker.UserClickEnableObjectiveByObjectiveUpdateModal(
                      findScreen(window.location.pathname, window.location.search),
                    ),
                  )
                : setShowDisableDialog(true)
            }
          >
            {t(isDisabledOkr ? 'ACTIVATING_X' : 'DISABLING_X', {
              x: t('THIS_ITEM', { item: t('OKR') }),
            })}
          </Button>
          {isDisabledOkr && (
            <Button
              size="large"
              type="tertiary"
              disabled={isApiProcessing}
              onClick={() => setShowDeleteDialog(true)}
            >
              {t('DELETION_X', { x: t('THIS_ITEM', { item: t('OKR') }) })}
            </Button>
          )}
        </div>
      </Modal.Footer>

      {showDisableDialog && (
        <OkrDisableAlertModal
          withGeishaModal
          onClickDisable={handleOnClickDisable}
          onClickCancel={() => setShowDisableDialog(false)}
        />
      )}
      {showDeleteDialog && (
        <OkrDeleteAlertModal
          withGeishaModal
          onClickDelete={handleOnClickDelete}
          onClickCancel={() => setShowDeleteDialog(false)}
        />
      )}
    </Modal>
  )
}

OkrUpdateModal.displayName = 'OkrUpdateModal'
