import { CSSObject } from '@emotion/react'
import { PropsWithoutRef } from 'react'

import { useTranslation } from '../../../i18n'
import { generateUser, generateHome } from '../../../urls'
import { Link, LinkMethod } from '../Link'
import { UserTooltipNew } from '../TooltipNew'

import { useStyles } from './index.styles'

// fixme: ドメイン知識のあるliteralになっているが、リファクタリングが必要だが、サイズが出揃うまではこのままにする
export type Size =
  | 'xxsmall'
  | 'xsmall'
  | 'small'
  | 'mediumShort'
  | 'medium'
  | 'large'
  | 'okr-map-list-page'
  | 'okr-map-tree-page'
  | 'user-page'
  | 'okr-page'
  | 'user-list-page'
  | 'user-list-page-resource-view'
  | 'side-nav-popover'
  | 'one-on-one-dashboard-page'
  | 'one-on-one-drawer-okr-tab'

export const sizes: {
  readonly [key in Size]: {
    readonly borderWidth?: number
    readonly width: number
    readonly height: number
    readonly fontSize: number
  }
} = {
  xxsmall: {
    borderWidth: 0,
    width: 14,
    height: 14,
    fontSize: 7,
  },
  xsmall: {
    borderWidth: 0,
    width: 16,
    height: 16,
    fontSize: 8,
  },
  small: {
    width: 20,
    height: 20,
    fontSize: 8,
  },
  mediumShort: {
    width: 28,
    height: 28,
    fontSize: 10,
  },
  medium: {
    width: 32,
    height: 32,
    fontSize: 13,
  },
  large: {
    width: 64,
    height: 64,
    fontSize: 20,
  },
  'okr-map-tree-page': {
    width: 28,
    height: 28,
    fontSize: 10,
  },
  'okr-map-list-page': {
    width: 28,
    height: 28,
    fontSize: 10,
  },
  'user-page': {
    width: 48,
    height: 48,
    fontSize: 19,
  },
  'okr-page': {
    width: 44,
    height: 44,
    fontSize: 14,
  },
  'user-list-page': {
    width: 40,
    height: 40,
    fontSize: 14,
  },
  'user-list-page-resource-view': {
    width: 24,
    height: 24,
    fontSize: 10,
  },
  'side-nav-popover': {
    width: 54,
    height: 54,
    fontSize: 20,
  },
  'one-on-one-dashboard-page': {
    width: 80,
    height: 80,
    fontSize: 24,
  },
  'one-on-one-drawer-okr-tab': {
    width: 24,
    height: 24,
    fontSize: 10,
  },
}

export type Props = PropsWithoutRef<JSX.IntrinsicElements['div']> & {
  firstName: string
  lastName: string
  avatarUrl: string | undefined
  isUserDisabled: boolean
  size?: Size
  tooltipDisabled?: boolean
  linkage?: {
    termId?: string
    userId: string
    method?: LinkMethod
  }
  backgroundColor?: string
  avatarStyle?: CSSObject
  hoverStyle?: CSSObject
  disabledStyle?: CSSObject
}

export const Avatar: React.FC<Props> = ({
  firstName,
  lastName,
  avatarUrl,
  isUserDisabled,
  size = 'medium',
  tooltipDisabled = false,
  linkage,
  backgroundColor,
  avatarStyle = {},
  hoverStyle = { backgroundColor: 'rgba(0, 0, 0, 0.1)' },
  disabledStyle = {},
  ...props
}) => {
  const { t } = useTranslation()
  const { width, height, fontSize } = sizes[size]
  const styles = useStyles({
    width,
    height,
    fontSize,
    backgroundColor,
    avatarStyle,
    hoverStyle,
    disabledStyle,
  })
  const fullName = t('FULL_NAME', { firstName, lastName })
  const shortName =
    size === 'small'
      ? t('CHAR_NAME', { firstName, lastName })
      : t('SHORT_NAME', { firstName, lastName })

  const common = (
    <div {...props}>
      <UserTooltipNew
        title={fullName}
        disabled={tooltipDisabled}
        isUserDisabled={isUserDisabled}
        css={styles.tooltip}
      >
        <div css={styles.wrapper}>
          {avatarUrl ? (
            <img src={avatarUrl} alt={fullName} loading="lazy" css={styles.image} />
          ) : (
            shortName
          )}
        </div>
      </UserTooltipNew>
    </div>
  )

  if (!linkage) return common

  const url = linkage.termId
    ? generateHome(linkage.termId)(linkage.userId)
    : generateUser(linkage.userId)

  return (
    <Link href={url} method={linkage.method} css={styles.root}>
      {common}
    </Link>
  )
}

Avatar.displayName = 'Avatar'
