import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'

import { CheckinSummaryEditPageView } from '../../components/domain/CheckinSummaryEditPageView'
import { AuthRouteProps } from '../../types/authRouteProps'
import { generateCheckinSummary } from '../../urls'
import { useUpdateKeyResultSharedItemMutation } from '../CheckinSummary/graphql'

import { useCheckinSummaryQuery, useUpdateCheckinSummaryDescriptionMutation } from './graphql'

export const CheckinSummaryEditContainer: React.FC<AuthRouteProps> = () => {
  const navigate = useNavigate()
  const { checkinSummaryId } = useParams<{ checkinSummaryId: string }>()

  const summaryQuery = useCheckinSummaryQuery({
    variables: {
      checkinSummaryId: checkinSummaryId ?? '',
    },
    // NOTE: 古い情報が表示されてしまうためキャッシュを無効にする
    fetchPolicy: 'network-only',
    skip: checkinSummaryId == null,
  })

  const [updateDescription] = useUpdateCheckinSummaryDescriptionMutation({
    onCompleted: () => {
      navigate(generateCheckinSummary(checkinSummary.node.id, checkinSummary.id))
    },
  })
  const [updateKeyResultSharedItem] = useUpdateKeyResultSharedItemMutation()

  if (!summaryQuery.data?.checkinSummary) return null

  const { checkinSummary } = summaryQuery.data

  return (
    <CheckinSummaryEditPageView
      checkinSummary={checkinSummary}
      onClickDetailCancel={() => {
        navigate(generateCheckinSummary(checkinSummary.node.id, checkinSummary.id))
      }}
      onClickDetailSave={async (treeJson, plainText) => {
        try {
          await updateDescription({
            variables: {
              id: checkinSummary.id,
              description: {
                treeJson,
                plainText,
              },
            },
          })
        } catch (e) {
          // nothing to do
        }
      }}
      onClickUpdateSharedItem={(id, itemType, body) =>
        updateKeyResultSharedItem({
          variables: {
            id,
            itemType,
            body,
          },
          update(cache, { data: updatedData }) {
            if (!updatedData) return

            // 更新対象のkeyResultSharedItemが更新されたら（削除されていなければ）、Apollo Cacheの更新はしない
            if (updatedData.updateKeyResultSharedItem?.body) return

            // Apollo Cacheから削除したkeyResultSharedItemを削除
            cache.evict({ id: cache.identify(updatedData.updateKeyResultSharedItem) })
          },
        })
      }
    />
  )
}

CheckinSummaryEditContainer.displayName = 'CheckinSummaryEditContainer'
