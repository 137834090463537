import { MouseEventHandler } from 'react'

import { Icon, Type as IconType } from '../../../../../Icon'
import { StyledText } from '../../../../../StyledText'

type Props = {
  icon: IconType
  text: string
  onMouseDown: MouseEventHandler<HTMLButtonElement>
}

export const MenuButton: React.FC<Props> = ({ icon, text, onMouseDown }) => (
  <button
    type="button"
    onMouseDown={onMouseDown}
    css={{
      color: 'rgb(255 255 255 / 90%)',
      width: '100%',
      height: '100%',
      padding: '8px',
      display: 'flex',
      alignItems: 'center',
      gap: 8,
      marginLeft: 8,
    }}
  >
    <Icon type={icon} />
    <StyledText>{text}</StyledText>
  </button>
)

MenuButton.displayName = 'MenuButton'
