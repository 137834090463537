import { NoteDetailEditPageCard } from '../../components/domain/NoteDetailEditPageCard'
import { NoteOptionContainer } from '../../components/domain/NoteOption'
import { PageContent } from '../../components/pageContent'
import { color } from '../../styles/newColors'
import { AuthRouteProps } from '../../types/authRouteProps'

import { useInjection } from './hooks'

export const NoteDetailEditContainer: React.FC<AuthRouteProps> = (props) => {
  const data = useInjection(props)

  if (!data) {
    return null
  }

  const { note, breadcrumbs, onNoteUpdate, onClickCancel, setNeedBlock } = data

  return (
    <PageContent
      breadcrumbs={breadcrumbs}
      layout={{
        css: {
          backgroundColor: color('background-bk-5'),
          padding:
            '60px max(40px, calc((100% - 1045px)/2)) 0 max(40px, calc((100% - 1045px)/2) - 50px)',
          minWidth: '1175px',
        },
      }}
    >
      <div css={{ display: 'flex', width: '1095px' }}>
        <div
          css={{
            minWidth: '855px',
          }}
        >
          <NoteDetailEditPageCard
            note={note}
            updateNote={onNoteUpdate}
            onClickCancel={onClickCancel}
            setNeedBlock={setNeedBlock}
          />
        </div>
        <div css={{ marginTop: '9px', marginLeft: '8px', minWidth: '232px' }}>
          <NoteOptionContainer noteId={note.id} />
        </div>
      </div>
    </PageContent>
  )
}

NoteDetailEditContainer.displayName = 'NoteDetailEditContainer'
