import { Box, Text } from 'grommet'

import { DateTime } from '../../../../components/ui/DateTime'
import { StyledText } from '../../../../components/ui/StyledText'
import { useTranslation } from '../../../../i18n'
import { color } from '../../../../styles/newColors'

import { SecondaryInformationBoxProps } from './types'

export const SecondaryInformationSection: React.FC<SecondaryInformationBoxProps> = ({ user }) => {
  const { t } = useTranslation()
  return (
    <Box
      direction="row"
      gap="48px"
      css={{ background: color('white-100'), padding: '24px 32px', borderRadius: '8px' }}
    >
      <Box gap="12px">
        <StyledText size="small" fontStyle="bold" color="text-bk-50">
          {t('TWO_FACTOR_AUTH')}
        </StyledText>
        <StyledText size="medium" fontStyle="regular" lineHeight="22px">
          {user.otpRequiredForLogin ? t('ENABLED') : t('DISABLED')}
        </StyledText>
      </Box>
      <Box gap="12px">
        <StyledText size="small" fontStyle="bold" color="text-bk-50">
          {t('CREATED_AT')}
        </StyledText>
        <DateTime
          datetime={user.createdAt}
          withoutTime
          style={{ fontSize: '14px', lineHeight: '22px' }}
        />
      </Box>
      <Box gap="12px">
        <StyledText size="small" fontStyle="bold" color="text-bk-50">
          {t('LAST_LOGIN_AT')}
        </StyledText>
        {user.currentSignInAt && (
          <DateTime
            datetime={user.currentSignInAt}
            style={{ fontSize: '14px', lineHeight: '22px' }}
          />
        )}
      </Box>
      {!user.confirmedAt && (
        <Box gap="12px">
          <StyledText size="small" fontStyle="bold" color="text-bk-50">
            {t('CONFIRMATION_SENT_AT')}
          </StyledText>
          {user.confirmationSentAt && (
            <DateTime
              datetime={user.confirmationSentAt}
              style={{ fontSize: '14px', lineHeight: '22px' }}
            />
          )}
        </Box>
      )}
      {user.unconfirmedEmail && (
        <Box gap="12px">
          <StyledText size="small" fontStyle="bold" color="text-bk-50">
            {t('UNCONFIRMED_EMAIL')}
          </StyledText>
          <Text>{user.unconfirmedEmail}</Text>
        </Box>
      )}
    </Box>
  )
}
