import {
  ELEMENT_CODE_BLOCK,
  ELEMENT_CODE_LINE,
  getNodeString,
  isElement,
  isText,
  TNode,
} from '@udecode/plate'

// FIXME: SlateEditorへの依存を削除する
import { isListTypes } from '../../SlateEditor/plugins/elements/List/types'

// Node.string のList改行版
export const stringify = (node: TNode): string => {
  if (isText(node)) {
    return node.text
  }
  if (isElement(node)) {
    if (isListTypes(node.type)) {
      return node.children.map(stringify).join('\n')
    }
    if ([ELEMENT_CODE_BLOCK, ELEMENT_CODE_LINE].includes(node.type)) {
      return node.children.map(stringify).join('\n')
    }
  }
  return getNodeString(node)
}

export const serializeText = (nodes: ReadonlyArray<TNode>): string =>
  nodes.map(stringify).join('\n')
