import React from 'react'

import { stripeButtonCss } from '../../../styles'

type Props = {
  children: React.ReactNode
}

export const SubmitButton: React.VFC<Props> = ({ children, ...rest }) => (
  <button type="submit" css={[stripeButtonCss]} {...rest}>
    {children}
  </button>
)
