import { Box } from 'grommet'

import { FeatureName } from '../../../../../../../lib/testId'

import {
  KeyResultDescriptionProgressRateFormActualValueForm,
  Props as KeyResultDescriptionProgressRateFormActualValueFormProps,
} from './KeyResultDescriptionProgressRateFormActualValueForm'
import {
  FormValue,
  KeyResultDescriptionProgressRateFormSetting,
  Props as KRDescriptionProgressRateFormSettingProps,
} from './KeyResultDescriptionProgressRateFormSetting'

export type Props = {
  progressRateInput: FormValue
  keyResultDescriptionProgressRateFormSettingProps: Pick<
    KRDescriptionProgressRateFormSettingProps,
    'buttonProps'
  >
  updateProgressRate: (value: FormValue) => void
  onChangeIsEditing?: (value: boolean) => void
  ['data-testid-feature-name']?: FeatureName
} & Pick<KeyResultDescriptionProgressRateFormActualValueFormProps, 'updateType'>

export const KeyResultDescriptionProgressRateForm: React.FC<Props> = ({
  progressRateInput,
  keyResultDescriptionProgressRateFormSettingProps,
  updateProgressRate,
  updateType,
  onChangeIsEditing = () => {},
  'data-testid-feature-name': dataTestIdFeatureName,
}) => (
  <Box>
    {/* 実績値 */}
    <KeyResultDescriptionProgressRateFormActualValueForm
      updateType={updateType}
      progressRateInput={progressRateInput}
      updateProgressRate={updateProgressRate}
      onChangeIsEditing={onChangeIsEditing}
    />
    {/* 進捗率設定 カスタム設定のみ */}
    <KeyResultDescriptionProgressRateFormSetting
      progressRateInput={progressRateInput}
      updateProgressRate={updateProgressRate}
      onChangeIsEditing={onChangeIsEditing}
      data-testid-feature-name={dataTestIdFeatureName}
      buttonProps={keyResultDescriptionProgressRateFormSettingProps.buttonProps}
    />
  </Box>
)

KeyResultDescriptionProgressRateForm.displayName = 'KeyResultDescriptionProgressRateForm'
