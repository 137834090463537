import { Box } from 'grommet'
import React, { useEffect, useState } from 'react'

import { useTranslation } from '../../../i18n'
import { summaryTitle } from '../../../lib/domain/checkinSummary'
import { color } from '../../../styles/newColors'
import { Button } from '../../ui/Button'
import { RichTextEditor } from '../../ui/RichTextEditor'
import { StyledText } from '../../ui/StyledText'
import { TextButton } from '../../ui/TextButton'

import { CheckinSummaryFragment } from './graphql'

export type Props = {
  summary: CheckinSummaryFragment
  onClickCancel: () => void
  onClickSave: (treeJson: string, text: string) => void
  onEdit: (isEditing: boolean) => void
}

export const CheckInSummaryEditCard: React.FC<Props> = ({
  summary,
  onClickCancel,
  onClickSave,
  onEdit,
}) => {
  const { t } = useTranslation()
  const [body, setBody] = useState(summary.description?.treeJson)
  const [plainText, setPlainText] = useState(summary.description?.plainText)

  useEffect(() => {
    if (body === summary.description?.treeJson) {
      onEdit(false)
      return
    }

    onEdit(true)
  }, [body, onEdit, summary.description?.treeJson])

  return (
    <div
      css={{
        backgroundColor: color('white-100'),
        borderRadius: '4px',
        padding: '24px',
      }}
    >
      <StyledText
        color="text-bk-100"
        css={{
          fontSize: 24,
          lineHeight: '32px',
          fontWeight: 'bold',
          display: 'inline-block',
          marginBottom: 20,
        }}
      >
        {summaryTitle(t, summary)}
      </StyledText>

      <RichTextEditor
        id={summary.id}
        css={{
          height: '443px',
          overflowX: 'hidden',
          overflowY: 'scroll',
        }}
        initialValueJSON={body}
        onChange={(json, text) => {
          setBody(json)
          setPlainText(text)
        }}
      />
      <div css={{ display: 'flex', alignItems: 'flex-start' }}>
        <div css={{ flexGrow: 1 }} />
        <Box direction="row" justify="end" css={{ flexBasis: '218px', marginTop: '30px' }}>
          <TextButton color="text-bk-50" onClick={onClickCancel} css={{ marginRight: '8px' }}>
            {t('CANCEL')}
          </TextButton>
          <Button
            newColor="resily-orange-100"
            weight="normal"
            size="s"
            onClick={() => onClickSave(body || '', plainText || '')}
          >
            {t('SAVE')}
          </Button>
        </Box>
      </div>
    </div>
  )
}

CheckInSummaryEditCard.displayName = 'CheckInSummaryEditCard'
