import { css } from '@emotion/react'
import { useMemo } from 'react'

import { color } from '../../../../styles/newColors'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useStyles = () =>
  useMemo(
    () =>
      ({
        root: css({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
          gap: '24px',
        }),
        link: css({
          display: 'flex',
          alignItems: 'center',
          textDecoration: 'underline',
          // TODO: https://github.com/Resily/geisha/issues/504を対応した後、下記を削除する
          color: color('resily-orange-100'),
        }),
      } as const),
    [],
  )
