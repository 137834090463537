import React from 'react'

import { StyledText } from '../../../../components/ui/StyledText'

export type Props = {
  children: React.ReactNode
}

export const SectionTitle: React.VFC<Props> = ({ children }) => (
  <StyledText weight="bold" size="xlarge">
    {children}
  </StyledText>
)
