import React, { forwardRef } from 'react'

import { MenuBox, Props as MenuBoxProps } from '../MenuBox'

import * as styles from './styles'

export type Props = {
  title?: string
  menus: ReadonlyArray<MenuBoxProps & { key: string }>
}

export const MenuCard = forwardRef<HTMLDivElement, Props>(
  ({ title, menus, ...rest }: Props, ref: React.Ref<HTMLDivElement>) => (
    <div ref={ref} css={styles.rootCss} {...rest}>
      {title && <p css={styles.sectionCss}>{title}</p>}
      <ul css={styles.menusWrapperCss}>
        {menus.map((m) => (
          <li key={m.key} css={styles.menuCss}>
            <MenuBox {...m} />
          </li>
        ))}
      </ul>
    </div>
  ),
)
