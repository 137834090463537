import { Component, ReactNode } from 'react'

export class ErrorBoundary extends Component {
  override componentDidCatch(error: unknown, errorInfo: unknown): void {
    // eslint-disable-next-line no-console
    console.error(error, errorInfo)
  }

  override render(): ReactNode {
    return this.props.children
  }
}
