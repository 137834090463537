import { DocumentNode } from 'graphql'
import { matchPath, useLocation } from 'react-router-dom'

import { ActivitiesDocument as OkrModalKeyResultDrawerActivitiesDocument } from '../../../../components/standalone/OkrModal/KeyResultDrawer/Activity/graphql'
import { ActivitiesDocument as OkrModalObjectiveRightSectionActivitiesDocument } from '../../../../components/standalone/OkrModal/RightSection/Activity/graphql'
import { useOkrModalQueryParams } from '../../../../components/standalone/OkrModal/hooks/useOkrModalQueryParams'
import { ActivitiesDocument as HomeActivitiesDocument } from '../../../../pages/User/beta/tabs/Activity/graphql'
import { homeActivity } from '../../../../urls'

export const useActivityRefetchQueries = (): Array<DocumentNode> => {
  const location = useLocation()
  const isHomeActivity = matchPath({ path: homeActivity }, location.pathname)

  const { objectiveTab, keyResultTab } = useOkrModalQueryParams()
  const isObjectiveModalActivity = objectiveTab === 'm-o-activity'
  const isKeyResultModalActivity = keyResultTab === 'm-kr-activity'

  return new Array<DocumentNode>()
    .concat(isHomeActivity ? [HomeActivitiesDocument] : [])
    .concat(isObjectiveModalActivity ? [OkrModalObjectiveRightSectionActivitiesDocument] : [])
    .concat(isKeyResultModalActivity ? [OkrModalKeyResultDrawerActivitiesDocument] : [])
}
