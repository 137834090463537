import { css, keyframes } from '@emotion/react'
import { useProfiler } from '@sentry/react'
import * as basicprimitives from 'basicprimitives'
import { OrgDiagram, OrgDiagramRef } from 'basicprimitivesreact'
import { Spinner } from 'grommet'
import html2canvas from 'html2canvas'
import debounce from 'lodash.debounce'
import throttle from 'lodash.throttle'
import {
  Fragment,
  memo,
  VFC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
  MutableRefObject,
  RefObject,
} from 'react'
import isEqual from 'react-fast-compare'
import { useDebounce } from 'react-use'

import { useTranslation } from '../../../i18n'
import { OKRTree } from '../../../lib/domain/okr/tree'
import { clamp } from '../../../lib/number'
import { Point, mul as mulPoint, sub as subPoint, zero as zeroPoint } from '../../../lib/point'
import {
  center as centerRect,
  getRectInRectScaleKeepAspect,
  isIncluded,
  leftTop,
  Rect,
  translate as translateRect,
  union as unionRect,
} from '../../../lib/rect'
import { tracker } from '../../../lib/tracking'
import { color, hex2rgba } from '../../../styles/newColors'
import { useIsFoldOkrCardGlobal } from '../OkrCardIsFoldGlobalContext'

import { getNodeSize } from './OkrNode'
import { MINIMAP_HEIGHT, MINIMAP_WIDTH, MINIMAP_WINDOW_GAP } from './const'
import { OkrNode } from './types'

const MINIMAP_DOT_SIZE: basicprimitives.Size = { width: 16, height: 16 }

// ミニマップの外枠の大きさ
const MINIMAP_OUTER_PADDING = 8
// ミニマップの外枠とcanvasとの間のmargin
const MINIMAP_CANVAS_MARGIN = 8

const INFO_ANIM_CLASS = 'information-show'

const fadeIn = keyframes`
  0% { opacity: 0 }
  50% { opacity: 1 }
  100% { opacity: 0 }
`
const fadeAnimation = css({
  [`&.${INFO_ANIM_CLASS}`]: { animation: `${fadeIn} 2s infinite` },
})

const isDivElement = (elm: Node): elm is HTMLDivElement => elm.nodeName === 'DIV'

const getElementOffset = (el: Element): Point => {
  const br = el.getBoundingClientRect()
  return { x: br.left + global.window.pageXOffset, y: br.top + global.window.pageYOffset }
}

// 要素の全体矩形を取得
const getRectOfContent = (el: Element): Rect => {
  const { x, y } = getElementOffset(el)
  return {
    x: x + el.clientLeft - el.scrollLeft,
    y: y + el.clientTop - el.scrollTop,
    width: el.scrollWidth,
    height: el.scrollHeight,
  }
}

// 要素の視界内の矩形を取得
const getRectOfViewport = (el: Element): Rect => {
  const { x, y } = getElementOffset(el)
  return translateRect(
    {
      x: x + el.clientLeft,
      y: y + el.clientTop,
      width: el.clientWidth,
      height: el.clientHeight,
    },
    mulPoint(leftTop(getRectOfContent(el)), -1),
  )
}

const minimapNodeCss = css({
  backgroundColor: color('white-100'),

  '&[data-dot="true"]': {
    backgroundColor: 'blue',
    borderRadius: '50%',
  },

  '&:not([data-dot="true"])': {
    '&[data-cursor="true"]': {
      backgroundColor: color('resily-orange-100'),
    },
    '&[data-filtering="true"]': {
      '&[data-filter-target="false"]': {
        backgroundColor: color('text-bk-20'),
        '&[data-cursor="true"]': {
          backgroundColor: color('resily-orange-100'),
        },
      },
      '&[data-filter-target="true"][data-cursor="true"]': {
        backgroundColor: color('resily-orange-100'),
      },
    },
  },
})

export type Props = JSX.IntrinsicElements['nav'] & {
  cursorItem: string
  selectedItems: ReadonlyArray<string>
  okrTree: OKRTree<OkrNode>
  isFiltering: boolean
  filteredOkrNodeIds: ReadonlyArray<string>
  scrollTarget?: HTMLElement | null | undefined
}

// FIXME: グループの変更等、ミニマップに影響がない変更時にも再レンダリングが走る
export const OkrTreeMinimap: VFC<Props> = memo(
  ({
    cursorItem,
    selectedItems,
    okrTree,
    isFiltering,
    filteredOkrNodeIds,
    scrollTarget,
    ...props
  }) => {
    const { t } = useTranslation()
    const isFoldOkrCardKr = useIsFoldOkrCardGlobal()

    const minimapRef = useRef<HTMLElement>(null) // 外枠も含めたミニマップ全体
    const minimapAreaRef = useRef<HTMLDivElement>(null) // canvasの外側の見かけ上ミニマップっぽいとこ
    const canvasRef = useRef<HTMLCanvasElement | null>(null) // 最内側のcanvas
    const viewportRectRef = useRef<HTMLDivElement>(null) // 視界を表すrect
    const informationRef = useRef<HTMLDivElement>(null) // ユーザー案内用の文言

    const diagramRef = useRef<OrgDiagramRef>(null) // 裏にあるミニマップ用マップ
    const nodesRootRef = useRef<HTMLElement>() // 裏にあるミニマップ用マップ内のOKRカードらの親DOM

    const isDragging = useRef<boolean>(false) // ミニマップをD&Dで操作中かどうか
    const [scale, setScale] = useState(0.025)

    // マップが初期スケールでもはみ出るぐらい大きい時にそれでも収まるようなスケールを計算する
    useLayoutEffect(() => {
      const innerScrollTarget = diagramRef.current?.scrollPanelRef.current
      if (!innerScrollTarget) {
        return () => {}
      }

      const observer = new MutationObserver((mutations) => {
        mutations.forEach((m) => {
          if (isDivElement(m.target)) {
            if (m.target.offsetWidth < m.target.scrollWidth) {
              setScale(m.target.offsetWidth / m.target.scrollWidth)
            }
          }
        })
      })
      observer.observe(innerScrollTarget, { attributes: true })
      return () => observer.disconnect()
    }, [])

    // viewport rect の更新
    const reRenderViewport = useCallback(() => {
      if (
        !scrollTarget ||
        !viewportRectRef.current ||
        !minimapAreaRef.current ||
        !canvasRef.current
      ) {
        return
      }

      const scrollTargetRect = getRectOfContent(scrollTarget)
      const viewportRect = getRectOfViewport(scrollTarget)
      const minimapAreaRect = getRectOfContent(minimapAreaRef.current)
      const canvasRect = getRectOfContent(canvasRef.current)

      // 画面上の視界範囲をミニマップ上の縮尺に合わせて変換する
      const baseX = canvasRect.x - minimapAreaRect.x
      const baseY = canvasRect.y - minimapAreaRect.y
      const scaleX = canvasRect.width / scrollTargetRect.width
      const scaleY = canvasRect.height / scrollTargetRect.height
      const top =
        MINIMAP_OUTER_PADDING + -MINIMAP_CANVAS_MARGIN / 2 + baseY + viewportRect.y * scaleY
      const left =
        MINIMAP_OUTER_PADDING + -MINIMAP_CANVAS_MARGIN / 2 + baseX + viewportRect.x * scaleX
      const width = MINIMAP_CANVAS_MARGIN + viewportRect.width * scaleX
      const height = MINIMAP_CANVAS_MARGIN + viewportRect.height * scaleY

      viewportRectRef.current.style.top = `${top}px`
      viewportRectRef.current.style.left = `${left}px`
      viewportRectRef.current.style.width = `${width}px`
      viewportRectRef.current.style.height = `${height}px`
    }, [scrollTarget])

    // canvasの更新を検知して更新
    useLayoutEffect(() => {
      if (!canvasRef.current) {
        return () => {}
      }

      const observer = new MutationObserver(reRenderViewport)
      observer.observe(canvasRef.current, { attributes: true, attributeFilter: ['height'] })
      return () => observer.disconnect()
    }, [reRenderViewport])

    // 画面のリサイズを検知して更新
    useLayoutEffect(() => {
      if (!scrollTarget) {
        return () => {}
      }

      const resizeObserver = new ResizeObserver(reRenderViewport)
      resizeObserver.observe(scrollTarget)
      return () => resizeObserver.disconnect()
    }, [reRenderViewport, scrollTarget])

    // ミニマップをD&Dする以外の手段でマップのスクロールばっかしてたら
    // ミニマップ使ってねというアニメーションをしたいのでその検知をする
    // 発火条件：2秒以上スクロールが連続したら
    useLayoutEffect(() => {
      if (!scrollTarget || !informationRef.current) {
        return () => {}
      }
      const { current: info } = informationRef

      let scrollStartTime: number | undefined

      const scrollStartTimeEvent = throttle(
        (_: Event) => {
          if (scrollStartTime === undefined) {
            scrollStartTime = performance.now()
          }
        },
        500,
        { leading: true, trailing: false },
      )

      const scrollEndEvent = debounce(() => {
        scrollStartTime = undefined

        let prevRemoveIter = 0
        const removeAnimation = (_: AnimationEvent) => {
          // スクロール終了から何回かアニメーションしてからやめる
          if (prevRemoveIter < 1) {
            prevRemoveIter += 1
            return
          }

          info.classList.remove(INFO_ANIM_CLASS)
          info.removeEventListener('animationiteration', removeAnimation)
        }
        info.addEventListener('animationiteration', removeAnimation)
      }, 350)

      const scrollEvent = throttle(
        (_: Event) => {
          // ミニマップのD&Dによるスクロールは無視する
          if (!isDragging.current && scrollStartTime) {
            const elapsedTime = performance.now() - scrollStartTime
            if (elapsedTime > 2000) {
              scrollStartTime = undefined
              info.classList.add(INFO_ANIM_CLASS)
            }
          }
        },
        2100,
        { leading: false, trailing: true },
      )

      scrollTarget.addEventListener('scroll', scrollStartTimeEvent)
      scrollTarget.addEventListener('scroll', scrollEndEvent)
      scrollTarget.addEventListener('scroll', scrollEvent)
      return () => {
        scrollTarget.removeEventListener('scroll', scrollStartTimeEvent)
        scrollTarget.removeEventListener('scroll', scrollEndEvent)
        scrollTarget.removeEventListener('scroll', scrollEvent)
      }
    }, [scrollTarget])

    // ミニマップ内でスクロールされた場合はマップの方をスクロール
    useLayoutEffect(() => {
      if (!minimapRef.current) {
        return () => {}
      }

      const { current: minimapRefCurrent } = minimapRef

      const forwardScroll = (ev: WheelEvent) => {
        ev.preventDefault()
        if (scrollTarget) {
          scrollTarget.scrollBy(ev.deltaX, ev.deltaY)
        }
      }

      minimapRefCurrent.addEventListener('wheel', forwardScroll, true)
      return () => minimapRefCurrent.removeEventListener('wheel', forwardScroll, true)
    }, [scrollTarget])

    // ミニマップのD&Dスクロールイベントをセット
    useLayoutEffect(() => {
      if (
        !minimapAreaRef.current ||
        !canvasRef.current ||
        !viewportRectRef.current ||
        !scrollTarget
      ) {
        return () => {}
      }

      const { current: minimapAreaRefCurrent } = minimapAreaRef
      const { current: canvasRefCurrent } = canvasRef
      const { current: viewportRectRefCurrent } = viewportRectRef

      // viewport rect内を掴んだ時のための補正分
      let adjustPoint = zeroPoint()

      const onDrag = (ev: MouseEvent) => {
        ev.preventDefault()

        const scrollTargetContentRect = getRectOfContent(scrollTarget)
        const scrollTargetViewportRect = getRectOfViewport(scrollTarget)
        const canvasRect = getRectOfContent(canvasRefCurrent)

        // ミニマップ上での次の座標
        const minimapX = ev.pageX - canvasRect.x - adjustPoint.x
        const minimapY = ev.pageY - canvasRect.y - adjustPoint.y

        // マップ全体とミニマップとの比率を算出
        const scaleX = scrollTargetContentRect.width / canvasRect.width
        const scaleY = scrollTargetContentRect.height / canvasRect.height

        // ミニマップ上での座標を画面上の座標に変換して中央寄せ
        const x = minimapX * scaleX - scrollTargetViewportRect.width / 2
        const y = minimapY * scaleY - scrollTargetViewportRect.height / 2

        scrollTarget.scrollLeft = x
        scrollTarget.scrollTop = y

        // viewport rectの位置を修正
        reRenderViewport()
      }

      const onDragEnd = (ev: MouseEvent) => {
        ev.preventDefault()
        isDragging.current = false

        minimapAreaRefCurrent.style.cursor = 'pointer'
        minimapAreaRefCurrent.ownerDocument.body.style.cursor = 'auto'

        global.window.removeEventListener('mousemove', onDrag)
        global.window.removeEventListener('mouseup', onDragEnd)

        onDrag(ev)
        tracker.OkrMapClickMiniMap()
      }

      const onDragStart = (ev: MouseEvent) => {
        ev.preventDefault()
        isDragging.current = true

        // viewport rectの内側で押下した場合はスクロールの基準位置を変えるために記憶しておく
        const viewportRect = getRectOfContent(viewportRectRefCurrent)
        const downPoint: Point = { x: ev.pageX, y: ev.pageY }
        const isGrapped = isIncluded(viewportRect, downPoint)
        adjustPoint = isGrapped ? subPoint(downPoint, centerRect(viewportRect)) : zeroPoint()

        minimapAreaRefCurrent.style.cursor = 'crosshair'
        minimapAreaRefCurrent.ownerDocument.body.style.cursor = 'crosshair'

        global.window.addEventListener('mousemove', onDrag)
        global.window.addEventListener('mouseup', onDragEnd)

        onDrag(ev)
      }

      minimapAreaRefCurrent.style.cursor = 'pointer'
      minimapAreaRefCurrent.addEventListener('mousedown', onDragStart)
      scrollTarget.addEventListener('load', reRenderViewport)
      scrollTarget.addEventListener('scroll', reRenderViewport)

      return () => {
        minimapAreaRefCurrent.removeEventListener('mousedown', onDragStart)
        scrollTarget.removeEventListener('load', reRenderViewport)
        scrollTarget.removeEventListener('scroll', reRenderViewport)
      }
    }, [reRenderViewport, scrollTarget])

    // ミニマップ用のconfigを生成
    const config = useMemo(() => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      const treeConfig: basicprimitives.OrgConfig<basicprimitives.OrgItemConfig<OkrNode>> = {
        ...new basicprimitives.OrgConfig(),
        cursorItem,
        highlightItem: null,
        items: [],
        pageFitMode: basicprimitives.PageFitMode.None, // 常に全て展開
        verticalAlignment: basicprimitives.VerticalAlignmentType.Top,
        hasSelectorCheckbox: basicprimitives.Enabled.False,
        dotItemsInterval: 4,
        dotLevelShift: 48,
        normalItemsInterval: 40,
        normalLevelShift: 48,
        showCallout: false,
        scale,
      }

      okrTree.traversal((node) => {
        treeConfig.items.push({
          ...new basicprimitives.OrgItemConfig({
            itemTitleColor: undefined,
            templateName: node.id,
          }),
          ...node,
          parent: node.parent?.get(node)?.id ?? '',
        })

        const isCursor = node.id === cursorItem
        const isDot = !selectedItems.includes(node.id)
        const size = isDot ? MINIMAP_DOT_SIZE : getNodeSize(node, isFoldOkrCardKr)

        treeConfig.templates.push({
          ...new basicprimitives.TemplateConfig(),
          name: node.id,
          itemSize: size,
          onHighlightRender: () => null,
          onCursorRender: () => null,
          onItemRender: () => (
            // ミニマップ上に表示されるダミーノード
            <div
              ref={(r: HTMLDivElement) => {
                if (!nodesRootRef.current && r.parentElement?.parentElement) {
                  // ミニマップ用に全NodeとDotの親を保持
                  nodesRootRef.current = r.parentElement.parentElement
                }
              }}
              style={size}
              css={minimapNodeCss}
              data-cursor={isCursor}
              data-dot={isDot}
              data-filtering={isFiltering}
              data-filter-target={filteredOkrNodeIds.includes(node.id)}
            />
          ),
        })
      })

      return treeConfig
    }, [
      cursorItem,
      filteredOkrNodeIds,
      isFiltering,
      isFoldOkrCardKr,
      okrTree,
      scale,
      selectedItems,
    ])

    return (
      <Fragment>
        {/* ミニマップ画像取得用の極小マップをマップの裏側に置く */}
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            pointerEvents: 'none',
            zIndex: -1000,
          }}
        >
          <OrgDiagram
            ref={diagramRef}
            config={config}
            centerOnCursor={false}
            onCursorChanging={useCallback((_, data: basicprimitives.OrgEventArgs<OkrNode>) => {
              data.cancel = true
              return true
            }, [])}
          />
        </div>

        {/* ミニマップ本体 */}
        <nav
          ref={minimapRef}
          style={{
            position: 'fixed',
            bottom: `${MINIMAP_WINDOW_GAP}px`,
            right: `${MINIMAP_WINDOW_GAP}px`,
            transition: 'right 150ms cubic-bezier(0.820, 0.085, 0.395, 0.895)',
            padding: `${MINIMAP_OUTER_PADDING}px`,
            height: `${MINIMAP_HEIGHT}px`,
            width: `${MINIMAP_WIDTH}px`,
            backgroundColor: 'white',
            borderRadius: '4px',
            boxShadow: `0px 8px 16px 0px ${hex2rgba('#222943', 8)}`,
          }}
          {...props}
        >
          <div
            ref={minimapAreaRef}
            style={{
              backgroundColor: color('border-bk-10'),
              borderRadius: '4px',
              padding: `${MINIMAP_CANVAS_MARGIN}px`,
              height: '100%',
              width: '100%',
            }}
          >
            <MinimapCanvas
              canvasRef={canvasRef}
              nodesRootRef={nodesRootRef}
              deps={[config]}
              ignoreRender={false}
            />
          </div>

          {/* viewport rect */}
          <div
            ref={viewportRectRef}
            style={{
              border: '1px solid #3BABEA',
              position: 'absolute',
              width: '100%',
              height: '100%',
              pointerEvents: 'none',
            }}
          />

          {/* ユーザー向け案内 */}
          <div
            ref={informationRef}
            css={fadeAnimation}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '4px',
              position: 'relative',
              top: '-100%',
              width: '100%',
              height: '100%',
              pointerEvents: 'none',
              opacity: '0',
              color: 'white',
              fontSize: '2rem',
              fontWeight: 'bold',
              textShadow:
                '2px  2px 10px #777, -2px  2px 10px #777, 2px -2px 10px #777, -2px -2px 10px #777',
            }}
          >
            {t('MINIMAP_INFOMATION')}
          </div>
        </nav>
      </Fragment>
    )
  },
  (prev, next) => {
    if (prev.cursorItem !== next.cursorItem) {
      return false
    }
    if (prev.isFiltering !== next.isFiltering) {
      return false
    }
    if (!isEqual(prev.filteredOkrNodeIds, next.filteredOkrNodeIds)) {
      return false
    }
    if (!isEqual(prev.selectedItems, next.selectedItems)) {
      return false
    }
    if (!isEqual(prev.scrollTarget, next.scrollTarget)) {
      return false
    }
    if (prev.okrTree !== next.okrTree) {
      return false
    }
    return true
  },
)
OkrTreeMinimap.displayName = 'OKRTreeMinimap'

// ミニマップの背景となるマップ全体の縮小画像がレンダリングされるcanvas
// FIXME: 同じOKRカード内の別KRを選択するとなぜか再レンダリングされる
const MinimapCanvas: VFC<{
  canvasRef?: MutableRefObject<HTMLElement | null>
  nodesRootRef: RefObject<HTMLElement | undefined>
  deps: Array<unknown>
  ignoreRender: boolean
}> = memo(
  ({ canvasRef, nodesRootRef, deps, ignoreRender }) => {
    useProfiler('MinimapCanvas')
    const innerCanvasRef = useRef<HTMLCanvasElement | null>(null)

    const [isRendering, setIsRendering] = useState(true)

    const [backgroundRerenderCount, setBackgroundRerenderCount] = useState(0)
    const reRenderBackground = useCallback(() => setBackgroundRerenderCount((prev) => prev + 1), [])

    // ミニマップの更新が終わるまでspinnerを表示
    useEffect(() => {
      setIsRendering(true)
      reRenderBackground()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reRenderBackground, ...deps])

    useDebounce(
      () => {
        if (!innerCanvasRef.current || !nodesRootRef.current || ignoreRender) {
          // ウィンドウが非表示等で取得に失敗する場合は定期的に再度実行を試みる
          // ドロワーが移動中にminimapを更新するとアニメーションがフラッシングを起こすので、処理をずらす
          setTimeout(reRenderBackground, 600)
          return
        }

        // 最小枠で切り抜いたcanvasを生成するために全体が収まるようなrectを計算する
        const boundingRect = unionRect(
          ...[...nodesRootRef.current.children].map((c) => c.getBoundingClientRect()),
        )
        const clipRect = translateRect(
          boundingRect,
          mulPoint(getElementOffset(nodesRootRef.current), -1),
        )

        // HACK: これがないとミニマップが壊れる
        nodesRootRef.current.style.width = '100%'
        nodesRootRef.current.style.height = '100%'

        // スクショ取得
        // scale上限15 = clipRectが最大サイズでもcanvasの上限に到達しないような値
        // @see  https://developer.mozilla.org/ja/docs/Web/HTML/Element/canvas#%E3%82%AD%E3%83%A3%E3%83%B3%E3%83%90%E3%82%B9%E3%81%AE%E6%9C%80%E5%A4%A7%E5%AF%B8%E6%B3%95
        // @args options https://html2canvas.hertzen.com/configuration
        html2canvas(nodesRootRef.current, {
          ...clipRect,
          backgroundColor: color('border-bk-10'),
          scale: clamp(window.devicePixelRatio * 5, 1, 15),
          logging: false,
        }).then((rawCanvas) => {
          // INFO: ミニマップのデバッグ用
          // const dlImg = document.createElement('a')
          // dlImg.setAttribute('href', rawCanvas.toDataURL())
          // dlImg.setAttribute('download', 'html2canvas.png')
          // document.body.appendChild(dlImg)
          // dlImg.click()
          // dlImg.remove()

          const parentElement = innerCanvasRef.current?.parentElement
          const canvasCtx = innerCanvasRef.current?.getContext('2d', { alpha: false })
          if (!innerCanvasRef.current || !parentElement || !canvasCtx) {
            setTimeout(reRenderBackground, 600)
            return
          }

          // 親コンポーネントに綺麗に収まるような比率を計算
          const keepAspectScale = getRectInRectScaleKeepAspect(
            rawCanvas,
            getRectOfContent(parentElement),
          )

          // canvas初期化
          innerCanvasRef.current.width = rawCanvas.width * keepAspectScale
          innerCanvasRef.current.height = rawCanvas.height * keepAspectScale
          canvasCtx.setTransform(1, 0, 0, 1, 0, 0)
          canvasCtx.scale(1, 1)
          canvasCtx.clearRect(0, 0, innerCanvasRef.current.width, innerCanvasRef.current.height)

          // html2canvasの結果を描画
          canvasCtx.scale(keepAspectScale, keepAspectScale)
          if (rawCanvas.width !== 0 && rawCanvas.height !== 0) {
            canvasCtx.drawImage(rawCanvas, 0, 0)
          }

          setIsRendering(false)
        })
      },
      500, // 一括で畳み状態の変更が入るとミニマップの更新と重なった時固まるのでタイミングを遅らせている
      [backgroundRerenderCount, reRenderBackground],
    )

    return (
      <div style={{ textAlign: 'center', width: '100%', height: '100%' }}>
        {/* 本体 */}
        <canvas
          ref={(e) => {
            innerCanvasRef.current = e
            if (canvasRef) {
              canvasRef.current = e
            }
          }}
          style={{ verticalAlign: 'top' }}
          width="208"
          height="168"
        />

        {/* 再レンダリング中を表現する */}
        <div
          style={{
            display: isRendering ? 'flex' : 'none',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgb(145 148 161 / 20%)',
            borderRadius: '4px',
            position: 'absolute',
            top: MINIMAP_OUTER_PADDING,
            left: MINIMAP_OUTER_PADDING,
            width: `calc(100% - ${MINIMAP_OUTER_PADDING * 2}px)`,
            height: `calc(100% - ${MINIMAP_OUTER_PADDING * 2}px)`,
          }}
        >
          <Spinner />
        </div>
      </div>
    )
  },
  (prev, next) => {
    if (prev.ignoreRender !== next.ignoreRender) {
      return false
    }
    if (!isEqual(prev.canvasRef, next.canvasRef)) {
      return false
    }
    if (!isEqual(prev.nodesRootRef, next.nodesRootRef)) {
      return false
    }
    if (!isEqual(prev.deps, next.deps)) {
      return false
    }
    return true
  },
)
MinimapCanvas.displayName = 'MinimapCanvas'
