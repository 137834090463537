import { yupResolver } from '@hookform/resolvers/yup'
import { Box } from 'grommet'
import { FC, Fragment, useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import * as Yup from 'yup'

import { Button } from '../../../../../../../components/ui/Button'
import { FormField } from '../../../../../../../components/ui/FormField'
import { NumberInput } from '../../../../../../../components/ui/NumberInput'
import { StyledText } from '../../../../../../../components/ui/StyledText'
import { TextButton } from '../../../../../../../components/ui/TextButton'
import { Textarea } from '../../../../../../../components/ui/Textarea'
import { useTranslation } from '../../../../../../../i18n'

import { FormValue } from './KeyResultDescriptionProgressRateFormSetting'
import { KeyResultUpdateType } from './graphql'

export type Props = {
  updateType: KeyResultUpdateType
  progressRateInput: FormValue
  updateProgressRate: (value: FormValue) => void
  onChangeIsEditing?: (value: boolean) => void
}

export const KeyResultDescriptionProgressRateFormActualValueForm: FC<Props> = ({
  updateType,
  progressRateInput,
  updateProgressRate,
  onChangeIsEditing = () => {},
}) => {
  const { t } = useTranslation()
  const [isEditingState, setIsEditingState] = useState<boolean>(false)
  const setIsEditing = (value: boolean) => {
    setIsEditingState(value)
    onChangeIsEditing(value)
  }

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<FormValue>({
    mode: 'onChange',
    defaultValues: progressRateInput,
    resolver: yupResolver(
      Yup.object().shape({
        progressRate: Yup.number().integer().nullable().typeError(t('ONLY_NUMBER')),
        initialValue: Yup.number().typeError(t('ONLY_NUMBER')),
        targetValue: Yup.number().typeError(t('ONLY_NUMBER')),
        actualValue: Yup.number().typeError(t('ONLY_NUMBER')),
      }),
    ),
  })

  useEffect(() => {
    if (!isEditingState) {
      reset({
        actualValue: progressRateInput.actualValue ?? 0,
        message: progressRateInput.message,
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progressRateInput.actualValue, progressRateInput.message, reset])

  const update = (values: FormValue) => {
    updateProgressRate({
      ...values,
      targetValue: progressRateInput.targetValue,
      initialValue: progressRateInput.initialValue,
      unit: progressRateInput.unit,
    })
    setValue('message', undefined)
    setIsEditing(false)
  }

  const resetValue = () => {
    setValue('actualValue', progressRateInput.actualValue)
    setValue('message', undefined)
    setIsEditing(false)
  }

  const isAuto = updateType === KeyResultUpdateType.Automate
  const needProgressRateSetting = isAuto && progressRateInput.targetValue == null

  return (
    <form onSubmit={handleSubmit(update)}>
      <Fragment key="actualValue1">
        <Box direction="row" justify="start" width="100%" css={{ marginTop: 8 }}>
          {needProgressRateSetting ? (
            <StyledText color="text-bk-100" size="xsmall" css={{ lineHeight: '14px' }}>
              {t('PROGRESS_RATE_HAS_NOT_SET')}
              <br />
              {t('ENTER_TARGET_VALUE_AND_UNIT')}
            </StyledText>
          ) : (
            <FormField
              label={t('ACTUAL_VALUE')}
              labelColor="text-bk-50"
              labelFontSize="xsmall"
              errorMessage={errors.actualValue?.message}
              css={{ margin: 0, maxWidth: 192 }}
            >
              <Controller
                name="actualValue"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <NumberInput
                    maxWidth={180}
                    defaultValue={value || 0}
                    onFocus={() => setIsEditing(true)}
                    onChange={onChange}
                  />
                )}
              />
              <StyledText
                size="small"
                oneline
                alignSelf="center"
                css={{
                  marginLeft: 4,
                  textOverflow: 'ellipsis',
                  overflowX: 'hidden',
                  whiteSpace: 'nowrap',
                  lineHeight: 'normal',
                }}
              >
                {progressRateInput.unit}
              </StyledText>
            </FormField>
          )}
        </Box>
      </Fragment>
      {isEditingState && (
        <Fragment>
          <FormField css={{ width: '100%' }} errorMessage={errors.message?.message}>
            <Controller
              name="message"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Textarea
                  value={value || ''}
                  placeholder={t('UPDATE_MEMOS_ONE_LINE')}
                  onChange={(e) => onChange(e.target.value || undefined)}
                />
              )}
            />
          </FormField>
          <Box direction="row" justify="end" wrap width="100%">
            <TextButton
              color="text-bk-50"
              type="button"
              onClick={resetValue}
              css={{ marginRight: '8px' }}
            >
              {t('CANCEL')}
            </TextButton>
            <Button
              newColor="resily-orange-100"
              disabledColor="white-100"
              weight="normal"
              size="s"
              type="submit"
              css={{ alignSelf: 'flex-end' }}
            >
              {t('UPDATION')}
            </Button>
          </Box>
        </Fragment>
      )}
    </form>
  )
}

KeyResultDescriptionProgressRateFormActualValueForm.displayName =
  'KeyResultDescriptionProgressRateFormActualValueForm'
