import { Icon } from '../../../../../components/ui/Icon'
import { StyledText } from '../../../../../components/ui/StyledText'
import { useTranslation } from '../../../../../i18n'

import { ActivityCard, Props as ActivityCardProps } from './ActivityCard'
import { ActivityCardSkelton } from './ActivityCardSkelton'
import { ActivityEdgeFragment } from './graphql'
import { styles } from './styles/index.styles'

export type ActivityProps = {
  termId: string
  loading: boolean
  activities: ReadonlyArray<ActivityEdgeFragment>
  showMore?: boolean
  onClickMore: () => void
} & Pick<ActivityCardProps, 'onClickKeyResultName'>

export const Activity: React.FC<ActivityProps> = ({
  termId,
  loading,
  activities,
  onClickKeyResultName,
  showMore,
  onClickMore,
}) => {
  const { t } = useTranslation()

  return (
    <>
      {/* eslint-disable-next-line no-nested-ternary */}
      {loading ? (
        <>
          {new Array(2).fill(undefined).map(() => (
            <>
              <ActivityCardSkelton showCheckinComment />
              <ActivityCardSkelton />
            </>
          ))}
        </>
      ) : activities.length > 0 ? (
        <>
          {activities.map((ac) => (
            <div key={ac.cursor} css={styles.activityCardWrapper}>
              <ActivityCard
                termId={termId}
                activityEdge={ac}
                onClickKeyResultName={onClickKeyResultName}
              />
            </div>
          ))}
          {showMore && (
            <button type="button" onClick={onClickMore} css={styles.fetchMoreButton}>
              <div>{t('SHOW_MORE')}</div>
              <Icon type="keyboardArrowDown" />
            </button>
          )}
        </>
      ) : (
        <div css={styles.emptyWrapper}>
          <div css={styles.emptyInner}>
            <StyledText size="large" lineHeight="16px">
              {t('X_IS_EMPTY', { x: t('ACTIVITY') })}
            </StyledText>
          </div>
          <Icon width={302} height={154} type="mailboxCat" />
        </div>
      )}
    </>
  )
}
Activity.displayName = 'Activity'
