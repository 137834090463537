import { css } from '@emotion/react'

export const boxCss = css({
  display: 'flex',
  gap: 4,
  flexDirection: 'column',
})

export const containerCss = css({
  display: 'flex',
  gap: 29,
  flexDirection: 'row',
  alignItems: 'center',
})
