import { convertFullCharToHalf } from './convertFullCharToHalf'
import { convertFullNumberToHalf } from './convertFullNumberToHalf'

const removeUnNumber = (target: string): string => {
  let hasPeriod = false
  return [...target].reduce((acc, crr) => {
    if (
      crr.match(/[^0-9.]/) || // 数値・ピリオド以外を削除
      (hasPeriod && crr === '.') // 最初のピリオド以外を削除
    )
      return acc

    if (crr === '.') hasPeriod = true
    return acc + crr
  }, '')
}

export const convertNumber = (input: string): string => {
  const halfString = convertFullCharToHalf(convertFullNumberToHalf(input))

  if (halfString === '0-' || halfString === '-') return '-'

  const sign = halfString.charAt(0) === '-' ? '-' : ''
  const unsignedNumber = sign ? halfString.slice(1) : halfString

  const number = removeUnNumber(unsignedNumber)
  if (!number.includes('.')) return `${sign}${String(Number(number))}`

  const [integerPart, decimalPart] = number.split('.')
  return `${sign}${String(Number(integerPart))}.${decimalPart}`
}
