import { TagButton } from '@resily/geisha'
import React, { useContext } from 'react'

import { OkrTermIdContext } from '../../../../../../contexts/OkrTermIdContext'
import { useOkrTermsQuery } from '../../../../../../contexts/graphql'
import { useTranslation } from '../../../../../../i18n'
import { findScreen } from '../../../../../../lib/screen'
import { tracker } from '../../../../../../lib/tracking'
import { CheckinSummaryFragment } from '../graphql'

import { useStyles } from './CheckinAreaList.styles'
import { useDisplayUpperLimitData } from './hooks/useDisplayUpperLimitData'

export type CheckinAreaListProps = {
  data: ReadonlyArray<CheckinSummaryFragment>
  showFetchMore: boolean
  onClickFetchMore: () => void
  onClickOpenCheckinSummary: (id: string, isOkrMeeting: boolean) => string | undefined
}

export const CheckinAreaList = React.memo<CheckinAreaListProps>(
  ({ data, showFetchMore, onClickFetchMore, onClickOpenCheckinSummary }) => {
    const { okrTermId } = useContext(OkrTermIdContext)
    const { t } = useTranslation()
    const styles = useStyles()
    const displayData = useDisplayUpperLimitData(data, showFetchMore)
    const { data: okrTermsData } = useOkrTermsQuery()

    return (
      <div css={styles.root}>
        {displayData.map((d, i) => {
          const isPrimary = okrTermsData?.okrTerms?.find((term) => term.primary)?.id === okrTermId
          const checkinSummaryUrl = onClickOpenCheckinSummary(d.id, isPrimary && i === 0)
          if (!checkinSummaryUrl) return null

          return (
            <button
              key={d.id}
              type="button"
              onClick={() => {
                tracker.UserClickCheckInSummeryButtonByObjectiveModal(
                  findScreen(window.location.pathname, window.location.search),
                )
              }}
            >
              <TagButton
                iconProps={{
                  type: 'check',
                  color: d.isCheckinDone ? 'state-green' : 'foreground-black-bk30',
                }}
                size="small"
                target="_blank"
                to={checkinSummaryUrl}
                label={d.label}
              />
            </button>
          )
        })}
        {showFetchMore && (
          <div css={styles.buttonWrapper}>
            <button
              type="button"
              onClick={() => {
                tracker.UserClickOkrModalInCheckinFetchMore(
                  findScreen(window.location.pathname, window.location.search),
                )
                onClickFetchMore()
              }}
              css={styles.button}
            >
              {t('SHOW_MORE')}
            </button>
          </div>
        )}
      </div>
    )
  },
)
