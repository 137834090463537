import { css } from '@emotion/react'
import { ChangeEvent, FocusEvent, forwardRef, KeyboardEvent, useRef } from 'react'

import { convertFullCharToHalf } from '../../../lib/convertFullCharToHalf'
import { convertFullNumberToHalf } from '../../../lib/convertFullNumberToHalf'
import { Input, InputProps } from '../../ui/Input'

export type Props = InputProps & {
  minWidth?: number
  maxWidth?: number
  growCharaterNumber?: number
  defaultValue?: number | string
  allowDecimal?: boolean
}

const inputCss = css({
  padding: '0 8px',
  textAlign: 'center',
})

export const KeyResultProgressRateFormInput = forwardRef<HTMLInputElement, Props>(
  (
    {
      minWidth = 48,
      maxWidth = 107,
      growCharaterNumber = 2,
      defaultValue = 0,
      allowDecimal = true,
      ...props
    },
    ref,
  ) => {
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    const preValue = useRef(String(defaultValue))

    const getInputWidth = (text: string): string => {
      if (!ctx) {
        return `${minWidth}px`
      }
      const textWidth = ctx.measureText(text).width
      if (text.length <= growCharaterNumber) {
        return `${minWidth}px`
      }
      const styleWidth = Math.floor(minWidth + textWidth - 23)
      if (styleWidth > maxWidth) {
        return `${maxWidth}px`
      }
      return `${styleWidth}px`
    }

    const changeWidthByTextLength = (e: ChangeEvent<HTMLInputElement>) => {
      const t = e.currentTarget
      t.style.width = getInputWidth(t.value)
    }

    const moveCursorToEndOfLine = (e: FocusEvent<HTMLInputElement>) => {
      const t = e.currentTarget
      t.setSelectionRange(t.value.length, t.value.length)
    }

    const filterFloatNegativeNumber = (e: KeyboardEvent<HTMLInputElement>) => {
      const target: HTMLInputElement = e.currentTarget
      const convertValue = convertFullCharToHalf(convertFullNumberToHalf(target.value))
      if (
        (allowDecimal && Number.isFinite(Number(convertValue))) ||
        (!allowDecimal && Number.isInteger(Number(convertValue))) ||
        convertValue === '-'
      ) {
        preValue.current = convertValue
        target.value = convertValue
      } else {
        target.value = preValue.current
      }
    }

    return (
      <Input
        required
        type="text"
        fontSize="medium"
        fieldStyle="bottomLine"
        css={inputCss}
        ref={ref}
        onFocus={moveCursorToEndOfLine}
        onChange={changeWidthByTextLength}
        onInput={filterFloatNegativeNumber}
        width={getInputWidth(String(defaultValue))}
        defaultValue={defaultValue}
        {...props}
      />
    )
  },
)

KeyResultProgressRateFormInput.displayName = 'KeyResultProgressRateFormInput'
