import React from 'react'

import { customerPortalFormURL, resubscriptionFormURL } from '../../constants'
import { Form } from '../Form'

import { rootCss } from './styles'

export const ExpiredContent: React.VFC = () => (
  <div css={rootCss}>
    <Form action={customerPortalFormURL}>
      <Form.TitleSection>
        <Form.TitleSection.Title>お支払い履歴</Form.TitleSection.Title>
        <Form.TitleSection.Description>お支払い履歴が確認できます。</Form.TitleSection.Description>
      </Form.TitleSection>
      <Form.SubmitButton>お支払い履歴を確認</Form.SubmitButton>
    </Form>
    <Form action={resubscriptionFormURL}>
      <Form.TitleSection>
        <Form.TitleSection.Title>プランの再登録</Form.TitleSection.Title>
        <Form.TitleSection.Description>
          現在の「有効ユーザー」の数でResilyのプランに再登録することができます。
        </Form.TitleSection.Description>
      </Form.TitleSection>
      <Form.SubmitButton>プランの再登録に進む</Form.SubmitButton>
    </Form>
  </div>
)
