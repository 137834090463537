import { useCallback } from 'react'

import { useUpdatePasswordMutation } from '../api/graphql'
import {
  Props as PasswordSettingProps,
  FormValues as PasswordSettingFormValues,
} from '../components/PasswordSetting'

export const usePasswordSetting = (): PasswordSettingProps => {
  const [updatePassword] = useUpdatePasswordMutation()

  const handleUpdatePassword = useCallback<PasswordSettingProps['updatePassword']>(
    async (values: PasswordSettingFormValues) => {
      await updatePassword({
        variables: values,
      })
    },
    [updatePassword],
  )

  return {
    updatePassword: handleUpdatePassword,
  }
}
