import { Box } from 'grommet'

import { Button } from '../../../../components/ui/Button'
import { TextButton } from '../../../../components/ui/TextButton'
import { useTranslation } from '../../../../i18n'

import { EditButtonSectionProps } from './types'

export const EditButtonSection: React.FC<EditButtonSectionProps> = ({ onClick }) => {
  const { t } = useTranslation()
  return (
    <Box align="end">
      <Box direction="row" margin={{ top: 'medium' }} gap="8px">
        <TextButton color="text-bk-50" hoverColor="resily-orange-100" onClick={onClick}>
          {t('CANCEL')}
        </TextButton>
        <Button newColor="resily-orange-100" weight="normal" size="s" type="submit">
          {t('SAVE')}
        </Button>
      </Box>
    </Box>
  )
}
