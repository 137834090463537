import {
  AutoformatBlockRule,
  AutoformatMarkRule,
  AutoformatRule,
  ELEMENT_BLOCKQUOTE,
  ELEMENT_CODE_BLOCK,
  ELEMENT_CODE_LINE,
  ELEMENT_DEFAULT,
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_H4,
  ELEMENT_H5,
  ELEMENT_H6,
  ELEMENT_HR,
  ELEMENT_OL,
  ELEMENT_TODO_LI,
  ELEMENT_UL,
  getBlockAbove,
  getNodeEntries,
  getParentNode,
  insertEmptyCodeBlock,
  insertNodes,
  isBlock,
  isElement,
  isType,
  KEY_LIST_STYLE_TYPE,
  ListStyleType,
  MARK_BOLD,
  MARK_ITALIC,
  MARK_UNDERLINE,
  MARK_STRIKETHROUGH,
  MARK_CODE,
  outdentList,
  PlateEditor,
  setNodes,
  TEditor,
  TTodoListItemElement,
  toggleIndentList,
  unsetNodes,
} from '@udecode/plate'

import { EMPTY_PARAGRAPH } from '../../constants'
import { HrElement, isIndentList, ParagraphElement } from '../../types'

export const clearListStyle: NonNullable<AutoformatBlockRule['preFormat']> = (editor) => {
  const nodes = Array.from(getNodeEntries(editor, { block: true }))
  if (nodes.length === 0) {
    return
  }

  const node = nodes[0][0]
  if (isElement(node) && isIndentList(node)) {
    // blockがリストだった場合のみoutdent
    outdentList(editor as PlateEditor)
  }
  unsetNodes(editor, [KEY_LIST_STYLE_TYPE, 'checked'])
}

// リストと他のElementが合体する対策
const clearElementForListAutoFormat = (editor: TEditor): void => {
  const block = getBlockAbove(editor)?.[0]
  if (
    isElement(block) &&
    [
      ELEMENT_BLOCKQUOTE,
      ELEMENT_TODO_LI,
      ELEMENT_H1,
      ELEMENT_H2,
      ELEMENT_H3,
      ELEMENT_H4,
      ELEMENT_H5,
      ELEMENT_H6,
    ].includes(block.type)
  ) {
    setNodes(editor, { type: ELEMENT_DEFAULT })
  }
}
const denyAutoFormatInSameListTypeQuery =
  (type: typeof ELEMENT_OL | typeof ELEMENT_UL): NonNullable<AutoformatRule['query']> =>
  (editor) => {
    const block = getBlockAbove(editor)?.[0]
    if (!isElement(block) || !isIndentList(block)) {
      return true
    }

    // 同リスト下でのautoformatは無視
    if (type === ELEMENT_OL && block.listStyleType === ListStyleType.Decimal) {
      return false
    }
    if (type === ELEMENT_UL && block.listStyleType === ListStyleType.Disc) {
      return false
    }

    return true
  }

const denyAutoFormatInCodeBlockQuery: NonNullable<AutoformatRule['query']> = (editor) => {
  const blockEntry = getBlockAbove(editor)
  if (!blockEntry) {
    return true
  }

  const [block, path] = blockEntry
  if (!isElement(block) || block.type !== ELEMENT_CODE_LINE) {
    return true
  }

  const parentBlock = getParentNode(editor, path)?.[0]
  if (!isElement(parentBlock) || parentBlock.type !== ELEMENT_CODE_BLOCK) {
    return true
  }

  return false
}

const format = (editor: TEditor, customFormatting: () => void) => {
  if (editor.selection) {
    const parentEntry = getParentNode(editor, editor.selection)
    if (!parentEntry) return
    const [node] = parentEntry
    if (
      isElement(node) &&
      !isType(editor as PlateEditor, node, ELEMENT_CODE_BLOCK) &&
      !isType(editor as PlateEditor, node, ELEMENT_CODE_LINE)
    ) {
      customFormatting()
    }
  }
}

const formatList = (editor: TEditor, elementType: typeof ELEMENT_OL | typeof ELEMENT_UL) => {
  format(editor, () => {
    toggleIndentList(editor as PlateEditor, {
      listStyleType: elementType === ELEMENT_OL ? ListStyleType.Decimal : ListStyleType.Disc,
    })
  })
}

const autoFormatMarks: Array<AutoformatMarkRule> = [
  {
    mode: 'mark',
    type: [MARK_BOLD, MARK_ITALIC],
    match: '***',
  },
  {
    mode: 'mark',
    type: [MARK_UNDERLINE, MARK_ITALIC],
    match: '__*',
  },
  {
    mode: 'mark',
    type: [MARK_UNDERLINE, MARK_BOLD],
    match: '__**',
  },
  {
    mode: 'mark',
    type: [MARK_UNDERLINE, MARK_BOLD, MARK_ITALIC],
    match: '___***',
  },
  {
    mode: 'mark',
    type: MARK_BOLD,
    match: '**',
  },
  {
    mode: 'mark',
    type: MARK_UNDERLINE,
    match: '__',
  },
  {
    mode: 'mark',
    type: MARK_ITALIC,
    match: '*',
  },
  {
    mode: 'mark',
    type: MARK_ITALIC,
    match: '_',
  },
  {
    mode: 'mark',
    type: MARK_STRIKETHROUGH,
    match: '~~',
  },
  {
    mode: 'mark',
    type: MARK_CODE,
    match: '`',
  },
]

const autoFormatLists: Array<AutoformatBlockRule> = [
  {
    mode: 'block',
    type: ELEMENT_UL,
    match: ['* ', '- ', '+ '],
    preFormat: (editor) => {
      clearListStyle(editor)
      clearElementForListAutoFormat(editor)
    },
    format: (editor) => formatList(editor, ELEMENT_UL),
    query: denyAutoFormatInSameListTypeQuery(ELEMENT_UL),
  },
  {
    mode: 'block',
    type: ELEMENT_OL,
    match: ['1. ', '1) '],
    preFormat: (editor) => {
      clearListStyle(editor)
      clearElementForListAutoFormat(editor)
    },
    format: (editor) => formatList(editor, ELEMENT_OL),
    query: denyAutoFormatInSameListTypeQuery(ELEMENT_OL),
  },
  {
    mode: 'block',
    type: ELEMENT_TODO_LI,
    match: '[] ',
    preFormat: clearListStyle,
  },
  {
    mode: 'block',
    type: ELEMENT_TODO_LI,
    match: '[x] ',
    preFormat: clearListStyle,
    format: (editor) =>
      setNodes(editor, { type: ELEMENT_TODO_LI, checked: true } as TTodoListItemElement, {
        match: (n) => isBlock(editor, n),
      }),
  },
]

const autoFormatBlocks: Array<AutoformatBlockRule> = [
  {
    mode: 'block',
    type: ELEMENT_H1,
    match: '# ',
    preFormat: clearListStyle,
  },
  {
    mode: 'block',
    type: ELEMENT_H2,
    match: '## ',
    preFormat: clearListStyle,
  },
  {
    mode: 'block',
    type: ELEMENT_H3,
    match: '### ',
    preFormat: clearListStyle,
  },
  {
    mode: 'block',
    type: ELEMENT_H4,
    match: '#### ',
    preFormat: clearListStyle,
  },
  {
    mode: 'block',
    type: ELEMENT_H5,
    match: '##### ',
    preFormat: clearListStyle,
  },
  {
    mode: 'block',
    type: ELEMENT_H6,
    match: '###### ',
    preFormat: clearListStyle,
  },
  {
    mode: 'block',
    type: ELEMENT_BLOCKQUOTE,
    match: '> ',
    preFormat: clearListStyle,
  },
  {
    mode: 'block',
    type: ELEMENT_CODE_BLOCK,
    match: '```',
    preFormat: clearListStyle,
    format: (editor: TEditor): void => {
      insertEmptyCodeBlock(editor as PlateEditor, { insertNodesOptions: { select: true } })
    },
  },
  {
    mode: 'block',
    type: ELEMENT_HR,
    match: '---',
    preFormat: clearListStyle,
    format: (editor: TEditor): void => {
      setNodes<HrElement>(editor, {
        type: ELEMENT_HR,
      })
      insertNodes<ParagraphElement>(editor, EMPTY_PARAGRAPH, { select: true })
    },
  },
  ...autoFormatLists,
]

export const autoFormatRules: Array<AutoformatRule> = [...autoFormatBlocks, ...autoFormatMarks].map(
  (rule) => {
    if (rule.query) {
      const prevQuery = rule.query
      const nextQuery: typeof prevQuery = (...args) =>
        // Plateがコードブロック内でAutoformatしないようになってない対策
        prevQuery(...args) && denyAutoFormatInCodeBlockQuery(...args)
      rule.query = nextQuery
    } else {
      rule.query = denyAutoFormatInCodeBlockQuery
    }
    return rule
  },
)
