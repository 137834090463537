import { Box } from 'grommet'
import { useEffect, forwardRef, Ref, useRef, useState } from 'react'

import { useTranslation } from '../../../i18n/index'
import { useClickOutside } from '../../../lib/clickOutside'
import { componentNames, featureNames, generateTestId } from '../../../lib/testId'
import { color } from '../../../styles/newColors'
import { Avatar } from '../../ui/Avatar'
import { Icon } from '../../ui/Icon'
import { MultiSelect } from '../../ui/MultiSelect'
import { StyledText } from '../../ui/StyledText'
import { TooltipNew } from '../../ui/TooltipNew'

import {
  avatarWidth,
  selectedBoxPadding,
  selectedTextFontSize,
  useStyles,
} from './ParentKeyResultSelect.styles'
import { KeyResultFragment } from './graphql'

export type Props = {
  value?: ReadonlyArray<KeyResultFragment>
  candidates: ReadonlyArray<KeyResultFragment>
  onChange?: (ids: Array<string>) => void
  onBlurFromMultiSelect?: () => void
  disabled?: boolean
}

export const ParentKeyResultSelect = forwardRef<HTMLInputElement, Props>(
  (
    { value = [], candidates = [], onChange, onBlurFromMultiSelect, disabled },
    _: Ref<HTMLInputElement>,
  ) => {
    const { t } = useTranslation()
    const styles = useStyles(disabled)
    const [open, setOpen] = useState(false)
    const multiSelectRef = useRef<HTMLDivElement>(null)
    const valuesWrapperRef = useRef<HTMLDivElement>(null)

    const options = candidates.map((kr) => ({
      id: kr.id,
      name: kr.name,
      searchText: kr.name,
      selected: value.some((v) => v.id === kr.id),
      icon: (
        <Avatar
          size="small"
          firstName={kr.owner.firstName}
          lastName={kr.owner.lastName}
          avatarUrl={kr.owner.avatar?.url}
          isUserDisabled={kr.owner.isDisabled}
        />
      ),
      isDisabled: kr.isDisabled,
    }))

    useClickOutside({ callback: () => setOpen(false), ref: multiSelectRef })

    useEffect(() => {
      if (!valuesWrapperRef.current || !value.length) return

      const maxWidth = valuesWrapperRef.current?.getBoundingClientRect().width
      if (!maxWidth) return

      Array.from(document.querySelectorAll<HTMLDivElement>('.selected-kr')).forEach((element) => {
        element.dataset.isDisplayNone = 'false'
        element.style.width = 'auto'
      })

      let sumSelectedWidth = 0
      let omitted = false
      const nextMinimumWidth = selectedBoxPadding + avatarWidth + selectedTextFontSize
      const selectedBoxes = Array.from(document.querySelectorAll<HTMLDivElement>('.selected-kr'))
      const selectedBoxesLastIndex = selectedBoxes.length - 1
      selectedBoxes.some((element, i) => {
        if (omitted) {
          element.dataset.isDisplayNone = 'true'
          return false
        }
        const { width } = element.getBoundingClientRect()
        if (width > maxWidth - sumSelectedWidth) {
          const newWidth = maxWidth - sumSelectedWidth
          element.style.width = `${newWidth}px`
          omitted = true
        } else if (
          selectedBoxesLastIndex !== i &&
          width >= maxWidth - sumSelectedWidth - nextMinimumWidth
        ) {
          const newWidth = width - 1
          element.style.width = `${newWidth}px`
          omitted = true
        }
        sumSelectedWidth += width
        return false
      })
    }, [valuesWrapperRef, value])

    return (
      <div css={styles.root}>
        <button
          type="button"
          css={styles.select}
          data-testid={generateTestId(
            featureNames.okrCreateModal,
            componentNames.parentKeyResultSelect,
          )}
          onClick={() => setOpen(!open)}
        >
          {value.length === 0 && (
            <StyledText color="text-bk-30" css={styles.placeholder}>
              {t('SELECT_PARENT_KEY_RESULT')}
            </StyledText>
          )}
          {value.length > 0 && (
            <>
              <div css={styles.values} ref={valuesWrapperRef}>
                {value.map((kr) => (
                  <Box
                    data-is-display-none="false"
                    className="selected-kr"
                    key={kr.id}
                    direction="row"
                    align="center"
                    gap="4px"
                    flex={{ shrink: 0 }}
                    css={styles.value}
                  >
                    <Avatar
                      size="small"
                      firstName={kr.owner.firstName}
                      lastName={kr.owner.lastName}
                      avatarUrl={kr.owner.avatar?.url}
                      isUserDisabled={kr.owner.isDisabled}
                    />
                    <StyledText
                      size="medium"
                      fontStyle="narrow"
                      numberOfOmittedLines={1}
                      lineHeight="20px"
                      oneline
                      isWrap
                    >
                      {kr.name}
                    </StyledText>
                  </Box>
                ))}
              </div>
              <TooltipNew title={t('CLEAR_X', { x: t('PARENT_KEY_RESULT') })} css={styles.tooltip}>
                <Icon
                  type="clear"
                  color={color('border-bk-50')}
                  width={14}
                  height={14}
                  hoverColor="resily-orange-100"
                  onClick={(e) => {
                    e.stopPropagation()
                    if (onChange) {
                      onChange(value.map((kr) => kr.id))
                    }
                  }}
                />
              </TooltipNew>
            </>
          )}
          <span css={styles.rightIcon}>
            <Icon
              type="selectDown"
              width={8}
              height={8}
              css={{
                transform: open ? 'rotate(-180deg)' : 'none',
              }}
              color={color('border-bk-20')}
            />
          </span>
        </button>
        {open && (
          <div ref={multiSelectRef} css={styles.dropdown}>
            <MultiSelect
              canSearch
              onBlurFromMutliSelect={onBlurFromMultiSelect}
              searchPlaceholder={t('SELECT_PARENT_KEY_RESULT')}
              options={options}
              onClickOption={(v) => {
                if (onChange) {
                  onChange([v])
                }
              }}
              full
            />
          </div>
        )}
      </div>
    )
  },
)

ParentKeyResultSelect.displayName = 'ParentKeyResultSelect'
