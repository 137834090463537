import * as Yup from 'yup'

import type { TFunction } from '../../../../i18n'
import { KeyResultFragment, KeyResultUpdateType, TargetSettingType } from '../graphql/graphql'

export const keyResultsKeyName = 'keyResults' as const

export type FormValues = Yup.InferType<ReturnType<typeof formSchema>>

const documentInputSchema = Yup.object()
  .shape({
    plainText: Yup.string(),
    treeJson: Yup.string(),
  })
  .nullable()

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const formSchema = (t: TFunction) =>
  Yup.object({
    [keyResultsKeyName]: Yup.array(
      Yup.object({
        actualValue: Yup.number().nullable().typeError(t('ONLY_NUMBER')),
        initialValue: Yup.number().nullable().typeError(t('ONLY_NUMBER')),
        keyResultId: Yup.string().required(t('REQUIRED_ERROR')),
        level: Yup.number().nullable(),
        other: documentInputSchema,
        priority: documentInputSchema,
        problem: documentInputSchema,
        targetValue: Yup.number().nullable().typeError(t('ONLY_NUMBER')),
        unit: Yup.string().nullable(),
        updateReason: Yup.string().nullable(),
        winSession: documentInputSchema,

        updateType: Yup.mixed<KeyResultUpdateType>()
          .oneOf(Object.values(KeyResultUpdateType))
          .required(t('REQUIRED_ERROR')),
        objectiveName: Yup.string().required(t('REQUIRED_ERROR')),
        keyResultName: Yup.string().required(t('REQUIRED_ERROR')),
        node: Yup.object({
          id: Yup.string().required(t('REQUIRED_ERROR')),
          depth: Yup.number().required(t('REQUIRED_ERROR')),
        }),
        beforeActualValue: Yup.number().nullable(),
        beforeInitialValue: Yup.number().nullable(),
        beforeTargetValue: Yup.number().nullable(),
        beforeUnit: Yup.string().nullable(),
        beforeLevel: Yup.number().nullable(),
        lastPriority: documentInputSchema,
        lastProblem: documentInputSchema,
        lastWinSession: documentInputSchema,
        lastOther: documentInputSchema,
        isCurrentPriorityComment: Yup.bool().required(t('REQUIRED_ERROR')),
        isCurrentProblemComment: Yup.bool().required(t('REQUIRED_ERROR')),
        isCurrentWinSessionComment: Yup.bool().required(t('REQUIRED_ERROR')),
        isCurrentOtherComment: Yup.bool().required(t('REQUIRED_ERROR')),
        targetSetting: Yup.mixed<KeyResultFragment['targetSetting']>()
          .oneOf(Object.values(TargetSettingType))
          .required(t('REQUIRED_ERROR')),
      }),
    ).required(),
  }).required()
