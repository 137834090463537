import { forwardRef, Ref, useCallback, useMemo } from 'react'
import { useWindowSize } from 'react-use'

import { GroupAndUserFilter } from '../../../components/domain/GroupAndUserFilter'
import { getSideNavWidth } from '../../../components/layout/GloNavAndMain/SideNav/useSideNavWidth'
import { Screen } from '../../../lib/screen'
import { tracker } from '../../../lib/tracking'
import { border } from '../../../styles/border'
import { color } from '../../../styles/newColors'
import { UserFragment, GroupFragment } from '../graphql'

export type ContainerProps = {
  groups: ReadonlyArray<GroupFragment>
  users: ReadonlyArray<UserFragment>
  selectedGroupIds: ReadonlyArray<string>
  selectedUserIds: ReadonlyArray<string>
  onFilterChange: (filter: {
    groupIds?: ReadonlyArray<string>
    userIds?: ReadonlyArray<string>
  }) => void
}

export const HeaderContainer = forwardRef<HTMLElement, ContainerProps>(
  (
    { groups, users, selectedGroupIds, selectedUserIds, onFilterChange }: ContainerProps,
    ref: Ref<HTMLElement>,
  ) => {
    const { width } = useWindowSize()
    const sideNavWidth = getSideNavWidth()
    const filterMaxWidth = useMemo(() => width - sideNavWidth - 56, [sideNavWidth, width])

    const reflectGroupTracker = useCallback((screen: Screen) => {
      tracker.UsersFilterGroup(screen)
    }, [])
    const reflectUserTracker = useCallback((screen: Screen) => {
      tracker.UsersFilterUser(screen)
    }, [])

    return (
      <header ref={ref}>
        <div
          css={{
            padding: '12px 24px',
            backgroundColor: color('white-100'),
            borderBottom: border('simple-10'),
            overflowX: 'auto',
          }}
        >
          <GroupAndUserFilter
            maxWidth={filterMaxWidth}
            canFilter
            groups={groups}
            users={users}
            selectedGroupIds={selectedGroupIds}
            selectedUserIds={selectedUserIds}
            onFilterChange={onFilterChange}
            onReflectGroupTracker={reflectGroupTracker}
            onReflectUserTracker={reflectUserTracker}
          />
        </div>
      </header>
    )
  },
)

HeaderContainer.displayName = 'HeaderContainer'
