import React from 'react'
import { Controller } from 'react-hook-form'

import { Props as SelectUnitProps } from '../../../../../domain/SelectUnit'
import { useGetFormMethods } from '../../contexts/FormProvider'
import { keyResultsKeyName } from '../../formSchema'

type SelectUnitControllerProps = {
  name: `${typeof keyResultsKeyName}.${number}.unit`
  children: (args: SelectUnitProps) => React.ReactNode
}

export const SelectUnitController: React.FC<SelectUnitControllerProps> = ({ name, children }) => {
  const { control } = useGetFormMethods()

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange } }) => (
        <>
          {children({
            value,
            onChange,
          })}
        </>
      )}
    />
  )
}

SelectUnitController.displayName = 'SelectUnitController'
