import { Box, Collapsible } from 'grommet'
import React, { useEffect, useState } from 'react'

import { color } from '../../../styles/newColors'
import { Icon } from '../../ui/Icon'
import { StyledText } from '../../ui/StyledText'
import { TooltipNew } from '../../ui/TooltipNew/index'

import { BorderlessCheckBox } from './BorderlessCheckBox'
import {
  OPTION_ROOT_CLASSNAME,
  OPTION_CONTAINER_CLASSNAME,
  OPTION_CONTAINER_FOCUS_CLASSNAME,
  OPTION_CLASSNAME,
  OPTION_COLLAPSIBLE_BUTTON_CLASSNAME,
} from './hooks'
import { GroupNode } from './types'

// 三角ボタンのsvgを10x5にするための倍率
const SVG_SCALE = 'scale(0.72029,0.824175)'

export type Props = {
  groups: ReadonlyArray<GroupNode>
  selectedIds: ReadonlyArray<string>
  onClickOption: (id: string) => void
}

export const GroupTreeAccordion: React.FC<Props> = ({ groups, selectedIds, onClickOption }) => (
  <ul>
    {groups.map((g) => (
      <GroupTreeAccordionRow
        key={g.id}
        group={g}
        selectedIds={selectedIds}
        onClick={onClickOption}
      />
    ))}
  </ul>
)

GroupTreeAccordion.displayName = 'GroupTreeAccordion'

type GroupTreeAccordionRowProps = {
  group: GroupNode
  selectedIds: ReadonlyArray<string>
  onClick: (id: string) => void
}

const GroupTreeAccordionRow: React.FC<GroupTreeAccordionRowProps> = ({
  group,
  selectedIds,
  onClick,
}) => {
  const [checked, setChecked] = useState<boolean>(false)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isHover, setIsHover] = useState<boolean>(false)

  useEffect(() => {
    setChecked(selectedIds.some((sId) => sId === group.id))
  }, [group.id, selectedIds])

  const isLeaf = group.children.length === 0

  return (
    <Box as="li" className={OPTION_ROOT_CLASSNAME}>
      <TooltipNew title={group.name} dropProps={{ stretch: false, plain: true }}>
        <Box
          direction="row"
          height="40px"
          background={{
            // eslint-disable-next-line no-nested-ternary
            color: checked
              ? color('border-bk-10')
              : isHover
              ? color('hover-background-bk-5')
              : undefined,
          }}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
          className={OPTION_CONTAINER_CLASSNAME}
          css={{
            [`&.${OPTION_CONTAINER_FOCUS_CLASSNAME}`]: {
              backgroundColor: color('hover-background-bk-5'),
            },
          }}
        >
          <BorderlessCheckBox
            className={OPTION_CLASSNAME}
            css={{
              paddingLeft: '12px',
              paddingRight: '4px',
            }}
            checked={checked}
            isRowHover={isHover}
            onClick={() => onClick(group.id)}
          />
          <Box
            align="center"
            direction="row"
            margin={{ left: `${(group.depth - 1) * 20}px` }} // indent
          >
            {isLeaf ? (
              <Box margin="8px" />
            ) : (
              <Box
                className={OPTION_COLLAPSIBLE_BUTTON_CLASSNAME}
                width={{ min: '16px' }}
                height={{ min: '16px' }}
                onClick={() => setIsOpen(!isOpen)}
              >
                <Icon
                  type="selectDown"
                  color={color('text-bk-30')}
                  css={{
                    transform: SVG_SCALE,
                    '&[data-open="false"]': { transform: `${SVG_SCALE} rotate(-90deg)` },
                  }}
                  data-open={isOpen}
                />
              </Box>
            )}
            <Box onClick={() => onClick(group.id)}>
              <StyledText
                css={{
                  paddingLeft: '8px',
                  paddingRight: '8px',
                  lineHeight: '20px',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
                color="text-bk-100"
              >
                {group.name}
              </StyledText>
            </Box>
          </Box>
        </Box>
      </TooltipNew>
      {isLeaf ? null : (
        <Collapsible open={isOpen}>
          <Box
            as="ul"
            css={{ '&[data-checked="true"]': { backgroundColor: color('background-bk-5') } }}
            data-checked={checked}
          >
            {group.children.map((c) => (
              <GroupTreeAccordionRow
                key={c.id}
                group={c}
                selectedIds={selectedIds}
                onClick={onClick}
              />
            ))}
          </Box>
        </Collapsible>
      )}
    </Box>
  )
}

GroupTreeAccordionRow.displayName = 'GroupTreeAccordionRow'
