import { BaseNodeType, descendants, Node } from './node'

/**
 * 複数木を扱うためのCollection
 */
export class TreeList<T extends BaseNodeType> {
  /**
   * TreeListは複数のrootを持つ木構造のためrootノードは複数持っている
   * indexがそのままrootノードの並び順となる
   * @protected
   * @type {Array<Node<T>>}
   * @memberof TreeList
   */
  protected roots: Array<Node<T>> = []

  /**
   * 階層毎にどのノードが存在するか記録したハッシュマップ
   * @protected
   * @type {Map<number, Node<T>>}
   * @memberof TreeList
   */
  protected levelLayerMap: Map<number, ReadonlyArray<Node<T>>> = new Map()

  /**
   * IDからノードを高速に参照するためのハッシュマップ
   * @protected
   * @type {Map<string, Node<T>>}
   * @memberof TreeList
   */
  protected nodeMap: Map<string, Node<T>> = new Map()

  public constructor()
  public constructor(nodes: ReadonlyArray<T> | undefined)
  public constructor(nodes?: ReadonlyArray<T>) {
    if (nodes) {
      this.parse(nodes)
    }
  }

  /**
   * NodeのArrayを木構造に変換する
   * parseした木構造はインスタンスに保持する
   * 木構造を取得したい場合はtree()を呼ぶ
   * @param nodes 木構造に変換する前のNode
   */
  public parse(nodes: ReadonlyArray<T>): void {
    const nodeMap = new Map<string, T>()
    const roots: Array<T> = []
    nodes.forEach((n) => {
      if (n.depth === 1) {
        roots.push(n)
      }
      nodeMap.set(n.id, n)
    })

    this.roots.push(
      ...this.buildTree(
        roots.map((r) => r.id),
        nodeMap,
      ),
    )
  }

  /**
   * インスタンスで保持している木構造を返却する
   * TreeList.parse()を呼び出していない場合は空の配列を返却
   * @returns 複数親の木構造
   */
  public trees(): ReadonlyArray<Node<T>> {
    return this.roots
  }

  /**
   * ツリーが空とみなせるかどうか
   */
  public isEmpty(): boolean {
    return this.roots.length === 0
  }

  /**
   * 木構造の最大深度を取得する
   * @returns 最大深度
   */
  public getMaxDepth(): number {
    return this.levelLayerMap.size
  }

  /**
   * depthで指定された階層のノードを返却します
   * @param depth 取得したいノード数の階層
   * @returns depthで指定された階層のノード
   */
  public getNodesByDepth(depth: number): ReadonlyArray<Node<T>> | undefined {
    return this.levelLayerMap.get(depth)
  }

  /**
   * depthで指定された階層の合計ノード数を返却します
   * @param depth 取得したいノード数の階層
   * @returns depthで指定された階層の合計ノード数
   */
  public getNodeCountByDepth(depth: number): number {
    return this.getNodesByDepth(depth)?.length ?? 0
  }

  /**
   * 最大サイズのrootノードの木を返却する
   * @returns 最大サイズの木
   */
  public getMaxSizeTree(): Node<T> | null {
    if (this.roots.length === 0) {
      return null
    }

    const max = {
      index: 0,
      size: 0,
    }
    this.roots.forEach((root, i) => {
      const len = descendants(root).length
      if (len > max.size) {
        max.index = i
        max.size = len
      }
    })
    return this.roots[max.index]
  }

  /**
   * IDからノードを取得する
   * @param id ノードのID
   * @returns ノード
   */
  public getNodeById(id: string): Node<T> | null {
    return this.nodeMap.get(id) || null
  }

  /**
   * IDの配列からからノード配列を取得する
   * @param ids ノードのIDの配列
   * @returns ノードの配列
   */
  public getNodeByIds(ids: ReadonlyArray<string>): ReadonlyArray<Node<T>> {
    return ids.map((id) => this.getNodeById(id)).flatMap((n) => (n ? [n] : []))
  }

  /**
   * 全てのノードを取得する
   * @returns 全ノード
   */
  public getAllNodes(): Array<Node<T>> {
    const nodes: Array<Node<T>> = []
    this.traversal((n) => nodes.push(n))
    return nodes
  }

  /**
   * 木構造を再帰的に探索しcbを実行する
   * 深さ優先探索で実装
   * このメソッドは複数親を考慮しているためただのinterfaceとして存在し
   * 処理の実態はtraversalTreeで実装されています
   * @param cb node毎に実行するcallback
   */
  public traversal(cb: (n: Node<T>) => void): void {
    this.traversalTree(this.roots, cb)
  }

  /**
   * 木構造を再帰的に探索しcbを実行する
   * 深さ優先探索で実装
   * @param children 子ノード配列
   * @param cb node毎に実行するcallback
   */
  private traversalTree(children: ReadonlyArray<Node<T>>, cb: (n: Node<T>) => void): void {
    children.forEach((node) => {
      cb(node)
      const c = node.children.get(node)
      if (c) {
        this.traversalTree(c, cb)
      }
    })
  }

  /**
   * ノードを生成する
   * ここではnullを返さないが子クラスで返すことを想定する
   * @param node ノード
   * @param parent 親ノード
   * @returns 整形済みNode<T> か null
   */
  // eslint-disable-next-line class-methods-use-this
  protected createNode(node: T, parent: Node<T> | undefined): Node<T> | null {
    const n: Node<T> = { children: new WeakMap(), ...node }
    if (parent) {
      n.parent = new WeakMap()
      n.parent.set(n, parent)
    }
    return n
  }

  /**
   * 再帰的に子のIDを参照しツリー構造を構築する
   * @param childNodeIds 子のノードID
   * @param nodes ノード一覧
   * @param parent 親ノード
   * @returns 整形済みNode<T>
   */
  private buildTree(
    childNodeIds: ReadonlyArray<T['id']>,
    nodes: ReadonlyMap<string, T>,
    parent?: Node<T>,
  ): Array<Node<T>> {
    return childNodeIds.flatMap((cId) => {
      const cNode = nodes.get(cId)
      if (cNode == null) {
        return []
      }

      const ret = this.createNode(cNode, parent)
      if (ret == null) {
        return []
      }

      this.nodeMap.set(ret.id, ret)

      // parse時に階層毎のノード数を記録しておく
      const layer = this.levelLayerMap.get(ret.depth)
      this.levelLayerMap.set(ret.depth, layer ? layer.concat([ret]) : [ret])

      // 深さ優先探索で再帰的に木構造を構築
      ret.children.set(ret, this.buildTree(ret.childNodeIds, nodes, ret))

      return ret
    })
  }
}
