import { useLayoutEffect, useState } from 'react'

import { TokenConfirmation } from '../../types/tokenConfirmation'
import { client } from '../client'

export type Props = {
  type: TokenConfirmation
  token: string | null | undefined
}

type ConfirmationResponse = {
  email?: string
}

export type ReturnType = {
  loading: boolean
  confirmedToken: boolean
  email?: string | null
}

export const useConfirmationToken = ({ type, token }: Props): ReturnType => {
  const [state, setState] = useState<ReturnType>({
    loading: true,
    confirmedToken: false,
  })

  useLayoutEffect(() => {
    if (token == null || token === '') return
    const confirmToken = async () => {
      try {
        const res = await client.post<ConfirmationResponse>(
          '/token/confirmation',
          {
            type,
            token,
          },
          {
            success: false,
            error: false,
          },
        )
        setState({ confirmedToken: true, loading: false, email: res?.email })
      } catch (err) {
        setState({ confirmedToken: false, loading: false })
      }
    }
    confirmToken()
  }, [token, type])

  return state
}
