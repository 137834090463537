import { componentNames, featureNames, generateTestId } from '../../../../../../lib/testId'
import { color } from '../../../../../../styles/newColors'
import { Icon, Type as IconType } from '../../../../../ui/Icon'
import { DirtyFieldConsumer } from '../DirtyFieldConsumer'
import { Names as DirtyFieldConsumerNames } from '../DirtyFieldConsumer/types'

type Props = {
  iconType: IconType
  names: DirtyFieldConsumerNames
}

export const Signal: React.VFC<Props> = ({ iconType, names }) => (
  <DirtyFieldConsumer names={names}>
    {(isDirty) => (
      <Icon
        data-testid={generateTestId(featureNames.checkinModal, componentNames.icon, {
          suffix: iconType,
        })}
        type={iconType}
        color={isDirty ? color('resily-orange-100') : color('resily-orange-16')}
      />
    )}
  </DirtyFieldConsumer>
)

Signal.displayName = 'CheckinModalSignal'
