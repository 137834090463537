import {
  ActivityCardDataItemProps as GeishaActivityCardDataItemProps,
  ConfidenceTag as GeishaConfidenceTag,
  ConfidenceTagProps as GeishaConfidenceTagProps,
} from '@resily/geisha'
import { useMemo, createElement, useCallback } from 'react'

import { useTranslation } from '../../../../../../../../../i18n'
import {
  generateConfidenceTextMap,
  useCalcDiffValue,
} from '../../../../../../../../../lib/domain/activity'
import { old2NewConfidence } from '../../../../../../../../../lib/geisha/confidence'
import { Props as ActivityProps } from '../types'

type Args = {
  keyResultEvents: ActivityProps['keyResultEvents']
  filterUnchagedItem?: boolean
}

type Item = GeishaActivityCardDataItemProps & { isChanged: boolean }

type Return = {
  isChanged: boolean
  itemGroup1: ReadonlyArray<Item>
  itemGroup2: ReadonlyArray<Item>
}

const baseValueStyles: GeishaActivityCardDataItemProps['valueStyles'] = { weight: 'bold' }

// geishaのActivityCardで使いやすくしたkeyResultEventsのデータ構造を返却する
export const useTransformActivityKeyResultEvents = ({
  keyResultEvents,
  filterUnchagedItem = true,
}: Args): Return => {
  const { t } = useTranslation()

  const diffValue = useCalcDiffValue(keyResultEvents)
  const confidenceText = generateConfidenceTextMap(t)

  const confidenceLevel = useMemo(
    () => old2NewConfidence(keyResultEvents?.afterConfidenceLevel),
    [keyResultEvents?.afterConfidenceLevel],
  )
  const confidenceLevelHashChanged = useMemo(() => {
    const beforeConfidenceLevel = keyResultEvents.beforeConfidenceLevel ?? 0

    return beforeConfidenceLevel !== keyResultEvents.afterConfidenceLevel
  }, [keyResultEvents.beforeConfidenceLevel, keyResultEvents.afterConfidenceLevel])

  const getUnit = useCallback(
    (unit?: string | null) => {
      if (unit == null) return undefined

      if (unit === '') {
        return t('UNIT_NOT_SET')
      }

      return unit
    },
    [t],
  )

  const ret: Omit<Return, 'isChanged'> = {
    itemGroup1: [
      {
        label: t('PROGRESS_RATE'),
        value: keyResultEvents.afterProgressRate,
        valueStyles: baseValueStyles,
        unit: '%',
        diffValue: diffValue.progressRate,
        isChanged: keyResultEvents.beforeProgressRate !== keyResultEvents.afterProgressRate,
      },
      {
        label: t('ACTUAL_VALUE'),
        value: keyResultEvents.afterActualValue,
        valueStyles: baseValueStyles,
        unit: keyResultEvents.afterUnit ?? '',
        diffValue: diffValue.actualValue,
        isChanged: keyResultEvents.beforeActualValue !== keyResultEvents.afterActualValue,
      },
      {
        label: t('TARGET_VALUE'),
        value: keyResultEvents.afterTargetValue,
        valueStyles: baseValueStyles,
        unit: keyResultEvents.afterUnit ?? '',
        diffValue: diffValue.targetValue,
        isChanged: keyResultEvents.beforeTargetValue !== keyResultEvents.afterTargetValue,
      },
      {
        label: t('INITIAL_VALUE'),
        value: keyResultEvents.afterInitialValue,
        valueStyles: baseValueStyles,
        unit: keyResultEvents.afterUnit ?? '',
        diffValue: diffValue.initialValue,
        isChanged: keyResultEvents.beforeInitialValue !== keyResultEvents.afterInitialValue,
      },
      {
        label: t('TARGET_VALUE_UNIT'),
        value: getUnit(keyResultEvents.afterUnit),
        valueStyles: baseValueStyles,
        diffValue: diffValue.unit,
        diffValueLabel: t('PREVIOUS_X', { x: t('TARGET_VALUE_UNIT') }),
        isChanged: keyResultEvents.beforeUnit !== keyResultEvents.afterUnit,
      },
      {
        label: t('CONFIDENCE'),
        value:
          confidenceLevel !== null
            ? // eslint-disable-next-line react/no-children-prop
              createElement<GeishaConfidenceTagProps>(GeishaConfidenceTag, {
                level: confidenceLevel,
                children: confidenceText[confidenceLevel],
              })
            : '',
        isChanged: confidenceLevelHashChanged,
      },
    ],
    itemGroup2: [
      {
        label: t('UPDATE_MEMO'),
        value: keyResultEvents.updateReason,
        valueStyles: {
          color: 'foreground-black-bk100',
        },
        isChanged: Boolean(keyResultEvents.updateReason),
      },
    ],
  }

  return {
    isChanged:
      ret.itemGroup1.some((item) => item.isChanged) ||
      ret.itemGroup2.some((item) => item.isChanged),
    ...(filterUnchagedItem
      ? {
          itemGroup1: ret.itemGroup1.filter((item) => item.isChanged),
          itemGroup2: ret.itemGroup2.filter((item) => item.isChanged),
        }
      : ret),
  }
}
