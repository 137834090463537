import { Tooltip } from '@resily/geisha'
import { ThemeContext } from 'grommet'
import { useCallback, useState } from 'react'

import { useEditingStateMachine } from '../../../../../../contexts/EditingStateContext'
import { useTranslation } from '../../../../../../i18n'
import { barColorConfidence } from '../../../../../../lib/confidence'
import { keyResultToConfidenceLevel } from '../../../../../../lib/domain/confidence'
import {
  childObjectiveProgressRate,
  hasChildObjective,
} from '../../../../../../lib/domain/keyResult/keyResult'
import { isNotFilledRequiredTargetValue } from '../../../../../../lib/domain/keyResult/keyResultProgress'
import { findScreen } from '../../../../../../lib/screen'
import { componentNames, featureNames, generateTestId } from '../../../../../../lib/testId'
import { tracker } from '../../../../../../lib/tracking'
import { color } from '../../../../../../styles/newColors'
import { KeyResultDescriptionProgressRateInfo } from '../../../../../domain/KeyResultDescriptionProgressRateInfo'
import { KeyResultQuickUpdateModalContainer } from '../../../../../domain/KeyResultQuickUpdateModal'
import { Button } from '../../../../../ui/Button'
import { Icon } from '../../../../../ui/Icon'
import { IconButton } from '../../../../../ui/IconButton'
import { ProgressRate } from '../../../../../ui/Meter'
import { StyledText } from '../../../../../ui/StyledText'
import { TooltipNew } from '../../../../../ui/TooltipNew'
import { useKeyResultUpdateModal } from '../../../../KeyResultUpdateModal'
import { AvatarWithName } from '../../../Common/AvatarWithName'
import { KeyResultDrawerDescriptionKeyResultFragment } from '../../../graphql'
import { LoadableProps } from '../../../types'
import { KeyResultUpdateType, TargetSettingType } from '../graphql'

import { Contributors } from './Contributors'
import { HeaderAreaSkeleton } from './HeaderAreaSkeleton'
import { useStyles } from './index.styles'

export type DataProps = {
  keyResult: KeyResultDrawerDescriptionKeyResultFragment
}

export type Props = LoadableProps<DataProps> & {
  updateAutoAggregate: () => void
  onUpdateParentObjective: (objectiveId: string) => void
  onDelete: () => void
}

export const HeaderArea: React.VFC<Props> = ({
  loading,
  keyResult,
  updateAutoAggregate,
  onUpdateParentObjective,
  onDelete,
}) => {
  const { t } = useTranslation()
  const styles = useStyles()

  const { requestStartEdit, updateIsDirty, finishEdit } = useEditingStateMachine()
  const [isOpenKeyResultQuickUpdateModal, setIsOpenKeyResultQuickUpdateModal] = useState(false)
  const openKeyResultQuickUpdateModal = useCallback(() => {
    requestStartEdit(() => {
      setIsOpenKeyResultQuickUpdateModal(true)
      // TODO: クイック更新モーダルで常に離脱モーダルが出てるのをやめたい
      setTimeout(() => updateIsDirty(true), 0)
      return () => setIsOpenKeyResultQuickUpdateModal(false)
    }, 'QUICK_UPDATE_MODAL')
  }, [requestStartEdit, updateIsDirty])
  const onClickProgressRate = useCallback(() => {
    tracker.UserClickProgressRateByKeyResultDrawer(
      findScreen(window.location.pathname, window.location.search),
    )
    openKeyResultQuickUpdateModal()
  }, [openKeyResultQuickUpdateModal])
  const onClickUpdateProgressRateButton = useCallback(() => {
    tracker.UserClickUpdateProgressRateButtonByKeyResultDrawer(
      findScreen(window.location.pathname, window.location.search),
    )
    openKeyResultQuickUpdateModal()
  }, [openKeyResultQuickUpdateModal])
  const closeKeyResultQuickUpdateModal = useCallback(() => {
    setIsOpenKeyResultQuickUpdateModal(false)
    finishEdit()
  }, [finishEdit])
  const onClickAutoAggregateButton = useCallback(() => {
    tracker.UserClickReflectChildOkrProgressRateByKeyResultDrawer(
      findScreen(window.location.pathname, window.location.search),
    )
    updateAutoAggregate()
  }, [updateAutoAggregate])

  const { KeyResultUpdateModal, openKeyResultUpdateModal } = useKeyResultUpdateModal({
    onUpdateParentObjective,
    onDelete,
  })

  return (
    <>
      {loading ? (
        <HeaderAreaSkeleton />
      ) : (
        <ThemeContext.Extend value={{ global: { drop: { zIndex: '201' } } }}>
          <div css={styles.root}>
            <div css={styles.titleArea}>
              <StyledText size="small" fontStyle="bold" isWrap numberOfOmittedLines="unset">
                {keyResult.targetSetting === TargetSettingType.Moon && (
                  <Icon type="moon" width={14} height={14} css={styles.targetIcon} />
                )}
                {keyResult.targetSetting === TargetSettingType.Roof && (
                  <Icon type="roof" width={14} height={14} css={styles.targetIcon} />
                )}
                {keyResult.name}
              </StyledText>
              <Tooltip label={t('EDIT_X', { x: t('KEY_RESULT') })}>
                <IconButton
                  border={false}
                  size={20}
                  css={styles.krSettings}
                  onClick={() => {
                    tracker.UserClickOpenKeyResultUpdateModalByKeyResultModal(
                      findScreen(window.location.pathname, window.location.search),
                    )
                    openKeyResultUpdateModal(keyResult.id)
                  }}
                >
                  <Icon type="setting" width={20} height={20} />
                </IconButton>
              </Tooltip>
            </div>
            <div css={styles.detailArea}>
              <div css={styles.userArea}>
                <div css={styles.owner}>
                  <StyledText
                    size="xsmall"
                    fontStyle="regular"
                    lineHeight="14px"
                    color="text-bk-50"
                  >
                    {t('OWNER')}
                  </StyledText>
                  <AvatarWithName
                    size="xsmall"
                    nameFontSize="xsmall"
                    user={keyResult.owner}
                    termId={keyResult.node.term.id}
                  />
                </div>
                <div css={styles.contributors}>
                  <StyledText
                    size="xsmall"
                    fontStyle="regular"
                    lineHeight="14px"
                    color="text-bk-50"
                  >
                    {t('CONTRIBUTOR')}
                  </StyledText>
                  {keyResult.members.length > 0 ? (
                    <Contributors
                      contributors={keyResult.members}
                      termId={keyResult.node.term.id}
                    />
                  ) : (
                    <StyledText
                      size="xsmall"
                      fontStyle="regular"
                      lineHeight="14px"
                      color="text-bk-100"
                    >
                      {t('NOT_SET')}
                    </StyledText>
                  )}
                </div>
              </div>
              <div css={styles.progressAndChildObjectiveArea}>
                <div css={styles.progressArea}>
                  <div css={styles.progressRateAndValueArea}>
                    <div css={styles.progressRateArea}>
                      <StyledText size="xsmall" fontStyle="regular" color="text-bk-50">
                        {t('PROGRESS')}
                      </StyledText>

                      <TooltipNew title={t('UPDATE_PROGRESS_RATE')} css={styles.tooltip}>
                        <button type="button" onClick={onClickProgressRate}>
                          <ProgressRate
                            color={color(barColorConfidence(keyResultToConfidenceLevel(keyResult)))}
                            rate={keyResult.progressRate || 0}
                          />
                        </button>
                      </TooltipNew>
                    </div>
                    <div css={styles.actualAndTarget}>
                      <StyledText size="xsmall" fontStyle="regular" color="text-bk-50">
                        {t('ACTUAL_AND_TARGET_VALUE')}
                      </StyledText>
                      {!isNotFilledRequiredTargetValue(keyResult) ? (
                        (() => {
                          const isManual =
                            keyResult.updateType === KeyResultUpdateType.Manual ||
                            keyResult.isAutoAggregate
                          const label = isManual
                            ? `${keyResult.progressRate ?? 0} / 100`
                            : `${keyResult.actualValue ?? 0} / ${keyResult.targetValue}`
                          const unit = isManual ? '%' : keyResult.unit ?? ''
                          return (
                            <TooltipNew title={`${label} ${unit}`} css={styles.tooltip}>
                              <StyledText size="small" fontStyle="bold" truncate>
                                {label}
                              </StyledText>
                              <StyledText size="xsmall" fontStyle="regular" truncate>
                                {unit}
                              </StyledText>
                            </TooltipNew>
                          )
                        })()
                      ) : (
                        <StyledText size="small" fontStyle="bold" color="tag-red">
                          {t('NOT_SET')}
                        </StyledText>
                      )}
                    </div>
                  </div>
                  <Button
                    weight="normal"
                    newColor="resily-orange-100"
                    css={styles.updateProgress}
                    onClick={onClickUpdateProgressRateButton}
                    data-testid={generateTestId(
                      featureNames.okrModal,
                      componentNames.updateProgressButton,
                    )}
                  >
                    {t('UPDATE_PROGRESS')}
                  </Button>
                </div>
                {hasChildObjective(keyResult) && (
                  <div css={styles.childObjective}>
                    <KeyResultDescriptionProgressRateInfo
                      isAutoAggregate={keyResult.isAutoAggregate}
                      progressRate={keyResult.progressRate || 0}
                      lowerOkrProgressRate={childObjectiveProgressRate(keyResult)}
                      onClickButton={onClickAutoAggregateButton}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          {isOpenKeyResultQuickUpdateModal && (
            <KeyResultQuickUpdateModalContainer
              withGeishaModal
              keyResultId={keyResult.id}
              onClickCancel={closeKeyResultQuickUpdateModal}
              onClickExecute={closeKeyResultQuickUpdateModal}
            />
          )}
        </ThemeContext.Extend>
      )}
      {KeyResultUpdateModal}
    </>
  )
}
