const suffix = (m: number): 'st' | 'nd' | 'rd' | 'th' => {
  if ([11, 12, 13].includes(m % 100)) return 'th'

  const i = m % 10

  if (i === 1) return 'st'
  if (i === 2) return 'nd'
  if (i === 3) return 'rd'

  return 'th'
}

export const ordinal = (n: number): string => n + suffix(n)
