import { ThemeContext } from 'grommet'
import { useCallback, useMemo, useRef } from 'react'
import scrollIntoView from 'scroll-into-view-if-needed'

import { useCurrentUser } from '../../../contexts/UserContext'

import { MilestoneInput } from './MilestoneInput'
import { NameInput } from './NameInput'
import { OtherFields, Props as OtherFieldsProps } from './OtherFields'
import { OwnerInput } from './OwnerInput'
import { ProgressRateInput } from './ProgressRateInput'
import { UserFragment, GroupFragment, TargetSettingType, File } from './graphql'
import { useKeyResultReducer } from './hooks/useKeyResultReducer'
import { useStyles } from './index.styles'
import {
  CreateKeyResultError,
  ExtendedCreateKeyResultInput,
  KeyResultEditFieldsInput,
  KeyResultEditType,
  UpdateKeyResultError,
} from './types'

export type Props<T extends KeyResultEditFieldsInput> = {
  type: KeyResultEditType
  initialKeyResult: T
  onChangeKeyResult: (kr: T) => void
  users: ReadonlyArray<UserFragment>
  disableUsers: ReadonlyArray<UserFragment>
  groups: ReadonlyArray<GroupFragment>
  error?: T extends ExtendedCreateKeyResultInput ? CreateKeyResultError : UpdateKeyResultError
}

export const KeyResultEditFields = <T extends KeyResultEditFieldsInput>({
  type,
  initialKeyResult,
  onChangeKeyResult,
  users,
  disableUsers,
  groups,
  error,
}: Props<T>): React.ReactElement => {
  const styles = useStyles()

  const [keyResult, dispatch] = useKeyResultReducer<T>(initialKeyResult, onChangeKeyResult)
  const setName = useCallback(
    (name: string) => dispatch({ type: 'name', payload: name }),
    [dispatch],
  )
  const setOwnerId = useCallback(
    (ownerId: string) => dispatch({ type: 'ownerId', payload: ownerId }),
    [dispatch],
  )
  const setTargetValue = useCallback(
    (targetValue: number) => dispatch({ type: 'targetValue', payload: targetValue }),
    [dispatch],
  )
  const setActualValue = useCallback(
    (actualValue: number) => dispatch({ type: 'actualValue', payload: actualValue }),
    [dispatch],
  )
  const setInitialValue = useCallback(
    (initialValue: number) => dispatch({ type: 'initialValue', payload: initialValue }),
    [dispatch],
  )
  const setUnit = useCallback(
    (unit: string) => dispatch({ type: 'unit', payload: unit }),
    [dispatch],
  )
  const setUpdateMemo = useCallback(
    (message: string) => dispatch({ type: 'message', payload: message }),
    [dispatch],
  )
  const setConfidence = useCallback(
    (confidence: number) => dispatch({ type: 'confidence', payload: confidence }),
    [dispatch],
  )
  const setDescription = useCallback(
    (treeJson: string, plainText: string) =>
      dispatch({ type: 'description', payload: { treeJson, plainText } }),
    [dispatch],
  )
  const setAttachments = useCallback(
    (files: ReadonlyArray<File>) => dispatch({ type: 'attachmentViews', payload: files }),
    [dispatch],
  )
  const setMembers = useCallback(
    (memberIds: ReadonlyArray<string>) => dispatch({ type: 'memberIds', payload: memberIds }),
    [dispatch],
  )
  const setTargetSetting = useCallback(
    (targetSetting: TargetSettingType) =>
      dispatch({ type: 'targetSetting', payload: targetSetting }),
    [dispatch],
  )

  const otherFieldsRef = useRef<HTMLDivElement>(null)
  const handleOpenDetailSetting = useCallback<NonNullable<OtherFieldsProps['onOpen']>>(() => {
    // DOMが更新されるまで待つ
    setTimeout(() => {
      if (!otherFieldsRef.current) return
      scrollIntoView(otherFieldsRef.current, {
        block: 'nearest',
        scrollMode: 'if-needed',
      })
    }, 0)
  }, [])

  const currentUser = useCurrentUser()
  const isCurrentUserAssigned = useMemo(
    () =>
      !!currentUser &&
      (currentUser.id === keyResult.ownerId || keyResult.memberIds.includes(currentUser.id)),
    [currentUser, keyResult],
  )

  const usersWithDisabledOwner = useMemo(
    () => users.concat(disableUsers.find((user) => user.id === keyResult.ownerId) ?? []),
    [disableUsers, keyResult.ownerId, users],
  )
  const usersWithDisabledMembers = useMemo(
    () => users.concat(disableUsers.filter((user) => keyResult.memberIds.includes(user.id))),
    [disableUsers, keyResult.memberIds, users],
  )

  return (
    <ThemeContext.Extend value={{ global: { drop: { zIndex: '201' } } }}>
      <div css={styles.root}>
        <NameInput name={keyResult.name} setName={setName} />
        <OwnerInput
          users={usersWithDisabledOwner}
          ownerId={keyResult.ownerId}
          memberIds={keyResult.memberIds}
          setOwnerId={setOwnerId}
        />
        <ProgressRateInput
          type={type}
          targetValue={keyResult.targetValue}
          setTargetValue={setTargetValue}
          isAlreadySetTargetValue={initialKeyResult.targetValue != null}
          actualValue={'actualValue' in keyResult ? keyResult.actualValue : null}
          setActualValue={setActualValue}
          initialValue={keyResult.initialValue}
          setInitialValue={setInitialValue}
          unit={keyResult.unit}
          setUnit={setUnit}
          setUpdateMemo={setUpdateMemo}
          confidence={keyResult.confidence}
          setConfidence={setConfidence}
          isCurrentUserAssigned={isCurrentUserAssigned}
          error={error}
        />
        <MilestoneInput
          initialValueJson={keyResult.description?.treeJson}
          setDescription={setDescription}
          attachmentViews={keyResult.attachmentViews ?? []}
          onChangeAttachments={setAttachments}
        />
        <OtherFields
          ref={otherFieldsRef}
          onOpen={handleOpenDetailSetting}
          users={usersWithDisabledMembers}
          groups={groups}
          targetSetting={keyResult.targetSetting}
          setTargetSetting={setTargetSetting}
          ownerId={keyResult.ownerId}
          memberIds={keyResult.memberIds}
          setMembers={setMembers}
        />
      </div>
    </ThemeContext.Extend>
  )
}
