export const PAGING_COUNT = 3

export const VARIABLES = {
  first: PAGING_COUNT,
  after: null,
  last: null,
  before: null,
}

export const dateFormat = 'YYYY/MM/DD'

export const timeFormat = 'H:mm'
