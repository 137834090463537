import { PropsWithoutRef } from 'react'

import * as colors from '../../../styles/colors'
import { Icon, Type as IconType } from '../Icon'

export type SpecificProps = PropsWithoutRef<JSX.IntrinsicElements['div']> & {
  open?: boolean
}

export const SuccessSnackbar: React.FC<SpecificProps> = (props) => (
  <Snackbar icon="check" css={{ backgroundColor: colors.success }} {...props} />
)

SuccessSnackbar.displayName = 'SuccessSnackbar'

export const WarningSnackbar: React.FC<SpecificProps> = (props) => (
  <Snackbar icon="warning" css={{ backgroundColor: colors.warning }} {...props} />
)

WarningSnackbar.displayName = 'WarningSnackbar'

export const ErrorSnackbar: React.FC<SpecificProps> = (props) => (
  <Snackbar icon="error" css={{ backgroundColor: colors.error }} {...props} />
)

ErrorSnackbar.displayName = 'ErrorSnackbar'

export type Props = SpecificProps & {
  icon?: IconType
}

export const Snackbar: React.FC<Props> = ({ open = false, icon, children, ...props }) => (
  <div
    css={{
      position: 'fixed',
      bottom: 16,
      left: '50%',
      transform: `translateX(-50%)`,
      pointerEvents: 'none',
      zIndex: 9999,
    }}
  >
    <div
      css={{
        borderRadius: 4,
        boxShadow: `0px 3px 5px -1px rgb(0 0 0 / 20%), 0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%)`,
        padding: '6px 16px',
        display: 'inline-flex',
        alignItems: 'center',
        fontWeight: 'bold',
        color: colors.textMain,
        transition: `opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
        opacity: 0,
        transform: `translateY(50%)`,
      }}
      style={
        open
          ? {
              opacity: 1,
              transform: `none`,
            }
          : {}
      }
      {...props}
    >
      {icon ? (
        <div
          css={{
            marginRight: 8,
          }}
        >
          <Icon type={icon} />
        </div>
      ) : null}
      <div
        css={{
          marginRight: 4,
        }}
      >
        {children}
      </div>
    </div>
  </div>
)

Snackbar.displayName = 'Snackbar'
