import dayjs, { Dayjs } from 'dayjs'

import { client as httpClient } from './lib/client'
import { updateLastSeen } from './lib/tracking'

const INTERVAL = 1_000 * 60 * 10 // 10min
type UpdateLastAccessedStore = {
  timer: NodeJS.Timeout | null
  lastRequested: Dayjs | null
}
const UPDATE_LAST_ACCESSED_STORE: UpdateLastAccessedStore = {
  timer: null,
  lastRequested: null,
}

/**
 * ログインユーザーの最終アクセス日時をDBに保持するため、一定のinterval(10min) で HTTP Request を投げる
 *
 * browser tab/window が inactive になった場合、ポーリングを中断する。再度 active になった時、
 * 最後に HTTP Request を投げた日時と現在日時の差 > interval の場合、即座にHTTP Request を投げ、ポーリングを開始
 * 最後に HTTP Request を投げた日時と現在日時の差 < interval の場合、intervalを一定に保つよう、差分の時分後にポーリングを再開
 *
 * multi browser/window/tab は考慮せず、各SPAでポーリングする
 */
export function startPollingUpdateLastAccessed(): void {
  window.addEventListener('blur', (_ev) => {
    const { timer } = UPDATE_LAST_ACCESSED_STORE
    if (timer) {
      clearTimeout(timer)
    }
  })
  window.addEventListener('focus', (_ev) => {
    const { lastRequested } = UPDATE_LAST_ACCESSED_STORE
    if (lastRequested) {
      const diff = Math.abs(lastRequested.diff())
      if (diff > INTERVAL) {
        updateLastAccessedDatetime()
      } else {
        setTimeout(updateLastAccessedDatetime, INTERVAL - diff)
      }
    } else {
      updateLastAccessedDatetime()
    }
  })
  updateLastAccessedDatetime()
}

async function updateLastAccessedDatetime() {
  if (document.hidden || !cookieExists()) {
    UPDATE_LAST_ACCESSED_STORE.timer = setTimeout(updateLastAccessedDatetime, INTERVAL)
  } else {
    updateLastSeen()
    await httpClient.post<void>(
      '/user/access',
      {},
      {
        success: false,
        error: false,
      },
    )
    UPDATE_LAST_ACCESSED_STORE.lastRequested = dayjs()
    UPDATE_LAST_ACCESSED_STORE.timer = setTimeout(updateLastAccessedDatetime, INTERVAL)
  }
}

const cookieExists = () => document.cookie.includes('resily_session')
