import { useCallback, useMemo } from 'react'

import { useCurrentUser } from '../../../../../contexts/UserContext'
import { useTranslation } from '../../../../../i18n'
import { findScreen } from '../../../../../lib/screen'
import { tracker } from '../../../../../lib/tracking'
import { generateActionPlanEdit } from '../../../../../urls'
import { useOkrModalQueryParams } from '../../hooks/useOkrModalQueryParams'

import { ActionPlan } from './ActionPlan'
import { useFetchKeyResultActionPlansQuery, useCreateActionPlanMutation } from './graphql'

export const ActionPlanContainer: React.VFC = () => {
  const user = useCurrentUser()
  const { t } = useTranslation()

  const [createActionPlan] = useCreateActionPlanMutation()
  const { keyResultId } = useOkrModalQueryParams()

  const { data, loading } = useFetchKeyResultActionPlansQuery({
    variables: { keyResultId },
    skip: keyResultId == null,
  })

  const handleCreateKeyResultActionPlan = useCallback(async () => {
    if (!user || !keyResultId) return

    tracker.UserClickCreateActionPlanByKeyResultDrawer(
      findScreen(window.location.pathname, window.location.search),
    )

    const result = await createActionPlan({
      variables: {
        title: t('EMPTY_ACTION_PLAN'),
        ownerId: user.id,
        keyResultId,
      },
    })
    if (!result.data?.createActionPlan) return
    window.open(
      generateActionPlanEdit(
        result.data.createActionPlan.keyResult.node.id,
        result.data.createActionPlan.keyResult.id,
        result.data.createActionPlan.id,
      ),
    )
  }, [createActionPlan, keyResultId, t, user])

  const actionPlans = useMemo(
    () => data?.okrNode.keyResults.find((kr) => kr.id === keyResultId)?.actionPlans,
    [data?.okrNode.keyResults, keyResultId],
  )

  return (
    <ActionPlan
      loading={loading && data == null}
      actionPlans={actionPlans}
      onClickCreateActionPlan={handleCreateKeyResultActionPlan}
    />
  )
}
