import { useModal } from '@resily/geisha'
import { useCallback, useEffect, useMemo } from 'react'
import { NavigationType, useNavigationType } from 'react-router-dom'

import { useCheckEditingOnClose } from '../../../../contexts/EditingStateContext'
import { Screen } from '../../../../lib/screen'
import { tracker } from '../../../../lib/tracking'
import { useKeyResultDrawer } from '../KeyResultDrawer/hooks/useKeyResultDrawer'
import { OkrModalContainer, OkrModalContainerProps } from '../OkrModalContainer'
import { KeyResultTab, ObjectiveTab } from '../types'

import { useOkrModalQueryParams } from './useOkrModalQueryParams'

export type OkrModalReturnType = {
  OkrModal: JSX.Element
  openOkrModal: (id: string, screen: Screen, isAssigned: boolean, tab?: ObjectiveTab) => void
  openOkrModalWithKeyResultDrawer: (
    objectiveId: string,
    keyResultId: string,
    screen: Screen,
    isAssigned?: boolean,
    tab?: KeyResultTab,
  ) => void
}

export const useOkrModal = (): OkrModalReturnType => {
  const {
    objectiveId: queryParamObjectiveId,
    keyResultId: queryParamKeyResultId,
    setObjectiveId,
    setObjectiveTab,
    resetAllQueryParams,
  } = useOkrModalQueryParams()
  // KR右ドロワーのqueryParamにはKRのIDしかないので、OのIDが無くてもKRのIDがあれば開く
  const [Modal, isOpened, { open, close }] = useModal(
    !!queryParamObjectiveId || !!queryParamKeyResultId,
  )

  const navType = useNavigationType()
  // ブラウザの進む・戻るでクエリパラメーターを確認して、モーダルの開閉状態を変更する
  useEffect(() => {
    if (navType === NavigationType.Replace) {
      return
    }

    if (queryParamObjectiveId) {
      open()
      return
    }

    close()
  }, [close, navType, open, queryParamKeyResultId, queryParamObjectiveId])

  const {
    isOpened: isOpenedKeyResultDrawer,
    open: openKeyResultDrawer,
    close: closeKeyResultDrawer,
  } = useKeyResultDrawer()

  const openOkrModal = useCallback<OkrModalReturnType['openOkrModal']>(
    (id, screen, isAssigned, tab) => {
      tracker.UserClickOpenOkrModalByObjectiveName(screen, isAssigned)
      setObjectiveId(id)
      if (tab) setObjectiveTab(tab)
      open()
    },
    [open, setObjectiveId, setObjectiveTab],
  )

  const checkEditingOnClose = useCheckEditingOnClose()
  const closeOkrModal = useCallback<OkrModalContainerProps['close']>(() => {
    const wrapCloseModal = () => {
      resetAllQueryParams()
      close()
    }
    if (isOpenedKeyResultDrawer) {
      closeKeyResultDrawer(wrapCloseModal)
    } else {
      checkEditingOnClose(wrapCloseModal)
    }
  }, [
    checkEditingOnClose,
    close,
    closeKeyResultDrawer,
    isOpenedKeyResultDrawer,
    resetAllQueryParams,
  ])

  // closeKeyResultDrawerを直渡し続けるとSyntheticEventが渡された時にバグるのでラップする
  const closeKeyResultDrawerOnly = useCallback(() => closeKeyResultDrawer(), [closeKeyResultDrawer])

  const openOkrModalWithKeyResultDrawer = useCallback<
    OkrModalReturnType['openOkrModalWithKeyResultDrawer']
  >(
    (objectiveId, keyResultId, screen, isAssigned, tab) => {
      tracker.UserClickOpenOkrModalByKeyResultName(screen, isAssigned)
      openKeyResultDrawer(objectiveId, keyResultId, tab)
      open()
    },
    [open, openKeyResultDrawer],
  )

  const handleClickKeyResultName = useCallback(
    (keyResultId: string) => {
      if (!queryParamObjectiveId) {
        throw new Error('queryParamObjectiveId is not found.')
      }

      openKeyResultDrawer(queryParamObjectiveId, keyResultId)
    },
    [openKeyResultDrawer, queryParamObjectiveId],
  )

  const OkrModal = useMemo(
    () => (
      <OkrModalContainer
        modalComponent={Modal}
        isOpened={isOpened}
        close={closeOkrModal}
        objective={{ onClickObjective: closeKeyResultDrawerOnly }}
        keyResult={{ onClickKeyResultName: handleClickKeyResultName }}
        keyResultDrawer={{
          isOpened: isOpenedKeyResultDrawer,
          onClose: closeKeyResultDrawerOnly,
        }}
      />
    ),
    [
      Modal,
      closeKeyResultDrawerOnly,
      closeOkrModal,
      handleClickKeyResultName,
      isOpened,
      isOpenedKeyResultDrawer,
    ],
  )

  return {
    OkrModal,
    openOkrModal,
    openOkrModalWithKeyResultDrawer,
  }
}
