import { useState, useMemo, useCallback, useRef } from 'react'

import { useSmartHrEmployeesLazyQuery, SmartHrEmployeeFragment, PageInfo } from '../graphql'

type UsePaginatedSmartHREmployeeProps = {
  synced: boolean
  page: number
}

export type DispathHandPageAction = 'initial' | 'next' | 'previous'
export type DispathHandPageActionOption = { perPage?: number; synced?: boolean }

// TODO: debug用で1にしているが、開発が終わったら30に戻す
export const PER_PAGES = [1, 50, 100] as const
export type PerPageType = typeof PER_PAGES[number]

export const DEFAULT_PER_PAGE = 100

export type PaginatedSmartHREmployeeReturn = {
  /** 現在のページ */
  page: number
  /** 全要素数 */
  allItemsCount: number
  /** 1ページあたりの行数 */
  perPage: number
  /** 1ページあたりの行数を変更 */
  setPerPage(perPage: PerPageType): void
  isLoading: boolean
  items: ReadonlyArray<SmartHrEmployeeFragment>
  /**
   * items内のデータを更新する
   * targetがitemsに含まれてない場合は何もしない
   */
  updateCache(targets: ReadonlyArray<SmartHrEmployeeFragment>): void
  /**
   * targetsがitemsに含まれていれば削除
   */
  removeCache(targets: ReadonlyArray<SmartHrEmployeeFragment>): void
  dispatchPagingAction: (
    action: DispathHandPageAction,
    option?: DispathHandPageActionOption,
  ) => void
}

export const usePaginatedSmartHREmployee = ({
  synced,
  page: defaultPage,
}: UsePaginatedSmartHREmployeeProps): PaginatedSmartHREmployeeReturn => {
  const [perPage, setPerPage] = useState<PerPageType>(DEFAULT_PER_PAGE) // 現在のページ
  const [page, setPage] = useState(defaultPage) // 現在のページ
  const [originItems, setItems] = useState<ReadonlyArray<SmartHrEmployeeFragment>>([]) // 最後に取得した従業員一覧
  const pageInfo = useRef<PageInfo>({ hasPreviousPage: false, hasNextPage: false })

  const items = useMemo(() => originItems, [originItems])
  const setPageInfo = (info: PageInfo) => {
    pageInfo.current = info
  }

  const updateCache = useCallback(
    (targets: ReadonlyArray<SmartHrEmployeeFragment>) => {
      if (!targets.length) return
      const newItems = items.map((item) => {
        const target = targets.find((t) => t.id === item.id)
        if (target) return target
        return item
      })
      setItems(newItems)
    },
    [items],
  )

  const removeCache = useCallback(
    (targets: ReadonlyArray<SmartHrEmployeeFragment>) => {
      if (!targets.length) return
      const newItems = items.filter((item) => !targets.some((target) => target.id === item.id))
      setItems(newItems)
    },
    [items],
  )

  const [fetchSmartHREmployees, fetchingState] = useSmartHrEmployeesLazyQuery({
    onCompleted: ({ findSmartHREmployees }) => {
      setPage(1)
      if (!findSmartHREmployees?.edges) return
      setPageInfo(findSmartHREmployees.pageInfo)
      setItems(findSmartHREmployees.edges.flatMap((e) => (e?.node ? [e.node] : [])))
    },
  })

  const allItemsCount = fetchingState.data?.findSmartHREmployees?.totalCount || 0

  const [nextSmartHREmployees, nextState] = useSmartHrEmployeesLazyQuery({
    onCompleted: ({ findSmartHREmployees }) => {
      setPage((prev) => prev + 1)
      if (!findSmartHREmployees?.edges) return
      setPageInfo(findSmartHREmployees.pageInfo)
      setItems(findSmartHREmployees.edges.flatMap((e) => (e?.node ? [e.node] : [])))
    },
  })

  const [previouSmartHREmployees, previousState] = useSmartHrEmployeesLazyQuery({
    onCompleted: ({ findSmartHREmployees }) => {
      setPage((prev) => prev - 1)
      if (!findSmartHREmployees?.edges) return
      setPageInfo(findSmartHREmployees.pageInfo)
      setItems(findSmartHREmployees.edges.flatMap((e) => (e?.node ? [e.node] : [])))
    },
  })

  const isLoading = fetchingState.loading || nextState.loading || previousState.loading

  const handlePageAction = useCallback(
    (action: DispathHandPageAction, option?: DispathHandPageActionOption) => {
      if (isLoading) return

      const settingPerPage = option?.perPage || perPage
      const settingSynced = option?.synced !== undefined ? option.synced : synced

      switch (action) {
        case 'initial':
          fetchSmartHREmployees({
            variables: {
              synced: settingSynced,
              first: settingPerPage,
              after: null,
              last: null,
              before: null,
            },
          })
          break
        case 'next':
          if (!pageInfo.current.hasNextPage) return
          nextSmartHREmployees({
            variables: {
              synced: settingSynced,
              first: settingPerPage,
              after: pageInfo.current.endCursor,
              last: null,
              before: null,
            },
          })
          break
        case 'previous':
          if (page <= 1 || !pageInfo.current.hasPreviousPage) return
          previouSmartHREmployees({
            variables: {
              synced: settingSynced,
              last: settingPerPage,
              before: pageInfo.current.startCursor,
              first: null,
              after: null,
            },
          })
          break
        default:
          fail(action)
      }
    },
    [
      synced,
      fetchSmartHREmployees,
      isLoading,
      nextSmartHREmployees,
      perPage,
      page,
      previouSmartHREmployees,
    ],
  )

  const handleSetPerPage = useCallback(
    (rowNumber: PerPageType) => {
      setPerPage(rowNumber)
      handlePageAction('initial', { perPage: rowNumber })
    },
    [handlePageAction],
  )

  return {
    items,
    updateCache,
    removeCache,
    allItemsCount,
    page,
    isLoading,
    perPage,
    setPerPage: handleSetPerPage,
    dispatchPagingAction: handlePageAction,
  }
}
