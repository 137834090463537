import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { useCurrentUser } from '../../contexts/UserContext'
import { AuthRouteProps } from '../../types/authRouteProps'
import * as urls from '../../urls'

export const TermContainer: React.FC<AuthRouteProps> = ({ onOkrTermLoaded }) => {
  const navigate = useNavigate()
  const { termId } = useParams<{ termId: string }>()
  const user = useCurrentUser()
  useEffect(() => {
    if (termId !== urls.currentTag && termId != null) {
      onOkrTermLoaded(termId)
    }
  }, [termId, onOkrTermLoaded])

  useEffect(() => {
    if (!user?.id || termId == null) return
    navigate(urls.generateHome(termId)(user.id), { replace: true })
  }, [navigate, termId, user?.id])

  return null
}

TermContainer.displayName = 'TermContainer'
