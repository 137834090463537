import {
  ELEMENT_CODE_BLOCK,
  ELEMENT_DEFAULT,
  ELEMENT_HR,
  ELEMENT_OL,
  ELEMENT_UL,
  getPluginType,
  insertEmptyCodeBlock,
  insertNodes,
  KEY_LIST_STYLE_TYPE,
  ListStyleType,
  PlateEditor,
  setNodes,
  toggleIndentList,
  toggleNodeType,
} from '@udecode/plate'
import { useCallback } from 'react'

import { EMPTY_PARAGRAPH } from '../constants'
import { clearListStyle } from '../plugins/autoformat/autoFormatRules'
import { ElementType, HrElement, ParagraphElement } from '../types'

type ToggleElement<T extends ElementType> = (
  type: T,
  inactiveType?: Exclude<ElementType, T>,
) => void

export const useToggleElement = <T extends ElementType>(
  editor: PlateEditor | null,
): ToggleElement<T> => {
  const toggleElement = useCallback<ToggleElement<T>>(
    (type, inactiveType) => {
      if (!editor) {
        return
      }

      if (type !== ELEMENT_OL && type !== ELEMENT_UL) {
        clearListStyle(editor)
      } else {
        setNodes(editor, { type: ELEMENT_DEFAULT })
      }

      switch (type) {
        case ELEMENT_CODE_BLOCK:
          insertEmptyCodeBlock(editor, { insertNodesOptions: { select: true } })
          break
        case ELEMENT_HR:
          setNodes<HrElement>(editor, {
            type: ELEMENT_HR,
            children: [{ text: '' }],
          } as HrElement)
          insertNodes<ParagraphElement>(editor, EMPTY_PARAGRAPH, { select: true })
          break
        case ELEMENT_OL:
          toggleIndentList(editor, { [KEY_LIST_STYLE_TYPE]: ListStyleType.Decimal })
          break
        case ELEMENT_UL:
          toggleIndentList(editor, { [KEY_LIST_STYLE_TYPE]: ListStyleType.Disc })
          break
        default:
          toggleNodeType(editor, { activeType: getPluginType(editor, type), inactiveType })
          break
      }
    },
    [editor],
  )

  return toggleElement
}
