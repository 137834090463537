import { createContext, useReducer, useMemo, FC } from 'react'

type ObjectiveId = string
type KeyResultId = string
type KeyResultIds = ReadonlyArray<KeyResultId>
export type State = {
  readonly filters: Map<ObjectiveId, KeyResultIds>
  lastFiltering: {
    id: string
    timestamp: number
  }
}

const initialState: State = { filters: new Map(), lastFiltering: { id: '', timestamp: 0 } }

type Action =
  | { type: 'toggle'; keys: ReadonlyArray<ObjectiveId>; value: KeyResultId }
  | { type: 'add'; keys: ReadonlyArray<ObjectiveId>; value: KeyResultId }
  | { type: 'remove'; keys: ReadonlyArray<ObjectiveId>; value: KeyResultId }
  | { type: 'clear' }

const hasFilter = (id: ObjectiveId, map: Map<ObjectiveId, KeyResultIds>) =>
  Array.from(map.values())
    .flat()
    .some((kid) => kid === id)

const addFilter = (oid: ObjectiveId, kids: KeyResultIds, map: Map<ObjectiveId, KeyResultIds>) =>
  kids.forEach((key) => map.set(key, map.get(key)?.concat(oid) ?? [oid]))

const removeFilter = (
  oid: ObjectiveId,
  kids: KeyResultIds,
  map: Map<ObjectiveId, KeyResultIds>,
) => {
  kids.forEach((key) => {
    const ids = map.get(key)
    if (ids?.length && !(ids.includes(oid) && ids.length === 1)) {
      map.set(
        key,
        ids.filter((id) => id !== oid),
      )
    } else {
      map.delete(key)
    }
  })
}

const reducer = (state: State, action: Action) => {
  const newState = { ...state }

  switch (action.type) {
    case 'toggle':
      if (hasFilter(action.value, state.filters)) {
        removeFilter(action.value, action.keys, newState.filters)
      } else {
        addFilter(action.value, action.keys, newState.filters)
      }
      return { ...newState, lastFiltering: { id: action.value, timestamp: new Date().getTime() } }
    case 'add':
      addFilter(action.value, action.keys, newState.filters)
      return { ...newState, lastFiltering: { id: action.value, timestamp: new Date().getTime() } }
    case 'remove':
      removeFilter(action.value, action.keys, newState.filters)
      return { ...newState, lastFiltering: { id: action.value, timestamp: new Date().getTime() } }
    case 'clear':
      return {
        ...state,
        filters: new Map<ObjectiveId, KeyResultIds>(),
        lastFiltering: { id: '', timestamp: 0 },
      }
    default:
      return state
  }
}

type Toggle = (keys: ReadonlyArray<ObjectiveId>, value: KeyResultId) => void
type Add = (keys: ReadonlyArray<ObjectiveId>, value: KeyResultId) => void
type Remove = (keys: ReadonlyArray<ObjectiveId>, value: KeyResultId) => void
type Clear = () => void
type Has = (key: ObjectiveId) => boolean
export type HasKeyResult = (key: KeyResultId) => boolean

type ContextType = State & {
  toggleKrFilter: Toggle
  addKrFilter: Add
  removeKrFilter: Remove
  clearKrFilters: Clear
  hasObjective: Has
  hasKeyResult: HasKeyResult
}

export const KrFilterContext = createContext<ContextType>({
  filters: initialState.filters,
  lastFiltering: initialState.lastFiltering,
  toggleKrFilter: () => {
    throw new Error('Should use KrFilterContextProvider.Provider')
  },
  addKrFilter: () => {
    throw new Error('Should use KrFilterContextProvider.Provider')
  },
  removeKrFilter: () => {
    throw new Error('Should use KrFilterContextProvider.Provider')
  },
  clearKrFilters: () => {
    throw new Error('Should use KrFilterContextProvider.Provider')
  },
  hasObjective: () => {
    throw new Error('Should use KrFilterContextProvider.Provider')
  },
  hasKeyResult: () => {
    throw new Error('Should use KrFilterContextProvider.Provider')
  },
})

export const KrFilterContextProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const toggleKrFilter: Toggle = (keys, value) => dispatch({ type: 'toggle', keys, value })
  const addKrFilter: Add = (keys, value) => dispatch({ type: 'add', keys, value })
  const removeKrFilter: Remove = (keys, value) => dispatch({ type: 'remove', keys, value })
  const clearKrFilters: Clear = () => dispatch({ type: 'clear' })

  const value = useMemo<ContextType>(
    () => {
      const hasObjective: Has = (key) => state.filters.has(key)
      const hasKeyResult: HasKeyResult = (key) => hasFilter(key, state.filters)

      return {
        ...state,
        toggleKrFilter,
        addKrFilter,
        removeKrFilter,
        clearKrFilters,
        hasObjective,
        hasKeyResult,
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state],
  )

  return <KrFilterContext.Provider value={value}>{children}</KrFilterContext.Provider>
}

KrFilterContextProvider.displayName = 'KrFilterContextProvider'
