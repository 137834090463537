import { useState, useEffect, useRef } from 'react'
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  ResponderProvided,
} from 'react-beautiful-dnd'

import { color } from '../../styles/newColors'

import { DraggableKeyResultsTableItems } from './DraggableKeyResultsTableItems'
import { KeyResultFragment } from './graphql'

export type Props = {
  termId: string
  items: ReadonlyArray<KeyResultFragment>
  reOrder: (keyResult: KeyResultFragment, displayOrder: number) => void
  onClickItem: (keyResult: KeyResultFragment) => void
}

const reorder = (list: ReadonlyArray<KeyResultFragment>, startIndex: number, endIndex: number) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export const DraggableKeyResultsTable: React.FC<Props> = ({
  termId,
  items,
  reOrder,
  onClickItem,
}) => {
  const [state, setState] = useState<{ items: ReadonlyArray<KeyResultFragment> }>({
    items,
  })

  const [width, setWidth] = useState<number | undefined>(undefined)

  const ref = useRef<HTMLTableDataCellElement>(null)

  useEffect(() => {
    setState({ items })
  }, [items])

  const onDragEnd = (result: DropResult, _: ResponderProvided) => {
    if (!result.destination) return

    const source = result.source.index
    const destination = result.destination.index

    // 移動していない場合は処理を行わない
    if (source === destination) return

    const newDisplayOrder = () => {
      const dispo = state.items[destination].displayOrder || 0
      // +1によるインクリメントをリセットしたいため、要素を1番目に移動した場合は、displayOrderをリセットする
      if (destination === 0) return 0

      // 要素の位置を後ろにするためには、押し除ける要素のdisplayOrder＋１を指定する必要がある
      if (destination > source) return dispo + 1

      return dispo
    }

    // 並び替え完了までのちらつき防止
    setState({ items: reorder(state.items, source, destination) })

    reOrder(state.items[result.source.index], newDisplayOrder())
  }

  const onBeforeDragStart = () => {
    setWidth(ref.current?.clientWidth)
  }

  return (
    <DragDropContext onDragEnd={onDragEnd} onBeforeDragStart={onBeforeDragStart}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <table
            ref={provided.innerRef}
            css={{
              width: '100%',
              borderCollapse: 'collapse',

              tbody: {
                tr: {
                  td: {
                    borderBottom: `1px solid ${color('border-bk-10')}`,

                    ':nth-of-type(1)': {
                      div: {
                        display: 'none',
                      },
                    },
                  },
                },
              },

              'tr:hover': {
                backgroundColor: color('hover-background-bk-5'),

                td: {
                  ':nth-of-type(1)': {
                    div: {
                      display: 'block',
                    },
                  },
                },
              },
            }}
          >
            <tbody>
              {state.items.map((item, index) => (
                <Draggable
                  key={item.id}
                  draggableId={item.id}
                  index={index}
                  css={{
                    cursor: 'auto',
                  }}
                >
                  {(p) => (
                    <DraggableKeyResultsTableItems
                      termId={termId}
                      keyResult={item}
                      provider={p}
                      tdWidth={width || 0}
                      onClick={() => onClickItem(item)}
                    />
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </tbody>
          </table>
        )}
      </Droppable>
    </DragDropContext>
  )
}

DraggableKeyResultsTable.displayName = 'DraggableKeyResultsTable'
