import { css } from '@emotion/react'

import { color } from '../../../../../../styles/newColors'

export const styles = {
  root: css({
    marginBottom: '12px',
    padding: '12px',
    backgroundColor: color('white-100'),
    borderRadius: '8px',
  }),
  header: css({
    display: 'flex',
    justifyContent: 'space-between',
  }),
  avatar: css({
    marginRight: '12px',
  }),
  link: css({
    display: 'flex',
    alignItems: 'flex-start',
  }),
  body: css({
    marginLeft: '40px',
  }),
  checkinComment: css({
    display: 'flex',
    alignItems: 'center',
    marginTop: '10px',
    '&:first-child': {
      marginTop: '12px',
    },
  }),
  checkinLabel: css({
    marginRight: '16px',
  }),
}
