import { css } from '@emotion/react'
import { IconButton } from '@resily/geisha'
import { Drop, DropExtendedProps, Box } from 'grommet'
import { Fragment, useRef, useState } from 'react'

import * as colors from '../../../styles/colors'
import { MenuItem, Props as MenuItemProps } from '../MenuItem'

const DropMenuCss = css({ borderRadius: '8px', padding: '12px 0px', overflow: 'visible' })

const DropMenuIconCss = css({
  padding: '16px 20px',
})

export type PopoverItem = {
  label: string
  disabled?: boolean
  icon?: MenuItemProps['icon']
  iconToolTip?: MenuItemProps['iconToolTip']
  tooltipLabel?: MenuItemProps['tooltipLabel']
  onItemClicked: () => void
}

export type Props = {
  tooltipLabel?: string
  popoverItems: ReadonlyArray<PopoverItem>
  popoverAlign?: DropExtendedProps['align']
}

export const ThreePointsWithMenu: React.FC<Props> = ({
  tooltipLabel,
  popoverItems,
  popoverAlign = { top: 'top', left: 'left' },
}) => {
  const threePointsRef = useRef<HTMLButtonElement>(null)
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  return (
    <Fragment>
      <IconButton
        ref={threePointsRef}
        type="threePointLeader"
        size="medium"
        tooltipProps={tooltipLabel ? { label: tooltipLabel } : undefined}
        onClick={() => setIsPopoverOpen(!isPopoverOpen)}
      />
      {isPopoverOpen && (
        <Drop
          target={threePointsRef.current || undefined}
          css={DropMenuCss}
          align={popoverAlign}
          onClickOutside={() => setIsPopoverOpen(false)}
        >
          <Box as="ul" direction="column" background={{ color: colors.baseFill }} round="small">
            {popoverItems.map(({ onItemClicked, ...item }) => (
              <MenuItem
                key={item.label}
                rootCss={DropMenuIconCss}
                {...item}
                onClick={() => {
                  setIsPopoverOpen(false)
                  onItemClicked()
                }}
              />
            ))}
          </Box>
        </Drop>
      )}
    </Fragment>
  )
}
ThreePointsWithMenu.displayName = 'ThreePointsWithMenuNew'
