import { useTranslation } from '../../../../i18n'
import { StyledText } from '../../../ui/StyledText'
import { UserSelect } from '../../UserSelect'
import { UserFragment } from '../graphql'

import { useStyles } from './index.styles'

export type Props = {
  users: ReadonlyArray<UserFragment>
  ownerId: string
  memberIds: ReadonlyArray<string>
  setOwnerId: (ownerId: string) => void
}

export const OwnerInput: React.FC<Props> = ({ users, ownerId, memberIds, setOwnerId }) => {
  const { t } = useTranslation()
  const styles = useStyles()

  return (
    <div css={styles.root}>
      <StyledText size="small" fontStyle="bold" css={styles.label}>
        {t('OWNER')}
      </StyledText>
      <UserSelect
        avatarSize="xsmall"
        nameFontSize="medium"
        value={ownerId}
        options={users.filter((u) => !memberIds.includes(u.id))}
        onChange={(id) => setOwnerId(id)}
      />
    </div>
  )
}
