import { Box } from 'grommet'
import { MouseEvent, memo, useCallback, useEffect, useMemo, useState } from 'react'
import isEqual from 'react-fast-compare'

import { Chart, Progress } from '../../../../components/domain/Chart'
import { OkrFloatDrawerList } from '../../../../components/domain/OkrFloatDrawerList'
import { Select, Props as SelectProps } from '../../../../components/ui/Select/index'
import { StyledText } from '../../../../components/ui/StyledText/index'
import { useGetFloatDrawerItems } from '../../../../contexts/OkrFloatDrawerContext'
import { useTranslation } from '../../../../i18n'
import { excludeDisableOkr } from '../../../../lib/domain/okr'
import { TermFragment, OkrNodeFragment, KeyResultFragment } from '../graphql'

import { AssignedOkrList } from './AssignedOkrList'

const CHART_PADDING_HORIZONTAL = 20

type OkrNodes = ReadonlyArray<OkrNodeFragment>

export type Props = {
  userId: string
  selectedTermId: string
  drawerWidth: number
  terms: ReadonlyArray<TermFragment>
  onClickTermOption: SelectProps['onClickOption']
  okrNodes: OkrNodes
}

export const OkrTabContent: React.VFC<Props> = ({
  userId,
  selectedTermId,
  terms,
  onClickTermOption,
  okrNodes,
  drawerWidth,
}) => {
  const { t } = useTranslation()

  const selectedTerm = useMemo<TermFragment | undefined>(
    () => terms.find((term) => term.id === selectedTermId),
    [terms, selectedTermId],
  )

  const termOptions = useMemo<SelectProps['options']>(
    () =>
      terms.map(({ id, name }) => ({
        value: id,
        text: name,
      })),
    [terms],
  )

  const [selectedOkrNodeId, setSelectedOkrNodeId] = useState<string>(
    okrNodes.length > 0 ? okrNodes[0].id : '',
  )
  // 期間切り替えの際に選択済みのOKRを変更
  useEffect(() => {
    setSelectedOkrNodeId(okrNodes.length > 0 ? okrNodes[0].id : '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTermId])

  const onClickOkr = useCallback(
    (_: MouseEvent<HTMLElement, MouseEvent>, okrNodeId: string) => {
      setSelectedOkrNodeId(okrNodeId)
    },
    [setSelectedOkrNodeId],
  )

  const filteredEnableOkrNodes = useMemo<OkrNodes>(
    () => excludeDisableOkr<OkrNodeFragment>(okrNodes),
    [okrNodes],
  )

  const floatDrawerItems = useGetFloatDrawerItems()

  return (
    <>
      <div css={{ height: '100%' }}>
        <div
          css={{
            alignItems: 'center',
            height: '100%',
          }}
        >
          <div>
            <Box css={{ width: '200px', marginBottom: '24px' }}>
              <StyledText
                size="small"
                color="text-bk-50"
                css={{
                  marginBottom: '12px',
                }}
              >
                {t('OKR_TERM')}
              </StyledText>
              <Select
                css={{
                  '*': {
                    '.icon-select-down': {
                      width: '100px',
                      verticalAlign: 'top',
                    },
                  },
                }}
                options={termOptions}
                value={selectedTermId}
                onClickOption={onClickTermOption}
                dropAlign={{ top: 'bottom', left: 'left' }}
              />
            </Box>
            {filteredEnableOkrNodes.length === 0 ? (
              <div
                css={{
                  width: '100%',
                  textAlign: 'center',
                  marginTop: '100px',
                }}
              >
                <StyledText size="large">{t('OKR_NOTHING')}</StyledText>
              </div>
            ) : (
              filteredEnableOkrNodes.map((okrNode) => (
                <div
                  key={okrNode.id}
                  style={{
                    marginBottom: '16px',
                    minWidth: '375px',
                  }}
                >
                  <AssignedOkrList
                    key={okrNode.id}
                    userId={userId}
                    termId={selectedTermId}
                    okrNode={okrNode}
                    onClickOkr={onClickOkr}
                  />
                </div>
              ))
            )}
          </div>

          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {selectedTerm &&
              filteredEnableOkrNodes.map((okrNode) => (
                <div
                  key={okrNode.id}
                  style={{
                    textAlign: 'center',
                    display: okrNode.id === selectedOkrNodeId ? 'block' : 'none',
                    marginBottom: '50px',
                  }}
                >
                  <OkrChart
                    okrNode={okrNode}
                    term={selectedTerm}
                    width={drawerWidth - CHART_PADDING_HORIZONTAL}
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
      <OkrFloatDrawerList addItems={floatDrawerItems} isParentRoot baseZIndex={10} />
    </>
  )
}

OkrTabContent.displayName = 'OkrTabContent'

type KeyResultForecast = Pick<KeyResultFragment, 'id' | 'name' | 'progressForecast'>
type OkrChartProps = {
  okrNode: OkrNodeFragment
  term: TermFragment
  width: number
  height?: number
}

const OkrChart: React.VFC<OkrChartProps> = memo(
  ({ okrNode, term, width, height = 250 }) => {
    const keyResultForecast: Array<KeyResultForecast> = useMemo(
      () =>
        okrNode.keyResults.map((kr) => ({
          id: kr.id,
          name: kr.name,
          progressForecast: kr.progressForecast,
        })),
      [okrNode.keyResults],
    )
    const keyResultProgressies = useMemo(
      () =>
        okrNode.keyResults.map(
          ({ id: keyResultId, name, progressRateHistory }): Array<Progress> =>
            progressRateHistory.edges.map(
              ({ node }) =>
                ({
                  id: node?.id,
                  resourceId: keyResultId,
                  name,
                  progressRate: node?.progressRate,
                  date: node?.createdAt,
                } as Progress),
            ),
        ) ?? [],
      [okrNode.keyResults],
    )

    return (
      <Chart
        objective={okrNode.objective}
        progressies={keyResultProgressies}
        term={term}
        keyResultForecast={keyResultForecast}
        fromDrawer
        fromObjectiveDrawer
        width={width}
        height={height}
      />
    )
  },
  (prev, next) => {
    if (!isEqual(prev.okrNode, next.okrNode)) {
      return false
    }
    if (!isEqual(prev.width, next.width)) {
      return false
    }
    return true
  },
)

OkrChart.displayName = 'OkrChart'
