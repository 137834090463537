import { css } from '@emotion/react'
import { useCallback, MouseEventHandler, useRef } from 'react'

import { useTranslation } from '../../../i18n'
import { LogoPreview } from '../../ui/LogoPreview'
import { TextButton } from '../../ui/TextButton'
import { ImageUploader, ImageFileInfo, UploaderRef } from '../ImageUploader'

const containerCss = css({
  display: 'flex',
  gap: 16,
  alignItems: 'center',
})
const uploadTextButtonCss = css({
  size: 16,
  lineHeight: 16,
  minWidth: 91,
})

export type Props = {
  imageUrl?: string
  onUploaded?: (image: ImageFileInfo) => void
  onRemove?: () => void
}

export const AvatarUploaderContainer: React.FC<Props> = ({
  imageUrl,
  onUploaded,
  onRemove,
  ...props
}) => {
  const { t } = useTranslation()

  const fileRef = useRef<UploaderRef>(null)
  const onClickUpload = useCallback(() => {
    if (!fileRef.current) return
    fileRef.current.openFile()
  }, [])

  const remove = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      e.preventDefault()
      if (onRemove) {
        onRemove()
      }
    },
    [onRemove],
  )

  return (
    <div css={containerCss} {...props}>
      <ImageUploader
        ref={fileRef}
        kind="avatars"
        onUploaded={(newImage) => {
          if (onUploaded) onUploaded(newImage)
        }}
      >
        <LogoPreview width="64px" height="64px" borderWidth="0" image={imageUrl} />
      </ImageUploader>
      <TextButton
        color="resily-orange-100"
        css={uploadTextButtonCss}
        padding="0"
        onClick={onClickUpload}
        hasUnderline
      >
        {t('UPLOAD_X', { x: t('IMAGE_PHOTO') })}
      </TextButton>
      {imageUrl && (
        <TextButton color="text-bk-50" hoverColor="text-bk-100" padding="0" onClick={remove}>
          {t('DELETION_X', { x: t('IMAGE_PHOTO') })}
        </TextButton>
      )}
    </div>
  )
}

AvatarUploaderContainer.displayName = 'AvatarUploaderContainer'
