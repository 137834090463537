import { Text, Button } from 'grommet'
import { FC, ReactNode, VFC, useCallback } from 'react'

import { useOkrTermsQuery } from '../../../contexts/graphql'
import { useTranslation } from '../../../i18n'
import { Screen } from '../../../lib/screen'
import { tracker } from '../../../lib/tracking'
import { border } from '../../../styles/border'
import { color } from '../../../styles/newColors'
import { generateUser, makeCheckinSearchParams } from '../../../urls'
import { useCheckinModal } from '../../standalone/CheckinModal'
import { Card } from '../../ui/Card'
import { StyledText } from '../../ui/StyledText'
import { useCheckinButton } from '../CheckinButton'
import { CheckinComment } from '../CheckinComment'

import { SwitchingEditor } from './SwitchingEditor'
import {
  CheckinUserFragment,
  CheckinSummaryFragment,
  CheckinSummaryKeyResultFragment,
  CheckinSummaryStatus,
  UserFragment,
  KeyResultFragment,
  KeyResultSharedItemType,
  DocumentInput,
} from './graphql'
import { Header } from './header'

export type Props = {
  termId: string
  user: UserFragment
  isShowLink: boolean
  summary: CheckinSummaryFragment
  keyResults?: ReadonlyArray<KeyResultFragment>
  onSaveKeyResultSharedItem: (
    checkinKeyResultId: string,
    itemType: KeyResultSharedItemType,
    comment: DocumentInput,
  ) => void
  onClickSave: (treeJson: string, plainText: string) => void
  drawer?: boolean
}

export const OkrPageCheckinMeetingCard: FC<Props> = ({
  termId,
  user,
  isShowLink,
  summary,
  keyResults,
  onSaveKeyResultSharedItem,
  onClickSave,
  drawer = false,
}) => {
  const { t } = useTranslation()

  const allCheckinUsers = summary.checkinSummaryKeyResults.reduce(
    (acc: Array<CheckinUserFragment>, cur) =>
      acc.concat(
        cur.checkinKeyResults.reduce(
          (pre: Array<CheckinUserFragment>, ck) => pre.concat(ck.user),
          [],
        ),
      ),
    [],
  )
  const checkinUsers = Array.from(new Map(allCheckinUsers.map((u) => [u.userId, u])).values())

  const currentKeyResult = (cskr: CheckinSummaryKeyResultFragment) => {
    if (keyResults === undefined) return {}
    const kr = keyResults.find((v: KeyResultFragment) => v.id === cskr.keyResult.id)
    if (kr) {
      return {
        name: kr.name,
        progressRate: kr.progressRate || cskr.progressRate,
        targetSetting: kr.targetSetting || cskr.targetSetting,
      }
    }
    return {}
  }

  const [CheckinModal, isOpenCheckinModal, { open, close }] = useCheckinModal()
  const openCheckinModal = useCallback(() => {
    tracker.UserClickOpenCheckin(Screen.Okr)
    open()
  }, [open])
  const closeCheckinModal = useCallback(() => close(), [close])
  const executeCheckin = useCallback(() => {
    tracker.UserClickCompletedCheckin(Screen.Okr)
    close()
  }, [close])
  const backCheckinModal = useCallback(() => {
    tracker.UserClickBackCheckin(Screen.Okr)
  }, [])

  const { data: okrTermsData } = useOkrTermsQuery()
  const [CheckinButton] = useCheckinButton({
    keyResults,
    okrTerms: okrTermsData?.okrTerms,
  })

  return (
    <Card css={{ paddingBottom: '0', border: drawer ? '0' : undefined }}>
      <Header
        link={
          isShowLink
            ? `${generateUser(user.id)}?${makeCheckinSearchParams(true, drawer)}`
            : undefined
        }
        isCheckined={summary.status !== CheckinSummaryStatus.Previous}
        checkinUsers={checkinUsers}
        drawer={drawer}
      />
      <div css={{ margin: '52px 0 54px' }}>
        <SwitchingEditor summary={summary} onClickSave={onClickSave} drawer={drawer} />
      </div>
      {summary.checkinSummaryKeyResults.length > 0 && (
        <div css={{ marginTop: '4px' }}>
          <div
            css={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
              borderBottom: `1px solid ${color('border-bk-10')}`,
              marginBottom: '24px',
              paddingBottom: '12px',
            }}
          >
            <StyledText size="xlarge" lineHeight="32px">
              {t('CHECKIN_OF_THE_WEEK')}
            </StyledText>
            <CheckinButton onClick={openCheckinModal} />
          </div>
          {summary.checkinSummaryKeyResults
            .concat()
            .sort((a, b) => a.displayOrder - b.displayOrder)
            .map((cskr) => (
              <div key={cskr.id} css={{ marginBottom: '28px' }}>
                <CheckinComment
                  termId={termId}
                  checkinSummaryKeyResult={{ ...cskr, ...currentKeyResult(cskr) }}
                  onClickSave={onSaveKeyResultSharedItem}
                  drawer={drawer}
                />
              </div>
            ))}
        </div>
      )}
      {isOpenCheckinModal && (
        <CheckinModal
          isOpen={isOpenCheckinModal}
          onClose={closeCheckinModal}
          onConfirm={executeCheckin}
          onBack={backCheckinModal}
          okrNodeId={summary.node.id}
        />
      )}
    </Card>
  )
}

OkrPageCheckinMeetingCard.displayName = 'OkrPageCheckinMeetingCard'

type PopoverProps = {
  children: ReactNode
  popoverContent: ReactNode
  isShow: boolean
}

const Popover: VFC<PopoverProps> = ({ children, popoverContent, isShow }) => (
  <div
    css={{
      display: 'inline',
      '.popover': {
        visibility: isShow ? 'visible' : 'hidden',
      },
    }}
  >
    {children}
    {isShow && (
      <div css={{ position: 'relative', zIndex: 2 }}>
        <div
          className="popover"
          css={{
            minWidth: '100%',
            maxWidth: '300px',
            backgroundColor: color('white-100'),
            border: border('simple-30'),
            borderRadius: '4px',
            boxShadow: '0px 8px 16px rgb(34 41 67 / 8%)',
            top: '4px',
            right: '-20px',
            position: 'absolute',
            visibility: 'hidden',
            padding: '10px 0',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
        >
          {popoverContent}
        </div>
      </div>
    )}
  </div>
)

Popover.displayName = 'Popover'

type PopoverContentProps = {
  keyResult: ReadonlyArray<CheckinSummaryKeyResultFragment>
  openCommentModal: (cskr: CheckinSummaryKeyResultFragment) => void
}

const PopoverContent: VFC<PopoverContentProps> = ({ keyResult, openCommentModal }) => {
  const { t } = useTranslation()
  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        whiteSpace: 'nowrap',
      }}
    >
      <StyledText
        weight="bold"
        css={{
          padding: '0 16px 10px',
          borderBottom: `1px solid ${color('border-bk-10')}`,
        }}
      >
        {t('SELECT_OF_X', { x: t('KEY_RESULT') })}
      </StyledText>
      {keyResult
        .concat()
        .sort((a, b) => a.displayOrder - b.displayOrder)
        .map((cskr) => (
          <Button
            key={cskr.id}
            onClick={() => {
              openCommentModal(cskr)
            }}
            css={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              padding: '10px 16px',
              ':hover': {
                cursor: 'pointer',
                backgroundColor: color('hover-background-bk-5'),
              },
            }}
          >
            <Text
              css={{
                fontSize: '14px',
                lineHeight: '20px',
                paddingLeft: '8px',
                borderLeft: `4px solid ${color('kr-green-100')}`,
              }}
            >
              {cskr.keyResult.name}
            </Text>
          </Button>
        ))}
    </div>
  )
}

PopoverContent.displayName = 'PopoverContent'
