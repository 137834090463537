import { css } from '@emotion/react'

import { ordinal } from '../../../lib/ordinal'
import { fontSize } from '../../../styles/font'
import { color } from '../../../styles/newColors'

const rootCss = css({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: 18,
  padding: '4px 16px 0',
  gap: 4,
})

const ordinalCss = css({
  ...fontSize('xsmall', 'regular'),
  color: color('text-bk-30'),
})

const horizontalRuleCss = css({
  width: '100%',
  height: 1,
  border: 'none',
})

export type Props = { depth: number }

export const Layer: React.VFC<Props> = ({ depth }) => (
  <div css={rootCss}>
    <span css={ordinalCss}>{ordinal(depth)}</span>
    <hr css={horizontalRuleCss} color={color('border-bk-10')} />
  </div>
)
