import { Box } from 'grommet'

import { StyledText } from '../../../../../components/ui/StyledText'
import { useTranslation } from '../../../../../i18n'
import { ActivatedUserTable } from '../../UserTable/ActivatedUserTable'
import { ActivatedUserTableProps } from '../../UserTable/ActivatedUserTable/types'
import { emptyCss } from '../styles'

import { ActivatedUserTabProps } from './types'

export const ActivatedUserTab: React.FC<ActivatedUserTabProps> = ({ users, onRowClick }) => {
  const { t } = useTranslation()
  return (
    <Box>
      <ActivatedUserTable
        users={users}
        onClickRow={onRowClick as ActivatedUserTableProps['onClickRow']}
      />
      {users.length < 1 && <StyledText css={emptyCss}>{t('TARGET_USER_IS_EMPTY')}</StyledText>}
    </Box>
  )
}
