import { SerializedStyles } from '@emotion/react'
import { ReactElement, useCallback } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

import { Tabs, Props as TabsProps, TabType as TabsTabType } from '../Tabs'

export type TabType<T extends string = string> = TabsTabType<T> & {
  path: string
}

export type Props<T extends string = string> = Omit<TabsProps<T>, 'tabs' | 'onClickTab'> & {
  tabs: ReadonlyArray<TabType<T>>
  onClickTab?: TabsProps<T>['onClickTab']
  tabCss?: SerializedStyles
  contentCss?: SerializedStyles
}

export const TabsWithReactRouter = <T extends string = string>({
  tabs,
  tab,
  onClickTab,
  tabCss,
  contentCss,
  ...props
}: Props<T>): ReactElement => {
  const navigate = useNavigate()
  const onClickTabWithChangeLocation = useCallback<TabsProps<T>['onClickTab']>(
    (t) => {
      if (onClickTab) {
        onClickTab(t)
      }
      navigate((t as TabType<T>).path)
    },
    [navigate, onClickTab],
  )

  return (
    <div {...props}>
      <Tabs<T> css={tabCss} tabs={tabs} tab={tab} onClickTab={onClickTabWithChangeLocation} />
      <div css={contentCss}>
        <Outlet />
      </div>
    </div>
  )
}
