import { css } from '@emotion/react'

import { StyledText } from '../../../../../components/ui/StyledText'
import { useTranslation } from '../../../../../i18n'
import { color } from '../../../../../styles/newColors'

import { SortButton } from './sortButton'
import { Sort } from './types'

export type Props = {
  sort: Sort
  onClickSortBy: (sortBy: Sort['sortBy']) => void
}
const cssTableCellHeader = css({
  display: 'flex',
  alignItems: 'center',
  paddingBottom: '12px',
  borderBottom: `1px solid ${color('border-bk-10')}`,
  backgroundColor: color('white-100'),
  justifyContent: 'start',
})

export const ActionPlanTableHead: React.VFC<Props> = ({ sort, onClickSortBy }) => {
  const { t } = useTranslation()

  return (
    <div
      css={{
        display: 'flex',
      }}
    >
      <div
        css={[
          cssTableCellHeader,
          {
            flex: 'auto',
            paddingLeft: '12px',
          },
        ]}
      >
        <SortButton sortBy="title" sort={sort} onClick={onClickSortBy}>
          <StyledText size="small" fontStyle="bold" lineHeight="1">
            {t('TITLE')}
          </StyledText>
        </SortButton>
      </div>
      <div
        css={[
          cssTableCellHeader,
          {
            flex: '0 0 354px',
          },
        ]}
      >
        <SortButton sortBy="keyResult" sort={sort} onClick={onClickSortBy}>
          <StyledText size="small" fontStyle="bold">
            {t('KEY_RESULT')}
          </StyledText>
        </SortButton>
      </div>
      <div
        css={[
          cssTableCellHeader,
          {
            flex: '0 0 123px',
          },
        ]}
      >
        <SortButton sortBy="status" sort={sort} onClick={onClickSortBy}>
          <StyledText size="small" fontStyle="bold">
            {t('STATUS')}
          </StyledText>
        </SortButton>
      </div>
      <div
        css={[
          cssTableCellHeader,
          {
            flex: '0 0 87px',
          },
        ]}
      >
        <SortButton sortBy="dueDate" sort={sort} onClick={onClickSortBy}>
          <StyledText size="small" fontStyle="bold">
            {t('DUE_DATE')}
          </StyledText>
        </SortButton>
      </div>
      <div
        css={[
          cssTableCellHeader,
          {
            flex: '0 0 108px',
          },
        ]}
      >
        <SortButton sortBy="updatedAt" sort={sort} onClick={onClickSortBy}>
          <StyledText size="small" fontStyle="bold">
            {t('UPDATED_AT')}
          </StyledText>
        </SortButton>
      </div>
    </div>
  )
}
