import { Group } from '../../graphql'
import { toggle } from '../array'

// 変換前のGraphQLに結合したtype
type GroupNodeFragment = Pick<Group, 'id' | 'name' | 'childGroupIds' | 'depth'>

// 変換後のTree Model化したtype
export type GroupTree<T extends GroupNodeFragment> = T & {
  parent?: GroupTree<T>
  children: Array<GroupTree<T>>
}

// Tに合致するグループ構造を木構造に変換します
export const groupsToTreeModel = <T extends GroupNodeFragment>(
  groups: ReadonlyArray<T>,
): Array<GroupTree<T>> => {
  const trees = new Array<GroupTree<T>>()

  const groupMap = new Map<string, T>()
  groups.forEach((g) => groupMap.set(g.id, g))
  groups.forEach((g) => {
    if (g.depth === 1) {
      trees.push(buildTree(g, groupMap))
    }
  })

  return trees
}

// 再帰的に子のIDを参照しツリー構造を構築します
const buildTree = <T extends GroupNodeFragment>(
  group: T,
  groups: ReadonlyMap<string, T>,
  parent?: GroupTree<T>,
): GroupTree<T> => {
  const ret: GroupTree<T> = {
    ...group,
    parent,
    children: [],
  }

  if (ret.childGroupIds.length > 0) {
    ret.childGroupIds.forEach((e) => {
      const child = groups.get(e)
      if (child) {
        ret.children.push(buildTree(child, groups, ret))
      }
    })
  }
  return ret
}

export const walk = <T extends GroupNodeFragment>(
  nodes: ReadonlyArray<GroupTree<T>>,
  callback: (n: GroupTree<T>) => void,
): ReadonlyArray<GroupTree<T>> => {
  nodes.forEach((node) => {
    callback(node)
    walk(node.children, callback)
  })
  return nodes
}

export const find = <T extends GroupNodeFragment>(
  nodes: ReadonlyArray<GroupTree<T>>,
  callback: (n: GroupTree<T>) => boolean,
): GroupTree<T> | undefined => {
  let result: GroupTree<T> | undefined
  walk(nodes, (node) => {
    if (!result && callback(node)) result = node
  })
  return result
}

export const getAncestors = <T extends GroupNodeFragment>(
  node: GroupTree<T>,
): Array<GroupTree<T>> => (node.parent ? getAncestors(node.parent).concat([node.parent]) : [])

export const getDescendants = <T extends GroupNodeFragment>(
  node: GroupTree<T>,
): Array<GroupTree<T>> => node.children.concat(node.children.flatMap(getDescendants))

// 下位グループ選択時に上位グループの選択を解除＆
// 上位グループ選択時に下位グループの選択を解除
export const toggleSelectedAndNormalize = <T extends GroupNodeFragment>(
  groups: ReadonlyArray<T>,
  selectedIds: ReadonlyArray<string>,
  toggleId: string,
): Array<string> => {
  let result = toggle(selectedIds, toggleId)
  if (!result.includes(toggleId)) {
    // 選択解除時
    return result
  }
  // 選択追加時
  const addedGroupNode = find(groupsToTreeModel(groups), (g) => g.id === toggleId)
  if (!addedGroupNode) {
    return result
  }

  const isRoot = addedGroupNode.parent === undefined
  const isLeaf = addedGroupNode.children.length === 0
  if (!isRoot) {
    // 上位グループの選択を解除する
    getAncestors(addedGroupNode).forEach((ancestor) => {
      if (result.includes(ancestor.id)) {
        result = toggle(result, ancestor.id)
      }
    })
  }
  if (!isLeaf) {
    // 下位グループの選択を解除する
    getDescendants(addedGroupNode).forEach((descendant) => {
      if (result.includes(descendant.id)) {
        result = toggle(result, descendant.id)
      }
    })
  }

  return result
}

// ids + その子孫のIdを返す
export const addDescendantIds = <T extends GroupNodeFragment>(
  groups: ReadonlyArray<T>,
  ids: ReadonlyArray<string>,
): Array<string> => {
  const treeizedGroups = groupsToTreeModel(groups)
  const idsAndDescendantIds = ids
    .map((gId) => find(treeizedGroups, (gnode) => gnode.id === gId))
    .flatMap((g) => (g ? [g.id, ...getDescendants(g).map((des) => des.id)] : []))
  return [...new Set(idsAndDescendantIds)] // remove dup id
}
