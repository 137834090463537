export type LayerAlias = 'drawer' | 'modal'

export type LayerObj = { zIndex: string; container: { zIndex: string } }

export const LayerAliasObj: { [key in LayerAlias]: LayerObj } = {
  drawer: {
    zIndex: '10',
    container: {
      zIndex: '11',
    },
  },
  modal: {
    zIndex: '12',
    container: {
      zIndex: '13',
    },
  },
}

export const layer = (alias: keyof typeof LayerAliasObj): LayerObj => LayerAliasObj[alias]
