import { Box } from 'grommet'
import React, { memo } from 'react'
import isEqual from 'react-fast-compare'

import { isPreFetchContextKey, skipNotifyCallbackContextKey } from '../../lib/apollo'
import { moveItemIndex } from '../../lib/array'

import { OnSubmitFn as onSubmitMeetingNotesFn } from './MeetingNotes'
import { OneOnOneAgendaMemo } from './OneOnOneAgendaMemo'
import { OneOnOneMemo } from './OneOnOneMemo'
import { OnSubmitFn as onSubmitPrivateNotesFn } from './PrivateNotes'
import { Props as RecommendAgendaProps } from './RecommendAgenda'
import {
  PrivateNoteFragment,
  useCreateUserAgendaMutation,
  useDeleteUserAgendaMutation,
  AgendasDocument,
  UserAgendasFragment,
  AgendasQuery,
  AgendasQueryVariables,
  useUpdateOneOnOneMeetingNoteMutation,
  useUpdateOneOnOneMeetingPrivateNoteMutation,
  useUpdateUserAgendaIndexMutation,
  useUpdateUserAgendaMutation,
  useAgendasQuery,
  useUpdateOneOnOneMeetingFixedAgendaNoteMutation,
  FixedAgendaStatus,
} from './graphql'

type Props = {
  oneOnOneMeetingId: string
  privateNote: PrivateNoteFragment
} & Pick<
  RecommendAgendaProps,
  'currentUser' | 'partnerUser' | 'currentMeetingAt' | 'previousMeetingAt' | 'termId'
>

export const OneOnOneSection: React.FC<Props> = memo(
  ({
    oneOnOneMeetingId,
    currentMeetingAt,
    previousMeetingAt,
    currentUser,
    partnerUser,
    privateNote,
    termId,
  }) => {
    const { data } = useAgendasQuery({
      variables: { meetingId: oneOnOneMeetingId },
      pollInterval: 300000, // 5 minutes
      // NOTE: 議事録に古い情報が表示されてしまうためキャッシュを無効にする
      fetchPolicy: 'network-only',
    })

    const [createUserAgendaMutation] = useCreateUserAgendaMutation()
    const [updateUserAgendaMutation] = useUpdateUserAgendaMutation()
    const [updateUserAgendaIndexMutation] = useUpdateUserAgendaIndexMutation()
    const [deleteUserOneOnOneMutation] = useDeleteUserAgendaMutation({
      onError: (err) => {
        throw err
      },
    })

    const [updateOneOnOneMeetingNoteMutation] = useUpdateOneOnOneMeetingNoteMutation()
    const [updateOneOnOneMeetingFixedAgendaNoteMutation] =
      useUpdateOneOnOneMeetingFixedAgendaNoteMutation()

    const [updateOneOnOneMeetingPrivateNoteMutation] = useUpdateOneOnOneMeetingPrivateNoteMutation()

    const createUserAgenda = async (title: string) => {
      await createUserAgendaMutation({
        variables: {
          input: {
            title,
          },
          userOneOnOneMeetingId: oneOnOneMeetingId,
        },
        update: (cache, { data: createUserAgendaRes }) => {
          const option = {
            query: AgendasDocument,
            variables: { meetingId: oneOnOneMeetingId },
          }

          const { userAgendas } = cache.readQuery<AgendasQuery, AgendasQueryVariables>(option) || {}

          if (!userAgendas || !createUserAgendaRes || !data?.userAgendas) {
            return
          }

          cache.writeQuery<AgendasQuery, AgendasQueryVariables>({
            ...option,
            data: {
              fixedAgendas: data.fixedAgendas,
              userAgendas: [createUserAgendaRes.createUserAgenda, ...userAgendas].sort(
                (a, b) => (a ? a.index : 0) - (b ? b.index : 0),
              ),
            },
          })
        },
      })
    }

    const updateUserAgenda = async (id: string, title: string) => {
      await updateUserAgendaMutation({
        variables: {
          id,
          input: {
            title,
          },
        },
        update: (cache, { data: updateUserAgendaRes }) => {
          const option = {
            query: AgendasDocument,
            variables: { meetingId: oneOnOneMeetingId },
          }

          const { userAgendas } = cache.readQuery<AgendasQuery, AgendasQueryVariables>(option) || {}

          if (!userAgendas || !updateUserAgendaRes || !data?.userAgendas) {
            return
          }

          const updatedUserAgendas = userAgendas.map((userAgenda) =>
            userAgenda && userAgenda.id === updateUserAgendaRes.updateUserAgenda.id
              ? { ...userAgenda, ...updateUserAgendaRes.updateUserAgenda }
              : userAgenda,
          )

          cache.writeQuery<AgendasQuery, AgendasQueryVariables>({
            ...option,
            data: {
              fixedAgendas: data.fixedAgendas,
              userAgendas: updatedUserAgendas,
            },
          })
        },
      })
    }

    const updateUserAgendaIndex = async (id: string, index: number) => {
      updateUserAgendaIndexMutation({
        variables: {
          id,
          input: {
            index,
          },
        },
        optimisticResponse: ({ id: updateAgendaId, input }) => {
          if (data?.userAgendas == null) {
            return {
              updateUserAgendaIndex: [],
            }
          }

          // アジェンダの配列内のnullを除外します
          const userAgendas = data.userAgendas.flatMap((v) => (v === null ? [] : [v]))
          const prevAgendaIndex = data.userAgendas.findIndex(
            (userAgenda) => userAgenda?.id === updateAgendaId,
          )
          if (prevAgendaIndex === -1) {
            return { updateUserAgendaIndex: [] }
          }

          const newUserAgendas = moveItemIndex<UserAgendasFragment>(
            userAgendas,
            prevAgendaIndex,
            input.index - 1,
          )

          return {
            updateUserAgendaIndex:
              newUserAgendas?.map((value, arrIndex) => ({
                ...value,
                index: arrIndex + 1,
              })) ?? [],
          }
        },
        update: (cache, result) => {
          if (!result.data || data?.userAgendas == null) {
            return
          }

          cache.writeQuery<AgendasQuery, AgendasQueryVariables>({
            query: AgendasDocument,
            variables: { meetingId: oneOnOneMeetingId },
            data: {
              fixedAgendas: data.fixedAgendas,
              userAgendas: userAgendas
                .map((value) => {
                  const updatedIndex = result.data?.updateUserAgendaIndex.find(
                    (updateUserAgendaIndexRes) => updateUserAgendaIndexRes.id === value?.id,
                  )?.index

                  if (updatedIndex !== undefined) {
                    return { ...value, index: updatedIndex }
                  }
                  return value
                })
                .sort((a, b) => a.index - b.index),
            },
          })
        },
      })
    }

    const deleteUserAgenda = async (id: string) => {
      await deleteUserOneOnOneMutation({
        variables: { id },
        update(cache, { data: deleteOneOnOneRes }) {
          const option = {
            query: AgendasDocument,
            variables: { meetingId: oneOnOneMeetingId },
          }
          const { userAgendas } = cache.readQuery<AgendasQuery, AgendasQueryVariables>(option) || {}

          if (!userAgendas || !deleteOneOnOneRes || !data?.userAgendas) {
            return
          }

          cache.writeQuery<AgendasQuery, AgendasQueryVariables>({
            ...option,
            data: {
              fixedAgendas: data.fixedAgendas,
              userAgendas: userAgendas.filter(
                (userAgenda) => userAgenda?.id !== deleteOneOnOneRes.deleteUserAgenda,
              ),
            },
          })
        },
      })
    }

    const handleSubmitMeetingNotes: onSubmitMeetingNotesFn = async (formVal) => {
      if (formVal.isFixedAgenda) {
        await updateOneOnOneMeetingFixedAgendaNoteMutation({
          variables: {
            meetingId: formVal.meetingId,
            fixedAgendaId: formVal.agendaId,
            input: {
              meetingNote: formVal.body.treeJson,
              meetingNoteText: formVal.body.plainText || '',
            },
          },
          context: {
            [isPreFetchContextKey]: true,
            [skipNotifyCallbackContextKey]: true,
          },
        })
        return
      }

      await updateOneOnOneMeetingNoteMutation({
        variables: {
          userAgendaId: formVal.agendaId,
          input: {
            meetingNote: formVal.body.treeJson,
            meetingNoteText: formVal.body.plainText || '',
          },
        },
        context: {
          [isPreFetchContextKey]: true,
          [skipNotifyCallbackContextKey]: true,
        },
      })
    }

    const handleSubmitPrivateNotes: onSubmitPrivateNotesFn = async (formVal) => {
      await updateOneOnOneMeetingPrivateNoteMutation({
        variables: {
          id: privateNote.id,
          input: {
            meetingNote: formVal.body.treeJson || '',
            meetingNoteText: formVal.body.plainText || '',
          },
        },
        context: {
          [skipNotifyCallbackContextKey]: true,
        },
      })
    }

    // アジェンダの配列内のnullを除外します
    const userAgendas = data?.userAgendas.flatMap((v) => (v === null ? [] : [v])) || []
    const fixedAgendas =
      data?.fixedAgendas
        .flatMap((v) => (v === null ? [] : [v]))
        .filter((fixedAgenda) => fixedAgenda.status === FixedAgendaStatus.Public) || []

    return (
      <Box
        direction="column"
        css={{
          height: `100%`,
          overflowY: 'scroll',
          scrollBehavior: 'smooth',
        }}
      >
        <OneOnOneAgendaMemo
          currentMeetingAt={currentMeetingAt}
          previousMeetingAt={previousMeetingAt}
          currentUser={currentUser}
          partnerUser={partnerUser}
          termId={termId}
          userAgendasQuery={userAgendas}
          fixedAgendaQuery={fixedAgendas}
          onDeleteUserAgenda={deleteUserAgenda}
          onCreateUserAgenda={createUserAgenda}
          onUpdateUserAgenda={updateUserAgenda}
          onUpdateUserAgendaIndex={updateUserAgendaIndex}
        />
        <OneOnOneMemo
          oneOnOneMeetingId={oneOnOneMeetingId}
          userAgendas={userAgendas}
          fixedAgendas={fixedAgendas}
          privateNote={privateNote}
          onSubmitMeetingNotes={handleSubmitMeetingNotes}
          onSubmitPrivateNotes={handleSubmitPrivateNotes}
        />
      </Box>
    )
  },
  (prev, next) => {
    if (prev.oneOnOneMeetingId !== next.oneOnOneMeetingId) {
      return false
    }
    if (!isEqual(prev.currentMeetingAt, next.currentMeetingAt)) {
      return false
    }
    if (!isEqual(prev.previousMeetingAt, next.previousMeetingAt)) {
      return false
    }
    if (!isEqual(prev.currentUser, next.currentUser)) {
      return false
    }
    if (!isEqual(prev.partnerUser, next.partnerUser)) {
      return false
    }
    if (!isEqual(prev.privateNote, next.privateNote)) {
      return false
    }
    if (prev.termId !== next.termId) {
      return false
    }
    return true
  },
)

OneOnOneSection.displayName = 'OneOnOneSection'
