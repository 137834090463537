import { css } from '@emotion/react'
import { useModal } from '@resily/geisha'
import { useCallback, useMemo, useState } from 'react'

import { useCurrentUser } from '../../../contexts/UserContext'
import { UserWithOrganizationDocument, UserWithOrganizationQuery } from '../../../contexts/graphql'
import { useTranslation } from '../../../i18n'
import { useActivityRefetchQueries } from '../../../lib/domain/activity/hooks/useActivityRefetchQueries'
import { FindCheckinSummariesByOkrNodeIdDocument } from '../OkrModal/Main/CheckinArea/graphql'

import { CheckinModalConfirmation } from './components/CheckinModalConfirmation'
import { CheckinModalForm } from './components/CheckinModalForm'
import { CheckinModalSkeleton } from './components/CheckinModalSkeleton'
import { DiscardCheckinModal } from './components/DiscardCheckinModal'
import { EditedCountSkeleton } from './components/EditedCountSkeleton'
import { EditedCount } from './form/components/EditedCount'
import { FormProvider } from './form/contexts/FormProvider'
import { keyResultsKeyName } from './form/formSchema'
import { useForm } from './form/hooks/useForm'
import {
  CheckinKeyResultInput,
  KeyResultUpdateType,
  useCreateCheckinKeyResultsMutation,
} from './graphql/graphql'
import { isCommentChanged } from './utils'

export type Props = {
  isOpen: boolean
  onConfirm: () => void
  onClose: () => void
  onBack: () => void
  okrNodeId?: string
}

type ModalComponent = ReturnType<typeof useModal>[0]

const editedCountWrapperCss = css({ margin: 'auto 0' })

export const CheckinModal: React.VFC<Props & { modalComponent: ModalComponent }> = ({
  modalComponent: Modal,
  onConfirm,
  onBack,
  onClose,
  isOpen,
  okrNodeId,
}) => {
  const { t } = useTranslation()
  const currentUser = useCurrentUser()

  // checkinModal, discardCheckinModalどっちのopen, closeか分かるように敢えて冗長な命名にした。
  const [
    ,
    isOpenDiscardCheckinModal,
    { open: openDiscardCheckinModal, close: closeDiscardCheckinModal },
  ] = useModal()
  const {
    loading,
    formMethods,
    formMethods: {
      handleSubmit,
      formState: { isDirty, errors },
    },
  } = useForm(okrNodeId)
  const [isConfirm, setIsConfirm] = useState<boolean>(false)

  const [createCheckinKeyResultsMutation] = useCreateCheckinKeyResultsMutation({
    refetchQueries: [FindCheckinSummariesByOkrNodeIdDocument, ...useActivityRefetchQueries()],
    update: (cache, { data }) => {
      // checkinKeyResultsが作成されていなけれな、osBulkUpdatedを変更しない
      if (!data || data.createCheckinKeyResults.length === 0) return

      // キャッシュのisBulkUpdatedをtrueにするのキャッシュを更新する
      const options = {
        query: UserWithOrganizationDocument,
      }
      const userWithOrganization = cache.readQuery<UserWithOrganizationQuery>(options)
      if (!userWithOrganization || userWithOrganization.user.checkinKeyResultsState.isBulkUpdated)
        return
      const { user } = userWithOrganization

      cache.writeQuery({
        ...options,
        data: {
          user: {
            ...user,
            checkinKeyResultsState: {
              ...user.checkinKeyResultsState,
              isBulkUpdated: true,
            },
          },
        },
      })
    },
  })

  const executeCheckin = useCallback<Parameters<typeof handleSubmit>[0]>(
    async ({ keyResults }) => {
      const input = keyResults
        .filter(
          (kr) =>
            kr.actualValue !== kr.beforeActualValue ||
            kr.initialValue !== kr.beforeInitialValue ||
            kr.targetValue !== kr.beforeTargetValue ||
            (kr.beforeLevel === undefined ? kr.level !== 0 : kr.level !== kr.beforeLevel) ||
            kr.unit !== kr.beforeUnit ||
            !!kr.updateReason ||
            isCommentChanged(kr.lastPriority, kr.priority, kr.isCurrentPriorityComment) ||
            isCommentChanged(kr.lastWinSession, kr.winSession, kr.isCurrentWinSessionComment) ||
            isCommentChanged(kr.lastProblem, kr.problem, kr.isCurrentProblemComment) ||
            isCommentChanged(kr.lastOther, kr.other, kr.isCurrentOtherComment),
        )
        .map<CheckinKeyResultInput>((kr) => {
          const isManual = kr.updateType === KeyResultUpdateType.Manual
          return {
            actualValue: kr.actualValue,
            initialValue: isManual ? 0 : kr.initialValue,
            keyResultId: kr.keyResultId,
            level: kr.level,
            other: isCommentChanged(kr.lastOther, kr.other, kr.isCurrentOtherComment)
              ? kr.other
              : undefined,
            priority: isCommentChanged(kr.lastPriority, kr.priority, kr.isCurrentPriorityComment)
              ? kr.priority
              : undefined,
            problem: isCommentChanged(kr.lastProblem, kr.problem, kr.isCurrentProblemComment)
              ? kr.problem
              : undefined,
            targetValue: isManual ? 100 : kr.targetValue || 0,
            unit: isManual ? t('PERCENTAGE') : kr.unit,
            updateReason: kr.updateReason || undefined,
            winSession: isCommentChanged(
              kr.lastWinSession,
              kr.winSession,
              kr.isCurrentWinSessionComment,
            )
              ? kr.winSession
              : undefined,
          }
        })

      await createCheckinKeyResultsMutation({ variables: { input } })
    },
    [createCheckinKeyResultsMutation, t],
  )

  const handleOnClose = useCallback(() => {
    if (isDirty) {
      openDiscardCheckinModal()
    } else {
      onClose()
    }
  }, [isDirty, onClose, openDiscardCheckinModal])

  const handleOnClickConfirm = useCallback(() => {
    closeDiscardCheckinModal()
    onClose()
  }, [closeDiscardCheckinModal, onClose])

  const headerTitle = useMemo(() => {
    if (isConfirm) return t('CONFIRMATION_CHECKIN')
    if (currentUser?.checkinKeyResultsState.isBulkUpdated) return t('RECHECKIN')
    return t('CHECKIN')
  }, [isConfirm, t, currentUser])

  return (
    <>
      <Modal
        isOpen={isOpen}
        size="xlarge"
        contentMargin={false}
        onClose={handleOnClose}
        restrictFocus={false}
      >
        <Modal.Header title={headerTitle} />
        <Modal.Content>
          {loading && <CheckinModalSkeleton />}
          {!loading && (
            <FormProvider methods={formMethods}>
              {!isConfirm && <CheckinModalForm />}
              {isConfirm && <CheckinModalConfirmation />}
            </FormProvider>
          )}
        </Modal.Content>
        <Modal.Footer
          cancelType="tertiary"
          cancelLabel={isConfirm ? t('BACK') : ''}
          confirmLabel={isConfirm ? t('CHECKIN') : t('CONFIRMATION')}
          confirmDisabled={loading || !!errors[keyResultsKeyName]}
          onCancel={() => {
            setIsConfirm(false)
            onBack()
          }}
          onConfirm={async () => {
            // 入力画面 - 確認画面を表示する
            if (!isConfirm) {
              setIsConfirm(true)
              return
            }

            // 確認画面 - チェックインを実行
            await handleSubmit(executeCheckin)()
            onConfirm()
          }}
        >
          <div css={editedCountWrapperCss}>
            {loading ? (
              <EditedCountSkeleton />
            ) : (
              <FormProvider methods={formMethods}>
                <EditedCount />
              </FormProvider>
            )}
          </div>
        </Modal.Footer>
      </Modal>
      <DiscardCheckinModal
        isOpen={isOpenDiscardCheckinModal}
        onClickConfirm={handleOnClickConfirm}
        onClose={closeDiscardCheckinModal}
      />
    </>
  )
}

CheckinModal.displayName = 'CheckinModal'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export type UseCheckinModalReturn = ReturnType<typeof useModal> extends [infer _, ...infer Items]
  ? [React.VFC<Props>, ...Items]
  : undefined

export const useCheckinModal = (initialState?: boolean | undefined): UseCheckinModalReturn => {
  const [Modal, ...rest] = useModal(initialState)

  const checkinModalComponent = useCallback<React.VFC<Props>>(
    (props) => <CheckinModal {...props} modalComponent={Modal} />,
    [Modal],
  )

  return [checkinModalComponent, ...rest]
}
