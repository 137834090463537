import { css } from '@emotion/react'
import { useMemo } from 'react'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useStyles = () =>
  useMemo(
    () =>
      ({
        root: css({
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: 48,
        }),
        empty: css({
          lineHeight: '20px',
          marginBottom: '28px',
          whiteSpace: 'pre-wrap',
          textAlign: 'center',
        }),
        create: css({ margin: '0 auto' }),
      } as const),
    [],
  )
