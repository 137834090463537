import { useCallback, useState } from 'react'

export type TAB = 'integratedUser' | 'segregatedUser' | 'group'

export type UseSmartHRTabProps = {
  /** tabに変更があったときに発火される */
  onChangeTab: (tab: TAB) => void
}

export type UseSmartHRTabStateReturn = TAB

export type UseSmartHRTabActionReturn = (tab: TAB) => void

export const useSmartHRTab = ({
  onChangeTab,
}: UseSmartHRTabProps): [UseSmartHRTabStateReturn, UseSmartHRTabActionReturn] => {
  const [tab, originSetTab] = useState<TAB>('integratedUser')

  const setTab = useCallback<(tab: TAB) => void>(
    (newTab: TAB) => {
      originSetTab(newTab)
      if (tab !== newTab) {
        onChangeTab(newTab)
      }
    },
    [onChangeTab, tab],
  )

  return [tab, setTab]
}
