import { useNavigate } from 'react-router-dom'

import { KeyResultDetailOption } from '../../components/domain/KeyResultDetailOption'
import { KeyResultDetailPageCard } from '../../components/domain/KeyResultDetailPageCard'
import { PageContent } from '../../components/pageContent'
import { color } from '../../styles/newColors'
import { AuthRouteProps } from '../../types/authRouteProps'
import { generateKeyResultEdit, keyResult as keyResultPath } from '../../urls'

import { OkrDetailKeyResultFragment, OkrDetailOkrNodeFragment } from './graphql'
import { useInjection } from './hooks'

export const KeyResultDetailContainer: React.FC<AuthRouteProps> = (props) => {
  const data = useInjection(props)

  if (!data) {
    return null
  }

  const { breadcrumbs, onKeyResultEdit, keyResult } = data

  return (
    <PageContent
      breadcrumbs={breadcrumbs}
      layout={{
        css: {
          backgroundColor: color('background-bk-5'),
          padding: '60px 76px',
        },
      }}
    >
      <div
        css={{
          display: 'flex',
        }}
      >
        <div
          css={{
            flex: '4 1 0',
          }}
        >
          <KeyResultDetailPageCard onEdit={onKeyResultEdit} keyResult={keyResult} />
        </div>
        <div css={{ marginTop: '9px', marginRight: '77px', flex: '1 1 0' }}>
          <KeyResultDetailOption keyResult={keyResult} />
        </div>
      </div>
    </PageContent>
  )
}

KeyResultDetailContainer.displayName = 'KeyResultDetailContainer'

// TODO: これより↓のコードはstorybookでしか使われていないので消す

export type Props = {
  node: OkrDetailOkrNodeFragment
  keyResult: OkrDetailKeyResultFragment
}

export const KeyResultDetail: React.FC<Props> = ({ node, keyResult }) => {
  const navigate = useNavigate()

  return (
    <PageContent
      breadcrumbs={{
        url: keyResultPath,
        items: [
          {
            breadcrumbName: 'okr',
            ...node,
            objective: {
              id: keyResult.objective.id,
              name: keyResult.objective.name,
            },
          },
          {
            breadcrumbName: 'keyResult',
            ...keyResult,
            node: {
              id: node.id,
            },
          },
          {
            breadcrumbName: 'keyResultDetail',
            ...keyResult,
            node: {
              id: node.id,
            },
          },
        ],
      }}
      layout={{
        css: {
          backgroundColor: color('background-bk-5'),
          padding: '60px max(40px, calc((100% - 1045px)/2)) ',
          minWidth: '1125px',
        },
      }}
    >
      <div
        css={{
          display: 'flex',
        }}
      >
        <div
          css={{
            minWidth: '805px',
          }}
        >
          <KeyResultDetailPageCard
            onEdit={() => {
              navigate(generateKeyResultEdit(node.id, keyResult.id))
            }}
            keyResult={keyResult}
          />
        </div>
        <div css={{ marginTop: '9px', marginLeft: '8px', minWidth: '232px' }}>
          <KeyResultDetailOption keyResult={keyResult} />
        </div>
      </div>
    </PageContent>
  )
}

KeyResultDetail.displayName = 'KeyResultDetail'
