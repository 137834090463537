import { MutableRefObject, useCallback } from 'react'
import { useQueryParams } from 'use-query-params'

import { Screen } from '../../../../../../lib/screen'
import { tracker } from '../../../../../../lib/tracking'
import { ActivityResourceType } from '../graphql'
import { ActivityListEnumParam } from '../types'

export const useChangeRadio = ({
  activityType,
}: {
  activityType: MutableRefObject<ActivityResourceType>
}): {
  currentActivityType: ActivityResourceType
  onChangeRadio: (value: ActivityResourceType) => void
} => {
  const [queryParams, setQueryParams] = useQueryParams({
    activityList: ActivityListEnumParam,
  })

  const currentActivityType = queryParams.activityList ?? activityType.current

  const onChangeRadio = useCallback(
    (value: ActivityResourceType) => {
      tracker.UserClickRadioButtonInHome(Screen.HomeActivity, value)
      setQueryParams({ activityList: value })
      activityType.current = value
    },
    [setQueryParams, activityType],
  )

  return { currentActivityType, onChangeRadio }
}
