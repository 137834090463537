import { css } from '@emotion/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { VFC, useState, useCallback } from 'react'
import { useForm } from 'react-hook-form'

import { useTranslation } from '../../../i18n'
import { okrGlossarySchemas } from '../../../lib/domain/okrGlossary'
import { Button } from '../../ui/Button'
import { StyledText } from '../../ui/StyledText'

import { OkrGlossaryListCard } from './OkrGlossaryListCard'
import { CustomOkrGlossaryFragment, OkrGlossary } from './graphql'

export type OkrGlossaryUpdateArgs = {
  okrGlossary: OkrGlossary
  customTextJa: string
  customTextEn: string
}

type FormValue = {
  [key in OkrGlossary]: OkrGlossaryUpdateArgs
}

/**
 * 元のOKR用語と一致しているか
 */
const equalOkrGlossary = (
  target: OkrGlossaryUpdateArgs,
  origin: CustomOkrGlossaryFragment,
): boolean =>
  target.okrGlossary === origin.okrGlossary &&
  target.customTextJa === origin.customTextJa &&
  target.customTextEn === origin.customTextEn

/**
 * OKR用語と一致したらCustomOkrGlossaryFragmentを返却
 */
const findOkrGlossary = (
  target: OkrGlossaryUpdateArgs,
  array: ReadonlyArray<CustomOkrGlossaryFragment>,
): CustomOkrGlossaryFragment | undefined => array.find((g) => g.okrGlossary === target.okrGlossary)

const formHeaderCss = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})
const saveButtonCss = css({
  display: 'flex',
  gap: 12,
})
const descriptionCss = css({
  display: 'block',
  marginTop: 8,
})
const listCardCss = css({
  marginTop: 42,
  padding: '36px 0',
})

export type Props = {
  /** 更新中のローティングUI制御 */
  updateLoading: boolean
  customOkrGlossaries: ReadonlyArray<CustomOkrGlossaryFragment>
  /** 変更があったOkrGlossaryだけcbする */
  onUpdate: (values: Array<OkrGlossaryUpdateArgs>, onUpdated: () => void) => void
}

export const OkrGlossaryForm: VFC<Props> = ({ updateLoading, onUpdate, customOkrGlossaries }) => {
  const { t } = useTranslation()
  const [mode, setMode] = useState<'edit' | 'view'>('view')
  const isEdit = mode === 'edit'
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<FormValue>({
    mode: 'onChange',
    resolver: yupResolver(okrGlossarySchemas(t)),
  })

  const update = useCallback(
    (formValue: FormValue) => {
      // key: OkrGlossaryのFormValue型ObjectをOkrGlossaryUpdatreArgsの配列に変換する
      const values = Object.values(formValue)
      const changedValues = values.filter((v) => {
        const origin = findOkrGlossary(v, customOkrGlossaries)
        // 変更があったものだけ抽出
        return !(origin && equalOkrGlossary(v, origin))
      })
      // 何も変更するものがなければ何もしない
      if (changedValues.length === 0) {
        setMode('view')
        return
      }
      onUpdate(changedValues, () => {
        setMode('view')
      })
    },
    [customOkrGlossaries, onUpdate],
  )

  const edit = useCallback(() => {
    setMode('edit')
  }, [setMode])

  const cancel = useCallback(() => {
    reset()
    setMode('view')
  }, [setMode, reset])

  return (
    <form onSubmit={handleSubmit(update)}>
      <div css={formHeaderCss}>
        <StyledText size="xlarge" lineHeight="1" fontStyle="bold">
          {t('DEFAULT_TEXT_')}
        </StyledText>
        {isEdit ? (
          <div css={saveButtonCss}>
            <Button
              disabled={updateLoading}
              size="s"
              newColor="white-100"
              weight="normal"
              onClick={cancel}
            >
              {t('CANCEL')}
            </Button>
            <Button
              size="s"
              newColor="resily-orange-100"
              weight="normal"
              type="submit"
              disabled={!isValid}
              isLoading={updateLoading}
            >
              {t('SAVE')}
            </Button>
          </div>
        ) : (
          <Button size="s" newColor="resily-orange-100" weight="normal" onClick={edit}>
            {t('EDITION')}
          </Button>
        )}
      </div>
      <StyledText size="medium" lineHeight="1" css={descriptionCss}>
        {t('DEFAULT_TEXT_DESCRIPTION')}
      </StyledText>
      <OkrGlossaryListCard
        register={register}
        errors={errors}
        mode={mode}
        customOkrGlossaries={customOkrGlossaries}
        css={listCardCss}
      />
    </form>
  )
}

OkrGlossaryForm.displayName = 'OkrGlossaryForm'
