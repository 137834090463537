import { captureException } from '@sentry/react'
import {
  createPluginFactory,
  ELEMENT_PARAGRAPH,
  ELEMENT_BLOCKQUOTE,
  ELEMENT_CODE_BLOCK,
  ELEMENT_CODE_LINE,
  ELEMENT_IMAGE,
  ELEMENT_LINK,
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_H4,
  ELEMENT_H5,
  ELEMENT_H6,
  ELEMENT_HR,
  ELEMENT_OL,
  ELEMENT_UL,
  ELEMENT_LI,
  ELEMENT_LIC,
  ELEMENT_TODO_LI,
  isElement,
  TDescendant,
  TElement,
  Value,
} from '@udecode/plate'
import { Node, Text } from 'slate'

import { isProduction } from '../../../../../../config'
import {
  ParagraphElement as SlateParagraphElement,
  ParagraphType,
  BlockquoteType,
  CodeBlockElement as SlateCodeBlockElement,
  CodeBlockType,
  ImageType,
  LinkElement as SlateLinkElement,
  LinkType,
  AllowListItemChildrenElement as SlateAllowListItemChildrenElement,
  HeadingType,
  ListTypes,
  CheckboxType,
  HrType,
  ElementType as SlateElementType,
} from '../../SlateEditor/plugins/elements/types'
import { getSlateFragmentAttribute } from '../../SlateEditor/plugins/handlers/MonkeyPatch/plugin'
import { MarksNode } from '../../SlateEditor/plugins/marks/types'
import {
  BlockquoteElement as PlateBlockquoteElement,
  CodeBlockElement as PlateCodeBlockElement,
  CodeLineElement as PlateCodeLineElement,
  PlateCustomText,
  ElementType as PlateElementType,
  LinkElement as PlateLinkElement,
  ListItemChildElement as PlateListItemChildElement,
  ListItemElement as PlateListItemElement,
  ParagraphElement as PlateParagraphElement,
} from '../../types'

import { convertPlateFragmentFromListToIndentList } from './convertPlateFragmentFromListToIndentList'

const CONVERT_ELEMENT_MAP: Record<SlateElementType, PlateElementType> = {
  [ParagraphType.PARAGRAPH]: ELEMENT_PARAGRAPH,
  [BlockquoteType.BLOCKQUOTE]: ELEMENT_BLOCKQUOTE,
  [CheckboxType.CHECKBOX]: ELEMENT_TODO_LI,
  [CodeBlockType.CODE_BLOCK]: ELEMENT_CODE_BLOCK,
  [ImageType.IMG]: ELEMENT_IMAGE,
  [HrType.HR]: ELEMENT_HR,
  [LinkType.LINK]: ELEMENT_LINK,
  [HeadingType.H1]: ELEMENT_H1,
  [HeadingType.H2]: ELEMENT_H2,
  [HeadingType.H3]: ELEMENT_H3,
  [HeadingType.H4]: ELEMENT_H4,
  [HeadingType.H5]: ELEMENT_H5,
  [HeadingType.H6]: ELEMENT_H6,
  [ListTypes.OL]: ELEMENT_OL,
  [ListTypes.UL]: ELEMENT_UL,
  [ListTypes.LI]: ELEMENT_LI,
} as const

// FIXME: 本来の関数型は(Value => Value)だが、本番のデータに不正なものが混ざっているので、データ移行が終わるまでは下記の型を用いる
export const convertFragmentFromSlateToPlate = (fragment: Array<TDescendant>): Value => {
  try {
    return convertPlateFragmentFromListToIndentList(convertFragmentFromSlateToPlateImpl(fragment))
  } catch (e) {
    // 変換処理に失敗するとノート画面を開くのとコピペができなくなるので、失敗した際は握り潰してSentryに送信する
    if (!isProduction()) {
      // eslint-disable-next-line no-console
      console.error(e)
    }
    captureException(e)
    return fragment.concat() as Value
  }
}

// Plateのデータ構造に変換する
export const convertFragmentFromSlateToPlateImpl = (fragment: Array<TDescendant>): Value =>
  fragment.flatMap((v) => {
    if (Text.isText(v)) {
      const ret: PlateParagraphElement = {
        type: ELEMENT_PARAGRAPH,
        children: [v],
      }
      return [ret]
    }

    // HACK: 型エラー対処のworkaround
    // eslint-disable-next-line no-param-reassign
    v = v as TElement

    if (v.type === BlockquoteType.BLOCKQUOTE || v.type === ELEMENT_BLOCKQUOTE) {
      return (
        v.children as unknown as Array<SlateLinkElement | PlateLinkElement | PlateCustomText>
      ).reduce((acc, c) => {
        if (!Text.isText(c)) {
          if (c?.type === LinkType.LINK || c?.type === ELEMENT_LINK) {
            const link: PlateLinkElement = { ...c, type: ELEMENT_LINK }
            acc[acc.length - 1].children.push(link)
            return acc
          }

          // ここには本来来ないはずだが万が一来てしまった場合は暫定的に文字列化する
          // eslint-disable-next-line no-param-reassign
          c = { text: Node.string(c) }
        }

        // Plateでは引用を行毎に分割するので改行で別Elementに切り出す
        const { text, ...marks } = c
        const lines = text.split('\n')
        lines.forEach((line, i) => {
          if (i > 0) {
            acc.push({ type: ELEMENT_BLOCKQUOTE, children: [] })
          }
          acc[acc.length - 1].children.push({
            ...marks,
            text: line,
          })
        })

        return acc
      }, new Array<PlateBlockquoteElement>({ type: ELEMENT_BLOCKQUOTE, children: [] }))
    }

    // Plateではコードブロックの各行をELEMENT_CODE_LINEで包むので、改行で切り出す
    if (v.type === CodeBlockType.CODE_BLOCK || v.type === ELEMENT_CODE_BLOCK) {
      if (
        !(v.children as ReadonlyArray<PlateCodeLineElement | MarksNode>).every(
          (c): c is PlateCodeLineElement => c?.type === ELEMENT_CODE_LINE,
        )
      ) {
        return [
          {
            type: ELEMENT_CODE_BLOCK,
            children: (v as SlateCodeBlockElement | PlateCodeBlockElement).children.flatMap((c) => {
              if (c?.type === ELEMENT_CODE_LINE) {
                return [c]
              }

              if (!Text.isText(c)) {
                // NOTE: Unexpected node! this is fallback plan
                // eslint-disable-next-line @typescript-eslint/dot-notation
                if (c['type'] === ELEMENT_CODE_BLOCK) {
                  // eslint-disable-next-line @typescript-eslint/dot-notation
                  return convertFragmentFromSlateToPlateImpl(c['children'])
                }
                // eslint-disable-next-line no-param-reassign
                c = { text: Node.string(c) }
              }

              const { text, ...marks } = c
              const lines = text.split('\n')
              if (lines[lines.length - 1] === '') {
                lines.pop()
              }
              if (lines.length > 0 && lines[0] === '') {
                lines.shift()
              }

              return lines.map((tl) => ({
                type: ELEMENT_CODE_LINE,
                children: [{ ...marks, text: tl }],
              }))
            }),
          },
        ]
      }
    }

    if (v.type === ListTypes.LI || v.type === ELEMENT_LI) {
      if (
        !(v.children as ReadonlyArray<{ type: SlateElementType | PlateElementType }>).every(
          (c): c is PlateListItemElement => c?.type === ELEMENT_LIC,
        )
      ) {
        v.children = (
          v.children as Array<SlateAllowListItemChildrenElement | PlateListItemChildElement>
        ).map<PlateListItemChildElement>(
          (c) =>
            ({
              ...c,
              type:
                c.type === ParagraphType.PARAGRAPH ||
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                ((c.type === ELEMENT_PARAGRAPH || c.type === ELEMENT_LIC || !c.type) &&
                  (c as unknown as SlateParagraphElement).children?.every(Text.isText))
                  ? ELEMENT_LIC
                  : CONVERT_ELEMENT_MAP[c.type as SlateElementType],
            } as PlateListItemChildElement),
        )
      }
    }

    const ret: TElement = {
      ...v,
      type: CONVERT_ELEMENT_MAP[v.type as SlateElementType] ?? v.type,
      children:
        Array.isArray(v.children) && v.children.every(isElement)
          ? convertFragmentFromSlateToPlateImpl(v.children)
          : v.children,
    }

    return [ret]
  })

export const createConvertFragmentFromSlateToPlatePlugin = createPluginFactory({
  key: 'insert-data-and-insert-fragment-behavior',
  withOverrides: (editor) => {
    const { insertData, insertFragment } = editor

    // NOTE: 編集モードでコピペした際は真っ先に旧Slateからの変換処理を噛ませないとクラッシュする
    editor.insertData = (data) => {
      const fragment =
        data.getData('application/x-slate-fragment') || getSlateFragmentAttribute(data)
      if (fragment) {
        editor.insertFragment(
          convertFragmentFromSlateToPlate(JSON.parse(decodeURIComponent(window.atob(fragment)))),
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          true,
        )
        return
      }

      insertData(data)
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    editor.insertFragment = (fragment, xslateflag: boolean | undefined) => {
      if (xslateflag) {
        insertFragment(fragment)
      } else {
        insertFragment(convertFragmentFromSlateToPlate(fragment))
      }
    }

    return editor
  },
})
