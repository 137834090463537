import { css } from '@emotion/react'
import { Skeleton } from '@resily/geisha'
import { DropButton } from 'grommet'
import { useState, Fragment } from 'react'

import { ActionPlanStatusBadge } from '../../../../../components/domain/ActionPlanStatusBadge'
import { ActionPlanStatusSelect } from '../../../../../components/domain/ActionPlanStatusSelect'
import { Icon } from '../../../../../components/ui/Icon'
import { StyledText } from '../../../../../components/ui/StyledText'
import { NewTitle } from '../../../../../components/ui/Title'
import { TooltipNew } from '../../../../../components/ui/TooltipNew'
import { useTranslation } from '../../../../../i18n'
import { formatDateInput } from '../../../../../lib/date'
import { fontSize } from '../../../../../styles/font'
import { color } from '../../../../../styles/newColors'
import { generateActionPlan } from '../../../../../urls'

import { ActionPlanFieldFragment, ActionPlanStatus } from './graphql'

export type Props = {
  actionPlan: ActionPlanFieldFragment
  isOwnActionPlanTab: boolean
  onUpdateActionPlanStatus: (data: { actionPlanId: string; status: ActionPlanStatus }) => void
}

const cssTableEntire = css({
  display: 'flex',
  cursor: 'pointer',
  ':hover': {
    color: color('resily-orange-100'),
    backgroundColor: color('hover-background-bk-5'),
    '.select-status-icon': {
      display: 'block',
    },
  },
})

const cssTableCommonCellBody = css({
  display: 'flex',
  alignItems: 'center',
  padding: '11px 0',
  borderBottom: `1px solid ${color('border-bk-10')}`,
  justifyContent: 'start',
})

const cssTableTitleCellBody = css([
  cssTableCommonCellBody,
  {
    flex: 'auto',
    paddingLeft: '12px',
    paddingRight: '24px',
    alignItems: 'center',
    ':hover': {
      color: color('resily-orange-100'),
    },
  },
])

const cssTableKeyResultCellBody = css([
  cssTableCommonCellBody,
  { flex: '0 0 354px', paddingRight: '24px' },
])

const cssTableStatusCellBody = css([
  cssTableCommonCellBody,
  {
    flex: '0 0 123px',
    color: color('text-bk-50'),
    paddingBottom: 7,
  },
])

const cssTableDueDateCellBody = css([
  cssTableCommonCellBody,
  {
    flex: '0 0 87px',
    paddingRight: '24px',
    color: color('text-bk-100'),
  },
])

const cssTableUpdatedAtCellBody = css([
  cssTableCommonCellBody,
  {
    flex: '0 0 108px',
    paddingRight: '12px',
    color: color('text-bk-100'),
  },
])

const cssActionPlanTitle = css({
  fontSize: '14px',
  lineHeight: '22px',
  fontWeight: 'bold',
  wordBreak: 'break-all',
  // stylelint-disable-next-line
  display: '-webkit-box',
  overflow: 'hidden',
  // stylelint-disable-next-line
  WebkitLineClamp: 2,
  // stylelint-disable-next-line
  WebkitBoxOrient: 'vertical',
})

const cssKeyResultTitle = css({
  fontWeight: 'normal',
  color: color('text-bk-100'),
  ...fontSize('small'),
})

const cssStatusClickableArea = css({
  display: 'flex',
  backgroundColor: color('white-100'),
  padding: '4px 10px 4px 4px',
  borderRadius: '4px',
  marginBottom: 4,
  ':hover': {
    padding: '3px 9px 3px 3px',
    border: `1px solid ${color('border-bk-30')}`,
  },
})

const cssStatusDropDown = css({
  display: 'flex',
  ':hover': {
    path: {
      fill: color('resily-orange-100'),
    },
    ':focus': {
      boxShadow: 'none',
    },
  },
})

const cssSelectStatusIcon = css({
  display: 'none',
  width: '7px',
  height: '3px',
  margin: '4px',
})

export const ActionPlanTableRow: React.VFC<Props> = ({
  actionPlan,
  isOwnActionPlanTab,
  onUpdateActionPlanStatus,
}) => {
  const { t } = useTranslation()
  const [actionPlanStatusOpen, setActionPlanStatusOpen] = useState<string | null>(null)
  return (
    <li>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div
        onClick={() =>
          window.open(
            generateActionPlan(
              actionPlan.keyResult.node.id,
              actionPlan.keyResult.id,
              actionPlan.id,
            ),
            '_blank',
          )
        }
        css={{
          color: color('text-bk-100'),
          textDecoration: 'none',
        }}
      >
        <div css={cssTableEntire}>
          <div css={cssTableTitleCellBody}>
            <TooltipNew
              title={actionPlan.title}
              dropProps={{
                align: {
                  top: 'bottom',
                  left: 'left',
                },
                stretch: false,
                plain: true,
              }}
            >
              <span css={cssActionPlanTitle}>{actionPlan.title}</span>
            </TooltipNew>
          </div>
          <div css={cssTableKeyResultCellBody}>
            <TooltipNew
              title={actionPlan.keyResult.name}
              dropProps={{
                align: {
                  top: 'bottom',
                  left: 'left',
                },
                stretch: false,
                plain: true,
              }}
            >
              <h2 css={cssKeyResultTitle}>
                <NewTitle
                  color="kr-green-100"
                  title={actionPlan.keyResult.name}
                  targetSetting={actionPlan.keyResult.targetSetting}
                  readonly
                  isWrap
                  isPointer
                  sideBarHeight={20}
                  css={{ padding: 0 }}
                />
              </h2>
            </TooltipNew>
          </div>
          <div css={cssTableStatusCellBody}>
            {isOwnActionPlanTab ? (
              <DropButton
                open={actionPlanStatusOpen === actionPlan.id}
                dropAlign={{ top: 'bottom' }}
                onClose={(e) => {
                  e.stopPropagation()
                  setActionPlanStatusOpen(null)
                }}
                onOpen={(e) => {
                  e.stopPropagation()
                  setActionPlanStatusOpen(actionPlan.id)
                }}
                dropContent={
                  <Fragment>
                    <ActionPlanStatusSelect
                      isDisplayTitle={false}
                      onSelect={(status, e) => {
                        e.stopPropagation()
                        onUpdateActionPlanStatus({ actionPlanId: actionPlan.id, status })
                        setActionPlanStatusOpen(null)
                      }}
                    />
                  </Fragment>
                }
                css={cssStatusDropDown}
              >
                <TooltipNew
                  title={t('UPDATE_X', { x: t('STATUS') })}
                  dropProps={{
                    align: {
                      top: 'bottom',
                    },
                    stretch: false,
                    plain: true,
                  }}
                >
                  <div css={cssStatusClickableArea}>
                    <div css={{ display: 'flex' }}>
                      <ActionPlanStatusBadge status={actionPlan.status} />
                      <div
                        css={{
                          width: '12px',
                          paddingLeft: '4px',
                          paddingTop: '8px',
                        }}
                      >
                        <Icon
                          className="select-status-icon"
                          type="selectDown"
                          color={color('text-bk-30')}
                          css={cssSelectStatusIcon}
                        />
                      </div>
                    </div>
                  </div>
                </TooltipNew>
              </DropButton>
            ) : (
              <div css={{ padding: '4px 0' }}>
                <ActionPlanStatusBadge status={actionPlan.status} />
              </div>
            )}
          </div>
          <div css={cssTableDueDateCellBody}>
            <StyledText size="small">
              {actionPlan.dueDate && formatDateInput(actionPlan.dueDate)}
            </StyledText>
          </div>
          <div css={cssTableUpdatedAtCellBody}>
            <StyledText size="small">
              {formatDateInput(actionPlan.updatedAt, 'YYYY/MM/DD HH:mm')}
            </StyledText>
          </div>
        </div>
      </div>
    </li>
  )
}

export const ActionPlanTableRowSkeleton: React.FC = () => (
  <li>
    <div css={{ display: 'flex' }}>
      <div css={cssTableTitleCellBody}>
        <Skeleton text height={14} />
      </div>
      <div css={cssTableKeyResultCellBody}>
        <Skeleton text width={368} height={12} />
      </div>
      <div css={cssTableStatusCellBody}>
        <Skeleton text width={64} height={12} />
      </div>
      <div css={cssTableDueDateCellBody}>
        <Skeleton text width={64} height={12} />
      </div>
      <div css={cssTableUpdatedAtCellBody}>
        <Skeleton text width={96} height={12} />
      </div>
    </div>
  </li>
)
