import { useTranslation } from '../../../i18n'
import { ThreePointsWithMenu } from '../../ui/ThreePointsWithMenu'

export type NoteMenu = 'edit' | 'delete'

export type Props = {
  selectMenu: (menu: NoteMenu) => void
}

export const NoteThreePoints: React.FC<Props> = ({ selectMenu }) => {
  const { t } = useTranslation()

  return (
    <ThreePointsWithMenu
      popoverItems={[
        {
          label: t('EDIT_X', { x: t('NOTE') }),
          onItemClicked: () => selectMenu('edit'),
        },
        {
          label: t('DELETE_X', { x: t('NOTE') }),
          onItemClicked: () => selectMenu('delete'),
        },
      ]}
    />
  )
}

NoteThreePoints.displayName = 'NoteThreePoints'
