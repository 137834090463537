import { useModal } from '@resily/geisha'
import { useCallback, useMemo, useState } from 'react'

import { useEditingStateMachine } from '../../../../contexts/EditingStateContext'
import {
  KeyResultUpdateModalContainer,
  Props as KeyResultUpdateModalContainerProps,
} from '../KeyResultUpdateModalContainer'

export type Props = Pick<KeyResultUpdateModalContainerProps, 'onUpdateParentObjective' | 'onDelete'>

export type KeyResultUpdateModalReturnType = {
  KeyResultUpdateModal: React.ReactNode
  openKeyResultUpdateModal: (keyResultId: string) => void
}

export const useKeyResultUpdateModal = ({
  onUpdateParentObjective,
  onDelete,
}: Props): KeyResultUpdateModalReturnType => {
  const [Modal] = useModal(false)
  const [keyResultId, setKeyResultId] = useState<string>()

  const { requestStartEdit, updateIsDirty, discardEdit, finishEdit } = useEditingStateMachine()

  const openKeyResultUpdateModal = useCallback(
    (id: string) => {
      requestStartEdit(() => {
        setKeyResultId(id)
        return () => setKeyResultId(undefined)
      }, 'KEY_RESULT_UPDATE_MODAL')
    },
    [requestStartEdit],
  )
  const closeKeyResultUpdateModal = useCallback(() => {
    setKeyResultId(undefined)
    finishEdit()
  }, [finishEdit])

  const KeyResultUpdateModal = useMemo(
    () =>
      keyResultId ? (
        <KeyResultUpdateModalContainer
          modalComponent={Modal}
          isOpened
          close={closeKeyResultUpdateModal}
          keyResultId={keyResultId}
          onUpdateParentObjective={onUpdateParentObjective}
          onClose={discardEdit}
          onEdit={updateIsDirty}
          onDelete={onDelete}
        />
      ) : null,
    [
      Modal,
      closeKeyResultUpdateModal,
      discardEdit,
      keyResultId,
      onDelete,
      onUpdateParentObjective,
      updateIsDirty,
    ],
  )

  return { KeyResultUpdateModal, openKeyResultUpdateModal }
}
