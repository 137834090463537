import dayjs from 'dayjs'
import { Box } from 'grommet'
import React from 'react'

import { StyledText } from '../../components/ui/StyledText'

import { MeetingHistoryNotes } from './MeetingHistoryNotes'
import { dateFormat, timeFormat } from './constants'
import { resolveAgendas } from './functions'
import { useAgendasQuery } from './graphql'
import { historyCardCss } from './styles'

export type MeetingHistoryCardProps = {
  dateOfMeeting: Date
  oneOnOneMeetingId: string
  startTime: Date
  endTime: Date
}

export const MeetingHistoryCard: React.FC<MeetingHistoryCardProps> = ({
  dateOfMeeting,
  oneOnOneMeetingId,
  startTime,
  endTime,
}) => {
  const { data: agendasData } = useAgendasQuery({
    variables: { meetingId: oneOnOneMeetingId },
    fetchPolicy: 'network-only',
  })

  const { userAgendas, fixedAgendas } = resolveAgendas(agendasData)

  return (
    <div data-testid="meetingHistoryCard">
      <Box direction="column" background="white" gap="16px" css={historyCardCss}>
        <Box direction="row" background="white" gap="8px">
          <StyledText weight="bold" size="xlarge">
            {dayjs(dateOfMeeting).format(dateFormat)}
          </StyledText>
          <StyledText weight="normal" size="large">
            {`${dayjs(startTime).format(timeFormat)} ~ ${dayjs(endTime).format(timeFormat)}`}
          </StyledText>
        </Box>
        <MeetingHistoryNotes
          oneOnOneMeetingId={oneOnOneMeetingId}
          userAgendas={userAgendas}
          fixedAgendas={fixedAgendas}
        />
      </Box>
    </div>
  )
}
