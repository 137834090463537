import React, { createContext, useState, useEffect } from 'react'
import { useDebounce } from 'react-use'

import { RequestContextLink } from '../lib/apollo'

type ContextType = {
  loading: boolean
  success: boolean
  errors: ReadonlyArray<string>
}

export const ApolloContext = createContext<ContextType>({
  loading: false,
  success: false,
  errors: [],
})

export type Props = {
  middleware: RequestContextLink
}

export const ApolloContextProvider: React.FC<Props> = ({ middleware, children }) => {
  const [loading, setLoading] = useState(middleware.loading())
  const [success, setSuccess] = useState(false)
  const [errors, setErrors] = useState<ReadonlyArray<string>>([])

  // 初期化時に
  // 1. middleware のローディング状態を state にセットする
  // 2. middleware のコールバックを上書きする
  useEffect(() => {
    setLoading(middleware.loading())
    middleware.onLoadingChange = (l) => setTimeout(() => setLoading(l), 0)
    middleware.onSuccess = (s) => setTimeout(() => setSuccess(s), 0)
    middleware.onErrors = (errs) => setTimeout(() => setErrors((es) => es.concat(errs)), 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useDebounce(
    () => {
      if (success) {
        setSuccess(false)
      }
    },
    6000,
    [success],
  )
  useDebounce(
    () => {
      if (errors.length > 0) {
        setErrors([])
      }
    },
    6000,
    [errors],
  )

  return (
    <ApolloContext.Provider value={{ loading, success, errors }}>{children}</ApolloContext.Provider>
  )
}

ApolloContextProvider.displayName = 'ApolloContextProvider'
