import { css } from '@emotion/react'
import { useProfiler } from '@sentry/react'
import { VFC, useMemo, useRef } from 'react'

import { useTranslation } from '../../../i18n'
import { color } from '../../../styles/newColors'
import { StyledText } from '../../ui/StyledText'
import { Chart, Progress } from '../Chart'

import {
  ObjectiveFragment,
  KeyResultFragment,
  TermFragment,
  KeyResultProgressRateHistoryFragment,
} from './graphql'

const progressChartBox = css({
  backgroundColor: color('white-100'),
  width: '100%',
  padding: '28px 32px',
  border: '1px solid #D3D4D9',
  borderRadius: '4px',
})
const mainBox = css({ display: 'flex' })
const chartTitleCss = css({
  display: 'block',
  lineHeight: '33px',
  fontSize: '24px',
  fontWeight: 'bold',
  marginBottom: '32px',
})
const objectiveProgressRateCss = css({
  fontSize: '14px',
  lineHeight: '20px',
  padding: '8px 0px 8px 12px',
  borderLeft: `4px solid ${color('objective-blue-100')}`,
  marginTop: '8px',
  marginBottom: '26px',
  // stylelint-disable-next-line
  display: '-webkit-box',
  // stylelint-disable-next-line
  WebkitLineClamp: 3,
  // stylelint-disable-next-line
  WebkitBoxOrient: 'vertical',
  overflowY: 'hidden',
})
const nameCss = css({
  fontWeight: 'bold',
})
const progressRateCss = css({
  display: 'inline-block',
  marginTop: '4px',
})
const nameBoxCss = css({
  width: '100%',
  maxHeight: '400px',
  height: '100%',
  overflowY: 'auto',
})
const krNamesCss = css({
  marginBottom: '20px',
  fontSize: '14px',
  lineHeight: '20px',
  padding: '8px 0px 8px 12px',
  borderLeft: `4px solid ${color('kr-green-100')}`,
  // stylelint-disable-next-line
  display: '-webkit-box',
  // stylelint-disable-next-line
  WebkitLineClamp: 2,
  // stylelint-disable-next-line
  WebkitBoxOrient: 'vertical',
  overflowY: 'hidden',
})
const BASE_CHART_WIDTH = 700

export type Props = {
  term: TermFragment
  objective: ObjectiveFragment
  keyResults: ReadonlyArray<KeyResultFragment>
}

export const ProgressChart: VFC<Props> = ({ term, objective, keyResults }) => {
  useProfiler('ProgressChart')
  const { t } = useTranslation()
  const mainBoxRef = useRef<HTMLDivElement>(null)
  const keyResultProgresses: Array<Array<Progress>> = useMemo(
    () =>
      keyResults.map(
        ({ name, progressRateHistory }): Array<Progress> =>
          progressRateHistory.edges
            .map(({ node }) => node as KeyResultProgressRateHistoryFragment)
            .map(
              ({ createdAt, progressRate }) =>
                ({
                  name,
                  progressRate,
                  date: createdAt,
                } as Progress),
            ),
      ),
    [keyResults],
  )

  return (
    <div css={progressChartBox}>
      <StyledText css={chartTitleCss}>{t('OKR_PROGRESS_CHART')}</StyledText>

      <div ref={mainBoxRef} css={mainBox}>
        <Chart
          objective={objective}
          progressies={keyResultProgresses}
          termStartDate={term.startDate}
          width={
            mainBoxRef.current
              ? Math.max((mainBoxRef.current?.clientWidth || 0) * (3 / 5), BASE_CHART_WIDTH)
              : BASE_CHART_WIDTH
          }
          height={400}
        />
        <div css={nameBoxCss}>
          <StyledText css={objectiveProgressRateCss}>
            <span css={nameCss}>{objective.name}</span>
            <br />
            <span css={progressRateCss}>{`${t('PROGRESS_RATE')}: ${
              objective.progressRate || 0
            }%`}</span>
          </StyledText>
          {keyResults.concat().map((kr) => (
            <StyledText key={kr.id} css={krNamesCss}>
              <span css={nameCss}>{kr.name}</span>
              <br />
              <span css={progressRateCss}>{`${t('PROGRESS_RATE')}: ${kr.progressRate || 0}%`}</span>
            </StyledText>
          ))}
        </div>
      </div>
    </div>
  )
}

ProgressChart.displayName = 'ProgressChart'
