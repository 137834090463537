import { BoxProps } from 'grommet'
import React from 'react'

import { useTranslation } from '../../../i18n'

import { OkrEmptyState } from './OkrEmptyState'

export const FilteredOkrEmptyState: React.VFC<BoxProps> = ({ ...props }) => {
  const { t } = useTranslation()
  return (
    <OkrEmptyState
      title={t('FILTER_EMPTY_TITLE')}
      description={t('FILTER_EMPTY_DESCRIPTION')}
      {...props}
    />
  )
}

FilteredOkrEmptyState.displayName = 'FilteredOkrEmptyState'
