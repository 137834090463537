import { css } from '@emotion/react'
import { Box, Heading } from 'grommet'
import React, { useState } from 'react'

import { PageContent } from '../../components/pageContent'
import { Tabs, TabType } from '../../components/ui/Tabs'
import { useTranslation } from '../../i18n'
import { color } from '../../styles/newColors'
import { AuthRouteProps } from '../../types/authRouteProps'
import { security } from '../../urls'

import { ContentContainer } from './components/ContentContainer'
import { useInjection } from './hooks'

const tabCss = css({ paddingTop: 8 })
const tabContentCss = css({ paddingLeft: 32, paddingRight: 32 })
const tabAndContentsCss = css({
  padding: '24px 32px',
  background: color('white-100'),
  borderRadius: '8px',
})

export const SecuritySettingContainer: React.FC<AuthRouteProps> = (props) => {
  const { t } = useTranslation()
  const { isAdmin, currentTab, tabs } = useInjection(props)
  const [selectedTab, setSelectedTab] = useState<TabType<string>>(currentTab)

  if (!isAdmin) {
    return null
  }

  return (
    <div style={{ width: '100%' }}>
      <PageContent
        breadcrumbs={{
          url: security,
          items: [{ breadcrumbName: 'setting' }, { breadcrumbName: 'security' }],
        }}
        layout={{ css: { padding: '48px 32px' } }}
        contentBackgroundColor={color('background-bk-5')}
      >
        <Box gap="24px">
          <Heading level="3" css={{ fontSize: '20px' }}>
            {t('IP_RESTRICTION_AND_AUTH_SETTING')}
          </Heading>
          <div css={tabAndContentsCss}>
            <Tabs
              tab={selectedTab.value}
              tabs={tabs}
              css={tabCss}
              onClickTab={(newTab: TabType<string>) => setSelectedTab(newTab)}
            />
            <div css={tabContentCss}>
              <ContentContainer tab={selectedTab.value} />
            </div>
          </div>
        </Box>
      </PageContent>
    </div>
  )
}

SecuritySettingContainer.displayName = 'SecuritySettingContainer'
