import { Dispatch, SetStateAction, useCallback, useState, useMemo } from 'react'

import { Attachments } from '..'
import { useTranslation } from '../../../../i18n'
import { File } from '../../../standalone/OkrModal/graphql'
import { DeleteModal } from '../../../ui/DeleteModal'

export type Props = Omit<AttachmentsViewProps, 'files' | 'setFiles'>

type ReturnFC = React.VFC<Props>

type ReturnType = {
  AttachmentsView: ReturnFC
  getAttachments: () => ReadonlyArray<File>
  resetAttachments: () => void
  isDirtyAttachments: boolean
}

export const useAttachmentsView = (attachmentViews: ReadonlyArray<File>): ReturnType => {
  const [files, setFiles] = useState<ReadonlyArray<File>>(attachmentViews)
  const getAttachments = useCallback(() => files, [files])
  const resetAttachments = useCallback(() => setFiles(attachmentViews), [attachmentViews])

  const isDirtyAttachments = useMemo(() => {
    if (files.length !== attachmentViews.length) return true

    return files.some(
      (file, index) => JSON.stringify(file) !== JSON.stringify(attachmentViews[index]),
    )
  }, [files, attachmentViews])

  return {
    AttachmentsView: useCallback<ReturnFC>(
      ({ readOnly, withDeleteModal, onChange }) => (
        <AttachmentsView
          files={files}
          setFiles={setFiles}
          readOnly={readOnly}
          withDeleteModal={withDeleteModal}
          onChange={onChange}
        />
      ),
      [files],
    ),
    getAttachments,
    resetAttachments,
    isDirtyAttachments,
  }
}

type AttachmentsViewProps = {
  files: ReadonlyArray<File>
  setFiles: Dispatch<SetStateAction<ReadonlyArray<File>>>
  readOnly: boolean
  withDeleteModal?: boolean
  onChange?: (files: ReadonlyArray<File>) => void
}

const AttachmentsView = ({
  files,
  setFiles,
  readOnly,
  withDeleteModal = false,
  onChange,
}: AttachmentsViewProps): React.ReactElement => {
  const { t } = useTranslation()

  const addAttachment = useCallback(
    (file: File) => {
      const addedFiles = files.concat(file)
      setFiles(addedFiles)

      if (onChange) onChange(addedFiles)
    },
    [files, onChange, setFiles],
  )

  const [deleteTargetFile, setDeleteTargetFile] = useState<File | undefined>()

  const deleteAttachment = useCallback(
    (file: File) => {
      const filteredFiles = files.filter((a) => a !== file)
      setFiles(filteredFiles)
      if (onChange) {
        onChange(filteredFiles)
      }
    },
    [files, onChange, setFiles],
  )

  const handleDeleteAttachment = useCallback(
    (file: File) => {
      if (withDeleteModal) {
        setDeleteTargetFile(file)
        return
      }

      deleteAttachment(file)
    },
    [deleteAttachment, withDeleteModal],
  )
  const handleCancelDeleteModal = useCallback(() => setDeleteTargetFile(undefined), [])
  const handleSubmitDeleteModal = useCallback(() => {
    if (deleteTargetFile) {
      deleteAttachment(deleteTargetFile)
    }
    setDeleteTargetFile(undefined)
  }, [deleteAttachment, deleteTargetFile])

  return (
    <>
      <Attachments
        attachments={files}
        readOnly={readOnly}
        onClickDeleteAttachment={handleDeleteAttachment}
        onUpdateFile={addAttachment}
      />
      {withDeleteModal && (
        <DeleteModal
          withGeishaModal
          isOpened={deleteTargetFile != null}
          deleteItemName={t('ATTACHMENTS')}
          deleteItemNameForBody={deleteTargetFile?.name}
          onClickDelete={handleSubmitDeleteModal}
          onClickCancel={handleCancelDeleteModal}
          onClickOutside={handleCancelDeleteModal}
        />
      )}
    </>
  )
}
