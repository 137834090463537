import { useTranslation } from '../../../../../../../../i18n'
import { componentNames, featureNames, generateTestId } from '../../../../../../../../lib/testId'
import { AutoResizeTextArea } from '../../../../../../../ui/AutoResizeTextArea'

export type ReasonInputFormFieldProps = {
  message?: string
  onChangeMessage: (val?: string) => void
}

const rootCss = {
  padding: '8px 9px',
}

export const ReasonInputFormField: React.VFC<ReasonInputFormFieldProps> = ({
  message,
  onChangeMessage,
}) => {
  const { t } = useTranslation()

  return (
    <AutoResizeTextArea
      data-testid={generateTestId(featureNames.checkinModal, componentNames.updateMemoInput)}
      css={rootCss}
      placeholder={t('ENTER_THE_UPDATE_MEMO')}
      minHeight={41}
      onChange={(e) => onChangeMessage(e.target.value)}
      value={message}
    />
  )
}
ReasonInputFormField.displayName = 'ReasonInputFormField'
