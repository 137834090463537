import { useEffect, useCallback, RefObject } from 'react'

export const OPTION_CLASSNAME = 'Select__Option'
export const OPTION_FOCUS_CLASSNAME = 'Select__Option--Focus'

export const useKeyboardCursor = (
  buttonRef: RefObject<HTMLButtonElement>,
  selectContainerRef: RefObject<HTMLDivElement>,
): void => {
  const setFocus = useCallback((el: HTMLButtonElement) => {
    el.classList.add(OPTION_FOCUS_CLASSNAME)
    el.focus()
  }, [])

  const onKeyDownInOption = useCallback(
    (e: KeyboardEvent) => {
      if (
        !buttonRef.current ||
        !selectContainerRef.current ||
        (e.target !== buttonRef.current &&
          !(e.target as HTMLElement).classList.contains(OPTION_CLASSNAME)) ||
        (e.code !== 'ArrowDown' && e.code !== 'ArrowUp')
      ) {
        return
      }
      e.stopImmediatePropagation()

      if (e.target === buttonRef.current) {
        if (e.code === 'ArrowUp') return

        const nextButton = selectContainerRef.current.querySelectorAll<HTMLButtonElement>(
          `.${OPTION_CLASSNAME}`,
        )[0]
        setFocus(nextButton)
        return
      }

      const el = e.target as HTMLElement
      const next = e.code === 'ArrowDown' ? el.nextElementSibling : el.previousElementSibling
      if (!next || !next.classList.contains(OPTION_CLASSNAME)) {
        return
      }

      el.classList.remove(OPTION_FOCUS_CLASSNAME)
      setFocus(next as HTMLButtonElement)
    },
    [selectContainerRef, buttonRef, setFocus],
  )

  useEffect(() => {
    document.addEventListener('keydown', onKeyDownInOption, true)
    return (): void => document.removeEventListener('keydown', onKeyDownInOption, true)
  }, [onKeyDownInOption])
}
