import { useCallback, useEffect, useRef } from 'react'
import {
  DraggableProvided,
  DraggingStyle,
  DraggableChildrenFn,
  DraggableStateSnapshot,
} from 'react-beautiful-dnd'
import { createPortal } from 'react-dom'

export const useDraggableInPortal = (): ((fn: DraggableChildrenFn) => DraggableChildrenFn) =>
  useCallback(
    (render: DraggableChildrenFn): DraggableChildrenFn =>
      (provided: DraggableProvided, snapshot: DraggableStateSnapshot, ...args) => {
        const result = render(provided, snapshot, ...args)
        const style = provided.draggableProps.style as DraggingStyle
        return style.position === 'fixed' ? <PortalBody result={result} /> : result
      },
    [],
  )

const PortalBody = ({ result }: { result: ReturnType<DraggableChildrenFn> }) => {
  const element = useRef<HTMLDivElement>(document.createElement('div')).current

  useEffect(() => {
    element.style.pointerEvents = 'none'
    element.style.position = 'absolute'
    element.style.height = '100%'
    element.style.width = '100%'
    element.style.top = '0'

    document.body.appendChild(element)
    return () => {
      document.body.removeChild(element)
    }
  }, [element])

  return createPortal(result, element)
}
