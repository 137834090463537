import { yupResolver } from '@hookform/resolvers/yup'
import React, { forwardRef, useCallback, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'

import {
  AvatarUploaderContainer,
  Props as AvatarUploaderContainerProps,
} from '../../../../components/domain/AvatarUploader/AvatarUploader.container'
import { ImageFileInfo } from '../../../../components/domain/ImageUploader'
import { Button } from '../../../../components/ui/Button'
import { FormField } from '../../../../components/ui/FormField'
import { FormSubmitField } from '../../../../components/ui/FormSubmitField'
import { Input } from '../../../../components/ui/Input'
import { TextButton } from '../../../../components/ui/TextButton'
import { useTranslation } from '../../../../i18n'

import * as styles from './styles'

export type FormValues = {
  firstName: string
  lastName: string
  avatarPath?: string
  email: string
}

export type ModalSubmitHandler = (...params: Parameters<SubmitHandler<FormValues>>) => Promise<void>

export type Props = FormValues & {
  avatarUrl?: string
  updateProfile: ModalSubmitHandler
}

export const ProfileSetting = forwardRef<HTMLDivElement, Props>(
  (
    { firstName, lastName, avatarPath, avatarUrl, email, updateProfile, ...rest }: Props,
    ref: React.Ref<HTMLDivElement>,
  ) => {
    const { t } = useTranslation()
    const [avatarImageUrl, setAvatarImageUrl] = useState<string | undefined>(avatarUrl)

    const {
      register,
      handleSubmit,
      reset,
      setValue,
      formState: { errors, touchedFields, isDirty, isValid, isSubmitting },
    } = useForm<FormValues>({
      mode: 'onChange',
      resolver: yupResolver(
        Yup.object().shape({
          firstName: Yup.string()
            .required(t('REQUIRED_ERROR'))
            .max(255, ({ max }) => t('TOO_LONG', { max })),
          lastName: Yup.string()
            .required(t('REQUIRED_ERROR'))
            .max(255, ({ max }) => t('TOO_LONG', { max })),
          email: Yup.string().required(t('REQUIRED_ERROR')).email(t('INVALID_EMAIL_FORMAT')),
        }),
      ),
      defaultValues: {
        firstName,
        lastName,
        avatarPath,
        email,
      },
    })

    const handleAvatarUploaded = useCallback<
      NonNullable<AvatarUploaderContainerProps['onUploaded']>
    >(
      (image: ImageFileInfo) => {
        setValue('avatarPath', image.path, { shouldTouch: true })
        setAvatarImageUrl(image.url)
      },
      [setValue],
    )

    const handleAvatarRemove = useCallback<
      NonNullable<AvatarUploaderContainerProps['onRemove']>
    >(() => {
      setAvatarImageUrl(undefined)
      setValue('avatarPath', undefined, { shouldTouch: true })
    }, [setValue])

    const resetDefaultValue = useCallback(
      (values: FormValues, avatarUrlValue?: string) => {
        reset(values, { keepDefaultValues: false })
        setAvatarImageUrl(avatarUrlValue)
      },
      [reset],
    )

    const handleEditCancel = useCallback(() => {
      resetDefaultValue(
        {
          firstName,
          lastName,
          avatarPath,
          email,
        },
        avatarUrl,
      )
    }, [avatarPath, avatarUrl, email, firstName, lastName, resetDefaultValue])

    return (
      <div ref={ref} css={styles.rootCss} {...rest}>
        <p css={styles.sectionCss}>{t('PROFILE')}</p>
        <form
          css={styles.formCss}
          onSubmit={handleSubmit((values: FormValues) =>
            updateProfile(values).then(() => {
              resetDefaultValue(values, avatarImageUrl)
            }),
          )}
        >
          <FormField css={styles.avatarFieldCss} touched={!!touchedFields.avatarPath}>
            <AvatarUploaderContainer
              imageUrl={avatarImageUrl}
              onUploaded={handleAvatarUploaded}
              onRemove={handleAvatarRemove}
            />
          </FormField>
          <div css={styles.fullNameFieldWrapperCss}>
            <FormField
              css={styles.fullNameFieldCss}
              label={t('LAST_NAME')}
              labelColor="text-bk-50"
              labelFontSize="small"
              errorMessage={errors.lastName ? `${errors.lastName.message}` : ''}
              touched={!!touchedFields.lastName}
            >
              <Input
                fontSize="medium"
                fieldStyle="bottomLine"
                css={styles.inputCss}
                {...register('lastName')}
              />
            </FormField>
            <FormField
              css={styles.fullNameFieldCss}
              label={t('FIRST_NAME')}
              labelColor="text-bk-50"
              labelFontSize="small"
              errorMessage={errors.firstName ? `${errors.firstName.message}` : ''}
              touched={!!touchedFields.firstName}
            >
              <Input
                fontSize="medium"
                fieldStyle="bottomLine"
                css={styles.inputCss}
                {...register('firstName')}
              />
            </FormField>
          </div>
          <FormField
            css={styles.emailFieldCss}
            label={t('EMAIL')}
            labelColor="text-bk-50"
            labelFontSize="small"
            errorMessage={errors.email ? `${errors.email.message}` : ''}
          >
            <Input
              {...register('email')}
              type="email"
              autoComplete="username"
              fontSize="medium"
              fieldStyle="bottomLine"
              css={styles.inputCss}
            />
          </FormField>
          {(Object.keys(touchedFields).length > 0 || isDirty) && (
            <FormSubmitField css={styles.formSubmitFieldCss}>
              <TextButton
                type="button"
                size="small"
                color="text-bk-50"
                padding="12px 6px"
                onClick={handleEditCancel}
              >
                {t('CANCEL')}
              </TextButton>
              <Button
                type="submit"
                disabled={isSubmitting || !isValid}
                newColor="resily-orange-100"
                size="ss"
                weight="normal"
              >
                {t('SAVE')}
              </Button>
            </FormSubmitField>
          )}
        </form>
      </div>
    )
  },
)

ProfileSetting.displayName = 'ProfileSetting'
