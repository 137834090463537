import { Box } from 'grommet'
import React from 'react'

import { useTranslation } from '../../../i18n'
import { summaryTitle } from '../../../lib/domain/checkinSummary'
import { color } from '../../../styles/newColors'
import { CHECKIN_SUMMARY_HASH, generateOkr } from '../../../urls'
import { Breadcrumb, Props as BreadcrumbProps } from '../../ui/Breadcrumb'
import { Icon } from '../../ui/Icon'
import { StyledText } from '../../ui/StyledText'
import { OkrIcon } from '../OkrIcon'

import { CheckinSummaryFragment, OkrNodeFragment } from './graphql'

export type Props = {
  okrNode: OkrNodeFragment
  checkinSummary: CheckinSummaryFragment
}

export const CheckinSummaryPageHeader: React.FC<Props> = ({ okrNode, checkinSummary }) => {
  const { t } = useTranslation()

  const breadcrumbItems: BreadcrumbProps['items'] = [
    {
      label: okrNode.objective.name,
      href: generateOkr(okrNode.id),
    },
    {
      label: (
        <div css={{ display: 'flex', alignItems: 'center' }}>
          <Icon
            type="checkinSummary"
            width={10}
            height={12}
            color={color('text-bk-50')}
            css={{ marginRight: '3px' }}
          />
          <div>{t('CHECKIN_SUMMARIES')}</div>
        </div>
      ),
      href: `${generateOkr(okrNode.id)}${CHECKIN_SUMMARY_HASH}`,
    },
  ]

  return (
    <Box
      style={{
        backgroundColor: color('white-100'),
        minWidth: '1044px',
        display: 'flex',
        flexDirection: 'row',
        padding: '26px 0 20px',
        boxShadow: '0px 1px 0px #D3D4D9',
      }}
    >
      <div css={{ display: 'flex', alignItems: 'center', marginLeft: '37px' }}>
        <Icon type="checkinSummary" width={24} height={24} color={color('text-bk-50')} />
      </div>
      <div css={{ flex: 1, marginLeft: '21px' }}>
        <StyledText size="medium" lineHeight="26px">
          {summaryTitle(t, checkinSummary)}
        </StyledText>
        <div css={{ display: 'flex', marginTop: '8px' }}>
          <OkrIcon />
          <Breadcrumb css={{ marginLeft: '6px' }} items={breadcrumbItems} />
        </div>
      </div>
    </Box>
  )
}

CheckinSummaryPageHeader.displayName = 'CheckinSummaryPageHeader'
