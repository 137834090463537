import { useTranslation } from '../../../../../../i18n'
import { StyledText } from '../../../../../ui/StyledText'
import { CreateNote } from '../CreateNote'

import { useStyles } from './EmptyView.styles'

export type EmptyViewProps = {
  onClickCreateNote: React.MouseEventHandler<HTMLButtonElement>
}

export const EmptyView: React.VFC<EmptyViewProps> = ({ onClickCreateNote }) => {
  const { t } = useTranslation()
  const styles = useStyles()

  return (
    <div css={styles.root}>
      <div css={styles.empty}>
        <StyledText as="div" color="text-bk-100" size="small">
          {t('X_IS_EMPTY', { x: t('NOTE') })}
        </StyledText>
        <StyledText as="div" color="text-bk-100" size="small">
          {t('CREATE_A_NEW_NOTE')}
        </StyledText>
      </div>
      <CreateNote color="text-bk-50" onClick={onClickCreateNote} />
    </div>
  )
}
EmptyView.displayName = 'EmptyView'
