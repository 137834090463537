import { useCallback, useMemo, useContext } from 'react'

import { KrFilterContext } from '../../../contexts/KrFilterContext'
import { OKRTree } from '../../../lib/domain/okr/tree'

import { highlightKeyResult, highlightObjective, resetAllHighlights } from './OkrNode'
import { KeyResult, OkrNode, Objective } from './types'

type Ret = [
  (o: Objective) => void,
  () => void,
  (k: KeyResult) => void,
  () => void,
  (k: KeyResult) => void,
]

export const useNodeHover = (okrTree: OKRTree<OkrNode>): Ret => {
  const childObjectivesIndex = useMemo<ReadonlyMap<string, ReadonlyArray<string>>>(() => {
    const index = new Map<string, ReadonlyArray<string>>()
    okrTree.traversal((node) => {
      node.objective.parentKeyResults.forEach((keyResult) => {
        if (index.has(keyResult.id)) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          index.set(keyResult.id, index.get(keyResult.id)!.concat([node.objective.id]))
        } else {
          index.set(keyResult.id, [node.objective.id])
        }
      })
    })
    return index
  }, [okrTree])

  const { toggleKrFilter } = useContext(KrFilterContext)

  const memoizedOnObjectiveEnter = useCallback((o: Objective) => {
    // 紐付く上位KRがいるOにホバーした場合
    if (o.parentKeyResults.length > 0) {
      // 自身と紐付く上位KRをハイライト
      highlightObjective(o.id)
      o.parentKeyResults.forEach((kr) => highlightKeyResult(kr.id))
    }
  }, [])

  const memoizedOnKeyResultEnter = useCallback(
    (k: KeyResult) => {
      // 紐付く下位OがいるKRにホバーした場合
      const childOs = childObjectivesIndex.get(k.id)
      if (childOs) {
        // 自身と紐付く下位Oをハイライト
        highlightKeyResult(k.id)
        childOs.forEach(highlightObjective)
      }
    },
    [childObjectivesIndex],
  )

  const memoizedOnKeyResultFilterClick = useCallback(
    (k: KeyResult) => {
      // 紐付く下位OがいるKRにホバーした場合
      const childOs = childObjectivesIndex.get(k.id)

      if (!childOs) return
      toggleKrFilter(childOs, k.id)
    },
    [childObjectivesIndex, toggleKrFilter],
  )

  return [
    memoizedOnObjectiveEnter,
    resetAllHighlights,
    memoizedOnKeyResultEnter,
    resetAllHighlights,
    memoizedOnKeyResultFilterClick,
  ]
}
