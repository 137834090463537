import { css } from '@emotion/react'

import { Avatar } from '../../ui/Avatar'
import { ObjectiveFragment } from '../ParentOkrSelect/graphql'

const rootCss = css({ display: 'flex', alignItems: 'center', gap: 8 })
const nameCss = css({
  // stylelint-disable-next-line
  display: '-webkit-box',
  maxWidth: '560px', // 元の実装から持ってきただけなので適切かどうか不明
  overflow: 'hidden',
  wordBreak: 'break-all',
  wordWrap: 'break-word',
  WebkitLineClamp: 1,
  WebkitBoxOrient: 'vertical',
  fontSize: '14px',
  lineHeight: '20px',
})

export type Props = { objective: Pick<ObjectiveFragment, 'name' | 'owner'> }

export const ObjectiveName: React.VFC<Props> = ({ objective }) => (
  <div css={rootCss}>
    <Avatar
      size="xsmall"
      firstName={objective.owner.firstName}
      lastName={objective.owner.lastName}
      avatarUrl={objective.owner.avatar?.url}
      isUserDisabled={objective.owner.isDisabled}
    />
    <span css={nameCss}>{objective.name}</span>
  </div>
)
