import { Box } from 'grommet'
import { useState, useMemo } from 'react'

import { BreadcrumbParams } from '../../../../components/domain/Breadcrumb'
import { PageContent } from '../../../../components/pageContent'
import { useTranslation } from '../../../../i18n'
import { Screen } from '../../../../lib/screen'
import { useTracking } from '../../../../lib/tracking'
import { color } from '../../../../styles/newColors'
import * as urls from '../../../../urls'
import { AdminUserModal } from '../AdminUserModal'
import { AvatarAndButtonSection } from '../AvatarAndButtonSection'
import { PrimaryInformationSection } from '../PrimaryInformationSection'
import { SecondaryInformationSection } from '../SecondaryInformationSection'
import { AdminUserProps } from '../types'

export const AdminUser: React.FC<AdminUserProps> = ({
  user,
  isOwnPage,
  remainingLicenses,
  onUpdate,
  onUpdateActivateStatus,
  onResendInvitation,
  onDeleteUser,
  onCancel,
}) => {
  const { t } = useTranslation()

  const [isEdit, setIsEdit] = useState(false)
  const [deleteModalVisibility, setDeleteModalVisibility] = useState(false)
  const [cancelModalVisibility, setCancelModalVisibility] = useState(false)
  const [disabledModalVisibility, setDisabledModalVisibility] = useState(false)

  useTracking(
    t('ADMIN_USER_PAGE_TITLE', {
      fullName: t('FULL_NAME', {
        firstName: user.firstName,
        lastName: user.lastName,
      }),
    }),
    Screen.UserSetting,
  )

  const breadcrumbs = useMemo<BreadcrumbParams>(
    () => ({
      url: urls.userAdmin,
      items: [
        {
          breadcrumbName: 'setting',
        },
        {
          breadcrumbName: 'settingUsers',
        },
        {
          breadcrumbName: 'settingUser',
          ...user,
        },
      ],
    }),
    [user],
  )

  return (
    <PageContent
      layout={{
        pad: 'large',
      }}
      breadcrumbs={breadcrumbs}
      contentBackgroundColor={color('background-bk-5')}
    >
      <Box gap="20px">
        <AvatarAndButtonSection
          user={user}
          isOwnPage={isOwnPage}
          remainingLicenses={remainingLicenses}
          onUpdateActivateStatus={onUpdateActivateStatus}
          onResendInvitation={onResendInvitation}
          setDeleteModalVisibility={setDeleteModalVisibility}
          setDisabledModalVisibility={setDisabledModalVisibility}
        />
        <PrimaryInformationSection
          user={user}
          onUpdate={onUpdate}
          isEdit={isEdit}
          onClickInput={() => setIsEdit(true)}
          onClickCancelButton={() => setCancelModalVisibility(true)}
          onClickUpdateButton={() => setIsEdit(false)}
        />
        <SecondaryInformationSection user={user} />
      </Box>
      <AdminUserModal
        user={user}
        deleteModalState={{ deleteModalVisibility, setDeleteModalVisibility }}
        disableModalState={{ disabledModalVisibility, setDisabledModalVisibility }}
        cancelModalState={{ cancelModalVisibility, setCancelModalVisibility }}
        onUpdateActivateStatus={onUpdateActivateStatus}
        onDeleteUser={onDeleteUser}
        onCancel={onCancel}
      />
    </PageContent>
  )
}

AdminUser.displayName = 'AdminUser'
