import { KeyResult, TargetSettingType } from '../../../graphql'
import { fontSize as fontSizeFn, FontSizeAlias, FontStyleAlias } from '../../../styles/font'
import { TitleLink } from '../../ui/DataTable/TitleLink'
import { Icon } from '../../ui/Icon'
import { StyledText } from '../../ui/StyledText'

type Props = JSX.IntrinsicElements['div'] & {
  keyResult: Pick<KeyResult, 'name' | 'targetSetting' | 'isDisabled'>
  fontSize?: FontSizeAlias
  fontStyle?: FontStyleAlias
  onClick?: () => void
}

export const KeyResultName: React.VFC<Props> = (props) => {
  if (props.onClick) {
    return <Link {...props} />
  }

  return <Text {...props} />
}

KeyResultName.displayName = 'KeyResultName'

const Link: React.VFC<Props> = ({ keyResult, fontSize, fontStyle, onClick, ...props }) => (
  <div {...props}>
    {keyResult.targetSetting === TargetSettingType.Moon && (
      <Icon
        type="moon"
        css={{
          display: 'inline',
          marginRight: '4px',
          opacity: keyResult.isDisabled ? 0.2 : undefined,
        }}
      />
    )}
    {keyResult.targetSetting === TargetSettingType.Roof && (
      <Icon
        type="roof"
        css={{
          display: 'inline',
          marginRight: '4px',
          opacity: keyResult.isDisabled ? 0.2 : undefined,
        }}
      />
    )}

    <TitleLink
      css={{
        display: 'inline',
        opacity: keyResult.isDisabled ? 0.2 : undefined,
        textDecoration: keyResult.isDisabled ? 'line-through' : undefined,
      }}
      titleProps={{
        title: keyResult.name,
        onClick,
        css: { display: 'inline', ...(fontSize ? fontSizeFn(fontSize, fontStyle) : undefined) },
      }}
      margin=""
    />
  </div>
)

Link.displayName = 'Link'

const Text: React.VFC<Props> = ({ keyResult, fontSize, fontStyle, ...props }) => (
  <div {...props}>
    <StyledText
      size={fontSize}
      fontStyle={fontStyle}
      isWrap
      css={{
        wordBreak: 'break-all',
        opacity: keyResult.isDisabled ? 0.2 : undefined,
        textDecoration: keyResult.isDisabled ? 'line-through' : undefined,
      }}
    >
      {keyResult.targetSetting === TargetSettingType.Moon && (
        <Icon
          type="moon"
          css={{
            display: 'inline',
            marginRight: '4px',
            opacity: keyResult.isDisabled ? 0.2 : undefined,
          }}
        />
      )}
      {keyResult.targetSetting === TargetSettingType.Roof && (
        <Icon
          type="roof"
          css={{
            display: 'inline',
            marginRight: '4px',
            opacity: keyResult.isDisabled ? 0.2 : undefined,
          }}
        />
      )}

      {keyResult.name}
    </StyledText>
  </div>
)

Text.displayName = 'Text'
