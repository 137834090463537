import { css } from '@emotion/react'

import { color } from '../../../styles/newColors'

export const contentWrapperCss = css({
  marginTop: '32px',
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
})

export const titleSectionCss = css({ display: 'flex', flexDirection: 'column', gap: '8px' })

export const linkCss = css({
  color: color('resily-orange-100'),
  textDecoration: 'underline',
})

export const trialEndWarningCss = css({
  backgroundColor: color('resily-orange-5'),
  borderRadius: 4,
  padding: '2px 12px',
  display: 'inline-grid',
})

export const stripeButtonCss = css({
  height: 44,
  padding: '12px 24px',
  borderRadius: 6,
  backgroundColor: color('resily-orange-100'),
  color: '#FFF',
  fontSize: 14,
  ':hover': { filter: 'brightness(.97)' },
})
