import { css } from '@emotion/react'
import 'prismjs/themes/prism.css'
import {
  EditorId,
  focusBlockStartById,
  getPlateActions,
  isCollapsed,
  // MentionNodeData,
  // MentionSelect,
  Plate,
  PlateProps,
  PlateProvider,
  usePlateSelectors,
  Value,
} from '@udecode/plate'
import { Spinner } from 'grommet'
import { isHotkey } from 'is-hotkey'
import {
  useCallback,
  useState,
  useMemo,
  useImperativeHandle,
  forwardRef,
  FC,
  FocusEventHandler,
  useRef,
} from 'react'
import isEqual from 'react-fast-compare'
import { useIsomorphicLayoutEffect, usePrevious } from 'react-use'
import scrollIntoView from 'scroll-into-view-if-needed'

import { useTranslation } from '../../../i18n'
import { uploadImage } from '../../../lib/client'
import { border } from '../../../styles/border'
import { color } from '../../../styles/newColors'

import { BalloonToolbar } from './PlateEditor/components/BalloonToolbar'
import { Toolbar } from './PlateEditor/components/Toolbar'
import { EMPTY_PARAGRAPH } from './PlateEditor/constants'
import { PlateErrorBoundary } from './PlateEditor/error'
import { convertFragmentFromPlainTextToPlate } from './PlateEditor/plugins/converter/convertFragmentFromPlainTextToPlate'
import { convertFragmentFromSlateToPlate } from './PlateEditor/plugins/converter/convertFragmentFromSlateToPlate'
import { ImageElementProps } from './PlateEditor/plugins/image/ImageElement'
import { serializeHtml } from './PlateEditor/plugins/serializer/html'
import { serializeMarkdown } from './PlateEditor/plugins/serializer/markdown'
import { serializeText } from './PlateEditor/plugins/serializer/text'
import { usePlatePlugins } from './PlateEditor/plugins/usePlatePlugins'
import { CollaborationPlugin, useCollaboration } from './useCollaborationEditor'
import { useFocusEditor } from './useFocusEditor'

// const renderMentionLabel = (mentionable: MentionNodeData) => {
//   const entry = MENTIONABLES.find((m) => m.value === mentionable.value)
//   if (!entry) return 'unknown option'
//   return `${entry.name} - ${entry.email}`
// }

type EditableProps = Omit<NonNullable<PlateProps['editableProps']>, 'scrollSelectionIntoView'>

export type Props = Omit<PlateProps, 'children' | 'editorProps' | 'placeholder' | 'onChange'> & {
  id: NonNullable<EditorId>
  ['data-testid']?: string
  editorProps?: EditableProps
  autoFocus?: boolean
  className?: string
  initialValueJSON?: string
  onlineMode?: CollaborationPlugin['onlineMode']
  autoSave?: boolean
  imageUploader?: (file: File) => Promise<string>
  onChange?: (json: string, plainText: string, html: string, markdown: string) => void
  onSave?: (json: string, plainText: string, html: string, markdown: string) => void
  onFocus?: () => void
  onChangeSummaryMode?: () => void
  imageElementProps?: ImageElementProps
}

export type EditorRef = {
  /**
   * エディタの値をセットします
   * valueが指定されていない場合は値をクリアします
   */
  setValue: (value?: string) => void

  /**
   * valueをinitialValueJSON/Textに入っている値にリセットします
   */
  resetInitialValue: () => void

  /**
   * エディタにフォーカスを当てます
   */
  focus: () => void
}

const DEFAULT_INITIAL_VALUE: Value = [EMPTY_PARAGRAPH]

export const DEFAULT_INITIAL_VALUE_JSON_STRING = JSON.stringify(DEFAULT_INITIAL_VALUE)

export const RichTextEditorInner = forwardRef<EditorRef, Props>(
  (
    {
      id,
      'data-testid': dataTestId,
      editorProps = {
        spellCheck: false,
        placeholder: undefined,
        readOnly: false,
      },
      autoFocus = true,
      autoSave = false,
      className,
      initialValueJSON,
      onlineMode,
      imageUploader = uploadImage,
      onChange,
      onSave,
      onFocus,
      onChangeSummaryMode,
      imageElementProps,
      ...props
    },
    editorRef,
  ) => {
    const { t } = useTranslation()
    const { readOnly, placeholder } = editorProps

    const editorCss = useMemo(
      () =>
        css({
          fontSize: 14,
          lineHeight: 2,
          position: 'relative',
          backgroundColor: readOnly ? undefined : color('hover-background-bk-5'),
          border: readOnly ? undefined : border('simple-30'),
          borderRadius: readOnly ? undefined : '4px',
          'div[data-slate-editor="true"][contenteditable="true"]': {
            height: '100%',
            padding: '16px',
          },
          // See: https://github.com/ianstormtaylor/slate/issues/1971
          // Noto Sansを利用しているとU+FEFFが変換され文頭のリンク文字列が壊れる問題を抱えている
          // この対応のため下記セレクタでSlateが出力するU+FEFFを非表示にすることで対応する
          'span[data-slate-zero-width="z"][data-slate-length="0"]': {
            display: 'none',
          },
        }),
      [readOnly],
    )

    const initialValue = useMemo<Value>(() => {
      if (initialValueJSON) {
        try {
          // slateのデータ構造が入ってくる可能性があるので変換する
          return convertFragmentFromSlateToPlate(JSON.parse(initialValueJSON))
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e)
          // initialValueJSONのparseに失敗した場合はfallbackプランとしてプレーンテキストとして解釈しデータを構成する
          return convertFragmentFromPlainTextToPlate(initialValueJSON)
        }
      }
      return DEFAULT_INITIAL_VALUE
    }, [initialValueJSON])

    const selector = usePlateSelectors(id)
    const value = selector.value()
    const previousValue = usePrevious(value)
    const { resetEditor, value: setValue } = getPlateActions(id)

    const handleSave = useCallback(
      (json: string, plainText: string, html: string, markdown: string) => {
        if (onSave) {
          onSave(json, plainText, html, markdown)
        } else if (onChange) {
          onChange(json, plainText, html, markdown)
        }
      },
      [onChange, onSave],
    )

    const plugins = usePlatePlugins(imageUploader, imageElementProps)
    const [editor, , isSubmitting, renderEditable, handleBlur] = useCollaboration({
      id,
      plugins,
      initialValue,
      onlineMode,
      autoSave,
      autoFocus,
      // TODO: 後でPropsの型定義を変更する
      onSave: onSave!,
    })

    useImperativeHandle<EditorRef, EditorRef>(
      editorRef,
      () => ({
        setValue: (resetValue?: string) => {
          if (resetValue != null && resetValue !== '') {
            try {
              // slateのデータ構造が入ってくる可能性があるので変換する
              setValue(convertFragmentFromSlateToPlate(JSON.parse(resetValue)))
            } catch (e) {
              // eslint-disable-next-line no-console
              console.error(e)
              // resetValueのparseに失敗した場合はfallbackプランとしてプレーンテキストとして解釈しデータを構成する
              const plainText = convertFragmentFromPlainTextToPlate(resetValue)
              setValue(plainText)
            }
            resetEditor()
            return
          }

          setValue(DEFAULT_INITIAL_VALUE)
          resetEditor()
        },

        resetInitialValue: () => {
          setValue(initialValue)
          resetEditor()
        },

        focus: () => {
          if (editor) {
            focusBlockStartById(editor, id)
          }
        },
      }),
      [setValue, resetEditor, id, initialValue, editor],
    )

    const isAutoFocus = useFocusEditor(id, autoFocus, readOnly ?? false)
    const [isFocus, setIsFocus] = useState<boolean>(false)
    const memoizedHandleBlur = useCallback<FocusEventHandler<HTMLDivElement>>(
      (e) => {
        setIsFocus(false)
        handleBlur(e)
      },
      [handleBlur],
    )
    const isShowPlaceholder =
      !readOnly && !isFocus && (isEqual(value, DEFAULT_INITIAL_VALUE) || value?.length === 0)
    const editableProps = useMemo<EditableProps>(
      () => ({
        autoFocus: isAutoFocus,
        ...editorProps,
        placeholder: isShowPlaceholder ? placeholder ?? t('INPUT_TEXT') : undefined,
        scrollSelectionIntoView,
        onBlur: memoizedHandleBlur,
        onFocus: () => {
          setIsFocus(true)
          onChangeSummaryMode?.()
        },
      }),
      [
        isAutoFocus,
        editorProps,
        isShowPlaceholder,
        placeholder,
        memoizedHandleBlur,
        onChangeSummaryMode,
        t,
      ],
    )

    const handleChange = useCallback(
      (newValue: Value) => {
        if (!onChange || editor == null) {
          return
        }
        // See: https://docs.slatejs.org/walkthroughs/06-saving-to-a-database
        const isAstChange = editor.operations.some((op) => op.type !== 'set_selection')
        if (isAstChange) {
          onChange(
            JSON.stringify(newValue),
            serializeText(newValue),
            serializeHtml(editor, { nodes: newValue }),
            serializeMarkdown(newValue),
          )
        }
      },
      [editor, onChange],
    )

    const parentScrollRef = useRef<HTMLDivElement>(null)

    useIsomorphicLayoutEffect(() => {
      if (!parentScrollRef.current) {
        return () => {}
      }

      const onSaveHotKeyBind = (e: KeyboardEvent) => {
        if (value && isHotkey('mod+s', e) && editor) {
          e.preventDefault()
          handleSave(
            JSON.stringify(value),
            serializeText(value),
            serializeHtml(editor, { nodes: value }),
            serializeMarkdown(value),
          )
        }
      }
      const { current } = parentScrollRef
      current.addEventListener('keydown', onSaveHotKeyBind)
      return () => current.removeEventListener('keydown', onSaveHotKeyBind)
    }, [editor, handleSave, value, parentScrollRef])

    return (
      <PlateErrorBoundary prevValue={previousValue} onSave={handleSave}>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
        <div
          ref={parentScrollRef}
          data-testid={dataTestId}
          translate="no"
          css={editorCss}
          className={`notranslate ${className}`}
          onFocus={onFocus}
        >
          <Plate
            id={id}
            editor={editor}
            // 共同編集時はinitialValueを渡してはいけない
            // 共同編集時はsharedRootが唯一のデータソースとなるため
            // エディタ側でinitialValueにノードを渡してしまうと
            // sharedRootとデータの不整合が置きてしまうためである
            initialValue={onlineMode != null ? [] : initialValue}
            plugins={plugins}
            onChange={handleChange}
            renderEditable={renderEditable}
            editableProps={editableProps}
            {...props}
          >
            {!readOnly && (
              <>
                <BalloonToolbar id={id} />
                <Toolbar id={id} imageUploader={imageUploader} />

                {/* <MentionSelect {...getMentionSelectProps()} renderLabel={renderMentionLabel} /> */}
              </>
            )}
          </Plate>
          {isSubmitting && <SubmittingSpinner />}
        </div>
      </PlateErrorBoundary>
    )
  },
)

RichTextEditorInner.displayName = 'RichTextEditorInner'

export const RichTextEditor = forwardRef<EditorRef, Props>((props, ref) => (
  <PlateProvider id={props.id}>
    <RichTextEditorInner ref={ref} {...props} />
  </PlateProvider>
))

RichTextEditor.displayName = 'RichTextEditor'

const SubmittingSpinner: FC = () => {
  const { t } = useTranslation()

  return (
    <div
      css={{
        display: 'flex',
        textAlign: 'center',
        float: 'right',
      }}
    >
      <Spinner />
      <span css={{ marginLeft: 8 }}>{t('SAVING')}</span>
    </div>
  )
}

SubmittingSpinner.displayName = 'SubmittingSpinner'

/**
 * defaultScrollSelectionIntoView の改造版
 * @see https://github.com/ianstormtaylor/slate/blob/f8d8d017d165cb94583524c644f146acdb663236/packages/slate-react/src/components/editable.tsx#L1757
 */
const scrollSelectionIntoView: NonNullable<
  PlateProps['editableProps']
>['scrollSelectionIntoView'] = (editor, domRange) => {
  if (!isCollapsed(editor.selection)) return

  // HACK: LINKの左右にあるzero-width spaceに移動すると、
  //       display: 'none'にしてる影響で変な値が飛んできてスクロールが吹っ飛ぶ対策
  if (Object.values(domRange.getBoundingClientRect().toJSON()).every((v) => v === 0)) {
    return
  }

  const leafEl = domRange.startContainer.parentElement!
  leafEl.getBoundingClientRect = domRange.getBoundingClientRect.bind(domRange)
  scrollIntoView(leafEl, { scrollMode: 'if-needed' })

  // @ts-expect-error an unorthodox delete D:
  delete leafEl.getBoundingClientRect
}
