import dayjs from 'dayjs'
import { useEffect, useMemo, useCallback, useReducer } from 'react'
import { useLocation, useMatch, matchPath } from 'react-router-dom'

import { User } from '../../../contexts/UserContext'
import { OrganizationFragment } from '../../../contexts/graphql'
import { useTranslation, Trans } from '../../../i18n'
import { findScreen } from '../../../lib/screen'
import { tracker } from '../../../lib/tracking'
import { color } from '../../../styles/newColors'
import * as urls from '../../../urls'
import { generateUserWithCheckinModalOpen } from '../../../urls'
import { Link } from '../../ui/Link'

import { useOkrTermsLazyQuery } from './graphql'

export const useCheckinAlert = ({
  isHiddenAlert,
  organization,
  user,
}: {
  /** checkinAlertを非表示にする */
  isHiddenAlert: boolean
  organization?: OrganizationFragment | null
  user?: User | null
}): {
  checkinPageUrl: string
  shouldShowAlert: boolean
  title: string
  message: string | JSX.Element
  closeAlert: () => void
  submitEvent: () => void
} => {
  const { t } = useTranslation()

  const userId = user?.id
  const location = useLocation()

  const alertShownPageUrls = useMemo(
    () => [
      urls.treesInCurrentTerm,
      urls.term,
      urls.trees,
      urls.okr,
      urls.objective,
      urls.actionPlans,
      urls.checkinSummary,
      urls.users,
      urls.usersInCurrentTerm,
    ],
    [],
  )

  const userMatch = useMatch(urls.user)
  const userInCurrentTermMatch = useMatch(urls.userInCurrentTerm)

  const isOtherUserPage =
    (userMatch !== null && userMatch.params.userId !== userId) ||
    (userInCurrentTermMatch !== null && userInCurrentTermMatch.params.userId !== userId)

  const isAlertShownPage =
    alertShownPageUrls.some((url) => matchPath(url, location.pathname) != null) || isOtherUserPage

  // isBulkUpdatedの値がfalseのとき(undefinedは含まない)にalertを表示
  const notDoneCheckin = user?.checkinKeyResultsState?.isBulkUpdated === false

  // ユーザーがアラートを閉じたのflag
  const [isClosedByUser, closeAlert] = useReducer(
    () => true,
    false,
    (arg) => {
      const closeUntil = localStorage.getItem('close-bulk-update-alert-until')
      if (!closeUntil) return arg
      return dayjs().isBefore(dayjs(closeUntil))
    },
  )
  useEffect(() => {
    if (isClosedByUser) {
      // 日曜日は、1日加算して今週月曜日の00:00:00にする
      // 日曜日以外は、翌週の月曜日の00:00:00とする
      localStorage.setItem(
        'close-bulk-update-alert-until',
        dayjs().format('d') === '0'
          ? dayjs().startOf('week').add(1, 'day').format()
          : dayjs().startOf('week').add(8, 'day').format(),
      )
    }
  }, [isClosedByUser])

  // アラートを表示する条件:
  // 表示する場所である、一括更新をしていない、ユーザーが閉じていない
  const shouldShowAlert = !isHiddenAlert && isAlertShownPage && notDoneCheckin && !isClosedByUser

  const checkinPageUrl = useMemo(
    () => (userId ? `${generateUserWithCheckinModalOpen(userId)}` : ''),
    [userId],
  )

  const submitEvent = useCallback(() => {
    const screen = findScreen(location.pathname, location.search)
    tracker.UserClickOpenCheckin(screen)
  }, [location.pathname, location.search])

  const shouldShowNotificationSetting =
    !!organization?.slackIntegrationEnabled && !user?.userSetting.notifyBulkUpdateEnabledOnSlack

  const message = useMemo(
    () =>
      shouldShowNotificationSetting ? (
        <Trans
          i18nKey="BULK_UPDATE_PLEASE_MESSAGE_WITH_SLACK"
          components={[
            <Link
              href={urls.accountSetting}
              css={{
                color: color('resily-orange-100'),
                ':hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              {t('ACCOUNT_SETTING_PAGE_TITLE')}
            </Link>,
          ]}
        />
      ) : (
        t('BULK_UPDATE_PLEASE_MESSAGE')
      ),
    [shouldShowNotificationSetting, t],
  )

  return {
    checkinPageUrl,
    shouldShowAlert,
    title: t('BULK_UPDATE_THIS_WEEK_PLEASE_TITLE'),
    message,
    closeAlert,
    submitEvent,
  }
}

export const useCreateNewOkrTermAlert = ({
  organization,
  user,
}: {
  organization?: OrganizationFragment | null
  user?: User | null
}): {
  shouldShowCreateNewOkrTermAlert: boolean
  closeAlert: () => void
} => {
  const progressRateCriteria = 83
  const okrTermId = organization?.id
  const [query, resp] = useOkrTermsLazyQuery()

  useEffect(() => {
    if (okrTermId) {
      query()
    }
  }, [okrTermId, query])

  const primaryTerm = resp?.data?.okrTerms.find(({ primary }) => primary)
  const notExistsNextTerm = useMemo<boolean>(() => {
    if (!primaryTerm || !resp.data) return false

    // 現在のOKR期間終了日から1ヶ月以内に開始されるOKR期間を次のOKR期間と判定する
    const nextTermStartDateStart = dayjs(new Date(primaryTerm.endDate))
    const nextTermStartDateEnd = dayjs(new Date(primaryTerm.endDate)).add(1, 'month')
    return resp.data.okrTerms.every(
      ({ primary, startDate }) =>
        primary ||
        !dayjs(new Date(startDate)).isBetween(
          nextTermStartDateStart,
          nextTermStartDateEnd,
          'day',
          '(]',
        ),
    )
  }, [primaryTerm, resp])

  const isAdmin = user?.admin ?? false

  const isAlertShownPage = useMatch(urls.treesInCurrentTerm) != null

  const [isClosedByUser, closeAlert] = useReducer(() => true, false)

  if (!okrTermId || !isAlertShownPage || !isAdmin || !primaryTerm || isClosedByUser) {
    return { shouldShowCreateNewOkrTermAlert: false, closeAlert }
  }

  return {
    shouldShowCreateNewOkrTermAlert:
      notExistsNextTerm && primaryTerm.progressRate >= progressRateCriteria,
    closeAlert,
  }
}
