import { css } from '@emotion/react'

import { color } from '../../../../../styles/newColors'

export const rootCss = css({
  width: '100%',
  backgroundColor: color('white-100'),
  borderRadius: '8px',
  padding: '24px 32px',
})
