import { Box, BoxProps, Drop, DropProps } from 'grommet'
import { useRef, ReactNode, ReactElement, Fragment } from 'react'

export type Props = BoxProps & {
  children: ReactElement
  title: ReactNode
  dropAlign?: Pick<DropProps, 'align'>['align']
  customized?: boolean
  disabled?: boolean
  restrictFocus?: boolean
  isOpened?: boolean
  onOpen: () => void
  onClose: () => void
}
export const DropTool: React.FC<Props> = ({
  title,
  dropAlign = { top: 'bottom' },
  disabled = false,
  restrictFocus = true,
  isOpened = false,
  children,
  onOpen,
  onClose,
  ...props
}) => {
  const ref = useRef<HTMLDivElement>(null)

  if (disabled) return children

  return (
    <Fragment>
      <Box
        ref={ref}
        width={{ min: 'auto', max: 'auto' }}
        height={{ min: 'auto', max: 'auto' }}
        onClick={(e) => {
          e.preventDefault()
          onOpen()
        }}
        {...props}
      >
        {children}
      </Box>
      {isOpened && ref.current ? (
        <Drop
          align={dropAlign}
          target={ref.current}
          restrictFocus={restrictFocus}
          onClickOutside={(e) => {
            e.preventDefault()
            onClose()
          }}
          plain
        >
          {title}
        </Drop>
      ) : null}
    </Fragment>
  )
}

DropTool.displayName = 'DropTool'
