import { css } from '@emotion/react'
import { useMemo } from 'react'

import { color } from '../../../styles/newColors'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useStyles = () =>
  useMemo(
    () =>
      ({
        logoWrapper: css({ textAlign: 'center' }),
        logo: css({ width: '156px', height: '44px' }),
        content: css({
          width: '500px',
          padding: '24px 48px',
          marginTop: '24px',
          backgroundColor: color('white-100'),
          border: `1px solid ${color('background-bk-30')}`,
          boxShadow: '0px 4px 8px rgb(0 0 0 / 14%)',
          borderRadius: '4px',
        }),
      } as const),
    [],
  )
