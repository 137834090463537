import { useState } from 'react'

import { useTranslation } from '../../../../../../../i18n'
import { componentNames, featureNames, generateTestId } from '../../../../../../../lib/testId'
import { color } from '../../../../../../../styles/newColors'
import { Icon } from '../../../../../../ui/Icon'
import { ProgressNumberInput } from '../../../../../../ui/ProgressNumberInput'
import { StyledText } from '../../../../../../ui/StyledText'
import { useGetFormMethods } from '../../../contexts/FormProvider'
import { keyResultsKeyName } from '../../../formSchema'
import { ProgressNumberInputController } from '../../ProgressNumberInputController'

import { initialValueSectionCss, initialValueSectionEditingCss } from './styles'

type InitialValueSectionProps = {
  index: number
  valueType: 'targetValue' | 'actualValue' | 'initialValue'
}
export const InitialValueSection: React.FC<InitialValueSectionProps> = ({ index, valueType }) => {
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const { t } = useTranslation()
  const { getValues } = useGetFormMethods()

  if (isEdit) {
    return (
      <section css={initialValueSectionEditingCss}>
        <StyledText size="xxsmall" color="border-bk-50">
          {t('INITIAL_VALUE')}
        </StyledText>
        <ProgressNumberInputController index={index} valueType={valueType}>
          {(progressNumberInputProps) => (
            <ProgressNumberInput
              data-testid={generateTestId(
                featureNames.checkinModal,
                componentNames.initialValueInput,
              )}
              {...progressNumberInputProps}
            />
          )}
        </ProgressNumberInputController>
      </section>
    )
  }

  return (
    <section css={initialValueSectionCss}>
      <StyledText size="small" color="text-bk-50" lineHeight="26px">
        {t('INITIAL_VALUE')}: {getValues(`${keyResultsKeyName}.${index}.${valueType}`) || 0}
      </StyledText>
      <Icon
        data-testid={generateTestId(featureNames.checkinModal, componentNames.icon, {
          suffix: 'edit-initial-value',
        })}
        type="editSimple"
        onClick={() => setIsEdit(true)}
        width={12}
        height={12}
        color={color('border-bk-50')}
        hoverColor="resily-orange-100"
      />
    </section>
  )
}
