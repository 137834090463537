import React, { useCallback } from 'react'

import { findScreen } from '../../../../../lib/screen'
import { generateTestId, featureNames, componentNames } from '../../../../../lib/testId'
import { useKeyResultCreateModal } from '../../../KeyResultCreateModal'
import { useStyles as useMainStyles } from '../Main.styles'

import {
  KeyResultAreaHeaderContainer,
  Props as KeyResultAreaHeaderContainerProps,
} from './KeyResultAreaHeader'
import {
  KeyResultAreaTableContainer,
  Props as KeyResultAreaTableContainerProps,
} from './KeyResultAreaTable'

export type Props = Omit<KeyResultAreaHeaderContainerProps, 'openKeyResultCreateModal'> &
  Omit<KeyResultAreaTableContainerProps, 'openKeyResultCreateModal'> & {
    objectiveId: string
    useWeighting: boolean
  }

export const KeyResultArea = React.memo<Props>(
  ({
    termId,
    objectiveId,
    useWeighting,
    keyResults,
    okrNodeId,
    disabledReOrderingChildOkr,
    onClickKeyResultName,
    openWeightSettingModal,
    openSortLowerOKRModal,
  }) => {
    const mainStyles = useMainStyles()

    const [KeyResultCreateModal, openKeyResultCreateModal] = useKeyResultCreateModal()
    const handleKeyResultCreateModal = useCallback(() => {
      openKeyResultCreateModal(
        { objectiveId, useWeighting },
        findScreen(window.location.pathname, window.location.search),
      )
    }, [objectiveId, openKeyResultCreateModal, useWeighting])

    return (
      <div
        css={mainStyles.areaRoot}
        data-testid={generateTestId(featureNames.okrModal, componentNames.krArea)}
      >
        <KeyResultAreaHeaderContainer
          disabledReOrderingChildOkr={disabledReOrderingChildOkr}
          openKeyResultCreateModal={handleKeyResultCreateModal}
          openWeightSettingModal={openWeightSettingModal}
          openSortLowerOKRModal={openSortLowerOKRModal}
        />
        <KeyResultAreaTableContainer
          termId={termId}
          keyResults={keyResults}
          okrNodeId={okrNodeId}
          onClickKeyResultName={onClickKeyResultName}
          openKeyResultCreateModal={handleKeyResultCreateModal}
        />

        {KeyResultCreateModal}
      </div>
    )
  },
)
KeyResultArea.displayName = 'KeyResultArea'
