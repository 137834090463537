import { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import { BreadcrumbParams } from '../../components/domain/Breadcrumb'
import { useTranslation } from '../../i18n'
import { convertToFileInput } from '../../lib/fileInput'
import { usePrompt } from '../../lib/prompt'
import { Screen } from '../../lib/screen'
import { useTracking } from '../../lib/tracking'
import { AuthRouteProps } from '../../types/authRouteProps'
import { generateNote, noteEdit as noteEditPath } from '../../urls'
import {
  NoteDetailFragment,
  NoteDetailKeyResultFragment,
  NoteDetailObjectiveFragment,
  NoteDetailViewNoteDetailFragment,
  useFindNoteByIdQuery,
  useUpdateNoteMutation,
} from '../NoteDetail/graphql'

type Resource = NoteDetailObjectiveFragment | NoteDetailKeyResultFragment

const isObjective = (resource: Resource): resource is NoteDetailObjectiveFragment =>
  resource.__typename === 'Objective'
const isKeyResult = (resource: Resource): resource is NoteDetailKeyResultFragment =>
  resource.__typename === 'KeyResult'

const generateBreadCrumbs = (note: NoteDetailFragment): BreadcrumbParams =>
  isObjective(note.resource)
    ? {
        url: noteEditPath,
        items: [
          {
            breadcrumbName: 'okr',
            ...note.resource.node,
          },
          {
            breadcrumbName: 'notesOfObjective',
            id: note.resource.id,
            name: note.resource.name,
            node: {
              ...note.resource.node,
            },
          },
          {
            breadcrumbName: 'note',
            ...note,
          },
        ],
      }
    : {
        url: noteEditPath,
        items: [
          {
            breadcrumbName: 'okr',
            ...note.resource.node,
          },
          {
            breadcrumbName: 'keyResult',
            id: note.resource.id,
            name: note.resource.name,
            node: {
              ...note.resource.node,
            },
          },
          {
            breadcrumbName: 'notesOfKeyResult',
            id: note.resource.id,
            name: note.resource.name,
            node: {
              ...note.resource.node,
            },
          },
          {
            breadcrumbName: 'note',
            ...note,
          },
        ],
      }

export const useInjection = ({
  onOkrTermLoaded,
}: AuthRouteProps): null | {
  note: NoteDetailFragment
  breadcrumbs: BreadcrumbParams
  onNoteUpdate: (note: NoteDetailViewNoteDetailFragment, redirect: boolean) => void
  onClickCancel: () => void
  setNeedBlock: (value: boolean) => void
} => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const originPath = window.location.pathname + window.location.search + window.location.hash
  const [, setNeedBlock] = usePrompt({ message: t('LEAVE_WARNING'), originPath })
  useTracking(t('NOTE_EDIT_PAGE_TITLE'), Screen.NoteEdit)

  const { noteId } = useParams()
  const res = useFindNoteByIdQuery({
    variables: { id: noteId ?? '' },
    // NOTE: 古い情報が表示されてしまうためキャッシュを無効にする
    fetchPolicy: 'network-only',
    skip: noteId == null,
  })
  const [updateNote] = useUpdateNoteMutation()

  useEffect(() => {
    if (!res.data) {
      return
    }
    onOkrTermLoaded(res.data.findNoteById.okrTerm.id)
  }, [res, onOkrTermLoaded])
  if (!res.data) {
    return null
  }

  const note = res.data.findNoteById

  const updateNoteMutation = async (value: NoteDetailViewNoteDetailFragment) => {
    if (!res.data) {
      return
    }
    await updateNote({
      variables: {
        id: value.id,
        title: value.title,
        body: {
          treeJson: value.body?.treeJson || '',
          plainText: value.body?.plainText || '',
        },
        attachments: value.attachments.map((attachment) => convertToFileInput(attachment)),
        objectiveId: isObjective(note.resource) ? note.resource.id : null,
        keyResultId: isKeyResult(note.resource) ? note.resource.id : null,
        permission: value.permission,
      },
    })
  }

  const onNoteUpdate = async (value: NoteDetailViewNoteDetailFragment, redirect: boolean) => {
    await updateNoteMutation(value)
    if (redirect) {
      navigate(generateNote(note.id))
    }
  }

  const onClickCancel = () => {
    navigate(generateNote(note.id))
  }

  return {
    note,
    breadcrumbs: generateBreadCrumbs(note),
    onNoteUpdate,
    onClickCancel,
    setNeedBlock,
  }
}
