import { css } from '@emotion/react'
import { useMemo } from 'react'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useStyles = () =>
  useMemo(
    () =>
      ({
        root: css({}),
        toggle: css({
          display: 'flex',
          alignItems: 'center',
        }),
        label: css({
          padding: 0,
        }),
        others: {
          marginTop: '16px',
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
        },
      } as const),
    [],
  )
