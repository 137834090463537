import { css } from '@emotion/react'
import { useMemo } from 'react'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useStyles = () =>
  useMemo(
    () =>
      ({
        root: css({
          display: 'grid',
          gridTemplateColumns: 'auto 1fr',
          alignItems: 'center',
          gap: '16px 12px',
          listStyle: 'none',
          '> li': { display: 'contents' },
        }),
        krNameArea: css({
          display: 'grid',
          gridTemplateColumns: '34px auto',
          alignItems: 'center',
          gap: 8,
          padding: '12px 0',
        }),
        input: css({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          gap: 4,
        }),
      } as const),
    [],
  )
