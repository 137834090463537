import React from 'react'

import { useStyles } from './KeyResultAreaTable.styles'
import { KeyResultAreaTableHeader } from './KeyResultAreaTableHeader'
import {
  KeyResultAreaTableRows,
  Props as KeyResultAreaTableRowsProps,
} from './KeyResultAreaTableRows'

export type Props = KeyResultAreaTableRowsProps

export const KeyResultAreaTable = React.memo<Props>(
  ({
    termId,
    keyResults,
    onClickKeyResultName,
    openKeyResultCreateModal,
    updateKeyResultDisplayOrder,
  }) => {
    const styles = useStyles()

    return (
      <div css={styles.root}>
        <KeyResultAreaTableHeader />
        <KeyResultAreaTableRows
          termId={termId}
          keyResults={keyResults}
          onClickKeyResultName={onClickKeyResultName}
          openKeyResultCreateModal={openKeyResultCreateModal}
          updateKeyResultDisplayOrder={updateKeyResultDisplayOrder}
        />
      </div>
    )
  },
)
KeyResultAreaTable.displayName = 'KeyResultAreaTable'
