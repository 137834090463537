import { useProfiler } from '@sentry/react'
import { Spinner } from 'grommet'
import { useContext, useEffect, useMemo } from 'react'

import { Breadcrumb, BreadcrumbParams } from '../../components/domain/Breadcrumb'
import { PageContent } from '../../components/pageContent'
import { OrganizationDispatchContext, Organization } from '../../contexts/OrganizationContext'
import { useCurrentUser, User } from '../../contexts/UserContext'
import { useTranslation } from '../../i18n'
import { Screen } from '../../lib/screen'
import { useTracking } from '../../lib/tracking'
import { color } from '../../styles/newColors'
import { AuthRouteProps } from '../../types/authRouteProps'
import * as urls from '../../urls'

import { useBulkUpdateNotificationEnabledQuery, OrganizationFragment } from './api/graphql'
import { LanguageSetting } from './components/LanguageSetting'
import { NotificationSetting } from './components/NotificationSetting'
import { PasswordSetting } from './components/PasswordSetting'
import { ProfileSetting } from './components/ProfileSetting'
import { TwoFactorAuthSetting } from './components/TwoFactorAuthSetting'
import { useLanguageSetting } from './hooks/useLanguageSetting'
import { useNotificationSetting } from './hooks/useNotificationSetting'
import { usePasswordSetting } from './hooks/usePasswordSetting'
import { useProfileSetting } from './hooks/useProfileSetting'
import { useTwoFactorAuthSetting } from './hooks/useTwoFactorAuth'
import * as styles from './styles'

export const AccountSettingContainer: React.FC<AuthRouteProps> = ({ onOkrTermLoaded }) => {
  const { t } = useTranslation()
  useTracking(t('ACCOUNT_SETTING_PAGE_TITLE'), Screen.AccountSetting)
  useProfiler('AccountSettingContainer')

  useEffect(() => {
    onOkrTermLoaded(null)
  }, [onOkrTermLoaded])

  const user = useCurrentUser()
  const updateOrganization = useContext(OrganizationDispatchContext)

  const { data: fetchOrganization } = useBulkUpdateNotificationEnabledQuery({
    onCompleted: ({ organization: newOrganization }) => {
      updateOrganization((prev) => ({
        ...(prev as Organization),
        bulkUpdateNotificationEnabled: newOrganization.bulkUpdateNotificationEnabled,
      }))
    },
  })

  const breadcrumbs = useMemo<BreadcrumbParams>(
    () => ({
      url: urls.accountSetting,
      items: [
        {
          breadcrumbName: 'setting',
        },
        {
          breadcrumbName: 'accountSetting',
        },
      ],
    }),
    [],
  )

  return (
    <PageContent
      layout={styles.pageContentLayout}
      contentBackgroundColor={color('background-bk-5')}
    >
      <div css={{ marginBottom: '32px' }}>
        <Breadcrumb breadcrumbs={breadcrumbs} />
      </div>
      {user && fetchOrganization?.organization ? (
        <AccountSetting user={user} organization={fetchOrganization.organization} />
      ) : (
        <div
          css={{
            height: '100%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Spinner size="medium" />
        </div>
      )}
    </PageContent>
  )
}

AccountSettingContainer.displayName = 'AccountSettingContainer'

export type Props = {
  organization: OrganizationFragment
  user: User
}

export const AccountSetting: React.FC<Props> = ({ organization, user }) => {
  const profileSettingProps = useProfileSetting({ user })
  const notificationSettingProps = useNotificationSetting({ organization, user })
  const passwordSettingProps = usePasswordSetting()
  const twoFactorAuthSettingProps = useTwoFactorAuthSetting({ user })
  const languageSettingProps = useLanguageSetting({ user })

  return (
    <div css={styles.accountSettingWrapperCss}>
      <ProfileSetting
        {...profileSettingProps}
        updateProfile={profileSettingProps.handleUpdateProfile}
      />
      <NotificationSetting {...notificationSettingProps} />
      <PasswordSetting {...passwordSettingProps} />
      <TwoFactorAuthSetting {...twoFactorAuthSettingProps} />
      <LanguageSetting {...languageSettingProps} />
    </div>
  )
}

AccountSetting.displayName = 'AccountSetting'
