import { css } from '@emotion/react'
import { TextLink } from '@resily/geisha'
import { forwardRef } from 'react'

import { StyledText } from '../../../../components/ui/StyledText'
import { Trans, useTranslation } from '../../../../i18n'
import { ExternalUrls } from '../../../../urls'

const cssRoot = css({
  paddingTop: 24,
  paddingBottom: 16,
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
})

export const UsersTableTitleSection = forwardRef<HTMLDivElement>((_, ref) => {
  const { t } = useTranslation()

  return (
    <div ref={ref} css={cssRoot}>
      <StyledText size="large" fontStyle="bold">
        {t('OKR_SETTING_STATUS')}
      </StyledText>
      <StyledText
        css={{
          '> a': {
            verticalAlign: 'baseline',
          },
        }}
        size="small"
        fontStyle="regular"
      >
        <Trans
          i18nKey="OKR_SETTING_STATUS_DESCRIPTION"
          components={[
            <TextLink type="primary" onClick={() => window.open(ExternalUrls.ADD_USER)}>
              here
            </TextLink>,
          ]}
        />
      </StyledText>
    </div>
  )
})
