import { CheckinAreaContainer, CheckinAreaContainerProps } from './CheckinArea'
import { KeyResultAreaContainer, KeyResultAreaContainerProps } from './KeyResultArea'
import { useStyles } from './Main.styles'
import { ObjectiveAreaContainer, Props as ObjectiveAreaContainerProps } from './ObjectiveArea'

export type MainComposition = {
  ObjectiveArea: React.VFC<ObjectiveAreaContainerProps>
  KeyResultArea: React.VFC<KeyResultAreaContainerProps>
  CheckinArea: React.VFC<CheckinAreaContainerProps>
}

export const Main: React.FC & MainComposition = ({ children }) => {
  const styles = useStyles()

  return <div css={styles.root}>{children}</div>
}
Main.displayName = 'Main'
Main.ObjectiveArea = ObjectiveAreaContainer
Main.KeyResultArea = KeyResultAreaContainer
Main.CheckinArea = CheckinAreaContainer
