import { FC } from 'react'

import { useTranslation } from '../../../i18n'
import { summaryTitle } from '../../../lib/domain/checkinSummary'
import { color } from '../../../styles/newColors'
import { generateCheckinSummary } from '../../../urls'
import { Link } from '../../ui/Link'
import { ListCard } from '../../ui/ListCard'
import { Popover } from '../../ui/Popover/deprecated'
import { StyledText } from '../../ui/StyledText'

import { CheckinSummaryFragment, OkrNodeFragment } from './graphql'

export type Props = {
  okrNode: OkrNodeFragment
  checkinSummaries: Array<CheckinSummaryFragment>
  onReachEnd: () => void
  drawer?: boolean
}

export const OkrPageCheckinSummaryListCard: FC<Props> = ({
  okrNode,
  checkinSummaries,
  onReachEnd,
  drawer = false,
}) => {
  const { t } = useTranslation()

  const pastSummaries = checkinSummaries.filter(
    ({ status }) => status === 'DONE' || status === 'UNDONE',
  )

  return (
    <ListCard
      header={
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '36px',
          }}
        >
          <StyledText size="xxlarge" color="text-bk-100" weight="bold">
            {t('CHECKIN_SUMMARY')}
          </StyledText>
          <Popover
            icon="help"
            iconCss={{ width: 20, height: 20, verticalAlign: 'middle' }}
            text={t('CHECKIN_SUMMARY_HISTORY_DESCRIPTION')}
            margin={{ top: '2px', left: '5px' }}
            position={{ left: '-100px' }}
          />
        </div>
      }
      table={{
        headers: [
          {
            title: t('CHECKIN_SUMMARY_NAME'),
          },
          {
            title: t('CHECKIN_STATUS'),
            minWidth: 146,
          },
        ],
        data: {
          rows: pastSummaries.map((c) => ({
            key: c.id,
            cells: [
              {
                key: 'title',
                cell: (
                  <Link
                    method="newTab"
                    href={generateCheckinSummary(okrNode.id, c.id)}
                    css={{
                      ':hover': {
                        color: color('resily-orange-100'),
                        textDecoration: 'underline',
                      },
                    }}
                  >
                    <StyledText
                      css={{
                        height: '28px',
                        paddingLeft: 8,
                      }}
                    >
                      {summaryTitle(t, c)}
                    </StyledText>
                  </Link>
                ),
                css: {
                  borderLeft: `4px solid ${color('objective-blue-100')}`,
                },
              },
              {
                key: 'status',
                cell: <StyledText>{t(`CHECKIN_STATUS_${c.status}` as const)}</StyledText>,
                minWidth: 146,
              },
            ],
            css: {
              ':hover': {
                backgroundColor: color('hover-background-bk-5'),
              },
            },
          })),
          maxHeight: 338,
          rowMinHeight: 44,
        },
      }}
      onReachEnd={onReachEnd}
      css={{
        padding: '32px 34px 36px 32px',
      }}
      borderless={drawer}
    />
  )
}

OkrPageCheckinSummaryListCard.displayName = 'OkrPageCheckinSummaryListCard'
