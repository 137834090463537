import { t } from 'i18next'
import { FieldArrayWithId } from 'react-hook-form'

import { componentNames, featureNames, generateTestId } from '../../../../../../../../lib/testId'
import { ProgressNumberInput } from '../../../../../../../ui/ProgressNumberInput'
import { StyledText } from '../../../../../../../ui/StyledText'
import { ProgressNumberInputController } from '../../../../../form/components/ProgressNumberInputController'
import { ProgressRateSettingForm } from '../../../../../form/components/ProgressRateSettingForm'
import { FormValues } from '../../../../../form/formSchema'
import { KeyResultUpdateType } from '../../../../../graphql/graphql'
import { unitCss } from '../../styles'
import { initialSettingsCompleted } from '../../utils'

type ProgressControllerAndFormFieldWrapperProps = {
  index: number
  keyResult: FieldArrayWithId<FormValues, 'keyResults', 'id'>
}

export const ProgressControllerAndFormFieldWrapper: React.FC<
  ProgressControllerAndFormFieldWrapperProps
> = ({ index, keyResult }) => {
  // 初期設定（目標値・初期値・単位の設定）が完了している場合
  if (initialSettingsCompleted(keyResult) || keyResult.updateType === KeyResultUpdateType.Manual) {
    return (
      <>
        <ProgressNumberInputController index={index} valueType="actualValue">
          {(progressNumberInputProps) => (
            <ProgressNumberInput
              data-testid={generateTestId(
                featureNames.checkinModal,
                componentNames.actualValueInput,
              )}
              {...progressNumberInputProps}
            />
          )}
        </ProgressNumberInputController>
        <StyledText size="small" css={unitCss}>
          {keyResult.unit}
        </StyledText>
        <StyledText size="small" color="text-bk-50" lineHeight="26px">
          {t('TARGET_VALUE')}: {keyResult.targetValue} {keyResult.unit}
        </StyledText>
      </>
    )
  }

  // 初期設定（目標値・初期値・単位の設定）が完了していない場合
  return <ProgressRateSettingForm index={index} />
}
