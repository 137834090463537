import { css } from '@emotion/react'

import { color } from '../../../../../../../../styles/newColors'

export const layerCss = css({
  whiteSpace: 'nowrap',
  marginRight: '8px',
  fontSize: 12,
  lineHeight: '20px',
  color: color('text-bk-30'),
})

export const borderCss = css({
  height: '2px',
  width: '100%',
  borderBottom: `1px solid ${color('text-bk-30')}`,
})
