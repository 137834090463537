import { OrgDiagramRef } from 'basicprimitivesreact'
import { RefObject, useEffect } from 'react'

const noop = () => {}

interface Pos {
  top: number
  left: number
  x: number
  y: number
}

export const useDragScroll = (ref: RefObject<OrgDiagramRef>): void => {
  useEffect(() => {
    if (!ref.current || !ref.current.scrollPanelRef.current) {
      return noop
    }
    const { current } = ref.current.scrollPanelRef
    current.style.cursor = 'auto'

    let pos: Pos = { top: 0, left: 0, x: 0, y: 0 }
    const onMouseDown = (e: MouseEvent) => {
      current.style.cursor = 'move'
      current.style.userSelect = 'none'

      pos = {
        left: current.scrollLeft,
        top: current.scrollTop,
        x: e.clientX,
        y: e.clientY,
      }
      current.addEventListener('mousemove', onMouseMove)
      current.addEventListener('mouseup', onMouseUp)
    }

    const onMouseMove = (e: MouseEvent) => {
      const dx = e.clientX - pos.x
      const dy = e.clientY - pos.y

      current.scrollTop = pos.top - dy
      current.scrollLeft = pos.left - dx
    }

    const onMouseUp = () => {
      current.style.cursor = 'auto'
      current.style.removeProperty('user-select')

      current.removeEventListener('mousemove', onMouseMove)
      current.removeEventListener('mouseup', onMouseUp)
    }

    current.addEventListener('mousedown', onMouseDown)
    return () => {
      current.removeEventListener('mousedown', onMouseDown)
      current.removeEventListener('mouseup', onMouseUp)
      current.removeEventListener('mousemove', onMouseMove)
    }
  }, [ref])
}
