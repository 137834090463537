import { Box } from 'grommet'
import * as React from 'react'

import { DeleteModal } from '../../../../components/ui/DeleteModal'
import { Modal } from '../../../../components/ui/Modal'
import { useTranslation } from '../../../../i18n'
import { FormValue, ModalMode } from '../../types'
import { OkrTermForm } from '../OkrTermForm'

import { AdminOkrTermsModalProps } from './types'

export const AdminOkrTermsModal: React.FC<AdminOkrTermsModalProps> = ({
  mode,
  term,
  onClose,
  onChanged,
  terms,
}: AdminOkrTermsModalProps) => {
  const { t } = useTranslation()

  const buttonLabels: Record<ModalMode, string> = {
    edit: t('EDIT_X', { x: t('OKR_TERM') }),
    copy: t('COPY_X', { x: t('OKR_TERM') }),
    create: t('CREATE_OF_X', { x: t('OKR_TERM') }),
    delete: t('DELETE'),
  }

  return (
    <>
      {mode === 'delete' ? (
        <DeleteModal
          isOpened
          deleteItemName={term?.name ?? ''}
          deleteItemNameForBody={t('X_OKR_TERM', { x: term?.name ?? '' })}
          onClickDelete={() => {
            if (onChanged) {
              onChanged({ id: term?.id || '', name: '', startDate: '', endDate: '' })
            }
          }}
          onClickCancel={() => onClose && onClose()}
          onClickOutside={() => onClose && onClose()}
        />
      ) : (
        <Modal
          isOpened={mode !== undefined}
          onClickClose={() => onClose && onClose()}
          onClickOutside={() => onClose && onClose()}
          title={buttonLabels[mode!]}
          css={{
            width: '544px',
            minHeight: '341px',
          }}
        >
          <Box
            width="xlarge"
            gap="medium"
            basis="xlarge"
            css={{
              flexGrow: 0,
              flexShrink: 0,
              width: '100%',
            }}
          >
            {mode && (
              <OkrTermForm
                mode={mode}
                term={term}
                terms={terms}
                onChange={(values: FormValue) => {
                  if (onChanged) {
                    onChanged(values)
                  }
                }}
                onCancel={() => onClose && onClose()}
              />
            )}
          </Box>
        </Modal>
      )}
    </>
  )
}

AdminOkrTermsModal.defaultProps = {
  mode: undefined,
  term: undefined,
  onClose: undefined,
  onChanged: undefined,
}
AdminOkrTermsModal.displayName = 'AdminOkrTermsModal'
