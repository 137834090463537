import { Skeleton } from '@resily/geisha'

import { useStyles } from '../CheckinAreaList/CheckinAreaList.styles'

export const CheckinAreaSkeleton: React.VFC = () => {
  const styles = useStyles()

  return (
    <div css={styles.root}>
      {new Array(9).fill(undefined).map((_, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <Skeleton key={i} text width={111} height={28} />
      ))}
    </div>
  )
}
