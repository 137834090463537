import { Dispatch, SetStateAction } from 'react'
import { useLocalStorage } from 'react-use'

type Ret = [boolean, Dispatch<SetStateAction<boolean | undefined>>]

/**
 * SideNavの開閉状態を保持しているlocalStorageのhooks
 */
export const useSideNavLocked = (initialSideNavLocked: boolean): Ret => {
  const key = 'side-nav-locked'
  const [sideNavLocked, setSideNavLocked] = useLocalStorage<boolean>(key, initialSideNavLocked)

  return [sideNavLocked ?? initialSideNavLocked, setSideNavLocked]
}
