import { Global } from '@emotion/react'
import { useProfiler } from '@sentry/react'
import { Box, Button } from 'grommet'
import { VFC, createRef, useCallback, useLayoutEffect, useMemo, useState } from 'react'

import { useOkrModalStatus } from '../../../components/standalone/OkrModal/hooks/useOkrModalStatus'
import { useTranslation } from '../../../i18n'
import { Node } from '../../../lib/collections/node'
import { ordinal } from '../../../lib/ordinal'
import { Screen } from '../../../lib/screen'
import { useTracking } from '../../../lib/tracking'
import { color } from '../../../styles/newColors'
import { OkrNode } from '../trees/types'
import { OkrCardKrFoldControllerContainer } from '../ui/OkrCardKrFoldController'

import { ListViewPageOkrCard, Props as ListViewPageOkrCardProps } from './ListViewPageOkrCard'

export type Props = Pick<
  ListViewPageOkrCardProps,
  'termId' | 'onOkrFloatDrawerOpen' | 'openOkrModal' | 'openOkrModalWithKeyResultDrawer'
> & {
  okrNodes: ReadonlyArray<Node<OkrNode>>
  onRendered: () => void
}

const OKR_CARD_WIDTH = '1044px'
const HEADER_HEIGHT = 117

type TargetLayer = {
  items: ReadonlyArray<Node<OkrNode>>
  layerNumber: number
  layerName: string
}

export const ListView: VFC<Props> = ({
  termId,
  okrNodes,
  onRendered,
  onOkrFloatDrawerOpen,
  openOkrModal,
  openOkrModalWithKeyResultDrawer,
}) => {
  useProfiler('ListView')
  const { t } = useTranslation()
  const { type: modalType, objectiveId: modalObjectiveId } = useOkrModalStatus()

  useTracking(t('OKR_LIST_PAGE_TITLE'), Screen.OkrMapList)

  // ローディング表示用
  useLayoutEffect(onRendered, [onRendered])

  // 表示形式に応じて変換する
  const targetLayers = useMemo<ReadonlyArray<TargetLayer>>(() => {
    const layerMap = new Map<number, Array<Node<OkrNode>>>()
    okrNodes.forEach((n) => {
      const layerIds = layerMap.get(n.depth)
      if (!layerIds) {
        layerMap.set(n.depth, [n])
      } else if (!layerIds.some((layerId) => layerId.id === n.id)) {
        layerIds.push(n)
      }
    })

    return [...layerMap.entries()]
      .map<TargetLayer>(([depth, layer]) => ({
        items: layer,
        layerNumber: depth,
        layerName: t('X_LAYER', { x: ordinal(depth) }),
      }))
      .sort((a, b) => a.layerNumber - b.layerNumber)
  }, [okrNodes, t])

  const refs = useMemo(
    () => Array.from({ length: targetLayers.length }).map(() => createRef<HTMLLIElement>()),
    [targetLayers.length],
  )

  return (
    <div style={{ width: '100%', position: 'relative', display: 'flex' }}>
      <Global styles={{ main: { backgroundColor: color('background-bk-5') } }} />
      <OkrCardKrFoldControllerContainer
        style={{
          position: 'fixed',
          margin: '18px 16px',
          zIndex: 1, // Avaterが貫通してくる対策としてz-indexを使っている
        }}
      />
      <LayerNavButton refs={refs} targetLayers={targetLayers} />

      <ul
        style={{ minWidth: OKR_CARD_WIDTH, width: '100%', margin: '48px 85px', listStyle: 'none' }}
      >
        {targetLayers
          .filter((target) => target.layerNumber !== 0)
          .map((target, i) => (
            <li ref={refs[i]} key={`card-${target.layerNumber}`}>
              <LayerDiviser>{target.layerName}</LayerDiviser>

              {/* カード */}
              {target.items.map((item) => (
                <ListViewPageOkrCard
                  key={item.id}
                  termId={termId}
                  item={item}
                  isMoveScroll={
                    modalType === 'objective' && modalObjectiveId === item.objective?.id
                  }
                  onOkrFloatDrawerOpen={onOkrFloatDrawerOpen}
                  openOkrModal={openOkrModal}
                  openOkrModalWithKeyResultDrawer={openOkrModalWithKeyResultDrawer}
                />
              ))}
            </li>
          ))}
      </ul>
    </div>
  )
}
ListView.displayName = 'ListView'

type LayerNavButtonProps = {
  refs: ReadonlyArray<React.RefObject<HTMLLIElement>>
  targetLayers: ReadonlyArray<TargetLayer>
}

const LayerNavButton = ({ refs, targetLayers }: LayerNavButtonProps) => {
  useProfiler('LayerNavButton')
  // const history = useHistory()
  const [displayLayer, setDisplayLayer] = useState<number>(0)

  const onScroll = useCallback(() => {
    setDisplayLayer((prev) => {
      const index = refs.findIndex((ref) => {
        const item = ref.current?.getClientRects().item(0)
        if (item === null || item === undefined) return false

        return item.bottom > HEADER_HEIGHT
      })

      return index < 0 ? prev : index
    })
  }, [refs])

  useLayoutEffect(() => {
    document.addEventListener('scroll', onScroll, true)
    return () => document.removeEventListener('scroll', onScroll, true)
  }, [onScroll])

  return (
    <nav
      style={{
        marginTop: '64px',
        paddingLeft: '16px',
        width: '84px',
        position: 'fixed',
      }}
    >
      <ul style={{ listStyle: 'none' }}>
        {targetLayers
          .filter((target) => target.layerNumber !== 0)
          .map((target, i) => (
            <li key={`nav-${target.layerNumber}`}>
              <Button
                data-is-display-layer={displayLayer === i}
                css={{
                  borderRadius: '4px',
                  color: color('text-bk-30'),

                  ':hover': {
                    color: color('resily-orange-100'),
                  },

                  ':hover div::before': {
                    backgroundColor: color('resily-orange-100'),
                  },

                  '&[data-is-display-layer="true"]': {
                    color: color('resily-orange-100'),
                    backgroundColor: color('border-bk-10'),
                  },
                }}
                onClick={() => {
                  const { current } = refs[i]
                  if (!current) return
                  // TODO: nodeIdからtargetLayerまでスクロール
                  // history.replace({ ...history, hash: '#nodeId='+ })
                  current.scrollIntoView({
                    behavior: 'smooth',
                  })
                }}
              >
                <div
                  css={{
                    margin: '6px',
                    fontSize: '16px',
                    lineHeight: '16px',

                    '&::before': {
                      display: 'inline-block',
                      width: '6px',
                      height: '6px',
                      marginRight: '6px',
                      marginLeft: '6px',
                      verticalAlign: 'middle',
                      content: '""',
                      backgroundColor: color('text-bk-30'),
                      borderRadius: '50%',
                    },

                    '&[data-is-display-layer="true"]': {
                      '&::before': { backgroundColor: color('resily-orange-100') },
                    },
                  }}
                  data-is-display-layer={displayLayer === i}
                >
                  {ordinal(target.layerNumber)}
                </div>
              </Button>
            </li>
          ))}
      </ul>
    </nav>
  )
}
LayerNavButton.displayName = 'LayerNavButton'

const LayerDiviser = ({ children }: { children: React.ReactNode }) => (
  <Box direction="row" align="center" css={{ width: '100%' }}>
    <div
      css={{
        whiteSpace: 'nowrap',
        marginTop: '36px',
        marginBottom: '29px',
        marginRight: '23px',
        fontSize: 20,
        lineHeight: '23px',
        color: color('text-bk-30'),
      }}
    >
      {children}
    </div>
    <div css={{ height: '9px', width: '100%', borderBottom: `1px solid ${color('text-bk-30')}` }} />
  </Box>
)
