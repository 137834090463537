import * as Yup from 'yup'

import { TFunction } from '../../i18n/index'

interface Password {
  newPassword: string
  passwordConfirmation: string
}

export const formSchema = (t: TFunction): Yup.SchemaOf<Password> =>
  Yup.object().shape({
    newPassword: Yup.string()
      .notOneOf([Yup.ref('currentPassword')], t('DIFFERENT_FROM_CURRENT'))
      .test('test', t('CONSECTIVE_CHARACTERS'), (value: string | undefined) => {
        if (!value) {
          return false
        }
        const s = value.split('').filter((x, i, self) => self.indexOf(x) === i)
        return s.length !== 1
      })
      .min(8, ({ min }) => t('TOO_SHORT', { min }))
      .required(t('REQUIRED_ERROR')),
    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref('newPassword')], t('SAME_PASSWORD'))
      .required(t('REQUIRED_ERROR')),
  })
