import { FormValues, keyResultsKeyName } from '../../../../form/formSchema'
import { KeyResultUpdateType } from '../../../../graphql/graphql'

type KeyResult = Pick<
  FormValues[typeof keyResultsKeyName][number],
  'targetValue' | 'initialValue' | 'unit' | 'updateType'
>
// 初期設定（目標値・初期値・単位の設定）が完了しているかどうか
export const initialSettingsCompleted = (keyResult: KeyResult): boolean =>
  (keyResult.targetValue != null &&
    keyResult.targetValue !== 0 &&
    keyResult.initialValue != null) ||
  keyResult.unit != null

// パーセント入力かどうか
export const isPercentageInput = (keyResult: KeyResult): boolean =>
  keyResult.updateType === KeyResultUpdateType.Automate
