import { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from 'react'

import { useTranslation } from '../../../i18n/index'
import { getUserSearchText } from '../../../lib/domain/user/searchText'
import { InterpolationWithTheme } from '../../../lib/emotion'
import { border } from '../../../styles/border'
import { color } from '../../../styles/newColors'
import { Avatar } from '../../ui/Avatar'
import { Icon } from '../../ui/Icon'
import { MultiSelect, Option } from '../../ui/MultiSelect'
import { StyledText } from '../../ui/StyledText'
import { TooltipNew } from '../../ui/TooltipNew'
import { UserTag } from '../UserTag'

import { UserFragment } from './graphql'

export type Props = {
  rootCss?: InterpolationWithTheme
  zIndex?: number
  trimCount: number
  setTrimCount: Dispatch<SetStateAction<number>>
  fixTrimCount?: number
  resetTrimCount?: number
  maxWidth?: number
  openMultiSelect: boolean
  onClickOpenMultiSelect: () => void
  onClickOption: (id: string) => void
  users: ReadonlyArray<UserFragment>
  selectedUserIds: ReadonlyArray<string>
  onClear: () => void
}

export const SelectedUsers: React.FC<Props> = ({
  rootCss,
  zIndex,
  trimCount,
  setTrimCount,
  fixTrimCount,
  resetTrimCount,
  maxWidth,
  openMultiSelect,
  onClickOpenMultiSelect,
  onClickOption,
  users,
  selectedUserIds,
  onClear,
}) => {
  const { t } = useTranslation()
  const usersRef = useRef<HTMLDivElement>(null)

  const [optionList, setOptionList] = useState<ReadonlyArray<Option>>([])

  useEffect(() => {
    if (maxWidth === undefined) {
      setTrimCount(0)
    }
  }, [maxWidth, setTrimCount])

  useEffect(() => {
    setTrimCount(0)
  }, [resetTrimCount, setTrimCount])

  useEffect(() => {
    if (!usersRef.current || !maxWidth) return
    if (usersRef.current.scrollWidth > maxWidth && trimCount + 1 < selectedUserIds.length) {
      setTrimCount((prev) => prev + 1)
    }
  }, [usersRef, maxWidth, trimCount, selectedUserIds, setTrimCount])

  useEffect(() => {
    const userOptions = users.map<Option>(
      ({ id, firstName, lastName, email, avatar, isDisabled }) => ({
        id,
        name: t('FULL_NAME', { firstName, lastName }),
        searchText: getUserSearchText({ firstName, lastName, email }),
        selected: selectedUserIds.some((value) => value === id),
        isDisabled,
        icon: (
          <Avatar
            tooltipDisabled
            firstName={firstName}
            lastName={lastName}
            avatarUrl={avatar?.url}
            size="xsmall"
            css={{ marginRight: '8px' }}
            isUserDisabled={isDisabled}
          />
        ),
      }),
    )
    const selectedUserOptions = userOptions.filter((o) => o.selected)
    const unSelectedUserOptions = userOptions.filter((o) => !o.selected)
    // 以下の順番でオプションを表示する
    // 1. 選択されている有効ユーザー
    // 2. 選択されている無効ユーザー
    // 3. 選択されていない有効ユーザー
    // 4. 選択されていない無効ユーザー
    setOptionList([
      ...selectedUserOptions.filter((o) => !o.isDisabled),
      ...selectedUserOptions.filter((o) => o.isDisabled),
      ...unSelectedUserOptions.filter((o) => !o.isDisabled),
      ...unSelectedUserOptions.filter((o) => o.isDisabled),
    ])
  }, [selectedUserIds, setOptionList, t, users])

  const selectedUsers: ReadonlyArray<UserFragment> = useMemo(() => {
    const allSelectedUsers = selectedUserIds
      .map((uid) => users.find(({ id }) => id === uid))
      .filter((u): u is Exclude<typeof u, undefined> => u !== undefined)
    const enabledSelectedUsers = allSelectedUsers.filter((u) => !u.isDisabled)
    const disabledSelectedUsers = allSelectedUsers.filter((u) => u.isDisabled)
    return [...enabledSelectedUsers, ...disabledSelectedUsers]
  }, [selectedUserIds, users])

  return (
    <div css={[{ zIndex }, rootCss]}>
      {selectedUserIds.filter((value) => users.some(({ id }) => value === id)).length === 0 ? (
        <TooltipNew title={t('SELECT_X', { x: t('USER') })}>
          <button
            type="button"
            css={{
              display: 'inline-flex',
              alignItems: 'center',
              border: border('simple-30'),
              borderRadius: '4px',
              padding: '10px 8px',
              height: '40px',
              cursor: 'pointer',
              outline: 'none',
              backgroundColor: openMultiSelect ? color('hover-background-bk-5') : undefined,
              ':hover': {
                backgroundColor: color('hover-background-bk-5'),
              },
            }}
            onClick={onClickOpenMultiSelect}
          >
            <Icon type="user" css={{ width: '14px', height: '14px' }} />
            <StyledText css={{ lineHeight: '14px', marginLeft: '4px', whiteSpace: 'nowrap' }}>
              {t('USER')}
            </StyledText>
            <Icon
              type="selectDown"
              color={color('border-bk-20')}
              css={{
                width: '8px',
                marginLeft: '8px',
                transform: openMultiSelect ? 'rotate(-180deg)' : undefined,
              }}
            />
          </button>
        </TooltipNew>
      ) : (
        <div
          ref={usersRef}
          css={{
            display: 'inline-flex',
            alignItems: 'center',
            maxWidth,
            border: border('simple-30'),
            borderRadius: '4px',
            padding: '10px 8px',
            height: '40px',
            backgroundColor: openMultiSelect ? color('background-bk-5') : undefined,
            ':hover': {
              backgroundColor: color('background-bk-5'),
            },
          }}
        >
          {selectedUsers
            .slice(
              0,
              users.filter((user) => selectedUserIds.includes(user.id)).length -
                (fixTrimCount !== undefined ? fixTrimCount : trimCount),
            )
            .map((user, i) => (
              <div
                key={user.id}
                css={{
                  marginRight: i === selectedUsers.length - 1 ? undefined : '4px',
                  display:
                    selectedUserIds.length -
                      (fixTrimCount !== undefined ? fixTrimCount : trimCount) ===
                    1
                      ? 'block'
                      : undefined,
                  overflowX:
                    selectedUserIds.length -
                      (fixTrimCount !== undefined ? fixTrimCount : trimCount) ===
                    1
                      ? 'hidden'
                      : undefined,
                }}
              >
                <TooltipNew title={t('SELECT_X', { x: t('USER') })}>
                  <button type="button" onClick={onClickOpenMultiSelect}>
                    <UserTag termId={undefined} isUserDisabled={user.isDisabled} {...user} />
                  </button>
                </TooltipNew>
              </div>
            ))}
          {(fixTrimCount !== undefined ? fixTrimCount : trimCount) > 0 && (
            <StyledText size="small" css={{ width: '12px', margin: '4px' }}>
              ...
            </StyledText>
          )}
          <TooltipNew title={t('CLEAR_X', { x: t('USER') })}>
            <div css={{ minWidth: '24px' }}>
              <Icon
                type="clear"
                color={color('border-bk-10')}
                css={{
                  marginLeft: '4px',
                  cursor: 'pointer',
                  ':hover': {
                    color: color('resily-orange-100'),
                  },
                }}
                onClick={onClear}
              />
            </div>
          </TooltipNew>
        </div>
      )}
      {openMultiSelect && (
        <div css={{ position: 'absolute', zIndex: 10 }}>
          <MultiSelect
            canSearch
            options={optionList}
            searchPlaceholder={t('INPUT_X', { x: t('USER_NAME') })}
            onClickOption={onClickOption}
          />
        </div>
      )}
    </div>
  )
}

SelectedUsers.displayName = 'SelectedUsers'
