import { css } from '@emotion/react'
import { useMemo } from 'react'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useStyles = () =>
  useMemo(
    () =>
      ({
        root: css({
          display: 'flex',
          gap: 12,
          padding: '12px 24px',
        }),
        wrapper: css({
          width: '100%',
          padding: '8px 0 4px',
        }),
        title: css({
          display: 'flex',
          flexDirection: 'column',
          gap: 8,
          marginBottom: 12,
        }),
        user: css({
          display: 'flex',
          alignItems: 'center',
        }),
        userName: css({
          margin: '0px 8px 0 4px',
        }),
      } as const),
    [],
  )
