import { Box } from 'grommet'
import React, { useCallback, useContext, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { Breadcrumb, BreadcrumbParams } from '../../components/domain/Breadcrumb'
import { PageContent } from '../../components/pageContent'
import { OrganizationContext } from '../../contexts/OrganizationContext'
import { useSelfServeConfig } from '../../lib/domain/organization/useSelfServeConfig'
import { color } from '../../styles/newColors'
import { AuthRouteProps } from '../../types/authRouteProps'
import { generateAdminUser, usersAdmin } from '../../urls'

import { writeInvitingUserTableFragment } from './api/cache'
import {
  useCreateUserMutation,
  CreateUserMutationVariables,
  useAdminUsersQuery,
} from './api/graphql'
import { AdminUsers } from './components/AdminUsers'
import { UsersProps } from './components/AdminUsers/types'
import { pageBoxCss } from './styles'

export const AdminUsersContainer: React.FC<AuthRouteProps> = () => {
  const organization = useContext(OrganizationContext)
  const { isSelfServe, remainingLicenses } = useSelfServeConfig()
  const [createUserMutation] = useCreateUserMutation()
  const navigate = useNavigate()

  const { data, loading } = useAdminUsersQuery()

  const createUser = useCallback(
    async (val: CreateUserMutationVariables) => {
      if (!organization) return
      await createUserMutation({
        variables: {
          ...val,
          samlEnabled: organization.isSamlEnabled && val.samlEnabled,
        },
        update: (cache, { data: createdUserRes }) => {
          if (!createdUserRes) {
            return
          }

          writeInvitingUserTableFragment(cache, organization.id, createdUserRes.inviteUser)
        },
      })
    },
    [createUserMutation, organization],
  )

  const breadcrumbs = useMemo<BreadcrumbParams>(
    () => ({
      url: usersAdmin,
      items: [{ breadcrumbName: 'setting' }, { breadcrumbName: 'settingUsers' }],
    }),
    [],
  )

  if (!organization) {
    return null
  }

  return (
    <PageContent contentBackgroundColor={color('background-bk-5')}>
      <Box direction="column" css={pageBoxCss}>
        <Breadcrumb breadcrumbs={breadcrumbs} />
        <AdminUsers
          isSamlEnabled={organization.isSamlEnabled}
          usersTableLoading={loading}
          users={data?.organization.users ?? []}
          invitingUsers={data?.organization.invitingUsers ?? []}
          disableUsers={data?.organization.disableUsers ?? []}
          onRowClick={(e: Parameters<UsersProps['onRowClick']>[0]) =>
            navigate(generateAdminUser(e.datum.id))
          }
          handleCreateUser={createUser}
          isSelfServe={isSelfServe}
          remainingLicenses={remainingLicenses}
        />
      </Box>
    </PageContent>
  )
}

AdminUsersContainer.displayName = 'AdminUsersContainer'
