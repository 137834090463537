import { BoxProps } from 'grommet'
import React from 'react'

import { useTranslation } from '../../../i18n'
import { ExternalUrls } from '../../../urls'

import { OkrEmptyState } from './OkrEmptyState'

export const NoGroupBelongingState: React.VFC<BoxProps> = ({ ...props }) => {
  const { t } = useTranslation()
  return (
    <OkrEmptyState
      title={t('NO_GROUP_BELONGING_TITLE')}
      description={t('NO_GROUP_BELONGING_DESCRIPTION')}
      link={{
        text: t('NO_GROUP_BELONGING_LINK_TEXT'),
        url: ExternalUrls.HOW_TO_USE_MAP_FILTERS,
      }}
      {...props}
    />
  )
}

NoGroupBelongingState.displayName = 'NoGroupBelongingState'
