import { File as GraphqlFile } from '../../../graphql'
import { AttachFile } from '../../ui/AttachFile'
import { MediaFile } from '../../ui/MediaFile'

import { useStyles } from './index.styles'

export type Props = {
  attachments: ReadonlyArray<GraphqlFile>
  readOnly: boolean
  onUpdateFile: (targetFile: GraphqlFile) => void
  onClickDeleteAttachment: (targetFile: GraphqlFile) => void
}

export const Attachments: React.VFC<Props> = ({
  attachments,
  readOnly,
  onUpdateFile,
  onClickDeleteAttachment,
}) => {
  const styles = useStyles()

  return (
    <div css={styles.root}>
      <ul css={styles.attachmentsList}>
        {!readOnly && (
          <li key="attachFile">
            <AttachFile onChange={onUpdateFile} />
          </li>
        )}
        {attachments.map((mediaFile) => (
          <li key={mediaFile.url} css={styles.attachment}>
            <MediaFile
              filename={mediaFile.name}
              url={mediaFile.url}
              readOnly={readOnly}
              onClickDelete={() => onClickDeleteAttachment(mediaFile)}
            />
          </li>
        ))}
      </ul>
    </div>
  )
}
Attachments.displayName = 'Attachments'
