import { Text } from 'grommet'
import React from 'react'

import { PageContent } from '../../components/pageContent'
import { useTranslation } from '../../i18n'
import { Screen } from '../../lib/screen'
import { useTracking } from '../../lib/tracking'

export const NotFound: React.FC = () => {
  const { t } = useTranslation()
  useTracking(t('NOT_FOUND_PAGE_TITLE'), Screen.ErrorNotFound)

  return (
    <PageContent
      breadcrumbs={undefined}
      layout={{
        height: '100%',
        align: 'center',
        justify: 'center',
        alignSelf: 'center',
        fill: 'vertical',
      }}
    >
      <Text weight="bold" size="xlarge">
        {t('PAGE_NOT_FOUND')}
      </Text>
    </PageContent>
  )
}

NotFound.displayName = 'NotFound'
