import { useTranslation } from '../../../i18n'
import { DeleteModal } from '../DeleteModal'
import { StyledText } from '../StyledText'

export type Props<T extends 'NOTE' | 'PERSONAL_NOTE'> = {
  isOpened: boolean
  noteType: T
  noteTitle: string
  onClickDelete: () => void
  onClickCancel: () => void
  onClickOutside: () => void
}

export const DeleteNoteModal = <T extends 'NOTE' | 'PERSONAL_NOTE'>({
  isOpened,
  noteType,
  noteTitle,
  onClickDelete,
  onClickCancel,
  onClickOutside,
}: Props<T>): JSX.Element => {
  const { t } = useTranslation()
  return (
    <DeleteModal
      isOpened={isOpened}
      deleteItemName={t(noteType)}
      onClickDelete={onClickDelete}
      onClickCancel={onClickCancel}
      onClickOutside={onClickOutside}
    >
      <StyledText weight="bold" css={{ marginTop: '24px' }}>
        {noteTitle}
      </StyledText>
    </DeleteModal>
  )
}
