import { css } from '@emotion/react'
import { useMemo } from 'react'

import { color } from '../../../styles/newColors'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useStyles = () =>
  useMemo(
    () =>
      ({
        header: css({
          display: 'flex',
          alignItems: 'center',
        }),
        headerLink: css({
          marginLeft: 16,
          display: 'inline-flex',
          alignItems: 'center',
          gap: 4,
          fontWeight: 'initial',
          textDecoration: 'underline',
          color: color('resily-orange-100'),
          ':hover, :visited:hover': {
            color: '#F4602E',
            svg: { color: '#F4602E' },
          },
        }),
        contentInner: css({
          display: 'flex',
          justifyContent: 'space-between',
          height: '100%',
        }),
      } as const),
    [],
  )
