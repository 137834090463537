import { Dispatch, SetStateAction, useContext } from 'react'
import { useLocalStorage } from 'react-use'

import { OrganizationContext } from '../../contexts/OrganizationContext'
import { useCurrentUser } from '../../contexts/UserContext'

type Ret = [number | undefined, Dispatch<SetStateAction<number | undefined>>]

/**
 * OKRツリーの縮尺を保持しているlocalStorageのhooks
 */
export const useOkrTreeScaleConfig = (initialScale: number, okrTermId: string): Ret => {
  const organization = useContext(OrganizationContext)
  const user = useCurrentUser()
  const key = `okr-tree-scale-config-${okrTermId}-${organization?.id}-${user?.id}`
  const [storageScale, setStorageScale] = useLocalStorage<number>(key, initialScale)

  return [storageScale, setStorageScale]
}
