import { css } from '@emotion/react'
import { useMemo } from 'react'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useStyles = () =>
  useMemo(
    () =>
      ({
        contentRoot: css({ display: 'flex', flexDirection: 'column', gap: 24 }),
        krList: css({ display: 'flex', flexDirection: 'column', gap: 16 }),
      } as const),
    [],
  )
