import { NoteCard } from '../../../../domain/NoteCard'
import { useCreateLineColor } from '../../../../domain/NoteCard/hooks/useCreateLineColor'
import { KeyResultNotesFragment } from '../../KeyResultDrawer/Note/graphql'
import { OkrNotesFragment } from '../../RightSection/Note/graphql'

import { CreateNote } from './CreateNote'
import { EmptyView } from './EmptyView'
import { useStyles } from './index.styles'

export type NotesWithCreateProps = {
  loading: boolean
  notes: ReadonlyArray<OkrNotesFragment | KeyResultNotesFragment> | undefined
  onClickCreateNote: React.MouseEventHandler<HTMLButtonElement>
}

export const NotesWithCreate: React.VFC<NotesWithCreateProps> = ({
  loading,
  notes,
  onClickCreateNote,
}) => {
  const styles = useStyles()
  const { createStyleOkrLineColor } = useCreateLineColor()

  if (loading) {
    return (
      <>
        {new Array(3).fill(undefined).map(() => (
          <div>
            <NoteCard.Skeleton />
            <NoteCard.Skeleton lineTitle={2} />
          </div>
        ))}
      </>
    )
  }

  if (notes == null) return null

  if (notes.length === 0) {
    return <EmptyView onClickCreateNote={onClickCreateNote} />
  }

  return (
    <>
      <div css={styles.createButtonArea}>
        <CreateNote onClick={onClickCreateNote} />
      </div>
      {notes.map((note) => (
        <div key={note.id} css={styles.note}>
          <NoteCard
            note={note}
            lineColor={createStyleOkrLineColor(note.resource.__typename)}
            avatarLinkMethod="newTab"
          />
        </div>
      ))}
    </>
  )
}
NotesWithCreate.displayName = 'NotesWithCreate'
