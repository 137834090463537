import { css } from '@emotion/react'

import { border } from '../../../../../../../../../styles/border'

export const rootCss = css({
  display: 'flex',
  padding: '40px 0',
  justifyContent: 'center',
  alignItems: 'center',
  borderTop: border('simple-10'),
  borderBottom: border('simple-10'),
})
