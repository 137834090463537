import { OkrFloatDrawerContextProvider } from '../../../contexts/OkrFloatDrawerContext'

import { SideNav } from './SideNav'

export type Props = JSX.IntrinsicElements['div']

export const MAIN_CONTENT_ID = 'main-content-id'
const mainProps = { id: MAIN_CONTENT_ID }

export const GloNavAndMain: React.FC<Props> = ({ children, ...rest }) => (
  <div {...mainProps} css={{ display: 'flex', position: 'relative' }} {...rest}>
    <SideNav />
    <OkrFloatDrawerContextProvider>{children}</OkrFloatDrawerContextProvider>
  </div>
)
GloNavAndMain.displayName = 'GloNavAndMain'
