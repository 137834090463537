// eslint-disable-next-line import/no-extraneous-dependencies
import { InterpolationWithTheme } from '@emotion/core'
import { Box, Select, Spinner, ThemeContext } from 'grommet'
import React, { HTMLAttributes, ReactNode, useEffect, useMemo, useState } from 'react'

import { useTranslation } from '../../../i18n'
import { filterString } from '../../../lib/array'
import { bySearchTextOrValue } from '../../../lib/domain/user/searchText'
import { color } from '../../../styles/newColors'
import { Icon } from '../Icon'
import { TooltipNew } from '../TooltipNew'

export type SearchSelectOption<TValue = string> = {
  value: TValue
  label: ReactNode
  searchText?: string
}

type Props = {
  label: ReactNode
  size?: 's' | 'm'
}

export const SearchSelectLabel: React.FC<Props> = ({ label, size = 'm' }) => {
  const pad = () => {
    switch (size) {
      case 's':
        return 'xxsmall'
      default:
        return 'xsmall'
    }
  }
  return <Box pad={pad()}>{label}</Box>
}

SearchSelectLabel.displayName = 'SearchSelectLabel'

export const SearchSelectName = {
  default: 'default',
  withIcon: 'withIcon',
} as const
export type SearchSelectNameType = typeof SearchSelectName[keyof typeof SearchSelectName]

export type SearchSelectProps<TValue = string> = {
  options: ReadonlyArray<SearchSelectOption<TValue>>
  value?: TValue
  placeholder?: ReactNode
  onChange?: (value: TValue) => void
  name?: SearchSelectNameType
  search?: boolean
  maxWidth?: string
} & Omit<HTMLAttributes<HTMLDivElement>, 'onChange'>

export const SearchSelect: React.FC<SearchSelectProps<string>> = ({
  options,
  value,
  placeholder,
  onChange,
  name = SearchSelectName.default,
  search = true,
  maxWidth = '300px',
  ...rest
}) => {
  const { t } = useTranslation()
  const currentOption = options.find((o) => o.value === value)
  const [filteredOptions, setFilteredOptions] = useState<ReadonlyArray<SearchSelectOption<string>>>(
    options.filter((o) => o.value !== value),
  )
  const handleOnChange = (v: string | undefined) => {
    setFilteredOptions(options.filter((o) => o.value !== v))
    if (v && onChange) {
      onChange(v)
    }
  }

  const isWithIcon = useMemo(() => name === SearchSelectName.withIcon, [name])

  return (
    <Box
      justify="start"
      width="medium"
      style={{ minWidth: isWithIcon ? '110px' : '200px' }}
      {...rest}
    >
      <ThemeContext.Extend
        value={{
          global: {
            control: {
              border: {
                radius: isWithIcon ? '4px' : '0px',
                color: isWithIcon ? color('border-bk-30') : color('border-bk-10'),
              },
            },
          },
          select: {
            container: {
              extend: {
                padding: '',
                maxWidth,
              },
            },
            icons: {
              down: (
                <Icon
                  type="selectDown"
                  color={color('border-bk-20')}
                  css={{ width: '8px', zIndex: -1 }}
                />
              ),
              up: (
                <Icon
                  type="selectDown"
                  color={color('border-bk-20')}
                  css={{ width: '8px', transform: 'rotate(-180deg)', zIndex: -1 }}
                />
              ),
            },
          },
        }}
      >
        <Select
          options={filteredOptions.map((o) => o.value)}
          value={value}
          valueLabel={
            currentOption ? (
              <Box pad="small" margin={isWithIcon ? 'xxsmall' : 'xsmall'}>
                {currentOption.label}
              </Box>
            ) : null
          }
          placeholder={
            <Box margin="none" pad="none">
              {placeholder}
            </Box>
          }
          onChange={(o) => handleOnChange(o.value)}
          searchPlaceholder={`${t('SEARCH')}...`}
          emptySearchMessage={t('NOT_APPLICABLE')}
          onOpen={() => {
            // filteredOptionsをリセット
            handleOnChange(value)
          }}
          onSearch={
            search
              ? (query) => {
                  setFilteredOptions(
                    filterString<SearchSelectOption<string>>(
                      query,
                      options.filter((o) => o.value !== value),
                      bySearchTextOrValue,
                    ),
                  )
                }
              : undefined
          }
        >
          {(option) => {
            const op = options.find((o) => o.value === option)
            return <Box pad="small">{op?.label ?? option}</Box>
          }}
        </Select>
      </ThemeContext.Extend>
    </Box>
  )
}

SearchSelect.displayName = 'SearchSelect'

export type NewSearchSelectProps<TValue = string> = {
  options: ReadonlyArray<SearchSelectOption<TValue>>
  isLoading?: boolean
  value?: TValue
  placeholder?: ReactNode
  onChange?: (value: TValue) => void
  onOpen?: () => void
  name?: string
  search?: boolean
  maxWidth?: string
  clear?: boolean
  addRightMargin?: boolean
  placeholderCss?: InterpolationWithTheme<unknown>
} & Omit<HTMLAttributes<HTMLDivElement>, 'onChange'>

export const NewSearchSelect: React.FC<NewSearchSelectProps<string>> = ({
  options,
  isLoading = false,
  value,
  placeholder,
  onChange,
  onOpen,
  search = true,
  maxWidth = '300px',
  clear = false,
  addRightMargin,
  placeholderCss,
  ...rest
}) => {
  const { t } = useTranslation()
  const currentOption = options.find((o) => o.value === value)
  const [filteredOptions, setFilteredOptions] = useState<ReadonlyArray<SearchSelectOption<string>>>(
    options.filter((o) => o.value !== value),
  )

  useEffect(() => {
    setFilteredOptions(options.filter((o) => o.value !== value))
  }, [value, options])

  const handleOnChange = (v: string | undefined) => {
    setFilteredOptions(options.filter((o) => o.value !== v))
    const newValue = v ?? ''
    if (onChange) {
      onChange(newValue)
    }
  }

  const loadingElement = [
    <div
      style={{
        display: 'flex',
        padding: '10px 0',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '4px',
      }}
    >
      <Spinner />
    </div>,
  ]

  return (
    <Box justify="start" width={{ width: 'medium', min: '210px' }} {...rest}>
      <ThemeContext.Extend
        value={{
          global: {
            control: {
              border: {
                radius: '4px',
                color: color('border-bk-10'),
              },
            },
          },
          select: {
            container: {
              extend: {
                maxHeight: '332px',
                maxWidth,
              },
            },
            icons: {
              margin: addRightMargin ? { right: '17px' } : undefined,
              down: <Icon type="selectDown" width={8} height={8} color={color('border-bk-20')} />,
              up: (
                <Icon
                  type="selectDown"
                  width={8}
                  height={8}
                  color={color('border-bk-20')}
                  css={{ transform: 'rotate(-180deg)' }}
                />
              ),
            },
          },
        }}
      >
        <Select
          options={isLoading ? loadingElement : filteredOptions.map((o) => o.value)}
          value={value}
          valueLabel={
            currentOption ? (
              <Box
                className="NewSearchSelect-ValueLabel"
                direction="row"
                align="center"
                width="100%"
                justify="between"
                pad="9px 5px 9px 16px"
              >
                {currentOption.label}
                {clear && (
                  <TooltipNew title={t('CLEAR_X', { x: t('JOINTED_PARENT_OKR') })}>
                    <Icon
                      type="clear"
                      color={color('border-bk-50')}
                      css={{
                        ':hover': { color: color('resily-orange-100') },
                      }}
                      onClick={(e) => {
                        e.stopPropagation()
                        handleOnChange(undefined)
                      }}
                    />
                  </TooltipNew>
                )}
              </Box>
            ) : null
          }
          placeholder={
            <Box margin="none" pad="none" css={placeholderCss}>
              {placeholder}
            </Box>
          }
          onChange={(o) => handleOnChange(o.value)}
          searchPlaceholder={`${t('SEARCH')}...`}
          emptySearchMessage={t('NOT_APPLICABLE')}
          onOpen={onOpen}
          onSearch={
            search
              ? (query) => {
                  setFilteredOptions(
                    filterString<SearchSelectOption<string>>(
                      query,
                      options.filter((o) => o.value !== value),
                      bySearchTextOrValue,
                    ),
                  )
                }
              : undefined
          }
        >
          {(option) => {
            const op = options.find((o) => o.value === option)
            return (
              <Box justify="center" pad="xsmall" css={{ minHeight: '40px' }}>
                {op?.label ?? option}
              </Box>
            )
          }}
        </Select>
      </ThemeContext.Extend>
    </Box>
  )
}

NewSearchSelect.displayName = 'NewSearchSelect'
