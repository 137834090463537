import { ConfidenceLevel } from '@resily/geisha'

import { Level } from '../domain/confidence'

/**
 * geishaの自信度に関するコンポーネントは新しい自信度の数値で作成しているため、古い自信度の数値から新しい数値の自信度に変換します
 * @param confidenceLevel
 * @returns
 */
export const old2NewConfidence = (confidenceLevel?: number | null): ConfidenceLevel | null => {
  if (confidenceLevel == null) return null
  if (confidenceLevel > 2) throw new Error('confidenceLevel is too large')
  if (confidenceLevel < -2) throw new Error('confidenceLevel is too small')
  return (confidenceLevel + 3) as ConfidenceLevel
}

export const newToOldConfidence = (confidenceLevel?: ConfidenceLevel): Level | null => {
  if (confidenceLevel == null) return null
  return (confidenceLevel - 3) as Level
}
