const orange = '#DC440A'
const lightOrange = '#ffdfce'
const white = '#fff'
const lightGray = '#f0f0f0'
const gray = '#dedfe0'
const darkGray = '#7c868f'
const lightBlack = '#666666'
const black = '#222943'
const red = '#DB2828'
const paleRed = '#FF8C8C'
const yellow = '#ffff00'
const paleYellow = '#FFDC75'
const paleGreen = '#91DB55'
const blue = '#327aec'
const lightBlue = '#e6eff4'
const paleBlue = '#59A5DB'
const transparentBlack = `rgba(0, 0, 0, 0.8)`

export type RGB = [number, number, number]
export type RGBA = [number, number, number, number]

export const hex2rgb = (hex: string): RGB => {
  const h = hex.replace(
    /^#?([0-9a-f])([0-9a-f])([0-9a-f])$/i,
    (_, r, g, b) => `${r}${r}${g}${g}${b}${b}`,
  )
  const res = h.match(/^#?([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})$/i)
  if (res == null) {
    throw new Error(`hex isn't valid: ${hex}`)
  }
  return [parseInt(res[1], 16), parseInt(res[2], 16), parseInt(res[3], 16)]
}
export const rgb2prop = ([r, g, b]: RGB): string =>
  `rgb(${r.toString(10)}, ${g.toString(10)}, ${b.toString(10)})`
export const rgba2prop = ([r, g, b, a]: RGBA): string =>
  `rgba(${r.toString(10)}, ${g.toString(10)}, ${b.toString(10)}, ${a.toString(10)})`
export const rgb2rgba = ([r, g, b]: RGB, a: number): RGBA => [r, g, b, a]
export const alpha = (hex: string, a: number): string => rgba2prop(rgb2rgba(hex2rgb(hex), a))

// TODO カラーパレット整理時に命名変更する
const gageGray = '#e6e6e6'
const buttonGray = '#373737'

export const headerFill = white
export const baseFill = white
export const badgeFill = darkGray
export const main = orange
export const background = lightGray
export const textMain = black
export const textLink = black
export const textLinkHover = lightBlack
export const textSub = darkGray
export const textTableHeader = '#707070'
export const border = gray
export const borderLight = '#e6e7e9'
export const choosingTab = black
export const gage = gageGray
export const negative = red
export const gradientMain = 'linear-gradient(90deg, #DC440A 0%, #ffb74a 100%)'
export const gradientSecond = 'linear-gradient(90deg, #5f83c9 0%, #5fb6c9 100%)'

export const focusedBackground = darkGray

export const inactive = darkGray
export const inactiveFill = alpha(buttonGray, 0.1)
export const inactiveText = white

export const buttonFillMain = buttonGray
export const buttonFillSub = alpha(buttonGray, 0.2)
export const buttonFillWarning = red
export const buttonTextMain = white
export const buttonTextSub = buttonGray
export const buttonTextWarning = white

export const avatarBorder = darkGray

export const iconButtonFill = white
export const iconButtonColor = darkGray
export const circularBackground = lightOrange

export const tooltipText = white
export const tooltipFill = transparentBlack

export const tagText = white
export const tagFill = darkGray

export const sliderBackground = white

export const selectedColor = paleBlue
export const selectedText = white

export const success = paleGreen
export const warning = paleYellow
export const error = paleRed

export const tabsBaseline = gray
export const buttonsBorder = gray

export const toolBarBackground = '#323845'
export const toolBarActiveColor = yellow
export const mentionShadow = '#b4d5ff'
export const mentionFontColor = '#2c649e'
export const mentionBackground = lightBlue
export const mentionSelected = blue
export const editorBoxShadow = 'rgba(0,0,0,0.33)'
export const editorActiveBoxShadow = '#b4d5ff'
export const snapShotBackGround = '#fafafa'
export const meterBackGround = lightOrange

export const hoveredTableRowBackground = 'rgba(0, 0, 0, 0.05)'

export const labelRed = '#D42922'
export const labelPaleRed = '#DC7B77'
export const labelOrange = '#F4B232'
export const labelYellow = '#F0D235'
export const labelGreen = '#29976F'
export const labelYellowGreen = '#32B988'
export const labelBlue = '#395EBF'
export const labelSkyBlue = '#3BABEA'
export const labelPurple = '#743DAB'
export const labelPalePurple = '#947ED2'
export const labelGray = '#8B8B8B'
export const labelLightGray = '#C0C0C0'
export const LabelColors = [
  labelRed,
  labelPaleRed,
  labelOrange,
  labelYellow,
  labelGreen,
  labelYellowGreen,
  labelBlue,
  labelSkyBlue,
  labelPurple,
  labelPalePurple,
  labelGray,
  labelLightGray,
]
