import { css } from '@emotion/react'
import { useMemo } from 'react'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useStyles = () =>
  useMemo(
    () =>
      ({
        form: css({
          display: 'flex',
          gap: '24px',
        }),
        label: css({
          width: '108px',
        }),
      } as const),
    [],
  )
