import {
  BalloonToolbarProps,
  collapseSelection,
  EditorId,
  ELEMENT_BLOCKQUOTE,
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_H4,
  ELEMENT_H5,
  ELEMENT_H6,
  ELEMENT_LINK,
  ELEMENT_OL,
  ELEMENT_TODO_LI,
  ELEMENT_UL,
  flip,
  focusEditor,
  getAboveNode,
  getLeafNode,
  getPluginType,
  hide,
  insertNodes,
  isCollapsed,
  MARK_BOLD,
  MARK_CODE,
  MARK_HIGHLIGHT,
  MARK_ITALIC,
  MARK_STRIKETHROUGH,
  MARK_UNDERLINE,
  offset,
  PlateEditor,
  PortalBody,
  select,
  shift,
  unwrapNodes,
  useFloatingToolbar,
  usePlateEditorState,
  withPlateProvider,
  wrapLink,
} from '@udecode/plate'
import { Box, Drop, TextInput, ThemeContext } from 'grommet'
import isUrl from 'is-url'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Range } from 'slate'

import { useTranslation } from '../../../../../../i18n'
import { getModalDialog, getModalUnderlay } from '../../../../../../lib/geisha/modal'
import { getScrollParent } from '../../../../../../lib/scroll'
import { Icon } from '../../../../Icon'
import { assertIsEditor as assertIsSlateEditor } from '../../SlateEditor/utils/types'
import { LinkElement } from '../../types'

import { ToolbarElement } from './ToolbarElement'
import { ToolbarLink } from './ToolbarLink'
import { ToolbarMark } from './ToolbarMark'
import { ErrorBoundary } from './error'

type Props = Omit<BalloonToolbarProps, 'children'> & {
  id: EditorId
}

// Plate(v10.0.0時点)のuseBalloonToolbarPopperがエラーを吐くケースがあるためErrorBoundaryで包むようにしている
// Cannot find a descendant at path ~~~~のエラーでクラッシュする
const UnstableBalloonToolbar = withPlateProvider(({ id }: Props) => {
  const { t } = useTranslation()

  const editor = usePlateEditorState(id != null ? id : undefined)
  assertIsSlateEditor(editor)
  const { floating, update, open, refs, style } = useFloatingToolbar({
    floatingOptions: {
      strategy: 'fixed',
      placement: 'top',
      middleware: [offset(8), flip(), shift(), hide()],
    },
  })

  useEffect(() => {
    if (!editor.selection || isCollapsed(editor.selection)) {
      // 範囲選択時しか表示しない
      return
    }

    const domSelection = window.getSelection()
    if (!domSelection || domSelection.rangeCount < 1) {
      return
    }

    const sp = getScrollParent(domSelection.getRangeAt(0).commonAncestorContainer)
    sp?.addEventListener('scroll', update)
    // eslint-disable-next-line consistent-return
    return () => sp?.removeEventListener('scroll', update)
  }, [editor.selection, update])

  // geishaモーダル上のエディタで、モーダル外にはみ出た状態のLINKボタンを押すとモーダルが閉じてしまう対策として、
  // InputURLを表示する瞬間はモーダルの範囲外クリックを無効にする
  useEffect(() => {
    // https://github.com/adobe/react-spectrum/blob/06e63d3ae7898b8cd81fdb6fa87b763faf2054bf/packages/%40react-aria/interactions/src/useInteractOutside.ts#L62
    const onPointerDown = (e: Event) => {
      if (!inputURLShowFirst.current) {
        return
      }

      const underlay = getModalUnderlay()
      const modalDialog = getModalDialog()
      if (!underlay || !modalDialog || !e.target) {
        return
      }

      const isTargetEvent =
        underlay.contains(e.target as Node) && !modalDialog.contains(e.target as Node)
      if (isTargetEvent) {
        e.preventDefault()
        e.stopPropagation()
        inputURLShowFirst.current = false
      }
    }

    if (typeof PointerEvent !== 'undefined') {
      window.addEventListener('pointerdown', onPointerDown, true)
      window.addEventListener('pointerup', onPointerDown, true)

      // eslint-disable-next-line consistent-return
      return () => {
        window.removeEventListener('pointerdown', onPointerDown, true)
        window.removeEventListener('pointerup', onPointerDown, true)
      }
    }

    window.addEventListener('mousedown', onPointerDown, true)
    window.addEventListener('mouseup', onPointerDown, true)
    window.addEventListener('touchstart', onPointerDown, true)
    window.addEventListener('touchend', onPointerDown, true)

    // eslint-disable-next-line consistent-return
    return () => {
      window.removeEventListener('mousedown', onPointerDown, true)
      window.removeEventListener('mouseup', onPointerDown, true)
      window.removeEventListener('touchstart', onPointerDown, true)
      window.removeEventListener('touchend', onPointerDown, true)
    }
  })

  const [inputURLShow, setInputURLShow] = useState<boolean>(false)
  const inputURLShowFirst = useRef(false)
  const showInputURL = useCallback(() => {
    inputURLShowFirst.current = true
    setInputURLShow(true)
  }, [])
  const hideInputURL = useCallback(() => {
    inputURLShowFirst.current = false
    setInputURLShow(false)
  }, [])

  const inputURLElement = useMemo(() => {
    if (!refs.floating.current || !inputURLShow) return null
    return (
      <ThemeContext.Extend value={{ global: { drop: { zIndex: 101 } } }}>
        <Drop
          align={{ top: 'bottom' }}
          target={refs.floating.current}
          onClickOutside={hideInputURL}
          plain
        >
          <InputURL editor={editor} onClose={hideInputURL} />
        </Drop>
      </ThemeContext.Extend>
    )
  }, [refs, inputURLShow, editor, hideInputURL])

  return (
    <PortalBody>
      {open && (
        <>
          {/* Google翻訳の拡張機能を入れてるとポップアップが重なって邪魔なので隠す */}
          <style>{'#gtx-trans { display: none; }'}</style>
          <div
            ref={floating}
            className="editor-balloon-toolbar"
            css={{
              zIndex: 101,
              background: 'rgb(63 68 71)',
              color: 'rgb(255 255 255 / 90%)',
              borderColor: 'transparent',
              borderRadius: 3,
              borderWidth: 1,
              boxShadow:
                'rgb(15 15 15 / 10%) 0px 0px 0px 1px, rgb(15 15 15 / 20%) 0px 3px 6px, rgb(15 15 15 / 40%) 0px 9px 24px',
            }}
            style={style}
          >
            <Box direction="row" pad="none" margin="none" height="32px">
              <Box
                direction="row"
                border={{ color: 'rgb(119, 125, 129)', side: 'right', size: 'thin' }}
              >
                <ToolbarElement type={ELEMENT_H1} tooltipMessage={t('H1')}>
                  <Icon type="h1" />
                </ToolbarElement>
                <ToolbarElement type={ELEMENT_H2} tooltipMessage={t('H2')}>
                  <Icon type="h2" />
                </ToolbarElement>
                <ToolbarElement type={ELEMENT_H3} tooltipMessage={t('H3')}>
                  <Icon type="h3" />
                </ToolbarElement>
                <ToolbarElement type={ELEMENT_H4} tooltipMessage={t('H4')}>
                  <Icon type="h4" />
                </ToolbarElement>
                <ToolbarElement type={ELEMENT_H5} tooltipMessage={t('H5')}>
                  <Icon type="h5" />
                </ToolbarElement>
                <ToolbarElement type={ELEMENT_H6} tooltipMessage={t('H6')}>
                  <Icon type="h6" />
                </ToolbarElement>
                <ToolbarElement type={ELEMENT_OL} tooltipMessage={t('OL')}>
                  <Icon type="numberedList" />
                </ToolbarElement>
                <ToolbarElement type={ELEMENT_UL} tooltipMessage={t('UL')}>
                  <Icon type="bulletedList" />
                </ToolbarElement>
                <ToolbarElement type={ELEMENT_TODO_LI} tooltipMessage={t('CHECKBOX')}>
                  <Icon type="checkbox" />
                </ToolbarElement>
                <ToolbarElement type={ELEMENT_BLOCKQUOTE} tooltipMessage={t('BLOCK_QUOTE')}>
                  <Icon type="blockQuote" />
                </ToolbarElement>
              </Box>
              <Box direction="row">
                <ToolbarMark type={MARK_BOLD} tooltipMessage={t('BOLD')}>
                  <Icon type="bold" />
                </ToolbarMark>
                <ToolbarMark type={MARK_ITALIC} tooltipMessage={t('ITALIC')}>
                  <Icon type="italic" />
                </ToolbarMark>
                <ToolbarMark type={MARK_STRIKETHROUGH} tooltipMessage={t('STRIKE_THROUGH')}>
                  <Icon type="strikeThrough" />
                </ToolbarMark>
                <ToolbarMark type={MARK_UNDERLINE} tooltipMessage={t('UNDERLINE')}>
                  <Icon type="underline" />
                </ToolbarMark>
                <ToolbarMark type={MARK_HIGHLIGHT} tooltipMessage={t('HIGHLIGHT')}>
                  <Icon type="highlight" />
                </ToolbarMark>
                <ToolbarMark type={MARK_CODE} tooltipMessage={t('CODE_INLINE')}>
                  <Icon type="codeInline" />
                </ToolbarMark>
                <ToolbarLink tooltipMessage={t('LINK')} onActivate={showInputURL}>
                  <Icon type="link" />
                </ToolbarLink>
              </Box>
            </Box>
          </div>
        </>
      )}
      {inputURLElement}
    </PortalBody>
  )
})

export const BalloonToolbar: React.VFC<Props> = (props) => (
  <ErrorBoundary>
    <UnstableBalloonToolbar {...props} />
  </ErrorBoundary>
)

type InputURLProps = {
  editor: PlateEditor
  onClose: () => void
}

export const InputURL: React.FC<InputURLProps> = ({ editor, onClose }) => {
  const { t } = useTranslation()
  const ref = useRef<HTMLInputElement>(null)

  // NOTE: TextInputにFocusが移るとselectionが無になる対策として、
  //       初回レンダリングからfocusされるまでの間にselectionを保持しておく
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const renderingTimeRange = useMemo(() => editor.selection, [])

  useEffect(() => {
    if (ref.current) {
      ref.current.focus()
    }
  }, [])

  return (
    <Box
      css={{
        background: 'rgb(63 68 71)',
        color: 'rgb(255 255 255 / 90%)',
        borderRadius: 3,
        borderWidth: 1,
        marginLeft: '8px',
      }}
    >
      <TextInput
        ref={ref}
        plain
        placeholder={t('JOIN', { a: t('INPUT'), b: t('URL') })}
        onKeyUp={(event) => {
          const url = event.currentTarget.value
          if (event.key === 'Enter' && isUrl(url) && renderingTimeRange) {
            upsertLinkAtSelection(editor, renderingTimeRange, url)

            onClose()

            if (editor.selection) {
              focusEditor(editor)
            }
          }
        }}
      />
    </Box>
  )
}

/**
 * plateのupsertLinkAtSelectionのeditor.selectionを受け取る版
 * @see https://github.com/udecode/plate/blob/main/packages/elements/link/src/transforms/upsertLinkAtSelection.ts
 */
const upsertLinkAtSelection = (editor: PlateEditor, selection: Range, url: string) => {
  const type = getPluginType(editor, ELEMENT_LINK)
  const includesLinkNodeInSelection = !!getAboveNode(editor, { match: { type } })
  const wrap = includesLinkNodeInSelection && isCollapsed(selection)

  if (!wrap && isCollapsed(selection)) {
    insertNodes<LinkElement>(editor, {
      type: type as typeof ELEMENT_LINK,
      url,
      children: [{ text: url }],
    })
    return
  }

  // if our cursor is inside an existing link, but don't have the text selected, select it now
  if (wrap && isCollapsed(selection)) {
    const linkLeaf = getLeafNode(editor, selection)
    const [, inlinePath] = linkLeaf
    select(editor, inlinePath)
  }

  unwrapNodes(editor, { at: selection, match: { type } })
  wrapLink(editor, { at: selection, url })
  collapseSelection(editor, { edge: 'end' })
}
