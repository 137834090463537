import { useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import { BreadcrumbParams } from '../../components/domain/Breadcrumb'
import { useCurrentUser } from '../../contexts/UserContext'
import { useTranslation } from '../../i18n'
import { useOkrTermLoadable } from '../../lib/domain/useOkrTermLoadable'
import { convertToFileInput } from '../../lib/fileInput'
import { usePrompt } from '../../lib/prompt'
import { AuthRouteProps } from '../../types/authRouteProps'
import { generateNoteEdit, generateOkr, makeDrawerSearchParams, note as notePath } from '../../urls'

import {
  CommentItemNoteFragment,
  File,
  FindNoteByIdDocument,
  FindNoteByIdQuery,
  FindNoteByIdQueryVariables,
  NoteDetailFragment,
  NoteDetailKeyResultFragment,
  NoteDetailObjectiveFragment,
  useCopyNoteMutation,
  useCreateCommentMutation,
  useCreateNoteCommentReactionMutation,
  useCreateNoteReactionMutation,
  useDeleteCommentMutation,
  useDeleteNoteCommentReactionMutation,
  useDeleteNoteMutation,
  useDeleteNoteReactionMutation,
  useFindNoteByIdQuery,
  useUpdateCommentMutation,
} from './graphql'

import type { Props } from './index'

type Resource = NoteDetailObjectiveFragment | NoteDetailKeyResultFragment

const isObjective = (resource: Resource): resource is NoteDetailObjectiveFragment =>
  resource.__typename === 'Objective'
const isKeyResult = (resource: Resource): resource is NoteDetailKeyResultFragment =>
  resource.__typename === 'KeyResult'

const generateBreadCrumbs = (note: NoteDetailFragment): BreadcrumbParams =>
  isObjective(note.resource)
    ? {
        url: notePath,
        items: [
          {
            breadcrumbName: 'okr',
            ...note.resource.node,
          },
          {
            breadcrumbName: 'notesOfObjective',
            id: note.resource.id,
            name: note.resource.name,
            node: {
              ...note.resource.node,
            },
          },
          {
            breadcrumbName: 'note',
            ...note,
          },
        ],
      }
    : {
        url: notePath,
        items: [
          {
            breadcrumbName: 'okr',
            ...note.resource.node,
          },
          {
            breadcrumbName: 'keyResult',
            id: note.resource.id,
            name: note.resource.name,
            node: {
              ...note.resource.node,
            },
          },
          {
            breadcrumbName: 'notesOfKeyResult',
            id: note.resource.id,
            name: note.resource.name,
            node: {
              ...note.resource.node,
            },
          },
          {
            breadcrumbName: 'note',
            ...note,
          },
        ],
      }

export const useInjection = ({ onOkrTermLoaded }: AuthRouteProps): null | Props => {
  const { noteId } = useParams()
  const user = useCurrentUser()

  const { t } = useTranslation()
  const navigate = useNavigate()
  const res = useFindNoteByIdQuery({
    variables: { id: noteId ?? '' },
    // NOTE: 古い情報が表示されてしまうためキャッシュを無効にする
    fetchPolicy: 'network-only',
    skip: noteId == null,
  })
  const [deleteNote] = useDeleteNoteMutation()
  const [copyNote] = useCopyNoteMutation()
  const [createComment] = useCreateCommentMutation()
  const [updateComment] = useUpdateCommentMutation()
  const [deleteComment] = useDeleteCommentMutation()
  const [deleteNoteReaction] = useDeleteNoteReactionMutation()
  const [createNoteReaction] = useCreateNoteReactionMutation()
  const [deleteNoteCommentReaction] = useDeleteNoteCommentReactionMutation()
  const [createNoteCommentReaction] = useCreateNoteCommentReactionMutation()

  useOkrTermLoadable(onOkrTermLoaded, undefined, res.data?.findNoteById.okrTerm.id)

  const originPath = window.location.pathname + window.location.search + window.location.hash
  const [, setNeedBlock] = usePrompt({ message: t('LEAVE_WARNING'), originPath })
  const [shouldExpireCache, setShouldExpireCache] = useState(true)
  const [noteUserVisibility, setNoteUserVisibility] = useState(false)

  if (!user) {
    return null
  }

  if (!res.data) {
    return null
  }

  const note = res.data.findNoteById

  const objective = ((r: Resource) => {
    if (isObjective(r)) return r
    if (isKeyResult(r)) return r.node.objective ?? null
    return null
  })(note.resource)

  if (objective == null) {
    return null
  }

  const createCommentMutation = async (
    parentNoteId: string,
    body: string,
    plainText: string,
    attachments: ReadonlyArray<File>,
  ) => {
    await createComment({
      variables: {
        noteId: parentNoteId,
        body: { treeJson: body, plainText },
        attachments: attachments.map((attachment) => convertToFileInput(attachment)),
        mentionedUsers: [],
      },
    })
  }

  const deleteNoteMutation = async (id: string) => {
    if (!res.data) return
    await deleteNote({ variables: { id } })

    const url = `${generateOkr(note.resource.node.id)}?${makeDrawerSearchParams(
      isObjective(note.resource) ? 'objective' : 'keyResult',
      'note',
      note.resource.id,
    )}`

    navigate(url)
  }

  const copyNoteMutation = async (id: string, title: string) => {
    if (!res.data) return

    const { data } = await copyNote({ variables: { id, title } })
    if (!data) return

    navigate(generateNoteEdit(data.copyNote.id))
  }

  const updateCommentMutation = async (comment: CommentItemNoteFragment) => {
    await updateComment({
      variables: {
        id: comment.id,
        body: { treeJson: comment.body?.treeJson || '', plainText: comment.body.plainText || '' },
        attachments: comment.attachments.map((attachment) => convertToFileInput(attachment)),
        mentionedUsers: [],
      },
    }).catch(() => {})
  }
  const deleteCommentMutation = async (id: string) => {
    await deleteComment({
      variables: {
        id,
      },
      update(cache) {
        const option = { query: FindNoteByIdDocument, variables: { id: noteId ?? '' } }
        const { findNoteById } =
          cache.readQuery<FindNoteByIdQuery, FindNoteByIdQueryVariables>(option) || {}
        if (!findNoteById) {
          return
        }

        cache.writeQuery<FindNoteByIdQuery, FindNoteByIdQueryVariables>({
          ...option,
          data: {
            findNoteById: {
              ...findNoteById,
              comments: findNoteById.comments.filter((e: CommentItemNoteFragment) => e.id !== id),
            },
          },
        })
      },
    }).catch(() => {})
  }
  const deleteNoteReactionMutation = async (id: string) => {
    await deleteNoteReaction({
      variables: { id },
      update(cache) {
        const option = { query: FindNoteByIdDocument, variables: { id: noteId ?? '' } }
        const { findNoteById } =
          cache.readQuery<FindNoteByIdQuery, FindNoteByIdQueryVariables>(option) || {}
        if (!findNoteById) {
          return
        }

        cache.writeQuery<FindNoteByIdQuery, FindNoteByIdQueryVariables>({
          ...option,
          data: {
            findNoteById: {
              ...findNoteById,
              reactions: findNoteById.reactions.filter((e) => e.id !== id),
            },
          },
        })
      },
    }).catch(() => {})
  }

  const createNoteReactionMutation = async (emoji: string) => {
    await createNoteReaction({
      variables: { noteId: noteId ?? '', emoji },
      update(cache, { data }) {
        if (!data) {
          return
        }

        const option = { query: FindNoteByIdDocument, variables: { id: noteId ?? '' } }
        const { findNoteById } =
          cache.readQuery<FindNoteByIdQuery, FindNoteByIdQueryVariables>(option) || {}
        if (!findNoteById) {
          return
        }

        if (findNoteById.reactions.some((e) => e.emoji === emoji && e.user?.id === user?.id)) return

        cache.writeQuery<FindNoteByIdQuery, FindNoteByIdQueryVariables>({
          ...option,
          data: {
            findNoteById: {
              ...findNoteById,
              reactions: [...findNoteById.reactions, data.createNoteReaction],
            },
          },
        })
      },
    }).catch(() => {})
  }

  const deleteNoteCommentReactionMutation = async (noteCommentId: string, id: string) => {
    await deleteNoteCommentReaction({
      variables: { id },
      update(cache) {
        const option = { query: FindNoteByIdDocument, variables: { id: noteId ?? '' } }
        const { findNoteById } =
          cache.readQuery<FindNoteByIdQuery, FindNoteByIdQueryVariables>(option) || {}
        if (!findNoteById) {
          return
        }

        cache.writeQuery<FindNoteByIdQuery, FindNoteByIdQueryVariables>({
          ...option,
          data: {
            findNoteById: {
              ...findNoteById,
              comments: findNoteById.comments.map((c) => {
                if (c.id === noteCommentId) {
                  return {
                    ...c,
                    reactions: c.reactions.filter((e) => e.id !== id),
                  }
                }
                return c
              }),
            },
          },
        })
      },
    }).catch(() => {})
  }

  const createNoteCommentReactionMutation = async (noteCommentId: string, emoji: string) => {
    await createNoteCommentReaction({
      variables: { noteCommentId, emoji },
      update(cache, { data }) {
        if (!data) {
          return
        }

        const option = { query: FindNoteByIdDocument, variables: { id: noteId ?? '' } }
        const { findNoteById } =
          cache.readQuery<FindNoteByIdQuery, FindNoteByIdQueryVariables>(option) || {}
        if (!findNoteById) {
          return
        }
        const comment = findNoteById.comments.find((c) => c.id === noteCommentId)
        if (
          !comment ||
          comment.reactions.find((e) => e.emoji === emoji && e.user?.id === user?.id) != null
        ) {
          return
        }

        cache.writeQuery<FindNoteByIdQuery, FindNoteByIdQueryVariables>({
          ...option,
          data: {
            findNoteById: {
              ...findNoteById,
              comments: findNoteById.comments.map((c) => {
                if (c.id === noteCommentId) {
                  return {
                    ...c,
                    reactions: [...c.reactions, data.createNoteCommentReaction],
                  }
                }
                return c
              }),
            },
          },
        })
      },
    }).catch(() => {})
  }

  const author = {
    userId: user.id,
    lastName: user.lastName,
    firstName: user.firstName,
    avatarUrl: user.avatar?.url || '',
    isDisabled: user.isDisabled,
  }

  const onNoteEdit = () => {
    navigate(generateNoteEdit(note.id))
  }

  const onDrawerClose = () => {
    setNoteUserVisibility(false)
  }

  const onNoteUserUpdate = () => {
    setShouldExpireCache(true)
  }

  const onNoteUserClose = () => {
    setNoteUserVisibility(false)
  }

  return {
    author,
    note,
    breadcrumbs: generateBreadCrumbs(note),
    deleteNote: deleteNoteMutation,
    copyNote: copyNoteMutation,
    createNoteReaction: createNoteReactionMutation,
    deleteNoteReaction: deleteNoteReactionMutation,
    createComment: createCommentMutation,
    updateComment: updateCommentMutation,
    deleteComment: deleteCommentMutation,
    createNoteCommentReaction: createNoteCommentReactionMutation,
    deleteNoteCommentReaction: deleteNoteCommentReactionMutation,
    onNoteEdit,
    setNeedBlock,
    isDrawerOpen: noteUserVisibility,
    shouldExpireCache,
    onDrawerClose,
    onNoteUserUpdate,
    onNoteUserClose,
  }
}
