import { useTranslation } from '../../../i18n'
import { Modal, Props as ModalProps } from '../../ui/Modal'
import { StyledText } from '../../ui/StyledText'

export type Props = Pick<ModalProps, 'withGeishaModal' | 'onClickCancel' | 'onClickExecute'>

export const KeyResultQuickUpdateModalCancelModal: React.VFC<Props> = ({
  withGeishaModal,
  onClickExecute,
  onClickCancel,
}) => {
  const { t } = useTranslation()

  return (
    <Modal
      isOpened
      withGeishaModal={withGeishaModal}
      title={t('DISCARD_UNSAVED_CHANGE')}
      executeText={t('DISCARD')}
      cancelText={t('CONTINUE_EDITING')}
      onClickExecute={onClickExecute}
      onClickCancel={onClickCancel}
      onClickOutside={onClickCancel}
    >
      <StyledText>{t('X_EDITING_CONFIRMATION_OF_DISCARD', { x: t('CHANGE') })}</StyledText>
    </Modal>
  )
}

KeyResultQuickUpdateModalCancelModal.displayName = 'KeyResultQuickUpdateModalCancelModal'
