import { useTranslation } from '../../../../i18n'
import { FormField } from '../../../ui/FormField'
import { Input } from '../../../ui/Input'
import { StyledText } from '../../../ui/StyledText'

import { useStyles } from './index.styles'

export type Props = {
  name: string
  setName: (title: string) => void
}

export const NameInput: React.FC<Props> = ({ name, setName }) => {
  const { t } = useTranslation()
  const styles = useStyles()

  return (
    <div css={styles.root}>
      <StyledText size="small" fontStyle="bold" css={styles.label}>
        {t('TITLE')}
      </StyledText>
      <FormField css={styles.form}>
        <Input
          autoFocus
          width="100%"
          fontSize="medium"
          fieldStyle="bottomLine"
          placeholder={t('INPUT_X', { x: t('NEW_KR_NAME') })}
          value={name}
          onInput={(e) => setName(e.currentTarget.value)}
        />
      </FormField>
    </div>
  )
}
