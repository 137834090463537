import { useTranslation } from '../../../../../../i18n'
import { TextButton, Props as TextButtonProps } from '../../../../../ui/TextButton'

export type CreateNoteProps = Pick<TextButtonProps, 'color'> & {
  onClick: React.MouseEventHandler<HTMLButtonElement>
}

export const CreateNote: React.VFC<CreateNoteProps> = ({ color = 'text-bk-30', onClick }) => {
  const { t } = useTranslation()

  return (
    <TextButton icon="plus" iconSize={12} color={color} padding="0" onClick={onClick}>
      {t('CREATE_NOTE')}
    </TextButton>
  )
}
CreateNote.displayName = 'CreateNote'
