import { useCallback } from 'react'
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd'

import { useDraggableInPortal } from '../../../../../lib/reactBeautifulDnd/hooks/useDraggableInPortal'
import { findScreen } from '../../../../../lib/screen'
import { tracker } from '../../../../../lib/tracking'
import { OkrNodeFragment } from '../graphql'

import { ChildOkrReOrderingRow } from './ChildOkrReOrderingRow'
import { useStyles } from './ChildOkrReOrderingRows.styles'

export type ChildOkrReOrderingRowsContainerProps = {
  childrenOkrNodes: ReadonlyArray<OkrNodeFragment>
  setReOrderingChildrenOkrNodes: (result: DropResult) => void
}

export const ChildOkrReOrderingRowsContainer: React.VFC<ChildOkrReOrderingRowsContainerProps> = ({
  childrenOkrNodes,
  setReOrderingChildrenOkrNodes,
}) => {
  const renderDraggable = useDraggableInPortal()
  const styles = useStyles()
  const onDragEnd = useCallback(
    (result: DropResult) => {
      tracker.UserSetSortTheChildOkrByObjectiveModal(
        findScreen(window.location.pathname, window.location.search),
      )
      setReOrderingChildrenOkrNodes(result)
    },
    [setReOrderingChildrenOkrNodes],
  )

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="childrenOkrNodesDroppable">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps} css={styles.root}>
            {childrenOkrNodes.map((node, index) => (
              <Draggable key={node.id} draggableId={node.id} index={index}>
                {renderDraggable((p) => (
                  <ChildOkrReOrderingRow
                    ref={p.innerRef}
                    objective={node.objective}
                    draggableProps={p.draggableProps}
                    dragHandleProps={p.dragHandleProps}
                  />
                ))}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}
