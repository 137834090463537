import { Grommet as GrommetOrig, GrommetProps, ThemeType } from 'grommet'
import React from 'react'

import * as colors from './colors'
import { color } from './newColors'

export type Props = GrommetProps

const baseSize = 8
const baseEdgeSize = 1
const s0 = 2 ** 0
const s1 = 2 ** 1
const s2 = 2 ** 2
const s3 = 2 ** 3
const s4 = 2 ** 4
const s5 = 2 ** 5
const s6 = 2 ** 6

export const theme: ThemeType = {
  global: {
    font: {
      family:
        '"Noto Sans JP", "Noto Color Emoji", -apple-system, BlinkMacSystemFont, "Yu Gothic", YuGothic, sans-serif',
      size: '12px',
      height: '1',
    },
    breakpoints: {
      small: { value: 559 },
      medium: { value: 959 },
      large: {},
    },
    colors: {
      brand: colors.main,
      text: { light: color('text-bk-100') },
    },
    size: {
      xxsmall: `${baseSize * s0}px`,
      xsmall: `${baseSize * s1}px`,
      small: `${baseSize * s2}px`,
      medium: `${baseSize * s3}px`,
      large: `${baseSize * s4}px`,
      xlarge: `${baseSize * s5}px`,
      xxlarge: `${baseSize * s6}px`,
      full: '100%',
    },
    edgeSize: {
      none: '0px',
      hair: `${baseEdgeSize * s0}px`,
      xxsmall: `${baseEdgeSize * s1}px`,
      xsmall: `${baseEdgeSize * s2}px`,
      small: `${baseEdgeSize * s3}px`,
      medium: `${baseEdgeSize * s4}px`,
      large: `${baseEdgeSize * s5}px`,
      xlarge: `${baseEdgeSize * s6}px`,
      responsiveBreakpoint: 'small',
    },
    focus: {
      border: {
        color: 'none',
      },
    },
  },
  heading: {
    level: {
      '1': {
        font: {},
        small: {
          size: '34px',
          height: '40px',
          maxWidth: 'none',
        },
        medium: {
          size: '50px',
          height: '56px',
          maxWidth: 'none',
        },
        large: {
          size: '82px',
          height: '88px',
          maxWidth: 'none',
        },
        xlarge: {
          size: '114px',
          height: '120px',
          maxWidth: 'none',
        },
      },
      '2': {
        font: {},
        small: {
          size: '26px',
          height: '32px',
          maxWidth: 'none',
        },
        medium: {
          size: '34px',
          height: '40px',
          maxWidth: 'none',
        },
        large: {
          size: '50px',
          height: '56px',
          maxWidth: 'none',
        },
        xlarge: {
          size: '66px',
          height: '72px',
          maxWidth: 'none',
        },
      },
      '3': {
        font: {},
        small: {
          size: '22px',
          height: '28px',
          maxWidth: 'none',
        },
        medium: {
          size: '26px',
          height: '32px',
          maxWidth: 'none',
        },
        large: {
          size: '34px',
          height: '40px',
          maxWidth: 'none',
        },
        xlarge: {
          size: '42px',
          height: '48px',
          maxWidth: 'none',
        },
      },
      '4': {
        font: {},
        small: {
          size: '18px',
          height: '24px',
          maxWidth: 'none',
        },
        medium: {
          size: '18px',
          height: '24px',
          maxWidth: 'none',
        },
        large: {
          size: '18px',
          height: '24px',
          maxWidth: 'none',
        },
        xlarge: {
          size: '18px',
          height: '24px',
          maxWidth: 'none',
        },
      },
      '5': {
        font: {},
        small: {
          size: '16px',
          height: '22px',
          maxWidth: 'none',
        },
        medium: {
          size: '16px',
          height: '22px',
          maxWidth: 'none',
        },
        large: {
          size: '16px',
          height: '22px',
          maxWidth: 'none',
        },
        xlarge: {
          size: '16px',
          height: '22px',
          maxWidth: 'none',
        },
      },
      '6': {
        font: {},
        small: {
          size: '12px',
          height: '18px',
          maxWidth: 'none',
        },
        medium: {
          size: '12px',
          height: '18px',
          maxWidth: 'none',
        },
        large: {
          size: '12px',
          height: '18px',
          maxWidth: 'none',
        },
        xlarge: {
          size: '12px',
          height: '18px',
          maxWidth: 'none',
        },
      },
    },
  },
  text: {
    xsmall: {
      size: '8px',
      height: '14px',
      maxWidth: 'none',
    },
    small: {
      size: '12px',
      height: '18px',
      maxWidth: 'none',
    },
    medium: {
      size: '16px',
      height: '20px',
      maxWidth: 'none',
    },
    large: {
      size: '24px',
      height: '24px',
      maxWidth: 'none',
    },
    xlarge: {
      size: '36px',
      height: '28px',
      maxWidth: 'none',
    },
    xxlarge: {
      size: '48px',
      height: '32px',
      maxWidth: 'none',
    },
  },
  anchor: {
    color: { dark: 'accent-1', light: color('text-bk-100') },
    fontWeight: 400,
    hover: {
      textDecoration: `underline ${color('resily-orange-100')}`,
      extend: {
        color: color('resily-orange-100'),
      },
    },
  },
  icon: {
    size: {
      xsmall: '14px',
      small: '18px',
      medium: '20px',
      large: '24px',
      xlarge: '28px',
      xxlarge: '32px',
    },
    extend: undefined,
  },
  meter: {
    color: 'dark-1',
  },
  layer: {
    overlay: {
      background: 'rgba(0, 0, 0, 0.5)',
    },
  },
  formField: {
    label: {
      margin: {
        vertical: 'hair',
        horizontal: 'small',
      },
      size: 'small',
    },
    margin: {
      vertical: 'medium',
    },
  },
  button: {
    border: {
      radius: '4px',
    },
    padding: {
      vertical: '4px',
      horizontal: '4px',
    },
  },
}

export const newTheme: ThemeType = {
  ...theme,
  global: {
    ...theme.global,
  },
  // Textコンポーネントで操作したい場合
  text: {
    xsmall: {
      size: '8px',
      height: '14px',
      maxWidth: 'none',
    },
    small: {
      size: '12px',
      height: '18px',
      maxWidth: 'none',
    },
    medium: {
      size: '16px',
      height: '20px',
      maxWidth: 'none',
    },
    large: {
      size: '24px',
      height: '24px',
      maxWidth: 'none',
    },
    xlarge: {
      size: '36px',
      height: '28px',
      maxWidth: 'none',
    },
    xxlarge: {
      size: '48px',
      height: '32px',
      maxWidth: 'none',
    },
  },
}
export const Grommet: React.FC<Props> = (props) => <GrommetOrig theme={theme} {...props} />
Grommet.displayName = 'Grommet'
