import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { findScreen } from '../../../lib/screen'
import { tracker } from '../../../lib/tracking'
import { fontSize } from '../../../styles/font'
import * as urls from '../../../urls'
import { Modal } from '../../ui/Modal'

export type Props = {
  isOpened: boolean
  onClickCheckPlan: () => void
}

export const NoLeftLicensesForAdminModal: React.FC<Props> = ({ isOpened, onClickCheckPlan }) => {
  // const { t } = useTranslation()
  const navigate = useNavigate()
  const [pushed, setPushed] = useState<'toUsers' | 'toBilling'>()

  return (
    <Modal
      isOpened={isOpened && pushed == null}
      title="ライセンス数が不足しています"
      cancelText="ユーザー画面へ"
      executeText="ライセンスを購入"
      onClickCancel={() => {
        setPushed('toUsers')
        navigate(urls.usersAdmin)
      }}
      onClickExecute={() => {
        tracker.CheckSubscriptionPlan(findScreen(window.location.pathname), true)
        setPushed('toBilling')
        onClickCheckPlan()
      }}
    >
      <div css={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <span css={{ ...fontSize('medium', 'regular'), whiteSpace: 'pre-wrap' }}>
          現在Resilyを使用しているユーザー数に対してライセンス数が足りません。
          ライセンスを追加で購入するか、有効ユーザー数を調整してください。
        </span>
      </div>
    </Modal>
  )
}
