import { css } from '@emotion/react'
import { useMemo } from 'react'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useStyles = () =>
  useMemo(
    () =>
      ({
        root: css({
          display: 'flex',
          flexDirection: 'column',
          gap: 12,
        }),
        header: css({
          display: 'flex',
          justifyContent: 'space-between',
        }),
        avatar: css({
          marginRight: 12,
        }),
        link: css({
          display: 'flex',
          alignItems: 'center',
        }),
        checkinCommentArea: css({
          display: 'flex',
          flexDirection: 'column',
          gap: 8,
        }),
        checkinComment: css({
          display: 'flex',
          alignItems: 'center',
          gap: 16,
        }),
        checkinLabel: css({}),
      } as const),
    [],
  )
