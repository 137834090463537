import { css } from '@emotion/react'
import { useMemo } from 'react'

import { color } from '../../../styles/newColors'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useStyles = () =>
  useMemo(
    () =>
      ({
        root: css({
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          position: 'sticky',
          top: 0,
          zIndex: 1,
          height: '28px',
          backgroundColor: color('background-bk-5'),
          padding: '0 32px',
          gap: '16px',
        }),
        title: css({
          maxWidth: '90%',
          display: 'flex',
          gap: '8px',
        }),
        updateParentObjectiveButton: css({
          minWidth: 'max-content',
        }),
        drop: css({
          padding: '0 4px',
          minWidth: 250,
          // HACK: grommetのDropの高さを制御する手段が無いので強制的に上限を指定
          maxHeight: '60vh !important',
          // スクロールすると上の隙間からチラ見えする対策に白帯を設置
          '> div::before': {
            position: 'sticky',
            top: 0,
            height: 4,
            content: '""',
            backgroundColor: color('white-100'),
          },
          input: {
            top: 4,
            marginBottom: 4,
            border: `1px solid ${color('border-bk-10')}`,
          },
        }),
        dropAlign: { top: 'bottom', right: 'right' },
        dropMargin: { top: '2px', left: '4px', right: '8px' },
      } as const),
    [],
  )
