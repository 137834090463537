import { css } from '@emotion/react'
import { VFC, Fragment, useState, useCallback, useEffect } from 'react'

import { StyledText } from '../../components/ui/StyledText'
import { AuthRouteProps } from '../../types/authRouteProps'

import {
  useIntegrateSmartHrMutation,
  useBulkUpdateStartDateSmartHrEmployeeMutation,
  useBulkUpdateEnableSmartHrEmployeeMutation,
  SmartHrEmployeeFragment,
} from './graphql'
import { Header } from './header'
import { useSmartHRTab, TAB } from './hooks/useSmartHRTab'
import { usePaginatedSmartHREmployee } from './hooks/useSmarthrEmployeeHandler'
import { List } from './list'
import { ListController } from './listController'

// TODO: 最終更新履歴が実装されたら置き換える
const lastIntegrated = {
  date: new Date(),
  user: {
    id: 'user-id',
    firstName: 'first name',
    lastName: 'last name',
    thumbnail: 'http://localhost:3000/images/user',
  },
} as const

const styles = {
  container: css({ padding: '48px 16px' }),
  title: css({
    marginTop: 0,
    marginBottom: 12,
    display: 'inline-block',
  }),
} as const

export const SmartHREmplyeeContainer: VFC<AuthRouteProps> = () => {
  const [isLoadingIntegration, setIsLoadingIntegration] = useState(false)
  const [tab, setTab] = useSmartHRTab({
    onChangeTab: () => {
      // TODO: formの設定まっさらになっているかチェック
      console.log('formの設定まっさらになっているかチェック')
    },
  })

  const pagination = usePaginatedSmartHREmployee({
    synced: tab === 'integratedUser',
    page: 1,
  })

  const [integrateSmartHrMutation] = useIntegrateSmartHrMutation()
  // 連携ユーザーを非連携ユーザーにする
  const [bulkUpdateEnableSmartHrEmployeeMutation] = useBulkUpdateEnableSmartHrEmployeeMutation({
    onCompleted: ({ bulkUpdateSmartHREmployee }) => {
      const targets = bulkUpdateSmartHREmployee.flatMap(
        (e): ReadonlyArray<SmartHrEmployeeFragment> => (e ? [e] : []),
      )
      // 連携ユーザー → 非連携ユーザーに変更される
      pagination.removeCache(targets)
    },
  })
  // 非連携ユーザーに対して利用開始日の設定
  const [bulkUpdateStartDateToSegregatedUsers] = useBulkUpdateStartDateSmartHrEmployeeMutation({
    onCompleted: ({ bulkUpdateSmartHREmployee }) => {
      const targets = bulkUpdateSmartHREmployee.flatMap(
        (e): ReadonlyArray<SmartHrEmployeeFragment> => (e ? [e] : []),
      )
      // 非連携ユーザーから連携ユーザーに変更される
      pagination.removeCache(targets)
    },
  })
  // 連携ユーザーに対して利用開始日の設定
  const [bulkUpdateStartDateToIntegratedUsers] = useBulkUpdateStartDateSmartHrEmployeeMutation({
    onCompleted: ({ bulkUpdateSmartHREmployee }) => {
      const targets = bulkUpdateSmartHREmployee.flatMap(
        (e): ReadonlyArray<SmartHrEmployeeFragment> => (e ? [e] : []),
      )
      // 連携ユーザーのキャッシュ更新
      pagination.updateCache(targets)
    },
  })

  const handleChangeTab = useCallback(
    (t: TAB) => {
      if (tab === t) return
      setTab(t)
      switch (t) {
        case 'integratedUser':
          pagination.dispatchPagingAction('initial', { synced: true })
          break
        case 'segregatedUser':
          pagination.dispatchPagingAction('initial', { synced: false })
          break
        case 'group':
          // TODO: グループを取得するようにする
          break
        default:
          fail(t)
      }
    },
    [pagination, setTab, tab],
  )

  const handleSearch = useCallback(
    (query: string) => {
      console.log('search', query, tab)
      pagination.dispatchPagingAction('initial')
    },
    [pagination, tab],
  )

  const handleClickClearSearch = useCallback(() => {}, [])

  const handleIntegration = useCallback(async () => {
    setIsLoadingIntegration(true)
    try {
      await integrateSmartHrMutation()
      setIsLoadingIntegration(false)
    } catch (e) {
      setIsLoadingIntegration(false)
    }
  }, [integrateSmartHrMutation])

  const handleBulkUpdateDisable = useCallback(
    async (
      employees: ReadonlyArray<Pick<SmartHrEmployeeFragment, 'id'>>,
      onSucceeded: () => void,
    ) => {
      setIsLoadingIntegration(true)
      try {
        await bulkUpdateEnableSmartHrEmployeeMutation({
          variables: {
            ids: employees.map((e) => e.id),
            enable: false,
          },
        })
        onSucceeded()
        setIsLoadingIntegration(false)
      } catch (e) {
        setIsLoadingIntegration(false)
      }
    },
    [bulkUpdateEnableSmartHrEmployeeMutation],
  )

  const handleBulkUpdateStartDate = useCallback(
    async (
      args: {
        employees: ReadonlyArray<Pick<SmartHrEmployeeFragment, 'id'>>
        startDate: Date
      },
      onSucceeded: () => void,
    ) => {
      const variables = {
        ids: args.employees.map((e) => e.id),
        startDate: args.startDate,
      } as const

      setIsLoadingIntegration(true)
      try {
        switch (tab) {
          case 'integratedUser':
            await bulkUpdateStartDateToIntegratedUsers({ variables })
            break
          case 'segregatedUser':
            await bulkUpdateStartDateToSegregatedUsers({ variables })
            break
          default:
            return
        }
        onSucceeded()
        setIsLoadingIntegration(false)
      } catch (e) {
        setIsLoadingIntegration(false)
      }
    },
    [bulkUpdateStartDateToIntegratedUsers, bulkUpdateStartDateToSegregatedUsers, tab],
  )

  useEffect(() => {
    // 連携ユーザーを取得
    pagination.dispatchPagingAction('initial')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Fragment>
      <div css={styles.container}>
        <Header
          loadingIntegration={isLoadingIntegration}
          lastIntegrated={lastIntegrated}
          onClickIntegration={handleIntegration}
        />
        <StyledText size="xlarge" weight="bold" color="text-bk-100" css={styles.title}>
          SmartHR設定
        </StyledText>
        <ListController
          onChangeTab={handleChangeTab}
          onSearch={handleSearch}
          onClickClearSeach={handleClickClearSearch}
        />
        {tab === 'integratedUser' ? (
          <List
            key="integratedUser"
            usingStartDateSettingText="利用開始日設定"
            canSegregation
            pagination={pagination}
            onClickBulkUpdateDisabled={handleBulkUpdateDisable}
            onClickBulkUpdateStartDate={handleBulkUpdateStartDate}
          />
        ) : (
          <List
            key="segregatedUser-or-group"
            usingStartDateSettingText="連携ユーザーに変更"
            canSegregation={false}
            pagination={pagination}
            onClickBulkUpdateDisabled={handleBulkUpdateDisable}
            onClickBulkUpdateStartDate={handleBulkUpdateStartDate}
          />
        )}
      </div>
    </Fragment>
  )
}

SmartHREmplyeeContainer.displayName = 'SmartHREmplyeeContainer'
