import React, { useCallback, useContext } from 'react'
import { useNavigate, useParams } from 'react-router'

import { OkrTermIdContext } from '../../../../../contexts/OkrTermIdContext'
import { useCurrentUser } from '../../../../../contexts/UserContext'
import { useTranslation } from '../../../../../i18n'
import { Screen } from '../../../../../lib/screen'
import { tracker, useTracking } from '../../../../../lib/tracking'
import { color } from '../../../../../styles/newColors'
import { AuthRouteProps } from '../../../../../types/authRouteProps'
import { generatePersonalNoteEdit } from '../../../../../urls'

import { useCreatePersonalNoteMutation, NotePermission, useFindNotesByUserIdQuery } from './graphql'
import { useSort } from './hooks/useSort'
import { Notes } from './notes'

export const NotesContainer: React.VFC<AuthRouteProps> = () => {
  const { t } = useTranslation()
  useTracking(t('NOTE_LIST'), Screen.HomeNote)
  const { userId: pageUserId } = useParams<{ userId: string }>()
  const { okrTermId: pageTermId } = useContext(OkrTermIdContext)
  const currentUser = useCurrentUser()
  const isOwnNoteTab = !!currentUser && currentUser.id === pageUserId

  const notesResp = useFindNotesByUserIdQuery({
    variables: { okrTermId: pageTermId || '', userId: pageUserId || '' },
    skip: !pageTermId || !pageUserId,
  })
  const notes = notesResp.data?.findNotesByUserId

  const { sortedNotes, sort, handleClickSortBy } = useSort(notes)

  const [createPersonalNote] = useCreatePersonalNoteMutation()
  const navigate = useNavigate()
  const onCreatePersonalEmptyNote = useCallback(async () => {
    if (!pageUserId || !pageTermId) return
    tracker.CreatePersonalNote(Screen.HomeNote)
    const result = await createPersonalNote({
      variables: {
        userId: pageUserId,
        title: t('EMPTY_NOTE'),
        permission: NotePermission.Public,
        okrTermId: pageTermId,
      },
    })
    if (!result.data) return
    navigate(generatePersonalNoteEdit(result.data.createPersonalNote.id))
  }, [createPersonalNote, navigate, pageTermId, pageUserId, t])

  return (
    <div
      css={{
        backgroundColor: color('white-100'),
        width: '100%',
        borderRadius: '8px',
      }}
    >
      <Notes
        loading={notes == null && notesResp.loading}
        notes={sortedNotes}
        termId={pageTermId}
        sort={sort}
        isOwnNoteTab={isOwnNoteTab}
        onClickSortBy={handleClickSortBy}
        onCreatePersonalNote={onCreatePersonalEmptyNote}
      />
    </div>
  )
}
