import { Box, BoxProps } from 'grommet'
import React from 'react'

import { useTranslation } from '../../../i18n'
import { color } from '../../../styles/newColors'
import { Icon } from '../Icon'
import { StyledText } from '../StyledText/index'

export type Props = BoxProps

export const OkrLoadingState: React.FC<Props> = ({ children: _, ...props }) => (
  <Box
    flex
    background={{ color: color('background-bk-5') }}
    height="calc(100vh - 52px - 66px)"
    pad={{ bottom: '62px' }}
    align="center"
    justify="center"
    {...props}
  >
    <LoadingState
      background="white"
      border={{ style: 'solid', color: color('border-bk-30') }}
      round="16px"
      width="640px"
    />
  </Box>
)

OkrLoadingState.displayName = 'OkrLoadingState'

const LoadingState: React.FC<BoxProps> = ({ children: _, ...props }) => {
  const { t } = useTranslation()
  return (
    <Box flex align="center" justify="center" height={{ max: '400px', min: '400px' }} {...props}>
      <StyledText css={{ fontSize: '20px', lineHeight: '20px' }} color="text-bk-100">
        {t('LOADING')}
      </StyledText>
      <StyledText
        css={{ fontSize: '16px', lineHeight: '16px' }}
        margin={{ top: '20px', bottom: '35px' }}
        color="text-bk-100"
      >
        {t('JUST_A_MOMENT')}
      </StyledText>
      <Icon type="okrLoading" height={153} width={361} />
    </Box>
  )
}

LoadingState.displayName = 'LoadingState'
