import { Button } from '../../../../../../../components/ui/Button'
import { KeyResultsWeightInput } from '../../../../../../../graphql'
import { useTranslation } from '../../../../../../../i18n'
import { color } from '../../../../../../../styles/newColors'

import { KeyResultWeightSettingErrorMessage } from './KeyResultWeightSettingErrorMessage'
import { KeyResultWeightSettingList } from './KeyResultWeightSettingList'
import { KeyResultWeightSettingTotal } from './KeyResultWeightSettingTotal'
import { KeyResultFragment } from './graphql'
import { useInjection } from './hooks'

export type Props = {
  useWeighting: boolean
  keyResults: ReadonlyArray<KeyResultFragment>
  onUpdate: (keyResults: Array<KeyResultsWeightInput>) => void
}

export const KeyResultWeightSetting: React.VFC<Props> = (props) => {
  const { t } = useTranslation()

  const {
    keyResults,
    onKeyResultWeightChange,
    totalWeight,
    hasTotalWeightError,
    onClickUpdateButton,
    setEditingWeight,
  } = useInjection(props)

  return (
    <div>
      <KeyResultWeightSettingList
        keyResults={keyResults}
        onKeyResultWeightChange={onKeyResultWeightChange}
      />
      <div css={{ marginTop: '20px' }}>
        <KeyResultWeightSettingTotal totalWeight={totalWeight} hasError={hasTotalWeightError} />
      </div>
      {hasTotalWeightError && (
        <div
          css={{
            marginTop: '16px',
          }}
        >
          <KeyResultWeightSettingErrorMessage />
        </div>
      )}
      <div css={{ display: 'flex', justifyContent: 'flex-end', marginTop: '12px' }}>
        <Button
          newColor="resily-orange-100"
          size="s"
          weight="normal"
          disabled={hasTotalWeightError}
          onClick={() => {
            onClickUpdateButton()
            setEditingWeight(false)
          }}
          css={{ '&:disabled': { backgroundColor: color('resily-orange-100') } }}
        >
          {t('SETTING')}
        </Button>
      </div>
    </div>
  )
}

KeyResultWeightSetting.displayName = 'KeyResultWeightSetting'
