import { css } from '@emotion/react'

import { TooltipNew, TooltipNewProps } from '../../../../../components/ui/TooltipNew'
import { useTranslation } from '../../../../../i18n'
import { color } from '../../../../../styles/newColors'

const buttonCss = css({
  padding: 4,
  borderRadius: 4,
  ':hover': {
    backgroundColor: 'white',
    outline: '1px solid #D3D4D9',
  },
})

const fontCss = css({
  fontSize: '10px',
  fontWeight: 700,
  lineHeight: '10px',
})

const upNumCss = css(fontCss, {
  color: color('tag-darkgreen'),
  '&::before': { content: '"↑"' },
})

const downNumCss = css(fontCss, {
  color: color('tag-red'),
  '&::before': { content: '"↓"' },
})

export type Props = Omit<TooltipNewProps, 'title'> & {
  progressRateTrend: number
  onClick: NonNullable<JSX.IntrinsicElements['button']['onClick']>
}

export const TrendButton: React.FC<Props> = ({ progressRateTrend, onClick, ...props }) => {
  const { t } = useTranslation()

  return (
    <TooltipNew
      title={t('LOOK_AT_X', { x: t('JOIN', { a: t('UPDATION'), b: t('HISTORY') }) })}
      {...props}
    >
      <button type="button" css={buttonCss} onClick={onClick}>
        {progressRateTrend !== 0 && (
          <span css={Math.sign(progressRateTrend) > 0 ? upNumCss : downNumCss}>
            {Math.min(Math.abs(progressRateTrend), 999)}
          </span>
        )}
      </button>
    </TooltipNew>
  )
}
