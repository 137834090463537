export type ColorAlias = keyof typeof ColorAliasObj

export const ColorAliasObj = {
  'white-100': '#FFFFFF',
  'resily-orange-100': '#DC440A',
  'resily-orange-80': '#E3693B',
  'resily-orange-60': '#EA8F6C',
  'resily-orange-50': '#EEA184',
  'resily-orange-40': '#F1B49D',
  'resily-orange-30': '#F5C7B6',
  'resily-orange-20': '#F8DACE',
  'resily-orange-16': '#F9E1D8',
  'resily-orange-5': '#FDF6F3',
  'objective-blue-100': '#2348A7',
  'objective-blue-20': '#D3DAED',
  'objective-blue-5': 'rgba(35, 72, 167, 0.1)',
  'user-gray-5': 'rgba(34, 41, 67, 0.1)',
  'kr-green-100': '#1CB0BA',
  'kr-green-20': '#D2EFF1',
  'kr-green-5': '#F4FBFC',
  'text-bk-100': '#222943',
  'text-bk-80': '#4E5469',
  'text-bk-50': '#9194A1',
  'text-bk-30': '#BDBFC7',
  'text-bk-20': '#D3D4D9',
  'text-bk-10': '#E9EAEC',
  'border-bk-80': '#4E5469',
  'border-bk-50': '#9194A1',
  'border-bk-40': '#BDBFC7',
  'border-bk-30': '#D3D4D9',
  'border-bk-20': '#C4C4C4',
  'border-bk-10': '#E9EAEC',
  'hover-background-bk-5': '#F8F9F9',
  'hover-background-bk-10': '#EDEDED',
  'background-bk-5': '#F4F4F6',
  'background-bk-10': '#f0f0f0',
  'background-bk-30': '#7c868f',
  'background-bk-100': '#222943',
  'negative-100': '#DB2828',
  'disabled-100': '#F9CCC1',
  'tag-orange': '#F4B232',
  'tag-red': '#D42922',
  'tag-darkgreen': '#29976F',
} as const

export const color = <T extends ColorAlias>(alias: T): `${typeof ColorAliasObj[T]}` =>
  `${ColorAliasObj[alias]}`

export const hex2rgb = (hexCode: string, delim = ','): string => {
  let hex = hexCode.replace('#', '')

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`
  }

  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)
  return `rgb(${r}${delim}${g}${delim}${b})`
}

export const hex2rgba = (hexCode: string, opacity: number): string => {
  let hex = hexCode.replace('#', '')

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`
  }

  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)
  return `rgba(${r},${g},${b},${opacity / 100})`
}
