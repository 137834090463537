import { css } from '@emotion/react'

import { fontSize } from '../../../../../styles/font'
import { color } from '../../../../../styles/newColors'

export const rootCss = css({
  minWidth: '480px',
  backgroundColor: color('white-100'),
  borderRadius: '8px',
  padding: '16px',
})

export const sectionCss = css({
  color: color('text-bk-50'),
  ...fontSize('small', 'bold'),
})

export const menusWrapperCss = css({
  marginTop: '4px',
  display: 'flex',
  flexWrap: 'wrap',
  columnGap: '24px',
  rowGap: '8px',
  listStyle: 'none',
})

export const menuCss = css({
  width: 'calc(50% - 12px)',
  ...fontSize('small', 'regular'),
})
