import { Calendar as GrommetCalendar, CalendarProps, Grommet } from 'grommet'
import { SyntheticEvent } from 'react'

import { border } from '../../../styles/border'
import { color } from '../../../styles/newColors'

export type Props = CalendarProps & {
  locale: 'ja' | 'en'
  onSelectDate: (date: Date) => void
}

export const Calendar: React.FC<Props> = ({ locale, onSelectDate, ...props }) => (
  <Grommet
    theme={{
      global: {
        size: {
          small: '260px',
        },
      },
      calendar: {
        day: {
          extend: ({ isSelected }: { isSelected: boolean }) => ({
            backgroundColor: isSelected ? color('resily-orange-100') : undefined,
            borderRadius: '2px',
          }),
        },
      },
    }}
  >
    <GrommetCalendar
      size="small"
      locale={locale}
      date={new Date().toISOString()}
      css={{
        backgroundColor: color('white-100'),
        padding: '16px',
        border: border('simple-30'),
        borderRadius: '4px',
        boxShadow: 'none',
        button: {
          ':focus': {
            boxShadow: 'none',
          },
        },
        div: {
          boxShadow: 'none',
        },
      }}
      {...props}
      onSelect={(value: string | Array<string> | SyntheticEvent<HTMLDivElement, Event>) => {
        if (typeof value === 'string') {
          onSelectDate(new Date(value))
        }
      }}
    />
  </Grommet>
)

Calendar.displayName = 'Calendar'
