import { Box, BoxProps, Drop, DropProps, Text } from 'grommet'
import React, { useState, useRef, ReactNode, Fragment } from 'react'

import { color } from '../../../styles/newColors'

export type Props = BoxProps & {
  title: ReactNode
  dropAlign?: DropProps['align']
  customized?: boolean
  disabled?: boolean
  restrictFocus?: boolean
}

export const Tooltip: React.FC<Props> = ({
  title,
  dropAlign = { top: 'bottom' },
  customized,
  disabled = false,
  restrictFocus = true,
  children,
  ...props
}) => {
  const [open, setOpen] = useState(false)
  const ref = useRef<HTMLDivElement>(null)

  if (disabled) {
    if (typeof children === 'undefined') {
      return null
    }
    return <Fragment>{children}</Fragment>
  }

  return (
    <Fragment>
      <Box
        ref={ref}
        width={{ min: 'auto', max: 'auto' }}
        height={{ min: 'auto', max: 'auto' }}
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
        {...props}
      >
        {children}
      </Box>
      {open && ref.current && (
        <Drop align={dropAlign} target={ref.current} restrictFocus={restrictFocus} plain>
          {customized ? (
            <Box direction="row">{title}</Box>
          ) : (
            <Box direction="row">
              <div
                css={{
                  marginTop: 4,
                  backgroundColor: color('text-bk-80'),
                  color: color('white-100'),
                  textAlign: 'center',
                  width: 'auto',
                  maxWidth: 200,
                  padding: '4px 8px',
                  borderRadius: 4,
                  lineHeight: '16px',
                }}
              >
                <Text size="small">{title}</Text>
              </div>
            </Box>
          )}
        </Drop>
      )}
    </Fragment>
  )
}

Tooltip.displayName = 'Tooltip'
