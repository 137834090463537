import { useEffect, useMemo, useCallback, useState } from 'react'
import { useForm, Control } from 'react-hook-form'

import { SmartHrEmployeeFragment } from '../graphql'

export type UseEmployeeFormProps = {
  items: ReadonlyArray<SmartHrEmployeeFragment>
}

export type UseEmployeeFormReturn = {
  selectedAll: boolean
  toggleSelectedAll: () => void
  reset(items: ReadonlyArray<SmartHrEmployeeFragment>): void
  selectedItems: ReadonlyArray<SmartHrEmployeeFragment>
  handleSelecteItems(checked: boolean, item: SmartHrEmployeeFragment): void
  control: Control<FormValue>
}

export type FormField = {
  checked: boolean
}

export type FormValue = {
  items: Array<FormField>
}

export const useEmployeeForm = ({ items }: UseEmployeeFormProps): UseEmployeeFormReturn => {
  const { reset, setValue, control } = useForm<FormValue>({
    mode: 'onChange',
    defaultValues: {
      items: items.map(() => ({ checked: false })),
    },
  })
  const [selectedItems, setSelectedItems] = useState<ReadonlyArray<SmartHrEmployeeFragment>>([])

  const selectedAll = useMemo(() => {
    if (items.length === 0 || selectedItems.length === 0) return false
    return items.every((item) => selectedItems.some((selectedItem) => selectedItem.id === item.id))
  }, [selectedItems, items])

  const addSelectedItems = useCallback(
    (item: SmartHrEmployeeFragment) => {
      if (selectedItems.some(({ id }) => id === item.id)) return
      setSelectedItems([...selectedItems, item])
    },
    [selectedItems],
  )

  const removeSelectedItems = useCallback(
    (item: SmartHrEmployeeFragment) => {
      const findIndex = selectedItems.findIndex(({ id }) => id === item.id)
      if (findIndex === -1) return
      setSelectedItems([
        ...selectedItems.slice(0, findIndex),
        ...selectedItems.slice(findIndex + 1),
      ])
    },
    [selectedItems],
  )

  const handleSelecteItems = useCallback(
    (checked: boolean, item: SmartHrEmployeeFragment) => {
      if (checked) {
        addSelectedItems(item)
      } else {
        removeSelectedItems(item)
      }
    },
    [addSelectedItems, removeSelectedItems],
  )

  const handleReset = useCallback(
    (targets: ReadonlyArray<SmartHrEmployeeFragment>) => {
      reset({
        items: targets.map(() => ({ checked: false })),
      })
      setSelectedItems([])
    },
    [reset],
  )

  const toggleSelectedAll = useCallback(() => {
    setValue(
      'items',
      items.map(() => ({ checked: !selectedAll })),
    )

    if (!selectedAll) {
      // 現在のページの項目を全選択
      const allItems = items.concat(selectedItems)
      const uniqueItems = [...new Map(allItems.map((item) => [item.id, item])).values()]
      setSelectedItems(uniqueItems)
    } else {
      // 現在のページの項目を全解除
      const removedSelectedItems = selectedItems.filter((selectedItem) =>
        items.every((item) => item.id !== selectedItem.id),
      )
      setSelectedItems(removedSelectedItems)
    }
  }, [selectedAll, setValue, items, selectedItems])

  // NOTE: mountedじにformの値をresetする
  useEffect(() => {
    reset({
      items: items.map(() => ({ checked: false })),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    selectedAll,
    selectedItems,
    handleSelecteItems,
    toggleSelectedAll,
    reset: handleReset,
    control,
  }
}
