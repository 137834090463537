import React, { useRef, useState, useCallback } from 'react'

import { useTranslation } from '../../../i18n/index'
import { useClickOutside } from '../../../lib/clickOutside'
import { GroupTag, Props as GroupTagProps } from '../../domain/GroupTag'
import { MultiSelect } from '../MultiSelect'
import { TextButton } from '../TextButton/index'

import { SlackChannelFieldsFragment } from './graphql'

export type Props = {
  disabled: boolean
  selectedSlackChannels: ReadonlyArray<GroupTagProps>
  channels: ReadonlyArray<SlackChannelFieldsFragment>
  isLoading?: boolean
  onOpen?: () => void
  onClickGroupOption: (id: string) => void
}

export const SlackSelect: React.FC<Props> = ({
  disabled,
  selectedSlackChannels,
  channels,
  isLoading = false,
  onOpen,
  onClickGroupOption,
}) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState<boolean>(false)
  const multiSelectRef = useRef<HTMLDivElement>(null)

  const options = channels
    .map(({ id, name }) => ({
      id,
      name,
      searchText: name,
      selected: selectedSlackChannels.some((c) => c.id === id),
      // Groupは無効化表示をしないため明示的にfalseを渡す
      isDisabled: false,
    }))
    .sort((a, b) => (b.selected ? 1 : -1) - (a.selected ? 1 : -1))

  useClickOutside({ callback: () => setOpen(false), ref: multiSelectRef })

  /**
   * マルチセレクトボックスを開く処理
   */
  const handleOpenMultiSelect = useCallback(() => {
    if (onOpen) {
      onOpen()
    }
    setOpen(true)
  }, [onOpen])

  return (
    <div css={{ position: 'relative' }}>
      {open && (
        <div
          ref={multiSelectRef}
          css={{
            position: 'absolute',
            zIndex: 1,
          }}
        >
          <MultiSelect
            canSearch
            onBlurFromMutliSelect={() => {}}
            searchPlaceholder={t('INPUT_X', { x: t('CHANNEL') })}
            options={options}
            isLoading={isLoading}
            onClickOption={onClickGroupOption}
          />
        </div>
      )}

      {selectedSlackChannels.length === 0 ? (
        <TextButton
          disabled={disabled}
          icon="plus"
          color="text-bk-30"
          padding="none"
          onClick={handleOpenMultiSelect}
        >
          {t('ADD_X', { x: t('CHANNEL') })}
        </TextButton>
      ) : (
        <div
          css={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          {selectedSlackChannels.map((channel) => (
            <GroupTag key={`group_${channel.id}`} name={channel.name} />
          ))}
          <TextButton
            disabled={disabled}
            icon="editOutline"
            color="text-bk-30"
            padding="none"
            onClick={handleOpenMultiSelect}
          >
            {t('EDIT')}
          </TextButton>
        </div>
      )}
    </div>
  )
}

SlackSelect.displayName = 'SlackSelect'
