import { Skeleton, ActivityCard as GeishaActivityCard } from '@resily/geisha'

import { useStyles } from './ActivityCardSkeleton.styles'

export const ActivityCardSkeleton: React.VFC = () => {
  const styles = useStyles()

  return (
    <GeishaActivityCard size="small">
      <div css={styles.root}>
        <div css={styles.header}>
          <div css={styles.link}>
            <Skeleton circle width={28} height={28} css={styles.avatar} />
            <Skeleton text width={220} height={12} />
          </div>
        </div>
        <Skeleton height={96} radius={8} />
        <div css={styles.checkinCommentArea}>
          <div css={styles.checkinComment}>
            <Skeleton text width={64} height={20} css={styles.checkinLabel} />
            <Skeleton text height={14} />
          </div>
          <div css={styles.checkinComment}>
            <Skeleton text width={64} height={20} css={styles.checkinLabel} />
            <Skeleton text height={14} />
          </div>
          <div css={styles.checkinComment}>
            <Skeleton text width={64} height={20} css={styles.checkinLabel} />
            <Skeleton text height={14} />
          </div>
          <div css={styles.checkinComment}>
            <Skeleton text width={64} height={20} css={styles.checkinLabel} />
            <Skeleton text height={14} />
          </div>
        </div>
      </div>
    </GeishaActivityCard>
  )
}
ActivityCardSkeleton.displayName = 'ActivityCardSkeleton'
