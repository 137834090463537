import { useContext } from 'react'

import { OkrTermIdContext } from '../../../../../contexts/OkrTermIdContext'
import { useChildOkrReOrderingModal } from '../../ChildOkrReOrderingModal'
import {
  useWeightSettingModal,
  Props as UseWeightSettingModalProps,
} from '../../WeightSettingModal'
import { useOkrModalQueryParams } from '../../hooks/useOkrModalQueryParams'

import { KeyResultArea, Props as KeyResultAreaProps } from './KeyResultArea'
import { KeyResultAreaSkeleton } from './KeyResultAreaSkeleton'

export type KeyResultAreaContainerProps = Pick<
  KeyResultAreaProps,
  'okrNodeId' | 'onClickKeyResultName'
> &
  Omit<UseWeightSettingModalProps, 'objectiveId'> & {
    disabledReOrderingChildOkr: KeyResultAreaProps['disabledReOrderingChildOkr'] | null | undefined
    keyResults: KeyResultAreaProps['keyResults'] | null | undefined
    loading: boolean
  }

export const KeyResultAreaContainer: React.VFC<KeyResultAreaContainerProps> = ({
  okrNodeId,
  useWeighting,
  keyResults,
  onClickKeyResultName,
  loading,
  disabledReOrderingChildOkr,
}) => {
  const { okrTermId } = useContext(OkrTermIdContext)
  const { objectiveId } = useOkrModalQueryParams()

  const [WeightSettingModal, openWeightSettingModal] = useWeightSettingModal({
    useWeighting,
    objectiveId,
    keyResults,
  })

  const { ChildOkrReOrderingModal, openChildOkrReOrderingModal } =
    useChildOkrReOrderingModal(okrNodeId)

  if (
    loading ||
    !objectiveId ||
    !okrTermId ||
    !keyResults ||
    useWeighting == null ||
    disabledReOrderingChildOkr == null
  )
    return <KeyResultAreaSkeleton />

  return (
    <>
      <KeyResultArea
        disabledReOrderingChildOkr={disabledReOrderingChildOkr}
        okrNodeId={okrNodeId}
        termId={okrTermId}
        objectiveId={objectiveId}
        useWeighting={useWeighting}
        keyResults={keyResults}
        onClickKeyResultName={onClickKeyResultName}
        openWeightSettingModal={openWeightSettingModal}
        openSortLowerOKRModal={openChildOkrReOrderingModal}
      />
      {WeightSettingModal}
      {ChildOkrReOrderingModal}
    </>
  )
}
KeyResultAreaContainer.displayName = 'KeyResultAreaContainer'
