import React from 'react'

import { rootCss } from './styles'

export type Props = {
  children: React.ReactNode
}

export const Card: React.VFC<Props> = ({ children, ...rest }) => (
  <div css={rootCss} {...rest}>
    {children}
  </div>
)
