import { css } from '@emotion/react'
import { useMemo } from 'react'

import { fontSize } from '../../../../../styles/font'
import { color, hex2rgba } from '../../../../../styles/newColors'
import { DefaultBarWidth } from '../../../../ui/Meter'

const gap = 12

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useStyles = () =>
  useMemo(
    () =>
      ({
        button: css({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap,
          borderRadius: 4,
          padding: 8,
          backgroundColor: color('white-100'),
          '&[data-is-selected="true"]': {
            backgroundColor: color('resily-orange-5'),
          },
          '&[data-is-selected="false"]:hover': {
            backgroundColor: color('hover-background-bk-5'),
            '.o-name': {
              color: color('resily-orange-100'),
              '&[data-is-disabled="true"]': {
                opacity: 0.5,
              },
            },
          },
        }),
        nameArea: css({
          width: `calc(100% - ${gap + DefaultBarWidth}px)`,
          display: 'flex',
          alignItems: 'center',
          gap: 8,
        }),
        name: css({
          // stylelint-disable-next-line
          display: '-webkit-box',
          overflow: 'hidden',
          WebkitLineClamp: 2,
          WebkitBoxOrient: 'vertical',
          textOverflow: 'ellipsis',
          ...fontSize('medium', 'bold'),
          '&[data-is-selected="true"]': {
            color: color('resily-orange-100'),
          },
          '&[data-is-disabled="true"]': {
            color: color('text-bk-30'),
            textDecoration: 'line-through',
            '&[data-is-selected="true"]': {
              color: hex2rgba(color('resily-orange-100'), 50),
            },
          },
        }),
      } as const),
    [],
  )
