import dayjs from 'dayjs'

import { CheckinSummaryStatus as Status, CheckinSummary } from '../../graphql'
import { TFunction } from '../../i18n'

const current = [Status.Previous, Status.Now] as const

export const isCurrent = (status: Status): boolean => current.some((v) => status === v)

export const summaryTitle = (
  t: TFunction,
  summary: Pick<CheckinSummary, '__typename' | 'endDate'>,
): string =>
  t('CHECKIN_SUMMARY_TITLE', {
    summaryDate: dayjs(new Date(summary.endDate)).format('YYYY/MM/DD'),
  })
