import { memo, useMemo } from 'react'
import isEqual from 'react-fast-compare'

import { useGetFormMethods } from '../../contexts/FormProvider'

import { Names } from './types'

export type Props = {
  names: Names
  children: (idDirty: boolean) => React.ReactNode
}

export const DirtyFieldConsumer: React.VFC<Props> = memo(
  ({ names, children }) => {
    const { getFieldState, formState } = useGetFormMethods()

    const isDirty = names.some((name) => getFieldState(name, formState).isDirty)

    return useMemo(() => <>{children(isDirty)}</>, [children, isDirty])
  },
  (prev, next) => {
    if (!isEqual(prev.names, next.names)) {
      return false
    }
    return true
  },
)

DirtyFieldConsumer.displayName = 'DirtyFieldConsumer'
