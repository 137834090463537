import { useCallback, useContext, useMemo } from 'react'

import { OkrTermIdContext } from '../../../../contexts/OkrTermIdContext'
import { useCurrentUser } from '../../../../contexts/UserContext'
import { TermFragment } from '../../../../contexts/graphql'
import { isIncludedUserInKeyResults } from '../../../../lib/domain/keyResult/keyResult'
import { isPrimaryOkrTerm } from '../../../../lib/domain/okrTerm'
import { CheckinButton, CheckinButtonProps } from '../CheckinButton'
import { KeyResultFragment } from '../graphql'

export type Props = Pick<CheckinButtonProps, 'onClick'>

export type UseCheckinButtonProps = {
  keyResults: ReadonlyArray<KeyResultFragment> | null | undefined
  okrTerms: ReadonlyArray<TermFragment> | null | undefined
}

export type UseCheckinButtonReturn = [React.VFC<Props>]

export const useCheckinButton = ({
  keyResults,
  okrTerms,
}: UseCheckinButtonProps): UseCheckinButtonReturn => {
  const user = useCurrentUser()
  const { okrTermId } = useContext(OkrTermIdContext)

  const isPrimary = isPrimaryOkrTerm(okrTerms, okrTermId)

  const alreadyCheckin = useMemo(() => {
    if (user == null) return null
    return isPrimary ? user.checkinKeyResultsState.isBulkUpdated : false
  }, [isPrimary, user])

  const checkinButtonComponent = useCallback<React.VFC<Props>>(
    (props) => (
      <CheckinButton
        {...props}
        isNotAssignee={!isIncludedUserInKeyResults(keyResults, user, true)}
        alreadyCheckin={alreadyCheckin}
        isNotOkrPeriod={!isPrimary}
      />
    ),
    [alreadyCheckin, isPrimary, keyResults, user],
  )

  return [checkinButtonComponent]
}
