import { Box } from 'grommet'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
  ResponderProvided,
} from 'react-beautiful-dnd'

import { Avatar } from '../../components/ui/Avatar'
import { Icon } from '../../components/ui/Icon'
import { StyledText } from '../../components/ui/StyledText'
import { ThreePointsWithMenu } from '../../components/ui/ThreePointsWithMenu'
import { useTranslation } from '../../i18n'
import { color } from '../../styles/newColors'

import { OneOnOneDeleteModal } from './OneOnOneDeleteModal'
import { UserAgendaTitle } from './UserAgendaTitle'
import { UserAgendasFragment } from './graphql'

export type AgendaContent = {
  id: string
  text: string
  memo: string
  firstName: string
  lastName: string
  isUserDisabled: boolean
  avatarUrl: undefined
}

type Props = {
  userAgendasQuery: ReadonlyArray<UserAgendasFragment>
  readonly: boolean
  onCreateUserAgenda: (title: string) => Promise<void>
  onUpdateUserAgenda: (id: string, title: string) => Promise<void>
  onDeleteUserAgenda: (id: string) => Promise<void>
  onUpdateUserAgendaIndex: (id: string, input: number) => void
}

export type IconFragment = {
  actionIcon: JSX.Element
}

export type MemoMenu = 'delete' | 'register' | 'edit'

export type PopoverItem = {
  label: string
  icon: JSX.Element
  disabled?: boolean
  onItemClicked: () => void
}

export const MemoList: React.FC<Props> = ({
  userAgendasQuery,
  readonly = false,
  onCreateUserAgenda,
  onUpdateUserAgenda,
  onDeleteUserAgenda,
  onUpdateUserAgendaIndex,
}) => {
  const { t } = useTranslation()
  const inputRef = useRef<HTMLTextAreaElement | null>(null)
  const [agendaTitle, setAgendaTitle] = useState<string>()
  const [editable, setEditable] = useState(false)
  const [editAgendaId, setEditAgendaId] = useState('')
  const [deleteAgendaId, setDeleteAgendaId] = useState('')
  const [deleteAgendaTitle, setDeleteAgendaTitle] = useState('')
  const [isShowDelete, setIsShowDelete] = useState(false)

  useEffect(() => {
    if (!editable) return
    if (!inputRef.current) return
    inputRef.current.focus()
  }, [editable])

  const handleOnDragEnd = (result: DropResult, _: ResponderProvided) => {
    if (!result.destination) return
    // 動かしたアイテム(元の場所)
    const source = result.source.index + 1
    // 動かしたアイテム(移動先)
    const destination = result.destination.index + 1
    if (source === destination) return

    onUpdateUserAgendaIndex(userAgendasQuery[result.source.index].id, destination)
  }

  const handleUpdateUserAgendaTitle = useCallback(
    (id: string, title: string) => {
      onUpdateUserAgenda(id, title)
      setEditAgendaId('')
    },
    [onUpdateUserAgenda],
  )

  return (
    <Box>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="characters">
          {(provided) => (
            <ul className="characters" {...provided.droppableProps} ref={provided.innerRef}>
              {userAgendasQuery.map((userAgenda, index) => (
                <Draggable key={userAgenda.id} draggableId={userAgenda.id} index={index}>
                  {(p) => (
                    <Box
                      direction="row"
                      align="center"
                      justify="between"
                      {...p.draggableProps}
                      ref={p.innerRef}
                      css={{
                        ':hover': {
                          cursor: 'pointer',
                          background: 'rgb(34 41 67 / 5%)',
                          borderRadius: '4px',
                        },
                      }}
                    >
                      <Box
                        direction="row"
                        align="center"
                        css={{ flexGrow: 1, paddingRight: '12px' }}
                      >
                        <div css={{ padding: '12px', lineHeight: 0 }} {...p.dragHandleProps}>
                          <Icon type="dragIndicator" color={color('text-bk-30')} />
                        </div>
                        <UserAgendaTitle
                          title={userAgenda.title}
                          editAgendaId={editAgendaId}
                          agendaId={userAgenda.id}
                          onUpdateUserAgenda={handleUpdateUserAgendaTitle}
                        />
                      </Box>
                      <div css={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Avatar
                          firstName={userAgenda.author.firstName}
                          lastName={userAgenda.author.lastName}
                          avatarUrl={userAgenda.author.avatar?.url}
                          size="user-list-page-resource-view"
                          isUserDisabled={userAgenda.author.isDisabled}
                          css={{
                            marginRight: '8px',
                          }}
                        />
                        <ThreePointsWithMenu
                          popoverItems={[
                            {
                              label: t('EDITION'),
                              icon: <Icon width={12} height={12} type="edit" />,
                              onItemClicked: () => setEditAgendaId(userAgenda.id),
                            },
                            {
                              label: t('DELETION'),
                              icon: <Icon width={12} height={12} type="garbage" />,
                              onItemClicked: () => {
                                setDeleteAgendaId(userAgenda.id)
                                setDeleteAgendaTitle(userAgenda.title)
                                setIsShowDelete(true)
                              },
                            },
                          ]}
                          popoverAlign={{ top: 'bottom', left: 'left' }}
                        />
                      </div>
                    </Box>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
              <div css={{ paddingLeft: '12px', paddingTop: '8px' }}>
                {editable ? (
                  <textarea
                    onChange={(event) => setAgendaTitle(event.target.value)}
                    rows={1}
                    onBlur={() => {
                      setEditable(false)
                      if (!agendaTitle) return
                      onCreateUserAgenda(agendaTitle)
                      setAgendaTitle('')
                    }}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        setEditable(false)
                        if (!agendaTitle) return
                        onCreateUserAgenda(agendaTitle)
                        setAgendaTitle('')
                      }
                    }}
                    onKeyDown={(e) => {
                      e.currentTarget.style.height = `${e.currentTarget.scrollHeight}px`
                    }}
                    onFocus={(e) => {
                      const c = e.currentTarget
                      c.style.height = `${c.scrollHeight}px`
                      c.setSelectionRange(c.value.length, c.value.length)
                    }}
                    css={{
                      width: '100%',
                      outline: 'none',
                      resize: 'none',
                      fontSize: '14px',
                    }}
                    ref={(e) => {
                      if (!e) return
                      inputRef.current = e
                    }}
                    required
                  />
                ) : (
                  <button
                    type="button"
                    onClick={() => {
                      if (!readonly) {
                        setEditable(true)
                      }
                    }}
                    css={{
                      width: '100%',
                      textAlign: 'left',
                      outline: 'none',
                      cursor: readonly ? 'default' : 'pointer',
                      fontSize: '14px',
                      color: color('border-bk-40'),
                    }}
                  >
                    ＋ {t('INPUT_X', { x: t('ONEONONE_AGENDA') })}
                  </button>
                )}
              </div>
            </ul>
          )}
        </Droppable>
      </DragDropContext>
      <OneOnOneDeleteModal
        title={deleteAgendaTitle ?? ''}
        id={deleteAgendaId}
        deleteItemName={t('ONEONONE_AGENDA')}
        isShowDeleteModal={isShowDelete}
        setIsShowDelete={setIsShowDelete}
        onDeleteCommonFunction={onDeleteUserAgenda}
      >
        <StyledText weight="bold">{t('ONEONONE_AGENDA_DELETE')}</StyledText>
      </OneOnOneDeleteModal>
    </Box>
  )
}

MemoList.displayName = 'MemoList'
