import { yupResolver } from '@hookform/resolvers/yup'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'

import { PageContent } from '../../components/pageContent'
import { Button } from '../../components/ui/ButtonDeprecated'
import { FormField } from '../../components/ui/FormField'
import { Input } from '../../components/ui/InputDeprecated'
import { Link } from '../../components/ui/Link'
import { Logo } from '../../components/ui/Logo'
import { useTranslation } from '../../i18n'
import { client } from '../../lib/client'
import { Screen } from '../../lib/screen'
import { useTracking } from '../../lib/tracking'
import * as colors from '../../styles/colors'
import { signIn as signInUrl } from '../../urls'

export const PasswordResetContainer: React.FC = () => {
  const resetPassword = async (
    values: {
      email: string
    },
    onSuccess: (email: string) => void,
  ) => {
    try {
      await client.post<string>(
        '/users/password',
        {
          email: values.email,
        },
        {
          error: true,
        },
      )

      onSuccess(values.email)
    } catch (e) {
      // nothing to do
    }
  }

  return <PasswordReset resetPassword={resetPassword} />
}

PasswordResetContainer.displayName = 'PasswordResetContainer'

type FormValue = {
  email: string
}

export type Props = {
  resetPassword: (
    values: {
      email: string
    },
    onSuccess: (email: string) => void,
  ) => void
}

export const PasswordReset: React.FC<Props> = ({ resetPassword }) => {
  const { t } = useTranslation()
  useTracking(t('PASSWORD_RESET_PAGE_TITLE'), Screen.ResetPassword)
  const {
    register,
    handleSubmit,
    formState: { errors },
    formState,
  } = useForm<FormValue>({
    resolver: yupResolver(
      Yup.object().shape({
        email: Yup.string().required(t('REQUIRED_ERROR')).email(t('INVALID_EMAIL_FORMAT')),
      }),
    ),
    defaultValues: {
      email: '',
    },
  })
  const [email, setEmail] = useState<string>('')
  const [completed, setCompleted] = useState<boolean>(false)

  const onSuccess = (emailValue: string) => {
    setEmail(emailValue)
    setCompleted(true)
  }
  const onSubmit = (values: FormValue): void => {
    resetPassword(values, onSuccess)
  }

  return (
    <PageContent
      breadcrumbs={undefined}
      layout={{
        height: '100%',
        align: 'center',
        justify: 'center',
      }}
    >
      <Logo css={{ width: '156px', height: '44px' }} />
      <div
        css={{
          width: '500px',
          padding: '24px 48px',
          marginTop: '24px',
          backgroundColor: colors.baseFill,
          border: `1px solid ${colors.inactive}`,
          boxShadow: '0px 4px 8px rgb(0 0 0 / 14%)',
          borderRadius: '4px',
        }}
      >
        {completed ? (
          <div
            css={{
              margin: '16px auto',
              textAlign: 'center',
              fontSize: '14px',
              lineHeight: 1.5,
              whiteSpace: 'nowrap',
            }}
          >
            <div css={{ whiteSpace: 'pre-line' }}>{t('SENT_RESET_PASSWORD', { email })}</div>
          </div>
        ) : (
          <React.Fragment>
            <div
              css={{
                margin: '16px auto 32px',
                textAlign: 'left',
                fontSize: '14px',
              }}
            >
              <h1>{t('RESET_PASSWORD')}</h1>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div
                css={{
                  margin: '16px 0',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <FormField
                  errorMessage={errors.email ? `${errors.email.message}` : ''}
                  css={{ display: 'inline-block', width: '100px', marginRight: '8px' }}
                >
                  {t('EMAIL')}
                </FormField>
                <div
                  css={{
                    position: 'relative',
                  }}
                >
                  <Input type="email" autoComplete="username" {...register('email')} />
                </div>
              </div>
              <div
                css={{
                  marginTop: '24px',
                  textAlign: 'center',
                }}
              >
                <Button type="submit" disabled={formState.isSubmitting}>
                  {t('SUBMIT')}
                </Button>
              </div>
            </form>
          </React.Fragment>
        )}
      </div>
      <Link
        css={{
          display: 'block',
          margin: '24px auto 0',
          fontWeight: 'bold',
          color: colors.textSub,
        }}
        href={signInUrl}
      >
        {t('BACK_TO_PAGE', { page: t('SIGN_IN') })}
      </Link>
    </PageContent>
  )
}

PasswordReset.displayName = 'PasswordReset'
