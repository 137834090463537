import { css } from '@emotion/react'
import { useState, createRef, useEffect } from 'react'

import { useTranslation } from '../../../i18n'
import { barColorConfidence } from '../../../lib/confidence'
import { isEmptyDocument } from '../../../lib/domain/editor'
import { border } from '../../../styles/border'
import { color } from '../../../styles/newColors'
import { ProgressRate } from '../../ui/Meter'
import { StyledText } from '../../ui/StyledText'
import { KeyResultIcon } from '../KeyResultIcon'
import { KeyResultName } from '../KeyResultName'

import { CheckinCommentsWrapper } from './CheckinCommentsWrapper'
import {
  CheckinSummaryKeyResultFragment,
  KeyResultSharedItemType,
  DocumentInput,
  CheckinKeyResultFragment,
  CommentsFragment,
} from './graphql'
import { CheckinCommentWithUser, CheckinComments } from './types'

export type Props = {
  termId: string
  checkinSummaryKeyResult: CheckinSummaryKeyResultFragment
  onClickSave: (
    checkinKeyResultId: string,
    itemType: KeyResultSharedItemType,
    comment: DocumentInput,
  ) => void
  onClickCancel?: (checkinKeyResultId: string) => void
  onChangeComment?: (checkinKeyResultId: string, comment: DocumentInput) => void
  drawer?: boolean
}

const noCheckinCommentCss = css({
  borderTop: `1px solid ${color('border-bk-10')}`,
  padding: '16px 20px',
})

const extractComments = (
  commentType: Exclude<keyof CommentsFragment, '__typename'>,
  checkinKeyResults: ReadonlyArray<CheckinKeyResultFragment>,
): ReadonlyArray<CheckinCommentWithUser> =>
  checkinKeyResults
    .filter(
      (
        item,
      ): item is CheckinKeyResultFragment & {
        [key in typeof commentType]: NonNullable<CheckinKeyResultFragment[key]>
      } => !isEmptyDocument(item[commentType]?.body?.plainText),
    )
    .map((ckr) => ({
      id: ckr[commentType].id,
      checkinKeyResultId: ckr.id,
      body: ckr[commentType].body,
      checkinUser: ckr.user,
      createdAt: ckr.createdAt,
      updatedAt: ckr.createdAt,
    }))

export const CheckinComment: React.FC<Props> = ({
  termId,
  checkinSummaryKeyResult,
  onClickCancel = () => {},
  onClickSave,
  drawer = false,
}) => {
  const { t } = useTranslation()
  const ref = createRef<HTMLDivElement>()

  const diffProgressRate =
    checkinSummaryKeyResult.progressRate -
    (checkinSummaryKeyResult.beforeCheckinSummaryKeyResult?.progressRate || 0)

  const checkinComments = checkinSummaryKeyResult.checkinKeyResults

  const allComments: ReadonlyArray<CheckinComments> = [
    {
      commentType: KeyResultSharedItemType.Priority,
      comments: extractComments('priority', checkinComments),
    },
    {
      commentType: KeyResultSharedItemType.WinSession,
      comments: extractComments('winSession', checkinComments),
    },
    {
      commentType: KeyResultSharedItemType.Problem,
      comments: extractComments('problem', checkinComments),
    },
    {
      commentType: KeyResultSharedItemType.Other,
      comments: extractComments('other', checkinComments),
    },
  ]
  const noComment = allComments.every((item) => item.comments.length === 0)

  const [keyResultNamePaddingTop, setKeyResultNamePaddingTop] = useState(28)

  useEffect(() => {
    if (!ref?.current) return
    if (ref.current.clientHeight > 20) {
      setKeyResultNamePaddingTop(28)
    } else {
      setKeyResultNamePaddingTop(24)
    }
  }, [ref])

  return (
    <div css={{ border: border('simple-30'), borderRadius: '4px' }}>
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: `${keyResultNamePaddingTop}px 20px 20px 20px`,
        }}
      >
        <div ref={ref} css={{ display: 'flex', alignItems: 'center', flex: '0 1 auto' }}>
          <KeyResultIcon />
          <KeyResultName
            keyResult={{
              name: checkinSummaryKeyResult.name,
              targetSetting: checkinSummaryKeyResult.targetSetting,
              isDisabled: false,
            }}
            css={{ marginLeft: '8px' }}
          />
        </div>
        <div css={{ display: 'flex', whiteSpace: 'nowrap', flex: '1 0 auto', marginLeft: '23px' }}>
          <div css={{ display: 'flex', marginLeft: 'auto' }}>
            {/* KeyResultProgressRateBar を使用しないのは、propsの型が合わないため */}
            <ProgressRate
              color={color(barColorConfidence(checkinSummaryKeyResult.confidence))}
              rate={checkinSummaryKeyResult?.progressRate}
            />
            {diffProgressRate !== 0 && (
              <StyledText
                size="xsmall"
                color={diffProgressRate < 0 ? 'text-bk-50' : 'resily-orange-100'}
                weight={700}
                css={{ marginLeft: '8px' }}
              >
                {diffProgressRate < 0 ? '↓' : '↑'}
                {diffProgressRate}pt
              </StyledText>
            )}
          </div>
        </div>
      </div>
      {noComment && (
        <div css={noCheckinCommentCss}>
          <StyledText>{t('NO_CHECKIN_COMMENT')}</StyledText>
        </div>
      )}
      {allComments.map(
        (item) =>
          item.comments.length > 0 && (
            <CheckinCommentsWrapper
              termId={termId}
              commentType={item.commentType}
              comments={item.comments}
              drawer={drawer}
              onClickSave={onClickSave}
              onClickCancel={onClickCancel}
            />
          ),
      )}
    </div>
  )
}

CheckinComment.displayName = 'CheckinComment'
