import { v1 as uuid } from 'uuid'

import { useTranslation } from '../../../i18n'
import { color } from '../../../styles/newColors'
import { Tooltip } from '../../ui/Tooltip'

const DISPLAY_USER_COUNT = 10

export const reactionDef = [
  { value: '👍', name: 'good' },
  { value: '👎', name: 'negative' },
  { value: '😄', name: 'smile' },
  { value: '😕', name: 'confused' },
  { value: '❤️️', name: 'heart' },
  { value: '🌕', name: 'moon' },
  { value: '🎉', name: 'tada' },
  { value: '👀', name: 'eye' },
] as const

type User = {
  reactedId: string
  userId: string
  lastName: string
  firstName: string
}

type Props = {
  emoji: string
  userId: string
  reactedUsers: ReadonlyArray<User>
  deleteReaction: (reactedId: string) => void
  createReaction: (emoji: string) => void
}

export const ReactedEmoji: React.FC<Props> = ({
  emoji,
  userId,
  reactedUsers,
  deleteReaction,
  createReaction,
}) => {
  const { t } = useTranslation()
  const target = reactionDef.find((e) => e.value === emoji)
  const isReacted = reactedUsers.find((user) => user.userId === userId)

  const onClick = () => {
    if (isReacted) {
      deleteReaction(isReacted.reactedId)
    } else {
      createReaction(emoji)
    }
  }

  const concatUser = ({ firstName, lastName }: User): string =>
    t('FULL_NAME', { firstName, lastName })

  return !target ? null : (
    <Tooltip
      key={uuid()}
      dropAlign={{ top: 'bottom' }}
      customized
      title={
        <div
          css={{
            fontSize: '12px',
            color: 'white',
            background: color('text-bk-80'),
            padding: '4px 8px',
            width: 'auto',
            maxWidth: 200,
            marginTop: '4px',
            borderRadius: 4,
            lineHeight: '16px',
          }}
        >
          <span css={{ fontWeight: 'bold' }}>
            {reactedUsers.length <= DISPLAY_USER_COUNT
              ? reactedUsers.map(concatUser).join(', ')
              : `${reactedUsers.slice(0, DISPLAY_USER_COUNT).map(concatUser).join(', ')}+
                ${reactedUsers.length - DISPLAY_USER_COUNT}`}
          </span>
          <span css={{ opacity: 0.6 }}>{t('REACTED')}</span>
        </div>
      }
    >
      <button
        type="button"
        onClick={onClick}
        css={{
          '&:hover': {
            '> div.tooltip': { display: 'inline-block' },
          },
          outline: 'none',
          cursor: 'pointer',
          height: '24px',
          width: '38px',
          padding: '4px 6px',
          marginRight: '8px',
          borderRadius: '4px',
          border: isReacted ? `1px solid ${color('resily-orange-100')}` : '',
          backgroundColor: isReacted ? color('resily-orange-5') : color('background-bk-5'),
          display: 'flex',
          alignItems: 'flex-start',
        }}
      >
        {/* リアクションされたアイコン */}
        <div css={{ alignItems: 'center', display: 'flex' }}>
          {target.value}
          <span
            css={{
              marginLeft: '3px',
              color: isReacted ? color('resily-orange-100') : color('text-bk-100'),
            }}
          >
            {reactedUsers.length}
          </span>
        </div>
      </button>
    </Tooltip>
  )
}

ReactedEmoji.displayName = 'ReactedEmoji'
