import { PropsWithoutRef } from 'react'

import { useTranslation } from '../../../i18n'
import * as colors from '../../../styles/colors'

const DEFAULT_WIDTH = '240px'
const DEFAULT_HEIGHT = '64px'
const DEFAULT_BORDER_WIDTH = '1px'

export type Props = PropsWithoutRef<JSX.IntrinsicElements['div']> & {
  image?: string | null
  width?: string
  height?: string
  message?: string
  alt?: string
  borderWidth?: string
}

export const LogoPreview: React.FC<Props> = ({
  image,
  width = DEFAULT_WIDTH,
  height = DEFAULT_HEIGHT,
  message,
  borderWidth = DEFAULT_BORDER_WIDTH,
  alt,
  ...props
}) => {
  const { t } = useTranslation()
  return (
    <div
      {...props}
      css={{
        width,
        height,
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        verticalAlign: 'bottom',
        fontWeight: 'bold',
        color: colors.baseFill,
        textTransform: 'uppercase',
        backgroundColor: colors.inactive,
        borderRadius: '50%',
        border: `${borderWidth} solid ${colors.avatarBorder}`,
        whiteSpace: 'nowrap',
        userSelect: 'none',
      }}
    >
      {image && (
        <img
          src={image}
          alt={alt}
          loading="lazy"
          css={{
            width: '100%',
          }}
        />
      )}
      {message && (
        <div
          css={{
            height: '24px',
            lineHeight: '24px',
            width,
            color: 'white',
            backgroundColor: 'rgb(0 0 0 / 30%)',
          }}
        >
          {message}
        </div>
      )}
      <div
        css={{
          flex: 1,
          width,
          lineHeight: `calc(${height} - 48px)`,
          pointerEvents: 'none',
        }}
      >
        {!image && <div css={{ textAlign: 'center' }}>{t('NOT_SET')}</div>}
      </div>
    </div>
  )
}

LogoPreview.displayName = 'LogoPreview'
