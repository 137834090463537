import { useQueryParams, StringParam } from 'use-query-params'

import type { OkrModalQueryParamsType } from './useOkrModalQueryParams'

export const useIsOpenOkrModal = (): boolean => {
  const [queryParams] = useQueryParams<Pick<OkrModalQueryParamsType, 'm-o-id'>>({
    'm-o-id': StringParam,
  })
  return !!queryParams['m-o-id']
}
