import { Box } from 'grommet'
import { Fragment, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import SortableTree, { TreeItem } from 'react-sortable-tree'

import { Section } from '../../../../components/layout/Section'
import { Button } from '../../../../components/ui/Button'
import { Icon } from '../../../../components/ui/Icon'
import { IconButton } from '../../../../components/ui/IconButton'
import { StyledText } from '../../../../components/ui/StyledText'
import { TooltipNew } from '../../../../components/ui/TooltipNew'
import { useTranslation } from '../../../../i18n'
import { Screen } from '../../../../lib/screen'
import { useTracking } from '../../../../lib/tracking'
import { color } from '../../../../styles/newColors'
import * as urls from '../../../../urls'
import { DeleteGroupNode, GroupNodes } from '../../types'
import { DeleteGroupModal } from '../DeleteGroupModal'

import { AdminGroupsSettingProps } from './types'

const generateNodeKey = (treeIndex: number, node: TreeItem) => `${treeIndex}-${node.id}`

export const AdminGroupsSetting: React.FC<AdminGroupsSettingProps> = ({
  loaded,
  isShowEditOrderButton,
  groups,
  editable,
  createGroup,
  createChildGroup,
  deleteGroup,
  moveGroup,
  isAdmin,
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  useTracking(t('ADMIN_GROUPS_SETTING_PAGE_TITLE'), Screen.GroupsSetting)
  const [groupState, setGroupState] = useState<Array<GroupNodes>>([...groups])
  const [draggable, setDraggable] = useState<boolean>(false)
  const [deleteGroupNode, setDeleteGroupNode] = useState<DeleteGroupNode | null>(null)
  const [deleteModalVisibility, setDeleteModalVisibility] = useState<boolean>(false)

  useEffect(() => {
    setGroupState([...groups])
  }, [groups])

  return (
    <Section margin={{ top: '4px', bottom: 'medium' }}>
      <Box direction="row" align="center" justify="between">
        <Box gap="8px">
          <StyledText fontStyle="bold" css={{ fontSize: '20px' }}>
            {t('GROUP')}
          </StyledText>
          <StyledText>{t('ADMIN_GROUPS_PAGE_TITLE')}</StyledText>
        </Box>
        <Box direction="row" align="center" margin={{ left: 'large' }} gap="12px">
          {!draggable && isAdmin && (
            <Button
              newColor="white-100"
              weight="normal"
              size="s"
              onClick={() => navigate(urls.groupsImportAdmin)}
            >
              {t('CSV_IMPORT_PAGE_TITLE')}
            </Button>
          )}
          {isShowEditOrderButton && !draggable && groups.length > 0 && (
            <Button
              weight="normal"
              size="s"
              onClick={() => setDraggable(!draggable)}
              newColor="resily-orange-100"
            >
              {t('EDIT_X', { x: t('GROUP_ORDER') })}
            </Button>
          )}
          {draggable && (
            <div>
              <Button
                weight="normal"
                size="s"
                onClick={() => setDraggable(false)}
                newColor="resily-orange-100"
              >
                {t('FINISH_X', { x: t('EDITION') })}
              </Button>
            </div>
          )}
        </Box>
      </Box>
      <div
        css={{
          background: color('white-100'),
          padding: '32px 24px',
        }}
      >
        <div
          css={{
            display: 'flex',
            justifyContent: 'end',
          }}
        >
          <Button newColor="white-100" weight="normal" size="s" onClick={createGroup}>
            {t('CREATE_GROUP')}
          </Button>
        </div>
        {loaded && groups.length < 1 && (
          <div
            css={{
              marginTop: 40,
              marginBottom: 84,
            }}
          >
            <StyledText
              color="text-bk-100"
              size="medium"
              css={{
                lineHeight: '20px',
                whiteSpace: 'pre-wrap',
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
              }}
            >
              {t('X_IS_EMPTY', { x: t('GROUP_TAG') })}
            </StyledText>
          </div>
        )}
        {groupState.length > 0 && (
          <SortableTree
            isVirtualized={false}
            treeData={groupState}
            canDrag={({ node }) => draggable && editable(node)}
            onChange={(treeDate: Array<GroupNodes>) => setGroupState(treeDate)}
            getNodeKey={({ treeIndex, node }) => generateNodeKey(treeIndex, node)}
            onMoveNode={({ treeData, nextParentNode, node }) => {
              // nextTreeIndexが子も含めたindexになっているため同じ階層でのindex取得方法が存在しない
              // そのため階層毎のindexは自分で算出している
              let index
              if (nextParentNode && nextParentNode.children) {
                // 親のchildrenからindexを算出
                index = (nextParentNode.children as Array<TreeItem>).findIndex(
                  (e) => e.id === node.id,
                )
              } else {
                index = treeData.findIndex((e) => e.id === node.id)
              }
              moveGroup(node.id, index + 1, nextParentNode?.id)
            }}
            generateNodeProps={({ node }) => ({
              buttons: [
                <div
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                    margin: 8,
                  }}
                >
                  {editable(node) && (
                    <Fragment>
                      <TooltipNew title={t('ADD_GROUP_TAG')}>
                        <IconButton
                          css={{ marginLeft: 12 }}
                          border={false}
                          size={12}
                          onClick={() => createChildGroup(node.id)}
                        >
                          <Icon
                            type="plus"
                            color={color('text-bk-30')}
                            css={{ ':hover': { color: color('resily-orange-100') } }}
                          />
                        </IconButton>
                      </TooltipNew>
                      <TooltipNew title={t('EDITION')}>
                        <IconButton
                          css={{ marginLeft: 16 }}
                          border={false}
                          size={12}
                          onClick={() => navigate(urls.generateGroup(node.id))}
                        >
                          <Icon
                            type="editOutline"
                            color={color('text-bk-30')}
                            css={{ ':hover': { color: color('resily-orange-100') } }}
                          />
                        </IconButton>
                      </TooltipNew>
                      <TooltipNew title={t('DELETION')}>
                        <IconButton
                          css={{ marginLeft: 12 }}
                          border={false}
                          size={12}
                          onClick={() => {
                            setDeleteGroupNode(node as DeleteGroupNode)
                            setDeleteModalVisibility(true)
                          }}
                        >
                          <Icon
                            type="deleteOutline"
                            color={color('text-bk-30')}
                            css={{ ':hover': { color: color('resily-orange-100') } }}
                          />
                        </IconButton>
                      </TooltipNew>
                    </Fragment>
                  )}
                </div>,
              ],
            })}
            css={{
              // stylelint-disable-next-line
              '.rst__rowContents': {
                border: 'solid #aaa 1px',
                borderRadius: '4px',
              },
              // stylelint-disable-next-line
              '.rst__moveHandle': {
                position: 'relative',
                left: '5px',
                cursor: 'grab',
                background: color('white-100'),
                backgroundImage: `url('data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%228%22%20height%3D%2212%22%20viewBox%3D%220%200%208%2012%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M3.33335%2010.0013C3.33335%2010.7346%202.73335%2011.3346%202.00002%2011.3346C1.26669%2011.3346%200.666687%2010.7346%200.666687%2010.0013C0.666687%209.26797%201.26669%208.66797%202.00002%208.66797C2.73335%208.66797%203.33335%209.26797%203.33335%2010.0013ZM2.00002%204.66797C1.26669%204.66797%200.666687%205.26797%200.666687%206.0013C0.666687%206.73463%201.26669%207.33463%202.00002%207.33463C2.73335%207.33463%203.33335%206.73463%203.33335%206.0013C3.33335%205.26797%202.73335%204.66797%202.00002%204.66797ZM2.00002%200.667969C1.26669%200.667969%200.666687%201.26797%200.666687%202.0013C0.666687%202.73464%201.26669%203.33464%202.00002%203.33464C2.73335%203.33464%203.33335%202.73464%203.33335%202.0013C3.33335%201.26797%202.73335%200.667969%202.00002%200.667969ZM6.00002%203.33464C6.73335%203.33464%207.33335%202.73464%207.33335%202.0013C7.33335%201.26797%206.73335%200.667969%206.00002%200.667969C5.26669%200.667969%204.66669%201.26797%204.66669%202.0013C4.66669%202.73464%205.26669%203.33464%206.00002%203.33464ZM6.00002%204.66797C5.26669%204.66797%204.66669%205.26797%204.66669%206.0013C4.66669%206.73463%205.26669%207.33463%206.00002%207.33463C6.73335%207.33463%207.33335%206.73463%207.33335%206.0013C7.33335%205.26797%206.73335%204.66797%206.00002%204.66797ZM6.00002%208.66797C5.26669%208.66797%204.66669%209.26797%204.66669%2010.0013C4.66669%2010.7346%205.26669%2011.3346%206.00002%2011.3346C6.73335%2011.3346%207.33335%2010.7346%207.33335%2010.0013C7.33335%209.26797%206.73335%208.66797%206.00002%208.66797Z%22%20fill%3D%22%23BDBFC7%22%2F%3E%3C%2Fsvg%3E')`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                borderRight: 'none',
                borderTopLeftRadius: '4px',
                borderBottomLeftRadius: '4px',
              },
              // stylelint-disable-next-line
              '.rst__collapseButton': {
                zIndex: 2,
                backgroundImage: `url('data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Crect%20x%3D%220.5%22%20y%3D%220.5%22%20width%3D%2219%22%20height%3D%2219%22%20rx%3D%229.5%22%20fill%3D%22white%22%2F%3E%3Cpath%20d%3D%22M10.0001%2013.2213L7.88679%2015.3346L6.94679%2014.3946L10.0001%2011.3346L13.0601%2014.3946L12.1135%2015.3346L10.0001%2013.2213ZM10.0001%206.7813L12.1135%204.66797L13.0535%205.60797L10.0001%208.66797L6.94012%205.60797L7.88679%204.66797L10.0001%206.7813Z%22%20fill%3D%22%239194A1%22%2F%3E%3Crect%20x%3D%220.5%22%20y%3D%220.5%22%20width%3D%2219%22%20height%3D%2219%22%20rx%3D%229.5%22%20stroke%3D%22%23E9EAEC%22%2F%3E%3C%2Fsvg%3E')`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                boxShadow: `0 0 0 1px ${color('border-bk-40')}`,
                ':hover': {
                  backgroundImage: `url('data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Crect%20x%3D%220.5%22%20y%3D%220.5%22%20width%3D%2219%22%20height%3D%2219%22%20rx%3D%229.5%22%20fill%3D%22white%22%2F%3E%3Cpath%20d%3D%22M10.0001%2013.2213L7.88679%2015.3346L6.94679%2014.3946L10.0001%2011.3346L13.0601%2014.3946L12.1135%2015.3346L10.0001%2013.2213ZM10.0001%206.7813L12.1135%204.66797L13.0535%205.60797L10.0001%208.66797L6.94012%205.60797L7.88679%204.66797L10.0001%206.7813Z%22%20fill%3D%22%23DC440A%22%2F%3E%3Crect%20x%3D%220.5%22%20y%3D%220.5%22%20width%3D%2219%22%20height%3D%2219%22%20rx%3D%229.5%22%20stroke%3D%22%23E9EAEC%22%2F%3E%3C%2Fsvg%3E')`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                },
                ':hover::after': {
                  position: 'fixed',
                  top: '25px',
                  left: '-25px',
                  width: '76px',
                  height: '24px',
                  padding: '4px 8px',
                  color: color('white-100'),
                  content: `'${t('FOLD')}'`,
                  background: color('border-bk-80'),
                  borderRadius: '4px',
                },
              },
              // stylelint-disable-next-line
              '.rst__expandButton': {
                zIndex: 2,
                backgroundImage: `url('data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Crect%20x%3D%220.5%22%20y%3D%220.5%22%20width%3D%2219%22%20height%3D%2219%22%20rx%3D%229.5%22%20fill%3D%22white%22%2F%3E%3Cpath%20d%3D%22M10%2014.1133L7.88667%2012L6.94667%2012.94L10%2016L13.06%2012.94L12.1133%2012L10%2014.1133ZM10%205.88667L12.1133%208L13.0533%207.06L10%204L6.94%207.06L7.88667%208L10%205.88667Z%22%20fill%3D%22%239194A1%22%2F%3E%3Crect%20x%3D%220.5%22%20y%3D%220.5%22%20width%3D%2219%22%20height%3D%2219%22%20rx%3D%229.5%22%20stroke%3D%22%23E9EAEC%22%2F%3E%3C%2Fsvg%3E')`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                boxShadow: `0 0 0 1px ${color('border-bk-40')}`,
                ':hover': {
                  backgroundImage: `url('data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Crect%20x%3D%220.5%22%20y%3D%220.5%22%20width%3D%2219%22%20height%3D%2219%22%20rx%3D%229.5%22%20fill%3D%22white%22%2F%3E%3Cpath%20d%3D%22M10%2014.1133L7.88667%2012L6.94667%2012.94L10%2016L13.06%2012.94L12.1133%2012L10%2014.1133ZM10%205.88667L12.1133%208L13.0533%207.06L10%204L6.94%207.06L7.88667%208L10%205.88667Z%22%20fill%3D%22%23DC440A%22%2F%3E%3Crect%20x%3D%220.5%22%20y%3D%220.5%22%20width%3D%2219%22%20height%3D%2219%22%20rx%3D%229.5%22%20stroke%3D%22%23E9EAEC%22%2F%3E%3C%2Fsvg%3E')`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                },
                ':hover::after': {
                  position: 'relative',
                  top: '25px',
                  left: '-12px',
                  width: '76px',
                  height: '24px',
                  padding: '4px 8px',
                  color: color('white-100'),
                  content: `'${t('OPEN')}'`,
                  background: color('border-bk-80'),
                  borderRadius: '4px',
                },
              },
              // stylelint-disable-next-line
              '.rst__lineHalfHorizontalRight::before, .rst__lineFullVertical::after, .rst__lineHalfVerticalTop::after, .rst__lineHalfVerticalBottom::after, .rst__lineChildren::after':
                {
                  backgroundColor: color('border-bk-40'),
                },
              // stylelint-disable-next-line
              '.rst__lineHalfHorizontalRight::before': {
                right: '-12%',
                width: '62%',
              },
            }}
          />
        )}
      </div>
      <DeleteGroupModal
        deleteItemName={t('GROUP')}
        deleteItemNameForBody={t('THIS_ITEM', { item: t('GROUP') })}
        isOpened={deleteModalVisibility}
        onClickCancel={() => {
          setDeleteModalVisibility(false)
        }}
        onClickOutside={() => {
          setDeleteModalVisibility(false)
        }}
        onClickDelete={() => {
          if (deleteGroupNode) deleteGroup(deleteGroupNode)
          setDeleteModalVisibility(false)
        }}
      />
    </Section>
  )
}

AdminGroupsSetting.displayName = 'AdminGroupsSetting'
