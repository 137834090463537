import { ReactElement, forwardRef, useImperativeHandle, useRef, useCallback, useState } from 'react'
import { useDropzone, FileRejection } from 'react-dropzone'

import { useTranslation } from '../../../i18n'
import { client } from '../../../lib/client'

const ACCEPTED_FILE_TYPES = ['image/png', 'image/jpeg']
const MAX_FILE_SIZE_MB = 16
const MAX_FILE_SIZE = MAX_FILE_SIZE_MB * 1024 * 1024

export type ImageFileInfo = { name: string; path: string; url: string }

export type Props = {
  kind: 'logos' | 'avatars' | 'files'
  onUploaded?: (imageFileInfo: ImageFileInfo) => void
  onPreview?: (previewUrl: string) => void
  children: ReactElement
}

export type UploaderRef = {
  openFile: () => void
}

export const ImageUploader = forwardRef<UploaderRef, Props>(
  ({ kind, children, onUploaded, onPreview, ...props }, ref) => {
    const { t } = useTranslation()
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
    const onDrop = useCallback(
      (acceptedFiles: ReadonlyArray<File>) => {
        if (acceptedFiles.length === 1) {
          // onPreviewが定義されている場合のみプレビュー生成を行う
          if (onPreview) {
            const reader = new FileReader()
            /* eslint-disable-next-line func-names */
            reader.addEventListener('load', function () {
              /* eslint-disable-next-line react/no-this-in-sfc */
              onPreview(this.result as string)
            })
            reader.readAsDataURL(acceptedFiles[0])
          }

          client
            .uploadFiles<ImageFileInfo>(
              `/upload/${kind}`,
              {
                image: acceptedFiles[0],
              },
              { error: true, success: false },
            )
            .then((res) => {
              if (res && onUploaded) {
                onUploaded(res)
              }
            })
        }
      },
      [onPreview, kind, onUploaded],
    )
    const onDropRejected = useCallback(
      (rejectedFiles: ReadonlyArray<FileRejection>) => {
        if (rejectedFiles.length === 0) {
          return
        }
        if (rejectedFiles.length > 1) {
          setErrorMessage(t('UPLOADER_ERROR_NUM_FILES', { num: 1 }))
        } else if (rejectedFiles[0].file.size > MAX_FILE_SIZE) {
          setErrorMessage(t('UPLOADER_ERROR_MAX_SIZE', { max: MAX_FILE_SIZE_MB }))
        } else {
          setErrorMessage(t('UPLOADER_ERROR_TYPE'))
        }
      },
      [setErrorMessage, t],
    )

    const { getRootProps, getInputProps } = useDropzone({
      onDrop,
      onDropRejected,
      multiple: false,
      maxSize: MAX_FILE_SIZE,
      accept: ACCEPTED_FILE_TYPES,
    })

    const uploaderRef = useRef<HTMLDivElement>(null)
    useImperativeHandle(ref, () => ({
      openFile: () => {
        if (!uploaderRef?.current) return
        uploaderRef.current.click()
      },
    }))

    return (
      <div {...props} {...getRootProps()} ref={uploaderRef}>
        <input type="file" {...getInputProps()} />
        {children}
        {errorMessage && (
          <div css={{ color: 'red', paddingTop: 4, paddingBottom: 4 }}>{errorMessage}</div>
        )}
      </div>
    )
  },
)

ImageUploader.displayName = 'ImageUploader'
