import { css } from '@emotion/react'

// Use for OneOnOneHistoryContainer component.
export const oneOnOneHistoryContainerCss = css({
  padding: '24px 20px',
  width: '100%',
  maxWidth: '1080px',
  margin: '0 auto',
})

// Use for PartnerUser component.
export const partnerUserCss = css({
  margin: '0px 0px 16px 20px',
  display: 'block',
})

// Use for MeetingHistoryCard component.
export const historyCardCss = css({
  border: '1px solid #D3D4D9',
  borderRadius: '8px',
  width: '100%',
  padding: '24px 20px',
  marginBottom: '10px',
})

// Use for MeetingHistoryNotes component.
export const labelCss = css({
  background: '#E9EAEC',
  borderRadius: 4,
  padding: '8px 24px',
  marginBottom: 4,
  alignItems: 'center',
  minHeight: 40,
  fontSize: 20,
  fontWeight: 700,
})

export const editorCss = css({
  backgroundColor: 'white',
  border: 'none',
  margin: '20px 24px 32px',
})
