import { MutableRefObject, LegacyRef, RefCallback } from 'react'

export const mergeRefs =
  (refs: Array<MutableRefObject<unknown> | LegacyRef<unknown>>): RefCallback<unknown> =>
  (value) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(value)
      } else if (ref != null) {
        ;(ref as MutableRefObject<unknown | null>).current = value
      }
    })
  }
