import { ActivityCard as GeishaActivityCard, ConfidenceTag } from '@resily/geisha'
import { useRef } from 'react'

import { useTranslation } from '../../../../../../i18n'
import { useActivityUtil } from '../../../../../../lib/domain/activity/hooks/useActivityUtil'
import { componentNames } from '../../../../../../lib/testId'
import * as urls from '../../../../../../urls'
import { EditorRef, RichTextEditor } from '../../../../../ui/RichTextEditor'
import { ActivityCardFragment, ActivityType, Document } from '../graphql'

import { ActivityCardSkeleton } from './ActivityCardSkeleton'
import { useStyles } from './index.styles'

const editorValue = (v: Document) => v.treeJson || v.plainText || undefined

export type Props = {
  termId: string
  activity: ActivityCardFragment
  showTitle: boolean
}

export type ActivityCardComposition = {
  Skeleton: typeof ActivityCardSkeleton
}

export const ActivityCard: React.VFC<Props> & ActivityCardComposition = ({
  termId,
  activity,
  showTitle,
}) => {
  const { t } = useTranslation()
  const styles = useStyles()

  // Editor
  const priorityEditorRef = useRef<EditorRef>(null)
  const winSessionEditorRef = useRef<EditorRef>(null)
  const problemEditorRef = useRef<EditorRef>(null)
  const otherEditorRef = useRef<EditorRef>(null)

  const {
    diffValue,
    confidenceText,
    beforeConfidenceLevel,
    afterConfidenceLevel,
    getDiffValueProps,
    getDiffValueByUpdateUnitProps,
    getDiffUnitProps,
    getDiffConfidenceLevelProps,
  } = useActivityUtil(activity)

  // Key Result Comments
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/naming-convention
  const { __typename: _, ...comments } = activity.keyResultComment || {}

  return (
    <GeishaActivityCard size="small">
      <GeishaActivityCard.Header
        avatarProps={{
          url: activity.user.avatar?.url,
          linkTo: urls.generateUserInTerm(termId)(activity.user.id),
          linkTarget: 'newTab',
        }}
        lastName={activity.user.lastName}
        firstName={activity.user.firstName}
        showTitle={showTitle}
        title={activity.keyResult.name}
        timestamp={activity.updatedAt}
      />
      <GeishaActivityCard.Body>
        {activity.keyResultEvent && (
          <GeishaActivityCard.DataGroup>
            <GeishaActivityCard.DataItemGroup>
              {activity.keyResultEvent.afterProgressRate != null && (
                <GeishaActivityCard.DataItem
                  label={t('PROGRESS_RATE')}
                  value={activity.keyResultEvent.afterProgressRate}
                  valueStyles={{ weight: 'bold' }}
                  unit="%"
                  testId={componentNames.progressRate}
                  {...getDiffValueProps(diffValue.progressRate)}
                />
              )}
              {activity.keyResultEvent.afterActualValue != null && (
                <GeishaActivityCard.DataItem
                  label={t('ACTUAL_VALUE')}
                  value={activity.keyResultEvent.afterActualValue}
                  valueStyles={{ weight: 'bold' }}
                  unit={activity.keyResultEvent.updatedUnit ?? undefined}
                  testId={componentNames.actualValue}
                  {...getDiffValueProps(diffValue.actualValue)}
                />
              )}
              {activity.keyResultEvent.afterTargetValue != null && (
                <GeishaActivityCard.DataItem
                  label={t('TARGET_VALUE')}
                  value={activity.keyResultEvent.afterTargetValue}
                  valueStyles={{ weight: 'bold' }}
                  unit={activity.keyResultEvent.updatedUnit ?? undefined}
                  testId={componentNames.targetValue}
                  {...getDiffValueByUpdateUnitProps(diffValue.targetValue)}
                />
              )}
              {activity.keyResultEvent.afterInitialValue != null && (
                <GeishaActivityCard.DataItem
                  label={t('INITIAL_VALUE')}
                  value={activity.keyResultEvent.afterInitialValue}
                  valueStyles={{ weight: 'bold' }}
                  unit={activity.keyResultEvent.updatedUnit ?? undefined}
                  testId={componentNames.initialValue}
                  {...getDiffValueByUpdateUnitProps(diffValue.initialValue)}
                />
              )}
              {activity.keyResultEvent.afterUnit != null && (
                <GeishaActivityCard.DataItem
                  label={t('TARGET_VALUE_UNIT')}
                  value={activity.keyResultEvent.afterUnit || t('UNIT_NOT_SET')}
                  valueStyles={{ weight: 'bold' }}
                  testId={componentNames.unit}
                  {...getDiffUnitProps(diffValue.unit)}
                />
              )}
              {afterConfidenceLevel != null && (
                <GeishaActivityCard.DataItem
                  label={t('CONFIDENCE')}
                  value={
                    <ConfidenceTag level={afterConfidenceLevel}>
                      {confidenceText[afterConfidenceLevel]}
                    </ConfidenceTag>
                  }
                  testId={componentNames.confidence}
                  {...getDiffConfidenceLevelProps(diffValue.confidenceLevel, beforeConfidenceLevel)}
                />
              )}
            </GeishaActivityCard.DataItemGroup>
            {(activity.keyResultEvent.updateReason ||
              activity.activityType === ActivityType.KrAutoAggregation) && (
              <GeishaActivityCard.DataItem
                label={t('UPDATE_MEMO')}
                value={
                  activity.activityType === ActivityType.KrAutoAggregation
                    ? t('PROGRESS_RATE_AUTO_AGGREGATED')
                    : activity.keyResultEvent.updateReason
                }
                valueStyles={{
                  color:
                    activity.activityType === ActivityType.KrAutoAggregation
                      ? 'foreground-black-bk50'
                      : 'foreground-black-bk100',
                }}
                testId={componentNames.updateReason}
              />
            )}
          </GeishaActivityCard.DataGroup>
        )}
        {Object.values(comments).some((comment) => comment != null) && (
          <>
            <GeishaActivityCard.Grid>
              {comments.priority?.body != null && (
                <GeishaActivityCard.Col label={t('PRIORITY')}>
                  <RichTextEditor
                    id={`activity-priority-${activity.id}`}
                    ref={priorityEditorRef}
                    initialValueJSON={editorValue(comments.priority.body)}
                    editorProps={{ readOnly: true }}
                    css={styles.editor}
                  />
                </GeishaActivityCard.Col>
              )}
              {comments.winSession?.body != null && (
                <GeishaActivityCard.Col label={t('WIN_SESSION')}>
                  <RichTextEditor
                    id={`activity-winSession-${activity.id}`}
                    ref={winSessionEditorRef}
                    initialValueJSON={editorValue(comments.winSession.body)}
                    editorProps={{ readOnly: true }}
                    css={styles.editor}
                  />
                </GeishaActivityCard.Col>
              )}
              {comments.problem?.body != null && (
                <GeishaActivityCard.Col label={t('PROBLEM')}>
                  <RichTextEditor
                    id={`activity-problem-${activity.id}`}
                    ref={problemEditorRef}
                    initialValueJSON={editorValue(comments.problem.body)}
                    editorProps={{ readOnly: true }}
                    css={styles.editor}
                  />
                </GeishaActivityCard.Col>
              )}
              {comments.other?.body != null && (
                <GeishaActivityCard.Col label={t('OTHER')}>
                  <RichTextEditor
                    id={`activity-other-${activity.id}`}
                    ref={otherEditorRef}
                    initialValueJSON={editorValue(comments.other.body)}
                    editorProps={{ readOnly: true }}
                    css={styles.editor}
                  />
                </GeishaActivityCard.Col>
              )}
            </GeishaActivityCard.Grid>
          </>
        )}
        {activity.createdAt !== activity.updatedAt && (
          <GeishaActivityCard.EditedLabel value={t('EDITED')} />
        )}
      </GeishaActivityCard.Body>
    </GeishaActivityCard>
  )
}
ActivityCard.Skeleton = ActivityCardSkeleton
ActivityCard.displayName = 'ActivityCard'
