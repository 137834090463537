import { useModal } from '@resily/geisha'
import { useCallback, useMemo, useState } from 'react'

import { useEditingStateMachine } from '../../../../contexts/EditingStateContext'
import { Screen } from '../../../../lib/screen'
import { tracker } from '../../../../lib/tracking'
import {
  OkrUpdateModalContainer,
  Props as OkrUpdateModalContainerProps,
} from '../OkrUpdateModalContainer'

export type Props = Pick<OkrUpdateModalContainerProps, 'onDelete' | 'onDisable'>

export type OkrUpdateModalReturnType = {
  OkrUpdateModal: React.ReactNode
  openOkrUpdateModal: (id: string, screen: Screen) => void
}

export const useOkrUpdateModal = (props: Props): OkrUpdateModalReturnType => {
  const [Modal] = useModal()
  const [objectiveId, setObjectiveId] = useState<string>()

  const { requestStartEdit, updateIsDirty, discardEdit, finishEdit } = useEditingStateMachine()

  const OkrUpdateModal = useMemo(
    () =>
      objectiveId ? (
        <OkrUpdateModalContainer
          modalComponent={Modal}
          objectiveId={objectiveId}
          isOpened
          onEdit={updateIsDirty}
          close={() => {
            setObjectiveId(undefined)
            finishEdit()
          }}
          onClose={discardEdit}
          {...props}
        />
      ) : null,
    [Modal, discardEdit, finishEdit, updateIsDirty, objectiveId, props],
  )

  const openOkrUpdateModal = useCallback<OkrUpdateModalReturnType['openOkrUpdateModal']>(
    (id, screen) => {
      tracker.UserClickOpenObjectiveUpdateModalByObjectiveModal(screen)
      requestStartEdit(() => {
        setObjectiveId(id)
        return () => setObjectiveId(undefined)
      }, 'OBJECTIVE_UPDATE_MODAL')
    },
    [requestStartEdit],
  )

  return {
    OkrUpdateModal,
    openOkrUpdateModal,
  }
}
