import { setUser } from '@sentry/react'
import mixpanel from 'mixpanel-browser'
import { useEffect } from 'react'
import ReactGA from 'react-ga'
import { useLocation, Location } from 'react-router-dom'
import { usePrevious } from 'react-use'

import {
  configureTracking,
  configureSentry,
  configureDomain,
  isDevelopment,
  isInternalEnvironment,
  isTest,
} from '../config'
import { useCurrentUser } from '../contexts/UserContext'

import { decodeId } from './domain/id'
import { OpenReplayTrackerSingleton as OpenReplayTracker } from './openReplay/openReplay'
import { overrideGlobalFetch } from './openReplay/plugins'
import { Screen } from './screen'

const config = configureTracking()

// クリックイベント
enum ClickEvent {
  // For Global Navigation
  ClickGlobalNavAllTree = 'CLICK_GLOBAL_NAV_ALL_TREE',
  ClickGlobalNavGroupTree = 'CLICK_GLOBAL_NAV_GROUP_TREE',
  ClickGlobalNavBookmarkTree = 'CLICK_GLOBAL_NAV_BOOKMARK_TREE',
  ClickGlobalNavMyTree = 'CLICK_GLOBAL_NAV_MY_TREE',
  ClickGlobalNavHome = 'CLICK_GLOBAL_NAV_HOME',
  ClickGlobalNavNotification = 'CLICK_GLOBAL_NAV_NOTIFICATION',
  ClickGlobalNavUsers = 'CLICK_GLOBAL_NAV_USERS',
  ClickGlobalNavOneOnOne = 'CLICK_GLOBAL_NAV_ONE_ON_ONE',
  ClickGlobalNavOrganizationSetting = 'CLICK_GLOBAL_NAV_ORGANIZATION_SETTING',
  ClickGlobalNavUserSetting = 'CLICK_GLOBAL_NAV_USER_SETTING',
  ClickGlobalNavHelpPage = 'CLICK_GLOBAL_NAV_HELP_PAGE',
  ClickGlobalNavSignOut = 'CLICK_GLOBAL_NAV_SIGN_OUT',

  // For OKR MAP
  AddOkr = 'CLICK_BUTTON_ADD_OKR',
  AddOkrAsChild = 'CLICK_BUTTON_ADD_OKR_AS_CHILD',
  ZoomOut = 'CLICK_ZOOM_OUT',
  ZoomIn = 'CLICK_ZOOM_IN',
  OpenDotMenu = 'CLICK_OPEN_DOT_MENU',
  OpenDotMenuShowOkrPage = 'CLICK_DOT_MENU_SHOW_OKR_PAGE',
  OpenDotMenuExpandDot = 'CLICK_DOT_MENU_EXPAND_DOT',
  OpenDotMenuShowInfo = 'CLICK_DOT_MENU_SHOW_INFO',
  MiniMap = 'CLICK_MINI_MAP',

  // For User
  ShowOkrPage = 'CLICK_SHOW_OKR_PAGE',
  ShowOkrMap = 'CLICK_SHOW_OKR_MAP',
  ShowKRNote = 'CLICK_SHOW_KR_NOTES',
  ClickUpdateConfidence = 'CLICK_UPDATE_CONFIDENCE',
  ClickCreatePersonalNote = 'CLICK_CREATE_PERSONAL_NOTE',
  ClickCreateActionPlan = 'CLICK_CREATE_ACTION_PLAN',
  ClickUpdateProgressRate = 'CLICK_UPDATE_PROGRESS_RATE',

  // Objective Drawer
  ClickObjectiveDrawerInfoTab = 'CLICK_OPEN_OBJECTIVE_DRAWER_INFO_TAB',
  ClickOpenObjectiveDrawerWeightTab = 'CLICK_OPEN_OBJECTIVE_DRAWER_WEIGHT_TAB',
  ClickObjectiveDrawerNoteTab = 'CLICK_OPEN_OBJECTIVE_DRAWER_NOTE_TAB',
  ClickObjectiveDrawerActionPlanTab = 'CLICK_OPEN_OBJECTIVE_DRAWER_ACTION_PLAN_TAB',
  ClickObjectiveDrawerConfidenceTab = 'CLICK_OPEN_OBJECTIVE_DRAWER_CONFIDENCE_TAB',
  ClickObjectiveDrawerSettingTab = 'CLICK_OPEN_OBJECTIVE_DRAWER_SETTING_TAB',
  ClickObjectiveDrawerHistoryTab = 'CLICK_OPEN_OBJECTIVE_DRAWER_HISTORY_TAB',
  ClickObjectiveDrawerOkrMeetingTab = 'CLICK_OPEN_OBJECTIVE_DRAWER_OKR_MEETING_TAB',

  // KeyResult Drawer
  ClickKeyResultDrawerInfoTab = 'CLICK_OPEN_KEY_RESULT_DRAWER_INFO_TAB',
  ClickKeyResultDrawerNoteTab = 'CLICK_OPEN_KEY_RESULT_DRAWER_NOTE_TAB',
  ClickKeyResultDrawerActionPlanTab = 'CLICK_OPEN_KEY_RESULT_DRAWER_ACTION_PLAN_TAB',
  ClickKeyResultDrawerConfidenceTab = 'CLICK_OPEN_KEY_RESULT_DRAWER_CONFIDENCE_TAB',
  ClickKeyResultDrawerSettingTab = 'CLICK_OPEN_KEY_RESULT_DRAWER_SETTING_TAB',
  ClickKeyResultDrawerHistoryTab = 'CLICK_OPEN_KEY_RESULT_DRAWER_HISTORY_TAB',
  ClickKeyResultDrawerOkrMeetingTab = 'CLICK_OPEN_KEY_RESULT_DRAWER_OKR_MEETING_TAB',

  // Checkin
  ClickOpenCheckin = 'CLICK_OPEN_BULK_UPDATE',
  ClickCompletedButtonOnCheckinModal = 'CLICK_COMPLETED_BUTTON_ON_BULK_UPDATE_MODAL',
  ClickBackButtonOnCheckinModal = 'CLICK_COMPLETED_BUTTON_ON_BULK_UPDATE_MODAL',

  // OKR Modal Objective
  ClickOpenOkrModalByObjectiveName = 'CLICK_OPEN_OKR_MODAL_BY_OBJECTIVE_NAME',
  ClickOpenObjectiveDetailTabInObjectiveDrawer = 'CLICK_OPEN_OBJECTIVE_DETAIL_TAB_IN_OBJECTIVE_DRAWER',
  ClickOpenNoteTabInObjectiveDrawer = 'CLICK_OPEN_NOTE_TAB_IN_OBJECTIVE_DRAWER',
  ClickOpenActivityTabInObjectiveDrawer = 'CLICK_OPEN_ACTIVITY_TAB_IN_OBJECTIVE_DRAWER',
  ClickOpenQuickUpdateModalInOkrModalByProgress = 'CLICK_OPEN_QUICK_UPDATE_MODAL_IN_OKR_MODAL_BY_PROGRESS',
  ClickOpenChildOkrSortModalInOkrModal = 'CLICK_OPEN_CHILD_OKR_SORT_MODAL_IN_OKR_MODAL',
  ClickCloseChildOkrSortModal = 'CLICK_CLOSE_CHILD_OKR_SORT_MODAL',
  SetSortTheChildOkrByObjectiveModal = 'UPDATE_CHILD_OKR_SORT_IN_OKR_MODAL',
  ClickOpenWeightSettingModalInOkrModal = 'CLICK_OPEN_WEIGHT_SETTING_MODAL_IN_OKR_MODAL',
  ClickUpdateWeightInOkrModal = 'CLICK_UPDATE_WEIGHT_IN_OKR_MODAL',
  ClickCloseWeightSettingModal = 'CLICK_CLOSE_WEIGHT_SETTING_MODAL',
  ClickShowCheckinSummaryPageInOkrModal = 'CLICK_SHOW_CHECKIN_SUMMERY_PAGE_IN_OKR_MODAL',
  ClickObjectiveDescriptionEditButtonByObjectiveDrawer = 'CLICK_OBJECTIVE_DESCRIPTION_EDIT_BUTTON_BY_OBJECTIVE_DRAWER',
  ClickCreateActionPlanByObjectiveDrawer = 'CLICK_CREATE_ACTION_PLAN_BUTTON_BY_OBJECTIVE_DRAWER',
  ClickCreatePersonalNoteByObjectiveDrawer = 'CLICK_CREATE_PERSONAL_NOTE_BY_OBJECTIVE_DRAWER',
  ClickShowMoreActivityByObjectiveDrawer = 'CLICK_SHOW_MORE_ACTIVITY_BUTTON_BY_OBJECTIVE_DRAWER',
  ClickOpenObjectiveUpdateModalByObjectiveModal = 'CLICK_OPEN_OBJECTIVE_UPDATE_MODAL_BY_OBJECTIVE_MODAL',
  ClickShowExternalSampleOkrPageByObjectiveCrateModal = 'CLICK_SHOW_EXTERNAL_SAMPLE_OKR_PAGE_BY_OBJECTIVE_CREATE_MODAL',
  ClickShowExternalSampleOkrPageByObjectiveUpdateModal = 'CLICK_SHOW_EXTERNAL_SAMPLE_OKR_PAGE_BY_OBJECTIVE_UPDATE_MODAL',
  ClickShowExternalAddUserPageByObjectiveCreateModal = 'CLICK_SHOW_EXTERNAL_ADD_USER_PAGE_BY_OBJECTIVE_CREATE_MODAL',
  ClickShowExternalAddUserPageByObjectiveUpdateModal = 'CLICK_SHOW_EXTERNAL_ADD_USER_PAGE_BY_OBJECTIVE_UPDATE_MODAL',
  ClickCloseObjectiveCreateModal = 'CLICK_CLOSE_OBJECTIVE_CREATE_MODAL',
  ClickCloseObjectiveUpdateModal = 'CLICK_CLOSE_OBJECTIVE_UPDATE_MODAL',
  ClickCreateObjectiveByObjectiveCreateModal = 'CLICK_CREATE_OBJECTIVE_BY_OBJECTIVE_CREATE_MODAL',
  ClickDisableObjectiveByObjectiveUpdateModal = 'CLICK_DISABLE_OBJECTIVE_BY_OBJECTIVE_UPDATE_MODAL',
  ClickEnableObjectiveByObjectiveUpdateModal = 'CLICK_ENABLE_OBJECTIVE_BY_OBJECTIVE_UPDATE_MODAL',
  ClickDeleteObjectiveByObjectiveUpdateModal = 'CLICK_DELETE_OBJECTIVE_BY_OBJECTIVE_UPDATE_MODAL',
  ClickUpdateObjectiveByObjectiveUpdateModal = 'CLICK_UPDATE_OBJECTIVE_BY_OBJECTIVE_UPDATE_MODAL',

  ClickEditObjectiveDescriptionInObjectiveDrawer = 'CLICK_EDIT_OBJECTIVE_DESCRIPTION_IN_OBJECTIVE_DRAWER',
  ClickUpdateObjectiveDescriptionInObjectiveDrawer = 'CLICK_UPDATE_OBJECTIVE_DESCRIPTION_IN_OBJECTIVE_DRAWER',
  ClickCloseOkrModal = 'CLICK_CLOSE_OKR_MODAL',
  ClickCancelEditObjectiveUpdateModal = 'CLICK_CANCEL_EDIT_OBJECTIVE_UPDATE_MODAL',
  ClickCancelEditObjectiveDescriptionInObjectiveDrawer = 'CLICK_CANCEL_EDIT_OBJECTIVE_DESCRIPTION_IN_OBJECTIVE_DRAWER',

  // OKR Modal KeyResult
  ClickOpenOkrModalByKeyResultName = 'CLICK_OPEN_OKR_MODAL_BY_KEY_RESULT_NAME',
  ClickOpenKeyResultUpdateModalByKeyResultModal = 'CLICK_OPEN_KEY_RESULT_UPDATE_MODAL_BY_KEY_RESULT_MODAL',
  ClickOpenKeyResultDrawerInOkrModalByKeyResultName = 'CLICK_OPEN_KEY_RESULT_DRAWER_IN_OKR_MODAL_BY_KEY_RESULT_NAME',
  ClickOpenKeyResultEditModalByKeyResultModal = 'CLICK_OPEN_KEY_RESULT_EDIT_MODAL_BY_KEY_RESULT_MODAL',
  ClickOpenDetailTabInKeyResultDrawer = 'CLICK_OPEN_DETAIL_TAB_IN_KEY_RESULT_DRAWER',
  ClickOpenActionPlanTabInKeyResultDrawer = 'CLICK_OPEN_ACTION_PLAN_TAB_IN_KEY_RESULT_DRAWER',
  ClickOpenNoteTabInKeyResultDrawer = 'CLICK_OPEN_NOTE_TAB_IN_KEY_RESULT_DRAWER',
  ClickOpenActivityTabInKeyResultDrawer = 'CLICK_OPEN_ACTIVITY_TAB_IN_KEY_RESULT_DRAWER',
  ClickOpenQuickUpdateModalInKeyResultDrawerByProgressRateBar = 'CLICK_OPEN_QUICK_UPDATE_MODAL_IN_KEY_RESULT_DRAWER_BY_PROGRESS_RATE_BAR',
  ClickOpenQuickUpdateModalInkeyResultDrawerByProgressRateButton = 'CLICK_OPEN_QUICK_UPDATE_MODAL_IN_KEY_RESULT_DRAWER_BY_PROGRESS_RATE_BUTTON',
  ClickCreateActionPlanInKeyResultDrawer = 'CLICK_CREATE_ACTION_PLAN_IN_KEY_RESULT_DRAWER',
  ClickCreatePersonalNoteInKeyResultDrawer = 'CLICK_CREATE_PERSONAL_NOTE_IN_KEY_RESULT_DRAWER',
  ClickShowMoreActivityByKeyResultDrawer = 'CLICK_SHOW_MORE_ACTIVITY_BY_KEY_RESULT_DRAWER',
  ClickEditKeyResultDescriptionInKeyResultDrawer = 'CLICK_EDIT_KEY_RESULT_DESCRIPTION_IN_KEY_RESULT_DRAWER',
  ClickUpdateKeyResultDescriptionInObjectiveDrawer = 'CLICK_UPDATE_KEY_RESULT_DESCRIPTION_IN_OBJECTIVE_DRAWER',
  ClickCancelEditKeyResultDescriptionInObjectiveDrawer = 'CLICK_CANCEL_EDIT_KEY_RESULT_DESCRIPTION_IN_OBJECTIVE_DRAWER',
  ClickReflectChildOkrProgressRateInKeyResultDrawer = 'CLICK_REFLECT_CHILD_OKR_PROGRESS_RATE_IN_KEY_RESULT_DRAWER',
  ClickCloseKeyResultDrawer = 'CLICK_CLOSE_KEY_RESULT_DRAWER',
  ClickCancelEditKeyResultDescriptionInKeyResultDrawer = 'CLICK_CANCEL_EDIT_KEY_RESULT_DESCRIPTION_IN_KEY_RESULT_DRAWER',

  // OKR Modal Checkin
  ClickFetchMoreCheckinSummaryInOkrModal = 'CLICK_FETCH_MORE_CHECKIN_SUMMARY_IN_OKR_MODAL',

  // Key Result Create Modal
  ClickShowExternalSampleOkrPageByKeyResultCreateModal = 'CLICK_SHOW_EXTERNAL_SAMPLE_OKR_PAGE_BY_KEY_RESULT_CREATE_MODAL',
  ClickCloseKeyResultCreateModal = 'CLICK_CLOSE_KEY_RESULT_CREATE_MODAL',
  ClickCreateKeyResultByKeyResultCreateModal = 'CLICK_CREATE_KEY_RESULT_BY_KEY_RESULT_CREATE_MODAL',

  // Key Result Update Modal
  ClickShowExternalSampleOkrPageByKeyResultUpdateModal = 'CLICK_SHOW_EXTERNAL_SAMPLE_OKR_PAGE_BY_KEY_RESULT_UPDATE_MODAL',
  ClickCloseKeyResultUpdateModal = 'CLICK_CLOSE_KEY_RESULT_UPDATE_MODAL',
  ClickDisableKeyResultByKeyResultUpdateModal = 'CLICK_DISABLE_KEY_RESULT_BY_KEY_RESULT_UPDATE_MODAL',
  ClickEnableKeyResultByKeyResultUpdateModal = 'CLICK_ENABLE_KEY_RESULT_BY_KEY_RESULT_UPDATE_MODAL',
  ClickDeleteKeyResultByKeyResultUpdateModal = 'CLICK_DELETE_KEY_RESULT_BY_KEY_RESULT_UPDATE_MODAL',
  ClickUpdateKeyResultByKeyResultUpdateModal = 'CLICK_UPDATE_KEY_RESULT_BY_KEY_RESULT_UPDATE_MODAL',
  ClickCancelEditKeyResultUpdateModal = 'CLICK_CANCEL_EDIT_KEY_RESULT_UPDATE_MODAL',

  // Bulk Update
  ClickOpenBulkUpdate = 'CLICK_OPEN_BULK_UPDATE',
  ClickCompletedButtonOnBulkUpdateModal = 'CLICK_COMPLETED_BUTTON_ON_BULK_UPDATE_MODAL',
  ClickBackButtonOnBulkUpdateModal = 'CLICK_COMPLETED_BUTTON_ON_BULK_UPDATE_MODAL',

  ClickExportTsvFile = 'CLICK_EXPORT_TSV_FILE',

  // For Home
  ClickTabInHome = 'CLICK_TAB_IN_HOME',
  ClickSortButtonOnNoteTab = 'CLICK_SORT_BUTTON_ON_NOTES_TAB',
  ClickRadioButtonInHome = 'CLICK_RADIO_BUTTON_IN_HOME',
  ClickMoreButtonInHome = 'CLICK_MORE_BUTTON_IN_HOME',

  // For SelfServe
  ClickCheckSubscriptionPlan = 'CLICK_CHECK_SUBSCRIPTION_PLAN',
  ClickBookTutorialSession = 'CLICK_BOOK_TUTORIAL_SESSION',

  // For 1on1
  ClickOpenOneOnOneDrawer = 'CLICK_OPEN_ONE_ON_ONE_DRAWER',
  ClickOneOnOneHistoryLink = 'CLICK_ONE_ON_ONE_HISTORY_LINK',
}

// Selectイベント
enum SelectEvent {
  // For OKR MAP
  OkrTerm = 'SELECT_OKR_TERM',
  FocusGroup = 'SELECT_FOCUS_GROUP',
  FocusUser = 'SELECT_FOCUS_USER',

  // For Users
  FilterGroup = 'FILTER_GROUP',
  FilterUser = 'FILTER_USER',
}

// configure Sentry SDK
export const sentryConf = configureSentry()

// initialize mixpanel client
mixpanel.init(config.mixpanelTrackingCode, {
  ignore_dnt: true,
  cookie_domain: configureDomain().appDomain,
  cross_site_cookie: true,
})

// initialize GA client
if (!isTest()) {
  ReactGA.initialize(config.googleAnalyticsTrackingCode)
}

// トラッキング用初期パラメータを設定する関数
// set_once()を使っているので、すでに存在するPropertyを上書きしないので複数呼ばれても問題無い
// See: https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelpeopleset_once
const setDefaultUserProperty = (): void => {
  mixpanel.people.set_once('Allow To Send Email', true)
}

type TrackIdentifyParam = {
  userId: string
  firstName: string
  lastName: string
  email: string
  createdAt: Date
  organizationId: string
  organizationName: string
  isTrial: boolean
  isSelfServe: boolean
}

// ユーザ個人をトラッキングするために必要な識別子をセットする
export const initTracking = ({
  userId,
  firstName,
  lastName,
  email,
  createdAt,
  organizationId,
  organizationName,
  isTrial,
  isSelfServe,
}: TrackIdentifyParam): void => {
  const decodedUserId = decodeId(userId)
  const decodedOrganizationId = decodeId(organizationId)

  // For GA
  ReactGA.set({
    userId: decodedUserId,
    dimension1: decodedOrganizationId,
    dimension2: organizationName,
    dimension3: isTrial,
    dimension4: isSelfServe,
  })

  // For Mixpanel
  mixpanel.identify(decodedUserId.toString())
  mixpanel.people.set({
    OrganizationId: decodedOrganizationId,
    Organization: organizationName,
    TrialAccount: isTrial,
    SelfServeAccount: isSelfServe,
    LastAccessedDate: new Date(),
  })
  setDefaultUserProperty()

  // For Sentry
  setUser({
    id: decodedUserId.toString(),
    username: `${firstName} ${lastName}`,
    email,
  })

  // For OpenReplay
  const tracker = OpenReplayTracker.getTracker()
  tracker?.start({
    userParam: {
      userId: decodedUserId,
      name: `${firstName} ${lastName}`,
      email,
      Organization: organizationName,
      OrganizationId: decodedOrganizationId,
      TrialAccount: isTrial,
      SelfServeAccount: isSelfServe,
    },
  })
  overrideGlobalFetch()

  // For User Guiding
  if (window != null && window.userGuiding != null) {
    window.userGuiding.identify(decodedUserId.toString(), {
      organization_id: decodedOrganizationId,
      name: `${lastName} ${firstName}`,
      email,
      trial_account: isTrial,
      self_serve_account: isSelfServe,
      created_at:
        // FIXME: createdAtが実際にはstringで来ている。自分をDate型だと思いこんでる
        typeof createdAt === 'string' ? new Date(createdAt).getTime() : createdAt.getTime(),
      company_name: organizationName,
      environment: process.env.REACT_APP_ENV,
    })
  }
}

// 最終アクセス日時を更新する
export const updateLastSeen = (): void => {
  const now = new Date()
  // For mixpanel
  mixpanel.people.set({
    LastAccessedDate: now,
  })
}

// integration function for web-vitals
export function sendToAnalytics({
  id,
  name,
  value,
}: {
  id: string
  name: string
  value: number
}): void {
  ReactGA.ga('send', 'event', {
    eventCategory: 'Web Vitals',
    eventAction: name,
    eventValue: Math.round(name === 'CLS' ? value * 1000 : value),
    eventLabel: id,
    nonInteraction: true,
  })
}

// PVを透過的に扱えるようにするためのfunction
export const trackPageView = (location: Location, screen: Screen): void => {
  // GA tracking
  const page = location.pathname || window.location.pathname
  ReactGA.set({ page })
  ReactGA.pageview(page)

  // Mixpanel tracking
  try {
    /**
     * @note
     * mixpanelのデフォルトの挙動では、初回に検出されたutmパラメータが保存され、
     * 以降のアクセスでutmパラメータが付与されていたとしてもその値で上書きされることは無い。
     * メールにutmパラメータを付与した場合など、"そのアクセス単体での計測"をしたい場合に備え、
     * アクセスごとのutmパラメータを別途event propertyに設定する。
     *
     * https://help.mixpanel.com/hc/en-us/articles/115004561786
     */
    const utmParams = new Map<string, string>()
    const params = new URLSearchParams(location.search)
    // eslint-disable-next-line no-restricted-syntax
    for (const [k, v] of params.entries()) {
      if (k.indexOf('utm_') !== -1) {
        utmParams.set(`current_${k}`, v)
      }
    }

    mixpanel.people.increment('page_views')
    mixpanel.track('Page View', {
      Page: page,
      Screen: screen,
      Environment: 'resily_new',
      ...Object.fromEntries(utmParams),
    })
  } catch (e) {
    // DO NOTHING, ignore error
  }
}

type EventOptionalProperty = {
  label?: string
  value?: number
  // For mixpanel
  properties?: Record<string, string | boolean>
}

// トラッキングイベントのbase code
const clickEvent = (event: ClickEvent, fromScreen: Screen, option?: EventOptionalProperty) => {
  if (isDevelopment() || isInternalEnvironment()) {
    // eslint-disable-next-line no-console
    console.info('event tracker: ', {
      Screen: fromScreen,
      action: event,
      option: {
        ...option,
      },
    })
  }
  ReactGA.event({
    category: fromScreen,
    action: event,
    ...option,
  })
  mixpanel.track(event, {
    Screen: fromScreen,
    ...option,
  })
}

// Item選択時のトラッキングイベントbase code
const selectEvent = (event: SelectEvent, fromScreen: Screen, option?: EventOptionalProperty) => {
  ReactGA.event({
    category: fromScreen,
    action: event,
    ...option,
  })
  mixpanel.track(event, {
    Screen: fromScreen,
    ...option,
  })
}

export const tracker = {
  // General Events
  // OKR追加
  ClickAddOKR: (screen: Screen): void => clickEvent(ClickEvent.AddOkr, screen),
  // 子のOKR追加
  ClickAddOKRAsChild: (screen: Screen): void => clickEvent(ClickEvent.AddOkrAsChild, screen),
  // 進捗率の更新
  UpdateProgressRate: (screen: Screen, keyResultId: string, objectiveId: string): void => {
    clickEvent(ClickEvent.ClickUpdateProgressRate, screen, {
      properties: {
        keyResultId: decodeId(keyResultId).toString(),
        objectiveId: decodeId(objectiveId).toString(),
      },
    })
  },
  // コンフィデンスの投稿
  UpdateConfidence: (keyResultId: string, objectiveId: string): void =>
    clickEvent(ClickEvent.ClickUpdateConfidence, Screen.User, {
      properties: {
        keyResultId: decodeId(keyResultId).toString(),
        objectiveId: decodeId(objectiveId).toString(),
      },
    }),
  // アクションプランの作成
  CreateActionPlan: (screen: Screen): void => {
    clickEvent(ClickEvent.ClickCreateActionPlan, screen)
  },
  // 個人ノートを作成
  CreatePersonalNote: (screen: Screen): void =>
    clickEvent(ClickEvent.ClickCreatePersonalNote, screen),

  // グローバルナビゲーションのイベント
  GlobalNavTransitionToAllTree: (): void =>
    clickEvent(ClickEvent.ClickGlobalNavAllTree, Screen.NULL),
  GlobalNavTransitionToGroupTree: (): void =>
    clickEvent(ClickEvent.ClickGlobalNavGroupTree, Screen.NULL),
  GlobalNavTransitionToBookmarkTree: (): void =>
    clickEvent(ClickEvent.ClickGlobalNavBookmarkTree, Screen.NULL),
  GlobalNavTransitionToMyTree: (): void => clickEvent(ClickEvent.ClickGlobalNavMyTree, Screen.NULL),
  GlobalNavTransitionToHome: (): void => clickEvent(ClickEvent.ClickGlobalNavHome, Screen.NULL),
  GlobalNavTransitionToNotification: (): void =>
    clickEvent(ClickEvent.ClickGlobalNavNotification, Screen.NULL),
  GlobalNavTransitionToUsers: (): void => clickEvent(ClickEvent.ClickGlobalNavUsers, Screen.NULL),
  GlobalNavTransitionToOneOnOne: (): void =>
    clickEvent(ClickEvent.ClickGlobalNavOneOnOne, Screen.NULL),
  GlobalNavTransitionToOrganizationSetting: (): void =>
    clickEvent(ClickEvent.ClickGlobalNavOrganizationSetting, Screen.NULL),
  GlobalNavTransitionToUserSetting: (): void =>
    clickEvent(ClickEvent.ClickGlobalNavUserSetting, Screen.NULL),
  GlobalNavTransitionToHelpPage: (): void =>
    clickEvent(ClickEvent.ClickGlobalNavHelpPage, Screen.NULL),
  GlobalNavSignOut: (): void => clickEvent(ClickEvent.ClickGlobalNavSignOut, Screen.NULL),

  // OKRマップ内のイベント
  // グループフィルタを選択
  OkrMapSelectFocusGroup: (screen: Screen): void => selectEvent(SelectEvent.FocusGroup, screen),
  // ユーザーフィルタを選択
  OkrMapSelectFocusUser: (screen: Screen): void => selectEvent(SelectEvent.FocusUser, screen),
  // OKRページリンククリック
  OkrMapClickOkrPage: (screen: Screen, isAssigned: boolean, useWeighting: boolean): void =>
    clickEvent(ClickEvent.ShowOkrPage, screen, { properties: { isAssigned, useWeighting } }),

  // OKRマップのイベント
  OkrMapClickDotMenu: (isAssigned: boolean, useWeighting: boolean): void =>
    clickEvent(ClickEvent.OpenDotMenu, Screen.OkrMapTree, {
      properties: { isAssigned, useWeighting },
    }),
  OkrMapClickDotMenuShowOkrPage: (isAssigned: boolean, useWeighting: boolean): void =>
    clickEvent(ClickEvent.OpenDotMenuShowOkrPage, Screen.OkrMapTree, {
      properties: { isAssigned, useWeighting },
    }),
  OkrMapClickDotMenuExpandDot: (isAssigned: boolean, useWeighting: boolean): void =>
    clickEvent(ClickEvent.OpenDotMenuExpandDot, Screen.OkrMapTree, {
      properties: { isAssigned, useWeighting },
    }),
  OkrMapClickDotMenuShowInfo: (isAssigned: boolean, useWeighting: boolean): void =>
    clickEvent(ClickEvent.OpenDotMenuShowInfo, Screen.OkrMapTree, {
      properties: { isAssigned, useWeighting },
    }),
  OkrMapClickMiniMap: (): void => clickEvent(ClickEvent.MiniMap, Screen.OkrMapTree),

  // ユーザー一覧内のイベント
  // グループフィルタ
  UsersFilterGroup: (screen: Screen): void => selectEvent(SelectEvent.FilterGroup, screen),
  // ユーザーフィルタ
  UsersFilterUser: (screen: Screen): void => selectEvent(SelectEvent.FilterUser, screen),

  // ユーザーページ内でのイベント
  // OKRページリンククリック
  UserClickOkrPage: (isAssigned: boolean, useWeighting: boolean): void =>
    clickEvent(ClickEvent.ShowOkrPage, Screen.User, { properties: { isAssigned, useWeighting } }),
  // OKRマップリンククリック
  UserClickOkrMap: (): void => clickEvent(ClickEvent.ShowKRNote, Screen.User),
  // KRノートクリック
  UserClickShowKRNotes: (): void => clickEvent(ClickEvent.ShowOkrMap, Screen.User),

  // ユーザー通知設定でのイベント
  // 通知設定のON/OFF
  NotificationSettingToggleSubscribe: (isAllow: boolean): void =>
    mixpanel.people.set('Allow To Send Email', isAllow),

  // Oのドロワー
  // 情報タブをクリック
  UserClickObjectiveDrawerInfoTab: (
    screen: Screen,
    isAssigned: boolean,
    useWeighting: boolean,
  ): void => {
    clickEvent(ClickEvent.ClickObjectiveDrawerInfoTab, screen, {
      properties: { isAssigned, useWeighting },
    })
  },
  // 重み付け設定タブをクリック
  UserClickObjectiveDrawerWeightTab: (screen: Screen, isAssigned: boolean): void => {
    clickEvent(ClickEvent.ClickOpenObjectiveDrawerWeightTab, screen, { properties: { isAssigned } })
  },
  // ノートタブをクリック
  UserClickObjectiveDrawerNoteTab: (screen: Screen, isAssigned: boolean): void => {
    clickEvent(ClickEvent.ClickObjectiveDrawerNoteTab, screen, { properties: { isAssigned } })
  },
  // アクションプランタブをクリック
  UserClickObjectiveDrawerActionPlanTab: (screen: Screen, isAssigned: boolean): void => {
    clickEvent(ClickEvent.ClickObjectiveDrawerActionPlanTab, screen, { properties: { isAssigned } })
  },
  // コンフィデンスタブをクリック
  UserClickObjectiveDrawerConfidenceTab: (screen: Screen, isAssigned: boolean): void => {
    clickEvent(ClickEvent.ClickObjectiveDrawerConfidenceTab, screen, { properties: { isAssigned } })
  },
  // 設定タブをクリック
  UserClickObjectiveDrawerSettingTab: (screen: Screen, isAssigned: boolean): void => {
    clickEvent(ClickEvent.ClickObjectiveDrawerSettingTab, screen, { properties: { isAssigned } })
  },
  //  履歴タブをクリック
  UserClickObjectiveDrawerHistoryTab: (screen: Screen, isAssigned: boolean): void => {
    clickEvent(ClickEvent.ClickObjectiveDrawerHistoryTab, screen, { properties: { isAssigned } })
  },
  //  OKRミーティングタブをクリック
  UserClickObjectiveDrawerOkrMeetingTab: (screen: Screen, isAssigned: boolean): void => {
    clickEvent(ClickEvent.ClickObjectiveDrawerOkrMeetingTab, screen, {
      properties: { isAssigned },
    })
  },

  // KRのドロワー
  // 情報タブをクリック
  UserClickKeyResultDrawerInfoTab: (screen: Screen, isAssigned: boolean): void => {
    clickEvent(ClickEvent.ClickKeyResultDrawerInfoTab, screen, { properties: { isAssigned } })
  },
  // ノートタブをクリック
  UserClickKeyResultDrawerNoteTab: (screen: Screen, isAssigned: boolean): void => {
    clickEvent(ClickEvent.ClickKeyResultDrawerNoteTab, screen, { properties: { isAssigned } })
  },
  // アクションプランタブをクリック
  UserClickKeyResultDrawerActionPlanTab: (screen: Screen, isAssigned: boolean): void => {
    clickEvent(ClickEvent.ClickKeyResultDrawerActionPlanTab, screen, { properties: { isAssigned } })
  },
  // コンフィデンスタブをクリック
  UserClickKeyResultDrawerConfidenceTab: (screen: Screen, isAssigned: boolean): void => {
    clickEvent(ClickEvent.ClickKeyResultDrawerConfidenceTab, screen, { properties: { isAssigned } })
  },
  // 設定タブをクリック
  UserClickKeyResultDrawerSettingTab: (screen: Screen, isAssigned: boolean): void => {
    clickEvent(ClickEvent.ClickKeyResultDrawerSettingTab, screen, { properties: { isAssigned } })
  },
  // 履歴タブをクリック
  UserClickKeyResultDrawerHistoryTab: (screen: Screen, isAssigned: boolean): void => {
    clickEvent(ClickEvent.ClickKeyResultDrawerHistoryTab, screen, { properties: { isAssigned } })
  },
  // OKRモーダル
  // Objective名をクリックしてOKRモーダルを開く
  UserClickOpenOkrModalByObjectiveName: (screen: Screen, isAssigned: boolean): void => {
    clickEvent(ClickEvent.ClickOpenOkrModalByObjectiveName, screen, { properties: { isAssigned } })
  },
  // ObjectiveモーダルでObjective詳細タグをクリック
  UserClickObjectiveDetailTabByObjectiveModal: (screen: Screen): void => {
    clickEvent(ClickEvent.ClickOpenObjectiveDetailTabInObjectiveDrawer, screen)
  },
  // Objectiveモーダルでノートタグをクリック
  UserClickNoteTabByObjectiveModal: (screen: Screen): void => {
    clickEvent(ClickEvent.ClickOpenNoteTabInObjectiveDrawer, screen)
  },
  // Objectiveモーダルでアクティビティタグをクリック
  UserClickActivityTabByObjectiveModal: (screen: Screen): void => {
    clickEvent(ClickEvent.ClickOpenActivityTabInObjectiveDrawer, screen)
  },
  // Objectiveモーダルでプログレスバーをクリック
  UserClickProgressRateByObjectiveModal: (screen: Screen): void => {
    clickEvent(ClickEvent.ClickOpenQuickUpdateModalInOkrModalByProgress, screen)
  },
  // 子OKR並び替えモーダルを開く
  UserClickSortTheChildOkrByObjectiveModal: (screen: Screen): void => {
    clickEvent(ClickEvent.ClickOpenChildOkrSortModalInOkrModal, screen)
  },
  // 子OKR並び替えモーダルを閉じる
  UserClickCloseSortTheChildOkrByObjectiveModal: (screen: Screen): void => {
    clickEvent(ClickEvent.ClickCloseChildOkrSortModal, screen)
  },
  // 子OKRを並び替える
  UserSetSortTheChildOkrByObjectiveModal: (screen: Screen): void => {
    clickEvent(ClickEvent.SetSortTheChildOkrByObjectiveModal, screen)
  },
  // 重み付け設定モーダルを開く
  UserClickWeightAllocationByObjectiveModal: (screen: Screen): void => {
    clickEvent(ClickEvent.ClickOpenWeightSettingModalInOkrModal, screen)
  },
  // 重み付け設定モーダルを閉じる
  UserClickCloseWeightAllocationByObjectiveModal: (screen: Screen): void => {
    clickEvent(ClickEvent.ClickCloseWeightSettingModal, screen)
  },
  // 重み付けを設定する
  UserSetWeightAllocationByObjectiveModal: (screen: Screen): void => {
    clickEvent(ClickEvent.ClickUpdateWeightInOkrModal, screen)
  },
  // チェックインの各ボタンをクリック
  UserClickCheckInSummeryButtonByObjectiveModal: (screen: Screen): void => {
    clickEvent(ClickEvent.ClickShowCheckinSummaryPageInOkrModal, screen)
  },
  // OKRモーダル内のアクションプランの作成をクリック
  UserClickCreateActionPlanByObjectiveDrawer: (screen: Screen): void => {
    clickEvent(ClickEvent.ClickCreateActionPlanByObjectiveDrawer, screen)
  },
  // OKRモーダル内のノートの作成をクリック
  UserClickCreatePersonalNoteByObjectiveDrawer: (screen: Screen): void => {
    clickEvent(ClickEvent.ClickCreatePersonalNoteByObjectiveDrawer, screen)
  },
  // OKRモーダルのアクティビティで「さらに表示」をクリック
  UserClickShowMoreActivityByObjectiveDrawer: (screen: Screen): void => {
    clickEvent(ClickEvent.ClickShowMoreActivityByObjectiveDrawer, screen)
  },
  // OKRモーダルでObjectiveの編集アイコンをクリック
  UserClickOpenObjectiveUpdateModalByObjectiveModal: (screen: Screen): void => {
    clickEvent(ClickEvent.ClickOpenObjectiveUpdateModalByObjectiveModal, screen)
  },
  // OKRモーダルでObjectiveの達成基準と計測方法の編集アイコンをクリック
  UserClickObjectiveDescriptionEditButtonByObjectiveDrawer: (screen: Screen): void => {
    clickEvent(ClickEvent.ClickEditObjectiveDescriptionInObjectiveDrawer, screen)
  },
  // Objective作成モーダルで「サンプルを見る」リンクをクリック
  UserClickShowExternalSampleOkrPageByObjectiveCrateModal: (screen: Screen): void => {
    clickEvent(ClickEvent.ClickShowExternalSampleOkrPageByObjectiveCrateModal, screen)
  },
  // Objective編集モーダルで「サンプルを見る」リンクをクリック
  UserClickShowExternalSampleOkrPageByObjectiveUpdateModal: (screen: Screen): void => {
    clickEvent(ClickEvent.ClickShowExternalSampleOkrPageByObjectiveUpdateModal, screen)
  },
  // Objective作成モーダルの編集者のツールチップの「詳細はこちら」リンクをクリック
  UserClickShowExternalAddUserPageByObjectiveCreateModal: (screen: Screen): void => {
    clickEvent(ClickEvent.ClickShowExternalAddUserPageByObjectiveCreateModal, screen)
  },
  // Objective編集モーダルの編集者のツールチップの「詳細はこちら」リンクをクリック
  UserClickShowExternalAddUserPageByObjectiveUpdateModal: (screen: Screen): void => {
    clickEvent(ClickEvent.ClickShowExternalAddUserPageByObjectiveUpdateModal, screen)
  },
  // Objective作成モーダルを閉じる
  UserClickCloseObjectiveCreateModal: (screen: Screen): void => {
    clickEvent(ClickEvent.ClickCloseObjectiveCreateModal, screen)
  },
  // Objective編集モーダルを閉じる
  UserClickCloseObjectiveUpdateModal: (screen: Screen): void => {
    clickEvent(ClickEvent.ClickCloseObjectiveUpdateModal, screen)
  },
  // Objective作成モーダルでObjectiveを作成
  UserClickCreateObjectiveByObjectiveCreateModal: (
    screen: Screen,
    needsCreateKeyResults: boolean,
  ): void => {
    clickEvent(ClickEvent.ClickCreateObjectiveByObjectiveCreateModal, screen, {
      properties: { needsCreateKeyResults },
    })
  },
  // Objective編集モーダルでObjective無効化ボタンをクリック
  UserClickDisableObjectiveByObjectiveUpdateModal: (screen: Screen): void => {
    clickEvent(ClickEvent.ClickDisableObjectiveByObjectiveUpdateModal, screen)
  },
  // Objective編集モーダルでObjective有効化ボタンをクリック
  UserClickEnableObjectiveByObjectiveUpdateModal: (screen: Screen): void => {
    clickEvent(ClickEvent.ClickEnableObjectiveByObjectiveUpdateModal, screen)
  },
  // Objective編集モーダルでObjective削除ボタンをクリック
  UserClickDeleteObjectiveByObjectiveUpdateModal: (screen: Screen): void => {
    clickEvent(ClickEvent.ClickDeleteObjectiveByObjectiveUpdateModal, screen)
  },
  // Objective編集モーダルで保存ボタンをクリック
  UserClickUpdateObjectiveByObjectiveUpdateModal: (screen: Screen): void => {
    clickEvent(ClickEvent.ClickUpdateObjectiveByObjectiveUpdateModal, screen)
  },
  // OKRモーダルでObjectiveの達成基準と計測方法の保存ボタンをクリック
  UserClickObjectiveDescriptionSaveButtonByObjectiveDrawer: (screen: Screen): void => {
    clickEvent(ClickEvent.ClickUpdateObjectiveDescriptionInObjectiveDrawer, screen)
  },
  // OKRモーダルでObjectiveの達成基準と計測方法のキャンセルボタンをクリック
  UserClickObjectiveDescriptionCancelButtonByObjectiveDrawer: (screen: Screen): void => {
    clickEvent(ClickEvent.ClickCancelEditObjectiveDescriptionInObjectiveDrawer, screen)
  },
  // OKRモーダルを閉じる
  UserClickCloseOkrModal: (screen: Screen): void => {
    clickEvent(ClickEvent.ClickCloseOkrModal, screen)
  },
  // Objective更新モーダルで編集破棄する
  UserClickCancelEditObjectiveUpdateModal: (screen: Screen): void => {
    clickEvent(ClickEvent.ClickCancelEditObjectiveUpdateModal, screen)
  },
  // Objectiveの説明を編集破棄する
  UserClickCancelEditObjectiveDescriptionInObjectiveDrawer: (screen: Screen): void => {
    clickEvent(ClickEvent.ClickCancelEditObjectiveDescriptionInObjectiveDrawer, screen)
  },
  // KeyResult名をクリックしてOKRモーダルを開く
  UserClickOpenOkrModalByKeyResultName: (screen: Screen, isAssigned?: boolean): void => {
    const properties: { isAssigned?: boolean } = {}
    // HomeActivityの場合は、isAssignedを取得するのにDBに負荷をかけてしまうため
    // HomeActivity以外のときにisAssignedを取得するようにする
    if (screen !== Screen.HomeActivity) {
      properties.isAssigned = isAssigned
    }
    clickEvent(ClickEvent.ClickOpenOkrModalByKeyResultName, screen, { properties })
  },
  // OKRモーダル内のKeyResult名をクリック
  UserClickOkrModalInKeyResultName: (screen: Screen): void => {
    clickEvent(ClickEvent.ClickOpenKeyResultDrawerInOkrModalByKeyResultName, screen)
  },
  // KeyResult編集モーダルを開く
  UserClickOpenKeyResultUpdateModalByKeyResultModal: (screen: Screen): void => {
    clickEvent(ClickEvent.ClickOpenKeyResultUpdateModalByKeyResultModal, screen)
  },
  // KRドロワー内の詳細タブをクリック
  UserClickDetailTabByKeyResultDrawer: (screen: Screen): void => {
    clickEvent(ClickEvent.ClickOpenDetailTabInKeyResultDrawer, screen)
  },
  // KRドロワー内のアクションプランタブをクリック
  UserClickActionPlanTabByKeyResultDrawer: (screen: Screen): void => {
    clickEvent(ClickEvent.ClickOpenActionPlanTabInKeyResultDrawer, screen)
  },
  // KRドロワー内のノートタブをクリック
  UserClickNoteTabByKeyResultDrawer: (screen: Screen): void => {
    clickEvent(ClickEvent.ClickOpenNoteTabInKeyResultDrawer, screen)
  },
  // KRドロワー内のアクティビティタブをクリック
  UserClickActivityTabByKeyResultDrawer: (screen: Screen): void => {
    clickEvent(ClickEvent.ClickOpenActivityTabInKeyResultDrawer, screen)
  },
  // KRドロワー内のプログレスバーをクリック
  UserClickProgressRateByKeyResultDrawer: (screen: Screen): void => {
    clickEvent(ClickEvent.ClickOpenQuickUpdateModalInKeyResultDrawerByProgressRateBar, screen)
  },
  // KRドロワー内の進捗更新ボタンをクリック
  UserClickUpdateProgressRateButtonByKeyResultDrawer: (screen: Screen): void => {
    clickEvent(ClickEvent.ClickOpenQuickUpdateModalInkeyResultDrawerByProgressRateButton, screen)
  },
  // KRドロワー内のアクションプランの作成をクリック
  UserClickCreateActionPlanByKeyResultDrawer: (screen: Screen): void => {
    clickEvent(ClickEvent.ClickCreateActionPlanInKeyResultDrawer, screen)
  },
  // KRドロワー内のノート作成をクリック
  UserClickCreatePersonalNoteByKeyResultDrawer: (screen: Screen): void => {
    clickEvent(ClickEvent.ClickCreatePersonalNoteInKeyResultDrawer, screen)
  },
  // KRドロワー内のアクティビティで「さらに表示」をクリック
  UserClickShowMoreActivityByKeyResultDrawer: (screen: Screen): void => {
    clickEvent(ClickEvent.ClickShowMoreActivityByKeyResultDrawer, screen)
  },
  // KRドロワー内の達成基準と計測方法の編集アイコンをクリック
  UserClickKeyResultDescriptionEditButtonByKeyResultDrawer: (screen: Screen): void => {
    clickEvent(ClickEvent.ClickEditKeyResultDescriptionInKeyResultDrawer, screen)
  },
  // KRドロワー内の達成基準と計測方法の保存ボタンをクリック
  UserClickKeyResultDescriptionSaveButtonByKeyResultDrawer: (screen: Screen): void => {
    clickEvent(ClickEvent.ClickUpdateKeyResultDescriptionInObjectiveDrawer, screen)
  },
  // KRドロワー内の達成基準と計測方法のキャンセルボタンをクリック
  UserClickKeyResultDescriptionCancelButtonByKeyResultDrawer: (screen: Screen): void => {
    clickEvent(ClickEvent.ClickCancelEditKeyResultDescriptionInObjectiveDrawer, screen)
  },
  // KRドロワー内の下位OKRの進捗率を反映するボタンをクリック
  UserClickReflectChildOkrProgressRateByKeyResultDrawer: (screen: Screen): void => {
    clickEvent(ClickEvent.ClickReflectChildOkrProgressRateInKeyResultDrawer, screen)
  },
  // KRドロワーを閉じる
  UserClickCloseKeyResultDrawer: (screen: Screen): void => {
    clickEvent(ClickEvent.ClickCloseKeyResultDrawer, screen)
  },
  // KeyResultの説明を編集破棄する
  UserClickCancelEditKeyResultDescriptionInKeyResultDrawer: (screen: Screen): void => {
    clickEvent(ClickEvent.ClickCancelEditKeyResultDescriptionInKeyResultDrawer, screen)
  },
  // OKRモーダル内のCheckin さらに表示をクリック
  UserClickOkrModalInCheckinFetchMore: (screen: Screen): void => {
    clickEvent(ClickEvent.ClickFetchMoreCheckinSummaryInOkrModal, screen)
  },
  // KeyResult作成モーダルのサンプルを見るリンクをクリック
  UserClickShowExternalSampleOkrPageByKeyResultCreateModal: (screen: Screen): void => {
    clickEvent(ClickEvent.ClickShowExternalSampleOkrPageByKeyResultCreateModal, screen)
  },
  // KeyResult作成モーダルを閉じた
  UserClickCloseKeyResultCreateModal: (screen: Screen): void => {
    clickEvent(ClickEvent.ClickCloseKeyResultCreateModal, screen)
  },
  // KeyResult作成モーダルでKeyResultを作成した
  UserClickCreateKeyResultByKeyResultCreateModal: (
    screen: Screen,
    fromCreateObjective: boolean,
  ): void => {
    clickEvent(ClickEvent.ClickCreateKeyResultByKeyResultCreateModal, screen, {
      properties: { fromCreateObjective },
    })
  },
  // KeyResult更新モーダルのサンプルを見るリンクをクリック
  UserClickShowExternalSampleOkrPageByKeyResultUpdateModal: (screen: Screen): void => {
    clickEvent(ClickEvent.ClickShowExternalSampleOkrPageByKeyResultUpdateModal, screen)
  },
  // KeyResult編集モーダルを閉じた
  UserClickCloseKeyResultUpdateModal: (screen: Screen): void => {
    clickEvent(ClickEvent.ClickCloseKeyResultUpdateModal, screen)
  },
  // KeyResult編集モーダルでKeyResultを無効化した
  UserClickDisableKeyResultByKeyResultUpdateModal: (screen: Screen): void => {
    clickEvent(ClickEvent.ClickDisableKeyResultByKeyResultUpdateModal, screen)
  },
  // KeyResult編集モーダルでKeyResultを有効化した
  UserClickEnableKeyResultByKeyResultUpdateModal: (screen: Screen): void => {
    clickEvent(ClickEvent.ClickEnableKeyResultByKeyResultUpdateModal, screen)
  },
  // KeyResult編集モーダルでKeyResultを削除した
  UserClickDeleteKeyResultByKeyResultUpdateModal: (screen: Screen): void => {
    clickEvent(ClickEvent.ClickDeleteKeyResultByKeyResultUpdateModal, screen)
  },
  // KeyResult編集モーダルでKeyResultを更新した
  UserClickUpdateKeyResultByKeyResultUpdateModal: (screen: Screen): void => {
    clickEvent(ClickEvent.ClickUpdateKeyResultByKeyResultUpdateModal, screen)
  },
  // KeyResult更新モーダルで編集破棄
  UserClickCancelEditKeyResultUpdateModal: (screen: Screen): void => {
    clickEvent(ClickEvent.ClickCancelEditKeyResultUpdateModal, screen)
  },
  // 一括更新ボタンをクリック
  UserClickOpenCheckin: (screen: Screen): void => {
    clickEvent(ClickEvent.ClickOpenCheckin, screen)
  },
  // 一括更新モーダルで完了ボタンをクリック
  UserClickCompletedCheckin: (screen: Screen): void => {
    clickEvent(ClickEvent.ClickCompletedButtonOnCheckinModal, screen)
  },
  // 一括更新モーダルで戻るボタンをクリック
  UserClickBackCheckin: (screen: Screen): void => {
    clickEvent(ClickEvent.ClickBackButtonOnCheckinModal, screen)
  },
  UserClickExportTsvFile: (screen: Screen): void => {
    clickEvent(ClickEvent.ClickExportTsvFile, screen)
  },
  //  OKRミーティングタブをクリック
  UserClickKeyResultDrawerOkrMeetingTab: (screen: Screen, isAssigned: boolean): void => {
    clickEvent(ClickEvent.ClickKeyResultDrawerOkrMeetingTab, screen, {
      properties: { isAssigned },
    })
  },
  // 1on1
  // 1on1のドロワーを開く
  UserClickOpenOneOnOneDrawer: (
    screen: Screen,
    targetUserId: string,
    isPartnerDrawer?: boolean,
  ): void => {
    clickEvent(ClickEvent.ClickOpenOneOnOneDrawer, screen, {
      properties: {
        targetUserId: decodeId(targetUserId).toString(),
        ...(isPartnerDrawer !== undefined ? { isPartnerDrawer } : undefined),
      },
    })
  },
  // 過去の議事録リンクをクリック
  UserClickOneOnOneHistoryLink: (screen: Screen): void => {
    clickEvent(ClickEvent.ClickOneOnOneHistoryLink, screen)
  },
  // HOME画面の各タブのクリック
  UserClickTabInHome: (screen: Screen, tab: string): void => {
    clickEvent(ClickEvent.ClickTabInHome, screen, { properties: { tab } })
  },
  // HOMEのノートタブのSortボタンをクリック
  UserClickSortButtonInHome: (screen: Screen, sortBy: string, orderBy: string): void => {
    clickEvent(ClickEvent.ClickSortButtonOnNoteTab, screen, { properties: { sortBy, orderBy } })
  },
  CheckSubscriptionPlan: (screen: Screen, byModal: boolean): void => {
    clickEvent(ClickEvent.ClickCheckSubscriptionPlan, screen, { properties: { byModal } })
  },
  BookTutorialSession: (screen: Screen): void => {
    clickEvent(ClickEvent.ClickBookTutorialSession, screen)
  },
  // HOMEのラジオボタンをクリック
  UserClickRadioButtonInHome: (screen: Screen, selected: string): void => {
    clickEvent(ClickEvent.ClickRadioButtonInHome, screen, { properties: { selected } })
  },
  // HOMEのさらに表示ボタンをクリック
  UserClickMoreButtonInHome: (screen: Screen): void => {
    clickEvent(ClickEvent.ClickMoreButtonInHome, screen)
  },
}

const isLocationChanged = (current: Location, prev?: Location): boolean => {
  if (!prev) {
    return true
  }
  return current.pathname !== prev.pathname || current.search !== prev.search
}

export const useTracking = (title: string, screen: Screen): void => {
  const user = useCurrentUser()
  const location = useLocation()
  const prevLocation = usePrevious(location)

  useEffect(() => {
    if (user && isLocationChanged(location, prevLocation)) {
      document.title = title
      trackPageView(location, screen)
    }
  }, [location, prevLocation, screen, title, user])

  useEffect(() => {
    document.title = title
  }, [title])
}
