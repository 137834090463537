import { css } from '@emotion/react'
import { useMemo } from 'react'

import { color } from '../../../../../../styles/newColors'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useStyles = () =>
  useMemo(
    () =>
      ({
        editor: {
          marginTop: -8,
          lineHeight: 1,
        },
        commentDelete: css({
          color: color('text-bk-50'),
        }),
      } as const),
    [],
  )
