import { css } from '@emotion/react'

export const rootCss = css({
  height: '100%',
  overflowY: 'auto',
  '> *:last-child': {
    minHeight: '100%',
  },
})

export const krWrapperCss = css({
  padding: '32px 32px 24px',
})

export const okrTitleWrapperCss = css({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
})
