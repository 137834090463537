import { useCallback, useState } from 'react'

import { useTranslation } from '../../../../../i18n'
import { DeleteModal } from '../../../../ui/DeleteModal'

type ModalFC = React.VFC<{ onConfirm: () => void }>

type ReturnType = {
  DeleteAlertModal: ModalFC
  open: () => void
}

export const useDeleteAlertModal = (): ReturnType => {
  const { t } = useTranslation()
  const [isOpened, setIsOpened] = useState<boolean>(false)

  const open = useCallback(() => setIsOpened(true), [])

  const DeleteAlertModal = useCallback<ModalFC>(
    ({ onConfirm }) => (
      <DeleteModal
        isOpened={isOpened}
        withGeishaModal
        deleteItemName={t('KEY_RESULT')}
        onClickCancel={() => setIsOpened(false)}
        onClickOutside={() => setIsOpened(false)}
        onClickDelete={() => {
          onConfirm()
          setIsOpened(false)
        }}
      />
    ),
    [isOpened, t],
  )

  return { DeleteAlertModal, open }
}
