import dayjs from 'dayjs'

import { useTranslation } from '../../../i18n'
import { formatDateInput } from '../../../lib/date'
import { color } from '../../../styles/newColors'
import { generateActionPlan } from '../../../urls'
import { AvatarWithName } from '../../ui/AvatarWithName'
import { Link, LinkMethod } from '../../ui/Link'
import { StyledText } from '../../ui/StyledText'
import { ActionPlanStatusBadge } from '../ActionPlanStatusBadge'

import { ActionPlanFragment } from './graphql'

export type Props = {
  actionPlan: ActionPlanFragment
  avatarLinkMethod?: LinkMethod
}

export const ActionPlanCard: React.FC<Props> = ({
  actionPlan: { id, title, dueDate, owner: user, status, keyResult },
  avatarLinkMethod,
}) => {
  const { t } = useTranslation()

  return (
    <div css={{ minHeight: '72px', padding: '16px 24px' }}>
      <ActionPlanStatusBadge status={status} />
      <div css={{ marginTop: '8px', marginBottom: '4px', width: '100%' }}>
        <Link
          href={generateActionPlan(keyResult.node.id, keyResult.id, id)}
          method="newTab"
          css={{
            ':hover': {
              color: color('resily-orange-100'),
              textDecoration: 'underline',
            },
          }}
        >
          <StyledText
            isWrap
            css={{
              overflow: 'hidden',
              maxHeight: `${3 * 20}px`,
            }}
            numberOfOmittedLines={3}
          >
            {title}
          </StyledText>
        </Link>
        <div css={{ marginTop: '12px', display: 'flex', alignItems: 'center' }}>
          <AvatarWithName
            size="xsmall"
            nameFontSize="small"
            firstName={user.firstName}
            lastName={user.lastName}
            avatarUrl={user.avatar?.url}
            linkage={{
              userId: user.id,
              termId: keyResult.node.term.id,
              method: avatarLinkMethod,
            }}
            isUserDisabled={user.isDisabled}
            linkTextMaxWidth="85%"
            newColor={!user.isDisabled ? undefined : 'text-bk-30'}
            avatarStyle={{ opacity: !user.isDisabled ? '1' : '0.1' }}
          />

          {dayjs(dueDate).isValid() && dueDate != null && (
            <StyledText
              color="text-bk-30"
              size="xsmall"
              css={{ lineHeight: '10px', marginLeft: '8px', whiteSpace: 'nowrap' }}
            >
              {`${t('DUE_DATE')} ${formatDateInput(dueDate)}`}
            </StyledText>
          )}
        </div>
      </div>
    </div>
  )
}

ActionPlanCard.displayName = 'ActionPlanCard'
