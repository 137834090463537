import { css } from '@emotion/react'
import { useMemo } from 'react'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useStyles = () =>
  useMemo(
    () =>
      ({
        root: css({
          display: 'flex',
        }),
        deleteButton: css({
          marginLeft: '12px',
        }),
      } as const),
    [],
  )
