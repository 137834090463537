import { Button } from '../../../../components/ui/Button'
import { TextButton } from '../../../../components/ui/TextButton'
import { useTranslation } from '../../../../i18n'

import { EditButtonSectionProps } from './types'

export const EditButtonSection: React.FC<EditButtonSectionProps> = ({ disabledEdit, onCancel }) => {
  const { t } = useTranslation()
  return (
    <div
      css={{
        display: 'flex',
        justifyContent: 'end',
      }}
    >
      <TextButton
        onClick={onCancel}
        color="text-bk-50"
        css={{
          width: '102px',
          fontWeight: 'normal',
        }}
      >
        {t('CANCEL')}
      </TextButton>
      <Button
        size="s"
        newColor="resily-orange-100"
        type="submit"
        disabled={disabledEdit}
        weight="normal"
      >
        {t('SAVE')}
      </Button>
    </div>
  )
}
