import { ColorAlias } from '../styles/newColors'

export type ConfidenceLevel = -1 | -2 | 0 | 1 | 2

/**
 * ConfidenceLevelで定義されている数値以外をConfidenceLevelに丸めます
 */
export const roundConfidence = (confidence: number | undefined): ConfidenceLevel => {
  if (!confidence) return 0
  const avg = Math.round(confidence)
  if (avg > 2) return 2
  if (avg < -2) return -2
  return avg as ConfidenceLevel
}

/**
 * ConfidenceLevelに応じて色を返す
 *   自信ある or 少し自信ある or どちらでもない -> KR green/100
 *   少し自信ない -> tag/tag_orange
 *   自信ない -> tag/tag_red
 */
export const barColorConfidence = (
  confidence: number | undefined,
): Extract<ColorAlias, 'kr-green-100' | 'tag-orange' | 'tag-red'> => {
  const confidenceLevel = roundConfidence(confidence)
  if (confidenceLevel === -2) return 'tag-red'
  if (confidenceLevel === -1) return 'tag-orange'
  return 'kr-green-100'
}
