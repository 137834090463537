import type { ComponentProps, FC } from 'react'
import type { FieldError, RefCallBack } from 'react-hook-form'

import { useTranslation } from '../../../i18n'
import { FormField } from '../../ui/FormField'
import { NumberInput } from '../../ui/NumberInput'
import { StyledText } from '../../ui/StyledText'

export type InputFormProps = Pick<ComponentProps<'input'>, 'onChange' | 'onBlur' | 'name'> & {
  errors: {
    actualValue?: FieldError | undefined
  }
  initialValue: number
  targetValue: number
  unit: string | undefined | null
  size: 'small' | 'medium'
  inputRef?: RefCallBack
}

export const InputForm: FC<InputFormProps> = ({
  name,
  onChange,
  onBlur,
  errors,
  initialValue,
  targetValue,
  unit,
  size,
  inputRef,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <StyledText
        color="text-bk-50"
        size="xsmall"
        css={size === 'small' ? { paddingRight: 8, whiteSpace: 'nowrap' } : { paddingRight: 8 }}
      >
        {t('ACTUAL_VALUE')}
      </StyledText>
      <FormField errorMessage={errors?.actualValue?.message} css={{ margin: 0, maxWidth: 192 }}>
        <NumberInput
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          maxWidth={180}
          ref={inputRef}
        />
        <StyledText
          size="small"
          oneline
          alignSelf="center"
          css={
            size === 'small'
              ? {
                  marginLeft: 4,
                  maxWidth: '64px',
                  display: '-webkit-box',
                  '-webkit-box-orient': 'vertical',
                  '-webkit-line-clamp': '2',
                  overflow: 'hidden',
                }
              : {
                  marginLeft: 4,
                  textOverflow: 'ellipsis',
                  overflowX: 'hidden',
                  whiteSpace: 'nowrap',
                  lineHeight: 'normal',
                }
          }
        >
          {unit}
        </StyledText>
      </FormField>

      <div
        css={
          size === 'small'
            ? {
                marginLeft: 24,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }
            : { marginLeft: 24 }
        }
      >
        <StyledText color="text-bk-50" size="xsmall">
          {t('TARGET_VALUE')}
        </StyledText>
        <br />
        <StyledText alignSelf="center" oneline size="xsmall">
          {targetValue}
        </StyledText>
        <StyledText alignSelf="center" oneline size="xsmall" css={{ marginLeft: 4 }}>
          {unit}
        </StyledText>
      </div>

      <div
        css={
          size === 'small'
            ? {
                marginLeft: 16,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }
            : { marginLeft: 32 }
        }
      >
        <StyledText color="text-bk-50" size="xsmall">
          {t('INITIAL_VALUE')}
        </StyledText>
        <br />
        <StyledText alignSelf="center" oneline size="xsmall">
          {initialValue}
        </StyledText>
        <StyledText alignSelf="center" oneline size="xsmall" css={{ marginLeft: 4 }}>
          {unit}
        </StyledText>
      </div>
    </>
  )
}

InputForm.displayName = 'InputForm'
