import { css, CSSObject } from '@emotion/react'
import { ThemeContext, Box, BoxProps, TextInput } from 'grommet'
import { FormClose } from 'grommet-icons'
import { ReactNode, useState } from 'react'

import { useTranslation } from '../../../i18n'
import { color } from '../../../styles/newColors'

type TagProps<TValue> = {
  value: TValue
  label: ReactNode
  // eslint-disable-next-line react/require-default-props
  size?: 'ss' | 's' | 'm'
  // eslint-disable-next-line react/require-default-props
  onRemove?: (value: TValue) => void
}

export function Tag<TValue = string>({
  value,
  size = 'm',
  label,
  onRemove,
}: TagProps<TValue>): JSX.Element {
  const fail = (message: never): never => {
    throw new Error(message)
  }
  const pad = () => {
    switch (size) {
      case 'm':
        return 'small'
      case 's':
        return '8px'
      case 'ss':
        return '4px'
      default:
        return fail(size)
    }
  }
  return (
    <Box
      direction="row"
      align="center"
      style={{ backgroundColor: '#f0f0f0' }}
      round="medium"
      margin="xsmall"
      pad={pad()}
      onClick={() => onRemove && onRemove(value)}
    >
      <Box>{label}</Box>
      <FormClose size="xsmall" />
    </Box>
  )
}

export type TagsOption<TValue> = {
  value: TValue
  label: ReactNode
  searchText?: string
  css?: CSSObject
}

type TagsProps<TValue> = {
  options: ReadonlyArray<TagsOption<TValue>>
  // eslint-disable-next-line react/require-default-props
  value?: ReadonlyArray<TValue>
  /* eslint-disable react/no-unused-prop-types */
  // eslint-disable-next-line react/require-default-props
  name?: string
  // eslint-disable-next-line react/require-default-props
  size?: 'ss' | 's' | 'm'
  // eslint-disable-next-line react/require-default-props
  border?: BoxProps['border']
  /* eslint-enable react/no-unused-prop-types */
  // eslint-disable-next-line react/require-default-props
  onChange?: (value: ReadonlyArray<TValue>) => void
  // eslint-disable-next-line react/require-default-props
  placeholder?: string
  disabled?: boolean
}

export function Tags<TValue = string>({
  value: selectedValues = [],
  options,
  placeholder,
  size = 'm',
  onChange,
  border = 'all',
  disabled,
  ...props
}: TagsProps<TValue>): JSX.Element {
  const [filteredOptions, setFilteredOptions] = useState<ReadonlyArray<TagsOption<TValue>>>(options)
  const [searchText, setSearchText] = useState<string>('')
  const { t } = useTranslation()

  const handleOnChange = (values: ReadonlyArray<TValue>) => {
    if (onChange) {
      onChange(values)
    }
  }

  return (
    <Box
      border={border}
      wrap
      direction="row"
      align="center"
      pad={size === 'm' ? 'small' : { left: 'small', right: 'small' }}
      css={{ backgroundColor: disabled ? color('background-bk-5') : undefined }}
      {...props}
    >
      {selectedValues.reduce<ReadonlyArray<ReactNode>>((a: ReadonlyArray<ReactNode>, v: TValue) => {
        const option = options.find((o) => o.value === v)
        if (option) {
          return [
            ...a,
            <Tag<TValue>
              key={`${option.value}`}
              value={option.value}
              label={option.label}
              size={size}
              onRemove={(removedValue) =>
                handleOnChange(selectedValues.filter((v2) => v2 !== removedValue))
              }
            />,
          ]
        }
        return a
      }, [])}
      <Box flex style={{ minWidth: '120px' }}>
        <ThemeContext.Extend
          value={{
            textInput: {
              extend: {
                padding: size === 'ss' ? '7px' : '8px',
              },
            },
          }}
        >
          <TextInput
            placeholder={placeholder || t('TAGS_PLACEHOLDER')}
            plain
            disabled={disabled}
            value={searchText}
            suggestions={filteredOptions.map((o) => ({
              value: o.value,
              label: (
                <Box pad="small" css={css([o.css])}>
                  {o.label}
                </Box>
              ),
            }))}
            onSelect={(e) => {
              const { value } = e.suggestion
              if (!selectedValues.includes(value)) {
                handleOnChange([...selectedValues, value])
                setFilteredOptions([])
                setSearchText('')
              }
            }}
            onClick={(e) => {
              e.preventDefault()
              if (!searchText) {
                setFilteredOptions(options.filter((o) => !selectedValues.includes(o.value)))
              }
            }}
            onKeyDown={(e) => {
              const { key } = e
              if (!searchText && (key === 'Backspace' || key === 'Delete')) {
                const newSelectedValues = selectedValues.slice(0, -1)
                handleOnChange(newSelectedValues)
                setFilteredOptions(options.filter((o) => !newSelectedValues.includes(o.value)))
              } else if (!searchText && key === 'ArrowDown') {
                setFilteredOptions(options.filter((o) => !selectedValues.includes(o.value)))
              }
            }}
            onChange={(e) => {
              setSearchText(e.currentTarget.value)
              setFilteredOptions(
                options.filter(
                  (o) =>
                    !selectedValues.includes(o.value) &&
                    (o.searchText || `${o.value}`).indexOf(e.currentTarget.value) !== -1,
                ),
              )
            }}
          />
        </ThemeContext.Extend>
      </Box>
    </Box>
  )
}
