import React from 'react'

import { decodeId } from '../../../../lib/domain/id'

export type Props = {
  stripePublicApiKey: string
  stripe1stPricingTableId: string
  organizationId: string
  email: string
}

export const BeforeSubscriptionContent: React.VFC<Props> = ({
  stripePublicApiKey,
  stripe1stPricingTableId,
  organizationId,
  email,
}) => (
  /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
  /* @ts-expect-error */
  <stripe-pricing-table
    // https://stripe.com/docs/no-code/pricing-table#track-subscriptions
    pricing-table-id={stripe1stPricingTableId} // 一度でもサブスク契約したorganizationには新規契約の導線がない方の料金表を表示する
    publishable-key={stripePublicApiKey}
    client-reference-id={decodeId(organizationId)}
    customer-email={email} // メールアドレス欄にユーザーのメールアドレスをprefillするためのパラメータ
  />
)
