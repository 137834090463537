import { Screen } from '../../../../../../lib/screen'
import { tracker } from '../../../../../../lib/tracking'
import { KeyResultTab, ObjectiveTab } from '../../../types'

export const clickDrawerTabTrack: (screen: Screen, content: KeyResultTab | ObjectiveTab) => void = (
  screen,
  content,
): void => {
  if (content === 'm-o-description')
    return tracker.UserClickObjectiveDetailTabByObjectiveModal(screen)
  if (content === 'm-o-note') return tracker.UserClickNoteTabByObjectiveModal(screen)
  if (content === 'm-o-activity') return tracker.UserClickActivityTabByObjectiveModal(screen)
  if (content === 'm-kr-description') return tracker.UserClickDetailTabByKeyResultDrawer(screen)
  if (content === 'm-kr-action-plan') return tracker.UserClickActionPlanTabByKeyResultDrawer(screen)
  if (content === 'm-kr-note') return tracker.UserClickNoteTabByKeyResultDrawer(screen)
  if (content === 'm-kr-activity') return tracker.UserClickActivityTabByKeyResultDrawer(screen)
  return fail(content)
}
