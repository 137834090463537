import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { StringParam, useQueryParams } from 'use-query-params'

import { client } from '../../lib/client'
import { useConfirmationToken } from '../../lib/domain/useConfirmationToken'
import { tokenConfirmation } from '../../types/tokenConfirmation'

import { InvalidLink } from './InvalidLink'
import { PasswordResetConfirm } from './PasswordResetConfirm'

export const PasswordResetConfirmContainer: React.VFC = () => {
  const navigate = useNavigate()
  const [{ reset_password_token: token }] = useQueryParams({ reset_password_token: StringParam })

  const { loading, confirmedToken } = useConfirmationToken({
    type: tokenConfirmation.PASSWORD_RESET,
    token,
  })

  const handleResetPassword = useCallback(
    async (values: { newPassword: string; passwordConfirmation: string; token: string }) => {
      try {
        await client.put<void>(
          '/users/password',
          {
            password: values.newPassword,
            password_confirmation: values.passwordConfirmation,
            reset_password_token: values.token,
          },
          {
            error: true,
          },
        )
        navigate('/signin')
      } catch (e) {
        // nothing to do
      }
    },
    [navigate],
  )

  if (typeof token !== 'string' || loading) {
    return null
  }

  if (!confirmedToken) {
    return <InvalidLink />
  }

  return <PasswordResetConfirm token={token} onSubmit={handleResetPassword} />
}

PasswordResetConfirmContainer.displayName = 'PasswordResetConfirmContainer'
