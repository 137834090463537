import { Reducer, useCallback, useEffect, useMemo, useReducer, useRef } from 'react'

import {
  EditorRef,
  RichTextEditor,
  Props as RichTextEditorProps,
} from '../../../../../ui/RichTextEditor'
import { serializeText } from '../../../../../ui/RichTextEditor/PlateEditor/plugins/serializer/text'
import { Document } from '../../../graphql'

import { useStyles } from './useEditor.styles'

type State = Document | null | undefined
type Action = { type: 'change'; treeJson: string; plainText: string } | { type: 'reset' }

export type Props = {
  id: string
  description: Document | null | undefined
  editorPlaceholder: string
  emptyPlaceholder: string
  readOnly: boolean
}

export const useEditor = ({
  id,
  description,
  editorPlaceholder,
  emptyPlaceholder,
  readOnly,
}: Props): {
  Editor: JSX.Element
  getEditorState: () => State
  resetEditor: () => void
  isDirtyEditor: boolean
} => {
  const ref = useRef<EditorRef>(null)

  const [state, dispatch] = useReducer<Reducer<State, Action>>((prevState, action) => {
    switch (action.type) {
      case 'change':
        return {
          ...prevState,
          treeJson: action.treeJson,
          plainText: action.plainText,
        }
      case 'reset':
        return description
      default:
        return prevState
    }
  }, description)

  const getEditorState = useCallback(() => state, [state])
  const resetEditor = useCallback(() => {
    ref.current?.resetInitialValue()
    dispatch({ type: 'reset' })
  }, [])
  useEffect(resetEditor, [resetEditor, description?.treeJson])

  // FIXME: ドロワー上だとObjectiveの時だけ何故かplaceholderが重なる
  const editorProps = useMemo<NonNullable<RichTextEditorProps['editorProps']>>(
    () => ({ readOnly, placeholder: editorPlaceholder }),
    [editorPlaceholder, readOnly],
  )
  const onChangeEditor = useCallback<NonNullable<RichTextEditorProps['onChange']>>(
    (json, text) => dispatch({ type: 'change', treeJson: json, plainText: text }),
    [],
  )

  // FIXME: 入力内容が空の場合にDescriptionをNullにしたいが、空文字が登録されてしまっているため、treeJsonの中身を見て判定している
  // FIXME: データ移行とOKR期間コピー時にDescriptionはコピーされるが、plain_textにNULLが入る場合があるかも
  const isEmptyDescription =
    !state?.plainText?.replaceAll('\n', '') &&
    !serializeText(JSON.parse(state?.treeJson || '[]')).replaceAll('\n', '')
  const isViewEmptyPlaceholder = readOnly && isEmptyDescription

  const styles = useStyles({ isViewEmptyPlaceholder })

  const isDirtyEditor = description?.treeJson !== state?.treeJson

  return {
    Editor: useMemo(
      () => (
        <>
          {isViewEmptyPlaceholder && <span css={styles.placeholder}>{emptyPlaceholder}</span>}
          <RichTextEditor
            ref={ref}
            id={id}
            css={styles.editor}
            initialValueJSON={description?.treeJson}
            editorProps={editorProps}
            onChange={onChangeEditor}
            onSave={onChangeEditor}
          />
        </>
      ),
      [
        description?.treeJson,
        editorProps,
        emptyPlaceholder,
        id,
        isViewEmptyPlaceholder,
        onChangeEditor,
        styles.editor,
        styles.placeholder,
      ],
    ),
    getEditorState,
    resetEditor,
    isDirtyEditor,
  }
}
