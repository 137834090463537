import { useWindowSize } from 'react-use'

import { useTranslation } from '../../../../../i18n'
import { Popover } from '../../../../ui/Popover'
import { StyledText } from '../../../../ui/StyledText'
import { KeyResultTargetSettingCheckBox } from '../../../KeyResultTargetSettingCheckBox'
import { TargetSettingType } from '../../graphql'

import { useStyles } from './index.styles'

export type Props = {
  targetSetting: TargetSettingType
  setTargetSetting: (targetSetting: TargetSettingType) => void
}

export const TargetSettingInput: React.FC<Props> = ({ targetSetting, setTargetSetting }) => {
  const { t } = useTranslation()
  const styles = useStyles()

  const { height } = useWindowSize()

  return (
    <div css={styles.form}>
      <div css={styles.label}>
        <StyledText size="small" fontStyle="bold">
          {t('TARGET_SETTING')}
        </StyledText>
        <Popover
          // NOTE: ブラウザの高さによって上下どっちに出すか変える
          position={height > 840 ? 'bottom' : 'top'}
          targetContent={<Popover.TargetContent.Help css={styles.icon} />}
          dropdownContent={
            <Popover.DropdownContent
              title={t('TARGET_SETTING')}
              text={t('TARGET_SETTING_HELP_MESSAGE')}
            />
          }
        />
      </div>
      <div css={styles.checkbox}>
        <KeyResultTargetSettingCheckBox targetSetting={targetSetting} onClick={setTargetSetting} />
      </div>
    </div>
  )
}
