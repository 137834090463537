import { ContentLayout } from '../../components/pageContent/ContentLayout'
import { Link } from '../../components/ui/Link'
import { Logo } from '../../components/ui/Logo'
import { StyledText } from '../../components/ui/StyledText'
import { Trans, useTranslation } from '../../i18n'
import { color } from '../../styles/newColors'
import { ExternalUrls } from '../../urls'

import { AccountSetupForm, FormValue } from './AccountSetupForm'

export type Props = {
  onSubmit: (values: FormValue) => void
  email: string
  token: string
}

export const AccountSetup: React.VFC<Props> = ({ email, onSubmit }) => {
  const { t } = useTranslation()

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
      }}
    >
      <div
        css={{
          height: '100%',
          width: '100%',
          overflow: 'auto',
          backgroundColor: color(`background-bk-5`),
        }}
      >
        <ContentLayout height="100%" align="center" justify="center">
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '520px',
              borderRadius: '16px',
              boxShadow: '0 8px 16px rgb(34 41 67 / 8%)',
              padding: '36px 80px',
              backgroundColor: color('white-100'),
            }}
          >
            <Logo width="154px" height="44px" style={{ marginBottom: '12px' }} />
            <StyledText
              as="p"
              size="xsmall"
              color="text-bk-30"
              css={{
                marginBottom: '20px',
                lineHeight: '1.4',
                textAlign: 'center',
              }}
            >
              {t('ACCOUNT_SETUP_DESCRIPTION')}
            </StyledText>
            <AccountSetupForm email={email} onSubmit={onSubmit} />
            <div
              css={{
                marginBottom: '20px',
                color: color('text-bk-50'),
                fontSize: '12px',
                lineHeight: '16px',
              }}
            >
              <Trans
                i18nKey="ACCOUNT_SETUP_INSTRUCTION"
                components={[
                  <Link
                    css={{
                      color: color('text-bk-50'),
                      textDecoration: 'underline',
                    }}
                    href={ExternalUrls.TERM_OF_SERVICE}
                    rel="noopener noreferrer"
                    method="newTab"
                  >
                    {t('TERM_OF_SERVICE')}
                  </Link>,
                  <Link
                    css={{
                      color: color('text-bk-50'),
                      textDecoration: 'underline',
                    }}
                    href={ExternalUrls.PRIVACY_POLICY}
                    rel="noopener noreferrer"
                    method="newTab"
                  >
                    {t('PRIVACY_POLICY')}
                  </Link>,
                  <Link
                    css={{
                      color: color('text-bk-50'),
                      textDecoration: 'underline',
                    }}
                    href={ExternalUrls.STRIPE_TERM_OF_SERVICE}
                    rel="noopener noreferrer"
                    method="newTab"
                  >
                    {t('STRIPE_TERM_OF_SERVICE')}
                  </Link>,
                  <Link
                    css={{
                      color: color('text-bk-50'),
                      textDecoration: 'underline',
                    }}
                    href={ExternalUrls.STRIPE_PRIVACY_POLICY}
                    rel="noopener noreferrer"
                    method="newTab"
                  >
                    {t('STRIPE_PRIVACY_POLICY')}
                  </Link>,
                ]}
              />
            </div>
            <div css={{ lineHeight: '20px' }}>
              <a
                css={{
                  color: color('text-bk-50'),
                  textDecoration: 'none',
                  fontSize: '12px',
                }}
                href={ExternalUrls.HELP_PAGE}
                rel="noopener noreferrer"
                target="_blank"
              >
                {t('HELP')}
              </a>
              <a
                css={{
                  color: color('text-bk-50'),
                  textDecoration: 'none',
                  fontSize: '12px',
                  marginLeft: '16px',
                }}
                href={ExternalUrls.INQUIRY_PAGE}
                rel="noopener noreferrer"
                target="_blank"
              >
                {t('CONTACT_US')}
              </a>
            </div>
          </div>
        </ContentLayout>
      </div>
    </div>
  )
}

AccountSetup.displayName = 'AccountSetup'
