import { css } from '@emotion/react'
import { forwardRef } from 'react'

import { StyledText } from '../../../../components/ui/StyledText'
import { useTranslation } from '../../../../i18n'
import { color } from '../../../../styles/newColors'
import { Sort } from '../types'

import { SortButton } from './SortButton'

type Props = {
  sort: Sort
  onClickSortBy: (sortBy: Sort['sortBy']) => void
  shiftPositionLeft: number
}

export const UsersTableHead = forwardRef<HTMLTableSectionElement, Props>(
  ({ sort, onClickSortBy, shiftPositionLeft }: Props, ref: React.Ref<HTMLTableSectionElement>) => {
    const { t } = useTranslation()

    const cssTableCellHeader = css({
      display: 'flex',
      alignItems: 'center',
      padding: '0 0 8px',
      borderBottom: `1px solid ${color('border-bk-30')}`,
      backgroundColor: color('white-100'),
    })

    return (
      <div
        ref={ref}
        css={{
          display: 'flex',
          position: 'absolute',
          width: 'calc(100% - 64px)',
          zIndex: 1,
        }}
      >
        <div
          css={[
            cssTableCellHeader,
            {
              flex: 'auto',
              paddingLeft: '8px',
            },
          ]}
        >
          <SortButton sortBy="user" sort={sort} onClick={onClickSortBy}>
            <StyledText size="small" fontStyle="bold" lineHeight="1">
              {t('NAME')}
            </StyledText>
          </SortButton>
        </div>
        <div
          css={[
            cssTableCellHeader,
            {
              flex: '0 0 136px',
              justifyContent: 'end',
              paddingRight: '20px',
            },
          ]}
        >
          <SortButton sortBy="objective" sort={sort} onClick={onClickSortBy}>
            <StyledText size="small" fontStyle="bold">
              {t('OBJECTIVE')}
            </StyledText>
          </SortButton>
        </div>
        <div
          css={[
            cssTableCellHeader,
            {
              flex: '0 0 140px',
              justifyContent: 'end',
              paddingRight: '20px',
            },
          ]}
        >
          <SortButton sortBy="keyResult" sort={sort} onClick={onClickSortBy}>
            <StyledText size="small" fontStyle="bold">
              {t('KEY_RESULT')}
            </StyledText>
          </SortButton>
        </div>
        <div
          css={[
            cssTableCellHeader,
            {
              flex: '0 0 164px',
              justifyContent: 'end',
              paddingRight: '24px',
            },
          ]}
        >
          <SortButton sortBy="contributor" sort={sort} onClick={onClickSortBy}>
            <StyledText size="small" fontStyle="bold">
              {`${t('KEY_RESULT')} ${t('CONTRIBUTOR')}`}
            </StyledText>
          </SortButton>
        </div>
        <div
          css={[
            cssTableCellHeader,
            {
              flex: `0 0 ${124 + shiftPositionLeft}px`,
              justifyContent: 'end',
              paddingRight: `${24 + shiftPositionLeft}px`,
            },
          ]}
        >
          <SortButton sortBy="lastLoggedIn" sort={sort} onClick={onClickSortBy}>
            <StyledText size="small" fontStyle="bold">
              {t('LAST_LOGIN_DATE')}
            </StyledText>
          </SortButton>
        </div>
      </div>
    )
  },
)

UsersTableHead.displayName = 'UsersTableHead'
