import { useRef } from 'react'
import { useQueryParams, ArrayParam } from 'use-query-params'

import { StyledText } from '../../../components/ui/StyledText'
import { useTranslation } from '../../../i18n'
import { Screen } from '../../../lib/screen'
import { useTracking } from '../../../lib/tracking'
import { UserFragment } from '../graphql'

import { Users } from './Users'
import { UsersTableContainer } from './UsersTable'
import { useSort } from './hooks/useSort'
import { SortEnumParam, OrderEnumParam } from './types'

type Props = {
  users: ReadonlyArray<UserFragment>
  loading: boolean
}

export const UsersContainer: React.VFC<Props> = ({ users, loading }) => {
  const { t } = useTranslation()
  useTracking(t('OKR_SETTING_STATUS'), Screen.Users)

  const [queryParams, setQueryParams] = useQueryParams({
    user: ArrayParam,
    group: ArrayParam,
    sort: SortEnumParam,
    order: OrderEnumParam,
  })

  const sort = { sortBy: queryParams.sort, order: queryParams.order }
  const { sortedUsers, handleClickSortBy } = useSort(sort, users, setQueryParams)

  const wrapperRef = useRef<HTMLDivElement>(null)
  const scrollbarHeight = wrapperRef.current
    ? wrapperRef.current.offsetHeight - wrapperRef.current.clientHeight
    : 0
  const filterResultRef = useRef<HTMLDivElement>(null)
  const filterResultHeight = filterResultRef.current
    ? filterResultRef.current.getBoundingClientRect().height
    : 0

  return (
    <Users
      ref={wrapperRef}
      displayFilterResult={!!queryParams.user || !!queryParams.group}
      filterResultRef={filterResultRef}
      filterResultChildren={
        <StyledText color="text-bk-50" size="small" fontStyle="bold">
          {t('APPLICABLE_RESULTS_X', { x: users.length.toString() })}
        </StyledText>
      }
      usersTableChildren={
        !loading && (
          <UsersTableContainer
            users={sortedUsers}
            expectTableHeight={filterResultHeight + scrollbarHeight}
            onClickSortBy={handleClickSortBy}
            sort={sort}
          />
        )
      }
    />
  )
}

UsersContainer.displayName = 'UsersContainer'
