import * as basicprimitives from 'basicprimitives'
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { OkrModalReturnType } from '../../../components/standalone/OkrModal/hooks/useOkrModal'
import { useOkrModalStatus } from '../../../components/standalone/OkrModal/hooks/useOkrModalStatus'
import { useCurrentUser } from '../../../contexts/UserContext'
import { ancestors, siblings } from '../../../lib/collections/node'
import { isIncludedUser } from '../../../lib/domain/objective'
import { OKRTree } from '../../../lib/domain/okr/tree'
import { ordinal } from '../../../lib/ordinal'
import { Screen } from '../../../lib/screen'
import { tracker } from '../../../lib/tracking'
import { hex2rgb, color } from '../../../styles/newColors'
import { generateOkr } from '../../../urls'
import { useIsFoldOkrCardGlobal } from '../OkrCardIsFoldGlobalContext'

import { DotMenu } from './DotMenu'
import {
  generateCollapseTemplateName,
  generateExpandTemplateName,
  getNodeSize,
  OkrNode,
} from './OkrNode'
import { MINIMAP_HEIGHT, MINIMAP_WIDTH, MINIMAP_WINDOW_GAP } from './const'
import { DotColorPair, OkrNode as OkrNodeType, OrgDiagramConfigItem } from './types'
import { SetCursorItem, useCenteringNode } from './useCenteringNode'
import { useNodeHover } from './useNodeHover'

export const TREE_MAP_PADDING: basicprimitives.Thickness = {
  top: 50,
  left: 50,
  right: 50 + MINIMAP_WIDTH + MINIMAP_WINDOW_GAP, // minimapとdotやnodeが被らないようにする
  bottom: 50 + 127 + MINIMAP_HEIGHT + MINIMAP_WINDOW_GAP, // DotMenuのOpen時に画面外に飛び出る分(127)とMINIMAPの余白を確保
}

const getNeighborhoodNodeIds = (okrTree: OKRTree<OkrNodeType>, nodeId: string) => {
  const n = okrTree.getNodeById(nodeId)
  let ancestorIds = new Array<string>()
  let childrenIds = new Array<string>()
  let siblingsIds = new Array<string>()
  if (n != null) {
    childrenIds = n.children.get(n)?.map((e) => e.id) ?? []
    ancestorIds = ancestors(n).map((e) => e.id)
    siblingsIds = siblings(n).map((e) => e.id)
  }
  return new Set([...ancestorIds, ...siblingsIds, ...childrenIds, nodeId])
}

type Ret = [
  basicprimitives.OrgConfig<OrgDiagramConfigItem>,
  boolean,
  ReadonlyArray<DotColorPair>,
  string,
  Dispatch<SetStateAction<string | undefined>>,
]

export type Props = {
  expandLevel: number
  okrTree: OKRTree<OkrNodeType>
  isFiltering: boolean
  filteredOkrNodeIds: ReadonlyArray<string>
  onNodeAdd: (parentNodeId: string) => Promise<void>
  onNodeAddConnectKR: (parentNodeId: string, _parentKeyResultId: string) => void
  onOkrFloatDrawerOpen: (objectiveId: string) => void
  openOkrModal: OkrModalReturnType['openOkrModal']
  openOkrModalWithKeyResultDrawer: OkrModalReturnType['openOkrModalWithKeyResultDrawer']
}

export const useNodeConfig = ({
  expandLevel,
  okrTree,
  isFiltering,
  filteredOkrNodeIds,
  onNodeAdd,
  onNodeAddConnectKR,
  onOkrFloatDrawerOpen,
  openOkrModal,
  openOkrModalWithKeyResultDrawer,
}: Props): Ret => {
  const {
    type: modalType,
    objectiveId: modalObjectiveId,
    keyResultId: modalKeyResultId,
  } = useOkrModalStatus()
  const currentUser = useCurrentUser()
  const user = useMemo(() => (currentUser?.id ? { id: currentUser.id } : null), [currentUser?.id])

  const [
    onObjectiveEnter,
    onObjectiveLeave,
    onKeyResultEnter,
    onKeyResultLeave,
    onKeyResultFilterClick,
  ] = useNodeHover(okrTree)

  const dotColorPairs = useRef<ReadonlyArray<DotColorPair>>([])
  const [preCursorItem, setPreCursorItem] = useState<string>() // クリックされたNode
  const [cursorItem, setCursorItem, isCentering] = useCenteringNode(okrTree)
  const isFoldOkrCardGlobal = useIsFoldOkrCardGlobal()

  const [selectedOkrNodeIds, setSelectedOkrNodeIds] = useState<ReadonlySet<string>>(
    getNeighborhoodNodeIds(okrTree, cursorItem),
  )

  // center指定ありで初回レンダリング時にcenter周辺の展開されているカードのDotMenuが開かないようにする
  useEffect(() => {
    setSelectedOkrNodeIds(getNeighborhoodNodeIds(okrTree, cursorItem))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [okrTree])

  const memoizedSetCursorItemWithExtendNeighborhood = useCallback<SetCursorItem>(
    (nodeId: string, centering = false) => {
      setSelectedOkrNodeIds(getNeighborhoodNodeIds(okrTree, nodeId))
      setCursorItem(nodeId, centering)
      setPreCursorItem(undefined)
    },
    [okrTree, setCursorItem],
  )

  // BasicPrimitivesに渡すconfigパラメータの作成
  const config = useMemo(() => {
    const defaultExpandOkrNodeIds = new Array<string>()

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const treeConfig: basicprimitives.OrgConfig<OrgDiagramConfigItem> = {
      ...new basicprimitives.OrgConfig(),
      cursorItem,
      highlightItem: null,
      highlightGravityRadius: 2, // Dotからcallout、Nodeからhighlightが出現する範囲
      // defaultCalloutTemplateName: 'callout', // INFO: calloutに表示するものをカスタマイズする場合に使うかもしれない
      calloutOffset: -10, // calloutの枠線のrect inflation
      defaultTemplateName: 'default',
      items: [],
      pageFitMode: basicprimitives.PageFitMode.SelectionOnly,
      verticalAlignment: basicprimitives.VerticalAlignmentType.Top,
      hasSelectorCheckbox: basicprimitives.Enabled.False,
      dotItemsInterval: 4, // dot間の横の余白
      dotLevelShift: 48, // dot間の高さの余白
      normalItemsInterval: 40, // ノード間の横の余白
      normalLevelShift: 48, // ノード間の高さの余白
      selectedItems: [], // 展開されたノードIDを格納
      padding: TREE_MAP_PADDING, // treeMap全体の余白
    }

    okrTree.traversal((node) => {
      const v: OrgDiagramConfigItem = {
        ...new basicprimitives.OrgItemConfig({
          itemTitleColor: undefined,
          templateName: generateExpandTemplateName(node.id),
          // 一括折り畳み時にcalloutの吹き出しが折り畳み前の最大サイズ前提だとはみ出る問題の対策
          calloutTemplateName: isFoldOkrCardGlobal
            ? generateCollapseTemplateName(node.id)
            : generateExpandTemplateName(node.id),
        }),
        ...node,
        id: node.id,
        parent: node.parent?.get(node)?.id ?? '',
      }
      treeConfig.items.push(v)

      if (v.depth <= expandLevel) {
        defaultExpandOkrNodeIds.push(v.id)
      }

      const isFilterTarget = filteredOkrNodeIds.includes(node.id)

      const expandTemplate: basicprimitives.TemplateConfig = {
        ...new basicprimitives.TemplateConfig(),
        name: generateExpandTemplateName(node.id),
        // 各自の折りたたみ時に毎回レイアウトに反映すると激重なので常に最大サイズで描画する
        itemSize: getNodeSize(node, false),
        minimizedItemSize: { width: 12, height: 12 }, // dotのサイズ
        // dotのborder color (svg:stroke)
        minimizedItemBorderColor:
          isFiltering && isFilterTarget ? color('border-bk-80') : color('border-bk-40'),
        // dotのborder width
        minimizedItemLineWidth: isFiltering && isFilterTarget ? 3 : 1,
        // dotのfill color (svg:fill)
        minimizedItemFillColor:
          isFiltering && !isFilterTarget ? color('background-bk-5') : color('white-100'),
        onHighlightRender: () => null,
        onCursorRender: () => null,
        onItemRender: (value: basicprimitives.RenderEventArgs<OrgDiagramConfigItem>) => {
          if (!value.context) return null
          return (
            <OkrNode
              key={value.context.id}
              node={value.context}
              userId={user?.id}
              isCallout={value.id === null && value.element === null}
              isFiltering={isFiltering}
              isFilterTarget={isFilterTarget}
              isSelected={cursorItem === value.context.id}
              isSelectedObjective={
                modalType === 'objective' ? modalObjectiveId === node.objective.id : false
              }
              selectedKeyResultId={
                modalType === 'keyResult' && node.keyResults.some((e) => e.id === modalKeyResultId)
                  ? modalKeyResultId
                  : false
              }
              relationLineColor={treeConfig.linesColor}
              okrPageUrl={generateOkr(value.context.id)}
              onClickOKR={memoizedSetCursorItemWithExtendNeighborhood}
              onAddChildNode={onNodeAdd}
              onAddChildNodeConnectKR={onNodeAddConnectKR}
              onObjectiveEnter={onObjectiveEnter}
              onObjectiveLeave={onObjectiveLeave}
              onKeyResultEnter={onKeyResultEnter}
              onKeyResultLeave={onKeyResultLeave}
              onKeyResultFilterClick={onKeyResultFilterClick}
              onOkrFloatDrawerOpen={onOkrFloatDrawerOpen}
              openOkrModal={openOkrModal}
              openOkrModalWithKeyResultDrawer={openOkrModalWithKeyResultDrawer}
            />
          )
        },
      }
      const collapseTemplate: basicprimitives.TemplateConfig = {
        ...expandTemplate,
        name: generateCollapseTemplateName(node.id),
        itemSize: getNodeSize(node, true),
      }
      treeConfig.templates.push(expandTemplate, collapseTemplate)
    })

    // Dotホバー時のcursorを変化させるために使っている色の組み合わせ一覧を取得
    dotColorPairs.current = [
      ...new Set<string>(
        treeConfig.templates.flatMap((t) => {
          if (!t.minimizedItemBorderColor && !t.minimizedItemFillColor) {
            return []
          }
          return [
            `${t.minimizedItemBorderColor ?? t.minimizedItemFillColor}+${
              t.minimizedItemFillColor ?? t.minimizedItemBorderColor
            }`,
          ]
        }),
      ),
    ].map<DotColorPair>((cp) => {
      const rgbcp = cp.split('+').map((c) => hex2rgb(c, ', ')) as [string, string]
      return { stroke: rgbcp[0], fill: rgbcp[1] }
    })

    treeConfig.selectedItems = [...defaultExpandOkrNodeIds, ...selectedOkrNodeIds.values()]

    return treeConfig
  }, [
    cursorItem,
    expandLevel,
    isFiltering,
    isFoldOkrCardGlobal,
    filteredOkrNodeIds,
    onNodeAdd,
    onNodeAddConnectKR,
    memoizedSetCursorItemWithExtendNeighborhood,
    modalKeyResultId,
    modalObjectiveId,
    modalType,
    okrTree,
    onKeyResultEnter,
    onKeyResultLeave,
    onObjectiveEnter,
    onObjectiveLeave,
    onKeyResultFilterClick,
    selectedOkrNodeIds,
    user?.id,
    onOkrFloatDrawerOpen,
    openOkrModal,
    openOkrModalWithKeyResultDrawer,
  ])

  const memoizedDotMenuAnnotation = useMemo(() => {
    if (!preCursorItem) {
      return undefined
    }

    const isAssignedToObjective = isIncludedUser(
      okrTree.getNodeById(preCursorItem)?.objective,
      user,
    )

    return new basicprimitives.ShapeAnnotationConfig({
      // 展開済みのノードはメニューを開かない
      items: !config.selectedItems.includes(preCursorItem) ? [preCursorItem] : [],
      label: (
        <DotMenu
          overrideParentZindex={10}
          onClickOutside={() => {
            setPreCursorItem(undefined)
          }}
          onClickOkrPage={() => {
            tracker.OkrMapClickDotMenuShowOkrPage(
              isAssignedToObjective,
              okrTree.getNodeById(preCursorItem)?.objective.useWeighting || false,
            )

            window.open(generateOkr(preCursorItem), '_blank', 'noopener,noreferrer')
            setPreCursorItem(undefined)
          }}
          onClickExpandTree={() => {
            tracker.OkrMapClickDotMenuExpandDot(
              isAssignedToObjective,
              okrTree.getNodeById(preCursorItem)?.objective.useWeighting || false,
            )

            memoizedSetCursorItemWithExtendNeighborhood(preCursorItem, true)
          }}
          onClickLookAtInfo={() => {
            tracker.OkrMapClickDotMenuShowInfo(
              isAssignedToObjective,
              okrTree.getNodeById(preCursorItem)?.objective.useWeighting || false,
            )

            const n = okrTree.getNodeById(preCursorItem)
            if (n?.objective != null) {
              openOkrModal(n.objective.id, Screen.OkrMapTree, isAssignedToObjective)
            }
            setPreCursorItem(undefined)
          }}
        />
      ),
      labelOffset: 8, // Dotとの距離
      labelPlacement: basicprimitives.PlacementType.Bottom,
      // TODO: ↓DotMenuが4列の場合は(130, 127)
      labelSize: new basicprimitives.Size(130, 94), // 英語だと横幅が増えるのでデザインより少し横に伸ばす
    })
  }, [
    preCursorItem,
    config.selectedItems,
    memoizedSetCursorItemWithExtendNeighborhood,
    okrTree,
    user,
    openOkrModal,
  ])

  // 階層ラベルの追加
  const memoizedLevelAnnotations = useMemo(() => {
    const levelAnnotations: Array<basicprimitives.LevelAnnotationConfig> = []

    for (let i = 0; i < okrTree.getMaxDepth(); i += 1) {
      levelAnnotations.push(
        new basicprimitives.LevelAnnotationConfig({
          levels: [i],
          title: `${ordinal(i + 1)} (${okrTree.getNodeCountByDepth(i + 1)})`,
          titleFontColor: color('text-bk-50'),
          titleColor: color('border-bk-10'),
          offset: new basicprimitives.Thickness(0, 0, 0, -2),
          lineWidth: new basicprimitives.Thickness(0, 0, 0, 0),
          opacity: 0,
          lineType: basicprimitives.LineType.Solid,
        }),
      )
    }

    return levelAnnotations
  }, [okrTree])

  const annnotatedConfig = useMemo(() => {
    config.annotations = memoizedDotMenuAnnotation ? [memoizedDotMenuAnnotation] : []
    config.annotations = config.annotations.concat(...memoizedLevelAnnotations)
    return config
  }, [config, memoizedDotMenuAnnotation, memoizedLevelAnnotations])

  return [annnotatedConfig, isCentering, dotColorPairs.current, cursorItem, setPreCursorItem]
}
