import { Box } from 'grommet'
import { useEffect } from 'react'
import { DraggableProvided } from 'react-beautiful-dnd'

import { KeyResultAvatarWithContributor } from '../../components/domain/AvatarWithContributor'
import { KeyResultName } from '../../components/domain/KeyResultName'
import { KeyResultProgressRateBar } from '../../components/domain/KeyResultProgressRateBar'
import { ProgressRateTooltip } from '../../components/domain/ProgressRateTooltip'
import { MAIN_CONTENT_ID } from '../../components/layout/GloNavAndMain'
import { Icon } from '../../components/ui/Icon'
import { scrollHash } from '../../lib/scrollHash'
import { color } from '../../styles/newColors'
import { generateKeyResultHash, matchKeyResultDomId, prefixKeyResultHash } from '../../urls'

import { KeyResultFragment, UserFragment } from './graphql'

export type Props = {
  termId: string
  keyResult: KeyResultFragment
  provider: DraggableProvided
  tdWidth: number
  onClick: () => void
}

export const DraggableKeyResultsTableItems: React.FC<Props> = ({
  termId,
  keyResult: item,
  tdWidth,
  provider: p,
  onClick,
}) => {
  useEffect(() => {
    const hash = window.location.hash || ''
    const krIdByHash = hash.replace(`#${prefixKeyResultHash}`, '')
    if (item.id !== krIdByHash) return

    scrollHash({
      scrollContentId: MAIN_CONTENT_ID,
      matchDomId: matchKeyResultDomId,
      top: -4,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <tr ref={p.innerRef} {...p.draggableProps} css={{ backgroundColor: color('white-100') }}>
      <td css={{ width: '48px' }} {...p.dragHandleProps}>
        <Box css={{ marginLeft: '12px' }}>
          <Icon type="dragIndicator" color={color('text-bk-30')} />
        </Box>
      </td>
      <td css={{ width: 57 }}>
        <KeyResultAvatarWithContributor
          size="medium"
          termId={termId}
          owner={item.owner}
          contributors={item.members as Array<UserFragment>}
          keyResult={item}
        />
      </td>
      <td id={generateKeyResultHash(item.id)} style={{ width: tdWidth || 'auto' }}>
        <KeyResultName
          keyResult={item}
          onClick={onClick}
          fontSize="large"
          css={{ margin: '23px 23px 23px 4px' }}
        />
      </td>
      <td css={{ width: '185px' }}>
        <ProgressRateTooltip keyResult={item}>
          <KeyResultProgressRateBar keyResult={item} />
        </ProgressRateTooltip>
      </td>
    </tr>
  )
}

DraggableKeyResultsTableItems.displayName = 'DraggableKeyResultsTableItems'
