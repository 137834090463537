import { css } from '@emotion/react'
import { useMemo } from 'react'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useStyles = () =>
  useMemo(
    () =>
      ({
        root: css({
          height: 20,
          display: 'flex',
          '> a': { display: 'flex' },
          '*:has(> .avatar-name)': { display: 'inline-flex' },
        }),
      } as const),
    [],
  )
