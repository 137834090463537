import React, { useCallback, useState } from 'react'

import { Link } from '../../../../components/ui/Link'
import { TextButton } from '../../../../components/ui/TextButton'
import { Trans, useTranslation } from '../../../../i18n'
import { ExternalUrls } from '../../../../urls'

import { TwoFactorAuthCreateModal } from './components/TwoFactorAuthCreateModal'
import * as styles from './styles'

export type Props = {
  loading: boolean
  otpRequiredForLogin: boolean
  updateSecuritySetting: (otpRequiredForLogin: boolean) => Promise<void>
}

export const TwoFactorAuthSetting: React.FC<Props> = ({
  loading,
  otpRequiredForLogin,
  updateSecuritySetting,
}) => {
  const { t } = useTranslation()
  const [isShowCreateTwoFactorAuthModal, setIsShowCreateTwoFactorAuthModal] =
    useState<boolean>(false)

  const handleToggleTwoFactorAuth = useCallback(() => {
    if (otpRequiredForLogin) {
      updateSecuritySetting(!otpRequiredForLogin)
      return
    }
    setIsShowCreateTwoFactorAuthModal(true)
  }, [otpRequiredForLogin, updateSecuritySetting])

  const handleCancelModal = useCallback(() => {
    setIsShowCreateTwoFactorAuthModal(false)
  }, [])

  const handleExecuteModal = useCallback(async () => {
    await updateSecuritySetting(!otpRequiredForLogin)
    setIsShowCreateTwoFactorAuthModal(false)
  }, [otpRequiredForLogin, updateSecuritySetting])

  return (
    <div css={styles.rootCss}>
      <p css={styles.sectionCss}>{t('TWO_FACTOR_AUTHENTICATION')}</p>
      <div css={styles.messageWrapperCss}>
        <Trans
          i18nKey={
            otpRequiredForLogin ? 'TWO_FA_ACTIVATED_INSTRUCTION' : 'TWO_FA_DEACTIVATED_INSTRUCTION'
          }
          components={[
            <Link
              method="newTab"
              href={ExternalUrls.TWO_FACTOR_AUTHENTICATION_SET_UP_GUIDE}
              css={styles.supportSiteLinkCss}
            >
              support site
            </Link>,
          ]}
        />
      </div>
      <TextButton
        type="button"
        size="medium"
        color="resily-orange-100"
        padding="0"
        hasUnderline
        disabled={loading}
        onClick={handleToggleTwoFactorAuth}
      >
        {t(otpRequiredForLogin ? 'DISABLE_X' : 'ENABLE_X', { x: t('TWO_FACTOR_AUTHENTICATION') })}
      </TextButton>
      <TwoFactorAuthCreateModal
        isOpened={isShowCreateTwoFactorAuthModal}
        loading={loading}
        onClickCancel={handleCancelModal}
        onClickExecute={handleExecuteModal}
      />
    </div>
  )
}

TwoFactorAuthSetting.displayName = 'TwoFactorAuthSetting'
