import { useCallback } from 'react'

import { ColorAlias } from '../../../../styles/newColors'
import { KeyResult, Objective } from '../graphql'

export type ReturnType = {
  createStyleOkrLineColor: (
    __typename: Objective['__typename'] | KeyResult['__typename'],
  ) => ColorAlias | undefined
}

export const useCreateLineColor = (): ReturnType => {
  const createStyleOkrLineColor = useCallback(
    (__typename: Objective['__typename'] | KeyResult['__typename']) => {
      const map: ReadonlyArray<[typeof __typename, ColorAlias]> = [
        ['KeyResult', 'kr-green-100'],
        ['Objective', 'objective-blue-100'],
      ]

      return map.find(([resource, _]) => resource === __typename)?.[1]
    },
    [],
  )

  return {
    createStyleOkrLineColor,
  }
}
