import React from 'react'

import { Modal } from '../../../../components/ui/Modal'
import { StyledText } from '../../../../components/ui/StyledText'
import { useTranslation } from '../../../../i18n'

import { DisabledModalProps } from './types'

export const DisabledModal: React.FC<DisabledModalProps> = ({
  children,
  isOpened,
  onClickDelete,
  onClickCancel,
  onClickOutside,
}) => {
  const { t } = useTranslation()
  return (
    <Modal
      isOpened={isOpened}
      title={t('DISABLE_X', { x: t('USER') })}
      executeText={t('DISABLING')}
      onClickExecute={onClickDelete}
      onClickCancel={onClickCancel}
      onClickOutside={onClickOutside}
    >
      <StyledText>{t('CONFIRMATION_OF_DISABLE_DESCRIPTION_X', { x: t('USER') })}</StyledText>
      {children}
    </Modal>
  )
}

DisabledModal.displayName = 'DisabledModal'
