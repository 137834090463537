import { css } from '@emotion/react'
import {
  RadioButtonGroup,
  RadioButton,
  RadioButtonGroupProps,
  RadioButtonProps,
  Popover,
} from '@resily/geisha'
import { useMemo } from 'react'

import { Icon } from '../../../../../components/ui/Icon'
import { Link } from '../../../../../components/ui/Link'
import { useTranslation } from '../../../../../i18n'
import { fontSize } from '../../../../../styles/font'
import { color } from '../../../../../styles/newColors'
import { ExternalUrls } from '../../../../../urls'

import { ActivityResourceType } from './graphql'
import { styles } from './styles/index.styles'

export type ActivityRadioButtonsProps = {
  currentActivityType: ActivityResourceType
  isOwnPage: boolean
  onChangeRadio: RadioButtonGroupProps['onChange']
}

const linkCss = css({
  ...fontSize('small', 'regular'),
  color: color('resily-orange-100'),
  textDecoration: 'underline',
  paddingTop: '4px',
})

const iconCss = css({
  verticalAlign: 'baseline',
  width: '10.5px',
  height: '10.5px',
  marginLeft: '5.75px',
})

export const ActivityRadioButtons: React.FC<ActivityRadioButtonsProps> = ({
  currentActivityType,
  isOwnPage,
  onChangeRadio,
}) => {
  const { t } = useTranslation()

  const radio = useMemo(
    (): ReadonlyArray<RadioButtonProps> => [
      {
        type: 'withIcon',
        value: ActivityResourceType.MyOkr,
        label: isOwnPage ? t('MY_OKR') : t('X_S_Y', { x: t('USER'), y: t('OKR') }),
        popoverContent: (
          <Popover.List>
            <Popover.ListItem>
              {isOwnPage
                ? t('HOME_ACTIVITY_MY_OKR_HELP_MESSAGE')
                : t('HOME_ACTIVITY_USER_OKR_HELP_MESSAGE')}
            </Popover.ListItem>
            <Popover.ListItem>
              <Link method="newTab" href={ExternalUrls.HOW_TO_HOME_ACTIVITY_MY_OKR} css={linkCss}>
                {t('MORE_DETAILS')}
                <Icon type="external" css={iconCss} />
              </Link>
            </Popover.ListItem>
          </Popover.List>
        ),
      },
      {
        type: 'withIcon',
        value: ActivityResourceType.RelatedOkr,
        label: t('CHILD_OKR'),
        popoverContent: (
          <Popover.List>
            <Popover.ListItem>
              {isOwnPage
                ? t('HOME_ACTIVITY_CHILD_OKR_HELP_MESSAGE')
                : t('HOME_ACTIVITY_USER_CHILD_OKR_HELP_MESSAGE')}
            </Popover.ListItem>
            <Popover.ListItem>
              <Link
                method="newTab"
                href={ExternalUrls.HOW_TO_HOME_ACTIVITY_CHILD_OKR}
                css={linkCss}
              >
                {t('MORE_DETAILS')}
                <Icon type="external" css={iconCss} />
              </Link>
            </Popover.ListItem>
          </Popover.List>
        ),
      },
    ],
    [isOwnPage, t],
  )

  return (
    <div css={styles.radioButtonWrapper}>
      <RadioButtonGroup
        defaultValue={currentActivityType}
        onChange={onChangeRadio}
        label={undefined}
        name={undefined}
        value={undefined}
      >
        {radio.map((r) => (
          <>
            {r.type === 'withIcon' ? (
              <RadioButton
                key={r.label?.toString()}
                type={r.type}
                value={r.value}
                label={r.label}
                checked={undefined}
                popoverContent={r.popoverContent}
              />
            ) : (
              <RadioButton
                key={r.label?.toString()}
                type={r.type}
                value={r.value}
                label={r.label}
                checked={undefined}
              />
            )}
          </>
        ))}
      </RadioButtonGroup>
    </div>
  )
}
