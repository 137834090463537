import { Fragment, useState } from 'react'

import { useTranslation } from '../../../i18n'
import { DeleteNoteModal } from '../../ui/DeleteNoteModal'
import { DetailCard } from '../../ui/DetailCard'
import { MediaFile } from '../../ui/MediaFile'
import { StyledText } from '../../ui/StyledText'
import { Reaction } from '../Reaction'

import { NoteFragment, PersonalNoteFragment } from './graphql'

export type Props = {
  note: NoteFragment | PersonalNoteFragment
  onEdit: () => void
  copyNote: (noteId: string, title: string) => void
  deleteNote: (noteId: string) => void
  createNoteReaction: (emoji: string) => void
  deleteNoteReaction: (reactedId: string) => void
}

export const NoteDetailCard: React.FC<Props> = ({
  note,
  onEdit,
  copyNote,
  deleteNote,
  createNoteReaction,
  deleteNoteReaction,
}) => {
  const [isOpened, setIsOpend] = useState(false)
  const { t } = useTranslation()

  return (
    <Fragment>
      <DeleteNoteModal
        isOpened={isOpened}
        noteType={note.__typename === 'Note' ? 'NOTE' : 'PERSONAL_NOTE'}
        noteTitle={note.title}
        onClickDelete={() => {
          deleteNote(note.id)
          setIsOpend(false)
        }}
        onClickCancel={() => setIsOpend(false)}
        onClickOutside={() => setIsOpend(false)}
      />
      <DetailCard
        editorId={`detail-note-${note.id}`}
        description={note.body?.treeJson}
        subtitle={
          <h1>
            <StyledText
              size="xxlarge"
              css={{
                wordBreak: 'break-all',
              }}
            >
              {note.title}
            </StyledText>
          </h1>
        }
        subfooter={
          <Fragment>
            <div css={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <div css={{ display: 'flex', flexWrap: 'wrap', overflow: 'hidden' }}>
                {note.attachments.map((attachment) => (
                  <div css={{ fontSize: '12px', margin: '0 8px 8px 0' }}>
                    <MediaFile
                      filename={attachment.name}
                      url={attachment.url}
                      readOnly
                      onClickDelete={() => {}}
                      key={attachment.name}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div css={{ display: 'flex', flexWrap: 'wrap', margin: '20px 8px 8px 0' }}>
              <Reaction
                reactions={note.reactions}
                createReaction={createNoteReaction}
                deleteReaction={deleteNoteReaction}
              />
            </div>
          </Fragment>
        }
        icons={[
          {
            type: 'editOutline',
            tooltip: t('EDITION'),
          },
          {
            type: 'copy',
            tooltip: t('CREATE_OF_X', { x: t('DUPLICATION') }),
          },
          {
            type: 'deleteOutline',
            tooltip: t('DELETION'),
          },
        ]}
        onClickIcon={(type) => {
          if (type === 'editOutline') onEdit()
          if (type === 'copy') copyNote(note.id, t('A_COPY_OF_X', { x: note.title }))
          if (type === 'deleteOutline') setIsOpend(true)
        }}
      />
    </Fragment>
  )
}

NoteDetailCard.displayName = 'NoteDetailCard'
