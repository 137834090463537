import { Global } from '@emotion/react'
import { useProfiler } from '@sentry/react'
import { OrgEventArgs } from 'basicprimitives'
import { OrgDiagram, OrgDiagramRef } from 'basicprimitivesreact'
import { Fragment, memo, useCallback, useEffect, useRef, useState, VFC } from 'react'
import isEqual from 'react-fast-compare'

import { State } from '../../../contexts/KrFilterContext'
import { useCurrentUser } from '../../../contexts/UserContext'
import { useTranslation } from '../../../i18n'
import { isIncludedUser } from '../../../lib/domain/objective'
import { Screen } from '../../../lib/screen'
import { useTracking, tracker } from '../../../lib/tracking'
import { color } from '../../../styles/newColors'
import { KrFilterController } from '../ui/KrFilterController'
import { OkrCardKrFoldControllerContainer } from '../ui/OkrCardKrFoldController'

import { OkrTreeMinimap } from './OkrTreeMinimap'
import { ScaleController } from './ScaleController'
import { OkrNode } from './types'
import { useDragScroll } from './useDragScroll'
import { useMonkeyPatch } from './useMonkeyPatch'
import { TREE_MAP_PADDING, useNodeConfig, Props as useNodeConfigProps } from './useNodeConfig'
import { useScaleAndScroll } from './useScaleAndScroll'

export type Props = useNodeConfigProps & {
  okrTermId: string
  krFilters: State['filters']
}

export const OkrTree: VFC<Props> = memo<Props>(
  ({
    expandLevel,
    okrTermId,
    okrTree,
    isFiltering,
    filteredOkrNodeIds,
    onNodeAdd,
    onNodeAddConnectKR,
    onOkrFloatDrawerOpen,
    openOkrModal,
    openOkrModalWithKeyResultDrawer,
  }) => {
    useProfiler('OkrTree')
    const { t } = useTranslation()
    const user = useCurrentUser()
    useTracking(t('OKR_MAP_PAGE_TITLE'), Screen.OkrMapTree)

    const diagramRef = useRef<OrgDiagramRef>(null)
    const [scale, percentage, zoomable, zoomUp, zoomDown] = useScaleAndScroll(diagramRef, okrTermId)
    const [config, isCentering, dotColorPairs, cursorItem, setPreCursorItem] = useNodeConfig({
      expandLevel,
      okrTree,
      isFiltering,
      filteredOkrNodeIds,
      onNodeAdd,
      onNodeAddConnectKR,
      onOkrFloatDrawerOpen,
      openOkrModal,
      openOkrModalWithKeyResultDrawer,
    })

    useDragScroll(diagramRef)

    useMonkeyPatch(diagramRef, dotColorPairs)

    const [scrollTarget, setScrollTarget] = useState(diagramRef.current?.scrollPanelRef.current)
    useEffect(() => {
      setScrollTarget(diagramRef.current?.scrollPanelRef.current)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [diagramRef.current?.scrollPanelRef.current])

    const onCursorChanging = useCallback(
      (_event: React.MouseEvent, data: OrgEventArgs<OkrNode>) => {
        if (data.context && !config.selectedItems.includes(data.context.id)) {
          tracker.OkrMapClickDotMenu(
            isIncludedUser(data.context.objective, user),
            data.context.objective.useWeighting,
          )

          setPreCursorItem(data.context.id)
        }

        // cancel following onCursorChanged event
        // @see https://github.com/BasicPrimitives/javascript/blob/9e7877f61515589297cc56b17a89df0a3dc236ef/src/BaseControl.js#L724
        data.cancel = true // cursorItemの変化を抑止

        // Return true in order to suppress set cursor item in control
        // it will be updated via subsequent state change and rendering event
        // @see https://www.basicprimitives.com/reactusecases/selectingcursoritem
        return true
      },
      [config.selectedItems, setPreCursorItem, user],
    )

    return (
      <Fragment>
        <Global
          styles={{
            main: {
              background: `linear-gradient(90deg, ${color(
                'background-bk-5',
              )} 22px, transparent 1%) center, linear-gradient(${color(
                'background-bk-5',
              )} 22px, transparent 1%) center, ${color('border-bk-10')}`,
              backgroundSize: '24px 24px',
            },
          }}
        />
        <div
          css={{
            height: '100%',
            position: 'relative',

            '> div': {
              outline: 'none',
            },
          }}
        >
          <OrgDiagram
            ref={diagramRef}
            centerOnCursor={isCentering}
            config={{ ...config, padding: TREE_MAP_PADDING, scale }}
            onCursorChanging={onCursorChanging}
          />

          <OkrTreeMinimap
            cursorItem={cursorItem}
            selectedItems={config.selectedItems}
            okrTree={okrTree}
            isFiltering={isFiltering}
            filteredOkrNodeIds={filteredOkrNodeIds}
            scrollTarget={scrollTarget}
          />

          <div
            style={{
              position: 'absolute',
              top: 16,
              left: 16,
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
            }}
          >
            <ScaleController
              zoomable={zoomable}
              zoomPercentage={percentage}
              onZoomUp={zoomUp}
              onZoomDown={zoomDown}
            />
            <OkrCardKrFoldControllerContainer />
            <KrFilterController />
          </div>
        </div>
      </Fragment>
    )
  },
  (prev, next) => {
    if (prev.krFilters !== next.krFilters) {
      return false
    }
    if (prev.isFiltering !== next.isFiltering) {
      return false
    }
    if (prev.okrTermId !== next.okrTermId) {
      return false
    }
    if (prev.expandLevel !== next.expandLevel) {
      return false
    }
    if (!isEqual(prev.filteredOkrNodeIds, next.filteredOkrNodeIds)) {
      return false
    }
    if (prev.okrTree !== next.okrTree) {
      return false
    }
    if (prev.onNodeAdd !== next.onNodeAdd) {
      return false
    }
    if (prev.onNodeAddConnectKR !== next.onNodeAddConnectKR) {
      return false
    }
    return true
  },
)
OkrTree.displayName = 'OKRTree'
