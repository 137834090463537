import { createContext, Dispatch, SetStateAction, useContext, useState } from 'react'

export const OkrCardIsFoldGlobalContext = createContext<boolean>(false)
export const SetOkrCardIsFoldGlobalContext = createContext<Dispatch<SetStateAction<boolean>>>(
  () => {
    throw new Error('Should use SetOkrCardIsFoldGlobalContext.Provider')
  },
)

export const OkrCardIsFoldGlobalContextProvider: React.FC = ({ children }) => {
  const [isFoldOkrCardGlobal, setIsFoldOkrCardGlobal] = useState<boolean>(false)

  return (
    <SetOkrCardIsFoldGlobalContext.Provider value={setIsFoldOkrCardGlobal}>
      <OkrCardIsFoldGlobalContext.Provider value={isFoldOkrCardGlobal}>
        {children}
      </OkrCardIsFoldGlobalContext.Provider>
    </SetOkrCardIsFoldGlobalContext.Provider>
  )
}

OkrCardIsFoldGlobalContextProvider.displayName = 'OkrCardIsFoldGlobalContextProvider'

export const useIsFoldOkrCardGlobal = (): boolean => useContext(OkrCardIsFoldGlobalContext)
export const useBulkUpdateIsFoldOkrCard = (): Dispatch<SetStateAction<boolean>> =>
  useContext(SetOkrCardIsFoldGlobalContext)
