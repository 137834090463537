import { css } from '@emotion/react'

import { Icon } from '../../../../../components/ui/Icon'
import { color } from '../../../../../styles/newColors'

const messageWithIconCss = css({
  display: 'flex',
  alignItems: 'center',
  gap: 4,
})

const messageCss = css({
  fontSize: '12px',
  lineHeight: '12px',
  color: color('text-bk-100'),
})

type Props = {
  message: string
}

export const CheckinMessageWithIcon: React.VFC<Props> = ({ message }) => (
  <div css={messageWithIconCss}>
    <Icon type="checkCircle" width={16} height={16} />
    <span css={messageCss}>{message}</span>
  </div>
)
