import { createContext, FC, useContext, useEffect, useRef, useState } from 'react'
import isEqual from 'react-fast-compare'

import { i18n } from '../i18n'
import { initTracking } from '../lib/tracking'
import { languageMap } from '../pages/AccountSetting/components/LanguageSetting'

import { OrganizationDispatchContext } from './OrganizationContext'
import { useSetCurrentUser } from './UserContext'
import { useSelfServeQuery, useUserWithOrganizationQuery } from './graphql'

export const AuthContext = createContext<boolean>(false)

export const AuthProvider: FC = ({ children }) => {
  const isInitializedTracker = useRef<boolean>(false)
  const [renderReady, setRenderReady] = useState(false)
  const [authenticated, setAuthenticated] = useState<boolean>(false)
  const updateUser = useSetCurrentUser()
  const updateOrganization = useContext(OrganizationDispatchContext)

  // UserとOrganizationのContextを一括でQueryして更新
  const { data: userWithOrganizationData, loading } = useUserWithOrganizationQuery({
    context: { ignoreForceRedirect: true },
    onCompleted: () => {
      if (!userWithOrganizationData?.user) {
        return
      }

      setAuthenticated(true)
      setRenderReady(true)

      if (isInitializedTracker.current) {
        return
      }

      const userData = userWithOrganizationData.user
      initTracking({
        userId: userData.id,
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email,
        createdAt: userData.createdAt,
        organizationId: userData.organization.id,
        organizationName: userData.organization.name,
        isTrial: userData.organization.isTrialAccount,
        isSelfServe: !!userData.organization.selfServe,
      })

      if (userData.organization.selfServe) {
        startPollingSelfServeQuery(1800000)
      }

      isInitializedTracker.current = true
    },
    onError: () => {
      setAuthenticated(false)
      setRenderReady(true)
    },
  })

  const { startPolling: startPollingSelfServeQuery } = useSelfServeQuery({
    skip: !userWithOrganizationData,
  })

  useEffect(() => {
    const changeLang = async () => {
      const langSetting = userWithOrganizationData?.user.userSetting.language
      if (!langSetting) {
        return
      }

      // CSVインポートなどの外部からの言語設定を即時反映
      const lang = Object.keys(languageMap).reduce<string>(
        (l, key) => (languageMap[key] === langSetting ? key : l),
        'ja',
      )

      // localStorage ではなく user_settings の値を優先し設定
      if (lang !== i18n.language) {
        await i18n.changeLanguage(lang)
      }
    }

    changeLang()
  }, [userWithOrganizationData?.user.userSetting.language])

  useEffect(() => {
    if (userWithOrganizationData?.user) {
      // organizationのみが変化した際に再レンダリングが走るのを避ける
      // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
      const { organization: _, ...userData } = userWithOrganizationData.user

      updateUser((prev) => (isEqual(prev, userData) ? prev : userData))
    }
  }, [updateUser, userWithOrganizationData?.user])

  useEffect(() => {
    const nextOrganization = userWithOrganizationData?.user.organization
    if (nextOrganization) {
      updateOrganization((prev) => (isEqual(prev, nextOrganization) ? prev : nextOrganization))
    }
  }, [updateOrganization, userWithOrganizationData?.user.organization])

  // 初回レンダリングは必ず完了まで待ってから子をレンダリング
  if (loading || !renderReady) {
    return null
  }

  return <AuthContext.Provider value={authenticated}>{children}</AuthContext.Provider>
}

AuthProvider.displayName = 'AuthProvider'
