import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { TabType } from '../../components/ui/Tabs'
import { useCurrentUser } from '../../contexts/UserContext'
import { useTranslation } from '../../i18n'
import { AuthRouteProps } from '../../types/authRouteProps'
import * as urls from '../../urls'

export const useInjection = ({
  onOkrTermLoaded,
}: AuthRouteProps): {
  isAdmin: boolean
  currentTab: TabType<string>
  tabs: ReadonlyArray<TabType>
} => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const currentPath = useLocation().pathname
  const admin = !!useCurrentUser()?.admin
  useEffect(() => {
    onOkrTermLoaded(null)
  }, [onOkrTermLoaded])

  const group: TabType = {
    value: 'group',
    name: t('GROUP_TAG'),
  }

  if (!admin) {
    if (currentPath === urls.groupsImportAdmin) {
      navigate(urls.groupsSettingAdmin)
    }

    return {
      isAdmin: !!admin,
      currentTab: group,
      tabs: [group],
    }
  }

  const tabs: ReadonlyArray<TabType> = [
    {
      value: 'ip',
      name: t('IP_RESTRICTION'),
    },
    {
      value: 'oauth',
      name: t('ALLOW_DOMAIN'),
    },
    {
      value: 'saml',
      name: t('SAML_AUTH'),
    },
  ]

  const currentTab = tabs[0]

  return {
    isAdmin: admin,
    currentTab,
    tabs,
  }
}
