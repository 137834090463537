import { css } from '@emotion/react'
import { useMemo } from 'react'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useStyles = () =>
  useMemo(
    () =>
      ({
        root: css({
          display: 'flex',
          gap: '24px',
          marginBottom: '24px',
        }),
        label: css({
          width: '108px',
          lineHeight: '20px',
          whiteSpace: 'pre-wrap',
        }),
        icon: css({
          marginLeft: '4px',
          verticalAlign: 'middle',
        }),
        form: css({
          flex: 1,
        }),
        editor: css({
          height: '200px',
          overflowX: 'hidden',
          overflowY: 'scroll',
        }),
      } as const),
    [],
  )
