import { border } from '../../../styles/border'
import { color } from '../../../styles/newColors'
import { PageContent } from '../../pageContent'
import { CheckInSummaryEditCard } from '../CheckInSummaryEditCard'
import { CheckinComment } from '../CheckinComment/index'
import { CheckinSummaryPageHeader } from '../CheckinSummaryPageHeader'

import { KeyResultSharedItemType, CheckinSummaryFragment, DocumentInput } from './graphql'

export type Props = {
  checkinSummary: CheckinSummaryFragment
  onClickUpdateSharedItem: (
    checkinKeyResultId: string,
    type: KeyResultSharedItemType,
    comment: DocumentInput,
  ) => void
  onClickDetailCancel: () => void
  onClickDetailSave: (treeJson: string, text: string) => void
}

export const CheckinSummaryEditPageView: React.FC<Props> = ({
  checkinSummary,
  onClickUpdateSharedItem,
  onClickDetailCancel,
  onClickDetailSave,
}) => (
  <PageContent
    breadcrumbs={undefined}
    layout={{ background: color('background-bk-5') }}
    header={
      <div css={{ width: '100%' }}>
        <CheckinSummaryPageHeader okrNode={checkinSummary.node} checkinSummary={checkinSummary} />
      </div>
    }
  >
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '28px 78px 0px',
      }}
    >
      <div
        css={{
          backgroundColor: color('white-100'),
          width: '100%',
          border: border('simple-20'),
          borderRadius: '4px',
        }}
      >
        <CheckInSummaryEditCard
          summary={checkinSummary}
          onClickCancel={onClickDetailCancel}
          onClickSave={onClickDetailSave}
          onEdit={() => {}}
        />
        <div css={{ borderBottom: border('simple-20'), margin: '14px 0 18px' }} />
        {checkinSummary.checkinSummaryKeyResults.map((item) => (
          <div key={item.id} css={{ margin: '28px 32px' }}>
            <CheckinComment
              termId={checkinSummary.node.term.id}
              checkinSummaryKeyResult={item}
              onClickSave={onClickUpdateSharedItem}
            />
          </div>
        ))}
      </div>
    </div>
  </PageContent>
)

CheckinSummaryEditPageView.displayName = 'CheckinSummaryEditPageView'
