import React, { useCallback } from 'react'

import { Checkbox } from '../../../../components/ui/Checkbox'
import { useTranslation } from '../../../../i18n'
import { useFlags } from '../../../../lib/featureToggle'

import * as styles from './styles'

type UserSetting = {
  notifyEmailEnabled: boolean
  notifyBulkUpdateEnabledOnSlack: boolean
}

export type Props = {
  loading: boolean
  userSetting: UserSetting
  organizationSlackNotification: boolean
  updateNotifyEmailEnabled: (enabled: boolean) => void
  updateNotifyBulkUpdateBySlack: (enabled: boolean) => void
}

export const NotificationSetting: React.FC<Props> = ({
  loading,
  userSetting,
  organizationSlackNotification,
  updateNotifyEmailEnabled,
  updateNotifyBulkUpdateBySlack,
}) => {
  const { t } = useTranslation()

  const { bulkUpdateSlackDm } = useFlags()

  const toggleUpdateNotifyEmailEnabled = useCallback(() => {
    updateNotifyEmailEnabled(!userSetting.notifyEmailEnabled)
  }, [updateNotifyEmailEnabled, userSetting.notifyEmailEnabled])

  const toggleUpdateNotifyBulkUpdateBySlack = useCallback(() => {
    updateNotifyBulkUpdateBySlack(!userSetting.notifyBulkUpdateEnabledOnSlack)
  }, [updateNotifyBulkUpdateBySlack, userSetting.notifyBulkUpdateEnabledOnSlack])

  return (
    <div css={styles.rootCss}>
      <p css={styles.titleCss}>{t('NOTIFICATION')}</p>
      <div css={styles.contentsWrapperCss}>
        <Checkbox
          // TODO: 子要素が増えた際は別途Checkboxの改修、出し分けが必要
          disabled={loading}
          checked={userSetting.notifyEmailEnabled}
          onChange={toggleUpdateNotifyEmailEnabled}
        >
          {t('ENABLE_EMAIL_NOTIFICATION')}
        </Checkbox>
        {false && (
          <div css={styles.settingEmailBoxCss}>
            <Checkbox
              disabled={loading}
              checked={userSetting.notifyEmailEnabled}
              onChange={toggleUpdateNotifyEmailEnabled}
            >
              {t('ENABLE_WEEKLY_REPORT_NOTIFICATION')}
            </Checkbox>
          </div>
        )}
        {bulkUpdateSlackDm && organizationSlackNotification && (
          <Checkbox
            disabled={loading}
            checked={userSetting.notifyBulkUpdateEnabledOnSlack}
            onChange={toggleUpdateNotifyBulkUpdateBySlack}
          >
            {t('NOTIFY_BULK_UPDATE_BY_SLACK')}
          </Checkbox>
        )}
      </div>
    </div>
  )
}

NotificationSetting.displayName = 'NotificationSetting'
