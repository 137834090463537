import { css } from '@emotion/react'

import { fontSize } from '../../../../../styles/font'
import { color } from '../../../../../styles/newColors'

export const rootCss = css({
  backgroundColor: color('white-100'),
  borderRadius: '8px',
  padding: '32px 24px',
})

export const sectionCss = css({
  color: color('text-bk-100'),
  ...fontSize('large', 'bold'),
})

export const messageWrapperCss = css({
  ...fontSize('medium', 'regular'),
  marginTop: '8px',
  marginBottom: '12px',
})

export const supportSiteLinkCss = css({
  color: color('resily-orange-100'),
  textDecoration: 'underline',
})
