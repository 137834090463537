import { AgendasQuery, FixedAgendasFragment, FixedAgendaStatus } from '../graphql'
import { FixedAgendasFragments, UserAgendasFragments } from '../types'

export const resolveAgendas = (
  data: AgendasQuery | undefined,
): {
  userAgendas: UserAgendasFragments
  fixedAgendas: FixedAgendasFragments
} => {
  // アジェンダの配列内のnullを除外します
  const userAgendas = data?.userAgendas.flatMap((v) => (v === null ? [] : [v])) || []
  const fixedAgendas =
    data?.fixedAgendas.filter(
      (fixedAgenda): fixedAgenda is FixedAgendasFragment =>
        fixedAgenda !== null && fixedAgenda.status === FixedAgendaStatus.Public,
    ) || []

  return { userAgendas, fixedAgendas }
}
