import { css } from '@emotion/react'

import { fontSize } from '../../../../../styles/font'

export const rootCss = css({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
})

export const sectionCss = css({
  display: 'flex',
  columnGap: '16px',
  alignItems: 'end',
  justifyContent: 'space-between',
})

export const titleCss = css({
  ...fontSize('large', 'bold'),
})

export const descriptionCss = css({
  marginTop: '4px',
  ...fontSize('medium', 'regular'),
})
