import React from 'react'

import { User } from '../../contexts/UserContext'

import { OneOnOneCard, Props as OneOnOneCardProps } from './OneOnOneCard'
import { UserOneOnOneFragment } from './graphql'

type PropsUser = Pick<User, 'lastName' | 'firstName' | 'isDisabled'> & {
  avatar: {
    url: string
  }
  groups: Array<{
    name: string
  }>
}

export type OneOnOne = {
  id: string
  user: PropsUser | null
  newAgenda?: number
  nextDate: string
  lastDate: string
  task: {
    num: number
    change: number
  }
  problem: {
    num: number
    change: number
  }
  win: {
    num: number
    change: number
  }
}

export type Props = {
  oneOnOnes: ReadonlyArray<UserOneOnOneFragment>
  handleEditMeeting: OneOnOneCardProps['handleEditMeeting']
  onDeleteOneOnOne: (id: string) => Promise<void>
}

export const OneOnOneCardList: React.FC<Props> = ({
  oneOnOnes,
  handleEditMeeting,
  onDeleteOneOnOne,
}) => (
  <div css={{ marginTop: '24px' }}>
    {oneOnOnes
      .filter((oneOnOne) => !oneOnOne.partnerUser.isDisabled)
      .map((oneOnOne) => (
        <OneOnOneCard
          key={`one-on-one-card-${oneOnOne.id}`}
          oneOnOne={oneOnOne}
          handleEditMeeting={handleEditMeeting}
          onDeleteOneOnOne={onDeleteOneOnOne}
        />
      ))}
  </div>
)

OneOnOneCardList.displayName = 'OneOnOneCardList'
