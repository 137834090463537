import { useModal } from '@resily/geisha'
import { useCallback } from 'react'

import { useTranslation } from '../../../../../i18n'
import { StyledText } from '../../../../ui/StyledText'

type ModalFC = React.VFC<{ onConfirm: () => void }>

type ReturnType = {
  DisableAlertModal: ModalFC
  open: () => void
}

export const useDisabledAlertModal = (): ReturnType => {
  const { t } = useTranslation()

  const [Modal, isOpen, { open, close }] = useModal()
  const DisableAlertModal = useCallback<ModalFC>(
    ({ onConfirm }) => (
      <Modal size="medium" isOpen={isOpen} onClose={close}>
        <Modal.Header title={t('DISABLING_X', { x: t('KEY_RESULT') })} />
        <Modal.Content>
          <StyledText fontStyle="regular">
            {t('X_CONFIRMATION_OF_DISABLE', { x: t('KEY_RESULT') })}
            <br />
            {t('EDITING_INPUT_DISCARD')}
          </StyledText>
        </Modal.Content>
        <Modal.Footer
          cancelType="tertiary"
          cancelLabel={t('CANCEL')}
          confirmLabel={t('DISABLING')}
          onCancel={close}
          onConfirm={() => {
            onConfirm()
            close()
          }}
        />
      </Modal>
    ),
    [Modal, close, isOpen, t],
  )

  return { DisableAlertModal, open }
}
