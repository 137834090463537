import {
  ELEMENT_LINK,
  getAboveNode,
  getPluginType,
  someNode,
  unwrapNodes,
  useEventEditorSelectors,
  usePlateEditorState,
} from '@udecode/plate'

import { ButtonTooltip } from './ButtonTooltip'
import { ToolbarButton } from './ToolbarButton'

export type Props = {
  tooltipMessage: string
  onActivate: () => void
}

export const ToolbarLink: React.FC<Props> = ({ tooltipMessage, children, onActivate }) => {
  const focusId = useEventEditorSelectors.focus()
  const editor = usePlateEditorState(focusId != null ? focusId : undefined)
  if (editor == null) {
    return null
  }

  const type = getPluginType(editor, ELEMENT_LINK)
  const isLink = !!editor?.selection && someNode(editor, { match: { type } })

  return (
    <ButtonTooltip message={tooltipMessage}>
      <ToolbarButton
        isActive={isLink}
        toggleNode={async () => {
          const existsLinkNode = !!getAboveNode(editor, { match: { type } })

          if (existsLinkNode && editor.selection) {
            unwrapNodes(editor, {
              at: editor.selection,
              match: { type },
            })
            return
          }

          onActivate()
        }}
      >
        {children}
      </ToolbarButton>
    </ButtonTooltip>
  )
}
