import { ChangeEvent, ReactNode, useRef, useState } from 'react'

import { Button } from '../../../../components/ui/ButtonDeprecated'
import { DateTime } from '../../../../components/ui/DateTime'
import { Section } from '../../../../components/ui/Section'
import { useTranslation } from '../../../../i18n'
import { client } from '../../../../lib/client'
import { Screen } from '../../../../lib/screen'
import { useTracking } from '../../../../lib/tracking'

import { useSamlSettingQuery } from './graphql'

type RowsProps = {
  children: ReactNode
  title: string
}

const Rows: React.FC<RowsProps> = ({ children, title }) => (
  <div>
    <h2 css={{ paddingBottom: '10px', borderBottom: '1px solid #dedfe0' }}>{title}</h2>
    <table
      css={{
        padding: '8px',

        th: {
          width: '200px',
          height: '30px',
        },
      }}
    >
      <tbody>{children}</tbody>
    </table>
  </div>
)

Rows.displayName = 'Rows'

export const AdminSamlContainer: React.FC = () => {
  const { t } = useTranslation()
  const [uploading, setUploading] = useState(false)
  const ref = useRef<HTMLInputElement>(null)
  const { data, refetch } = useSamlSettingQuery()

  useTracking(t('ADMIN_SAML_PAGE_TITLE'), Screen.SamlSetting)

  if (!data) {
    return null
  }

  const handleChangeFile = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.currentTarget.files) return
    const { files } = e.currentTarget
    const item = files.item(0)
    if (!item) return
    setUploading(true)
    client
      .uploadFiles<{ name: string; path: string; url: string }>('/upload/saml', {
        metadata: item,
      })
      .then(() => {
        refetch()
      })
      .catch(() => {})
      .finally(() => setUploading(false))
  }

  return (
    <Section>
      <Rows title={t('ID_PROVIDER')}>
        <tr>
          <th>Issuer URL</th>
          <td>{data.samlSetting.identityProvider?.issuerUrl}</td>
        </tr>
        <tr>
          <th>{t('UPDATED_ON')}</th>
          <td>
            {data.samlSetting.updatedAt && <DateTime datetime={data.samlSetting.updatedAt} />}
          </td>
        </tr>
      </Rows>
      <div
        css={{
          marginTop: '10px',
          marginBottom: '40px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <input type="file" ref={ref} onChange={handleChangeFile} css={{ display: 'none' }} />
        <Button disabled={uploading} onClick={() => ref.current?.click()}>
          {t('UPLOAD')}
        </Button>
        <span css={{ marginLeft: '10px' }}>{t('SAML_UPLOAD_HELP')}</span>
      </div>
      <Rows title={t('SERVICE_PROVIDER')}>
        <tr>
          <th>Audience</th>
          <td>{data.samlSetting.serviceProvider.audienceUrl}</td>
        </tr>
        <tr>
          <th>ACS URL</th>
          <td>{data.samlSetting.serviceProvider.acsUrl}</td>
        </tr>
        <tr>
          <th>ACS URL Validator</th>
          <td>{data.samlSetting.serviceProvider.acsUrlValidator}</td>
        </tr>
        <tr>
          <th>Single Logout URL</th>
          <td>{data.samlSetting.serviceProvider.singleLogoutUrl}</td>
        </tr>
      </Rows>
    </Section>
  )
}

AdminSamlContainer.displayName = 'AdminSamlContainer'
