import { Button } from 'grommet'
import React from 'react'

import { GeneralTitle } from '../../../../../components/ui/GeneralTitle'
import { Icon } from '../../../../../components/ui/Icon'
import { Link } from '../../../../../components/ui/Link'
import { StyledText } from '../../../../../components/ui/StyledText'
import { TextButton } from '../../../../../components/ui/TextButton'
import { Trans, useTranslation } from '../../../../../i18n'
import { border } from '../../../../../styles/border'
import { color } from '../../../../../styles/newColors'
import * as urls from '../../../../../urls'
import { ExternalUrls } from '../../../../../urls'

import { NoteOrPersonalNoteFieldFragment } from './graphql'
import { NotesTableHead } from './noteTableHead'
import { NotesTableRow, NoteTableRowSkeleton } from './noteTableRow'
import { Sort } from './types'

export type Props = {
  loading: boolean
  notes: ReadonlyArray<NoteOrPersonalNoteFieldFragment>
  termId: string | null
  sort: Sort
  isOwnNoteTab: boolean
  onClickSortBy: (sortBy: Sort['sortBy']) => void
  onCreatePersonalNote: () => void
}

export const Notes: React.VFC<Props> = ({
  loading,
  notes,
  termId,
  sort,
  isOwnNoteTab,
  onClickSortBy,
  onCreatePersonalNote,
}) => {
  const { t } = useTranslation()
  return (
    <section css={{ padding: '24px 32px' }}>
      <div
        css={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          marginBottom: '20px',
        }}
      >
        <div css={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
          <GeneralTitle title={t('NOTE')} />
          <div css={{ lineHeight: '20px' }}>
            <Trans
              i18nKey={isOwnNoteTab ? 'HOME_MY_NOTE_DESCRIPTION' : 'HOME_USER_NOTE_DESCRIPTION'}
              components={[
                <Link
                  method="newTab"
                  href={ExternalUrls.HOW_TO_USE_NOTE}
                  css={{
                    color: color('resily-orange-100'),
                    textDecoration: 'underline',
                  }}
                />,
              ]}
            />
          </div>
        </div>
        {isOwnNoteTab && (
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <TextButton
              icon="plus"
              color="text-bk-30"
              onClick={onCreatePersonalNote}
              css={{ padding: 0 }}
            >
              {t('CREATE_NOTE')}
            </TextButton>
          </div>
        )}
      </div>
      <NotesTableHead sort={sort} onClickSortBy={onClickSortBy} />
      {/* eslint-disable-next-line no-nested-ternary */}
      {loading ? (
        <ul css={{ listStyle: 'none' }}>
          {new Array(4).fill(undefined).map(() => (
            <NoteTableRowSkeleton />
          ))}
        </ul>
      ) : notes.length > 0 ? (
        <ul css={{ listStyle: 'none' }}>
          {notes.map((note) => (
            <NotesTableRow
              key={note.id}
              note={note}
              userHref={
                !termId
                  ? urls.generateUser(note.author.id)
                  : urls.generateHome(termId)(note.author.id)
              }
            />
          ))}
        </ul>
      ) : (
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            margin: 40,
            gap: 35,
          }}
        >
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 16,
            }}
          >
            <StyledText size="large" lineHeight="16px">
              {t('X_IS_EMPTY', { x: t('NOTE') })}
            </StyledText>
            {isOwnNoteTab && (
              <Button
                onClick={onCreatePersonalNote}
                css={{
                  padding: '0 16px',
                  height: '40px',
                  border: border('simple-30'),
                  borderRadius: '4px',
                  textAlign: 'center',
                  ':hover': {
                    backgroundColor: color('hover-background-bk-5'),
                  },
                }}
              >
                <StyledText>{t('CREATE_OF_X', { x: t('NOTE') })}</StyledText>
              </Button>
            )}
          </div>
          <Icon width={302} height={154} type="mailboxCat" />
        </div>
      )}
    </section>
  )
}
