import { Fragment } from 'react'

import { useTranslation } from '../../../i18n'
import { OkrDetailObjectiveFragment } from '../../../pages/ObjectiveDetail/graphql'
import { color } from '../../../styles/newColors'
import { DetailCard } from '../../ui/DetailCard'
import { MediaFile } from '../../ui/MediaFile'
import { StyledText } from '../../ui/StyledText'

export type Props = {
  objective: OkrDetailObjectiveFragment
  onEdit: () => void
}

export const ObjectiveDetailPageCard: React.FC<Props> = ({ objective, onEdit }) => {
  const { t } = useTranslation()

  return (
    <Fragment>
      <DetailCard
        editorId={`detail-o-${objective.id}`}
        description={objective.description?.treeJson}
        subtitle={
          <div css={{ display: 'flex', alignItems: 'flex-start' }}>
            <div
              css={{
                paddingLeft: '12px',
                borderLeft: `4px solid ${color('objective-blue-100')}`,
                fontWeight: 'bold',
              }}
            >
              <StyledText lineHeight="32px" size="xxlarge" isWrap>
                {objective.name}
              </StyledText>
            </div>
          </div>
        }
        subfooter={
          <Fragment>
            <div css={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <div css={{ display: 'flex', flexWrap: 'wrap', overflow: 'hidden' }}>
                {objective.attachments.map((attachment) => (
                  <div css={{ fontSize: '12px', margin: '0 8px 8px 0' }}>
                    <MediaFile
                      filename={attachment.name}
                      url={attachment.url}
                      readOnly
                      onClickDelete={() => {}}
                      key={attachment.name}
                    />
                  </div>
                ))}
              </div>
            </div>
          </Fragment>
        }
        icons={[
          {
            type: 'editOutline',
            tooltip: t('EDITION'),
          },
        ]}
        onClickIcon={(type) => {
          if (type === 'editOutline') onEdit()
        }}
      />
    </Fragment>
  )
}

ObjectiveDetailPageCard.displayName = 'ObjectiveDetailPageCard'
