import dayjs from 'dayjs'
import GraphemeSplitter from 'grapheme-splitter'
import i18n, { FormatFunction, StringMap } from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { ReactElement, useCallback } from 'react'
/* eslint-disable no-restricted-imports */
import {
  initReactI18next,
  Namespace,
  useTranslation as useTranslationOrig,
  UseTranslationOptions,
  UseTranslationResponse,
  Trans,
} from 'react-i18next'

/* eslint-enable no-restricted-imports */
import { Langs } from './language'
import en from './locales/en.json'
import ja from './locales/ja.json'
import { TKeys, TArgs, ignoreSentenceKeys, shouldKeepIntactKeys } from './type'

export const format: FormatFunction = (value, fmt) => {
  switch (fmt) {
    case undefined:
      return value
    case 'firstChar':
      return new GraphemeSplitter().splitGraphemes(value)[0]
    default:
      if (value instanceof Date) {
        return dayjs(value).format(fmt)
      }
      return value
  }
}

const resources: { [key in Langs]: { translation: { [key: string]: string } } } = {
  ja: {
    // NOTE: 直接渡すとaddResourcesを実行時に元のjaデータが上書きされてしまうバグが発生する
    //       元の言語データはデフォルト値で使用するために上書きされてしまうのを回避する
    translation: { ...ja },
  },
  en: {
    translation: { ...en },
  },
}

const FALLBACK_LOCALE = 'en' as const

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: FALLBACK_LOCALE,
    resources,
    interpolation: {
      escapeValue: false,
      format,
    },
    detection: {
      caches: ['localStorage', 'cookie'],
      lookupCookie: 'i18n',
      cookieMinutes: 10,
      cookieOptions: { path: '/', sameSite: 'strict' },
    },
  })

// use from storybook config
export { i18n, Trans }

export interface TFunction {
  // basic usage
  (key: TKeys, args?: TArgs): string
  (key: TKeys): string
  // overloaded usage
  // <TInterpolationMap extends object = StringMap>(
  //   key: TKeys | Array<TKeys>,
  //   defaultValue?: string,
  //   options?: TOptions<TInterpolationMap> | string,
  // ): string
}

export const isAbbr = (word: string): boolean =>
  word.length >= 2 && word[0] === word[0].toUpperCase() && word[1] === word[1].toUpperCase()

/** strに含まれるhを全てrに置き換える */
const findAndReplace = (str: string, h: string, r: string): string => {
  const reg = new RegExp(`(${h})`, 'gi')
  return str.replaceAll(reg, r)
}
/** i18n keyとして設定されている用語を素のt(key)用語にする */
const replaceKeyWord = (t: TFunction, str: string, keys: Array<TKeys>): string => {
  let ret = str
  keys.forEach((key: TKeys) => {
    const word = t(key)
    ret = findAndReplace(ret, word, word)
  })
  return ret
}

export const withSentence =
  (t: TFunction) =>
  (key: TKeys, options?: StringMap): string => {
    if (ignoreSentenceKeys.includes(key)) {
      /* eslint-disable @typescript-eslint/ban-ts-comment */
      // @ts-ignore
      return t(key, options)
    }
    const value = t(
      /* eslint-disable @typescript-eslint/ban-ts-comment */
      // @ts-ignore
      key,
      !options
        ? undefined
        : Object.keys(options).reduce<StringMap>((opts, k) => {
            const v = options[k]
            if (typeof v !== 'string') {
              opts[k] = v
            } else if (isAbbr(v)) {
              opts[k] = v
            } else {
              opts[k] = `${v[0].toLowerCase()}${v.substring(1)}`
            }
            return opts
          }, {}),
    )
    if (value.length === 0) {
      return value
    }
    // i18nで定義している用語をそのまま使う場合の変換
    return replaceKeyWord(t, `${value[0].toUpperCase()}${value.substring(1)}`, shouldKeepIntactKeys)
  }

// useTranslation は react-i18next の useTranslation の t 関数の引数に
// 型を導入します。
export const useTranslation = (
  ns?: Namespace,
  options?: UseTranslationOptions,
): Omit<UseTranslationResponse<Namespace<Langs>, undefined>, 't'> & { t: TFunction } => {
  const { t, ...rest } = useTranslationOrig<Namespace<Langs>>(ns, options)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const tWithSentence = useCallback(withSentence(t), [])
  return {
    t: tWithSentence,
    ...rest,
  }
}

// Language は i18n の言語を指定した lang に変更して、 children を返します。
// Storybook などで使用される想定です。
export const Language = ({
  lang,
  children,
}: {
  lang: string
  children: ReactElement
}): ReactElement => {
  const { i18n: i } = useTranslation()
  i.changeLanguage(lang)
  return children
}

export type UpdateGlossary<T extends TKeys> = { [key in T]: string }

/** デフォルトで設定されている言語に任意の用語で上書きする */
export const updateResoruce = <T extends TKeys>(
  lang: Langs,
  glossaries: UpdateGlossary<T>,
): void => {
  const origin = resources[lang]
  const newResouce: UpdateGlossary<T> = {
    // NOTE: 指定の言語がリソース表に一致しない場合
    //       フォールバック言語を返却する
    ...(origin != null ? origin.translation : resources[FALLBACK_LOCALE].translation),
    ...glossaries,
  }
  i18n.addResources(lang, 'translation', newResouce)
}
