import { useModal } from '@resily/geisha'
import { useCallback, useMemo } from 'react'

import { useEditingStateMachine } from '../../../../../contexts/EditingStateContext'
import { PartialNullable } from '../../../../../lib/type'
import {
  WeightSettingModalContainer,
  Props as WeightSettingModalContainerProps,
} from '../WeightSettingModalContainer'

export type Props = PartialNullable<
  Pick<WeightSettingModalContainerProps, 'useWeighting' | 'objectiveId' | 'keyResults'>
>

export const useWeightSettingModal = ({
  useWeighting,
  objectiveId,
  keyResults,
}: Props): [React.ReactNode, () => void] => {
  const [Modal, isOpened, { open, close }] = useModal(false)

  const { requestStartEdit, updateIsDirty, discardEdit, finishEdit } = useEditingStateMachine()

  const WeightSettingModal = useMemo(
    () =>
      isOpened && useWeighting != null && objectiveId != null && keyResults != null ? (
        <WeightSettingModalContainer
          modalComponent={Modal}
          isOpened
          useWeighting={useWeighting}
          objectiveId={objectiveId}
          keyResults={keyResults}
          onEdit={updateIsDirty}
          onClose={discardEdit}
          onConfirm={() => {
            close()
            finishEdit()
          }}
        />
      ) : null,
    [
      Modal,
      close,
      discardEdit,
      finishEdit,
      isOpened,
      keyResults,
      objectiveId,
      updateIsDirty,
      useWeighting,
    ],
  )

  const handleOpen = useCallback(() => {
    requestStartEdit(() => {
      open()
      return close
    }, 'WEIGHT_SETTING_MODAL')
  }, [close, open, requestStartEdit])

  return [WeightSettingModal, handleOpen]
}
