import { useCallback, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

import { useTranslation } from '../../../../i18n'
import { FixedAgendaStatus } from '../../api/graphql'
import { Props as StatusFilterToggleProps } from '../../components/FixedAgendaContent/components/StatusFilterToggle'
import { STATUS_FILTER_ALL } from '../../components/FixedAgendaContent/components/StatusFilterToggle/constants'
import { Value as StatusFilterToggleValue } from '../../components/FixedAgendaContent/components/StatusFilterToggle/types'

const DEFAULT_FILTER_STATUS = STATUS_FILTER_ALL

export const useStatusFilterToggle = (): StatusFilterToggleProps => {
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()

  const data: StatusFilterToggleProps['data'] = useMemo(
    () => [
      { value: STATUS_FILTER_ALL, label: t('ALL') },
      {
        value: FixedAgendaStatus.Public,
        label: t('PUBLIC_GROUP'),
      },
      { value: FixedAgendaStatus.Private, label: t('PRIVATE_GROUP') },
    ],
    [t],
  )

  const filteredStatus: StatusFilterToggleProps['value'] =
    data.find(({ value }) => value === searchParams.get('status'))?.value ?? DEFAULT_FILTER_STATUS

  const handleSelectStatus = useCallback<StatusFilterToggleProps['onSelect']>(
    (value: StatusFilterToggleValue) => setSearchParams({ status: value }),
    [setSearchParams],
  )

  return {
    value: filteredStatus,
    data,
    onSelect: handleSelectStatus,
  }
}
