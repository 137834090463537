import React, { MouseEventHandler } from 'react'

import { Button } from '../../../../components/ui/Button'
import { useTranslation } from '../../../../i18n'

import { FixedAgendaList, Props as FixedAgendaListProps } from './components/FixedAgendaList'
import {
  Props as StatusFilterToggleProps,
  StatusFilterToggle,
} from './components/StatusFilterToggle'
import { rootCss, sectionCss, titleCss, descriptionCss } from './styles'

export type Props = {
  statusFilterToggleProps: StatusFilterToggleProps
  fixedAgendaListProps: FixedAgendaListProps
  onClickCreateFixedAgenda: MouseEventHandler<HTMLButtonElement>
}

export const FixedAgendaContent: React.VFC<Props> = ({
  statusFilterToggleProps,
  fixedAgendaListProps,
  onClickCreateFixedAgenda,
}) => {
  const { t } = useTranslation()

  return (
    <div css={rootCss}>
      <div css={sectionCss}>
        <div>
          <p css={titleCss}>{t('ONEONONE') + t('ONEONONE_FIXED_AGENDA')}</p>
          <p css={descriptionCss}>{t('ONEONONE_FIXED_HELP')}</p>
        </div>
        <Button
          newColor="resily-orange-100"
          weight="normal"
          size="s"
          onClick={onClickCreateFixedAgenda}
        >
          {t('CREATE_OF_X', { x: t('ONEONONE_FIXED_AGENDA') })}
        </Button>
      </div>
      <StatusFilterToggle {...statusFilterToggleProps} />
      <FixedAgendaList {...fixedAgendaListProps} />
    </div>
  )
}
