import React, { forwardRef } from 'react'

import * as styles from './styles'

export type Props = {
  children: React.ReactNode
}

export const SectionTitle = forwardRef<HTMLParagraphElement, Props>(
  ({ children }: Props, ref: React.Ref<HTMLParagraphElement>) => (
    <p ref={ref} css={styles.rootCss}>
      {children}
    </p>
  ),
)
