import React, { forwardRef } from 'react'
import TextareaAutosize, { TextareaAutosizeProps } from 'react-textarea-autosize'

import { color } from '../../../styles/newColors'

export type Props = Omit<TextareaAutosizeProps, 'ref'> & {
  minHeight?: number
  maxHeight?: number
}

export const AutoResizeTextArea = forwardRef<HTMLTextAreaElement, Props>(
  ({ minHeight = 54, maxHeight = 76, ...props }, ref) => (
    <TextareaAutosize
      css={{
        overflow: 'auto',
        resize: 'none',
        width: '100%',
        minHeight: `${minHeight}px`,
        maxHeight: `${maxHeight}px`,
        backgroundColor: color('hover-background-bk-5'),
        border: `1px solid ${color('border-bk-30')}`,
        boxSizing: 'border-box',
        borderRadius: '4px',
        padding: '4px 8px',
        fontSize: 14,
        lineHeight: '22px',
        color: color('text-bk-100'),
        outline: 'none',
        ':hover ': {
          borderColor: color('resily-orange-100'),
        },
        '::placeholder': {
          color: color('text-bk-30'),
        },
      }}
      {...props}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ref={ref}
    />
  ),
)

AutoResizeTextArea.displayName = 'AutoResizeTextArea'
