import { Box, ColumnConfig } from 'grommet'
import { Checkmark } from 'grommet-icons'

import { DateTime } from '../../../../../../components/ui/DateTime'
import { PopoverPortal } from '../../../../../../components/ui/PopoverPortal'
import { StyledText } from '../../../../../../components/ui/StyledText'
import { WrapText } from '../../../../../../components/ui/WrapText'
import { useTranslation } from '../../../../../../i18n'
import { fontSize } from '../../../../../../styles/font'
import { color } from '../../../../../../styles/newColors'
import { ExternalUrls } from '../../../../../../urls'
import { titleCss } from '../../styles'
import { InvitingUserTableDatum } from '../types'

export const useDataTableColumns = (): Array<ColumnConfig<InvitingUserTableDatum>> => {
  const { t } = useTranslation()

  const columns: Array<ColumnConfig<InvitingUserTableDatum>> = [
    {
      property: 'fullName',
      header: (
        <StyledText size="small" fontStyle="bold" css={titleCss}>
          {t('USER')}
        </StyledText>
      ),
      search: true,
      sortable: true,
      size: '200px',
      render: (datum: InvitingUserTableDatum) => (
        <WrapText
          css={{
            ...fontSize('medium', 'regular'),
          }}
          text={datum.fullName}
          maxWidth="199px"
        />
      ),
    },
    {
      property: 'email',
      header: (
        <StyledText size="small" fontStyle="bold" css={titleCss}>
          {t('EMAIL')}
        </StyledText>
      ),
      primary: true,
      search: true,
      sortable: true,
      render: (datum: InvitingUserTableDatum) => (
        <WrapText
          css={{
            ...fontSize('medium', 'regular'),
          }}
          text={datum.email}
          maxWidth="291px"
        />
      ),
    },
    {
      property: 'admin',
      header: (
        <Box direction="row" gap="4px">
          <StyledText size="small" fontStyle="bold" css={titleCss}>
            {t('ADMINISTRATOR')}
          </StyledText>
          <PopoverPortal
            portalPosition={{
              top: 13,
              left: -200,
            }}
            icon="help"
            iconCss={{
              color: color('text-bk-50'),
              ':hover': {
                color: color('resily-orange-100'),
              },
            }}
            text={t('ADMINISTRATOR_HELP')}
            link={{
              text: t('SUPPORT_SITE'),
              href: ExternalUrls.ADMIN_PERMISSION,
            }}
            css={{
              display: 'flex',
              justifyContent: 'center',
            }}
            hasGap={false}
          />
        </Box>
      ),
      align: 'center',
      sortable: true,
      size: '110px',
      render: (datum: InvitingUserTableDatum) => (
        <Box align="center">{datum.admin && <Checkmark />}</Box>
      ),
    },
    {
      property: 'confirmationSentAt',
      header: (
        <StyledText size="small" fontStyle="bold" css={titleCss}>
          {t('CONFIRMATION_SENT_AT')}
        </StyledText>
      ),
      sortable: true,
      size: '170px',
      render: (datum: InvitingUserTableDatum) =>
        datum.confirmationSentAt && (
          <DateTime fontStyle="regular" datetime={datum.confirmationSentAt} />
        ),
    },
  ]

  return columns
}
