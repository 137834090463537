import { useCallback, useState } from 'react'

import { TargetSettingType } from '../../../graphql'
import { useTranslation } from '../../../i18n'
import { Checkbox } from '../../ui/Checkbox'
import { Icon } from '../../ui/Icon'

export type Props = {
  onClick: (targetSetting: TargetSettingType) => void
  targetSetting: TargetSettingType
}

export const KeyResultTargetSettingCheckBox: React.VFC<Props> = ({
  onClick,
  targetSetting: targetSettingProps,
}) => {
  const { t } = useTranslation()
  const [targetSetting, setTargetSetting] = useState<TargetSettingType>(targetSettingProps)

  const isMoon = targetSetting === TargetSettingType.Moon
  const isRoof = targetSetting === TargetSettingType.Roof

  const onClickMoon = useCallback(() => {
    const nextTargetSetting = isMoon ? TargetSettingType.None : TargetSettingType.Moon

    setTargetSetting(nextTargetSetting)
    onClick(nextTargetSetting)
  }, [isMoon, setTargetSetting, onClick])

  const onClickRoof = useCallback(() => {
    const nextTargetSetting = isRoof ? TargetSettingType.None : TargetSettingType.Roof

    setTargetSetting(nextTargetSetting)
    onClick(nextTargetSetting)
  }, [isRoof, setTargetSetting, onClick])

  return (
    <div css={{ display: 'flex', gap: '26px' }}>
      <Checkbox name="moon" checked={isMoon} onClick={onClickMoon}>
        <div css={{ display: 'flex', alignItems: 'center' }}>
          <Icon type="moon" />
          <span css={{ marginLeft: '5px' }}>{t('MOON_SHOT')}</span>
        </div>
      </Checkbox>
      <Checkbox name="roof" checked={isRoof} onClick={onClickRoof}>
        <div css={{ display: 'flex', alignItems: 'center' }}>
          <Icon type="roof" />
          <span css={{ marginLeft: '5px' }}>{t('ROOF_SHOT')}</span>
        </div>
      </Checkbox>
    </div>
  )
}

KeyResultTargetSettingCheckBox.displayName = 'KeyResultTargetSettingCheckBox'
