import { useCallback, useMemo, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import { BreadcrumbParams } from '../../components/domain/Breadcrumb'
import { useCurrentUser } from '../../contexts/UserContext'
import { useTranslation } from '../../i18n'
import { useOkrTermLoadable } from '../../lib/domain/useOkrTermLoadable'
import { convertToFileInput } from '../../lib/fileInput'
import { usePrompt } from '../../lib/prompt'
import { AuthRouteProps } from '../../types/authRouteProps'
import * as urls from '../../urls'
import { generatePersonalNoteEdit } from '../../urls'

import {
  File,
  FindPersonalNoteByIdDocument,
  FindPersonalNoteByIdQuery,
  FindPersonalNoteByIdQueryVariables,
  PersonalNoteCommentItemNoteFragment,
  useCopyPersonalNoteMutation,
  useCreatePersonalNoteCommentMutation,
  useCreatePersonalNoteCommentReactionMutation,
  useCreatePersonalNoteReactionMutation,
  useDeletePersonalNoteCommentMutation,
  useDeletePersonalNoteCommentReactionMutation,
  useDeletePersonalNoteMutation,
  useDeletePersonalNoteReactionMutation,
  useFindPersonalNoteByIdQuery,
  useUpdatePersonalNoteCommentMutation,
} from './graphql'

import type { Props } from './index'

export const useInjection = ({ onOkrTermLoaded }: AuthRouteProps): null | Props => {
  const { personalNoteId } = useParams()
  const user = useCurrentUser()

  const { t } = useTranslation()
  const navigate = useNavigate()
  const res = useFindPersonalNoteByIdQuery({
    variables: { id: personalNoteId ?? '' },
    // NOTE: 古い情報が表示されてしまうためキャッシュを無効にする
    fetchPolicy: 'network-only',
    skip: personalNoteId == null,
  })
  const [deletePersonalNote] = useDeletePersonalNoteMutation()
  const [copyPersonalNote] = useCopyPersonalNoteMutation()
  const [createPersonalNoteComment] = useCreatePersonalNoteCommentMutation()
  const [updatePersonalNoteComment] = useUpdatePersonalNoteCommentMutation()
  const [deletePersonalNoteComment] = useDeletePersonalNoteCommentMutation()
  const [deletePersonalNoteReaction] = useDeletePersonalNoteReactionMutation()
  const [createPersonalNoteReaction] = useCreatePersonalNoteReactionMutation()
  const [deletePersonalNoteCommentReaction] = useDeletePersonalNoteCommentReactionMutation()
  const [createPersonalNoteCommentReaction] = useCreatePersonalNoteCommentReactionMutation()

  const onChangeOkrTerm = useCallback(
    (okrTermId) => {
      if (!res.data) return
      navigate(urls.generateHome(okrTermId)(res.data.findPersonalNoteById.author.id))
    },
    [navigate, res.data],
  )
  const okrTermId = useOkrTermLoadable(onOkrTermLoaded, onChangeOkrTerm, undefined)

  const originPath = window.location.pathname + window.location.search + window.location.hash
  const [, setNeedBlock] = usePrompt({ message: t('LEAVE_WARNING'), originPath })
  const [shouldExpireCache, setShouldExpireCache] = useState(true)
  const [personalNoteUserVisibility, setPersonalNoteUserVisibility] = useState(false)

  const createPersonalNoteCommentMutation = useCallback(
    async (
      parentNoteId: string,
      body: string,
      plainText: string,
      attachments: ReadonlyArray<File>,
    ) => {
      await createPersonalNoteComment({
        variables: {
          personalNoteId: parentNoteId,
          body: { treeJson: body, plainText },
          attachments: attachments.map((attachment) => convertToFileInput(attachment)),
        },
      })
    },
    [createPersonalNoteComment],
  )

  const deletePersonalNoteMutation = useCallback(
    async (id: string) => {
      await deletePersonalNote({ variables: { id } })
      navigate(urls.meInCurrentTerm)
    },
    [deletePersonalNote, navigate],
  )

  const copyPersonalNoteMutation = useCallback(
    async (id: string, title: string) => {
      const { data } = await copyPersonalNote({ variables: { id, title } })
      if (!data) return

      navigate(urls.generatePersonalNoteEdit(data.copyPersonalNote.id))
    },
    [copyPersonalNote, navigate],
  )

  const updatePersonalNoteCommentMutation = useCallback(
    async (comment: PersonalNoteCommentItemNoteFragment) => {
      await updatePersonalNoteComment({
        variables: {
          id: comment.id,
          body: { treeJson: comment.body?.treeJson || '', plainText: comment.body.plainText || '' },
          attachments: comment.attachments.map((attachment) => convertToFileInput(attachment)),
        },
      }).catch(() => {})
    },
    [updatePersonalNoteComment],
  )

  const deletePersonalNoteCommentMutation = useCallback(
    async (id: string) => {
      await deletePersonalNoteComment({
        variables: { id },
        update(cache) {
          const option = {
            query: FindPersonalNoteByIdDocument,
            variables: { id: personalNoteId ?? '' },
          }
          const { findPersonalNoteById } =
            cache.readQuery<FindPersonalNoteByIdQuery, FindPersonalNoteByIdQueryVariables>(
              option,
            ) || {}
          if (!findPersonalNoteById) {
            return
          }

          cache.writeQuery<FindPersonalNoteByIdQuery, FindPersonalNoteByIdQueryVariables>({
            ...option,
            data: {
              findPersonalNoteById: {
                ...findPersonalNoteById,
                comments: findPersonalNoteById.comments.filter(
                  (e: PersonalNoteCommentItemNoteFragment) => e.id !== id,
                ),
              },
            },
          })
        },
      }).catch(() => {})
    },
    [deletePersonalNoteComment, personalNoteId],
  )

  const deletePersonalNoteReactionMutation = useCallback(
    async (id: string) => {
      await deletePersonalNoteReaction({
        variables: { id },
        update(cache) {
          const option = {
            query: FindPersonalNoteByIdDocument,
            variables: { id: personalNoteId ?? '' },
          }
          const { findPersonalNoteById } =
            cache.readQuery<FindPersonalNoteByIdQuery, FindPersonalNoteByIdQueryVariables>(
              option,
            ) || {}
          if (!findPersonalNoteById) {
            return
          }

          cache.writeQuery<FindPersonalNoteByIdQuery, FindPersonalNoteByIdQueryVariables>({
            ...option,
            data: {
              findPersonalNoteById: {
                ...findPersonalNoteById,
                reactions: findPersonalNoteById.reactions.filter((e) => e.id !== id),
              },
            },
          })
        },
      }).catch(() => {})
    },
    [deletePersonalNoteReaction, personalNoteId],
  )

  const createPersonalNoteReactionMutation = useCallback(
    async (emoji: string) => {
      await createPersonalNoteReaction({
        variables: { personalNoteId: personalNoteId ?? '', emoji },
        update(cache, { data }) {
          if (!data) {
            return
          }

          const option = {
            query: FindPersonalNoteByIdDocument,
            variables: { id: personalNoteId ?? '' },
          }
          const { findPersonalNoteById } =
            cache.readQuery<FindPersonalNoteByIdQuery, FindPersonalNoteByIdQueryVariables>(
              option,
            ) || {}
          if (!findPersonalNoteById) {
            return
          }

          if (
            findPersonalNoteById.reactions.find(
              (e) => e.emoji === emoji && e.user?.id === user?.id,
            ) != null
          )
            return

          cache.writeQuery<FindPersonalNoteByIdQuery, FindPersonalNoteByIdQueryVariables>({
            ...option,
            data: {
              findPersonalNoteById: {
                ...findPersonalNoteById,
                reactions: [...findPersonalNoteById.reactions, data.createPersonalNoteReaction],
              },
            },
          })
        },
      }).catch(() => {})
    },
    [createPersonalNoteReaction, personalNoteId, user?.id],
  )

  const deletePersonalNoteCommentReactionMutation = useCallback(
    async (personalNoteCommentId: string, id: string) => {
      await deletePersonalNoteCommentReaction({
        variables: { id },
        update(cache) {
          const option = {
            query: FindPersonalNoteByIdDocument,
            variables: { id: personalNoteId ?? '' },
          }
          const { findPersonalNoteById } =
            cache.readQuery<FindPersonalNoteByIdQuery, FindPersonalNoteByIdQueryVariables>(
              option,
            ) || {}
          if (!findPersonalNoteById) {
            return
          }

          const deletedReactionComments =
            findPersonalNoteById.comments.map<PersonalNoteCommentItemNoteFragment>((comment) => ({
              ...comment,
              reactions:
                comment.id !== personalNoteCommentId
                  ? comment.reactions
                  : comment.reactions.filter((e) => e.id !== id),
            }))
          cache.writeQuery<FindPersonalNoteByIdQuery, FindPersonalNoteByIdQueryVariables>({
            ...option,
            data: {
              findPersonalNoteById: {
                ...findPersonalNoteById,
                comments: deletedReactionComments,
              },
            },
          })
        },
      }).catch(() => {})
    },
    [deletePersonalNoteCommentReaction, personalNoteId],
  )

  const createPersonalNoteCommentReactionMutation = useCallback(
    async (personalNoteCommentId: string, emoji: string) => {
      await createPersonalNoteCommentReaction({
        variables: { personalNoteCommentId, emoji },
        update(cache, { data }) {
          if (!data) {
            return
          }

          const option = {
            query: FindPersonalNoteByIdDocument,
            variables: { id: personalNoteId ?? '' },
          }
          const { findPersonalNoteById } =
            cache.readQuery<FindPersonalNoteByIdQuery, FindPersonalNoteByIdQueryVariables>(
              option,
            ) || {}
          if (!findPersonalNoteById) {
            return
          }

          const comm = findPersonalNoteById.comments.find((c) => c.id === personalNoteCommentId)
          if (
            !comm ||
            comm.reactions.find((e) => e.emoji === emoji && e.user?.id === user?.id) != null
          ) {
            return
          }

          const createdReactionComments =
            findPersonalNoteById.comments.map<PersonalNoteCommentItemNoteFragment>((comment) => ({
              ...comment,
              reactions:
                comment.id !== personalNoteCommentId
                  ? comment.reactions
                  : [...comment.reactions, data.createPersonalNoteCommentReaction],
            }))
          cache.writeQuery<FindPersonalNoteByIdQuery, FindPersonalNoteByIdQueryVariables>({
            ...option,
            data: {
              findPersonalNoteById: {
                ...findPersonalNoteById,
                comments: createdReactionComments,
              },
            },
          })
        },
      }).catch(() => {})
    },
    [createPersonalNoteCommentReaction, personalNoteId, user?.id],
  )

  const author = useMemo<Props['author'] | undefined>(() => {
    if (!user) {
      return undefined
    }

    return {
      userId: user.id,
      lastName: user.lastName,
      firstName: user.firstName,
      isDisabled: user.isDisabled,
      avatarUrl: user.avatar?.url || '',
    }
  }, [user])

  const personalNote = res.data?.findPersonalNoteById

  const breadcrumbs = useMemo<BreadcrumbParams | undefined>(
    () =>
      personalNote && user && okrTermId
        ? {
            url: urls.personalNote,
            items: [
              {
                breadcrumbName: 'homeNote',
                ...user,
                okrTermId,
              },
              {
                breadcrumbName: 'personalNote',
                ...personalNote,
              },
            ],
          }
        : undefined,
    [okrTermId, personalNote, user],
  )

  const onPersonalNoteEdit = useCallback(() => {
    if (!personalNote?.id) return
    navigate(generatePersonalNoteEdit(personalNote.id))
  }, [navigate, personalNote?.id])

  const onDrawerClose = useCallback(() => setPersonalNoteUserVisibility(false), [])
  const onPersonalNoteUserUpdate = useCallback(() => setShouldExpireCache(true), [])
  const onPersonalNoteUserClose = useCallback(() => setPersonalNoteUserVisibility(false), [])

  if (!author || !personalNote || !breadcrumbs) {
    return null
  }

  return {
    author,
    personalNote,
    breadcrumbs,
    deletePersonalNote: deletePersonalNoteMutation,
    copyPersonalNote: copyPersonalNoteMutation,
    createPersonalNoteReaction: createPersonalNoteReactionMutation,
    deletePersonalNoteReaction: deletePersonalNoteReactionMutation,
    createPersonalNoteComment: createPersonalNoteCommentMutation,
    updatePersonalNoteComment: updatePersonalNoteCommentMutation,
    deletePersonalNoteComment: deletePersonalNoteCommentMutation,
    createPersonalNoteCommentReaction: createPersonalNoteCommentReactionMutation,
    deletePersonalNoteCommentReaction: deletePersonalNoteCommentReactionMutation,
    onPersonalNoteEdit,
    setNeedBlock,
    isDrawerOpen: personalNoteUserVisibility,
    shouldExpireCache,
    onDrawerClose,
    onPersonalNoteUserUpdate,
    onPersonalNoteUserClose,
  }
}
