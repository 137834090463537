import { css } from '@emotion/react'
import { useMemo } from 'react'

import { color } from '../../../../../styles/newColors'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useStyles = () =>
  useMemo(
    () =>
      ({
        tableRow: css({
          height: '48px',
          display: 'flex',
          alignItems: 'center',
          borderBottom: `1px solid ${color('border-bk-10')}`,
          padding: '4px 0',
        }),
        avatar: css({
          paddingLeft: 21,
        }),
        krTitle: css({
          paddingLeft: 15,
          paddingRight: 4,
        }),
        progressRate: css({
          paddingLeft: 12,
          paddingRight: 12,
        }),
        values: css({
          paddingLeft: 8,
        }),
        lastUpdate: css({
          paddingLeft: 8,
        }),
      } as const),
    [],
  )
