import { Skeleton } from '@resily/geisha'

import { useTranslation } from '../../../../../../i18n'
import { StyledText } from '../../../../../ui/StyledText'

import { useStyles } from './index.styles'

export const HeaderAreaSkeleton: React.VFC = () => {
  const styles = useStyles()
  const { t } = useTranslation()

  return (
    <div css={styles.root}>
      <div css={styles.titleArea}>
        <Skeleton text height={12} width={384} />
      </div>
      <div css={styles.detailArea}>
        <div css={styles.userArea}>
          <div css={styles.owner}>
            <StyledText size="xsmall" fontStyle="regular" lineHeight="14px" color="text-bk-50">
              {t('OWNER')}
            </StyledText>
            <Skeleton circle height={16} />
            <Skeleton text height={10} width={58} />
          </div>
          <div css={styles.contributors}>
            <StyledText size="xsmall" fontStyle="regular" lineHeight="14px" color="text-bk-50">
              {t('CONTRIBUTOR')}
            </StyledText>
            <Skeleton circle height={16} />
            <Skeleton text height={10} width={40} />
            <Skeleton circle height={16} />
            <Skeleton text height={10} width={40} />
          </div>
        </div>
        <div css={styles.progressAndChildObjectiveArea}>
          <div css={styles.progressArea}>
            <div css={styles.progressRateAndValueArea}>
              <div css={styles.progressRateArea}>
                <StyledText size="xsmall" fontStyle="regular" color="text-bk-50">
                  {t('PROGRESS')}
                </StyledText>
                <Skeleton text height={12} width={84} />
              </div>
              <div css={styles.actualAndTarget}>
                <StyledText size="xsmall" fontStyle="regular" color="text-bk-50">
                  {t('ACTUAL_AND_TARGET_VALUE')}
                </StyledText>
                <Skeleton text height={12} width={118} />
              </div>
            </div>
          </div>

          <div css={styles.childObjective}>
            <Skeleton text height={12} width={223} />
          </div>
        </div>
      </div>
    </div>
  )
}
