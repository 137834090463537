export const scrollHash = ({
  scrollContentId,
  matchDomId,
  top = 0,
}: {
  scrollContentId: string
  matchDomId: RegExp
  top?: number
}): void => {
  const hash = window.location.hash || ''
  const domId = hash.replace('#', '')
  if (!domId.match(matchDomId)) return

  const contentLayout = document.getElementById(scrollContentId)
  const target = document.getElementById(domId)
  if (!target || !contentLayout) return

  const rect = target.getBoundingClientRect()
  contentLayout.scrollTop = rect.top + top
}
