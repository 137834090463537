import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'

dayjs.extend(isBetween)

const DateInputFormat = 'YYYY/MM/DD'
const TimeInputFormat = 'hh:mm'
const DateTimeInputFormat = 'YYYY/MM/DD HH:mm'
const GraphqlFormat = 'YYYY-MM-DDTHH:mm:ssZ'

export const formatDateInput = (
  date: string | Date,
  fmt: typeof DateInputFormat | typeof DateTimeInputFormat | undefined = DateInputFormat,
): string => (dayjs(new Date(date)).isValid() ? dayjs(new Date(date)).format(fmt) : '')

export const formatDateGraphql = (date: string): string | null =>
  dayjs(new Date(date)).isValid() ? dayjs(new Date(date)).format(GraphqlFormat) : null

export const formatTimeInput = (time: string): string =>
  dayjs(new Date(time)).isValid() ? dayjs(new Date(time)).format(TimeInputFormat) : ''

// time は 07:00 のような文字列
export const dateFromTime = (time: string): Date => {
  const date = `${dayjs(new Date()).format(DateInputFormat)}T${time}`
  return new Date(date)
}

export const timezoneOffset = (): number => new Date().getTimezoneOffset()

export const formatDuration = ({
  startDate: _startDate,
  endDate: _endDate,
}: {
  startDate: Date
  endDate: Date
}): string => {
  const startDate = new Date(_startDate)
  const endDate = new Date(_endDate)

  if (!dayjs(startDate).isSame(endDate, 'year')) {
    const start = dayjs(startDate).format('YYYY.MM.DD')
    const end = dayjs(endDate).format('YYYY.MM.DD')

    return `${start}-${end}`
  }

  if (!dayjs(startDate).isSame(endDate, 'month')) {
    const start = dayjs(startDate).format('YYYY.MM.DD')
    const end = dayjs(endDate).format('MM.DD')

    return `${start}-${end}`
  }

  const start = dayjs(startDate).format('YYYY.MM.DD')
  const end = dayjs(endDate).format('DD')

  return `${start}-${end}`
}

export const formatNowDate = (): string => dayjs(new Date()).format('YYYY_MM_DD')
