import { css } from '@emotion/react'
import { Box } from 'grommet'
import React, {
  Dispatch,
  SetStateAction,
  useState,
  useCallback,
  useMemo,
  useContext,
  useEffect,
} from 'react'
import { v1 as uuid } from 'uuid'

import { OrganizationContext } from '../../../contexts/OrganizationContext'
import { useTranslation, Trans } from '../../../i18n'
import { toggle } from '../../../lib/array'
import { getCandidateUsers } from '../../../lib/domain/users'
import { findScreen } from '../../../lib/screen'
import { generateTestId, featureNames, componentNames } from '../../../lib/testId'
import { tracker } from '../../../lib/tracking'
import { border } from '../../../styles/border'
import { fontSize } from '../../../styles/font'
import { color } from '../../../styles/newColors'
import { integrationsAdmin } from '../../../urls'
import { File } from '../../standalone/OkrModal/graphql'
import { Checkbox } from '../../ui/Checkbox'
import { Input } from '../../ui/Input'
import { Link } from '../../ui/Link'
import { Popover } from '../../ui/Popover'
import { RichTextEditor } from '../../ui/RichTextEditor'
import { SlackSelect } from '../../ui/SlackSelect'
import { StyledText, Props as StyledTextProps } from '../../ui/StyledText'
import { TextButton } from '../../ui/TextButton'
import { useAttachmentsView } from '../Attachments/hooks/useAttachmentsView'
import { ParentOkrSelectContainer } from '../ParentOkrSelect'
import { UserSelect } from '../UserSelect'

import { GroupSelect } from './GroupSelect'
import { ParentKeyResultSelect } from './ParentKeyResultSelect'
import {
  GroupFragment,
  OkrNodeFragment,
  SlackChannelFieldsFragment,
  useGroupsLazyQuery,
  useOkrNodeLazyQuery,
  useReadSlackChannelListLazyQuery,
  UserFragment,
  useUsersLazyQuery,
} from './graphql'

const RequiredItemCss = css({
  '&::after': {
    color: color('resily-orange-100'),
    content: '"*"',
  },
})

const ChatIntegrationAreaCss = css({
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '1fr 3fr',
  rowGap: 8,
  marginLeft: 24,
})

const ChatIntegrationInputCss = css({
  ...fontSize('small', 'regular'),
  display: 'contents',
})

export type State = {
  name: string
  parentOkrNode?: OkrNodeFragment
  owner?: UserFragment
  selectedGroups: ReadonlyArray<GroupFragment>
  description: { plainText: string; body?: string }
  attachmentViews: ReadonlyArray<File>
  isDescriptionChanged: boolean
  parentKeyResultIds: ReadonlyArray<string>
  slackChannelIds: ReadonlyArray<string>
  chatworkApiToken?: string
  chatworkRoomId?: string
  teamsWebhookURL?: string
}

export type Props = {
  // 編集モーダルで親OKR選択時に自身が表示されないようにする対策
  editingObjectiveId?: string
  state: State
  setState: Dispatch<SetStateAction<State>>
  slackNotifyFlg: boolean
  handleSlackNotifyFlg: () => void
  slackIntegratedChannelList?: ReadonlyArray<SlackChannelFieldsFragment>
  chatworkNotifyFlg: boolean
  handleChatworkNotifyFlg: () => void
  teamsNotifyFlg: boolean
  handleTeamsNotifyFlg: () => void
}

export const OkrCreateModalContent: React.FC<Props> = ({
  editingObjectiveId,
  state,
  setState,
  slackNotifyFlg,
  handleSlackNotifyFlg,
  slackIntegratedChannelList,
  chatworkNotifyFlg,
  handleChatworkNotifyFlg,
  teamsNotifyFlg,
  handleTeamsNotifyFlg,
}) => {
  const { t } = useTranslation()
  const [okrNodeQuery] = useOkrNodeLazyQuery({
    onCompleted: (data) => {
      setState((prev) => ({
        ...prev,
        parentOkrNode: data.okrNode,
        parentKeyResultIds: [],
      }))
      setIsParentOkrSelected(true)
    },
  })
  const [usersQuery, usersResp] = useUsersLazyQuery()
  const [groupsQuery, groupsResp] = useGroupsLazyQuery()
  const [readSlackChannel, slackChannelData] = useReadSlackChannelListLazyQuery()

  const organization = useContext(OrganizationContext)
  const [selectedSlackChannels, setSelectedSlackChannels] = useState<
    ReadonlyArray<SlackChannelFieldsFragment>
  >(slackIntegratedChannelList ?? [])
  const [oldChatworkToken, setOldChatworkToken] = useState<{
    apiToken: string | undefined
    roomId: string | undefined
  }>({ apiToken: state.chatworkApiToken, roomId: state.chatworkRoomId })
  const [oldTeamsWebhookURL, setOldTeamsWebhookURL] = useState<string | undefined>(
    state.teamsWebhookURL,
  )
  const [isShowParentKeyResultSelectForm, setIsShowParentKeyResultSelectForm] = useState<boolean>(
    !!state?.parentOkrNode?.id,
  )
  const [isParentOkrSelected, setIsParentOkrSelected] = useState<boolean>(
    !!state?.parentOkrNode?.id,
  )

  const setName = (name: State['name']) => setState((prev) => ({ ...prev, name }))
  const setDescription = useCallback(
    (description: State['description']) => setState((prev) => ({ ...prev, description })),
    [setState],
  )

  const users = useMemo<ReadonlyArray<UserFragment>>(() => {
    const candidateUsers = getCandidateUsers<UserFragment>(usersResp.data?.users || [])
    const ownerUser = state.owner != null ? [state.owner] : []
    return candidateUsers.length > 0 ? candidateUsers : ownerUser
  }, [state.owner, usersResp.data?.users])

  const parentKeyResults = useMemo(
    () => state.parentOkrNode?.keyResults.filter((kr) => !kr.isDisabled) ?? [],
    [state.parentOkrNode?.keyResults],
  )

  /**
   * 「グループタグ」のセレクトボックスを開く処理
   */
  const onOpenGroupTagSelect = useCallback(() => {
    if (!groupsResp.called) {
      groupsQuery()
    }
  }, [groupsQuery, groupsResp.called])

  /**
   * Objective選択処理
   * @param {string} parentOkrNode
   */
  const onChangeOkrSelect = useCallback(
    (parentOkrNodeId: string | undefined) => {
      if (!parentOkrNodeId) {
        setState((prev) => ({
          ...prev,
          parentOkrNode: undefined,
          parentKeyResultIds: [],
        }))
        setIsParentOkrSelected(false)
        return
      }

      okrNodeQuery({ variables: { okrNodeId: parentOkrNodeId } })
    },
    [okrNodeQuery, setState],
  )

  /**
   * 「KRの紐づきを追加」クリック時の処理
   * (親KeyResultのselectフォームを表示させる)
   */
  const onClickShowParentKeyResultSelectForm = useCallback(
    () => setIsShowParentKeyResultSelectForm(true),
    [],
  )

  /**
   * Key Result選択処理
   * @param {Array<string>} value
   */
  const onChangeKeyResultSelect = useCallback(
    (value: Array<string>) => {
      setState((prev) => ({
        ...prev,
        parentKeyResultIds: value.some((id) => prev.parentKeyResultIds.includes(id))
          ? prev.parentKeyResultIds.filter((id) => !value.includes(id))
          : [...prev.parentKeyResultIds, ...value],
      }))
    },
    [setState],
  )

  /**
   * Slack連携フォームの活性、非活性処理
   */
  const handleSlackForm = useCallback(() => {
    // 「Slackと連携する」のチェックを外す際は、選択済みのSlackチャンネルリストをリセット
    if (slackNotifyFlg) {
      setState((prev) => ({
        ...prev,
        slackChannelIds: [],
      }))
    } else {
      setState((prev) => ({
        ...prev,
        slackChannelIds: selectedSlackChannels.map((channel) => channel.id),
      }))
    }
    handleSlackNotifyFlg()
  }, [slackNotifyFlg, handleSlackNotifyFlg, setState, selectedSlackChannels])

  /**
   * slackチャンネル選択、選択解除処理
   * @param {string} channelId
   */
  const onClickSelectSlackChannel = useCallback(
    (channelId: string) => {
      if (selectedSlackChannels.some((channel) => channel.id === channelId)) {
        // 選択済みの場合、チャンネル選択解除 (マルチセレクトに表示する選択済みのチャンネルリスト)
        const newSelectedSlackChannels = selectedSlackChannels.filter(
          (channel) => channel.id !== channelId,
        )
        setSelectedSlackChannels(newSelectedSlackChannels)
      } else {
        // 未選択の場合、チャンネル選択 (マルチセレクトに表示する選択済みのチャンネルリスト)
        const addSlackChannel = slackChannelData.data?.readSlackChannelList.find(
          (channel) => channel.id === channelId,
        )
        if (addSlackChannel) {
          setSelectedSlackChannels([...selectedSlackChannels, addSlackChannel])
        }
      }

      // 新規OKR作成時にmutationに渡すSlackチャンネルのIDリスト
      setState((prev) => ({
        ...prev,
        slackChannelIds: toggle(prev.slackChannelIds, channelId),
      }))
    },
    [selectedSlackChannels, slackChannelData.data?.readSlackChannelList, setState],
  )

  /**
   * Chatwork連携フォームの活性、非活性処理
   */
  const handleChatworkForm = useCallback(() => {
    if (chatworkNotifyFlg) {
      setState((prev) => ({
        ...prev,
        chatworkApiToken: undefined,
        chatworkRoomId: undefined,
      }))
      setOldChatworkToken({
        apiToken: state.chatworkApiToken,
        roomId: state.chatworkRoomId,
      })
    } else {
      setState((prev) => ({
        ...prev,
        chatworkApiToken: oldChatworkToken.apiToken,
        chatworkRoomId: oldChatworkToken.roomId,
      }))
    }
    handleChatworkNotifyFlg()
  }, [
    chatworkNotifyFlg,
    handleChatworkNotifyFlg,
    oldChatworkToken.apiToken,
    oldChatworkToken.roomId,
    setState,
    state.chatworkApiToken,
    state.chatworkRoomId,
  ])

  /**
   * Teams連携フォームの活性、非活性処理
   */
  const handleTeamsForm = useCallback(() => {
    if (teamsNotifyFlg) {
      setState((prev) => ({
        ...prev,
        teamsWebhookURL: undefined,
      }))
      setOldTeamsWebhookURL(state.teamsWebhookURL)
    } else {
      setState((prev) => ({
        ...prev,
        teamsWebhookURL: oldTeamsWebhookURL,
      }))
    }
    handleTeamsNotifyFlg()
  }, [handleTeamsNotifyFlg, oldTeamsWebhookURL, setState, state.teamsWebhookURL, teamsNotifyFlg])

  // 添付ファイル
  const onChangeAttachments = useCallback(
    (files: ReadonlyArray<File>) => setState((prev) => ({ ...prev, attachmentViews: files })),
    [setState],
  )
  const { AttachmentsView } = useAttachmentsView(state.attachmentViews)

  const handleOnClickHelpLink = useCallback(() => {
    if (!editingObjectiveId) {
      tracker.UserClickShowExternalAddUserPageByObjectiveCreateModal(
        findScreen(window.location.pathname, window.location.search),
      )
    } else {
      tracker.UserClickShowExternalAddUserPageByObjectiveUpdateModal(
        findScreen(window.location.pathname, window.location.search),
      )
    }
  }, [editingObjectiveId])

  useEffect(() => {
    if (organization?.slackIntegrationEnabled) {
      // 初期表示時にSlackチャンネルを取得する
      readSlackChannel()
    }
    groupsQuery()
  }, [readSlackChannel, organization?.slackIntegrationEnabled, groupsQuery])

  return (
    <div css={{ padding: '0px 16px' }}>
      <Row
        label={<Label label={t('TITLE')} css={RequiredItemCss} />}
        form={
          <Input
            autoFocus
            width="100%"
            fontSize="medium"
            fieldStyle="bottomLine"
            placeholder={t('INPUT_X', { x: t('OBJECTIVE_NAME') })}
            value={state.name}
            onInput={(e) => setName(e.currentTarget.value)}
          />
        }
      />

      <Row
        isHelp
        text={t('PARENT_OKR_HELP_MESSAGE')}
        label={<Label label={t('PARENT_OKR')} />}
        form={
          <ParentOkrSelectContainer
            fullWidth
            value={state.parentOkrNode}
            editingObjectiveId={editingObjectiveId}
            onChange={(n) => onChangeOkrSelect(n?.id)}
          />
        }
      />

      {isParentOkrSelected && !isShowParentKeyResultSelectForm && (
        <Row
          label={<Label label={' '} css={{ lineHeight: '20px', marginTop: '10px' }} />}
          form={
            <TextButton
              data-testid={generateTestId(
                featureNames.okrCreateModal,
                componentNames.addConnectionKrButton,
              )}
              icon="plus"
              color="text-bk-30"
              padding="none"
              onClick={onClickShowParentKeyResultSelectForm}
              css={{ margin: '0px', height: '20px' }}
            >
              {t('ADD_CONNECTION_KR')}
            </TextButton>
          }
        />
      )}

      {isParentOkrSelected && isShowParentKeyResultSelectForm && (
        <Row
          isHelp
          text={t('PARENT_KEY_RESULTS_HELP_MESSAGE')}
          label={
            <Label
              label={t('PARENT_KEY_RESULT')}
              css={{ lineHeight: '20px', whiteSpace: 'pre-wrap' }}
            />
          }
          form={
            <ParentKeyResultSelect
              disabled={!isParentOkrSelected || parentKeyResults.length === 0}
              value={parentKeyResults.filter((c) => state.parentKeyResultIds.includes(c.id))}
              candidates={parentKeyResults}
              onChange={(value) => onChangeKeyResultSelect(value)}
            />
          }
        />
      )}

      <Row
        label={<Label label={t('OWNER')} css={RequiredItemCss} />}
        form={
          <div css={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <UserSelect
              css={{
                '> button': {
                  border: border('simple-30'),
                  borderRadius: 4,
                },
                // stylelint-disable-next-line selector-class-pattern
                '.NewSearchSelect-ValueLabel': {
                  height: 40,
                  padding: '6px 12px',
                },
              }}
              data-testid={generateTestId(featureNames.okrCreateModal, componentNames.ownerSelect)}
              nameFontSize="medium"
              value={state.owner?.id || undefined}
              options={users}
              onOpen={!usersResp.called ? usersQuery : undefined}
              isLoading={!usersResp.data?.users && (!usersResp.called || usersResp.loading)}
              onChange={(ownerId) =>
                setState((prev) => ({ ...prev, owner: users.find((e) => e.id === ownerId) }))
              }
              onClickHelpLink={handleOnClickHelpLink}
            />
          </div>
        }
      />
      <Row
        label={<Label label={t('GROUP')} />}
        form={
          <GroupSelect
            data-testid={generateTestId(featureNames.okrCreateModal, componentNames.groupSelect)}
            selectedGroups={state.selectedGroups}
            groups={groupsResp.data?.groups || []}
            onOpen={onOpenGroupTagSelect}
            isLoading={!groupsResp.called || groupsResp.loading}
            onClickGroupOption={(id) => {
              if (groupsResp.data?.groups != null) {
                const g = groupsResp.data.groups.find((e) => e.id === id)
                if (g) {
                  setState((prev) => ({
                    ...prev,
                    selectedGroups: prev.selectedGroups.find((e) => e.id === g.id)
                      ? prev.selectedGroups.filter((sg) => sg.id !== g.id)
                      : [...prev.selectedGroups, g],
                  }))
                }
              }
            }}
          />
        }
      />

      <Row
        isHelp
        text={t('TARGET_BACKGROUND_HELP_MESSAGE')}
        label={<Label label={t('OKR_BACKGROUND')} css={{ lineHeight: '20px' }} />}
        form={
          <>
            <RichTextEditor
              id={useMemo(uuid, [])}
              css={{
                height: '140px',
                overflowX: 'hidden',
                overflowY: 'scroll',
                minWidth: '100%',
              }}
              editorProps={useMemo(() => ({ placeholder: t('OKR_BACKGROUND_PLACEHOLDER') }), [t])}
              initialValueJSON={state.description.body}
              onChange={useCallback(
                (body, plainText) => setDescription({ body, plainText }),
                [setDescription],
              )}
              autoFocus={false}
            />
            <AttachmentsView readOnly={false} onChange={onChangeAttachments} />
          </>
        }
        alignItems="start"
      />

      <Row
        label={<Label label={t('CHAT_INTEGRATION')} />}
        alignItems="start"
        form={
          <div css={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
            {/* Slack */}
            {organization?.slackIntegrationEnabled ? (
              <Checkbox
                checked={slackNotifyFlg}
                iconColor="resily-orange-100"
                onChange={handleSlackForm}
              >
                <StyledText color="text-bk-100" size="medium">
                  {t('INTEGRATE_WITH_SLACK')}
                </StyledText>
              </Checkbox>
            ) : (
              <Box direction="row" align="center">
                <Popover
                  targetContent={
                    <Checkbox
                      css={{ paddingRight: '8px', height: '20px', marginTop: '2px' }}
                      disabled
                    />
                  }
                  dropdownContent={
                    <Popover.DropdownContent
                      text={
                        <Trans
                          i18nKey="POPOVER_TEXT_TO_WORK_WITH_SLACK"
                          components={[
                            <Link
                              href={integrationsAdmin}
                              method="newTab"
                              css={{
                                color: color('resily-orange-100'),
                                ':hover': {
                                  textDecoration: 'underline',
                                },
                              }}
                            >
                              {t('ORGANIZATION_SETTINGS')}
                            </Link>,
                          ]}
                        />
                      }
                    />
                  }
                />
                <StyledText color="text-bk-10" size="medium">
                  {t('INTEGRATE_WITH_SLACK')}
                </StyledText>
              </Box>
            )}
            {slackNotifyFlg && (
              <div
                css={[
                  ChatIntegrationAreaCss,
                  { width: '100%', marginTop: 5, marginBottom: 16, columnGap: 24 },
                ]}
              >
                <Label
                  label={t('NOTIFY_SLACK_CHANNEL')}
                  weight="normal"
                  size="medium"
                  lineHeight="24px"
                  css={[RequiredItemCss, { alignSelf: 'flex-start', whiteSpace: 'nowrap' }]}
                />
                <SlackSelect
                  disabled={false}
                  selectedSlackChannels={selectedSlackChannels}
                  channels={slackChannelData.data?.readSlackChannelList || []}
                  isLoading={!slackChannelData.called || slackChannelData.loading}
                  onClickGroupOption={onClickSelectSlackChannel}
                />
              </div>
            )}

            {/* Chatwork */}
            <Checkbox
              checked={chatworkNotifyFlg}
              iconColor="resily-orange-100"
              onChange={handleChatworkForm}
            >
              <StyledText color="text-bk-100" size="medium">
                {t('INTEGRATE_WITH_CHATWORK')}
              </StyledText>
            </Checkbox>
            {chatworkNotifyFlg && (
              <div css={ChatIntegrationAreaCss}>
                <label css={ChatIntegrationInputCss}>
                  <span css={RequiredItemCss}>{t('API_TOKEN')}：</span>
                  <Input
                    fontSize="medium"
                    fieldStyle="bottomLine"
                    placeholder={t('INPUT_X', { x: t('API_TOKEN') })}
                    value={state.chatworkApiToken}
                    onInput={(e) =>
                      setState((prev) => ({ ...prev, chatworkApiToken: e.currentTarget.value }))
                    }
                  />
                </label>
                <label css={ChatIntegrationInputCss}>
                  <span css={RequiredItemCss}>{t('ROOM_ID')}：</span>
                  <Input
                    fontSize="medium"
                    fieldStyle="bottomLine"
                    placeholder={t('INPUT_X', { x: t('ROOM_ID') })}
                    value={state.chatworkRoomId}
                    onInput={(e) =>
                      setState((prev) => ({ ...prev, chatworkRoomId: e.currentTarget.value }))
                    }
                  />
                </label>
              </div>
            )}

            {/* Teams */}
            <Checkbox
              checked={teamsNotifyFlg}
              iconColor="resily-orange-100"
              onChange={handleTeamsForm}
            >
              <StyledText color="text-bk-100" size="medium">
                {t('INTEGRATE_WITH_TEAMS')}
              </StyledText>
            </Checkbox>
            {teamsNotifyFlg && (
              <div css={ChatIntegrationAreaCss}>
                <label css={ChatIntegrationInputCss}>
                  <span css={RequiredItemCss}>{t('WEBHOOK_URL')}：</span>
                  <Input
                    fontSize="medium"
                    fieldStyle="bottomLine"
                    placeholder={t('INPUT_X', { x: t('WEBHOOK_URL') })}
                    value={state.teamsWebhookURL}
                    onInput={(e) =>
                      setState((prev) => ({ ...prev, teamsWebhookURL: e.currentTarget.value }))
                    }
                  />
                </label>
              </div>
            )}
          </div>
        }
        style={{ marginBottom: 34 }}
      />
    </div>
  )
}

OkrCreateModalContent.displayName = 'OkrCreateModalContent'

type RowProps = {
  label: React.ReactElement
  form: React.ReactElement
  alignItems?: 'start' | 'center' | 'end'
  isHelp?: boolean
  text?: string
  showOpenOkrFloatDrawerIcon?: boolean
}

type LabelProps = { label: string; weight?: 'bold' | 'normal'; disabled?: boolean } & Omit<
  StyledTextProps,
  'color' | 'weight'
>
const Label: React.FC<LabelProps> = ({ label, weight = 'bold', disabled = false, ...props }) => (
  <StyledText
    weight={weight}
    size="small"
    lineHeight="12px"
    color={disabled ? 'text-bk-10' : 'text-bk-100'}
    {...props}
  >
    {label}
  </StyledText>
)

Label.displayName = 'Label'

const Row: React.FC<RowProps & JSX.IntrinsicElements['div']> = ({
  label,
  form,
  alignItems = 'center',
  isHelp = false,
  text = '',
  ...props
}) => (
  <div css={{ display: 'flex', width: '100%', marginBottom: '24px', alignItems }} {...props}>
    <div css={{ flex: '0 0 180px', display: 'flex', gap: '4px', alignItems: 'center' }}>
      {label}
      {isHelp && (
        <Popover
          targetContent={<Popover.TargetContent.Help css={{ verticalAlign: 'baseline' }} />}
          dropdownContent={<Popover.DropdownContent text={text} />}
        />
      )}
    </div>

    <div css={{ flex: '1 1 auto' }}>{form}</div>
  </div>
)

Row.displayName = 'Row'
