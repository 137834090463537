import { useMemo } from 'react'
import { v1 as uuid } from 'uuid'

import { useTranslation } from '../../../../i18n'
import { Popover } from '../../../ui/Popover'
import { RichTextEditor } from '../../../ui/RichTextEditor'
import { StyledText } from '../../../ui/StyledText'
import { useAttachmentsView } from '../../Attachments/hooks/useAttachmentsView'
import { File } from '../graphql'

import { useStyles } from './index.styles'

export type Props = {
  initialValueJson?: string
  setDescription: (body: string, plainText: string) => void
  attachmentViews: ReadonlyArray<File>
  onChangeAttachments: (files: ReadonlyArray<File>) => void
}

export const MilestoneInput: React.FC<Props> = ({
  initialValueJson,
  setDescription,
  attachmentViews,
  onChangeAttachments,
}) => {
  const { t } = useTranslation()
  const styles = useStyles()
  const { AttachmentsView } = useAttachmentsView(attachmentViews)

  return (
    <div css={styles.root}>
      <div css={styles.label}>
        <StyledText size="small" fontStyle="bold">
          {t('ACHIEVEMENT_CRITERIA_AND_METRICS_NEW_LINE')}
        </StyledText>
        <Popover
          targetContent={<Popover.TargetContent.Help css={styles.icon} />}
          dropdownContent={
            <Popover.DropdownContent text={t('ACHIEVEMENT_CRITERIA_AND_METRICS_HELP_MESSAGE')} />
          }
        />
      </div>
      <div css={styles.form}>
        <RichTextEditor
          // MEMO:本来はKRのIDを渡すはずだが、IDを渡すとeditorで尿力できない等の問題が発生するため、暫定対応としてuuidを渡している
          id={useMemo(uuid, [])}
          initialValueJSON={initialValueJson}
          onChange={setDescription}
          autoFocus={false}
          css={styles.editor}
          editorProps={useMemo(() => ({ placeholder: t('KR_DESCRIPTION_PLACEHOLDER') }), [t])}
        />
        <AttachmentsView readOnly={false} onChange={onChangeAttachments} />
      </div>
    </div>
  )
}
