import { css } from '@emotion/react'
import {
  TrialBar as GeishaTrialBar,
  TrialBarItemProps as GeishaTrialBarItemProps,
} from '@resily/geisha'
import dayjs from 'dayjs'
import React, { MouseEventHandler, useCallback, useMemo } from 'react'

import { useTranslation } from '../../../i18n'
import { useSelfServeConfig } from '../../../lib/domain/organization/useSelfServeConfig'
import { findScreen } from '../../../lib/screen'
import { tracker } from '../../../lib/tracking'

export const HEIGHT = 32
const wrapperCss = css({
  position: 'fixed',
  zIndex: 10,
  top: 0,
  left: 0,
  height: `${HEIGHT}px`,
  width: '100%',
  minWidth: '420px',
  // FIXME: デザインシステムのTrialBarのスタイルが間違っているので暫定的に対応
  '> div': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

export type Props = Omit<JSX.IntrinsicElements['div'], 'css'> & {
  onClickCheckPlan: () => void
  onClickBookTutorial: () => void
}

export const TrialBar: React.VFC<Props> = ({ onClickCheckPlan, onClickBookTutorial, ...props }) => {
  const { trialExpiresAt } = useSelfServeConfig()
  const { t } = useTranslation()

  // トライアルの有効期限内であれば、有効期限までの日数を計算する
  const now = dayjs()
  const trialRemainingDays = now.isBefore(trialExpiresAt)
    ? dayjs(trialExpiresAt).diff(dayjs(), 'day')
    : null

  const handleClickCheckPlanLink = useCallback<MouseEventHandler>(
    (e) => {
      e.preventDefault()
      onClickCheckPlan()
      tracker.CheckSubscriptionPlan(findScreen(window.location.pathname), false)
    },
    [onClickCheckPlan],
  )

  const trialBarItemProps1 = useMemo<GeishaTrialBarItemProps>(() => {
    const msg =
      trialRemainingDays === null
        ? t('TRIAL_EXPIRED')
        : t('TRIAL_LEFT', { days: trialRemainingDays })

    return {
      text: t('TRIAL_MESSAGE', { msg }),
      textLinkProps: { text: t('CHECK_SUBSCRIBE_PLAN'), onClick: handleClickCheckPlanLink },
    }
  }, [handleClickCheckPlanLink, t, trialRemainingDays])

  const handleClickBookTutorialLink = useCallback<MouseEventHandler>(
    (e) => {
      e.preventDefault()
      onClickBookTutorial()
      tracker.BookTutorialSession(findScreen(window.location.pathname))
    },
    [onClickBookTutorial],
  )

  const trialBarItemProps2 = useMemo<GeishaTrialBarItemProps>(
    () => ({
      text: t('BOOK_TUTORIAL_SESSION_MESSAGE'),
      textLinkProps: { text: t('CHECK_TUTORIAL_SESSION'), onClick: handleClickBookTutorialLink },
    }),
    [handleClickBookTutorialLink, t],
  )

  return (
    <div css={wrapperCss} {...props}>
      <GeishaTrialBar>
        <GeishaTrialBar.ItemGroup>
          <GeishaTrialBar.Item {...trialBarItemProps1} />
          <GeishaTrialBar.Item {...trialBarItemProps2} />
        </GeishaTrialBar.ItemGroup>
      </GeishaTrialBar>
    </div>
  )
}
TrialBar.displayName = 'TrialBar'
