import { useTranslation } from '../../../i18n'
import { findScreen } from '../../../lib/screen'
import { tracker } from '../../../lib/tracking'
import { fontSize } from '../../../styles/font'
import * as urls from '../../../urls'
import { LogoTrialExpired } from '../../ui/LogoTrialExpired'
import { Modal } from '../../ui/Modal'

export type Props = {
  isOpened: boolean
  isAdmin: boolean
  onClickCheckPlan: () => void
}

// FIXME: 管理者以外の場合の考慮
export const TrialExpiredModal: React.FC<Props> = ({ isOpened, onClickCheckPlan }) => {
  const { t } = useTranslation()

  return (
    <Modal
      isOpened={isOpened}
      title={t('TRIAL_EXPIRED_MODAL_TITLE')}
      cancelButtonProps={{ color: 'text-bk-50' }}
      cancelText={t('CONTACT_US')}
      executeText={t('CHECK_SUBSCRIBE_PLAN')}
      onClickCancel={() => window.open(urls.ExternalUrls.CONTACT_TO_SALES)}
      onClickExecute={() => {
        onClickCheckPlan()
        tracker.CheckSubscriptionPlan(findScreen(window.location.pathname), true)
      }}
    >
      <div css={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <LogoTrialExpired width={323} height={136} css={{ marginBottom: '24px' }} />
        <span css={{ ...fontSize('medium', 'regular'), whiteSpace: 'pre-wrap' }}>
          {t('TRIAL_EXPIRED_MODAL_DETAIL')}
        </span>
      </div>
    </Modal>
  )
}

TrialExpiredModal.displayName = 'TrialExpiredModal'
