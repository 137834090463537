import { PropsWithoutRef } from 'react'

import * as colors from '../../../styles/colors'

export type Props = PropsWithoutRef<JSX.IntrinsicElements['section']>

export const Section: React.FC<Props> = (props) => (
  <section
    css={{
      marginTop: 48,
      paddingBottom: 48,
      borderBottom: `1px solid ${colors.border}`,
      marginBottom: 48,
      '&:last-of-type': {
        borderBottom: 'none',
      },
    }}
    {...props}
  />
)

Section.displayName = 'Section'
