import { matchPath as routerMatchPath } from 'react-router'

import * as urls from '../urls'

// イベントトラッキングで渡す画面名
export enum Screen {
  // Null
  NULL = 'NULL',

  // Error Screens
  ErrorNotFound = 'ERROR_NOT_FOUND',

  // Not required authentication Screens
  SignIn = 'SIGN_IN',
  SignUp = 'SIGN_UP',
  ResetEmail = 'EMAIL_RESET',
  ResetPassword = 'PASSWORD_RESET',
  ResetPasswordConfirmation = 'PASSWORD_RESET_CONFIRMATION',
  AccountSetup = 'ACCOUNT_SETUP',
  AccountSetupComplete = 'ACCOUNT_SETUP_COMPLETE',

  // Notification Screens
  Notification = 'NOTIFICATION',

  // Setting Screens
  Setting = 'SETTING',
  AccountSetting = 'SETTING_ACCOUNT',
  OrganizationSetting = 'SETTING_ORGANIZATION',
  OkrGlossarySetting = 'SETTING_OKR_GLOSSARY',
  OkrTermSetting = 'SETTING_OKR_TERM',
  UserSetting = 'SETTING_USER',
  UsersSetting = 'SETTING_USERS',
  UsersImportAdmin = 'SETTING_USERS_IMPORT',
  OneOnOneSetting = 'SETTING_ONE_ON_ONES',
  FixedAgendaCreateSetting = 'SETTING_FIXED_AGENDA_CREATE',
  FixedAgendaEditSetting = 'SETTING_FIXED_AGENDA_Edit',
  GroupsSetting = 'SETTING_GROUPS',
  GroupSetting = 'SETTING_GROUP',
  GroupsImportAdmin = 'SETTING_GROUPS_IMPORT',
  IntegrationSetting = 'SETTING_INTEGRATIONS',
  IpWhiteListSetting = 'SETTING_ALLOW_IPS',
  DomainWhiteListSetting = 'SETTING_ALLOW_DOMAINS',
  SamlSetting = 'SETTING_SAML',

  // OKR Screens
  OkrMapTree = 'OKR_MAP_TREE',
  OkrMapList = 'OKR_MAP_LIST',
  OkrMapActivity = 'OKR_MAP_ACTIVITY',
  Okr = 'OKR',
  ObjectiveDetailInfo = 'OBJECTIVE_INFO',
  ObjectiveEdit = 'OBJECTIVE_EDIT',
  KeyResultDetailInfo = 'KEY_RESULT_INFO',
  KeyResultDetailActionPlan = 'KEY_RESULT_ACTION_PLAN',
  KeyResultEdit = 'KEY_RESULT_EDIT',

  // User Screens
  User = 'USER',
  Users = 'USERS',

  // New My Page
  HomeOkr = 'HOME_OKR',
  HomeActionPlan = 'HOME_ACTION_PLAN',
  HomeNote = 'HOME_NOTE',
  HomeActivity = 'HOME_ACTIVITY',

  // Note and Personal Note Label List Screens
  NoteLabelList = 'NOTE_LABEL_LIST',

  // Note Screens
  NoteDetail = 'NOTE_DETAIL',
  NoteEdit = 'NOTE_EDIT',
  NoteLabeledList = 'NOTE_LABELED_LIST',

  // Personal Note Screens
  PersonalNoteDetail = 'PERSONAL_NOTE_DETAIL',
  PersonalNoteEdit = 'PERSONAL_NOTE_EDIT',
  PersonalNoteLabeledList = 'PERSONAL_NOTE_LABELED_LIST',

  // Action Plan Screens
  ActionPlanDetail = 'ACTION_PLAN_DETAIL',
  ActionPlanEdit = 'ACTION_PLAN_EDIT',

  // Summnary Screens
  Summary = 'SUMMARY_DETAIL',
  SummaryEdit = 'SUMMARY_EDIT',

  // OneOnOne Screens
  OneOnOne = 'ONE_ON_ONE',
  OneOnOneDetail = 'ONE_ON_ONE_DETAIL',
  OneOnOneHistory = 'ONE_ON_ONE_HISTORY',

  // Billing
  Billing = 'BILLING',
}

const matchPath = (pathname: string, target: string): boolean =>
  Boolean(routerMatchPath({ path: target }, pathname))

const matchQuery = (query: string, target: string): boolean => {
  const re = new RegExp(`[?|&]${target}`)
  return Boolean(query.match(re))
}

// TODO: https://github.com/Resily/resily-new/issues/6100
// urlsに設定されている全てがScreenにあるわけではないので↑これで対応する
/**
 * urlから対象のscreenを取得する
 *
 * @example
 * url: https://qa.risily.com/terms/current/okrs?okr-list=self
 * const screen = findScreen(location.pathaname, location.search)
 * // screen = 'OKR_MAP_TREE'
 */
export const findScreen = (pathaname: string, query?: string): Screen => {
  if (matchPath(pathaname, urls.notFound)) return Screen.ErrorNotFound
  if (matchPath(pathaname, urls.signIn)) return Screen.SignIn
  if (matchPath(pathaname, urls.signUp)) return Screen.SignUp
  if (matchPath(pathaname, urls.passwordReset)) return Screen.ResetPassword
  if (matchPath(pathaname, urls.passwordResetConfirm)) return Screen.ResetPasswordConfirmation
  if (matchPath(pathaname, urls.accountSetup)) return Screen.AccountSetup
  if (matchPath(pathaname, urls.accountSetupComplete)) return Screen.AccountSetupComplete
  if (matchPath(pathaname, urls.notifications)) return Screen.Notification
  if (matchPath(pathaname, urls.setting)) return Screen.Setting
  if (matchPath(pathaname, urls.accountSetting)) return Screen.AccountSetting
  if (matchPath(pathaname, urls.organizationAdmin)) return Screen.OrganizationSetting
  if (matchPath(pathaname, urls.okrTermsAdmin)) return Screen.OkrTermSetting
  if (matchPath(pathaname, urls.groupsSettingAdmin)) return Screen.GroupsSetting
  if (matchPath(pathaname, urls.group)) return Screen.GroupSetting
  if (matchPath(pathaname, urls.userAdmin)) return Screen.UserSetting
  if (matchPath(pathaname, urls.usersAdmin)) return Screen.UsersSetting
  if (matchPath(pathaname, urls.integrationsAdmin)) return Screen.IntegrationSetting
  if (matchPath(pathaname, urls.ipWhitelistAdmin)) return Screen.IpWhiteListSetting
  if (matchPath(pathaname, urls.oauthWhitelistAdmin)) return Screen.DomainWhiteListSetting
  if (matchPath(pathaname, urls.samlAdmin)) return Screen.SamlSetting
  if (matchPath(pathaname, urls.okr)) return Screen.Okr
  if (matchPath(pathaname, urls.objective)) return Screen.ObjectiveDetailInfo
  if (matchPath(pathaname, urls.objectiveEdit)) return Screen.ObjectiveEdit
  if (matchPath(pathaname, urls.keyResult)) return Screen.KeyResultDetailInfo
  if (matchPath(pathaname, urls.keyResultEdit)) return Screen.KeyResultEdit
  if (matchPath(pathaname, urls.user)) return Screen.User
  if (matchPath(pathaname, urls.noteLabels)) return Screen.NoteLabelList
  if (matchPath(pathaname, urls.note)) return Screen.NoteDetail
  if (matchPath(pathaname, urls.noteEdit)) return Screen.NoteEdit
  if (matchPath(pathaname, urls.labeledNotes)) return Screen.NoteLabeledList
  if (matchPath(pathaname, urls.personalNote)) return Screen.PersonalNoteDetail
  if (matchPath(pathaname, urls.personalNoteEdit)) return Screen.PersonalNoteEdit
  if (matchPath(pathaname, urls.personalNoteLabels)) return Screen.PersonalNoteLabeledList
  if (matchPath(pathaname, urls.actionPlan)) return Screen.ActionPlanDetail
  if (matchPath(pathaname, urls.actionPlans)) return Screen.NULL
  if (matchPath(pathaname, urls.actionPlanEdit)) return Screen.ActionPlanEdit
  if (matchPath(pathaname, urls.checkinSummary)) return Screen.Summary
  if (matchPath(pathaname, urls.checkinSummaryEdit)) return Screen.SummaryEdit
  if (matchPath(pathaname, urls.oneOnOne)) return Screen.OneOnOne
  if (matchPath(pathaname, urls.oneOnOneDetail)) return Screen.OneOnOneDetail
  if (matchPath(pathaname, urls.oneOnOneHistory)) return Screen.OneOnOneHistory
  if (matchPath(pathaname, urls.billingAdmin)) return Screen.Billing
  if (matchPath(pathaname, urls.term)) return Screen.NULL
  if (matchPath(pathaname, urls.homeOkr)) return Screen.HomeOkr
  if (matchPath(pathaname, urls.homeActionPlan)) return Screen.HomeActionPlan
  if (matchPath(pathaname, urls.homeNote)) return Screen.HomeNote
  if (matchPath(pathaname, urls.homeActivity)) return Screen.HomeActivity
  if (matchPath(pathaname, urls.trees) || matchPath(pathaname, urls.treesInCurrentTerm)) {
    if (!query) return Screen.OkrMapTree
    if (matchQuery(query, `${urls.MAP_VIEW_QUERY_PARAMS}=${urls.MAP_PAGE_LIST_VIEW_QUERY}`))
      return Screen.OkrMapList
    if (matchQuery(query, `${urls.MAP_VIEW_QUERY_PARAMS}=${urls.MAP_PAGE_ACTIVITY_VIEW_QUERY}`))
      return Screen.OkrMapActivity
    if (matchQuery(query, `${urls.OKR_LIST_TAB_QUERY}=${urls.OKR_LIST_TAB[0]}`))
      return Screen.OkrMapTree
    if (matchQuery(query, `${urls.OKR_LIST_TAB_QUERY}=${urls.OKR_LIST_TAB[1]}`))
      return Screen.OkrMapTree
    if (matchQuery(query, `${urls.OKR_LIST_TAB_QUERY}=${urls.OKR_LIST_TAB[2]}`))
      return Screen.OkrMapTree
    return Screen.OkrMapTree
  }
  if (matchPath(pathaname, urls.users) || matchPath(pathaname, urls.usersInCurrentTerm))
    return Screen.Users

  return Screen.NULL
}
