import { Box } from 'grommet'
import { useState, useRef, forwardRef, useLayoutEffect } from 'react'

import { Attachments } from '../../../../../../../components/domain/Attachments'
import { Button } from '../../../../../../../components/ui/Button'
import { FloatDrawerIconButton } from '../../../../../../../components/ui/FloatDrawerIconButton'
import { Icon } from '../../../../../../../components/ui/Icon'
import { IconButton } from '../../../../../../../components/ui/IconButton'
import { Link } from '../../../../../../../components/ui/Link'
import { Popover } from '../../../../../../../components/ui/Popover/deprecated'
import { EditorRef, RichTextEditor } from '../../../../../../../components/ui/RichTextEditor'
import { StyledText } from '../../../../../../../components/ui/StyledText'
import { TextButton } from '../../../../../../../components/ui/TextButton'
import { TooltipNew } from '../../../../../../../components/ui/TooltipNew'
import { File } from '../../../../../../../graphql'
import { useTranslation } from '../../../../../../../i18n'
import { trimInvalidCharacter } from '../../../../../../../lib/string'
import { color } from '../../../../../../../styles/newColors'

import { useInjection } from './hooks'
import type { OkrDrawerRef } from './types'

export type Props = {
  editorId: string
  treeJson: string
  plainText: string
  attachments: ReadonlyArray<File>
  referUrl: string
  label?: string
  helpText: string
  onUpdateDescription: (
    description: string,
    plainText: string,
    beforeTreeJson: string,
    beforePlainText: string,
    attachments: ReadonlyArray<File>,
  ) => void
  onOkrFloatDrawerOpen: () => void
}

export const OkrDrawerEditor = forwardRef<OkrDrawerRef, Props>(
  (
    {
      editorId,
      treeJson,
      plainText,
      attachments,
      referUrl,
      label,
      helpText,
      onUpdateDescription,
      onOkrFloatDrawerOpen,
    },
    ref,
  ) => {
    const { t } = useTranslation()
    const editorRef = useRef<EditorRef>(null)
    const [readOnly, setReadOnly] = useState<boolean>(true)
    const initialBody = treeJson
    const initialBodyText = plainText
    const initialFiles = attachments

    const [body, setBody] = useState<string>(treeJson)
    const [bodyText, setBodyText] = useState<string>(plainText)
    const [files, setFiles] = useState<ReadonlyArray<File>>(attachments)
    const okrDrawerEditorRef = useRef<HTMLDivElement>(null)
    const { onDescriptionChange, setEditingDescription } = useInjection(ref, () => {
      if (editorRef.current) {
        editorRef.current.resetInitialValue()
      }
      setBody(initialBody)
      setBodyText(initialBodyText)
      setFiles(initialFiles)
      setReadOnly(true)
    })

    useLayoutEffect(() => {
      if (!readOnly) {
        okrDrawerEditorRef?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'start',
        })
      }
    }, [readOnly])

    return (
      <div
        ref={okrDrawerEditorRef}
        css={{
          paddingTop: '16px',
          marginTop: '8px',
        }}
      >
        <div
          css={{
            display: 'flex',
            justifyContent: readOnly ? 'flex-start' : 'space-between',
            alignItems: 'center',
          }}
        >
          <div>
            <StyledText color="text-bk-50" size="small" weight="bold">
              {!label ? t('DESCRIPTION') : label}
            </StyledText>
            <Popover
              icon="help"
              text={helpText}
              iconCss={{
                display: 'Block',
                ':hover': {
                  color: color('resily-orange-100'),
                },
              }}
              margin={{ left: '4px' }}
              hasGap={false}
              position={{
                top: 'auto',
                bottom: '20px',
              }}
              zIndex={10}
            />
          </div>
          {readOnly ? (
            <div
              css={{
                marginLeft: '24px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <TooltipNew title={t('OPEN_IN_NEW')}>
                <Link href={referUrl} method="newTab">
                  <Icon
                    type="openInNew"
                    color={color('text-bk-50')}
                    hoverColor="resily-orange-100"
                    css={{
                      width: '16px',
                      height: '16px',
                    }}
                  />
                </Link>
              </TooltipNew>
              <TooltipNew title={t('EDITION')}>
                <IconButton
                  border={false}
                  onClick={() => {
                    setReadOnly(false)
                    onDescriptionChange()
                  }}
                  css={{
                    width: '14px',
                    height: '14px',
                    marginLeft: '12.5px',
                  }}
                >
                  <Icon
                    type="editSimple"
                    color={color('text-bk-50')}
                    hoverColor="resily-orange-100"
                    css={{
                      width: '14px',
                      height: '14px',
                    }}
                  />
                </IconButton>
              </TooltipNew>
              <FloatDrawerIconButton
                css={{
                  color: color('text-bk-50'),
                  padding: '2px',
                  marginLeft: '10.5px',
                }}
                onClick={onOkrFloatDrawerOpen}
              />
            </div>
          ) : (
            <Box direction="row" align="center" justify="end" css={{ flexBasis: '218px' }}>
              <TextButton
                color="text-bk-50"
                onClick={() => {
                  // キャンセルした時は初期状態のデータに戻す
                  if (editorRef.current) {
                    editorRef.current.resetInitialValue()
                  }
                  setBody(initialBody)
                  setBodyText(initialBodyText)
                  setFiles(initialFiles)
                  setReadOnly(true)
                  setEditingDescription(false)
                }}
                size="small"
                padding="10px 12px"
                css={{ marginRight: '8px' }}
              >
                {t('CANCEL')}
              </TextButton>
              <Button
                newColor="resily-orange-100"
                weight="normal"
                css={{
                  fontSize: 12,
                  height: 32,
                  lineHeight: '32px',
                  paddingLeft: 12,
                  paddingRight: 12,
                }}
                onClick={() => {
                  setReadOnly(true)
                  onUpdateDescription(body, bodyText, initialBody, initialBodyText, files)
                  setEditingDescription(false)
                }}
              >
                {t('SAVE')}
              </Button>
            </Box>
          )}
        </div>
        <div
          css={[
            {
              marginTop: 8,
            },
            readOnly
              ? trimInvalidCharacter(treeJson).length === 0 && {
                  display: 'none',
                  marginTop: 0,
                }
              : undefined,
          ]}
        >
          <RichTextEditor
            ref={editorRef}
            id={editorId}
            css={[
              {
                color: color('text-bk-100'),
              },
              !readOnly && {
                overflowY: 'scroll',
                minHeight: 156,
                maxHeight: 640,
              },
            ]}
            initialValueJSON={initialBody}
            editorProps={{
              readOnly,
              placeholder: t('INPUT_X', { x: t('OBJECTIVE_BACKGROUND') }),
            }}
            onChange={(json, text) => {
              setBody(json)
              setBodyText(text)
            }}
            onSave={(json, text) => {
              setBody(json)
              setBodyText(text)
            }}
          />
        </div>
        <Attachments
          attachments={files}
          readOnly={readOnly}
          onClickDeleteAttachment={(file) => {
            setFiles(files.filter((a) => a !== file))
          }}
          onUpdateFile={(file) => setFiles([...files, file])}
        />
      </div>
    )
  },
)

OkrDrawerEditor.displayName = 'OkrDrawerEditor'
