import { Box, Text } from 'grommet'
import React, { useEffect } from 'react'
import { useParams } from 'react-router'

import { PersonalNotesCard } from '../../components/domain/PersonalNotesCard'
import { useTranslation } from '../../i18n'
import { Screen } from '../../lib/screen'
import { useTracking } from '../../lib/tracking'
import { AuthRouteProps } from '../../types/authRouteProps'

import {
  useFindNoteLabelByIdQuery,
  PersonalNoteConnectionFragment,
  useDeletePersonalNoteMutation,
  FindNoteLabelByIdQuery,
  FindNoteLabelByIdQueryVariables,
  FindNoteLabelByIdDocument,
} from './graphql'

export const LabeledPersonalNoteListContainer: React.FC<AuthRouteProps> = ({ onOkrTermLoaded }) => {
  const { personalNoteLabelId } = useParams<{ personalNoteLabelId: string }>()
  const labelQuery = useFindNoteLabelByIdQuery({
    variables: { noteLabelId: personalNoteLabelId ?? '', notesLength: 100 },
    skip: personalNoteLabelId == null,
  })
  const [deletePersonalNote] = useDeletePersonalNoteMutation()
  const deletePersonalNoteMutation = async (noteId: string) => {
    await deletePersonalNote({
      variables: { id: noteId },
      update(cache) {
        const option = {
          query: FindNoteLabelByIdDocument,
          variables: { noteLabelId: personalNoteLabelId ?? '', notesLength: 100 },
        }
        const { findNoteLabelByID } =
          cache.readQuery<FindNoteLabelByIdQuery, FindNoteLabelByIdQueryVariables>(option) || {}
        if (!findNoteLabelByID) {
          return
        }

        cache.writeQuery<FindNoteLabelByIdQuery, FindNoteLabelByIdQueryVariables>({
          ...option,
          data: {
            findNoteLabelByID: {
              ...findNoteLabelByID,
              personalNotes: {
                ...findNoteLabelByID.personalNotes,
                edges: findNoteLabelByID.personalNotes.edges.filter(
                  (note) => !!note.node && note.node.id !== noteId,
                ),
              },
            },
          },
        })
      },
    })
  }

  useEffect(() => {
    onOkrTermLoaded(null)
  }, [onOkrTermLoaded])

  if (!labelQuery.data) {
    return null
  }

  return (
    <LabeledPersonalNoteList
      name={labelQuery.data.findNoteLabelByID.name}
      personalNotes={labelQuery.data.findNoteLabelByID.personalNotes}
      deletePersalNote={deletePersonalNoteMutation}
    />
  )
}

LabeledPersonalNoteListContainer.displayName = 'LabeledPersonalNoteListContainer'

type Props = {
  name: string
  personalNotes: PersonalNoteConnectionFragment
  deletePersalNote: (noteId: string) => void
}

export const LabeledPersonalNoteList: React.FC<Props> = ({
  name,
  personalNotes,
  deletePersalNote,
}) => {
  const { t } = useTranslation()
  useTracking(
    t('LABELED_PERSONAL_NOTE_LIST_PAGE_TITLE', { labelName: name }),
    Screen.PersonalNoteLabeledList,
  )

  return (
    <Box pad="large">
      <Text size="large" weight="bold" wordBreak="break-all" margin={{ bottom: 'large' }}>
        {t('X_IN_Y', { x: t('NOTE_LIST'), y: name })}
      </Text>
      <Box margin={{ top: 'large' }}>
        <PersonalNotesCard
          personalNotes={personalNotes.edges
            .map((edge) => edge.node)
            .filter((note): note is NonNullable<typeof note> => note != null)}
          deletePersonalNote={deletePersalNote}
        />
      </Box>
    </Box>
  )
}

LabeledPersonalNoteList.displayName = 'LabeledPersonalNoteList'
