import {
  collapseSelection,
  getEndPoint,
  getStartPoint,
  select,
  usePlateEditorRef,
} from '@udecode/plate'
import { useState } from 'react'
import { useIsomorphicLayoutEffect } from 'react-use'

export const useFocusEditor = (id: string, focus: boolean, readOnly: boolean): boolean => {
  const editor = usePlateEditorRef(id)
  const [autoFocus, setAutoFocus] = useState<boolean>(focus)

  useIsomorphicLayoutEffect(() => {
    if (!focus || readOnly || !editor) {
      return
    }

    setAutoFocus(false)
    setTimeout(() => {
      setAutoFocus(true)
      setTimeout(() => {
        try {
          select(editor, {
            anchor: getStartPoint(editor, []),
            focus: getEndPoint(editor, []),
          })
          collapseSelection(editor, { edge: 'end' })
        } catch (e) {
          // 何もしない
        }
      }, 0)
    }, 0)
  }, [setAutoFocus, editor, focus, readOnly])

  return autoFocus
}
