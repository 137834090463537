import { Dispatch, SetStateAction } from 'react'
import { useLocalStorage } from 'react-use'

type Ret = [number, Dispatch<SetStateAction<number | undefined>>]

/**
 * sidenavの横幅を保持しているlocalStorageのhooks
 */

const key = 'side-nav-width'

export const useSideNavWidth = (initialSideNavWidth: number): Ret => {
  const [sideNavWidth, setSideNavWidth] = useLocalStorage<number>(key, initialSideNavWidth)

  return [sideNavWidth ?? initialSideNavWidth, setSideNavWidth]
}

/**
 * useLocalStorageでは、UI上の操作でlocalStorageの値が更新されても即時反映されないため
 * 更新後、即時利用したい場合にこの関数を利用する
 */
export const getSideNavWidth = (): number => {
  const rawSideNavWidth = localStorage.getItem(key)
  return rawSideNavWidth ? Number(rawSideNavWidth) : 0
}
