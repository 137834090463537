import React from 'react'

import { GroupTreeTag, Props as GroupTreeTagProps } from '../GroupTreeTag'

export type Props = Omit<GroupTreeTagProps, 'name' | 'ancestorNames'> & {
  ancestorsAndNames: Exclude<GroupTreeTagProps['ancestorNames'], ReadonlyArray<string>>
}

export const HiddenGroupsTreeTag: React.FC<Props> = ({
  ancestorsAndNames,
  tooltipDisabled = false,
  ...props
}) => (
  <GroupTreeTag
    hiddenGroupsTreeTag
    name={`+${ancestorsAndNames.length}`}
    ancestorNames={ancestorsAndNames}
    tooltipDisabled={tooltipDisabled}
    {...props}
  />
)

HiddenGroupsTreeTag.displayName = 'HiddenGroupsTreeTag'
