import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { useCheckinModal } from '../../../../../components/standalone/CheckinModal'
import { useOkrCreateModal } from '../../../../../components/standalone/OkrCreateModal'
import { useOkrModal } from '../../../../../components/standalone/OkrModal'
import { OkrTermIdContext } from '../../../../../contexts/OkrTermIdContext'
import { useCurrentUser } from '../../../../../contexts/UserContext'
import { useTranslation } from '../../../../../i18n'
import { isIncludedUser as isIncludedUserKeyResult } from '../../../../../lib/domain/keyResult/keyResult'
import { isIncludedUser as isIncludedUserObjective } from '../../../../../lib/domain/objective'
import { getCurrentTermId } from '../../../../../lib/domain/okrTerm'
import { Screen } from '../../../../../lib/screen'
import { tracker, useTracking } from '../../../../../lib/tracking'
import { AuthRouteProps } from '../../../../../types/authRouteProps'
import * as urls from '../../../../../urls'
import { CHECKIN_MODAL } from '../../../../../urls'

import { CheckinArea, CheckinAreaSkeleton } from './CheckinArea'
import { OkrsTable, Props as OkrsTableProps } from './OkrsTable'
import { useFindUserByIdQuery } from './graphql'

export const OkrContainer: React.FC<AuthRouteProps> = () => {
  const { userId: pageUserId } = useParams<{ userId: string }>()
  const { okrTermId: pageTermId } = useContext(OkrTermIdContext)
  const currentTermId = getCurrentTermId()
  const currentUser = useCurrentUser()

  const userResp = useFindUserByIdQuery({
    variables: { termId: pageTermId || '', userId: pageUserId || '' },
    skip: !pageTermId || !pageUserId,
  })

  const pageUser = userResp.data?.findUserById
  const isShowDisabledOkr = !!currentUser?.userSetting.showDisabledOkrs

  const objectives = useMemo(() => {
    const os = pageUser?.okrs
    if (!os) return []
    if (isShowDisabledOkr) {
      return os
    }

    return os
      .filter((o) => !o.isDisabled)
      .flatMap((o) => {
        const enableKrs = o.keyResults.filter((kr) => !kr.isDisabled)
        const isAssignOnlyDisabledKr =
          !isIncludedUserObjective(o, pageUser) &&
          enableKrs.filter((kr) => isIncludedUserKeyResult(kr, pageUser)).length === 0
        // 無効になっているKRにのみ所属している場合はOごと結果から除外
        return !isAssignOnlyDisabledKr ? [{ ...o, keyResults: enableKrs }] : []
      })
  }, [isShowDisabledOkr, pageUser])

  const { t } = useTranslation()
  const isOwnPage = currentUser?.id === pageUserId
  const userName = pageUser
    ? t('FULL_NAME', { firstName: pageUser.firstName, lastName: pageUser.lastName })
    : t('USER')
  const pageTitle = isOwnPage ? t('MY_OKR') : t('X_OF_Y', { x: t('OKR'), y: userName })
  useTracking(pageTitle, Screen.HomeOkr)

  const { OkrModal, openOkrModal, openOkrModalWithKeyResultDrawer } = useOkrModal()

  const openObjectiveDrawer = useCallback(
    (id: string, isAssigned: boolean) => openOkrModal(id, Screen.HomeOkr, isAssigned),
    [openOkrModal],
  )
  const openKeyResultDrawer = useCallback(
    (objectiveId: string, keyResultId: string, isAssigned) =>
      openOkrModalWithKeyResultDrawer(objectiveId, keyResultId, Screen.HomeOkr, isAssigned),
    [openOkrModalWithKeyResultDrawer],
  )

  const onClickObjectiveTrend = useCallback(
    (id: string, isAssigned: boolean) =>
      openOkrModal(id, Screen.HomeOkr, isAssigned, 'm-o-activity'),
    [openOkrModal],
  )
  const onClickKeyResultTrend = useCallback(
    (objectiveId: string, keyResultId: string, isAssigned: boolean) =>
      openOkrModalWithKeyResultDrawer(
        objectiveId,
        keyResultId,
        Screen.HomeOkr,
        isAssigned,
        'm-kr-activity',
      ),
    [openOkrModalWithKeyResultDrawer],
  )

  const onCompletedOkrCreate = useCallback(() => userResp.refetch?.(), [userResp])

  if (!pageTermId || !pageUserId || !currentUser) return null
  const checkinableKeyResults = userResp.data?.findUserById?.checkinableKeyResults
  const isCurrentTerm = currentTermId === pageTermId
  const showCheckinArea =
    isCurrentTerm && checkinableKeyResults ? checkinableKeyResults.length > 0 : false

  return (
    <>
      <Okr
        loading={pageUser == null && userResp.loading}
        termId={pageTermId}
        userId={pageUserId}
        objectives={objectives}
        isCurrentTerm={isCurrentTerm}
        showCheckinArea={showCheckinArea}
        isOwnPage={isOwnPage}
        isCheckin={currentUser.checkinKeyResultsState.isBulkUpdated}
        onCompletedOkrCreate={onCompletedOkrCreate}
        onClickObjectiveName={openObjectiveDrawer}
        onClickKeyResultName={openKeyResultDrawer}
        onClickObjectiveTrend={onClickObjectiveTrend}
        onClickKeyResultTrend={onClickKeyResultTrend}
      />
      {OkrModal}
    </>
  )
}
OkrContainer.displayName = 'OkrContainer'

export type Props = Omit<OkrsTableProps, 'onClickCreateOkr'> & {
  isCurrentTerm: boolean
  showCheckinArea: boolean
  isOwnPage: boolean
  isCheckin: boolean
  onCompletedOkrCreate: () => void
}

export const Okr = React.memo<Props>(
  ({
    loading,
    termId,
    userId,
    isCurrentTerm,
    showCheckinArea,
    isOwnPage,
    isCheckin,
    objectives,
    onCompletedOkrCreate,
    onClickObjectiveName,
    onClickKeyResultName,
    onClickObjectiveTrend,
    onClickKeyResultTrend,
  }) => {
    const [CheckinModal, isOpenCheckinModal, { open, close }] = useCheckinModal()
    const openCheckinModal = useCallback(() => {
      tracker.UserClickOpenCheckin(Screen.HomeOkr)
      open()
    }, [open])
    const closeCheckinModal = useCallback(() => close(), [close])

    const executeCheckin = useCallback(() => {
      tracker.UserClickCompletedCheckin(Screen.HomeOkr)
      close()
    }, [close])

    const backCheckinModal = useCallback(() => {
      tracker.UserClickBackCheckin(Screen.HomeOkr)
    }, [])

    const [OkrCreateModal, openOkrCreateModal] = useOkrCreateModal({
      onClose: onCompletedOkrCreate,
    })

    useEffect(() => {
      if (urls.getCheckinModalQuery()[CHECKIN_MODAL]) {
        openCheckinModal()
        return
      }
      closeCheckinModal()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          gap: 24,
          '> div': {
            backgroundColor: 'white',
            borderRadius: 8,
          },
        }}
      >
        {/* eslint-disable-next-line no-nested-ternary */}
        {isCurrentTerm && isOwnPage ? (
          // eslint-disable-next-line no-nested-ternary
          loading ? (
            <CheckinAreaSkeleton />
          ) : showCheckinArea ? (
            <CheckinArea
              isCheckin={isCheckin}
              termId={termId}
              userId={userId}
              onClickCheckin={openCheckinModal}
            />
          ) : null
        ) : null}
        <div>
          <OkrsTable
            loading={loading}
            termId={termId}
            userId={userId}
            isOwnPage={isOwnPage}
            objectives={objectives}
            onClickCreateOkr={useCallback(
              () => openOkrCreateModal(Screen.HomeOkr),
              [openOkrCreateModal],
            )}
            onClickObjectiveName={onClickObjectiveName}
            onClickKeyResultName={onClickKeyResultName}
            onClickObjectiveTrend={onClickObjectiveTrend}
            onClickKeyResultTrend={onClickKeyResultTrend}
          />
        </div>
        {OkrCreateModal}
        {isOpenCheckinModal && (
          <CheckinModal
            isOpen={isOpenCheckinModal}
            onClose={closeCheckinModal}
            onConfirm={executeCheckin}
            onBack={backCheckinModal}
          />
        )}
      </div>
    )
  },
)
Okr.displayName = 'Okr'
