import { css } from '@emotion/react'
import { PropsWithoutRef } from 'react'

import { Icon } from '../../../components/ui/Icon'
import { color } from '../../../styles/newColors'

export type Props = Omit<PropsWithoutRef<JSX.IntrinsicElements['button']>, 'css'>

const buttonStyle = css({
  outline: `1px solid ${color('border-bk-30')}`,
  width: '40px',
  height: '40px',
  backgroundColor: color('white-100'),
  borderRadius: '20px',
  boxShadow: '0px 4px 16px 0px rgb(34 41 67 / 16%)',
  ':hover': {
    path: {
      fill: color('resily-orange-100'),
    },
  },
})

export const AddAnchor: React.FC<Props> = (props) => (
  <button type="button" css={buttonStyle} {...props}>
    <Icon type="plus" width={18} height={18} color={color('text-bk-80')} />
  </button>
)

AddAnchor.displayName = 'AddAnchor'
