import { ReactElement, useCallback } from 'react'

import { Tabs, Props as TabsProps, TabType as TabsTabType } from '../../../../ui/Tabs'

import { CloseButton, CloseButtonProps } from './CloseButton'
import { useStyles } from './TabsWithChildren.styles'

export type TabType<T extends string = string> = TabsTabType<T> & {
  children: React.ReactNode
}

export type TabsWithChildrenProps<T extends string = string> = Omit<
  TabsProps<T>,
  'tabs' | 'onClickTab' | 'tabName'
> & {
  tabs: ReadonlyArray<TabType<T>>
  tabName: TabsProps<T>['tabName']
  tabContentTestId: string
  ['data-testid']?: string
  onClickTab?: TabsProps<T>['onClickTab']
  onClickCloseButton?: CloseButtonProps['onClick']
}

export const TabsWithChildren = <T extends string = string>({
  tabs,
  tab,
  tabName,
  'data-testid': dataTestId,
  tabContentTestId,
  onClickTab,
  onClickCloseButton,
}: TabsWithChildrenProps<T>): ReactElement => {
  const styles = useStyles()

  const handleClickChangeTab = useCallback<TabsProps<T>['onClickTab']>(
    (t) => {
      if (onClickTab) {
        onClickTab(t)
      }
    },
    [onClickTab],
  )

  return (
    <div css={styles.root}>
      <div css={styles.tabWrapper} data-testid={dataTestId}>
        <Tabs<T>
          size="small"
          css={styles.tab}
          tabs={tabs}
          tab={tab}
          tabName={tabName}
          onClickTab={handleClickChangeTab}
        />
        {onClickCloseButton && <CloseButton onClick={onClickCloseButton} />}
      </div>
      <div css={styles.tabContent} data-testid={tabContentTestId}>
        {tabs.find((t) => tab === t.value)?.children}
      </div>
    </div>
  )
}
TabsWithChildren.displayName = 'TabsWithChildren'
