import { PropsWithoutRef, useEffect, useState } from 'react'

import { filterString } from '../../../lib/array'
import { byUserName } from '../../../lib/domain/user/searchText'
import { NoteUserDialog } from '../NoteUserDialog'

import {
  useFindUserPersonalNotePermissionQuery,
  UserPersonalNotePermission,
  useUpdatePersonalNoteUsersMutation,
} from './graphql'

export type Props = PropsWithoutRef<JSX.IntrinsicElements['section']> & {
  personalNoteId: string
  close: () => void
  shouldExpireCache: boolean
  onUpdated: () => void
}

export const PersonalNoteUserDialogContainer: React.FC<Props> = ({
  personalNoteId,
  close,
  shouldExpireCache,
  onUpdated,
}) => {
  const [visibleList, setVisibleList] = useState<ReadonlyArray<string>>([])

  const [optionList, setOptionList] = useState<ReadonlyArray<UserPersonalNotePermission>>([])
  const [searchText, setSearchText] = useState<string>('')
  const [searchedOptions, setSearchedOptions] =
    useState<ReadonlyArray<UserPersonalNotePermission>>(optionList)

  const { data, error } = useFindUserPersonalNotePermissionQuery({
    fetchPolicy: shouldExpireCache ? 'network-only' : 'cache-first',
    variables: { id: personalNoteId },
    onCompleted: (result) =>
      setVisibleList(
        result.findUserPersonalNotePermission.reduce<ReadonlyArray<string>>((ids, u) => {
          if (u && u.noteVisible) {
            return [...ids, u.id]
          }
          return ids
        }, []),
      ),
  })

  useEffect(() => {
    if (data?.findUserPersonalNotePermission) {
      setOptionList(
        data.findUserPersonalNotePermission.filter((u): u is UserPersonalNotePermission => !!u),
      )
    }
  }, [data])

  useEffect(() => {
    setSearchedOptions(filterString(searchText, optionList, byUserName))
  }, [optionList, searchText])

  // NOTE: 更新後にダイアログを閉じるため更新時にcacheリフレッシュはせずに、ダイアログオープン時に再検索が実行されるようにする
  const [updatePersonalNoteUsers, { loading }] = useUpdatePersonalNoteUsersMutation({
    onCompleted: () => onUpdated(),
  })

  const handleUpdate = (userIds: ReadonlyArray<string>) => {
    updatePersonalNoteUsers({ variables: { personalNoteId, userIds } })
  }

  return (
    <NoteUserDialog
      searchedOptions={searchedOptions}
      searchText={searchText}
      visibleList={visibleList}
      existsData={!!data?.findUserPersonalNotePermission}
      loading={loading}
      error={error}
      close={close}
      handleUpdate={handleUpdate}
      setSearchText={setSearchText}
      setVisibleList={setVisibleList}
    />
  )
}

PersonalNoteUserDialogContainer.displayName = 'PersonalNoteUserDialogContainer'
