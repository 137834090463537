import { css } from '@emotion/react'
import { TooltipData } from '@visx/xychart'
import dayjs from 'dayjs'
import { useEffect, FC, useState } from 'react'

import { formatDateInput } from '../../../lib/date'
import { labelGreen } from '../../../styles/colors'
import { color } from '../../../styles/newColors'
import { DateTime } from '../../ui/DateTime'
import { StyledText } from '../../ui/StyledText'

import { ObjectiveFragment } from './graphql'

const drawerTooltipCss = css({
  minWidth: '209px',
  maxWidth: '235px',
  fontWeight: 'normal',
  fontFamily: '"Noto Sans JP", sans-serif',
})
const tooltipDateCss = css({ display: 'block' })

const accessors = {
  xAccessor: (d: { x: string }) => d.x,
  yAccessor: (d: { y: number }) => d.y,
}
const tooltipDatum = (datum?: unknown): Series => {
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (!datum)
    return {
      label: '',
      x: '',
      y: 0,
    }
  return datum as Series
}
/**
 * チャートのレンダリングに必要な型情報
 */
type Series = {
  label: string
  x: string
  y: number
}

type PartiallyPartial<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>

type Props = {
  tooltipData: TooltipData
  objective: PartiallyPartial<ObjectiveFragment, 'progressRateHistory'>
  krSeries: Array<Array<Series>>
  keys: Array<string>
  progressForecastKeys: Array<string>
  fromObjectiveDrawer?: boolean
  displayProgressForecast: boolean
}

export const DrawerTooltip: FC<Props> = ({
  tooltipData,
  objective,
  krSeries,
  keys,
  progressForecastKeys,
  fromObjectiveDrawer,
  displayProgressForecast,
}) => {
  const [objectiveRateDiff, setObjectiveRateDiff] = useState<number>(0)
  const objectiveProgressForecast = objective.progressForecast
  const nearestDatum = tooltipData.nearestDatum?.datum as Series | undefined
  const datumDate = nearestDatum?.x.replace(/-/g, '/')
  const targetDuration = objective.progressRateHistory?.filter(
    (h) => formatDateInput(h.createdAt) === datumDate,
  )
  const objectiveProgressRate = targetDuration?.length
    ? targetDuration[0].progressRate
    : objective.progressRate

  useEffect(() => {
    if (!targetDuration?.length && objective.progressRate && objective.progressRateHistory) {
      // 最新の進捗度
      setObjectiveRateDiff(objective.progressRate - objective.progressRateHistory[0].progressRate)
      return
    }

    if (!targetDuration?.length || !objective.progressRateHistory) return
    // 過去の進捗度
    if (!objective.progressRateHistory.length || tooltipData.nearestDatum?.index === 0) {
      setObjectiveRateDiff(0)
      return
    }
    if (objective.progressRateHistory.length === 1) {
      setObjectiveRateDiff(objective.progressRateHistory[0].progressRate)
      return
    }

    // 同一日付の場合は最新の進捗率を使用する
    const progressRateHistory = objective.progressRateHistory.filter(
      (progress, index, historyArr) =>
        historyArr.findIndex(
          (progress2) =>
            dayjs(progress.createdAt).format('YYYY/MM/DD') ===
            dayjs(progress2.createdAt).format('YYYY/MM/DD'),
        ) === index,
    )

    progressRateHistory.forEach((h, i, historyArr) => {
      if (Object.is(historyArr.length - 1, i)) return

      if (h.createdAt === targetDuration[0].createdAt) {
        setObjectiveRateDiff(targetDuration[0].progressRate - historyArr[i + 1].progressRate)
      }
    })
  }, [
    targetDuration,
    objective.progressRateHistory,
    objective.progressRate,
    tooltipData.nearestDatum,
  ])

  return (
    <div css={drawerTooltipCss}>
      {/* 日付 */}
      <StyledText size="xsmall" color="text-bk-50" css={tooltipDateCss}>
        <DateTime
          datetime={new Date(accessors.xAccessor(tooltipDatum(tooltipData?.nearestDatum?.datum)))}
          withoutTime
        />
      </StyledText>
      {/* Objective */}
      {fromObjectiveDrawer && (
        <ObjectiveTooletip
          objectiveName={objective.name}
          objectiveRateDiff={objectiveRateDiff}
          objectiveProgressRate={objectiveProgressRate}
          displayProgressForecast={displayProgressForecast}
          objectiveProgressForecast={objectiveProgressForecast}
        />
      )}
      {/* keyResult */}
      {displayProgressForecast
        ? progressForecastKeys.map((key: string) => {
            const datum = tooltipData?.datumByKey[key].datum as Series | undefined
            if (!datum || !tooltipData?.nearestDatum) return ''
            return (
              <div
                key={key}
                css={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <StyledText
                  size="small"
                  color="text-bk-100"
                  css={{
                    width: '142px',
                    marginRight: '8px',
                    flexShrink: 0,
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {datum.label}
                </StyledText>
                <StyledText
                  size="small"
                  color="kr-green-100"
                  weight="bold"
                  css={{
                    width: '33px',
                  }}
                >
                  {`${datum.y ? datum.y : 0}%`}
                </StyledText>
              </div>
            )
          })
        : keys.map((key: string, i) => {
            const datum = tooltipData?.datumByKey[key].datum as Series | undefined
            if (!datum || !tooltipData?.nearestDatum) return ''
            const keyResultRateDiff =
              tooltipData.nearestDatum.index === 0 ||
              Object.is(krSeries[i][tooltipData.nearestDatum.index - 1], i)
                ? 0
                : datum.y - krSeries[i][tooltipData.nearestDatum.index - 1].y
            return (
              <div
                key={key}
                css={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <StyledText
                  size="small"
                  color="text-bk-100"
                  css={{
                    width: '142px',
                    marginRight: '8px',
                    flexShrink: 0,
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {datum.label}
                </StyledText>
                <StyledText
                  size="small"
                  color="kr-green-100"
                  weight="bold"
                  css={{
                    width: '33px',
                    marginRight: keyResultRateDiff === 0 ? '14px' : '8px',
                  }}
                >
                  {`${datum.y ? datum.y : 0}%`}
                </StyledText>
                {keyResultRateDiff !== 0 ? (
                  <StyledText
                    size="xsmall"
                    weight="bold"
                    css={{
                      color: keyResultRateDiff > 0 ? labelGreen : color('tag-red'),
                    }}
                  >
                    {keyResultRateDiff > 0 ? '↑' : '↓'}
                    {keyResultRateDiff > 0 ? keyResultRateDiff : Math.abs(keyResultRateDiff)}
                  </StyledText>
                ) : (
                  <StyledText size="xsmall" color="text-bk-50" weight="bold">
                    -
                  </StyledText>
                )}
              </div>
            )
          })}
    </div>
  )
}

DrawerTooltip.displayName = 'DrawerTooltip'

type ObjectiveTooletipProps = {
  objectiveName: string
  objectiveRateDiff: number
  objectiveProgressRate?: number | null
  displayProgressForecast: boolean
  objectiveProgressForecast: number
}

const ObjectiveTooletip: React.FC<ObjectiveTooletipProps> = ({
  objectiveName,
  objectiveRateDiff,
  objectiveProgressRate,
  displayProgressForecast,
  objectiveProgressForecast,
}) =>
  displayProgressForecast ? (
    <div
      css={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}
    >
      <StyledText
        size="small"
        color="text-bk-100"
        css={{
          width: '142px',
          marginRight: '8px',
          flexShrink: 0,
          fontWeight: 'bold',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
      >
        {objectiveName}
      </StyledText>
      <StyledText
        size="small"
        color="objective-blue-100"
        weight="bold"
        css={{
          width: '33px',
          marginRight: objectiveRateDiff === 0 ? '14px' : '8px',
        }}
      >
        {`${objectiveProgressForecast}%`}
      </StyledText>
    </div>
  ) : (
    <div
      css={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}
    >
      <StyledText
        size="small"
        color="text-bk-100"
        css={{
          width: '142px',
          marginRight: '8px',
          flexShrink: 0,
          fontWeight: 'bold',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
      >
        {objectiveName}
      </StyledText>
      <StyledText
        size="small"
        color="objective-blue-100"
        weight="bold"
        css={{
          width: '33px',
          marginRight: objectiveRateDiff === 0 ? '14px' : '8px',
        }}
      >
        {`${objectiveProgressRate || 0}%`}
      </StyledText>
      {objectiveRateDiff !== 0 ||
        (objectiveRateDiff ? (
          <StyledText
            size="xsmall"
            weight="bold"
            css={{
              color: objectiveRateDiff > 0 ? labelGreen : color('tag-red'),
            }}
          >
            {objectiveRateDiff > 0 ? '↑' : '↓'}
            {objectiveRateDiff > 0 ? objectiveRateDiff : Math.abs(objectiveRateDiff)}
          </StyledText>
        ) : (
          <StyledText size="xsmall" color="text-bk-50" weight="bold">
            -
          </StyledText>
        ))}
    </div>
  )

ObjectiveTooletip.displayName = 'ObjectiveTooletip'
