import { useTranslation } from '../../../i18n'
import { ActionPlanFragment } from '../../../pages/ActionPlanDetail/graphql'
import { DetailEditCard } from '../../ui/DetailEditCard'

export type Props = {
  actionPlan: ActionPlanFragment
  updateActionPlan: (actionPlan: Partial<ActionPlanFragment>) => void
  onClickCancel: () => void
  setNeedBlock: (value: boolean) => void
}

export const ActionPlanDetailEditPageCard: React.FC<Props> = ({
  actionPlan,
  updateActionPlan,
  onClickCancel,
  setNeedBlock,
}) => {
  const { t } = useTranslation()
  const handleUpdateActionPlan = (noteTitle: string, treeJson: string, text: string) => {
    setNeedBlock(false)
    updateActionPlan({
      ...actionPlan,
      title: noteTitle,
      body: {
        treeJson,
        plainText: text,
      },
    })
  }

  return (
    <DetailEditCard
      editorId={`detailedit-ap-${actionPlan.id}`}
      title={actionPlan.title}
      titlePlaceholder={t('EMPTY_ACTION_PLAN')}
      body={actionPlan.body?.treeJson}
      plainText={actionPlan.body?.plainText || ''}
      onClickCancel={onClickCancel}
      onClickSave={handleUpdateActionPlan}
      onEdit={setNeedBlock}
    />
  )
}

ActionPlanDetailEditPageCard.displayName = 'ActionPlanDetailEditPageCard'
