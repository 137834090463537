import { css } from '@emotion/react'

import { color } from '../../../../../styles/newColors'

export const saveButtonCss = css({
  backgroundColor: color('resily-orange-100'),
  fontWeight: 'normal',
  '&:disabled': {
    backgroundColor: color('disabled-100'),
    opacity: 1,
  },
})

export const cancelTextButtonCss = css({
  width: '102px',
  fontWeight: 'normal',
})

export const inputCss = css({ padding: '0 8px', height: '40px' })
