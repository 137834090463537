import { css } from '@emotion/react'
import { useMemo } from 'react'

import { fontSize } from '../../../../../../styles/font'
import { color } from '../../../../../../styles/newColors'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useStyles = () =>
  useMemo(
    () =>
      ({
        root: css({
          display: 'flex',
          flexDirection: 'column',
          borderBottom: `1px solid ${color('border-bk-10')}`,
        }),
        titleArea: css({
          display: 'flex',
          justifyContent: 'space-between',
          padding: '8px 24px',
          gap: 10,
          '> *:first-child': { alignSelf: 'center' },
        }),
        targetIcon: css({ marginRight: '4px', verticalAlign: 'middle' }),
        krSettings: css({
          display: 'inline-flex',
          margin: 2,
          svg: { verticalAlign: 'unset' },
        }),
        detailArea: css({
          display: 'flex',
          flexDirection: 'column',
          padding: '6px 24px 12px',
          gap: 12,
        }),
        userArea: css({
          display: 'flex',
          gap: 16,
          height: 20,
        }),
        owner: css({
          display: 'flex',
          alignItems: 'center',
          gap: 8,
          minWidth: 'fit-content',
        }),
        contributors: css({
          display: 'flex',
          alignItems: 'center',
          gap: 8,
          height: 20,
          '> *:first-child': { flex: '1 0 auto' },
        }),
        progressAndChildObjectiveArea: css({
          display: 'flex',
          flexDirection: 'column',
          gap: 8,
        }),
        progressArea: css({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: 12,
          height: 32,
        }),
        progressRateAndValueArea: css({
          display: 'flex',
          alignItems: 'center',
          gap: 18,
          whiteSpace: 'nowrap',
        }),
        progressRateArea: css({ display: 'flex', alignItems: 'center', gap: 12 }),
        tooltip: css({
          display: 'flex',
          gap: 4,
          '> *:first-child': { flexShrink: 0 },
        }),
        actualAndTarget: css({
          display: 'flex',
          alignItems: 'center',
          '> *:nth-child(2)': {
            maxWidth: 100,
            marginRight: 4,
            marginLeft: 12,
          },
        }),
        updateProgress: css({
          ...fontSize('small', 'regular'),
          height: 32,
          paddingLeft: 12,
          paddingRight: 12,
        }),
        childObjective: css({
          marginLeft: 32,
          '.reflect-progress-rate': {
            display: 'flex',
            justifyContent: 'center',
            '> button': { flexBasis: '75%' },
          },
        }),
      } as const),
    [],
  )
