import { Button, ButtonProps } from 'grommet'

import { useTranslation } from '../../../i18n'
import { FontSizeAlias } from '../../../styles/font'
import { color } from '../../../styles/newColors'
import { Icon } from '../Icon'
import { StyledText } from '../StyledText'

export type Props = ButtonProps & {
  showIcon?: boolean
  onClick?: () => void
  fontSize?: FontSizeAlias
  isShorterLabel?: boolean
}

export const FloatDrawerTextButton: React.FC<Props> = ({
  showIcon = true,
  onClick,
  fontSize,
  isShorterLabel = false,
  ...props
}) => {
  const { t } = useTranslation()
  const label = isShorterLabel
    ? t('SHOW_RELATED_OKR_BACKGROUNDS_SHORTER')
    : t('SHOW_RELATED_OKR_BACKGROUNDS')

  return (
    <Button
      onClick={onClick}
      css={{
        display: 'flex',
        alignItems: 'center',
        ':hover': {
          color: color('resily-orange-100'),
        },
      }}
      {...props}
    >
      {showIcon && <Icon type="callMade" width={10} height={10} css={{ marginRight: '7px' }} />}
      <StyledText size={fontSize}>{label}</StyledText>
    </Button>
  )
}

FloatDrawerTextButton.displayName = 'FloatDrawerTextButton'
