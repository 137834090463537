import { t } from 'i18next'
import { FieldArrayWithId } from 'react-hook-form'

import { useGetFormMethods } from '../../../../../../../form/contexts/FormProvider'
import { FormValues, keyResultsKeyName } from '../../../../../../../form/formSchema'
import { initialSettingsCompleted, isPercentageInput } from '../../../../utils'
import { PromptMessageBase as PromptMessage } from '../Base'

type SetTargetValueProps = {
  keyResult: FieldArrayWithId<FormValues, 'keyResults', 'id'>
  index: number
}

export const SetTargetValue: React.FC<SetTargetValueProps> = ({ keyResult, index }) => {
  const { formState, getFieldState } = useGetFormMethods()

  if (
    isPercentageInput(keyResult) &&
    !initialSettingsCompleted(keyResult) &&
    !getFieldState(`${keyResultsKeyName}.${index}.targetValue`, formState).isDirty
  ) {
    return <PromptMessage>{t('SET_TARGET_VALUE_TO_MEASURE_PROGRESS_RATE')}</PromptMessage>
  }
  return null
}
