import { useCallback } from 'react'
import { InView, IntersectionOptions } from 'react-intersection-observer'

type Props = Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> & {
  rootElement: IntersectionOptions['root']
  keyResultId: string
  onSelectKeyResult: (id: string) => void
  children: React.ReactNode
}

const rootMargin = `-25% 0px -75% 0px`

export const KeyResultSelectionHandler: React.VFC<Props> = ({
  rootElement,
  keyResultId,
  onSelectKeyResult,
  children,
  ...rest
}) => {
  const handleChange = useCallback<NonNullable<IntersectionOptions['onChange']>>(
    (_, entry) => {
      if (entry.isIntersecting) {
        onSelectKeyResult(keyResultId)
      }
    },
    [keyResultId, onSelectKeyResult],
  )

  return (
    <InView
      as="div"
      root={rootElement}
      rootMargin={rootMargin}
      threshold={0}
      onChange={handleChange}
      {...rest}
    >
      {children}
    </InView>
  )
}

KeyResultSelectionHandler.displayName = 'KeyResultSelectionHandler'
