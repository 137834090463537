import { memo, useEffect, useMemo, useState } from 'react'
import { useNavigate, useLocation, useParams, NavigateFunction } from 'react-router-dom'

import { useOkrTermLoadable } from '../../../lib/domain/useOkrTermLoadable'
import { AuthRouteProps } from '../../../types/authRouteProps'
import {
  generateFilteredTreeUrl,
  generateDeprecatedTrees,
  MAP_VIEW_LIST,
  MapView,
  generateTrees,
} from '../../../urls'

import { useOkrNodesLazyQuery, usePartialTreeQuery } from './graphql'
import { useFilter } from './useFilter'

// # ルーティングからノンコンテナなコンポーネントまでのフロー
//
// 複数のルーティングからマップをレンダリングするフローが存在する。
// そのため、ルーティングから一次請けするコンテナでNodeのfetchや抽出を行い、次のコンテナに受け渡す。
// コンテナ群の最下流としてOkrMapContainerがNodeリストを受け、
// 変形情報抽出などの共通処理を行ってからコンポーネントをレンダリングする。
// URLを変更する必要があるイベントがコンポーネントから来た際は、
// OkrMapContainerがhistoryを操作し、Routerがhistoryの変更を検知する。
//
//  +------------------------------------------------------------------------+
//  |                                                                        |
//  +-> URL -> Router -+-> TreesContainer -+-> useFilter -> OkrMapContainer -+-> OkrMap
//                     |                   |               |               |
//                     +-> TreeContainer --+               +-> useFolding -+
//                     |                   |
//                     +-> NodeContainer --+
//
//
// # フィルタや折り畳みのURL反映とレンダリングのフロー
//
// マップ内での history back/forward/go などの操作によって、フィルタや折り畳みを復元する。
// マップ外からの遷移時やURLを開いた場合やリロード時も、フィルタや折り畳みを復元する。
// そのため、ユーザー入力を一度URLに反映し、URLの変更を検知してからレンダリングすることで、
// ユーザー入力時もURL変更時も同じフローでレンダリングされる。
//
// ## 初期化
//
// Component                           Container                              URL
//     |                                   |                                   |
//     |<- Render the filter status.     <-|<- Parse a search query.         --|
//     |                                   |                                   |
//
// ## ユーザー入力からのライフサイクル
//
// Component                           Container                              URL
//     |                                   |                                   |
//     |-> When user input is detected,  ->|-> Push history with             ->|
//     |   notify the filter status.       |   a seach query to represent      |
//     |                                   |   the filter status.              |
//     |                                   |                                   |
//     |<- Render the filter status.     <-|<- When URL change is detected,  --|
//     |                                   |   parse a search query.           |
//     |                                   |                                   |
//
// ## フィルタや折り畳みのHooksについて
//
// 以前はフィルタする用のContainerを経由する方法を採っていた。
// しかしフィルタ切り替えでマップ全体が再度レンダリングされてしまう。
// それを防ぐため、必要に応じてHooksを経由する方式になった。
// HooksはOkrNodesを受け取り、必要に応じて変形して返す。
// これを受け取って別のHooksに渡すことで複数の機能を両立させている。
//
// (図はルーティングからノンコンテナなコンポーネントまでのフローを参照)
//
// # 変形のURL反映とレンダリングのフロー
//
// マップ内での history back/forward/go などの操作によって、変形を復元する。
// マップ外からの遷移時やURLを開いた場合やリロード時には、変形を復元する。
// 変形は連続的なユーザー入力をソースとすることが多いため、
// 即座にレンダリングしないと入力から出力までのラグを感じてしまう。
// そこで、URLを介してレンダリングするのではなく、入力を即座にレンダリングに反映する。
// また、同じマップの表示時に変形を行った履歴を全て取っておくことはせず、
// hisotryをreplaceすることでURLに反映する。
// つまり、同じマップの表示中は最後の変形がhistoryに積まれることになる。
// また、ユーザー入力直後とURL変更後の2度に渡ってレンダリングしているように見えるが、
// レンダリング前に既に反映された変形かをチェックしているため実際には連続的な変形時は
// 入力直後しかレンダリングされない。
//
// ## 初期化
//
// Component                           Container                              URL
//     |                                   |                                   |
//     |<- Render the transformation.    <-|<- Parse a hash parameter.       --|
//     |                                   |                                   |
//
// ## ユーザー入力からのライフサイクル
//
// Component                           Container                              URL
//     |                                   |                                   |
//     |-> When user input is detected,  ->|-> Replace history with          ->|
//     |   notify the transformation       |   a hash parameter to represent   |
//     |<- and render the transformation.  |   the transformation.             |
//     |                                   |                                   |
//     |<- Render the transformation     <-|<- When URL change is detected,  <-|
//     |   when it differs from            |   parse a search query.           |
//     |   the current transformation.     |                                   |
//     |                                   |                                   |
//

const useViewType = (): MapView | null => {
  const { search } = useLocation()
  const [selectedViewType, setSelectedViewType] = useState<MapView | null>(null)

  useEffect(() => {
    const params = new window.URLSearchParams(search.replace(/^\?/, ''))
    setSelectedViewType((prev) => {
      const view = params.get('view')
      if (!view) {
        return prev === view ? prev : null
      }

      const next: MapView = MAP_VIEW_LIST.includes(view as MapView) ? (view as MapView) : 'tree'

      return prev === next ? prev : next
    })
  }, [search])

  return selectedViewType
}

const reflectViewType =
  (navigate: NavigateFunction, viewType: MapView | null) => (okrTermId: string) => {
    navigate(
      generateFilteredTreeUrl(generateDeprecatedTrees(okrTermId), { view: viewType ?? 'tree' }),
      { replace: true },
    )
  }

export const TreesContainer: React.FC<AuthRouteProps> = memo<AuthRouteProps>(
  ({ onOkrTermLoaded }) => {
    const navigate = useNavigate()
    const viewType = useViewType()
    const termId = useOkrTermLoadable(onOkrTermLoaded, reflectViewType(navigate, viewType))

    const [okrNodesQuery, okrNodesResp] = useOkrNodesLazyQuery()

    const allOkrNodes = useMemo(() => okrNodesResp.data?.okrNodes || [], [okrNodesResp])
    const [, , selectedGroupIds, selectedUserIds] = useFilter(allOkrNodes)

    // NOTE: 旧マップのクエリパラメータを引き継いでβに飛ばす
    if (termId) {
      const params = new URLSearchParams([
        ...selectedGroupIds.map((e) => ['group', e]),
        ...selectedUserIds.map((e) => ['user', e]),
        ['view', viewType ?? ''],
      ])
      navigate(`${generateTrees({ termId })}?${params.toString()}`, { replace: true })
    }

    useEffect(() => {
      if (termId) {
        okrNodesQuery({ variables: { okrTermId: termId } })
      }
    }, [okrNodesQuery, termId])

    return null
  },
)

TreesContainer.displayName = 'TreesContainerDeprecated'

export const TreeContainer: React.FC<AuthRouteProps> = ({ onOkrTermLoaded }) => {
  const { nodeId } = useParams<{ nodeId: string }>()
  const navigate = useNavigate()
  const viewType = useViewType()
  const termId = useOkrTermLoadable(onOkrTermLoaded, reflectViewType(navigate, viewType))

  const partialTreeResp = usePartialTreeQuery({
    variables: { vertexOkrNodeId: nodeId ?? '' },
    skip: nodeId == null,
  })

  const allOkrNodes = useMemo(() => partialTreeResp.data?.partialTree || [], [partialTreeResp])
  const [, , selectedGroupIds, selectedUserIds] = useFilter(allOkrNodes)

  // NOTE: 旧マップのクエリパラメータを引き継いでβに飛ばす
  if (termId) {
    const params = new URLSearchParams([
      ...selectedGroupIds.map((e) => ['group', e]),
      ...selectedUserIds.map((e) => ['user', e]),
      ['view', viewType ?? ''],
    ])
    navigate(`${generateTrees({ termId })}?${params.toString()}`, { replace: true })
  }
  return null
}

TreeContainer.displayName = 'TreeContainerDeprecated'

export const NodeContainer: React.FC<AuthRouteProps> = ({ onOkrTermLoaded }) => {
  const navigate = useNavigate()
  const viewType = useViewType()
  const termId = useOkrTermLoadable(onOkrTermLoaded, reflectViewType(navigate, viewType))

  const [okrNodesQuery, okrNodesResp] = useOkrNodesLazyQuery()

  const allOkrNodes = useMemo(() => okrNodesResp.data?.okrNodes || [], [okrNodesResp])
  const [, , selectedGroupIds, selectedUserIds] = useFilter(allOkrNodes)

  // NOTE: 旧マップのクエリパラメータを引き継いでβに飛ばす
  if (termId) {
    const params = new URLSearchParams([
      ...selectedGroupIds.map((e) => ['group', e]),
      ...selectedUserIds.map((e) => ['user', e]),
      ['view', viewType ?? ''],
    ])
    navigate(`${generateTrees({ termId })}?${params.toString()}`, { replace: true })
  }

  useEffect(() => {
    if (termId) {
      okrNodesQuery({ variables: { okrTermId: termId } })
    }
  }, [okrNodesQuery, termId])

  return null
}

NodeContainer.displayName = 'NodeContainerDeprecated'
