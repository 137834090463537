import { TextLink, useModal } from '@resily/geisha'
import React, { useCallback } from 'react'

import { useTranslation } from '../../../i18n'
import { findScreen } from '../../../lib/screen'
import { componentNames, featureNames, generateTestId } from '../../../lib/testId'
import { tracker } from '../../../lib/tracking'
import { ExternalUrls } from '../../../urls'
import { Icon } from '../../ui/Icon'
import { StyledText } from '../../ui/StyledText'

import { KeyResultDrawerContainer, KeyResultDrawerContainerProps } from './KeyResultDrawer'
import { Main } from './Main'
import { CheckinAreaContainerProps } from './Main/CheckinArea'
import { KeyResultAreaContainerProps } from './Main/KeyResultArea'
import { Props as ObjectiveAreaContainerProps } from './Main/ObjectiveArea'
import { useStyles } from './OkrModal.styles'
import { RightSectionContainer, DataProps as RightSectionContainerProps } from './RightSection'

export type DataProps = {
  objectiveAreaData: ObjectiveAreaContainerProps['objective']
  keyResultAreaData: Pick<
    KeyResultAreaContainerProps,
    'okrNodeId' | 'keyResults' | 'disabledReOrderingChildOkr' | 'useWeighting'
  >
  checkinAreaData: Pick<CheckinAreaContainerProps, 'okrNodeId' | 'keyResults'>
  rightSectionData: RightSectionContainerProps['rightSectionData']
  keyResultDrawerData: Pick<KeyResultDrawerContainerProps, 'keyResults'>
}

type LoadableProps<T extends Record<never, never>> =
  | ({ loading: true } & Partial<T>)
  | ({ loading: false } & T)

export type OkrModalProps = LoadableProps<DataProps> & {
  isOpened: boolean
  close: () => void
  objective: {
    onClickObjective: () => void
  }
  keyResult: {
    onClickKeyResultName: (id: string) => void
  }
  rightSection: {
    onDisabledObjective: () => void
  }
  keyResultDrawer: {
    isOpened: boolean
    onClose: () => void
  }
}

export const OkrModal = React.memo<
  OkrModalProps & { modalComponent: ReturnType<typeof useModal>[0] }
>(
  ({
    modalComponent: Modal,
    isOpened,
    close,
    loading,
    objective,
    objectiveAreaData,
    keyResult,
    keyResultAreaData,
    checkinAreaData,
    rightSection,
    rightSectionData,
    keyResultDrawer,
    keyResultDrawerData,
  }) => {
    const { t } = useTranslation()
    const styles = useStyles()

    return (
      <Modal
        size="xxlarge"
        contentMargin={false}
        // NOTE: 非geishaのモーダルを上に重ねて表示すると、その内部のinput等にfocusが当たらない問題の対策
        // geishaモーダルへの置き換えが完了した場合は不要
        restrictFocus={false}
        backgroundColor="background-black-bk3"
        isOpen={isOpened}
        onClose={useCallback(() => {
          tracker.UserClickCloseOkrModal(
            findScreen(window.location.pathname, window.location.search),
          )
          close()
        }, [close])}
      >
        <Modal.Header
          backgroundColor="background-black-bk5"
          title={
            <div
              css={styles.header}
              data-testid={generateTestId(featureNames.okrModal, componentNames.header)}
            >
              <StyledText fontStyle="bold">{t('X_DETAIL', { x: t('OKR') })}</StyledText>
              <TextLink
                type="primary"
                css={styles.headerLink}
                onClick={() =>
                  window.open(ExternalUrls.HOW_TO_USE_OKR_MODAL, '_blank', 'noopener, noreferrer')
                }
              >
                <StyledText size="small" fontStyle="regular">
                  {t('LOOK_AT_X', { x: t('HOW_TO_USE') })}
                </StyledText>
                <Icon type="openInNew" width={14} height={14} />
              </TextLink>
            </div>
          }
        />
        <Modal.Content>
          <div
            css={styles.contentInner}
            data-testid={generateTestId(featureNames.okrModal, componentNames.content)}
          >
            <Main>
              <Main.ObjectiveArea
                loading={loading as never}
                objective={objectiveAreaData}
                onClickObjective={objective.onClickObjective}
              />
              <Main.KeyResultArea
                disabledReOrderingChildOkr={keyResultAreaData?.disabledReOrderingChildOkr}
                okrNodeId={keyResultAreaData?.okrNodeId}
                keyResults={keyResultAreaData?.keyResults}
                onClickKeyResultName={keyResult.onClickKeyResultName}
                useWeighting={keyResultAreaData?.useWeighting}
                loading={loading}
              />
              <Main.CheckinArea
                okrNodeId={checkinAreaData?.okrNodeId}
                keyResults={checkinAreaData?.keyResults}
              />
            </Main>
            <RightSectionContainer
              loading={loading as never}
              showRightSection={!keyResultDrawer.isOpened}
              rightSectionData={rightSectionData}
              onDelete={close}
              onDisable={rightSection.onDisabledObjective}
            />
            {keyResultDrawer.isOpened && (
              <KeyResultDrawerContainer
                keyResults={keyResultDrawerData?.keyResults}
                onDeleteKeyResult={keyResultDrawer.onClose}
              />
            )}
          </div>
        </Modal.Content>
      </Modal>
    )
  },
)
