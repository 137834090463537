import { color } from '../../../styles/newColors'
import { StyledText } from '../../ui/StyledText'
import { TooltipNew } from '../../ui/TooltipNew'

import { GroupFragment } from './graphql'

export type Props = React.PropsWithoutRef<JSX.IntrinsicElements['button']> & {
  name: GroupFragment['name']
  tooltipDisabled?: boolean
  onClick?: () => void
}

export const GroupTag: React.FC<Props> = ({ name, tooltipDisabled = false, onClick, ...props }) => (
  <TooltipNew
    disabled={tooltipDisabled}
    title={name}
    dropProps={{
      align: {
        top: 'bottom',
        left: 'left',
      },
      stretch: false,
      plain: true,
    }}
    css={{ maxWidth: '100%', display: 'inline' }}
  >
    <button
      type="button"
      css={{ outline: 'none', cursor: onClick ? 'pointer' : 'auto', maxWidth: '100%' }}
      onClick={onClick}
      {...props}
    >
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          maxWidth: '100%',
          backgroundColor: color('border-bk-10'),
          borderRadius: '2px',
          padding: '2px 4px',
          marginRight: '8px',
          color: color('text-bk-80'),
        }}
      >
        <StyledText css={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {name}
        </StyledText>
      </div>
    </button>
  </TooltipNew>
)

GroupTag.displayName = 'GroupTag'
