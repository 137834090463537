import { Box, Meter } from 'grommet'

import { StyledText } from '../../components/ui/StyledText'
import { FontSizeAlias } from '../../styles/font'

type Size = 'medium' | 'large'

type SizeProps = {
  meter: {
    width: string
    height: string
  }
  text: FontSizeAlias
}

const SizeObj: { [key in Size]: SizeProps } = {
  medium: {
    meter: {
      width: '84px',
      height: '20px',
    },
    text: 'medium',
  },
  large: {
    meter: {
      width: '56px',
      height: '16px',
    },
    text: 'large',
  },
}

const sizeProps = (key: keyof typeof SizeObj) => SizeObj[key]

export type Props = {
  color: string
  rate: number
  size?: Size
  withoutText?: boolean
  fill?: boolean
}

export const NewProgressRate: React.FC<Props> = (props) => {
  const { color, rate, size, withoutText, fill } = props

  const { meter, text } = sizeProps(size || 'medium')
  const fillStyle = fill ? { width: '100%' } : undefined

  return (
    <Box direction="row" align="center">
      <Meter
        round
        values={[{ value: rate, color }]}
        background={{ color, opacity: 0.16 }}
        css={{
          ...meter,
          borderRadius: 10,
          ...fillStyle,
        }}
      />
      {withoutText ? null : (
        <StyledText
          size={text}
          color="white-100"
          css={{
            fontWeight: 'bold',
            position: 'absolute',
            fontSize: '10px',
            paddingLeft: '2px',
          }}
        >
          {rate}%
        </StyledText>
      )}
    </Box>
  )
}

NewProgressRate.displayName = 'NewProgressRate'
