import { css, CSSObject } from '@emotion/react'
import { Box, Button } from 'grommet'
import { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'

import { Icon } from '../Icon'
import { Props as PopoverProps, Content } from '../Popover/deprecated'

export type Props = Omit<PopoverProps, 'position'> & {
  portalPosition?: {
    top?: number
    left?: number
  }
}

/** @deprecated */
export const PopoverPortal: React.FC<Props> = ({
  icon = 'help',
  iconCss = {} as CSSObject,
  title,
  text,
  link,
  hasGap = true,
  portalPosition,
  ...props
}) => {
  const el = document.getElementById('main-content-id')
  const ref = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLButtonElement>(null)

  const [buttonRect, setButtonRect] = useState<{ top: number; left: number }>({ top: 0, left: 0 })
  const [isMouseEnter, setIsMouseEnter] = useState<boolean>(false)

  useEffect(() => {
    if (!hasGap || !buttonRef.current) return

    // hasGapの場合、画面左上にクリック領域が発生するためアイコンボタンの位置を初期値に設定する
    const rect = buttonRef.current.getBoundingClientRect()
    setButtonRect({ left: rect.left, top: rect.top })
  }, [hasGap, buttonRef])

  useEffect(() => {
    if (!ref.current) {
      return
    }
    // 初期状態だとアイコンボタンとポップオーバーの左上頂点が重なっている。右辺の{}内の第2項は、左上頂点の初期位置から左右上下に設定したpx分動かす事が出来る
    ref.current.style.left = `${buttonRect.left + (portalPosition?.left || 0)}px`
    ref.current.style.top = `${buttonRect.top + (portalPosition?.top || 0)}px`
  }, [ref, buttonRect.left, buttonRect.top, portalPosition])

  return (
    <Box {...props} css={{ display: 'inline' }}>
      <Button
        ref={buttonRef}
        icon={<Icon type={icon} css={css([{ width: 12, height: 12, ...iconCss }, iconCss])} />}
        onMouseEnter={(e) => {
          setButtonRect({
            left: e.currentTarget.getBoundingClientRect().left,
            top: e.currentTarget.getBoundingClientRect().top,
          })
          setIsMouseEnter(true)
        }}
        onMouseLeave={() => {
          setIsMouseEnter(false)
        }}
        css={{ padding: 0 }}
      />

      {createPortal(
        <div
          ref={ref}
          css={{
            position: 'absolute',
            zIndex: 700,
            '.popover': {
              display: isMouseEnter ? 'block' : undefined,
            },
            ':hover': {
              '.popover': {
                display: 'block',
              },
            },
          }}
        >
          <Content
            icon={icon}
            iconCss={iconCss}
            title={title}
            text={text}
            link={link}
            hasGap={hasGap}
          />
        </div>,
        el || document.body, // document.bodyをcontainerに指定すると子孫のdiv内で設定しているcssが適用されないため
      )}
    </Box>
  )
}

PopoverPortal.displayName = 'PopoverPortal'
