import { Fragment, useState } from 'react'

import { useTranslation } from '../../../i18n'
import { ActionPlanFragment } from '../../../pages/ActionPlanDetail/graphql'
import { DeleteModal } from '../../ui/DeleteModal'
import { DetailCard } from '../../ui/DetailCard'
import { Icon } from '../../ui/Icon'
import { StyledText } from '../../ui/StyledText'

export type Props = {
  actionPlan: ActionPlanFragment
  onEdit: () => void
  deleteActionPlan: (actionPlanId: string) => void
}

export const ActionPlanDetailCard: React.FC<Props> = ({ actionPlan, onEdit, deleteActionPlan }) => {
  const { t } = useTranslation()
  const [isOpened, setIsOpend] = useState(false)

  return (
    <Fragment>
      <DeleteModal
        isOpened={isOpened}
        deleteItemName={t('ACTION_PLAN')}
        onClickDelete={() => {
          deleteActionPlan(actionPlan.id)
          setIsOpend(false)
        }}
        onClickCancel={() => setIsOpend(false)}
        onClickOutside={() => setIsOpend(false)}
      >
        <StyledText weight="bold" css={{ marginTop: '24px' }}>
          {actionPlan.title}
        </StyledText>
      </DeleteModal>
      <DetailCard
        editorId={`detail-ap-${actionPlan.id}`}
        description={actionPlan.body?.treeJson}
        subtitle={
          <div css={{ display: 'flex', alignItems: 'flex-start' }}>
            <div css={{ width: 24, marginRight: 12, marginTop: 4 }}>
              <Icon type="actionPlan" css={{ width: 24, height: 24 }} />
            </div>
            <h1>
              <StyledText
                size="xxlarge"
                css={{
                  display: 'flex',
                  wordBreak: 'break-all',
                }}
              >
                {actionPlan.title}
              </StyledText>
            </h1>
          </div>
        }
        icons={[
          {
            type: 'editOutline',
            tooltip: t('EDITION'),
          },
          {
            type: 'deleteOutline',
            tooltip: t('DELETION'),
          },
        ]}
        onClickIcon={(type) => {
          if (type === 'editOutline') onEdit()
          if (type === 'deleteOutline') setIsOpend(true)
        }}
      />
    </Fragment>
  )
}

ActionPlanDetailCard.displayName = 'ActionPlanDetailCard'
