import { Fragment } from 'react'

import { Logo } from '../../../components/ui/Logo'

import { useStyles } from './index.styles'

export type Props = {
  children: React.ReactNode
}

export const Wrapper: React.VFC<Props> = ({ children }) => {
  const styles = useStyles()

  return (
    <Fragment>
      <Logo css={styles.logo} />
      <div css={styles.content}>{children}</div>
    </Fragment>
  )
}
