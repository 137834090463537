import { TextLink } from '@resily/geisha'
import { useCallback } from 'react'

import { useTranslation } from '../../../../i18n'
import { findScreen } from '../../../../lib/screen'
import { tracker } from '../../../../lib/tracking'
import { ExternalUrls } from '../../../../urls'
import { StyledText } from '../../../ui/StyledText'

import { useStyles } from './index.styles'

type Props = {
  isObjectiveMode: boolean
}

export const Header: React.FC<Props> = ({ isObjectiveMode }) => {
  const { t } = useTranslation()
  const styles = useStyles()

  return (
    <div css={styles.root}>
      {t('CREATE_OF_X', { x: t(isObjectiveMode ? 'OBJECTIVE' : 'KEY_RESULT') })}
      <TextLink
        type="primary"
        isExternalLink
        onClick={useCallback(() => {
          tracker.UserClickShowExternalSampleOkrPageByObjectiveCrateModal(
            findScreen(window.location.pathname, window.location.search),
          )
          window.open(ExternalUrls.SAMPLE_OKR, '_blank', 'noopener, noreferrer')
        }, [])}
        css={styles.link}
      >
        <StyledText size="small" weight="normal">
          {t('SEE_SAMPLE')}
        </StyledText>
      </TextLink>
    </div>
  )
}
