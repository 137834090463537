import { Box } from 'grommet'
import { useMemo } from 'react'
import { TreeItem } from 'react-sortable-tree'

import 'react-sortable-tree/style.css'
import { PageContent } from '../../components/pageContent'
import { Icon } from '../../components/ui/Icon'
import { StyledText } from '../../components/ui/StyledText'
import { WrapText } from '../../components/ui/WrapText'
import { useCurrentUser, useSetCurrentUser } from '../../contexts/UserContext'
import { useTranslation } from '../../i18n'
import { groupsToTreeModel, GroupTree } from '../../lib/domain/group'
import { color } from '../../styles/newColors'
import { AuthRouteProps } from '../../types/authRouteProps'
import * as urls from '../../urls'

import { writeGroupsQuery } from './api/cache'
import {
  GroupFieldsFragment,
  useCreateGroupMutation,
  useDisableGroupMutation,
  useGroupsQuery,
  useMoveGroupMutation,
} from './api/graphql'
import { AdminGroupsSetting } from './components/AdminGroupsSetting'
import { DeleteGroupNode, GroupNodes } from './types'

export const AdminGroupsSettingContainer: React.FC<AuthRouteProps> = () => {
  const { t } = useTranslation()
  const user = useCurrentUser()
  const updateUser = useSetCurrentUser()
  const groups = useGroupsQuery()
  const [createGroupMutation] = useCreateGroupMutation()
  const [disableGroupMutation] = useDisableGroupMutation()
  const [moveGroupMutation] = useMoveGroupMutation()

  const addGroupToCurrentUser = (data?: GroupFieldsFragment) => {
    if (!data || !user) return
    const newGroup = (({ __typename, id, name, depth, childGroupIds }) => ({
      __typename,
      id,
      name,
      depth,
      childGroupIds,
    }))(data)
    updateUser({ ...user, groups: [...user.groups, { ...newGroup }] })
  }

  const deleteGroupFromCurrentUser = (id: string) => {
    if (!user || !user.groups.some((group) => group.id === id)) return
    const newGroups = user?.groups.filter((group) => group.id !== id)
    updateUser({ ...user, groups: newGroups })
  }

  const createGroup = async () => {
    if (!user) return
    const data = await createGroupMutation({
      variables: {
        name: t('EMPTY_GROUP'),
        ownerUserIds: [user.id],
      },
    })
    addGroupToCurrentUser(data.data?.createGroup)
    await groups.refetch()
  }

  const createChildGroup = async (parentId: string) => {
    if (!user) return
    const data = await createGroupMutation({
      variables: {
        name: t('EMPTY_GROUP'),
        ownerUserIds: [user.id],
        parentGroupId: parentId,
      },
    })
    addGroupToCurrentUser(data.data?.createGroup)
    await groups.refetch()
  }

  const deleteGroup = async (node: DeleteGroupNode) => {
    await disableGroupMutation({
      variables: {
        id: node.id,
      },
      update(cache, { data }) {
        if (!data?.disableGroup) {
          return
        }
        const { disableGroup: deletedGroups } = data
        writeGroupsQuery(cache, deletedGroups)
      },
    })
    deleteGroupFromCurrentUser(node.id)
  }

  const moveGroup = async (id: string, index: number, parentGroupId?: string) => {
    await moveGroupMutation({
      variables: {
        id,
        parentGroupId,
        index,
      },
      update(cache, { data }) {
        if (!data?.moveGroup) {
          return
        }
        const { moveGroup: movedGroupds } = data
        writeGroupsQuery(cache, movedGroupds)
      },
    })
  }

  const editableNode = (node: TreeItem): boolean => {
    if (!user) {
      return false
    }
    // 管理者なら無条件で編集可能
    if (user.admin) {
      return true
    }

    // 自身が管理者なら編集可能
    if (node.admins.findIndex((e: GroupFieldsFragment) => e.id === user?.id) !== -1) {
      return true
    }

    // グループ管理者は自身より配下のグループの編集が可能
    const findOwner = (g: TreeItem & { parent?: GroupTree<GroupFieldsFragment> }): boolean => {
      const { parent } = g
      if (parent) {
        const isFind = parent.admins.findIndex((e) => e.id === user?.id) !== -1
        if (!isFind) {
          return findOwner(parent)
        }
        return true
      }
      return false
    }
    return findOwner(node)
  }

  const groupNodes = useMemo<ReadonlyArray<GroupNodes>>(() => {
    if (!groups.data) {
      return []
    }

    const gNodes = groupsToTreeModel<GroupFieldsFragment>(groups.data.groups)
    const decorateTreeNode = (nodes: ReadonlyArray<GroupNodes>): Array<GroupNodes> =>
      nodes.map((e) => {
        if (e.children.length > 0) {
          e.children = decorateTreeNode(e.children)
        }
        return {
          ...e,
          title: (
            <Box direction="row" gap="8px" width="400px" align="center">
              <WrapText text={e.name} maxWidth="300px" />
              <Box direction="row" gap="4px" align="center">
                <Icon type="personWhite" />
                <StyledText fontStyle="narrow" color="text-bk-50">
                  {e.admins.length + e.members.length}
                </StyledText>
              </Box>
            </Box>
          ),
          expanded: true,
        }
      })
    return decorateTreeNode(gNodes)
  }, [groups.data])

  const isShowEditOrderButton = useMemo<boolean>(() => {
    // 管理者なら無条件で編集可能
    if (user?.admin) {
      return true
    }
    // ログインユーザーのIDが定まっていない or グループのデータの取得が終わっていない場合は編集不可
    if (!user?.id || !groups.data?.groups) {
      return false
    }

    return groups.data.groups.some((g) => g.admins.some((e) => e.id === user.id))
  }, [groups.data?.groups, user?.admin, user?.id])

  return (
    <PageContent
      breadcrumbs={{
        url: urls.groupsSettingAdmin,
        items: [{ breadcrumbName: 'setting' }, { breadcrumbName: 'settingGroups' }],
      }}
      layout={{ css: { padding: '48px 36px' } }}
      contentBackgroundColor={color('background-bk-5')}
    >
      <AdminGroupsSetting
        loaded={groups.called && !groups.loading}
        isShowEditOrderButton={isShowEditOrderButton}
        groups={groupNodes}
        editable={editableNode}
        createGroup={createGroup}
        createChildGroup={createChildGroup}
        deleteGroup={deleteGroup}
        moveGroup={moveGroup}
        isAdmin={!!user?.admin}
      />
    </PageContent>
  )
}

AdminGroupsSettingContainer.displayName = 'AdminGroupsSettingContainer'
