import { ThemeContext } from 'grommet'
import React, { useEffect, useReducer, useState, useCallback } from 'react'
import { usePrevious } from 'react-use'

import { useEditingStateMachine } from '../../../../../contexts/EditingStateContext'
import { useTranslation } from '../../../../../i18n'
import { findScreen } from '../../../../../lib/screen'
import { tracker } from '../../../../../lib/tracking'
import { color } from '../../../../../styles/newColors'
import { useAttachmentsView } from '../../../../domain/Attachments/hooks/useAttachmentsView'
import { Button } from '../../../../ui/Button'
import { Icon } from '../../../../ui/Icon'
import { StyledText } from '../../../../ui/StyledText'
import { TextButton } from '../../../../ui/TextButton'
import { File, Document } from '../../graphql'

import { useStyles } from './Description.styles'
import { DescriptionSkeleton } from './DescriptionSkeleton'
import { useEditor } from './hooks/useEditor'

export type Props = {
  title: string
  editorId: string
  editorPlaceholder: string
  emptyPlaceholder: string
  description: Document | null | undefined
  attachmentViews: ReadonlyArray<File>
  saveDescription: (
    treeJson: string,
    plainText: string,
    beforeTreeJson: string,
    beforePlainText: string,
    attachmentViews: ReadonlyArray<File>,
  ) => void
}

type DescriptionComposition = {
  Skeleton: typeof DescriptionSkeleton
}

export const Description = React.memo<Props>(
  ({
    title,
    editorId,
    editorPlaceholder,
    emptyPlaceholder,
    description,
    attachmentViews,
    saveDescription,
  }) => {
    const { t } = useTranslation()
    const styles = useStyles()

    const [readOnly, setReadOnly] = useState(true)

    const { Editor, getEditorState, resetEditor, isDirtyEditor } = useEditor({
      id: editorId,
      description,
      editorPlaceholder,
      emptyPlaceholder,
      readOnly,
    })
    const { AttachmentsView, getAttachments, resetAttachments, isDirtyAttachments } =
      useAttachmentsView(attachmentViews)
    useEffect(resetAttachments, [resetAttachments])

    const [, reRender] = useReducer((i: number) => i + 1, 0)

    const { requestStartEdit, updateIsDirty, discardEdit, finishEdit } = useEditingStateMachine()
    const isDirty = isDirtyEditor || isDirtyAttachments
    const isDirtyPrev = usePrevious(isDirty)
    if (!readOnly && isDirtyPrev !== isDirty) {
      updateIsDirty(isDirty)
    }

    const handleEditButtonClick = useCallback(() => {
      if (title === t('KR_DESCRIPTION')) {
        tracker.UserClickKeyResultDescriptionEditButtonByKeyResultDrawer(
          findScreen(window.location.pathname, window.location.search),
        )
      }
      if (title === t('OKR_BACKGROUND')) {
        tracker.UserClickObjectiveDescriptionSaveButtonByObjectiveDrawer(
          findScreen(window.location.pathname, window.location.search),
        )
      }

      requestStartEdit(
        () => {
          // キャンセルした時は初期状態のデータに戻す
          const reset = () => {
            resetEditor()
            resetAttachments()
            setReadOnly(true)
            // FIXME: キャンセル時にreadonlyなエディタがレンダリングされない場合がある対策
            setTimeout(reRender, 0)
          }

          setReadOnly(false)
          return reset
        },
        title === t('KR_DESCRIPTION')
          ? 'KEY_RESULT_DESCRIPTION_IN_OKR_MODAL'
          : 'OBJECTIVE_DESCRIPTION_IN_OKR_MODAL',
      )
    }, [requestStartEdit, resetAttachments, resetEditor, t, title])

    const handleSaveButtonClick = useCallback(() => {
      if (title === t('KR_DESCRIPTION')) {
        tracker.UserClickKeyResultDescriptionSaveButtonByKeyResultDrawer(
          findScreen(window.location.pathname, window.location.search),
        )
      }
      if (title === t('OKR_BACKGROUND')) {
        tracker.UserClickObjectiveDescriptionSaveButtonByObjectiveDrawer(
          findScreen(window.location.pathname, window.location.search),
        )
      }

      setReadOnly(true)
      finishEdit()
      if (!isDirtyEditor && !isDirtyAttachments) return
      const editorState = getEditorState()
      // FIXME:どちらかのみ保存できるようにDescription変更のmutaionの引数のeditor,attachmentそれぞれをnullableに変更する
      saveDescription(
        editorState?.treeJson ?? '',
        editorState?.plainText ?? '',
        description?.treeJson ?? '',
        description?.plainText ?? '',
        getAttachments(),
      )
    }, [
      finishEdit,
      getAttachments,
      getEditorState,
      isDirtyAttachments,
      isDirtyEditor,
      saveDescription,
      t,
      title,
      description,
    ])

    const handleCancelButtonClick = useCallback(() => {
      if (title === t('KR_DESCRIPTION')) {
        tracker.UserClickKeyResultDescriptionCancelButtonByKeyResultDrawer(
          findScreen(window.location.pathname, window.location.search),
        )
      }
      if (title === t('OKR_BACKGROUND')) {
        tracker.UserClickObjectiveDescriptionCancelButtonByObjectiveDrawer(
          findScreen(window.location.pathname, window.location.search),
        )
      }

      discardEdit()
    }, [discardEdit, t, title])

    return (
      <ThemeContext.Extend value={{ global: { drop: { zIndex: '201' } } }}>
        <div css={styles.root}>
          <div css={styles.header}>
            <StyledText size="small" fontStyle="bold">
              {title}
            </StyledText>
            {readOnly ? (
              <button type="button" css={styles.editButton} onClick={handleEditButtonClick}>
                <Icon type="editSimple" color={color('text-bk-30')} />
                <StyledText fontStyle="regular" color="text-bk-30">
                  {t('EDITION')}
                </StyledText>
              </button>
            ) : (
              <div css={styles.headerButtons}>
                <TextButton
                  size="small"
                  color="text-bk-50"
                  padding="10px 12px"
                  onClick={handleCancelButtonClick}
                >
                  {t('CANCEL')}
                </TextButton>
                <Button
                  weight="normal"
                  newColor="resily-orange-100"
                  css={styles.saveButton}
                  onClick={handleSaveButtonClick}
                >
                  {t('SAVE')}
                </Button>
              </div>
            )}
          </div>
          {Editor}
          <AttachmentsView readOnly={readOnly} withDeleteModal />
        </div>
      </ThemeContext.Extend>
    )
  },
) as React.NamedExoticComponent<Props> & DescriptionComposition
Description.displayName = 'Description'
Description.Skeleton = DescriptionSkeleton
