import React, { useCallback } from 'react'

import { useActivityRefetchQueries } from '../../../../../lib/domain/activity/hooks/useActivityRefetchQueries'
import { useParentOkrNodesLazyQuery } from '../../../../../lib/domain/okr/graphql'
import { convertToFileInput } from '../../../../../lib/fileInput'

import { KeyResultDescription, Props as KeyResultDescriptionProps } from './KeyResultDescription'
import {
  File,
  useUpdateKeyResultDescriptionMutation,
  useUpdateKeyResultIsAutoAggregateMutation,
} from './graphql'

export type Props = Pick<
  KeyResultDescriptionProps,
  'keyResult' | 'onUpdateParentObjective' | 'onDelete'
>

export const KeyResultDescriptionContainer: React.VFC<Props> = ({
  keyResult,
  onUpdateParentObjective,
  onDelete,
}) => {
  const [parentOkrNodesQuery] = useParentOkrNodesLazyQuery()
  const [updateKeyResultIsAutoAggregate] = useUpdateKeyResultIsAutoAggregateMutation({
    refetchQueries: useActivityRefetchQueries(),
  })
  const updateKeyResultAutoAggregateMutation = useCallback(async () => {
    if (keyResult == null) return

    await updateKeyResultIsAutoAggregate({
      variables: {
        id: keyResult.id,
        isAutoAggregate: true,
      },
    })
    // 自動吸い上げをONにしたNodeは返り値で進捗率を取得するので
    // 親のNodeのみを取得すればよい
    parentOkrNodesQuery({ variables: { nodeId: keyResult.node.id } })
  }, [keyResult, parentOkrNodesQuery, updateKeyResultIsAutoAggregate])

  const [updateKeyResultDescription] = useUpdateKeyResultDescriptionMutation()
  const updateObjectiveDescriptionMutation = useCallback(
    async (
      treeJson: string,
      plainText: string,
      // TODO: 達成基準と計測方法更新時の即時通知を実装する際に修正する
      beforeTreeJson: string,
      beforePlainText: string,
      newAttachments: ReadonlyArray<File>,
    ) => {
      if (!keyResult?.id) return

      await updateKeyResultDescription({
        variables: {
          id: keyResult.id,
          description: { treeJson, plainText },
          attachments: newAttachments.map(convertToFileInput),
          // TODO: isDescriptionChangedフラグの扱いを確認する
          // isDescriptionChanged: true,
        },
      })
    },
    [keyResult?.id, updateKeyResultDescription],
  )

  return (
    <KeyResultDescription
      loading={(keyResult == null || onUpdateParentObjective == null || onDelete == null) as never}
      keyResult={keyResult as never}
      saveDescription={updateObjectiveDescriptionMutation}
      updateAutoAggregate={updateKeyResultAutoAggregateMutation}
      onUpdateParentObjective={onUpdateParentObjective as never}
      onDelete={onDelete as never}
    />
  )
}
