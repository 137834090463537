import { Box } from 'grommet'
import * as React from 'react'

import { StyledText } from '../../components/ui/StyledText'
import { useTranslation } from '../../i18n'
import * as urls from '../../urls'

import { MeetingNotes, OnSubmitFn as onSubmitMeetingNotesFn } from './MeetingNotes'
import { OnSubmitFn as onSubmitPrivateNotesFn, PrivateNotes } from './PrivateNotes'
import { Tab, Props as TabProps, TabProps as TabContentProps } from './Tab'
import { FixedAgendasFragment, PrivateNoteFragment, UserAgendasFragment } from './graphql'

export type Props = {
  oneOnOneMeetingId: string
  userAgendas: ReadonlyArray<UserAgendasFragment>
  fixedAgendas: ReadonlyArray<FixedAgendasFragment>
  privateNote: PrivateNoteFragment
  onSubmitMeetingNotes: onSubmitMeetingNotesFn
  onSubmitPrivateNotes: onSubmitPrivateNotesFn
}

export const OneOnOneMemo: React.FC<Props> = ({
  oneOnOneMeetingId,
  userAgendas,
  fixedAgendas,
  privateNote,
  onSubmitMeetingNotes,
  onSubmitPrivateNotes,
}) => {
  const { t } = useTranslation()
  const agendaFlag = fixedAgendas.length > 0 || userAgendas.length > 0
  const memoViews: Pick<TabProps, 'tabs'>['tabs'] = new Map<string, TabContentProps>([
    [
      urls.PROCCEDING_MEMO,
      {
        name: t('MEETING_NOTES'),
        content: (
          <Box css={{ height: '100%' }} pad={{ horizontal: '32px', top: '16px' }}>
            <StyledText css={{ display: agendaFlag ? 'none' : 'block' }} color="text-bk-50">
              {t('ONEONONE_AGENDA_INPUT')}
            </StyledText>
            <MeetingNotes
              css={{ display: agendaFlag ? 'block' : 'none' }}
              oneOnOneMeetingId={oneOnOneMeetingId}
              userAgendas={userAgendas}
              fixedAgendas={fixedAgendas}
              onSubmit={onSubmitMeetingNotes}
            />
          </Box>
        ),
      },
    ],
    [
      urls.PRIVATE_MEMO,
      {
        icon: 'lock',
        name: t('PERSONAL_MEMO'),
        content: (
          <Box css={{ height: '100%' }} pad={{ horizontal: '32px', top: '24px' }}>
            <PrivateNotes
              oneOnOneMeetingId={oneOnOneMeetingId}
              privateNote={privateNote}
              onSubmit={onSubmitPrivateNotes}
            />
          </Box>
        ),
      },
    ],
  ])

  return <Tab tabs={memoViews} />
}

OneOnOneMemo.displayName = 'OneOnOneMemo'
