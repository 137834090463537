import { css } from '@emotion/react'
import { Button } from '@resily/geisha'
import { useProfiler } from '@sentry/react'
import { Drop } from 'grommet'
import { useState, useCallback, useRef, ChangeEvent } from 'react'
import { useNavigate } from 'react-router-dom'

import { Checkbox } from '../../../components/ui/Checkbox'
import { Icon } from '../../../components/ui/Icon'
import { Select, Option } from '../../../components/ui/Select'
import { StyledText } from '../../../components/ui/StyledText'
import { TooltipNew } from '../../../components/ui/TooltipNew'
import { useCurrentUser } from '../../../contexts/UserContext'
import { useTranslation } from '../../../i18n'
import { border } from '../../../styles/border'
import { color } from '../../../styles/newColors'
import { generateAdminOkrTerms } from '../../../urls'

export type Props = {
  existsTerm: boolean
  isShownDisabledOkr: boolean
  personalVisibleNodeLevel: number
  showCreateOkrModal: () => void
  onClickActivity: () => void
  onChangeDisabledOkr: (isDisabled: boolean) => void
  onChangePersonalVisibleNodeLevel: (level: number) => void
}

const okrControlerCss = css({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'space-between',
})

const buttonAreaCss = css({ display: 'flex', gap: 12 })
const iconButtonCss = css({
  padding: '4px',
  borderRadius: '8px',
  ':hover': {
    backgroundColor: color('hover-background-bk-5'),
    svg: {
      circle: { stroke: color('resily-orange-100') },
      path: { stroke: color('resily-orange-100') },
    },
  },
})
const activityCss = css(iconButtonCss, {
  ':hover': {
    svg: {
      path: { fill: color('resily-orange-100') },
    },
  },
})

const settingPopupCss = css({
  marginTop: '22px',
  border: border('simple-30'),
  boxShadow: '0px 8px 16px rgb(34 41 67 / 8%)',
  borderRadius: '8px',
  padding: '32px 24px',
})
const disabledCheckboxCss = css({
  marginTop: '20px',
  cursor: 'pointer',
})
const personalSettingText = css({
  display: 'block',
})

const cardDisplayWraperCss = css({
  height: 40,
})

const cardDisplayTextCss = css({
  display: 'inline-block',
  marginBottom: 6,
  lineHeight: 1,
})

const cardDisplaySelectorCss = css({ display: 'flex', alignItems: 'center' })

const cardDisplayLayerTextCss = css({ display: 'inline-block', marginLeft: 6 })

// 2 ~ 12
const VISIBLE_OKR_NODE_LEVEL_OPTIONS: Array<Option> = Array.from({ length: 11 }).map((_, i) => ({
  value: String(i + 2),
  text: String(i + 2),
}))

export const OkrControler: React.VFC<Props> = ({
  existsTerm,
  personalVisibleNodeLevel,
  isShownDisabledOkr,
  showCreateOkrModal,
  onClickActivity,
  onChangeDisabledOkr,
  onChangePersonalVisibleNodeLevel,
}) => {
  useProfiler('OkrControler')
  const { t } = useTranslation()

  const navigate = useNavigate()
  const goToTerm = useCallback(() => navigate(generateAdminOkrTerms()), [navigate])

  const settingRef = useRef<HTMLButtonElement>(null)
  const [isSettingOpen, setIsSettingOpen] = useState(false)
  const closeSetting = useCallback(() => setIsSettingOpen(false), [setIsSettingOpen])

  const changeDisabledOkr = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => onChangeDisabledOkr(target.checked),
    [onChangeDisabledOkr],
  )

  const changePersonalVisibleNodeLevel = useCallback(
    (l: string) => onChangePersonalVisibleNodeLevel(Number(l)),
    [onChangePersonalVisibleNodeLevel],
  )

  const isAdmin = !!useCurrentUser()?.admin

  return (
    <div css={okrControlerCss}>
      <div css={cardDisplayWraperCss}>
        <StyledText css={cardDisplayTextCss} color="text-bk-50" size="xsmall">
          {t('CARD_DISPLAY')}
        </StyledText>
        <div css={cardDisplaySelectorCss}>
          <Select
            value={personalVisibleNodeLevel.toString()}
            options={VISIBLE_OKR_NODE_LEVEL_OPTIONS}
            onClickOption={changePersonalVisibleNodeLevel}
            popoverCss={{ width: '64px' }}
            valueButtonCss={{
              width: '48px',
              height: '24px',
              padding: '5px 0 5px 12px',
            }}
          />
          <StyledText css={cardDisplayLayerTextCss} color="text-bk-100" size="small">
            {t('UP_TO_THE_LAYER')}
          </StyledText>
        </div>
      </div>

      <div css={buttonAreaCss}>
        <button type="button" key={t('ACTIVITY')} onClick={onClickActivity} css={activityCss}>
          <Icon type="history" color={color('text-bk-50')} width={20} height={20} />
        </button>
        <button
          type="button"
          ref={settingRef}
          onClick={() => setIsSettingOpen(true)}
          css={iconButtonCss}
        >
          <Icon type="setting" color={color('text-bk-50')} width={20} height={20} />
        </button>
        {settingRef.current && isSettingOpen && (
          <Drop
            target={settingRef.current}
            align={{ top: 'bottom' }}
            css={settingPopupCss}
            onClickOutside={closeSetting}
          >
            <StyledText size="small" color="text-bk-50" weight="bold" css={personalSettingText}>
              {t('DISPLAY_PERSONAL_SETTINGS')}
            </StyledText>
            <Checkbox
              iconSize="s"
              checked={isShownDisabledOkr}
              onChange={changeDisabledOkr}
              css={disabledCheckboxCss}
            >
              <StyledText color="text-bk-100" oneline>
                {t('SHOW_DISABLED_OKR')}
              </StyledText>
            </Checkbox>
          </Drop>
        )}
        {existsTerm ? (
          <Button type="primary" size="medium" onClick={showCreateOkrModal}>
            {t('ADD_X', { x: t('OKR') })}
          </Button>
        ) : (
          <TooltipNew disabled={isAdmin} title={t('INVALID_CREATE_OKR_WITH_ADMIN')}>
            <Button type="primary" size="medium" disabled={!isAdmin} onClick={goToTerm}>
              {t('CREATE_OF_X', { x: t('OKR_TERM') })}
            </Button>
          </TooltipNew>
        )}
      </div>
    </div>
  )
}

OkrControler.displayName = 'OkrControler'
