import { CSSObject } from '@emotion/react'
import React, { ReactNode, useRef, useState, useEffect, VFC } from 'react'

import { useTranslation } from '../../../i18n'
import { InterpolationWithTheme } from '../../../lib/emotion'
import { border } from '../../../styles/border'
import { color, hex2rgba } from '../../../styles/newColors'
import {
  Avatar as BaseAvatar,
  Props as BaseAvatarProps,
  Size as AvatarSizeType,
  sizes as avatarSize,
} from '../../ui/Avatar'
import { AvatarWithName } from '../../ui/AvatarWithName'
import { LinkMethod } from '../../ui/Link'
import { StyledText } from '../../ui/StyledText'
import { OwnerConfidenceTag } from '../ConfidenceTag/OwnerConfidenceTag'
import { UserConfidenceTag } from '../ConfidenceTag/UserConfidenceTag'

import { UserFragment, KeyResultFragment } from './graphql'

type BaseProps = {
  size: AvatarSizeType
  owner: UserFragment
  usersStyle?: { userIds: Array<string>; css: CSSObject }
  onPopoverOpen?: (isOpened: boolean) => void
  popoverChildren: ReactNode
  popoverDisabled?: boolean
}

type KeyResultBaseProps = BaseProps & {
  displayTotalContributors?: number
  contributors: ReadonlyArray<UserFragment>
  avatarGap?: number
  avatarLinkMethod?: LinkMethod
}

export type ObjectiveProps = Omit<BaseProps, 'popoverChildren'> & {
  termId: string // ユーザーページに飛ばすのに必要
}

export type KeyResultProps = Omit<KeyResultBaseProps, 'popoverChildren'> &
  (
    | {
        popoverDisabled?: false
        termId: string
        keyResult: KeyResultFragment
      }
    | {
        popoverDisabled: true
        termId?: string
        keyResult?: KeyResultFragment
      }
  )

const POPOVER_Z_INDEX = 4
const DISPLAY_CONTRIBUTOR_AVATAR_NUM = 2

export const ObjectiveAvatar: VFC<ObjectiveProps> = ({
  size,
  owner,
  onPopoverOpen,
  termId,
  usersStyle,
}) => (
  <BaseAvatarPopover
    popoverChildren={<ObjectivePopoverContent termId={termId} owner={owner} />}
    size={size}
    owner={owner}
    usersStyle={usersStyle}
    onPopoverOpen={onPopoverOpen}
  />
)

ObjectiveAvatar.displayName = 'ObjectiveAvatar'

export const KeyResultOwner: VFC<
  Omit<KeyResultProps, 'contributors'> & Omit<KeyResultPopoverContentProps, 'contributors'>
> = ({
  size,
  owner,
  usersStyle,
  onPopoverOpen,
  termId,
  keyResult,
  avatarGap,
  avatarLinkMethod,
  popoverDisabled,
}) => (
  <KeyResultBaseAvatarWithContributor
    popoverChildren={
      !popoverDisabled ? (
        <KeyResultPopoverContent
          termId={termId}
          owner={owner}
          contributors={[]}
          keyResult={keyResult}
          avatarLinkMethod={avatarLinkMethod}
        />
      ) : null
    }
    usersStyle={usersStyle}
    size={size}
    owner={owner}
    contributors={[]}
    onPopoverOpen={onPopoverOpen}
    avatarGap={avatarGap}
  />
)

KeyResultOwner.displayName = 'KeyResultOwner'

export const KeyResultContributors: VFC<
  Omit<KeyResultProps, 'owner'> & Omit<KeyResultPopoverContentProps, 'owner'>
> = ({
  size,
  displayTotalContributors = DISPLAY_CONTRIBUTOR_AVATAR_NUM,
  usersStyle,
  contributors,
  onPopoverOpen,
  termId,
  keyResult,
  avatarGap,
  avatarLinkMethod,
}) => (
  <KeyResultBaseAvatarWithContributor
    popoverChildren={
      contributors.length > 0 ? (
        <KeyResultPopoverContent
          termId={termId}
          contributors={contributors}
          keyResult={keyResult}
          avatarLinkMethod={avatarLinkMethod}
        />
      ) : null
    }
    displayTotalContributors={displayTotalContributors}
    usersStyle={usersStyle}
    size={size}
    contributors={contributors}
    onPopoverOpen={onPopoverOpen}
    avatarGap={avatarGap}
  />
)

KeyResultContributors.displayName = 'KeyResultContributors'

export const KeyResultAvatarWithContributor: VFC<KeyResultProps> = ({
  size,
  owner,
  contributors,
  onPopoverOpen,
  termId,
  keyResult,
  avatarGap,
  popoverDisabled,
  avatarLinkMethod,
}) => (
  <KeyResultBaseAvatarWithContributor
    popoverChildren={
      !popoverDisabled ? (
        <KeyResultPopoverContent
          termId={termId}
          owner={owner}
          contributors={contributors}
          keyResult={keyResult}
          avatarLinkMethod={avatarLinkMethod}
        />
      ) : null
    }
    size={size}
    owner={owner}
    contributors={contributors}
    onPopoverOpen={onPopoverOpen}
    avatarGap={avatarGap}
    popoverDisabled={popoverDisabled}
  />
)

KeyResultAvatarWithContributor.displayName = 'KeyResultAvatarWithContributor'

const BaseAvatarPopover: VFC<BaseProps> = ({
  size,
  owner,
  usersStyle,
  onPopoverOpen,
  popoverChildren,
}) => {
  const { width: avatarWidth, height: avatarHeight } = avatarSize[size]

  return (
    <Popover size={size} popoverContent={popoverChildren} onPopoverOpen={onPopoverOpen}>
      <div
        css={{
          width: avatarWidth,
          height: avatarHeight,
          cursor: 'pointer',
        }}
      >
        {/* 責任者アバター */}
        <Avatar
          size={size}
          firstName={owner.firstName}
          lastName={owner.lastName}
          avatarUrl={owner.avatar ? owner.avatar.url : undefined}
          tooltipDisabled
          avatarStyle={
            usersStyle && usersStyle?.userIds.includes(owner.id) ? usersStyle.css : undefined
          }
          isUserDisabled={owner.isDisabled}
        />
      </div>
    </Popover>
  )
}

BaseAvatarPopover.displayName = 'BaseAvatarPopover'

const KeyResultBaseAvatarWithContributor: VFC<
  Omit<KeyResultBaseProps, 'owner'> & Partial<Pick<KeyResultBaseProps, 'owner'>>
> = ({
  size,
  owner,
  usersStyle,
  displayTotalContributors = DISPLAY_CONTRIBUTOR_AVATAR_NUM,
  contributors,
  onPopoverOpen,
  popoverChildren,
  popoverDisabled,
  avatarGap,
}) => {
  const AvatarsComponent = (
    <Avatars
      rootCss={{ cursor: 'pointer' }}
      usersStyle={usersStyle}
      displayTotalContributors={displayTotalContributors}
      size={size}
      owner={owner}
      contributors={contributors}
      avatarGap={avatarGap}
    />
  )

  return (
    <div
      css={{
        position: 'relative',
        display: 'inline-block',
        visibility: !owner && contributors.length === 0 ? 'hidden' : 'visible',
      }}
    >
      {popoverDisabled ? (
        <>{AvatarsComponent}</>
      ) : (
        <Popover size={size} popoverContent={popoverChildren} onPopoverOpen={onPopoverOpen}>
          {AvatarsComponent}
        </Popover>
      )}
    </div>
  )
}

KeyResultBaseAvatarWithContributor.displayName = 'KeyResultBaseAvatarWithContributor'

type AvatarsProps = Omit<KeyResultBaseProps, 'popoverChildren' | 'onPopoverOpen' | 'owner'> & {
  rootCss?: InterpolationWithTheme
  owner?: KeyResultBaseProps['owner']
}

export const Avatars: VFC<AvatarsProps> = ({
  rootCss,
  size,
  owner,
  contributors,
  usersStyle,
  displayTotalContributors,
  avatarGap = 6,
}) => {
  const { width: avatarWidth, height: avatarHeight } = avatarSize[size]
  return (
    <div
      css={[
        {
          position: 'relative',
          width: avatarWidth,
          height: avatarHeight,
          ...(!owner && contributors.length === 0 ? undefined : { cursor: 'pointer' }),
        },
        rootCss,
      ]}
    >
      {/* コントリビュータは指定人数まで表示 */}
      {contributors
        .slice(0, displayTotalContributors)
        .map((c, i) => (
          <Avatar
            key={c.id}
            size={size}
            firstName={c.firstName}
            lastName={c.lastName}
            avatarUrl={c.avatar ? c.avatar.url : undefined}
            tooltipDisabled
            css={{
              boxSizing: 'content-box',
              position: 'absolute',
              left: owner ? `${(i + 1) * avatarGap}px` : `${i * avatarGap}px`,
            }}
            avatarStyle={
              usersStyle && usersStyle?.userIds.includes(c.id) ? usersStyle.css : undefined
            }
            isUserDisabled={c.isDisabled}
          />
        ))
        .reverse()}
      {/* 責任者アバター */}
      {owner && (
        <Avatar
          size={size}
          firstName={owner.firstName}
          lastName={owner.lastName}
          avatarUrl={owner.avatar ? owner.avatar.url : undefined}
          tooltipDisabled
          css={{
            boxSizing: 'content-box',
            position: 'absolute',
          }}
          avatarStyle={
            usersStyle && usersStyle?.userIds.includes(owner.id) ? usersStyle.css : undefined
          }
          isUserDisabled={owner.isDisabled}
        />
      )}
    </div>
  )
}

Avatars.displayName = 'Avatars'

type AvatarProps = BaseAvatarProps & {
  size: AvatarSizeType
}

const Avatar: VFC<AvatarProps> = ({ size, ...props }) => {
  const { width: avatarWidth, height: avatarHeight } = avatarSize[size]
  return (
    <div
      css={{
        ':hover': {
          '& > .hover-avatar': {
            display: 'block',
          },
        },
      }}
    >
      <BaseAvatar
        size={size}
        avatarStyle={{
          border: `1px solid ${color('white-100')}`,
        }}
        hoverStyle={{
          backgroundColor: 'none', // BaseAvatarコンポーネント側で実装されているbackgroundColorを打ち消す
        }}
        {...props}
      />
      <div
        className="hover-avatar"
        css={{
          display: 'none',
          position: 'absolute',
          borderRadius: '50%',
          width: avatarWidth,
          height: avatarHeight,
          backgroundColor: hex2rgba(color('white-100'), 50),
        }}
      />
    </div>
  )
}

Avatar.displayName = 'Avatar'

type ObjectivePopoverContentProps = Pick<ObjectiveProps, 'termId' | 'owner'>
const ObjectivePopoverContent: VFC<ObjectivePopoverContentProps> = ({ termId, owner }) => {
  const { t } = useTranslation()

  return (
    <div css={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
      <StyledText
        color="text-bk-50"
        size="small"
        css={{
          fontWeight: 'bold',
        }}
      >
        {t('OWNER')}
      </StyledText>
      <div css={{ display: 'flex', maxWidth: '240px', alignItems: 'baseline' }}>
        <div css={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
          <AvatarWithName
            size="xxsmall"
            firstName={owner.firstName}
            lastName={owner.lastName}
            avatarUrl={owner.avatar ? owner.avatar.url : undefined}
            linkage={{
              termId,
              userId: owner.id,
            }}
            avatarStyle={{
              display: 'flex',
            }}
            css={{
              margin: '8px 4px 0 0',
              overflow: 'hidden',
            }}
            isUserDisabled={owner.isDisabled}
          />
        </div>
      </div>
    </div>
  )
}

ObjectivePopoverContent.displayName = 'ObjectivePopoverContent'

type KeyResultPopoverContentProps = Pick<KeyResultProps, 'contributors' | 'avatarLinkMethod'> &
  Required<Pick<KeyResultProps, 'termId' | 'keyResult'>> &
  Partial<Pick<KeyResultProps, 'owner'>>

export const KeyResultPopoverContent: VFC<KeyResultPopoverContentProps> = ({
  termId,
  owner,
  contributors,
  keyResult,
  avatarLinkMethod,
}) => {
  const { t } = useTranslation()
  const disabledContributors = contributors.filter((c) => c.isDisabled)
  const unDisabledContributors = contributors.filter((c) => !c.isDisabled)
  const orderContributors = unDisabledContributors.concat(disabledContributors)

  return (
    <>
      {owner && (
        <>
          <StyledText color="text-bk-50" size="small" css={{ fontWeight: 'bold' }}>
            {t('OWNER')}
          </StyledText>
          <div css={{ display: 'flex', maxWidth: '240px', alignItems: 'end' }}>
            <div css={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
              <AvatarWithName
                size="xxsmall"
                firstName={owner.firstName}
                lastName={owner.lastName}
                avatarUrl={owner.avatar ? owner.avatar.url : undefined}
                linkage={{
                  termId,
                  userId: owner.id,
                  method: avatarLinkMethod,
                }}
                avatarStyle={{
                  display: 'flex',
                }}
                css={{
                  margin: '8px 4px 0 0',
                  overflow: 'hidden',
                  height: '20px',
                }}
                isUserDisabled={owner.isDisabled}
              />
            </div>
            {!owner.isDisabled && (
              <OwnerConfidenceTag size="medium" fontColor="text-bk-50" keyResult={keyResult} />
            )}
          </div>
        </>
      )}
      {orderContributors.length > 0 && (
        <StyledText
          color="text-bk-50"
          size="small"
          css={{
            marginTop: owner ? '16px' : undefined,
            fontWeight: 'bold',
            display: 'inline-block',
            whiteSpace: 'nowrap',
          }}
        >
          {t('CONTRIBUTOR')}
        </StyledText>
      )}
      {orderContributors.map((c) => (
        <div key={c.id} css={{ display: 'flex', maxWidth: '240px', alignItems: 'end' }}>
          <div
            css={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            <AvatarWithName
              size="xxsmall"
              firstName={c.firstName}
              lastName={c.lastName}
              avatarUrl={c.avatar ? c.avatar.url : undefined}
              linkage={{
                termId,
                userId: c.id,
                method: avatarLinkMethod,
              }}
              avatarStyle={{
                display: 'flex',
              }}
              css={{
                display: 'flex',
                margin: '8px 4px 0 0',
                height: '20px',
              }}
              isUserDisabled={c.isDisabled}
            />
          </div>
          {!c.isDisabled && (
            <UserConfidenceTag
              size="medium"
              fontColor="text-bk-50"
              keyResult={keyResult}
              user={c}
            />
          )}
        </div>
      ))}
    </>
  )
}

KeyResultPopoverContent.displayName = 'KeyResultPopoverContent'

const GAP_WIDTH = 2

type PopoverProps = {
  size: AvatarSizeType
  children: ReactNode
  popoverContent: ReactNode
  onPopoverOpen?: (isOpened: boolean) => void
}

const Popover: VFC<PopoverProps> = ({ size, children, popoverContent, onPopoverOpen }) => {
  const popoverRef = useRef<HTMLDivElement>(null)
  const [gapHeight, setGapHeight] = useState(0)
  const { width: avatarWidth, height: avatarHeight } = avatarSize[size]
  const [isProtrudedFromScreen, setIsProtrudedFromScreen] = useState<boolean>(false)

  useEffect(() => {
    if (!popoverRef.current) return
    setGapHeight(popoverRef.current.clientHeight)
  }, [])

  return (
    <div
      css={{
        display: 'inline',
        ':hover': {
          '.popover': {
            visibility: 'visible',
          },
        },
      }}
      onMouseEnter={(e) => {
        if (onPopoverOpen) {
          onPopoverOpen(true)
        }
        // currentが取得できなかった場合は判定できないため、暫定的に初期値にする
        if (!popoverRef.current) {
          setIsProtrudedFromScreen(true)
          return
        }
        setIsProtrudedFromScreen(
          popoverRef.current.clientWidth <
            window.innerWidth -
              (e.currentTarget.getBoundingClientRect().left + avatarWidth + GAP_WIDTH),
        )
      }}
      onMouseLeave={() => {
        if (onPopoverOpen) {
          onPopoverOpen(false)
        }
      }}
    >
      {children}
      <div css={{ position: 'relative' }}>
        <div
          css={{
            position: 'absolute',
            height: `${gapHeight}px`,
            width: `${GAP_WIDTH}px`,
            top: `-${avatarHeight}px`,
            left: isProtrudedFromScreen ? `${avatarWidth}px` : `-${GAP_WIDTH}px`,
          }}
        />
        <div
          ref={popoverRef}
          className="popover"
          css={{
            backgroundColor: color('white-100'),
            border: border('simple-30'),
            borderRadius: '4px',
            boxShadow: '0px 8px 16px rgb(34 41 67 / 8%)',
            top: `-${avatarHeight}px`,
            left: isProtrudedFromScreen
              ? `${avatarWidth + GAP_WIDTH}px`
              : `-${(popoverRef.current?.clientWidth || 0) + 2 * GAP_WIDTH}px`,
            position: 'absolute',
            visibility: 'hidden',
            padding: '12px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            zIndex: POPOVER_Z_INDEX,
          }}
        >
          {popoverContent}
        </div>
      </div>
    </div>
  )
}

Popover.displayName = 'Popover'
