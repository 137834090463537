import { useCallback } from 'react'

import { useOkrTermsQuery } from '../../../../../../contexts/graphql'
import { findScreen } from '../../../../../../lib/screen'
import { tracker } from '../../../../../../lib/tracking'
import { KeyResultFragment } from '../../../../../domain/CheckinButton/graphql'
import { useCheckinModal } from '../../../../CheckinModal'
import { FindCheckinSummariesByOkrNodeIdQueryVariables } from '../graphql'

import { CheckinAreaHeader } from './CheckinAreaHeader'

export type CheckinAreaHeaderProps =
  | {
      showCheckinButton: true
      okrNodeId: FindCheckinSummariesByOkrNodeIdQueryVariables['okrNodeId'] | null | undefined
      keyResults: ReadonlyArray<KeyResultFragment> | null | undefined
    }
  | {
      showCheckinButton?: never
      okrNodeId?: never
      keyResults?: never
    }

export const CheckinAreaHeaderContainer: React.VFC<CheckinAreaHeaderProps> = ({
  showCheckinButton,
  okrNodeId,
  keyResults,
}) => {
  // チェックイン
  const [CheckinModal, isOpenCheckinModal, { open, close }] = useCheckinModal()
  const openCheckinModal = useCallback(() => {
    tracker.UserClickOpenCheckin(findScreen(window.location.pathname, window.location.search))
    open()
  }, [open])
  const closeCheckinModal = useCallback(() => close(), [close])
  const executeCheckin = useCallback(() => {
    tracker.UserClickCompletedCheckin(findScreen(window.location.pathname, window.location.search))
    close()
  }, [close])
  const backCheckinModal = useCallback(() => {
    tracker.UserClickBackCheckin(findScreen(window.location.pathname, window.location.search))
  }, [])
  const { data: okrTermsData } = useOkrTermsQuery()

  return (
    <>
      <CheckinAreaHeader
        checkinButtonProps={{
          showCheckinButton: showCheckinButton ?? false,
          keyResults,
          okrTerms: okrTermsData?.okrTerms,
          onClick: openCheckinModal,
        }}
      />
      {isOpenCheckinModal && okrNodeId && (
        <CheckinModal
          isOpen={isOpenCheckinModal}
          onClose={closeCheckinModal}
          onConfirm={executeCheckin}
          onBack={backCheckinModal}
          okrNodeId={okrNodeId}
        />
      )}
    </>
  )
}
