import { Skeleton } from '@resily/geisha'

import { StyledText } from '../../../../ui/StyledText'

import { useStyles } from './Description.styles'

export const DescriptionSkeleton: React.VFC<{ title: string }> = ({ title }) => {
  const styles = useStyles()

  return (
    <div css={styles.root}>
      <div css={styles.header}>
        <StyledText size="small" fontStyle="bold">
          {title}
        </StyledText>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 8, padding: 8 }}>
        <Skeleton text height={14} />
        <Skeleton text height={14} />
        <Skeleton text height={14} />
        <Skeleton text height={14} width={136} />
      </div>
    </div>
  )
}
