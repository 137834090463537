import { Layer, ThemeContext, LayerProps, Box } from 'grommet'
import { useState, useCallback, useEffect, PropsWithoutRef } from 'react'

import { border as borderStyle } from '../../../styles/border'
import { layer } from '../../../styles/layer'

export type Props = LayerProps &
  PropsWithoutRef<JSX.IntrinsicElements['div']> & {
    open?: boolean
    modal?: boolean
    outsideClose?: boolean
    elevation?: string
    contentPad?: string
    top?: number
    border?: string
    header?: JSX.Element
    onClose?: () => void
  }

export const Drawer: React.FC<Props> = ({
  position = 'right',
  open = false,
  modal = true,
  outsideClose = true,
  elevation = 'large',
  contentPad = 'large',
  onClose: onCloseProp,
  border = borderStyle('simple-10'),
  header = null,
  children,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(open)
  const onClose = useCallback(() => {
    setIsOpen(false)
    if (onCloseProp) {
      onCloseProp()
    }
  }, [onCloseProp])

  useEffect(() => {
    setIsOpen(open)
  }, [open])

  if (!isOpen) {
    return null
  }

  return (
    <ThemeContext.Extend
      value={{
        layer: layer('drawer'),
      }}
    >
      <Layer
        modal={modal}
        onClickOutside={() => {
          if (!outsideClose) {
            return
          }
          onClose()
        }}
        position={position}
        full="vertical"
        onEsc={onClose}
        {...props}
      >
        <Box
          fill="vertical"
          background="white"
          elevation={elevation}
          css={{
            border,
          }}
        >
          {header}
          <Box overflow="auto" pad={contentPad} css={{ height: '100%' }}>
            {children}
          </Box>
        </Box>
      </Layer>
    </ThemeContext.Extend>
  )
}

Drawer.displayName = 'Drawer'
