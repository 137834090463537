import { Box, Text } from 'grommet'
import React, { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import { NotesTable } from '../../components/domain/NotesTable'
import { useTranslation } from '../../i18n'
import { useOkrTermLoadable } from '../../lib/domain/useOkrTermLoadable'
import { Screen } from '../../lib/screen'
import { useTracking } from '../../lib/tracking'
import { AuthRouteProps } from '../../types/authRouteProps'
import { generateLabeledNoteList } from '../../urls'

import { useFindNoteLabelByIdLazyQuery, OkrDetailNotesTableFragment } from './graphql'

export const LabeledNoteListContainer: React.FC<AuthRouteProps> = ({ onOkrTermLoaded }) => {
  const { noteLabelId } = useParams<{ noteLabelId: string }>()
  const navigate = useNavigate()
  const termId = useOkrTermLoadable(onOkrTermLoaded, (okrTermId) => {
    navigate(generateLabeledNoteList(okrTermId, noteLabelId ?? ''))
  })

  const [findNoteLabelByIdQuery, labelQuery] = useFindNoteLabelByIdLazyQuery()

  useEffect(() => {
    if (termId && noteLabelId) {
      findNoteLabelByIdQuery({
        variables: { noteLabelId, okrTermId: termId },
      })
    }
  }, [findNoteLabelByIdQuery, noteLabelId, termId])

  if (!labelQuery.data) {
    return null
  }

  return (
    <LabeledNoteList
      name={labelQuery.data.findNoteLabelByID.name}
      notes={labelQuery.data.findNoteLabelByID.notes}
    />
  )
}

LabeledNoteListContainer.displayName = 'LabeledNoteListContainer'

type Props = {
  name: string
  notes: ReadonlyArray<OkrDetailNotesTableFragment>
}

export const LabeledNoteList: React.FC<Props> = ({ name, notes }) => {
  const { t } = useTranslation()
  useTracking(t('LABELED_NOTE_LIST_PAGE_TITLE', { labelName: name }), Screen.NoteLabeledList)

  return (
    <Box pad="large">
      <Text size="large" weight="bold" wordBreak="break-all" margin={{ bottom: 'large' }}>
        {t('X_IN_Y', { x: t('NOTE_LIST'), y: name })}
      </Text>
      <Box margin={{ top: 'large' }}>
        <NotesTable notes={notes} />
      </Box>
    </Box>
  )
}

LabeledNoteList.displayName = 'LabeledNoteList'
