type File = {
  name: string
  url: string
  path: string
}

type FileInput = {
  name: string
  path: string
}

export const convertToFileInput = (file: File): FileInput => ({
  name: file.name,
  path: file.path,
})
