import { Icon } from '../../../../../../../../../ui/Icon'
import { StyledText } from '../../../../../../../../../ui/StyledText'
import { promptMessageIconCss, promptMessageWrapperCss, promptMessageCss } from '../../../../styles'

type CautionMessageBaseProps = {
  children: React.ReactNode
}

export const PromptMessageBase: React.VFC<CautionMessageBaseProps> = ({ children }) => (
  <div css={promptMessageWrapperCss}>
    <Icon type="info" width={12} height={12} css={promptMessageIconCss} />
    <StyledText size="xsmall" css={promptMessageCss}>
      {children}
    </StyledText>
  </div>
)
