import { MouseEventHandler, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { generateAdminCreateFixedAgenda } from '../../../../urls'
import type { FixedAgendaStatus } from '../../api/graphql'
import type { Props as FixedAgendaContentProps } from '../../components/FixedAgendaContent'
import { STATUS_FILTER_ALL } from '../../components/FixedAgendaContent/components/StatusFilterToggle/constants'

import { useFixedAgendaList } from './useFixedAgendaList'
import { useStatusFilterToggle } from './useStatusFilterToggle'

export const useFixedAgendaContent = (): FixedAgendaContentProps => {
  const navigate = useNavigate()

  const statusFilterToggleProps = useStatusFilterToggle()

  const filteredFixedAgendaStatus: FixedAgendaStatus | undefined =
    statusFilterToggleProps.value !== STATUS_FILTER_ALL ? statusFilterToggleProps.value : undefined
  const fixedAgendaListProps = useFixedAgendaList(filteredFixedAgendaStatus)

  const handleClickCreateFixedAgenda = useCallback<MouseEventHandler<HTMLButtonElement>>(() => {
    navigate(generateAdminCreateFixedAgenda())
  }, [navigate])

  return {
    fixedAgendaListProps,
    statusFilterToggleProps,
    onClickCreateFixedAgenda: handleClickCreateFixedAgenda,
  }
}
