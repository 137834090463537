import { css } from '@emotion/react'
import { useMemo } from 'react'

import { fontSize } from '../../../../../styles/font'
import { color } from '../../../../../styles/newColors'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useStyles = () =>
  useMemo(
    () =>
      ({
        showMoreButton: css({
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          padding: '16px 0',
          backgroundColor: color('white-100'),
          borderRadius: '8px',
          color: color('text-bk-50'),
          ...fontSize('medium', 'regular'),
          '&:hover': {
            color: color('resily-orange-100'),
          },
        }),
      } as const),
    [],
  )
