/**
 * jQueryUIのscrollParentの移植版
 * @see https://stackoverflow.com/a/49186677
 *
 * @WARNING
 * DOM構造に依存する、かつ、命令的なアプローチのため、非推奨な関数です。
 */
export const getScrollParent = (node: Node, includeHidden = false): Element | null => {
  const parents = (_node: Element, ps: ReadonlyArray<Element> = []): ReadonlyArray<Element> => {
    if (_node.parentElement === null) return ps
    return parents(_node.parentElement, ps.concat([_node]))
  }

  const getStyle = (_node: Element, prop: string) =>
    getComputedStyle(_node, null).getPropertyValue(prop)
  const getOverflowStyles = (_node: Element) =>
    getStyle(_node, 'overflow') + getStyle(_node, 'overflow-y') + getStyle(_node, 'overflow-x')

  const overflowRegex = includeHidden ? /(auto|scroll|hidden)/ : /(auto|scroll)/
  const isScrollable = (_node: Element) => overflowRegex.test(getOverflowStyles(_node))

  const scrollParent = (_node: Node) => {
    if (!(_node instanceof HTMLElement || _node instanceof SVGElement)) {
      if (!(_node.nodeType === Node.TEXT_NODE && _node.parentElement)) {
        return null
      }
      // eslint-disable-next-line no-param-reassign
      _node = _node.parentElement
    }

    const scrollableParent = parents(_node as Element).find(isScrollable)
    return scrollableParent || document.scrollingElement || document.documentElement
  }

  return scrollParent(node)
}
