import { useCallback, useMemo } from 'react'

import { User } from '../../../contexts/UserContext'
import { useUpdateLanguageMutation, Language } from '../api/graphql'
import { languageMap, Props as LanguageSettingProps } from '../components/LanguageSetting'

export const useLanguageSetting = ({ user }: { user: User }): LanguageSettingProps => {
  const [updateLanguage, { loading }] = useUpdateLanguageMutation()

  const language = useMemo(
    () =>
      Object.keys(languageMap).reduce<string>(
        (l, key) => (languageMap[key] === user?.userSetting.language ? key : l),
        'ja',
      ),
    [user?.userSetting.language],
  )

  const handleUpdateLanguage = useCallback<LanguageSettingProps['updateLanguage']>(
    async (lang: Language) => {
      await updateLanguage({
        variables: { language: lang },
      })
    },
    [updateLanguage],
  )

  return {
    loading,
    language,
    updateLanguage: handleUpdateLanguage,
  }
}
