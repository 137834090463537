import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import * as Yup from 'yup'

import { KeyResultProgressRateFormInput } from '../../../../../../../components/domain/KeyResultProgressRateFormInput'
import { ErrorMessage } from '../../../../../../../components/ui/ErrorMessage'
import { StyledText } from '../../../../../../../components/ui/StyledText'
import { useTranslation } from '../../../../../../../i18n'
import { convertCheckInput } from '../../../../../../../lib/convertCheckInput'
import { convertFullCharToHalf } from '../../../../../../../lib/convertFullCharToHalf'
import { convertFullNumberToHalf } from '../../../../../../../lib/convertFullNumberToHalf'
import { color } from '../../../../../../../styles/newColors'

import type { KeyResult } from './types'

export type Props = {
  keyResult: KeyResult
  onWeightChange: (weight: number) => void
}

type FormValue = { weight: number }

export const KeyResultWeightSettingListItem: React.VFC<Props> = ({ keyResult, onWeightChange }) => {
  const { t } = useTranslation()

  const {
    control,
    formState: { errors },
    watch,
    formState: { isValid },
  } = useForm<FormValue>({
    mode: 'onChange',
    defaultValues: {
      weight: keyResult.weight,
    },
    resolver: yupResolver(
      Yup.object().shape({
        weight: Yup.number().integer(t('ONLY_INTEGER')).typeError(t('ONLY_INTEGER')),
      }),
    ),
  })

  const inputWeight = watch('weight')

  useEffect(() => {
    if (isValid) {
      onWeightChange(inputWeight)
    }
    // onWeightChangeを依存に入れると無限ループに陥るので暫定対応
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputWeight, isValid])

  return (
    <div>
      <div css={{ display: 'flex', justifyContent: 'space-between' }}>
        <div css={{ margin: 'auto 0' }}>
          <div
            css={{ padding: '4px 12px 4px 8px', borderLeft: `4px solid ${color('kr-green-100')}` }}
          >
            <StyledText
              lineHeight="16px"
              size="small"
              css={{ overflow: 'hidden', maxWidth: '221px' }}
              isWrap
              numberOfOmittedLines={3}
            >
              {keyResult.name}
            </StyledText>
          </div>
        </div>
        <div css={{ display: 'flex', margin: 'auto 0' }}>
          <Controller
            name="weight"
            control={control}
            render={({ field: { onChange, value } }) => (
              <KeyResultProgressRateFormInput
                maxWidth={180}
                allowDecimal={false}
                inputSize="s"
                defaultValue={value || 0}
                onChange={(e) => {
                  const convertValue = convertFullCharToHalf(
                    convertFullNumberToHalf(e.target.value),
                  )
                  if (convertCheckInput(convertValue, true)) {
                    onChange(convertValue !== '-' ? Number(convertValue) : convertValue)
                    return
                  }
                  onChange(value || 0)
                }}
              />
            )}
          />
          <StyledText size="small" oneline alignSelf="center" css={{ marginLeft: 4 }}>
            %
          </StyledText>
        </div>
      </div>
      {keyResult.previousWeight && keyResult.previousWeight !== 0 ? (
        <div css={{ display: 'flex', justifyContent: 'space-between' }}>
          <StyledText
            size="small"
            css={{ lineHeight: '20px', marginLeft: '12px' }}
            color="text-bk-50"
          >
            {t('PREVIOUS_SETTING')}：{keyResult.previousWeight}%
          </StyledText>
          <div css={{ marginTop: '6px' }}>
            {errors.weight?.message && <ErrorMessage>{errors.weight?.message}</ErrorMessage>}
          </div>
        </div>
      ) : (
        <div css={{ marginTop: '6px', textAlign: 'right' }}>
          {errors.weight?.message && <ErrorMessage>{errors.weight?.message}</ErrorMessage>}
        </div>
      )}
    </div>
  )
}

KeyResultWeightSettingListItem.displayName = 'KeyResultWeightSettingListItem'
