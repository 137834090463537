import { Global, css, CSSObject } from '@emotion/react'
import { useProfiler } from '@sentry/react'
import { Box } from 'grommet'
import { VFC } from 'react'

import { useOkrModal } from '../../../components/standalone/OkrModal'
import { AvatarWithName } from '../../../components/ui/AvatarWithName'
import { DateTime } from '../../../components/ui/DateTime'
import { InfiniteScroll } from '../../../components/ui/InfiniteScroll'
import { Link } from '../../../components/ui/Link'
import { StyledText } from '../../../components/ui/StyledText'
import { useCurrentUser } from '../../../contexts/UserContext'
import { useTranslation } from '../../../i18n'
import { isIncludedUser } from '../../../lib/domain/keyResult/keyResult'
import { message as progressRateComment } from '../../../lib/domain/keyResultProgressRateHistory'
import { Screen } from '../../../lib/screen'
import { useTracking } from '../../../lib/tracking'
import { border } from '../../../styles/border'
import { color } from '../../../styles/newColors'
import { KeyResultProgressRateItem, useKeyResultProgressRateHistoriesQuery } from '../graphql'

export type Props = {
  termId: string
  okrNodeIds: ReadonlyArray<string>
  selectedGroupIds: ReadonlyArray<string>
  selectedUserIds: ReadonlyArray<string>
}

// fixme: https://github.com/Resily/resily-new/issues/3464
// 以下の理由から、多めに取得しておかないとスクロールが反応しないことがある
// 1. 無限スクロールコンポーネントは最後までスクロールしないと反応しない
// 2. 無限スクロールコンポーネントのheightを画面に合うよう可変にしている
const COUNT_PER_PAGE = 50 as const

export const ActivityView: VFC<Props> = ({
  termId,
  okrNodeIds,
  selectedGroupIds,
  selectedUserIds,
}) => {
  useProfiler('ActivityView')

  const { t } = useTranslation()

  const currentUser = useCurrentUser()

  useTracking(t('OKR_ACTIVITY_PAGE_TITLE'), Screen.OkrMapActivity)
  const { OkrModal, openOkrModalWithKeyResultDrawer } = useOkrModal()

  const { data, fetchMore, loading } = useKeyResultProgressRateHistoriesQuery({
    variables: {
      userIds: selectedUserIds,
      groupIds: selectedGroupIds,
      okrNodeIds,
      termId,
      first: COUNT_PER_PAGE,
    },
  })
  if (data == null) return null
  const { pageInfo, edges: activities } = data.keyResultProgressRateHistories

  const maxLength = Math.max(
    ...activities.map((activity) =>
      Math.max(
        activity.node.beforeProgressRate.toString().length,
        activity.node.progressRate.toString().length,
      ),
    ),
  )
  const historyWidth = Math.min(160, Math.max(80, maxLength * 20))
  const commentWidth = 432 - historyWidth

  const cssHistory = css({
    display: 'flex',
    flex: `0 0 ${historyWidth}px`,
    alignItems: 'center',
    marginRight: '18px',
  })

  const cssKeyResults = css({
    display: 'flex',
    flex: '0 0 240px',
    alignItems: 'end',
    marginRight: '32px',
  })

  const cssComment = css({
    display: 'flex',
    flex: `0 0 ${commentWidth}px`,
    alignItems: 'end',
    marginRight: '32px',
  })

  const cssUpdatedAt = css({
    display: 'flex',
    flex: '0 0 80px',
    alignItems: 'end',
    marginRight: '32px',
  })

  const cssAuthor = css({
    display: 'flex',
    flex: '0 0 98px',
    alignItems: 'end',
  })

  const cssHistoryText = css({
    width: `${historyWidth - 50}px`,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  })

  const cssHeader = css({
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  })

  return (
    <Box
      css={{
        position: 'relative',
        zIndex: 0,
        width: '1044px',
        marginLeft: '78px',
        backgroundColor: color('white-100'),
        border: border('simple-30'),
      }}
    >
      <Global styles={{ main: { backgroundColor: color('background-bk-5') } }} />
      <div
        css={{
          position: 'absolute',
          zIndex: 1,
          display: 'flex',
          alignItems: 'center',
          width: `calc(100% - 64px)`,
          marginLeft: '32px',
          marginRight: '32px',
          padding: '28px 0px 16px',
          backgroundColor: color('white-100'),
          borderBottom: border('simple-30'),
        }}
      >
        <div css={cssHistory}>
          <HeaderText style={{ ...cssHeader, width: `${historyWidth}px` }}>
            {t('HISTORY')}
          </HeaderText>
        </div>
        <div css={cssKeyResults}>
          <HeaderText style={{ ...cssHeader, width: '240px' }}>{t('KR')}</HeaderText>
        </div>
        <div
          css={{
            ...cssComment,
          }}
        >
          <HeaderText style={{ ...cssHeader, width: `${commentWidth}px` }}>
            {t('REASON')}
          </HeaderText>
        </div>
        <div css={cssUpdatedAt}>
          <HeaderText style={{ ...cssHeader, width: '80px' }}>{t('UPDATED_ON')}</HeaderText>
        </div>
        <div css={cssAuthor}>
          <HeaderText style={{ ...cssHeader, width: '98px' }}>
            {t('KEY_RESULT_PROGRESS_RATE_HISTORY_AUTHOR')}
          </HeaderText>
        </div>
      </div>
      <InfiniteScroll
        css={{ padding: '60px 32px 0' }}
        height="calc(100% - 54px)"
        needMore={data?.keyResultProgressRateHistories.pageInfo.hasNextPage}
        onReachEnd={async () => {
          if (!pageInfo.hasNextPage || loading) return
          await fetchMore({
            variables: {
              userIds: selectedUserIds,
              groupIds: selectedGroupIds,
              okrNodeIds,
              termId,
              first: COUNT_PER_PAGE,
              after: pageInfo.endCursor,
            },
          })
        }}
      >
        {activities.map((activity) => (
          <div
            key={activity.node.id}
            css={{
              display: 'flex',
              alignItems: 'center',
              minHeight: '64px',
              width: '100%',
              padding: '12px 0',
              borderBottom: border('simple-10'),

              ':hover': {
                backgroundColor: color('hover-background-bk-5'),
              },
            }}
          >
            <div
              css={{
                ...cssHistory,
                height: '40px',
                borderLeft: `4px solid ${color('kr-green-100')}`,
              }}
            >
              <Box css={{ marginLeft: '14px' }}>
                <Box direction="row">
                  <StyledText css={cssHistoryText}>{activity.node.progressRate}</StyledText>
                  {activity.node.progressRate !== activity.node.beforeProgressRate ? (
                    <StyledText
                      color={
                        activity.node.progressRate > activity.node.beforeProgressRate
                          ? 'resily-orange-100'
                          : 'text-bk-50'
                      }
                      css={{
                        transform:
                          activity.node.progressRate > activity.node.beforeProgressRate
                            ? undefined
                            : 'rotate(-180deg)',
                      }}
                    >
                      ↑
                    </StyledText>
                  ) : null}
                </Box>
                <StyledText color="text-bk-30" css={cssHistoryText}>
                  {activity.node.beforeProgressRate}
                </StyledText>
              </Box>
            </div>

            <div css={cssKeyResults}>
              <Link
                onClick={() => {
                  openOkrModalWithKeyResultDrawer(
                    activity.node.keyResult.node.objective.id,
                    activity.node.keyResult.id,
                    Screen.OkrMapActivity,
                    isIncludedUser(activity.node.keyResult, currentUser),
                  )
                }}
                css={{
                  ':hover': {
                    color: color('resily-orange-100'),
                    textDecoration: 'underline',
                  },
                }}
              >
                <StyledText css={{ overflowWrap: 'anywhere' }}>
                  {activity.node.keyResult.name}
                </StyledText>
              </Link>
            </div>
            <div css={cssComment}>
              <StyledText
                color={
                  activity.node.item === KeyResultProgressRateItem.ProgressRateAggregation
                    ? 'text-bk-30'
                    : 'text-bk-100'
                }
                css={{ whiteSpace: 'pre-wrap', overflowWrap: 'anywhere' }}
              >
                {progressRateComment(t, activity.node)}
              </StyledText>
            </div>
            <div css={cssUpdatedAt}>
              <StyledText>
                <DateTime datetime={new Date(activity.node.createdAt)} withoutTime />
              </StyledText>
            </div>
            <div
              css={{
                ...cssAuthor,
                ...cssHeader,
              }}
            >
              <AvatarWithName
                css={{ width: '98px' }}
                size="small"
                nameFontSize="small"
                linkage={{
                  termId,
                  userId: activity.node.user.id,
                }}
                firstName={activity.node.user.firstName}
                lastName={activity.node.user.lastName}
                avatarUrl={activity.node.user.avatar?.url}
                isUserDisabled={activity.node.user.isDisabled}
              />
            </div>
          </div>
        ))}
      </InfiniteScroll>
      {OkrModal}
    </Box>
  )
}

ActivityView.displayName = 'ActivityView'

const HeaderText = ({ children, style }: { children: string; style: CSSObject }) => (
  <StyledText color="text-bk-50" css={[css(style), { fontWeight: 600 }]}>
    {children}
  </StyledText>
)
