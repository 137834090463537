import { Box } from 'grommet'
import * as React from 'react'
import { useEffect } from 'react'

import { PageContent } from '../../components/pageContent'
import { Button } from '../../components/ui/ButtonDeprecated'
import { useTranslation } from '../../i18n'
import { Screen } from '../../lib/screen'
import { useTracking } from '../../lib/tracking'
import * as colors from '../../styles/colors'
import { AuthRouteProps } from '../../types/authRouteProps'

import { PersonalNoteLabelListItem } from './PersonalNoteLabelListItem'
import {
  NoteLabelPartsFragment,
  useCreateNoteLabelMutation,
  useDeleteNoteLabelMutation,
  useNoteLabelsQuery,
  useUpdateNoteLabelMutation,
} from './graphql'

export type ContainerProps = AuthRouteProps

export const PersonalNoteLabelListContainer: React.FC<ContainerProps> = ({ onOkrTermLoaded }) => {
  const { data, refetch } = useNoteLabelsQuery()
  const [createNoteLabelMutation] = useCreateNoteLabelMutation()
  const [updateNoteLabelMutation] = useUpdateNoteLabelMutation()
  const [deleteNoteLabelMutation] = useDeleteNoteLabelMutation()
  useEffect(() => {
    onOkrTermLoaded(null)
  }, [onOkrTermLoaded])
  if (!data) {
    return null
  }

  const createNoteLabel = (name: string, color: string) =>
    createNoteLabelMutation({ variables: { name, color } })
      .then(() => refetch())
      .catch(() => {})
  const updateNoteLabel = (id: string, name: string, color: string) =>
    updateNoteLabelMutation({ variables: { id, name, color } })
      .then(() => refetch())
      .catch(() => {})
  const deleteNoteLabel = (id: string) =>
    deleteNoteLabelMutation({ variables: { id } })
      .then(() => refetch())
      .catch(() => {})

  return (
    <PersonalNoteLabelList
      labels={data.noteLabels}
      createNoteLabel={createNoteLabel}
      updateNoteLabel={updateNoteLabel}
      deleteNoteLabel={deleteNoteLabel}
    />
  )
}

PersonalNoteLabelListContainer.displayName = 'PersonalNoteLabelListContainer'

type Props = {
  labels: ReadonlyArray<NoteLabelPartsFragment>
  createNoteLabel: (name: string, color: string) => Promise<unknown>
  updateNoteLabel: (id: string, name: string, color: string) => Promise<unknown>
  deleteNoteLabel: (id: string) => Promise<unknown>
}

export const PersonalNoteLabelList: React.FC<Props> = ({
  labels,
  createNoteLabel,
  updateNoteLabel,
  deleteNoteLabel,
}) => {
  const { t } = useTranslation()
  useTracking(t('PERSONAL_NOTE_LABEL_LIST_PAGE_TITLE'), Screen.PersonalNoteLabeledList)

  return (
    <PageContent
      breadcrumbs={undefined}
      layout={{
        pad: '20px',
        flex: false,
      }}
    >
      <Box width={{ max: '19%' }}>
        <Button color="main" onClick={() => createNoteLabel('Empty', '#cccccc')}>
          {t('ADD_X', { x: t('LABEL') })}
        </Button>
      </Box>
      <Box as="ul" margin={{ top: '10px' }} border={{ color: colors.border, side: 'vertical' }}>
        <Box as="span" border={{ color: colors.border, side: 'top' }} />
        {labels.map((e) => (
          <PersonalNoteLabelListItem
            updateNoteLabel={updateNoteLabel}
            deleteNoteLabel={deleteNoteLabel}
            key={e.id}
            label={e}
          />
        ))}
      </Box>
    </PageContent>
  )
}

PersonalNoteLabelList.displayName = 'PersonalNoteLabelList'
