import { Box } from 'grommet'
import { FC, Fragment, useCallback, useEffect, useRef, useState, forwardRef } from 'react'

import { useTranslation } from '../../../i18n'
import { trimInvalidCharacter } from '../../../lib/string'
import { color } from '../../../styles/newColors'
import { AvatarWithName } from '../../ui/AvatarWithName'
import { Button } from '../../ui/Button'
import { Icon } from '../../ui/Icon'
import { RichTextEditor, Props as RichTextEditorProps, EditorRef } from '../../ui/RichTextEditor'
import { StyledText } from '../../ui/StyledText'
import { TextButton } from '../../ui/TextButton'

import { CheckinSummaryFragment } from './graphql'

export type Props = {
  summary: CheckinSummaryFragment
  onClickSave: (treeJson: string, plainText: string) => void
  drawer?: boolean
}

type SummaryMode = 'viewMode' | 'editMode'

export const SwitchingEditor: FC<Props> = ({ summary, onClickSave, drawer = false }) => {
  const { t } = useTranslation()
  const editIconType = 'editOutline'
  const editorRef = useRef<EditorRef>(null)
  const [isThereSummaryDescription, setIsThereSummaryDescription] = useState(
    summary.description ? !!trimInvalidCharacter(summary.description.plainText) : false,
  )
  const [summaryMode, setSummaryMode] = useState<SummaryMode>('viewMode')
  const [body, setBody] = useState(summary.description?.treeJson)
  const [plainText, setPlainText] = useState(summary.description?.plainText)

  const resetInitialValue = useCallback(() => {
    if (!editorRef.current) {
      return
    }
    editorRef.current.resetInitialValue()
  }, [])

  return (
    <Fragment>
      <div css={{ display: 'flex', alignItems: 'center', marginBottom: '12px' }}>
        <StyledText css={{ lineHeight: '32px', fontSize: '20px' }}>{t('MEETING_NOTES')}</StyledText>
        <div
          data-mode={summaryMode}
          css={{
            display: 'flex',
            alignItems: 'center',
            marginLeft: '24px',
            justifyContent: 'space-between',
            '&[data-mode="editMode"]': {
              display: 'none',
            },
          }}
        >
          <div css={{ display: 'flex', width: drawer ? '94%' : '98%' }}>
            <StyledText color="text-bk-30" css={{ whiteSpace: 'nowrap' }}>
              {t('LAST_UPDATED_AT')} : {t('DATE_TIME', { date: new Date(summary.updatedAt) })}
            </StyledText>
            {summary.changer && (
              <div css={{ marginLeft: '10px', maxWidth: drawer ? '50%' : '82%' }}>
                <AvatarWithName
                  firstName={summary.changer.firstName}
                  lastName={summary.changer.lastName}
                  avatarUrl={summary.changer.avatar?.url}
                  size="xsmall"
                  newColor="text-bk-30"
                  linkage={
                    summary.changer.id
                      ? {
                          userId: summary.changer.id,
                        }
                      : undefined
                  }
                  // TODO: https://github.com/Resily/resily-new/issues/7372 で検討
                  // 現在は暫定的に無効化ユーザー表示をさせないため明示的にfalseを渡す
                  isUserDisabled={false}
                />
              </div>
            )}
          </div>
          {isThereSummaryDescription && (
            <button
              type="button"
              css={{ display: 'flex', alignItems: 'center' }}
              onClick={() => setSummaryMode('editMode')}
            >
              <Icon
                type={editIconType}
                color={color('text-bk-50')}
                css={{ display: 'block' }}
                width={12}
                height={12}
              />
              <StyledText
                color="text-bk-50"
                lineHeight="19px"
                css={{ marginLeft: '4px', width: '29px' }}
              >
                {t('EDITION')}
              </StyledText>
            </button>
          )}
        </div>
      </div>
      <CheckinMeetingRichTextEditorWrapper
        id={summary.id}
        ref={editorRef}
        initialValueJSON={summary.description?.treeJson}
        summaryMode={summaryMode}
        onChange={(json, text) => {
          setBody(json)
          setPlainText(text)
        }}
        onChangeSummaryMode={() => setSummaryMode('editMode')}
      />
      <Bottom
        summaryMode={summaryMode}
        isThereSummaryDescription={isThereSummaryDescription}
        onClickCancel={() => {
          setBody(summary.description?.treeJson)
          setPlainText(summary.description?.plainText)
          resetInitialValue()
          setSummaryMode('viewMode')
        }}
        onClickSave={() => {
          onClickSave(body || '', plainText || '')
          setSummaryMode('viewMode')
          setIsThereSummaryDescription(!!trimInvalidCharacter(plainText))
        }}
        onClickStart={() => {
          setSummaryMode('editMode')
        }}
      />
    </Fragment>
  )
}

SwitchingEditor.displayName = 'SwitchingEditor'

type BottomProps = {
  summaryMode: SummaryMode
  isThereSummaryDescription: boolean
  onClickCancel: () => void
  onClickSave: () => void
  onClickStart: () => void
}
const Bottom: FC<BottomProps> = ({
  summaryMode,
  isThereSummaryDescription,
  onClickSave,
  onClickCancel,
  onClickStart,
}) => {
  const { t } = useTranslation()
  return (
    <Fragment>
      {summaryMode === 'editMode' && (
        <Box direction="row" justify="end" css={{ flexBasis: '218px', marginTop: '20px' }}>
          <TextButton color="text-bk-50" onClick={onClickCancel} css={{ marginRight: '8px' }}>
            {t('CANCEL')}
          </TextButton>
          <Button newColor="resily-orange-100" weight="normal" size="s" onClick={onClickSave}>
            {t('SAVE')}
          </Button>
        </Box>
      )}

      {!isThereSummaryDescription && (
        <Button
          data-mode={summaryMode}
          newColor="resily-orange-100"
          size="s"
          weight="normal"
          onClick={onClickStart}
          css={{
            display: 'block',
            marginTop: '16px',
            '&[data-mode="editMode"]': {
              display: 'none',
            },
          }}
        >
          {t('CHECKIN_START')}
        </Button>
      )}
    </Fragment>
  )
}

Bottom.displayName = 'Bottom'

type WrapperProps = {
  id: string
  initialValueJSON: string | undefined
  summaryMode: SummaryMode
  onChangeSummaryMode: () => void
  onChange?: (json: string, plainText: string) => void
} & RichTextEditorProps

const CheckinMeetingRichTextEditorWrapper = forwardRef<EditorRef, WrapperProps>(
  ({ id, initialValueJSON, summaryMode, onChangeSummaryMode, onChange, ...props }, ref) => {
    const { t } = useTranslation()
    const editorBoxRef = useRef<HTMLDivElement>(null)
    const editorMaxHeight = 246
    const [isShowAll, setIsShowAll] = useState(true)

    useEffect(() => {
      if (!editorBoxRef.current) return

      setIsShowAll(editorBoxRef.current.getBoundingClientRect().height < editorMaxHeight)

      if (summaryMode === 'editMode') {
        setIsShowAll(true)
      }
    }, [editorBoxRef, summaryMode])

    return (
      <div
        ref={editorBoxRef}
        css={{
          whiteSpace: 'pre-wrap',
          wordWrap: 'break-word',
        }}
      >
        <RichTextEditor
          id={id}
          autoFocus={false}
          ref={ref}
          css={{
            maxHeight: isShowAll ? 'auto' : `${editorMaxHeight}px`,
            // MEMO: Homeのアクティビティタブで開くとエディターの高さが意図しないものになるため、設定
            minHeight: 88,
            overflow: isShowAll ? 'auto' : 'hidden',
            backgroundColor: undefined,
          }}
          initialValueJSON={initialValueJSON}
          editorProps={{
            placeholder: t('CHECKIN_MEETING_PLACEHOLDER'),
          }}
          onChangeSummaryMode={onChangeSummaryMode}
          onChange={onChange}
          {...props}
        />
        {!isShowAll && (
          <TextButton color="text-bk-80" onClick={() => setIsShowAll(true)}>
            <StyledText
              css={{
                lineHeight: '22px',
                fontSize: '14px',
                fontWeight: 'bold',
                textDecorationLine: 'underline',
              }}
            >
              ...{t('CHECKIN_MEETING_EXPAND')}
            </StyledText>
          </TextButton>
        )}
      </div>
    )
  },
)

CheckinMeetingRichTextEditorWrapper.displayName = 'CheckinMeetingRichTextEditorWrapper'
