import { css } from '@emotion/react'
import { BoxProps, Text, Button, Box } from 'grommet'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { NoteThreePoints } from '../../components/domain/NoteThreePoints'
import { OkrToolTip } from '../../components/domain/OkrTooltip'
import { AvatarWithName } from '../../components/ui/AvatarWithName'
import { BorderdButton } from '../../components/ui/BorderdButton'
import { DeleteNoteModal } from '../../components/ui/DeleteNoteModal'
import { Link } from '../../components/ui/Link'
import { StyledText } from '../../components/ui/StyledText'
import { TextButton } from '../../components/ui/TextButton'
import { useTranslation } from '../../i18n'
import { formatDateInput } from '../../lib/date'
import { border } from '../../styles/border'
import { color } from '../../styles/newColors'
import { generateNote } from '../../urls'

import { NoteFragment, ObjectiveFragment, KeyResultFragment } from './graphql'

export type Props = BoxProps & {
  notes: ReadonlyArray<NoteFragment>
  objective: ObjectiveFragment
  keyResults: ReadonlyArray<KeyResultFragment>
  createNote: () => void
  deleteNote: (noteId: string) => void
}

export const OkrNoteListView: React.FC<Props> = ({
  notes,
  objective,
  keyResults,
  createNote,
  deleteNote,
}) => {
  const { t } = useTranslation()
  return (
    <Box
      background={color('white-100')}
      width="100%"
      css={{
        padding: '28px 32px 36px',
        border: border('simple-30'),
        borderRadius: '4px',
      }}
    >
      <Box direction="row" align="center" justify="between" css={{ marginBottom: '37px' }}>
        <Text css={{ lineHeight: '33px', fontSize: '24px', fontWeight: 'bold' }}>
          {`${t('OKR')}${t('NOTE')}`}
        </Text>
        {notes.length > 0 ? (
          <Box direction="row" align="center">
            <TextButton icon="plus" color="text-bk-50" onClick={createNote}>
              {t('CREATE_NOTE')}
            </TextButton>
          </Box>
        ) : null}
      </Box>
      {notes.length === 0 ? (
        <NoNoteList createNote={createNote} />
      ) : (
        <OkrNoteList
          notes={notes.concat().sort((a, b) => (a.updatedAt < b.updatedAt ? 1 : -1))}
          objective={objective}
          keyResults={keyResults}
          deleteNote={deleteNote}
        />
      )}
    </Box>
  )
}

OkrNoteListView.displayName = 'OkrNoteListView'

type OkrNoteListProps = BoxProps & {
  notes: ReadonlyArray<NoteFragment>
  objective: ObjectiveFragment
  keyResults: ReadonlyArray<KeyResultFragment>
  deleteNote: (noteId: string) => void
}

const cssTitle = css({
  display: 'flex',
  width: '100%',
  flex: 'auto',
  marginRight: '16px',
  alignItems: 'center',
})

const cssDate = css({
  flex: '0 0 75px',
  minWidth: 75,
  alignItems: 'end',
  marginRight: '32px',
})

const cssAuthor = css({
  flex: '0 0 114px',
  width: 114,
  alignItems: 'end',
  marginRight: '22px',
  overflowX: 'hidden',
})

const cssThreePoints = css({
  flex: '0 0 32px',
  minWidth: 32,
  alignItems: 'end',
  marginRight: '14px',
})

const OkrNoteList: React.FC<OkrNoteListProps> = ({ notes, objective, keyResults, deleteNote }) => {
  const { t } = useTranslation()
  return (
    <section
      css={{
        backgroundColor: color('white-100'),
      }}
    >
      {/* header */}
      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          paddingBottom: '16px',
          borderBottom: border('simple-10'),
          backgroundColor: color('white-100'),
        }}
      >
        <div css={cssTitle}>
          <StyledText size="small" color="text-bk-50" css={{ fontWeight: 600 }}>
            {t('NOTE_NAME')}
          </StyledText>
        </div>
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div css={cssDate}>
            <StyledText size="small" color="text-bk-50" css={{ fontWeight: 600 }}>
              {t('CREATED_ON')}
            </StyledText>
          </div>
          <div css={cssDate}>
            <StyledText size="small" color="text-bk-50" css={{ fontWeight: 600 }}>
              {t('UPDATED_ON')}
            </StyledText>
          </div>
          <div css={cssAuthor}>
            <StyledText size="small" color="text-bk-50" css={{ fontWeight: 600 }}>
              {t('AUTHOR')}
            </StyledText>
          </div>
          <div css={cssThreePoints} />
        </div>
      </div>
      {/* body */}
      <div
        css={{
          overflowY: 'scroll',
          height: '338px',
          borderBottom: border('simple-10'),
        }}
      >
        {notes.map((note) => (
          <NoteListItem
            key={note.id}
            note={note}
            objective={objective}
            keyResults={keyResults}
            deleteNote={deleteNote}
          />
        ))}
      </div>
    </section>
  )
}

OkrNoteList.displayName = 'OkrNoteList'

type NoteListItemProps = {
  note: NoteFragment
  objective: ObjectiveFragment
  keyResults: ReadonlyArray<KeyResultFragment>
  deleteNote: (noteId: string) => void
}

export const NoteListItem: React.FC<NoteListItemProps> = ({
  note,
  objective,
  keyResults,
  deleteNote,
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [isHover, setIsHover] = React.useState(false)
  const [isOpened, setIsOpend] = React.useState(false)

  const resourceKeyResult = keyResults.find((kr) =>
    kr.notes.some((krNote) => krNote.id === note.id),
  )

  const cssOkrButton = css({
    display: 'flex',
    flex: '0 0 122px',
    alignItems: 'center',
  })

  return (
    <div
      key={note.id}
      css={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        padding: '8px 0px',
        borderBottom: border('simple-10'),

        ':hover': {
          backgroundColor: color('hover-background-bk-5'),
        },
      }}
      onMouseEnter={() => {
        setIsHover(true)
      }}
      onMouseLeave={() => {
        setIsHover(false)
      }}
    >
      <div css={{ display: 'flex', justifyContent: 'space-between', ...cssTitle }}>
        <div
          css={{
            borderLeft: `4px solid ${color(
              note.resource.__typename === 'Objective' ? 'objective-blue-100' : 'kr-green-100',
            )}`,
            padding: '8px',
          }}
        >
          <Link
            href={generateNote(note.id)}
            css={{
              cursor: 'pointer',

              ':hover': {
                color: color('resily-orange-100'),
                textDecorationLine: 'underline',
              },
            }}
          >
            <StyledText css={{ overflowWrap: 'anywhere' }}>{note.title}</StyledText>
          </Link>
        </div>
        <span css={cssOkrButton}>
          <OkrToolTip
            objective={{
              name: objective.name,
              keyResults,
            }}
            krID={resourceKeyResult && resourceKeyResult.id}
          >
            <div css={{ zIndex: 2 }}>
              <BorderdButton isShow={isHover} text={t('SHOW_OKR')} />
            </div>
          </OkrToolTip>
        </span>
      </div>
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <div css={cssDate}>
          <StyledText>{formatDateInput(note.createdAt)}</StyledText>
        </div>
        <div css={cssDate}>
          <StyledText>{formatDateInput(note.updatedAt)}</StyledText>
        </div>
        <div css={cssAuthor}>
          <AvatarWithName
            size="small"
            nameFontSize="small"
            firstName={note.author.firstName}
            lastName={note.author.lastName}
            avatarUrl={note.author.avatar?.url}
            linkage={{
              userId: note.author.id,
            }}
            isUserDisabled={note.author.isDisabled}
          />
        </div>
        <div css={cssThreePoints}>
          <NoteThreePoints
            selectMenu={(menu) => {
              if (menu === 'edit') {
                navigate(generateNote(note.id))
                return
              }
              if (menu === 'delete') {
                setIsOpend(true)
              }
            }}
          />
          <DeleteNoteModal
            isOpened={isOpened}
            noteType="NOTE"
            noteTitle={note.title}
            onClickDelete={() => {
              deleteNote(note.id)
              setIsOpend(false)
            }}
            onClickCancel={() => setIsOpend(false)}
            onClickOutside={() => setIsOpend(false)}
          />
        </div>
      </div>
    </div>
  )
}

NoteListItem.displayName = 'NoteListItem'

type NotDataProps = {
  createNote: () => void
}

const NoNoteList: React.FC<NotDataProps> = ({ createNote }) => {
  const { t } = useTranslation()
  return (
    <Box align="center" css={{ marginTop: '54px', marginBottom: '64px' }}>
      <StyledText size="large" css={{ marginBottom: '20px' }}>
        {t('OBJECTIVE_NOTE_IS_EMPTY')}
      </StyledText>
      <Button
        css={{
          border: border('simple-30'),
          borderRadius: '4px',
          padding: '10px 16px',
        }}
        onClick={createNote}
      >
        <StyledText>{t('CREATE_OF_X', { x: t('NOTE') })}</StyledText>
      </Button>
    </Box>
  )
}

NoNoteList.displayName = 'NoNoteList'
