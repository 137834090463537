import { ActionPlanDetailCard } from '../../components/domain/ActionPlanDetailCard'
import { ActionPlanOption } from '../../components/domain/ActionPlanOption'
import { PageContent } from '../../components/pageContent'
import { color } from '../../styles/newColors'
import { AuthRouteProps } from '../../types/authRouteProps'

import { useInjection } from './hooks'

export const ActionPlanDetailContainer: React.FC<AuthRouteProps> = (props) => {
  const data = useInjection(props)

  if (!data) {
    return null
  }

  const { actionPlan, deleteActionPlan, breadcrumbs, onActionPlanEdit } = data

  return (
    <PageContent
      breadcrumbs={breadcrumbs}
      layout={{
        css: {
          backgroundColor: color('background-bk-5'),
          padding: '60px max(40px, calc((100% - 1045px)/2)) ',
          minWidth: '1125px',
        },
      }}
    >
      <div
        css={{
          display: 'flex',
        }}
      >
        <div
          css={{
            minWidth: '805px',
          }}
        >
          <ActionPlanDetailCard
            onEdit={onActionPlanEdit}
            actionPlan={actionPlan}
            deleteActionPlan={deleteActionPlan}
          />
        </div>
        <div css={{ marginTop: '9px', marginLeft: '8px', minWidth: '232px' }}>
          <ActionPlanOption actionPlanId={actionPlan.id} />
        </div>
      </div>
    </PageContent>
  )
}

ActionPlanDetailContainer.displayName = 'ActionPlanDetailContainer'
