import { ReactElement } from 'react'

import { useTranslation } from '../../../i18n/index'
import { border } from '../../../styles/border'
import { color } from '../../../styles/newColors'
import { StyledText } from '../../ui/StyledText'
import { Tooltip } from '../../ui/Tooltip'

export type Props = {
  children: ReactElement
  krID?: string
  objective?: {
    name: string
    keyResults: ReadonlyArray<{ id: string; name: string }>
  }
}

export const OkrToolTip: React.FC<Props> = (props) => {
  const { children, krID, objective } = props

  const { t } = useTranslation()

  return (
    <Tooltip
      dropAlign={{ top: 'bottom', left: 'left' }}
      customized
      title={
        objective ? (
          <div
            css={{
              backgroundColor: color('white-100'),
              border: border('simple-30'),
              borderRadius: 4,
              fontSize: '12px',
              padding: '4px 8px',
              maxWidth: 200,
              width: 'auto',
              marginTop: '4px',
              lineHeight: '16px',
            }}
          >
            <div
              css={{
                fontWeight: 'bold',
                paddingTop: 16,
                paddingBottom: 8,
                borderBottom: border('simple-30'),
              }}
            >
              <StyledText
                size="large"
                css={{
                  display: 'block',
                  paddingLeft: 24,
                  paddingRight: 24,
                  fontWeight: 'bold',
                }}
              >
                {objective.name}
              </StyledText>
            </div>
            <div
              css={{
                paddingLeft: 24,
                paddingRight: 24,
                paddingBottom: 24,
              }}
            >
              {objective.keyResults.map((k: { id: string; name: string }) => (
                <div
                  key={k.id}
                  css={{
                    marginTop: 16,
                    paddingLeft: 8,
                    paddingBottom: 1,
                    backgroundColor: krID && krID === k.id ? color('kr-green-20') : undefined,
                  }}
                >
                  <StyledText>{k.name}</StyledText>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div
            css={{
              backgroundColor: color('white-100'),
              border: border('simple-30'),
              borderRadius: 4,
              fontSize: '12px',
              padding: '4px 8px',
              maxWidth: 200,
              width: 'auto',
              marginTop: '4px',
              lineHeight: '16px',
            }}
          >
            <StyledText>{t('OBJECTIVE_NOT_SET')}</StyledText>
          </div>
        )
      }
    >
      {children}
    </Tooltip>
  )
}

OkrToolTip.displayName = 'OkrToolTip'
