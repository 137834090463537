import { Box, Tip } from 'grommet'
import React from 'react'

import { useTranslation } from '../../../i18n'
import { color } from '../../../styles/newColors'
import { Icon } from '../Icon'
import { StyledText, Props as StyledTextProps } from '../StyledText'

const integrationServices = {
  slack: 'Slack',
  chatwork: 'Chatwork',
  teams: 'Teams',
}

type IntegrationService = keyof typeof integrationServices
export type Props = StyledTextProps & {
  service: IntegrationService
}

export const IntegrationTag: React.FC<Props> = ({ service, ...props }) => {
  const { t } = useTranslation()

  const serviceName = integrationServices[service]
  const label = t('X_NOTIFICATION', { x: serviceName })
  const content = t('INTEGRATE_WITH_X_TAG_TOOLTIP', { x: serviceName })

  return (
    <Tip
      plain
      content={
        <Box margin={{ top: '2px' }} direction="row">
          <Box background={color('text-bk-80')} width={{ max: '196px' }} pad="4px">
            <StyledText size="small">{content}</StyledText>
          </Box>
        </Box>
      }
    >
      <Box
        gap="4px"
        direction="row"
        align="center"
        background={color('hover-background-bk-5')}
        round="2px"
        pad="4px"
        css={{ display: 'inline-flex', cursor: 'pointer' }}
      >
        <Icon type={`${service}Logo`} />
        <StyledText size="small" lineHeight="12px" {...props}>
          {label}
        </StyledText>
      </Box>
    </Tip>
  )
}

IntegrationTag.displayName = 'IntegrationTag'

export const integrationServicesForStorybook = Object.keys(
  integrationServices,
) as ReadonlyArray<IntegrationService>
