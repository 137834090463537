import { css } from '@emotion/react'

import { Props as ContentLayoutProps } from '../../../components/pageContent/ContentLayout'

export const adminOkrTermContainerLayout: ContentLayoutProps = {
  pad: '41px 36px',
}

export const sectionDescriptionWrapperCss = css({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  marginTop: '40px',
  marginBottom: '42px',
})
