import { useCallback, useMemo, useState } from 'react'
import isEqual from 'react-fast-compare'
import * as Yup from 'yup'

import { useTranslation } from '../../../../i18n'

type Values = ReadonlyArray<{
  initialValue?: number | null
  targetValue?: number | null
}>

export type Errors = ReadonlyArray<{
  [key in keyof Values[number]]?: string
}>

type Return = {
  validate: (values: Values) => boolean
  errors: Errors
}

const VALIDATE_OPTIONS = { abortEarly: false }

export const useFormValidation = (): Return => {
  const { t } = useTranslation()

  const schema = useMemo(
    () =>
      Yup.array(
        Yup.object().shape({
          initialValue: Yup.number().typeError(t('ONLY_NUMBER')).nullable(),
          targetValue: Yup.number().typeError(t('ONLY_NUMBER')).nullable(),
        }),
      ),
    [t],
  )

  const [errors, setErrors] = useState<Errors>([])

  const validate = useCallback(
    (values: Values): Errors => {
      try {
        // バリデーション実行
        schema.validateSync(values, VALIDATE_OPTIONS)
      } catch (err) {
        if (Yup.ValidationError.isError(err)) {
          return parseErrors(err)
        }
      }
      return [] as Errors
    },
    [schema],
  )

  const handleValidation = useCallback(
    (values: Values): boolean => {
      const validationErrors = validate(values)
      if (!isEqual(errors, validationErrors)) setErrors(validationErrors)

      // エラーがない場合はtrue
      const isValid = validationErrors.length === 0
      return isValid
    },
    [errors, validate],
  )

  return { validate: handleValidation, errors }
}

const parseErrors = (errors: Yup.ValidationError): Errors => {
  const validationErrors: Array<Errors[number]> = []
  errors.inner.forEach(({ path, message }) => {
    if (!path) return
    // 配列のindexを取得
    const match = path.match(/\[(\d+)\]/)
    const index = match && match.length > 1 ? parseInt(match[1], 10) : undefined
    if (index === undefined) throw new Error(`Failed Form Validation: Invalid path ${path}`)
    // エラー発生のkeyを取得
    const key = path.split('.')[1]

    validationErrors[index] = { ...validationErrors[index], [key]: message }
  })
  return validationErrors as Errors
}
