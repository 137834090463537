import { useProfiler } from '@sentry/react'
import { VFC, memo, useCallback } from 'react'
import isEqual from 'react-fast-compare'

import { useCurrentUser } from '../../../contexts/UserContext'

import { OkrTree, Props as OkrTreeProps } from './OkrTree'

export type Props = Omit<OkrTreeProps, 'expandLevel' | 'onNodeAdd' | 'onNodeAddConnectKR'> & {
  setParentOkrNodeId: (id: string | null) => void
  setParentKeyResultIds: (ids: Array<string>) => void
  showCreateOkrModal: () => void
}

export const TreesContainer: VFC<Props> = memo(
  ({
    okrTermId,
    okrTree,
    isFiltering,
    filteredOkrNodeIds,
    krFilters,
    setParentOkrNodeId,
    setParentKeyResultIds,
    showCreateOkrModal,
    onOkrFloatDrawerOpen,
    openOkrModal,
    openOkrModalWithKeyResultDrawer,
  }) => {
    useProfiler('TreesContainer')
    const user = useCurrentUser()

    const showModal = useCallback(
      async (_parentNodeId?: string): Promise<void> => {
        if (_parentNodeId) {
          setParentOkrNodeId(_parentNodeId)
          setParentKeyResultIds([])
        }
        showCreateOkrModal()
      },
      [showCreateOkrModal, setParentOkrNodeId, setParentKeyResultIds],
    )

    const showModalConnectParentKR = useCallback(
      (_parentNodeId: string, _parentKeyResultId: string) => {
        if (_parentNodeId && _parentKeyResultId) {
          setParentOkrNodeId(_parentNodeId)
          setParentKeyResultIds([_parentKeyResultId])
        }
        showCreateOkrModal()
      },
      [showCreateOkrModal, setParentOkrNodeId, setParentKeyResultIds],
    )

    const expandLevel = user?.userSetting.minimumVisibleOkrNodeLevel ?? 3
    return (
      <OkrTree
        expandLevel={expandLevel}
        okrTermId={okrTermId}
        okrTree={okrTree}
        isFiltering={isFiltering}
        filteredOkrNodeIds={filteredOkrNodeIds}
        onNodeAdd={showModal}
        onNodeAddConnectKR={showModalConnectParentKR}
        krFilters={krFilters}
        onOkrFloatDrawerOpen={onOkrFloatDrawerOpen}
        openOkrModal={openOkrModal}
        openOkrModalWithKeyResultDrawer={openOkrModalWithKeyResultDrawer}
      />
    )
  },
  (prev, next) => {
    if (prev.krFilters !== next.krFilters) {
      return false
    }
    if (prev.okrTermId !== next.okrTermId) {
      return false
    }
    if (prev.isFiltering !== next.isFiltering) {
      return false
    }
    if (!isEqual(prev.filteredOkrNodeIds, next.filteredOkrNodeIds)) {
      return false
    }
    if (prev.okrTree !== next.okrTree) {
      return false
    }
    if (prev.showCreateOkrModal !== next.showCreateOkrModal) {
      return false
    }
    return true
  },
)
TreesContainer.displayName = 'TreesContainer'
