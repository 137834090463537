import { Box } from 'grommet'
import type { FC, ComponentProps } from 'react'
import type { FieldError, RefCallBack } from 'react-hook-form'

import { InputForm, InputFormProps } from './InputForm'

export type Props = Pick<ComponentProps<'input'>, 'onChange' | 'onBlur' | 'name'> & {
  errors: {
    actualValue?: FieldError | undefined
  }
  initialValue: InputFormProps['initialValue']
  targetValue: InputFormProps['targetValue']
  unit: InputFormProps['unit']
  size?: 'small' | 'medium'
  inputRef?: RefCallBack
}

export const QuickUpdateKeyResultViewProgressRateForm: FC<Props> = ({
  name,
  onChange,
  onBlur,
  errors,
  initialValue,
  targetValue,
  unit,
  size = 'medium',
  inputRef,
}) => (
  <Box>
    <Box direction="row" justify="start" align="center" width="100%">
      <InputForm
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        errors={errors}
        initialValue={initialValue}
        targetValue={targetValue}
        unit={unit}
        size={size}
        inputRef={inputRef}
      />
    </Box>
  </Box>
)

QuickUpdateKeyResultViewProgressRateForm.displayName = 'QuickUpdateKeyResultViewProgressRateForm'
