import { useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import { BreadcrumbParams } from '../../components/domain/Breadcrumb'
import { OkrTermIdContext } from '../../contexts/OkrTermIdContext'
import { useTranslation } from '../../i18n'
import { convertToFileInput } from '../../lib/fileInput'
import { usePrompt } from '../../lib/prompt'
import { Screen } from '../../lib/screen'
import { useTracking } from '../../lib/tracking'
import * as urls from '../../urls'
import { generatePersonalNote } from '../../urls'
import {
  PersonalNoteDetailFragment,
  PersonalNoteDetailViewNoteDetailFragment,
  useFindPersonalNoteByIdQuery,
  useUpdatePersonalNoteMutation,
} from '../PersonalNoteDetail/graphql'

export const useInjection = (): null | {
  personalNote: PersonalNoteDetailFragment
  breadcrumbs?: BreadcrumbParams
  onUpdatePersonalNote: (value: PersonalNoteDetailViewNoteDetailFragment, redirect: boolean) => void
  onClickCancel: () => void
  setNeedBlock: (value: boolean) => void
} => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const originPath = window.location.pathname + window.location.search + window.location.hash
  const [, setNeedBlock] = usePrompt({ message: t('LEAVE_WARNING'), originPath })
  useTracking(t('PERSONAL_NOTE_EDIT_PAGE_TITLE'), Screen.PersonalNoteEdit)

  const { personalNoteId } = useParams()
  const res = useFindPersonalNoteByIdQuery({
    variables: { id: personalNoteId ?? '' },
    // NOTE: 古い情報が表示されてしまうためキャッシュを無効にする
    fetchPolicy: 'network-only',
    skip: personalNoteId == null,
  })
  const [updatePersonalNote] = useUpdatePersonalNoteMutation()

  const { okrTermId } = useContext(OkrTermIdContext)

  if (!res.data) {
    return null
  }

  const personalNote = res.data.findPersonalNoteById

  const breadcrumbs: BreadcrumbParams | undefined = okrTermId
    ? {
        url: urls.personalNote,
        items: [
          {
            breadcrumbName: 'homeNote',
            ...personalNote.author,
            okrTermId,
          },
          {
            breadcrumbName: 'personalNote',
            ...personalNote,
          },
        ],
      }
    : undefined

  const updatePersonalNoteMutation = async (value: PersonalNoteDetailViewNoteDetailFragment) => {
    if (!res.data) {
      return
    }
    await updatePersonalNote({
      variables: {
        id: value.id,
        title: value.title,
        body: {
          treeJson: value.body?.treeJson || '',
          plainText: value.body?.plainText || '',
        },
        attachments: value.attachments.map((attachment) => convertToFileInput(attachment)),
        permission: value.permission,
      },
    })
  }

  const onUpdatePersonalNote = async (
    value: PersonalNoteDetailViewNoteDetailFragment,
    redirect: boolean,
  ) => {
    await updatePersonalNoteMutation(value)
    if (redirect) {
      navigate(generatePersonalNote(personalNote.id))
    }
  }

  const onClickCancel = () => {
    navigate(generatePersonalNote(personalNote.id))
  }

  return {
    personalNote,
    breadcrumbs,
    onUpdatePersonalNote,
    onClickCancel,
    setNeedBlock,
  }
}
