import { css } from '@emotion/react'
import { useMemo } from 'react'

import { fontSize } from '../../../../../../styles/font'
import { color, hex2rgba } from '../../../../../../styles/newColors'

export type KeyResultAreaTableStylesParams = {
  hoverActionDisabled: boolean
  disabled: boolean
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useStyles = ({ hoverActionDisabled, disabled }: KeyResultAreaTableStylesParams) =>
  useMemo(
    () =>
      ({
        tableRow: css({
          display: 'flex',
          alignItems: 'center',
          borderBottom: `1px solid ${color('border-bk-10')}`,
          padding: '4px 8px',
          height: '48px',
          backgroundColor: color('white-100'),
          ':hover': {
            backgroundColor: hoverActionDisabled ? undefined : color('hover-background-bk-5'),
            '.krname': {
              color: hoverActionDisabled ? undefined : color('resily-orange-100'),
              textDecoration: disabled ? 'line-through' : undefined,
              opacity: disabled ? 0.5 : undefined,
            },
          },
          '&[data-is-selected="true"]': {
            backgroundColor: color('resily-orange-5'),
          },
        }),
        draggableIcon: css({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }),
        avatars: css({
          display: 'flex',
          marginLeft: 4,
          maxWidth: 22,
          width: '100%',
        }),
        krTitle: css({
          width: '320px',
          paddingLeft: '8px',
          // stylelint-disable-next-line
          display: '-webkit-box',
          overflow: 'hidden',
          WebkitLineClamp: 2,
          WebkitBoxOrient: 'vertical',
          textOverflow: 'ellipsis',
          color: disabled ? color('text-bk-30') : undefined,
          textDecoration: disabled ? 'line-through' : undefined,
          ...fontSize('small', 'bold'),
          '&[data-is-selected="true"]': {
            color: disabled ? hex2rgba(color('resily-orange-100'), 50) : color('resily-orange-100'),
          },
        }),
        krIcon: css({
          display: 'inline',
          marginRight: '4px',
          verticalAlign: 'middle',
        }),
        progressRate: css({
          width: '105px',
          display: 'flex',
          alignItems: 'center',
        }),
        progressRateButton: css({
          paddingRight: '8px',
          display: 'inline-flex',
          alignItems: 'center',
          ':hover': {
            backgroundColor: color('white-100'),
            borderRadius: '12px',
            outline: `1px solid ${color('border-bk-30')}`,
          },
        }),
        progressRateBar: css({
          width: '68px',
          paddingLeft: '12px',
          paddingRight: '8px',
          '> div': {
            height: '6px',
          },
        }),
        values: css({
          width: '100px',
          paddingLeft: '8px',
          // stylelint-disable-next-line
          display: '-webkit-box',
          WebkitLineClamp: 1,
          overflow: 'hidden',
          WebkitBoxOrient: 'vertical',
          textOverflow: 'ellipsis',
        }),
        valuesText: css({
          ...fontSize('small', 'regular'),
        }),
        notSettingValue: css({
          color: color('tag-red'),
        }),
        lastUpdate: css({
          width: '60px',
          paddingLeft: '8px',
        }),
      } as const),
    [disabled, hoverActionDisabled],
  )
