import { ApolloLink, Observable, NextLink, Operation, FetchResult } from '@apollo/client'
import { DefinitionNode } from 'graphql'

import { timezoneOffset } from './date'

// スナックバーを表示するか否かを制御するContextのキー
export const skipNotifyCallbackContextKey = 'skip-notify-callback'

// ローディング中のインジケーター表示のContextのキー
export const isPreFetchContextKey = 'is-pre-fetch'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ErrorWithShouldIgnore = Error & { shouldIgnore: boolean } & any

const noop = (): void => {}

type OperationDefinitionNode = DefinitionNode & {
  operation: 'query' | 'mutation'
}

export class RequestContextLink extends ApolloLink {
  private fetching: boolean

  constructor() {
    super()
    this.fetching = false
  }

  override request(operation: Operation, forward: NextLink): Observable<FetchResult> | null {
    const isPreFetch: boolean = operation.getContext()[isPreFetchContextKey] ?? false
    if (!isPreFetch) {
      this.fetching = true
    }
    this.onStart()
    operation.setContext({
      headers: {
        'x-client-timezone-offset': timezoneOffset().toString(),
        'x-request-client-source-url': window.location.href,
      },
    })

    const isSkipNotifyCallback: boolean =
      operation.getContext()[skipNotifyCallbackContextKey] ?? false
    const isMutation = operation.query.definitions
      .filter((d): d is OperationDefinitionNode => d.kind === 'OperationDefinition')
      .some((d) => d.operation === 'mutation')

    const subscriber = forward(operation)
    return new Observable((observer) => {
      let isPending = true

      const subscription = subscriber.subscribe({
        next: (result) => {
          isPending = false

          const { errors, data } = result
          this.onErrors(errors ? errors.map((e) => e.message) : [])
          if (isMutation && !isSkipNotifyCallback) {
            this.onSuccess(!!data)
          }

          this.fetching = false
          this.onComplete()
          observer.next(result)
        },
        error: (networkError) => {
          isPending = false
          this.fetching = false
          this.onNetworkError(networkError)
          observer.error(networkError)
        },
        complete: observer.complete.bind(observer),
      })
      return () => {
        if (isPending) this.fetching = false
        subscription.unsubscribe()
      }
    })
  }

  public loading(): boolean {
    return this.fetching
  }

  public onErrors: (_errors: ReadonlyArray<string>) => void = noop

  public onSuccess: (_success: boolean) => void = noop

  public onLoadingChange: (_loading: boolean) => void = noop

  private onStart = () => {
    this.onLoadingChange(this.loading())
  }

  private onNetworkError = (err: ErrorWithShouldIgnore) => {
    const { shouldIgnore }: { shouldIgnore: boolean } = err
    if (!shouldIgnore) {
      this.onErrors([err.result?.message ?? err.message])
    }
    this.onLoadingChange(this.loading())
  }

  private onComplete = () => {
    this.onLoadingChange(this.loading())
  }
}
