import { useContext, useEffect } from 'react'
import { useNavigate, useParams, useLocation, useNavigationType } from 'react-router-dom'
import { usePrevious } from 'react-use'

import { OkrTermIdContext } from '../../contexts/OkrTermIdContext'
import { AuthRouteProps } from '../../types/authRouteProps'
import { currentTag, generateChangeTermFallback } from '../../urls'

import { useOkrTermsLazyQuery } from './graphql'
import { useCurrentTermIdLazyQuery } from './okrTerm'

export const useOkrTermLoadable = (
  onOkrTermLoaded: AuthRouteProps['onOkrTermLoaded'],
  onChangeOkrTerm: ((id: string) => void) | undefined, // undefinedの場合はfixedTermIdが指定されることを期待している
  fixedTermId?: string, // currentなterm以外を直接開く可能性がある場合指定する
): string | null => {
  const navigate = useNavigate()
  const location = useLocation()
  const { termId } = useParams<{ termId?: string }>()
  const { okrTermId } = useContext(OkrTermIdContext) // onOkrTermLoadedが実質updateOkrTermIdなのでこっちでは使わない
  const prevOkrTermId = usePrevious(okrTermId)
  const [termsQuery, termsResp] = useOkrTermsLazyQuery()

  const [currentTermQuery, currentTermResp] = useCurrentTermIdLazyQuery(
    onChangeOkrTerm === undefined ? () => {} : onOkrTermLoaded,
  )

  useEffect(() => {
    termsQuery()
  }, [termsQuery])

  useEffect(() => {
    if (fixedTermId) {
      onOkrTermLoaded(fixedTermId)
      return
    }

    if (onChangeOkrTerm === undefined) {
      // fixedTermIdがまだundefined
      return
    }

    // URLのtermIdの変化を検知する
    if (termId && termId !== currentTag && termsResp.data) {
      const exists = termsResp.data.okrTerms.some(({ id }) => termId === id)
      if (exists) {
        onOkrTermLoaded(termId)
        return
      }
      navigate(`${location.pathname.replace(termId, currentTag)}${location.search}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fixedTermId, termId, termsResp])

  // 引数にもurlにもcontextにもtermIdがない場合はquery
  useEffect(() => {
    if (!currentTermResp.called && okrTermId == null && (termId == null || termId === currentTag)) {
      // セッションストレージの都合があるのでfixedTermIdが指定されていてもcallする
      currentTermQuery()
    }
  }, [currentTermQuery, currentTermResp.called, okrTermId, termId])

  const type = useNavigationType()
  useEffect(() => {
    if (!(okrTermId && prevOkrTermId && okrTermId !== prevOkrTermId)) {
      return
    }

    if (onChangeOkrTerm) {
      onChangeOkrTerm(okrTermId)
    } else {
      if (type === 'POP') return
      // 期間変更時のデフォルトの動作
      navigate(generateChangeTermFallback(okrTermId))
    }
  }, [fixedTermId, navigate, location, okrTermId, onChangeOkrTerm, prevOkrTermId, type])

  return okrTermId
}
