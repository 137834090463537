import {
  createPluginFactory,
  getMarks,
  isSelectionAtBlockEnd,
  isSelectionAtBlockStart,
  KEY_INDENT,
  KEY_LIST_STYLE_TYPE,
  PlateEditor,
  removeMark,
  TEditor,
  unsetNodes,
  Value,
} from '@udecode/plate'

/**
 * Remove selection all marks.
 */
const removeSelectionMark = <V extends Value = Value>(editor: TEditor<V>): void => {
  const marks = getMarks(editor)
  if (!marks) return

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  removeMark(editor, { key: Object.keys(marks) })
}

export const KEY_EDITOR_PROTOCOL = 'editorProtocol'

export const withEditorProtocol = <
  V extends Value = Value,
  E extends PlateEditor<V> = PlateEditor<V>,
>(
  editor: E,
): E => {
  const { deleteBackward, deleteForward, deleteFragment } = editor

  const resetMarks = () => {
    if (isSelectionAtBlockStart(editor)) {
      removeSelectionMark(editor)
    }
  }

  // NOTE: ここだけコピペではない
  const resetNodes = () => {
    if (isSelectionAtBlockStart(editor) && isSelectionAtBlockEnd(editor)) {
      unsetNodes(editor, [KEY_INDENT, KEY_LIST_STYLE_TYPE, 'checked'])
    }
  }

  editor.deleteBackward = (unit) => {
    deleteBackward(unit)

    resetMarks()
  }

  editor.deleteForward = (unit) => {
    deleteForward(unit)

    resetMarks()
  }

  editor.deleteFragment = (direction) => {
    deleteFragment(direction)

    resetMarks()
    // NOTE: 範囲削除時はmarksだけでなくインデント情報やチェックボックスの状態もリセットする
    resetNodes()
  }

  return editor
}

/**
 * FIXME: 範囲削除時にmarkがresetされないバグ修正として、19.1.0で入ったcreateEditorProtocolPluginをコピペしただけ
 *        当該バージョン以降なら不要
 * @see https://github.com/udecode/plate/blob/449591215afaf18557fc2e2c501ecb157400c649/packages/core/src/plugins/createEditorProtocolPlugin.ts
 */
export const createEditorProtocolPlugin = createPluginFactory({
  key: KEY_EDITOR_PROTOCOL,
  withOverrides: withEditorProtocol,
})
