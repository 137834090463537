import { yupResolver } from '@hookform/resolvers/yup'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'

import { FormField } from '../../../../components/ui/FormField'
import { Input } from '../../../../components/ui/Input'
import { StyledText } from '../../../../components/ui/StyledText'
import { useTranslation } from '../../../../i18n'
import { EditButtonSection } from '../EditButtonSection'

import { EditOrganizationNameFormSectionProps, FormValues } from './types'

export const EditOrganizationNameFormSection: React.FC<EditOrganizationNameFormSectionProps> = ({
  organization,
  updateOrganization,
}) => {
  const { t } = useTranslation()

  const {
    register,
    handleSubmit,
    formState: { touchedFields, errors, isSubmitting, isDirty },
    reset,
  } = useForm<FormValues>({
    mode: 'onChange',
    resolver: yupResolver(
      Yup.object().shape({
        name: Yup.string().required(t('REQUIRED_ERROR')).min(3, t('MIN_LENGTH_ERROR')),
      }),
    ),
    defaultValues: {
      name: organization.name,
    },
  })

  const onCancel = useCallback(() => {
    reset({ name: organization.name })
  }, [organization.name, reset])

  return (
    <form
      onSubmit={handleSubmit((values: FormValues) =>
        updateOrganization(values.name).then((r) => {
          if (r.data) {
            const {
              data: { updateOrganization: org },
            } = r
            reset({ name: org.name })
          }
        }),
      )}
    >
      <StyledText fontStyle="bold">{t('ORGANIZATION_NAME')}</StyledText>
      <FormField
        errorMessage={errors.name ? errors.name.message : ''}
        touched={!!touchedFields.name}
        css={{
          marginTop: '12px',
          marginBottom: '0',
        }}
      >
        <Input fieldStyle="bottomLine" css={{ minWidth: '506px' }} {...register('name')} />
      </FormField>
      {isDirty && <EditButtonSection disabledEdit={!isDirty || isSubmitting} onCancel={onCancel} />}
    </form>
  )
}
