import { css } from '@emotion/react'
import { FC } from 'react'

import { border } from '../../../styles/border'
import { color } from '../../../styles/newColors'

const innerCss = css({
  display: 'flex',
  alignItems: 'center',
  height: '32px',
  width: 'fit-content',
  background: color('white-100'),
  border: border('simple-20'),
  borderRadius: '4px',
  boxSizing: 'border-box',
  boxShadow: '0px 8px 16px rgb(34 41 67 / 16%)',
  opacity: 0.6,
  '&:hover': { opacity: 1 },
})

export type Props = Omit<JSX.IntrinsicElements['div'], 'css'>

export const ControllerBase: FC<Props> = ({ children, ...props }) => (
  <div css={innerCss} {...props}>
    {children}
  </div>
)

ControllerBase.displayName = 'ControllerBase'
