import { Box } from 'grommet'
import React, { useCallback, useRef, useState } from 'react'

import { useTranslation } from '../../../i18n'
import { onError, onLoadingChange, onSuccess } from '../../../lib/client'
import { timezoneOffset } from '../../../lib/date'
import { color } from '../../../styles/newColors'
import { Button } from '../Button'
import { StyledText } from '../StyledText'

const downloadCsv = (fileName: string, csv: string) => {
  const link = document.createElement('a')
  document.body.appendChild(link)
  link.href = window.URL.createObjectURL(new Blob([csv], { type: 'text/csv' }))
  link.download = fileName
  link.click()
  document.body.removeChild(link)
}

export type PostBodyKeys = 'groups' | 'users'

export type Props = Omit<JSX.IntrinsicElements['form'], 'onSubmit'> & {
  submitDisabled?: boolean
  fileName: string
  importUrl: string
  postBodyKey: PostBodyKeys
  onSubmitSuccess?: JSX.IntrinsicElements['form']['onSubmit']
}

export const CsvUploadForm: React.FC<Props> = ({
  submitDisabled,
  fileName,
  importUrl,
  postBodyKey,
  onSubmitSuccess,
  ...props
}) => {
  const { t } = useTranslation()

  const inputFileRef = useRef<HTMLInputElement>(null)
  const [uploadCsv, setUploadCsv] = useState<File | undefined>(undefined)
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)

  const onSubmit = useCallback<React.FormEventHandler<HTMLFormElement>>(
    async (e) => {
      e.preventDefault()

      if (!uploadCsv) return

      onLoadingChange(true)

      let result
      try {
        const body = new FormData()
        body.append(postBodyKey, uploadCsv)
        result = await fetch(importUrl, {
          method: 'POST',
          credentials: 'include',
          mode: 'cors',
          headers: {
            'x-client-timezone-offset': timezoneOffset().toString(),
            'x-request-client-source-url': window.location.href,
          },
          body,
        })
      } catch (err) {
        if (err instanceof Error) {
          onError(err.message)
        }
      } finally {
        onLoadingChange(false)
      }
      if (!result || !result.ok) return

      const text = await result.text()

      const allTextLines = text.split(/\r\n|\n|\r/)
      const errors = allTextLines.reduce((accumulator: Array<number>, line, index) => {
        if (line.startsWith('# ')) accumulator.push(index)
        return accumulator
      }, [])
      if (errors.length) {
        const newErrorMessage = errors
          .map(
            (value) =>
              `${value + 1}: ${allTextLines[value].substring(2, allTextLines[value].indexOf(','))}`,
          )
          .join('\n')
        setErrorMessage(newErrorMessage)
        onError(t('UPDATE_FAILED'))

        // eslint-disable-next-line no-alert
        window.alert(t('UPLOAD_ERROR_CONTAIN_ERROR'))

        // エラー情報を含んだCSVをダウンロードさせる
        downloadCsv(fileName, text)
      } else {
        setErrorMessage(undefined)
        onSuccess(true)
        if (onSubmitSuccess) {
          onSubmitSuccess(e)
        }
      }

      setUploadCsv(undefined)
      if (inputFileRef.current) {
        inputFileRef.current.value = ''
      }
    },
    [fileName, importUrl, onSubmitSuccess, postBodyKey, t, uploadCsv],
  )

  return (
    <form onSubmit={onSubmit} {...props}>
      <Box margin={{ top: '12px' }}>
        <input
          ref={inputFileRef}
          type="file"
          name="file"
          accept="text/csv,.csv"
          required
          onChange={(e) => {
            if (e.target.files?.length === 1) {
              setUploadCsv(e.target.files[0])
            }
          }}
          css={{ display: 'none' }}
        />
        <Box direction="row" gap="small" align="center">
          <Button
            newColor="white-100"
            weight="normal"
            size="s"
            css={{
              alignItems: 'center',
              display: 'flex',
              padding: '6px 12px',
              height: '32px',
              fontSize: '12px',
            }}
            onClick={(e) => {
              e.currentTarget.blur()
              inputFileRef.current?.click()
            }}
          >
            {t('SELECT_CSV_FILE')}
          </Button>
          <StyledText color="text-bk-50" size="small">
            {uploadCsv && uploadCsv.name}
          </StyledText>
        </Box>
      </Box>
      {errorMessage && (
        <Box margin={{ top: '12px' }}>
          <p>
            <StyledText color="tag-red" lineHeight="22px">
              {t('ERROR_CSV_FILE')}
            </StyledText>
          </p>
          <p
            style={{
              color: color('tag-red'),
              whiteSpace: 'pre-wrap',
              marginTop: '4px',
              fontSize: '12px',
              lineHeight: '20px',
            }}
          >
            {errorMessage}
          </p>
        </Box>
      )}
      <Box margin={{ top: '16px' }}>
        <Button
          newColor="resily-orange-100"
          weight="normal"
          size="s"
          css={{ width: '116px' }}
          type="submit"
          disabled={submitDisabled || !uploadCsv}
          disabledColor="resily-orange-100"
        >
          {t('UPLOAD')}
        </Button>
      </Box>
    </form>
  )
}

CsvUploadForm.displayName = 'CsvUploadForm'
