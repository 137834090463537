import { Box, BoxProps } from 'grommet'
import React from 'react'

import { Icon } from '../../../components/ui/Icon'
import { Link as StyledLink } from '../../../components/ui/Link'
import { StyledText } from '../../../components/ui/StyledText'
import { color } from '../../../styles/newColors'

type Link = {
  text: string
  url: string
}

type EmptyStateProps = {
  title: string
  description: string | React.ReactElement
  link?: Link
}

export type Props = BoxProps & EmptyStateProps

export const OkrEmptyState: React.FC<Props> = ({
  children: _,
  title,
  description,
  link,
  ...props
}) => (
  <EmptyStateContainer {...props}>
    <EmptyState title={title} description={description} link={link} />
  </EmptyStateContainer>
)

OkrEmptyState.displayName = 'OkrEmptyState'

const EmptyStateContainer: React.FC<BoxProps> = ({ children, ...props }) => (
  <Box
    flex
    background={{ color: color('background-bk-5') }}
    height="calc(100vh - 52px - 64px)"
    align="center"
    justify="center"
    {...props}
  >
    {children}
  </Box>
)

EmptyStateContainer.displayName = 'EmptyStateContainer'

const EmptyState: React.FC<EmptyStateProps> = ({ children: _, title, description, link }) => (
  <Box
    flex
    align="center"
    justify="center"
    height={{ max: '400px', min: '400px' }}
    width={{ max: '640px', min: '640px' }}
    background="white"
    border={{ style: 'solid', color: color('border-bk-30') }}
    round="16px"
  >
    <StyledText size="xlarge" lineHeight="20px" color="text-bk-100">
      {title}
    </StyledText>
    <div css={{ marginTop: '20px' }}>
      {typeof description === 'string' ? (
        <StyledText size="large" lineHeight="16px" color="text-bk-100">
          {description}
        </StyledText>
      ) : (
        [description]
      )}
    </div>
    {link && (
      <Box direction="row" align="center" margin={{ top: '16px' }} gap="3.75px">
        <StyledLink
          method="newTab"
          href={link.url}
          css={{ fontSize: '14px', lineHeight: '22px', color: color('resily-orange-100') }}
        >
          {link.text}
        </StyledLink>
        <Icon type="external" height={10.5} width={10.5} color={color('resily-orange-100')} />
      </Box>
    )}
    <div css={{ marginTop: link ? '26px' : '64px' }}>
      <Icon type="okrEmpty" height={153} width={361} />
    </div>
  </Box>
)

EmptyState.displayName = 'EmptyState'
