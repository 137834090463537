import { useMemo, useContext } from 'react'

import { KrFilterContext } from '../../../contexts/KrFilterContext'
import { ancestors, descendants, Node } from '../../../lib/collections/node'
import { OKRTree } from '../../../lib/domain/okr/tree'
import { OkrNode } from '../trees/types'

export const useFilteredNodes = ({
  filteredDisabledOkrNodes,
  requiredGroupIds,
  selectedUserIds,
  showingNodeIds,
  isShownDisabledOkr,
}: {
  filteredDisabledOkrNodes: ReadonlyArray<Node<OkrNode>>
  requiredGroupIds: ReadonlyArray<string>
  selectedUserIds: ReadonlyArray<string>
  showingNodeIds: ReadonlyArray<string>
  isShownDisabledOkr: boolean
}): {
  filteredOkrNodes: ReadonlyArray<Node<OkrNode>>
  filteredOkrNodeIds: ReadonlyArray<string>
  filteredOkrTree: OKRTree<OkrNode>
} => {
  const { filters, hasKeyResult } = useContext(KrFilterContext)

  // クエリパラメータに該当するノード
  const filteredOkrNodes = useMemo<ReadonlyArray<Node<OkrNode>>>(
    () =>
      OKRTree.filterOkrNodes(filteredDisabledOkrNodes, {
        requiredGroupIds,
        selectedUserIds,
        showingNodeIds,
      }),
    [filteredDisabledOkrNodes, requiredGroupIds, selectedUserIds, showingNodeIds],
  )

  const excludeIds = useMemo<ReadonlyArray<string>>(
    () =>
      OKRTree.getExcludeIds(filteredDisabledOkrNodes, {
        showingNodeIds,
        filters,
        hasKeyResult,
      }),
    [filteredDisabledOkrNodes, showingNodeIds, filters, hasKeyResult],
  )

  const filteredOkrNodeIds = useMemo<ReadonlyArray<string>>(
    () => filteredOkrNodes.filter((n) => !excludeIds.includes(n.id)).map((n) => n.id),
    [filteredOkrNodes, excludeIds],
  )

  // ツリービュー向けに再構築したツリー
  const filteredOkrTree = useMemo(() => {
    const requireNodeMap = new Map<Node<OkrNode>['id'], Node<OkrNode>>()
    // フィルタ対象の祖先と子孫だけ残す
    filteredOkrNodes.forEach((n) => {
      requireNodeMap.set(n.id, n)
      ancestors(n).forEach((a) => {
        if (!isShownDisabledOkr && a.objective.isDisabled) {
          return
        }
        requireNodeMap.set(
          a.id,
          isShownDisabledOkr
            ? a
            : {
                ...a,
                keyResults: a.keyResults.filter((kr) => !kr.isDisabled),
              },
        )
      })
      descendants(n).forEach((d) => {
        if (!isShownDisabledOkr && d.objective.isDisabled) {
          return
        }
        requireNodeMap.set(
          d.id,
          isShownDisabledOkr
            ? d
            : {
                ...d,
                keyResults: d.keyResults.filter((kr) => !kr.isDisabled),
              },
        )
      })
    })

    const allNodes = [...requireNodeMap.values()]
    const nodes = isShownDisabledOkr ? allNodes : allNodes.filter((n) => !excludeIds.includes(n.id))
    return new OKRTree<OkrNode>(nodes)
  }, [filteredOkrNodes, isShownDisabledOkr, excludeIds])

  return { filteredOkrNodes, filteredOkrNodeIds, filteredOkrTree }
}
