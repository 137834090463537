import React, { forwardRef } from 'react'

export type Props = {
  displayFilterResult: boolean
  filterResultRef: React.Ref<HTMLDivElement>
  filterResultChildren: React.ReactNode
  usersTableChildren: React.ReactNode
}

export const Users = forwardRef<HTMLDivElement, Props>(
  (
    { displayFilterResult, filterResultRef, filterResultChildren, usersTableChildren }: Props,
    ref: React.Ref<HTMLDivElement>,
  ) => (
    <div
      css={{
        overflowY: 'hidden',
        height: '100%',
        padding: '0 24px',
      }}
      ref={ref}
    >
      <div
        ref={filterResultRef}
        css={{
          padding: displayFilterResult ? '20px 0' : '12px 0',
        }}
      >
        {displayFilterResult && filterResultChildren}
      </div>
      {usersTableChildren}
    </div>
  ),
)

Users.displayName = 'Users'
