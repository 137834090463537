import { useModal } from '@resily/geisha'
import { useCallback, useMemo, useState } from 'react'

import { useEditingStateMachine } from '../../../../contexts/EditingStateContext'
import { Screen } from '../../../../lib/screen'
import { tracker } from '../../../../lib/tracking'
import { useWeightSettingResetAlertModal } from '../../WeightSettingResetAlertModal'
import {
  KeyResultCreateModalContainer,
  Props as KeyResultCreateModalContainerProps,
} from '../KeyResultCreateModalContainer'

type Props = Pick<KeyResultCreateModalContainerProps, 'objectiveId' | 'ownerId' | 'onCompletedSave'>

type OpenFunc = (props: Props & { useWeighting: boolean }, screen: Screen) => void

export const useKeyResultCreateModal = (): [JSX.Element, OpenFunc] => {
  const [Modal, isOpen, { open, close }] = useModal()
  const [props, setProps] = useState<Props>()

  const { requestStartEdit, updateIsDirty, discardEdit, finishEdit } = useEditingStateMachine()
  const wrapClose = useCallback(() => {
    close()
    setProps(undefined)
  }, [close])
  const wrapOpen = useCallback(() => {
    requestStartEdit(() => {
      open()
      return wrapClose
    }, 'KEY_RESULT_CREATE_MODAL')
  }, [open, requestStartEdit, wrapClose])

  const [WeightSettingResetAlertModal, openWeightSettingResetAlertModal] =
    useWeightSettingResetAlertModal('CREATE')

  const KeyResultCreateModal = useMemo(
    () => (
      <>
        {isOpen && props && (
          <KeyResultCreateModalContainer
            modalComponent={Modal}
            isOpen
            onClose={discardEdit}
            onCreate={() => {
              finishEdit()
              wrapClose()
            }}
            onEdit={updateIsDirty}
            {...props}
          />
        )}
        <WeightSettingResetAlertModal onConfirm={wrapOpen} />
      </>
    ),
    [
      Modal,
      WeightSettingResetAlertModal,
      discardEdit,
      finishEdit,
      isOpen,
      props,
      updateIsDirty,
      wrapClose,
      wrapOpen,
    ],
  )

  const openOkrCreateModal = useCallback<OpenFunc>(
    ({ useWeighting, ...restProps }, screen) => {
      // TODO: 適切なtrackerを付与
      tracker.ClickAddOKR(screen)
      setProps(restProps)

      if (useWeighting) {
        openWeightSettingResetAlertModal()
        return
      }

      wrapOpen()
    },
    [openWeightSettingResetAlertModal, wrapOpen],
  )

  return [KeyResultCreateModal, openOkrCreateModal]
}
