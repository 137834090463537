import dayjs from 'dayjs'
import { Box } from 'grommet'
import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Avatar } from '../../components/ui/Avatar'
import { Icon } from '../../components/ui/Icon'
import { Menus, Props as MenusProps } from '../../components/ui/Menus'
import { StyledText } from '../../components/ui/StyledText'
import { useCurrentUser } from '../../contexts/UserContext'
import { useTranslation } from '../../i18n'
import { color } from '../../styles/newColors'
import { generateOneOnOneDetail } from '../../urls'
import { OneOnOneDeleteModal } from '../OneOnOnesDetail/OneOnOneDeleteModal'

import { OneOnOneUserFragment, UserOneOnOneFragment, useUserAgendasLazyQuery } from './graphql'

export type Props = {
  oneOnOne: UserOneOnOneFragment
  handleEditMeeting: (oneOnOne: {
    oneOnOneId: string
    partner: {
      user: OneOnOneUserFragment
    }
  }) => void
  onDeleteOneOnOne: (id: string) => Promise<void>
}

const DateFormat = 'YYYY/MM/DD'
const TimeFormat = 'H:mm'

export const OneOnOneCard: React.FC<Props> = ({
  oneOnOne,
  handleEditMeeting,
  onDeleteOneOnOne,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const loginUser = useCurrentUser()
  const [userAgendasQuery, userAgendasRes] = useUserAgendasLazyQuery()
  const [isDeleteModal, setIsDeleteModal] = useState(false)

  const nextMeeting =
    oneOnOne.futureMeetings.edges.length > 0 ? oneOnOne.futureMeetings.edges[0] : null

  const nextMeetingDateTime = nextMeeting
    ? `${dayjs(nextMeeting.node.dateOfMeeting).format(DateFormat)} ${dayjs(
        nextMeeting.node.startTime,
      ).format(TimeFormat)}-${dayjs(nextMeeting.node.endTime).format(TimeFormat)}`
    : null

  const lastMeeting = oneOnOne.pastMeetings.edges.length > 0 ? oneOnOne.pastMeetings.edges[0] : null

  const lastMeetingDateTime = lastMeeting
    ? `${dayjs(lastMeeting.node.dateOfMeeting).format(DateFormat)} ${dayjs(
        lastMeeting.node.startTime,
      ).format(TimeFormat)}-${dayjs(lastMeeting.node.endTime).format(TimeFormat)}`
    : null

  const futureMeetingCount = oneOnOne.futureMeetings.totalCount

  useEffect(() => {
    if (nextMeeting) {
      userAgendasQuery({
        variables: {
          meetingId: nextMeeting.node.id,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextMeetingDateTime])

  const menus: MenusProps['popoverItems'] = useMemo(
    () =>
      [
        {
          label: t('EDIT_ONEONONE_DATE'),
          icon: <Icon type="edit" />,
          onItemClicked: () => {
            handleEditMeeting({
              oneOnOneId: oneOnOne.id,
              partner: {
                user: oneOnOne.partnerUser,
              },
            })
          },
        },
        {
          label: t('DELETION'),
          icon: <Icon type="garbage" />,
          onItemClicked: () => setIsDeleteModal(true),
        },
      ].filter((menu): menu is NonNullable<typeof menu> => menu != null),
    [t, oneOnOne.id, oneOnOne.partnerUser, handleEditMeeting],
  )

  if (!loginUser) return null

  // 自分が作成したアジェンダ以外の次回のミーティングが作成された時以降に登録・更新されたアジェンダの数
  const partnerAgendasCount = userAgendasRes.data?.userAgendas.filter(
    (userAgenda) =>
      nextMeeting &&
      userAgenda &&
      dayjs(userAgenda.updatedAt).isAfter(dayjs(nextMeeting.node.createdAt)) &&
      userAgenda?.author.id !== loginUser.id,
  ).length

  return (
    <>
      <Box
        direction="row"
        key={oneOnOne.id}
        margin={{
          bottom: '8px',
        }}
        pad={{
          horizontal: '25px',
          vertical: '25px',
        }}
        round={{
          size: '8px',
        }}
        onClick={() => {
          // 次回のミーティングが存在していたら、次回のミーティングに遷移、存在しなければ、最終実施のミーティングに遷移
          const meetingId = nextMeeting ? nextMeeting.node.id : lastMeeting?.node.id
          if (meetingId) {
            navigate(
              generateOneOnOneDetail({
                oneOnOneId: oneOnOne.id,
                oneOnOneMeetingId: meetingId,
              }),
            )
            return
          }

          handleEditMeeting({
            oneOnOneId: oneOnOne.id,
            partner: {
              user: oneOnOne.partnerUser,
            },
          })
        }}
        css={{
          justifyContent: 'space-between',
          border: `1px solid ${color('border-bk-10')}`,
          '&:hover': {
            padding: '24px',
            border: `2px solid ${color('resily-orange-100')}`,
          },
        }}
      >
        <Box direction="row">
          <Avatar
            avatarUrl={oneOnOne.partnerUser?.avatar?.url}
            className="avatar-icon"
            firstName={oneOnOne.partnerUser.firstName}
            lastName={oneOnOne.partnerUser.lastName}
            isUserDisabled={oneOnOne.partnerUser.isDisabled}
            size="user-page"
          />

          <div css={{ marginLeft: '16px' }}>
            <Box direction="row" align="center">
              <StyledText size="large" weight="bold" margin={{ right: '12px' }}>
                {t('FULL_NAME', {
                  firstName: oneOnOne.partnerUser.firstName,
                  lastName: oneOnOne.partnerUser.lastName,
                })}
              </StyledText>
              {partnerAgendasCount && partnerAgendasCount > 0 ? (
                <StyledText size="small" weight="bold" color="resily-orange-100" lineHeight="12px">
                  {t('AGENDA_REGISTRATION', { count: partnerAgendasCount })}
                </StyledText>
              ) : null}
            </Box>
            <ul
              css={{
                display: 'flex',
                listStyle: 'none',

                '&>li': {
                  paddingRight: '8px',
                },

                '&>li:not(:first-of-type)': {
                  padding: '0 8px',
                  borderLeft: `1px solid ${color('border-bk-10')}`,
                },
                marginTop: '8px',
                flexWrap: 'wrap',
              }}
            >
              {oneOnOne.partnerUser.groups?.map((group) => (
                <li key={group.name}>
                  <StyledText size="small" color="text-bk-50">
                    {group.name}
                  </StyledText>
                </li>
              ))}
            </ul>
          </div>
        </Box>
        <div
          css={{
            display: 'flex',
          }}
        >
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              marginLeft: 'auto',
              textAlign: 'right',
              position: 'relative',
              marginRight: '16px',
              whiteSpace: 'nowrap',
            }}
          >
            {nextMeetingDateTime ? (
              <StyledText size="large" weight="bold">
                {t('NEXT_EVENT_DATE')}：{nextMeetingDateTime}
              </StyledText>
            ) : (
              <StyledText size="medium" color="resily-orange-100" weight="bold">
                {t('NEXT_ONEONONE_NOTHING')}
              </StyledText>
            )}

            <StyledText size="small" color="text-bk-50">
              {`${t('LAST_INPLEMENTATION_DATE')}： ${lastMeetingDateTime ?? '-'}`}
            </StyledText>

            {futureMeetingCount > 0 && (
              <StyledText size="small" color="text-bk-50">
                {t('NEXT_ONEONONE_COUNT', { count: futureMeetingCount })}
              </StyledText>
            )}
          </div>
          <Box
            width="40px"
            height="40px"
            direction="row"
            justify="center"
            align="center"
            background={{ color: 'light' }}
            onClick={(e) => {
              e.stopPropagation()
            }}
            css={{
              position: 'relative',
              zIndex: 1,
              borderRadius: '50%',
              padding: '1px',
              border: `1px solid ${color('border-bk-30')}`,
              '&:hover': {
                border: `1px solid ${color('border-bk-50')}`,
              },
            }}
          >
            <Menus
              key={oneOnOne.id}
              popoverItems={menus}
              css={{
                display: 'flex',
                flexDirection: 'column',
                marginLeft: 'auto',
                textAlign: 'right',
                position: 'relative',
                marginRight: '16px',
              }}
            />
          </Box>
        </div>
      </Box>
      <OneOnOneDeleteModal
        id={oneOnOne.id}
        isShowDeleteModal={isDeleteModal}
        deleteItemName={t('X_WITH_Y', {
          x: t('ONEONONE'),
          y: t('COMPELLATION', {
            fullName: t('FULL_NAME', {
              firstName: oneOnOne.partnerUser.firstName,
              lastName: oneOnOne.partnerUser.lastName,
            }),
          }),
        })}
        onDeleteCommonFunction={() => {
          onDeleteOneOnOne(oneOnOne.id)
          setIsDeleteModal(false)
        }}
        setIsShowDelete={setIsDeleteModal}
      />
    </>
  )
}

OneOnOneCard.displayName = 'OneOnOneCard'
