import { forwardRef, PropsWithoutRef } from 'react'

import * as colors from '../../../styles/colors'
import { color } from '../../../styles/newColors'
import { Icon } from '../Icon'

type Props = PropsWithoutRef<JSX.IntrinsicElements['input']> & {
  children?: React.ReactNode
  isError?: boolean
  round?: boolean
  roundIconWidth?: string
  roundIconHeight?: string
  roundIconColor?: string
}

export const Radio = forwardRef<HTMLInputElement, Props>(
  (
    {
      name,
      checked,
      disabled,
      children,
      isError,
      round,
      roundIconWidth = '14px',
      roundIconHeight = '14px',
      roundIconColor = color('resily-orange-100'),
      ...props
    }: Props,
    ref: React.Ref<HTMLInputElement>,
  ) => (
    <label
      css={{
        position: 'relative',
        display: 'inline-flex',
        alignItems: 'center',
        cursor: disabled ? 'default' : 'pointer',
      }}
    >
      <input
        ref={ref}
        css={{
          position: 'absolute',
          zIndex: -1,
          opacity: '0',
        }}
        type="radio"
        name={name}
        checked={checked}
        disabled={disabled}
        {...props}
      />
      <span
        css={[
          {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '16px',
            height: '16px',
            border: round ? undefined : `1px solid ${colors.border}`,
            transition: 'all 0.1s ease-in-out, color 0s linear',
            '&:hover': {
              borderColor: colors.main,
            },
          },
          checked && !round
            ? {
                color: colors.baseFill,
                backgroundColor: colors.main,
                borderColor: colors.main,
              }
            : null,
          disabled
            ? {
                color: colors.inactive,
                backgroundColor: colors.background,
                borderColor: colors.inactive,
              }
            : null,
          isError
            ? {
                borderColor: colors.negative,
              }
            : null,
        ]}
      >
        {round ? (
          <Icon
            type={checked ? 'radioButtonChecked' : 'radioButtonUnchecked'}
            css={[
              {
                color: checked ? roundIconColor : color('text-bk-30'),
                width: roundIconWidth,
                height: roundIconHeight,
              },
              !disabled
                ? {
                    ':hover': { color: roundIconColor },
                  }
                : null,
            ]}
          />
        ) : (
          <Icon
            css={
              checked
                ? {
                    display: 'block',
                  }
                : {
                    display: 'none',
                  }
            }
            type="check"
          />
        )}
      </span>
      {children}
    </label>
  ),
)

Radio.displayName = 'Radio'
