import { ComponentProps, memo, useCallback, useMemo, useRef } from 'react'
import { useController } from 'react-hook-form'

import {
  CommentTabsRef,
  CommentTabs,
  Props as CommentsTabProps,
} from '../../../components/CommentTabs'
import { isDefaultJsonComment } from '../../../utils'
import { useGetFormMethods } from '../../contexts/FormProvider'
import { keyResultsKeyName } from '../../formSchema'

export type Props = {
  name: `${typeof keyResultsKeyName}.${number}`
  children: (args: ComponentProps<typeof CommentTabs>) => React.ReactNode
}

export const CommentTabsController: React.VFC<Props> = memo(
  ({ name, children }) => {
    const { control, getValues, setValue } = useGetFormMethods()

    // 優先事項
    const priorityName = `${name}.priority` as const
    const {
      field: { value: priority, onChange: onChangePriority },
    } = useController({ name: priorityName, control })
    const handleChangePriority = useCallback<
      NonNullable<CommentsTabProps['comments']['priority']['onChange']>
    >(
      (treeJson: string, plainText: string) => {
        onChangePriority({ treeJson, plainText })
      },
      [onChangePriority],
    )
    const noPriorityComment = isDefaultJsonComment(getValues(`${name}.lastPriority.treeJson`))

    // 課題・障害
    const problemName = `${name}.problem` as const
    const {
      field: { value: problem, onChange: onChangeProblem },
    } = useController({ name: problemName, control })
    const handleChangeProblem = useCallback<
      NonNullable<CommentsTabProps['comments']['problem']['onChange']>
    >(
      (treeJson: string, plainText: string) => {
        onChangeProblem({ treeJson, plainText })
      },
      [onChangeProblem],
    )
    const noProblemComment = isDefaultJsonComment(getValues(`${name}.lastProblem.treeJson`))

    // ウィンセッション
    const winSessionName = `${name}.winSession` as const
    const {
      field: { value: winSession, onChange: onChangeWinSession },
    } = useController({ name: winSessionName, control })
    const handleChangeWinSession = useCallback<
      NonNullable<CommentsTabProps['comments']['winSession']['onChange']>
    >(
      (treeJson: string, plainText: string) => {
        onChangeWinSession({ treeJson, plainText })
      },
      [onChangeWinSession],
    )
    const noWinSessionComment = isDefaultJsonComment(getValues(`${name}.lastWinSession.treeJson`))

    // そのほか
    const otherName = `${name}.other` as const
    const {
      field: { value: other, onChange: onChangeOther },
    } = useController({ name: otherName, control })
    const handleChangeOther = useCallback<
      NonNullable<CommentsTabProps['comments']['other']['onChange']>
    >(
      (treeJson: string, plainText: string) => {
        onChangeOther({ treeJson, plainText })
      },
      [onChangeOther],
    )
    const noOtherComment = isDefaultJsonComment(getValues(`${name}.lastOther.treeJson`))

    const ref = useRef<CommentTabsRef>(null)
    const keyResultId = getValues(`${name}.keyResultId`)

    const handleSetLatestComments = useCallback(() => {
      const lastPriorityName = `${name}.lastPriority` as const
      const lastProblemName = `${name}.lastProblem` as const
      const lastWinSessionName = `${name}.lastWinSession` as const
      const lastOtherName = `${name}.lastOther` as const
      // Editorの更新
      ref.current?.setValues({
        priority: getValues(lastPriorityName)?.treeJson,
        problem: getValues(lastProblemName)?.treeJson,
        winSession: getValues(lastWinSessionName)?.treeJson,
        other: getValues(lastOtherName)?.treeJson,
      })

      // Formの更新
      setValue(priorityName, getValues(lastPriorityName))
      setValue(problemName, getValues(lastProblemName))
      setValue(winSessionName, getValues(lastWinSessionName))
      setValue(otherName, getValues(lastOtherName))
    }, [getValues, name, otherName, priorityName, problemName, setValue, winSessionName])

    return useMemo(
      () => (
        <>
          {children({
            ref,
            keyResultId,
            comments: {
              priority: {
                initialValueJSON: priority?.treeJson,
                onChange: handleChangePriority,
              },
              problem: {
                initialValueJSON: problem?.treeJson,
                onChange: handleChangeProblem,
              },
              winSession: {
                initialValueJSON: winSession?.treeJson,
                onChange: handleChangeWinSession,
              },
              other: {
                initialValueJSON: other?.treeJson,
                onChange: handleChangeOther,
              },
            },
            noPreviousComments:
              noPriorityComment && noProblemComment && noWinSessionComment && noOtherComment,
            onClickSetPreviousComments: handleSetLatestComments,
          })}
        </>
      ),
      [
        children,
        handleChangeOther,
        handleChangePriority,
        handleChangeProblem,
        handleChangeWinSession,
        handleSetLatestComments,
        keyResultId,
        noOtherComment,
        noPriorityComment,
        noProblemComment,
        noWinSessionComment,
        other?.treeJson,
        priority?.treeJson,
        problem?.treeJson,
        winSession?.treeJson,
      ],
    )
  },
  (prev, next) => {
    if (prev.name !== next.name) {
      return false
    }
    return true
  },
)

CommentTabsController.displayName = 'CommentTabsController'
