import { useProfiler } from '@sentry/react'
import { Box, Button } from 'grommet'
import React, { useCallback, useEffect, useRef, useState } from 'react'

import {
  getCandidateContributors,
  isCandidateGroup,
  Contributor,
} from '../../../../../../../components/domain/Contributor'
import { KeyResultDescriptionProgressRateInfo } from '../../../../../../../components/domain/KeyResultDescriptionProgressRateInfo'
import { KeyResultTargetSettingCheckBox } from '../../../../../../../components/domain/KeyResultTargetSettingCheckBox'
import { UserSelect } from '../../../../../../../components/domain/UserSelect'
import { UserTag } from '../../../../../../../components/domain/UserTag'
import { Card } from '../../../../../../../components/ui/Card'
import { Icon } from '../../../../../../../components/ui/Icon'
import { Popover } from '../../../../../../../components/ui/Popover/deprecated'
import {
  isChangeDefaultTemplate,
  changeTreeJsonToPlainText,
  createDefaultKeyResultDescriptionTreeJson,
} from '../../../../../../../components/ui/RichTextEditor/defaultTemplates'
import { StyledText } from '../../../../../../../components/ui/StyledText'
import { TextButton } from '../../../../../../../components/ui/TextButton'
import { NewTitle } from '../../../../../../../components/ui/Title'
import { TooltipNew } from '../../../../../../../components/ui/TooltipNew'
import { useSetFloatDrawerItems } from '../../../../../../../contexts/OkrFloatDrawerContext'
import { useCurrentUser } from '../../../../../../../contexts/UserContext'
import { useTranslation } from '../../../../../../../i18n'
import { useClickOutside } from '../../../../../../../lib/clickOutside'
import { useActivityRefetchQueries } from '../../../../../../../lib/domain/activity/hooks/useActivityRefetchQueries'
import {
  childObjectiveProgressRate,
  hasChildObjective,
} from '../../../../../../../lib/domain/keyResult/keyResult'
import { useParentOkrNodesLazyQuery } from '../../../../../../../lib/domain/okr/graphql'
import { getCandidateUsers, getOwnerOptions } from '../../../../../../../lib/domain/users'
import { convertToFileInput } from '../../../../../../../lib/fileInput'
import { Screen } from '../../../../../../../lib/screen'
import { tracker } from '../../../../../../../lib/tracking'
import { fontSize } from '../../../../../../../styles/font'
import { color } from '../../../../../../../styles/newColors'
import { generateKeyResult, usersAdmin } from '../../../../../../../urls'
import { OkrDrawerEditor } from '../OkrDrawerEditor'
import type { OkrDrawerRef } from '../OkrDrawerEditor/types'
import { OkrDrawerEmptyContent } from '../OkrDrawerEmptyContent'
import { OkrDrawerLoadingContent } from '../OkrDrawerLoadingContent'

import {
  KeyResultDescriptionProgressRateForm,
  Props as KeyResultDescriptionProgressRateFormProps,
} from './KeyResultDescriptionProgressRateForm'
import {
  File,
  KeyResultFragment,
  KeyResultEventScreen,
  KeyResultUpdateType,
  ProgressRateInput,
  TargetSettingType,
  UserFragment,
  useKeyResultLazyQuery,
  useGroupsLazyQuery,
  useUpdateKeyResultIsAutoAggregateMutation,
  useUpdateKeyResultNameMutation,
  useUpdateKeyResultOwnerMutation,
  useUpdateKeyResultProgressRateMutation,
  useUpdateKeyResultTargetSettingMutation,
  useUsersLazyQuery,
  useAttachMemberToKeyResultMutation,
  useDetachMemberFromKeyResultMutation,
  useUpdateKeyResultDescriptionMutation,
} from './graphql'

export type ContainerProps = {
  keyResultId: string
  hideKeyResult?: boolean
}

export const KeyResultDescriptionContainer: React.FC<ContainerProps> = ({
  keyResultId,
  hideKeyResult = false,
}) => {
  const { t } = useTranslation()

  // 「マイルストーンと計測方法」のテンプレート文言
  const defaultKeyResultDescriptionTreeJson = createDefaultKeyResultDescriptionTreeJson([
    `${t('ACHIEVEMENT_CRITERIA_AND_METRICS_DEFAULT_TEXT_FIRST')}`,
    `${t('ACHIEVEMENT_CRITERIA_AND_METRICS_DEFAULT_TEXT_TWO')}`,
    `${t('ACHIEVEMENT_CRITERIA_AND_METRICS_DEFAULT_TEXT_THIRD')}`,
    `${t('ACHIEVEMENT_CRITERIA_AND_METRICS_DEFAULT_TEXT_FORTH')}`,
    `${t('ACHIEVEMENT_CRITERIA_AND_METRICS_DEFAULT_TEXT_FIFTH')}`,
    `${t('ACHIEVEMENT_CRITERIA_AND_METRICS_DEFAULT_TEXT_SIX')}`,
    `${t('ACHIEVEMENT_CRITERIA_AND_METRICS_DEFAULT_TEXT_SEVEN')}`,
  ])
  const defaultKeyResultDescriptionPlainText = changeTreeJsonToPlainText(
    defaultKeyResultDescriptionTreeJson,
  )

  const [keyResultQuery, { data, loading, called }] = useKeyResultLazyQuery()

  const [updateKeyResultName] = useUpdateKeyResultNameMutation()
  const [updateKeyResultOwner] = useUpdateKeyResultOwnerMutation()
  const [updateKeyResultDescription] = useUpdateKeyResultDescriptionMutation()

  const [updateKeyResultProgressRate, { loading: isLoadingUpdateKeyResultProgressRate }] =
    useUpdateKeyResultProgressRateMutation({
      refetchQueries: useActivityRefetchQueries(),
    })
  const [updateKeyResultIsAutoAggregate] = useUpdateKeyResultIsAutoAggregateMutation({
    refetchQueries: useActivityRefetchQueries(),
  })

  const [attachMember] = useAttachMemberToKeyResultMutation()
  const [detachMember] = useDetachMemberFromKeyResultMutation()

  const [parentOkrNodesQuery] = useParentOkrNodesLazyQuery()
  const setFloatDrawerItems = useSetFloatDrawerItems()

  useEffect(() => {
    keyResultQuery({ variables: { keyResultId } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!called || loading) {
    return <OkrDrawerLoadingContent />
  }

  const keyResult = data?.keyResult
  const objective = keyResult?.objective
  if (!keyResult || !objective) {
    return <OkrDrawerEmptyContent />
  }

  const updateNameMutation = async (name: string) => {
    await updateKeyResultName({
      variables: {
        id: keyResult.id,
        name,
      },
    })
  }

  const updateKeyResultIsAutoAggregateMutation = async () => {
    await updateKeyResultIsAutoAggregate({
      variables: {
        id: keyResult.id,
        isAutoAggregate: true,
      },
    })
    // 自動吸い上げをONにしたNodeは返り値で進捗率を取得するので
    // 親のNodeのみを取得すればよい
    parentOkrNodesQuery({ variables: { nodeId: keyResult.node.id } })
  }

  const updateOwnerMutation = async (ownerId: string) => {
    await updateKeyResultOwner({
      variables: {
        id: keyResult.id,
        ownerId,
      },
    })
  }

  const updateKeyResultProgressRateMutation = async (input: ProgressRateInput) => {
    tracker.UpdateProgressRate(Screen.OneOnOneDetail, keyResult.id, objective.id)
    await updateKeyResultProgressRate({
      variables: {
        id: keyResult.id,
        input,
      },
    }).catch(() => {})
    parentOkrNodesQuery({ variables: { nodeId: keyResult.node.id } })
  }

  const updateKeyResultDescriptionMutation = async (
    treeJson: string,
    plainText: string,
    // TODO: 達成基準と計測方法更新時の即時通知を実装する際に修正する
    beforeTreeJson: string,
    beforePlainText: string,
    attachments: ReadonlyArray<File>,
  ) => {
    await updateKeyResultDescription({
      variables: {
        id: keyResult.id,
        description: {
          treeJson,
          plainText,
        },
        attachments: attachments.map((attachment) => convertToFileInput(attachment)),
        isDescriptionChanged: isChangeDefaultTemplate(
          treeJson,
          defaultKeyResultDescriptionPlainText,
        ),
      },
    })
  }
  const attachMemberMutation = async (id: string, memberId: string) => {
    await attachMember({
      variables: {
        id,
        memberId,
      },
    }).catch(() => {})
  }

  const detachMemberMutation = async (id: string, memberId: string) => {
    await detachMember({
      variables: {
        id,
        memberId,
      },
    }).catch(() => {})
  }

  const openOkrFloatDrawer = () => {
    setFloatDrawerItems([
      {
        objectiveId: keyResult.objective.id,
        selectedKeyResultIds: [keyResult.id],
      },
    ])
  }

  return (
    <KeyResultDescription
      termId={keyResult.node.term.id}
      hideKeyResult={hideKeyResult}
      keyResult={keyResult}
      keyResultDescriptionProgressRateFormSettingProps={{
        keyResultDescriptionProgressRateFormSettingProps: {
          buttonProps: {
            isLoading: isLoadingUpdateKeyResultProgressRate,
          },
        },
      }}
      updateOwner={updateOwnerMutation}
      updateName={updateNameMutation}
      updateProgressRate={updateKeyResultProgressRateMutation}
      updateKeyResultDescription={updateKeyResultDescriptionMutation}
      attachMemberMutation={attachMemberMutation}
      detachMemberMutation={detachMemberMutation}
      updateIsAutoAggregate={updateKeyResultIsAutoAggregateMutation}
      openOkrFloatDrawer={openOkrFloatDrawer}
    />
  )
}

KeyResultDescriptionContainer.displayName = 'KeyResultDescriptionContainer'

export type Props = {
  termId: string
  hideKeyResult: boolean
  keyResult: KeyResultFragment
  keyResultDescriptionProgressRateFormSettingProps: Pick<
    KeyResultDescriptionProgressRateFormProps,
    'keyResultDescriptionProgressRateFormSettingProps'
  >
  updateOwner: (ownerId: string) => void
  updateName: (name: string) => void
  updateProgressRate: (input: ProgressRateInput) => void
  updateIsAutoAggregate: () => void
  updateKeyResultDescription: (
    description: string,
    plainText: string,
    beforeDescription: string,
    beforePlainText: string,
    attachments: ReadonlyArray<File>,
  ) => void
  attachMemberMutation: (id: string, memberId: string) => void
  detachMemberMutation: (id: string, memberId: string) => void
  openOkrFloatDrawer: () => void
}

export const KeyResultDescription: React.FC<Props> = ({
  termId,
  hideKeyResult,
  keyResult,
  keyResultDescriptionProgressRateFormSettingProps,
  updateName,
  updateIsAutoAggregate,
  updateOwner,
  updateProgressRate,
  updateKeyResultDescription,
  attachMemberMutation,
  detachMemberMutation,
  openOkrFloatDrawer,
}) => {
  useProfiler('KeyResultDescription')
  const { t } = useTranslation()
  const user = useCurrentUser()
  const multiSelectRef = useRef<HTMLDivElement>(null)
  const okrDrawerEditorRef = useRef<OkrDrawerRef>(null)
  const [usersQuery, usersResp] = useUsersLazyQuery()
  const [groupsQuery, groupsResp] = useGroupsLazyQuery()

  const candidates: ReadonlyArray<UserFragment> = usersResp.data?.users
    ? getCandidateUsers<UserFragment>(usersResp.data?.users)
    : []

  const ownerOptions: ReadonlyArray<UserFragment> = usersResp.data?.users
    ? getOwnerOptions(candidates, keyResult.owner, keyResult.members)
    : ([keyResult.owner] as ReadonlyArray<UserFragment>)

  const isCalledUsersQuery: boolean = usersResp.called
  const isLoadingUsersQuery: boolean = !isCalledUsersQuery || usersResp.loading
  const isCalledGroupsQuery: boolean = groupsResp.called
  const isLoadingGroupsQuery: boolean = !isCalledGroupsQuery || groupsResp.loading

  const [openMemberMultiSelect, setOpenMemberMultiSelect] = useState(false)

  useClickOutside({ callback: () => setOpenMemberMultiSelect(false), ref: multiSelectRef })

  const isAuto = keyResult.updateType === KeyResultUpdateType.Automate && !keyResult.isAutoAggregate
  const progressRateInput: ProgressRateInput = {
    actualValue: isAuto ? keyResult.actualValue : keyResult.progressRate,
    initialValue: isAuto ? keyResult.initialValue : 0,
    targetValue: isAuto ? keyResult.targetValue : 100,
    unit: isAuto ? keyResult.unit : t('PERCENTAGE'),
    message: null,
    screen: KeyResultEventScreen.Drawer,
  }

  const candidateContributors = getCandidateContributors(
    keyResult.members,
    candidates,
    keyResult.owner.id,
  )

  const contributorIds = keyResult.members.map((m) => m.id)

  const groups = groupsResp.data?.groups
    ? groupsResp.data.groups.filter((g) =>
        isCandidateGroup(g, keyResult.owner.id, keyResult.members),
      )
    : []

  const [updateKeyResultTargetSettingMutation] = useUpdateKeyResultTargetSettingMutation()

  const updateKeyResultTargetSetting = useCallback(
    (targetSetting: TargetSettingType) => {
      updateKeyResultTargetSettingMutation({
        variables: {
          id: keyResult.id,
          targetSetting,
        },
      })
    },
    [updateKeyResultTargetSettingMutation, keyResult],
  )

  return (
    <Box>
      {!hideKeyResult && (
        <Section>
          <div
            css={{
              width: '100%',
              display: 'flex',
            }}
          >
            <div css={{ marginRight: 'auto', flex: '1 1 auto' }}>
              <StyledText color="text-bk-50" size="small" weight="bold">
                {t('KEY_RESULT')}
              </StyledText>
              <h2
                css={{
                  flex: '1 1 auto',
                  marginTop: '8px',
                  fontWeight: 'normal',
                  color: color('text-bk-100'),
                  ...fontSize('medium'),
                }}
              >
                <NewTitle
                  color="kr-green-100"
                  title={keyResult.name || ''}
                  onUpdate={updateName}
                  targetSetting={keyResult.targetSetting}
                />
              </h2>
            </div>
          </div>
        </Section>
      )}
      <Section>
        <Box margin={{ top: '24px', bottom: '12px' }}>
          <StyledText color="text-bk-50" size="small" weight="bold">
            {t('OWNER')}
          </StyledText>
        </Box>
        <Box css={{ fontSize: '14px' }}>
          <UserSelect
            value={keyResult.owner.id}
            isTargetUserDisabled={keyResult.owner.isDisabled}
            options={ownerOptions}
            onOpen={() => {
              if (!isCalledUsersQuery) {
                usersQuery()
              }
            }}
            isLoading={!usersResp.data?.users && isLoadingUsersQuery}
            onChange={(id) => {
              if (id === keyResult.owner.id) {
                return
              }
              const newOwner = ownerOptions.find((o) => o.id === id)
              if (newOwner) {
                updateOwner(newOwner.id)
              }
            }}
            css={{ width: '306px' }}
          />
        </Box>
      </Section>
      <Section>
        <Box margin={{ top: '24px', bottom: '12px' }}>
          <div css={{ position: 'relative' }}>
            <div>
              <StyledText color="text-bk-50" size="small" weight="bold">
                {t('CONTRIBUTOR')}
              </StyledText>
              {keyResult.members.length > 0 && (
                <TooltipNew
                  title={t('EDITION')}
                  css={{ display: 'inline-block', marginLeft: '24px' }}
                >
                  <Button
                    onClick={() => {
                      setOpenMemberMultiSelect(!openMemberMultiSelect)
                      if (!isCalledGroupsQuery) {
                        groupsQuery()
                      }
                      if (!isCalledUsersQuery) {
                        usersQuery()
                      }
                    }}
                  >
                    <Icon
                      type="editSimple"
                      color={color('text-bk-50')}
                      hoverColor="resily-orange-100"
                      css={{
                        width: '14px',
                        height: '14px',
                      }}
                    />
                  </Button>
                </TooltipNew>
              )}
            </div>
            {!isLoadingGroupsQuery && !isLoadingUsersQuery && openMemberMultiSelect ? (
              <div ref={multiSelectRef} css={{ position: 'absolute', zIndex: 11, width: '100%' }}>
                {candidateContributors.length === 0 ? (
                  <Card css={{ width: '300px', textAlign: 'center' }}>
                    <StyledText
                      size="small"
                      color="text-bk-100"
                      weight="normal"
                      css={{ marginBottom: '28px' }}
                    >
                      {t('USER_IS_EMPTY')}
                      <br />
                      {t('INVITE_A_NEW_USER')}
                    </StyledText>
                    {user?.admin ? (
                      <a
                        href={usersAdmin}
                        target="_blank"
                        rel="noopener noreferrer"
                        css={{
                          textDecoration: 'none',
                          color: color('text-bk-50'),
                          ':hover': { color: color('resily-orange-100') },
                        }}
                      >
                        <div
                          css={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <Icon
                            type="plus"
                            css={{ height: '12px', width: '12px', marginRight: '8px' }}
                          />
                          <StyledText>{t('INVITE_USER')}</StyledText>
                        </div>
                      </a>
                    ) : null}
                  </Card>
                ) : (
                  <Contributor
                    users={candidateContributors}
                    groups={groups}
                    selectedUserIds={contributorIds}
                    onClose={() => setOpenMemberMultiSelect(false)}
                    onClickUserOption={(next) => {
                      // 選択が入った時
                      if (keyResult.members.length < next.length) {
                        next.forEach((i) => {
                          if (keyResult.members.some((m) => i === m.id)) return
                          attachMemberMutation(keyResult.id, i)
                        })
                      }
                      // 選択が外れた時
                      if (keyResult.members.length > next.length) {
                        keyResult.members.forEach((m) => {
                          if (next.some((i) => i === m.id)) return
                          detachMemberMutation(keyResult.id, m.id)
                        })
                      }
                    }}
                  >
                    {user?.admin ? (
                      <a
                        href={usersAdmin}
                        target="_blank"
                        rel="noopener noreferrer"
                        css={{
                          textDecoration: 'none',
                          height: '44px',
                          paddingLeft: '32px',
                          display: 'flex',
                          alignItems: 'center',
                          color: color('text-bk-50'),
                          ':hover': {
                            color: color('resily-orange-100'),
                          },
                        }}
                      >
                        <StyledText>{t('ADMIN_USERS_PAGE_TITLE')}</StyledText>
                      </a>
                    ) : undefined}
                  </Contributor>
                )}
              </div>
            ) : null}
          </div>
        </Box>
        {keyResult.members.length === 0 ? (
          <div css={{ flexDirection: 'row' }}>
            <TextButton
              icon="plus"
              color="text-bk-30"
              padding="none"
              onClick={() => {
                setOpenMemberMultiSelect(!openMemberMultiSelect)
                if (!isCalledGroupsQuery) {
                  groupsQuery()
                }
                if (!isCalledUsersQuery) {
                  usersQuery()
                }
              }}
            >
              {t('ADD_X', { x: t('CONTRIBUTOR') })}
            </TextButton>
          </div>
        ) : (
          <div
            css={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '8px',
            }}
          >
            {keyResult.members
              .concat()
              .sort(
                (a: UserFragment, b: UserFragment) =>
                  (b.isDisabled ? -1 : 1) - (a.isDisabled ? -1 : 1),
              )
              .map((o) => (
                <UserTag key={o.id} termId={termId} {...o} isUserDisabled={o.isDisabled} />
              ))}
          </div>
        )}
      </Section>
      <Section>
        <Box margin={{ top: '24px', bottom: '8px' }}>
          <StyledText color="text-bk-50" size="small" weight="bold">
            {t('PROGRESS_RATE')}
          </StyledText>
        </Box>
        <KeyResultDescriptionProgressRateForm
          updateType={keyResult.updateType}
          progressRateInput={progressRateInput}
          updateProgressRate={(values) =>
            updateProgressRate({
              ...values,
              screen: KeyResultEventScreen.Drawer,
            })
          }
          keyResultDescriptionProgressRateFormSettingProps={
            keyResultDescriptionProgressRateFormSettingProps.keyResultDescriptionProgressRateFormSettingProps
          }
        />
        {hasChildObjective(keyResult) && (
          <div css={{ marginTop: '16px' }}>
            <KeyResultDescriptionProgressRateInfo
              isAutoAggregate={keyResult.isAutoAggregate}
              progressRate={keyResult.progressRate || 0}
              lowerOkrProgressRate={childObjectiveProgressRate(keyResult)}
              onClickButton={updateIsAutoAggregate}
            />
          </div>
        )}
      </Section>

      <Section>
        <Box margin={{ top: '24px', bottom: '12px' }}>
          <div css={{ display: 'flex', alignItems: 'center' }}>
            <StyledText color="text-bk-50" size="small" weight="bold">
              {t('TARGET_SETTING')}
            </StyledText>
            <Popover
              icon="help"
              title={t('TARGET_SETTING')}
              text={t('TARGET_SETTING_HELP_MESSAGE')}
              height="12px"
              margin={{ left: '4px' }}
              position={{ left: '-80px', top: '8px' }}
              css={{ zIndex: 10, whiteSpace: 'pre-wrap' }}
              hasGap={false}
            />
          </div>
        </Box>
        <Box css={{ fontSize: '14px' }}>
          <div>
            <KeyResultTargetSettingCheckBox
              targetSetting={keyResult.targetSetting}
              onClick={updateKeyResultTargetSetting}
            />
          </div>
        </Box>
      </Section>

      {!hideKeyResult && (
        <Section>
          <OkrDrawerEditor
            editorId={`drawer-kr-${keyResult.id}`}
            treeJson={keyResult.description?.treeJson || ''}
            plainText={keyResult.description?.plainText || ''}
            attachments={keyResult.attachments}
            onUpdateDescription={updateKeyResultDescription}
            referUrl={generateKeyResult(keyResult.node.id, keyResult.id)}
            label={t('ACHIEVEMENT_CRITERIA_AND_METRICS')}
            ref={okrDrawerEditorRef}
            onOkrFloatDrawerOpen={openOkrFloatDrawer}
            helpText={t('ACHIEVEMENT_CRITERIA_AND_METRICS_HELP_MESSAGE')}
          />
        </Section>
      )}
    </Box>
  )
}

KeyResultDescription.displayName = 'KeyResultDescription'

const Section: React.FC = ({ children }) => <Box flex={false}>{children}</Box>
