import React from 'react'
import { Controller } from 'react-hook-form'

import { ErrorMessage } from '../../../../../ui/ErrorMessage'
import { Props as ProgressNumberInputProps } from '../../../../../ui/ProgressNumberInput'
import { useGetFormMethods } from '../../contexts/FormProvider'
import { keyResultsKeyName } from '../../formSchema'

type ProgressNumberInputControllerProps = {
  index: number
  valueType: 'targetValue' | 'actualValue' | 'initialValue'
  children: (args: ProgressNumberInputProps) => React.ReactNode
}

export const ProgressNumberInputController: React.FC<ProgressNumberInputControllerProps> = ({
  index,
  valueType,
  children,
}) => {
  const {
    control,
    formState: { errors },
  } = useGetFormMethods()

  return (
    <Controller
      control={control}
      name={`${keyResultsKeyName}.${index}.${valueType}`}
      render={({ field: { value, onChange } }) => (
        <div>
          {children({
            maxWidth: 180,
            allowDecimal: true,
            defaultValue: value || 0,
            setValueOnChange: (type) => {
              const diff = type === 'up' ? 1 : -1
              const num = Number(value || 0)
              onChange(num + diff)
            },
            onChangeInputValue: (inputValue: string) => onChange(inputValue),
          })}
          {errors[keyResultsKeyName]?.[index]?.[valueType]?.message && (
            <ErrorMessage css={{ fontSize: 12 }}>
              {errors[keyResultsKeyName][index][valueType]?.message}
            </ErrorMessage>
          )}
        </div>
      )}
    />
  )
}
