import { Skeleton } from '@resily/geisha'

import { useTranslation } from '../../../../../i18n'
import { GeneralTitle } from '../../../../ui/GeneralTitle'
import { StyledText } from '../../../../ui/StyledText'
import { useStyles as useMainStyles } from '../Main.styles'

import { useStyles as useHeaderStyles } from './KeyResultAreaHeader/KeyResultAreaHeader.styles'
import { useStyles } from './KeyResultAreaSkeleton.styles'
import { useStyles as useTableStyles } from './KeyResultAreaTable/KeyResultAreaTable.styles'

export const KeyResultAreaSkeleton: React.VFC = () => {
  const { t } = useTranslation()
  const mainStyles = useMainStyles()
  const headerStyles = useHeaderStyles()
  const tableStyles = useTableStyles()
  const styles = useStyles()

  return (
    <div css={mainStyles.areaRoot}>
      <div css={headerStyles.root}>
        <GeneralTitle title={t('KEY_RESULT')} />
      </div>
      <div css={tableStyles.root}>
        <div css={tableStyles.header}>
          <div css={tableStyles.titleColumn}>
            <StyledText fontStyle="bold" size="small" color="text-bk-50">
              {t('KEY_RESULT')}
            </StyledText>
          </div>
          <div css={tableStyles.progressColumn}>
            <StyledText fontStyle="bold" size="small" color="text-bk-50">
              {t('PROGRESS')} ({t('CONFIDENCE')})
            </StyledText>
          </div>
          <div css={tableStyles.valuesColumn}>
            <StyledText fontStyle="bold" size="small" color="text-bk-50">
              {t('ACTUAL_AND_TARGET_VALUE')}
            </StyledText>
          </div>
          <div css={tableStyles.lastUpdateColumn}>
            <StyledText fontStyle="bold" size="small" color="text-bk-50">
              {t('LAST_UPDATED_AT')}
            </StyledText>
          </div>
        </div>
        {new Array(3).fill(undefined).map((_, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={i} css={styles.tableRow}>
            <div css={styles.avatar}>
              <Skeleton circle width={16} height={16} />
            </div>
            <div css={styles.krTitle}>
              <Skeleton text width={304} height={12} />
            </div>
            <div css={styles.progressRate}>
              <Skeleton text width={81} height={12} />
            </div>
            <div css={styles.values}>
              <Skeleton text width={92} height={12} />
            </div>
            <div css={styles.lastUpdate}>
              <Skeleton text width={51} height={12} />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
