import { css } from '@emotion/react'
import { Select, Grommet } from 'grommet'
import { useState } from 'react'

import { useTranslation } from '../../../i18n'

import { extendValue, selectCss } from './styles'

const getRegExp = (text: string) => {
  // The line below escapes regular expression special characters:
  // [ \ ^ $ . | ? * + ( )
  const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&')

  // Create the regular expression with modified value which
  // handles escaping special characters. Without escaping special
  // characters, errors will appear in the console
  return new RegExp(escapedText, 'i')
}

export type Props = {
  ['data-testid']?: string
  width?: number
  height?: number
  value?: string | null
  onChange: (value: string) => void
}

export const SelectUnit: React.VFC<Props> = ({
  'data-testid': dataTestId,
  width = 38,
  height = 22,
  value,
  onChange,
}) => {
  const { t } = useTranslation()
  const defaultOptions = [
    { label: t('UNIT_NOT_SET'), value: '' },
    { label: t('NO_UNIT'), value: t('NO_UNIT') },
    { label: t('YEN'), value: t('YEN') },
    { label: t('TEN_THOUSAND_YEN'), value: t('TEN_THOUSAND_YEN') },
    { label: t('PERCENTAGE'), value: t('PERCENTAGE') },
  ]
  const isCustomUnit = (unit?: string): boolean =>
    !!(unit && unit !== '' && !defaultOptions.some((option) => option.value === unit))
  const addOptions = (unit: string) => {
    if (isCustomUnit(unit)) return [...defaultOptions, { label: unit, value: unit }]
    return defaultOptions
  }
  // NOTICE: ドロップメニューを開閉する
  const [options, setOptions] = useState(addOptions(value || ''))
  const [searchValue, setSearchValue] = useState('')

  const handleOnSearch = (text: string) => {
    const exp = getRegExp(text)
    const filteredOptions = defaultOptions.filter((o) => exp.test(o.value))
    if (text === '') {
      setOptions(defaultOptions)
    } else if (filteredOptions.some((option) => option.value === text)) {
      setOptions(filteredOptions)
    } else {
      setOptions([...filteredOptions, { label: t('CREATE_OF_X', { x: `'${text}'` }), value: text }])
    }
    setSearchValue(text)
  }

  // FIXME: OptionをSelectした状態で、検索すると十字キーの上下矢印を押してもカーソル移動できない。
  // その状態でEnterを押すと`option`がundefinedになるので、以下の書き方で対応。バージョン2.23で治ってる。
  const handleOnChange = ({ option }: { option?: { label: string; value: string } }) => {
    // optionをフォーカスしていない状態で`Enter`を押すと`undefined`になるので、その際は検索窓に入力した値を取得する。
    const input = option?.value || searchValue
    if (option?.value === '') {
      onChange(option.value)
      setOptions(defaultOptions)
      return
    }
    if (defaultOptions.some((o) => o.value === input)) {
      onChange(input)
      setOptions(defaultOptions)
      return
    }
    setOptions([...defaultOptions, { label: searchValue, value: searchValue }])
    onChange(searchValue)
  }

  return (
    <Grommet theme={extendValue}>
      <Select
        data-testid={dataTestId}
        css={css(selectCss, { width, height })}
        emptySearchMessage={t('NOT_APPLICABLE')}
        labelKey="label"
        valueKey={{ key: 'value', reduce: true }}
        value={value ?? options[0].value}
        options={options}
        onChange={handleOnChange}
        onClose={() => setOptions(options)}
        onSearch={handleOnSearch}
      />
    </Grommet>
  )
}

SelectUnit.displayName = 'SelectUnit'
