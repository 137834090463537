import { Dispatch, SetStateAction, useState } from 'react'
import { useLocalStorage } from 'react-use'

type Ret = [
  boolean,
  Dispatch<SetStateAction<boolean | undefined>> | Dispatch<SetStateAction<boolean>>,
]

/**
 * 1 on 1詳細のおすすめアジェンダのON/OFFを保持しているlocalStorageのhooks
 * OKR期間ごとにON/OFFを管理している
 * OKR期間が存在しない場合は、LocalStorageではON・OFFを管理せず、Stateで管理する（再描画時にはON・OFFの反映されない）
 */
const key = 'enabled-recommend-agenda'

export const useEnableRecommendAgenda = (termId: string | undefined, initial: boolean): Ret => {
  // termIDが指定されていない場合は、常におすすめアジェンダを非表示に設定する
  const [enableState, setEnableState] = useState<boolean>(initial)

  // termIDが指定されている場合は、ローカルストレージに保存された表示設定を取得する
  const [enable, setEnable] = useLocalStorage<boolean>(key + (termId ?? ''), initial)

  return termId ? [enable ?? initial, setEnable] : [enableState, setEnableState]
}
