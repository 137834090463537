import dayjs from 'dayjs'
import { DocumentNode } from 'graphql'
import isUrl from 'is-url'
import { matchPath, useLocation } from 'react-router-dom'

import { InitDocument } from '../../../components/standalone/OkrModal/graphql'
import { useIsOpenOkrModal } from '../../../components/standalone/OkrModal/hooks/useIsOpenOkrModal'
import { TKeys } from '../../../i18n/type'
import { OkrDocument, OkrNodeForCheckinSummariesDocument } from '../../../pages/Okr/graphql'
import { OkrNodesDocument } from '../../../pages/OkrMap/graphql'
import { UserAssignOkrDocument } from '../../../pages/OneOnOnesDetail/Drawer/graphql'
import { FindUserByIdDocument } from '../../../pages/User/beta/tabs/Okr/graphql'
import { trees, treesInCurrentTerm, okr, homeOkr, oneOnOneDetail } from '../../../urls'

import { AddOkrNodeInput, NodeForExcludeDisableFragment, UpdateOkrNodeInput } from './graphql'

export const isFilledRequiredFieldsForCreateAndUpdateOkr = (
  variables:
    | Pick<
        AddOkrNodeInput,
        | 'okrTermId'
        | 'name'
        | 'ownerId'
        | 'slackChannelIds'
        | 'chatworkApiToken'
        | 'chatworkRoomId'
        | 'teamsWebhookUrl'
      >
    | Pick<
        UpdateOkrNodeInput,
        | 'name'
        | 'ownerId'
        | 'slackChannelIds'
        | 'chatworkApiToken'
        | 'chatworkRoomId'
        | 'teamsWebhookUrl'
      >,
  slackNotifyFlg: boolean,
  chatworkNotifyFlg: boolean,
  teamsNotifyFlg: boolean,
): boolean =>
  ('okrTermId' in variables ? !!variables.okrTermId : true) &&
  !!variables.name &&
  !!variables.ownerId &&
  (!slackNotifyFlg || (variables.slackChannelIds?.length ?? 0) > 0) &&
  (!chatworkNotifyFlg || (!!variables.chatworkApiToken && !!variables.chatworkRoomId)) &&
  (!teamsNotifyFlg || isUrl(variables.teamsWebhookUrl ?? ''))

export type DurationType = Extract<
  TKeys,
  'LAST_A_WEEK_AGO' | 'LAST_2_WEEKS_AGO' | 'STRAIGHT_NEAR_30_DAYS_AGO' | 'MORE_THAN_30_DAYS_AGO'
>

export type MinDuration = Extract<DurationType, 'LAST_A_WEEK_AGO' | 'LAST_2_WEEKS_AGO'>

export const addDay = (date: Date, day: number): Date => dayjs(date).add(day, 'd').toDate()

export const fail = (message: never): never => {
  throw new Error(message)
}

export const makeDurationsAWeek = (
  now: Date,
): { [K in DurationType]: { from?: Date; to?: Date } } => ({
  LAST_A_WEEK_AGO: { from: addDay(now, -7), to: undefined },
  LAST_2_WEEKS_AGO: { from: addDay(now, -14), to: addDay(now, -7) },
  STRAIGHT_NEAR_30_DAYS_AGO: { from: addDay(now, -30), to: addDay(now, -14) },
  MORE_THAN_30_DAYS_AGO: { from: undefined, to: addDay(now, -30) },
})

export const makeDurationsTwoWeeks = (
  now: Date,
): { [K in Exclude<DurationType, 'LAST_A_WEEK_AGO'>]: { from?: Date; to?: Date } } => ({
  LAST_2_WEEKS_AGO: { from: addDay(now, -14), to: undefined },
  STRAIGHT_NEAR_30_DAYS_AGO: { from: addDay(now, -30), to: addDay(now, -14) },
  MORE_THAN_30_DAYS_AGO: { from: undefined, to: addDay(now, -30) },
})
export const excludeDisableOkr = <T extends NodeForExcludeDisableFragment>(
  okrNodes: ReadonlyArray<T>,
): ReadonlyArray<T> =>
  okrNodes
    .filter((okrNode) => !okrNode.objective.isDisabled)
    .map((okrNode) => ({
      ...okrNode,
      keyResults: okrNode.keyResults.filter((keyResult) => !keyResult.isDisabled),
    }))

export type BaseDisplayOrderItem = {
  displayOrder: number
}

export const newDisplayOrder = <T extends BaseDisplayOrderItem>(
  items: ReadonlyArray<T>,
  sourceIndex: number,
  destinationIndex: number,
): number => {
  const dispo = items[destinationIndex].displayOrder || 0

  // +1によるインクリメントをリセットしたいため、要素を1番目に移動した場合は、displayOrderをリセットする
  if (destinationIndex === 0) return 0

  // 要素の位置を後ろにするためには、押し除ける要素のdisplayOrder＋１を指定する必要がある
  if (destinationIndex > sourceIndex) return dispo + 1

  return dispo
}

export const reOrderingItems = <T extends BaseDisplayOrderItem>(
  items: ReadonlyArray<T>,
  sourceIndex: number,
  destinationIndex: number,
): Array<T> | null => {
  // 移動していない場合は処理を行わない
  if (sourceIndex === destinationIndex) return null

  // displayOrderをセットする
  const newIndex = newDisplayOrder(items, sourceIndex, destinationIndex)
  let indexToShift = newIndex
  const ret = items.map((r, index) => {
    if (index === sourceIndex) {
      return {
        ...r,
        displayOrder: newIndex,
      }
    }
    // 移動先が後ろ(sourceIndex < destinationIndex)ならindex > destinationIndex、移動先が前(sourceIndex > destinationIndex)ならindex >= destinationIndex条件
    if (
      index > destinationIndex ||
      (sourceIndex > destinationIndex && destinationIndex === index)
    ) {
      indexToShift += 1
      return {
        ...r,
        displayOrder: indexToShift,
      }
    }
    return r
  })

  const [reorderedItem] = ret.splice(sourceIndex, 1)
  ret.splice(destinationIndex, 0, reorderedItem)

  return ret
}

export const useOkrRefetchQueries = (): Array<DocumentNode> => {
  const location = useLocation()
  const isMap = [trees, treesInCurrentTerm].some((path) => matchPath({ path }, location.pathname))
  const isOKR = [okr].some((path) => matchPath({ path }, location.pathname))
  const isHomeOkr = [homeOkr].some((path) => matchPath({ path }, location.pathname))
  const isOneOnOneDetail = [oneOnOneDetail].some((path) => matchPath({ path }, location.pathname))

  return new Array<DocumentNode>()
    .concat(isHomeOkr ? [FindUserByIdDocument] : [])
    .concat(isMap ? [OkrNodesDocument] : [])
    .concat(isOKR ? [OkrDocument, OkrNodeForCheckinSummariesDocument] : [])
    .concat(isOneOnOneDetail ? [UserAssignOkrDocument] : [])
}

export const useOkrRefetchQueriesWithOkrModalInitQuery = (): Array<DocumentNode> => {
  const isOpenOkrModal = useIsOpenOkrModal()
  const refetchQueries = useOkrRefetchQueries()

  // MEMO: OKR削除時にInitDocumentが存在するとモーダルが閉じなかったため、分割している
  return refetchQueries.concat(isOpenOkrModal ? [InitDocument] : [])
}
