import { IconTextButton } from '@resily/geisha'
import { MouseEventHandler, useCallback, forwardRef, useState } from 'react'

import { useTranslation } from '../../../../i18n'

import { MembersInput, Props as MembersInputProps } from './MembersInput'
import { TargetSettingInput, Props as TargetSettingInputProps } from './TargetSettingInput'
import { useStyles } from './index.styles'

export type Props = MembersInputProps &
  TargetSettingInputProps & {
    onOpen?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  }

export const OtherFields = forwardRef<HTMLDivElement, Props>(
  (
    { onOpen, users, groups, targetSetting, setTargetSetting, ownerId, memberIds, setMembers },
    ref,
  ) => {
    const { t } = useTranslation()
    const styles = useStyles()

    const [open, setOpen] = useState<boolean>(false)
    const handleClickCollapse = useCallback<MouseEventHandler<HTMLButtonElement>>(
      (event) => {
        const newOpen = !open
        setOpen(newOpen)
        // 開く場合のみ、onClickExpandを呼ぶ
        if (newOpen) {
          onOpen?.(event)
        }
      },
      [onOpen, open],
    )

    return (
      <div ref={ref} css={styles.root}>
        <div css={styles.toggle}>
          <IconTextButton
            variant="secondary"
            iconType={open ? 'arrow2' : 'arrow3'}
            css={styles.label}
            onClick={handleClickCollapse}
          >
            {t('DETAIL_SETTING')}
          </IconTextButton>
        </div>
        {open && (
          <div css={styles.others}>
            <MembersInput
              users={users}
              groups={groups}
              ownerId={ownerId}
              memberIds={memberIds}
              setMembers={setMembers}
            />
            <TargetSettingInput targetSetting={targetSetting} setTargetSetting={setTargetSetting} />
          </div>
        )}
      </div>
    )
  },
)
