import { useCallback, useEffect, useState, useContext } from 'react'

import { AlertModalContext } from '../../../../../../../contexts/AlertModalContext'

import { KeyResultFragment } from './graphql'
import type { KeyResult } from './types'

import type { Props } from './index'

export const useInjection = ({
  useWeighting,
  keyResults,
  onUpdate,
}: Props): {
  keyResults: Array<KeyResult>
  onKeyResultWeightChange: (changedKeyResultIndex: number) => (weight: number) => void
  totalWeight: number
  hasTotalWeightError: boolean
  onClickUpdateButton: () => void
  editingWeight: boolean
  setEditingWeight: (value: boolean) => void
} => {
  const [keyResultsState, setKeyResultsState] = useState<Array<KeyResult>>(
    makeInitialState({ useWeighting, keyResults }),
  )

  const { status, closeAlertModal, changeEditingType } = useContext(AlertModalContext)

  // 重み付け設定タブが表示されなくなった場合、警告モーダルを表示しないようにする（status.typeをNONEに変更する）
  useEffect(
    () => () => closeAlertModal(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  // Tab Close or Reload Page
  useEffect(() => {
    if (status.type === 'WEIGHT') {
      onbeforeunload = (event) => {
        event.preventDefault()
        event.returnValue = ''
      }
    } else {
      onbeforeunload = null
    }
    return () => {
      onbeforeunload = null
    }
  }, [status.type])

  const onKeyResultWeightChange = useCallback(
    (changedKeyResultIndex: number) => (weight: number) => {
      // 重み付け設定が初期値と違う場合遷移ブロックする
      // NOTE: 2回目のレンダリングでweightがstringになってしまうため暫定処理
      if (Number(weight) !== keyResultsState.find((_, i) => i === changedKeyResultIndex)?.weight) {
        // 重み付け設定を編集中にする
        changeEditingType('WEIGHT')
      }
      setKeyResultsState(
        keyResultsState.map((keyResult, index) =>
          changedKeyResultIndex === index
            ? {
                ...keyResult,
                weight: Number(weight),
              }
            : keyResult,
        ),
      )
    },
    [keyResultsState, setKeyResultsState, changeEditingType],
  )

  const totalWeight = keyResultsState.reduce(
    (total, keyResult) => total + (keyResult.weight || 0),
    0,
  )
  const hasTotalWeightError = totalWeight !== 100

  const onClickUpdateButton = useCallback(() => {
    onUpdate(keyResultsState)
  }, [onUpdate, keyResultsState])

  return {
    keyResults: keyResultsState,
    onKeyResultWeightChange,
    totalWeight,
    hasTotalWeightError,
    onClickUpdateButton,
    editingWeight: status.type === 'WEIGHT',
    setEditingWeight: (isEditing: boolean) => {
      if (isEditing) {
        changeEditingType('WEIGHT')
      } else {
        closeAlertModal()
      }
    },
  }
}

export const makeInitialState = <T extends Pick<KeyResultFragment, 'weight'>>({
  useWeighting,
  keyResults,
}: {
  useWeighting: boolean
  keyResults: ReadonlyArray<T>
}): Array<T & { weight: number; previousWeight: number | undefined }> => {
  if (useWeighting) {
    return keyResults.map((kr) => ({
      ...kr,
      weight: kr.weight || 0,
      previousWeight: undefined,
    }))
  }

  const quotient = Math.trunc(100 / keyResults.length)
  const remainder = 100 % keyResults.length

  return keyResults.map((kr, i) => ({
    ...kr,
    weight: quotient + (i === keyResults.length - 1 ? remainder : 0),
    previousWeight: kr.weight || undefined,
  }))
}
