import { decycle } from '../json'

/**
 * 親子関係を持つノードの最小定義
 */
export interface BaseNodeType {
  // ノードID
  readonly id: string
  // ノードの深さ
  readonly depth: number
  // 子ノードID
  readonly childNodeIds: ReadonlyArray<string>
}

/**
 * 親子関係を持つノード
 */
export type Node<T extends BaseNodeType> = T & {
  // 親ノード
  parent?: WeakMap<Node<T>, Node<T>>
  // 子ノード
  children: WeakMap<Node<T>, Array<Node<T>>>
}

/**
 * 指定の親ノードの子孫ノードを取得する
 * 深さ優先探索で実装
 * @param node 対象ノード
 * @returns 子孫ノード
 */
export const descendants = <T extends BaseNodeType>(node: Node<T>): Array<Node<T>> => {
  const c = node.children.get(node)
  return c ? c.concat(c.flatMap(descendants)) : []
}

/**
 * 指定のノードの祖先ノードを取得する
 * @param node 対象ノード
 * @returns 祖先ノード
 */
export const ancestors = <T extends BaseNodeType>(node: Node<T>): Array<Node<T>> => {
  const p = node.parent?.get(node)
  return p ? [p, ...ancestors(p)] : []
}

/**
 * 指定のノードの兄弟ノードを取得する
 * @param node 対象ノード
 * @returns 兄弟ノード
 */
export const siblings = <T extends BaseNodeType>(node: Node<T>): Array<Node<T>> => {
  const p = node.parent?.get(node)
  if (!p) return []
  return p.children.get(p)?.filter((n) => n.id !== node.id) ?? []
}

export const serialize = <T extends BaseNodeType>(node: T): string => JSON.stringify(decycle(node))

export const isEqual = <T extends BaseNodeType>(lhs: T, rhs: T): boolean =>
  lhs.id === rhs.id && serialize(lhs) === serialize(rhs)
