import { Box, Text } from 'grommet'
import { useRef, useState, useEffect } from 'react'

import { useTranslation } from '../../../i18n'
import { FontSizeAlias, fontSize } from '../../../styles/font'
import { Avatar, sizes as AvatarSizes } from '../Avatar'
import { UserTooltipNew } from '../TooltipNew'

export type Props = {
  nameFontSize?: FontSizeAlias
  avatarSize?: keyof typeof AvatarSizes
  firstName: string
  lastName: string
  avatarUrl: string | undefined
  showAll?: boolean
  isUserDisabled: boolean
}

export const UserOption: React.FC<Props> = ({
  nameFontSize = 'small',
  avatarSize = 'small',
  firstName,
  lastName,
  avatarUrl,
  showAll = false,
  isUserDisabled,
}) => {
  const { t } = useTranslation()
  const nameTextRef = useRef<HTMLSpanElement>(null)
  const boxRef = useRef<HTMLDivElement>(null)
  const [tooltipDisabled, setTooltipDisabled] = useState(false)
  const [boxWidth, setBoxWidth] = useState<number | undefined>(undefined)

  // domのレンダリング後にテキストが省略されているか判定してツールチップ表示設定を行う
  useEffect(() => {
    if (!boxRef.current) {
      return
    }

    const resizeObserver = new ResizeObserver(
      (entries: Array<{ target: { id: string }; contentRect: { width: number } }>) => {
        if (boxRef.current && entries[0].target.id === boxRef.current.id) {
          setBoxWidth(entries[0].contentRect.width)
        }
      },
    )
    resizeObserver.observe(boxRef.current)

    // eslint-disable-next-line consistent-return
    return () => resizeObserver.disconnect()
  }, [boxRef])

  useEffect(() => {
    if (showAll) {
      setTooltipDisabled(true)
      return
    }
    if (boxWidth === undefined || !nameTextRef.current) return
    const { width: avatarWidth } = AvatarSizes[avatarSize]
    const gap = 8
    const nameTextWidth = boxWidth - avatarWidth - gap
    setTooltipDisabled(nameTextRef.current?.getBoundingClientRect().width <= nameTextWidth)
  }, [firstName, lastName, nameTextRef, boxWidth, showAll, avatarSize])

  return (
    <Box
      ref={boxRef}
      wrap
      direction="row"
      gap="6px"
      align="center"
      css={{
        flexWrap: showAll ? 'wrap' : 'nowrap',
      }}
    >
      <Avatar
        size={avatarSize}
        firstName={firstName}
        lastName={lastName}
        avatarUrl={avatarUrl}
        hoverStyle={{ backgroundColor: 'none' }}
        tooltipDisabled={tooltipDisabled}
        isUserDisabled={isUserDisabled}
      />
      <Box>
        <UserTooltipNew
          title={t('FULL_NAME', { firstName, lastName })}
          disabled={tooltipDisabled}
          isUserDisabled={isUserDisabled}
        >
          <Text
            ref={nameTextRef}
            css={{
              ...fontSize(nameFontSize),
              display: 'block',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: showAll ? 'normal' : 'nowrap',
            }}
          >
            {!isUserDisabled ? (
              <span>{t('FULL_NAME', { firstName, lastName })}</span>
            ) : (
              <span>
                ({t('DISABLING')})
                <span css={{ textDecoration: 'line-through' }}>
                  {t('FULL_NAME', { firstName, lastName })}
                </span>
              </span>
            )}
          </Text>
        </UserTooltipNew>
      </Box>
    </Box>
  )
}

UserOption.displayName = 'UserOption'
