import { DataTable } from 'grommet'

import { color } from '../../../../../styles/newColors'

import { useDataTableColumns } from './hooks/useDataTableColumns'
import { useMakeInvitingUserData } from './hooks/useMakeData'
import { InvitingUserTableProps } from './types'

export const InvitingUserTable: React.FC<InvitingUserTableProps> = ({ users, onClickRow }) => {
  const invitingUsersData = useMakeInvitingUserData(users)
  const columns = useDataTableColumns()

  return (
    <DataTable
      columns={columns}
      border={{
        header: {
          color: color('border-bk-30'),
          side: 'bottom',
          size: '1px',
        },
        body: {
          color: color('border-bk-10'),
          side: 'bottom',
          size: '1px',
        },
      }}
      data={invitingUsersData}
      pin="header"
      sortable
      onClickRow={onClickRow}
    />
  )
}

InvitingUserTable.displayName = 'InvitingUserTable'
