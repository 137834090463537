import { Box, BoxTypes } from 'grommet'
import React from 'react'

export type Props = BoxTypes

export const Section: React.FC<Props> = (props) => (
  <Box gap="medium" height={{ min: 'auto', max: 'auto' }} {...props} />
)

Section.displayName = 'Section'
