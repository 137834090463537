import {
  ELEMENT_DEFAULT,
  ELEMENT_OL,
  ELEMENT_UL,
  ELEMENT_LI,
  ELEMENT_LIC,
  KEY_LIST_STYLE_TYPE,
  ListStyleType,
  Value,
} from '@udecode/plate'

import { ListItemElement, OrderedListElement, UnorderedListElement } from '../../types'
import { serializeText } from '../serializer/text'

export const convertListToIndentList = (
  v: OrderedListElement | UnorderedListElement,
  indent: number,
): Value =>
  v.children.flatMap((li: ListItemElement, liIdx: number) => {
    if (li.type !== ELEMENT_LI) return []
    return li.children.flatMap((lic) => {
      // licがあるべき場所になぜかpがいる事がある対策
      if ([ELEMENT_LIC, ELEMENT_DEFAULT].includes(lic.type)) {
        return [
          {
            type: ELEMENT_DEFAULT,
            children: lic.children,
            indent,
            [KEY_LIST_STYLE_TYPE]:
              v.type === ELEMENT_OL ? ListStyleType.Decimal : ListStyleType.Disc,
            // root直下のインデントリストの最初の行はlistStart無し
            // (おそらくあっても動作に影響はないはずだがPlateが作ってないのでそれに合わせる)
            ...(indent !== 1 || liIdx > 0 ? { listStart: liIdx + 1 } : {}),
          },
        ]
      }
      // リストの子として不正なデータが万が一あった場合は文字列化して処理する
      if (![ELEMENT_OL, ELEMENT_UL].includes(lic.type)) {
        return {
          type: ELEMENT_DEFAULT,
          children: [{ text: serializeText([lic]) }],
        }
      }
      return convertPlateFragmentFromListToIndentList([lic], indent + 1)
    })
  })

export const convertPlateFragmentFromListToIndentList = (fragment: Value, indent = 1): Value =>
  fragment.flatMap((v) => {
    if (v.type === ELEMENT_OL || v.type === ELEMENT_UL) {
      return convertListToIndentList(v as OrderedListElement | UnorderedListElement, indent)
    }

    return [v]
  })
