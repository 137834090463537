import {
  FileFragment,
  OkrDetailObjectiveFragment,
  DocumentFragment,
} from '../../../pages/ObjectiveDetailEdit/graphql'
import { DetailEditCard } from '../../ui/DetailEditCard'

export type Props = {
  objective: OkrDetailObjectiveFragment
  updateObjective: (
    objective: OkrDetailObjectiveFragment,
    beforeDescription: DocumentFragment,
    redirect: boolean,
  ) => void
  onClickCancel: () => void
  setNeedBlock: (value: boolean) => void
}

export const ObjectiveDetailEditPageCard: React.FC<Props> = ({
  objective,
  updateObjective,
  onClickCancel,
  setNeedBlock,
}) => {
  const handleUpdateObjectiveDetail = (
    objectiveName: string,
    treeJson: string,
    text: string,
    beforeTreeJson: string,
    beforePlainText: string,
  ) => {
    updateObjective(
      {
        ...objective,
        name: objectiveName,
        description: {
          treeJson,
          plainText: text,
        },
      },
      {
        treeJson: beforeTreeJson,
        plainText: beforePlainText,
      },
      true,
    )
  }

  const handleUploadFile = (file: FileFragment) => {
    updateObjective(
      {
        ...objective,
        description: {
          treeJson: objective.description?.treeJson || '',
          plainText: objective.description?.plainText || '',
        },
        attachments: [...objective.attachments, file],
      },
      {
        treeJson: objective.description?.treeJson || '',
        plainText: objective.description?.plainText || '',
      },
      false,
    )
  }

  const handleDeleteAttachment = (newFiles: ReadonlyArray<FileFragment>) => {
    updateObjective(
      {
        ...objective,
        description: {
          treeJson: objective.description?.treeJson || '',
          plainText: objective.description?.plainText || '',
        },
        attachments: newFiles,
      },
      {
        treeJson: objective.description?.treeJson || '',
        plainText: objective.description?.plainText || '',
      },
      false,
    )
  }

  return (
    <DetailEditCard
      editorId={`detailedit-o-${objective.id}`}
      title={objective.name}
      body={objective.description?.treeJson}
      plainText={objective.description?.plainText || ''}
      attachments={objective.attachments}
      onClickCancel={onClickCancel}
      onClickDeleteAttachments={handleDeleteAttachment}
      onClickSave={handleUpdateObjectiveDetail}
      onUpdateFile={handleUploadFile}
      onEdit={setNeedBlock}
    />
  )
}

ObjectiveDetailEditPageCard.displayName = 'ObjectiveDetailEditPageCard'
