import { yupResolver } from '@hookform/resolvers/yup'
import { Box } from 'grommet'
import { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'

import { SelectedUserTags } from '../../../../components/domain/SelectedUserTags'
import { PageContent } from '../../../../components/pageContent'
import { Button } from '../../../../components/ui/Button'
import { DateTime } from '../../../../components/ui/DateTime'
import { FormField } from '../../../../components/ui/FormField'
import { Input } from '../../../../components/ui/Input'
import { StyledText } from '../../../../components/ui/StyledText'
import { useTranslation } from '../../../../i18n'
import { Screen } from '../../../../lib/screen'
import { useTracking } from '../../../../lib/tracking'
import { color } from '../../../../styles/newColors'
import * as urls from '../../../../urls'
import { UserFieldsFragment } from '../../api/graphql'
import { CancelEditModal } from '../CancelModal'
import { DeleteGroupModal } from '../DeleteGroupModal'
import { EditButtonSection } from '../EditButtonSection'

import { GroupDetailFormValue, GroupDetailProps } from './types'

export const GroupDetail: React.FC<GroupDetailProps> = ({
  group,
  updateGroup,
  deleteGroup,
  attachAdmin,
  detachAdmin,
  attachMember,
  detachMember,
  ...props
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  useTracking(t('GROUP_DETAIL_PAGE_TITLE', { groupName: group.name }), Screen.GroupSetting)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<GroupDetailFormValue>({
    mode: 'onChange',
    resolver: yupResolver(
      Yup.object().shape({
        name: Yup.string().required(t('REQUIRED_ERROR')).min(1, t('MIN_LENGTH_ERROR')),
        permission: Yup.bool(),
      }),
    ),
    defaultValues: {
      name: t('EMPTY_GROUP') === group.name ? '' : group.name,
    },
  })
  const [deleteModalVisibility, setDeleteModalVisibility] = useState(false)
  const [cancelModalVisibility, setCancelModalVisibility] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  // TODO: react-hook-form v4で不要になるArrayの管理コード
  const [admins, setAdmins] = useState<ReadonlyArray<UserFieldsFragment>>([])
  const [members, setMembers] = useState<ReadonlyArray<UserFieldsFragment>>([])

  useEffect(() => {
    setAdmins(
      // 有効-無効の順にユーザーを並び替え
      group.admins.filter((a) => !a.isDisabled).concat(group.admins.filter((a) => a.isDisabled)),
    )
  }, [group.admins])

  useEffect(() => {
    setMembers(
      // 有効-無効の順にユーザーを並び替え
      group.members.filter((m) => !m.isDisabled).concat(group.members.filter((m) => m.isDisabled)),
    )
  }, [group.members])

  return (
    <PageContent
      breadcrumbs={{
        url: urls.group,
        items: [
          {
            breadcrumbName: 'setting',
          },
          {
            breadcrumbName: 'settingGroups',
          },
          {
            breadcrumbName: 'group',
            ...group,
          },
        ],
      }}
      layout={{
        css: {
          padding: '48px',
        },
      }}
      contentBackgroundColor={color('background-bk-5')}
    >
      <section {...props}>
        <Box gap="24px">
          <Box direction="row" justify="between" align="center">
            <StyledText size="xlarge" fontStyle="bold">
              {group.name}
            </StyledText>
            <Button
              newColor="resily-orange-100"
              weight="normal"
              size="s"
              onClick={() => setDeleteModalVisibility(!deleteModalVisibility)}
            >
              {t('DELETION_X', { x: t('GROUP') })}
            </Button>
            <DeleteGroupModal
              deleteItemName={t('GROUP_TAG')}
              deleteItemNameForBody={t('THIS_ITEM', { item: t('GROUP_TAG') })}
              isOpened={deleteModalVisibility}
              onClickCancel={() => {
                setDeleteModalVisibility(false)
              }}
              onClickOutside={() => {
                setDeleteModalVisibility(false)
              }}
              onClickDelete={async () => {
                setDeleteModalVisibility(false)
                deleteGroup()
              }}
            />
          </Box>
          <div
            css={{
              backgroundColor: color('white-100'),
              padding: '24px 32px',
              borderRadius: '8px',
            }}
          >
            <form
              onSubmit={handleSubmit((data) => {
                updateGroup(data.name || t('EMPTY_GROUP'))
                setIsEdit(false)
              })}
              css={{
                '& > *': {
                  marginTop: '20px',
                },
              }}
            >
              <FormField errorMessage={errors.name ? `${errors.name.message}` : ''}>
                <Box>
                  <StyledText color="text-bk-50" fontStyle="bold">
                    {t('NAME')}
                  </StyledText>
                  <Input
                    fieldStyle="bottomLine"
                    placeholder={t('EMPTY_GROUP')}
                    type="text"
                    onClick={() => setIsEdit(true)}
                    {...register('name')}
                  />
                </Box>
              </FormField>
              <div css={{ marginBottom: '16px' }}>
                <SelectedUserTags
                  title={t('GROUP_ADMIN')}
                  termId={urls.currentTag}
                  isAdmin
                  selectedUsers={admins}
                  userOptions={group.organization.users
                    .filter((user) => !members.some((u) => user.id === u.id))
                    .concat(admins.filter((a) => a.isDisabled))}
                  onAdd={(user) => attachAdmin(user.id)}
                  onRemove={(user) => detachAdmin(user.id)}
                />
              </div>
              <div css={{ marginBottom: '16px' }}>
                <SelectedUserTags
                  title={t('GROUP_MEMBER')}
                  termId={urls.currentTag}
                  selectedUsers={members}
                  userOptions={group.organization.users
                    .filter((user) => !admins.some((u) => user.id === u.id))
                    .concat(members.filter((m) => m.isDisabled))}
                  onAdd={(user) => attachMember(user.id)}
                  onRemove={(user) => detachMember(user.id)}
                />
              </div>
              {isEdit && <EditButtonSection onClick={() => setCancelModalVisibility(true)} />}
              <CancelEditModal
                isOpened={cancelModalVisibility}
                onClickCancel={() => {
                  setCancelModalVisibility(false)
                }}
                onClickOutside={() => {
                  setCancelModalVisibility(false)
                }}
                onClickDelete={async () => {
                  navigate(urls.groupsSettingAdmin)
                }}
              />
            </form>
          </div>
          <Box
            direction="row"
            gap="48px"
            css={{
              backgroundColor: color('white-100'),
              padding: '24px 32px',
              borderRadius: '8px',
            }}
          >
            <Box gap="12px">
              <StyledText color="text-bk-50" fontStyle="bold">
                {t('CREATED_ON')}
              </StyledText>
              <DateTime datetime={group.createdAt} withoutTime />
            </Box>
            <Box gap="12px">
              <StyledText color="text-bk-50" fontStyle="bold">
                {t('UPDATED_ON')}
              </StyledText>
              <DateTime datetime={group.updatedAt} withoutTime />
            </Box>
          </Box>
        </Box>
      </section>
    </PageContent>
  )
}

GroupDetail.displayName = 'GroupDetail'
