import { css } from '@emotion/react'

import { fontSize } from '../../../../../styles/font'
import { color } from '../../../../../styles/newColors'

export const rootCss = css({
  borderRadius: '8px',
  cursor: 'pointer',
  ':hover': {
    backgroundColor: color('hover-background-bk-5'),
  },
  padding: '16px',
})

export const contentsWrapperCss = css({
  display: 'grid',
  gridTemplateColumns: '16px auto',
  alignItems: 'center',
  columnGap: '8px',
  rowGap: '4px',
})

export const iconCss = css({
  gridRow: '1 / 2',
  gridColumn: '1 / 2',
})

export const titleCss = css({
  gridRow: '1 / 2',
  gridColumn: '2 / 3',
  ...fontSize('medium', 'bold'),
})

export const descriptionCss = css({
  gridRow: '2 / 3',
  gridColumn: '2 / 3',
  ...fontSize('small', 'regular'),
})
