import { Box } from 'grommet'

import { borderCss, layerCss } from './styles'

type Props = {
  children: React.ReactNode
}

export const LayerDiviser: React.FC<Props> = ({ children }) => (
  <Box direction="row" align="center" css={{ width: '100%' }}>
    <div css={layerCss}>{children}</div>
    <div css={borderCss} />
  </Box>
)
