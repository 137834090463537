import { useMemo } from 'react'
import { Controller } from 'react-hook-form'

import { useTranslation } from '../../../../../../i18n'
import { confidenceList } from '../../../../../../lib/domain/confidence'
import { newToOldConfidence, old2NewConfidence } from '../../../../../../lib/geisha/confidence'
import { ConfidenceSelectFormFieldProps } from '../../../components/CheckinModalForm/InputsArea/components/ConfidenceSelectFormField'
import { useGetFormMethods } from '../../contexts/FormProvider'
import { keyResultsKeyName } from '../../formSchema'

type ConfidenceSelectControllerProps = {
  name: `${typeof keyResultsKeyName}.${number}.level`
  children: (args: ConfidenceSelectFormFieldProps) => React.ReactNode
}

export const ConfidenceSelectController: React.FC<ConfidenceSelectControllerProps> = ({
  name,
  children,
}) => {
  const { control } = useGetFormMethods()
  const { t } = useTranslation()

  const options = useMemo(
    () =>
      confidenceList.map<ConfidenceSelectFormFieldProps['options'][0]>(([level, i18nKey]) => ({
        value: old2NewConfidence(level) ?? 3,
        label: t(i18nKey),
      })) as ConfidenceSelectFormFieldProps['options'],
    [t],
  )
  const defaultConfidenceLevel = options[2].value

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <>
          {children({
            options,
            value: old2NewConfidence(value) ?? defaultConfidenceLevel,
            onSelect: (level) => onChange(newToOldConfidence(level)),
          })}
        </>
      )}
    />
  )
}
