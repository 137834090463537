import { useMemo } from 'react'

import { useTranslation } from '../../../../i18n'
import { componentNames, featureNames, generateTestId } from '../../../../lib/testId'
import { TabsWithChildren, useTab, TabType } from '../Common/TabsWithChildren'
import { useOkrModalQueryParams } from '../hooks/useOkrModalQueryParams'
import { KeyResultTab, KeyResultTabObj } from '../types'

import { ActionPlanContainer } from './ActionPlan'
import { ActivityContainer } from './Activity'
import {
  KeyResultDescriptionContainer,
  Props as KeyResultDescriptionContainerProps,
} from './KeyResultDescription'
import { KeyResultDrawer } from './KeyResultDrawer'
import { NoteContainer } from './Note'

export type KeyResultDrawerContainerProps = {
  keyResults:
    | ReadonlyArray<NonNullable<KeyResultDescriptionContainerProps['keyResult']>>
    | null
    | undefined
  onDeleteKeyResult: () => void
}

export const KeyResultDrawerContainer: React.VFC<KeyResultDrawerContainerProps> = ({
  keyResults,
  onDeleteKeyResult,
}) => {
  const { t } = useTranslation()
  const { keyResultId, keyResultTab, setKeyResultTab, setObjectiveId } = useOkrModalQueryParams()
  const [tab, handleClickTab] = useTab<KeyResultTab>(
    keyResultTab ?? KeyResultTabObj['m-kr-description'],
    setKeyResultTab,
  )

  const keyResultDescriptionTab = useMemo<TabType<KeyResultTab>>(
    () => ({
      value: KeyResultTabObj['m-kr-description'],
      name: `${t('X_DETAIL', { x: t('KEY_RESULT') })}`,
      children: (
        <KeyResultDescriptionContainer
          keyResult={keyResults?.find((kr) => kr.id === keyResultId)}
          onUpdateParentObjective={setObjectiveId}
          onDelete={onDeleteKeyResult}
        />
      ),
    }),
    [keyResultId, keyResults, onDeleteKeyResult, setObjectiveId, t],
  )

  const tabs = useMemo<ReadonlyArray<TabType<KeyResultTab>>>(
    () => [
      keyResultDescriptionTab,
      {
        value: KeyResultTabObj['m-kr-activity'],
        name: t('ACTIVITY'),
        children: <ActivityContainer />,
      },
      {
        value: KeyResultTabObj['m-kr-action-plan'],
        name: t('ACTION_PLAN'),
        children: <ActionPlanContainer />,
      },
      {
        value: KeyResultTabObj['m-kr-note'],
        name: t('NOTE'),
        children: <NoteContainer />,
      },
    ],
    [keyResultDescriptionTab, t],
  )

  return (
    <KeyResultDrawer>
      <TabsWithChildren<KeyResultTab>
        tab={tab}
        tabs={tabs}
        tabName="m-kr-tab"
        data-testid={generateTestId(featureNames.okrModal, componentNames.krTab)}
        tabContentTestId={generateTestId(featureNames.okrModal, componentNames.krTabContent)}
        onClickTab={handleClickTab}
      />
    </KeyResultDrawer>
  )
}
KeyResultDrawerContainer.displayName = 'KeyResultDrawerContainer'
