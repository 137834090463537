import { Box } from 'grommet'

import { Avatar } from '../../../components/ui/Avatar'
import { StyledText } from '../../../components/ui/StyledText/index'
import { useTranslation } from '../../../i18n'

import { OneOnOneUserFragment } from './graphql'

const GROUP_NAME_SEPARATOR = ' | '

export type Props = {
  user: OneOnOneUserFragment | undefined
}

export const AvatarArea: React.VFC<Props> = ({ user }) => {
  const { t } = useTranslation()

  return (
    <Box direction="row" align="center" width="100%" css={{ paddingRight: '28px' }}>
      <Box direction="row" align="center" flex="grow">
        <div css={{ marginRight: '16px' }}>
          {user && (
            <Avatar
              firstName={user.firstName}
              lastName={user.lastName}
              avatarUrl={user.avatar?.url}
              size="large"
              isUserDisabled={user.isDisabled}
            />
          )}
        </div>
        <Box>
          <Box direction="row" margin={{ bottom: '8px' }}>
            <Box
              margin={{ right: '8px' }}
              css={{
                fontWeight: 'bold',
                fontSize: '18px',
              }}
            >
              {user && t('FULL_NAME', { firstName: user.firstName, lastName: user.lastName })}
            </Box>
          </Box>
          <StyledText
            color="text-bk-50"
            css={{
              fontSize: '12px',
              width: '100%',
            }}
          >
            {user?.groups.flatMap((group) => [group.name]).join(GROUP_NAME_SEPARATOR)}
          </StyledText>
        </Box>
      </Box>
    </Box>
  )
}

AvatarArea.displayName = 'AvatarArea'
