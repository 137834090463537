import { useCallback, useEffect, useState } from 'react'

type ReturnType = {
  online: boolean
}
export const useNetworkStatus = (): ReturnType => {
  const [online, setOnline] = useState<boolean>(true)

  const updateOnline = useCallback(() => {
    setOnline(true)
  }, [])
  const updateOffline = useCallback(() => {
    setOnline(false)
  }, [])

  useEffect(() => {
    setOnline(window.navigator.onLine)
    window.addEventListener('online', updateOnline)
    window.addEventListener('offline', updateOffline)

    return () => {
      window.removeEventListener('online', updateOnline)
      window.removeEventListener('offline', updateOffline)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { online }
}
