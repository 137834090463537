import { useMemo } from 'react'

import { TermFragment, UserFragment } from '../graphql'

export const useFilter = (
  usersResp: ReadonlyArray<UserFragment> | undefined,
  term: TermFragment | undefined,
  selectedGroupIds: ReadonlyArray<string>,
  selectedUserIds: ReadonlyArray<string>,
): {
  filteredUsers: ReadonlyArray<UserFragment>
} => {
  const filteredUsers = useMemo(() => {
    if (!usersResp || !term) {
      return []
    }

    return usersResp
      .filter((u) => u.createdAt < term.endDate)
      .filter((u) => {
        if (selectedGroupIds.length === 0 && selectedUserIds.length === 0) {
          return true
        }

        if (selectedUserIds.includes(u.id)) {
          return true
        }
        if (u.groups.some((g) => selectedGroupIds.includes(g.id))) {
          return true
        }

        return false
      })
  }, [usersResp, term, selectedGroupIds, selectedUserIds])

  return { filteredUsers }
}
