/**
 * String LiteralのUnion型を判定する関数.
 *
 * 引数のarrは const t = ['a', 'b'] as const のような配列を想定.
 */
export const isUnionType =
  <T extends string>(arr: ReadonlyArray<T>) =>
  (s: string): s is T =>
    arr.some((value) => value === s)

/**
 * ['a', 'b'] as const のような要素がそれぞれ異なり要素数が2のタプルから引数の値以外を返す関数.
 */
export const not =
  <T, K>(t: readonly [T, K extends T ? never : K]) =>
  (s: T | K): T | K =>
    t.filter((v) => v !== s)[0]
