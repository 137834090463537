import { createContext, useState } from 'react'

type ContextType = {
  okrTermId: string | null
  updateOkrTermId: (okrTermId: string | null) => void
}

export const OkrTermIdContext = createContext<ContextType>({
  okrTermId: null,
  updateOkrTermId: () => {
    throw new Error('Should use OkrTermIdContext.Provider')
  },
})

export const OkrTermIdContextProvider: React.FC = ({ children }) => {
  const [okrTermId, updateOkrTermId] = useState<string | null>(null)
  return (
    <OkrTermIdContext.Provider value={{ okrTermId, updateOkrTermId }}>
      {children}
    </OkrTermIdContext.Provider>
  )
}

OkrTermIdContextProvider.displayName = 'OkrTermIdContextProvider'
