import { Box } from 'grommet'
import { Fragment, useCallback, useEffect, useState } from 'react'

import { useTranslation } from '../../../i18n'
import { border } from '../../../styles/border'
import { Card } from '../../ui/Card'
import { StyledText } from '../../ui/StyledText'

import { NewCommentEditor } from './NewCommentEditor'
import { NoteDetailCommentItem } from './NoteDetailCommentItem'
import { File } from './graphql'
import { CommonCommentItemNoteFragment } from './type'

export type Props<T extends CommonCommentItemNoteFragment> = {
  noteId: string
  author: {
    userId: string
    firstName: string
    lastName: string
    avatarUrl: string
    isDisabled: boolean
  }
  setNeedBlock: (need: boolean) => void
  comments: ReadonlyArray<T>
  createNoteComment: (
    parentNoteId: string,
    body: string,
    plainText: string,
    attachments: ReadonlyArray<File>,
  ) => void
  updateNoteComment: (comment: T) => void
  deleteNoteComment: (commentId: string) => void
  createNoteCommentReaction: (noteCommentId: string, emoji: string) => void
  deleteNoteCommentReaction: (noteCommentId: string, reactedId: string) => void
}

export const NoteCommentCard = <T extends CommonCommentItemNoteFragment>({
  noteId,
  author,
  setNeedBlock,
  comments,
  createNoteComment,
  updateNoteComment,
  deleteNoteComment,
  createNoteCommentReaction,
  deleteNoteCommentReaction,
  ...props
}: Props<T>): JSX.Element => {
  const { t } = useTranslation()
  const [changedCommentIds, setChangedComments] = useState<ReadonlyArray<string | 'newCommentId'>>(
    [],
  )
  const removeChangedComment = useCallback(
    (commentId: string) => {
      setChangedComments(changedCommentIds.filter((id) => id !== commentId))
    },
    [changedCommentIds],
  )
  const addChangedComment = useCallback(
    (commentId: string) => {
      if (changedCommentIds.some((id) => id === commentId)) return
      setChangedComments([...changedCommentIds, commentId])
    },
    [changedCommentIds],
  )

  useEffect(() => {
    setNeedBlock(!!changedCommentIds.length)
  }, [changedCommentIds.length, setNeedBlock])

  return (
    <Card {...props} css={{ padding: 24 }}>
      <Box css={{ borderBottom: '1px solid #D3D4D9', padding: '0 0 16px 8px' }}>
        <StyledText size="large" weight="bold">
          {t('COMMENT_TITLE')}
        </StyledText>
      </Box>
      <Box css={{ padding: '0 8px' }}>
        {comments
          .concat()
          .sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1))
          .map((comment: T) => (
            <Fragment key={comment.id}>
              <NoteDetailCommentItem
                comment={comment}
                update={updateNoteComment}
                deleteComment={deleteNoteComment}
                createReaction={createNoteCommentReaction}
                deleteReaction={deleteNoteCommentReaction}
                css={{
                  marginTop: 16,
                }}
                onChanged={(changed) => {
                  if (changed) {
                    addChangedComment(comment.id)
                  } else {
                    removeChangedComment(comment.id)
                  }
                }}
              />
              <div
                css={{
                  width: '100%',
                  borderBottom: border('simple-10'),
                }}
              />
            </Fragment>
          ))}
        <NewCommentEditor
          author={author}
          create={useCallback(
            (b, p, attachments) => {
              createNoteComment(noteId, b, p, attachments)
            },
            [createNoteComment, noteId],
          )}
          css={{ marginTop: 26 }}
          onChanged={useCallback(
            (changed) => {
              if (changed) {
                // 新しいコメントはidを持っていないので仮のidを入れる
                addChangedComment('newCommentId')
              } else {
                removeChangedComment('newCommentId')
              }
            },
            [addChangedComment, removeChangedComment],
          )}
        />
      </Box>
    </Card>
  )
}
