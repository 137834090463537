import { css } from '@emotion/react'

import { ApplicationProps } from '../../components/domain/ApplicationCard'
import { Icon } from '../../components/ui/Icon'

const styles = {
  thumbnail: css({
    display: 'flex',
    alignItems: 'center',
  }),
  logo: css({ width: 32, height: 32 }),
  logoText: css({ marginLeft: 14, width: 'auto', height: 14 }),
} as const

const Thumbnail: JSX.Element = (
  <div css={styles.thumbnail}>
    <Icon type="smarthrLogo" css={styles.logo} />
    <Icon type="smarthr" css={styles.logoText} />
  </div>
)

export type Props = {
  enabled: boolean
  onClickSetting: () => void
  onClickIntegrate: () => void
  onClickSegregate: () => void
}
export const SmartHRApp = ({
  onClickIntegrate,
  onClickSegregate,
  onClickSetting,
  enabled,
}: Props): ApplicationProps => ({
  appName: 'SmartHR',
  applicationUrl: 'https://smarthr.jp',
  enabled,
  thumbnail: Thumbnail,
  onClickSetting,
  handleIntegrate: onClickIntegrate,
  handleSegregate: onClickSegregate,
})
