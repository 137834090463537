import { Box, BoxProps } from 'grommet'
import { Map, IconProps } from 'grommet-icons'
import * as React from 'react'

export type Props = BoxProps & {
  icon?: boolean | IconProps
  children: React.ReactNode
}

const isIconProps = (icon: Props['icon']): icon is IconProps =>
  icon !== undefined && typeof icon !== 'boolean'

export const ObjectiveName: React.FC<Props> = ({ icon, children, ...props }) => (
  <Box as="span" direction="row" align="center" gap="xxsmall" {...props}>
    {icon !== undefined && (isIconProps(icon) ? <Map {...icon} /> : <Map />)}
    <>{children}</>
  </Box>
)

ObjectiveName.displayName = 'ObjectiveName'
