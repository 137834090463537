import {
  EditorId,
  ELEMENT_BLOCKQUOTE,
  ELEMENT_CODE_BLOCK,
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_H4,
  ELEMENT_H5,
  ELEMENT_H6,
  ELEMENT_HR,
  ELEMENT_OL,
  ELEMENT_TODO_LI,
  ELEMENT_UL,
  StyledProps,
  ToolbarProps,
  usePlateEditorState,
  withPlateProvider,
} from '@udecode/plate'
import { Button, Box, Drop, ThemeContext } from 'grommet'
import { Add } from 'grommet-icons'
import { useRef } from 'react'

import { useTranslation } from '../../../../../../i18n'
import { ImageButton } from '../../SlateEditor/components/Toolbar/ImageButton'
import { useToolbarMove } from '../../SlateEditor/components/Toolbar/useToolbarMove'
import { useToolbarShow } from '../../SlateEditor/components/Toolbar/useToolbarShow'
import { assertIsEditor as assertIsSlateEditor } from '../../SlateEditor/utils/types'

import { BlockButton } from './BlockButton'

interface Props extends StyledProps<ToolbarProps> {
  id: EditorId
  imageUploader: (file: File) => Promise<string>
}

export const Toolbar = withPlateProvider(({ id, imageUploader }: Props) => {
  const { t } = useTranslation()
  const ref = useRef<HTMLDivElement>(null)
  const expansionButtonRef = useRef<HTMLButtonElement>(null)
  const editor = usePlateEditorState(id != null ? id : undefined)
  assertIsSlateEditor(editor)
  const { hiddenMenu, hiddenDropMenu, onClose } = useToolbarShow({ editor, expansionButtonRef })
  useToolbarMove({ editor, ref })

  return (
    <div
      ref={ref}
      data-hidden={hiddenMenu}
      css={{
        position: 'absolute',
        zIndex: 500,
        borderRadius: 3,
        borderWidth: 1,
        borderColor: 'transparent',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        overflowY: 'auto',
        visibility: 'visible',
        '&[data-hidden="true"]': { visibility: 'hidden' },
      }}
    >
      {!hiddenMenu && (
        <Button
          ref={expansionButtonRef}
          className="editor-toolbar-button"
          alignSelf="end"
          icon={<Add size="14px" />}
        />
      )}
      {!hiddenDropMenu && (
        <ThemeContext.Extend value={{ global: { drop: { zIndex: 101 } } }}>
          <Drop
            align={{ bottom: 'top', left: 'right' }}
            target={ref.current ?? undefined}
            plain
            onClickOutside={onClose}
            onEsc={onClose}
          >
            <Box
              className="editor-toolbar"
              width="xlarge"
              height="xlarge"
              align="start"
              overflow={{ vertical: 'scroll', horizontal: 'hidden' }}
              round="small"
              css={{
                background: 'rgb(63 68 71)',
                boxShadow:
                  'rgb(15 15 15 / 10%) 0px 0px 0px 1px, rgb(15 15 15 / 20%) 0px 3px 6px, rgb(15 15 15 / 40%) 0px 9px 24px',
              }}
            >
              <Box
                width="xlarge"
                height="xlarge"
                align="start"
                overflow={{ vertical: 'scroll', horizontal: 'hidden' }}
                round="small"
                css={{
                  background: 'rgb(63 68 71)',
                  boxShadow:
                    'rgb(15 15 15 / 10%) 0px 0px 0px 1px, rgb(15 15 15 / 20%) 0px 3px 6px, rgb(15 15 15 / 40%) 0px 9px 24px',
                }}
              >
                <List>
                  <ListTitle title={t('BASIC_ELEMENTS')} />
                  <ListItem>
                    <BlockButton
                      editor={editor}
                      type={ELEMENT_H1}
                      text={t('H1')}
                      icon="h1"
                      onClose={onClose}
                    />
                  </ListItem>
                  <ListItem>
                    <BlockButton
                      editor={editor}
                      type={ELEMENT_H2}
                      text={t('H2')}
                      icon="h2"
                      onClose={onClose}
                    />
                  </ListItem>
                  <ListItem>
                    <BlockButton
                      editor={editor}
                      type={ELEMENT_H3}
                      text={t('H3')}
                      icon="h3"
                      onClose={onClose}
                    />
                  </ListItem>
                  <ListItem>
                    <BlockButton
                      editor={editor}
                      type={ELEMENT_H4}
                      text={t('H4')}
                      icon="h4"
                      onClose={onClose}
                    />
                  </ListItem>
                  <ListItem>
                    <BlockButton
                      editor={editor}
                      type={ELEMENT_H5}
                      text={t('H5')}
                      icon="h5"
                      onClose={onClose}
                    />
                  </ListItem>
                  <ListItem>
                    <BlockButton
                      editor={editor}
                      type={ELEMENT_H6}
                      text={t('H6')}
                      icon="h6"
                      onClose={onClose}
                    />
                  </ListItem>
                  <ListItem>
                    <BlockButton
                      editor={editor}
                      type={ELEMENT_UL}
                      text={t('UL')}
                      icon="bulletedList"
                      onClose={onClose}
                    />
                  </ListItem>
                  <ListItem>
                    <BlockButton
                      editor={editor}
                      type={ELEMENT_OL}
                      text={t('OL')}
                      icon="numberedList"
                      onClose={onClose}
                    />
                  </ListItem>
                  <ListItem>
                    <BlockButton
                      editor={editor}
                      type={ELEMENT_TODO_LI}
                      text={t('CHECKBOX')}
                      icon="checkbox"
                      onClose={onClose}
                    />
                  </ListItem>
                  <ListItem>
                    <BlockButton
                      editor={editor}
                      type={ELEMENT_BLOCKQUOTE}
                      text={t('BLOCK_QUOTE')}
                      icon="blockQuote"
                      onClose={onClose}
                    />
                  </ListItem>
                  <ListItem>
                    <BlockButton
                      editor={editor}
                      type={ELEMENT_CODE_BLOCK}
                      text={t('CODE_BLOCK')}
                      icon="codeBlock"
                      onClose={onClose}
                    />
                  </ListItem>
                  <ListItem>
                    <BlockButton
                      editor={editor}
                      type={ELEMENT_HR}
                      text={t('DIVIDER')}
                      icon="divider"
                      onClose={onClose}
                    />
                  </ListItem>
                  <Divider />
                  <ListTitle title={t('MEDIA')} />
                  <ListItem>
                    <ImageButton
                      editor={editor}
                      imageUploader={imageUploader}
                      text={t('IMAGE')}
                      icon="image"
                      onClose={onClose}
                    />
                  </ListItem>
                  <Divider />
                </List>
              </Box>
            </Box>
          </Drop>
        </ThemeContext.Extend>
      )}
    </div>
  )
})

const List: React.FC = ({ children }) => (
  <ol
    css={{
      listStyle: 'none',
      padding: '8px 0',
      margin: 0,
      width: '100%',
    }}
  >
    {children}
  </ol>
)

const ListItem: React.FC = ({ children }) => (
  <li
    css={{
      width: '100%',
      height: '36px',
      ':hover': {
        background: 'rgb(119 125 129)',
      },
      transition: 'background 120ms ease-in 0s',
    }}
  >
    {children}
  </li>
)

type ListTitleProps = {
  title: string
}
const ListTitle: React.FC<ListTitleProps> = ({ title }) => (
  <span
    css={{
      display: 'flex',
      paddingLeft: 14,
      paddingRight: 14,
      marginTop: 6,
      marginBottom: 8,
      fontSize: 11,
      color: 'rgb(255 255 255 / 60%)',
    }}
  >
    {title}
  </span>
)

const Divider: React.FC = () => (
  <div
    css={{
      paddingTop: 6,
      paddingBottom: 6,
      borderTop: '2px solid rgb(255 255 255 / 7%)',
    }}
  />
)
