import { useModal } from '@resily/geisha'
import { useCallback, useMemo, useState } from 'react'

import { useTranslation } from '../../../../i18n'
import { findScreen } from '../../../../lib/screen'
import { tracker } from '../../../../lib/tracking'
import { Writable } from '../../../../lib/type'
import { KeyResultWeightSettingErrorMessage } from '../../../../pages/OneOnOnesDetail/Drawer/FloatDrawer/Content/components/KeyResultWeightSetting/KeyResultWeightSettingErrorMessage'
import { KeyResultWeightSettingTotal } from '../../../../pages/OneOnOnesDetail/Drawer/FloatDrawer/Content/components/KeyResultWeightSetting/KeyResultWeightSettingTotal'
import { makeInitialState } from '../../../../pages/OneOnOnesDetail/Drawer/FloatDrawer/Content/components/KeyResultWeightSetting/hooks'
import { StyledText } from '../../../ui/StyledText'
import { KeyResultsWeightInput } from '../graphql'

import {
  KeyResult,
  WeightSettingKeyResultList,
  Props as WeightSettingKeyResultListProps,
} from './WeightSettingKeyResultList'
import { useStyles } from './WeightSettingModal.styles'

type KRs = ReadonlyArray<{ id: string; weight: number }>
const isChangeWeightSetting = (krs1: KRs, krs2: KRs): boolean =>
  krs1.some((kr1, i) => kr1.weight !== krs2[i].weight)

export type Props = {
  isOpened: boolean
  useWeighting: boolean
  keyResults: ReadonlyArray<KeyResult>
  onClose: () => void
  onEdit: (isDirty: boolean) => void
  onConfirm: (keyResults: ReadonlyArray<KeyResultsWeightInput>) => void
}

export const WeightSettingModal: React.VFC<
  Props & { modalComponent: ReturnType<typeof useModal>[0] }
> = ({ modalComponent: Modal, isOpened, useWeighting, keyResults, onClose, onEdit, onConfirm }) => {
  const { t } = useTranslation()
  const styles = useStyles()

  const initialKeyResultsState = useMemo<WeightSettingKeyResultListProps['keyResults']>(
    () => makeInitialState({ useWeighting, keyResults }),
    [keyResults, useWeighting],
  )
  const [keyResultsState, setKeyResultsState] = useState(initialKeyResultsState)

  const totalWeight = Math.round(keyResultsState.reduce((total, kr) => total + kr.weight, 0))
  const hasTotalWeightError = totalWeight !== 100

  const onWeightChange = useCallback<WeightSettingKeyResultListProps['onWeightChange']>(
    (id, weight) => {
      let isDirty = false
      setKeyResultsState((prev) => {
        const next: Array<Writable<typeof prev[0]>> = [...prev]
        const i = next.findIndex((kr) => kr.id === id)
        if (i === -1) return prev
        next[i] = { ...next[i], weight }
        isDirty = isChangeWeightSetting(next, initialKeyResultsState)
        return next
      })
      onEdit(isDirty)
    },
    [initialKeyResultsState, onEdit],
  )

  const onCloseModal = useCallback(() => {
    tracker.UserClickCloseWeightAllocationByObjectiveModal(
      findScreen(window.location.pathname, window.location.search),
    )
    onClose()
  }, [onClose])

  return (
    <Modal size="medium" isOpen={isOpened} onClose={onCloseModal}>
      <Modal.Header title={t('USE_WEIGHTING')} />
      <Modal.Content>
        <div css={styles.contentRoot}>
          <StyledText size="small">{t('KEY_RESULT_WEIGHT_SETTING_DESCRIPTION')}</StyledText>
          <div css={styles.krList}>
            <WeightSettingKeyResultList
              keyResults={keyResultsState}
              onWeightChange={onWeightChange}
            />
            <KeyResultWeightSettingTotal totalWeight={totalWeight} hasError={hasTotalWeightError} />
          </div>
          {hasTotalWeightError && <KeyResultWeightSettingErrorMessage />}
        </div>
      </Modal.Content>
      <Modal.Footer
        cancelType="tertiary"
        cancelLabel={t('CANCEL')}
        confirmLabel={t('SETTING')}
        confirmDisabled={hasTotalWeightError}
        onCancel={onCloseModal}
        onConfirm={useCallback(() => {
          tracker.UserSetWeightAllocationByObjectiveModal(
            findScreen(window.location.pathname, window.location.search),
          )
          onConfirm(keyResultsState)
        }, [keyResultsState, onConfirm])}
      />
    </Modal>
  )
}
