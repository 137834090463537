import { css } from '@emotion/react'

export const initialValueSectionEditingCss = css({
  display: 'flex',
  flexDirection: 'column',
})

export const initialValueSectionCss = css({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  marginTop: '20px',
})
