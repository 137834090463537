import { Accordion, AccordionPanel, Grommet } from 'grommet'
import React from 'react'

import { useTranslation } from '../../../i18n'
import { color } from '../../../styles/newColors'
import { BorderdButton } from '../../ui/BorderdButton'
import { StyledText } from '../../ui/StyledText'

export type Props = {
  isAutoAggregate: boolean
  lowerOkrProgressRate?: number
  progressRate: number
  onClickButton: () => void
}

const KeyResultWrapper: React.FC = ({ children }) => (
  <div
    css={{
      height: '36px',
      display: 'flex',
      alignItems: 'center',
      background: color('hover-background-bk-5'),
      border: `1px solid ${color('border-bk-10')}`,
      paddingLeft: '16px',
    }}
  >
    {children}
  </div>
)

KeyResultWrapper.displayName = 'KeyResultWrapper'

const DescriptionText: React.FC = ({ children }) => (
  <StyledText size="small" css={{ lineHeight: '12px', color: '#222943' }}>
    {children}
  </StyledText>
)

DescriptionText.displayName = 'DescriptionText'

const AutoAggregateKeyResult: React.FC = () => {
  const { t } = useTranslation()
  return (
    <KeyResultWrapper>
      <DescriptionText>{t('PROGRESS_RATE_ALIGNED')}</DescriptionText>
    </KeyResultWrapper>
  )
}

AutoAggregateKeyResult.displayName = 'AutoAggregateKeyResult'

const NoProgressRateDifferenceKeyResult: React.FC = () => {
  const { t } = useTranslation()
  return (
    <KeyResultWrapper>
      <DescriptionText>{t('NO_PROGRESS_RATE_DIFFERENCE')}</DescriptionText>
    </KeyResultWrapper>
  )
}

NoProgressRateDifferenceKeyResult.displayName = 'NoProgressRateDifferenceKeyResult'

const HasProgressRateDifferenceKeyResult: React.FC<Omit<Props, 'isAutoAggregate'>> = ({
  lowerOkrProgressRate,
  progressRate,
  onClickButton,
}) => {
  const { t } = useTranslation()

  return (
    <Grommet
      theme={{
        accordion: {
          icons: {
            color: color('border-bk-50'),
          },
          border: undefined,
        },
        global: {
          focus: {
            shadow: undefined,
            border: undefined,
          },
        },
      }}
    >
      <Accordion
        css={{
          background: color('hover-background-bk-5'),
          border: `1px solid ${color('border-bk-10')}`,
          paddingLeft: '16px',
        }}
      >
        <AccordionPanel
          label={
            <div
              css={{
                height: '36px',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <DescriptionText>{t('PROGRESS_RATE_DIFFERENCE')}</DescriptionText>
            </div>
          }
        >
          <div
            css={{
              marginBottom: '12px',
              marginRight: '16px',
            }}
          >
            <div>
              <StyledText size="small" css={{ lineHeight: '18px', color: color('text-bk-50') }}>
                {t('CHILD_OKR_PROGRESS_RATE')}：
              </StyledText>
              <StyledText
                size="small"
                css={{ lineHeight: '18px', fontWeight: 'bold', color: color('text-bk-80') }}
              >
                {lowerOkrProgressRate}％
              </StyledText>
            </div>
            <div>
              <StyledText size="small" css={{ lineHeight: '20px', color: color('text-bk-50') }}>
                {t('CURRENT_KEY_RESULT_PROGRESS_RATE')}：
              </StyledText>
              <StyledText
                size="small"
                css={{ lineHeight: '20px', fontWeight: 'bold', color: color('text-bk-80') }}
              >
                {progressRate}％
              </StyledText>
            </div>
            <div className="reflect-progress-rate" css={{ marginTop: '12px' }}>
              <BorderdButton
                text={t('REFLECT_PROGRESS_RATE')}
                isShow
                css={{
                  width: '100%',
                  background: color('white-100'),
                  height: '40px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  border: `1px solid ${color('border-bk-30')}`,
                }}
                onClick={onClickButton}
              />
            </div>
          </div>
        </AccordionPanel>
      </Accordion>
    </Grommet>
  )
}

HasProgressRateDifferenceKeyResult.displayName = 'HasProgressRateDifferenceKeyResult'

// 条件分岐を含むコンポーネントの置き場所については議論中
const NotAutoAggregateKeyResult: React.FC<Omit<Props, 'isAutoAggregate'>> = (props) => (
  <>
    {/* eslint-disable-next-line react/destructuring-assignment */}
    {props.lowerOkrProgressRate !== props.progressRate ? (
      <HasProgressRateDifferenceKeyResult {...props} />
    ) : (
      <NoProgressRateDifferenceKeyResult />
    )}
  </>
)

NotAutoAggregateKeyResult.displayName = 'NotAutoAggregateKeyResult'

export const KeyResultDescriptionProgressRateInfo: React.FC<Props> = ({
  isAutoAggregate,
  ...props
}) => {
  // eslint-disable-next-line react/destructuring-assignment
  if (props.lowerOkrProgressRate === undefined) {
    return null
  }

  return (
    <>{isAutoAggregate ? <AutoAggregateKeyResult /> : <NotAutoAggregateKeyResult {...props} />}</>
  )
}

KeyResultDescriptionProgressRateInfo.displayName = 'KeyResultDescriptionProgressRateInfo'
