import { ELEMENT_LI, ELEMENT_PARAGRAPH } from '@udecode/plate'
import { useEffect, useState, RefObject, useCallback } from 'react'
import { useIsomorphicLayoutEffect } from 'react-use'
import { Editor, Element, Text } from 'slate'
import { ReactEditor } from 'slate-react'

import { isIndentList } from '../../../types'
import { ListTypes } from '../../plugins/elements/List'
import { ParagraphType } from '../../plugins/elements/Paragraph'

export const useToolbarShow = ({
  editor,
  expansionButtonRef,
}: {
  editor: Editor | undefined
  expansionButtonRef: RefObject<HTMLButtonElement>
}): {
  hiddenMenu: boolean
  hiddenDropMenu: boolean
  onClose: () => void
} => {
  const [hiddenMenu, setHiddenMenu] = useState<boolean>(true)
  const [hiddenDropMenu, setHiddenDropMenu] = useState<boolean>(true)

  // +ボタンのメニューを表示可否を判定
  useEffect(() => {
    if (!editor) {
      return
    }

    const { selection, isVoid } = editor
    if (!selection) {
      setHiddenMenu(true)
      setHiddenDropMenu(true)
      return
    }

    try {
      const [node, path] = Editor.node(editor, selection)
      if (Text.isText(node) && node.text !== '') {
        setHiddenMenu(true)
        setHiddenDropMenu(true)
        return
      }

      const [parentNode] = Editor.parent(editor, path)
      if (Element.isElement(parentNode) && isVoid(parentNode)) {
        setHiddenMenu(true)
        setHiddenDropMenu(true)
        return
      }
      if (
        Element.isElement(parentNode) &&
        ![ParagraphType.PARAGRAPH, ELEMENT_PARAGRAPH].includes(parentNode.type)
      ) {
        setHiddenMenu(true)
        setHiddenDropMenu(true)
        return
      }

      // parentに複数のchildrenがあるということは、inline要素も包容しているのでメニューを隠す
      if (
        Element.isElement(parentNode) &&
        [ParagraphType.PARAGRAPH, ELEMENT_PARAGRAPH].includes(parentNode.type)
      ) {
        if (parentNode.children.length > 1) {
          setHiddenMenu(true)
          setHiddenDropMenu(true)
          return
        }
      }

      const blockEntry = Editor.above(editor, {
        match: (n) => Editor.isBlock(editor, n),
      })
      if (blockEntry) {
        const [blockNode, blockPath] = blockEntry
        const [parentBlockNode] = Editor.parent(editor, blockPath)

        // インデントリスト
        if (Element.isElement(blockNode) && isIndentList(blockNode)) {
          setHiddenMenu(true)
          setHiddenDropMenu(true)
          return
        }

        // 普通のリスト
        if (
          Element.isElement(parentBlockNode) &&
          [ListTypes.LI, ELEMENT_LI].includes(parentBlockNode.type)
        ) {
          setHiddenMenu(true)
          setHiddenDropMenu(true)
          return
        }
      }

      setHiddenMenu(false)
    } catch (e) {
      setHiddenMenu(true)
      setHiddenDropMenu(true)
    }
  }, [editor, editor?.selection, hiddenMenu])

  // +ボタンで展開されるドロップメニューの表示可否を判定
  useIsomorphicLayoutEffect(() => {
    if (!expansionButtonRef.current) {
      return () => {}
    }

    const { current } = expansionButtonRef

    if (hiddenMenu) {
      setHiddenDropMenu(true)
    }

    const toggle = () => setHiddenDropMenu(!hiddenDropMenu)

    current.addEventListener('click', toggle)
    return () => current.removeEventListener('click', toggle)
  }, [hiddenMenu, hiddenDropMenu, expansionButtonRef])

  // dropメニューのクローズハンドラ
  const onClose = useCallback(() => {
    setHiddenMenu(true)
    setHiddenDropMenu(true)

    // TODO: エディタ外をクリックした際、フォーカスを復元するためのバグ回避
    // see: https://github.com/ianstormtaylor/slate/issues/3412
    if (editor?.selection) {
      ReactEditor.focus(editor)
    }
  }, [editor])

  return { hiddenMenu, hiddenDropMenu, onClose }
}
