import { useMemo } from 'react'
import { useFieldArray } from 'react-hook-form'

import { keyResultsKeyName } from '../../form/formSchema'
import { UseCheckinModalFormReturn } from '../../form/hooks/useForm'
import { IndexAreaKeyResultsFragment } from '../IndexArea'

type Args = {
  control: UseCheckinModalFormReturn['formMethods']['control']
}

type Return = IndexAreaKeyResultsFragment

export const useExtractKeyResultsFragment = ({ control }: Args): Return => {
  const { fields: keyResultsFields } = useFieldArray({
    name: keyResultsKeyName,
    control,
  })

  return useMemo<IndexAreaKeyResultsFragment>(
    () =>
      keyResultsFields.map<IndexAreaKeyResultsFragment[number]>((keyResult) => ({
        id: keyResult.keyResultId,
        name: keyResult.keyResultName,
        node: keyResult.node,
      })),
    [keyResultsFields],
  )
}
