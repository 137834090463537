import {
  ELEMENT_BLOCKQUOTE,
  ELEMENT_CODE_BLOCK,
  ELEMENT_CODE_LINE,
  ELEMENT_DEFAULT,
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_H4,
  ELEMENT_H5,
  ELEMENT_H6,
  ELEMENT_HR,
  ELEMENT_IMAGE,
  ELEMENT_LI,
  ELEMENT_LIC,
  ELEMENT_LINK,
  ELEMENT_OL,
  ELEMENT_PARAGRAPH,
  ELEMENT_TD,
  ELEMENT_TODO_LI,
  ELEMENT_UL,
  KEY_INDENT,
  KEY_LIST_START,
  KEY_LIST_STYLE_TYPE,
  ListStyleType,
  MARK_BOLD,
  MARK_CODE,
  MARK_HIGHLIGHT,
  MARK_ITALIC,
  MARK_STRIKETHROUGH,
  MARK_UNDERLINE,
  TElement,
} from '@udecode/plate'
import { BaseElement } from 'slate'

export type EmptyText = { text: '' }

export type ElementType =
  | typeof ELEMENT_BLOCKQUOTE
  | typeof ELEMENT_CODE_BLOCK
  | typeof ELEMENT_CODE_LINE
  | typeof ELEMENT_H1
  | typeof ELEMENT_H2
  | typeof ELEMENT_H3
  | typeof ELEMENT_H4
  | typeof ELEMENT_H5
  | typeof ELEMENT_H6
  | typeof ELEMENT_HR
  | typeof ELEMENT_IMAGE
  | typeof ELEMENT_LINK
  | typeof ELEMENT_PARAGRAPH
  | typeof ELEMENT_TD
  | typeof ELEMENT_OL
  | typeof ELEMENT_UL
  | typeof ELEMENT_LI
  | typeof ELEMENT_LIC
  | typeof ELEMENT_TODO_LI

export const isElementType = (type: string): type is ElementType =>
  [
    ELEMENT_BLOCKQUOTE,
    ELEMENT_CODE_BLOCK,
    ELEMENT_CODE_LINE,
    ELEMENT_DEFAULT,
    ELEMENT_H1,
    ELEMENT_H2,
    ELEMENT_H3,
    ELEMENT_H4,
    ELEMENT_H5,
    ELEMENT_H6,
    ELEMENT_HR,
    ELEMENT_IMAGE,
    ELEMENT_LI,
    ELEMENT_LIC,
    ELEMENT_LINK,
    ELEMENT_OL,
    ELEMENT_PARAGRAPH,
    ELEMENT_TD,
    ELEMENT_TODO_LI,
    ELEMENT_UL,
  ].includes(type)

export const INDENTABLE_ELEMENT_TYPES = [
  ELEMENT_BLOCKQUOTE,
  ELEMENT_CODE_BLOCK,
  ELEMENT_DEFAULT,
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_H4,
  ELEMENT_H5,
  ELEMENT_H6,
  ELEMENT_HR,
  ELEMENT_IMAGE,
  ELEMENT_LINK,
  ELEMENT_PARAGRAPH,
  ELEMENT_TODO_LI,
] as const

export type IndentableElementTypeType = typeof INDENTABLE_ELEMENT_TYPES[number]
export type IndentableElement = Extract<PlateCustomElement, { type: IndentableElementTypeType }>
export type WithIndent<T extends { type: IndentableElementTypeType } & BaseElement> = T & {
  [KEY_INDENT]?: number
}

export type BlockquoteElement = WithIndent<{
  type: typeof ELEMENT_BLOCKQUOTE
  children: Array<CommonText>
}>

export type CodeBlockElement = WithIndent<{
  type: typeof ELEMENT_CODE_BLOCK
  children: Array<CodeLineElement>
  language?: string | null
}>

export type CodeLineElement = {
  type: typeof ELEMENT_CODE_LINE
  children: Array<CommonText>
}

export type HeadingElements = WithIndent<{
  type:
    | typeof ELEMENT_H1
    | typeof ELEMENT_H2
    | typeof ELEMENT_H3
    | typeof ELEMENT_H4
    | typeof ELEMENT_H5
    | typeof ELEMENT_H6
  children: Array<CommonText>
}>

export type HrElement = {
  type: typeof ELEMENT_HR
  children: [EmptyText]
}

export type ParagraphElement = WithIndent<{
  type: typeof ELEMENT_PARAGRAPH
  children: Array<CommonText | ImageElement>
}>

export type ImageElement = WithIndent<{
  type: typeof ELEMENT_IMAGE
  children: [EmptyText]
  url: string
  width?: number
  height?: number
}>

export type LinkElement = WithIndent<{
  type: typeof ELEMENT_LINK
  children: Array<PlateCustomText>
  url: string
}>

export type IndentableOrderedListElement = WithIndent<{
  type: typeof ELEMENT_DEFAULT
  children: Array<CommonText>
  [KEY_LIST_START]?: number
  [KEY_LIST_STYLE_TYPE]: ListStyleType.Decimal
}>

export type IndentableUnorderedListElement = WithIndent<{
  type: typeof ELEMENT_DEFAULT
  children: Array<CommonText>
  [KEY_LIST_START]?: number
  [KEY_LIST_STYLE_TYPE]: ListStyleType.Disc
}>

export const isIndentList = (
  e: TElement,
): e is IndentableOrderedListElement | IndentableUnorderedListElement =>
  e.type === ELEMENT_DEFAULT &&
  KEY_LIST_STYLE_TYPE in e &&
  [ListStyleType.Decimal, ListStyleType.Disc].includes(
    (e as IndentableOrderedListElement | IndentableUnorderedListElement)[KEY_LIST_STYLE_TYPE],
  )

export const isIndentedElement = (e: TElement): e is IndentableElement =>
  INDENTABLE_ELEMENT_TYPES.includes(e.type as IndentableElementTypeType) && KEY_INDENT in e

/**
 * @deprecated
 */
export type OrderedListElement = {
  type: typeof ELEMENT_OL
  children: Array<ListItemElement>
}

/**
 * @deprecated
 */
export type UnorderedListElement = {
  type: typeof ELEMENT_UL
  children: Array<ListItemElement>
}

/**
 * @deprecated
 */
export type ListItemElement = {
  type: typeof ELEMENT_LI
  children: Array<ListItemChildElement | OrderedListElement | UnorderedListElement>
}

/**
 * @deprecated
 */
export type ListItemChildElement = {
  type: typeof ELEMENT_LIC
  children: Array<CommonText>
}

export type CheckBoxElement = WithIndent<{
  type: typeof ELEMENT_TODO_LI
  children: Array<CommonText>
  checked?: boolean
}>

export type PlateCustomElement =
  | BlockquoteElement
  | CodeBlockElement
  | CodeLineElement
  | CheckBoxElement
  | HeadingElements
  | HrElement
  | ImageElement
  | LinkElement
  | ParagraphElement
  | IndentableOrderedListElement
  | IndentableUnorderedListElement
  | OrderedListElement
  | UnorderedListElement
  | ListItemElement
  | ListItemChildElement

export type MarkType =
  | typeof MARK_BOLD
  | typeof MARK_CODE
  | typeof MARK_HIGHLIGHT
  | typeof MARK_ITALIC
  | typeof MARK_STRIKETHROUGH
  | typeof MARK_UNDERLINE

export const isMarkType = (type: string): type is MarkType =>
  [MARK_BOLD, MARK_CODE, MARK_HIGHLIGHT, MARK_ITALIC, MARK_STRIKETHROUGH, MARK_UNDERLINE].includes(
    type,
  )

export type CommonText = PlateCustomText | LinkElement

export type PlateCustomText = Partial<Record<MarkType, boolean>> & { type?: never; text: string }
