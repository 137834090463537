import { Dispatch, SetStateAction, createContext, useCallback, useContext, useState } from 'react'

import { OkrFloatDrawerListItem } from '../components/domain/OkrFloatDrawerList/types'

export const OkrFloatDrawerContext = createContext<ReadonlyArray<OkrFloatDrawerListItem>>([])
export const OkrFloatDrawerDispatchContext = createContext<
  Dispatch<SetStateAction<ReadonlyArray<OkrFloatDrawerListItem>>>
>(() => {
  throw new Error('Should use OkrFloatDrawerDispatchContext.Provider')
})

export const OkrFloatDrawerContextProvider: React.FC = ({ children }) => {
  const [floatDrawerItems, setFloatDrawerItems] = useState<ReadonlyArray<OkrFloatDrawerListItem>>(
    [],
  )

  return (
    <OkrFloatDrawerContext.Provider value={floatDrawerItems}>
      <OkrFloatDrawerDispatchContext.Provider value={setFloatDrawerItems}>
        {children}
      </OkrFloatDrawerDispatchContext.Provider>
    </OkrFloatDrawerContext.Provider>
  )
}
OkrFloatDrawerContextProvider.displayName = 'OkrDrawerContextProvider'

export const useGetFloatDrawerItems = (): ReadonlyArray<OkrFloatDrawerListItem> =>
  useContext(OkrFloatDrawerContext)

export const useSetFloatDrawerItems = (): ((
  items: ReadonlyArray<OkrFloatDrawerListItem>,
) => void) => {
  const setFloatDrawerItems = useContext(OkrFloatDrawerDispatchContext)
  return useCallback(
    (items: ReadonlyArray<OkrFloatDrawerListItem>) => setFloatDrawerItems(items),
    [setFloatDrawerItems],
  )
}
