import { useTranslation } from '../../../../../i18n'
import { StyledText } from '../../../../ui/StyledText'
import { TextButton } from '../../../../ui/TextButton'

import { useStyles } from './EmptyView.styles'

export type EmptyViewProps = {
  onClickCreateActionPlan: () => void
}

export const EmptyView: React.VFC<EmptyViewProps> = ({ onClickCreateActionPlan }) => {
  const { t } = useTranslation()
  const styles = useStyles()

  return (
    <div css={styles.root}>
      <div css={styles.empty}>
        <StyledText as="div" color="text-bk-100" size="small">
          {`${t('X_IS_EMPTY', { x: t('ACTION_PLAN') })}\n${t('CREATE_A_NEW_ACTION_PLAN')}`}
        </StyledText>
      </div>
      <TextButton
        icon="plus"
        color="text-bk-50"
        onClick={onClickCreateActionPlan}
        css={styles.create}
      >
        {t('CREATE_ACTION_PLAN')}
      </TextButton>
    </div>
  )
}
