import { UseFormReturn, FormProvider as FormProviderOrigin, useFormContext } from 'react-hook-form'

import { FormValues } from '../formSchema'

type Props = {
  methods: UseFormReturn<FormValues>
}

export const FormProvider: React.FC<Props> = ({ methods, children }) => (
  <FormProviderOrigin {...methods}>{children}</FormProviderOrigin>
)

FormProvider.displayName = 'CheckinModalContextFormProvider'

export const useGetFormMethods = (): UseFormReturn<FormValues> => useFormContext<FormValues>()
