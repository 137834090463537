import { css } from '@emotion/react'
import { Button as GrommetButton } from 'grommet'
import { Pin } from 'grommet-icons'
import { FC, VFC } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { RichTextEditor } from '../../components/ui/RichTextEditor'
import { configureCollaborationEditingServer } from '../../config'
import { useCurrentUser } from '../../contexts/UserContext'
import { useTranslation } from '../../i18n'

import { FixedAgendasFragment, UserAgendasFragment } from './graphql'

export type Props = {
  oneOnOneMeetingId: string
  userAgendas: ReadonlyArray<UserAgendasFragment>
  fixedAgendas: ReadonlyArray<FixedAgendasFragment>
  onSubmit: OnSubmitFn
}

export type FormValue = {
  meetingId: string
  agendaId: string
  isFixedAgenda: boolean
  body: {
    treeJson: string
    plainText?: string
  }
}

export type OnSubmitFn = (data: FormValue) => Promise<void>

const labelCss = css({
  background: '#E9EAEC',
  borderRadius: 4,
  padding: '8px 24px',
  marginBottom: 4,
  alignItems: 'center',
  minHeight: 40,
  fontSize: 20,
  fontWeight: 700,
})

const editorCss = css({
  backgroundColor: 'white',
  border: 'none',
  margin: '0 8px',
})

const isFixedAgendaType = (
  arg: UserAgendasFragment | FixedAgendasFragment,
): arg is FixedAgendasFragment => arg.__typename === 'FixedAgenda'

export const MeetingNotes: VFC<Props> = ({
  oneOnOneMeetingId,
  fixedAgendas,
  userAgendas,
  onSubmit,
}) => (
  <>
    {userAgendas.map((e) => (
      <MeetingNoteItem
        key={e.id}
        oneOnOneMeetingId={oneOnOneMeetingId}
        agenda={e}
        onSubmit={onSubmit}
      />
    ))}
    {fixedAgendas.map((e) => (
      <MeetingNoteItem
        key={e.id}
        oneOnOneMeetingId={oneOnOneMeetingId}
        agenda={e}
        onSubmit={onSubmit}
      />
    ))}
  </>
)

MeetingNotes.displayName = 'MeetingNotes'

type MeetingNoteItemProps = {
  oneOnOneMeetingId: string
  agenda: UserAgendasFragment | FixedAgendasFragment
  onSubmit: OnSubmitFn
}
const MeetingNoteItem: VFC<MeetingNoteItemProps> = ({ oneOnOneMeetingId, agenda, onSubmit }) => {
  const isFixedAgenda = isFixedAgendaType(agenda)
  const { handleSubmit, control } = useForm<FormValue>({
    defaultValues: {
      meetingId: oneOnOneMeetingId,
      agendaId: agenda.id,
      isFixedAgenda,
      body: {
        treeJson: agenda.meetingNote !== '' ? agenda.meetingNote : undefined,
      },
    },
  })
  const { t } = useTranslation()
  const user = useCurrentUser()

  // 固定アジェンダの場合、固定アジェンダから手を加えられていなければ
  // 固定アジェンダのテンプレート文字列をそのまま埋め込む
  let initialValueJSON = agenda.meetingNote
  if (isFixedAgenda && (agenda.meetingNote === '' || agenda.body === agenda.meetingNote)) {
    initialValueJSON = agenda.body
  }

  return (
    <form
      id={agenda.id}
      onSubmit={handleSubmit(onSubmit)}
      css={{
        display: 'block',
        paddingTop: '8px',
      }}
    >
      <Controller
        name="meetingId"
        control={control}
        defaultValue={oneOnOneMeetingId}
        render={() => <input type="hidden" />}
      />
      <Controller
        name="agendaId"
        control={control}
        defaultValue={agenda.id}
        render={() => <input type="hidden" />}
      />
      <Controller
        name="isFixedAgenda"
        control={control}
        defaultValue={isFixedAgenda}
        render={() => <input type="hidden" />}
      />
      <AgendaLabel>
        {isFixedAgenda && (
          <GrommetButton
            icon={
              <Pin
                css={{
                  width: '14px',
                  height: '14px',
                }}
              />
            }
            css={{
              padding: '8px 8px 8px 0',
              cursor: 'default',
            }}
          />
        )}
        {agenda.title}
      </AgendaLabel>
      <Controller
        name="body"
        control={control}
        render={({ field: { onChange } }) => (
          <RichTextEditor
            // 固定アジェンダの場合は固定アジェンダのリソースIDだけでは一意とならないためMTG IDも付与してIDとする
            id={isFixedAgenda ? `${oneOnOneMeetingId}-${agenda.id}` : agenda.id}
            autoFocus={false}
            autoSave
            initialValueJSON={initialValueJSON}
            onlineMode={{
              webSocketEndpoint: configureCollaborationEditingServer(),
              userName: t('FULL_NAME', {
                firstName: user?.firstName ?? '',
                lastName: user?.lastName ?? '',
              }),
            }}
            onChange={(treeJson, plainText) => onChange({ treeJson, plainText })}
            onSave={(treeJson, plainText) => {
              onSubmit({
                meetingId: oneOnOneMeetingId,
                agendaId: agenda.id,
                isFixedAgenda,
                body: {
                  treeJson,
                  plainText,
                },
              })
            }}
            css={editorCss}
          />
        )}
      />
    </form>
  )
}

MeetingNoteItem.displayName = 'MeetingNoteItem'

// アジェンダのラベル
const AgendaLabel: FC = ({ children }) => (
  <div
    css={[
      labelCss,
      {
        display: 'flex',
      },
    ]}
  >
    {children}
  </div>
)

AgendaLabel.displayName = 'AgendaLabel'
