import { ApolloCache } from '@apollo/client'

import { GroupsDocument, GroupsQuery, GroupsQueryVariables } from '../graphql'

export const writeGroupsQuery = (
  cache: ApolloCache<unknown>,
  groups: GroupsQuery['groups'],
): void => {
  const option = { query: GroupsDocument }
  cache.writeQuery<GroupsQuery, GroupsQueryVariables>({
    ...option,
    data: {
      groups,
    },
  })
}
