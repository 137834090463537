import { css, CSSObject } from '@emotion/react'

import { fontSize } from '../../../../../styles/font'
import { color } from '../../../../../styles/newColors'

export const rootCss = css({
  backgroundColor: color('white-100'),
  borderRadius: '8px',
  padding: '32px 24px',
})

export const titleCss = css({
  marginBottom: '12px',
  color: color('text-bk-100'),
  ...fontSize('large', 'bold'),
})

export const valueButtonCss: CSSObject = {
  padding: '13px 16px',
  width: 300,
  height: 40,
}
