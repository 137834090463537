/* eslint-disable */

      export type PossibleTypesResultData = {
  "possibleTypes": {
    "Agenda": [
      "FixedAgenda",
      "UserAgenda"
    ],
    "Confidence": [
      "KeyResultConfidence",
      "ObjectiveConfidence"
    ],
    "ConfidenceScore": [
      "KeyResultConfidenceScore",
      "ObjectiveConfidenceScore"
    ],
    "KeyResultSharedItem": [
      "KeyResultOther",
      "KeyResultPriority",
      "KeyResultProblem",
      "KeyResultWinSession"
    ],
    "Node": [
      "Activity",
      "CheckinKeyResultEventHistory",
      "FixedAgenda",
      "KeyResult",
      "KeyResultEvent",
      "Note",
      "Notification",
      "Objective",
      "ObjectiveEvent",
      "OkrEventHistory",
      "OkrNode",
      "UserAgenda",
      "UserOneOnOne"
    ],
    "NoteOrPersonalNote": [
      "Note",
      "PersonalNote"
    ],
    "NoteResource": [
      "KeyResult",
      "Objective"
    ],
    "NotificationResource": [
      "KeyResult",
      "Note",
      "Objective"
    ],
    "OkrNodeItem": [
      "KeyResult",
      "Objective"
    ],
    "ProgressRateHistory": [
      "KeyResultProgressRateHistory",
      "ObjectiveProgressRateHistory"
    ],
    "RecommendAgenda": [
      "BasicRecommendAgenda",
      "LowKeyResultConfidence",
      "NeverProgressKeyResult",
      "NoProgressKeyResult",
      "OverKeyResultProgressRate",
      "RiseKeyResultProgressRate"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Agenda": [
      "FixedAgenda",
      "UserAgenda"
    ],
    "Confidence": [
      "KeyResultConfidence",
      "ObjectiveConfidence"
    ],
    "ConfidenceScore": [
      "KeyResultConfidenceScore",
      "ObjectiveConfidenceScore"
    ],
    "KeyResultSharedItem": [
      "KeyResultOther",
      "KeyResultPriority",
      "KeyResultProblem",
      "KeyResultWinSession"
    ],
    "Node": [
      "Activity",
      "CheckinKeyResultEventHistory",
      "FixedAgenda",
      "KeyResult",
      "KeyResultEvent",
      "Note",
      "Notification",
      "Objective",
      "ObjectiveEvent",
      "OkrEventHistory",
      "OkrNode",
      "UserAgenda",
      "UserOneOnOne"
    ],
    "NoteOrPersonalNote": [
      "Note",
      "PersonalNote"
    ],
    "NoteResource": [
      "KeyResult",
      "Objective"
    ],
    "NotificationResource": [
      "KeyResult",
      "Note",
      "Objective"
    ],
    "OkrNodeItem": [
      "KeyResult",
      "Objective"
    ],
    "ProgressRateHistory": [
      "KeyResultProgressRateHistory",
      "ObjectiveProgressRateHistory"
    ],
    "RecommendAgenda": [
      "BasicRecommendAgenda",
      "LowKeyResultConfidence",
      "NeverProgressKeyResult",
      "NoProgressKeyResult",
      "OverKeyResultProgressRate",
      "RiseKeyResultProgressRate"
    ]
  }
};
      export default result;
    