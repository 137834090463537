import { ExternalUrls } from '../../../urls'

import startGuide1 from './assets/start_guide_1.svg'
import startGuide2 from './assets/start_guide_2.svg'
import startGuide3 from './assets/start_guide_3.svg'
import startGuide4 from './assets/start_guide_4.svg'
import startGuide5 from './assets/start_guide_5.svg'

export type TipsItem = {
  title: string
  body: string
  category: string
  kinds: string
  link: string
  externalArticleTitle?: string
  image: string
}

/**
 * 対象の1on1に合わせたTipsを取得
 * index番号0~4の場合はスタートガイド (OneOnOneStartGuideItems) を返す。
 * それ以外の場合は、1on1のTips (OneOnOneTipsItems) をランダムに返す。
 *
 * @param {number} index  対象の1on1のindex番号 (0以上の整数を想定)
 */
export const getTipsItem = (index: number): TipsItem | undefined => {
  try {
    if (!Number.isInteger(index)) {
      throw new Error(`index: ${index}\n変数indexには0以上の整数を入れてください`)
    }
    if (Math.sign(index) === -1) {
      throw new Error(`index: ${index}\n変数indexには0以上の整数を入れてください`)
    }

    return index < 5
      ? OneOnOneStartGuideItems[index]
      : OneOnOneTipsItems[Math.floor(Math.random() * OneOnOneTipsItems.length)]
  } catch (e) {
    console.error('エラー：', e)
    return undefined
  }
}

const OneOnOneStartGuideItems: Array<TipsItem> = [
  {
    title: '1on1スタートガイド第1回（全5回:1on1そのものについて話してみる',
    body: '会社から「1on1を習慣的にやってください」と言われたとき、こんなことを思う方もいるのではないでしょうか。\n\n・普段やってる進捗確認や業務相談と何が違うの？\n・違うのであれば何を話せばいいの？\n\n1on1スタートガイドでは、全5回にわたって上記のような疑問を解消するためのヒントをお届けします。\n\n初回のテーマは、「1on1そのものについて話してみる」です。',
    category: '対話テクニック',
    kinds: 'スタートガイド',
    image: startGuide1,
    link: ExternalUrls.ONE_ON_ONE_START_GUIDE_1,
  },
  {
    title: '1on1スタートガイド第2回（全5回）:一緒に振り返りをしてみる',
    body: '第2回のテーマは、「一緒に振り返りをしてみる」です。\n\nOKRでお互いの目標やその進捗が見えるとしても、それについてどう思っているかであったり、お互いの解釈の違いについて共有し合う機会はなかなかないのではないでしょうか。\n\n進捗率や実行したことなどの事実と同じくらい、「思っていること」や「感じたこと」を交換し合うことは大切です。',
    category: '対話テクニック',
    kinds: 'スタートガイド',
    image: startGuide2,
    link: ExternalUrls.ONE_ON_ONE_START_GUIDE_2,
  },
  {
    title: '1on1スタートガイド第3回（全5回）:曖昧なことを言語化してみる',
    body: '1on1スタートガイド、折り返しを過ぎて第3回です。過去2回の1on1で、二人での会話に慣れてきたでしょうか？\n\n少し駆け足になりますが、先に進みましょう。今回のテーマは、「曖昧なことを言語化してみる」です。\n\n日常の業務の中では、どうしても発言の明確さやシンプルさが求められます。しかしながら、本当に共有されるべき情報には、曖昧ですぐには言語化が難しいものもあります。',
    category: '対話テクニック',
    kinds: 'スタートガイド',
    image: startGuide3,
    link: ExternalUrls.ONE_ON_ONE_START_GUIDE_3,
  },
  {
    title: '1on1スタートガイド第4回（全5回）:少し視野を広げてみる',
    body: '第4回のテーマは、「少し視野を広げてみる」です。\n\n毎回お互いが自分のことを話し続けるのは、少し窮屈に感じることもあります。\n\n毎回同じテーマでも会話のスキルが上がることで有意義さや新鮮さを保つことはできますが、マンネリ化が先に訪れてしまってはいけません。\n\n今回は、会話に多様さを加えて、視野を広げるきっかけをつくりましょう。',
    category: '対話テクニック',
    kinds: 'スタートガイド',
    image: startGuide4,
    link: ExternalUrls.ONE_ON_ONE_START_GUIDE_4,
  },
  {
    title: '1on1スタートガイド最終回（全5回）:二人にとっての1on1を話し合う',
    body: '1on1スタートガイド、最終回を迎えました。ここまでお疲れ様でした。\n\n最後のテーマは、「今の二人にとっての1on1を話し合う」です。\n\n過去4回でトライした会話を振り返ってみると、しっくりこなかったこと、1回だけでは物足りなかったこと、新しく試したいことなどが出てくると思います。\n\n今までのガイドに拘ることなく、今後の1on1の方向性を定める会話をはじめてみましょう。',
    category: '対話テクニック',
    kinds: 'スタートガイド',
    image: startGuide5,
    link: ExternalUrls.ONE_ON_ONE_START_GUIDE_5,
  },
]

const OneOnOneTipsItems: Array<TipsItem> = [
  {
    title: 'キャリアプランの明確化による最適なタスクアサインとは',
    body: '抱える業務において、やりたい仕事・やりたくない仕事が存在するかと思います。1on1を通じて各々が適切な業務にアサインされてモチベートされている状態は作り出す為にキャリアプランを活用する方法が説明されています。',
    category: '目標設計・アサインについて',
    kinds: '外部記事',
    image:
      'https://assets.st-note.com/production/uploads/images/36962671/rectangle_large_type_2_513e461755a831dd790ae98f2027fc9b.jpg?width=800',
    externalArticleTitle:
      'メンバーに「この仕事、やりたい仕事じゃないです」と言われたら？(動画つき)',
    link: 'https://note.com/nagam/n/n3d30dbba2814',
  },
  {
    title: '音声通話のみで1on1を行う事により「傾聴力」と「継続性」を強化。',
    body: '現在ではリモート環境下で1on1を行う方も多いと思いますが、たまに「視覚」をOFFにして音声だけで行うのはいかがでしょうか？\n視覚情報がなくなる事により、より相手の声に集中し傾聴しながらの対話が可能になったり、手軽になる為、継続性が増すかもしれません。',
    category: '対話テクニック',
    kinds: '外部記事',
    image:
      'https://assets.st-note.com/production/uploads/images/38437228/rectangle_large_type_2_b3efd769b9615036bf9cf3906641f41e.jpg?width=800',
    externalArticleTitle:
      'オンライン1on1を「声だけ」にしたらメリットしかなかった話。 | BizHint（ビズヒント）- クラウド活用と生産性向上の専門サイト',
    link: 'https://bizhint.jp/article/290454',
  },
  {
    title: '1on1はメンバーの為の時間。エンゲージメントを高める1on1とは？',
    body: '「組織と個人、仕事と個人のエンゲージメントを高めること」を1on1の目的とした際に、どのようなことを大事すれば良いでしょうか。\nプロセス、重要な事、マインドセットなどが記載されています。',
    category: '1on1について',
    kinds: '外部記事',
    image: 'https://hrnote.jp/wp/wp-content/uploads/2021/02/newone-icatch-1.jpg',
    externalArticleTitle:
      'テレワーク中の組織マネジメントにおいて「1on1」は救世主となりえるか？|NEWONE連載#1 | 人事部から企業成長を応援するメディアHR NOTE',
    link: 'https://hrnote.jp/contents/1on1-newone-1/',
  },
  {
    title: '1on1で重要な「聴く力」具体的にどのようなスキルでそう身に付けるとよい？',
    body: '現在ではリモート環境下で1on1を行う方も多いと思いますが、たまに「視覚」をOFFにして音声だけで行うのはいかがでしょうか？視覚情報がなくなる事により、より相手の声に集中し傾聴しながらの対話が可能になったり、手軽になる為、継続性が増すかもしれません。',
    category: '対話テクニック',
    kinds: '外部記事',
    image: 'https://img.logmi.jp/article_images/5ULxLnmHmKZ5ivBMnG7nbs.jpg',
    externalArticleTitle:
      '1on1がうまくいかない上司に不足している「聴かれた体験」「聴く力」を高める、関心・理解・実践サイクルの回し方',
    link: 'https://logmi.jp/business/articles/325196',
  },
  {
    title: '1on1に臨むときのスタンス',
    body: '率直に指摘し合える職場に必要なスタンス「4つのA」ネットフリックスの1on1',
    category: '対話テクニック',
    kinds: '外部記事',
    image: 'https://okadama3.com/wp-content/uploads/2021/01/book-731199_1920.jpg',
    externalArticleTitle: 'Netflixに学ぶ1on1で意識すること',
    link: 'https://okadama3.com/netflix-learnnig/',
  },
  {
    title: 'チームのやる気を向上させる対話手法「フィードフォワード」とは？',
    body: '「フィードフォワード」とは、チームメンバーのパフォーマンスにおける問題点を指摘するのではなく、未来の「解決策」に注力した、主体性を尊重するコメントを残すことで、チームメンバーのやる気や自主性を促すことが主な目的の対話手法です。\n本記事では具体的なフィードフォワードのやり方説明する記事になります',
    category: '対話テクニック',
    kinds: '外部記事',
    image: 'https://gendai.ismcdn.jp/mwimgs/8/3/-/img_830f3bdb5967618bb89579b17ed4a549123889.jpg',
    externalArticleTitle:
      '断言しよう、PDCAサイクルはもう「時代遅れ」だ（久野 和禎） | 現代ビジネス | 講談社（2/4）',
    link: 'https://gendai.ismedia.jp/articles/-/56500?page=2',
  },
  {
    title: '1on1で成果を最大化する方法とは？',
    body: '「1om1」の実施は、チームメンバーの能力を効かすための機会をつくり、チームメンバーにとって自身の成長に対して、ヒントを得る機会をつくる事が主な目的です。そのために何をすればいいのか？1on1の具体的なメソッドを説明するポッドキャストになります。',
    category: '1on1について',
    kinds: '外部記事',
    image:
      'https://hiromaeda.com/wp-content/uploads/2019/03/52766794_399075074256145_227418960704831488_n-1.jpg',
    externalArticleTitle: '1on1を語る - 前田ヒロ',
    link: 'https://hiromaeda.com/2019/03/11/one-on-one/',
  },
  {
    title: '1on1を実施するときのポイント',
    body: '1on1は、業績や数値管理といった「ビジネスマネジメント」が目的の1on1と、メンバーのモチベーションや成長を促す「ピープルマネジメント」が目的の1on1に大きく分けられます。\n\n本記事では、目的毎に応じた1on1の実施方法について解説している記事になります。',
    category: '1on1について',
    kinds: '外部記事',
    image:
      'https://uploads-ssl.webflow.com/5fe46727a1e86d417021043c/5ffac26c7fde038d0b6e6ed6_5fed4886ec1dd16ddafacfd3_ASSF%E3%83%8F%E3%82%99%E3%83%8A%E3%83%BC12_resize.png',
    externalArticleTitle: 'ARR400%達成の要となった1on1テクニック',
    link: 'https://blog.allstarsaas.com/posts/1on1technique-20210105',
  },
  {
    title: '「アサーティブコミュニケーション」とは？ ',
    body: '「アサーティブコミュニケーション」と呼ばれる手法が、良好な人間関係の構築に貢献するものとして注目を集めています。この記事では、「アサーティブコミュニケーション」について、その内容や実践のポイントについて解説されています。',
    category: '対話テクニック',
    kinds: '外部記事',
    image: 'https://www.hrpro.co.jp/images/tokushu/hr_tokushu_photo__3MVJ99.jpg',
    externalArticleTitle:
      '「アサーティブコミュニケーション」の意味や事例とは？ ポイントを実践し、コミュニケーション活性化やメンタルヘルスケアを実現 | 人事のプロを支援するHRプロ',
    link: 'https://www.hrpro.co.jp/series_detail.php?t_no=2511',
  },
  {
    title: '「1on1」で注意すべき点とは？',
    body: 'この記事では、1on1をより効果的に行うために、実施の前に注意点を理解しておきたい「5つの注意すべき点」について解説されています。',
    category: '1on1について',
    kinds: '外部記事',
    image: 'https://www.hrpro.co.jp/images/tokushu/hr_tokushu_photo__8FVC07.jpg',
    externalArticleTitle:
      '「1on1」の目的やメリットとは？部下との接し方や企業事例も解説 | 人事のプロを支援するHRプロ',
    link: 'https://www.hrpro.co.jp/series_detail.php?t_no=2324',
  },
]
