import { Spinner, ThemeContext } from 'grommet'
import { useMemo } from 'react'

import { color } from '../../../../styles/newColors'

import { ActivatedUserTab } from './ActivatedUserTab'
import { DisabledUserTab } from './DisabledUserTab'
import { InvitingUserTab } from './InvitingUserTab'
import { tableContentsMapWrapperCss } from './styles'
import { TableMapProps } from './types'

export const TableContentsMap: React.FC<TableMapProps> = ({
  tab,
  onRowClick,
  loading,
  users,
  invitingUsers,
  disableUsers,
}) => {
  const contents = useMemo<React.ReactNode>(() => {
    if (tab === 'InviteUser') {
      return <ActivatedUserTab users={users} onRowClick={onRowClick} />
    }
    if (tab === 'InvitingUser') {
      return <InvitingUserTab invitingUsers={invitingUsers} onRowClick={onRowClick} />
    }
    return <DisabledUserTab disableUsers={disableUsers} onRowClick={onRowClick} />
  }, [disableUsers, invitingUsers, onRowClick, tab, users])

  return (
    <div css={tableContentsMapWrapperCss}>
      <ThemeContext.Extend
        value={{
          dataTable: {
            header: {
              extend: {
                input: {
                  backgroundColor: color('hover-background-bk-5'),
                  border: 'none',
                  borderRadius: 0,
                  boxShadow: `0px 1px 0px ${color('resily-orange-100')}`,
                },
              },
            },
            body: {
              extend: {
                tr: {
                  height: '40px',
                },
              },
            },
            pinned: {
              header: {
                background: {
                  color: color('white-100'),
                  opacity: 1,
                },
              },
            },
          },
        }}
      >
        {loading &&
        users.length === 0 &&
        invitingUsers.length === 0 &&
        disableUsers.length === 0 ? (
          <div
            css={{
              display: 'flex',
              padding: '40px 0',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Spinner size="medium" />
          </div>
        ) : (
          contents
        )}
      </ThemeContext.Extend>
    </div>
  )
}
