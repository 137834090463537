import React from 'react'

import { KeyResultFragment } from '../../graphql'

import { KeyResultDescriptionContainer } from './components/KeyResultDescription'

export const UpdateKeyResultContent: React.VFC<{ keyResult: KeyResultFragment }> = ({
  keyResult,
}) => (
  <div css={{ overflowY: 'auto', padding: '24px 0', margin: '0 24px' }}>
    <KeyResultDescriptionContainer keyResultId={keyResult.id} />
  </div>
)
