import {
  FileFragment,
  OkrDetailKeyResultFragment,
} from '../../../pages/KeyResultDetailEdit/graphql'
import { DetailEditCard } from '../../ui/DetailEditCard'

export type Props = {
  keyResult: OkrDetailKeyResultFragment
  updateKeyResult: (keyResult: OkrDetailKeyResultFragment, redirect: boolean) => void
  onClickCancel: () => void
  setNeedBlock: (value: boolean) => void
}

export const KeyResultDetailEditPageCard: React.FC<Props> = ({
  keyResult,
  updateKeyResult,
  onClickCancel,
  setNeedBlock,
}) => {
  const handleUpdateKeyResultDetail = (keyResultName: string, treeJson: string, text: string) => {
    updateKeyResult(
      {
        ...keyResult,
        name: keyResultName,
        description: {
          treeJson,
          plainText: text,
        },
      },
      true,
    )
  }

  const handleUploadFile = (file: FileFragment) => {
    updateKeyResult(
      {
        ...keyResult,
        description: {
          treeJson: keyResult.description?.treeJson || '',
          plainText: keyResult.description?.plainText || '',
        },
        attachments: [...keyResult.attachments, file],
      },
      false,
    )
  }

  const handleDeleteAttachment = (newFiles: ReadonlyArray<FileFragment>) => {
    updateKeyResult(
      {
        ...keyResult,
        description: {
          treeJson: keyResult.description?.treeJson || '',
          plainText: keyResult.description?.plainText || '',
        },
        attachments: newFiles,
      },
      false,
    )
  }

  return (
    <DetailEditCard
      editorId={`detailedit-kr-${keyResult.id}`}
      title={keyResult.name}
      body={keyResult.description?.treeJson}
      plainText={keyResult.description?.plainText || ''}
      attachments={keyResult.attachments}
      onClickCancel={onClickCancel}
      onClickDeleteAttachments={handleDeleteAttachment}
      onClickSave={handleUpdateKeyResultDetail}
      onUpdateFile={handleUploadFile}
      onEdit={setNeedBlock}
    />
  )
}

KeyResultDetailEditPageCard.displayName = 'KeyResultDetailEditPageCard'
