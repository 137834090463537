import { DocumentInput } from './graphql'

const removeBlankAndNewLine = (text: string): string => text.replace(/\s+/g, '')

type PlainText = DocumentInput['plainText']

export const isEmptyDocument = (doc: PlainText | null | undefined): boolean =>
  doc == null || removeBlankAndNewLine(doc) === ''

type TreeJsonString = DocumentInput['treeJson']

export const isEqualDocument = (
  a: TreeJsonString | null | undefined,
  b: TreeJsonString | null | undefined,
): boolean => {
  // eslint-disable-next-line no-param-reassign
  if (a == null) a = ''
  // eslint-disable-next-line no-param-reassign
  if (b == null) b = ''
  return a === b
}
