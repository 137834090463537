import { useParams, useNavigate } from 'react-router-dom'

import { BreadcrumbParams } from '../../components/domain/Breadcrumb'
import { useTranslation } from '../../i18n'
import { useOkrTermLoadable } from '../../lib/domain/useOkrTermLoadable'
import { Screen } from '../../lib/screen'
import { useTracking } from '../../lib/tracking'
import { AuthRouteProps } from '../../types/authRouteProps'
import { generateObjectiveEdit, objective as objectivePath } from '../../urls'

import { OkrDetailObjectiveFragment, useOkrNodeQuery } from './graphql'

export const useInjection = ({
  onOkrTermLoaded,
}: AuthRouteProps): null | {
  objective: OkrDetailObjectiveFragment
  breadcrumbs: BreadcrumbParams
  onObjectiveEdit: () => void
} => {
  const { objectiveId } = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { data } = useOkrNodeQuery({
    variables: { objectiveId },
    // NOTE: 古い情報が表示されてしまうためキャッシュを無効にする
    fetchPolicy: 'network-only',
  })
  useTracking(t('OBJECTIVE'), Screen.ObjectiveDetailInfo)

  useOkrTermLoadable(onOkrTermLoaded, undefined, data?.okrNode.term.id)

  const { okrNode } = data || {}
  if (!okrNode?.objective) {
    return null
  }
  const { objective } = okrNode

  const breadcrumbs: BreadcrumbParams = {
    url: objectivePath,
    items: [
      {
        breadcrumbName: 'okr',
        ...okrNode,
      },
      {
        breadcrumbName: 'objectiveDetail',
        ...objective,
        node: {
          id: okrNode.id,
        },
      },
    ],
  }

  const onObjectiveEdit = () => {
    navigate(generateObjectiveEdit(okrNode.id, objective.id))
  }

  return {
    objective,
    breadcrumbs,
    onObjectiveEdit,
  }
}
