import { css } from '@emotion/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { VFC, useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import { ErrorMessage } from '../../components/ui/ErrorMessage'
import { Input } from '../../components/ui/Input'
import { Link } from '../../components/ui/Link'
import { Modal } from '../../components/ui/Modal'
import { StyledText } from '../../components/ui/StyledText'
import { useTranslation, Trans } from '../../i18n'
import { color } from '../../styles/newColors'

import { IntegateSmartHrInput } from './graphql'

// TODO: ヘルプサイトができたら対応する
// @link https://github.com/Resily/resily-new/issues/10548
const SMARTHR_HELP =
  'https://knowledge.smarthr.jp/hc/ja/articles/360026264893-%E3%82%B5%E3%83%96%E3%83%89%E3%83%A1%E3%82%A4%E3%83%B3%E3%81%A8%E3%81%AF'

const styles = {
  input: css({ marginTop: 12, marginBottom: 24, width: '100%' }),
  link: css({
    color: color('resily-orange-100'),
    textDecoration: 'underline',
  }),
  error: css({
    marginTop: 24,
  }),
} as const

type FormValue = IntegateSmartHrInput

type Props = {
  isOpened: boolean
  integrationLoading: boolean
  handleIntegrate: (value: FormValue) => Promise<void>
  onClickClose: () => void
}

export const SmartHRSettingModal: VFC<Props> = ({
  isOpened,
  integrationLoading,
  handleIntegrate,
  onClickClose,
}) => {
  const { t } = useTranslation()
  const [isIntegrationError, setIsIntegrationError] = useState(false)
  const {
    register,
    formState: { errors },
    formState,
    getValues,
  } = useForm<FormValue>({
    mode: 'onChange',
    resolver: yupResolver(
      yup.object().shape({
        accessToken: yup.string().required(t('REQUIRED_ERROR')),
        secretToken: yup.string().required(t('REQUIRED_ERROR')),
        tenantId: yup.string().required(t('REQUIRED_ERROR')),
      }),
    ),
    defaultValues: {
      accessToken: '',
      secretToken: '',
      tenantId: '',
    },
  })

  const onSubmit = useCallback(async () => {
    try {
      await handleIntegrate(getValues())
      setIsIntegrationError(false)
    } catch (e) {
      setIsIntegrationError(true)
    }
  }, [getValues, handleIntegrate])

  return (
    <Modal
      title={t('INTEGRATE_X', { x: t('SMARTHR') })}
      isOpened={isOpened}
      isRunning={integrationLoading}
      disabledExecute={!formState.isValid}
      executeText={t('INTEGRATE')}
      onClickExecute={onSubmit}
      onClickCancel={onClickClose}
      onClickOutside={onClickClose}
    >
      <form>
        <StyledText oneline size="small" color="text-bk-50" weight="bold">
          {t('ACCESS_TOKEN')}
        </StyledText>
        <Input
          {...register('accessToken')}
          isError={!!errors.accessToken}
          type="text"
          fieldStyle="bottomLine"
          css={styles.input}
        />
        <StyledText oneline size="small" color="text-bk-50" weight="bold">
          {t('SECRET_TOKEN')}
        </StyledText>
        <Input
          {...register('secretToken')}
          isError={!!errors.secretToken}
          type="text"
          fieldStyle="bottomLine"
          css={styles.input}
        />
        <StyledText oneline size="small" color="text-bk-50" weight="bold">
          {t('TENANT_ID')}
        </StyledText>
        <Input
          {...register('tenantId')}
          isError={!!errors.tenantId}
          type="text"
          fieldStyle="bottomLine"
          css={styles.input}
        />

        <StyledText color="text-bk-100">
          <Trans
            i18nKey="CHECK_HELP_FOR_TOKEN_WITH_SMARTHR"
            components={[
              <Link method="newTab" href={SMARTHR_HELP} css={styles.link}>
                help
              </Link>,
            ]}
          />
        </StyledText>
        {isIntegrationError && (
          <ErrorMessage css={styles.error}>
            <Trans
              i18nKey="INTEGRATION_SMARTHR_ERROR"
              components={[
                <Link method="newTab" href={SMARTHR_HELP} css={styles.link}>
                  help
                </Link>,
              ]}
            />
          </ErrorMessage>
        )}
      </form>
    </Modal>
  )
}

SmartHRSettingModal.displayName = 'SmartHRSettingModal'
