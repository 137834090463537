import { ObjectiveDetailOption } from '../../components/domain/ObjectiveDetailOption'
import { ObjectiveDetailPageCard } from '../../components/domain/ObjectiveDetailPageCard'
import { PageContent } from '../../components/pageContent'
import { color } from '../../styles/newColors'
import { AuthRouteProps } from '../../types/authRouteProps'

import { useInjection } from './hooks'

export const ObjectiveDetailContainer: React.FC<AuthRouteProps> = (props) => {
  const data = useInjection(props)

  if (!data) {
    return null
  }

  const { objective, breadcrumbs, onObjectiveEdit } = data

  return (
    <PageContent
      breadcrumbs={breadcrumbs}
      layout={{
        css: {
          backgroundColor: color('background-bk-5'),
          padding: '60px max(40px, calc((100% - 1045px)/2)) ',
          minWidth: '1125px',
        },
      }}
    >
      <div
        css={{
          display: 'flex',
        }}
      >
        <div
          css={{
            minWidth: '805px',
          }}
        >
          <ObjectiveDetailPageCard onEdit={onObjectiveEdit} objective={objective} />
        </div>
        <div css={{ marginTop: '9px', marginLeft: '8px', minWidth: '232px' }}>
          <ObjectiveDetailOption objective={objective} />
        </div>
      </div>
    </PageContent>
  )
}

ObjectiveDetailContainer.displayName = 'ObjectiveDetailContainer'
