import { css } from '@emotion/react'
import { VFC, useMemo, Fragment } from 'react'

import { FontSizeAlias } from '../../../styles/font'
import { color as colorFunc, hex2rgba } from '../../../styles/newColors'
import { StyledText } from '../StyledText'

export const DefaultBarWidth = 84

export type Props = JSX.IntrinsicElements['div'] & {
  color: string
  rate: number
  size?: FontSizeAlias
  withoutText?: boolean
  barWidth?: string
}

export const ProgressRate: VFC<Props> = ({
  color,
  rate,
  size = 'small',
  withoutText,
  barWidth = DefaultBarWidth,
  ...props
}) => {
  const percentText = useMemo(() => {
    if (withoutText) return <Fragment />
    return (
      <StyledText
        weight={700}
        css={{
          display: 'block',
          color: rate >= 50 ? colorFunc('white-100') : color,
          marginRight: rate >= 50 ? 4 : 0,
          marginLeft: rate < 50 ? 4 : 0,
        }}
        size={size}
        lineHeight="16px"
      >
        {`${rate}%`}
      </StyledText>
    )
  }, [color, rate, size, withoutText])

  const barBox = useMemo(
    () =>
      css({
        display: 'flex',
        height: 16,
        width: barWidth,
        backgroundColor: hex2rgba(color, 16),
        borderRadius: '8px',
        overflow: 'hidden',
      }),
    [barWidth, color],
  )

  const barCss = useMemo(
    () =>
      css({
        width: `${rate > 100 ? 100 : rate}%`, // バーが突き抜けないようにmaxを100とする
        backgroundColor: color,
        textAlign: rate >= 50 ? 'right' : 'left',
      }),
    [color, rate],
  )

  return (
    <div css={barBox} {...props}>
      <div css={barCss}>{!withoutText && rate >= 50 && percentText}</div>
      {!withoutText && rate < 50 && percentText}
    </div>
  )
}

ProgressRate.displayName = 'ProgressRate'
