import { VFC } from 'react'

import { Modal } from '../../components/ui/Modal'
import { StyledText } from '../../components/ui/StyledText'

type Props = {
  isOpened: boolean
  onClickSegration: () => void
  onClickCancel: () => void
}

export const SegregatedUserModal: VFC<Props> = ({ isOpened, onClickSegration, onClickCancel }) => (
  <Modal
    title="非連携ユーザーに変更する"
    isOpened={isOpened}
    executeText="無効化"
    onClickExecute={onClickSegration}
    onClickCancel={onClickCancel}
  >
    <StyledText>
      このユーザーを本当に非連携にしますか？非連携にした場合でもユーザーの情報は保持され、連携された時には情報が復元されます。
    </StyledText>
  </Modal>
)

SegregatedUserModal.displayName = 'SegregatedUserModal'
