import {
  Props as RichTextEditorProps,
  DEFAULT_INITIAL_VALUE_JSON_STRING,
} from '../../../ui/RichTextEditor'
import type { DocumentInput } from '../graphql/graphql'

export const generateKeyResultLinkId = (keyResultId: string): string =>
  `checkin-modal-key-result-${keyResultId}`

export const isDefaultJsonComment = (
  initialValueJSON: RichTextEditorProps['initialValueJSON'],
): boolean => !!(!initialValueJSON || initialValueJSON === DEFAULT_INITIAL_VALUE_JSON_STRING)

export const isCommentChanged = (
  before: DocumentInput,
  after: DocumentInput,
  isCurrentComment: boolean,
): boolean =>
  (!!after?.treeJson && after?.treeJson !== DEFAULT_INITIAL_VALUE_JSON_STRING) ||
  (isCurrentComment && after?.treeJson !== before.treeJson)
