import { Tooltip } from '@resily/geisha'
import dayjs from 'dayjs'
import { ThemeContext } from 'grommet'
import { Draggable } from 'react-beautiful-dnd'

import { useTranslation } from '../../../../../../i18n'
import { barColorConfidence } from '../../../../../../lib/confidence'
import { keyResultToConfidenceLevel } from '../../../../../../lib/domain/confidence'
import { isNotFilledRequiredTargetValue } from '../../../../../../lib/domain/keyResult/keyResultProgress'
import { useDraggableInPortal } from '../../../../../../lib/reactBeautifulDnd/hooks/useDraggableInPortal'
import { findScreen } from '../../../../../../lib/screen'
import { componentNames, featureNames, generateTestId } from '../../../../../../lib/testId'
import { tracker } from '../../../../../../lib/tracking'
import { color } from '../../../../../../styles/newColors'
import { KeyResultAvatarWithContributor } from '../../../../../domain/AvatarWithContributor'
import { ProgressRateTooltipWithContext } from '../../../../../domain/ProgressRateTooltip'
import { Icon } from '../../../../../ui/Icon'
import { ProgressRate } from '../../../../../ui/Meter'
import { StyledText } from '../../../../../ui/StyledText'
import {
  KeyResultAreaKeyResultFragment,
  KeyResultUpdateType,
  TargetSettingType,
} from '../../../graphql'
import { useOkrModalQueryParams } from '../../../hooks/useOkrModalQueryParams'

import { useStyles } from './KeyResultAreaTableRow.styles'

export type Props = {
  index: number
  kr: KeyResultAreaKeyResultFragment
  termId: string
  hoverActionDisabled: boolean
  onClickKeyResultName: (keyResultId: string) => void
}

export const KeyResultAreaTableRow: React.VFC<Props> = ({
  index,
  kr,
  termId,
  hoverActionDisabled,
  onClickKeyResultName,
}) => {
  const { t } = useTranslation()
  const styles = useStyles({ hoverActionDisabled, disabled: kr.isDisabled })
  const renderDraggable = useDraggableInPortal()
  const { keyResultId } = useOkrModalQueryParams()

  const isManual = kr.updateType === KeyResultUpdateType.Manual || kr.isAutoAggregate
  const label = isManual
    ? `${kr.progressRate ?? 0} / 100 %`
    : `${kr.actualValue ?? 0} / ${kr.targetValue} ${kr.unit ?? ''}`

  return (
    <Draggable draggableId={kr.id} index={index} css={{ minWidth: '100%' }}>
      {renderDraggable((p) => (
        // eslint-disable-next-line jsx-a11y/interactive-supports-focus, jsx-a11y/click-events-have-key-events
        <div
          ref={p.innerRef}
          {...p.draggableProps}
          role="button"
          onClick={() => {
            tracker.UserClickOkrModalInKeyResultName(
              findScreen(window.location.pathname, window.location.search),
            )
            onClickKeyResultName(kr.id)
          }}
          css={styles.tableRow}
          data-is-selected={kr.id === keyResultId}
        >
          <div {...p.dragHandleProps} css={styles.draggableIcon}>
            <Icon type="dragIndicator" width={12} height={12} color={color('text-bk-30')} />
          </div>
          <div css={styles.avatars}>
            <KeyResultAvatarWithContributor
              size="xsmall"
              owner={kr.owner}
              contributors={kr.members}
              termId={termId}
              keyResult={kr}
              popoverDisabled={hoverActionDisabled}
              avatarLinkMethod="newTab"
            />
          </div>
          <Tooltip label={kr.name} position="bottom-start" disabled={hoverActionDisabled}>
            <div css={styles.krTitle} data-is-selected={kr.id === keyResultId}>
              {kr.targetSetting === TargetSettingType.Moon && (
                <Icon type="moon" width={16} height={16} css={styles.krIcon} />
              )}
              {kr.targetSetting === TargetSettingType.Roof && (
                <Icon type="roof" width={16} height={16} css={styles.krIcon} />
              )}
              <span className="krname">{kr.name}</span>
            </div>
          </Tooltip>
          <div css={styles.progressRate}>
            <ThemeContext.Extend value={{ global: { drop: { zIndex: 201 } } }}>
              <ProgressRateTooltipWithContext
                withGeishaModal
                keyResult={kr}
                hoverActionDisabled={hoverActionDisabled}
              >
                <button
                  type="button"
                  onClick={() => {
                    tracker.UserClickProgressRateByObjectiveModal(
                      findScreen(window.location.pathname, window.location.search),
                    )
                  }}
                  data-testid={generateTestId(featureNames.okrModal, componentNames.percentageBar)}
                >
                  <div css={styles.progressRateButton}>
                    <div css={styles.progressRateBar}>
                      <ProgressRate
                        withoutText
                        color={color(barColorConfidence(keyResultToConfidenceLevel(kr)))}
                        rate={kr.progressRate || 0}
                        barWidth="48px"
                      />
                    </div>
                    <StyledText fontStyle="regular" size="small">
                      {kr.progressRate || 0}%
                    </StyledText>
                  </div>
                </button>
              </ProgressRateTooltipWithContext>
            </ThemeContext.Extend>
          </div>
          <div css={styles.values}>
            {!isNotFilledRequiredTargetValue(kr) ? (
              <Tooltip label={label} position="bottom" disabled={hoverActionDisabled}>
                <span css={styles.valuesText}>{label}</span>
              </Tooltip>
            ) : (
              <StyledText fontStyle="regular" size="small" css={styles.notSettingValue}>
                {t('NOT_SET')}
              </StyledText>
            )}
          </div>
          <div css={styles.lastUpdate}>
            <StyledText fontStyle="regular" size="small">
              {dayjs(kr.updatedAt).format('MM/DD')}
            </StyledText>
          </div>
        </div>
      ))}
    </Draggable>
  )
}
KeyResultAreaTableRow.displayName = 'KeyResultAreaTableRow'
