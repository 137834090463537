import { FieldArrayWithId } from 'react-hook-form'

import { Trans, useTranslation } from '../../../../../../../../../../i18n'
import { isQualitativeGoal } from '../../../../../../../../../../lib/domain/keyResult/keyResultProgress'
import { color } from '../../../../../../../../../../styles/newColors'
import { ExternalUrls } from '../../../../../../../../../../urls'
import { Link } from '../../../../../../../../../ui/Link'
import { useGetFormMethods } from '../../../../../../../form/contexts/FormProvider'
import { FormValues, keyResultsKeyName } from '../../../../../../../form/formSchema'
import { initialSettingsCompleted, isPercentageInput } from '../../../../utils'
import { PromptMessageBase as PromptMessage } from '../Base'

type QualitativeGoalProps = {
  keyResult: FieldArrayWithId<FormValues, 'keyResults', 'id'>
  index: number
}

const linkCss = {
  color: color('resily-orange-100'),
}

export const QualitativeGoal: React.FC<QualitativeGoalProps> = ({ keyResult, index }) => {
  const { watch } = useGetFormMethods()
  const { t } = useTranslation()

  const [targetValue, initialValue, unit] = watch([
    `${keyResultsKeyName}.${index}.targetValue`,
    `${keyResultsKeyName}.${index}.initialValue`,
    `${keyResultsKeyName}.${index}.unit`,
  ])

  if (
    isPercentageInput(keyResult) &&
    !initialSettingsCompleted(keyResult) &&
    isQualitativeGoal(t, targetValue, initialValue, unit)
  ) {
    return (
      <PromptMessage>
        <Trans
          i18nKey="QUALITATIVE_GOAL_CAUTION_MESSAGE"
          components={[
            <Link method="newTab" href={ExternalUrls.FAQ_WORRY_OKR} css={linkCss}>
              こちらのページ
            </Link>,
          ]}
        />
      </PromptMessage>
    )
  }
  return null
}
