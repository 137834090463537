import { css } from '@emotion/react'
import { memo, useCallback, useMemo, useRef, useState } from 'react'
import isEqual from 'react-fast-compare'

import { OverflowDetector } from '../../../lib/OverflowDetector'
import { getAncestors, GroupTree } from '../../../lib/domain/group'
import { GroupTreeTag, Props as GroupTreeTagProps } from '../../ui/GroupTreeTag'
import { HiddenGroupsTreeTag } from '../../ui/HiddenGroupsTreeTag'

import { PartialGroupFragment, TreeGroupFragment } from './graphql'

const rootCss = css({
  display: 'flex',
  flexWrap: 'wrap',
  listStyle: 'none',
  maxHeight: '100%',
  maxWidth: '100%',
  overflow: 'hidden',
})
const detectorCss = css({ marginBottom: 8, marginRight: 8, maxWidth: '100%' })

export type Props = Omit<JSX.IntrinsicElements['ul'], 'css'> & {
  groups: ReadonlyArray<PartialGroupFragment>
  tagProps?: Omit<GroupTreeTagProps, 'name' | 'ancestorNames' | 'hiddenGroupsTreeTag'>
}

// 親やpropsで領域を制限されると隠れた部分を+Nで表示するGroupTagの集まり
export const GroupTags = memo<Props>(({ groups, tagProps, ...props }) => {
  const [hiddenGroupIds, setHiddenGroupIds] = useState<ReadonlySet<string>>(new Set())
  const groupsRef = useRef<HTMLUListElement>(null)

  const groupAncestorNames = useMemo<ReadonlyMap<string, ReadonlyArray<string>>>(() => {
    setHiddenGroupIds(new Set())

    const m = new Map<string, ReadonlyArray<string>>()
    groups
      .filter((g): g is GroupTree<TreeGroupFragment> => Boolean(g))
      .forEach((g) => {
        m.set(
          g.id,
          getAncestors(g).map((a) => a.name),
        )
      })
    return m
  }, [groups])

  const hideLastGroup = useCallback(
    (isHidden: boolean) => {
      if (!isHidden) return
      const visibleLastGroupId = groups.filter((g) => !hiddenGroupIds.has(g.id)).pop()
      if (visibleLastGroupId) {
        // +N タグが見えるようになるまでそれ以前のタグを後ろから順に非表示にする
        setHiddenGroupIds((prev) => new Set([...prev, visibleLastGroupId.id]))
      }
    },
    [groups, hiddenGroupIds],
  )

  return (
    <ul ref={groupsRef} css={rootCss} {...props}>
      {groups
        .filter((g) => !hiddenGroupIds.has(g.id) && groupAncestorNames.has(g.id))
        .map((group) => (
          <OverflowDetector
            key={group.id}
            as="li"
            css={detectorCss}
            containerRef={groupsRef}
            onChangeOverflow={(isHidden) => {
              setHiddenGroupIds((prev) => {
                if (isHidden) {
                  return new Set([...prev, group.id])
                }
                const next = new Set(prev)
                return next.delete(group.id) ? next : prev
              })
            }}
          >
            <GroupTreeTag
              name={group.name}
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              ancestorNames={groupAncestorNames.get(group.id)!}
              {...tagProps}
            />
          </OverflowDetector>
        ))}
      {hiddenGroupIds.size > 0 && (
        <OverflowDetector
          as="li"
          css={detectorCss}
          containerRef={groupsRef}
          onChangeOverflow={hideLastGroup}
        >
          <HiddenGroupsTreeTag
            ancestorsAndNames={groups
              .filter((g) => hiddenGroupIds.has(g.id) && groupAncestorNames.has(g.id))
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              .map((g) => groupAncestorNames.get(g.id)!.concat([g.name]))}
            {...tagProps}
          />
        </OverflowDetector>
      )}
    </ul>
  )
}, isEqual)

GroupTags.displayName = 'GroupTags'
