import { useMemo } from 'react'
import { NavigateFunction } from 'react-router-dom'

import { TFunction } from '../../../i18n'
import { useSelfServeConfig } from '../../../lib/domain/organization/useSelfServeConfig'
import {
  accountSetting as accountSettingURL,
  organizationAdmin as organizationAdminURL,
  okrTermsAdmin as okrTermsAdminURL,
  usersAdmin as usersAdminURL,
  okrGlossaries as okrGlossariesURL,
  oneOnOneAdmin as oneOnOneAdminURL,
  integrationsAdmin as integrationsAdminURL,
  groupsSettingAdmin as groupsSettingAdminURL,
  security as securityURL,
  billingAdmin as billingAdminURL,
} from '../../../urls'
import { TitleWithMenus } from '../types'

type UseTitleWithMenusArgs = {
  isAdmin: boolean
  t: TFunction
  navigate: NavigateFunction
}

export const useTitleWithMenus = ({
  isAdmin,
  t,
  navigate,
}: UseTitleWithMenusArgs): TitleWithMenus => {
  const { isSelfServe } = useSelfServeConfig()

  const titleWithMenus: TitleWithMenus = useMemo<TitleWithMenus>(() => {
    // アカウントの設定メニューの項目
    const personalSettingTitleWithMenus: TitleWithMenus = [
      // 個人設定
      {
        key: 'personal_settings',
        title: t('PERSONAL_SETTING'),
        menuCards: [generatePersonalSettingMenuCard(t, navigate)],
      },
    ]

    // 組織設定メニューの項目の項目
    const organizationSettingTitleWithMenu: TitleWithMenus[number] = {
      key: 'organization_setting',
      title: t('ORGANIZATION_SETTINGS'),
      menuCards: [
        generateOrganizationSettingMenuCard(t, navigate, isSelfServe),
        generateDisplayAndFeatureMenuCard(t, navigate),
        generateSecurityMenuCard(t, navigate),
      ],
    }

    // FIXME: 暫定対応、現実装では拡張性に欠ける
    return isAdmin
      ? [...personalSettingTitleWithMenus, organizationSettingTitleWithMenu]
      : [
          ...personalSettingTitleWithMenus,
          {
            key: 'organization_setting',
            title: t('ORGANIZATION_SETTINGS'),
            menuCards: [
              {
                key: 'organization_info_settings',
                title: t('ORGANIZATION_INFO_SETTINGS'),
                menus: [
                  {
                    key: 'admin_group_setting',
                    icon: 'group2',
                    title: t('GROUP'),
                    description: t('ADMIN_GROUP_SETTING_DESCRIPTION'),
                    onClick: () => navigate(groupsSettingAdminURL),
                  },
                ],
              },
            ],
          },
        ]
  }, [isAdmin, isSelfServe, navigate, t])

  return titleWithMenus
}

// アカウント設定のカードのエリアのデータを作成する
const generatePersonalSettingMenuCard = (
  t: TFunction,
  navigate: NavigateFunction,
): TitleWithMenus[number]['menuCards'][number] => ({
  key: 'account_settings',
  menus: [
    {
      key: 'account_setting_menu',
      icon: 'settingOutline',
      title: t('ACCOUNT_SETTING'),
      description: t('ACCOUNT_SETTING_DESCRIPTION'),
      onClick: () => navigate(accountSettingURL),
    },
  ],
})

// 組織基本情報のカードのエリアのデータを作成する
const generateOrganizationSettingMenuCard = (
  t: TFunction,
  navigate: NavigateFunction,
  isSelfServe: boolean,
): TitleWithMenus[number]['menuCards'][number] => {
  const ret: TitleWithMenus[number]['menuCards'][number] = {
    key: 'organization_info_settings',
    title: t('ORGANIZATION_INFO_SETTINGS'),
    menus: [
      {
        key: 'organization_name_setting',
        icon: 'company',
        title: t('ORGANIZATION_NAME'),
        description: t('ADMIN_ORGANIZATION_NAME_SETTING_DESCRIPTION'),
        onClick: () => navigate(organizationAdminURL),
      },
      {
        key: 'okr_term_setting',
        icon: 'date',
        title: t('OKR_TERM'),
        description: t('ADMIN_OKR_TERM_SETTING_DESCRIPTION'),
        onClick: () => navigate(okrTermsAdminURL),
      },
      {
        key: 'admin_user_setting',
        icon: 'user2',
        title: t('USER'),
        description: t('ADMIN_USER_SETTING_DESCRIPTION'),
        onClick: () => navigate(usersAdminURL),
      },
      {
        key: 'admin_group_setting',
        icon: 'group2',
        title: t('GROUP'),
        description: t('ADMIN_GROUP_SETTING_DESCRIPTION'),
        onClick: () => navigate(groupsSettingAdminURL),
      },
    ],
  }

  if (isSelfServe) {
    return {
      ...ret,
      menus: [
        ...ret.menus,
        {
          key: 'billing_menu',
          icon: 'settingOutline',
          title: 'プラン・お支払い',
          description: 'プランに管理とお支払い履歴を確認します',
          onClick: () => navigate(billingAdminURL),
        },
      ],
    }
  }

  return ret
}

// 画面表示・機能設定のカードのエリアのデータを作成する
const generateDisplayAndFeatureMenuCard = (
  t: TFunction,
  navigate: NavigateFunction,
): TitleWithMenus[number]['menuCards'][number] => ({
  key: 'display_and_feature_settings',
  title: t('DISPLAY_AND_FEATURE_SETTINGS'),
  menus: [
    {
      key: 'admin_glossary_setting',
      icon: 'note',
      title: t('DEFAULT_TEXT_'),
      description: t('ADMIN_GLOSSARY_SETTING_DESCRIPTION'),
      onClick: () => navigate(okrGlossariesURL),
    },
    {
      key: 'admin_one_on_one_setting',
      icon: 'oneOnOne',
      title: t('ONEONONE_FEATURE'),
      description: t('ADMIN_ONE_ON_ONE_SETTING_DESCRIPTION'),
      onClick: () => navigate(oneOnOneAdminURL),
    },
  ],
})

// セキュリティ設定のカードのエリアのデータを作成する
const generateSecurityMenuCard = (
  t: TFunction,
  navigate: NavigateFunction,
): TitleWithMenus[number]['menuCards'][number] => ({
  key: 'security_settings',
  title: t('SECURITY_SETTING'),
  menus: [
    {
      key: 'admin_integrations_setting',
      icon: 'sync',
      title: t('INTEGRATIONS'),
      description: t('ADMIN_INTEGRATION_SETTING_DESCRIPTION'),
      onClick: () => navigate(integrationsAdminURL),
    },
    {
      icon: 'mdiPublic',
      key: 'admin_ip_and_auth_setting',
      title: t('IP_RESTRICTION_AND_AUTH_SETTING'),
      description: t('ADMIN_IP_AND_AUTH_SETTING_SETTING_DESCRIPTION'),
      onClick: () => navigate(securityURL),
    },
  ],
})
