/* eslint-disable rulesdir/require-files */
import { useCallback, useContext, useMemo } from 'react'

import { OkrTermIdContext } from '../../../contexts/OkrTermIdContext'

import { ParentOkrSelect, Props as ParentOkrSelectProps } from './ParentOkrSelect'
import { OkrNodeFragment, useOkrNodesLazyQuery } from './graphql'

export type Props = Pick<
  ParentOkrSelectProps<OkrNodeFragment>,
  'fullWidth' | 'value' | 'onChange'
> & {
  editingObjectiveId: string | undefined
}

export const ParentOkrSelectContainer: React.VFC<Props> = ({ editingObjectiveId, ...props }) => {
  const { okrTermId } = useContext(OkrTermIdContext)

  const [okrNodesQuery, { data, called, loading }] = useOkrNodesLazyQuery()
  const nodes = useMemo(
    () =>
      (editingObjectiveId
        ? data?.okrNodes.filter((n) => n.objective.id !== editingObjectiveId)
        : data?.okrNodes) ?? [],
    [data?.okrNodes, editingObjectiveId],
  )
  const onOpen = useCallback(() => {
    if (!called && okrTermId) {
      okrNodesQuery({ variables: { okrTermId } })
    }
  }, [okrTermId, called, okrNodesQuery])

  return <ParentOkrSelect loading={loading} clear nodes={nodes} onOpen={onOpen} {...props} />
}
