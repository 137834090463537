import { ActionPlanDetailEditPageCard } from '../../components/domain/ActionPlanDetailEditPageCard'
import { ActionPlanOption } from '../../components/domain/ActionPlanOption'
import { PageContent } from '../../components/pageContent'
import { color } from '../../styles/newColors'
import { AuthRouteProps } from '../../types/authRouteProps'

import { useInjection } from './hooks'

export const ActionPlanDetailEditContainer: React.FC<AuthRouteProps> = (props) => {
  const data = useInjection(props)

  if (!data) {
    return null
  }

  const { breadcrumbs, actionPlan, onUpdateActionPlan, onClickCancel, setNeedBlock, actionPlanId } =
    data

  return (
    <PageContent
      breadcrumbs={breadcrumbs}
      layout={{
        css: {
          backgroundColor: color('background-bk-5'),
          paddingTop: '60px',
          paddingLeft: '76px',
        },
      }}
    >
      <div css={{ display: 'flex' }}>
        <div
          css={{
            flex: '4 1 0',
          }}
        >
          <ActionPlanDetailEditPageCard
            actionPlan={actionPlan}
            updateActionPlan={onUpdateActionPlan}
            onClickCancel={onClickCancel}
            setNeedBlock={setNeedBlock}
          />
        </div>
        <div css={{ marginTop: '9px', marginRight: '77px', flex: '1 1 0' }}>
          <ActionPlanOption actionPlanId={actionPlanId} />
        </div>
      </div>
    </PageContent>
  )
}

ActionPlanDetailEditContainer.displayName = 'ActionPlanDetailEditContainer'
