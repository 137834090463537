import { useState } from 'react'
import { v1 as uuid } from 'uuid'

import { useCurrentUser } from '../../../contexts/UserContext'
import { useTranslation } from '../../../i18n'
import { InterpolationWithTheme } from '../../../lib/emotion'
import { color } from '../../../styles/newColors'
import { DropTool } from '../../ui/DropTool'
import { Icon } from '../../ui/Icon'
import { StyledText } from '../../ui/StyledText'
import { Tooltip } from '../../ui/Tooltip'

import { EmojiButton } from './EmojiButton'
import { ReactedEmoji, reactionDef } from './ReactedEmoji'

const reactionGroups = [
  reactionDef.slice(0, reactionDef.length / 2),
  reactionDef.slice(reactionDef.length / 2),
]

export type ReactionItem = {
  id: string
  emoji: string
  user: {
    id: string
    firstName: string
    lastName: string
  }
}

export type Props = {
  rootCss?: InterpolationWithTheme
  reactions: ReadonlyArray<ReactionItem>
  createReaction: (emoji: string) => void
  deleteReaction: (reactedId: string) => void
}

type ReactedEmojis = {
  [emoji: string]: Array<
    Readonly<{ reactedId: string; userId: string; lastName: string; firstName: string }>
  >
}

const groupByEmoji = (reactions: ReadonlyArray<ReactionItem>): ReactedEmojis => {
  const result: ReactedEmojis = {}
  reactions.forEach((item: ReactionItem) => {
    if (typeof result[item.emoji] === 'undefined') {
      result[item.emoji] = []
    }
    result[item.emoji].push({
      reactedId: item.id,
      userId: item.user.id,
      firstName: item.user.firstName,
      lastName: item.user.lastName,
    })
  })
  return result
}

export const Reaction: React.FC<Props> = ({
  rootCss,
  reactions,
  createReaction,
  deleteReaction,
}) => {
  const { t } = useTranslation()
  const user = useCurrentUser()
  const emojis = groupByEmoji(reactions)
  const [isOpened, setIsOpened] = useState(false)
  const keys = Object.keys(emojis)
  keys.sort(
    (a, b) =>
      reactionDef.findIndex((e) => e.value === a) - reactionDef.findIndex((e) => e.value === b),
  )

  return (
    <div css={rootCss}>
      <div css={{ display: 'flex', alignItems: 'center' }}>
        <div css={{ display: 'flex' }}>
          {keys.map((e) => (
            <ReactedEmoji
              key={e}
              emoji={e}
              userId={user?.id ?? ''}
              reactedUsers={emojis[e]}
              createReaction={createReaction}
              deleteReaction={deleteReaction}
            />
          ))}
        </div>
        <DropTool
          isOpened={isOpened}
          onOpen={() => setIsOpened(true)}
          onClose={() => setIsOpened(false)}
          title={
            <div
              css={{
                zIndex: 10,
                marginTop: '8px',
                padding: '5px',
                background: 'white',
                borderRadius: '4px',
                boxShadow: '0 1px 15px rgb(27 31 35 / 15%)',
                border: '1px solid rgb(27 31 35 / 15%)',
                backgroundClip: 'padding-box',
              }}
            >
              <div css={{ margin: '8px', textAlign: 'center' }}>
                <StyledText size="small" css={{ fontWeight: 'bold' }}>
                  {t('PICK_REACTION')}
                </StyledText>
              </div>
              <div
                css={{
                  display: 'block',
                  height: 0,
                  margin: '8px 0',
                  borderTop: '1px solid #e1e4e8',
                }}
              />
              {reactionGroups.map((group, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={index} css={{ display: 'flex', marginBottom: 5 }}>
                  {group.map((i) => (
                    <EmojiButton
                      key={i.value}
                      emoji={i.value}
                      reactedUsers={emojis[i.value] ?? []}
                      userId={user?.id ?? ''}
                      createReaction={createReaction}
                      deleteReaction={deleteReaction}
                    />
                  ))}
                </div>
              ))}
            </div>
          }
        >
          <Tooltip
            key={uuid()}
            dropAlign={{ top: 'bottom' }}
            customized
            title={
              <div
                css={{
                  background: color('text-bk-80'),
                  fontSize: '12px',
                  color: 'white',
                  padding: '4px 8px',
                  marginTop: '4px',
                  width: 'auto',
                  maxWidth: 200,
                  borderRadius: 4,
                  lineHeight: '16px',
                }}
              >
                {t('REACT')}
              </div>
            }
          >
            <button
              type="button"
              className="reaction-button"
              css={{
                '&:hover': {
                  '.reaction': { color: color('resily-orange-100') },
                  border: `1px solid ${color('border-bk-30')}`,
                },
                position: 'relative',
                backgroundColor: color('background-bk-5'),
                height: '24px',
                width: '26px',
                outline: 'none',
                borderRadius: '4px',
              }}
            >
              <Icon
                className="reaction"
                type="reaction"
                color={color('text-bk-50')}
                hoverColor="resily-orange-100"
                css={{
                  verticalAlign: 'middle',
                }}
              />
            </button>
          </Tooltip>
        </DropTool>
      </div>
    </div>
  )
}

Reaction.displayName = 'Reaction'
