import { Layer } from 'grommet'
import React from 'react'

import { Icon } from '../../../../components/ui/Icon'
import { configureDomain } from '../../../../config'
import { color } from '../../../../styles/newColors'

const { cdnDomain } = configureDomain()

type Props = {
  isOpened: boolean
  onClose: () => void
}

export const ChangeLicenseTutorialModal: React.VFC<Props> = ({ isOpened, onClose }) =>
  isOpened ? (
    <>
      <Layer onEsc={onClose} onClickOutside={onClose}>
        <img src={`https://${cdnDomain}/contents/aseets/tutorial_change_license.gif`} alt="" />
      </Layer>
      <button
        type="button"
        css={{
          borderRadius: '4px',
          height: '28px',
          width: '28px',
          backgroundColor: color('white-100'),
          position: 'fixed',
          top: '10px',
          right: '10px',
          zIndex: 201,
        }}
      >
        <Icon type="mdiClear" color={color('text-bk-20')} hoverColor="resily-orange-100" />
      </button>
    </>
  ) : null
