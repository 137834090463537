import { useTranslation } from '../../../../../../i18n'
import { StyledText } from '../../../../../ui/StyledText'

export const CheckinAreaEmptyView: React.VFC = () => {
  const { t } = useTranslation()

  return (
    <StyledText color="text-bk-100" size="medium" fontStyle="regular" lineHeight="22px">
      {t('NOT_EXIST_CHECKIN_NOTE')}
    </StyledText>
  )
}
