import { Text, Box, Stack, BoxProps, Meter } from 'grommet'
import React from 'react'

import { TooltipNew } from '../TooltipNew'

export type Props = BoxProps & {
  ratio?: number
  meterColor?: string
}

/**
 *
 * 段階移行のためCircleMeterを既存のものとは別で実装している
 */
export const NewCircleMeter: React.FC<Props> = ({ ratio, meterColor, ...props }) => {
  const roundedRatio = ratio ? Math.round(ratio * 100) : undefined
  const isOver = roundedRatio ? roundedRatio > -1000 && roundedRatio < 1000 : undefined

  return (
    <Box direction="row" {...props}>
      <TooltipNew
        dropProps={{ stretch: false, plain: true }}
        disabled={!roundedRatio || isOver}
        title={roundedRatio?.toString() || ''}
      >
        <Stack anchor="center">
          <Meter
            type="circle"
            background="#F4F4F6"
            size="44px"
            thickness="4px"
            values={[{ value: ratio == null ? 0 : ratio * 100, color: meterColor }]}
          />
          {roundedRatio ? (
            <Box direction="row" justify="center" align="baseline">
              <Text css={{ fontSize: 14, lineHeight: '16px', color: '#222943' }}>
                {Math.max(Math.min(roundedRatio, 999), -999)}
              </Text>
              <Text textAlign="end" css={{ fontSize: 10, lineHeight: '12px', color: '#222943' }}>
                {/* eslint-disable-next-line no-nested-ternary */}
                {roundedRatio > 999 ? '+' : roundedRatio < -999 ? null : '%'}
              </Text>
            </Box>
          ) : (
            <Text weight="bold">--</Text>
          )}
        </Stack>
      </TooltipNew>
    </Box>
  )
}

NewCircleMeter.displayName = 'NewCircleMeter'
