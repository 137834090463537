import { css } from '@emotion/react'

import { color } from '../../../../../../styles/newColors'

export const rootCss = css({
  padding: '20px 24px 20px 32px',
  backgroundColor: color('background-bk-5'),
  overflow: 'auto',
})

export const indexItemCss = css({
  display: 'block',
  padding: '8px',
  textDecoration: 'none',
  ':hover span': {
    color: color('resily-orange-100'),
    '::before': {
      backgroundColor: color('resily-orange-100'),
    },
  },
})

export const selectedIndexItemCss = css({
  borderRadius: '8px',
  backgroundColor: color('border-bk-10'),
  span: {
    color: color('resily-orange-100'),
    '::before': {
      backgroundColor: color('resily-orange-100'),
    },
  },
})

export const indexTitleCss = css({
  position: 'relative',
  paddingLeft: '14px',
  color: color('text-bk-30'),
  '&::before': {
    position: 'absolute',
    top: '0',
    bottom: '0',
    left: '0',
    width: '6px',
    height: '6px',
    margin: 'auto 8px auto 0',
    content: `""`,
    backgroundColor: color('text-bk-30'),
    borderRadius: '50%',
  },
})

export const signalsWrapperCss = css({ display: 'flex', gap: '4px', marginLeft: '14px' })
