import { css } from '@emotion/react'
import { useProfiler } from '@sentry/react'
import { VFC, useCallback } from 'react'
import { useWindowSize } from 'react-use'

import {
  GroupAndUserFilter,
  Props as GroupAndUserFilterProps,
} from '../../../components/domain/GroupAndUserFilter'
import { getSideNavWidth } from '../../../components/layout/GloNavAndMain/SideNav/useSideNavWidth'
import { tracker } from '../../../lib/tracking'
import { border } from '../../../styles/border'
import { color } from '../../../styles/newColors'

import { MapViewMode, Props as MapViewModeProps } from './MapViewMode'
import { OkrControler, Props as OkrControlerProps } from './OkrControler'

const headerTopCss = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderBottom: border('simple-10'),
  padding: '8px 24px',
  overflowX: 'auto',
})
const headerBottomCss = css({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '8px 24px',
  overflowX: 'auto',
})
const headerCss = css({
  borderBottom: border('simple-10'),
  backgroundColor: color('white-100'),
  transition: 'background-color 0.3s linear',
  boxSizing: 'border-box' as const,
})

export type Props = Pick<
  OkrControlerProps,
  | 'existsTerm'
  | 'isShownDisabledOkr'
  | 'onChangeDisabledOkr'
  | 'personalVisibleNodeLevel'
  | 'onChangePersonalVisibleNodeLevel'
> &
  Pick<
    GroupAndUserFilterProps,
    | 'groups'
    | 'users'
    | 'selectedGroupIds'
    | 'selectedUserIds'
    | 'onFilterChange'
    | 'createBookmark'
  > & {
    viewType: MapViewModeProps['tab'] | null
    onChangeViewType: MapViewModeProps['onClickTab']
    showCreateOkrModal: () => void
  }

const noop = () => {}

export const OkrMapHeader: VFC<Props> = ({
  viewType,
  existsTerm,
  personalVisibleNodeLevel,
  groups,
  users,
  selectedGroupIds,
  selectedUserIds,
  isShownDisabledOkr,
  onChangeDisabledOkr,
  showCreateOkrModal,
  createBookmark,
  onChangeViewType,
  onFilterChange,
  onChangePersonalVisibleNodeLevel,
}) => {
  useProfiler('OkrMapHeader')

  const { width } = useWindowSize()
  const sideNavWidth = getSideNavWidth()
  const filterMaxWidth = width - sideNavWidth - 300

  const showActivity = useCallback(() => onChangeViewType('activity'), [onChangeViewType])

  return (
    <header css={headerCss}>
      <div css={headerTopCss}>
        <GroupAndUserFilter
          createBookmark={createBookmark}
          maxWidth={filterMaxWidth}
          type={undefined}
          name={undefined}
          onClickNameCancel={noop}
          canFilter
          groups={groups}
          users={users}
          selectedGroupIds={selectedGroupIds}
          selectedUserIds={selectedUserIds}
          onFilterChange={onFilterChange}
          onReflectGroupTracker={tracker.OkrMapSelectFocusGroup}
          onReflectUserTracker={tracker.OkrMapSelectFocusUser}
        />
        <MapViewMode tab={viewType || 'tree'} onClickTab={onChangeViewType} />
      </div>
      <div css={headerBottomCss}>
        <OkrControler
          existsTerm={existsTerm}
          personalVisibleNodeLevel={personalVisibleNodeLevel}
          isShownDisabledOkr={isShownDisabledOkr}
          showCreateOkrModal={showCreateOkrModal}
          onClickActivity={showActivity}
          onChangeDisabledOkr={onChangeDisabledOkr}
          onChangePersonalVisibleNodeLevel={onChangePersonalVisibleNodeLevel}
        />
      </div>
    </header>
  )
}

OkrMapHeader.displayName = 'OkrMapHeader'
