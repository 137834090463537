import { useProfiler } from '@sentry/react'
import { VFC, useCallback, useContext, useEffect, useMemo, useState, useRef } from 'react'
import { useNavigate, matchPath } from 'react-router-dom'
import { ArrayParam, createEnumParam, StringParam, useQueryParams } from 'use-query-params'

import { OkrFloatDrawerList } from '../../components/domain/OkrFloatDrawerList'
import { OkrFloatDrawerListItem } from '../../components/domain/OkrFloatDrawerList/types'
import { PageContent } from '../../components/pageContent'
import { useOkrCreateModal } from '../../components/standalone/OkrCreateModal'
import { useOkrModal } from '../../components/standalone/OkrModal'
import { AlertModalContext } from '../../contexts/AlertModalContext'
import { KrFilterContext } from '../../contexts/KrFilterContext'
import {
  useGetFloatDrawerItems,
  useSetFloatDrawerItems,
} from '../../contexts/OkrFloatDrawerContext'
import { useCurrentUser } from '../../contexts/UserContext'
import { Node } from '../../lib/collections/node'
import { OKRTree } from '../../lib/domain/okr/tree'
import { useOkrTermLoadable } from '../../lib/domain/useOkrTermLoadable'
import { useBlocker } from '../../lib/prompt'
import { findScreen, Screen } from '../../lib/screen'
import { AuthRouteProps } from '../../types/authRouteProps'
import {
  generateTrees,
  MapView,
  OkrMapType,
  OKR_LIST_TAB_QUERY,
  trees as treesPath,
} from '../../urls'

import { OkrCardIsFoldByIdContextProvider } from './OkrCardIsFoldByIdContext'
import { OkrCardIsFoldGlobalContextProvider } from './OkrCardIsFoldGlobalContext'
import { ActivityContainer } from './activity'
import {
  useInitialDataQuery,
  useOkrNodeByNodeLazyQuery,
  useOkrNodesLazyQuery,
  useUpdateShowDisabledOkrsMutation,
} from './graphql'
import { OkrMapHeaderContainer } from './header'
import { useEmptyState } from './hooks/useEmptyState'
import { useFilteredNodes } from './hooks/useFilteredNodes'
import { useHandlerForOkrCreation } from './hooks/useHandlerForOkrCreation'
import { useParseFilterConditions } from './hooks/useParseFilterConditions'
import { ListContainer } from './list'
import { TreesContainer } from './trees'
import { OkrNode } from './trees/types'
import { AllOkrEmptyState } from './ui/AllOkrEmptyState'
import { FilteredOkrEmptyState } from './ui/FilteredOkrEmptyState'
import { GroupOkrEmptyState } from './ui/GroupOkrEmptyState'
import { MyOkrEmptyState } from './ui/MyOkrEmptyState'
import { NoGroupBelongingState } from './ui/NoGroupBelongingState'

export const OkrMapContainer: VFC<AuthRouteProps> = ({ onOkrTermLoaded }) => {
  useProfiler('ReplOkrMapContainer')
  const navigate = useNavigate()
  const [queryParams, setQueryParams] = useQueryParams({
    group: ArrayParam,
    user: ArrayParam,
    node: ArrayParam,
    view: createEnumParam<MapView>(['tree', 'list', 'activity']),
    [OKR_LIST_TAB_QUERY]: StringParam,
  })
  const viewType: MapView = queryParams.view ?? 'tree'
  const showingNodeIds = useMemo<ReadonlyArray<string>>(
    () => queryParams.node?.filter((n): n is string => n != null) ?? [],
    [queryParams.node],
  )
  const { OkrModal, openOkrModal, openOkrModalWithKeyResultDrawer } = useOkrModal()

  const { checkOpenAlertModal } = useContext(AlertModalContext)
  const termId = useOkrTermLoadable(onOkrTermLoaded, (okrTermId) =>
    navigate(generateTrees({ termId: okrTermId }), {
      replace: true,
      state: { view: viewType ?? 'tree' },
    }),
  )

  const [fetchOkrNodes, okrNodesResp] = useOkrNodesLazyQuery()
  const allNodes = useMemo(() => okrNodesResp.data?.okrNodes ?? [], [okrNodesResp.data?.okrNodes])
  useEffect(() => {
    if (termId) {
      fetchOkrNodes({ variables: { okrTermId: termId } })
    }
  }, [fetchOkrNodes, termId])

  const user = useCurrentUser()
  const isShownDisabledOkr = user?.userSetting.showDisabledOkrs ?? false
  const [updateShowDisabledOkrsMutation] = useUpdateShowDisabledOkrsMutation()
  const setIsShownDisabledOkr = useCallback(
    async (isShow: boolean) => {
      await updateShowDisabledOkrsMutation({
        variables: { isShow },
        optimisticResponse: user
          ? {
              updateShowDisabledOkrs: {
                __typename: 'UserSetting',
                id: user.userSetting.id,
                showDisabledOkrs: isShow,
              },
            }
          : undefined,
      })
    },
    [updateShowDisabledOkrsMutation, user],
  )

  const floatDrawerItems = useGetFloatDrawerItems()
  const okrFloatDrawerListContainerRef = useRef<HTMLDivElement>(null)
  const [addOkrFloatDrawerItems, setAddOkrFloatDrawerItems] = useState<
    ReadonlyArray<OkrFloatDrawerListItem>
  >([])
  useEffect(() => {
    if (floatDrawerItems.length) {
      setAddOkrFloatDrawerItems(floatDrawerItems)
    }
  }, [floatDrawerItems])

  // ここから画面上に表示するOkrNodeをフィルタする処理
  // まず完全なOKRツリーを作成
  const allOkrTree = useMemo<OKRTree<OkrNode>>(() => new OKRTree<OkrNode>(allNodes), [allNodes])

  // 無効なO/KRをフィルタしたノード
  // FIXME: useFilteredNodesでこの処理も行う
  const filteredDisabledOkrNodes = useMemo<ReadonlyArray<Node<OkrNode>>>(
    () =>
      isShownDisabledOkr
        ? allOkrTree.getAllNodes()
        : allOkrTree.filterMap((node) => {
            // 自身 OR 親が無効化されている場合は表示しない
            if (node.objective.isDisabled || node.parent?.get(node)?.objective.isDisabled)
              return null

            // FIXME: keyResultをfilterするためにnode objectを新しく生成しているので、
            // parentとchildrenのweakMapをsetし直さないといけない
            const parentMap = new WeakMap()
            const childrenMap = new WeakMap()
            const parent = node.parent?.get(node)
            const children = node.children.get(node)
            const newNode = { ...node }
            newNode.parent = parent ? parentMap.set(newNode, parent) : undefined
            newNode.children = childrenMap.set(newNode, children)
            newNode.keyResults = node.keyResults.filter((kr) => !kr.isDisabled)

            return newNode
          }),
    [isShownDisabledOkr, allOkrTree],
  )

  const initialDataResp = useInitialDataQuery()
  const groups = useMemo(() => initialDataResp.data?.groups ?? [], [initialDataResp.data?.groups])
  const users = useMemo(() => initialDataResp.data?.users ?? [], [initialDataResp.data?.users])
  const { selectedGroupIds, selectedUserIds, requiredGroupIds, isFiltering } =
    useParseFilterConditions({
      groups,
      queryParamsGroupIds: queryParams.group,
      queryParamsUserIds: queryParams.user,
    })

  const { filteredOkrNodes, filteredOkrNodeIds, filteredOkrTree } = useFilteredNodes({
    filteredDisabledOkrNodes,
    requiredGroupIds,
    selectedUserIds,
    showingNodeIds,
    isShownDisabledOkr,
  })

  const {
    isMyOkrEmpty,
    isNoGroupBelonging,
    isGroupOkrEmpty,
    isAllOkrEmpty,
    isFilteredOkrEmpty,
    hasEmptyState,
  } = useEmptyState({
    selectedUserIds,
    selectedGroupIds,
    filteredOkrTree,
    queryParamsOkrList: queryParams[OKR_LIST_TAB_QUERY] as OkrMapType,
  })

  // ここまで画面上に表示するOkrNodeをフィルタする処理

  const appendFilteringNode = useCallback(
    (nodeId: string, nodeIds: ReadonlyArray<string>) => {
      setQueryParams(
        {
          group: [...selectedGroupIds],
          user: [...selectedUserIds],
          ...(isFiltering ? { node: [...nodeIds, nodeId] } : {}),
        },
        'pushIn',
      )
    },
    [setQueryParams, selectedGroupIds, selectedUserIds, isFiltering],
  )

  const [fetchCreatedOkr] = useOkrNodeByNodeLazyQuery({
    onCompleted: ({ okrNode }) => {
      appendFilteringNode(okrNode.id, showingNodeIds)
      // 作成したOKRを含めたOKRマップをリロードして表示する
      // 本来はキャッシュを更新したい
      navigate(0)
    },
  })
  const onCompletedCreate = useCallback(
    (nodeId: string) => fetchCreatedOkr({ variables: { nodeId } }),
    [fetchCreatedOkr],
  )

  const { setParentOkrNodeId, setParentKeyResultIds, defaultValueForOkrCreation } =
    useHandlerForOkrCreation({
      users,
      groups,
      nodes: filteredDisabledOkrNodes,
      selectedUserIds,
      selectedGroupIds,
    })

  const { filters } = useContext(KrFilterContext)

  /**
   * Objective作成モーダルに渡す
   * 上位OKRNodeIDと上位KeyResultIDをリセット
   */
  const handleResetParentOkrParameter = useCallback(() => {
    setParentOkrNodeId(null)
    setParentKeyResultIds([])
  }, [setParentOkrNodeId, setParentKeyResultIds])

  const [OkrCreateModal, openOkrCreateModal] = useOkrCreateModal({
    initialObjectiveStateOverride: defaultValueForOkrCreation,
    onClose: handleResetParentOkrParameter,
    onCompletedCreate,
  })

  const memoizedShowOkrModalForTree = useCallback(
    () => openOkrCreateModal(Screen.OkrMapTree, 'ClickAddOKRAsChild'),
    [openOkrCreateModal],
  )
  const clickCreatingOkr = useCallback(() => {
    checkOpenAlertModal({
      onClickDiscard: () => {
        // closeDrawer()
        openOkrCreateModal(findScreen(window.location.pathname, window.location.search))
      },
    })
  }, [checkOpenAlertModal, openOkrCreateModal])

  const [needBlock, setNeedBlock] = useState(false)
  const setFloatDrawerItems = useSetFloatDrawerItems()
  useBlocker((tx) => {
    if (matchPath(treesPath, tx.location.pathname) || !needBlock) return
    setFloatDrawerItems([])
    setNeedBlock(false)
    tx.retry()
  }, needBlock)

  const memoizedOnOkrFloatDrawerOpen = useCallback(
    (objectiveId: string) => setAddOkrFloatDrawerItems([{ objectiveId }]),
    [],
  )

  useEffect(() => {
    // FIXME: floatDrawerItemsを削除していない、サイト全体として設計・管理できていない。
    setNeedBlock(floatDrawerItems.length !== 0)
  }, [floatDrawerItems])

  return (
    <PageContent
      breadcrumbs={undefined}
      header={
        <OkrMapHeaderContainer
          viewType={viewType}
          termId={termId}
          groups={groups}
          users={users}
          selectedGroupIds={selectedGroupIds}
          selectedUserIds={selectedUserIds}
          isShownDisabledOkr={isShownDisabledOkr}
          onChangeDisabledOkr={setIsShownDisabledOkr}
          showCreateOkrModal={clickCreatingOkr}
        />
      }
      layout={{ css: { position: 'relative' } }}
    >
      {isMyOkrEmpty && <MyOkrEmptyState onClickCreatingOkr={clickCreatingOkr} />}
      {isNoGroupBelonging && <NoGroupBelongingState />}
      {isGroupOkrEmpty && <GroupOkrEmptyState />}
      {isAllOkrEmpty && <AllOkrEmptyState onClickCreatingOkr={clickCreatingOkr} />}
      {isFilteredOkrEmpty && <FilteredOkrEmptyState />}
      <OkrCardIsFoldGlobalContextProvider>
        <OkrCardIsFoldByIdContextProvider>
          {!hasEmptyState &&
            termId &&
            (() => {
              switch (viewType) {
                case 'tree':
                  return (
                    <TreesContainer
                      okrTermId={termId}
                      okrTree={filteredOkrTree}
                      isFiltering={isFiltering}
                      filteredOkrNodeIds={filteredOkrNodeIds}
                      setParentOkrNodeId={setParentOkrNodeId}
                      setParentKeyResultIds={setParentKeyResultIds}
                      krFilters={filters}
                      showCreateOkrModal={memoizedShowOkrModalForTree}
                      onOkrFloatDrawerOpen={memoizedOnOkrFloatDrawerOpen}
                      openOkrModal={openOkrModal}
                      openOkrModalWithKeyResultDrawer={openOkrModalWithKeyResultDrawer}
                    />
                  )
                case 'list':
                  return (
                    <ListContainer
                      termId={termId}
                      okrNodes={filteredOkrNodes}
                      onOkrFloatDrawerOpen={memoizedOnOkrFloatDrawerOpen}
                      openOkrModal={openOkrModal}
                      openOkrModalWithKeyResultDrawer={openOkrModalWithKeyResultDrawer}
                    />
                  )
                case 'activity':
                  return (
                    <ActivityContainer
                      termId={termId}
                      okrNodeIds={filteredOkrNodeIds}
                      selectedGroupIds={selectedGroupIds}
                      selectedUserIds={selectedUserIds}
                    />
                  )
                default:
                  return null
              }
            })()}
        </OkrCardIsFoldByIdContextProvider>
      </OkrCardIsFoldGlobalContextProvider>

      {OkrCreateModal}

      <div
        ref={okrFloatDrawerListContainerRef}
        css={{ position: 'absolute', width: '100%', height: '100%', pointerEvents: 'none' }}
      >
        <OkrFloatDrawerList
          addItems={addOkrFloatDrawerItems}
          parentRef={okrFloatDrawerListContainerRef}
          baseZIndex={6}
        />
      </div>

      {OkrModal}
    </PageContent>
  )
}
OkrMapContainer.displayName = 'OkrMapContainer'
