import dayjs from 'dayjs'
import { Box, Select, SelectProps, ThemeContext } from 'grommet'
import { forwardRef, useCallback, useMemo, useRef } from 'react'

import { Icon } from '../../../components/ui/Icon'
import { StyledText } from '../../../components/ui/StyledText'
import { useTranslation } from '../../../i18n'
import { color } from '../../../styles/newColors'

type DurationMinutes = 15 | 30 | 60

export type OneOnOneModalTimePartsProps = {
  value?: string
  isDisabled?: boolean
  hasError?: boolean
  durationMinutes?: DurationMinutes
  onChange: (time: string) => void
  onOpen?: () => void
} & Omit<SelectProps, 'options' | 'value' | 'onChange' | 'onOpen'>

export const TIME_FORMAT = 'H:mm'

const START_TIME = dayjs().hour(0).minute(0).second(0)
const END_TIME = START_TIME.add(1, 'days')

const OPTION_ELEMENT_HEIGHT = 44

export const OneOnOneModalTimeParts = forwardRef<HTMLButtonElement, OneOnOneModalTimePartsProps>(
  (
    {
      value,
      isDisabled = false,
      hasError = false,
      durationMinutes = 15,
      onChange,
      onOpen,
      ...rest
    },
    ref,
  ) => {
    const { t } = useTranslation()
    const optionRef = useRef<HTMLDivElement>(null)
    const options = useMemo(() => {
      const ret: Array<string> = []

      for (
        let date = START_TIME;
        date.isBefore(END_TIME);
        date = date.add(durationMinutes, 'minutes')
      ) {
        ret.push(date.format(TIME_FORMAT))
      }
      return ret
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleOnChange = useCallback(
      ({ value: selectedTime }: { value: string }) => {
        onChange(selectedTime)
      },
      [onChange],
    )

    const handleOnOpen = useCallback(() => {
      if (onOpen) onOpen()

      // 選択済みのOptionを表示領域内に表示するための処理
      setTimeout(() => {
        // 選択済みのOptionのindexを取得する
        const selectedItemIndex = options.findIndex((option) => option === value)

        if (!selectedItemIndex) return

        // 選択済みのOptionの上２つ目のDOMが表示領域のTopにくるため、そのDOMを用いてスクロールする
        const scrollTopElementIndex = selectedItemIndex - 2
        // スクロール基準にするDOMのindexが0以下だった場合に、スクロールする必要がない
        if (scrollTopElementIndex <= 0) return

        // スクロール領域のDOMを取得する
        const optionsAreaElem = optionRef.current?.parentElement?.parentElement
        if (!optionsAreaElem) return
        optionsAreaElem.style.scrollBehavior = 'auto'
        optionsAreaElem.scrollTo(0, scrollTopElementIndex * OPTION_ELEMENT_HEIGHT)
        optionsAreaElem.style.scrollBehavior = 'smooth'
      }, 0)
    }, [onOpen, options, value])

    return (
      <Box justify="start">
        <ThemeContext.Extend
          value={{
            global: {
              control: {
                border: {
                  color: hasError ? '#D42922' : '#DADADA',
                  radius: '4px',
                },
              },
              drop: {
                border: {
                  radius: '4px',
                },
              },
              input: {
                font: {
                  color: color('text-bk-100'),
                  weight: 'normal',
                },
                padding: {
                  left: '8px',
                },
              },
            },
            select: {
              container: {
                extend: {
                  padding: 0,
                  maxWidth: '510px',
                  borderRadius: '4px',
                  border: '1px solid #D3D4D9',
                },
              },
              icons: {
                down: (
                  <Icon type="selectDown" color={color('border-bk-20')} css={{ width: '8px' }} />
                ),
                up: (
                  <Icon
                    type="selectDown"
                    color={color('border-bk-20')}
                    css={{ width: '8px', transform: 'rotate(-180deg)' }}
                  />
                ),
              },
              options: {
                text: {
                  weight: 'normal',
                },
              },
            },
          }}
        >
          <Select
            ref={ref}
            size="14px"
            disabled={isDisabled}
            options={options}
            value={value}
            emptySearchMessage={t('NOT_APPLICABLE')}
            dropHeight="224px"
            css={{
              width: '78px',
              height: '38px',
            }}
            onOpen={handleOnOpen}
            onChange={handleOnChange}
            {...rest}
          >
            {(option: string, index: number, _, { selected }: { selected: boolean }) => (
              <div
                /**
                 * NOTE:
                 *  SelectのOption全体をWrapしているDOMを取得するため、Optionsの初期描画で必ずレンダリングされるindex:0番目の要素のみrefを渡す
                 *  SelectのOption全体をWrapしているDOMを取得する目的としては、GrommetがOptionsを開いた際に選択済みのOptionを表示領域に表示しないため、
                 *  開いた際にindex:0番目のDOM経由でOptions全体をWrapしているDOM（スクロール領域のDOM）を取得して、スクロールさせることで選択済みのOptionを表示領域内に表示するため
                 *   ※ handleOnOpen関数参照
                 */
                ref={index === 0 ? optionRef : null}
                css={{
                  padding: '12px 24px',
                  height: `${OPTION_ELEMENT_HEIGHT}px`,
                  backgroundColor: selected ? color('border-bk-10') : undefined,
                }}
              >
                <StyledText color="text-bk-100">{option}</StyledText>
              </div>
            )}
          </Select>
        </ThemeContext.Extend>
      </Box>
    )
  },
)
