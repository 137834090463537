import { css } from '@emotion/react'
import { Action as NavigationType } from 'history'
import { useEffect, useMemo, useState } from 'react'
import {
  matchPath,
  Navigate,
  useLocation,
  useNavigate,
  useNavigationType,
  useParams,
  useSearchParams,
} from 'react-router-dom'

import { GroupTags } from '../../../components/domain/GroupTags'
import { AlertModal } from '../../../components/standalone/AlertModal'
import { AvatarWithName } from '../../../components/ui/AvatarWithName'
import { TabType, TabsWithReactRouter } from '../../../components/ui/TabsWithReactRouter'
import { useCurrentUser } from '../../../contexts/UserContext'
import { useTranslation } from '../../../i18n'
import { useCurrentTermIdQuery } from '../../../lib/domain/okrTerm'
import { useOkrTermLoadable } from '../../../lib/domain/useOkrTermLoadable'
import { Screen } from '../../../lib/screen'
import { tracker } from '../../../lib/tracking'
import { color } from '../../../styles/newColors'
import { AuthRouteProps } from '../../../types/authRouteProps'
import * as urls from '../../../urls'

import { UserFragment, useFindUserByIdLazyQuery } from './graphql'

const HEADER_HEIGHT = 44
const TAB_HEIGHT = 48

const headerCss = css({
  display: 'flex',
  paddingTop: 12,
  gap: 24,
  backgroundColor: 'white',
  width: '100%',
  height: HEADER_HEIGHT,
  position: 'fixed',
  zIndex: 1,
})

const tabWithContentCss = css({
  backgroundColor: color('background-bk-10'),
  minHeight: `calc(100% - ${HEADER_HEIGHT}px)`,
  marginTop: HEADER_HEIGHT,
})
const tabCss = css({
  paddingLeft: 16,
  backgroundColor: 'white',
  position: 'sticky',
  top: HEADER_HEIGHT,
  height: TAB_HEIGHT,
  zIndex: 1,
})
const tabContentCss = css({
  padding: '32px 36px',
  minWidth: '968px',
  backgroundColor: 'inherit',
})

const groupsCss = css({
  position: 'relative',
  top: '8px',
  maxWidth: 600,
  maxHeight: 20,
})

export const BetaHomeRedirectContainer: React.VFC<AuthRouteProps> = () => {
  const { pathname, search } = useLocation()

  return (
    <Navigate
      replace
      to={{
        pathname: pathname.replace('/beta', ''),
        search,
      }}
    />
  )
}

export const MyHomeContainer: React.VFC<AuthRouteProps> = ({ onOkrTermLoaded }) => {
  const navigate = useNavigate()
  const user = useCurrentUser()
  const currentTermId = useCurrentTermIdQuery(onOkrTermLoaded)

  useEffect(() => {
    if (!currentTermId || !user) {
      return
    }

    navigate(urls.generateHome(currentTermId)(user.id), { replace: true })
  }, [currentTermId, navigate, user])

  return null
}

const useCurrentTabInPath = (): Tab | null => {
  const location = useLocation()
  const isOkr = matchPath({ path: urls.homeOkr }, location.pathname)
  if (isOkr) return 'okr'
  const isActivity = matchPath({ path: urls.homeActivity }, location.pathname)
  if (isActivity) return 'activity'
  const isActionPlan = matchPath({ path: urls.homeActionPlan }, location.pathname)
  if (isActionPlan) return 'action-plan'
  const isNote = matchPath({ path: urls.homeNote }, location.pathname)
  if (isNote) return 'note'
  return null
}

export const HomeContainer: React.VFC<AuthRouteProps> = ({ onOkrTermLoaded }) => {
  const currentUser = useCurrentUser()
  const [searchParams] = useSearchParams()
  const { userId: pageUserId = '' } = useParams<{ userId: string }>()
  const initTab = useCurrentTabInPath()
  const navigate = useNavigate()
  const okrTermId = useOkrTermLoadable(onOkrTermLoaded, (termId) => {
    switch (initTab) {
      case 'okr':
        navigate(urls.generateHomeOkr(termId)(pageUserId))
        break
      case 'activity':
        navigate(urls.generateHomeActivity(termId)(pageUserId))
        break
      case 'action-plan':
        navigate(urls.generateHomeActionPlan(termId)(pageUserId))
        break
      case 'note':
        navigate(urls.generateHomeNote(termId)(pageUserId))
        break
      default:
        navigate(urls.generateHomeOkr(termId)(pageUserId), { replace: true })
        break
    }
  })

  const [userQuery, userResp] = useFindUserByIdLazyQuery()
  const user = userResp.data?.findUserById
  useEffect(() => {
    if (okrTermId && initTab != null) {
      userQuery({ variables: { userId: pageUserId } })
    }
  }, [initTab, okrTermId, pageUserId, userQuery])

  if (okrTermId && initTab == null) {
    return (
      <Navigate
        replace
        to={{
          pathname: urls.generateHomeOkr(okrTermId)(pageUserId),
          search: searchParams.toString(),
        }}
      />
    )
  }

  if (!okrTermId || !currentUser || !user) {
    return null
  }

  return (
    <Home
      initTab={initTab ?? 'okr'}
      user={user}
      termId={okrTermId}
      isOwnPage={currentUser.id === pageUserId}
    />
  )
}

HomeContainer.displayName = 'HomeContainer'

type Tab = 'okr' | 'action-plan' | 'note' | 'activity'

const trackingClickTab = (tab: Tab, value: Tab) => {
  switch (tab) {
    case 'okr':
      tracker.UserClickTabInHome(Screen.HomeOkr, value)
      break
    case 'activity':
      tracker.UserClickTabInHome(Screen.HomeActivity, value)
      break
    case 'action-plan':
      tracker.UserClickTabInHome(Screen.HomeActionPlan, value)
      break
    case 'note':
      tracker.UserClickTabInHome(Screen.HomeNote, value)
      break
    default:
      fail()
  }
}

export type Props = {
  initTab: Tab
  termId: string
  user: UserFragment
  isOwnPage: boolean
}

export const Home: React.VFC<Props> = ({ initTab, termId, user, isOwnPage }) => {
  const { t } = useTranslation()

  const navType = useNavigationType()
  const [tab, setTab] = useState<Tab>(initTab)
  useEffect(() => {
    // ブラウザの進む・戻るでタブを復元する
    if (navType !== NavigationType.Replace) {
      setTab(initTab)
    }
  }, [initTab, navType])

  const tabs = useMemo<ReadonlyArray<TabType<Tab>>>(
    () => [
      {
        value: 'okr',
        name: isOwnPage ? t('MY_OKR') : t('X_S_Y', { x: t('USER'), y: t('OKR') }),
        path: urls.generateHomeOkr(termId)(user.id),
      },
      {
        value: 'activity',
        name: t('ACTIVITY'),
        path: urls.generateHomeActivity(termId)(user.id),
      },
      {
        value: 'action-plan',
        name: t('ACTION_PLAN'),
        path: urls.generateHomeActionPlan(termId)(user.id),
      },
      {
        value: 'note',
        name: t('NOTE'),
        path: urls.generateHomeNote(termId)(user.id),
      },
    ],
    [isOwnPage, t, termId, user.id],
  )

  return (
    <main style={{ width: '100%', overflow: 'overlay' }}>
      <header css={headerCss}>
        <AvatarWithName
          firstName={user.firstName}
          lastName={user.lastName}
          avatarUrl={user.avatar?.url}
          isUserDisabled={user.isDisabled}
          fontStyle={{ fontSize: 20, lineHeight: '20px', fontWeight: 'bold' }}
          margin={{ left: '32px' }}
          width={{ max: `${272 + 36}px` }}
        />

        <div css={groupsCss}>
          <GroupTags
            groups={user.groups}
            tagProps={{
              color: 'text-bk-80',
              backgroundColor: 'border-bk-10',
            }}
          />
        </div>
      </header>
      <TabsWithReactRouter<Tab>
        tabs={tabs}
        tab={tab}
        onClickTab={(ct) => {
          setTab(ct.value)
          trackingClickTab(tab, ct.value)
        }}
        css={tabWithContentCss}
        tabCss={tabCss}
        contentCss={tabContentCss}
      />
      <AlertModal />
    </main>
  )
}

Home.displayName = 'Home'
