import React from 'react'

import { rootCss, toggleButtonCss } from './styles'
import type { Value, StatusFilterToggleData } from './types'

export type Props = {
  value: Value
  data: StatusFilterToggleData
  onSelect: (value: Value) => void
}

export const StatusFilterToggle: React.VFC<Props> = ({ value, data, onSelect }) => (
  <ul css={rootCss}>
    {data.map(({ value: v, label }) => (
      <li key={v}>
        <label css={toggleButtonCss}>
          <input
            name="statusFilterGroup"
            type="radio"
            onChange={() => onSelect(v)}
            value={v}
            css={{
              display: 'none',
            }}
            checked={v === value}
          />
          {label}
        </label>
      </li>
    ))}
  </ul>
)
