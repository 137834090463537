import dayjs from 'dayjs'
import * as Yup from 'yup'

import { TFunction } from '../../i18n'

interface AccountSetup {
  organizationName: string
  firstName: string
  lastName: string
  phoneNumber: string
  email: string
  password: string
  termStartDate?: Date
  termEndDate?: Date
}

export const formSchema = (t: TFunction): Yup.SchemaOf<AccountSetup> =>
  Yup.object().shape({
    organizationName: Yup.string()
      .required(t('REQUIRED_ERROR'))
      .min(3, ({ min }) => t('MIN_LENGTH_ERROR', { min }))
      .max(255, ({ max }) => t('TOO_LONG', { max })),
    firstName: Yup.string()
      .required(t('REQUIRED_ERROR'))
      .max(255, ({ max }) => t('TOO_LONG', { max })),
    lastName: Yup.string()
      .required(t('REQUIRED_ERROR'))
      .max(255, ({ max }) => t('TOO_LONG', { max })),
    phoneNumber: Yup.string()
      .required(t('REQUIRED_ERROR'))
      .matches(/^[0-9+()-.x]+$/, t('INVALID_PHONE_NUMBER_FORMAT'))
      .max(20, ({ max }) => t('TOO_LONG', { max })),
    email: Yup.string()
      .required(t('REQUIRED_ERROR'))
      .email(t('INVALID_EMAIL_FORMAT'))
      .max(255, ({ max }) => t('TOO_LONG', { max })),
    password: Yup.string()
      .required(t('REQUIRED_ERROR'))
      .test('test', t('CONSECTIVE_CHARACTERS'), (value: string | undefined) => {
        if (!value) {
          return false
        }
        const s = value.split('').filter((x, i, self) => self.indexOf(x) === i)
        return s.length !== 1
      })
      .min(8, ({ min }) => t('TOO_SHORT', { min })),
    termStartDate: Yup.date().typeError(t('FIELD_SET_ERROR', { field: t('TERM_START_DATE') })),
    termEndDate: Yup.date()
      .typeError(t('FIELD_SET_ERROR', { field: t('TERM_END_DATE') }))
      .test('okr term endDate', t('END_DATE_BEFORE_START_DATE_ERROR'), (value, testContext) => {
        const { termStartDate } = testContext.parent
        if (!value || Number.isNaN(termStartDate.getTime())) {
          return true
        }
        return dayjs(new Date(termStartDate)).isBefore(new Date(value))
      }),
  })
