export const getUserSearchText = ({
  firstName,
  lastName,
  email,
}: {
  firstName: string
  lastName: string
  email: string
}): string => `${firstName} ${lastName} ${email}`

export const bySearchTextOrName = (option: {
  searchText?: string
  name: string
}): Array<string> => [`${option.searchText || option.name}`]

export const bySearchTextOrValue = (option: {
  searchText?: string
  value: string
}): Array<string> => [`${option.searchText || option.value}`]

export const byUserName = (user: { firstName: string; lastName: string }): Array<string> => [
  `${user.firstName}${user.lastName}`,
  `${user.lastName}${user.firstName}`,
]
