import { Box, Button, Calendar, DropButton, ThemeContext } from 'grommet'
import { Next, Previous } from 'grommet-icons'
import { useState, useEffect, forwardRef } from 'react'

import { Icon } from '../../../../components/ui/Icon'
import { StyledText } from '../../../../components/ui/StyledText'
import { useTranslation } from '../../../../i18n'
import { color } from '../../../../styles/newColors'

import { locale } from './constants'
import { calenderCss, makeDateInputCss } from './styles'
import { DateInputProps } from './types'

export const DateInput = forwardRef<HTMLInputElement, DateInputProps>(
  ({ value: initValue, onChange: onUpdate, errors }, ref) => {
    const { t } = useTranslation()

    const [value, setValue] = useState(initValue)
    const [isShowCalendar, setIsShowCalendar] = useState(false)

    // grommetのCalenderコンポーネントの引数onSelectの型にstring[]もあるため、引数の型を合わせた
    const onSelect = (newDate: string | Array<string>) => {
      if (!Array.isArray(newDate)) {
        setValue(newDate)
        if (onUpdate) onUpdate(newDate)
      }
      setIsShowCalendar(false)
    }

    useEffect(() => {
      setValue(initValue)
    }, [initValue])

    return (
      <DropButton
        css={makeDateInputCss(errors)}
        color="#DADADA"
        dropAlign={{
          top: 'bottom',
          left: 'left',
        }}
        dropProps={{
          round: '8px',
        }}
        open={isShowCalendar}
        onClose={() => setIsShowCalendar(false)}
        onOpen={() => setIsShowCalendar(true)}
        dropContent={
          <ThemeContext.Extend
            value={{
              global: {
                size: {
                  small: `${524 / 2}px`,
                },
              },
              calendar: {
                day: {
                  extend: ({ isSelected }: { isSelected: boolean }) => ({
                    backgroundColor: isSelected ? color('resily-orange-100') : undefined,
                    borderRadius: '50%',
                    marginBottom: '8px',
                  }),
                },
                extend: {
                  button: {
                    borderRadius: '50%',
                  },
                },
              },
            }}
          >
            {/* TODO: 余白の大きさの確認 */}
            <Box
              justify="center"
              direction="row"
              pad={{
                vertical: '16px',
                horizontal: '10px',
              }}
              width="220px"
            >
              <Calendar
                animate={false}
                date={value ? new Date(value).toISOString() : ''}
                locale="ja"
                fill
                size="small"
                showAdjacentDays={false}
                css={calenderCss}
                header={({ date: currentDate, onPreviousMonth, onNextMonth }) => (
                  <Box
                    direction="row"
                    align="center"
                    margin={{
                      bottom: '16px',
                    }}
                  >
                    <Button icon={<Previous />} onClick={onPreviousMonth} />
                    <StyledText css={{ marginLeft: 'auto', marginRight: 'auto' }} weight="bold">
                      {currentDate.toLocaleDateString(locale, {
                        month: 'long',
                        year: 'numeric',
                      })}
                    </StyledText>
                    <Button icon={<Next />} onClick={onNextMonth} />
                  </Box>
                )}
                onSelect={onSelect}
              />
            </Box>
          </ThemeContext.Extend>
        }
      >
        <StyledText color={value ? 'text-bk-100' : 'text-bk-50'}>
          {value
            ? new Date(value).toLocaleDateString(locale)
            : t('SELECT_X', { x: t('TERM_DATE') })}
        </StyledText>
        <input value={value ? new Date(value).toLocaleDateString(locale) : ''} ref={ref} hidden />
        {isShowCalendar ? (
          <Icon
            type="selectDown"
            color={color('border-bk-20')}
            css={{ width: '8px', transform: 'rotate(-180deg)' }}
          />
        ) : (
          <Icon type="selectDown" color={color('border-bk-20')} css={{ width: '8px' }} />
        )}
      </DropButton>
    )
  },
)

DateInput.defaultProps = {
  value: undefined,
  onChange: undefined,
}

DateInput.displayName = 'DateInput'
