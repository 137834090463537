import { css, CSSObject } from '@emotion/react'
import { Box, BoxTypes, Text, TextProps } from 'grommet'
import { useEffect, useMemo, useRef, useState } from 'react'

import { useTranslation } from '../../../i18n'
import { fontSize, FontSizeAlias, FontStyleAlias, TextStyle } from '../../../styles/font'
import { color as colorStyle, ColorAlias } from '../../../styles/newColors'
import { generateUser, generateHome } from '../../../urls'
import { Avatar, Props as AvatarProps, sizes as AvatarSizes } from '../Avatar'
import { Link } from '../Link'
import { UserTooltipNew } from '../TooltipNew'

export type Props = BoxTypes &
  Pick<
    AvatarProps,
    'firstName' | 'lastName' | 'avatarUrl' | 'isUserDisabled' | 'size' | 'linkage'
  > & {
    color?: TextProps['color']
    newColor?: ColorAlias
    shouldChangeColor?: boolean
    nameFontSize?: FontSizeAlias
    nameFontStyle?: FontStyleAlias
    avatarStyle?: CSSObject
    // 下記のfontStyleは、AliasにないStyleを使用したい時のみ使うようにする
    fontStyle?: TextStyle
    linkTextMaxWidth?: string
  }

export const AvatarWithName: React.FC<Props> = ({
  firstName,
  lastName,
  avatarUrl,
  linkage,
  size = 'medium',
  color,
  newColor,
  shouldChangeColor = true,
  nameFontSize = 'medium',
  nameFontStyle,
  isUserDisabled,
  avatarStyle = {},
  fontStyle,
  linkTextMaxWidth,
  ...props
}) => {
  const { t } = useTranslation()
  const nameFontCss = fontStyle ?? { ...fontSize(nameFontSize, nameFontStyle) }
  const nameTextRef = useRef<HTMLSpanElement>(null)
  const boxRef = useRef<HTMLDivElement>(null)
  const fullName = t('FULL_NAME', { firstName, lastName })
  const commonCss = useMemo(() => {
    const nameColor = (() => {
      if (shouldChangeColor) {
        return colorStyle('resily-orange-100')
      }
      if (newColor) {
        return colorStyle(newColor)
      }
      return color
    })()

    return css({
      ':hover': {
        color: nameColor,
        '.avatar-name': {
          color: nameColor,
          textDecoration: 'underline',
        },
        '> .avatar-icon': {
          filter: 'opacity(20%)',
        },
      },
    })
  }, [color, newColor, shouldChangeColor])

  const [tooltipDisabled, setTooltipDisabled] = useState(false)

  // domのレンダリング後にテキストが省略されているか判定してツールチップ表示設定を行う
  useEffect(() => {
    if (!nameTextRef.current || !boxRef.current) return
    const boxWidth = boxRef.current.getBoundingClientRect().width
    const { width: avatarWidth } = AvatarSizes[size]
    const gap = 4
    const nameTextWidth = boxWidth - avatarWidth - gap
    setTooltipDisabled(nameTextRef.current.getBoundingClientRect().width <= nameTextWidth)
  }, [firstName, lastName, nameTextRef, boxRef, size])

  const common = (
    <Box
      ref={boxRef}
      direction="row"
      align="center"
      gap="xsmall"
      css={linkage ? commonCss : undefined}
      {...props}
    >
      <Avatar
        className="avatar-icon"
        size={size}
        firstName={firstName}
        lastName={lastName}
        avatarUrl={avatarUrl}
        tooltipDisabled={tooltipDisabled}
        avatarStyle={avatarStyle}
        hoverStyle={{ backgroundColor: 'none' }}
        isUserDisabled={isUserDisabled}
      />
      <UserTooltipNew
        title={fullName}
        disabled={tooltipDisabled}
        css={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
        isUserDisabled={isUserDisabled}
      >
        <Text
          ref={nameTextRef}
          className="avatar-name"
          css={{
            display: 'inline-block',
            ...nameFontCss,
            color: newColor ? colorStyle(newColor) : color,
          }}
          truncate
        >
          {!isUserDisabled ? (
            <span>{fullName}</span>
          ) : (
            <span>
              ({t('DISABLING')})<span css={{ textDecoration: 'line-through' }}>{fullName}</span>
            </span>
          )}
        </Text>
      </UserTooltipNew>
    </Box>
  )

  if (!linkage) return common

  const url = linkage.termId
    ? generateHome(linkage.termId)(linkage.userId)
    : generateUser(linkage.userId)

  return (
    <Link
      href={url}
      method={linkage.method}
      css={{ maxWidth: linkTextMaxWidth, ':hover': { textDecoration: 'none' } }}
    >
      {common}
    </Link>
  )
}

AvatarWithName.displayName = 'AvatarWithName'
