import { ConfidenceLevel } from '@resily/geisha'
import { useCallback, useMemo } from 'react'

import { useTranslation } from '../../../../i18n'
import { old2NewConfidence } from '../../../geisha/confidence'
import { ActivityFragment } from '../graphql'
import { generateConfidenceTextMap } from '../utils/generateConfidenceTextMap'

import { useCalcDiffValue } from './useCalcDiffValue'

export type ReturnTypes = {
  diffValue: ReturnType<typeof useCalcDiffValue>
  confidenceText: ReturnType<typeof generateConfidenceTextMap>
  beforeConfidenceLevel: ConfidenceLevel | null
  afterConfidenceLevel: ConfidenceLevel | null
  getDiffValueProps: (diff: number | undefined) =>
    | {
        noDiffLabel?: string
        diffValue: undefined
      }
    | {
        noDiffLabel: undefined
        diffValue?: number
      }
  getDiffValueByUpdateUnitProps: (diff: number | undefined) =>
    | {
        noDiffLabel?: string
        diffValue: undefined
      }
    | {
        noDiffLabel: undefined
        diffValue?: number
      }
  getDiffUnitProps: (diff: string | undefined) =>
    | {
        noDiffLabel: string
        diffValue: undefined
        diffValueLabel: undefined
      }
    | {
        diffValue: string | undefined
        diffValueLabel: string
        noDiffLabel: undefined
      }
  getDiffConfidenceLevelProps: (
    diff: number | undefined,
    beforeConfidence: ConfidenceLevel | null,
  ) =>
    | {
        noDiffLabel: string
        diffValueLabel: undefined
        diffValue: undefined
      }
    | {
        noDiffLabel: undefined
        diffValueLabel: undefined
        diffValue: undefined
      }
    | {
        noDiffLabel: undefined
        diffValueLabel: string
        diffValue: string
      }
}

export const useActivityUtil = (activity: ActivityFragment): ReturnTypes => {
  const { t } = useTranslation()

  // アクティビティ前後の差分
  const diffValue = useCalcDiffValue(activity.keyResultEvent)

  // 実績値 or 進捗率の差分もしくは変化してないことを返却する関数
  const getDiffValueProps = useCallback(
    (diff: number | undefined) =>
      diff === 0 ? { noDiffLabel: `(${t('NO_CHANGE')})` } : { diffValue: diff },
    [t],
  )

  // 単位
  // 単位の更新があった場合に差分の表示を行わないようにするため、undefinedを返す
  const isUpdateUnit = !!activity.keyResultEvent?.afterUnit
  const showDiffValueByUpdateUnit = useCallback(
    (value: number | undefined) => (isUpdateUnit ? undefined : value),
    [isUpdateUnit],
  )

  // 単位の差分もしくは変化してないことを返却する関数
  const getDiffValueByUpdateUnitProps = useCallback(
    (diff: number | undefined) =>
      diff === 0
        ? { noDiffLabel: `(${t('NO_CHANGE')})` }
        : { diffValue: showDiffValueByUpdateUnit(diff) },
    [showDiffValueByUpdateUnit, t],
  )

  // 単位の差分もしくは変化してないことを返却する関数
  const getDiffUnitProps = useCallback(
    (diff: string | undefined) => {
      if (diff === '') {
        return { noDiffLabel: `(${t('NO_CHANGE')})` }
      }
      return {
        diffValue: diff,
        diffValueLabel: t('PREVIOUS_X', { x: t('TARGET_VALUE_UNIT') }),
      }
    },
    [t],
  )

  // 自信度
  // 自信度の差分もしくは変化してないことを返却する関数
  const confidenceText = generateConfidenceTextMap(t)
  const getDiffConfidenceLevelProps = useCallback(
    (diff: number | undefined, beforeConfidence: ConfidenceLevel | null) => {
      if (diff === 0) {
        return { noDiffLabel: `(${t('NO_CHANGE')})` }
      }
      if (diff === undefined || beforeConfidence === null) {
        return {}
      }
      return {
        diffValueLabel: t('PREVIOUS_X', { x: t('CONFIDENCE') }),
        diffValue: confidenceText[beforeConfidence],
      }
    },
    [confidenceText, t],
  )

  const beforeConfidenceLevel: ConfidenceLevel | null = useMemo(
    () => old2NewConfidence(activity.keyResultEvent?.beforeConfidenceLevel),
    [activity.keyResultEvent?.beforeConfidenceLevel],
  )

  const afterConfidenceLevel: ConfidenceLevel | null = useMemo(
    () => old2NewConfidence(activity.keyResultEvent?.afterConfidenceLevel),
    [activity.keyResultEvent?.afterConfidenceLevel],
  )

  return {
    diffValue,
    confidenceText,
    beforeConfidenceLevel,
    afterConfidenceLevel,
    getDiffValueProps,
    getDiffValueByUpdateUnitProps,
    getDiffUnitProps,
    getDiffConfidenceLevelProps,
  }
}
