import {
  focusEditor,
  insertImage,
  insertEmptyElement,
  isImageUrl,
  PlateEditor,
  ELEMENT_PARAGRAPH,
} from '@udecode/plate'

export type WithImageUploadOptions = {
  imageUploader: (file: File) => Promise<string>
}

/**
 * Allows for pasting images from clipboard.
 */
export const withImageUpload =
  ({ imageUploader }: WithImageUploadOptions) =>
  (editor: PlateEditor): PlateEditor => {
    const { insertData } = editor

    editor.insertData = (data: DataTransfer) => {
      const text = data.getData('text/plain')
      const { files } = data
      if (files.length > 0) {
        Array.from(files).forEach(async (file) => {
          const [mime] = file.type.split('/')
          if (mime === 'image') {
            await imageUploader(file).then((url) => {
              insertImage(editor, url)
              insertEmptyElement(editor, ELEMENT_PARAGRAPH)
              focusEditor(editor)
            })
          }
        })
      } else if (isImageUrl(text)) {
        insertImage(editor, text)
        insertEmptyElement(editor, ELEMENT_PARAGRAPH)
        focusEditor(editor)
      } else {
        insertData(data)
      }
    }

    return editor
  }
