import * as React from 'react'

import { useTranslation } from '../../../i18n'
import { getUserSearchText } from '../../../lib/domain/user/searchText'
import { ExternalUrls } from '../../../urls'
import { Popover } from '../../ui/Popover'
import { NewSearchSelect, SearchSelectProps } from '../../ui/SearchSelect'
import { StyledText } from '../../ui/StyledText'
import { UserOption, Props as UserOptionProps } from '../../ui/UserOption'

import { UserFragment } from './graphql'
import { useStyles } from './index.styles'

export type Props = Omit<SearchSelectProps, 'options'> &
  Pick<UserOptionProps, 'nameFontSize' | 'avatarSize'> & {
    options: ReadonlyArray<UserFragment>
    isLoading?: boolean
    onOpen?: () => void
    isTargetUserDisabled?: boolean
    onClickHelpLink?: () => void
  }

export const UserSelect: React.FC<Props> = ({
  value,
  options,
  isLoading = false,
  onChange,
  onOpen,
  isTargetUserDisabled = false,
  nameFontSize,
  avatarSize,
  onClickHelpLink,
  ...props
}) => {
  const { t } = useTranslation()
  const styles = useStyles()

  return (
    <>
      <div css={styles.userSelectRoot}>
        <NewSearchSelect
          addRightMargin
          value={value}
          options={options.map((o) => ({
            value: o.id,
            label: (
              <UserOption
                nameFontSize={nameFontSize}
                avatarSize={avatarSize}
                firstName={o.firstName}
                lastName={o.lastName}
                avatarUrl={o.avatar?.url}
                isUserDisabled={o.isDisabled}
              />
            ),
            searchText: getUserSearchText(o),
          }))}
          isLoading={isLoading}
          onChange={onChange}
          onOpen={onOpen}
          search={options.length > 3}
          {...props}
        />
        <Popover
          targetContent={<Popover.TargetContent.Help css={{ marginLeft: '8px' }} />}
          dropdownContent={
            <Popover.DropdownContent
              text={t('OWNER_SELECT_HELP')}
              link={{
                text: t('MORE_DETAILS'),
                href: ExternalUrls.ADD_USER,
                onClick: onClickHelpLink,
              }}
            />
          }
        />
      </div>
      {isTargetUserDisabled && (
        <StyledText size="xsmall" color="tag-red">
          {t('ALREADY_DISABLED_USER')}
        </StyledText>
      )}
    </>
  )
}

UserSelect.displayName = 'UserSelect'
