import { css } from '@emotion/react'

export const rootCss = css({
  display: 'block',
  marginTop: '24px',
})

export const editorCss = css({
  marginTop: -4,
  lineHeight: 1,
})
