import { useTranslation } from '../../../../../../i18n'
import { Description, Props as DescriptionProps } from '../../../Common/Description'
import { LoadableProps } from '../../../types'

export type DataProps = Pick<
  DescriptionProps,
  'description' | 'attachmentViews' | 'saveDescription'
> & {
  objectiveId: string
}

export type Props = LoadableProps<DataProps>

export const DescriptionArea: React.VFC<Props> = ({
  loading,
  objectiveId,
  description,
  attachmentViews,
  saveDescription,
}) => {
  const { t } = useTranslation()

  return loading ? (
    <Description.Skeleton title={t('OKR_BACKGROUND')} />
  ) : (
    <Description
      title={t('OKR_BACKGROUND')}
      editorId={`m-o-${objectiveId}`}
      editorPlaceholder={t('OKR_BACKGROUND_PLACEHOLDER')}
      emptyPlaceholder={`${t('OKR_BACKGROUND_EMPTY')}\n\n${t('OKR_BACKGROUND_PLACEHOLDER')}`}
      description={description}
      attachmentViews={attachmentViews}
      saveDescription={saveDescription}
    />
  )
}
