import { DeleteModal } from '../../../../components/ui/DeleteModal'
import { useTranslation } from '../../../../i18n'
import { CancelModal } from '../CancelModal'
import { DisabledModal } from '../DisabledModal'

import { AdminUserModalProps } from './types'

export const AdminUserModal: React.FC<AdminUserModalProps> = ({
  user,
  deleteModalState,
  disableModalState,
  cancelModalState,
  onUpdateActivateStatus,
  onDeleteUser,
  onCancel,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <DeleteModal
        deleteItemName={t('USER')}
        deleteItemNameForBody={t('THIS_ITEM', { item: t('USER') })}
        isOpened={deleteModalState.deleteModalVisibility}
        onClickCancel={() => {
          deleteModalState.setDeleteModalVisibility(false)
        }}
        onClickOutside={() => {
          deleteModalState.setDeleteModalVisibility(false)
        }}
        onClickDelete={async () => {
          deleteModalState.setDeleteModalVisibility(false)
          onDeleteUser(user.id)
        }}
      />
      <CancelModal
        isOpened={cancelModalState.cancelModalVisibility}
        onClickCancel={() => {
          cancelModalState.setCancelModalVisibility(false)
        }}
        onClickOutside={() => {
          cancelModalState.setCancelModalVisibility(false)
        }}
        onClickDelete={async () => {
          cancelModalState.setCancelModalVisibility(false)
          onCancel()
        }}
      />
      <DisabledModal
        isOpened={disableModalState.disabledModalVisibility}
        onClickCancel={() => {
          disableModalState.setDisabledModalVisibility(false)
        }}
        onClickOutside={() => {
          disableModalState.setDisabledModalVisibility(false)
        }}
        onClickDelete={async () => {
          disableModalState.setDisabledModalVisibility(false)
          onUpdateActivateStatus(user.id)
        }}
      />
    </>
  )
}
