import { css } from '@emotion/react'
import { Drop } from 'grommet'
import { VFC, useMemo, useCallback, useRef } from 'react'

import { Icon } from '../../components/ui/Icon'
import { IconButton } from '../../components/ui/IconButton'
import { StyledText } from '../../components/ui/StyledText'
import { useDisclosure } from '../../lib/useDisclosure'
import { border } from '../../styles/border'
import { color } from '../../styles/newColors'

import {
  PaginatedSmartHREmployeeReturn,
  PerPageType,
  PER_PAGES,
} from './hooks/useSmarthrEmployeeHandler'

type Props = {
  pagination: PaginatedSmartHREmployeeReturn
}

export const ListBottom: VFC<Props> = ({ pagination }) => {
  const { open, close, isOpen } = useDisclosure()
  const rowButtonRef = useRef<HTMLButtonElement>(null)

  const bottomInfo = useMemo<{
    minItemsCount: number
    maxItemsCount: number
  }>(() => {
    const itemsCount = pagination.items.length
    if (itemsCount === 0) return { minItemsCount: 0, maxItemsCount: 0 }

    const baseCount = (pagination.page - 1) * pagination.perPage
    return {
      minItemsCount: baseCount + 1,
      maxItemsCount: baseCount + itemsCount,
    }
  }, [pagination])

  const handlePrevious = useCallback(() => {
    pagination.dispatchPagingAction('previous')
  }, [pagination])

  const handleNext = useCallback(() => {
    pagination.dispatchPagingAction('next')
  }, [pagination])

  const handleClickRowNumber = (num: PerPageType) => {
    pagination.setPerPage(num)
    close()
  }

  return (
    <>
      {rowButtonRef.current && isOpen && (
        <Drop
          target={rowButtonRef.current}
          align={{ bottom: 'top' }}
          onClickOutside={close}
          css={styles.rowButtonBox}
        >
          {PER_PAGES.map((num) => (
            <button
              key={num}
              type="button"
              onClick={() => handleClickRowNumber(num)}
              css={styles.rowButton}
            >
              {num}
            </button>
          ))}
        </Drop>
      )}
      <div css={styles.bottomBox}>
        <div>
          <StyledText>1ページあたりの行数: {pagination.perPage}</StyledText>
          <IconButton ref={rowButtonRef} border={false} css={styles.rowNumberButton} onClick={open}>
            <Icon
              type="mdiKeyboardArrowDown"
              color={color('text-bk-30')}
              hoverColor="resily-orange-100"
            />
          </IconButton>
        </div>
        <div>
          <StyledText>
            {bottomInfo.minItemsCount}-{bottomInfo.maxItemsCount} / {pagination.allItemsCount}
          </StyledText>
          <IconButton border={false} css={styles.backButton} onClick={handlePrevious}>
            <Icon type="mdiKeyboardArrowDown" color={color('text-bk-30')} />
          </IconButton>
          <IconButton border={false} css={styles.nextButton} onClick={handleNext}>
            <Icon type="mdiKeyboardArrowDown" color={color('text-bk-30')} />
          </IconButton>
        </div>
      </div>
    </>
  )
}

const styles = {
  rowButtonBox: css({
    width: 72,
    padding: '12px 0',
    border: border('simple-30'),
    borderRadius: 4,
  }),
  rowButton: css({
    width: '100%',
    textAlign: 'left',
    padding: '6px 0 6px 24px',
    color: color('text-bk-100'),
    ':hover': {
      backgroundColor: color('hover-background-bk-5'),
    },
  }),
  bottomBox: css({
    marginTop: 12,
    justifyContent: 'end',
    alignItems: 'center',
    display: 'flex',
  }),
  backButton: css({
    marginLeft: 8,
    width: 24,
    height: 24,
    borderRadius: 4,
    transform: 'rotate(90deg)',
    ':hover': {
      backgroundColor: color('hover-background-bk-5'),
      path: {
        fill: color('resily-orange-100'),
      },
    },
  }),
  nextButton: css({
    width: 24,
    height: 24,
    borderRadius: 4,
    transform: 'rotate(-90deg)',
    ':hover': {
      backgroundColor: color('hover-background-bk-5'),
      path: {
        fill: color('resily-orange-100'),
      },
    },
  }),
  rowNumberButton: css({
    marginLeft: 4,
    marginRight: 24,
    width: 24,
    height: 24,
    borderRadius: 4,
    ':hover': {
      backgroundColor: color('hover-background-bk-5'),
      path: {
        fill: color('resily-orange-100'),
      },
    },
  }),
} as const
