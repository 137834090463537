import { SerializedStyles } from '@emotion/react'
import { Button, ButtonProps } from 'grommet'

import { useTranslation } from '../../../i18n'
import { color } from '../../../styles/newColors'
import { Icon } from '../Icon'
import { TooltipNew } from '../TooltipNew'

export type Props = ButtonProps & {
  onClick?: () => void
  containerCss?: SerializedStyles
}

export const FloatDrawerIconButton: React.FC<Props> = ({ onClick, containerCss, ...props }) => {
  const { t } = useTranslation()

  return (
    <TooltipNew title={t('SHOW_RELATED_OKR_BACKGROUNDS')} css={containerCss}>
      <Button
        onClick={onClick}
        css={{
          ':hover': {
            color: color('resily-orange-100'),
          },
        }}
        {...props}
      >
        <Icon type="callMade" width={10} height={10} />
      </Button>
    </TooltipNew>
  )
}

FloatDrawerIconButton.displayName = 'FloatDrawerIconButton'
