import { Scope, withScope, captureException } from '@sentry/react'
import { Box, Text } from 'grommet'
import React, { Component, ErrorInfo, ReactNode } from 'react'
// eslint-disable-next-line no-restricted-imports
import { withTranslation, WithTranslation } from 'react-i18next'

type Props = WithTranslation

type State = {
  hasError: boolean
}

class ErrorBoundaryComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      hasError: false,
    }
  }

  static getDerivedStateFromError(_: Error): State {
    return { hasError: true }
  }

  override componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    withScope((scope) => {
      scope.setExtras(errorInfo as unknown as Parameters<Scope['setExtras']>[0])
      captureException(error)
    })
  }

  override render(): ReactNode {
    const { children, t } = this.props
    const { hasError } = this.state

    if (hasError) {
      return (
        <Box fill="vertical" justify="center" alignSelf="center">
          <Text weight="bold" size="xlarge">
            {t('ERROR_DETECTED')}
          </Text>
        </Box>
      )
    }

    return children
  }
}

export const ErrorBoundary = withTranslation()(ErrorBoundaryComponent)
ErrorBoundary.displayName = 'ErrorBoundary'
