import { Dispatch, SetStateAction } from 'react'
import { useLocalStorage } from 'react-use'

type Ret = [number, Dispatch<SetStateAction<number | undefined>>]

/**
 * 1 on 1のドロワーの横幅を保持しているlocalStorageのhooks
 */

const key = 'one-on-one-drawer-width'

export const useDrawerWidth = (initialDrawerWidth: number): Ret => {
  const [drawerWidth, setDrawerWidth] = useLocalStorage<number>(key, initialDrawerWidth)

  return [drawerWidth ?? initialDrawerWidth, setDrawerWidth]
}
