import { Button } from '@resily/geisha'
import { ThemeContext } from 'grommet'
import { useCallback, useMemo } from 'react'

import { useTranslation } from '../../../../../../i18n'
import { findScreen } from '../../../../../../lib/screen'
import { tracker } from '../../../../../../lib/tracking'
import { StyledText } from '../../../../../ui/StyledText'
import {
  ThreePointsWithMenu,
  Props as ThreePointsWithMenuProps,
} from '../../../../../ui/ThreePointsWithMenu'

import { useStyles } from './KeyResultAreaHeader.styles'

export type Props = {
  disabledReOrderingChildOkr: boolean
  openKeyResultCreateModal: () => void // TODO: 命名 & 型
  openWeightSettingModal: () => void // TODO: 命名 & 型
  openSortLowerOKRModal: () => void // TODO: 命名 & 型
}

export const KeyResultAreaHeader: React.VFC<Props> = ({
  disabledReOrderingChildOkr,
  openKeyResultCreateModal,
  openWeightSettingModal,
  openSortLowerOKRModal,
}) => {
  const { t } = useTranslation()
  const styles = useStyles()

  const onClickWeightSetting = useCallback(() => {
    tracker.UserClickWeightAllocationByObjectiveModal(
      findScreen(window.location.pathname, window.location.search),
    )
    openWeightSettingModal()
  }, [openWeightSettingModal])

  const onClickSortLowerOKR = useCallback(() => {
    tracker.UserClickSortTheChildOkrByObjectiveModal(
      findScreen(window.location.pathname, window.location.search),
    )
    openSortLowerOKRModal()
  }, [openSortLowerOKRModal])

  const menuItems = useMemo<ThreePointsWithMenuProps['popoverItems']>(
    () => [
      {
        label: t('USE_WEIGHTING'),
        onItemClicked: onClickWeightSetting,
      },
      {
        label: t('SORT_CHILD_OKR'),
        onItemClicked: onClickSortLowerOKR,
        disabled: disabledReOrderingChildOkr,
        tooltipLabel: disabledReOrderingChildOkr ? t('DISABLED_RE_ORDERING_CHILD_OKR') : undefined,
      },
    ],
    [disabledReOrderingChildOkr, onClickSortLowerOKR, onClickWeightSetting, t],
  )

  return (
    <div css={styles.root}>
      <StyledText size="large" fontStyle="bold" lineHeight="24px">
        {t('KEY_RESULT')}
      </StyledText>
      <div css={styles.buttons}>
        <Button type="secondary" size="medium" onClick={openKeyResultCreateModal}>
          <StyledText size="small" fontStyle="regular">
            {t('ADD_X', { x: t('KEY_RESULT') })}
          </StyledText>
        </Button>
        <ThemeContext.Extend value={{ global: { drop: { zIndex: 201 } } }}>
          <ThreePointsWithMenu
            tooltipLabel={t('DETAIL_SETTING')}
            popoverItems={menuItems}
            popoverAlign={{ top: 'bottom', left: 'left' }}
          />
        </ThemeContext.Extend>
      </div>
    </div>
  )
}
KeyResultAreaHeader.displayName = 'KeyResultAreaHeader'
