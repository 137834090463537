import { useTranslation } from '../../../i18n'
import { Modal } from '../../ui/Modal'
import { StyledText } from '../../ui/StyledText'

export type Props = {
  withGeishaModal?: boolean
  onClickDisable: () => void
  onClickCancel: () => void
}

export const OkrDisableAlertModal: React.VFC<Props> = ({
  withGeishaModal,
  onClickDisable,
  onClickCancel,
}) => {
  const { t } = useTranslation()

  return (
    <Modal
      isOpened
      withGeishaModal={withGeishaModal}
      title={t('DISABLING_X', { x: t('OKR') })}
      executeText={t('DISABLING')}
      onClickExecute={onClickDisable}
      onClickOutside={onClickCancel}
      onClickCancel={onClickCancel}
    >
      <StyledText color="text-bk-100" size="medium">
        {t('X_CONFIRMATION_OF_DISABLE', { x: t('OKR') })}
        <br />
        {t('EDITING_INPUT_DISCARD')}
      </StyledText>
    </Modal>
  )
}
OkrDisableAlertModal.displayName = 'OkrDisableAlertModal'
