import { useEffect, useCallback, RefObject } from 'react'

export const OPTION_ROOT_CLASSNAME = 'MultiSelectGroupTree__Option-Root'
export const OPTION_CONTAINER_CLASSNAME = 'MultiSelectGroupTree__Option-Root__Container'
export const OPTION_CONTAINER_FOCUS_CLASSNAME =
  'MultiSelectGroupTree__Option-Root__Container--Focus'
export const OPTION_CLASSNAME = 'MultiSelectGroupTree__Option-Root__Container__Option'
export const OPTION_COLLAPSIBLE_BUTTON_CLASSNAME =
  'MultiSelectGroupTree__Option-Root__Container__Option__Collapsible-Button'

export const useKeyboardCursor = (
  searchInputRef: RefObject<HTMLInputElement>,
  selectContainerRef: RefObject<HTMLDivElement>,
): void => {
  const onKeyUpInSearch = useCallback(
    (e: KeyboardEvent) => {
      const { target } = e
      if (!(target instanceof HTMLElement)) {
        return
      }
      if (
        !selectContainerRef.current ||
        !searchInputRef.current ||
        !searchInputRef.current.contains(target) ||
        e.code !== 'ArrowDown'
      )
        return

      e.stopImmediatePropagation()
      const optionButton = selectContainerRef.current.querySelectorAll<HTMLButtonElement>(
        `.${OPTION_CLASSNAME}`,
      )[0]
      optionButton
        .closest(`.${OPTION_CONTAINER_CLASSNAME}`)
        ?.classList.add(OPTION_CONTAINER_FOCUS_CLASSNAME)
      optionButton.focus()
    },
    [searchInputRef, selectContainerRef],
  )

  const toggleChildGroup = useCallback((el: HTMLElement, openOrClose: 'open' | 'close') => {
    const avoidDatasetValue = openOrClose === 'open' ? 'true' : 'false'
    const container = el.parentElement
    const switcher = container?.querySelector<HTMLDivElement>(
      `.${OPTION_COLLAPSIBLE_BUTTON_CLASSNAME}`,
    )
    const selectDownIcon = switcher?.querySelector<SVGElement>('[data-open]')
    if (!switcher || !selectDownIcon || selectDownIcon.dataset.open === avoidDatasetValue) {
      return
    }
    switcher.click()
  }, [])

  const getNextRoot = useCallback((currentRoot: HTMLLIElement, upOrDown: 'up' | 'down') => {
    const childRoot = currentRoot.querySelector<HTMLLIElement>(`.${OPTION_ROOT_CLASSNAME}`)
    // 子グループ
    if (childRoot && upOrDown === 'down') {
      return childRoot
    }
    // 同階層
    const nextRoot =
      upOrDown === 'down' ? currentRoot.nextElementSibling : currentRoot.previousElementSibling
    if (nextRoot) {
      if (upOrDown === 'down') return nextRoot

      const nextChildRoot = nextRoot.querySelectorAll<HTMLLIElement>(`.${OPTION_ROOT_CLASSNAME}`)
      if (!nextChildRoot.length) return nextRoot

      return nextChildRoot[nextChildRoot.length - 1]
    }
    // 同階層がなければ親グループ
    const parentRoot = currentRoot.parentElement?.closest<HTMLLIElement>(
      `.${OPTION_ROOT_CLASSNAME}`,
    )
    if (upOrDown === 'up') {
      return parentRoot
    }
    let baseRoot: HTMLElement | null | undefined = parentRoot
    while (baseRoot) {
      const nextSiblingRoot = baseRoot.nextElementSibling
      if (nextSiblingRoot) {
        return nextSiblingRoot
      }
      baseRoot = baseRoot.parentElement?.closest<HTMLLIElement>(`.${OPTION_ROOT_CLASSNAME}`)
    }
    return null
  }, [])

  const onKeyDownInOption = useCallback(
    (e: KeyboardEvent) => {
      const { target } = e
      if (!(target instanceof HTMLElement)) {
        return
      }
      if (
        !selectContainerRef.current ||
        !target.classList.contains(OPTION_CLASSNAME) ||
        (e.code !== 'ArrowDown' &&
          e.code !== 'ArrowUp' &&
          e.code !== 'ArrowRight' &&
          e.code !== 'ArrowLeft')
      ) {
        return
      }
      e.stopImmediatePropagation()

      if (e.code === 'ArrowRight' || e.code === 'ArrowLeft') {
        toggleChildGroup(target, e.code === 'ArrowRight' ? 'open' : 'close')
        return
      }

      const el = target
      const liElement = el.closest<HTMLLIElement>(`.${OPTION_ROOT_CLASSNAME}`)
      if (!liElement) {
        return
      }

      const nextRoot = getNextRoot(liElement, e.code === 'ArrowDown' ? 'down' : 'up')
      if (
        !nextRoot &&
        e.code === 'ArrowUp' &&
        el ===
          selectContainerRef.current.querySelectorAll<HTMLDivElement>(`.${OPTION_CLASSNAME}`)[0]
      ) {
        liElement.classList.remove(OPTION_CONTAINER_FOCUS_CLASSNAME)
        searchInputRef?.current?.focus()
        return
      }
      if (!nextRoot || !nextRoot.classList.contains(OPTION_ROOT_CLASSNAME)) {
        return
      }

      const nextButton = nextRoot.querySelector<HTMLButtonElement>(`.${OPTION_CLASSNAME}`)
      const nextButtonContainer = nextRoot.querySelector<HTMLButtonElement>(
        `.${OPTION_CONTAINER_CLASSNAME}`,
      )
      if (!nextButton || !nextButtonContainer) return
      liElement
        .querySelector(`.${OPTION_CONTAINER_CLASSNAME}`)
        ?.classList.remove(OPTION_CONTAINER_FOCUS_CLASSNAME)
      nextButtonContainer.classList.add(OPTION_CONTAINER_FOCUS_CLASSNAME)
      nextButton.focus()
    },
    [searchInputRef, selectContainerRef, toggleChildGroup, getNextRoot],
  )

  useEffect(() => {
    document.addEventListener('keydown', onKeyDownInOption, true)
    return (): void => document.removeEventListener('keydown', onKeyDownInOption, true)
  }, [onKeyDownInOption])

  useEffect(() => {
    document.addEventListener('keyup', onKeyUpInSearch, true)
    return (): void => document.removeEventListener('keyup', onKeyUpInSearch, true)
  }, [onKeyUpInSearch])
}
