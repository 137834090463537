import { useModal } from '@resily/geisha'
import { useCallback, useMemo } from 'react'

import { WeightSettingModal, Props as WeightSettingModalProps } from './WeightSettingModal'
import { KeyResultsWeightInput, useUpdateKeyResultsWeightMutation } from './graphql'

export type Props = Omit<WeightSettingModalProps, 'termId' | 'onConfirm'> & {
  objectiveId: string
  onConfirm: () => void
}

export const WeightSettingModalContainer: React.VFC<
  Props & { modalComponent: ReturnType<typeof useModal>[0] }
> = ({ modalComponent: Modal, objectiveId, keyResults, onConfirm, ...rest }) => {
  const [updateKeyResultsWeight] = useUpdateKeyResultsWeightMutation()
  const onUpdate = useCallback(
    (krs: ReadonlyArray<KeyResultsWeightInput>) => {
      updateKeyResultsWeight({
        variables: {
          objectiveId,
          inputs: krs.map((kr) => ({ id: kr.id, weight: kr.weight })),
        },
      })
      onConfirm()
    },
    [objectiveId, onConfirm, updateKeyResultsWeight],
  )

  return (
    <WeightSettingModal
      modalComponent={Modal}
      keyResults={useMemo(() => keyResults.filter((kr) => !kr.isDisabled), [keyResults])}
      onConfirm={onUpdate}
      {...rest}
    />
  )
}
