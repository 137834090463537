import { useContext, useRef } from 'react'
import { useParams } from 'react-router'

import { OkrTermIdContext } from '../../../../../contexts/OkrTermIdContext'
import { useCurrentUser } from '../../../../../contexts/UserContext'
import { useTranslation } from '../../../../../i18n'
import { Screen } from '../../../../../lib/screen'
import { useTracking } from '../../../../../lib/tracking'
import { AuthRouteProps } from '../../../../../types/authRouteProps'

import { Activities } from './Activities'
import { ActivityRadioButtons } from './ActivityRadioButtons'
import { ActivityTitleSection } from './ActivityTitleSection'
import { ActivityResourceType } from './graphql'
import { useChangeRadio } from './hooks/useChangeRadio'

export const ActivityContainer: React.FC<AuthRouteProps> = () => {
  const { t } = useTranslation()
  useTracking(t('ACTIVITY'), Screen.HomeNote)
  const { userId: pageUserId } = useParams<{ userId: string }>()
  const { okrTermId: pageTermId } = useContext(OkrTermIdContext)
  const currentUser = useCurrentUser()
  const activityType = useRef<ActivityResourceType>(ActivityResourceType.MyOkr)

  const { currentActivityType, onChangeRadio } = useChangeRadio({ activityType })

  if (!pageUserId || !pageTermId || !currentUser) return null

  const isOwnPage = currentUser.id === pageUserId
  return (
    <>
      <ActivityTitleSection isOwnPage={isOwnPage} />
      <ActivityRadioButtons
        currentActivityType={currentActivityType}
        isOwnPage={isOwnPage}
        onChangeRadio={onChangeRadio}
      />
      <Activities userId={pageUserId} okrTermId={pageTermId} resourceType={currentActivityType} />
    </>
  )
}

ActivityContainer.displayName = 'ActivityContainer'
