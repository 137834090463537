import { ActivityCard as GeishaActivityCard } from '@resily/geisha'
import { useMemo } from 'react'
import { v1 as uuid } from 'uuid'

import { useTranslation } from '../../../../../../../../i18n'
import { componentNames, featureNames, generateTestId } from '../../../../../../../../lib/testId'
import {
  DEFAULT_INITIAL_VALUE_JSON_STRING,
  RichTextEditor,
} from '../../../../../../../ui/RichTextEditor'
import { StyledText } from '../../../../../../../ui/StyledText'

import { useTransformActivityKeyResultComments } from './hooks/useTransformActivityKeyResultComments'
import { useTransformActivityKeyResultEvents } from './hooks/useTransformActivityKeyResultEvents'
import { editorCss, rootCss } from './styles'
import { Props } from './types'

export const Activity: React.VFC<Props> = ({
  keyResultEvents,
  keyResultComments,
  lastKeyResultComments,
}) => {
  const id = useMemo(() => uuid(), [])

  const { t } = useTranslation()

  const { isChanged: isChangedKeyResultEvents, ...activityKeyResultEvents } =
    useTransformActivityKeyResultEvents({
      keyResultEvents,
    })

  const { isChanged: isChangedKeyResultComments, items: activityKeyResultComments } =
    useTransformActivityKeyResultComments({
      keyResultComments,
      lastKeyResultComments,
    })

  /** チェックインで該当のKRに対して更新していない場合 */
  if (!(isChangedKeyResultEvents || isChangedKeyResultComments)) {
    return (
      <StyledText css={rootCss} size="medium" fontStyle="regular">
        {t('NO_UPDATED')}
      </StyledText>
    )
  }

  return (
    <div css={rootCss}>
      {isChangedKeyResultEvents && (
        <GeishaActivityCard.DataGroup>
          <GeishaActivityCard.DataItemGroup>
            {Object.entries(activityKeyResultEvents.itemGroup1).map(([key, event]) => (
              <GeishaActivityCard.DataItem key={key} {...event} />
            ))}
          </GeishaActivityCard.DataItemGroup>
          <GeishaActivityCard.DataItemGroup>
            {Object.entries(activityKeyResultEvents.itemGroup2).map(([key, event]) => (
              <GeishaActivityCard.DataItem key={key} {...event} />
            ))}
          </GeishaActivityCard.DataItemGroup>
        </GeishaActivityCard.DataGroup>
      )}
      {isChangedKeyResultComments && (
        <GeishaActivityCard.Grid>
          {activityKeyResultComments.map(({ keyName, label, initialValueJSON }) => (
            <GeishaActivityCard.Col key={keyName} label={label}>
              {!initialValueJSON || initialValueJSON === DEFAULT_INITIAL_VALUE_JSON_STRING ? (
                <StyledText
                  data-testid={generateTestId(featureNames.checkinModal, componentNames.editor, {
                    suffix: keyName,
                  })}
                  color="text-bk-50"
                >
                  {t('DELETED')}
                </StyledText>
              ) : (
                <RichTextEditor
                  id={`${id}-${keyName}`}
                  data-testid={generateTestId(featureNames.checkinModal, componentNames.editor, {
                    suffix: keyName,
                  })}
                  initialValueJSON={initialValueJSON}
                  editorProps={{ readOnly: true }}
                  css={editorCss}
                />
              )}
            </GeishaActivityCard.Col>
          ))}
        </GeishaActivityCard.Grid>
      )}
    </div>
  )
}

Activity.displayName = 'ConfirmationActivity'
