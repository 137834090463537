import { useCallback } from 'react'

import { OrganizationFragment } from '../../../contexts/graphql'
import { useUpdateOneOnOneEnabledMutation } from '../api/graphql'
import { Props as OneOnOneEnabledContentProps } from '../components/OneOnOneEnabledContent'

export const useOneOnOneEnabledContent = ({
  organization,
}: {
  organization: OrganizationFragment
}): OneOnOneEnabledContentProps => {
  const [updateOneOnOneEnabled, { loading }] = useUpdateOneOnOneEnabledMutation()

  const handleClickSwitch = useCallback<OneOnOneEnabledContentProps['handleClickSwitch']>(
    (enabled: boolean) =>
      updateOneOnOneEnabled({
        variables: { enabled },
      }),
    [updateOneOnOneEnabled],
  )

  return {
    loading,
    enabled: organization.oneOnOneEnabled,
    handleClickSwitch,
  }
}
