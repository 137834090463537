import { ELEMENT_PARAGRAPH, ELEMENT_H4 } from '@udecode/plate'

import { stringify } from './PlateEditor/plugins/serializer/text'
import {
  ParagraphElement,
  HeadingElements,
  PlateCustomElement,
  PlateCustomText,
  LinkElement,
} from './PlateEditor/types'

const DOT_WORD = '・'

/**
 * 改行・空白を除去
 * @param {string} text
 * @returns {string}
 */
const removeBlankAndNewLine = (text: string): string => text.replace(/\s+/g, '')

/**
 * json形式のstring文字列から、textデータのみを抽出する
 * @param {string} targetTreeJson
 * @returns {string}
 */
export const changeTreeJsonToPlainText = (targetTreeJson: string): string =>
  JSON.parse(targetTreeJson).map(stringify).join('\n') // treeJsonをJson形式に変換し、そこからtextデータのみを抽出する

/**
 * デフォルトテンプレート文言との比較処理
 * 「空白・改行」を除く文字列の完全一致で比較する
 * @param {string} targetTreeJson
 * @param {string} defaultTemplate
 * @returns {boolean}
 */
export const isChangeDefaultTemplate = (targetTreeJson: string, defaultTemplate: string): boolean =>
  removeBlankAndNewLine(changeTreeJsonToPlainText(targetTreeJson)) !==
  removeBlankAndNewLine(defaultTemplate)

/**
 * type: "p"のリッチテキストjsonを生成
 * @param {Array<string>} textList
 * @returns
 */
export const createParagraphElements = (textList: Array<string>): Array<ParagraphElement> =>
  textList.map((text) => {
    const json: ParagraphElement = {
      type: ELEMENT_PARAGRAPH,
      children: [{ text }],
    }
    return json
  })

/**
 * type: "h4"のリッチテキストjsonを生成 (Objective作成用)
 * @param {string} textList
 * @returns
 */
export const createObjectiveHeadingFourElements = (
  textList: Array<string>,
): Array<HeadingElements> =>
  textList.map((text) => {
    const json: HeadingElements = {
      type: ELEMENT_H4,
      children: text === DOT_WORD ? [{ text }] : [{ text, bold: true, code: true }],
    }
    return json
  })

/**
 * Objective作成モーダルのテンプレート文言を作成する関数(「目標と背景」の値)
 * @param {string} textList
 * @returns
 */
export const createDefaultObjectiveDescriptionTreeJson = (textList: Array<string>): string =>
  JSON.stringify(createObjectiveHeadingFourElements(textList))

/**
 * KR作成モーダルのテンプレート文言を作成する関数(「マイルストーンと計測方法」の値)
 * @param {Array<string>} textList
 * @returns
 */
export const createDefaultKeyResultDescriptionTreeJson = (textList: Array<string>): string =>
  JSON.stringify(createParagraphElements(textList))

/**
 * リッチテキストエディタで利用するコンテンツのビルダークラス
 */
export class ContentBuilder {
  /**
   * nodesはContentBuilderで生成したコンテンツを格納
   */
  private nodes: Array<PlateCustomElement> = []

  /**
   * 段落を生成します
   * 引数がArrayとなっているのは、同一段落内で異なる装飾等をサポートするためです
   * @param contents 段落に設定したい文字列(装飾やリンク含む)。urlを指定した場合はリンク文字列となります。
   * @returns ContentBuilder
   *
   * @example 段落を生成し文字列を設定する場合:
   * ```
   * new ContentBuilder()
   *       .paragraph([{ text: '1行目の段落: 強調表示', bold: true }])
   *       .paragraph([
   *        { text: '2行目の段落: 途中の装飾無し文字列' },
   *        { text: '2行目の段落: 強調表示', bold: true }])
   *        .build()
   * // output
   * // <p><b>1行目の段落: 強調表示</b></p>
   * // <p>2行目の段落: 途中の装飾無し文字列<b>2行目の段落: 強調表示</b></p>
   * ```
   *
   * @example リンク付き文字列を生成する場合:
   * ```
   * new ContentBuilder()
   *       .paragraph([{ text: '1行目の段落: 強調表示', bold: true }])
   *       .paragraph([{ text: '2行目の段落: 途中の装飾無し文字列' }])
   *       .paragraph([
   *        {
   *          text: 'Resily HP',
   *          url: 'https://resily.com',
   *        }])
   *        .build()
   * // output
   * // <p><b>1行目の段落: 強調表示</b></p>
   * // <p>1行目の段落: 途中の装飾無し文字列</p>
   * // <p><a href='https://resily.com'>Resily HP</a></p>
   * ```
   */
  public paragraph(
    contents: Array<
      PlateCustomText & {
        url?: string
      }
    >,
  ): ContentBuilder {
    const paragraphNode: ParagraphElement | LinkElement = { type: 'p', children: [] }

    contents.forEach((e) => {
      const { text, url, ...marks } = e

      // URLが指定されている場合はLINKエレメントにする
      if (url != null) {
        paragraphNode.children.push({
          type: 'a',
          url,
          children: [{ text, ...marks }],
        } as unknown as LinkElement)
        return
      }

      paragraphNode.children.push({ text, ...marks })
    })

    this.nodes.push(paragraphNode)
    return this
  }

  /**
   * ビルダーで生成したコンテンツをリッチテキストエディタのNodeとして生成し返却します
   * @returns ビルダーで生成したリッチテキストエディタのコンテンツ
   */
  public build(): Array<PlateCustomElement> {
    return this.nodes
  }
}
