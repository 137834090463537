import { BoxProps } from 'grommet'
import React from 'react'

import { useTranslation } from '../../../i18n'
import { ExternalUrls } from '../../../urls'

import { OkrEmptyState } from './OkrEmptyState'

export const GroupOkrEmptyState: React.VFC<BoxProps> = ({ ...props }) => {
  const { t } = useTranslation()
  return (
    <OkrEmptyState
      title={t('GROUP_OKR_EMPTY_TITLE')}
      description={t('GROUP_OKR_EMPTY_DESCRIPTION')}
      link={{
        text: t('GROUP_OKR_EMPTY_LINK_TEXT'),
        url: ExternalUrls.HOW_TO_SET_GROUP_TAGS_IN_OKR,
      }}
      {...props}
    />
  )
}

GroupOkrEmptyState.displayName = 'GroupOkrEmptyState'
