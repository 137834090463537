import { Box } from 'grommet'
import React, { useEffect, useState } from 'react'

export type Props = {
  title: string
  agendaId: string
  editAgendaId: string
  onUpdateUserAgenda: (id: string, title: string) => void
}

export const UserAgendaTitle: React.FC<Props> = ({
  title,
  agendaId,
  editAgendaId,
  onUpdateUserAgenda,
}) => {
  const [agendaTitle, setAgendaTitle] = useState(title)
  useEffect(() => {
    setAgendaTitle(title)
  }, [title])

  return (
    <Box
      css={{
        lineHeight: '20px',
        fontWeight: 600,
        fontSize: '14px',
        width: '100%',
        margin: 'auto 0',
      }}
    >
      {editAgendaId === agendaId ? (
        <textarea
          defaultValue={agendaTitle}
          rows={1}
          onChange={(event) => setAgendaTitle(event.target.value)}
          onBlur={() => {
            if (!agendaTitle) return
            setAgendaTitle(agendaTitle)
            onUpdateUserAgenda(agendaId, agendaTitle)
          }}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              if (!agendaTitle) return
              setAgendaTitle(agendaTitle)
              onUpdateUserAgenda(agendaId, agendaTitle)
            }
          }}
          onKeyDown={(e) => {
            e.currentTarget.style.height = `${e.currentTarget.scrollHeight}px`
          }}
          onFocus={(e) => {
            const t = e.currentTarget
            t.setSelectionRange(t.value.length, t.value.length)
          }}
          css={{
            width: '100%',
            maxWidth: '100%',
            outline: 'none',
            resize: 'none',
            fontSize: '14px',
          }}
          ref={(e) => {
            if (!e) return
            e.focus()
          }}
          required
        />
      ) : (
        <a
          href={`#${agendaId}`}
          style={{ textDecoration: 'none', color: 'black', padding: '10px 0' }}
        >
          {agendaTitle}
        </a>
      )}
    </Box>
  )
}

UserAgendaTitle.displayName = 'UserAgendaTitle'
