import { useEffect, useState } from 'react'
import isEqual from 'react-fast-compare'
import { useLocation } from 'react-router-dom'

import { NodeFragment } from './graphql'

export const useFilter = (
  okrNodes: ReadonlyArray<NodeFragment>,
): [
  ReadonlyArray<NodeFragment>,
  ReadonlyArray<string>,
  ReadonlyArray<string>,
  ReadonlyArray<string>,
  ReadonlyArray<string>,
] => {
  const selectedGroupIds = useGetQueryParams('group')
  const selectedUserIds = useGetQueryParams('user')
  const showingNodeIds = useGetQueryParams('node')
  const [focusedIds] = useState<ReadonlyArray<string>>([])
  const [nodes] = useState<ReadonlyArray<NodeFragment>>(okrNodes)

  return [nodes, focusedIds, selectedGroupIds, selectedUserIds, showingNodeIds]
}

export const useGetUserParams = (): ReadonlyArray<string> => useGetQueryParams('user')

/**
 * クエリパラメータから指定したkeyの値を取得する
 * @param target 取得対象のkey
 * @returns 指定したkeyのクエリパラメータの配列
 */
const useGetQueryParams = (target: string): ReadonlyArray<string> => {
  const location = useLocation()
  const [selectedTargetIds, setSelectedTargetIds] = useState<ReadonlyArray<string>>([])

  useEffect(() => {
    const params = new window.URLSearchParams(location.search.replace(/^\?/, ''))
    setSelectedTargetIds((prev) => {
      const next = params.getAll(target)
      if (isEqual(prev, next)) {
        return prev
      }
      return next
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  return selectedTargetIds
}
