import { useTranslation } from '../../../i18n'
import { border, textMain } from '../../../styles/colors'
import { fontSize } from '../../../styles/font'
import { color } from '../../../styles/newColors'
import { Icon } from '../Icon'
import { TooltipNew } from '../TooltipNew'

type Props = {
  filename: string
  url: string
  readOnly: boolean
  fileNameMaxWidth?: number
  onClickDelete: () => void
}

const MediaFile: React.FC<Props> = ({
  filename,
  url,
  readOnly,
  fileNameMaxWidth = 438,
  onClickDelete,
}) => {
  const { t } = useTranslation()
  const size = fontSize('small').fontSize

  return (
    <div
      css={{
        position: 'relative',
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        border: `solid 1px ${border}`,
        borderRadius: '20px',
        paddingTop: '7px',
        paddingBottom: '7px',
        paddingRight: readOnly ? '16px' : '30px',
        paddingLeft: '24px',
        backgroundColor: color('white-100'),
        ':hover:not(:has(button.media-close:hover))': {
          backgroundColor: color('hover-background-bk-5'),
        },
      }}
    >
      <Icon
        type="attachFile"
        color={color('text-bk-50')}
        width={14}
        height={14}
        css={{ position: 'absolute', left: 8 }}
      />
      <a
        href={url}
        css={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          textDecoration: 'none',
          color: textMain,
          fontSize: `${size}px`,
        }}
        rel="noopener noreferrer"
        target="_blank"
      >
        <span
          css={{
            textOverflow: 'ellipsis',
            maxWidth: fileNameMaxWidth,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            lineHeight: '20px',
          }}
        >
          {filename}
        </span>
      </a>
      {!readOnly && (
        <TooltipNew
          title={t('DELETE_X', { x: t('ATTACHMENTS') })}
          css={{ position: 'absolute', right: 8 }}
        >
          <button type="button" className="media-close" onClick={onClickDelete}>
            <Icon type="clear" color={color('text-bk-50')} width={14} height={14} />
          </button>
        </TooltipNew>
      )}
    </div>
  )
}

MediaFile.displayName = 'MediaFile'

export { MediaFile }
