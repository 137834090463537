import { Box, BoxProps, DropProps } from 'grommet'
import { Tip } from 'grommet/components/Tip'
import { Fragment, PropsWithoutRef, ReactNode } from 'react'

import { useTranslation } from '../../../i18n'
import { color } from '../../../styles/newColors'
import { StyledText } from '../StyledText'

export type BaseProps = PropsWithoutRef<JSX.IntrinsicElements['div']> & {
  margin?: Exclude<BoxProps['margin'], string>
  dropProps?: DropProps
  disabled?: boolean
}

export type TooltipNewProps = BaseProps & {
  title?: string
  boxChildren?: ReactNode
  background?: string
}

export type UserTooltipNewProps = TooltipNewProps & {
  isUserDisabled: boolean
}

type TipBoxProps = BaseProps & {
  boxChildren: ReactNode
  background?: string
  fontColor?: string
}

export const TooltipNew: React.FC<TooltipNewProps> = ({
  title,
  margin = {},
  disabled = false,
  children,
  dropProps,
  boxChildren,
  ...props
}) => (
  <TipBox
    disabled={disabled}
    dropProps={dropProps}
    margin={margin}
    boxChildren={
      boxChildren !== undefined ? (
        <Fragment>{boxChildren}</Fragment>
      ) : (
        <StyledText size="small" lineHeight="16px">
          {title}
        </StyledText>
      )
    }
    {...props}
  >
    {children}
  </TipBox>
)

TooltipNew.displayName = 'TooltipNew'

export const UserTooltipNew: React.FC<UserTooltipNewProps> = ({
  title,
  margin = {},
  disabled = false,
  children,
  dropProps,
  isUserDisabled,
  ...props
}) => {
  const { t } = useTranslation()

  return (
    <TipBox
      disabled={disabled}
      dropProps={dropProps}
      margin={margin}
      boxChildren={
        !isUserDisabled ? (
          <StyledText size="small" lineHeight="16px">
            {title}
          </StyledText>
        ) : (
          <StyledText size="small" lineHeight="16px">
            ({t('DISABLING')})<span css={{ textDecoration: 'line-through' }}>{title}</span>
          </StyledText>
        )
      }
      {...props}
    >
      {children}
    </TipBox>
  )
}

UserTooltipNew.displayName = 'UserTooltipNew'

export const TipBox: React.FC<TipBoxProps> = ({
  disabled = false,
  children,
  dropProps,
  margin,
  boxChildren,
  background = color('text-bk-80'),
  fontColor = color('white-100'),
  ...props
}) =>
  disabled ? (
    <div {...props}>{children}</div>
  ) : (
    <Tip
      plain
      dropProps={dropProps}
      content={
        <Box margin={{ top: '4px', ...margin }} direction="row">
          <div
            css={{
              backgroundColor: background,
              color: fontColor,
              width: 'auto',
              maxWidth: 200,
              padding: '4px 8px',
              borderRadius: 4,
            }}
          >
            {boxChildren}
          </div>
        </Box>
      }
    >
      <div {...props}>{children}</div>
    </Tip>
  )

TipBox.displayName = 'TipBox'
