import { useModal } from '@resily/geisha'
import { useCallback } from 'react'

import { useTranslation } from '../../../../i18n'
import { findScreen } from '../../../../lib/screen'
import { tracker } from '../../../../lib/tracking'

import { ChildOkrReOrderingContent } from './ChildOkrReOrderingContent'
import {
  ChildOkrReOrderingRowsContainer,
  ChildOkrReOrderingRowsContainerProps,
} from './ChildOkrReOrderingRows'

export type ChildOkrReOrderingModalProps = Pick<
  ChildOkrReOrderingRowsContainerProps,
  'childrenOkrNodes' | 'setReOrderingChildrenOkrNodes'
> & {
  isOpened: boolean
  onClose: () => void
}

export const ChildOkrReOrderingModal: React.VFC<
  ChildOkrReOrderingModalProps & {
    modalComponent: ReturnType<typeof useModal>[0]
  }
> = ({
  modalComponent: Modal,
  childrenOkrNodes,
  setReOrderingChildrenOkrNodes,
  isOpened,
  onClose,
}) => {
  const { t } = useTranslation()

  return (
    <Modal
      size="medium"
      isOpen={isOpened}
      onClose={useCallback(() => {
        tracker.UserClickCloseSortTheChildOkrByObjectiveModal(
          findScreen(window.location.pathname, window.location.search),
        )
        onClose()
      }, [onClose])}
    >
      <Modal.Header title={t('SORT_CHILD_OKR')} />
      <Modal.Content>
        <ChildOkrReOrderingContent>
          <ChildOkrReOrderingRowsContainer
            childrenOkrNodes={childrenOkrNodes}
            setReOrderingChildrenOkrNodes={setReOrderingChildrenOkrNodes}
          />
        </ChildOkrReOrderingContent>
      </Modal.Content>
    </Modal>
  )
}
