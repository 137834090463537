/**
 * ローカル環境かどうか判定します
 * @returns ローカル環境であればtrue
 */
export const isDevelopment = (): boolean => process.env.REACT_APP_ENV === 'development'

/**
 * Preview環境（Pull Request環境）かどうか判定します
 * @returns Preview環境（Pull Request環境）であればtrue
 */
const isPreview = (): boolean =>
  process.env.REACT_APP_ENV === 'development' && window.location.hostname !== 'localhost'

/**
 * テスト実行かどうか判定します
 * @returns テスト環境であればtrue
 */
export const isTest = (): boolean => process.env.NODE_ENV === 'test'

/**
 * 内部のテスト環境かどうか判定します
 * @returns Dogfooding環境を除いたホスティング環境の場合はtrue
 */
export const isInternalEnvironment = (): boolean =>
  process.env.REACT_APP_ENV === 'staging' ||
  process.env.REACT_APP_ENV === 'qa' ||
  process.env.REACT_APP_ENV === 'qaBeyond'

/**
 * 本番環境かどうか判定します
 * Dogfooding環境は本番環境とも見なせますが、一部デバッグツールを有効にしたいので含めません
 * @returns 本番環境であればtrue
 */
export const isProduction = (): boolean => process.env.REACT_APP_ENV === 'production'

// ドメインの設定
export type DomainSetting = {
  appDomain: string
  cdnDomain?: string
}

const domainSettings: { [env in NodeJS.ProcessEnv['REACT_APP_ENV']]: DomainSetting } = {
  development: {
    appDomain: 'localhost',
    // NOTE： 開発環境では、staging環境のCDNを使用する
    cdnDomain: 'cdn.risily.com',
  },
  staging: {
    appDomain: 'risily.com',
    cdnDomain: 'cdn.risily.com',
  },
  qa: {
    appDomain: 'risily.com',
    cdnDomain: 'qa-cdn.risily.com',
  },
  qaBeyond: {
    appDomain: 'risily.com',
    cdnDomain: 'qa-beyond-cdn.risily.com',
  },
  dogfooding: {
    appDomain: 'risily.com',
    cdnDomain: 'df-cdn.risily.com',
  },
  production: {
    appDomain: 'resily.com',
    cdnDomain: 'cdn.resily.com',
  },
}

export const configureDomain = (): DomainSetting => domainSettings[process.env.REACT_APP_ENV]

// APIのエンドポイント
export type ApiSetting = {
  pathname: string
}

const apiSettings: { [env in NodeJS.ProcessEnv['REACT_APP_ENV']]: ApiSetting } = {
  development: {
    pathname: '/api',
  },
  staging: {
    pathname: '/api',
  },
  qa: {
    pathname: '/api',
  },
  qaBeyond: {
    pathname: '/api',
  },
  dogfooding: {
    pathname: '/api',
  },
  production: {
    pathname: '/api',
  },
}

export type ApiEndpoint = {
  base: string
}

export const configureEndpoint = ({
  ...props
}: {
  protocol: string
  host: string
}): ApiEndpoint => {
  const setting = apiSettings[process.env.REACT_APP_ENV]
  const { protocol, host, pathname } = { ...props, ...setting }

  const base = `${protocol}//${host}${pathname}`
  return { base }
}

// トラッキングコードの設定
export type Tracking = {
  googleTagManagerCode: string
  googleAnalyticsTrackingCode: string
  mixpanelTrackingCode: string
}

const trackingSettings: { [env in NodeJS.ProcessEnv['REACT_APP_ENV']]: Tracking } = {
  development: {
    googleTagManagerCode: 'GTM-WPMS3S9',
    googleAnalyticsTrackingCode: 'UA-111410984-9',
    mixpanelTrackingCode: '1434f6d6d5272917e485b150dd448813',
  },
  staging: {
    googleTagManagerCode: 'GTM-WPMS3S9',
    googleAnalyticsTrackingCode: 'UA-111410984-9',
    mixpanelTrackingCode: 'c8de307c03292a5cb432a4de66d23ea4',
  },
  qa: {
    googleTagManagerCode: 'GTM-WPMS3S9',
    googleAnalyticsTrackingCode: 'UA-111410984-9',
    mixpanelTrackingCode: 'c8de307c03292a5cb432a4de66d23ea4',
  },
  qaBeyond: {
    googleTagManagerCode: 'GTM-WPMS3S9',
    googleAnalyticsTrackingCode: 'UA-111410984-9',
    mixpanelTrackingCode: 'c8de307c03292a5cb432a4de66d23ea4',
  },
  dogfooding: {
    googleTagManagerCode: 'GTM-WPMS3S9',
    googleAnalyticsTrackingCode: 'UA-111410984-9',
    mixpanelTrackingCode: 'c8de307c03292a5cb432a4de66d23ea4',
  },
  production: {
    googleTagManagerCode: 'GTM-WPMS3S9',
    googleAnalyticsTrackingCode: 'UA-111410984-8',
    mixpanelTrackingCode: '45bea21e3ddaf7e63c8ca0e835775316',
  },
}

export const configureTracking = (): Tracking => trackingSettings[process.env.REACT_APP_ENV]

export const openReplayDomain = 'app.openreplay.com'

// OpenReplayの設定
export type OpenReplay = {
  projectId: string
  projectKey: string
}

// Preview環境（Pull Request環境）用のOpenReplayの設定
const previewOpenReplaySetting: OpenReplay = {
  projectId: '4608',
  projectKey: 'Zkl6VeYHavNOWVc4KiWx',
}

const openReplaySettings: { [env in NodeJS.ProcessEnv['REACT_APP_ENV']]: OpenReplay } = {
  development: {
    projectId: '',
    projectKey: '',
  },
  staging: {
    projectId: '',
    projectKey: '',
  },
  qaBeyond: {
    projectId: '4608',
    projectKey: 'Fha1ZyvJ2YFSTTyX7ch0',
  },
  qa: {
    projectId: '4608',
    projectKey: 'SznP9wxczptqCQ3C0Ac1',
  },
  dogfooding: {
    projectId: '4606',
    projectKey: 'qpLXHVrq6Ns5BDzUzY75',
  },
  production: {
    projectId: '4604',
    projectKey: 'CkGePBWqiw8CG5NjlJGu',
  },
}

export const configureOpenReplay = (): OpenReplay =>
  isPreview() ? previewOpenReplaySetting : openReplaySettings[process.env.REACT_APP_ENV]

// Sentryの設定
export type Sentry = {
  dsn: string
  tracesSampleRate: number
}

const sentrySettings: { [env in NodeJS.ProcessEnv['REACT_APP_ENV']]: Sentry } = {
  development: {
    dsn: '',
    tracesSampleRate: 0,
  },
  staging: {
    dsn: 'https://0fd7baf640e948d0bf4ea355f53ad363@o431831.ingest.sentry.io/5383680',
    tracesSampleRate: 0,
  },
  qa: {
    dsn: 'https://0fd7baf640e948d0bf4ea355f53ad363@o431831.ingest.sentry.io/5383680',
    tracesSampleRate: 0,
  },
  qaBeyond: {
    dsn: 'https://0fd7baf640e948d0bf4ea355f53ad363@o431831.ingest.sentry.io/5383680',
    tracesSampleRate: 0,
  },
  dogfooding: {
    dsn: 'https://0fd7baf640e948d0bf4ea355f53ad363@o431831.ingest.sentry.io/5383680',
    tracesSampleRate: 0,
  },
  production: {
    dsn: 'https://0fd7baf640e948d0bf4ea355f53ad363@o431831.ingest.sentry.io/5383680',
    tracesSampleRate: 0.5,
  },
}

export const configureSentry = (): Sentry => sentrySettings[process.env.REACT_APP_ENV]

// Launch Darklyの設定
export type LaunchDarklySetting = {
  clientSideID: string
}

const launchDarklySettings: { [env in NodeJS.ProcessEnv['REACT_APP_ENV']]: LaunchDarklySetting } = {
  development: {
    clientSideID: '612caac8b7dd84259fbb71a1',
  },
  staging: {
    clientSideID: '61238497fd2ac4272d0126cb',
  },
  qa: {
    clientSideID: '6123849e408d8c2639ff542e',
  },
  qaBeyond: {
    clientSideID: '612384a821ad6425ae51d288',
  },
  dogfooding: {
    clientSideID: '612384b4d78a14277ea87599',
  },
  production: {
    clientSideID: '61234dd1fd2ac4272d011ad7',
  },
}

export const configureLaunchDarkly = (): LaunchDarklySetting =>
  launchDarklySettings[process.env.REACT_APP_ENV]

// チャネルトークの設定
export type ChannelTalk = {
  pluginKey: string
}

const channelTalkSettings: { [env in NodeJS.ProcessEnv['REACT_APP_ENV']]: ChannelTalk } = {
  development: {
    pluginKey: '1a88606f-1c75-46f1-9d21-eafc81d6b455',
  },
  staging: {
    pluginKey: '1a88606f-1c75-46f1-9d21-eafc81d6b455',
  },
  qa: {
    pluginKey: '1a88606f-1c75-46f1-9d21-eafc81d6b455',
  },
  qaBeyond: {
    pluginKey: '1a88606f-1c75-46f1-9d21-eafc81d6b455',
  },
  dogfooding: {
    pluginKey: '1a88606f-1c75-46f1-9d21-eafc81d6b455',
  },
  production: {
    pluginKey: 'be81b38f-0453-431f-a1f6-1d8cd633c2ec',
  },
}

export const configureChannelTalk = (): ChannelTalk =>
  channelTalkSettings[process.env.REACT_APP_ENV]

// アプリケーション内連携設定
export type Integration = {
  slack: {
    clientId: string
    scope: Array<string>
  }
}

const integrationSetting: { [env in NodeJS.ProcessEnv['REACT_APP_ENV']]: Integration } = {
  development: {
    slack: {
      clientId: '155636730961.3765565187061',
      scope: [
        'channels:join',
        'channels:manage',
        'channels:read',
        'chat:write',
        'im:history',
        'im:write',
        // 'incoming-webhook', // 旧版でのみ使用
        'users:read',
        'users:read.email',
      ],
    },
  },
  staging: {
    slack: {
      clientId: '155636730961.3765565187061',
      scope: [
        'channels:join',
        'channels:manage',
        'channels:read',
        'chat:write',
        'im:history',
        'im:write',
        // 'incoming-webhook', // 旧版でのみ使用
        'users:read',
        'users:read.email',
      ],
    },
  },
  qa: {
    slack: {
      clientId: '155636730961.3765565187061',
      scope: [
        'channels:join',
        'channels:manage',
        'channels:read',
        'chat:write',
        'im:history',
        'im:write',
        // 'incoming-webhook', // 旧版でのみ使用
        'users:read',
        'users:read.email',
      ],
    },
  },
  qaBeyond: {
    slack: {
      clientId: '155636730961.3765565187061',
      scope: [
        'channels:join',
        'channels:manage',
        'channels:read',
        'chat:write',
        'im:history',
        'im:write',
        // 'incoming-webhook', // 旧版でのみ使用
        'users:read',
        'users:read.email',
      ],
    },
  },
  dogfooding: {
    slack: {
      clientId: '155636730961.3765565187061',
      scope: [
        'channels:join',
        'channels:manage',
        'channels:read',
        'chat:write',
        'im:history',
        'im:write',
        // 'incoming-webhook', // 旧版でのみ使用
        'users:read',
        'users:read.email',
      ],
    },
  },
  production: {
    slack: {
      clientId: '155636730961.554466058915',
      // Slack Appの申請が通り次第切り替え
      // scope: [
      //   'channels:join',
      //   'channels:manage',
      //   'channels:read',
      //   'chat:write',
      //   'im:history',
      //   'im:write',
      //   // 'incoming-webhook', // 旧版でのみ使用
      //   'users:read',
      //   'users:read.email',
      // ],
      scope: ['channels:read', 'channels:join', 'groups:read', 'chat:write'],
    },
  },
}

export const configureIntegration = (): Integration => integrationSetting[process.env.REACT_APP_ENV]

const previewCollaborationEditingServer = `wss://${window.location.host}/api/collaboration`

const collaborationEditingServer: { [env in NodeJS.ProcessEnv['REACT_APP_ENV']]: string } = {
  development: 'ws://localhost:3000/api/collaboration',
  staging: 'wss://app.risily.com/api/collaboration',
  qa: 'wss://qa.risily.com/api/collaboration',
  qaBeyond: 'wss://qa-beyond.risily.com/api/collaboration',
  dogfooding: 'wss://df.risily.com/api/collaboration',
  production: 'wss://app.resily.com/api/collaboration',
}

export const configureCollaborationEditingServer = (): string =>
  isPreview()
    ? previewCollaborationEditingServer
    : collaborationEditingServer[process.env.REACT_APP_ENV]
