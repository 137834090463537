import { useTranslation } from '../../../i18n'
import { formatDuration } from '../../../lib/date'
import { summaryTitle } from '../../../lib/domain/checkinSummary'
import { color } from '../../../styles/newColors'
import { generateCheckinSummary } from '../../../urls'
import { Icon } from '../../ui/Icon'
import { IconButton } from '../../ui/IconButton'
import { Link } from '../../ui/Link'
import { RichTextEditor } from '../../ui/RichTextEditor'
import { StyledText } from '../../ui/StyledText'
import { TooltipNew } from '../../ui/TooltipNew'
import { Reaction } from '../Reaction'

import { CheckinSummaryFragment } from './graphql'

export type Props = {
  okrNodeId: string
  summary: CheckinSummaryFragment
  onClickEdit: () => void
  onClickCreateReaction: (emoji: string) => void
  onClickDeleteReaction: (reactedId: string) => void
}

export const CheckInSummaryDetail: React.FC<Props> = ({
  okrNodeId,
  summary,
  onClickEdit,
  onClickCreateReaction,
  onClickDeleteReaction,
}) => {
  const { t } = useTranslation()
  const editIconType = 'editOutline'

  return (
    <div css={{ padding: '40px 40px 24px' }}>
      <div css={{ display: 'flex', alignItems: 'center' }}>
        <StyledText
          color="text-bk-100"
          css={{
            fontSize: 24,
            lineHeight: '32px',
            fontWeight: 'bold',
          }}
        >
          {summaryTitle(t, summary)}
        </StyledText>
        <TooltipNew key={editIconType} title={t('EDITION')}>
          <IconButton css={{ marginLeft: 8 }} border={false} size={16} onClick={onClickEdit}>
            <Icon
              type={editIconType}
              color={color('text-bk-30')}
              css={{ display: 'block', cursor: `pointer` }}
            />
          </IconButton>
        </TooltipNew>
      </div>

      {summary.beforeCheckinSummary && (
        <div css={{ marginTop: '8px' }}>
          <Link
            css={{ display: 'flex', alignItems: 'center' }}
            href={generateCheckinSummary(okrNodeId, summary.beforeCheckinSummary.id)}
          >
            <StyledText
              size="large"
              lineHeight="32px"
              color="text-bk-50"
              css={{
                textDecoration: 'underline',
              }}
            >
              {t('PREVIOUS_SUMMARY', { x: formatDuration(summary.beforeCheckinSummary) })}
            </StyledText>
            <Icon
              type="linkPage"
              width={20}
              height={20}
              color={color('text-bk-50')}
              css={{ marginLeft: '4px' }}
            />
          </Link>
        </div>
      )}

      <div css={{ paddingTop: '24px' }}>
        <RichTextEditor
          id={summary.id}
          editorProps={{
            readOnly: true,
          }}
          initialValueJSON={summary.description?.treeJson}
        />
        <Reaction
          reactions={summary.reactions}
          createReaction={onClickCreateReaction}
          deleteReaction={onClickDeleteReaction}
        />
      </div>
    </div>
  )
}

CheckInSummaryDetail.displayName = 'CheckInSummaryDetail'
