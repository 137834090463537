import { useModal } from '@resily/geisha'
import { useCallback, useContext } from 'react'

import { OkrTermIdContext } from '../../../contexts/OkrTermIdContext'
import { useCurrentUser } from '../../../contexts/UserContext'
import { useOkrRefetchQueriesWithOkrModalInitQuery } from '../../../lib/domain/okr'
import {
  InitQueryContextProvider,
  useKeyResultEditFieldsFetch,
} from '../../domain/KeyResultEditFields/hooks'

import { KeyResultCreateModal, Props as KeyResultCreateModalProps } from './KeyResultCreateModal'
import { CreateKeyResultInput, useCreateKeyResultsMutation, useObjectiveQuery } from './graphql'

export type Props = Omit<
  KeyResultCreateModalProps,
  'ownerId' | 'termId' | 'parentObjectiveInfo' | 'onClickSaveKeyResults'
> & {
  objectiveId: string
  ownerId?: string
  onCreate: () => void
  onCompletedSave?: () => void
}

const KeyResultCreateModalContainerInner: React.VFC<
  Props & { modalComponent: ReturnType<typeof useModal>[0] }
> = ({ objectiveId, ownerId, onCreate, onCompletedSave, ...props }) => {
  const { okrTermId } = useContext(OkrTermIdContext)
  const currentUser = useCurrentUser()

  const { data } = useObjectiveQuery({ variables: { objectiveId } })

  const [createKeyResultsMutation] = useCreateKeyResultsMutation({
    refetchQueries: useOkrRefetchQueriesWithOkrModalInitQuery(),
    onCompleted: onCompletedSave,
  })
  const onClickSaveKeyResults = useCallback(
    (krs: ReadonlyArray<CreateKeyResultInput>) => {
      const inputs = krs.map<CreateKeyResultInput>((kr) => {
        const isAssigned: boolean =
          !!currentUser && (currentUser.id === kr.ownerId || kr.memberIds.includes(currentUser.id))
        return { ...kr, confidence: isAssigned ? kr.confidence : undefined }
      })

      return createKeyResultsMutation({ variables: { inputs } }).then(onCreate)
    },
    [createKeyResultsMutation, currentUser, onCreate],
  )

  const loadingKrFieldsData = useKeyResultEditFieldsFetch()

  if (!data || !okrTermId || !currentUser || loadingKrFieldsData) {
    return null
  }

  return (
    <KeyResultCreateModal
      termId={okrTermId}
      ownerId={ownerId || currentUser.id}
      parentObjectiveInfo={data.objective}
      onClickSaveKeyResults={onClickSaveKeyResults}
      {...props}
    />
  )
}

export const KeyResultCreateModalContainer: typeof KeyResultCreateModalContainerInner = ({
  ...props
}) => (
  <InitQueryContextProvider>
    <KeyResultCreateModalContainerInner {...props} />
  </InitQueryContextProvider>
)
