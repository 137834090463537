import { css } from '@emotion/react'

import { fontSize } from '../../../../../styles/font'
import { color } from '../../../../../styles/newColors'

export const rootCss = css({
  backgroundColor: color('white-100'),
  borderRadius: '8px',
  padding: '32px 24px',
})

export const titleCss = css({
  color: color('text-bk-100'),
  ...fontSize('large', 'bold'),
})

export const contentsWrapperCss = css({
  marginTop: '12px',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
})

export const settingEmailBoxCss = css({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: 24,
  marginBottom: 8,
})
