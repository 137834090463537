import { css } from '@emotion/react'
import { useMemo } from 'react'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useStyles = () =>
  useMemo(
    () =>
      ({
        closeButton: css({
          display: 'flex',
          alignItems: 'center',
        }),
      } as const),
    [],
  )
