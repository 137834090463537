import React, { useRef, useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQueryParams, StringParam, ArrayParam } from 'use-query-params'

import { PageContent } from '../../components/pageContent'
import { useOkrTermLoadable } from '../../lib/domain/useOkrTermLoadable'
import { color } from '../../styles/newColors'
import { AuthRouteProps } from '../../types/authRouteProps'
import * as urls from '../../urls'

import { HeaderContainer } from './Header'
import { UsersContainer as Users } from './Users'
import { GroupFragment, TermFragment, useInitialDataQuery, useUsersQuery } from './graphql'
import { useFilter } from './hooks/useFilter'

export const UsersContainer: React.FC<AuthRouteProps> = ({ onOkrTermLoaded }) => {
  const navigate = useNavigate()

  const initialDataResp = useInitialDataQuery()
  const groups = useMemo(() => initialDataResp.data?.groups ?? [], [initialDataResp.data?.groups])
  const okrTerms = useMemo(
    () => initialDataResp.data?.okrTerms ?? [],
    [initialDataResp.data?.okrTerms],
  )

  const termId = useOkrTermLoadable(onOkrTermLoaded, (id) => {
    navigate({
      pathname: urls.generateUsers(id),
    })
  })
  const term = useMemo(() => okrTerms.find(({ id }) => id === termId), [okrTerms, termId])
  const loading = !!term && !!termId

  return <>{loading && <UsersWrapper termId={termId} term={term} groups={groups} />}</>
}

type UsersContainerProps = {
  termId: TermFragment['id']
  term: TermFragment
  groups: ReadonlyArray<GroupFragment>
}

// データの読み込みが完了するまでコンポーネントを表示しないように分割
export const UsersWrapper: React.FC<UsersContainerProps> = ({ termId, term, groups }) => {
  const [queryParams, setQueryParams] = useQueryParams({
    user: ArrayParam,
    group: ArrayParam,
    sort: StringParam,
    order: StringParam,
  })

  const selectedGroupIds = useMemo<ReadonlyArray<string>>(
    () => queryParams.group?.filter((g): g is string => g != null) ?? [],
    [queryParams.group],
  )

  const selectedUserIds = useMemo<ReadonlyArray<string>>(
    () => queryParams.user?.filter((u): u is string => u != null) ?? [],
    [queryParams.user],
  )

  const handleChangeFilter = useCallback(
    ({
      groupIds,
      userIds,
    }: {
      groupIds?: ReadonlyArray<string>
      userIds?: ReadonlyArray<string>
    }) => {
      setQueryParams({
        ...(userIds ? { user: [...userIds] } : {}),
        ...(groupIds ? { group: [...groupIds] } : {}),
      })
    },
    [setQueryParams],
  )

  const headerRef = useRef<HTMLElement>(null)

  const usersResp = useUsersQuery({ variables: { termId } })
  const users = useMemo(() => usersResp.data?.users ?? [], [usersResp.data?.users])
  const { filteredUsers } = useFilter(users, term, selectedGroupIds, selectedUserIds)

  return (
    <PageContent
      breadcrumbs={undefined}
      contentBackgroundColor={color('background-bk-5')}
      header={
        <HeaderContainer
          groups={groups}
          ref={headerRef}
          users={users}
          selectedGroupIds={selectedGroupIds}
          selectedUserIds={selectedUserIds}
          onFilterChange={handleChangeFilter}
        />
      }
    >
      <Users users={filteredUsers} loading={usersResp.loading} />
    </PageContent>
  )
}

UsersContainer.displayName = 'UsersContainer'
