import { useCallback, useEffect, useState } from 'react'
import { NavigationType, useNavigationType } from 'react-router-dom'

import { useCheckEditingOnClose } from '../../../../../../contexts/EditingStateContext'
import { findScreen } from '../../../../../../lib/screen'
import { TabType } from '../../../../../ui/Tabs'
import { KeyResultTab, ObjectiveTab } from '../../../types'
import { clickDrawerTabTrack } from '../utils/clickTabTrack'

export type TabReturnType<T extends KeyResultTab | ObjectiveTab> = [
  tab: T,
  handleClickTab: (t: TabType<T>) => void,
]

export const useTab = <T extends KeyResultTab | ObjectiveTab>(
  initialTab: T,
  setQueryParamTab: (tab: T) => void,
): TabReturnType<T> => {
  const [tab, setTab] = useState<T>(initialTab)

  const navType = useNavigationType()
  useEffect(() => {
    // ブラウザの進む・戻るでタブを復元する
    if (navType !== NavigationType.Replace) {
      setTab(initialTab)
    }
  }, [initialTab, navType])

  const checkEditingOnClose = useCheckEditingOnClose()
  const handleClickTab = useCallback(
    (t: TabType<T>) => {
      clickDrawerTabTrack(findScreen(window.location.pathname, window.location.search), t.value)
      checkEditingOnClose(() => {
        setTab(t.value)
        setQueryParamTab(t.value)
      })
    },
    [checkEditingOnClose, setQueryParamTab],
  )

  return [tab, handleClickTab]
}
