import { Box, Text } from 'grommet'

import { RichTextEditor } from '../../../../../components/ui/RichTextEditor'
import { StyledText } from '../../../../../components/ui/StyledText'
import { useTranslation } from '../../../../../i18n'
import { color } from '../../../../../styles/newColors'
import { ObjectiveFragment, KeyResultFragment, KeyResultUpdateType } from '../../graphql'

type Props = {
  uniqId: string
  objective?: ObjectiveFragment
  keyResult?: KeyResultFragment
}

export const OkrInfo: React.VFC<Props> = ({ uniqId, objective, keyResult }) => {
  const { t } = useTranslation()

  if (!(objective || keyResult)) {
    return null
  }

  return (
    <Box pad="24px">
      <StyledText
        css={{
          fontWeight: 'bold',
          fontSize: '12px',
          color: color('text-bk-50'),
          marginBottom: '14px',
        }}
      >
        {t('PROGRESS_RATE')}
      </StyledText>
      {objective && <ManualProgressRate progressRate={objective.progressRate ?? 0} />}
      {!objective &&
        keyResult &&
        (keyResult.updateType === KeyResultUpdateType.Automate && !keyResult.isAutoAggregate ? (
          <AutomateProgressRate
            targetValue={keyResult?.targetValue ?? null}
            actualValue={keyResult?.actualValue ?? null}
            unit={keyResult?.unit ?? ''}
          />
        ) : (
          <ManualProgressRate progressRate={keyResult.progressRate ?? 0} />
        ))}
      <Text
        css={{
          display: 'block',
          fontWeight: 'bold',
          fontSize: '12px',
          color: color('text-bk-50'),
          marginTop: '24px',
        }}
      >
        {t('DESCRIPTION')}
      </Text>
      <div css={{ margin: '8px 0' }}>
        <RichTextEditor
          id={uniqId}
          editorProps={{
            readOnly: true,
          }}
          initialValueJSON={
            objective ? objective.description?.treeJson : keyResult?.description?.treeJson
          }
        />
      </div>
    </Box>
  )
}

type ManualProgressRateProps = {
  progressRate: number
}

const ManualProgressRate: React.VFC<ManualProgressRateProps> = ({ progressRate }) => (
  <Text
    css={{
      display: 'block',
      fontSize: '14px',
      paddingLeft: '12px',
    }}
  >
    {progressRate}
    <span css={{ display: 'inline-block', marginLeft: '16px' }}>%</span>
  </Text>
)

type AutomateProgressRateProps = {
  actualValue: number | null
  targetValue: number | null
  unit: string
}

const AutomateProgressRate: React.VFC<AutomateProgressRateProps> = ({
  actualValue,
  targetValue,
  unit,
}) => {
  const { t } = useTranslation()
  const needProgressRateSetting = targetValue === null

  return (
    <div css={{ display: 'flex', marginBottom: '24px', gap: '74px' }}>
      {needProgressRateSetting ? (
        <StyledText color="text-bk-100" size="xsmall" css={{ lineHeight: '14px' }}>
          {t('PROGRESS_RATE_HAS_NOT_SET')}
          <br />
          {t('ENTER_TARGET_VALUE_AND_UNIT')}
        </StyledText>
      ) : (
        <>
          <AutomateProgressRateItem title={t('ACTUAL_VALUE')} value={actualValue} unit={unit} />
          <AutomateProgressRateItem title={t('TARGET_VALUE')} value={targetValue} unit={unit} />
        </>
      )}
    </div>
  )
}

type AutomateProgressRateItemProps = {
  title: string
  value: number | null
  unit: string
}

const AutomateProgressRateItem: React.VFC<AutomateProgressRateItemProps> = ({
  title,
  value,
  unit,
}) => (
  <div>
    <p
      css={{
        display: 'block',
        fontSize: '10px',
        color: color('text-bk-50'),
        marginBottom: '13px',
      }}
    >
      {title}
    </p>
    <div css={{ display: 'flex' }}>
      <p
        css={{
          fontSize: '14px',
          paddingLeft: '12px',
        }}
      >
        {value != null ? `${value.toLocaleString(undefined, { maximumFractionDigits: 20 })}` : '-'}
      </p>
      <span css={{ marginLeft: '12px' }}>{value != null && (unit ?? '')}</span>
    </div>
  </div>
)
