import { css } from '@emotion/react'
import { useMemo } from 'react'

import { color } from '../../../styles/newColors'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useStyles = () =>
  useMemo(
    () =>
      ({
        editThisWeeksPost: css({
          display: 'flex',
          alignItems: 'center',
          gap: 4,
          color: color('text-bk-30'),
          fontSize: '14px',
          '&:hover': {
            color: color('resily-orange-100'),
          },
        }),
      } as const),
    [],
  )
