import { useModal } from '@resily/geisha'
import { ThemeContext } from 'grommet'
import { useCallback, useState } from 'react'
import isEqual from 'react-fast-compare'

import { useTranslation } from '../../../i18n'
import { findScreen } from '../../../lib/screen'
import { tracker } from '../../../lib/tracking'
import { useApiProcessing } from '../../../lib/useApiProcessing'
import {
  KeyResultCreateModalContentProps,
  generateInitialKeyResultState,
  useKeyResultCreateModalContent,
} from '../../domain/KeyResultCreateModalContent'

import { Header } from './Header'
import { CreateKeyResultInput } from './graphql'

export type Props = {
  isOpen: boolean
  ownerId: string
  termId: string
  parentObjectiveInfo: KeyResultCreateModalContentProps['objective']
  onClose: () => void
  onEdit: (isDirty: boolean) => void
  onClickSaveKeyResults: (inputs: ReadonlyArray<CreateKeyResultInput>) => Promise<unknown>
}

export const KeyResultCreateModal: React.VFC<
  Props & { modalComponent: ReturnType<typeof useModal>[0] }
> = ({
  modalComponent: Modal,
  isOpen,
  ownerId,
  termId,
  parentObjectiveInfo,
  onClose,
  onEdit,
  onClickSaveKeyResults,
}) => {
  const { t } = useTranslation()

  const [isApiProcessing, wrapApiPromise] = useApiProcessing()

  const [keyResultStates, setKeyResultStates] = useState<
    KeyResultCreateModalContentProps['keyResultStates']
  >([generateInitialKeyResultState(ownerId, termId, parentObjectiveInfo.id)])
  const {
    KeyResultCreateModalContent,
    canSave,
    validate,
    errors,
    convertStateToInput,
    AddKRButton,
  } = useKeyResultCreateModalContent()

  const handelSetKeyResultStates = useCallback<typeof setKeyResultStates>(
    (arg) => {
      setKeyResultStates((prevState) => {
        const nextState = typeof arg === 'function' ? arg(prevState) : arg
        validate(nextState)

        if (nextState.length !== 1) {
          onEdit(true)
        } else {
          const initialKR = convertStateToInput([
            generateInitialKeyResultState(ownerId, termId, parentObjectiveInfo.id),
          ])[0]
          const isDirty = !convertStateToInput(nextState).every((kr) => isEqual(kr, initialKR))
          onEdit(isDirty)
        }

        return nextState
      })
    },
    [convertStateToInput, onEdit, ownerId, parentObjectiveInfo.id, termId, validate],
  )

  return (
    <ThemeContext.Extend value={{ global: { drop: { zIndex: '201' } } }}>
      <Modal
        isOpen={isOpen}
        contentMargin={false}
        size="large"
        restrictFocus={false}
        onClose={useCallback(() => {
          tracker.UserClickCloseKeyResultCreateModal(findScreen(window.location.pathname))
          if (isApiProcessing) return
          onClose()
        }, [isApiProcessing, onClose])}
      >
        <Modal.Header title={<Header />} />
        <Modal.Content>
          <KeyResultCreateModalContent
            objective={parentObjectiveInfo}
            keyResultStates={keyResultStates}
            onChangeKeyResults={handelSetKeyResultStates}
            errors={errors}
          />
        </Modal.Content>
        <Modal.Footer
          confirmLabel={t('SAVE')}
          confirmDisabled={isApiProcessing || !canSave(keyResultStates)}
          onConfirm={useCallback(() => {
            tracker.UserClickCreateKeyResultByKeyResultCreateModal(
              findScreen(window.location.pathname),
              false,
            )

            const isValid = validate(keyResultStates)
            if (!isValid) return

            wrapApiPromise(onClickSaveKeyResults(convertStateToInput(keyResultStates)))
          }, [
            convertStateToInput,
            keyResultStates,
            onClickSaveKeyResults,
            wrapApiPromise,
            validate,
          ])}
        >
          <AddKRButton
            disabled={isApiProcessing}
            onClick={() =>
              handelSetKeyResultStates((prev) =>
                prev.concat(generateInitialKeyResultState(ownerId, termId, parentObjectiveInfo.id)),
              )
            }
          />
        </Modal.Footer>
      </Modal>
    </ThemeContext.Extend>
  )
}
