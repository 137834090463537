import { memo } from 'react'
import isEqual from 'react-fast-compare'

import { ordinal } from '../../../../../lib/ordinal'
import { componentNames, featureNames, generateTestId } from '../../../../../lib/testId'
import { Signal } from '../../form/components/Signal'
import { keyResultsKeyName } from '../../form/formSchema'
import { IndexAreaFragment } from '../../graphql/graphql'
import { generateKeyResultLinkId } from '../../utils'

import { IndexTitle } from './components/IndexTitle'
import { LayerDiviser } from './components/LayerDiviser'
import {
  indexItemCss,
  indexTitleCss,
  rootCss,
  selectedIndexItemCss,
  signalsWrapperCss,
} from './styles'

export type IndexAreaKeyResultsFragment = ReadonlyArray<IndexAreaFragment>

export type Props = {
  keyResults: IndexAreaKeyResultsFragment
  selectedKeyResultId: string
}

export const IndexArea: React.VFC<Props> = memo(
  ({ keyResults, selectedKeyResultId }) => (
    <div css={rootCss}>
      {keyResults.map((keyResult, index) => (
        <div key={keyResult.id}>
          {(index === 0 || keyResult.node.depth !== keyResults[index - 1].node.depth) && (
            <LayerDiviser>{ordinal(keyResult.node.depth)}</LayerDiviser>
          )}
          <a
            data-testid={generateTestId(featureNames.checkinModal, componentNames.indexItem, {
              suffix: keyResult.id,
            })}
            href={`#${generateKeyResultLinkId(keyResult.id)}`}
            css={
              (!selectedKeyResultId && index === 0) || keyResult.id === selectedKeyResultId
                ? [indexItemCss, selectedIndexItemCss]
                : indexItemCss
            }
          >
            {/** KRタイトル */}
            <IndexTitle css={indexTitleCss}>{keyResult.name}</IndexTitle>
            {/** シグナル */}
            <div css={signalsWrapperCss}>
              <Signal
                iconType="progressRate"
                names={[
                  `${keyResultsKeyName}.${index}.unit`,
                  `${keyResultsKeyName}.${index}.initialValue`,
                  `${keyResultsKeyName}.${index}.targetValue`,
                  `${keyResultsKeyName}.${index}.actualValue`,
                ]}
              />
              <Signal iconType="confidence" names={[`${keyResultsKeyName}.${index}.level`]} />
              <Signal
                iconType="comment"
                names={[
                  `${keyResultsKeyName}.${index}.priority`,
                  `${keyResultsKeyName}.${index}.problem`,
                  `${keyResultsKeyName}.${index}.winSession`,
                  `${keyResultsKeyName}.${index}.other`,
                ]}
              />
            </div>
          </a>
        </div>
      ))}
    </div>
  ),
  (prev, next) => {
    if (!isEqual(prev.keyResults, next.keyResults)) {
      return false
    }
    if (prev.selectedKeyResultId !== next.selectedKeyResultId) {
      return false
    }

    return true
  },
)

IndexArea.displayName = 'IndexArea'
