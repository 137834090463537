import React, { useEffect, useState } from 'react'

import { Avatar } from '../../../components/ui/Avatar'
import { useDrawerState } from '../Drawer/hooks/useDrawerState'
import { UserFragment } from '../graphql'

type Users = ReadonlyArray<UserFragment>

export type Props = {
  users?: Users
  onClickAvatar: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, user: UserFragment) => void
}

export const MeetingAvatars: React.VFC<Props> = ({ users, onClickAvatar }) => {
  const renderUsers = users || []
  const clickableAvatar = Boolean(users)
  const { params } = useDrawerState()
  const [selectedUserId, setSelectedUserId] = useState<string>(params?.userId ?? '')

  // ドロワーの状態が本コンポーネント以外で更新された場合、選択ユーザーを変更する
  useEffect(() => {
    setSelectedUserId(params?.userId ?? '')
  }, [params])

  return (
    <>
      {renderUsers.map((user) => (
        <button
          key={user.id}
          type="button"
          disabled={user.id === selectedUserId}
          css={{
            '&:disabled': {
              cursor: 'default',
            },
          }}
          onClick={(e) => {
            if (clickableAvatar) {
              setSelectedUserId(user.id)
              onClickAvatar(e, user)
            }
          }}
        >
          <Avatar
            firstName={user.firstName}
            lastName={user.lastName}
            avatarUrl={user.avatar?.url}
            size="user-list-page"
            css={{ marginRight: '8px' }}
            isUserDisabled={user.isDisabled}
            avatarStyle={selectedUserId === user.id ? { border: '2px solid #DC440A' } : undefined}
          />
        </button>
      ))}
    </>
  )
}

MeetingAvatars.displayName = 'MeetingAvatars'
