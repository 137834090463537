import { css } from '@emotion/react'

export const tableContentsMapWrapperCss = css({
  overflow: 'auto',
})

export const emptyCss = css({
  display: 'flex',
  justifyContent: 'center',
  height: '102px',
  padding: '40px',
})
