import { IconTextButton } from '@resily/geisha'
import { Button } from 'grommet'
import React from 'react'

import { Icon } from '../../../../../components/ui/Icon'
import { StyledText } from '../../../../../components/ui/StyledText'
import { useTranslation } from '../../../../../i18n'
import { border } from '../../../../../styles/border'
import { color } from '../../../../../styles/newColors'

import { OkrsTableRow, Props as RowProps, OkrsTableRowSkeleton } from './OkrsTableRow'
import { KR_PROGRESS_WIDTH, O_KR_GAP, O_WIDTH } from './const'

export type Props = Omit<RowProps, 'objective'> & {
  loading?: boolean
  isOwnPage: boolean
  objectives: ReadonlyArray<RowProps['objective']>
  onClickCreateOkr: () => void
}

export const OkrsTable: React.FC<Props> = ({
  loading,
  termId,
  userId,
  isOwnPage,
  objectives,
  onClickCreateOkr,
  onClickObjectiveName,
  onClickKeyResultName,
  onClickObjectiveTrend,
  onClickKeyResultTrend,
}) => {
  const { t } = useTranslation()

  return (
    <section css={{ padding: '24px 32px' }}>
      <div css={{ display: 'flex', justifyContent: 'space-between' }}>
        <div css={{ display: 'flex', alignItems: 'center', gap: 6 }}>
          <div>
            <span
              css={{
                fontSize: '16px',
                lineHeight: '24px',
                fontWeight: 'bold',
                display: 'block',
                marginBottom: '4px',
              }}
            >
              {t('OKR')}
            </span>
            <span
              css={{
                fontSize: '12px',
                lineHeight: '20px',
              }}
            >
              {isOwnPage ? t('HOME_MY_OKR_DESCRIPTION') : t('HOME_USER_OKR_DESCRIPTION')}
            </span>
          </div>
        </div>
        <div>
          {isOwnPage ? (
            <IconTextButton variant="primary" iconType="add" onClick={onClickCreateOkr}>
              {t('CREATE_OF_X', { x: t('OKR') })}
            </IconTextButton>
          ) : null}
        </div>
      </div>
      <div
        css={{
          marginTop: 20,
          height: 24,
          display: 'flex',
          alignItems: 'flex-start',
          color: color('text-bk-50'),
          boxShadow: `0px 1px 0px 0px ${color('border-bk-10')}`,
          fontSize: '12px',
          lineHeight: '12px',
          fontWeight: 'bold',
          '& > *:nth-child(1)': {
            flex: `0 0 ${O_WIDTH + O_KR_GAP}px`,
            paddingLeft: 12,
          },
          '& > *:nth-child(2)': {
            flex: '1 1 auto',
            paddingLeft: 4,
          },
          '& > *:nth-child(3)': {
            flex: `0 0 ${KR_PROGRESS_WIDTH}px`,
            paddingLeft: 12,
          },
        }}
      >
        <span>{t('OBJECTIVE')}</span>
        <span>{t('KEY_RESULT')}</span>
        <span>{t('PROGRESS_RATE')}</span>
      </div>
      {/* eslint-disable-next-line no-nested-ternary */}
      {loading ? (
        <ul>
          {new Array(2).fill(undefined).map(() => (
            <OkrsTableRowSkeleton />
          ))}
        </ul>
      ) : objectives.length > 0 ? (
        <ul>
          {objectives.map((o) => (
            <OkrsTableRow
              key={o.id}
              termId={termId}
              userId={userId}
              objective={o}
              onClickObjectiveName={onClickObjectiveName}
              onClickKeyResultName={onClickKeyResultName}
              onClickObjectiveTrend={onClickObjectiveTrend}
              onClickKeyResultTrend={onClickKeyResultTrend}
            />
          ))}
        </ul>
      ) : (
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            margin: 40,
            gap: 35,
          }}
        >
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 16,
            }}
          >
            <StyledText size="large" lineHeight="16px">
              {t('X_IS_EMPTY', {
                x: isOwnPage ? t('MY_OKR') : t('X_OF_Y', { x: t('OKR'), y: t('USER') }),
              })}
            </StyledText>
            {isOwnPage ? (
              <Button
                onClick={onClickCreateOkr}
                css={{
                  padding: '0 16px',
                  height: '40px',
                  border: border('simple-30'),
                  borderRadius: '4px',
                  textAlign: 'center',
                  ':hover': {
                    backgroundColor: color('hover-background-bk-5'),
                  },
                }}
              >
                <StyledText>{t('CREATE_OF_X', { x: t('OKR') })}</StyledText>
              </Button>
            ) : null}
          </div>
          <Icon width={302} height={154} type="mailboxCat" />
        </div>
      )}
    </section>
  )
}
