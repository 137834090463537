import { useApolloClient } from '@apollo/client'
import { useCallback, useMemo } from 'react'
import { DecodedValueMap, StringParam, useQueryParams } from 'use-query-params'

import { DrawerQuery, DRAWER_QUERY_ID, DRAWER_QUERY_TAB, DRAWER_QUERY_TYPE } from '../../../../urls'
import type { DrawerTab } from '../../../domain/OkrDrawer/types'
import { withDrawerParams } from '../../../domain/OkrDrawer/withDrawerParams'
import { KeyResultTab, KeyResultTabParam, ObjectiveTab, ObjectiveTabParam } from '../types'

import {
  KeyResultToObjectiveFragment,
  KeyResultToObjectiveFragmentDoc,
  useKeyResultToObjectiveLazyQuery,
} from './graphql'

const DrawerQueryRemoveParams: DrawerQuery = {
  [DRAWER_QUERY_TYPE]: undefined,
  [DRAWER_QUERY_TAB]: undefined,
  [DRAWER_QUERY_ID]: undefined,
}

const convertObjectiveDrawerToOkrModalParam = (tab: DrawerTab): ObjectiveTab | undefined => {
  switch (tab) {
    case 'info':
      return 'm-o-description'
    case 'note':
      return 'm-o-note'
    case 'event':
      return 'm-o-activity'
    default:
      return undefined
  }
}

const convertKeyResultDrawerToOkrModalParam = (tab: DrawerTab): KeyResultTab | undefined => {
  switch (tab) {
    case 'info':
      return 'm-kr-description'
    case 'note':
      return 'm-kr-note'
    case 'actionPlan':
      return 'm-kr-action-plan'
    case 'event':
      return 'm-kr-activity'
    default:
      return undefined
  }
}

export type OkrModalQueryParamsType = {
  'm-o-id': typeof StringParam
  'm-o-tab': typeof ObjectiveTabParam
  'm-kr-id': typeof StringParam
  'm-kr-tab': typeof KeyResultTabParam
}

export type OkrModalQueryParamsReturnType = {
  objectiveId: DecodedValueMap<OkrModalQueryParamsType>['m-o-id']
  objectiveTab: DecodedValueMap<OkrModalQueryParamsType>['m-o-tab']
  keyResultId: DecodedValueMap<OkrModalQueryParamsType>['m-kr-id']
  keyResultTab: DecodedValueMap<OkrModalQueryParamsType>['m-kr-tab']
  setObjectiveId: (id: string) => void
  setObjectiveTab: (tab: ObjectiveTab) => void
  setObjectiveIdAndKeyResultId: (objectiveId: string, keyResultId: string) => void
  setKeyResultId: (id: string) => void
  setKeyResultTab: (tab: KeyResultTab) => void
  resetAllQueryParams: () => void
  resetKeyResultId: () => void
}

export const useOkrModalQueryParams = (): OkrModalQueryParamsReturnType => {
  const client = useApolloClient()
  const [fetchKeyResultToObjectiveLazyQuery, { data, called }] = useKeyResultToObjectiveLazyQuery()

  const [queryParams, setQueryParams] = useQueryParams(
    withDrawerParams({
      'm-o-id': StringParam,
      'm-o-tab': ObjectiveTabParam,
      'm-kr-id': StringParam,
      'm-kr-tab': KeyResultTabParam,
    }),
  )

  const setObjectiveId = useCallback(
    (id: string) => setQueryParams({ 'm-o-id': id }),
    [setQueryParams],
  )
  const setObjectiveTab = useCallback(
    (tab: ObjectiveTab) => setQueryParams({ 'm-o-tab': tab }),
    [setQueryParams],
  )
  const setObjectiveIdAndKeyResultId = useCallback(
    (objectiveId: string, keyResultId: string) =>
      setQueryParams({ 'm-o-id': objectiveId, 'm-kr-id': keyResultId }),
    [setQueryParams],
  )
  const setKeyResultId = useCallback(
    (id: string) => setQueryParams({ 'm-kr-id': id }),
    [setQueryParams],
  )
  const setKeyResultTab = useCallback(
    (tab: KeyResultTab) => setQueryParams({ 'm-kr-tab': tab }),
    [setQueryParams],
  )

  const resetAllQueryParams = useCallback(() => {
    setQueryParams({
      'm-o-id': undefined,
      'm-o-tab': undefined,
      'm-kr-id': undefined,
      'm-kr-tab': undefined,
    })
  }, [setQueryParams])

  const resetKeyResultId = useCallback(() => {
    setQueryParams({
      'm-kr-id': undefined,
    })
  }, [setQueryParams])

  const { objectiveId, objectiveTab, keyResultId, keyResultTab } = useMemo<
    Pick<
      OkrModalQueryParamsReturnType,
      'objectiveId' | 'objectiveTab' | 'keyResultId' | 'keyResultTab'
    >
  >(() => {
    // OKRモーダルが表示可能な別ページに遷移しようとすると元のページに戻る対策
    if (Object.values(queryParams).every((p) => p == null)) {
      return {
        objectiveId: undefined,
        objectiveTab: undefined,
        keyResultId: undefined,
        keyResultTab: undefined,
      }
    }

    const drawerType = queryParams['d-type']
    const drawerTab = queryParams['d-tab']
    const drawerId = queryParams['d-id']

    // 右ドロワー関連のqueryParamが無い/無視できる場合はそのまま流す
    if (!drawerType || !drawerId || !drawerTab || drawerTab === 'empty') {
      // 右ドロワー関連のqueryParamが存在する場合のみ、右ドロワーのqueryParamを削除する
      if (drawerType || drawerId || drawerTab) {
        setQueryParams(DrawerQueryRemoveParams, 'replaceIn')
      }

      return {
        objectiveId: queryParams['m-o-id'],
        objectiveTab: queryParams['m-o-tab'],
        keyResultId: queryParams['m-kr-id'],
        keyResultTab: queryParams['m-kr-tab'],
      }
    }

    // fallbackのquery実行時はそちらで取得したデータを元にIDを付与する
    if (called && data?.keyResult.objective.id) {
      setQueryParams(
        {
          ...DrawerQueryRemoveParams,
          'm-o-id': data.keyResult.objective.id,
          'm-o-tab': undefined,
          'm-kr-id': data.keyResult.id,
          'm-kr-tab': convertKeyResultDrawerToOkrModalParam(drawerTab) || queryParams['m-kr-tab'],
        },
        'replaceIn',
      )

      return {
        objectiveId: data.keyResult.objective.id,
        objectiveTab: undefined,
        keyResultId: data.keyResult.id,
        keyResultTab: queryParams['m-kr-tab'],
      }
    }

    // O右ドロワーのqueryParamが来た場合は単純に変換
    if (drawerType === 'objective') {
      setQueryParams(
        {
          ...DrawerQueryRemoveParams,
          'm-o-id': drawerId || queryParams['m-o-id'],
          'm-o-tab': convertObjectiveDrawerToOkrModalParam(drawerTab) || queryParams['m-o-tab'],
          'm-kr-id': queryParams['m-kr-id'],
          'm-kr-tab': queryParams['m-kr-tab'],
        },
        'replaceIn',
      )

      return {
        objectiveId: drawerId || queryParams['m-o-id'],
        objectiveTab: convertObjectiveDrawerToOkrModalParam(drawerTab) || queryParams['m-o-tab'],
        keyResultId: queryParams['m-kr-id'],
        keyResultTab: queryParams['m-kr-tab'],
      }
    }

    // KR右ドロワーのqueryParamにはKRのIDしかないのでキャッシュ経由でOのIDを取得する
    const id: `${NonNullable<
      KeyResultToObjectiveFragment['__typename']
    >}:${string}` = `KeyResult:${drawerId}`
    const fragmentCacheData = client.readFragment<KeyResultToObjectiveFragment>({
      id,
      fragment: KeyResultToObjectiveFragmentDoc,
    })
    if (fragmentCacheData?.objective.id != null) {
      setQueryParams(
        {
          ...DrawerQueryRemoveParams,
          'm-o-id': fragmentCacheData.objective.id,
          'm-o-tab': undefined,
          'm-kr-id': drawerId || queryParams['m-kr-id'],
          'm-kr-tab': convertKeyResultDrawerToOkrModalParam(drawerTab) || queryParams['m-kr-tab'],
        },
        'replaceIn',
      )

      return {
        objectiveId: fragmentCacheData.objective.id,
        objectiveTab: undefined,
        keyResultId: drawerId || queryParams['m-kr-id'],
        keyResultTab: convertKeyResultDrawerToOkrModalParam(drawerTab) || queryParams['m-kr-tab'],
      }
    }

    // キャッシュがない場合のfallbackとしてKRのIDを元にOのIDをqueryする
    // デバッグ用: ?d-type=keyResult&d-tab=note&d-id=S2V5UmVzdWx0czoz
    fetchKeyResultToObjectiveLazyQuery({ variables: { keyResultId: drawerId } })

    // このパターンだけsetQueryParamsせずに、最後にqueryしたデータを元に変換する時にsetQueryParamsする
    return {
      objectiveId: queryParams['m-o-id'],
      objectiveTab: queryParams['m-o-tab'],
      keyResultId: drawerId || queryParams['m-kr-id'],
      keyResultTab: convertKeyResultDrawerToOkrModalParam(drawerTab) || queryParams['m-kr-tab'],
    }
  }, [called, client, data, fetchKeyResultToObjectiveLazyQuery, queryParams, setQueryParams])

  return {
    objectiveId,
    objectiveTab,
    keyResultId,
    keyResultTab,
    setObjectiveId,
    setObjectiveTab,
    setObjectiveIdAndKeyResultId,
    setKeyResultId,
    setKeyResultTab,
    resetAllQueryParams,
    resetKeyResultId,
  }
}
