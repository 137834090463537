import { Box } from 'grommet'
import { useEffect, forwardRef } from 'react'

import { MAIN_CONTENT_ID } from '../../../../../../../components/layout/GloNavAndMain'
import { Icon } from '../../../../../../../components/ui/Icon'
import { scrollHash } from '../../../../../../../lib/scrollHash'
import { color } from '../../../../../../../styles/newColors'
import {
  generateKeyResultHash,
  matchKeyResultDomId,
  prefixKeyResultHash,
} from '../../../../../../../urls'

import { KeyResultItem } from './KeyResultItem'
import { KeyResultFragment } from './graphql'

export type Props = {
  keyResult: KeyResultFragment
  updateKeyResultName: (keyResultId: string, name: string) => void
}

export const KeyResultsTableItemsDescription = forwardRef<HTMLTableRowElement, Props>(
  ({ keyResult: item, updateKeyResultName, ...props }, ref) => {
    useEffect(() => {
      const hash = window.location.hash || ''
      const krIdByHash = hash.replace(`#${prefixKeyResultHash}`, '')
      if (item.id !== krIdByHash) return

      scrollHash({
        scrollContentId: MAIN_CONTENT_ID,
        matchDomId: matchKeyResultDomId,
        top: -4,
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
      <tr ref={ref} {...props}>
        <td css={{ width: '32px' }}>
          <Box css={{ padding: '0 8px' }}>
            <Icon type="dragIndicator" color={color('text-bk-30')} />
          </Box>
        </td>
        <td
          id={generateKeyResultHash(item.id)}
          style={{
            width: 'auto',
          }}
        >
          <KeyResultItem
            key={`kr_${item.id}`}
            keyResult={item}
            onSave={(name) => {
              updateKeyResultName(item.id, name)
            }}
          />
        </td>
      </tr>
    )
  },
)

KeyResultsTableItemsDescription.displayName = 'KeyResultsTableItemsDescription'
