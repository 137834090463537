import { useCallback, useContext } from 'react'

import { OrganizationContext } from '../../../../contexts/OrganizationContext'

import { IpTableList } from './IpTableList'
import { useUpdateIpWhitelistMutation } from './graphql'

export const AdminIpWhitelistContainer: React.FC = () => {
  const organization = useContext(OrganizationContext)
  const [updateIpWhitelist] = useUpdateIpWhitelistMutation()
  const onClickSave = useCallback(
    (ipWhitelist) => updateIpWhitelist({ variables: { whitelist: ipWhitelist } }),
    [updateIpWhitelist],
  )

  if (!organization) return null

  return <IpTableList defaultList={organization?.ipWhitelist} onClickSave={onClickSave} />
}

AdminIpWhitelistContainer.displayName = 'AdminIpWhitelistContainer'
