import { css } from '@emotion/react'
import { useMemo } from 'react'

import { fontSize } from '../../../../../../styles/font'
import { color } from '../../../../../../styles/newColors'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useStyles = () =>
  useMemo(
    () =>
      ({
        root: css({
          display: 'grid',
          gridTemplateColumns: '120px 120px 120px 120px 120px',
          gap: 8,
        }),
        buttonWrapper: css({
          display: 'flex',
        }),
        button: css({
          color: color('text-bk-50'),
          textDecoration: 'underline',
          textAlign: 'left',
          ':hover': {
            color: color('resily-orange-100'),
          },
          ...fontSize('small'),
        }),
      } as const),
    [],
  )
