import { css, CSSObject } from '@emotion/react'
import { useProfiler } from '@sentry/react'
import { Drop } from 'grommet'
import {
  VFC,
  RefObject,
  useMemo,
  useCallback,
  MouseEvent as ReactMouseEvent,
  useRef,
  MouseEventHandler,
} from 'react'

import { useCurrentUser } from '../../../../contexts/UserContext'
import { useTranslation } from '../../../../i18n'
import { CUSTOM_LAUNCHER_CLASS_NAME, useChannelTalk } from '../../../../lib/channelTalk'
import { client } from '../../../../lib/client'
import { destruct } from '../../../../lib/domain/auth'
import { tracker } from '../../../../lib/tracking'
import { border } from '../../../../styles/border'
import { color } from '../../../../styles/newColors'
import * as urls from '../../../../urls'
import { ImageUploader, ImageFileInfo } from '../../../domain/ImageUploader'
import { Avatar } from '../../../ui/Avatar'
import { Icon } from '../../../ui/Icon'
import { Link } from '../../../ui/Link'
import { StyledText } from '../../../ui/StyledText'

import { useUpdateProfileMutation } from './graphql'

type LogoutResponse = {
  IdentityProviderSLOURL: string
}

export const SIDE_NAV_BOTTOM_CLASS_NAME = 'side-nav-bottom'
export const USER_ICON_CLASS_NAME = 'side-nav-bottom-user-icon'
export const COLLAPSE_CLASS_NAME = 'side-nav-bottom-collapse'

const sideNavBottomBox = css({
  minHeight: '64px', // min-heightを指定しないとなぜか外部のコンテンツの影響を受けてしまう
  height: '64px', // heightを直接指定して中身のコンテンツの影響でアニメーションがガタツクのを防ぐ
  overflow: 'hidden', // アニメーション中の中身の影響をなくす
  padding: '12px 10px 14px',
  alignItems: 'center',
  '&[data-is-collapse="true"]': {
    [`.${USER_ICON_CLASS_NAME}`]: {
      opacity: 1,
      transition: 'opacity 100ms cubic-bezier(0.820, 0.085, 0.395, 0.895)',
    },
    [`.${COLLAPSE_CLASS_NAME}`]: {
      opacity: 1,
      transition: 'opacity 100ms cubic-bezier(0.820, 0.085, 0.395, 0.895)',
    },
  },
  borderTop: `1px solid ${color('border-bk-10')}`,
  display: 'flex',
})
const collapsedTextCss = css({ lineHeight: 1, whiteSpace: 'nowrap' })
const unreadCountCss = css({
  display: 'block',
  marginTop: '2px',
  fontSize: 8,
  lineHeight: 1,
  fontWeight: 'bold',
})
const unreadCountCircle = css({
  display: 'block',
  '&[data-is-unread-count="0"]': {
    display: 'none',
  },
  backgroundColor: color('resily-orange-100'),
  borderRadius: '50%',
  width: '14px',
  height: '14px',
  position: 'absolute',
  top: '1px',
  right: '1px',
  boxShadow: '0px 1px 1px rgb(0 0 0 / 10%)',
})
const unreadCountBox = css({
  cursor: 'pointer',
  width: '36px',
  height: '36px',
  borderRadius: '8px',
  padding: '4px',
  position: 'relative',
  ':hover': {
    backgroundColor: color('hover-background-bk-5'),
  },
})

const userIconCss = css({
  display: 'flex',
  alignItems: 'center',
  gap: '6px',
})
const profileCloseIconCss = css({
  color: color('text-bk-50'),
  ':hover': {
    color: color('text-bk-100'),
  },
})
const popoverStyle = {
  align: { bottom: 'top' },
  margin: {
    bottom: '8px',
    left: '36px',
  },
  css: css({
    border: `1px solid ${color('border-bk-30')}`,
    boxShadow: '0px 8px 16px rgb(34 41 67 / 8%)',
  }),
} as const
const profileBox = css({
  width: 178,
  minHeight: 231,
  display: 'flex',
  flexDirection: 'column',
  padding: '24px 4px 20px',
  borderRadius: '8px',
  alignItems: 'center',
})
const profileButtonCss = css({
  marginLeft: '10px',
  marginRight: '14px',
  borderRadius: '8px',
  cursor: 'pointer',
  padding: '4px 2px 4px 4px',
  position: 'relative',
  ':hover': {
    backgroundColor: color('hover-background-bk-5'),
  },
})
const profileIconCss = css({ position: 'relative' })
const profileAvatarCss = css({
  marginBottom: '8px',
})
const profileHoverAvatarCss: CSSObject = {
  backgroundColor: 'none',
}
const profileLinkCss = css({
  height: 80,
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
})
const linkCss = css({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  gap: 12,
  ':hover': {
    color: color('resily-orange-100'),
    svg: {
      stroke: color('resily-orange-100'),
    },
    textDecoration: 'none',
  },
})
const profileNameCss = css({
  marginBottom: '28px',
  overflow: 'hidden',
  width: '154px',
  textAlign: 'center',
  wordBreak: 'break-all',
})
const profileImageUploaderCss = css({
  cursor: 'pointer',
  position: 'absolute',
  right: '-5px',
  bottom: '3px',
  ':hover': {
    svg: {
      color: color('text-bk-100'),
    },
  },
  width: '26px',
  height: '26px',
  borderRadius: '12px',
  backgroundColor: color('white-100'),
  border: border('simple-20'),
})
const profileImageUploadIconCss = css({ marginLeft: '5px', marginTop: '5px' })

const gapBorderCss = css({
  height: 24,
  width: 1,
  backgroundColor: color('border-bk-10'),
  marginRight: 6,
})

const collapseBox = css({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: '10px 8px 10px 10px',
  borderRadius: 4,
  gap: 8,
  ':hover': {
    backgroundColor: color('hover-background-bk-5'),
  },
})

const isClickedInsideRef = (
  targetRef: RefObject<HTMLButtonElement>,
  targetElement: EventTarget,
): boolean => {
  if (!targetRef.current) return false

  const getAllNodes = (node: ChildNode): Array<ChildNode> => {
    if (!node.hasChildNodes()) return [node]
    return [
      node,
      ...Array.from(node.childNodes)
        .map((childeNode) => getAllNodes(childeNode))
        .flat(),
    ]
  }
  const targets = getAllNodes(targetRef.current)
  return targets.some((node) => node === targetElement)
}

export type Props = {
  sideNavLocked: boolean
  onClickCollapse: () => void
  isPopoverOpen: boolean
  setIsPopoverOpen: (isPopoverOpen: boolean) => void
  setIsMessenger: (isMessenger: boolean) => void
}

export const SideNavBottom: VFC<Props> = ({
  sideNavLocked,
  isPopoverOpen,
  onClickCollapse,
  setIsPopoverOpen,
  setIsMessenger,
}) => {
  useProfiler('SideNavBottom')

  const { t } = useTranslation()
  const user = useCurrentUser()

  const profileBoxRef = useRef<HTMLButtonElement>(null)
  const popoverRef = useRef<HTMLDivElement>(null)
  const [unreadCount] = useChannelTalk(
    useMemo(
      () => ({
        onShowMessenger: () => setIsMessenger(true),
        onHideMessenger: () => setIsMessenger(false),
      }),
      [setIsMessenger],
    ),
  )

  const signOut = useCallback(async () => {
    try {
      destruct()
      tracker.GlobalNavSignOut()

      const res = await client.post<LogoutResponse>('/logout', {})
      if (res?.IdentityProviderSLOURL) {
        window.location.href = res.IdentityProviderSLOURL
      } else {
        window.location.href = urls.signIn
      }
    } catch (e) {
      // eslint-disable-next-line no-alert
      alert(t('SIGN_OUT_ERROR'))
    }
  }, [t])
  const [updateProfile] = useUpdateProfileMutation()
  const closeProile = useCallback(
    (e: ReactMouseEvent<unknown, MouseEvent>) => {
      // ボタンをクリックした時に2重実行を防ぐ
      if (isClickedInsideRef(profileBoxRef, e.target)) return
      setIsPopoverOpen(false)
    },
    [setIsPopoverOpen],
  )
  const togglePopover = useCallback<MouseEventHandler<HTMLElement>>(() => {
    setIsPopoverOpen(!isPopoverOpen)
  }, [isPopoverOpen, setIsPopoverOpen])

  const handleClickPopoverLink = useCallback<MouseEventHandler<HTMLAnchorElement>>(
    (e) => {
      // isPopoverOpenの更新により、Popoverを閉じるとグロナビ上にマウスカーソルがあっても一瞬閉じるので、refを使って非表示にする
      if (popoverRef.current) {
        popoverRef.current.style.display = 'none'
      }

      setTimeout(() => {
        togglePopover(e)
      }, 0)
    },
    [togglePopover],
  )

  const onAvatarImageUploaded = useCallback(
    (newimage: ImageFileInfo) => {
      if (!user) return

      updateProfile({
        variables: {
          firstName: user.firstName,
          lastName: user.lastName,
          avatarPath: newimage.path || null,
        },
      })
    },
    [user, updateProfile],
  )

  return (
    <div
      className={SIDE_NAV_BOTTOM_CLASS_NAME}
      data-is-collapse={sideNavLocked}
      css={sideNavBottomBox}
    >
      {/* チャネルトーク */}
      <button type="button" className={CUSTOM_LAUNCHER_CLASS_NAME} css={unreadCountBox}>
        <div data-is-unread-count={unreadCount} css={unreadCountCircle}>
          <StyledText size="xxsmall" color="white-100" css={unreadCountCss}>
            {unreadCount}
          </StyledText>
        </div>
        <Icon
          type={unreadCount ? 'channelTalkNotification' : 'channelTalk'}
          width={28}
          height={28}
        />
      </button>
      {/* アバター */}
      <button
        type="button"
        className={USER_ICON_CLASS_NAME}
        ref={profileBoxRef}
        css={profileButtonCss}
        onClick={togglePopover}
      >
        <div css={userIconCss}>
          {user && (
            <Avatar
              tooltipDisabled
              firstName={user.firstName}
              lastName={user.lastName}
              avatarUrl={user.avatar?.url}
              size="okr-map-tree-page"
              isUserDisabled={false}
            />
          )}
          <Icon type="closed" width={20} height={20} css={profileCloseIconCss} />
        </div>
      </button>
      {/* プロフィール、設定動線UI */}
      {isPopoverOpen && user && (
        <Drop
          ref={popoverRef}
          align={popoverStyle.align}
          margin={popoverStyle.margin}
          target={profileBoxRef.current || undefined}
          round="8px"
          css={popoverStyle.css}
          onClickOutside={closeProile}
        >
          <div css={profileBox}>
            <div css={profileIconCss}>
              <Avatar
                tooltipDisabled
                firstName={user.firstName}
                lastName={user.lastName}
                avatarUrl={user.avatar?.url}
                size="side-nav-popover"
                hoverStyle={profileHoverAvatarCss}
                css={profileAvatarCss}
                isUserDisabled={false}
              />
              <ImageUploader kind="avatars" onUploaded={onAvatarImageUploaded}>
                <div css={profileImageUploaderCss}>
                  <Icon
                    type="camera"
                    width={13}
                    height={13}
                    color={color('text-bk-50')}
                    css={profileImageUploadIconCss}
                  />
                </div>
              </ImageUploader>
            </div>
            <StyledText size="small" css={profileNameCss} oneline isWrap numberOfOmittedLines={1}>
              {t('FULL_NAME', {
                firstName: user?.firstName ?? '',
                lastName: user?.lastName ?? '',
              })}
            </StyledText>
            <div css={profileLinkCss}>
              <Link
                css={linkCss}
                href={urls.setting}
                onClick={(e) => {
                  handleClickPopoverLink(e)
                  tracker.GlobalNavTransitionToUserSetting()
                }}
              >
                <Icon width={16} height={16} type="config" />
                <StyledText size="large" fontStyle="regular">
                  {t('SETTING')}
                </StyledText>
              </Link>
              <Link
                css={linkCss}
                method="newTab"
                href={urls.ExternalUrls.HELP_PAGE}
                onClick={(e) => {
                  handleClickPopoverLink(e)
                  tracker.GlobalNavTransitionToHelpPage()
                }}
              >
                <Icon width={16} height={16} type="help" />
                <StyledText size="large" fontStyle="regular">
                  {t('HELP')}
                </StyledText>
              </Link>
              <Link css={linkCss} onClick={signOut}>
                <Icon width={16} height={16} type="signOut" />
                <StyledText size="large" fontStyle="regular">
                  {t('SIGN_OUT')}
                </StyledText>
              </Link>
            </div>
          </div>
        </Drop>
      )}
      <div className={COLLAPSE_CLASS_NAME} css={gapBorderCss} />
      {/* 折り畳みUI */}
      <button
        type="button"
        className={COLLAPSE_CLASS_NAME}
        onClick={onClickCollapse}
        css={collapseBox}
      >
        <Icon
          type={sideNavLocked ? 'chevronDoubleLeft' : 'chevronDoubleRight'}
          color={color('text-bk-50')}
        />
        <StyledText size="small" color="text-bk-50" css={collapsedTextCss}>
          {sideNavLocked ? t('COLLAPSE') : t('LOCK')}
        </StyledText>
      </button>
    </div>
  )
}

SideNavBottom.displayName = 'SideNavBottom'
