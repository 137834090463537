import React from 'react'

import { Switch } from '../../../../components/ui/Switch'
import { useTranslation } from '../../../../i18n'

import { rootCss, titleCss, descriptionCss } from './styles'

export type Props = {
  loading: boolean
  enabled: boolean
  handleClickSwitch: (enabled: boolean) => void
}

export const OneOnOneEnabledContent: React.VFC<Props> = ({
  loading,
  enabled,
  handleClickSwitch,
}) => {
  const { t } = useTranslation()

  return (
    <div css={rootCss}>
      <Switch disabled={loading} isCheck={enabled} handleCheckboxClick={handleClickSwitch} />
      <div>
        <p css={titleCss}>{t('ACTIVATING_X', { x: t('ONEONONE_FEATURE') })}</p>
        <p css={descriptionCss}>{t('ONEONONE_ACTIVETE_DESCRIPTION')}</p>
      </div>
    </div>
  )
}
