import { useState, useCallback } from 'react'

export type UseDisclosureProps = {
  defaultIsOpen?: boolean
  onOpen?(): void
  onClose?(): void
}

export type UseDisclosureReturn = {
  isOpen: boolean
  open(): void
  close(): void
}

export const useDisclosure = ({
  defaultIsOpen,
  onOpen,
  onClose,
}: UseDisclosureProps = {}): UseDisclosureReturn => {
  const [isOpen, setIsOpen] = useState(defaultIsOpen || false)

  const open = useCallback(() => {
    setIsOpen(true)
    if (onOpen) {
      onOpen()
    }
  }, [onOpen])

  const close = useCallback(() => {
    setIsOpen(false)
    if (onClose) {
      onClose()
    }
  }, [onClose])

  return {
    isOpen,
    open,
    close,
  }
}
