import { useContext } from 'react'

import { Organization, OrganizationContext } from '../../contexts/OrganizationContext'
import { AuthRouteProps } from '../../types/authRouteProps'

import { useUpdateOrganizationMutation } from './api/graphql'
import { OrganizationAdmin } from './components/OrganizationAdmin'

export type OrganizationAdminProps = {
  organization: Organization
  updateOrganization: (
    name: string,
  ) => ReturnType<ReturnType<typeof useUpdateOrganizationMutation>[0]>
}

export type Props = AuthRouteProps

export const AdminOrganizationContainer: React.FC<Props> = ({ ...props }) => {
  const organization = useContext(OrganizationContext)
  const [update] = useUpdateOrganizationMutation()

  if (!organization) {
    return null
  }

  return (
    <OrganizationAdmin
      organization={organization}
      updateOrganization={(name: string) =>
        update({
          variables: { name },
        })
      }
      {...props}
    />
  )
}

AdminOrganizationContainer.displayName = 'AdminOrganizationContainer'
