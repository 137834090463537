import { color } from '../../../../../../styles/newColors'

export const styles = {
  editor: {
    marginTop: -8,
    lineHeight: 1,
  },
  commentDelete: {
    color: color('text-bk-50'),
  },
}
