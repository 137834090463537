import { useCallback } from 'react'

import { useTranslation } from '../../../i18n'
import { Screen } from '../../../lib/screen'
import { tracker } from '../../../lib/tracking'
import { useCheckinModal } from '../../standalone/CheckinModal'
import { StyledText } from '../../ui/StyledText'
// TODO: 全てロジックも共通化したかったがここでhooksを使うと親コンポーネントも手を加えることになり、スコープがかなり広がるため、コンポーネントを直接参照している
import { CheckinButton } from '../CheckinButton/CheckinButton'

export type Props = {
  okrNodeId: string
  isCheckinPeriod: boolean
  alreadyCheckin?: boolean
  isOwnerOrContributor: boolean
}

export const CheckinTitleAndButton: React.FC<Props> = ({
  okrNodeId,
  isCheckinPeriod,
  alreadyCheckin = false,
  isOwnerOrContributor,
}) => {
  const { t } = useTranslation()

  const [CheckinModal, isOpenCheckinModal, { open, close }] = useCheckinModal()
  const openCheckinModal = useCallback(() => {
    tracker.UserClickOpenCheckin(Screen.Okr)
    open()
  }, [open])
  const closeCheckinModal = useCallback(() => close(), [close])
  const executeCheckin = useCallback(() => {
    tracker.UserClickCompletedCheckin(Screen.Okr)
    close()
  }, [close])
  const backCheckinModal = useCallback(() => {
    tracker.UserClickBackCheckin(Screen.Okr)
  }, [])

  if (isCheckinPeriod) {
    return (
      <>
        <StyledText size="xlarge" lineHeight="32px">
          {t('CHECKIN_OF_THE_WEEK')}
        </StyledText>
        {!isOwnerOrContributor ? (
          <CheckinButton isNotAssignee />
        ) : (
          <>
            <CheckinButton alreadyCheckin={alreadyCheckin} onClick={openCheckinModal} />
            {isOpenCheckinModal && (
              <CheckinModal
                isOpen={isOpenCheckinModal}
                onClose={closeCheckinModal}
                onConfirm={executeCheckin}
                onBack={backCheckinModal}
                okrNodeId={okrNodeId}
              />
            )}
          </>
        )}
      </>
    )
  }

  return (
    <>
      <StyledText size="xlarge" lineHeight="32px">
        {t('CHECKIN_IN_THIS_WEEK')}
      </StyledText>
      <CheckinButton isNotOkrPeriod />
    </>
  )
}
