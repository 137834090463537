import { useTranslation } from '../../../i18n'
import { Modal } from '../../ui/Modal'
import { StyledText } from '../../ui/StyledText'

export type Props = {
  withGeishaModal?: boolean
  onClickDelete: () => void
  onClickCancel: () => void
}

export const OkrDeleteAlertModal: React.VFC<Props> = ({
  withGeishaModal,
  onClickDelete,
  onClickCancel,
}) => {
  const { t } = useTranslation()

  return (
    <Modal
      isOpened
      withGeishaModal={withGeishaModal}
      title={t('DELETION_X', { x: t('OKR') })}
      executeText={t('DELETION')}
      onClickExecute={onClickDelete}
      onClickOutside={onClickCancel}
      onClickCancel={onClickCancel}
    >
      <StyledText color="text-bk-100" size="medium">
        {t('X_CONFIRMATION_OF_DELETE', { x: t('OKR') })}
        {t('WARNING')}
      </StyledText>
    </Modal>
  )
}
OkrDeleteAlertModal.displayName = 'OkrDeleteAlertModal'
