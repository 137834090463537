import { css } from '@emotion/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useRef, useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'

import { useTranslation } from '../../../i18n'
import { TargetSettingType } from '../../../pages/OneOnOnesDetail/Drawer/FloatDrawer/Content/components/KeyResultDescription/graphql'
import * as newColor from '../../../styles/newColors'
import { KeyResultTargetSettingIcon } from '../../domain/KeyResultTargetSettingIcon'
import { ErrorMessage } from '../ErrorMessage'

type Props = {
  title: string
  onUpdate: (title: string) => void
}

type FormData = { title: string }

export const Title: React.FC<Props> = ({ title, onUpdate }) => {
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [editable, setEditable] = useState(false)
  const { register, handleSubmit } = useForm<FormData>()

  useEffect(() => {
    if (!editable) return
    if (!inputRef.current) return
    inputRef.current.focus()
  }, [editable])

  const onSubmit = (data: FormData) => {
    onUpdate(data.title)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div css={{ width: '100%' }}>
        {editable ? (
          <input
            type="text"
            defaultValue={title}
            css={{ width: '100%', outline: 'none' }}
            {...(register('title'),
            {
              onBlur: () => {
                setEditable(false)
                if (!inputRef.current) return
                if (inputRef.current.value === title) return
                onUpdate(inputRef.current.value)
              },
            })}
            ref={(e) => {
              if (!e) return
              // register('title') // TODO: 「e」を入れるとタイプエラーになる。仕様を要確認
              inputRef.current = e
            }}
          />
        ) : (
          <button
            type="button"
            onClick={() => {
              setEditable(true)
            }}
            css={{ width: '100%', outline: 'none' }}
          >
            <p
              css={{
                textAlign: 'left',
                overflowWrap: 'anywhere',
              }}
            >
              {title}
            </p>
          </button>
        )}
      </div>
    </form>
  )
}

Title.displayName = 'Title'

type NewTitleProps = {
  title: string
  color: newColor.ColorAlias
  focus?: boolean
  readonly?: boolean
  onClickLink?: () => void
  onUpdate?: (title: string) => void
  targetSetting?: TargetSettingType
  disabled?: boolean
  isWrap?: boolean
  numberOfOmittedLines?: number
  isPointer?: boolean
  sideBarBorderRadius?: number
  sideBarHeight?: number
}

export const NewTitle: React.FC<NewTitleProps> = ({
  title,
  color,
  focus = false,
  readonly = false,
  onClickLink,
  onUpdate,
  targetSetting,
  disabled = false,
  isWrap = false,
  numberOfOmittedLines = 2,
  isPointer = false,
  sideBarBorderRadius = 2,
  sideBarHeight = 22,
  ...props
}) => {
  const { t } = useTranslation()
  const [editable, setEditable] = useState(focus)

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    watch,
  } = useForm<FormData>({
    mode: 'all',
    defaultValues: {
      title,
    },
    resolver: yupResolver(
      Yup.object().shape({
        title: Yup.string()
          .required(t('FIELD_EMPTY_ERROR', { field: t('TITLE') }))
          .notOneOf([title], ''),
      }),
    ),
  })

  const [watchTitle] = watch(['title'])

  const onSubmit = (data: FormData) => {
    if (onUpdate) {
      onUpdate(data.title)
    }
  }

  const titleCss = css([
    { display: 'flex', gap: 8, padding: '8px 0' },
    !readonly && {
      '&:hover': {
        backgroundColor: newColor.color('hover-background-bk-5'),
        outline: 'none',
      },
      '&:focus-within': {
        backgroundColor: newColor.color('background-bk-5'),
        borderBottom: `1px solid ${newColor.color(color)}`,
        borderLeft: 'none',
        outline: 'none',
      },
    },
  ])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div css={css(titleCss)} {...props}>
        <div
          css={{
            backgroundColor: newColor.color(color),
            borderRadius: sideBarBorderRadius,
            minHeight: sideBarHeight,
            width: 3,
            content: '""',
          }}
        />
        {editable ? (
          <input
            {...(register('title'),
            {
              onChange: (e) => {
                setValue('title', e.currentTarget.value)
              },
              onKeyDown: (e) => {
                e.currentTarget.style.height = `${e.currentTarget.scrollHeight}px`
              },
              onFocus: (e) => {
                const target = e.currentTarget
                target.style.height = `${target.scrollHeight}px`
                target.setSelectionRange(target.value.length, target.value.length)
              },
              onBlur: () => {
                setEditable(false)
                const updatedTitle = getValues('title')
                if (onUpdate && updatedTitle !== '') {
                  onUpdate(updatedTitle)
                }
              },
            })}
            type="text"
            defaultValue={watchTitle}
            css={{
              width: '100%',
              outline: 'none',
              resize: 'none',
            }}
            required
          />
        ) : (
          <button
            type="button"
            onClick={() => {
              if (!readonly) {
                setEditable(true)
                return
              }
              if (onClickLink) {
                onClickLink()
              }
            }}
            css={{
              width: '100%',
              outline: 'none',
              cursor: readonly && !isPointer ? 'default' : 'pointer',
            }}
          >
            <p
              css={[
                {
                  textAlign: 'left',
                  overflowWrap: 'anywhere',
                  wordBreak: 'break-all',
                  cursor: onClickLink && 'pointer',
                  textDecoration: disabled ? 'line-through' : undefined,
                  opacity: disabled ? 0.5 : undefined,
                  ':hover': {
                    color: onClickLink && newColor.color('resily-orange-100'),
                    textDecorationLine: onClickLink && 'underline',
                  },
                },
                isWrap && {
                  display: '-webkit-box',
                  overflow: 'hidden',
                  // stylelint-disable-next-line
                  WebkitLineClamp: numberOfOmittedLines,
                  // stylelint-disable-next-line
                  WebkitBoxOrient: 'vertical',
                },
              ]}
            >
              <span>
                <KeyResultTargetSettingIcon
                  targetSetting={targetSetting || TargetSettingType.None}
                  margin="0 4px 0 0"
                />
              </span>
              <span>{watchTitle}</span>
            </p>
          </button>
        )}
      </div>
      <ErrorMessage>{errors.title?.message}</ErrorMessage>
    </form>
  )
}

NewTitle.displayName = 'NewTitle'
