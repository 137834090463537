import { forwardRef, Ref } from 'react'

import { useTranslation } from '../../../i18n/index'
import { color } from '../../../styles/newColors'
import { Tags } from '../../ui/Tags'

import { OkrTagsOkrNodeItemFragment } from './graphql'

export type Props = {
  name?: string
  value?: ReadonlyArray<OkrTagsOkrNodeItemFragment>
  candidates: ReadonlyArray<OkrTagsOkrNodeItemFragment>
  onChange?: (keyResults: ReadonlyArray<OkrTagsOkrNodeItemFragment>) => void
  disabled?: boolean
  width?: string
}

export const OkrTags = forwardRef<HTMLInputElement, Props>(
  (
    { name, value = [], candidates = [], onChange, disabled, width = '684px' },
    _: Ref<HTMLInputElement>,
  ) => {
    const { t } = useTranslation()

    const options = candidates.map((o) => ({
      value: o.id,
      label: o.name,
      searchText: o.name,
      css: {
        width,
      },
    }))

    return (
      <div>
        <Tags
          css={{ padding: 0, width }}
          name={name}
          placeholder={t('SELECT_X', { x: t('KEY_RESULT') })}
          size="ss"
          border={{ color: color('border-bk-10'), side: 'all' }}
          value={value.map((o) => o.id)}
          options={options}
          onChange={(v) => {
            if (onChange) {
              onChange(candidates.filter((o) => v.includes(o.id)))
            }
          }}
          disabled={disabled}
        />
      </div>
    )
  },
)

OkrTags.displayName = 'OkrTags'
