import 'core-js/features/string/replace-all'
import { Scope, captureException, withScope } from '@sentry/react'
import React from 'react'
import ReactDOM from 'react-dom'
import TagManager from 'react-gtm-module'

import { App } from './App'
import { configureTracking } from './config'
import { i18n } from './i18n'
import { sendToAnalytics } from './lib/tracking'
import { reportWebVitals } from './reportWebVitals'

// HACK: この対応はReactのVDOMと実DOMの差異があることによって起こるクラッシュ問題の暫定対応
//       上記が原因でリッチテキストエディタがクラッシュが発生するのを回避するためである
//       本来この処理は不要でありアプリケーション全体のパフォーマンスが低下するため、即座に中止すべきである
//       恐らくリッチテキストエディタが返却する値をstateで保持しないように改修することで修正される見込み
const originalRemoveChild = Node.prototype.removeChild
// eslint-disable-next-line func-names
Node.prototype.removeChild = function <T extends Node>(child: T): T {
  if (child.parentNode !== this) {
    withScope((scope) => {
      scope.setExtras(child as unknown as Parameters<Scope['setExtras']>[0])
      captureException(new Error('Cannot remove a child from a different parent'))
    })
    return child
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line prefer-rest-params
  return originalRemoveChild.apply(this, arguments)
}
const originalInsertBefore = Node.prototype.insertBefore
// eslint-disable-next-line func-names
Node.prototype.insertBefore = function <T extends Node>(node: T, child: Node | null): T {
  if (child && child.parentNode !== this) {
    withScope((scope) => {
      scope.setExtras(child as unknown as Parameters<Scope['setExtras']>[0])
      captureException(new Error('Cannot insert before a reference node from a different parent'))
    })
    return node
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line prefer-rest-params
  return originalInsertBefore.apply(this, arguments)
}

// Initialize GTM
TagManager.initialize({
  gtmId: configureTracking().googleTagManagerCode,
})

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
)

// NOTE: userの言語設定をlangに設定
document.querySelector('html')?.setAttribute('lang', i18n.language)

reportWebVitals(sendToAnalytics)
