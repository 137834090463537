import { useTranslation } from '../../../../../../i18n'

import { useGetEditedKeyResultCount } from './hooks/useGetEditedKeyResultCount'

export const EditedCount: React.VFC = () => {
  const { t } = useTranslation()
  const { totalCount, editedCount } = useGetEditedKeyResultCount()

  return <>{`${editedCount}/${totalCount} ${t('INPUT_COMPLETE')}`}</>
}
