import { Box, Button } from 'grommet'
import { useState, useEffect, useRef, useCallback } from 'react'
import { Rnd, DraggableData } from 'react-rnd'

import { useTranslation } from '../../../i18n'
import { border } from '../../../styles/border'
import { color } from '../../../styles/newColors'
import { Icon } from '../Icon'
import { TooltipNew } from '../TooltipNew'

export type Position = { x: number; y: number }

export type Props = {
  open?: boolean
  show?: boolean
  header?: JSX.Element
  position?: Position
  parentClientRect: DOMRect
  zIndex?: number
  bounds?: string
  onDragStart?: (data: DraggableData) => void
  onDragStop?: (data: DraggableData) => void
  onResizeStart?: (ref: HTMLElement | null) => void
  onResizeStop?: (ref: HTMLElement | null, position: Position) => void
  onRemoved?: () => void
  onMouseEnter?: (e: MouseEvent) => void
  onMouseLeave?: (e: MouseEvent) => void
  onClick?: (e: MouseEvent) => void
}

export const defaultWidth = 354
export const defaultHeight = 558
const headerHeight = 32
const contentHeight = defaultHeight - headerHeight

export const FloatDrawer: React.FC<Props> = ({
  open = true,
  show = true,
  header = null,
  position,
  parentClientRect,
  zIndex,
  onDragStart,
  onDragStop,
  onResizeStart,
  onResizeStop,
  onRemoved,
  onMouseEnter,
  onMouseLeave,
  onClick,
  children,
}) => {
  const { t } = useTranslation()
  const contentRef = useRef<HTMLDivElement>(null)
  const headerRef = useRef<HTMLDivElement>(null)
  const [resizableRnd, setResizableRnd] = useState<Rnd | null>(null)
  const [draggableRnd, setDraggableRnd] = useState<Rnd | null>(null)
  const [isDragging, setIsDragging] = useState(false)
  const [isOpen, setIsOpen] = useState(open)

  // MEMO: boundsをreact-rnd指定のものにするとドラッグ範囲のカスタマイズができないため
  //       手動設定値が有効になるような値を設定する
  const bounds = 'custom'

  const setDefaultBoundsState = useCallback(() => {
    if (!draggableRnd || typeof parentClientRect === 'undefined') return

    const { top, left, right, bottom } = parentClientRect
    draggableRnd.setState({
      bounds: {
        top: 0,
        right: right - left - Math.floor(defaultWidth / 2),
        left: 0,
        bottom: bottom - top - headerHeight,
      },
    })
  }, [draggableRnd, parentClientRect])

  const setDefaultSize = useCallback(
    (newIsOpen: boolean) => {
      if (!resizableRnd || !draggableRnd || !contentRef.current) return

      const newHeight = newIsOpen ? defaultHeight : headerHeight
      contentRef.current.style.height = `${contentHeight}px`
      resizableRnd.updateSize({ width: defaultWidth, height: newHeight })
      draggableRnd.updateSize({ width: defaultWidth, height: newHeight })
      setDefaultBoundsState()
    },
    [resizableRnd, draggableRnd, contentRef, setDefaultBoundsState],
  )

  useEffect(() => {
    setIsOpen(open)
  }, [open])

  useEffect(() => {
    setDefaultBoundsState()
  }, [setDefaultBoundsState])

  const onSwitchIsOpen = useCallback(
    (newIsOpen: boolean) => {
      setIsOpen(newIsOpen)
      setDefaultSize(newIsOpen)
    },
    [setDefaultSize],
  )

  const onRemoveButtonClicked = useCallback(
    (e) => {
      e.stopPropagation()
      if (onRemoved) {
        onRemoved()
      }
    },
    [onRemoved],
  )

  if (!show) {
    return null
  }

  return (
    <Rnd
      ref={(c) => setDraggableRnd(c)}
      onDrag={() => setIsDragging(true)}
      default={{
        x: position?.x ?? 0,
        y: position?.y ?? 0,
        width: defaultWidth,
        height: headerHeight,
      }}
      enableResizing={{
        bottom: false,
        bottomLeft: false,
        bottomRight: false,
        left: false,
        right: false,
        top: false,
        topLeft: false,
        topRight: false,
      }}
      bounds={bounds}
      onDragStart={(_, data) => {
        if (onDragStart) {
          onDragStart(data)
        }
      }}
      onDragStop={(_, data) => {
        if (onDragStop) {
          onDragStop(data)
        }
      }}
      css={{
        zIndex: zIndex ?? 'auto',
        pointerEvents: 'auto',
      }}
      onMouseEnter={(e: MouseEvent) => {
        if (onMouseEnter) {
          onMouseEnter(e)
        }
      }}
      onMouseLeave={(e: MouseEvent) => {
        if (onMouseLeave) {
          onMouseLeave(e)
        }
      }}
      cancel=".cancel"
    >
      <Rnd
        ref={(c) => setResizableRnd(c)}
        default={{
          x: 0,
          y: 0,
          width: defaultWidth,
          height: defaultHeight,
        }}
        enableResizing={{
          bottom: true,
          bottomLeft: true,
          bottomRight: true,
          left: true,
          right: true,
          top: false,
          topLeft: false,
          topRight: false,
        }}
        css={{
          background: color('white-100'),
          border: border('simple-30'),
          boxShadow: '0px 4px 16px rgb(34 41 67 / 16%)',
          borderRadius: '8px',
          overflow: 'hidden',
        }}
        bounds={bounds}
        onResizeStart={(_e, direction, ref) => {
          setIsOpen(true)
          if (!resizableRnd) {
            if (onResizeStart && draggableRnd) {
              onResizeStart(draggableRnd.getSelfElement())
            }
            return
          }

          const { right: boundsRight, left: boundsLeft } = parentClientRect
          const { right, left, width: currentWidth } = ref.getBoundingClientRect()
          if (direction === 'right' || direction === 'bottomRight') {
            // 右端ウインドウを超えられるのは幅の半分までのため、ウインドウまでの長さを倍にしたものを最大幅に設定する
            const maxWidth = (currentWidth + boundsRight - right) * 2
            resizableRnd.setState({ maxWidth })
          } else if (direction === 'left' || direction === 'bottomLeft') {
            // 左端ウインドウは超えられないため左端までを最大幅に設定する
            const maxWidth = currentWidth + left - boundsLeft
            resizableRnd.setState({ maxWidth })
          }
          if (onResizeStart && draggableRnd) {
            onResizeStart(draggableRnd.getSelfElement())
          }
        }}
        onResize={(_e, _direction, ref, _delta, _position) => {
          if (!contentRef.current) return

          contentRef.current.style.height = `calc(${ref.style.height} - ${headerHeight}px)`
        }}
        onResizeStop={(_e, direction, ref, delta, _position) => {
          if (!draggableRnd) return

          // リサイズ後のサイズ・ポジションにドラッグ用の要素を追従させる
          const { width: newWidth } = ref.getBoundingClientRect()
          draggableRnd.updateSize({ width: newWidth, height: headerHeight })
          if (direction === 'left' || direction === 'bottomLeft') {
            const { x, y } = draggableRnd.getDraggablePosition()
            draggableRnd.updatePosition({ x: x - delta.width, y })
            resizableRnd?.updatePosition({ x: 0, y: 0 })
          }
          const { right, left } = parentClientRect
          draggableRnd.setState((prev) => ({
            bounds: { ...prev.bounds, right: right - left - Math.floor(newWidth / 2) },
          }))

          if (onResizeStop) {
            const { x, y } = ref.getBoundingClientRect()
            onResizeStop(draggableRnd.getSelfElement(), { x, y })
          }
        }}
        minWidth={defaultWidth}
        minHeight={headerHeight}
        disableDragging
      >
        <Box
          ref={headerRef}
          direction="row"
          gap="8px"
          height={`${headerHeight}px`}
          align="center"
          alignContent="between"
          pad={{ right: '12px', left: '8px' }}
          css={{ boxShadow: '0px 1px 0px #D3D4D9' }}
          onClick={(e: MouseEvent) => {
            if (isDragging) {
              setIsDragging(false)
              return
            }
            onSwitchIsOpen(!isOpen)
            if (onClick) {
              onClick(e)
            }
          }}
        >
          <Box flex={{ grow: 1, shrink: 1 }} basis="0">
            {header}
          </Box>
          <Box width="48px" direction="row" gap="8px">
            <TooltipNew title={isOpen ? t('MINIMIZE') : t('EXPAND')}>
              <Button onClick={() => onSwitchIsOpen(!isOpen)}>
                <Icon
                  type={isOpen ? 'remove' : 'keyboardArrowDown'}
                  width={20}
                  height={20}
                  color={color('text-bk-50')}
                  hoverColor="text-bk-100"
                />
              </Button>
            </TooltipNew>
            <Button onClick={onRemoveButtonClicked}>
              <Icon
                type="mdiClear"
                width={20}
                height={20}
                color={color('text-bk-50')}
                hoverColor="text-bk-100"
              />
            </Button>
          </Box>
        </Box>
        <Box
          ref={contentRef}
          data-is-opened={isOpen}
          height={`${contentHeight}px`}
          overflow={{ vertical: 'scroll' }}
          pad={{ top: '20px', horizontal: '12px', bottom: '24px' }}
          css={{
            cursor: 'auto',
            height: `calc(${defaultHeight}px - ${headerHeight}px)`,
            '&[data-is-opened="false"]': {
              display: 'none',
            },
          }}
          className="cancel"
          onClick={(e) => {
            if (onClick) {
              onClick(e)
            }
          }}
        >
          {children}
        </Box>
      </Rnd>
    </Rnd>
  )
}

FloatDrawer.displayName = 'FloatDrawer'
