/* eslint-disable no-restricted-imports */
import { TFuncKey as OriginTKeys } from 'react-i18next'

export type TKeys = OriginTKeys
export type TArgs = { [key: string]: string | number | Date }

// Ignore sentence processing keys
export const ignoreSentenceKeys = [
  'FULL_NAME',
  'SHORT_NAME',
  'CHAR_NAME',
  'INTEGRATE_WITH_X_TAG_TOOLTIP',
]

export type OkrGlossaryI18nKey =
  | 'OBJECTIVE'
  | 'KEY_RESULT'
  | 'KR'
  | 'OKR'
  | 'OKR_MEETING'
  | 'WIN_SESSION'
  | 'ACTION_PLAN'
  | 'PROBLEM'
  | 'CONFIDENCE'
  | 'CONTRIBUTOR'
  | 'ONEONONE'
  | 'OTHER'
  | 'PRIORITY'
  | 'OWNER'
  | 'CHECKIN'

type ShouldKeepIntactKey =
  | 'OBJECTIVE'
  | 'KEY_RESULT'
  | 'KR'
  | 'OKR'
  | 'OKR_MEETING'
  | 'WIN_SESSION'
  | 'CONFIDENCE'
  | 'ONEONONE'
  | 'NOTE'
  | 'LABEL'
  | 'GROUP'
  | 'ACTION_PLAN'
  | 'CHECKIN'

// Should keep Intact word/sentence keys
export const shouldKeepIntactKeys: Array<ShouldKeepIntactKey> = [
  'OKR_MEETING',
  'OBJECTIVE',
  'KEY_RESULT',
  'KR',
  'OKR',
  'WIN_SESSION',
  'CONFIDENCE',
  'ONEONONE',
  'NOTE',
  'LABEL',
  'GROUP',
  'ACTION_PLAN',
  'CHECKIN',
]

/** ShouldKeepIntactKeyに含まれない変換対象raw textの用語Key */
export const translateKeys: Array<Exclude<OkrGlossaryI18nKey, ShouldKeepIntactKey>> = [
  'PRIORITY',
  'PROBLEM',
  'OWNER',
  'CONTRIBUTOR',
  'OTHER',
]
