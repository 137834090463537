import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { useCurrentUser } from '../../contexts/UserContext'
import { useTranslation } from '../../i18n'
import { useOkrTermLoadable } from '../../lib/domain/useOkrTermLoadable'
import { AuthRouteProps } from '../../types/authRouteProps'
import { generateNoteEdit } from '../../urls'

import { Okr } from './Okr'
import {
  CheckinSummaryFragment,
  KeyResultSharedItemType,
  NotePermission,
  OkrDocument,
  OkrQuery,
  OkrQueryVariables,
  ReOrderingKeyResultMutationVariables,
  UpdateActionPlanMutationVariables,
  useCreateNoteMutation,
  useDeleteNoteMutation,
  useOkrNodeForCheckinSummariesLazyQuery,
  useOkrQuery,
  useOkrTermsQuery,
  useReOrderingKeyResultMutation,
  useUpdateCheckinSummaryDescriptionMutation,
  useUpdateActionPlanMutation,
  useUpdateKeyResultSharedItemMutation,
  DocumentInput,
} from './graphql'

export const OkrContainer: React.FC<AuthRouteProps> = ({ onOkrTermLoaded }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const user = useCurrentUser()
  const { nodeId } = useParams<{ nodeId: string }>()

  const userId = user?.id
  if (!userId) {
    throw new Error()
  }

  const { data, loading, refetch } = useOkrQuery({
    variables: {
      okrNodeId: nodeId ?? '',
      historyLength: 1000, // FIXME: 初期何件取るのか精査が必要
      userId,
    },
    skip: nodeId == null,
  })

  const { data: okrTermsData } = useOkrTermsQuery()

  const [status, setStatus] = useState<'init' | 'processing' | 'completed'>('init')
  const [allCheckinSummaries, setAllCheckinSummaries] = useState<Array<CheckinSummaryFragment>>([])
  const [fetchCheckinSummaries, fetchCheckinSummariesState] =
    useOkrNodeForCheckinSummariesLazyQuery({
      onCompleted: (okrNodeData) => {
        const allCheckinSummaryIds = allCheckinSummaries.map((a) => a.id)
        const checkinSummaries = okrNodeData.okrNode.checkinSummaries.edges
          .map((e) => e.node)
          .flatMap((c) => (c ? [c] : []))
          .filter((c) => !allCheckinSummaryIds.includes(c.id))
        setAllCheckinSummaries(allCheckinSummaries.concat(checkinSummaries))
      },
    })
  const [reOrder] = useReOrderingKeyResultMutation({
    onCompleted: () => {
      setStatus('completed')
      refetch()
    },
  })
  const [createNote] = useCreateNoteMutation()
  const [deleteNoteMutation] = useDeleteNoteMutation()
  const [updateActionPlanMutation] = useUpdateActionPlanMutation()
  const [updateKeyResultSharedItemMutation] = useUpdateKeyResultSharedItemMutation()
  const [updateDescription] = useUpdateCheckinSummaryDescriptionMutation()

  useOkrTermLoadable(onOkrTermLoaded, undefined, data?.okrNode.term.id)

  useEffect(() => {
    if (!data || status === 'completed') return

    if (!data.okrNode.keyResults.find((kr) => kr.displayOrder == null)) {
      setStatus('completed')
    }

    // display orderが未設定のkey resultがある場合、display orderを設定する
    if (status === 'init' && data.okrNode.keyResults.find((kr) => kr.displayOrder == null)) {
      setStatus('processing')
      reOrder({
        variables: {
          nodeID: data.okrNode.id,
          keyResultID: data.okrNode.keyResults[0].id,
          displayOrder: 0,
        },
      })
    }
  }, [data, status, reOrder])

  if (loading) {
    return null
  }

  if (!data?.findUserById) {
    return null
  }

  if (!okrTermsData?.okrTerms) {
    return null
  }

  const isPrimary: boolean =
    okrTermsData.okrTerms.find((term) => term.primary)?.id === data.okrNode.term.id

  const reOrderKeyResult = async (val: Omit<ReOrderingKeyResultMutationVariables, 'nodeID'>) => {
    reOrder({
      variables: {
        nodeID: nodeId ?? '',
        ...val,
      },
    })
  }

  const createEmptyNote = async () => {
    const result = await createNote({
      variables: {
        input: {
          title: t('EMPTY_NOTE'),
          objectiveId: data.okrNode.objective.id,
          permission: NotePermission.Public,
          attachments: [],
          labelIds: [],
          userIds: [],
        },
      },
    })

    if (!result.data) {
      return
    }

    navigate(generateNoteEdit(result.data.createNote.id))
  }

  const deleteNote = async (noteId: string) => {
    await deleteNoteMutation({
      variables: { id: noteId },
      update(cache) {
        const option = {
          query: OkrDocument,
          variables: {
            okrNodeId: nodeId ?? '',
            historyLength: 1000,
            userId,
          },
        }
        const { okrNode } = cache.readQuery<OkrQuery, OkrQueryVariables>(option) || {}
        if (!okrNode) {
          return
        }

        cache.writeQuery<OkrQuery, OkrQueryVariables>({
          ...option,
          data: {
            okrNode: { ...okrNode, notes: okrNode.notes.filter((note) => note.id !== noteId) },
          },
        })
      },
    }).catch(() => {})
  }

  const updateActionPlan = async (variables: UpdateActionPlanMutationVariables) => {
    await updateActionPlanMutation({
      variables,
    })
  }

  const updateKeyResultSharedItem = async (
    checkinKeyResultId: string,
    itemType: KeyResultSharedItemType,
    body: DocumentInput,
  ) => {
    await updateKeyResultSharedItemMutation({
      variables: {
        id: checkinKeyResultId,
        itemType,
        body,
      },
      update(cache, { data: updatedData }) {
        if (!updatedData) return

        // 更新対象のkeyResultSharedItemが更新されたら（削除されていなければ）、Apollo Cacheの更新はしない
        if (updatedData.updateKeyResultSharedItem?.body) return

        // Apollo Cacheから削除したkeyResultSharedItemを削除
        cache.evict({ id: cache.identify(updatedData.updateKeyResultSharedItem) })
      },
    })
  }

  const { pageInfo: checkinSummareisPageInfo } = fetchCheckinSummariesState.data?.okrNode
    .checkinSummaries || {
    pageInfo: {
      hasNextPage: false,
    },
    edges: [],
  }

  if (!fetchCheckinSummariesState.called && nodeId) {
    fetchCheckinSummaries({ variables: { okrNodeId: nodeId, after: null } })
  }

  const onClickSave = async (treeJson: string, plainText: string) => {
    const checkinSummaryId = data.okrNode.currentCheckinSummary?.id
    if (!checkinSummaryId) return

    try {
      await updateDescription({
        variables: {
          id: checkinSummaryId,
          description: {
            treeJson,
            plainText,
          },
        },
      })
    } catch (e) {
      // nothing to do
    }
  }

  return (
    <Okr
      isPrimary={isPrimary}
      node={data.okrNode}
      user={data.findUserById}
      checkinSummary={data.okrNode.currentCheckinSummary || undefined}
      checkinSummaries={allCheckinSummaries}
      reOrder={reOrderKeyResult}
      createNote={createEmptyNote}
      deleteNote={deleteNote}
      fetchCheckinSummaries={() => {
        if (!nodeId || !checkinSummareisPageInfo.hasNextPage || fetchCheckinSummariesState.loading)
          return
        fetchCheckinSummaries({
          variables: {
            okrNodeId: nodeId,
            after: checkinSummareisPageInfo.endCursor,
          },
        })
      }}
      updateKeyResultSharedItem={updateKeyResultSharedItem}
      updateActionPlan={updateActionPlan}
      onClickSave={onClickSave}
    />
  )
}

OkrContainer.displayName = 'OkrContainer'
