import { css } from '@emotion/react'

import { color } from '../../../../../../../../styles/newColors'

export const rootCss = css({
  padding: '20px 24px 0 32px',
  backgroundColor: color('background-bk-5'),
})

export const skeletonWrapperCss = css({
  padding: '12px 8px 32px',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
})
