import { Skeleton } from '@resily/geisha'

import { useStyles } from './NoteCardSkeleton.styles'

const BORDER_HEIGHT = 52
const CONTENT_PADDING = 20

export type NoteCardSkeletonProps = {
  lineTitle?: number
}

export const NoteCardSkeleton: React.FC<NoteCardSkeletonProps> = ({ lineTitle = 1 }) => {
  const styles = useStyles()

  return (
    <div css={styles.root}>
      <Skeleton width={4} height={CONTENT_PADDING * lineTitle + BORDER_HEIGHT} />
      <div css={styles.wrapper}>
        <div css={styles.title}>
          {new Array(lineTitle).fill(undefined).map(() => (
            <Skeleton width="100%" height={12} />
          ))}
          <Skeleton width={56} height={10} />
        </div>
        <div css={styles.user}>
          <Skeleton circle width={14} height={14} />
          <Skeleton width={92} height={12} css={styles.userName} />
          <Skeleton width={40} height={10} />
        </div>
      </div>
    </div>
  )
}
