import { TFunction } from '../../../../i18n'

export const generateConfidenceTextMap = (
  t: TFunction,
): {
  1: string
  2: string
  3: string
  4: string
  5: string
} => ({
  1: t('CONFIDENCE_LEVEL_NOT_CONFIDENT'),
  2: t('CONFIDENCE_LEVEL_LESS_CONFIDENT'),
  3: t('CONFIDENCE_LEVEL_NEITHER'),
  4: t('CONFIDENCE_LEVEL_FAIRLY_CONFIDENT'),
  5: t('CONFIDENCE_LEVEL_VERY_CONFIDENT'),
})
