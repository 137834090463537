import { Box, Heading } from 'grommet'
import React, { useCallback } from 'react'

import { PageContent } from '../../components/pageContent'
import { CsvDownloadLink } from '../../components/ui/CsvDownloadLink'
import { CsvUploadForm } from '../../components/ui/CsvUploadForm'
import { Link } from '../../components/ui/Link'
import { StyledText } from '../../components/ui/StyledText'
import { useTranslation, Trans } from '../../i18n'
import { config } from '../../lib/client'
import { useSelfServeConfig } from '../../lib/domain/organization/useSelfServeConfig'
import { Screen } from '../../lib/screen'
import { useTracking } from '../../lib/tracking'
import { color } from '../../styles/newColors'
import { AuthRouteProps } from '../../types/authRouteProps'
import * as urls from '../../urls'
import { RemainingLicensesArea } from '../AdminUsers/components/RemainingLicensesArea'

import { useFetchSelfServeLazyQuery } from './graphql'

const USER_EXPORT_URL = `${config.base}/export/users`
const USER_IMPORT_URL = `${config.base}/import/users`

export type ContainerProps = AuthRouteProps

export const UsersImportContainer: React.VFC<ContainerProps> = () => {
  const { remainingLicenses } = useSelfServeConfig()

  const [fetchSelfServe] = useFetchSelfServeLazyQuery()
  const onUploadSucceed = useCallback(() => {
    if (remainingLicenses == null) return
    fetchSelfServe()
  }, [fetchSelfServe, remainingLicenses])

  return (
    <PageContent
      breadcrumbs={{
        url: urls.usersImportAdmin,
        items: [
          { breadcrumbName: 'setting' },
          { breadcrumbName: 'settingUsers' },
          { breadcrumbName: 'importUsers' },
        ],
      }}
      layout={{ css: { padding: '48px 36px' } }}
      contentBackgroundColor={color('background-bk-5')}
    >
      <UsersImport remainingLicenses={remainingLicenses} onUploadSucceed={onUploadSucceed} />
    </PageContent>
  )
}
UsersImportContainer.displayName = 'UsersImportContainer'

export type Props = {
  remainingLicenses: number | undefined
  onUploadSucceed: () => void
}

export const UsersImport: React.VFC<Props> = ({ remainingLicenses, onUploadSucceed }) => {
  const { t } = useTranslation()
  useTracking(t('CSV_IMPORT_PAGE_TITLE'), Screen.UsersImportAdmin)

  return (
    <>
      <Heading level="3" margin={{ bottom: '8px' }} css={{ fontSize: '24px' }}>
        {t('CSV_IMPORT_PAGE_TITLE')}
      </Heading>
      <p>
        <StyledText lineHeight="22px">{t('USER_CSV_IMPORT_DESCRIPTION')}</StyledText>
      </p>
      <Box
        as="ol"
        margin={{ top: '24px' }}
        background={color('white-100')}
        css={{ padding: '24px 32px', borderRadius: '8px' }}
      >
        <Box as="li" height={{ min: 'auto' }}>
          <Heading level="4" css={{ fontSize: '16px' }}>
            1. {t('DOWNLOAD_CSV_FILE')}
          </Heading>
          <div css={{ marginTop: '8px' }}>
            <StyledText lineHeight="22px">
              <Trans
                i18nKey="DOWNLOAD_CSV_FILE_DESCRIPTION"
                components={[
                  <Link
                    method="newTab"
                    href={urls.ExternalUrls.USERS_WITH_CSV}
                    css={{
                      color: color('resily-orange-100'),
                      textDecoration: 'underline',
                    }}
                  >
                    here
                  </Link>,
                ]}
              />
            </StyledText>
          </div>
          <CsvDownloadLink
            margin={{ top: 'large' }}
            exportUrl={USER_EXPORT_URL}
            exportOptions={[
              {
                key: 'all',
                value: 'true',
                text: t('USER_CSV_EXPORT_INCLUDE_DISABLED_USERS'),
              },
              {
                key: 'groups-csv',
                value: 'true',
                text: t('USER_CSV_EXPORT_INCLUDE_GROUP_CSV'),
                hint: t('USER_CSV_EXPORT_INCLUDE_GROUP_CSV_HINT'),
                link: {
                  text: t('USER_CSV_EXPORT_INCLUDE_GROUP_CSV_HINT_LINK'),
                  href: urls.ExternalUrls.USER_CSV_EXPORT_INCLUDE_GROUP_CSV_HINT_PAGE,
                },
              },
            ]}
          />
        </Box>
        <Box as="li" margin={{ top: '40px' }} height={{ min: 'auto' }} gap="8px">
          <Heading level="4" css={{ fontSize: '16px' }}>
            2. {t('UPLOAD_CSV_FILE')}
          </Heading>
          <StyledText lineHeight="22px">{t('UPLOAD_CSV_FILE_DESCRIPTION')}</StyledText>
          {remainingLicenses != null && (
            <RemainingLicensesArea
              remainingLicenses={remainingLicenses}
              backgroundColor={color('background-bk-5')}
            />
          )}
          <CsvUploadForm
            submitDisabled={remainingLicenses != null && remainingLicenses < 1}
            fileName="users.csv"
            importUrl={USER_IMPORT_URL}
            postBodyKey="users"
            onSubmitSuccess={onUploadSucceed}
          />
        </Box>
      </Box>
    </>
  )
}
UsersImport.displayName = 'UsersImport'
