import { css } from '@emotion/react'

import { fontSize } from '../../../../../styles/font'
import { color } from '../../../../../styles/newColors'

export const rootCss = css({
  backgroundColor: color('white-100'),
  borderRadius: '8px',
  padding: '32px 24px',
})

export const sectionCss = css({
  color: color('text-bk-100'),
  ...fontSize('large', 'bold'),
})

export const formCss = css({
  marginTop: '12px',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
})
export const avatarFieldCss = css({
  margin: 0,
})

export const fullNameFieldWrapperCss = css({
  display: 'flex',
  flexWrap: 'nowrap',
  columnGap: '24px',
})

export const fullNameFieldCss = css({
  width: 'calc(50% - 12px)',
  maxWidth: 228,
  margin: 0,
})

export const emailFieldCss = css({
  maxWidth: '480px',
  margin: 0,
})

export const inputCss = css({
  width: '100%',
})

export const formSubmitFieldCss = css({
  display: 'flex',
  gap: '20px',
  justifyContent: 'end',
  marginTop: '16px',
})
